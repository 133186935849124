 
import { format } from "date-fns";

import COMMENT_OWNER from "../../../../../../../assets/icons/BASE/COMMENT_OWNER.svg";
import SEND from "../../../../../../../assets/icons/BASE/SEND.svg";
import type { TimelineMessage } from "../../../../../../graphql/generated/schema";
import {
  Timeline_Message_Status,
  Timeline_Message_Type,
} from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import Box from "../../../../../Base/Box";
import { CustomText } from "../../../../../Base/Text";

interface TimelineMessageItemProps {
  message: TimelineMessage;
}

const BADGE_SIZE = 45;

const TimelineMessageItem = ({ message }: TimelineMessageItemProps) => {
  const { messageDate, messageType, messageStatus, messageTitle } = message;

  const getMessageType = () => {
    switch (messageType) {
      case Timeline_Message_Type.Email:
        return "Email";
      case Timeline_Message_Type.PushNotification:
        return "Push Notification";
      case Timeline_Message_Type.Sms:
        return "Sms";

      default:
        break;
    }
    return "-";
  };

  const getMessageStatus = () => {
    switch (messageStatus) {
      case Timeline_Message_Status.Delivered:
        return "Délivré";

      case Timeline_Message_Status.Error:
        return "Erreur";

      case Timeline_Message_Status.Read:
        return "Lu";
      case Timeline_Message_Status.Sent:
        return "Envoyé";

      default:
        break;
    }
    return "-";
  };

  return (
    <Box>
      <Box flexDirection="row" alignItems="center">
        <CustomText variant="label" fontSize={14} mr="s" color="primaryTextColor">
          {format(new Date(messageDate), "dd/MM/yyyy")}
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          {format(new Date(messageDate), "(HH:mm)")}
        </CustomText>
      </Box>
      <Box mt="s" backgroundColor="disabled" borderRadius="button" p="s">
        <Box
          backgroundColor="success"
          borderRadius="button"
          height={BADGE_SIZE}
          width={BADGE_SIZE}
          alignItems="center"
          justifyContent="center"
          position="absolute"
          right={10}
          top={-10}
        >
          <SEND height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.white} />
        </Box>
        <CustomText variant="label" color="labelColor">
          {getMessageType()} - {getMessageStatus()}
        </CustomText>
        <Box
          flexDirection="row"
          alignItems="center"
          marginVertical="m"
          flex={1}
          flexWrap="wrap"
        >
          <COMMENT_OWNER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />

          <Box flex={1}>
            <CustomText variant="description" pl="s">
              {messageTitle}
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineMessageItem;
