import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import PRICING from "../../../../../assets/icons/BASE/PRICING.svg";
import TAX from "../../../../../assets/icons/BASE/TAX.svg";
import Box from "../../../../components/Base/Box";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import AccountingReports from "../AccountingReports";
import CashRegisterSettingsPaymentMethods from "../CashRegisterSettingsPaymentMethods";
import MerchantDiscountList from "../MerchantDiscount/MerchantDiscountList";
import PricingRatesList from "../PricingRates/PricingRatesList";
import TaxList from "../Tax/TaxList";
interface AccountSettingsListProps {
  goBack?: () => void;
}

const AccountSettingsList = ({ goBack }: AccountSettingsListProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseSelectedTab = () => {
    setSelectedTab(undefined);
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "ACCOUNTING_REPORT_SETTINGS":
        return <AccountingReports goBack={handleCloseSelectedTab} />;
      case "TAX_LIST":
        return <TaxList goBack={handleCloseSelectedTab} />;
      case "PAYMENT_METHODS":
        return <CashRegisterSettingsPaymentMethods goBack={handleCloseSelectedTab} />;
      case "DISCOUNTS_LIST":
        return <MerchantDiscountList goBack={handleCloseSelectedTab} />;
      case "PRICING_RATES_LIST":
        return <PricingRatesList goBack={handleCloseSelectedTab} />;

      default:
        break;
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Comptabilité"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <ScrollView
          contentContainerStyle={{
            paddingBottom: 50,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box
            mt="m"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            {/* <SettingsButtonItem
              title="Rapports"
              subText=""
              onPress={() => handleNavigation("ACCOUNTING_REPORT_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
            /> */}
            <SettingsButtonItem
              title="Taxes"
              subText="Gérer les taxes"
              onPress={() => handleNavigation("TAX_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<TAX fill={PALETTE.green} height={ICON_SIZE} width={ICON_SIZE} />}
            />
            {/* <SettingsButtonItem
              title="Moyens de paiement"
              subText="-"
              onPress={() => handleNavigation("PAYMENT_METHODS")}
              bottomLineWidth={LINE_THICKNESS}
            /> */}
            {/* <SettingsButtonItem
              title="Remise"
              subText="-"
              onPress={() => handleNavigation("DISCOUNTS_LIST")}
              bottomLineWidth={LINE_THICKNESS}
            /> */}
            <SettingsButtonItem
              title="Tarification"
              subText="Gérer les tarifications"
              onPress={() => handleNavigation("PRICING_RATES_LIST")}
              bottomLineWidth={0}
              icon={<PRICING fill={PALETTE.green} height={ICON_SIZE} width={ICON_SIZE} />}
            />
            {/* <SettingsButtonItem
              title="Offres / Promotions"
              subText="-"
              onPress={() => handleNavigation("PRESCRIBER_LIST")}
              bottomLineWidth={0}
            /> */}
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default AccountSettingsList;
