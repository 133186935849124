import { Image, TouchableOpacity } from "react-native";

import EMAIL from "../../../../assets/icons/BASE/EMAIL.svg";
import INFO from "../../../../assets/icons/BASE/INFO.svg";
import PHONE from "../../../../assets/icons/BASE/PHONE.svg";
import type { MerchantPersonnelFragment } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface StaffListCardProps {
  isSelected: boolean;
  merchantPersonnel: MerchantPersonnelFragment;
  onPress: () => void;
}

const IMAGE_SIZE = 50;

const StaffListCard = ({
  isSelected,
  onPress,
  merchantPersonnel,
}: StaffListCardProps) => {
  const {
    firstName = "",
    lastName,
    phone,
    email = "",
    jobTitle,
    picture,
  } = merchantPersonnel;

  const name = `${lastName} ${firstName}`;

  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        minHeight={95}
        width="100%"
        borderColor={"success"}
        backgroundColor={isSelected ? "white" : "disabled"}
        borderWidth={isSelected ? 1 : 0}
        borderRadius="button"
        p="s"
      >
        <Box mb="s" flexDirection="row" alignItems="center">
          <CustomText pr="s" variant="label">
            {name}
          </CustomText>
        </Box>
        {jobTitle && (
          <Box mb="s" flexDirection="row" alignItems="center">
            <INFO width={20} height={20} fill={PALETTE.green} />

            <CustomText variant="description" pl="s">
              {jobTitle}
            </CustomText>
          </Box>
        )}

        <Box mb="s" flexDirection="row" alignItems="center">
          {phone && (
            <Box flexDirection="row" alignItems="center">
              <PHONE width={20} height={20} fill={PALETTE.green} />

              <CustomText variant="description" pl="s">
                {phone}
              </CustomText>
            </Box>
          )}

          {email && (
            <Box ml="m" flexDirection="row" alignItems="center">
              <EMAIL width={20} height={20} fill={PALETTE.green} />

              <CustomText variant="description" pl="s">
                {email}
              </CustomText>
            </Box>
          )}
        </Box>
      </Box>
      {picture && (
        <Image
          source={{
            uri: picture,
          }}
          style={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            borderRadius: IMAGE_SIZE / 2,
            position: "absolute",
            top: -10,
            right: 10,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export default StaffListCard;
