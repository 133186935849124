import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateTaxInput,
  TaxFragment,
} from "../../../../../graphql/generated/schema";
import {
  GetTaxesDocument,
  useCreateTaxMutation,
  useGetTaxLazyQuery,
  useUpdateTaxMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../utils/common";
interface TaxListDetailsProps {
  goBack?: () => void;
  newValue?: boolean;
  taxId?: string;
}

const DEFAULT_TAX: CreateTaxInput = {
  name: "",
  taxRate: 0,
};

const schema = yup.object().shape({
  name: yup.string().required("Nom est requis"),
  taxRate: yup.number().min(1, "Taxe doit etre supérieur ou égal à 1").required(),
});

const TaxListDetails = ({ goBack, newValue, taxId }: TaxListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const isNew = newValue || params?.isNew;
  const id = taxId || params?.id;

  const [loading, setLoading] = useState(true);
  const [tax, setTax] = useState(DEFAULT_TAX);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getTax] = useGetTaxLazyQuery();
  const [createTax] = useCreateTaxMutation();
  const [updateTax] = useUpdateTaxMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetTax = async () => {
    setLoading(true);
    try {
      if (id) {
        const { data } = await getTax({
          variables: {
            taxId: id,
          },
        });

        if (data?.getTax) {
          setTax(data.getTax);
        }
      }
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_TAX",
            path: "ERROR_GET_TAX",
            message: "Une erreur est survenue lors de la récupération de la taxe.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTax();
  }, [id]);

  const consolidateTax = () => {
    const taxRate = getSafeNumberFromInput(tax.taxRate, true);

    return {
      ...tax,
      taxRate,
    };
  };

  const validateForm = async () => {
    try {
      const consolidatedTax = consolidateTax();

      await schema.validate(consolidatedTax, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    const consolidatedTax = consolidateTax();

    try {
      if (isNew) {
        await createTax({
          variables: {
            tax: consolidatedTax,
          },
          refetchQueries: [
            {
              query: GetTaxesDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consolidatedTax);
        delete updates._id;

        await updateTax({
          variables: {
            taxId: id,
            taxUpdates: updates,
          },
          refetchQueries: [
            {
              query: GetTaxesDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_TAX",
            path: "ERROR_UPDATE_TAX",
            message: "Une erreur est survenue lors de la mise à jour de la taxe.",
          },
        ],
        "error",
      );
    }
  };

  const updateTaxData = (key: keyof TaxFragment, value: string | number) => {
    setTax(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const displayTitle = isNew ? "Nouvelle taxe" : "Modifier la taxe";

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box marginVertical="m">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Nom"
          isRequired
          hasErrors={doesInputHaveError("name")}
          initialValue={tax.name}
          onChangeText={t => updateTaxData("name", t)}
        />
      </Box>

      <Box
        marginVertical="s"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomText variant="label" color="primaryTextColor">
          Pourcentage de la taxe
        </CustomText>
        <CustomTextInput
          placeHolder="Montant"
          isRequired
          keyboardType="numeric"
          hasErrors={doesInputHaveError("taxRate")}
          value={tax.taxRate.toString()}
          onChangeText={t => updateTaxData("taxRate", t)}
          boxProps={{
            minWidth: 100,
          }}
        />
      </Box>
      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default TaxListDetails;
