import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import SelectedFieldItems from "../../../../../components/PdfTemplatesSettings/SelectedFieldItems/index";
import SortItemsByItem from "../../../../../components/PdfTemplatesSettings/SortItemsByItem/index";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  BookingPdfPrintTemplateFragment,
  CreateBookingPdfPrintTempateInput,
  PrintTemplateOrderItem,
} from "../../../../../graphql/generated/schema";
import {
  Booking_Status,
  Print_Order_Sort_Type,
  Print_Order_Type,
  useCreateBookingPdfPrintTemplateMutation,
  useGetBookingPdfPrintTemplateByIdLazyQuery,
  useUpdateBookingPdfPrintTemplateMutation,
} from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";
import { getLabelForBookingStatus } from "../../../../../utils/booking";
import { formaYupErrors, removeTypeNames, type ERROR } from "../../../../../utils/common";

const DEFAULT_BOOKING_PDF_TEMPLATE: CreateBookingPdfPrintTempateInput = {
  groupBookingsBySlots: false,
  name: "",
  selectedFields: [],
  showClientPhoneNumber: false,
  showComplimentaryInformation: false,
  showListOfBookings: false,
  showNumberOfBookingsPerPersons: false,
  showNumberOfPersonsForDay: false,
  showNumberOfPersonsPerSlot: false,
  sortItemsBy: [
    {
      orderType: Print_Order_Type.Name,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 0,
    },
    {
      orderType: Print_Order_Type.Time,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 1,
    },
    {
      orderType: Print_Order_Type.Status,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 2,
    },
    {
      orderType: Print_Order_Type.Table,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 3,
    },
    {
      orderType: Print_Order_Type.Persons,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 4,
    },
    {
      orderType: Print_Order_Type.OrderAmount,
      orderSortType: Print_Order_Sort_Type.Ascending,
      position: 5,
    },
  ],
  statuses: [],
  isEnabled: false,
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est obligatoire"),
  isEnabled: yup.boolean().required("Le statut est obligatoire"),
  showClientPhoneNumber: yup
    .boolean()
    .required("Le statut pour montrer le téléphone du client est obligatoire"),
  showComplimentaryInformation: yup
    .boolean()
    .required("Le statut pour montrer les informations complémentaires est obligatoire"),
  showListOfBookings: yup
    .boolean()
    .required("Le statut pour montrer la liste des réservations est obligatoire"),
  showNumberOfBookingsPerPersons: yup
    .boolean()
    .required(
      "Le statut pour montrer le nombre de réservations par nombre de couverts est obligatoire",
    ),
  showNumberOfPersonsForDay: yup
    .boolean()
    .required(
      "Le statut pour montrer le nombre de couverts sur la journée est obligatoire",
    ),
  showNumberOfPersonsPerSlot: yup
    .boolean()
    .required(
      "Le statut pour montrer le nombre de couverts par créneau horaire est obligatoire",
    ),
  groupBookingsBySlots: yup
    .boolean()
    .required(
      "Le statut pour grouper les réservations par créneau horaire est obligatoire",
    ),
  sortItemsBy: yup
    .array()
    .of(
      yup.object().shape({
        orderType: yup.string().required("Le type de tri est obligatoire"),
        orderSortType: yup.string().required("Le type de tri est obligatoire"),
        position: yup.number().required("La position est obligatoire"),
      }),
    )
    .required("Le tri est obligatoire"),
  statuses: yup
    .array()
    .of(yup.string().required("Il faut au moins un statut de réservation"))
    .min(1, "Il faut au moins un statut de réservation"),
  selectedFields: yup
    .array()
    .of(
      yup.object().shape({
        field: yup.string().required("Le champ est obligatoire"),
        fontSize: yup.number().required("La taille de police est obligatoire"),
      }),
    )
    .when("showListOfBookings", {
      is: true,
      then: yup
        .array()
        .of(
          yup.object().shape({
            field: yup.string().required("Le champ est obligatoire"),
            fontSize: yup.number().required("La taille de police est obligatoire"),
          }),
        )
        .min(1, "Il faut au moins un champ de sélectionné pour l'impression"),
    }),
});

interface BookingPdfPrintTemplatesSettingsDetailsProps {
  id?: string;
  isDuplicate?: boolean;
  goBack?: () => void;
}

const BookingPdfPrintTemplatesSettingsDetails = ({
  id: idValue,
  isDuplicate: isDuplicateValue,
  goBack,
}: BookingPdfPrintTemplatesSettingsDetailsProps) => {
  const { params } = useRoute();

  const isDuplicate = isDuplicateValue || params?.isDuplicate;
  const id = idValue || params?.id;

  const isNew = !id || isDuplicate;
  useResizeMode();
  const [loading, setLoading] = useState(!isNew);
  const navigation = useNavigation();
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [pdfTemplate, setPdfTemplate] = useState<
    Omit<BookingPdfPrintTemplateFragment, "_id" | "createdAt" | "updatedAt">
  >(DEFAULT_BOOKING_PDF_TEMPLATE);

  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [getPdfTemplate] = useGetBookingPdfPrintTemplateByIdLazyQuery();
  const [createPdfTemplate] = useCreateBookingPdfPrintTemplateMutation();
  const [updatePdfTemplate] = useUpdateBookingPdfPrintTemplateMutation();

  const handleGetPdfTemplate = async () => {
    try {
      if (!id) return;

      const response = await getPdfTemplate({
        variables: {
          getBookingPdfPrintTemplateByIdId: id,
        },
      });

      if (response?.data?.getBookingPdfPrintTemplateById) {
        const pdfInfo = response.data.getBookingPdfPrintTemplateById;
        setPdfTemplate({
          ...pdfInfo,
          name: isDuplicate ? `${pdfInfo.name} (copie)` : pdfInfo.name,
        });
      }
    } catch (err) {
      console.log("ERROR_GETTING_BOOKING_PDF_PRINT_TEMPLATE", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPdfTemplate();
  }, [id]);

  const handleValidateTemplateInfo = async () => {
    try {
      await schema.validate(pdfTemplate, { abortEarly: false });

      return true;
    } catch (err: any) {
      if (err?.inner) {
        setErrors(formaYupErrors(err));

        infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
      } else {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_VALIDATE_FORM",
              message:
                err.message ||
                "Une erreur est survenue lors de la validation du formulaire",
            },
          ],
          "error",
        );
      }
    }
    return false;
  };

  const handleCreateTemplate = async () => {
    try {
      const template = removeTypeNames(pdfTemplate);

      const response = await createPdfTemplate({
        variables: {
          bookingPdfPrintTemplate: template,
        },
      });
      if (response?.data?.createBookingPdfPrintTemplate) {
        handleGoBack();
      }
    } catch (err) {
      console.log("ERROR_CREATING_BOOKING_PDF_PRINT_TEMPLATE", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_CREATING_BOOKING_PDF_PRINT_TEMPLATE",
            message: "Une erreur est survenue lors de la création du modèle d'impression",
          },
        ],
        "error",
      );
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      if (!id) return;

      const updates = removeTypeNames({ ...pdfTemplate });

      delete updates._id;
      delete updates.createdAt;
      delete updates.updatedAt;

      const response = await updatePdfTemplate({
        variables: {
          updateBookingPdfPrintTemplateId: id,
          bookingPdfPrintTemplate: updates,
        },
      });

      if (response?.data?.updateBookingPdfPrintTemplate) {
        handleGoBack();
      }
    } catch (err) {
      console.log("ERROR_UPDATING_BOOKING_PDF_PRINT_TEMPLATE", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATING_BOOKING_PDF_PRINT_TEMPLATE",
            message:
              "Une erreur est survenue lors de la mise à jour du modèle d'impression",
          },
        ],
        "error",
      );
    }
  };

  const handleSubmit = async () => {
    try {
      const isFormValid = await handleValidateTemplateInfo();

      if (!isFormValid) return;

      if (isNew) {
        await handleCreateTemplate();
      } else {
        await handleUpdateTemplate();
      }
    } catch (err) {
      console.log("ERROR_CREATING_BOOKING_PDF_PRINT_TEMPLATE", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_SUBMITTING_PDF_TEMPLATE",
            message:
              "Une erreur est survenue lors de la soumission du modèle d'impression",
          },
        ],
        "error",
      );
    }
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleUpdatePdfTemplate = (
    key: keyof BookingPdfPrintTemplateFragment,
    value: string | boolean,
  ) => {
    setPdfTemplate(v => {
      return {
        ...v,
        [key]: value,
      };
    });
  };

  const getStatuses = () => {
    return [
      Booking_Status.New,
      Booking_Status.WaitingList,
      Booking_Status.Validated,
      Booking_Status.AutomaticValidated,
      Booking_Status.Seated,
      Booking_Status.Arrived,
      Booking_Status.ArrivedWaiting,
      Booking_Status.CancelledByUser,
      Booking_Status.CancelledByOwner,
      Booking_Status.Noshow,
      Booking_Status.Ended,
    ].map(status => ({
      label: getLabelForBookingStatus(status),
      key: status.toString(),
    }));
  };

  const handleSelectStatusFilter = (statuses: string[]) => {
    const formattedStatuses = statuses.map(status => status as Booking_Status);

    setPdfTemplate(v => ({
      ...v,
      statuses: formattedStatuses,
    }));
  };

  const handleUpdateSortItem = (
    type: Print_Order_Type,
    updatedItem: PrintTemplateOrderItem,
  ) => {
    setPdfTemplate(v => ({
      ...v,
      sortItemsBy: v.sortItemsBy.map(item => {
        if (item.orderType === type) {
          return updatedItem;
        }
        return item;
      }),
    }));
  };

  const handleSortItemsBy = (sortItemsBy: PrintTemplateOrderItem[]) => {
    setPdfTemplate(v => ({
      ...v,
      sortItemsBy,
    }));
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const displayTitle = isNew
    ? "Nouveau modèle d'impression"
    : "Modifier le modèle d'impression";

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} height="100%" pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              textColor={doesInputHaveError("isEnabled") ? "danger" : "primaryTextColor"}
              text="Activé"
              value={pdfTemplate.isEnabled}
              onValueChange={v => handleUpdatePdfTemplate("isEnabled", v)}
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomTextInput
              placeHolder="Nom du modèle"
              initialValue={pdfTemplate.name}
              onChangeText={t => handleUpdatePdfTemplate("name", t)}
              isRequired
              hasErrors={doesInputHaveError("name")}
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <SelectButton
              placeHolder="Statuts à imprimer"
              selectedOptions={pdfTemplate.statuses}
              isMultiSelect
              hasErrors={doesInputHaveError("statuses")}
              onPress={handleSelectStatusFilter}
              options={getStatuses()}
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              textColor={
                doesInputHaveError("showNumberOfBookingsPerPersons")
                  ? "danger"
                  : "primaryTextColor"
              }
              text="Afficher le tableau récapitulatif du nombre de réservations par nombre de couverts"
              value={pdfTemplate.showNumberOfBookingsPerPersons}
              onValueChange={v =>
                handleUpdatePdfTemplate("showNumberOfBookingsPerPersons", v)
              }
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              textColor={
                doesInputHaveError("showNumberOfPersonsPerSlot")
                  ? "danger"
                  : "primaryTextColor"
              }
              text="Afficher le tableau récapitulatif du nombre de couverts par créneau horaire"
              value={pdfTemplate.showNumberOfPersonsPerSlot}
              onValueChange={v =>
                handleUpdatePdfTemplate("showNumberOfPersonsPerSlot", v)
              }
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              textColor={
                doesInputHaveError("showNumberOfPersonsForDay")
                  ? "danger"
                  : "primaryTextColor"
              }
              text="Afficher le nombre de couverts sur la journée"
              value={pdfTemplate.showNumberOfPersonsForDay}
              onValueChange={v => handleUpdatePdfTemplate("showNumberOfPersonsForDay", v)}
            />
          </Box>

          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              textColor={
                doesInputHaveError("showListOfBookings") ? "danger" : "primaryTextColor"
              }
              text="Imprimer la liste de réservations"
              value={pdfTemplate.showListOfBookings}
              onValueChange={v => handleUpdatePdfTemplate("showListOfBookings", v)}
            />
          </Box>

          {pdfTemplate.showListOfBookings && (
            <Box paddingHorizontal="s">
              <Box
                mt="s"
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={LINE_THICKNESS}
              >
                <ToggleInputLine
                  textVariant="content"
                  textColor={
                    doesInputHaveError("groupBookingsBySlots")
                      ? "danger"
                      : "primaryTextColor"
                  }
                  text="Grouper de réservations par créneau horaire"
                  value={pdfTemplate.groupBookingsBySlots}
                  onValueChange={v => handleUpdatePdfTemplate("groupBookingsBySlots", v)}
                />
              </Box>
              <Box
                mt="s"
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={LINE_THICKNESS}
              >
                <Box marginVertical="xs">
                  <CustomText variant="content" color="primaryTextColor">
                    Trier la liste en fonction
                  </CustomText>
                </Box>
                <DraggableFlatList
                  showsVerticalScrollIndicator={false}
                  data={pdfTemplate.sortItemsBy}
                  keyExtractor={(_, index) => index.toString()}
                  renderItem={({ item, drag }) => (
                    <Box marginVertical="s">
                      <SortItemsByItem
                        item={item}
                        onChange={v => handleUpdateSortItem(item.orderType, v)}
                        onPressIn={drag}
                      />
                    </Box>
                  )}
                  onDragEnd={({ data }) => {
                    handleSortItemsBy(data);
                  }}
                />
              </Box>
              <Box
                mt="s"
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={LINE_THICKNESS}
              >
                <Box marginVertical="xs">
                  <CustomText
                    variant="content"
                    color={
                      doesInputHaveError("selectedFields") ? "danger" : "primaryTextColor"
                    }
                  >
                    Liste des éléments imprimables
                  </CustomText>
                </Box>
                <SelectedFieldItems
                  pdfTemplate={pdfTemplate}
                  onChange={v => setPdfTemplate(v)}
                />
              </Box>
              <Box
                mt="s"
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={LINE_THICKNESS}
              >
                <ToggleInputLine
                  textVariant="content"
                  textColor={
                    doesInputHaveError("showComplimentaryInformation")
                      ? "danger"
                      : "primaryTextColor"
                  }
                  text="Afficher les informations complémentaires des réservations"
                  value={pdfTemplate.showComplimentaryInformation}
                  onValueChange={v =>
                    handleUpdatePdfTemplate("showComplimentaryInformation", v)
                  }
                />
              </Box>
              <Box
                mt="s"
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={LINE_THICKNESS}
              >
                <ToggleInputLine
                  textVariant="content"
                  textColor={
                    doesInputHaveError("showClientPhoneNumber")
                      ? "danger"
                      : "primaryTextColor"
                  }
                  text="Afficher le numéro de téléphone du client"
                  value={pdfTemplate.showClientPhoneNumber}
                  onValueChange={v => handleUpdatePdfTemplate("showClientPhoneNumber", v)}
                />
              </Box>
            </Box>
          )}
        </ScrollView>
      </KeyboardAwareScrollView>
      <BottomButton title="Enregistrer" onPress={handleSubmit} />
    </Box>
  );
};

export default BookingPdfPrintTemplatesSettingsDetails;
