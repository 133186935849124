import { isBefore } from "date-fns";
import { useState } from "react";
import { useResizeMode } from "react-native-keyboard-controller";

import type { BookingFragment, PrepaidPayment } from "../../../graphql/generated/schema";
import { Booking_Status } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingListCardStatusButtonItem, {
  BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE,
} from "../../BookingList/BookingListCardStatusButtonItem";
import BottomButton from "../../BottomButton";
import ContentModal from "../../ContentModal";
import KeyboardAwareScrollView from "../../KeyboardAwareScrollView/index";
import { CustomTextInput } from "../../TextInput";
import Touchable from "../../Touchable";
import PrepaymentPercentageModalContent from "../PrepaymentPercentageModalContent";

interface BookingStatusListModalProps {
  isVisible: boolean;
  showAllStatuses: boolean;
  status: Booking_Status;
  onClose: () => void;
  onPress: (
    status: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => void;
  prepaidPayment?: PrepaidPayment | null;
  payment?: Omit<
    BookingFragment["payment"],
    "paymentIntentId" | "expiresAt" | "prepaymentCapturePercentage"
  > | null;
}

const BookingStatusListModal = ({
  showAllStatuses = false,
  onPress,
  isVisible,
  onClose,
  status,
  prepaidPayment,
  payment,
}: BookingStatusListModalProps) => {
  useResizeMode();
  const [selectedStatus, setSelectedStatus] = useState<Booking_Status | null>(null);
  const [refusalReason, setRefusalReason] = useState("");
  const [showRefusalReasonInput, setShowRefusalReasonInput] = useState(false);
  const [showPrepaymentAskModal, setShowPrepaymentAskModal] = useState(false);

  const determineAvailableStatus = (): Booking_Status[] => {
    const ALL_AVAILABLE_STATUSES_FOR_OWNER = [
      Booking_Status.New,
      // Booking_Status.WaitingList,
      Booking_Status.Validated,
      // Booking_Status.Reconfimed,
      Booking_Status.Arrived,
      Booking_Status.ArrivedWaiting,
      Booking_Status.Seated,
      Booking_Status.CancelledByOwner,
      Booking_Status.Noshow,
      Booking_Status.Ended,
    ];

    const HAS_PAID = prepaidPayment?.paid || payment?.paid;

    if (showAllStatuses && (!prepaidPayment || !payment)) {
      return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
    }

    if (status === Booking_Status.New || status === Booking_Status.WaitingList) {
      return [Booking_Status.Validated, Booking_Status.CancelledByOwner];
    }

    if (status === Booking_Status.Arrived || status === Booking_Status.ArrivedWaiting) {
      return [Booking_Status.Seated, Booking_Status.Ended];
    }

    if (
      status === Booking_Status.Validated ||
      status === Booking_Status.AutomaticValidated
    ) {
      return [
        Booking_Status.Arrived,
        Booking_Status.ArrivedWaiting,
        Booking_Status.Seated,
        Booking_Status.Ended,
        Booking_Status.Noshow,
        Booking_Status.CancelledByOwner,
      ];
    }

    if (status === Booking_Status.Seated) {
      return [Booking_Status.Ended];
    }

    if (status === Booking_Status.Ended && HAS_PAID) {
      return [Booking_Status.Refunded];
    }

    return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
  };

  const handlePress = (newStatus: Booking_Status) => {
    setSelectedStatus(newStatus);
    if (newStatus === Booking_Status.CancelledByOwner) {
      setShowRefusalReasonInput(true);
    } else if (newStatus === Booking_Status.Noshow && prepaidPayment) {
      setShowPrepaymentAskModal(true);
    } else {
      onPress(newStatus, "", false, false);
      handleOnClose();
    }
    // onPress(newStatus);
  };

  const handleOnClose = () => {
    setSelectedStatus(null);
    setShowRefusalReasonInput(false);
    setShowPrepaymentAskModal(false);
    setRefusalReason("");
    onClose();
  };

  const handleRefusalModalSubmit = () => {
    setShowRefusalReasonInput(false);

    const IS_ALLOWED_TO_CANCEL = isBefore(
      new Date(),
      new Date(prepaidPayment?.expiresAt),
    );

    if (prepaidPayment) {
      if (!IS_ALLOWED_TO_CANCEL) setShowPrepaymentAskModal(true);
      else {
        if (selectedStatus) onPress(selectedStatus, refusalReason, false, false);

        handleOnClose();
      }
    } else {
      if (selectedStatus) onPress(selectedStatus, refusalReason, false, false);

      handleOnClose();
    }
  };

  const displayContent = () => {
    if (showRefusalReasonInput) {
      return (
        <>
          <Box height="40%">
            <KeyboardAwareScrollView style={{ flex: 1 }}>
              <Box>
                <CustomText variant="label" color="primaryTextColor">
                  Indiquez la raison du refus
                </CustomText>

                <Box flex={1} mt="s">
                  <CustomTextInput
                    placeHolder="Raison du refus"
                    initialValue={refusalReason}
                    onChangeText={setRefusalReason}
                    multiLine
                  />
                </Box>
              </Box>
            </KeyboardAwareScrollView>
          </Box>
          <BottomButton
            styles={{
              mb: "s",
            }}
            title="Valider"
            onPress={handleRefusalModalSubmit}
          />
        </>
      );
    } else if (showPrepaymentAskModal) {
      return (
        <Box mt="s" height="100%">
          <PrepaymentPercentageModalContent
            onSubmit={(automaticPercentage, shouldReimburse) => {
              handleSubmit(automaticPercentage, shouldReimburse);
            }}
          />
        </Box>
      );
    }
    return (
      <Box mt="s">
        {determineAvailableStatus().map((item, idx) => {
          return (
            <Box key={idx} mb="s">
              <Touchable onPress={() => handlePress(item)}>
                <BookingListCardStatusButtonItem
                  status={item}
                  type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
                />
              </Touchable>
            </Box>
          );
        })}
      </Box>
    );
  };

  const handleSubmit = (
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => {
    if (selectedStatus) {
      onPress(
        selectedStatus,
        refusalReason,
        applyAutomaticPrepaymentPercentage,
        reimburse,
      );
    }

    handleOnClose();
  };

  return (
    <ContentModal
      title="Status"
      onClose={handleOnClose}
      isVisible={isVisible}
      styles={{
        height: showRefusalReasonInput ? "50%" : "auto",
      }}
    >
      {displayContent()}
    </ContentModal>
  );
};

export default BookingStatusListModal;
