import { useNavigation, useRoute } from "@react-navigation/native";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { ScrollView } from "react-native";
import * as yup from "yup";

import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import ClientEditTab from "../../../../components/BookingListDetails/BookingListDetailsView/Tabs/Client/Edit";
import BottomButton from "../../../../components/BottomButton";
import ErrorInfoMessageAlert from "../../../../components/ErrorInfoMessageAlert";
import type { ListingTab } from "../../../../components/ListingTabs";
import { ListingTabs } from "../../../../components/ListingTabs";
import Loader from "../../../../components/Loader";
import NewQuoteDate from "../../../../components/Quotes/NewQuote/NewQuoteDate";
import NewQuoteInformation from "../../../../components/Quotes/NewQuote/NewQuoteInformation";
import NewQuoteServices from "../../../../components/Quotes/NewQuote/NewQuoteServices";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import type { QUOTE } from "../../../../Data";
import type {
  CreateQuoteProInput,
  UpdateQuoteProInput,
} from "../../../../graphql/generated/schema";
import {
  Sex,
  useCreateInvoiceMutation,
  useCreateQuoteMutation,
  useGetInvoiceLazyQuery,
  useGetQuoteLazyQuery,
  useUpdateInvoiceMutation,
  useUpdateQuoteMutation,
} from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import type { Quote } from "../../../../types/types";
import { Quote_Status } from "../../../../types/types";
import { formaYupErrors, removeTypeNames } from "../../../../utils/common";

type QUOTE_TAB_KEYS = "INFORMATION" | "SERVICES" | "DATE" | "CLIENT";

const TABS: ListingTab[] = [
  {
    title: "Prestations",
    value: "",
    key: "SERVICES",
  },
  {
    title: "Date",
    value: "",
    key: "DATE",
  },
  {
    title: "Informations",
    value: "",
    key: "INFORMATIONS",
  },
  {
    title: "Client",
    value: "",
    key: "CLIENT",
  },
];

const DEFAULT_NEW_QUOTE: CreateQuoteProInput = {
  items: {
    basicItems: [],
    commentItems: [],
    productItems: [],
  },
  deliveryAddress: "",
  isOnlinePaymentEnabled: false,
  conditionOfAcceptance: "",
  extraField: "",
  client: {
    acceptedReceiveMarketingSms: false,
    acceptedReceiveNewsLetters: false,
    address: {
      city: "",
      country: "",
      zipCode: "",
      street: "",
      formattedAddress: "",
      number: "",
    },
    comment: "",
    dateOfBirth: "",
    email: "",
    firstName: "",
    isBlacklisted: false,
    isVIP: false,
    language: "",
    lastName: "",
    merchantCompanyId: "",
    phone: "",
    picture: "",
    secondaryEmail: "",
    secondaryPhone: "",
    sex: Sex.Male,
  },
  sentDate: new Date().toISOString(),
  status: Quote_Status.Accepted,
  title: "",
  validityInDays: 0,
};

const schema = yup.object().shape({
  title: yup.string().required(),
  status: yup.string().required(),
  deliveryAddress: yup.string(),
  isOnlinePaymentEnabled: yup.boolean(),
  conditionOfAcceptance: yup.string(),
  extraField: yup.string(),
  sentDate: yup.date().required(),
  validityInDays: yup.number().min(0),
  items: yup
    .object()
    .shape({
      basicItems: yup.array().of(
        yup.object().shape({
          title: yup.string().required(),
          price: yup.object().shape({
            amount: yup.number().min(0),
            taxRate: yup.number().min(0).max(100),
          }),
          quantity: yup.number().min(0),
        }),
      ),
      productItems: yup.array().of(
        yup.object().shape({
          productId: yup.string().required(),
          quantity: yup.number().min(0),
        }),
      ),
      commentItems: yup.array().of(
        yup.object().shape({
          comment: yup.string().required(),
        }),
      ),
    })
    .required(),
  client: yup
    .object()
    .shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: yup.string().required(),
      email: yup.string().required(),
    })
    .required(),
});

const NewQuote = () => {
  const { params } = useRoute();
  const { itemId, isForQuote } = params;

  const isNew = !itemId;

  const [isLoading, setIsLoading] = useState(!isNew);
  const [listTabs, setListTabs] = useState(TABS);
  const [currentTab, setCurrentTab] = useState(TABS[0].key);
  const navigation = useNavigation();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getQuote] = useGetQuoteLazyQuery();
  const [getInvoice] = useGetInvoiceLazyQuery();
  const [createQuote] = useCreateQuoteMutation();
  const [updateQuote] = useUpdateQuoteMutation();
  const [createInvoice] = useCreateInvoiceMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();

  const handleSetInitialItemValue = () => {
    if (isNew) return DEFAULT_NEW_QUOTE;
    return null;
  };

  const [item, setItem] = useState<Partial<Quote> | null>(handleSetInitialItemValue());
  const selectedMarkedDate = useMemo(() => {
    if (item?.sentDate) {
      return {
        [format(new Date(item?.sentDate), "yyyy-MM-dd")]: {
          selected: true,
          marked: true,
          selectedColor: PALETTE.green,
        },
      };
    }
    return undefined;
  }, [item?.sentDate]);

  useEffect(() => {
    const newTabs = listTabs.map(tab => {
      if (tab.key === "DATE") {
        return {
          ...tab,
          value: item?.sentDate && format(new Date(item.sentDate), "d MMM"),
        };
      }

      return tab;
    });
    setListTabs(newTabs);
  }, [item?.sentDate]);

  const handleGetItem = async () => {
    if (!isNew) {
      try {
        if (isForQuote) {
          const result = await getQuote({
            variables: {
              quoteId: itemId,
            },
          });

          if (result.data?.getQuoteForPro) {
            setItem(result.data.getQuoteForPro);
          }
        } else {
          const result = await getInvoice({
            variables: {
              invoiceId: itemId,
            },
          });

          if (result.data?.getInvoiceForPro) {
            setItem(result.data.getInvoiceForPro);
          }
        }
      } catch (err) {
        console.log("err get quote,invoice", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    handleGetItem();
  }, []);

  const formatQuoteInvoice = () => {
    const {
      client,
      conditionOfAcceptance,
      sentDate,
      deliveryAddress,
      isOnlinePaymentEnabled,
      items,
      title,
      extraField,
      validityInDays,
      merchantClient,
      status,
    } = item;

    const { basicItems, commentItems, productItems } = items;

    const transformedProductItems = productItems.map(productItem => {
      const productId =
        productItem.productId && typeof productItem.productId === "object"
          ? productItem.productId._id
          : productItem._id;
      const { quantity, position } = productItem;

      return {
        quantity,
        position,
        productId,
      };
    });

    const consolidatedItems = {
      basicItems,
      commentItems,
      productItems: transformedProductItems,
    };

    const quote: UpdateQuoteProInput = {
      client: client || merchantClient,
      conditionOfAcceptance,
      extraField,
      sentDate,
      deliveryAddress,
      isOnlinePaymentEnabled,
      items: consolidatedItems,
      title,
      validityInDays,
      status,
    };

    const cleanedQuote = removeTypeNames(quote);
    delete cleanedQuote._id;
    delete cleanedQuote.client.bookingOccurences;
    delete cleanedQuote.client.orderOccurences;
    delete cleanedQuote.client.ticketOccurences;

    return cleanedQuote;
  };

  const handleUpdateQuote = async () => {
    try {
      // const {
      //   client,
      //   conditionOfAcceptance,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items,
      //   title,
      //   extraField,
      //   validityInDays,
      // } = item;

      // const { basicItems, commentItems, productItems } = items;

      // const transformedProductItems = productItems.map(productItem => {
      //   const productId =
      //     typeof productItem.productId === "string"
      //       ? productItem.productId
      //       : productItem.productId._id;

      //   return {
      //     ...item,
      //     productId,
      //   };
      // });

      // const consolidatedItems = {
      //   basicItems,
      //   commentItems,
      //   productItems: transformedProductItems,
      // };

      // const quote: UpdateQuoteProInput = {
      //   client,
      //   conditionOfAcceptance,
      //   extraField,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items: consolidatedItems,
      //   title,
      //   validityInDays,
      // };

      // const cleanedQuote = removeTypeNames(quote);

      const cleanedQuote = formatQuoteInvoice();

      await updateQuote({
        variables: {
          quoteId: itemId,
          quote: cleanedQuote,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ path: "submit", code: "SUBMIT_ERROR", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const handleCreateInvoice = async () => {
    try {
      // const {
      //   client,
      //   conditionOfAcceptance,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items,
      //   title,
      //   extraField,
      //   validityInDays,
      // } = item;

      // const invoice: CreateInvoiceProInput = {
      //   client,
      //   conditionOfAcceptance,
      //   extraField,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items,
      //   title,
      //   validityInDays,
      //   status: Quote_Status.Accepted,
      // };

      // const cleanedInvoice = removeTypeNames(invoice);

      const cleanedInvoice = formatQuoteInvoice();

      await createInvoice({
        variables: {
          invoice: cleanedInvoice,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ path: "submit", code: "SUBMIT_ERROR", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const handleUpdateInvoice = () => {
    try {
      // const {
      //   client,
      //   conditionOfAcceptance,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items,
      //   title,
      //   extraField,
      //   validityInDays,
      // } = item;

      // const { basicItems, commentItems, productItems } = items;

      // const transformedProductItems = productItems.map(item => {
      //   const productId =
      //     typeof item.productId === "string" ? item.productId : item.productId._id;
      //   const { position = 0, quantity } = item;
      //   return {
      //     position,
      //     quantity,
      //     productId,
      //   };
      // });

      // const consolidatedItems = removeTypeNames({
      //   basicItems,
      //   commentItems,
      //   productItems: transformedProductItems,
      // });

      // const invoice: UpdateInvoiceProInput = {
      //   client,
      //   conditionOfAcceptance,
      //   extraField,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items: consolidatedItems,
      //   title,
      //   validityInDays,
      // };

      const invoice = formatQuoteInvoice();

      updateInvoice({
        variables: {
          invoiceId: itemId,
          invoice,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ path: "submit", code: "SUBMIT_ERROR", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const handleCreateQuote = () => {
    try {
      // const {
      //   client,
      //   conditionOfAcceptance,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items,
      //   title,
      //   extraField,
      //   validityInDays,
      // } = item;

      // const { basicItems, commentItems, productItems } = items;

      // const transformedProductItems = productItems.map(item => {
      //   const productId =
      //     typeof item.productId === "string" ? item.productId : item.productId._id;
      //   const { position = 0, quantity } = item;
      //   return {
      //     position,
      //     quantity,
      //     productId,
      //   };
      // });

      // const consolidatedItems = removeTypeNames({
      //   basicItems,
      //   commentItems,
      //   productItems: transformedProductItems,
      // });

      // const quote: CreateQuoteProInput = {
      //   client,
      //   conditionOfAcceptance,
      //   extraField,
      //   sentDate,
      //   deliveryAddress,
      //   isOnlinePaymentEnabled,
      //   items: consolidatedItems,
      //   title,
      //   validityInDays,
      //   status: Quote_Status.Accepted,
      // };

      const quote = formatQuoteInvoice();

      createQuote({
        variables: {
          quote,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ path: "submit", code: "SUBMIT_ERROR", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const validateForm = async () => {
    try {
      const itemForValidation = formatQuoteInvoice();

      await schema.validate(itemForValidation, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err));

      setIsSnackBarOpen(true);
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    if (isForQuote) {
      if (isNew) {
        handleCreateQuote();
      } else {
        handleUpdateQuote();
      }
    } else {
      if (isNew) {
        handleCreateInvoice();
      } else {
        handleUpdateInvoice();
      }
    }
  };

  const handleButtonPress = () => {
    switch (currentTab) {
      case "SERVICES":
        setCurrentTab("DATE");
        break;
      case "DATE":
        setCurrentTab("INFORMATIONS");
        break;
      case "INFORMATIONS":
        setCurrentTab("CLIENT");
        break;
      case "CLIENT":
        // ! TODO validate form

        handleSubmit();
        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNewQuoteUpdate = (key: keyof QUOTE, value: any) => {
    setItem(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const displayScreenName = () => {
    if (isForQuote) {
      return isNew ? "Nouveau devis" : "Modifier devis";
    }
    return isNew ? "Nouvelle facture" : "Modifier facture";
  };

  const displayTabContent = () => {
    switch (currentTab) {
      case "DATE":
        return (
          <Box>
            <NewQuoteDate
              selectedDate={item.sentDate || null}
              updateQuote={handleNewQuoteUpdate}
              quote={item}
              onDateSelected={date =>
                handleNewQuoteUpdate("sentDate", new Date(date).toISOString())
              }
              markedDates={selectedMarkedDate}
            />
          </Box>
        );
      case "SERVICES":
        return (
          <Box>
            <NewQuoteServices quote={item} updateQuote={handleNewQuoteUpdate} />
          </Box>
        );
      case "INFORMATIONS":
        return (
          <Box>
            <NewQuoteInformation updateQuote={handleNewQuoteUpdate} quote={item} />
          </Box>
        );
      case "CLIENT":
        return (
          <Box flex={1}>
            <Box mb="m">
              <CustomTextInput
                leftIcon={<SEARCH width={24} height={24} fill={PALETTE.lightGrey} />}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChangeText={() => {}}
                placeHolder="Rechercher un client"
              />
            </Box>

            <ClientEditTab
              data={isNew ? item.client : item?.merchantClient}
              updateData={data => handleNewQuoteUpdate("client", data)}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box flex={1} paddingHorizontal="s" pt="l" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader
          title={displayScreenName()}
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>

      <Box marginVertical="s">
        <ListingTabs
          tabs={listTabs}
          currentTabName={currentTab}
          onSelectTab={tab => setCurrentTab(tab)}
          boxProps={{
            minHeight: 45,
          }}
        />
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <Box flex={1}>{displayTabContent()}</Box>
      </ScrollView>

      <BottomButton
        onPress={handleButtonPress}
        title={currentTab === "CLIENT" ? "Terminer" : "Suivant"}
      />

      <ErrorInfoMessageAlert
        isOpen={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
        type="error"
        title="Erreur dans le formulaire"
        data={errors}
      />
    </Box>
  );
};

export default NewQuote;
