import { format } from "date-fns";
import { Image, TouchableOpacity } from "react-native";

import FACEBOOK from "../../../../assets/icons/BASE/FACEBOOK.svg";
import INSTAGRAM from "../../../../assets/icons/BASE/INSTAGRAM.svg";
// import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import type { GetPublicationsQuery } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { convertPublicationType } from "../../../utils/Publication";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface PublicationListCardProps {
  publication: GetPublicationsQuery["getPublications"][0];
  onPress: (itemId: string) => void;
  onTogglePublish: (itemId: string, val: boolean) => void;
  onTogglePin: (itemId: string, val: boolean) => void;
  onToggleBoost: (itemId: string, val: boolean) => void;
}

const HEIGHT = 350;
const ICON_SIZE = 20;
const WIDTH = 300;
const IMAGE_HEIGHT = HEIGHT * 0.8;

const PublicationListCard = ({
  publication,
  onPress,
  onTogglePublish,
  onTogglePin,
  onToggleBoost,
}: PublicationListCardProps) => {
  const {
    _id: publicationId,
    createdAt,
    title,
    description,
    isPublished,
    isPinned,
    isBoosted,
    type,
    tags,
    images,
    isPublishedOnFacebook,
    isPublishedOnInstagram,
  } = publication;

  const displayImage = images?.length > 0 ? images[0] : undefined;

  const formattedCreatedAtDate = format(new Date(createdAt), "dd/MM/yyyy");

  const handleOnPress = () => {
    onPress(publicationId);
  };

  const handleTogglePublish = () => {
    onTogglePublish(publicationId, !isPublished);
  };

  const handleTogglePin = () => {
    onTogglePin(publicationId, !isPinned);
  };

  const handleToggleBoost = () => {
    onToggleBoost(publicationId, !isBoosted);
  };

  return (
    <TouchableOpacity onPress={handleOnPress}>
      <Box
        // minWidth={WIDTH}
        minHeight={HEIGHT}
        borderColor="lightGrey"
        borderWidth={LINE_THICKNESS}
        borderRadius="button"
      >
        <Box>
          <Box height={IMAGE_HEIGHT}>
            {displayImage ? (
              <Image
                resizeMode="contain"
                source={{
                  uri: displayImage,
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                }}
              />
            ) : (
              <Box alignItems="center" justifyContent="center" flex={1}>
                <CustomText variant="content" color="lightGrey">
                  Pas d'image
                </CustomText>
              </Box>
            )}
          </Box>

          <Box
            p="s"
            backgroundColor="yellow"
            borderRadius="modal"
            position="absolute"
            zIndex={1}
            left={10}
            top={-10}
          >
            <CustomText variant="content" color="white">
              {convertPublicationType(type)}
            </CustomText>
          </Box>

          {/* <Box
            p="s"
            backgroundColor="white"
            borderTopLeftRadius="button"
            borderBottomLeftRadius="button"
            position="absolute"
            top={10}
            right={0}
          >
            <Box flexDirection="row" alignItems="center">
              <TouchableOpacity onPress={handleTogglePublish} style={{ marginRight: 8 }}>
                <EDIT
                  width={15}
                  height={15}
                  fill={isPublished ? PALETTE.green : PALETTE.lightGrey}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleToggleBoost} style={{ marginRight: 8 }}>
                <FLAME
                  width={15}
                  height={15}
                  fill={isBoosted ? PALETTE.yellow : PALETTE.lightGrey}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleTogglePin} style={{ marginRight: 8 }}>
                <PIN
                  // width={15}
                  // height={15}
                  fill={isPinned ? PALETTE.yellow : PALETTE.lightGrey}
                />
              </TouchableOpacity>
            </Box>
          </Box> */}
        </Box>
        <Box mt="xs" paddingHorizontal="s">
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <CustomText variant="label" color="primaryTextColor">
              {title}
            </CustomText>
            <CustomText variant="text" color="lightGrey">
              Crée le {formattedCreatedAtDate}
            </CustomText>
          </Box>

          {description && (
            <Box>
              <CustomText numberOfLines={3} variant="text" color="black">
                {description}
              </CustomText>
            </Box>
          )}

          {/* {tags.length > 0 && ( */}
          <Box marginVertical="s">
            <Box flexDirection="row" alignItems="center" flexWrap="wrap">
              <Box mr="s">
                {isPublishedOnFacebook && <FACEBOOK fill={PALETTE.blue} />}
              </Box>
              <Box mr="s">
                {isPublishedOnInstagram && <INSTAGRAM fill={PALETTE.purple} />}
              </Box>

              {tags.map(tag => (
                <Box
                  key={tag}
                  mr="s"
                  p="s"
                  borderRadius="button"
                  backgroundColor="disabled"
                >
                  <CustomText variant="description" color="primaryTextColor">
                    {tag}
                  </CustomText>
                </Box>
              ))}
            </Box>
          </Box>
          {/* // )} */}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default PublicationListCard;
