/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import PhoneInput from "../../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../../components/TextInput";

const AccountSecurityEdit = () => {
  const navigation = useNavigation();
  const displayTitle = "Mot de passe et sécurité";

  const handleSubmit = () => {
    navigation.goBack();
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 150,
        }}
      >
        <Box flex={1}>
          <Box marginVertical="s">
            <ScreenHeader
              title={displayTitle}
              hasBackButton
              onBackPress={navigation.goBack}
            />
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Mot de passe actuel"
              secureTextEntry
              //  initialValue={option.description}
              onChangeText={t => {}}
              isRequired
            />
          </Box>
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Confirmation du mot de passe actuel"
              secureTextEntry
              //  initialValue={option.description}
              onChangeText={t => {}}
              isRequired
            />
          </Box>

          <Box marginVertical="s">
            <PhoneInput onChangeFormattedText={t => {}} value={""} />
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </>
  );
};

export default AccountSecurityEdit;
