/* eslint-disable max-len */
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import NumberInputLine from "../../../../../components/NumberInputLine";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { BookingSlot } from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, getSafeNumberFromInput } from "../../../../../utils/common";

const schema = yup.object().shape({
  _id: yup.string().required("Le ID est obligatoire"),
  day: yup
    .number()
    .nullable()
    .when("isRecurring", {
      is: true,
      then: yup.number().required("La couleur est obligatoire"),
    }),

  date: yup
    .date()
    .nullable()
    .when("isRecurring", {
      is: false,
      then: yup.date().required("La date est obligatoire"),
    }),

  totalPersonsAllowed: yup.object().shape({
    isEnabled: yup.boolean(),
    value: yup.number(),
  }),
  startTime: yup.string().required("L'heure de début est obligatoire"),
  endTime: yup.string().required("L'heure de fin est obligatoire"),
  isRecurring: yup.boolean().required("Le type de slot est obligatoire"),

  minMaxBookingPersons: yup.object().shape({
    isEnabled: yup
      .boolean()
      .required("Le statut pour la délimitation des couverts est obligatoire"),
    minPersons: yup.number(),
    maxPersons: yup.number(),
  }),
  automaticConfirmationSettings: yup.object().shape({
    isEnabled: yup.boolean(),
    isEnabledForMaxCapacity: yup.boolean(),
    minPersons: yup.number().when("isEnabledForMaxCapacity", {
      is: true,
      then: yup.number().min(1, "Le total de la liste d'attente doit etre au moins à 1"),
    }),
    maxPersons: yup.number().when("isEnabledForMaxCapacity", {
      is: true,
      then: yup.number().min(1, "Le total de la liste d'attente doit etre au moins à 1"),
    }),
  }),
  waitingListSettings: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    minPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre minimum de personnes pour la liste d'attente doit etre au moins à 1",
        ),
    }),
    maxPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre maximum de personnes pour la liste d'attente doit etre au moins à 1",
        ),
    }),
    totalPersonsAllowed: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre maximum de personnes pour la liste d'attente doit etre au moins à 1",
        ),
    }),
  }),
});

const BookingServiceSlotListDetails = () => {
  useResizeMode();
  const navigation = useNavigation();
  const { params } = useRoute();
  const { slot, onUpdateSlotSubmit, totalService, service } = params;
  const [updatedSlot, setUpdatedSlot] = useState<BookingSlot>(slot);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const validateForm = async (slotData: BookingSlot) => {
    try {
      const { totalPersonsAllowed, minMaxBookingPersons, maxCapacityAllowedForSlot } =
        service;

      // SLOT LIMITS

      if (
        totalPersonsAllowed.isEnabled &&
        totalPersonsAllowed.value < slotData.totalPersonsAllowed
      ) {
        throw new Error(
          "Le nombre total de personnes par créneau est supérieur au nombre total de personnes autorisées pour le service",
        );
      }

      if (
        maxCapacityAllowedForSlot.isEnabled &&
        slotData.totalPersonsAllowed.value > maxCapacityAllowedForSlot.value
      ) {
        throw new Error(
          "Le nombre total de personnes par créneau est supérieur au nombre maximum de personnes autorisées pour le service",
        );
      }

      if (
        maxCapacityAllowedForSlot.isEnabled &&
        slotData.minMaxBookingPersons.maxPersons > maxCapacityAllowedForSlot.value
      ) {
        throw new Error(
          "Le nombre maximum de personnes par créneau est supérieur au nombre maximum de personnes autorisées pour le service",
        );
      }

      if (slotData.minMaxBookingPersons.minPersons < minMaxBookingPersons.minPersons) {
        throw new Error(
          "Le nombre minimum de personnes par créneau est inférieur au nombre minimum de personnes autorisées pour le service",
        );
      }
      if (slotData.minMaxBookingPersons.maxPersons > minMaxBookingPersons.maxPersons) {
        throw new Error(
          "Le nombre maximum de personnes par créneau est supérieur au nombre maximum de personnes autorisées pour le service",
        );
      }

      if (
        slotData.minMaxBookingPersons.minPersons >
        slotData.minMaxBookingPersons.maxPersons
      ) {
        throw new Error(
          "Le nombre minimum de personnes par créneau doit etre inférieur au nombre maximum de personnes autorisées pour le service",
        );
      }

      // SLOT WAITING LIST

      if (
        updatedSlot.waitingListSettings.isEnabled &&
        updatedSlot.waitingListSettings.maxPersons >
          updatedSlot.waitingListSettings.totalPersonsAllowed
      ) {
        throw new Error(
          "Le nombre maximum de personnes dans la liste d'attente ne peut pas être supérieur au nombre total de personnes autorisées",
        );
      }

      if (
        updatedSlot.waitingListSettings.isEnabled &&
        updatedSlot.waitingListSettings.minPersons >
          updatedSlot.waitingListSettings.maxPersons
      ) {
        throw new Error(
          "Le nombre minimum de personnes dans la liste d'attente ne peut pas être supérieur au nombre maximum de personnes dans la liste d'attente",
        );
      }

      await schema.validate(slot, { abortEarly: false });

      return true;
    } catch (err) {
      if (err?.inner) {
        setErrors(formaYupErrors(err));

        infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
      } else {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_VALIDATE_FORM",
              message:
                err.message ||
                "Une erreur est survenue lors de la validation du formulaire",
            },
          ],
          "error",
        );
      }
    }
    return false;
  };

  const updateSlotData = (key: keyof BookingSlot, value: boolean | number) => {
    setUpdatedSlot(prev => ({ ...prev, [key]: value }));
  };

  const updateTotalPersonsAllowed = (
    dataKey: "value" | "isEnabled",
    value: number | boolean,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      totalPersonsAllowed: {
        ...prev.totalPersonsAllowed,
        [dataKey]: value,
        isEnabled: true,
      },
    }));
  };

  const updateMinMaxPersons = (key: "minPersons" | "maxPersons", value: number) => {
    setUpdatedSlot(prev => ({
      ...prev,
      minMaxBookingPersons: {
        ...prev.minMaxBookingPersons,
        [key]: value,
      },
    }));
  };

  const updateSlotWaitingList = (
    key: keyof BookingSlot["waitingListSettings"],
    value: boolean | number,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      waitingListSettings: { ...prev.waitingListSettings, [key]: value },
    }));
  };

  const updateSlotAutomaticConfirmation = (
    key: keyof BookingSlot["automaticConfirmationSettings"],
    value: boolean | number,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      automaticConfirmationSettings: {
        ...prev.automaticConfirmationSettings,
        [key]: value,
      },
    }));
  };

  const onSubmit = async () => {
    try {
      const isFormValid = await validateForm(updatedSlot);

      if (!isFormValid) return;

      onUpdateSlotSubmit(slot._id, updatedSlot);
      navigation.goBack();
    } catch (_err) {}
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Personnaliser le créneau"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box mt="m">
            <ToggleInputLine
              textVariant="content"
              value={updatedSlot.isEnabled}
              onValueChange={value => updateSlotData("isEnabled", value)}
              text="Activer le créneau"
            />
          </Box>

          {service.maxCapacityAllowedForSlot.isEnabled && (
            <Box mt="m">
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <CustomText variant="content" color="primaryTextColor">
                  Couverts autorisés
                </CustomText>

                <Box flexDirection="row" alignItems="center">
                  <CustomTextInput
                    placeHolder="0"
                    hideFloatingLabel
                    hasErrors={doesInputHaveError("totalPersonsAllowed")}
                    initialValue={updatedSlot.totalPersonsAllowed.value.toString()}
                    onChangeText={t =>
                      updateTotalPersonsAllowed("value", getSafeNumberFromInput(t))
                    }
                  />
                  {/* <CustomText
                marginHorizontal="xs"
                variant="text"
                fontSize={25}
                color="primaryTextColor"
              >
                /
              </CustomText> */}

                  {service?.maxCapacityAllowedForSlot?.isEnabled && (
                    <Box ml="s">
                      <CustomTextInput
                        placeHolder="50"
                        hideFloatingLabel
                        disabled
                        initialValue={service?.maxCapacityAllowedForSlot.value?.toString()}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onChangeText={() => {}}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <CustomText variant="text" color="lightGrey">
                Maximum de couverts autorisés pour ce créneau
              </CustomText>
            </Box>
          )}

          {service.minMaxBookingPersons.isEnabled && (
            <Box mt="m">
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <CustomText variant="content" color="primaryTextColor">
                  Couverts acceptés
                </CustomText>

                <Box flexDirection="row" alignItems="center">
                  <CustomTextInput
                    placeHolder="0"
                    hideFloatingLabel
                    hasErrors={doesInputHaveError("minMaxBookingPersons.minPersons")}
                    value={updatedSlot.minMaxBookingPersons.minPersons.toString()}
                    onChangeText={t =>
                      updateMinMaxPersons("minPersons", getSafeNumberFromInput(t))
                    }
                  />

                  <Box ml="s">
                    <CustomTextInput
                      placeHolder="50"
                      hideFloatingLabel
                      hasErrors={doesInputHaveError("minMaxBookingPersons.maxPersons")}
                      value={updatedSlot.minMaxBookingPersons.maxPersons.toString()}
                      onChangeText={t =>
                        updateMinMaxPersons("maxPersons", getSafeNumberFromInput(t))
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <CustomText variant="text" color="lightGrey">
                Autoriser un minimum et maximum de couverts aurotisés pour une réservation
              </CustomText>
            </Box>
          )}

          <Box mt="m">
            <ToggleInputLine
              textVariant="content"
              text="Accepter automatiquement"
              value={updatedSlot.automaticConfirmationSettings.isEnabled}
              onValueChange={value => updateSlotAutomaticConfirmation("isEnabled", value)}
            />
            <CustomText variant="text" color="lightGrey">
              Toutes les réservations effectuées par vos clients seront automatiquement
              acceptées jusqu’à la limite du service.
            </CustomText>
          </Box>

          <Box mt="m">
            <ToggleInputLine
              textVariant="content"
              text="Accepter automatiquement jusqu'a"
              value={updatedSlot.automaticConfirmationSettings.isEnabledForMaxCapacity}
              onValueChange={value =>
                updateSlotAutomaticConfirmation("isEnabledForMaxCapacity", value)
              }
            />
            <CustomText variant="text" color="lightGrey">
              Toutes les réservations effectuées jusqu’à X couverts seront automatiquement
              acceptées jusqu’à la limite du service.
            </CustomText>
            {updatedSlot.automaticConfirmationSettings.isEnabledForMaxCapacity && (
              <Box mt="m" flexDirection="row" alignItems="center" alignSelf="flex-end">
                <CustomTextInput
                  hideFloatingLabel
                  placeHolder="0"
                  value={updatedSlot.automaticConfirmationSettings.maxCapacityForAutomaticConfirmation.toString()}
                  onChangeText={t =>
                    updateSlotAutomaticConfirmation(
                      "maxCapacityForAutomaticConfirmation",
                      getSafeNumberFromInput(t),
                    )
                  }
                />
              </Box>
            )}
          </Box>

          <Box mt="m">
            <ToggleInputLine
              textVariant="content"
              text="Autoriser le surbooking"
              value={updatedSlot.waitingListSettings.isEnabled}
              onValueChange={value => updateSlotWaitingList("isEnabled", value)}
            />
          </Box>

          {updatedSlot.waitingListSettings.isEnabled && (
            <>
              <Box mt="m">
                <NumberInputLine
                  textVariant="content"
                  hasErrors={doesInputHaveError(
                    "waitingListSettings.totalPersonsAllowed",
                  )}
                  text="Nombre de couverts"
                  value={updatedSlot.waitingListSettings.totalPersonsAllowed}
                  placeHolder="0"
                  onValueChange={value =>
                    updateSlotWaitingList(
                      "totalPersonsAllowed",
                      getSafeNumberFromInput(value),
                    )
                  }
                />

                <CustomText variant="text" color="lightGrey">
                  Couverts disponibles à la réservation dans votre établissement
                </CustomText>
              </Box>

              <Box mt="m" flexDirection="row" alignItems="center" alignSelf="flex-end">
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  hasErrors={doesInputHaveError("waitingListSettings.minPersons")}
                  value={updatedSlot.waitingListSettings.minPersons.toString()}
                  onChangeText={t =>
                    updateSlotWaitingList("minPersons", getSafeNumberFromInput(t))
                  }
                />

                <Box ml="s">
                  <CustomTextInput
                    placeHolder="0"
                    hideFloatingLabel
                    hasErrors={doesInputHaveError("waitingListSettings.maxPersons")}
                    value={updatedSlot.waitingListSettings.maxPersons.toString()}
                    onChangeText={t =>
                      updateSlotWaitingList("maxPersons", getSafeNumberFromInput(t))
                    }
                  />
                </Box>
              </Box>
            </>
          )}
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton onPress={onSubmit} title="Valider" />
    </Box>
  );
};

export default BookingServiceSlotListDetails;
