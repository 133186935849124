import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import MerchantConnectedDeviceCard from "../../../../components/Settings/MerchantConnectedDeviceCard";
import type { MerchantDeviceFragment } from "../../../../graphql/generated/schema";
import {
  GetMerchantDeviceDocument,
  useDeletMerchantDeviceMutation,
  useGetMerchantDevicesLazyQuery,
} from "../../../../graphql/generated/schema";

interface MerchantDevicesListProps {
  goBack?: () => void;
}

const MerchantDevicesList = ({ goBack }: MerchantDevicesListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<MerchantDeviceFragment[]>([]);

  const [getMerchantDevices] = useGetMerchantDevicesLazyQuery();
  const [deleteMerchantDevice] = useDeletMerchantDeviceMutation();

  const handleGetMerchantDevices = async () => {
    try {
      const { data } = await getMerchantDevices();

      if (data) {
        setDevices(data.getMerchantDevices);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchantDevices();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await deleteMerchantDevice({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: GetMerchantDeviceDocument,
            variables: {
              pagination: {
                offset: 0,
                limit: 10,
              },
            },
          },
        ],
        // update: cache => {
        //   const existingDevices = cache.readQuery({
        //     query: MerchantDeviceFragmentDoc,
        //   });

        //   if (existingDevices) {
        //     cache.writeQuery({
        //       query: MerchantDeviceFragmentDoc,
        //       data: {
        //         getMerchantDevices: existingDevices.getMerchantDevices.filter(
        //           (device: MerchantDeviceFragment) => device.id !== id,
        //         ),
        //       },
        //     });
        //   }
        // },
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  if (loading) return <Loader />;

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Appareils connectés"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <Box mt="m">
        <CustomText mb="s" textTransform="uppercase" variant="content" color="success">
          Informations sur l'appareil
        </CustomText>
      </Box>

      <FlatList
        data={devices}
        showsVerticalScrollIndicator={false}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => (
          <Box marginVertical="s">
            <MerchantConnectedDeviceCard
              merchantDevice={item}
              // onDelete={() => handleDelete(item._id)}
            />
          </Box>
        )}
      />
    </Box>
  );
};

export default MerchantDevicesList;
