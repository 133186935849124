import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import type { GetTotalBookingsDispsersedByDayOfWeekQuery } from "../../../../graphql/generated/schema";
import { useGetTotalBookingsDispsersedByDayOfWeekLazyQuery } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

const BookingAverageFrequency = () => {
  const [loading, setLoading] = useState(true);
  const [bookingStats, setBookingStats] = useState<
    | GetTotalBookingsDispsersedByDayOfWeekQuery["getTotalBookingsDispsersedByDayOfWeek"]
    | null
  >(null);

  const [getBookingsByDayOfWeek] = useGetTotalBookingsDispsersedByDayOfWeekLazyQuery();

  const handleGetBookingsTotalRecap = async () => {
    try {
      const { data } = await getBookingsByDayOfWeek();

      if (data?.getTotalBookingsDispsersedByDayOfWeek) {
        setBookingStats(data.getTotalBookingsDispsersedByDayOfWeek);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, []);

  if (loading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!bookingStats) return null;

  return (
    <Box flex={1}>
      <Box>
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Moyenne de fréquentation
        </CustomText>
      </Box>
      <Box mt="m">
        <CustomText variant="text" color="lightGrey">
          En couverts par jour de la semaine
        </CustomText>
      </Box>

      <Box mt="s" ml="m" mr="l">
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Lundi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.monday}
          </CustomText>
        </Box>

        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Mardi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.tuesday}
          </CustomText>
        </Box>

        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Mecredi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.wednesday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Jeudi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.thursday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Vendredi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.friday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Samedi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.saturday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Dimanche
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.sunday}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingAverageFrequency;
