import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import config from "../../config/env";

const { STRIPE_PUBLISHABLE_KEY } = config;

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const AppStripeProvider = ({ children }: { children: React.ReactElement }) => (
  <Elements stripe={stripe}>{children}</Elements>
  // <>{children}</>
);
