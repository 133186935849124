import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { FlatList, Platform, ScrollView, TouchableOpacity } from "react-native";

import CREDIT_CARD from "../../../../../../assets/icons/BASE/CREDIT_CARD.svg";
import HOME from "../../../../../../assets/icons/BASE/HOME.svg";
import INFO from "../../../../../../assets/icons/BASE/INFO.svg";
import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import Loader from "../../../../../components/Loader";
import NewSubscriptionCard from "../../../../../components/NewSubscriptionCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type {
  MerchantSettingsFragment,
  MerchantSettingsPaymentMethodBank,
  MerchantSettingsPaymentMethodCard,
} from "../../../../../graphql/generated/schema";
import { useGetMerchantSettingsLazyQuery } from "../../../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";

import AddPaymentMethod from "./AddPaymentMethod";
import BillingDetails from "./BillingDetails";
import PaymentMethodDetails from "./PaymentMethodDetails";

const IS_WEB = Platform.OS === "web";

interface AccountBillingDetailsProps {
  goBack?: () => void;
}

const AccountBillingDetails = ({ goBack }: AccountBillingDetailsProps) => {
  const navigation = useNavigation();

  const [merchantSettings, setMerchantSettings] = useState<
    MerchantSettingsFragment | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedPm, setSelectedPm] = useState<
    MerchantSettingsPaymentMethodCard | MerchantSettingsPaymentMethodBank | undefined
  >(undefined);

  const [getMerchantSettings] = useGetMerchantSettingsLazyQuery();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleNavigation = (screenName: string) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleGetMerchantSettings = async () => {
    try {
      const { data } = await getMerchantSettings({
        fetchPolicy: "cache-and-network",
      });

      if (data?.getMerchantSettings) {
        setMerchantSettings(data?.getMerchantSettings);
      }
    } catch (err) {
      console.log("err get merchant settings", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMerchantSettings();
    }, []),
  );

  if (loading) return <Loader />;

  const handleFormatPaymentMethods = () => {
    if (!merchantSettings) return [];

    const { paymentMethods } = merchantSettings;
    const { cards, banks } = paymentMethods;

    return [...cards, ...banks];
  };

  const handleGoPaymentMethodDetails = (
    item: MerchantSettingsPaymentMethodCard | MerchantSettingsPaymentMethodBank,
  ) => {
    if (goBack) {
      setSelectedPm(item);
      setSelectedTab("ACCOUNT_BILLING_PAYMENT_METHOD_DETAILS");
    } else {
      navigation.navigate("ACCOUNT_BILLING_PAYMENT_METHOD_DETAILS", {
        paymentMethod: item,
        defaultPaymentMethodId:
          merchantSettings?.paymentMethods?.defaultPaymentMethodId || "",
      });
    }
  };

  const renderPaymentMethod = (
    item: MerchantSettingsPaymentMethodCard | MerchantSettingsPaymentMethodBank,
  ) => {
    const isCard = "brand" in item;
    const isDefault =
      item.stripePaymentMethodId ===
      merchantSettings?.paymentMethods?.defaultPaymentMethodId;

    if (isCard) {
      return (
        <TouchableOpacity onPress={() => handleGoPaymentMethodDetails(item)}>
          <Box
            minHeight={85}
            flexDirection="row"
            alignItems="center"
            p="s"
            borderRadius="button"
            backgroundColor="disabled"
          >
            <Box
              alignItems="center"
              justifyContent="center"
              height={40}
              width={40}
              backgroundColor="white"
              borderRadius="button"
              mr="s"
            >
              <CREDIT_CARD width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Box>

            <Box flex={1}>
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <CustomText variant="label" color="primaryTextColor">
                  {item.brand?.toUpperCase()}
                </CustomText>
                {isDefault && (
                  <CustomText variant="content" color="primaryTextColor">
                    Défault
                  </CustomText>
                )}
              </Box>
              <Box mt="xs">
                <CustomText variant="content" color="primaryTextColor">
                  ****{item.lastFour}
                </CustomText>
              </Box>
              <Box mt="s">
                <CustomText variant="content" color="primaryTextColor">
                  {item.expMonth}/{item.expYear}
                </CustomText>
              </Box>
            </Box>
          </Box>
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity onPress={() => handleGoPaymentMethodDetails(item)}>
        <Box
          minHeight={85}
          flexDirection="row"
          alignItems="center"
          p="s"
          borderRadius="button"
          backgroundColor="disabled"
        >
          <Box
            alignItems="center"
            justifyContent="center"
            height={40}
            width={40}
            backgroundColor="white"
            borderRadius="button"
            mr="s"
          >
            <HOME width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <Box flex={1}>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                SEPA
              </CustomText>
              {isDefault && (
                <CustomText variant="content" color="primaryTextColor">
                  Défault
                </CustomText>
              )}
            </Box>
            <Box mt="xs">
              <CustomText variant="content" color="primaryTextColor">
                ****{item.lastFour}
              </CustomText>
            </Box>
          </Box>
        </Box>
      </TouchableOpacity>
    );
  };

  const displaySettingsList = () => {
    return (
      <Box flex={1} p="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader title="Mode de paiement" hasBackButton onBackPress={handleGoBack} />

        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Box mt="m" mb="s">
            <InputSectionTitle text="Mode de paiements" />
          </Box>

          <Box backgroundColor="disabled" borderRadius="button">
            <FlatList
              scrollEnabled={false}
              data={handleFormatPaymentMethods()}
              ListFooterComponent={
                <>
                  {IS_WEB && (
                    <Box
                      paddingHorizontal="s"
                      backgroundColor="disabled"
                      borderRadius="button"
                    >
                      <NewSubscriptionCard
                        text="Ajouter un mode de paiement"
                        icon={
                          <NEW
                            height={ICON_SIZE}
                            width={ICON_SIZE}
                            fill={PALETTE.green}
                          />
                        }
                        onPress={() =>
                          handleNavigation("ACCOUNT_BILLING_ADD_PAYMENT_METHOD")
                        }
                      />
                    </Box>
                  )}
                </>
              }
              renderItem={({ item }) => {
                return (
                  <Box borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
                    {renderPaymentMethod(item)}
                  </Box>
                );
              }}
            />
          </Box>

          <Box mt="m" mb="s">
            <InputSectionTitle text="Détails de facturation " />
          </Box>
          <Box paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
            <NewSubscriptionCard
              text="Détails de facturation"
              icon={<INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />}
              onPress={() => handleNavigation("ACCOUNT_BILLING_DETAILS")}
            />
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const handleCloseSelectedTab = async () => {
    await handleGetMerchantSettings();
    setSelectedTab("");
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "ACCOUNT_BILLING_ADD_PAYMENT_METHOD":
        return <AddPaymentMethod goBack={handleCloseSelectedTab} />;
      case "ACCOUNT_BILLING_DETAILS":
        return <BillingDetails goBack={handleCloseSelectedTab} />;
      case "ACCOUNT_BILLING_PAYMENT_METHOD_DETAILS":
        return (
          <PaymentMethodDetails
            goBack={handleCloseSelectedTab}
            pm={selectedPm}
            defaultPaymentMethodId={
              merchantSettings?.paymentMethods?.defaultPaymentMethodId
            }
          />
        );

      default:
        break;
    }
    return <></>;
  };

  const displayContent = () => {
    if (!selectedTab) return displaySettingsList();
    return displaySelectedTab();
  };
  return displayContent();
};

export default AccountBillingDetails;
