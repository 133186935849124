// import { LINE_THICKNESS } from "../index";
export const ButtonVariants = {
  primaryButton: {
    // backgroundColor: "primaryColor",
    // padding: "m",
    // margin: "m",
    borderRadius: "button",
  },
  outlineButton: {
    // backgroundColor: "white",
    // padding: "m",
    // margin: "m",
    borderRadius: "button",
    borderWidth: 1,
    // borderColor: "primaryColor",
  },
  serviceButton: {
    // backgroundColor: "white",
    padding: "s",
    borderRadius: "button",
    borderWidth: 1,
    // alignItems: "center",
    // borderColor: "primaryColor",
  },
};

export type BUTTON_VARIANTS = keyof typeof ButtonVariants;
