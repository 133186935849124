import type { RouteProp } from "@react-navigation/native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useMemo, useState } from "react";
import { FlatList, ScrollView, TouchableWithoutFeedback } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";

import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import PLUS from "../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../assets/icons/BASE/TRASH.svg";
import type { SettingsStackParamList } from "../../navigation/AppStack/SettingsStack/ParamList";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import BottomButton from "../BottomButton";
import ScreenHeader from "../ScreenHeader";
import Touchable from "../Touchable";

type ListSelectScreenRouteProps = RouteProp<SettingsStackParamList, "LIST_SELECT">;

const ListMultiSelect = () => {
  const {
    params: {
      title = "Sélection",
      listItems,
      selectedItems,
      onSubmit,
      multiSelect = true,
      simple = false,
    },
  } = useRoute<ListSelectScreenRouteProps>();
  const navigation = useNavigation();
  const [selected, setSelected] =
    useState<{ _id: string; name: string }[]>(selectedItems);

  const handleSelectItem = (item: { _id: string; name: string }) => {
    setSelected(prev => {
      if (!multiSelect) {
        return [item];
      }
      return [...prev, item];
    });
  };

  const handleRemoveItem = (item: { _id: string; name: string }) => {
    setSelected(prev => prev.filter(i => i.name !== item.name));
  };

  const handleSortItems = (items: { _id: string; name: string }[]) => {
    setSelected(items);
  };

  const handleReset = () => {
    setSelected([]);
  };

  const handleSubmit = () => {
    onSubmit(selected.filter(v => v));
  };

  const filteredItems = useMemo(
    () => listItems.filter(item => !selected.includes(item)),
    [selected, listItems],
  );

  return (
    <Box flex={1} backgroundColor="white" pt="m" paddingHorizontal="s">
      <ScreenHeader title={title} hasBackButton onBackPress={navigation.goBack} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        {!simple && (
          <Box mt="m" marginVertical="s">
            <CustomText variant="content" color="success" textTransform="uppercase">
              Selectionnés
            </CustomText>
          </Box>
        )}

        <DraggableFlatList
          scrollEnabled={false}
          data={selected}
          keyExtractor={(item, idx) => `${item}-${idx}`}
          style={{ width: "100%" }}
          containerStyle={{ width: "100%" }}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, drag }) => {
            if (!item) return <></>;
            return (
              <TouchableWithoutFeedback
                onPressIn={drag}
                // onPress={() => handleSelectItem(item)}
              >
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="s"
                >
                  <Box
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor={simple ? "white" : "disabled"}
                    borderRadius="button"
                    borderColor={simple ? "success" : "disabled"}
                    borderWidth={simple ? 1 : 0}
                    mr="s"
                    paddingHorizontal="s"
                    minHeight={45}
                  >
                    {!simple && (
                      <DRAGGABLE height={20} width={20} fill={PALETTE.lightGrey} />
                    )}
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      {item.name}
                    </CustomText>
                  </Box>
                  {!simple && (
                    <Touchable onPress={() => handleRemoveItem(item)}>
                      <TRASH height={20} width={20} fill={PALETTE.red} />
                    </Touchable>
                  )}
                </Box>
              </TouchableWithoutFeedback>
            );
          }}
          onDragEnd={({ data }) => handleSortItems(data)}
        />

        {!simple && (
          <Box mt="m" marginVertical="s">
            <CustomText variant="content" color="success" textTransform="uppercase">
              Disponibles
            </CustomText>
          </Box>
        )}

        <FlatList
          scrollEnabled={false}
          data={filteredItems}
          keyExtractor={(item, idx) => `${item}-${idx}`}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => {
            if (!item) return <></>;
            return (
              <Touchable onPress={() => handleSelectItem(item)}>
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="s"
                >
                  <Box
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor="disabled"
                    borderRadius="button"
                    mr="s"
                    paddingHorizontal="s"
                    minHeight={45}
                  >
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      {item.name}
                    </CustomText>
                  </Box>
                  {!simple && (
                    <Box>
                      <PLUS height={20} width={20} fill={PALETTE.green} />
                    </Box>
                  )}
                </Box>
              </Touchable>
            );
          }}
        />
      </ScrollView>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default ListMultiSelect;
