import { useEffect, useState } from "react";

import HORIZONTAL_MORE_DOTS from "../../../assets/icons/BASE/HORIZONTAL_MORE_DOTS.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import VALIDATE from "../../../assets/icons/BASE/VALIDATE.svg";
import type {
  Click_Status,
  GetAccumulatedOrderAmountsByFilterQuery,
} from "../../graphql/generated/schema";
import { useGetAccumulatedOrderAmountsByFilterLazyQuery } from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import { ICON_SIZE } from "../../theme/index";
import ListingStatusFilter from "../ListingStatusFilter/index";

interface OnlineOrderListStatusFilterProps {
  date: Date;
  serviceId: string | null;
  handleSelectedFilter: (filter: string) => void;
  selectedFilter: string;
  selectedStatusFilters: Click_Status[];
  refreshData?: boolean;
  containerWidth?: number;
}

const OnlineOrderListStatusFilter = ({
  date,
  serviceId,
  handleSelectedFilter,
  selectedFilter,
  selectedStatusFilters,
  refreshData,
  containerWidth,
}: OnlineOrderListStatusFilterProps) => {
  const [statusFilterData, setStatusFilterData] = useState<
    {
      label?: string;
      key: string;
      value?: number;
      icon?: JSX.Element;
    }[]
  >([]);

  const [getAccumulatedOrderAmountsByFilter] =
    useGetAccumulatedOrderAmountsByFilterLazyQuery();

  const handleGetAccumulatedOrderAmountsByFilter = async () => {
    try {
      console.log("selectedStatusFilters", selectedStatusFilters);
      const response = await getAccumulatedOrderAmountsByFilter({
        fetchPolicy: "cache-and-network",
        variables: {
          date,
          serviceId,
          selectedStatusFilters,
        },
      });

      if (response.data) {
        consolidatedOrderStatusFilterData(
          response.data.getAccumulatedOrderAmountsByFilter,
        );
      }
    } catch (err) {
      console.log("ERROR_GETTING_ACCUMULATED_ORDER_AMOUNTS_BY_FILTER", err);
    }
  };

  useEffect(() => {
    handleGetAccumulatedOrderAmountsByFilter();
  }, [date, serviceId, selectedStatusFilters, refreshData]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     handleGetAccumulatedOrderAmountsByFilter();
  //   }, [date, serviceId, selectedStatusFilters, refreshData]),
  // );

  const consolidatedOrderStatusFilterData = (
    data: GetAccumulatedOrderAmountsByFilterQuery["getAccumulatedOrderAmountsByFilter"],
  ) => {
    const HAS_SELECTED_STATUS_FILTERS = selectedStatusFilters.length > 0;

    const all = {
      label: "Tous",
      key: "ALL",
      value: data.allOrders,
    };

    const pending = {
      key: "PENDING",
      value: data.pendingOrders,
      icon: (
        <>
          <OCCURENCES
            height={ICON_SIZE}
            width={ICON_SIZE}
            fill={selectedFilter === "PENDING" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    const validated = {
      key: "VALIDATED",
      value: data.validatedOrders,
      icon: (
        <>
          <VALIDATE
            height={ICON_SIZE}
            width={ICON_SIZE}
            fill={selectedFilter === "VALIDATED" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    const others = {
      key: "OTHERS",
      value: HAS_SELECTED_STATUS_FILTERS ? data.selectedStatusOrders : data.otherOrders,
      icon: (
        <>
          <HORIZONTAL_MORE_DOTS
            // height={ICON_SIZE}
            // width={ICON_SIZE}
            fill={selectedFilter === "OTHERS" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    return setStatusFilterData([all, pending, validated, others]);
  };

  return (
    <ListingStatusFilter
      {...{
        onSelected: handleSelectedFilter,
        selectedStatus: selectedFilter,
        tabs: statusFilterData,
        containerWidth: containerWidth,
      }}
    />
  );
};

export default OnlineOrderListStatusFilter;
