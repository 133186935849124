import { TouchableOpacity } from "react-native-gesture-handler";

import CUTLERY from "../../../../assets/icons/BASE/CUTLERY.svg";
import DRAGGABLE from "../../../../assets/icons/BASE/DRAGGABLE.svg";
import INFO from "../../../../assets/icons/BASE/INFO.svg";
import TABLE from "../../../../assets/icons/BASE/TABLE.svg";
import type { BookingFloorListFragment } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
interface BookingFloorListCardProps {
  isSelected?: boolean;
  bookingFloor: BookingFloorListFragment;
  isDraggable?: boolean;
  onPressIn?: () => void;
}

const BookingFloorListCard = ({
  isSelected = false,
  bookingFloor,
  isDraggable = true,
  onPressIn,
}: BookingFloorListCardProps) => {
  const { name, description, prefix, tables } = bookingFloor;

  const displayFloorCapacity = () => {
    const capacity = tables.reduce((acc, table) => {
      return acc + table.minCapacity;
    }, 0);
    return capacity;
  };

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      backgroundColor={isSelected ? "white" : "disabled"}
      borderColor="success"
      borderWidth={isSelected ? LINE_THICKNESS : 0}
      p="s"
      borderRadius="button"
    >
      {isDraggable && (
        <TouchableOpacity onPressIn={onPressIn}>
          <Box height={40} paddingHorizontal="xs">
            <DRAGGABLE width={20} height={20} fill={PALETTE.lightGrey} />
          </Box>
        </TouchableOpacity>
      )}
      <Box ml="s">
        <Box pb="s" flexDirection="row" alignItems="center">
          <CustomText variant="label" color="primaryTextColor">
            {name}
          </CustomText>
          <CustomText ml="s" variant="content" color="primaryTextColor">
            ({prefix})
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box mr="m" flexDirection="row" alignItems="center">
            <TABLE width={15} height={15} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {tables.length}
            </CustomText>
          </Box>
          <Box mr="m" flexDirection="row" alignItems="center">
            <CUTLERY width={15} height={15} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {displayFloorCapacity()}
            </CustomText>
          </Box>
          {description && (
            <Box mr="m" flexDirection="row" alignItems="center">
              <INFO width={15} height={15} fill={PALETTE.green} />
              <CustomText ml="s" variant="content" color="lightGrey">
                {description}
              </CustomText>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingFloorListCard;
