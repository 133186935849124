import type {
  MerchantClientFragment,
  Modules,
} from "../../../../../graphql/generated/schema";
import Box from "../../../../Base/Box";

import ClientEditTab from "./Edit";
import ClientViewTab from "./View";

interface BookingListDetailsViewClientTabProps {
  moduleType: Modules;
  isEditable: boolean;
  updateData: (data: MerchantClientFragment) => void;
  data: MerchantClientFragment;
}

const BookingListDetailsViewClientTab = ({
  moduleType,
  isEditable,
  updateData,
  data,
}: BookingListDetailsViewClientTabProps) => {
  return (
    <Box paddingHorizontal="s">
      {isEditable ? (
        <ClientEditTab data={data} updateData={updateData} />
      ) : (
        <ClientViewTab hideHeaders data={data} moduleType={moduleType} />
      )}
    </Box>
  );
};

export default BookingListDetailsViewClientTab;
