import { useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import type { OrderFragment } from "../../../graphql/generated/schema";
import { Click_Status } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import { CustomTextInput } from "../../TextInput";
import OnlineOrderStatusButtonItem, {
  ONLINE_STATUS_LAYOUT_TYPE,
} from "../OnlineOrderStatusButtonItem";

interface OnlineOrderStatusListModalProps {
  isVisible: boolean;
  showAllStatuses?: boolean;
  status: Click_Status;
  onClose: () => void;
  onPress: (status: Click_Status, refualReason: string) => void;
  payment?: OrderFragment["payment"];
}

const OnlineOrderStatusListModal = ({
  showAllStatuses = false,
  onPress,
  isVisible,
  onClose,
  status,
  payment,
}: OnlineOrderStatusListModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<Click_Status | null>(null);
  const [refusalReason, setRefusalReason] = useState("");
  const [showRefusalReasonInput, setShowRefusalReasonInput] = useState(false);

  const determineAvailableStatus = (): Click_Status[] => {
    const ALL_AVAILABLE_STATUSES_FOR_OWNER = [
      Click_Status.New,
      Click_Status.Validated,
      Click_Status.Preparing,
      Click_Status.Prepared,
      Click_Status.CancelledByOwner,
      Click_Status.Noshow,
      Click_Status.Ended,
    ];

    const HAS_CB_PAYMENT = payment?.paymentType === "CARD";

    if (showAllStatuses && !HAS_CB_PAYMENT) {
      return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
    }

    if (status === Click_Status.New) {
      return [Click_Status.Validated, Click_Status.CancelledByOwner];
    }

    if (status === Click_Status.Validated || status === Click_Status.AutomaticValidated) {
      return [
        Click_Status.Preparing,
        Click_Status.Prepared,
        Click_Status.Noshow,
        Click_Status.CancelledByOwner,
        Click_Status.Ended,
      ];
    }

    if (status === Click_Status.Preparing) {
      return [Click_Status.Prepared, Click_Status.Noshow, Click_Status.Ended];
    }

    if (status === Click_Status.Prepared) {
      return [Click_Status.Noshow, Click_Status.Ended];
    }

    if (
      (status === Click_Status.Ended && HAS_CB_PAYMENT) ||
      (status === Click_Status.Noshow && HAS_CB_PAYMENT) ||
      (status === Click_Status.CancelledByOwner && HAS_CB_PAYMENT) ||
      (status === Click_Status.CancelledByUser && HAS_CB_PAYMENT)
    ) {
      return [Click_Status.Refunded];
    }

    return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
  };

  const handlePress = (newStatus: Click_Status) => {
    setSelectedStatus(newStatus);
    if (newStatus === Click_Status.CancelledByOwner) {
      setShowRefusalReasonInput(true);
    } else {
      onPress(newStatus, "");
      handleOnClose();
    }
    // onPress(newStatus);
  };

  const handleOnClose = () => {
    setSelectedStatus(null);
    setShowRefusalReasonInput(false);
    setRefusalReason("");
    onClose();
  };

  const handleSubmit = () => {
    if (selectedStatus) {
      onPress(selectedStatus, refusalReason);
    }

    handleOnClose();
  };

  const IS_DISABLED = !refusalReason?.trim().length || selectedStatus === null;

  return (
    <ContentModal title="Status" onClose={handleOnClose} isVisible={isVisible}>
      <>
        {!showRefusalReasonInput ? (
          <Box mt="s">
            <FlatList
              data={determineAvailableStatus()}
              renderItem={({ item }) => {
                return (
                  <Box mb="s">
                    <TouchableOpacity onPress={() => handlePress(item)}>
                      <OnlineOrderStatusButtonItem
                        status={item}
                        type={ONLINE_STATUS_LAYOUT_TYPE.TEXT}
                      />
                    </TouchableOpacity>
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Indiquez la raison du refus
            </CustomText>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Raison du refus"
                isRequired
                onChangeText={setRefusalReason}
              />
            </Box>

            <Box mt="s">
              <CustomButton
                disabled={IS_DISABLED}
                buttonVariant="primaryButton"
                buttonColor={IS_DISABLED ? "lightGrey" : "success"}
                onPress={handleSubmit}
              >
                <CustomText variant="primaryButtonText" color="white">
                  Valider
                </CustomText>
              </CustomButton>
            </Box>
          </Box>
        )}
      </>
    </ContentModal>
  );
};

export default OnlineOrderStatusListModal;
