import type { TEXT_VARIANTS } from "../../../../../theme/variants/TextVariants";
import Box from "../../../../Base/Box";
import { CustomText } from "../../../../Base/Text";

const HEIGHT = 60;
const LEFT_CONTAINER_ICON_WIDTH = 30;

interface TabTextInfoProps {
  icon: JSX.Element;
  title: string;
  content: string;
  rightIcon?: JSX.Element;
  titleTextVariant?: TEXT_VARIANTS;
  contentTextVariant?: TEXT_VARIANTS;
}

const TabTextInfo = ({
  icon,
  title,
  content,
  rightIcon,
  titleTextVariant = "label",
  contentTextVariant = "description",
}: TabTextInfoProps) => {
  return (
    <Box
      minHeight={HEIGHT}
      justifyContent="center"
      mt="s"
      borderBottomColor="disabled"
      borderBottomWidth={1}
      pb="s"
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center" flex={1} flexWrap="wrap">
          <Box width={LEFT_CONTAINER_ICON_WIDTH}>{icon}</Box>
          <Box flex={1}>
            <CustomText variant={titleTextVariant} color="labelColor">
              {title}
            </CustomText>
          </Box>
        </Box>
        {rightIcon}
      </Box>
      <Box
        style={{
          paddingLeft: LEFT_CONTAINER_ICON_WIDTH,
        }}
      >
        <CustomText variant={contentTextVariant}> {content}</CustomText>
      </Box>
    </Box>
  );
};

export default TabTextInfo;
