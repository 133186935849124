import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useState } from "react";
import { Image, ScrollView } from "react-native";

import BURGER from "../../../../../../../assets/icons/BASE/BURGER.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import LINKS from "../../../../../../../assets/icons/BASE/LINKS.svg";
import PRICE_TAG from "../../../../../../../assets/icons/BASE/PRICE_TAG.svg";
import SHOPPING_BAG from "../../../../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import SOLD_OUT from "../../../../../../../assets/icons/BASE/SOLD_OUT.svg";
import STOCK from "../../../../../../../assets/icons/BASE/STOCK.svg";
import TAGS from "../../../../../../../assets/icons/BASE/TAGS.svg";
import TIMER from "../../../../../../../assets/icons/BASE/TIMER.svg";
import VALIDATION from "../../../../../../../assets/icons/BASE/VALIDATION.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { ProductFragment } from "../../../../../../graphql/generated/schema";
import { useGetProductLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import {
  formatCurrencyPrice,
  getWeekdayFromNumber,
} from "../../../../../../utils/common";

interface ProductListDetailsViewProps {
  id: string;
  hideHeader?: boolean;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const ProductListDetailsView = ({
  id,
  hideHeader = false,
  goBack,
}: ProductListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState<ProductFragment | null>(null);

  const [getProdcut] = useGetProductLazyQuery();

  const handleGetProduct = async () => {
    try {
      const { data } = await getProdcut({
        fetchPolicy: "cache-and-network",
        variables: {
          productId: id,
        },
      });

      if (data?.getProduct) {
        setProduct(data.getProduct);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetProduct();
    }, [id]),
  );

  if (loading) {
    return <Loader />;
  }

  if (!product) {
    return (
      <Box>
        <ScreenHeader
          title="Produit"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue." />
      </Box>
    );
  }

  const displayImage = () => {
    const { picture } = product;

    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  const displayGroupOptions = () => {
    const { groupOptions } = product;

    return groupOptions.map(gOption => gOption.name).join(" - ");
  };

  const displayCategories = () => {
    const { categories } = product;

    return categories.map(category => category.name).join("\n");
  };

  const displayPrices = () => {
    const { basePrice } = product;

    const amount = formatCurrencyPrice(basePrice.amount, "fr", "eur");

    return `Standard: ${amount} - TVA: ${basePrice.tax?.taxRate}%\n`;
  };

  const displayTags = () => {
    const { tags } = product;

    return tags.map(tag => tag).join(" - ");
  };

  const displayAvailability = () => {
    const { baseAvailability } = product;

    const { isRecurring, recurringDates, specificDates } = baseAvailability;

    if (isRecurring) {
      const { days, startTime, endTime } = recurringDates;

      const formattedDays = days
        .map(day => {
          const weekDay = getWeekdayFromNumber(day);

          return `${weekDay}: ${startTime} - ${endTime}`;
        })
        .join("\n");

      return formattedDays;
    } else {
      const { startDate, endDate, startTime, endTime } = specificDates;

      const formattedStartDate = format(new Date(startDate), "dd/MM/yyyy");
      const formattedEndDate = format(new Date(endDate), "dd/MM/yyyy");

      return `${formattedStartDate} - ${formattedEndDate}: ${startTime} - ${endTime}`;
    }
  };

  const displayStock = () => {
    const { stockAmount, physicalSettings } = product;
    const { enableStock, depletedLimitForOnlineSales, unavailableLimitForOnlineSales } =
      physicalSettings;

    if (!enableStock) {
      return "";
    }

    // eslint-disable-next-line max-len
    return `Stock: ${stockAmount}\nSeuil d’insponibilité: ${unavailableLimitForOnlineSales}\nSeuil de rupture: ${depletedLimitForOnlineSales}`;
  };

  const { name, description, isEnabled, color, productCode, slug } = product;

  return (
    <Box flex={1}>
      {!hideHeader && (
        <ScreenHeader
          title={name}
          rightButtons={displayImage()}
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      )}

      <Box mt="s">
        <Box flexDirection="row" alignItems="center">
          <TAGS height={15} width={15} fill={PALETTE.green} />
          <CustomText ml="s" variant="content" color="primaryTextColor">
            {productCode}
          </CustomText>
        </Box>
        {product?.physicalSettings?.enableStock && (
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            backgroundColor="disabled"
            padding="s"
            borderRadius="button"
            width={60}
          >
            <SHOPPING_BAG height={15} width={15} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="primaryTextColor">
              {product.stockAmount}
            </CustomText>
          </Box>
        )}
      </Box>

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        {description && (
          <TabTextInfo
            {...{
              title: "Description",
              content: description,
              icon: (
                <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Catégorie",
            content: displayCategories(),
            icon: (
              <BURGER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Groupes d'options",
            content: displayGroupOptions(),
            icon: <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
        <TabTextInfo
          {...{
            title: "Prix",
            content: displayPrices(),
            icon: (
              <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        {product?.physicalSettings?.enableStock && (
          <TabTextInfo
            {...{
              title: "Rupture de stock",
              content: product?.stockAmount > 0 ? "Non" : "Oui",
              icon: (
                <STOCK height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Indisponible",
            content: !isEnabled ? "Oui" : "Non",
            icon: (
              <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Disponibilité",
            content: displayAvailability(),
            icon: <TIMER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />

        {displayStock() && (
          <TabTextInfo
            {...{
              title: "Gestion de stock",
              content: displayStock(),
              icon: (
                <SOLD_OUT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        {product.tags.length > 0 && (
          <TabTextInfo
            {...{
              title: "Tags",
              content: displayTags(),
              icon: (
                <TAGS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}
      </ScrollView>
    </Box>
  );
};

export default ProductListDetailsView;
