import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "react-native-gesture-handler";

import type { BookingFragment } from "../../../../../../graphql/generated/schema";
import { Event_Type, Origin } from "../../../../../../graphql/generated/schema";
import { getLabelsForEvent, getLabelsForOrigin } from "../../../../../../utils/common";
import Box from "../../../../../Base/Box";
import SelectModal from "../../../../../SelectModal";
import { CustomTextInput } from "../../../../../TextInput";

interface BookingListDetailsEditProps {
  data: BookingFragment;
  updateData: (data: BookingFragment) => void;
}

const BookingListDetailsEdit = ({ data, updateData }: BookingListDetailsEditProps) => {
  const navigation = useNavigation();
  const { eventType, source, commentOwner } = data;

  const handleSelectEventType = () => {
    navigation.navigate("LIST_SELECT", {
      listItems: Object.keys(Event_Type).map(key => ({
        _id: key,
        name: getLabelsForEvent(key),
      })),
      selectedItems: [
        {
          _id: eventType,
          name: getLabelsForEvent(eventType || "", eventType || ""),
        },
      ],
      multiSelect: false,
      simple: true,
      onSubmit: (selectedItems: { _id: string; name: string }[]) => {
        const [type] = selectedItems;

        const formattedEventType = Event_Type[type._id];

        updateData({
          ...data,
          eventType: formattedEventType,
        });
        navigation.goBack();
      },
    });
  };

  const getSources = () => {
    const sources = Object.keys(Origin)
      .filter(key => {
        if (key === "Google") return false;
        return true;
      })
      .map(key => ({
        _id: key,
        name: getLabelsForOrigin(key),
      }));

    return sources;
  };

  const handleSelectSource = () => {
    navigation.navigate("LIST_SELECT", {
      listItems: getSources(),
      selectedItems: [
        {
          _id: source,
          name: getLabelsForOrigin(source, source),
        },
      ],
      multiSelect: false,
      simple: true,
      onSubmit: (selectedItems: { _id: string; name: string }[]) => {
        const [type] = selectedItems;

        const formattedSource = Origin[type._id];

        updateData({
          ...data,
          source: formattedSource,
        });
        navigation.goBack();
      },
    });
  };

  return (
    <Box>
      <Box>
        <CustomTextInput
          placeHolder="Note sur la réservation"
          initialValue={commentOwner}
          onChangeText={(text: string) => updateData({ ...data, commentOwner: text })}
          multiLine
        />
      </Box>

      <Box mt="s">
        <TouchableOpacity onPress={handleSelectEventType}>
          <SelectModal
            title="Évènement"
            value={eventType ? getLabelsForEvent(eventType, eventType) : ""}
          />
        </TouchableOpacity>

        <TouchableOpacity
          disabled={source === Origin.Google}
          onPress={handleSelectSource}
        >
          <SelectModal
            hasTopBorder={false}
            title="Source"
            value={getLabelsForOrigin(source, source)}
          />
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default BookingListDetailsEdit;
