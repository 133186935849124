import { createTheme } from "@shopify/restyle";

import { PALETTE } from "./Palette";
import { ButtonVariants } from "./variants/ButtonVariants";
import { TextVariants } from "./variants/TextVariants";

export const ICON_SIZE = 24;
export const LINE_THICKNESS = 0.75;

const theme = createTheme({
  colors: {
    transparent: "transparent",
    // GENERAL
    offWhite: PALETTE.offWhite,
    white: PALETTE.white,
    black: PALETTE.black,
    lightBlue: PALETTE.blueSecondary,

    blue: PALETTE.blueTertiary,
    blueBackground: PALETTE.blueBackground,

    purple: PALETTE.purple,
    lightPurple: PALETTE.lightPurple,

    danger: PALETTE.red,
    redBackground: PALETTE.redBackground,

    yellow: PALETTE.yellow,
    yellowBackground: PALETTE.yellowBackground,

    darkGrey: PALETTE.darkGrey,
    lightGrey: PALETTE.lightGrey,
    disabled: PALETTE.grey,

    orange: PALETTE.orange,
    orangeBackground: PALETTE.orangeBackground,

    success: PALETTE.green,
    greenBackground: PALETTE.greenBackground,

    mainBackground: PALETTE.white,
    mainForeground: PALETTE.black,

    floorBackground: PALETTE.floorBackground,

    // LGOIN
    loginBackground: PALETTE.blue,
    loginForeground: PALETTE.white,

    // HEADER
    headerBackground: PALETTE.blue,
    subHeaderBackgroundActive: PALETTE.white,
    subHeaderBackgroundInactive: PALETTE.grey,

    // LIST
    listItemActiveBackground: PALETTE.blue,
    listItemInactiveBackground: PALETTE.white,
    listItemActiveColor: PALETTE.white,
    listItemInactiveColor: PALETTE.blue,

    // LIST TABS
    secondaryTab: PALETTE.secondaryTab,
    tertiaryTab: PALETTE.tertiaryTab,

    // TEXT
    descriptionColor: PALETTE.lightGrey,
    labelColor: PALETTE.darkBlue,
    primaryTextColor: PALETTE.blue,
    dangerTextColor: PALETTE.red,
    // outlineButtonColor: PALETTE.,
    // buttonPrimaryBackground: PALETTE.purplePrimary,

    // TEXT INPUT
    defaultTextInput: PALETTE.grey,
    errorTextInput: PALETTE.red,

    // BUTTONS
    primaryButton: PALETTE.green,
    dangerButton: PALETTE.red,
    pendingButton: PALETTE.orange,

    // BOOKING STATUS
    BOOKING_NEW: PALETTE.BOOKING_NEW,
    BOOKING_NEW_BACKGROUND: PALETTE.BOOKING_NEW_BACKGROUND,

    BOOKING_WAITING_LIST: PALETTE.BOOKING_WAITING_LIST,
    BOOKING_WAITING_LIST_BACKGROUND: PALETTE.BOOKING_WAITING_LIST_BACKGROUND,

    BOOKING_CONFIRMED: PALETTE.BOOKING_CONFIRMED,
    BOOKING_CONFIRMED_BACKGROUND: PALETTE.BOOKING_CONFIRMED_BACKGROUND,

    BOOKING_RECONFIRMED: PALETTE.BOOKING_RECONFIRMED,
    BOOKING_RECONFIRMED_BACKGROUND: PALETTE.BOOKING_RECONFIRMED_BACKGROUND,

    BOOKING_CANCELLED: PALETTE.BOOKING_CANCELLED,
    BOOKING_CANCELLED_BACKGROUND: PALETTE.BOOKING_CANCELLED_BACKGROUND,

    BOOKING_MODIFIED: PALETTE.BOOKING_MODIFIED,
    BOOKING_MODIFIED_BACKGROUND: PALETTE.BOOKING_MODIFIED_BACKGROUND,

    BOOKING_ANOTHER_TIME_SLOT: PALETTE.BOOKING_ANOTHER_TIME_SLOT,

    BOOKING_ARRIVED: PALETTE.BOOKING_ARRIVED,
    BOOKING_ARRIVED_BACKGROUND: PALETTE.BOOKING_ARRIVED_BACKGROUND,

    BOOKING_ARRIVED_WAITING_LIST: PALETTE.BOOKING_ARRIVED_WAITING_LIST,
    BOOKING_ARRIVED_WAITING_LIST_BACKGROUND:
      PALETTE.BOOKING_ARRIVED_WAITING_LIST_BACKGROUND,

    BOOKING_ENDED: PALETTE.BOOKING_ENDED,
    BOOKING_ENDED_BACKGROUND: PALETTE.BOOKING_ENDED_BACKGROUND,

    BOOKING_SEATED: PALETTE.BOOKING_SEATED,
    BOOKING_SEATED_BACKGROUND: PALETTE.BOOKING_SEATED_BACKGROUND,

    BOOKING_NO_SHOW: PALETTE.BOOKING_NO_SHOW,
    BOOKING_NO_SHOW_BACKGROUND: PALETTE.BOOKING_NO_SHOW_BACKGROUND,

    BOOKING_REFUNDED: PALETTE.BOOKING_REFUNDED,
    BOOKING_REFUNDED_BACKGROUND: PALETTE.BOOKING_REFUNDED_BACKGROUND,

    // ORDER ONLINE STATUS
    ORDERONLINE_NEW: PALETTE.ORDERONLINE_NEW,
    ORDERONLINE_NEW_BACKGROUND: PALETTE.ORDERONLINE_NEW_BACKGROUND,

    ORDERONLINE_CONFIRMED: PALETTE.ORDERONLINE_CONFIRMED,
    ORDERONLINE_CONFIRMED_BACKGROUND: PALETTE.ORDERONLINE_CONFIRMED_BACKGROUND,

    ORDERONLINE_PREPARING: PALETTE.ORDERONLINE_PREPARING,
    ORDERONLINE_PREPARING_BACKGROUND: PALETTE.ORDERONLINE_PREPARING_BACKGROUND,

    ORDERONLINE_PREPARED: PALETTE.ORDERONLINE_PREPARED,
    ORDERONLINE_PREPARED_BACKGROUND: PALETTE.ORDERONLINE_PREPARED_BACKGROUND,

    ORDERONLINE_ENDED: PALETTE.ORDERONLINE_ENDED,
    ORDERONLINE_ENDED_BACKGROUND: PALETTE.ORDERONLINE_ENDED_BACKGROUND,

    ORDERONLINE_CANCELLED: PALETTE.ORDERONLINE_CANCELLED,
    ORDERONLINE_CANCELLED_BACKGROUND: PALETTE.ORDERONLINE_CANCELLED_BACKGROUND,

    ORDERONLINE_NO_SHOW: PALETTE.ORDERONLINE_NO_SHOW,
    ORDERONLINE_NO_SHOW_BACKGROUND: PALETTE.ORDERONLINE_NO_SHOW_BACKGROUND,

    ORDERONLINE_PAID: PALETTE.ORDERONLINE_PAID,
    ORDERONLINE_PAID_BACKGROUND: PALETTE.ORDERONLINE_PAID_BACKGROUND,

    ORDERONLINE_REFUNDED: PALETTE.ORDERONLINE_REFUNDED,
    ORDERONLINE_REFUNDED_BACKGROUND: PALETTE.ORDERONLINE_REFUNDED_BACKGROUND,

    ORDERONLINE_MODIFIED: PALETTE.ORDERONLINE_MODIFIED,
    ORDERONLINE_MODIFIED_BACKGROUND: PALETTE.ORDERONLINE_MODIFIED_BACKGROUND,

    ORDERONLINE_ANOTHER_TIME_SLOT: PALETTE.ORDERONLINE_ANOTHER_TIME_SLOT,
    ORDERONLINE_DELIVERING: PALETTE.ORDERONLINE_DELIVERING,
    ORDERONLINE_DELIVERED: PALETTE.ORDERONLINE_DELIVERED,

    // TICKET STATUS
    TICKET_OPEN: PALETTE.TICKET_OPEN,
    TICKET_ORDERING: PALETTE.TICKET_ORDERING,
    TICKET_SENT: PALETTE.TICKET_SENT,
    TICKET_PAID: PALETTE.TICKET_PAID,
    TICKET_CLOSED: PALETTE.TICKET_CLOSED,
    TICKET_CANCELLED: PALETTE.TICKET_CANCELLED,
    TICKET_REFUNDED: PALETTE.TICKET_REFUNDED,

    // QUOTE STATUS
    QUOTE_NEW: PALETTE.QUOTE_NEW,
    QUOTE_ACCEPTED: PALETTE.QUOTE_ACCEPTED,
    QUOTE_SENT: PALETTE.QUOTE_SENT,
    QUOTE_MODIFIED: PALETTE.QUOTE_MODIFIED,
    QUOTE_CANCELLED: PALETTE.QUOTE_CANCELLED,
    QUOTE_REJECTED: PALETTE.QUOTE_REJECTED,

    // INVOICE STATUS
    INVOICE_NEW: PALETTE.INVOICE_NEW,
    INVOICE_CONFIRMED: PALETTE.INVOICE_CONFIRMED,
    INVOICE_SENT: PALETTE.INVOICE_SENT,
    INVOICE_PAID: PALETTE.INVOICE_PAID,
    INVOICE_MODIFIED: PALETTE.INVOICE_MODIFIED,
    INVOICE_CANCELLED: PALETTE.INVOICE_CANCELLED,
    INVOICE_REJECTED: PALETTE.INVOICE_REJECTED,
  },
  spacing: {
    none: 0,
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
  },
  breakpoints: {
    phone: 0,
    longPhone: {
      width: 0,
      height: 812,
    },
    tablet: 768,
    largeTablet: 1024,
  },
  borderRadii: {
    none: 0,
    icon: 2,
    button: 8,
    modal: 24,
  },
  buttonVariants: ButtonVariants,
  textVariants: TextVariants,
});

export type Theme = typeof theme;
export default theme;

export type THEME_COLORS = keyof typeof theme.colors;
