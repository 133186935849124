import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";

import Box from "../../../../components/Base/Box";
import BottomButton from "../../../../components/BottomButton";

import StaffListDetailsEdit from "./Edit";
import StaffListDetailsView from "./View";

interface StaffListDetailsProps {
  isNewValue?: boolean;
  staffId?: string;
  goBack?: () => void;
}

const StaffListDetails = ({
  isNewValue = false,
  staffId = "",
  goBack,
}: StaffListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const isNew = params?.isNew || isNewValue;
  const id = params?.id || staffId;
  const [isInEditMode, setIsInEditMode] = useState(isNew);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
    return;
    // if (isNew) {
    //   if (goBack) {
    //     goBack();
    //   } else {
    //     navigation.goBack();
    //   }
    // } else {
    //   setIsInEditMode(false);
    // }
  };

  const displayContent = () => {
    if (isInEditMode) {
      return <StaffListDetailsEdit isNew={isNew} id={id} onGoBack={handleGoBack} />;
    } else {
      return <StaffListDetailsView id={id} goBack={goBack} />;
    }
  };

  return (
    <Box flex={1} p="m" backgroundColor="white">
      {displayContent()}
      {!isInEditMode && (
        <BottomButton onPress={() => setIsInEditMode(true)} title="Modifier" />
      )}
    </Box>
  );
};

export default StaffListDetails;
