import CALENDAR from "../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../assets/icons/BASE/CUTLERY.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface BookingServiceListCardProps {
  name: string;
  areLimitsEnabled: boolean;
  isEnabled: boolean;
  updatedEnabled: (value: boolean) => void;
  capacity: string;
  days: string;
  time: string;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const BookingServiceListCard = ({
  name,
  capacity,
  areLimitsEnabled,
  days,
  time,
  isEnabled,
  updatedEnabled,
  isSelected = false,
  isDisabled = false,
}: BookingServiceListCardProps) => {
  return (
    <Box
      p="s"
      borderColor={isSelected ? "success" : "disabled"}
      borderWidth={isSelected ? LINE_THICKNESS : 0}
      borderRadius="button"
      backgroundColor={isSelected ? "white" : "disabled"}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="primaryTextColor">
          {name}
        </CustomText>
        {!isDisabled && <Toggle value={isEnabled} onChange={updatedEnabled} />}
      </Box>
      {areLimitsEnabled && (
        <Box mt="s" flexDirection="row" alignItems="center">
          <CUTLERY width={15} height={15} fill={PALETTE.green} />
          <CustomText ml="s" variant="content" color="primaryTextColor">
            {capacity}
          </CustomText>
        </Box>
      )}

      <Box mt="s" flexDirection="row" alignItems="center">
        <CALENDAR width={15} height={15} fill={PALETTE.green} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          {days}
        </CustomText>
      </Box>
      <Box mt="s" flexDirection="row" alignItems="center">
        <OCCURENCES width={15} height={15} fill={PALETTE.green} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          {time}
        </CustomText>
      </Box>
    </Box>
  );
};

export default BookingServiceListCard;
