import { useNavigation } from "@react-navigation/native";
import { FlatList } from "react-native";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import ScreenHeader from "../../../../../../components/ScreenHeader";

const MenuStepAddProduct = () => {
  const navigation = useNavigation();

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <FlatList
        ListHeaderComponent={
          <>
            <ScreenHeader
              title="étape entrée"
              hasBackButton
              onBackPress={navigation.goBack}
            />

            {/* <Box marginVertical="s">
              <CustomTextInput
                {...{
                  placeHolder: "Rechercher",
                  // leftIcon: (
                  //   <SEARCH
                  //     height={ICON_SIZE}
                  //     width={ICON_SIZE}
                  //     fill={PALETTE.darkBlue}
                  //   />
                  // ),
                  onChangeText: (text: string) => {},
                  variant: "defaultTextInput",
                  boxProps: {
                    backgroundColor: "disabled",
                    padding: "s",
                    borderRadius: "button",
                  },
                }}
              />
            </Box> */}
          </>
        }
        showsVerticalScrollIndicator={false}
        data={[1, 2]}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => (
          <Box marginVertical="s">
            {/* <StaffListCard isSelected={item === 1} onPress={() => {}} /> */}
          </Box>
        )}
      />

      {/* <BottomButton
        title="Valider"

      /> */}
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPress={() => {}}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MenuStepAddProduct;
