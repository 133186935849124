import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import Loader from "../../../../../../components/Loader";
import AddNewImageModal from "../../../../../../components/Media/AddNewImageMedia";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CombinedCategoryFragment,
  CreateCombinedCategory,
} from "../../../../../../graphql/generated/schema";
import {
  GetCombinedCategoriesDocument,
  useCreateCombinedCategoryMutation,
  useDeleteCombinedCategoryMutation,
  useGetCombinedCategoryLazyQuery,
  useSavePictureMutation,
  useUpdateCombinedCategoryMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../../utils/common";
interface CombinedCategoryListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 95;

const DEFAULT_COMBINED_CATEGORY: CreateCombinedCategory = {
  categories: [],
  description: "",
  isEnabled: true,
  name: "",
  picture: "",
  position: 0,
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est requis"),
  description: yup.string(),
  categories: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Vous devez sélectionner au moins une catégorie")
    .required("Vous devez sélectionner au moins une catégorie"),
});

const CombinedCategoryListDetailsEdit = ({
  id,
  goBack,
}: CombinedCategoryListDetailsEditProps) => {
  const navigation = useNavigation();
  const isNew = !id;
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(DEFAULT_COMBINED_CATEGORY);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  const [getCategory] = useGetCombinedCategoryLazyQuery();
  const [createCategory] = useCreateCombinedCategoryMutation();
  const [updateCategory] = useUpdateCombinedCategoryMutation();
  const [deleteCategory] = useDeleteCombinedCategoryMutation();
  const [savePicture] = useSavePictureMutation();

  const handlGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetCategory = async () => {
    try {
      if (id) {
        const { data } = await getCategory({
          variables: {
            combinedCategoryId: id,
          },
        });

        if (data?.getCombinedCategory) {
          setCategory(data.getCombinedCategory);
        }
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  const updateCategoryData = (
    key: keyof CombinedCategoryFragment,
    value: string | string[] | number | boolean,
  ) => {
    setCategory(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleRemove = (cid: string) => {
    setCategory(prev => ({
      ...prev,
      categories: prev.categories.filter(p => p._id !== cid),
    }));
  };

  const handleGoToAdd = () => {
    navigation.navigate("COMBINED_CATEGORIES_LIST_DETAILS_ADD_CATEGORY", {
      selectedCategories: category.categories,
      onSubmit: (p: string[]) => updateCategoryData("categories", p),
    });
  };

  const validateForm = async () => {
    try {
      await schema.validate(category, { abortEarly: false });

      return true;
    } catch (err) {
      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
      setErrors(formaYupErrors(err));
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const categories = category.categories.map(p => p._id);
      const consolidatedCategory = {
        ...category,
        categories,
      };

      if (isNew) {
        await createCategory({
          variables: {
            combinedCategory: consolidatedCategory,
          },
          refetchQueries: [
            {
              query: GetCombinedCategoriesDocument,
              variables: {
                limit: 10,
                offset: 0,
              },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consolidatedCategory);
        delete updates._id;
        await updateCategory({
          variables: {
            combinedCategoryId: id,
            combinedCategory: updates,
          },
          refetchQueries: [
            {
              query: GetCombinedCategoriesDocument,
              variables: {
                limit: 10,
                offset: 0,
              },
            },
          ],
        });
      }
      handlGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ code: "submit", message: err.message }]);

      // setIsSnackBarOpen(true);
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteCategory({
          variables: {
            combinedCategoryId: id,
          },
          // refetchQueries: [
          //   {
          //     query: GetCombinedCategoriesDocument,
          //     variables: {
          //       limit: 10,
          //       offset: 0,
          //     },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "CombinedCategory",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handlGoBack();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setCategory(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = category;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  const displayTitle = isNew
    ? "Nouvelle catégorie combinée"
    : "Modifier la catégorie combinée";

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handlGoBack} />
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 150,
        }}
      >
        <Box flex={1}>
          <Box marginVertical="s">
            <Box>{displayPicture()}</Box>
          </Box>

          <Box>
            <CustomTextInput
              placeHolder="Nom"
              initialValue={category.name}
              hasErrors={doesInputHaveError("name")}
              onChangeText={t => updateCategoryData("name", t)}
              isRequired
            />
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Description"
              multiLine
              initialValue={category.description}
              onChangeText={t => updateCategoryData("description", t)}
            />
          </Box>

          <Box
            marginVertical="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText
              style={{ flex: 1, flexWrap: "wrap" }}
              variant="label"
              color="primaryTextColor"
            >
              Activé
            </CustomText>
            <Toggle
              value={category.isEnabled}
              onChange={v => updateCategoryData("isEnabled", v)}
            />
          </Box>

          <Box mt="m">
            <CustomText
              mb="s"
              variant="content"
              color={doesInputHaveError("categories") ? "danger" : "success"}
              textTransform="uppercase"
            >
              Catégories
            </CustomText>

            <DraggableFlatList
              scrollEnabled={false}
              data={category.categories}
              style={{ width: "100%" }}
              ListHeaderComponent={
                <TouchableOpacity onPress={handleGoToAdd}>
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mr="s"
                    marginVertical="s"
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title="Ajouter une catégorie"
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <PLUS fill={PALETTE.green} width={15} height={15} />
                  </Box>
                </TouchableOpacity>
              }
              renderItem={({ item, drag }) => (
                <TouchableOpacity
                  onPressIn={drag}
                  style={{
                    marginVertical: 8,
                    marginRight: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box flex={0.95}>
                    <InlineSelectButton
                      isDraggable
                      title={item.name}
                      options={[]}
                      selectedOptionKeys={[]}
                      isMultiSelect={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onPress={() => {}}
                    />
                  </Box>

                  <TouchableOpacity onPress={() => handleRemove(item._id)}>
                    <TRASH fill={PALETTE.red} width={15} height={15} />
                  </TouchableOpacity>
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => `draggable-item-${index}`}
              onDragEnd={({ data }) => {
                updateCategoryData("categories", data);
              }}
            />
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>

      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default CombinedCategoryListDetailsEdit;
