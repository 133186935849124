import { useFocusEffect } from "@react-navigation/native";
import { startOfDay } from "date-fns";
import React, { useContext, useState } from "react";
import { Platform } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import BookingOrderAlertCard from "../../../components/Alerts/BookingAlertCard";
import Box from "../../../components/Base/Box";
import CollapsibleList from "../../../components/CollapsibleList";
import ContentModal from "../../../components/ContentModal";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import Touchable from "../../../components/Touchable";
import { AppContext } from "../../../contexts/AppContext";
import type { BookingFragment } from "../../../graphql/generated/schema";
import {
  Booking_Status,
  useGetPendingBookingsFromDateLazyQuery,
  useUpdateBookingMutation,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { uniquefyArray } from "../../../utils/common";
import BookingListDetailsView from "../../Booking/BookingListDetails";

const IS_WEB = Platform.OS === "web";

const BookingAlerts = () => {
  const appContext = useContext(AppContext);
  // const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [bookings, setBookings] = useState<BookingFragment[]>([]);
  const [loading, setLoading] = useState(true);
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [pendingAmount, setPendingAmount] = useState(appContext.totalPendingBookings);

  const [getPendingBookings, { fetchMore }] = useGetPendingBookingsFromDateLazyQuery();
  const [updateBooking] = useUpdateBookingMutation();

  const handleFetchMore = async () => {
    const date = startOfDay(new Date()).toISOString();
    try {
      const { data } = await fetchMore({
        variables: {
          date,
          pagination: {
            limit: 20,
          },
        },
      });
      if (data?.getPendingBookingsFromDate) {
        const pendingBookings = data?.getPendingBookingsFromDate;
        const allBookings = [...bookings, ...pendingBookings].filter(b => {
          const statuses = [
            Booking_Status.New,
            Booking_Status.WaitingList,
            Booking_Status.ModifiedByOwner,
            Booking_Status.ModifiedByUser,
          ];
          return statuses.includes(b.status);
        });
        const arr = uniquefyArray(allBookings);
        setBookings(arr);
      }
    } catch (err) {}
  };

  const handleGetPendingBookings = async () => {
    const date = startOfDay(new Date()).toISOString();

    try {
      const { data } = await getPendingBookings({
        fetchPolicy: "cache-and-network",
        variables: {
          date,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getPendingBookingsFromDate) {
        const pendingBookings = data?.getPendingBookingsFromDate;

        const isOpen = pendingBookings.length > 0;
        setIsListOpen(isOpen);

        const pending = pendingBookings.filter(b => {
          const statuses = [
            Booking_Status.New,
            Booking_Status.WaitingList,
            Booking_Status.ModifiedByOwner,
            Booking_Status.ModifiedByUser,
          ];
          return statuses.includes(b.status);
        });

        setBookings(pending);
      }
    } catch (err) {
      console.log("err getting pending bookings", err);
    } finally {
      setLoading(false);
    }
  };

  const handleBookingStatusChange = async (
    itemId: string,
    newStatus: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => {
    try {
      await updateBooking({
        variables: {
          bookingId: itemId,
          booking: {
            status: newStatus,
            refusalReason,
            applyAutomaticPrepaymentPercentage,
            sendClientNotifications: true,
          },
        },
      });

      setPendingAmount(p => (p - 1 < 0 ? 0 : p - 1));
    } catch (err) {
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "BOOKING_UPDATE_ERROR",
      //       path: "BOOKING_UPDATE_ERROR",
      //       message: "Une erreur est survenue lors de la mise à jour de la réservation",
      //     },
      //   ],
      //   "error",
      // );
    } finally {
      await handleGetPendingBookings();
      // appContext.refreshAlerts();
    }
  };

  const handleBookingUpdates = async () => {
    setSelectedId("");
    await handleGetPendingBookings();
    // appContext.refreshAlerts();
  };

  // useEffect(() => {
  //   handleBookingUpdates();
  // }, [appContext.totalPendingBookings]);

  useFocusEffect(
    React.useCallback(() => {
      handleGetPendingBookings();
      // appContext.refreshAlerts();
    }, []),
  );

  const handleCardPress = (itemId: string) => {
    // navigation.navigate("BOOKING", {
    //   screen: "BOOKINGS_LIST_DETAILS",
    //   params: {
    //     bookingId: itemId,
    //   },
    // });
  };

  const displaySelectedBookingModal = () => {
    return (
      <ContentModal
        title="Réservation"
        isVisible={!!selectedId}
        onClose={() => setSelectedId("")}
        hasBackButton={false}
      >
        <Box height="100%" pb="xl">
          <BookingListDetailsView
            bookingId={selectedId}
            showGoBack={false}
            onUpdateComplete={handleGetPendingBookings}
          />
        </Box>
      </ContentModal>
    );
  };

  if (loading) return <Loader />;

  return (
    <Box
      maxWidth={1024}
      width="100%"
      alignSelf="center"
      flex={1}
      pt="s"
      paddingHorizontal="s"
      backgroundColor="white"
    >
      <ScreenHeader title="Réservations" />

      <Box mt="s">
        <CollapsibleList
          isOpen={isListOpen}
          nbItems={pendingAmount}
          title="Réservations en attente"
          onChange={() => bookings.length > 0 && setIsListOpen(v => !v)}
        >
          <FlatList
            data={bookings}
            onEndReached={handleFetchMore}
            keyExtractor={item => item._id}
            contentContainerStyle={{
              paddingBottom: bookings.length > 0 && !IS_WEB ? 150 : 0,
            }}
            renderItem={({ item, index: idx }) => {
              const IS_LAST = idx === bookings.length - 1;

              return (
                <Touchable onPress={() => setSelectedId(item._id)}>
                  <Box
                    key={item._id}
                    borderBottomColor="disabled"
                    borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                  >
                    <BookingOrderAlertCard
                      {...{
                        data: item,
                        boxStyles: { paddingHorizontal: "s" },
                        onCardPress: handleCardPress,
                        onPress: (...args) => {
                          handleBookingStatusChange(item._id, ...args);
                        },
                      }}
                    />
                  </Box>
                </Touchable>
              );
            }}
          />
        </CollapsibleList>
      </Box>
      {selectedId && displaySelectedBookingModal()}
    </Box>
  );
};

export default BookingAlerts;
