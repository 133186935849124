import { addDays, format, isBefore, isSameDay } from "date-fns";
import { useState } from "react";

import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomButton } from "../../../Button";
import ContentModal from "../../../ContentModal";
import CustomCalendar, { CALENDAR_VIEW } from "../../../CustomCalendar/index";

interface PersonnaliseCalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  startDate: Date;
  endDate: Date;
  onSubmit: (start: Date, end: Date) => void;
}

const PersonnaliseCalendarModal = ({
  isOpen,
  onClose,
  startDate,
  endDate,
  onSubmit,
}: PersonnaliseCalendarModalProps) => {
  const [start, setStart] = useState<Date | null>(startDate);
  const [end, setEnd] = useState<Date | null>(
    isSameDay(startDate, endDate) ? null : endDate,
  );

  const handleReset = () => {
    setStart(null);
    setEnd(null);
  };

  const getMarkedDates = () => {
    if (start && !end) {
      return {
        [format(new Date(start), "yyyy-MM-dd")]: {
          selected: true,
          marked: true,
          selectedColor: PALETTE.green,
        },
      };
    }
    if (!start && !end) {
      return {};
    }

    const period: Record<string, unknown> = {};
    let s = start;
    while (isBefore(s, end)) {
      period[format(s, "yyyy-MM-dd")] = {
        startingDay: false,
        color: PALETTE.green,
        selected: true,
      };

      s = addDays(s, 1);
    }

    period[format(start, "yyyy-MM-dd")] = {
      startingDay: true,
      color: PALETTE.green,
      selected: true,
    };
    period[format(end, "yyyy-MM-dd")] = {
      endingDay: true,
      color: PALETTE.green,
      selected: true,
    };

    return period;
  };

  const handleDateChange = (date: Date) => {
    if (!start) {
      setStart(date);
    } else if (!end) {
      if (date > start) {
        setEnd(date);
      } else {
        setStart(date);
      }
    } else {
      setStart(date);
      setEnd(null);
    }
  };

  const handleSubmit = () => {
    if (start && end) {
      onSubmit(start, end);
    }
  };

  return (
    <ContentModal isVisible={isOpen} onClose={onClose} title="Personnaliser la période">
      <Box height="100%">
        <Box
          p="s"
          borderRadius="button"
          borderColor="disabled"
          borderWidth={LINE_THICKNESS}
        >
          <CustomCalendar
            calendarView={CALENDAR_VIEW.MONTH}
            onDateChange={handleDateChange}
            markingType={start && end ? "period" : "simple"}
            selectedDate={new Date()}
            markedDates={getMarkedDates()}
          />
        </Box>

        <Box
          position={{
            phone: "absolute",
            tablet: "relative",
          }}
          p="s"
          pb={{
            phone: "l",
            tablet: "none",
          }}
          bottom={{
            phone: 20,
            tablet: 0,
          }}
          left={0}
          right={0}
        >
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="yellow"
            onPress={handleReset}
          >
            <CustomText variant="outlineButtonText" color="yellow">
              Réinitialiser
            </CustomText>
          </CustomButton>

          <CustomButton
            styles={{ mt: "s" }}
            disabled={!start || !end}
            buttonVariant="primaryButton"
            buttonColor={start && end ? "success" : "lightGrey"}
            onPress={handleSubmit}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default PersonnaliseCalendarModal;
