import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import CALENDAR from "../../../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../../../assets/icons/BASE/CUTLERY.svg";
import type { GetTotalBookingsDifferentiatedByStatusQuery } from "../../../../graphql/generated/schema";
import { useGetTotalBookingsDifferentiatedByStatusLazyQuery } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

const ICON_BOX_SIZE = 70;

const BookingStatTotalRecap = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookingsStats, setBookingsStats] = useState<
    | GetTotalBookingsDifferentiatedByStatusQuery["getTotalBookingsDifferentiatedByStatus"]
    | null
  >(null);

  const [getBookingsTotalRecap] = useGetTotalBookingsDifferentiatedByStatusLazyQuery();

  const handleGetBookingsTotalRecap = async () => {
    try {
      const { data } = await getBookingsTotalRecap();

      if (data?.getTotalBookingsDifferentiatedByStatus) {
        setBookingsStats(data.getTotalBookingsDifferentiatedByStatus);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, []);

  if (isLoading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!bookingsStats) return null;

  return (
    <Box flex={1}>
      <Box mb="s">
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Récap depuis le début
        </CustomText>
      </Box>
      <Box ml="m">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          marginVertical="s"
        >
          <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
            <CALENDAR width={16} height={16} fill={PALETTE.darkBlue} />
          </Box>
          <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
            <CUTLERY width={16} height={16} fill={PALETTE.darkBlue} />
          </Box>
        </Box>

        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="label" fontSize={14} color="primaryTextColor">
            Réservations totales
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="label" fontSize={16} color="primaryTextColor">
                {bookingsStats.totalBookings}
              </CustomText>
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="label" fontSize={16} color="primaryTextColor">
                {bookingsStats.totalPaxBookings}
              </CustomText>
            </Box>
          </Box>
        </Box>

        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Réservations en ligne
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalOnlineBookings}
              </CustomText>
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalPaxOnlineBookings}
              </CustomText>
            </Box>
          </Box>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Réservations de passage
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalOfflineBookings}
              </CustomText>
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalPaxOfflineBookings}
              </CustomText>
            </Box>
          </Box>
        </Box>
        {/* <Box flexDirection="row" alignItems="center" mb="s" justifyContent="space-between">
        <CustomText variant="content" color="primaryTextColor">
          Réservations de passage
        </CustomText>
        <Box flexDirection="row" alignItems="center">
          <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              4
            </CustomText>
          </Box>
          <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              40
            </CustomText>
          </Box>
        </Box>
      </Box> */}
        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Réservations annulés
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalCancelledBookings}
              </CustomText>
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalPaxCancelledBookings}
              </CustomText>
            </Box>
          </Box>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Réservations noshow
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalNoShowBookings}
              </CustomText>
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalPaxNoShowBookings}
              </CustomText>
            </Box>
          </Box>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          mb="s"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Fichiers clients
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              {/* <CustomText variant="content" color="primaryTextColor"></CustomText> */}
            </Box>
            <Box width={ICON_BOX_SIZE} alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                {bookingsStats.totalClients}
              </CustomText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingStatTotalRecap;
