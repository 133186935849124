import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import CompanyCard from "../../../../components/CompanyCard";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import type { CompanyFragment } from "../../../../graphql/generated/schema";
import { useGetCompaniesLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import CompanyListDetails from "../CompanyListDetails";

interface CompanyListProps {
  goBack?: () => void;
}

const CompanyList = ({ goBack }: CompanyListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, offset: 0 });
  const [companies, setCompanies] = useState<CompanyFragment[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [showCompanyInline, setShowCompanyInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getCompanies] = useGetCompaniesLazyQuery();

  const filteredCompanies = useMemo(() => {
    const formattedSearch = search.toLowerCase()?.trim();

    if (!formattedSearch) {
      return companies;
    }

    return companies.filter(company => {
      const formatteName = company.companyName.toLowerCase()?.trim();
      const formatteEmail = company.email.toLowerCase()?.trim();
      const formattePhone = company.phone.toLowerCase()?.trim();

      return (
        formatteName.includes(formattedSearch) ||
        formatteEmail.includes(formattedSearch) ||
        formattePhone.includes(formattedSearch)
      );
    });
  }, [search, companies]);

  const handleGetCompanies = async () => {
    try {
      const { data } = await getCompanies({
        fetchPolicy: "cache-and-network",
        variables: { pagination },
      });

      setCompanies(data?.getMerchantClientCompanies || []);
    } catch (err) {
      console.log("err get companies", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetCompanies();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setSelectedCompanyId("");
      setShowCompanyInline(true);
    } else {
      navigation.navigate("COMPANY_LIST_DETAILS", { isNew: true });
    }
  };

  const handleNavigationToDetails = (id: string) => {
    if (goBack) {
      setSelectedCompanyId(id);
      setShowCompanyInline(true);
    } else {
      navigation.navigate("COMPANY_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetCompanies();
    setShowCompanyInline(false);
  };

  const displayCompanyInline = () => {
    return (
      <CompanyListDetails
        companyId={selectedCompanyId}
        goBack={handleCloseSelectedTab}
        newValue={selectedCompanyId === ""}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Entreprises"
          rightButtons={
            <TouchableOpacity onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <FlatList
          ListHeaderComponent={
            <Box>
              <Box marginVertical="s">
                <CustomTextInput
                  {...{
                    placeHolder: "Rechercher",
                    leftIcon: (
                      <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.black} />
                    ),
                    onChangeText: setSearch,
                  }}
                />
              </Box>
            </Box>
          }
          showsVerticalScrollIndicator={false}
          data={filteredCompanies}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleNavigationToDetails(item._id)}>
                <CompanyCard
                  name={item.companyName}
                  subText={`${item?.clientsAttached?.length || 0} personnes`}
                  phone={item.phone}
                  email={item.email}
                  image={item.picture}
                  isSelected={false}
                />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showCompanyInline) {
      return displayCompanyInline();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default CompanyList;
