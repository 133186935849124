import { useNavigation } from "@react-navigation/native";
import { format, isSameDay, setDay } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Alert, TouchableOpacity } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import ContentModal from "../../../../components/ContentModal";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import AddNewMerchantHourModalContent, {
  HOUR_LOCATION_MODAL_CONTENT_TYPE,
} from "../../../../components/Settings/MerchantHoursModalContents/AddNewMerchantHourModalContent";
import Closure from "../../../../components/Settings/MerchantHoursModalContents/Closure";
import Hour from "../../../../components/Settings/MerchantHoursModalContents/Hour";
import Location from "../../../../components/Settings/MerchantHoursModalContents/Location";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  ClosedDate,
  MerchantFragment,
  OpenHour,
} from "../../../../graphql/generated/schema";
import {
  useGetMerchantLazyQuery,
  useUpdateMerchantMutation,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import { removeTypeNames } from "../../../../utils/common";

interface HoursAndLocationsProps {
  goBack?: () => void;
}

const HoursAndLocations = ({ goBack }: HoursAndLocationsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [isNewSelectModelOpen, setIsNewSelectModelOpen] = useState(false);
  const [modalType, setModalType] = useState(
    HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR_LOCAITON_CHOICE,
  );
  const [selectedHour, setSelectedHour] = useState<OpenHour | undefined>();
  const [selectedClosedDate, setSelectedClosedDate] = useState<ClosedDate | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);

  const [getMerchant] = useGetMerchantLazyQuery();
  const [updateMerchant] = useUpdateMerchantMutation();

  const handleGetMerchant = async () => {
    try {
      const { data, error } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchant();
  }, []);

  const handleNewPress = () => {
    setIsNewSelectModelOpen(true);
  };

  const openModal = (type: HOUR_LOCATION_MODAL_CONTENT_TYPE) => {
    setModalType(type);
    setIsNewSelectModelOpen(true);
  };

  const closeModal = () => {
    setIsNewSelectModelOpen(false);
    setModalType(HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR_LOCAITON_CHOICE);

    setSelectedHour(undefined);
    setSelectedClosedDate(undefined);
  };

  const openClosedDateModal = (closedDate?: ClosedDate) => {
    openModal(HOUR_LOCATION_MODAL_CONTENT_TYPE.CLOSURE);
    setIsNewSelectModelOpen(true);
    setSelectedClosedDate(closedDate);
  };

  const openHourModal = (hour?: OpenHour) => {
    openModal(HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR);
    setIsNewSelectModelOpen(true);
    setSelectedHour(hour);
  };

  const handleAddModalPress = (type: HOUR_LOCATION_MODAL_CONTENT_TYPE) => {
    if (type === HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR) {
      openHourModal();
    } else if (type === HOUR_LOCATION_MODAL_CONTENT_TYPE.CLOSURE) {
      openClosedDateModal();
    }
  };

  const handleOpenHourChange = async (hour: OpenHour, prevHour?: OpenHour) => {
    try {
      const isNew = !prevHour;
      const hours = merchant?.openHours || [];
      let newHours: OpenHour[] = [];

      if (isNew) {
        newHours = [...hours, hour];
      } else {
        newHours = hours.map(h => {
          if (h.openAt === prevHour.openAt && h.closeAt === prevHour.closeAt) {
            return hour;
          }

          return h;
        });
      }

      const updates = removeTypeNames({
        openHours: newHours,
      });

      await updateMerchant({
        variables: {
          updates,
        },
      });
    } catch (err) {
      Alert.alert(
        "Erreur",
        "Une erreur est survenue lors de la mise à jour de l'heure d'ouverture.",
      );
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "ERROR_UPDATE_MERCHANT_HOUR",
      //       path: "ERROR_UPDATE_MERCHANT_HOUR",
      //       message:
      //         "Une erreur est survenue lors de la mise à jour de l'heure d'ouverture.",
      //     },
      //   ],
      //   "error",
      // );

      console.log("err updating merchant hour", err);
    } finally {
      closeModal();
      handleGetMerchant();
    }
  };

  const handleOpenHourDelete = async (hour: OpenHour) => {
    try {
      closeModal();

      const hours = merchant?.openHours || [];
      const newHours = hours.filter(
        h => h._id !== hour._id,
        // h.openAt !== hour.openAt && h.closeAt !== hour.closeAt,
      );

      const updates = removeTypeNames({
        openHours: newHours,
      });

      await updateMerchant({
        variables: {
          updates,
        },
      });
    } catch (err) {
      Alert.alert(
        "Erreur",
        "Une erreur est survenue lors de la suppression de l'heure d'ouverture.",
      );
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "ERROR_DELETE_MERCHANT_HOUR",
      //       path: "ERROR_DELETE_MERCHANT_HOUR",
      //       message:
      //         "Une erreur est survenue lors de la suppression de l'heure d'ouverture.",
      //     },
      //   ],
      //   "error",
      // );

      console.log("err deleting merchant hour", err);
    } finally {
      handleGetMerchant();
    }
  };

  const handleClosedDateChange = async (
    closedDate: ClosedDate,
    prevClosedDate?: ClosedDate,
  ) => {
    try {
      closeModal();

      const isNew = !prevClosedDate;
      const closedDates = merchant?.closedDates || [];
      let newClosedDates: ClosedDate[] = [];

      if (isNew) {
        newClosedDates = [...closedDates, closedDate];
      } else {
        newClosedDates = closedDates.map(c => {
          if (
            isSameDay(new Date(c.startDate), new Date(prevClosedDate.startDate)) &&
            isSameDay(new Date(c.endDate), new Date(prevClosedDate.endDate))
          ) {
            return closedDate;
          }

          return c;
        });
      }

      const formattedClosedDates = newClosedDates.map(c => ({
        ...c,
        startDate: new Date(c.startDate).toISOString(),
        endDate: new Date(c.endDate).toISOString(),
      }));
      const updates = removeTypeNames({
        closedDates: formattedClosedDates,
      });

      await updateMerchant({
        variables: {
          updates,
        },
      });
    } catch (err) {
      Alert.alert(
        "Erreur",
        "Une erreur est survenue lors de la mise à jour de l'heure de fermeture.",
      );
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "ERROR_UPDATE_MERCHANT_CLOSED_HOUR",
      //       path: "ERROR_UPDATE_MERCHANT_CLOSED_HOUR",
      //       message:
      //         "Une erreur est survenue lors de la mise à jour de l'heure de fermeture.",
      //     },
      //   ],
      //   "error",
      // );
      console.log("err updating merchant closed date", err);
    } finally {
      handleGetMerchant();
    }
  };

  const handleClosedDateDelete = async (closedDate: ClosedDate) => {
    try {
      closeModal();

      const closedDates = merchant?.closedDates || [];
      const newClosedDates = closedDates.filter(
        c => c._id !== closedDate._id,
        // !isSameDay(new Date(c.startDate), new Date(closedDate.startDate)) &&
        // !isSameDay(new Date(c.endDate), new Date(closedDate.endDate)),
      );

      const formattedClosedDates = newClosedDates.map(c => ({
        ...c,
        startDate: new Date(c.startDate).toISOString(),
        endDate: new Date(c.endDate).toISOString(),
      }));
      const updates = removeTypeNames({
        closedDates: formattedClosedDates,
      });

      await updateMerchant({
        variables: {
          updates,
        },
      });
    } catch (err) {
      Alert.alert(
        "Erreur",
        "Une erreur est survenue lors de la suppression de l'heure de fermeture.",
      );
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "ERROR_DELETE_MERCHANT_CLOSED_HOUR",
      //       path: "ERROR_DELETE_MERCHANT_CLOSED_HOUR",
      //       message:
      //         "Une erreur est survenue lors de la suppression de l'heure de fermeture.",
      //     },
      //   ],
      //   "error",
      // );
      console.log("err deleting merchant closed date", err);
    } finally {
      handleGetMerchant();
    }
  };

  const displayModalContent = () => {
    if (modalType === HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR) {
      return (
        <Hour
          hour={selectedHour}
          onChange={handleOpenHourChange}
          onDelete={handleOpenHourDelete}
        />
      );
    }
    if (modalType === HOUR_LOCATION_MODAL_CONTENT_TYPE.CLOSURE) {
      return (
        <Closure
          closedDate={selectedClosedDate}
          onChange={handleClosedDateChange}
          onDelete={handleClosedDateDelete}
        />
      );
    }
    if (modalType === HOUR_LOCATION_MODAL_CONTENT_TYPE.LOCATION) {
      return <Location />;
    }
    return <AddNewMerchantHourModalContent onPress={handleAddModalPress} />;
  };

  const displayTitle = () => {
    return "Horaire";
  };

  const displayDays = (days: number[]) => {
    return days
      .map(day => {
        const date = setDay(new Date(), day);
        return format(date, "EEE");
      })
      .join(" | ");
  };

  if (loading) {
    return <Loader />;
  }

  if (!merchant) {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader
          title="Horaires & Lieux"
          rightButtons={
            <TouchableOpacity onPress={handleNewPress}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader
          title="Horaires & Lieux"
          rightButtons={
            <TouchableOpacity onPress={handleNewPress}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      </Box>

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
      >
        <Box mt="m">
          <Box>
            <CustomText
              mb="s"
              textTransform="uppercase"
              variant="content"
              color="success"
            >
              Horaires d'ouverture
            </CustomText>
          </Box>

          <Box
            mt="m"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            {merchant?.openHours?.map((openHour, idx) => {
              const IS_LAST = idx === merchant?.openHours?.length - 1;
              const formattedDays = displayDays(openHour.days);
              const formattedTime = `${openHour.openAt} - ${openHour.closeAt}`;
              return (
                <SettingsButtonItem
                  key={idx + formattedDays}
                  title={formattedTime}
                  subText={formattedDays}
                  onPress={() => openHourModal(openHour)}
                  bottomLineWidth={IS_LAST ? 0 : LINE_THICKNESS}
                />
              );
            })}
          </Box>
        </Box>

        {/* <Box mt="m">
          <Box>
            <CustomText
              mb="s"
              textTransform="uppercase"
              variant="content"
              color="success"
            >
              HORAIRES AVEC EMPLACEMENTS
            </CustomText>
          </Box>
          <Box
            mt="m"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            {merchant.locationOpeningHours.map((locationOpenHour, idx) => {
              const { openHours, location } = locationOpenHour;
              const IS_LAST = idx === merchant?.locationOpeningHours?.length - 1;
              const formattedDays = displayDays(openHours.days);
              const formattedTime = `${openHours.openAt} - ${openHours.closeAt}`;
              return (
                <SettingsButtonItem
                  key={idx + formattedDays}
                  title={formattedTime}
                  subText={formattedDays}
                  onPress={() => {}}
                  tertiaryText={location.formattedAddress}
                  bottomLineWidth={IS_LAST ? 0 : LINE_THICKNESS}
                />
              );
            })}
          </Box>
        </Box> */}
        <Box mt="m">
          <Box>
            <CustomText
              mb="s"
              textTransform="uppercase"
              variant="content"
              color="success"
            >
              FERMETURES
            </CustomText>
          </Box>

          <Box
            mt="m"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            {merchant.closedDates.map((closedDate, idx) => {
              const IS_LAST = idx === merchant?.closedDates?.length - 1;
              const formattedStartDate = format(
                new Date(closedDate.startDate),
                "dd/MM/yyyy",
              );
              const formattedEndDate = format(new Date(closedDate.endDate), "dd/MM/yyyy");
              return (
                <SettingsButtonItem
                  key={idx + formattedStartDate}
                  title={`${formattedStartDate} - ${formattedEndDate}`}
                  subText={closedDate.reason}
                  onPress={() => openClosedDateModal(closedDate)}
                  bottomLineWidth={IS_LAST ? 0 : LINE_THICKNESS}
                />
              );
            })}
          </Box>
        </Box>
      </ScrollView>

      <ContentModal
        isVisible={isNewSelectModelOpen}
        onClose={closeModal}
        title={displayTitle()}
        styles={{
          maxWidth: 450,
          height: "auto",
        }}
      >
        {displayModalContent()}
      </ContentModal>
    </Box>
  );
};

export default HoursAndLocations;
