/* eslint-disable max-len */
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState, type Dispatch, type SetStateAction } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import PLUS from "../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../assets/icons/BASE/TRASH.svg";
import type {
  BookingFloorCapacityConfiguration,
  BookingFloorExperienceFragment,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ErrorMessage from "../../ErrorMessage";
import InlineSelectButton from "../../InlineSelectButton";
import NumberInputLine from "../../NumberInputLine";
import RadioSelectButton from "../../RadioSelectButton/index";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";
import Touchable from "../../Touchable";

interface ServiceTablePlacementProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const TABLE_DEACTIVATION_MESSAGE_WHEN_TABLE_SELECTION_IS_ENABLED_THEN_FLOOR_CAPACITY_LIMIT_IS_ENABLED =
  "Lorsque vous activez la capacité par emplacement vous ne pouvez plus proposer le choix de la table à vos clients. Si vous souhaitez proposer le choix de table, vous devez désactiver la capacité sur vos emplacements.";

const TABLE_DEACTIVATION_MESSAGE_WHEN_FLOOR_CAPACITY_LIMIT_IS_ENABLED_THEN_TABLE_SELECTION_IS_ENABLED =
  "Lorsque vous activez la capacité par emplacement vous ne pouvez plus proposer le choix de la table à vos clients. Si vous souhaitez proposer le choix de table, vous devez désactiver la capacité sur vos emplacements.";

const ServiceTablePlacement = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServiceTablePlacementProps) => {
  const navigation = useNavigation();
  const [initalBookingAvailability] = useState(bookingAvailability);
  const [tableDeactivationMessage, setTableDeactivationMessage] = useState("");

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateBookingFloorExperienceSettings = (
    key: keyof BookingFloorExperienceFragment,
    value: any,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      bookingFloorSettings: {
        ...prev.bookingFloorSettings,
        [key]: value,
      },
    }));
  };

  const handleResetFloorConfigurations = () => {
    const shouldResetFloorsConfiguration =
      !initalBookingAvailability?.totalPersonsAllowed?.isEnabled &&
      bookingAvailability?.totalPersonsAllowed?.isEnabled;

    if (!shouldResetFloorsConfiguration) return;

    const floorsConfiguration =
      bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration || [];

    const updatedBookingFloorConfiguration = floorsConfiguration.map(floor => ({
      ...floor,
      isCapacityLimitEnabled: false,
    }));

    updateBookingFloorExperienceSettings(
      "bookingFloorsCapacityConfiguration",
      updatedBookingFloorConfiguration,
    );
  };

  useEffect(() => {
    handleResetFloorConfigurations();
  }, [bookingAvailability?.totalPersonsAllowed?.isEnabled]);

  const handleCheckFloorsConfigurationAreCorrect = () => {
    const floorsConfiguration =
      bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration || [];

    const isServiceLimitEnabled = bookingAvailability?.totalPersonsAllowed?.isEnabled;
    const serviceLimit = bookingAvailability?.totalPersonsAllowed?.value || 0;

    let accumulatedFloorConfigurationLimit = 0;
    let hasServiceLimtBeenReachedByFloorConfigurations = false;

    const updatedFloorsConfiguration = floorsConfiguration.map(floor => {
      if (floor.isCapacityLimitEnabled) {
        accumulatedFloorConfigurationLimit += floor.capacity;
      }

      if (isServiceLimitEnabled && accumulatedFloorConfigurationLimit > serviceLimit) {
        hasServiceLimtBeenReachedByFloorConfigurations = true;
      }

      if (hasServiceLimtBeenReachedByFloorConfigurations) {
        return {
          ...floor,
          capacity: 0,
        };
      }

      return floor;
    });

    if (hasServiceLimtBeenReachedByFloorConfigurations) {
      updateBookingFloorExperienceSettings(
        "bookingFloorsCapacityConfiguration",
        updatedFloorsConfiguration,
      );
    }
  };

  useEffect(() => {
    handleCheckFloorsConfigurationAreCorrect();
  }, [
    bookingAvailability.totalPersonsAllowed,
    bookingAvailability.bookingFloorSettings.bookingFloorsCapacityConfiguration,
  ]);

  const handleGoToBookingFloors = () => {
    navigation.navigate("BOOKING_SERVICES_FLOOR_SELECT", {
      selectedFloors: bookingAvailability.bookingFloorSettings.bookingFloors,
      onCompleteFlooInfoSubmit: floors => {
        const floorIdsAndNames = floors.map(floor => ({
          _id: floor._id,
          name: floor.name,
        }));

        updateBookingFloorExperienceSettings("bookingFloors", floorIdsAndNames);

        floors?.map(floor =>
          handleBookingFloorCapacityLimitChange(
            floor.name,
            floor._id,
            floor.totalBookingCapacity,
          ),
        );
      },
    });
  };

  const handleRemoveBookingFLoor = (floorId: string) => {
    const { bookingFloors } = bookingAvailability.bookingFloorSettings;

    const updatedBookingFloors = bookingFloors.filter(floor => floor._id !== floorId);

    updateBookingFloorExperienceSettings("bookingFloors", updatedBookingFloors);
  };

  const handleToggleBookingFloorActive = (isActive: boolean) => {
    updateBookingFloorExperienceSettings("isEnabled", isActive);

    if (!isActive) {
      updateBookingFloorExperienceSettings("isTableSelectionEnabled", false);
    }
  };

  const handleToggleBookingFloorCapacityEnabled = (
    floorName: string,
    floorId: string,
    isEnabled: boolean,
  ) => {
    const bookingFloorConfiguration =
      bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration || [];

    const doesFloorConfigurationExist = bookingFloorConfiguration.some(
      floor => floor.floorId === floorId,
    );

    if (!doesFloorConfigurationExist) {
      const updatedBookingFloorConfiguration = [
        ...bookingFloorConfiguration,
        {
          floorId,
          floorName,
          isCapacityLimitEnabled: isEnabled,
          capacity: 0,
        },
      ];

      checkIfShouldShowTableDeactivationMessage(updatedBookingFloorConfiguration);

      updateBookingFloorExperienceSettings(
        "bookingFloorsCapacityConfiguration",
        updatedBookingFloorConfiguration,
      );

      return;
    }

    const updatedBookingFloorConfiguration = bookingFloorConfiguration.map(floor => {
      if (floor.floorId === floorId) {
        return {
          ...floor,
          isCapacityLimitEnabled: isEnabled,
        };
      }

      return floor;
    });

    checkIfShouldShowTableDeactivationMessage(updatedBookingFloorConfiguration);

    updateBookingFloorExperienceSettings(
      "bookingFloorsCapacityConfiguration",
      updatedBookingFloorConfiguration,
    );
  };

  const handleBookingFloorCapacityLimitChange = (
    floorName,
    floorId: string,
    limit: number,
  ) => {
    const bookingFloorConfiguration =
      bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration || [];

    const isServiceLimitEnabled = bookingAvailability?.totalPersonsAllowed?.isEnabled;
    const serviceLimit = bookingAvailability?.totalPersonsAllowed?.value || 0;

    const remainingFloorsConfigurationLimitOfServiceLimit =
      serviceLimit -
      bookingFloorConfiguration
        .filter(f => f.floorId !== floorId)
        .reduce((acc, floor) => acc + floor.capacity || 0, 0);

    const capacityLimit = isServiceLimitEnabled
      ? Math.min(limit, serviceLimit, remainingFloorsConfigurationLimitOfServiceLimit)
      : limit;

    const capacity = Math.abs(capacityLimit);

    const doesFloorConfigurationExist = bookingFloorConfiguration.some(
      floor => floor.floorId === floorId,
    );

    if (!doesFloorConfigurationExist) {
      const updatedBookingFloorConfiguration = [
        ...bookingFloorConfiguration,
        {
          floorName,
          floorId,
          isCapacityLimitEnabled: false,
          capacity,
        },
      ];

      checkIfShouldShowTableDeactivationMessage(updatedBookingFloorConfiguration);

      updateBookingFloorExperienceSettings(
        "bookingFloorsCapacityConfiguration",
        updatedBookingFloorConfiguration,
      );

      return;
    }

    const updatedBookingFloorConfiguration = bookingFloorConfiguration.map(floor => {
      if (floor.floorId === floorId) {
        return {
          ...floor,
          capacity,
        };
      }

      return floor;
    });

    checkIfShouldShowTableDeactivationMessage(updatedBookingFloorConfiguration);

    updateBookingFloorExperienceSettings(
      "bookingFloorsCapacityConfiguration",
      updatedBookingFloorConfiguration,
    );
  };

  const checkIfShouldShowTableDeactivationMessage = (
    floorsConfiguration: BookingFloorCapacityConfiguration[],
  ) => {
    const { isTableSelectionEnabled } = bookingAvailability.bookingFloorSettings;

    const isFloorConfigurationEnabled = floorsConfiguration.some(
      floor => floor.isCapacityLimitEnabled,
    );

    if (isTableSelectionEnabled && isFloorConfigurationEnabled) {
      setTableDeactivationMessage(
        TABLE_DEACTIVATION_MESSAGE_WHEN_TABLE_SELECTION_IS_ENABLED_THEN_FLOOR_CAPACITY_LIMIT_IS_ENABLED,
      );
      updateBookingFloorExperienceSettings("isTableSelectionEnabled", false);
    } else {
      setTableDeactivationMessage("");
    }
  };

  const handleTableSelectionEnabled = (isEnabled: boolean) => {
    updateBookingFloorExperienceSettings("isTableSelectionEnabled", isEnabled);

    if (isEnabled) {
      const isFloorConfigurationEnabled =
        bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration?.some(
          floor => floor.isCapacityLimitEnabled,
        );

      if (isFloorConfigurationEnabled) {
        setTableDeactivationMessage(
          TABLE_DEACTIVATION_MESSAGE_WHEN_FLOOR_CAPACITY_LIMIT_IS_ENABLED_THEN_TABLE_SELECTION_IS_ENABLED,
        );

        const updatedBookingFloorConfiguration =
          bookingAvailability?.bookingFloorSettings?.bookingFloorsCapacityConfiguration?.map(
            floor => ({
              ...floor,
              isCapacityLimitEnabled: false,
            }),
          );

        updateBookingFloorExperienceSettings(
          "bookingFloorsCapacityConfiguration",
          updatedBookingFloorConfiguration,
        );
      }
    } else {
      setTableDeactivationMessage("");
    }
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          PLACEMENT
        </CustomText>
      </Box>
      <CustomText mt="s" variant="text" color="lightGrey">
        Si vous avec activé la limitation de place par LA GESTION DES TABLES, vous ne
        pouvez pas activer le placement en salle.
      </CustomText>

      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          text="Activer le choix en salle"
          value={bookingAvailability.bookingFloorSettings.isEnabled}
          onValueChange={v => handleToggleBookingFloorActive(v)}
        />
        <CustomText variant="text" color="lightGrey">
          Vos clients pourront sélectionner la salle dans laquelle ils souhaitent être
          installés
        </CustomText>
      </Box>

      {bookingAvailability.bookingFloorSettings.isEnabled && (
        <Box>
          {tableDeactivationMessage && (
            <Box>
              <ErrorMessage isWarning message={tableDeactivationMessage} />
            </Box>
          )}

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Activer le choix de la table dans la salle"
              value={!!bookingAvailability.bookingFloorSettings.isTableSelectionEnabled}
              onValueChange={handleTableSelectionEnabled}
            />
            <CustomText variant="text" color="lightGrey">
              Vos clients pourront sélectionner la table dans la salle dans laquelle ils
              souhaitent être installés
            </CustomText>
          </Box>

          <Box mt="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("bookingFloorSettings.minPersons")}
              textVariant="content"
              text="A partir de combien de couverts"
              placeHolder="A partir de combien de couverts"
              value={bookingAvailability.bookingFloorSettings.minPersons}
              onValueChange={v =>
                updateBookingFloorExperienceSettings(
                  "minPersons",
                  getSafeNumberFromInput(v),
                )
              }
            />
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomText
              mb="s"
              variant="content"
              color={
                doesInputHaveError("bookingFloorSettings.bookingFloors")
                  ? "danger"
                  : "primaryTextColor"
              }
            >
              Salle(s) disponible(s)
            </CustomText>
            <FlatList
              scrollEnabled={false}
              data={bookingAvailability.bookingFloorSettings.bookingFloors}
              style={{ width: "100%" }}
              ListHeaderComponent={
                <TouchableOpacity onPress={handleGoToBookingFloors}>
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mr="s"
                    marginVertical="s"
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title="Ajouter une salle"
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <PLUS fill={PALETTE.green} width={15} height={15} />
                  </Box>
                </TouchableOpacity>
              }
              renderItem={({ item, index }) => {
                const isLast =
                  index ===
                  bookingAvailability.bookingFloorSettings.bookingFloors.length - 1;

                const isFloorCapacityLimitEnabled =
                  !!bookingAvailability.bookingFloorSettings.bookingFloorsCapacityConfiguration?.find(
                    floor => floor.floorId === item._id,
                  )?.isCapacityLimitEnabled;

                const floorCapacity =
                  bookingAvailability.bookingFloorSettings.bookingFloorsCapacityConfiguration?.find(
                    floor => floor.floorId === item._id,
                  )?.capacity || 0;

                return (
                  <Box
                    mt="m"
                    pb="s"
                    borderBottomColor="disabled"
                    borderBottomWidth={isLast ? 0 : LINE_THICKNESS}
                  >
                    <Touchable
                      onPress={() => handleRemoveBookingFLoor(item._id)}
                      style={{
                        flex: 1,
                      }}
                    >
                      <Box
                        // flex={0.95}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box flex={0.95}>
                          <InlineSelectButton
                            title={item.name}
                            options={[]}
                            selectedOptionKeys={[]}
                            isMultiSelect={false}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onPress={() => {}}
                          />
                        </Box>

                        <TRASH fill={PALETTE.red} width={15} height={15} />
                      </Box>
                    </Touchable>

                    <Box mt="xs">
                      <ToggleInputLine
                        text="Limiter le nombre de couverts disponibles pour cet emplacement"
                        value={isFloorCapacityLimitEnabled}
                        textVariant="content"
                        onValueChange={v =>
                          handleToggleBookingFloorCapacityEnabled(item.name, item._id, v)
                        }
                      />
                    </Box>

                    {isFloorCapacityLimitEnabled && (
                      <Box mt="xs">
                        <Box
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CustomText variant="content" color="primaryTextColor">
                            Nombre de couverts
                          </CustomText>
                          <Box flexDirection="row" alignItems="center">
                            <CustomTextInput
                              placeHolder="0"
                              hideFloatingLabel
                              keyboardType="number-pad"
                              value={floorCapacity?.toString()}
                              onChangeText={v => {
                                handleBookingFloorCapacityLimitChange(
                                  item.name,
                                  item._id,
                                  getSafeNumberFromInput(v),
                                );
                              }}
                              inputStyles={{ textAlign: "center" }}
                            />
                            {bookingAvailability?.totalPersonsAllowed?.isEnabled &&
                              bookingAvailability?.totalPersonsAllowed?.value > 0 && (
                                <Box ml="s">
                                  <CustomTextInput
                                    placeHolder="50"
                                    hideFloatingLabel
                                    disabled
                                    initialValue={bookingAvailability.totalPersonsAllowed.value?.toString()}
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onChangeText={() => {}}
                                    inputStyles={{ textAlign: "center" }}
                                  />
                                </Box>
                              )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              }}
              keyExtractor={(item, index) => `draggable-item-${index}`}
            />
          </Box>
          <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
            <Touchable
              onPress={() =>
                updateBookingFloorExperienceSettings(
                  "isRequired",
                  !bookingAvailability.bookingFloorSettings.isRequired,
                )
              }
            >
              <Box
                mt="m"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  Rendre obligatoire le placement
                </CustomText>
                <RadioSelectButton
                  isSelected={bookingAvailability.bookingFloorSettings.isRequired}
                />
              </Box>
            </Touchable>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ServiceTablePlacement;
