/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import ModuleFormInputLine from "../../../../../components/ModuleFormInputLine";
import ScreenHeader from "../../../../../components/ScreenHeader";

interface DeliveryFormSettingsProps {
  goBack?: () => void;
}

const DeliveryFormSettings = ({ goBack }: DeliveryFormSettingsProps) => {
  const navigation = useNavigation();

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Formulaire de commande de livraison"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <Box marginVertical="s">
        <CustomText variant="content" color="lightGrey">
          Veuillez choisir les champs que vous souhaitez utiliser lors de la prise de
          commande de livraison.
        </CustomText>
      </Box>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="s">
          <ModuleFormInputLine
            text="Civilité"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Nom"
            isRequired
            isOptional={false}
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Prénom"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Téléphone"
            isRequired
            isOptional={false}
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Email"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Addresse"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Note sur le client"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="VIP"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
        <Box mt="s">
          <ModuleFormInputLine
            text="Note sur la réservation"
            isRequired
            isOptional
            isEnabled={false}
            onValueChange={() => {}}
          />
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={() => {}}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DeliveryFormSettings;
