import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import PLUS from "../../../../../assets/icons/BASE/PLUS.svg";
import Box from "../../../../components/Base/Box";
import BottomButton from "../../../../components/BottomButton";
import ColorPickerModal from "../../../../components/ColorPickerModal";
import InputSectionTitle from "../../../../components/InputSectionTitle/index";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import ColorListSelect from "../../../../components/Settings/ColorListSelect";
import { CustomTextInput } from "../../../../components/TextInput/index";
import Touchable from "../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { BookingSettingsFragment } from "../../../../graphql/generated/schema";
import {
  useGetBookingSettingsLazyQuery,
  useUpdateBookingSettingsMutation,
} from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import { removeTypeNames } from "../../../../utils/common";

interface BookingClientSettingsProps {
  goBack?: () => void;
}

const BookingClientSettings = ({ goBack }: BookingClientSettingsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [loading, setLoading] = useState(true);
  const [bookingSettings, setBookingSettings] = useState<BookingSettingsFragment | null>(
    null,
  );
  const [isColorPickerModalOpen, setIsColorPickerModalOpen] = useState(false)

  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings();

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  const handleUpdateBookingSettings = async () => {
    try {
      const updates = removeTypeNames(bookingSettings);
      delete updates._id;

      await updateBookingSettings({
        variables: {
          updates,
        },
      });

      handleGoBack();
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_BOOKING_SETTINGS",
            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
    }
  };

  const updateBookingSettingsData = (
    key: keyof BookingSettingsFragment,
    value: BookingSettingsFragment[keyof BookingSettingsFragment],
  ) => {
    setBookingSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Module de réservation"
        // eslint-disable-next-line max-len
        description="Vous pouvez personnaliser l’ensemble des textes que vos clients verront sur votre module de réservation Widget."
        hasBackButton
        onBackPress={handleGoBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 150,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputSectionTitle text="COULEUR DU WIDGET" />
          <Touchable
            onPress={() => setIsColorPickerModalOpen(true)}
          >
            <Box p="s" borderRadius="button" borderColor="success" borderWidth={1}>
              <PLUS width={14} height={14} fill={PALETTE.green} />
            </Box>
          </Touchable>
        </Box>

        <Box marginVertical="s">
          <ColorListSelect
            selectedColor={bookingSettings?.widgetColor || ""}
            onPress={color => updateBookingSettingsData("widgetColor", color)}
          />
        </Box>

        <Box mt="m">
          <InputSectionTitle text="COMMENTAIRE EN HAUT DE LA PAGE" />
        </Box>

        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Message"
            initialValue={bookingSettings?.message}
            onChangeText={t => updateBookingSettingsData("message", t)}
            multiLine
          />
        </Box>
        <Box mt="m">
          <InputSectionTitle text="INFORMATION COMPLÉMENTAIRE" />
        </Box>

        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Message"
            initialValue={bookingSettings?.complimentaryInformation}
            onChangeText={t => updateBookingSettingsData("complimentaryInformation", t)}
            multiLine
          />
        </Box>
        <Box mt="m">
          <InputSectionTitle text="BAS DE PAGE" />
        </Box>

        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Message"
            initialValue={bookingSettings?.footerInformation}
            onChangeText={t => updateBookingSettingsData("footerInformation", t)}
            multiLine
          />
        </Box>
      </ScrollView>

      <BottomButton onPress={handleUpdateBookingSettings} title="Enregistrer" />
      <ColorPickerModal
        isOpen={isColorPickerModalOpen}
        onClose={() => setIsColorPickerModalOpen(false)}
        initialColor={bookingSettings?.widgetColor}
        onSubmit={(color) => {
          updateBookingSettingsData("widgetColor", color)
          setIsColorPickerModalOpen(false)
        }}
      />
    </Box>
  );
};

export default BookingClientSettings;
