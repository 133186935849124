import { useNavigation, useRoute } from "@react-navigation/native";
import { format, isAfter, isSameDay, startOfDay } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import CustomCalendar, {
  CALENDAR_VIEW,
} from "../../../../../components/CustomCalendar/index";
import DateInput from "../../../../../components/DateInput/index";
import Loader from "../../../../../components/Loader";
import type { Tab } from "../../../../../components/OutlineTabs/index";
import OutlineTabs from "../../../../../components/OutlineTabs/index";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { Closure, CreateClosure } from "../../../../../graphql/generated/schema";
import {
  Closure_Types,
  useCreateClosureMutation,
  useGetClosureLazyQuery,
  useUpdateClosureMutation,
} from "../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../theme/Palette";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";

interface TakeAwayClosureDetailsProps {
  newValue?: boolean;
  goBack?: () => void;
  selectedClosureId?: string;
}
const TABS: Tab[] = [
  {
    title: "Période",
    key: "PERIOD",
  },
  {
    title: "Jour(s) spécifique(s)",
    key: "SPECIFIC_DAYS",
  },
];

const DEFAULT_CLOSURE: CreateClosure = {
  comment: "",
  endDate: new Date(),
  serviceId: "",
  slotId: "",
  startDate: new Date(),
  title: "",
  type: Closure_Types.OnlineSales,
};

const schema = yup.object().shape({
  title: yup.string().required("Le nom est obligatoire"),
  comment: yup.string().required("Le commentaire est obligatoire"),
});

const TakeAwayClosureDetails = ({
  newValue,
  goBack,
  selectedClosureId,
}: TakeAwayClosureDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const isNew = newValue || params?.isNew;
  const id = selectedClosureId || params?.id;

  const [loading, setLoading] = useState(true);
  const [closure, setClosure] = useState<CreateClosure>(DEFAULT_CLOSURE);
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const [multCloseDays, setMultCloseDays] = useState<Date[]>(closure.dates || []);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [createClosure] = useCreateClosureMutation();
  const [getClosure] = useGetClosureLazyQuery();
  const [updateClosure] = useUpdateClosureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const validateForm = async (
    consolidatedService: CreateClosure,
    displayErrors = true,
  ) => {
    try {
      await schema.validate(consolidatedService, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err) || []);

      if (displayErrors)
        infoAlert.openAlert("Erreur", formaYupErrors(err) || [], "error");
    }
    return false;
  };

  const handleGetClosure = async () => {
    try {
      if (id) {
        const result = await getClosure({
          variables: {
            closureId: id,
          },
        });
        if (result.data) {
          setClosure(result.data.getClosure);
          setMultCloseDays(result.data.getClosure.dates || []);
          setSelectedTab(result.data.getClosure.isPeriod ? "PERIOD" : "SPECIFIC_DAYS");
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClosure();
  }, []);

  const handleSubmit = async () => {
    try {
      const isFormValid = await validateForm(closure);

      if (!isFormValid) return;

      if (isNew) {
        const startDate = startOfDay(new Date(closure.startDate));
        const endDate = startOfDay(new Date(closure.endDate));

        const dates =
          multCloseDays?.map(date => startOfDay(new Date(date)).toDateString()) || [];

        const isPeriod = selectedTab === "PERIOD";

        await createClosure({
          variables: {
            closure: {
              ...closure,
              startDate,
              endDate,
              dates,
              isPeriod,
            },
          },
        });
      } else {
        const isPeriod = selectedTab === "PERIOD";
        const dates =
          multCloseDays?.map(date => startOfDay(new Date(date)).toDateString()) || [];

        const startDate = startOfDay(new Date(closure.startDate)).toDateString();
        const endDate = startOfDay(new Date(closure.endDate)).toDateString();

        let updates = {
          ...closure,
          dates,
          isPeriod,
          startDate,
          endDate,
        };

        updates = removeTypeNames(updates);
        delete updates._id;
        await updateClosure({
          variables: {
            closureId: id,
            closure: updates,
          },
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_CREATE_CLOSURE",
            message: "Une erreur est survenue lors de la création de la fermeture",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateClosureData = (key: keyof Closure, value: string | Date) => {
    setClosure(prev => ({ ...prev, [key]: value }));
  };

  const updateClosureDate = (type: "startDate" | "endDate", date: Date) => {
    updateClosureData(type, date);
    if (type === "startDate" && isAfter(date, closure.endDate)) {
      updateClosureData("endDate", date);
    }
  };

  const displayPeriod = () => {
    return (
      <Box>
        <Box marginVertical="s">
          <DateInput
            minDate={new Date()}
            placeHolder="Début du période"
            onChange={date => updateClosureDate("startDate", date)}
            date={new Date(closure.startDate)}
          />
        </Box>
        <Box marginVertical="s">
          <DateInput
            minDate={new Date(closure.startDate) || new Date()}
            placeHolder="Fin du période"
            onChange={date => updateClosureDate("endDate", date)}
            date={new Date(closure.endDate)}
          />
        </Box>
      </Box>
    );
  };

  const getMarkedDates = () => {
    const markedDates: Record<string, unknown> = {};
    multCloseDays.forEach(day => {
      markedDates[format(new Date(day), "yyyy-MM-dd")] = {
        selected: true,
        // marked: true,
        selectedColor: PALETTE.green,
      };
    });
    return markedDates;
  };

  const onDateSelected = (date: Date) => {
    setMultCloseDays(prev => {
      const index = prev.findIndex(d => isSameDay(new Date(d), date));
      if (index === -1) {
        return [...prev, date];
      } else {
        return prev.filter(d => !isSameDay(new Date(d), date));
      }
    });
  };

  const displayMultDaySelect = () => {
    return (
      <Box>
        <CustomCalendar
          markingType="simple"
          minDate={new Date()}
          calendarView={CALENDAR_VIEW.MONTH}
          onDateChange={onDateSelected}
          markedDates={getMarkedDates()}
        />
      </Box>
    );
  };

  const displayContent = () => {
    switch (selectedTab) {
      case "PERIOD":
        return displayPeriod();
      case "SPECIFIC_DAYS":
        return displayMultDaySelect();
      default:
        return null;
    }
  };

  const showClosureRecap = () => {
    return (
      <Box>
        <Box mt="s">
          <CustomText variant="content" color="primaryTextColor">
            Période de fermeture
          </CustomText>
        </Box>
        <Box ml="m">
          {selectedTab === "PERIOD" ? (
            <Box>
              <Box mt="s">
                <CustomText variant="text" color="lightGrey">
                  du {format(new Date(closure.startDate), "E dd MMMM yyyy")}
                </CustomText>
              </Box>
              <Box mt="s">
                <CustomText variant="text" color="lightGrey">
                  au {format(new Date(closure.endDate), "E dd MMMM yyyy")}
                </CustomText>
              </Box>
            </Box>
          ) : (
            <Box>
              {multCloseDays.map((date, idx) => (
                <Box key={idx} marginVertical="s">
                  <CustomText variant="text" color="lightGrey">
                    {format(new Date(date), "E dd MMMM yyyy")}
                  </CustomText>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Fermetures exceptionnelles"
        hasBackButton
        onBackPress={handleGoBack}
      />
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="m">
          <CustomText variant="content" color="lightGrey">
            Veuillez choisir une ou plusieurs disponibilités afin d’indiquer la période de
            fermeture pour les commandes à retrait. Vous pouvez également personnaliser
            une période.
          </CustomText>
        </Box>

        <Box mt="m">
          <CustomTextInput
            hasErrors={doesInputHaveError("title")}
            placeHolder="Nom de la fermeture"
            initialValue={closure.title}
            onChangeText={t => updateClosureData("title", t)}
            isRequired
          />
        </Box>

        <Box mt="m">
          <CustomTextInput
            hasErrors={doesInputHaveError("comment")}
            placeHolder="Commentaire en haut de la page"
            isRequired
            multiLine
            initialValue={closure.comment}
            onChangeText={t => updateClosureData("comment", t)}
          />
        </Box>

        <Box mt="m">
          <OutlineTabs
            tabs={TABS}
            onTabPress={setSelectedTab}
            selectedTab={selectedTab}
          />
        </Box>

        <Box mt="m">{displayContent()}</Box>

        <Box mt="m">{showClosureRecap()}</Box>
      </ScrollView>
      <BottomButton onPress={handleSubmit} title={isNew ? "Ajouter" : "Modifier"} />
    </Box>
  );
};

export default TakeAwayClosureDetails;
