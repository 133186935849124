import React from "react";
import { TouchableOpacity } from "react-native";

import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface SettingsButtonItemProps {
  title: string;
  subText?: string;
  tertiaryText?: string;
  icon?: JSX.Element;
  onPress: () => void;
  bottomLineWidth: number;
}

const SettingsButtonItem = ({
  title,
  subText,
  tertiaryText,
  onPress,
  bottomLineWidth,
  icon,
}: SettingsButtonItemProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        p="s"
        paddingVertical="m"
        borderBottomColor="lightGrey"
        borderBottomWidth={bottomLineWidth}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex={0.9} flexDirection="row" alignItems="center">
          <>
            <Box ml="s">
              <CustomText variant="paragraph" color="primaryTextColor">
                {title}
              </CustomText>

              {subText && (
                <Box mt="xs" flexDirection="row" alignItems="center">
                  {icon ? (
                    icon
                  ) : (
                    <RIGHT_ARROW height={15} width={15} fill={PALETTE.green} />
                  )}

                  <CustomText ml="s" variant="text" color="primaryTextColor">
                    {subText}
                  </CustomText>
                </Box>
              )}

              {tertiaryText && (
                <Box mt="s" flexDirection="row" alignItems="center">
                  <RIGHT_ARROW height={15} width={15} fill={PALETTE.green} />
                  <CustomText ml="s" variant="text" color="primaryTextColor">
                    {tertiaryText}
                  </CustomText>
                </Box>
              )}
            </Box>
          </>
        </Box>
        <RIGHT_ARROW height={15} width={15} fill={PALETTE.green} />
      </Box>
    </TouchableOpacity>
  );
};

export default SettingsButtonItem;
