import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { AddMerchantDiscountsInput } from "../../../../../graphql/generated/schema";
import {
  GetMerchantDiscountsDocument,
  useCreateMerchantDiscountMutation,
  useGetMerchantDiscountLazyQuery,
  useUpdateMerchantDiscountMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";

interface MerchantDiscountListDetailsProps {
  id?: string;
  newValue?: boolean;
  goBack?: () => void;
}

const DEFAULT_MERCHANT_DISCOUNT: AddMerchantDiscountsInput = {
  isPercentage: false,
  value: 0,
  name: "",
};

const schema = yup.object().shape({
  name: yup.string().required(),
  value: yup.number().min(1).required(),
  isPercentage: yup.boolean().required(),
});

const MerchantDiscountListDetails = ({
  id: discountId,
  newValue,
  goBack,
}: MerchantDiscountListDetailsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const { params } = useRoute();

  const id = discountId || params?.id;
  const isNew = newValue || params?.isNew;

  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState(DEFAULT_MERCHANT_DISCOUNT);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getDiscount] = useGetMerchantDiscountLazyQuery();
  const [updateDiscount] = useUpdateMerchantDiscountMutation();
  const [createDiscount] = useCreateMerchantDiscountMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetDiscount = async () => {
    try {
      if (id) {
        const { data } = await getDiscount({
          variables: {
            id,
          },
        });

        if (data?.getMerchantDiscount) {
          setDiscount(data.getMerchantDiscount);
        }
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDiscount();
  }, []);

  const updateDiscountData = (
    key: keyof AddMerchantDiscountsInput,
    value: string | number | boolean,
  ) => {
    setDiscount(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = async () => {
    try {
      await schema.validate(discount, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      if (isNew) {
        await createDiscount({
          variables: {
            discount,
          },
          refetchQueries: [
            {
              query: GetMerchantDiscountsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(discount);
        delete updates._id;
        await updateDiscount({
          variables: {
            discount: updates,
            id,
          },
          refetchQueries: [
            {
              query: GetMerchantDiscountsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_DISCOUNT",
            path: "ERROR_UPDATE_DISCOUNT",
            message: "Une erreur est survenue lors de la mise à jour de la remise",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const displayTitle = isNew ? "Nouvelle remise" : "Modifier la remise";

  if (loading) {
    return <Loader />;
  }

  if (!discount) {
    return (
      <Box>
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données de la remise" />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Nom"
          isRequired
          hasErrors={doesInputHaveError("name")}
          initialValue={discount.name}
          onChangeText={t => updateDiscountData("name", t)}
        />
      </Box>

      <Box marginVertical="s">
        <ToggleInputLine
          text="Pourcentage"
          value={discount.isPercentage}
          onValueChange={v => updateDiscountData("isPercentage", v)}
        />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Valeur"
          isRequired
          hasErrors={doesInputHaveError("value")}
          initialValue={discount.value.toString()}
          onChangeText={t => updateDiscountData("value", +t)}
        />
      </Box>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default MerchantDiscountListDetails;
