import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import NumberInputLine from "../../../../../components/NumberInputLine";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { OnlineSalesServiceSlot } from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, getSafeNumberFromInput } from "../../../../../utils/common";

const TakeAwayServiceSlotsListDetails = () => {
  useResizeMode();
  const navigation = useNavigation();
  const { params } = useRoute();
  const { slot, onUpdateSlotSubmit, totalService, service } = params;
  const [updatedSlot, setUpdatedSlot] = useState<OnlineSalesServiceSlot>(slot);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const validateForm = async (slotToValidate: OnlineSalesServiceSlot) => {
    try {
      const serviceMaxSlot = service?.maxOrdersAllowedForSlot.value;
      const serviceMinPrice = service?.minPrice?.value;
      const serviceMaxPrice = service?.maxPrice?.value;
      const serviceMinArticles = service?.minArticles?.value;
      const serviceMaxArticles = service?.maxArticles?.value;

      const schema = yup.object().shape({
        _id: yup.string().required("Le ID est obligatoire"),
        day: yup
          .number()
          .nullable()
          .when("isRecurring", {
            is: true,
            then: yup.number().required("La couleur est obligatoire"),
          }),

        date: yup
          .date()
          .nullable()
          .when("isRecurring", {
            is: false,
            then: yup.date().required("La date est obligatoire"),
          }),

        totalOrdersAllowed: yup
          .number()
          .min(1, "`Le total des commandes par  créneau doit etre supérieur à 1")
          .max(
            serviceMaxSlot,
            `Le total des commandes par créneau ne peut pas supérieur à celui du service (${serviceMaxSlot})`,
          ),

        startTime: yup.string().required("L'heure de début est obligatoire"),
        endTime: yup.string().required("L'heure de fin est obligatoire"),
        isRecurring: yup.boolean().required("Le type de slot est obligatoire"),
        minPrice: yup.object().shape({
          isEnabled: yup.boolean().required("Le statut est obligatoire"),
          value: yup.number().when("isEnabled", {
            is: true,
            then: yup
              .number()
              .min(serviceMinPrice, `Le prix min doit etre au moin à ${serviceMinPrice}`),
          }),
        }),

        maxPrice: yup.object().shape({
          isEnabled: yup.boolean().required("Le statut est obligatoire"),
          value: yup.number().when("isEnabled", {
            is: true,
            then: yup
              .number()
              .min(1, "Le prix max doit etre au moins à 1")
              .max(
                serviceMaxPrice,
                `Le prix max du créneau ne peut pas supérieur à celui du service (${serviceMaxPrice})`,
              ),
          }),
        }),
        minArticles: yup.object().shape({
          isEnabled: yup.boolean().required("Le statut est obligatoire"),
          value: yup.number().when("isEnabled", {
            is: true,
            then: yup
              .number()
              .min(
                serviceMinArticles,
                `Le min d'articles doit etre au moins à ${serviceMinArticles}`,
              ),
          }),
        }),
        maxArticles: yup.object().shape({
          isEnabled: yup.boolean().required("Le statut est obligatoire"),
          value: yup.number().when("isEnabled", {
            is: true,
            then: yup
              .number()
              .min(1, "Le max d'articles doit etre au moins à 1")
              .max(
                serviceMaxArticles,
                `Le max articles du créneau ne peut pas supérieur à celui du service (${serviceMaxArticles})`,
              ),
          }),
        }),
        automaticClickConfirmationSettings: yup.object().shape({
          acceptAutomatically: yup
            .boolean()
            .required("Le statut pour l'acceptation auto est obligatoire"),
          acceptAutomaticallyWithLimits: yup
            .boolean()
            .required("Le statut pour l'acceptation auto est obligatoire"),
          minArticlesPerOrder: yup.number().when("acceptAutomaticallyWithLimits", {
            is: true,
            then: yup
              .number()
              .min(
                1,
                "Le min d'articles pour la confirmation auto doit etre au moins à 1",
              ),
          }),
          minPricePerOrder: yup.number().when("acceptAutomaticallyWithLimits", {
            is: true,
            then: yup
              .number()
              .min(1, "Le min prix pour la confirmation auto doit etre au moins à 1"),
          }),
        }),
        waitingListSettings: yup.object().shape({
          isEnabled: yup.boolean().required("Le statut est obligatoire"),
          totalOrdersAllowed: yup.number().when("isEnabled", {
            is: true,
            then: yup
              .number()
              .min(1, "Le total de la liste d'attente doit etre au moins à 1"),
          }),
        }),
      });
      await schema.validate(slotToValidate, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err) || []);

      infoAlert.openAlert("Erreur", formaYupErrors(err) || [], "error");
    }
    return false;
  };

  const updateSlotData = (key: keyof OnlineSalesServiceSlot, value: boolean | number) => {
    setUpdatedSlot(prev => ({ ...prev, [key]: value }));
  };

  const updateServiceMinMaxData = (
    key: "minArticles" | "maxArticles" | "minPrice" | "maxPrice",
    dataKey: "value" | "isEnabled",
    value: number | boolean,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [dataKey]: value,
        isEnabled: true,
      },
    }));
  };

  const updateSlotWaitingList = (
    key: keyof OnlineSalesServiceSlot["waitingListSettings"],
    value: boolean | number,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      waitingListSettings: { ...prev.waitingListSettings, [key]: value },
    }));
  };
  const updateSlotAutomaticConfirmation = (
    key: keyof OnlineSalesServiceSlot["automaticClickConfirmationSettings"],
    value: boolean | number,
  ) => {
    setUpdatedSlot(prev => ({
      ...prev,
      automaticClickConfirmationSettings: {
        ...prev.automaticClickConfirmationSettings,
        [key]: value,
      },
    }));
  };

  const onSubmit = async () => {
    try {
      const isFormValid = await validateForm(updatedSlot);

      if (!isFormValid) return;

      onUpdateSlotSubmit(slot._id, updatedSlot);

      navigation.goBack();
    } catch (_err) {}
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Personnaliser le créneau"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
            <ToggleInputLine
              textVariant="content"
              value={updatedSlot.isEnabled}
              onValueChange={value => updateSlotData("isEnabled", value)}
              text="Activer le créneau"
            />
          </Box>

          {service?.maxOrdersAllowedForSlot?.isEnabled && (
            <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
              <NumberInputLine
                hasErrors={doesInputHaveError("totalOrdersAllowed")}
                textVariant="content"
                placeHolder="0"
                text="Commandes autorisées"
                value={updatedSlot.totalOrdersAllowed}
                onValueChange={v =>
                  updateSlotData("totalOrdersAllowed", getSafeNumberFromInput(v))
                }
              />

              <CustomText variant="text" color="lightGrey">
                Maximum de commandes autorisés pour ce créneau
              </CustomText>
            </Box>
          )}

          {service?.minArticles?.isEnabled && (
            <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
              <NumberInputLine
                textVariant="content"
                text="Articles minimum"
                placeHolder="Articles minimum"
                hasErrors={doesInputHaveError("minArticles.value")}
                value={updatedSlot.minArticles.value}
                onValueChange={v =>
                  updateServiceMinMaxData(
                    "minArticles",
                    "value",
                    getSafeNumberFromInput(v),
                  )
                }
              />
              <CustomText variant="text" color="lightGrey">
                Entrez le nombre minimum d’articles pour effectuer une commande. Le nombre
                doit être supérieur à X.{" "}
              </CustomText>
            </Box>
          )}

          {service?.minPrice?.isEnabled && (
            <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
              <NumberInputLine
                textVariant="content"
                text="Prix minimum"
                placeHolder="Prix minimum"
                hasErrors={doesInputHaveError("minPrice.value")}
                value={updatedSlot.minPrice.value}
                onValueChange={v =>
                  updateServiceMinMaxData("minPrice", "value", getSafeNumberFromInput(v))
                }
              />
              <CustomText variant="text" color="lightGrey">
                Entrez le montant minimum requis pour effectuer une commande. Le montant
                doit être supérieur à X.
              </CustomText>
            </Box>
          )}

          {service?.maxArticles?.isEnabled && (
            <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
              <NumberInputLine
                textVariant="content"
                text="Articles maximum"
                placeHolder="Articles maximum"
                hasErrors={doesInputHaveError("maxArticles.value")}
                value={updatedSlot.maxArticles.value}
                onValueChange={v =>
                  updateServiceMinMaxData(
                    "maxArticles",
                    "value",
                    getSafeNumberFromInput(v),
                  )
                }
              />
              <CustomText variant="text" color="lightGrey">
                Entrez le nombre maximum d’articles autorisés pour effectuer une commande.
                Le nombre doit être supérieur à 0.
              </CustomText>
            </Box>
          )}

          {service?.maxPrice?.isEnabled && (
            <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
              <NumberInputLine
                textVariant="content"
                text="Prix maximum"
                placeHolder="Prix maximum"
                hasErrors={doesInputHaveError("maxPrice.value")}
                value={updatedSlot.maxPrice.value}
                onValueChange={v =>
                  updateServiceMinMaxData("maxPrice", "value", getSafeNumberFromInput(v))
                }
              />
              <CustomText variant="text" color="lightGrey">
                Entrez le montant maximum à ne pas dépasser pour effectuer une commande.
                Le nombre doit être supérieur à 0€.
              </CustomText>
            </Box>
          )}

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Accepter automatiquement"
              value={updatedSlot.automaticClickConfirmationSettings.acceptAutomatically}
              onValueChange={v =>
                updateSlotAutomaticConfirmation("acceptAutomatically", v)
              }
            />
            <CustomText variant="text" color="lightGrey">
              Toutes les commandes effectuées par vos clients seront automatiquement
              acceptées jusqu’à la limite du service.
            </CustomText>
          </Box>
          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Accepter automatiquement jusqu'a"
              value={
                updatedSlot.automaticClickConfirmationSettings
                  .acceptAutomaticallyWithLimits
              }
              onValueChange={v =>
                updateSlotAutomaticConfirmation("acceptAutomaticallyWithLimits", v)
              }
            />
            <CustomText variant="text" color="lightGrey">
              Toutes les commandes effectuées jusqu’à X articles ou montant seront
              automatiquement acceptées.
            </CustomText>

            {updatedSlot.automaticClickConfirmationSettings
              .acceptAutomaticallyWithLimits && (
              <Box
                mt="s"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box flexDirection="row" alignItems="center">
                  <CustomTextInput
                    hasErrors={doesInputHaveError(
                      "automaticClickConfirmationSettings.minArticlesPerOrder",
                    )}
                    placeHolder="0"
                    hideFloatingLabel
                    initialValue={updatedSlot.automaticClickConfirmationSettings.minArticlesPerOrder.toString()}
                    keyboardType="number-pad"
                    onChangeText={v =>
                      updateSlotAutomaticConfirmation(
                        "minArticlesPerOrder",
                        getSafeNumberFromInput(v),
                      )
                    }
                    boxProps={{
                      minWidth: 70,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    inputStyles={{ textAlign: "center" }}
                  />
                  <Box
                    ml="s"
                    p="s"
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={50}
                    minWidth={50}
                  >
                    <CustomText variant="content" color="primaryTextColor">
                      Articles
                    </CustomText>
                  </Box>
                </Box>
                <CustomText variant="content" color="primaryTextColor">
                  ou
                </CustomText>
                <Box flexDirection="row" alignItems="center">
                  <CustomTextInput
                    placeHolder="0"
                    hideFloatingLabel
                    initialValue={updatedSlot.automaticClickConfirmationSettings.minPricePerOrder.toString()}
                    hasErrors={doesInputHaveError(
                      "automaticClickConfirmationSettings.minPricePerOrder",
                    )}
                    keyboardType="number-pad"
                    onChangeText={v =>
                      updateSlotAutomaticConfirmation(
                        "minPricePerOrder",
                        getSafeNumberFromInput(v),
                      )
                    }
                    boxProps={{
                      minWidth: 70,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    inputStyles={{ textAlign: "center" }}
                  />
                  <Box
                    ml="s"
                    p="s"
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={50}
                    minWidth={50}
                  >
                    <CustomText variant="content" color="primaryTextColor">
                      €
                    </CustomText>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Autoriser la surcommande"
              value={updatedSlot.waitingListSettings.isEnabled}
              onValueChange={v => updateSlotWaitingList("isEnabled", v)}
            />

            {updatedSlot.waitingListSettings.isEnabled && (
              <Box mt="s" ml="s">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  placeHolder="0"
                  text="Nombre de commandes"
                  hasErrors={doesInputHaveError("waitingListSettings.totalOrdersAllowed")}
                  value={updatedSlot.waitingListSettings.totalOrdersAllowed}
                  onValueChange={v =>
                    updateSlotWaitingList("totalOrdersAllowed", getSafeNumberFromInput(v))
                  }
                />

                <CustomText variant="text" color="lightGrey">
                  Commandes supplémentaires mais en attente de validation.
                </CustomText>
              </Box>
            )}
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton onPress={onSubmit} title="Valider" />
    </Box>
  );
};

export default TakeAwayServiceSlotsListDetails;
