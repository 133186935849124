import { useRoute } from "@react-navigation/native";
import { useState } from "react";

import Box from "../../../../components/Base/Box";
import BottomButton from "../../../../components/BottomButton";

import ClientListDetailsEdit from "./Edit";
import ClientListDetailsView from "./View";

interface ClientListDetailsProps {
  newValue?: boolean;
  clientId?: string;
  goBack?: () => void;
}

const ClientListDetails = ({
  newValue = false,
  clientId = "",
  goBack,
}: ClientListDetailsProps) => {
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = clientId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <ClientListDetailsEdit id={id} goBack={goBack} />
      ) : (
        <ClientListDetailsView id={id} goBack={goBack} />
      )}
      {!inEditMode && (
        <BottomButton
          onPress={() => {
            setInEditMode(true);
          }}
          title="Modifier"
        />
      )}
    </Box>
  );
};

export default ClientListDetails;
