import type { Dispatch, SetStateAction } from "react";

import type {
  BookingAutomaticConfirmationSettingsFragment,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface ServiceConfirmationProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const ServiceConfirmation = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServiceConfirmationProps) => {
  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const toggleConfirmationType = (type: "automatic" | "manual") => {
    setBookingAvailability(prev => {
      if (type === "manual") {
        return {
          ...prev,
          automaticConfirmationSettings: {
            ...prev.automaticConfirmationSettings,
            isEnabled: false,
            isEnabledForMaxCapacity: prev.automaticConfirmationSettings
              .isEnabledForMaxCapacity
              ? false
              : true,
            maxCapacityForAutomaticConfirmation: 0,
          },
        };
      } else {
        return {
          ...prev,
          automaticConfirmationSettings: {
            ...prev.automaticConfirmationSettings,
            isEnabled: prev.automaticConfirmationSettings.isEnabled ? false : true,
            isEnabledForMaxCapacity: false,
            maxCapacityForAutomaticConfirmation: 0,
          },
        };
      }
    });
  };

  const updateBookingAutomaticConfirmationSettings = (
    key: keyof BookingAutomaticConfirmationSettingsFragment,
    value: boolean | number,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      automaticConfirmationSettings: {
        ...prev.automaticConfirmationSettings,
        [key]: value,
      },
    }));
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          CONFIRMATION
        </CustomText>
      </Box>
      <Box mt="m">
        <ToggleInputLine
          textVariant="content"
          text="Accepter tout automatiquement"
          value={bookingAvailability?.automaticConfirmationSettings?.isEnabled}
          onValueChange={() => toggleConfirmationType("automatic")}
        />
        <CustomText variant="text" color="lightGrey">
          Toutes les réservations effectuées par vos clients seront automatiquement
          acceptées jusqu’à la limite du service.
        </CustomText>
      </Box>

      <Box mt="m">
        <ToggleInputLine
          textVariant="content"
          text="Accepter automatiquement jusqu'a"
          value={
            bookingAvailability?.automaticConfirmationSettings.isEnabledForMaxCapacity
          }
          onValueChange={() => toggleConfirmationType("manual")}
        />
        <CustomText variant="text" color="lightGrey">
          Toutes les réservations effectuées jusqu’à X couverts seront automatiquement
          acceptées jusqu’à la limite du service.
        </CustomText>
        {bookingAvailability.automaticConfirmationSettings.isEnabledForMaxCapacity && (
          <Box mt="s" flexDirection="row" alignItems="center" alignSelf="flex-end">
            <CustomTextInput
              placeHolder="0"
              keyboardType="number-pad"
              hasErrors={doesInputHaveError("maxCapacityForAutomaticConfirmation")}
              hideFloatingLabel
              value={bookingAvailability.automaticConfirmationSettings.maxCapacityForAutomaticConfirmation?.toString()}
              onChangeText={v =>
                updateBookingAutomaticConfirmationSettings(
                  "maxCapacityForAutomaticConfirmation",
                  getSafeNumberFromInput(v),
                )
              }
              inputStyles={{ textAlign: "center" }}
            />
            {bookingAvailability?.minMaxBookingPersons?.isEnabled &&
              bookingAvailability?.minMaxBookingPersons?.maxPersons > 0 && (
                <Box flexDirection="row" alignItems="center">
                  <CustomText
                    marginHorizontal="s"
                    variant="label"
                    color="primaryTextColor"
                  >
                    /
                  </CustomText>
                  <CustomTextInput
                    hideFloatingLabel
                    placeHolder="50"
                    disabled
                    initialValue={bookingAvailability.minMaxBookingPersons?.maxPersons?.toString()}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChangeText={() => {}}
                    inputStyles={{ textAlign: "center" }}
                  />
                </Box>
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServiceConfirmation;
