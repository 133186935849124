import { useState } from "react";
import { TouchableOpacity } from "react-native";

import DOWN_ARROW from "../../../assets/icons/BASE/DOWN_ARROW.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface CollapsibleListProps {
  title: string;
  nbItems: number;
  isOpen: boolean;
  children: JSX.Element;
  onChange: (isOpen: boolean) => void;
}

const CollapsibleList = ({
  title,
  nbItems,
  isOpen,
  children,
  onChange,
}: CollapsibleListProps) => {
  const [isListOpen, setIsListOpen] = useState(isOpen);

  const toggleList = () => {
    setIsListOpen(!isListOpen);
    onChange(!isListOpen);
  };

  return (
    <>
      <TouchableOpacity onPress={toggleList}>
        <Box
          p="s"
          borderColor="lightGrey"
          borderWidth={1}
          borderRadius="button"
          borderBottomWidth={isListOpen ? 0 : 1}
          borderBottomLeftRadius={isListOpen ? "none" : "button"}
          borderBottomRightRadius={isListOpen ? "none" : "button"}
          minHeight={45}
        >
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <CustomText variant="primaryButtonText" color="success">
              {title}
            </CustomText>
            <Box flexDirection="row" alignItems="center">
              <Box
                p="s"
                minWidth={35}
                minHeight={35}
                backgroundColor="danger"
                borderRadius="button"
                alignItems="center"
                justifyContent="center"
                mr="s"
              >
                <CustomText variant="primaryButtonText" color="white">
                  {nbItems}
                </CustomText>
              </Box>
              {isListOpen ? (
                <DOWN_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              ) : (
                <RIGHT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              )}
            </Box>
          </Box>
        </Box>
      </TouchableOpacity>
      {isListOpen && (
        <Box
          borderColor="lightGrey"
          borderWidth={1}
          borderRadius="button"
          borderTopLeftRadius={isListOpen ? "none" : "button"}
          borderTopRightRadius={isListOpen ? "none" : "button"}
          borderBottomLeftRadius={isListOpen ? "button" : "button"}
          borderBottomRightRadius={isListOpen ? "button" : "button"}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default CollapsibleList;
