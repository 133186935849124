import ClientListDetailsView from "../../../screens/Settings/Clients/ClientListDetails/View";
import ContentModal from "../../ContentModal";

interface ClientDetailsViewModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  clientId: string;
}

const ClientDetailsViewModal = ({
  isOpen,
  setIsOpen,
  clientId,
}: ClientDetailsViewModalProps) => {
  const handleClose = () => setIsOpen(false);

  return (
    <ContentModal isVisible={isOpen} onClose={handleClose} title="Détails du client">
      {/* <Box height="95%" overflow="hidden"> */}
      <ClientListDetailsView id={clientId} showBackButton={false} />
      {/* </Box> */}
    </ContentModal>
  );
};

export default ClientDetailsViewModal;
