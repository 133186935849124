import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import EMAIL from "../../../../../../../assets/icons/BASE/EMAIL.svg";
import PAX_PERSONS from "../../../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import PHONE from "../../../../../../../assets/icons/BASE/PHONE.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { User } from "../../../../../../graphql/generated/schema";
import { useGetMerchantOwnerLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";

interface AccountDetailsViewProps {
  goBack?: () => void;
}

const AccountDetailsView = ({ goBack }: AccountDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState<User | undefined>(undefined);

  const [getMerchantOwner] = useGetMerchantOwnerLazyQuery();

  const handleGetMerchantOwner = async () => {
    try {
      const { data } = await getMerchantOwner({
        fetchPolicy: "cache-and-network",
      });
      if (data) {
        setOwner(data.getMerchantOwner);
      }

      setLoading(false);
    } catch (err) {
      console.log("err get merchant owner: ", err);
    }
  };

  useEffect(() => {
    handleGetMerchantOwner();
  }, []);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  if (loading) return <Loader />;

  return (
    <Box>
      <ScreenHeader title="Nom et coordonnées" hasBackButton onBackPress={handleGoBack} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <TabTextInfo
          {...{
            title: "Gérant",
            content: `${owner?.profile?.lastName || ""} ${
              owner?.profile?.firstName || ""
            }`.trim(),
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        />
        {/* <TabTextInfo
          {...{
            title: "Contrat",
            content: "123456789",
            icon: (
              <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        /> */}
        {/* <TabTextInfo
          {...{
            title: "Siret",
            content: "123456789",
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        /> */}
        <TabTextInfo
          {...{
            title: "Téléphone",
            content: owner?.profile?.phone || "-",
            icon: <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
          }}
        />
        <TabTextInfo
          {...{
            title: "Mail",
            content: owner?.profile?.email || "-",
            icon: <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default AccountDetailsView;
