import { useNetInfo } from "@react-native-community/netinfo";

import INFO from "../../../assets/icons/BASE/INFO.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

const InternetConnectivity = () => {
  const { isInternetReachable } = useNetInfo();

  if (isInternetReachable) return null;

  return (
    <Box position="absolute" bottom={50} left={0} right={0} zIndex={9999}>
      <Box
        m="s"
        marginHorizontal="xs"
        backgroundColor="darkGrey"
        borderRadius="button"
        borderColor="primaryTextColor"
        borderWidth={2}
        p="m"
        flexDirection="row"
        alignItems="center"
        maxWidth={370}
        width="100%"
        alignSelf="flex-end"
      >
        <INFO width={24} height={24} fill={PALETTE.darkBlue} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          Pas de connexion internet
        </CustomText>
      </Box>
    </Box>
  );
};

export default InternetConnectivity;
