import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../../theme";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface CashRegisterNumberKeyboardProps {
  handleAmountChange: (amount: string) => void;
  handleClear: () => void;
  handleCommaPress?: () => void;
  showComma: boolean;
}

const BUTTON_HEIGHT = 50;

const CashRegisterNumberKeyboard = ({
  handleAmountChange,
  handleClear,
  handleCommaPress,
  showComma,
}: CashRegisterNumberKeyboardProps) => {
  return (
    <Box backgroundColor="disabled" borderRadius="button">
      <Box
        flexDirection="row"
        alignItems="center"
        borderBottomColor="white"
        borderBottomWidth={LINE_THICKNESS}
      >
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("1")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              1
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("2")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              2
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: "33%" }}
          onPress={() => handleAmountChange("3")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              3
            </CustomText>
          </Box>
        </TouchableOpacity>
      </Box>
      <Box
        flexDirection="row"
        alignItems="center"
        borderBottomColor="white"
        borderBottomWidth={LINE_THICKNESS}
      >
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("4")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              4
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("5")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              5
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => handleAmountChange("6")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              6
            </CustomText>
          </Box>
        </TouchableOpacity>
      </Box>
      <Box
        flexDirection="row"
        alignItems="center"
        borderBottomColor="white"
        borderBottomWidth={LINE_THICKNESS}
      >
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("7")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              7
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("8")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              8
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => handleAmountChange("9")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              9
            </CustomText>
          </Box>
        </TouchableOpacity>
      </Box>
      <Box flexDirection="row" alignItems="center">
        <TouchableOpacity
          onPress={handleCommaPress}
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {showComma ? (
            <Box p="s" alignItems="center" justifyContent="center">
              <CustomText variant="content" color="primaryTextColor">
                ,
              </CustomText>
            </Box>
          ) : (
            <Box p="s" alignItems="center" justifyContent="center" />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
            borderRightColor: "white",
            borderRightWidth: LINE_THICKNESS,
            borderLeftColor: "white",
            borderLeftWidth: LINE_THICKNESS,
          }}
          onPress={() => handleAmountChange("0")}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              0
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: "33%",
            height: BUTTON_HEIGHT,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={handleClear}
        >
          <Box p="s" alignItems="center" justifyContent="center">
            <CustomText variant="content" color="primaryTextColor">
              C
            </CustomText>
          </Box>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default CashRegisterNumberKeyboard;
