import type { ImageInfo } from "expo-image-picker";
import { useState } from "react";
import type { ImageStyle, StyleProp } from "react-native";
import { Alert, Image, TouchableOpacity } from "react-native";

import { useSavePictureMutation } from "../../graphql/generated/schema";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import AddNewImageModal from "../Media/AddNewImageMedia";

interface AddImageModalProps {
  picture?: string;
  width?: number;
  height?: number;
  displayImageStyle?: StyleProp<ImageStyle>;
  shouldResize?: boolean;
  onSubmit: (picture?: string) => void;
}

const IMAGE_SIZE = 65;

const DEFAULT_DISPLAY_IMAGE_STYLE: StyleProp<ImageStyle> = {
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
  borderRadius: IMAGE_SIZE / 2,
};

const AddImageModal = ({
  picture,
  height,
  width,
  displayImageStyle = {},
  shouldResize = true,
  onSubmit,
}: AddImageModalProps) => {
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const [savePicture] = useSavePictureMutation();

  const handleAddImage = async (image?: ImageInfo) => {
    const pictureBase64 = image?.base64;
    if (!pictureBase64) return;

    if (width && height) {
      const { width: imageWidth, height: imageHeight } = image;
      if (imageWidth < width || imageHeight < height) {
        Alert.alert(
          "Image trop petite",
          `L'image doit faire au moins ${width}x${height} pixels`,
        );
        return;
      }
    }

    try {
      const { data } = await savePicture({
        variables: { picture: pictureBase64, shouldResize },
      });

      onSubmit(data?.savePicture);
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              ...DEFAULT_DISPLAY_IMAGE_STYLE,
              ...(displayImageStyle ? displayImageStyle : {}),
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <Box>
      {displayPicture()}
      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </Box>
  );
};

export default AddImageModal;
