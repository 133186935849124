import { TouchableOpacity } from "react-native";

import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
interface NewSubscriptionCardProps {
  onPress: () => void;
  text: string;
  icon: JSX.Element;
}

const ICON_CONTAINER_SIZE = 45;

const NewSubscriptionCard = ({ onPress, text, icon }: NewSubscriptionCardProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        paddingVertical="m"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          width={ICON_CONTAINER_SIZE}
          height={ICON_CONTAINER_SIZE}
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          borderRadius="button"
          p="s"
        >
          {icon}
        </Box>
        <Box pl="m" flex={1}>
          <CustomText variant="content" color="primaryTextColor">
            {text}
          </CustomText>
        </Box>
        <RIGHT_ARROW height={16} width={16} fill={PALETTE.green} />
      </Box>
    </TouchableOpacity>
  );
};

export default NewSubscriptionCard;
