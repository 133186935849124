import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { FlatList } from "react-native";

import ACCOUNTING from "../../../assets/icons/BASE/ACCOUNTING.svg";
import ALERT_BELL from "../../../assets/icons/BASE/ALERT_BELL.svg";
import BOOK from "../../../assets/icons/BASE/BOOK.svg";
import CREDIT_CARD from "../../../assets/icons/BASE/CREDIT_CARD.svg";
import DATA from "../../../assets/icons/BASE/DATA.svg";
import DIGITAL_MENU from "../../../assets/icons/BASE/DIGITAL_MENU.svg";
import HOME from "../../../assets/icons/BASE/HOME.svg";
import ONLINE_SALES from "../../../assets/icons/BASE/ONLINE_SALES.svg";
import PARTNERS from "../../../assets/icons/BASE/PARTNERS.svg";
import PRINTER from "../../../assets/icons/BASE/PRINTER.svg";
import POWER_BUTTON from "../../../assets/icons/BASE/PowerButton.svg";
import REVIEW from "../../../assets/icons/BASE/REVIEW.svg";
import STATS from "../../../assets/icons/BASE/STATS.svg";
import TABLE from "../../../assets/icons/BASE/TABLE.svg";
import USER_GROUP from "../../../assets/icons/BASE/USER_GROUP.svg";
import Box from "../../components/Base/Box";
import type { SETTING_BUTTON } from "../../components/Settings/SettingsButtonBlock";
import SettingsButtonBlock from "../../components/Settings/SettingsButtonBlock";
import SplitView from "../../components/SplitView";
import { AppContext } from "../../contexts/AppContext";
import { Modules } from "../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";

import AccountAndSubscriptions from "./AccountAndSubscriptions";
import AccountSettingsList from "./Accounting/AccountSettingList";
import BookingSettingsList from "./Booking/BookingSettingsList";
import BookingFloorGeneralSettings from "./BookingFloorGeneralSettings";
import CashRegisterSettings from "./CashRegisterSettings";
import ClientSettings from "./Clients";
import DashbaordSettings from "./Dashboard";
import DataSettingsList from "./Data";
import DevicesSettingsList from "./Devices";
import DigitalMenuList from "./DigitalMenu/DigitalMenuList";
import EstablishmentSettingsList from "./Establishment/EstablishmentSettingsList";
import GeneralSettings from "./General";
import NotificationsSettingsList from "./Notifications/NotificationsSettingsList";
import OnlineSalesSettingsList from "./OnlineSales/OnlineSalesSettingsList";
import Partners from "./Partners";
import PerformanceSettings from "./PerformanceSettings";
import QuoteInvoiceSettings from "./QuoteInvoiceSettings";
import ReviewResponsesList from "./Reviews/ReviewResponsesList";
import StaffSettingsList from "./Staff/StaffSettingsList";
import TablePayment from "./TablePaymentSettings";

const SETTINGS_BLOCKS: SETTING_BUTTON[][] = [
  [
    // {
    //   title: "Général",
    //   icon: <SETTINGS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "GENERAL_SETTINGS_LIST",
    // },
    // {
    //   title: "Dashboard",
    //   icon: <WIDGETS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "DASHBOARD_SETTINGS",
    // },
    {
      title: "Utilisateurs & groupes",
      icon: <USER_GROUP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "STAFF_SETTINGS_LIST",
    },
    {
      title: "Matériel",
      icon: <PRINTER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "DEVICES_SETTINGS_LIST",
    },

    {
      title: "Partenaires",
      icon: <PARTNERS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "PARTNERS",
    },
    {
      title: "Notifications",
      icon: <ALERT_BELL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "NOTIFICATIONS_SETTINGS_LIST",
    },
  ],
  [
    {
      title: "Fiche établissement",
      icon: <HOME height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "ESTABLISHMENT_SETTINGS_LIST",
    },
    {
      title: "Données",
      icon: <DATA height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "DATA_SETTINGS_LIST",
    },
    {
      title: "Clients & entreprises",
      icon: <USER_GROUP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "CLIENT_SETTINGS_LIST",
    },
  ],
  [
    {
      title: "Comptabilité",
      icon: <ACCOUNTING height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "ACCOUNTING_SETTINGS_LIST",
    },
    // {
    //   title: "Encaissement",
    //   icon: <CASH_REGISTER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "CASH_REGISTER_SETTINGS",
    // },
    {
      title: "Commande en ligne",
      icon: <ONLINE_SALES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "ONLINE_SALES_SETTINGS_LIST",
    },
    // {
    //   title: "Commande & paiement à table",
    //   icon: <TABLE_PAYMENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "TABLE_PAYMENT_SETTINGS",
    // },
    {
      title: "Salles & Tables",
      icon: <TABLE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "BOOKING_FLOOR_GENERAL_SETTINGS",
    },
    {
      title: "Réservation",
      icon: <BOOK height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "BOOKING_SETTINGS_LIST",
    },

    // {
    //   title: "Facturation & devis",
    //   icon: <RECEIPT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "QUOTES_INVOICE_SETTINGS_LIST",
    // },
    {
      title: "Carte digitale",
      icon: <DIGITAL_MENU height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "DIGITAL_MENUS_LIST",
    },
  ],
  [
    {
      title: "Statistiques & Rapports",
      icon: <STATS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "PERFORMANCE_AND_STAT_SETTINGS",
    },
    // {
    //   title: "Publications",
    //   icon: <EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
    //   hasOptions: true,
    //   screenName: "GENERAL_SETTINGS_LIST",
    // },
    {
      title: "Avis",
      icon: <REVIEW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "REVIEW_RESPONSES_LIST",
    },
  ],
  [
    {
      title: "Compte et abonnements",
      icon: <CREDIT_CARD height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />,
      hasOptions: true,
      screenName: "ACCOUNT_AND_SUBSCRIPTION_SETTINGS_LIST",
    },
    {
      title: "Se déconnecter",
      icon: <POWER_BUTTON fill={PALETTE.red} />,
      hasOptions: false,
      screenName: "GENERAL_SETTINGS_LIST",
      color: "danger",
      action: "LOGOUT",
    },
  ],
];

const Settings = () => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(appContext.isSplittable ? "STAFF_SETTINGS_LIST" : undefined);

  const handleLogout = () => {
    appContext?.logOutUser();
  };

  const handleNavigation = (button: SETTING_BUTTON) => {
    if (button.action === "LOGOUT") {
      handleLogout();
      return;
    }

    const { screenName } = button;
    if (appContext.isSplittable) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseRightComponent = () => setSelectedTab(undefined);

  const displayRightComponent = () => {
    if (!selectedTab) return undefined;

    switch (selectedTab) {
      case "GENERAL_SETTINGS_LIST":
        return <GeneralSettings goBack={handleCloseRightComponent} />;
      case "DASHBOARD_SETTINGS":
        return <DashbaordSettings goBack={handleCloseRightComponent} />;
      case "STAFF_SETTINGS_LIST":
        return <StaffSettingsList goBack={handleCloseRightComponent} />;
      case "DEVICES_SETTINGS_LIST":
        return <DevicesSettingsList goBack={handleCloseRightComponent} />;
      case "PARTNERS":
        return <Partners goBack={handleCloseRightComponent} />;
      case "NOTIFICATIONS_SETTINGS_LIST":
        return <NotificationsSettingsList goBack={handleCloseRightComponent} />;
      case "ESTABLISHMENT_SETTINGS_LIST":
        return <EstablishmentSettingsList goBack={handleCloseRightComponent} />;
      case "DATA_SETTINGS_LIST":
        return <DataSettingsList goBack={handleCloseRightComponent} />;
      case "CLIENT_SETTINGS_LIST":
        return <ClientSettings goBack={handleCloseRightComponent} />;
      case "ACCOUNTING_SETTINGS_LIST":
        return <AccountSettingsList goBack={handleCloseRightComponent} />;
      case "CASH_REGISTER_SETTINGS":
        return <CashRegisterSettings goBack={handleCloseRightComponent} />;
      case "ONLINE_SALES_SETTINGS_LIST":
        return <OnlineSalesSettingsList goBack={handleCloseRightComponent} />;
      case "TABLE_PAYMENT_SETTINGS":
        return <TablePayment goBack={handleCloseRightComponent} />;
      case "BOOKING_SETTINGS_LIST":
        return <BookingSettingsList goBack={handleCloseRightComponent} />;
      case "QUOTES_INVOICE_SETTINGS_LIST":
        return <QuoteInvoiceSettings goBack={handleCloseRightComponent} />;
      case "PERFORMANCE_AND_STAT_SETTINGS":
        return <PerformanceSettings goBack={handleCloseRightComponent} />;
      case "REVIEW_RESPONSES_LIST":
        return <ReviewResponsesList goBack={handleCloseRightComponent} />;
      case "ACCOUNT_AND_SUBSCRIPTION_SETTINGS_LIST":
        return <AccountAndSubscriptions goBack={handleCloseRightComponent} />;
      case "BOOKING_FLOOR_GENERAL_SETTINGS":
        return <BookingFloorGeneralSettings goBack={handleCloseRightComponent} />;
      case "DIGITAL_MENUS_LIST":
        return <DigitalMenuList goBack={handleCloseRightComponent} />;

      default:
        break;
    }
  };

  const getDisallowedScreenNames = () => {
    let disallowedScreens: string[] = [];

    if (!appContext.connectedUser?.isOwner) {
      disallowedScreens.push("ACCOUNT_AND_SUBSCRIPTION_SETTINGS_LIST");
    }

    const HAS_ALL_PERMISSIONS = appContext.merchantPermissions.includes(Modules.All);
    const HAS_BOOKING_PERMISSION = appContext.merchantPermissions.includes(
      Modules.Reservation,
    );
    const HAS_ONLINE_SALES_PERMISSION = appContext.merchantPermissions.includes(
      Modules.OnlineSales,
    );
    const HAS_MERCHANT_CLIENTS_PERMISSIONS = appContext.merchantPermissions.includes(
      Modules.MerchantClients,
    );
    const HAS_DIGITAL_MENU_PERMISSION = appContext.merchantPermissions.includes(
      Modules.DigitalMenu,
    );

    if (HAS_ALL_PERMISSIONS) return [];

    if (HAS_BOOKING_PERMISSION && !HAS_ONLINE_SALES_PERMISSION) {
      disallowedScreens.push("ONLINE_SALES_SETTINGS_LIST");
    }

    if (HAS_ONLINE_SALES_PERMISSION && !HAS_BOOKING_PERMISSION) {
      disallowedScreens = [
        ...disallowedScreens,
        "BOOKING_FLOOR_GENERAL_SETTINGS",
        "BOOKING_SETTINGS_LIST",
      ];
    }

    if (!HAS_DIGITAL_MENU_PERMISSION) {
      disallowedScreens.push("DIGITAL_MENUS_LIST");
    }

    if (!HAS_MERCHANT_CLIENTS_PERMISSIONS) {
      disallowedScreens.push("CLIENT_SETTINGS_LIST");
    }

    return disallowedScreens;
  };

  return (
    <SplitView
      leftComponent={
        <Box paddingHorizontal="s" flex={1} paddingVertical="s" backgroundColor="white">
          <FlatList
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 50 }}
            data={SETTINGS_BLOCKS}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item }) => (
              <Box marginVertical="s">
                <Box>
                  <SettingsButtonBlock
                    buttons={item}
                    onPress={handleNavigation}
                    selectedButton={selectedTab}
                    disallowedScreens={getDisallowedScreenNames()}
                  />
                </Box>
              </Box>
            )}
          />
        </Box>
      }
      rightComponent={displayRightComponent()}
    />
  );
};

export default Settings;
