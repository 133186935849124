import { useFocusEffect } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import React, { useContext } from "react";

import Box from "../../components/Base/Box";
import { CustomText } from "../../components/Base/Text";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext";
import { useGetMerchantStripeLDashboardLinkLazyQuery } from "../../graphql/generated/schema";

interface MerchantStripeAccountReturnProps {}

const MerchantStripeAccountReturn = (props: MerchantStripeAccountReturnProps) => {
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [getStripeDashboardLink] = useGetMerchantStripeLDashboardLinkLazyQuery();

  const handleOpenStripeDashboard = async () => {
    try {
      const { data } = await getStripeDashboardLink();

      if (data) {
        const url = data.getMerchantStripeLoginLink;

        if (url) {
          await WebBrowser.openBrowserAsync(url, {
            windowFeatures: {
              target: "_blank",
            },
          });
        }
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_OPEN_STRIPE_DASHBOARD",
            message: "Une erreur est survenue lors de l'ouverture du dashboard Stripe",
          },
        ],
        "error",
      );
      console.log("err get stripe dashboard link", err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleOpenStripeDashboard();
    }, []),
  );

  return (
    <Box flex={1} justifyContent="center" alignItems="center" backgroundColor="white">
      <CustomText variant="content" color="primaryTextColor">
        Redirection vers votre dashboard Stripe en cours
      </CustomText>
    </Box>
  );
};

export default MerchantStripeAccountReturn;
