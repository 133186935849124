import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import FACEBOOK from "../../../../assets/icons/BASE/FACEBOOK.svg";
import INSTAGRAM from "../../../../assets/icons/BASE/INSTAGRAM.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  PartnerSettingsFragment,
  PublicationFragment,
} from "../../../graphql/generated/schema";
import {
  GetPublicationsDocument,
  useCreatePublicationMutation,
  useDeletePublicationMutation,
  useGetPartnerSettingsLazyQuery,
  useUpdatePublicationMutation,
} from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import { Publication_Cta, Publication_Type } from "../../../types/types";
import { convertPublicationType } from "../../../utils/Publication";
import { createDateWithTime, removeTypeNames } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BottomButtomWithActions from "../../BottomButtomWithActions";
import ButtonList from "../../ButtonList";
import DateInput from "../../DateInput/index";
import DaysSelector from "../../DaysSelector";
import ImageCarouselPicker from "../../ImageCarouselPicker";
import KeyboardAwareScrollView from "../../KeyboardAwareScrollView";
import ScreenHeader from "../../ScreenHeader";
import SelectButton from "../../Select/SelectButton";
import { CustomTextInput } from "../../TextInput";
import TimeInput from "../../TimeInput/index";
import Toggle from "../../Toggle";
import ToggleInputLine from "../../ToggleInputLine";

interface PublicationDetailsProps {
  isNew: boolean;
  onGoBack: () => void;
  publication?: PublicationFragment;
}

const SELECTION_OPTIONS = [
  // { label: "Aucun", key: "NONE" },
  { label: "Réserver", key: "RESERVE" },
  { label: "Commander", key: "ORDER" },
  { label: "Acheter", key: "BUY" },
  { label: "En savoir plus", key: "MORE_INFO" },
  { label: "Appeler", key: "CALL" },
];

const CATEGORY_WIDTH = 100;
const DISPLAY_IMAGE_HEIGHT = 150;
const IMAGE_LIST_SIZE = 50;

enum CATEGORY_TYPES {
  OFFRE = "Offre",
  NEW = "Nouveauté",
  EVENT = "Événement",
}

const PUBLICATION_CATEGORY_TYPES: Record<Publication_Type, string> = {
  OFFER: CATEGORY_TYPES.OFFRE,
  NEW_INFO: CATEGORY_TYPES.NEW,
  EVENT: CATEGORY_TYPES.EVENT,
};

type PublicationDetailsType = Omit<PublicationFragment, "createdAt" | "updatedAt">;

const NEW_PUBLICATION: PublicationDetailsType = {
  _id: "",
  type: Publication_Type.Offer,
  cta: Publication_Cta.Reserve,
  ctaLink: "",
  title: "",
  description: "",
  promoCode: "",
  usageConditions: "",
  isVisibleOnline: false,
  eventPrice: 0,
  eventCode: "",
  tags: [],
  isBoosted: false,
  isPinned: false,
  isPublished: true,
  visibility: {
    isRecurring: false,
    days: [],
    endDate: new Date(),
    startDate: new Date(),
    startTime: "10:00",
    endTime: "12:00",
  },
  images: [],
  isPublishedOnFacebook: false,
  isPublishedOnInstagram: false,
  instagramPublishIds: [],
  facebookPublishIds: [],
};

const PublicationDetails = ({
  onGoBack,
  isNew = true,
  publication: existingPublication,
}: PublicationDetailsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [publication, setPublication] = useState<PublicationDetailsType>(
    isNew ? NEW_PUBLICATION : existingPublication,
  );
  const [parnterSettings, setParnterSettings] = useState<
    PartnerSettingsFragment | undefined
  >(undefined);

  const [getPartnerSettings] = useGetPartnerSettingsLazyQuery();
  const [createPublication] = useCreatePublicationMutation();
  const [updatePublication] = useUpdatePublicationMutation();
  const [deletePublication] = useDeletePublicationMutation();

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetPartnerSettings = async () => {
    try {
      const { data } = await getPartnerSettings({
        fetchPolicy: "cache-and-network",
      });

      if (data?.getPartnersSettings) {
        setParnterSettings(data?.getPartnersSettings);
      }
    } catch (err) {
      console.log("err get partner settings", err);
    }
  };

  useEffect(() => {
    handleGetPartnerSettings();
  }, []);

  const handleDeletePublication = async (publicationId: string) => {
    try {
      await deletePublication({
        variables: {
          publicationId: publicationId,
        },
      });
    } catch (err) {}
  };

  const handleButtonSubmit = async (key: string) => {
    if (key === "CANCEL") {
      handleGoBack();
    }
    if (key === "DELETE") {
      if (!publication._id) return;
      await handleDeletePublication(publication._id);
      handleGoBack();
    }
  };

  const handleSubmit = async () => {
    try {
      if (isNew) {
        const newPublication = { ...publication };

        delete newPublication?._id;
        delete newPublication?.ctaLink;
        delete newPublication?.instagramPublishIds;
        delete newPublication?.facebookPublishIds;

        await createPublication({
          variables: {
            publication: newPublication,
          },
          refetchQueries: [
            {
              query: GetPublicationsDocument,
            },
          ],
        });
      } else {
        const {
          _id: publicationId,
          title,
          description,
          images,
          type,
          cta,
          promoCode,
          usageConditions,
          isBoosted,
          isPinned,
          isVisibleOnline,
          eventCode,
          eventPrice,
          visibility,
          tags,
          isPublished,
          isPublishedOnFacebook,
          isPublishedOnInstagram,
        } = publication;

        const formattedVisibility = {
          ...visibility,
          startDate: new Date(visibility.startDate).toDateString(),
          endDate: new Date(visibility.endDate).toDateString(),
        };

        const updates = removeTypeNames({
          title,
          description,
          images,
          type,
          cta,
          promoCode,
          usageConditions,
          isBoosted,
          isPinned,
          isVisibleOnline,
          eventCode,
          eventPrice,
          visibility: formattedVisibility,
          tags,
          isPublished,
          isPublishedOnFacebook,
          isPublishedOnInstagram,
        });

        await updatePublication({
          variables: {
            publicationId: publicationId,
            publication: updates,
          },
          refetchQueries: [
            {
              query: GetPublicationsDocument,
            },
          ],
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err publication add upate", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "PUBLICATION_UPDATE_ERROR",
            message: "Une erreur est survenue lors de la mise à jour de la publication",
          },
        ],
        "error",
      );
    }
  };

  const handleAddNewImage = async (image?: string) => {
    if (!image) return;

    setPublication(prev => ({
      ...prev,
      images: [...(prev.images || []), image],
    }));
  };

  const handleRemoveImage = (image?: string) => {
    if (!image) return;

    setPublication(prev => {
      const images = prev.images ? prev.images.filter(i => i !== image) : [];

      return {
        ...prev,
        images,
      };
    });
  };

  const handleUpdatePublicationVisibility = (
    key: keyof PublicationFragment["visibility"],
    value: number[] | Date | boolean,
  ) => {
    setPublication(prev => ({
      ...prev,
      visibility: {
        ...prev.visibility,
        [key]: value,
      },
    }));
  };

  const handleUpdatePublicationVisibilityTimes = (
    key: "startTime" | "endTime",
    value: Date,
  ) => {
    const time = format(value, "HH:mm");

    setPublication(prev => ({
      ...prev,
      visibility: {
        ...prev.visibility,
        [key]: time,
      },
    }));
  };

  const handleUpdatePublication = (
    key: keyof PublicationFragment,
    value: PublicationFragment[keyof PublicationFragment],
  ) => {
    setPublication(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUpdateRecurringDays = (days: number[] | number) => {
    const formattedDays = Array.isArray(days) ? days : [days];

    setPublication(prev => ({
      ...prev,
      visibility: {
        ...prev.visibility,
        days: formattedDays,
      },
    }));
  };

  const getButtonActions = () => {
    if (isNew) {
      return [
        {
          title: "Annuler",
          key: "CANCEL",
          variant: "outline",
          color: "danger",
        },
      ];
    }
    return [
      {
        title: "Supprimer",
        key: "DELETE",
        variant: "primary",
        color: "danger",
      },
      {
        title: "Annuler",
        key: "CANCEL",
        variant: "outline",
        color: "danger",
      },
    ];
  };

  return (
    <Box pt="s" flex={1} backgroundColor="white">
      <ScreenHeader
        title={isNew ? "Nouvelle publication" : "Modifier la publication"}
        hasBackButton
        description="Choisissez la catégorie de publication et laissez-vous guider."
        onBackPress={handleGoBack}
      />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 130 }}
        >
          <Box flex={1} pt="s" paddingHorizontal="s" backgroundColor="white">
            <Box mt="m">
              <CustomText variant="label" color="primaryTextColor">
                Catégories
              </CustomText>
            </Box>

            <Box mt="m" flexWrap="wrap" flexDirection="row" alignItems="center">
              {Object.keys(PUBLICATION_CATEGORY_TYPES).map((category, idx) => {
                const IS_SELECTED = publication.type === category;
                return (
                  <TouchableOpacity
                    key={category}
                    onPress={() => handleUpdatePublication("type", category)}
                  >
                    <Box
                      minWidth={CATEGORY_WIDTH}
                      mr="s"
                      mb="s"
                      backgroundColor={IS_SELECTED ? "yellow" : "white"}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="button"
                      borderWidth={1}
                      borderColor="yellow"
                      p="s"
                    >
                      <CustomText
                        variant="content"
                        color={IS_SELECTED ? "white" : "primaryTextColor"}
                      >
                        {convertPublicationType(category)}
                      </CustomText>
                    </Box>
                  </TouchableOpacity>
                );
              })}
            </Box>

            <Box>
              <ImageCarouselPicker
                images={publication.images || []}
                onAddImage={handleAddNewImage}
                onRemoveImage={handleRemoveImage}
                maxImages={5}
              />
            </Box>
            {/* <Box>
            <CustomButton
              styles={{
                minHeight: DISPLAY_IMAGE_HEIGHT,
              }}
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleAddNewImage}
            >
              <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              <CustomText variant="title" color="success">
                Ajouter une photo
              </CustomText>
            </CustomButton>
            <Box mt="s" alignSelf="flex-end">
              <CustomText variant="description" color="disabled">
                0/10
              </CustomText>
            </Box>
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleAddNewImage}
              styles={{
                height: IMAGE_LIST_SIZE,
                width: IMAGE_LIST_SIZE,
              }}
            >
              <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </CustomButton>
          </Box> */}

            <Box mt="m">
              <ToggleInputLine
                text="Publier"
                value={publication.isPublished}
                onValueChange={value => handleUpdatePublication("isPublished", value)}
              />
            </Box>

            <Box mt="m">
              <CustomTextInput
                placeHolder="Titre de la publication"
                initialValue={publication.title}
                onChangeText={text => handleUpdatePublication("title", text)}
                isRequired
              />
            </Box>

            <Box mt="m">
              <CustomTextInput
                placeHolder="Description de la publication"
                initialValue={publication.description}
                multiLine
                boxProps={{
                  minHeight: 100,
                  alignItems: "flex-start",
                  pt: "s",
                }}
                onChangeText={text => handleUpdatePublication("description", text)}
              />
            </Box>

            {publication.type === "OFFER" && (
              <Box>
                <Box mt="m">
                  <CustomTextInput
                    placeHolder="Code promotionnel"
                    initialValue={publication.promoCode}
                    onChangeText={text => handleUpdatePublication("promoCode", text)}
                    isRequired
                  />
                </Box>
                <Box mt="m">
                  <CustomTextInput
                    placeHolder="Conditions d’utilisation"
                    initialValue={publication.usageConditions}
                    onChangeText={text =>
                      handleUpdatePublication("usageConditions", text)
                    }
                  />
                </Box>
              </Box>
            )}

            <Box mt="m">
              <CustomText mb="s" variant="label" color="primaryTextColor">
                Tags
              </CustomText>

              <ButtonList
                values={publication.tags?.map(tag => ({ key: tag, label: tag })) || []}
                onChange={tags =>
                  handleUpdatePublication(
                    "tags",
                    tags.map(tag => tag.label),
                  )
                }
              />
            </Box>

            {publication.type !== "NEW_INFO" && (
              <>
                <Box mt="m">
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CustomText variant="label" color="primaryTextColor">
                      Personnaliser la période
                    </CustomText>

                    {/* <RadioSelectButton isSelected={true} /> */}
                  </Box>
                  <Box
                    mt="s"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CustomText variant="content" color="primaryTextColor">
                      À une date spécifique
                    </CustomText>

                    <Toggle
                      value={!publication?.visibility.isRecurring}
                      onChange={() =>
                        handleUpdatePublicationVisibility("isRecurring", false)
                      }
                    />
                  </Box>
                  <Box
                    mt="s"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CustomText variant="content" color="primaryTextColor">
                      Récurrent
                    </CustomText>

                    <Toggle
                      value={publication?.visibility.isRecurring}
                      onChange={() =>
                        handleUpdatePublicationVisibility("isRecurring", true)
                      }
                    />
                  </Box>
                </Box>

                {publication.visibility.isRecurring ? (
                  <>
                    <Box mt="m">
                      <DaysSelector
                        isMulti
                        selectedDays={publication?.visibility?.days || []}
                        onDayPress={handleUpdateRecurringDays}
                      />
                    </Box>
                  </>
                ) : (
                  <Box mt="m">
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <CustomText variant="content" color="primaryTextColor">
                          Début
                        </CustomText>
                      </Box>
                      <Box minWidth={100}>
                        <DateInput
                          date={new Date(publication.visibility?.startDate)}
                          onChange={date =>
                            date && handleUpdatePublicationVisibility("startDate", date)
                          }
                          minDate={new Date()}
                          placeHolder="Date de début"
                        />
                      </Box>
                    </Box>
                    <Box
                      mt="s"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <CustomText variant="content" color="primaryTextColor">
                          Fin
                        </CustomText>
                      </Box>
                      <Box minWidth={100}>
                        <DateInput
                          date={new Date(publication.visibility?.endDate)}
                          onChange={date =>
                            date && handleUpdatePublicationVisibility("endDate", date)
                          }
                          placeHolder="Date de fin"
                          minDate={new Date(publication.visibility?.startDate)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box
                  mt="m"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText variant="content" color="primaryTextColor">
                    Heure de début
                  </CustomText>
                  <Box minWidth={100}>
                    <TimeInput
                      date={createDateWithTime(
                        new Date(),
                        publication.visibility?.startTime,
                      )}
                      onChange={date =>
                        date && handleUpdatePublicationVisibilityTimes("startTime", date)
                      }
                      placeHolder="Heure de début"
                    />
                  </Box>
                </Box>
                <Box
                  mt="m"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText variant="content" color="primaryTextColor">
                    Heure de fin
                  </CustomText>
                  <Box minWidth={100}>
                    <TimeInput
                      onChange={date =>
                        date && handleUpdatePublicationVisibilityTimes("endTime", date)
                      }
                      date={createDateWithTime(
                        new Date(),
                        publication.visibility?.endTime,
                      )}
                      placeHolder="Heure de fin"
                    />
                  </Box>
                </Box>
              </>
            )}
            <Box mt="m">
              <Box mb="s">
                <CustomText variant="label" color="primaryTextColor">
                  Ajouter un bouton
                </CustomText>
                <CustomText variant="description" color="disabled">
                  Ajoutez un bouton d’action
                </CustomText>
              </Box>
              <SelectButton
                isMultiSelect={false}
                options={SELECTION_OPTIONS}
                placeHolder="CTA"
                selectedOptions={[publication.cta]}
                onPress={([cta]) => handleUpdatePublication("cta", cta)}
                styles={{
                  minWidth: 128,
                }}
              />
            </Box>

            {/* <TouchableOpacity onPress={() => navigation.navigate("PUBLICATION_VISIBILITY")}>
            <Box
              mt="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="label" color="primaryTextColor">
                Personnaliser périodicité
              </CustomText>
              <RIGHT_ARROW width={20} height={20} />
            </Box>
          </TouchableOpacity> */}

            <Box mt="m">
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <CustomText variant="label" color="primaryTextColor">
                  Visibilité - Diffusion
                </CustomText>
                <Toggle
                  onChange={value => handleUpdatePublication("isVisibleOnline", value)}
                  value={!!publication.isVisibleOnline}
                />
              </Box>
              <Box mt="s">
                <CustomText variant="description" color="lightGrey">
                  Par défaut, votre publication sera tous les jours visible sur la
                  plateforme Eiwie. Si vous décochez la case, votre publication sera
                  visible uniquement les jours que vous aurez sélectionnés dans
                  Personnaliser périodicité. Ex : Vos clients pourront voir toute la
                  semaine votre publication du samedi soir mais elle ne sera pas
                  disponible en dehors du samedi soir.
                </CustomText>
              </Box>
            </Box>

            {/* <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="label" color="primaryTextColor">
              Épingler la publication
            </CustomText>
            <Toggle
              onChange={value => handleUpdatePublication("isPinned", value)}
              value={!!publication.isPinned}
            />
          </Box> */}

            {/* <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                Booster la publication
              </CustomText>

              <Toggle
                onChange={value => handleUpdatePublication("isBoosted", value)}
                value={!!publication.isBoosted}
              />
            </Box>
            <Box mt="s">
              <CustomText variant="description" color="lightGrey">
                En boostant l’application, vous apparaîtrez en top post.
              </CustomText>
            </Box>
          </Box> */}

            <Box
              mt="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="label" color="primaryTextColor">
                Publier sur vos réseaux
              </CustomText>
              {/* <Toggle onChange={() => {}} value={true} /> */}
            </Box>

            {publication.cta === "MORE_INFO" && (
              <Box mt="m">
                <CustomTextInput
                  placeHolder="Lien de votre bouton"
                  onChangeText={value => handleUpdatePublication("ctaLink", value)}
                />
              </Box>
            )}

            <Box mt="m" flexDirection="row" alignItems="center">
              {parnterSettings?.instagramSettings?.isEnabled &&
                publication.instagramPublishIds.length === 0 && (
                  <TouchableOpacity
                    disabled={!isNew && publication.instagramPublishIds.length > 0}
                    onPress={() =>
                      handleUpdatePublication(
                        "isPublishedOnInstagram",
                        !publication?.isPublishedOnInstagram,
                      )
                    }
                  >
                    <Box mr="s">
                      <INSTAGRAM
                        fill={
                          publication?.isPublishedOnInstagram
                            ? PALETTE.green
                            : PALETTE.darkGrey
                        }
                      />
                    </Box>
                  </TouchableOpacity>
                )}

              {parnterSettings?.facebookSettings?.isEnabled &&
                publication.facebookPublishIds.length === 0 && (
                  <TouchableOpacity
                    disabled={!isNew && publication.facebookPublishIds.length > 0}
                    onPress={() =>
                      handleUpdatePublication(
                        "isPublishedOnFacebook",
                        !publication.isPublishedOnFacebook,
                      )
                    }
                  >
                    <Box mr="s">
                      <FACEBOOK
                        fill={
                          publication?.isPublishedOnFacebook
                            ? PALETTE.green
                            : PALETTE.darkGrey
                        }
                      />
                    </Box>
                  </TouchableOpacity>
                )}
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      {/* <BottomButton title="Valider" onPress={handleSubmit} /> */}
      <BottomButtomWithActions
        title="Valider"
        actions={getButtonActions()}
        onMainActionPress={handleSubmit}
        backGroundColor="white"
        onSubmit={handleButtonSubmit}
      />
    </Box>
  );
};

export default PublicationDetails;
