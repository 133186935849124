import { format } from "date-fns";
import { useState } from "react";

import VALIDATION from "../../../assets/icons/BASE/VALIDATION.svg";
import {
  Merchant_Subscription_Status,
  type SubscriptionTransactionFragment,
} from "../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";
import Touchable from "../Touchable";

interface SubscriptionOptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  subscriptionTransaction: SubscriptionTransactionFragment;
  handleCancellationOfSubscriptionOptions: (ids: string[]) => void;
}

const SubscriptionOptionsModal = ({
  isOpen,
  onClose,
  subscriptionTransaction,
  handleCancellationOfSubscriptionOptions,
}: SubscriptionOptionsModalProps) => {
  const { interval, endDate, status } = subscriptionTransaction;

  const [optionsToRemove, setOptionsToRemove] = useState<string[]>([]);

  const IS_SUBSCRIPTION_CANCELLED = status === Merchant_Subscription_Status.Cancelled;

  const options = subscriptionTransaction.options.filter(option => {
    return option.status !== Merchant_Subscription_Status.Expired;
  });

  const cancelledOptions = subscriptionTransaction.options.filter(option => {
    return option.status === Merchant_Subscription_Status.Cancelled;
  });

  const shouldShowRemoveButton = optionsToRemove.length > 0 && !IS_SUBSCRIPTION_CANCELLED;

  const handleRemoveOptions = () => {
    handleCancellationOfSubscriptionOptions(optionsToRemove);

    onClose();
  };

  const handleAddOptionToRemove = (optionId: string) => {
    if (
      cancelledOptions.find(option => option._id === optionId) ||
      IS_SUBSCRIPTION_CANCELLED
    )
      return;

    setOptionsToRemove(prev => {
      const isOptionAlreadyAdded = prev.includes(optionId);

      if (isOptionAlreadyAdded) {
        return prev.filter(id => id !== optionId);
      }

      return [...prev, optionId];
    });
  };

  const getOptionIconColor = (optionId: string) => {
    if (cancelledOptions.find(option => option._id === optionId)) return PALETTE.red;

    if (optionsToRemove.includes(optionId)) return PALETTE.red;

    return PALETTE.green;
  };

  return (
    <ContentModal title="Vos options" isVisible={isOpen} onClose={onClose}>
      <Box>
        {!IS_SUBSCRIPTION_CANCELLED && (
          <Box
            backgroundColor="disabled"
            borderRadius="button"
            minHeight={60}
            justifyContent="center"
            alignItems="center"
            p="s"
            marginVertical="s"
          >
            <CustomText variant="content" color="primaryTextColor">
              Sélectionnez les options que vous souhaitez retirer. Vous continuerez à etre
              facturé jusqu'à la fin de votre abonnement.
            </CustomText>
          </Box>
        )}

        <Box
          p="m"
          borderWidth={LINE_THICKNESS}
          borderRadius="button"
          borderColor="lightGrey"
        >
          <CustomText variant="label" color="primaryTextColor">
            Votre abonnement comprend
          </CustomText>

          {options.map(option => {
            const { subscription } = option;

            const price = subscription.prices.find(p => p.paymentInterval === interval);

            const isCancelled =
              cancelledOptions.some(o => o._id === option._id) ||
              IS_SUBSCRIPTION_CANCELLED;

            return (
              <Touchable
                key={option._id}
                disabled={isCancelled}
                onPress={() => {
                  handleAddOptionToRemove(option._id);
                }}
              >
                <Box
                  marginVertical="m"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box flexDirection="row" alignItems="flex-start">
                    <Box>
                      <CustomText variant="content" color="primaryTextColor">
                        {subscription.name}
                      </CustomText>
                      {subscription.description && (
                        <CustomText variant="text" color="primaryTextColor">
                          {subscription.description}
                        </CustomText>
                      )}
                      {isCancelled && (
                        <CustomText mt="s" variant="text" color="danger">
                          Annulé - (module activé jusqu'au{" "}
                          {format(new Date(endDate), "dd/MM/yyyy")}
                        </CustomText>
                      )}
                    </Box>
                  </Box>
                  <Box flexDirection="row" alignItems="center">
                    <CustomText mr="s" variant="label" color="primaryTextColor">
                      {formatCurrencyPrice(price?.price || 0, "fr", "eur")}
                    </CustomText>

                    {!isCancelled && (
                      <VALIDATION
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        fill={getOptionIconColor(option._id)}
                      />
                    )}
                  </Box>
                </Box>
              </Touchable>
            );
          })}
        </Box>

        {shouldShowRemoveButton && (
          <Box mt="m">
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={handleRemoveOptions}
            >
              <CustomText variant="outlineButtonText" color="danger">
                Retirer les options
              </CustomText>
            </CustomButton>
          </Box>
        )}
      </Box>
    </ContentModal>
  );
};

export default SubscriptionOptionsModal;
