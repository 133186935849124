import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SingleSelectButtonProps {
  isSelected: boolean;
  title: string;
  onPress: (value: string) => void;
  boxStyles?: React.ComponentProps<typeof Box>;
}
const SingleSelectButton = ({
  isSelected,
  title,
  onPress,
  boxStyles,
}: SingleSelectButtonProps) => {
  return (
    <TouchableOpacity onPress={() => onPress(title)}>
      <Box
        height={45}
        borderColor={isSelected ? "success" : "lightGrey"}
        borderWidth={LINE_THICKNESS}
        borderRadius="button"
        p="s"
        paddingHorizontal="m"
        justifyContent="center"
        {...boxStyles}
      >
        <CustomText variant="paragraph" color={"primaryTextColor"}>
          {title}
        </CustomText>
      </Box>
    </TouchableOpacity>
  );
};
export default SingleSelectButton;
