import { TouchableOpacity } from "react-native";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import RadioSelectButton from "../RadioSelectButton";

interface RadioSelectProps {
  isSelected: boolean;
  onPress: () => void;
  label: string;
  iconOnRight?: boolean;
  btnStyles?: React.ComponentProps<typeof Box>;
  iconSize?: number;
  isRequired?: boolean;
  hasErrors?: boolean;
}

const RadioSelect = ({
  isSelected,
  onPress,
  label,
  btnStyles,
  iconSize,
  iconOnRight,
  isRequired,
  hasErrors,
}: RadioSelectProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        minHeight={45}
      >
        {!iconOnRight && (
          <RadioSelectButton
            isSelected={isSelected}
            boxStyles={btnStyles}
            iconSize={iconSize}
          />
        )}
        <Box flexDirection="row" alignItems="center">
          <CustomText
            style={{ flexWrap: "wrap" }}
            variant="content"
            color="primaryTextColor"
          >
            {label}
          </CustomText>
          {isRequired && (
            <CustomText
              variant="defaultTextInput"
              color={hasErrors ? "danger" : "success"}
            >
              *
            </CustomText>
          )}
        </Box>
        {iconOnRight && (
          <RadioSelectButton
            isSelected={isSelected}
            boxStyles={btnStyles}
            iconSize={iconSize}
          />
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default RadioSelect;
