 
import CIRCLE_1 from "../../../../assets/icons/tables/CIRCLE_1.svg";
import CIRCLE_10 from "../../../../assets/icons/tables/CIRCLE_10.svg";
import CIRCLE_12 from "../../../../assets/icons/tables/CIRCLE_12.svg";
import CIRCLE_2 from "../../../../assets/icons/tables/CIRCLE_2.svg";
import CIRCLE_4 from "../../../../assets/icons/tables/CIRCLE_4.svg";
import CIRCLE_6 from "../../../../assets/icons/tables/CIRCLE_6.svg";
import CIRCLE_8 from "../../../../assets/icons/tables/CIRCLE_8.svg";
import RECTANGLE_1 from "../../../../assets/icons/tables/RECTANGLE_1.svg";
import RECTANGLE_10 from "../../../../assets/icons/tables/RECTANGLE_10.svg";
import RECTANGLE_12 from "../../../../assets/icons/tables/RECTANGLE_12.svg";
import RECTANGLE_2 from "../../../../assets/icons/tables/RECTANGLE_2.svg";
import RECTANGLE_4 from "../../../../assets/icons/tables/RECTANGLE_4.svg";
import RECTANGLE_6 from "../../../../assets/icons/tables/RECTANGLE_6.svg";
import RECTANGLE_8 from "../../../../assets/icons/tables/RECTANGLE_8.svg";
import SQUARE_1 from "../../../../assets/icons/tables/SQUARE_1.svg";
import SQUARE_10 from "../../../../assets/icons/tables/SQUARE_10.svg";
import SQUARE_12 from "../../../../assets/icons/tables/SQUARE_12.svg";
import SQUARE_2 from "../../../../assets/icons/tables/SQUARE_2.svg";
import SQUARE_4 from "../../../../assets/icons/tables/SQUARE_4.svg";
import SQUARE_6 from "../../../../assets/icons/tables/SQUARE_6.svg";
import SQUARE_8 from "../../../../assets/icons/tables/SQUARE_8.svg";
import { Table_Form } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";

const SIZE = 70;

export interface TABLE {
  capacity: number;
  form: Table_Form;
  icon: JSX.Element;
}

export const TABLES: TABLE[] = [
  {
    capacity: 1,
    form: Table_Form.Square,
    icon: <SQUARE_1 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 2,
    form: Table_Form.Square,
    icon: <SQUARE_2 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 4,
    form: Table_Form.Square,
    icon: <SQUARE_4 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 6,
    form: Table_Form.Square,
    icon: <SQUARE_6 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 8,
    form: Table_Form.Square,
    icon: <SQUARE_8 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 10,
    form: Table_Form.Square,
    icon: <SQUARE_10 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 12,
    form: Table_Form.Square,
    icon: <SQUARE_12 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 1,
    form: Table_Form.Circle,
    icon: <CIRCLE_1 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 2,
    form: Table_Form.Circle,
    icon: <CIRCLE_2 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 4,
    form: Table_Form.Circle,
    icon: <CIRCLE_4 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 6,
    form: Table_Form.Circle,
    icon: <CIRCLE_6 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 8,
    form: Table_Form.Circle,
    icon: <CIRCLE_8 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 10,
    form: Table_Form.Circle,
    icon: <CIRCLE_10 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 12,
    form: Table_Form.Circle,
    icon: <CIRCLE_12 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 1,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_1 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 2,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_2 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 4,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_4 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 6,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_6 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 8,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_8 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 10,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_10 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    capacity: 12,
    form: Table_Form.Rectangle,
    icon: <RECTANGLE_12 height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
];

// export const getTablesWithColor = (tables: TABLE[], color: string) => {
//   return tables.map((table) => ({
//     ...table,
//     icon: React.cloneElement(table.icon, { fill: color }),
//   }));
// };
