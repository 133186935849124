import { TouchableOpacity } from "react-native-gesture-handler";

import EIWIE from "../../../assets/icons/BASE/EIWIE.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SubscriptionCardProps {
  title: string;
  price: number;
  description: string;
  cancelledText?: string;
  onPress: () => void;
  borderBottomWidth?: number;
  icon?: JSX.Element;
}

const ICON_CONTAINER_SIZE = 45;

const SubscriptionCard = ({
  title,
  description,
  price,
  onPress,
  borderBottomWidth = LINE_THICKNESS,
  icon,
  cancelledText,
}: SubscriptionCardProps) => {
  const formattedPrice = formatCurrencyPrice(price, "fr", "eur");

  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        paddingVertical="m"
        borderBottomColor="lightGrey"
        borderBottomWidth={borderBottomWidth}
        flexDirection="row"
        alignItems="center"
      >
        <Box
          width={ICON_CONTAINER_SIZE}
          height={ICON_CONTAINER_SIZE}
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          borderRadius="button"
          p="s"
        >
          {icon || <EIWIE fill={PALETTE.green} />}
        </Box>
        <Box ml="s" flex={1}>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              flexWrap="wrap"
            >
              <CustomText variant="content" color="primaryTextColor">
                {title}
              </CustomText>

              <CustomText mr="m" variant="description" color="primaryTextColor">
                {formattedPrice}
              </CustomText>
            </Box>

            <Box flexDirection="row" alignItems="center">
              <RIGHT_ARROW height={16} width={16} fill={PALETTE.green} />
            </Box>
          </Box>
          <CustomText variant="content" color="lightGrey">
            {description}
          </CustomText>
          {cancelledText && (
            <CustomText mt="s" variant="text" color="danger">
              {cancelledText}
            </CustomText>
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default SubscriptionCard;
