import { useEffect, useState } from "react";
import type { LayoutRectangle } from "react-native";
import { ActivityIndicator, Dimensions } from "react-native";
import PieChart from "react-native-chart-kit/dist/PieChart";

import type { GetBookingsBasedOnBookingPersonGoalQuery } from "../../../../graphql/generated/schema";
import { useGetBookingsBasedOnBookingPersonGoalLazyQuery } from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface BookingsByGoalsProps {
  startDate: Date;
  endDate: Date;
}

const screenWidth = Dimensions.get("window").width;

const chartConfig = {
  // backgroundGradientFrom: "#1E2923",
  // backgroundGradientFromOpacity: 0,
  // backgroundGradientTo: "#08130D",
  // backgroundGradientToOpacity: 0.5,
  // backgroundColor: "#fff",
  backgroundGradientFrom: "#fff",
  backgroundGradientTo: "#fff",
  color: (opacity = 1) => `rgba(0,0,0,${opacity})`,
  strokeWidth: 2, // optional, default 3
  barPercentage: 0.5,
  useShadowColorFromDataset: false, // optional
};

const BookingsByGoals = ({ startDate, endDate }: BookingsByGoalsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orderStats, setOrderStats] = useState<
    GetBookingsBasedOnBookingPersonGoalQuery["getBookingsBasedOnBookingPersonGoal"] | null
  >(null);
  const [containerLayout, setcontainerLayout] = useState<LayoutRectangle | undefined>(
    undefined,
  );

  const [getBookings] = useGetBookingsBasedOnBookingPersonGoalLazyQuery();

  const handleGetBookingsTotalRecap = async (refresh = false) => {
    if (refresh) setIsLoading(true);
    try {
      const { data } = await getBookings({
        fetchPolicy: "cache-and-network",
        variables: {
          startDate,
          endDate,
        },
      });

      if (data?.getBookingsBasedOnBookingPersonGoal) {
        setOrderStats(data.getBookingsBasedOnBookingPersonGoal);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, [startDate, endDate]);

  if (isLoading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!orderStats) return null;

  const getChartData = () => {
    return [
      {
        name: "En ligne",
        population: orderStats.fillRate,
        color: PALETTE.green,
        legendFontColor: "#7F7F7F",
        legendFontSize: 15,
      },
      {
        name: "",
        population: 100 - orderStats.fillRate < 0 ? 0 : 100 - orderStats.fillRate,
        color: PALETTE.white,
        legendFontColor: "#7F7F7F",
        legendFontSize: 15,
      },
    ];
  };

  return (
    <Box
      flex={1}
      borderRadius="button"
      borderColor="lightGrey"
      borderWidth={LINE_THICKNESS}
      p="s"
      onLayout={e => {
        setcontainerLayout(e.nativeEvent.layout);
      }}
    >
      <Box p="s">
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Objectif de couverts (remplissage)
        </CustomText>
      </Box>

      <Box flex={1} alignItems="center" justifyContent="center" alignSelf="center">
        <PieChart
          data={getChartData()}
          width={containerLayout?.width || screenWidth}
          height={280}
          chartConfig={chartConfig}
          accessor={"population"}
          backgroundColor={"transparent"}
          paddingLeft={"0"}
          absolute
          avoidFalseZero
          hasLegend={false}
          center={[(containerLayout?.width || 300) / 4, 10]}
          style={{ marginLeft: "auto", marginRight: "auto", left: 0, right: 0 }}
        />

        <Box
          position="absolute"
          alignItems="center"
          justifyContent="center"
          alignSelf="center"
          backgroundColor="white"
          top="35%"
          left={(containerLayout?.width || screenWidth) / 2 - 50}
          width={100}
          height={100}
          style={{
            borderRadius: 70,
          }}
        >
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.bookingPersonsGoalForPeriod}
          </CustomText>
          <CustomText mt="s" variant="content" color="primaryTextColor">
            Objectif
          </CustomText>
        </Box>
      </Box>

      <Box marginVertical="s" alignItems="center">
        <CustomText variant="label" color="primaryTextColor">
          {Math.ceil(orderStats.fillRate)} %
        </CustomText>

        <CustomText mt="s" variant="content" color="primaryTextColor">
          {orderStats.totalPersons} - {orderStats.totalBookings} réservations
        </CustomText>
      </Box>
    </Box>
  );
};

export default BookingsByGoals;
