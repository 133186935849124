import { useMemo, useState } from "react";
import { Dimensions, FlatList, TouchableOpacity } from "react-native";

import {
  BOOKING_FLOOR_HEIGHT_MIN,
  BOOKING_FLOOR_WIDTH_MIN,
} from "../../constants/BookingFloor";
import type {
  BookingFloorFragment,
  BookingSettingsFragment,
} from "../../graphql/generated/schema";
import type { BOOKING_FLOOR_VIEW_TYPE } from "../../types";
import Box from "../Base/Box";
import AnimatedFloor from "../BookingFloor/AnimatedFloor";
import BookingFloorCard from "../BookingFloorCard";
import type { MODULE_TYPE } from "../ModuleList/common";

interface FloorViewProps {
  bookingFloors: BookingFloorFragment[];
  tableIds: string[];
  handleTableSelection: (tableId: string) => void;
  moduleType: MODULE_TYPE;
  bookingSettings?: BookingSettingsFragment;
  selectedBookingServiceId?: string;
  isSplittable?: boolean;
  bookingFloorViewType?: BOOKING_FLOOR_VIEW_TYPE;
}

const { height, width } = Dimensions.get("window");

const SCREEN_HEIGHT = height;
const SCREEN_WIDTH = width * 0.7;

const FloorView = ({
  bookingFloors,
  tableIds,
  handleTableSelection,
  moduleType,
  bookingSettings,
  selectedBookingServiceId,
  isSplittable = false,
  bookingFloorViewType,
}: FloorViewProps) => {
  const [selectedBookingFloorId, setSelectedBookingFloorId] = useState(
    bookingFloors[0]?._id || "",
  );

  const selectedBookingFloor = useMemo(
    () => bookingFloors.find(floor => floor._id === selectedBookingFloorId),
    [selectedBookingFloorId, bookingFloors],
  );

  const tables = useMemo(() => {
    if (!selectedBookingFloor) {
      return [];
    }

    return selectedBookingFloor.tables.filter(t => tableIds.includes(t._id));
  }, [selectedBookingFloor, tableIds]);

  const floorHeight = selectedBookingFloor?.height || BOOKING_FLOOR_HEIGHT_MIN;
  const floorWidth = selectedBookingFloor?.width || BOOKING_FLOOR_WIDTH_MIN;

  // const FLOOR_HEIGHT =
  //   floorHeight > SCREEN_HEIGHT - BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT
  //     ? SCREEN_HEIGHT
  //     : floorHeight;

  // const FLOOR_WIDTH = floorWidth > SCREEN_WIDTH ? SCREEN_WIDTH : floorWidth;

  // console.log("floorHeight", floorHeight);
  // console.log("floorWidth", floorWidth);
  // console.log("FLOOR_HEIGHT", FLOOR_HEIGHT);
  // console.log("FLOOR_WIDTH", FLOOR_WIDTH);

  return (
    <Box
      flex={1}
      // height="100%"
      backgroundColor="white"
    >
      <Box flex={1}>
        <Box marginVertical="s">
          <FlatList
            horizontal
            data={bookingFloors}
            showsHorizontalScrollIndicator={false}
            keyExtractor={item => item._id}
            renderItem={({ item }) => {
              const IS_SELECTED = item._id === selectedBookingFloorId;
              return (
                <TouchableOpacity onPress={() => setSelectedBookingFloorId(item._id)}>
                  <Box marginHorizontal="s">
                    <BookingFloorCard isSelected={IS_SELECTED} name={item.name} />
                  </Box>
                </TouchableOpacity>
              );
            }}
          />
        </Box>

        {selectedBookingFloor && (
          <Box
            flex={1}
            // height="100%"
            backgroundColor="floorBackground"
            borderRadius="button"
          >
            <AnimatedFloor
              selectTable={handleTableSelection}
              selectedTables={tables}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              updateTable={() => {}}
              disableMovement
              tables={selectedBookingFloor?.tables || []}
              decorations={selectedBookingFloor?.decorations || []}
              updateDecoration={() => {}}
              selectDecoration={() => {}}
              showGrid={false}
              moduleType={moduleType}
              bookingSettings={bookingSettings}
              floorHeight={floorHeight}
              floorWidth={floorWidth}
              selectedBookingServiceId={selectedBookingServiceId}
              isSplittable={isSplittable}
              bookingFloorViewType={bookingFloorViewType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FloorView;
