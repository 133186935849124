import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { ScrollView } from "react-native";

import FINGER from "../../../../../../../assets/icons/BASE/FINGER.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import LINKS from "../../../../../../../assets/icons/BASE/LINKS.svg";
import REQUIRED_LINK from "../../../../../../../assets/icons/BASE/REQUIRED_LINK.svg";
import SPEED from "../../../../../../../assets/icons/BASE/SPEED.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { ProductGroupOptionFragment } from "../../../../../../graphql/generated/schema";
import { useGetProductGroupOptionLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../../utils/common";

interface GroupOptionsListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const GroupOptionsListDetailsView = ({
  id,
  goBack,
}: GroupOptionsListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [groupOption, setGroupOption] = useState<ProductGroupOptionFragment | null>(null);

  const [getGroupOption] = useGetProductGroupOptionLazyQuery();

  const handleGetGroupOption = async () => {
    try {
      const { data } = await getGroupOption({
        variables: {
          id,
        },
      });

      if (data?.getProductGroupOption) {
        setGroupOption(data.getProductGroupOption);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetGroupOption();
    }, []),
  );

  if (loading) {
    return <Loader />;
  }

  if (!groupOption) {
    return (
      <Box>
        <ScreenHeader
          title="Groupe d'option"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données." />
      </Box>
    );
  }

  const displayOptions = groupOption.options.map(option => option.name).join("\n");

  const displayMinMaxSelection = () => {
    const { selectionSettings } = groupOption;
    const { minSelection, maxSelection } = selectionSettings;

    return `Minimum: ${minSelection} \nMaximum: ${maxSelection}`;
  };

  const displayExtraSelection = () => {
    const { selectionSettings } = groupOption;
    const { authoriseExtraSelection, extraPrice } = selectionSettings;

    const extraPriceFormatted = formatCurrencyPrice(extraPrice.amount, "fr", "eur");

    if (authoriseExtraSelection) {
      return `Oui\n${extraPriceFormatted}`;
    }
    return "Non";
  };

  return (
    <Box>
      <ScreenHeader
        title={groupOption.name}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
        style={{ marginTop: 20 }}
      >
        {groupOption.description && (
          <TabTextInfo
            {...{
              title: "Description",
              content: groupOption.description || "-",
              icon: (
                <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Contenu du groupes",
            content: displayOptions,
            icon: <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
        <TabTextInfo
          {...{
            title: "Requis",
            content: displayMinMaxSelection(),
            icon: (
              <REQUIRED_LINK
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Autoriser une option à être sélectionnée plusieurs fois ",
            content: groupOption.selectionSettings.allowDuplicate ? "Oui" : "Non",
            icon: (
              <FINGER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Dépasser la quantité maximum moyennant un supplément",
            content: displayExtraSelection(),
            icon: <SPEED height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default GroupOptionsListDetailsView;
