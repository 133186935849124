import { useContext, useMemo, useState } from "react";
import { Dimensions, FlatList } from "react-native";

import Box from "../../../../../components/Base/Box";
import BookingSummaryByPersons from "../../../../../components/BookingSummaryByPersons";
import ListingStatusFilter from "../../../../../components/ListingStatusFilter";
import ModuleListItemRenderer from "../../../../../components/ModuleList/ModuleListItemRenderer";
// eslint-disable-next-line max-len
import { getBookingListStatusFilters } from "../../../../../components/ModuleList/StatusFilters/BookingListStatusFilter";
import { SplitViewContext } from "../../../../../contexts/SpitViewContext";
import type { BookingListingSettings } from "../../../../../graphql/generated/schema";
import { Booking_Status, Modules } from "../../../../../graphql/generated/schema";

import { BOOKINGS, BOOKING_LISTING_PREVIEW_DATA } from "./BookingListSettingsPreviewData";

const { width } = Dimensions.get("window");
const MIN_WIDTH = width / 15;

interface BookingListingSettingsPreviewProps {
  settings: BookingListingSettings;
}

const BookingListingSettingsPreview = ({
  settings,
}: BookingListingSettingsPreviewProps) => {
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const splitViewContext = useContext(SplitViewContext);
  const {
    groupBySlotInterval,
    clientNameFormat,
    includeCancelledInAllFilter,
    groupBookingsByPersons,
    showClientComment,
    showCreationDate,
    showEventType,
  } = settings;

  const containerWidth = splitViewContext.rightContainer?.width;

  const statusFilterTabsData = useMemo(
    () =>
      getBookingListStatusFilters(
        BOOKINGS,
        selectedFilter,
        [],
        includeCancelledInAllFilter,
      ),
    [selectedFilter, includeCancelledInAllFilter],
  );

  const filteredData = useMemo(() => {
    if (selectedFilter === "ALL") {
      return BOOKING_LISTING_PREVIEW_DATA;
    }
    return {
      ...BOOKING_LISTING_PREVIEW_DATA,
      slots: BOOKING_LISTING_PREVIEW_DATA.slots.map(s => {
        return {
          ...s,
          data: s.data.filter(v => {
            if (selectedFilter === "PENDING") {
              return v.status === Booking_Status.New;
            }
            if (selectedFilter === "VALIDATED") {
              return (
                v.status === Booking_Status.Validated ||
                v.status === Booking_Status.AutomaticValidated
              );
            }
            return (
              v.status !== Booking_Status.New &&
              v.status !== Booking_Status.Validated &&
              v.status !== Booking_Status.AutomaticValidated
            );
          }),
        };
      }),
    };
  }, [selectedFilter]);

  return (
    <Box>
      <ListingStatusFilter
        {...{
          onSelected: setSelectedFilter,
          selectedStatus: selectedFilter,
          tabs: statusFilterTabsData,
          containerWidth,
        }}
      />
      <FlatList
        data={[filteredData]}
        scrollEventThrottle={16}
        keyExtractor={item => item.serviceName}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => {
          return (
            <ModuleListItemRenderer
              nameFormat={clientNameFormat}
              groupBySlotInterval={groupBySlotInterval}
              item={item}
              type={Modules.Reservation}
              isDisabled
              isExtraActionButtonDisabled
              isTablePressDisabled
              isStatusChangeDisabled
              showClientComment={showClientComment}
              showCreationDate={showCreationDate}
              showEventType={showEventType}
            />
          );
        }}
      />
      {groupBookingsByPersons && (
        <Box mt="s">
          <BookingSummaryByPersons
            bookings={BOOKINGS}
            itemMinWidth={(containerWidth || width) / 15}
          />
        </Box>
      )}
    </Box>
  );
};

export default BookingListingSettingsPreview;
