import EIWIE from "../../../../assets/icons/BASE/EIWIE.svg";
import LOCATION from "../../../../assets/icons/BASE/LOCATION.svg";
import EMAIL from "../../../../assets/icons/BASE/ORIGINS/EMAIL.svg";
import FACEBOOK from "../../../../assets/icons/BASE/ORIGINS/FACEBOOK.svg";
import FAIRTRIP from "../../../../assets/icons/BASE/ORIGINS/FAIRTRIP.svg";
import GOOGLE from "../../../../assets/icons/BASE/ORIGINS/GOOGLE.svg";
import HOTEL from "../../../../assets/icons/BASE/ORIGINS/HOTEL.svg";
import INSTAGRAM from "../../../../assets/icons/BASE/ORIGINS/INSTAGRAM.svg";
import LASTABLE from "../../../../assets/icons/BASE/ORIGINS/LASTABLE.svg";
import MARKETING from "../../../../assets/icons/BASE/ORIGINS/MARKETING.svg";
import MICHELIN from "../../../../assets/icons/BASE/ORIGINS/MICHELIN.svg";
import OPENTABLE from "../../../../assets/icons/BASE/ORIGINS/OPENTABLE.svg";
import OUBRANCHER from "../../../../assets/icons/BASE/ORIGINS/OUBRANCHER.svg";
import RESTOPOLITAIN from "../../../../assets/icons/BASE/ORIGINS/RESTOPOLITAIN.svg";
import SMARTPHONE from "../../../../assets/icons/BASE/ORIGINS/SMARTPHONE.svg";
import SMS from "../../../../assets/icons/BASE/ORIGINS/SMS.svg";
import THEFORK from "../../../../assets/icons/BASE/ORIGINS/THEFORK.svg";
import WEB from "../../../../assets/icons/BASE/WEB.svg";
import { Origin } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";

interface BookingListCardOriginProps {
  origin: Origin;
}

const BookingListCardOrigin = ({ origin }: BookingListCardOriginProps) => {
  const determineCorrectOriginIcon = () => {
    switch (origin) {
      case Origin.Google:
        return <GOOGLE fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Iframe:
        return <WEB fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Local:
        return <LOCATION fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Mobile:
        return <SMARTPHONE fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Phone:
        return <SMARTPHONE width={20} height={20} fill={PALETTE.lightGrey} />;
      case Origin.Web:
        return <EIWIE fill={PALETTE.green} width={20} height={20} />;
      case Origin.Email:
        return <EMAIL fill={PALETTE.green} width={20} height={20} />;
      case Origin.Sms:
        return <SMS fill={PALETTE.green} width={20} height={20} />;
      case Origin.Facebook:
        return <FACEBOOK fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Instagram:
        return <INSTAGRAM fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.TheFork:
        return <THEFORK fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.LasTable:
        return <LASTABLE fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Michelin:
        return <MICHELIN fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Fairtrip:
        return <FAIRTRIP fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.Marketing:
        return <MARKETING fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.HotelClient:
        return <HOTEL fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.OuBruncher:
        return <OUBRANCHER fill={PALETTE.lightGrey} width={20} height={20} />;
      case Origin.OpenTable:
        return <OPENTABLE fill={PALETTE.lightGrey} width={20} height={20} />;

      // case Origin.BusinessTable:
      //   return <OPENTABLE fill={PALETTE.green} width={20} height={20} />;
      // case Origin.Oral:
      //   return <OPENTABLE fill={PALETTE.green} width={20} height={20} />;
      case Origin.Restopolitain:
        return <RESTOPOLITAIN fill={PALETTE.lightGrey} width={20} height={20} />;

      default:
        return <EIWIE fill={PALETTE.green} width={20} height={20} />;
    }
  };

  return (
    <Box alignItems="center" justifyContent="center">
      {determineCorrectOriginIcon()}
    </Box>
  );
};

export default BookingListCardOrigin;
