import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { TAB_CONFIG } from "../TabsConfig";

import BookingStats from "./Booking";
import OnlineOrderStats from "./OnlineOrders/index";
import type { StatsTabsParamList } from "./ParamList";

const Tab = createMaterialTopTabNavigator<StatsTabsParamList>();

function MyTabs() {
  return (
    <Tab.Navigator
      sceneContainerStyle={{ overflow: "visible" }}
      screenOptions={TAB_CONFIG}
      initialRouteName="BOOKINGS_STATS_TAB"
    >
      {/* <Tab.Screen
        options={{ title: "Géneral" }}
        name="GENERAL_STATS_TAB"
        component={GeneralStats}
      /> */}

      <Tab.Screen
        options={{ title: "Réservation" }}
        name="BOOKINGS_STATS_TAB"
        component={BookingStats}
      />
      <Tab.Screen
        options={{ title: "Vente" }}
        name="SALES_STATS_TAB"
        component={OnlineOrderStats}
      />
    </Tab.Navigator>
  );
}

const Stats = () => {
  return <MyTabs />;
};

export default Stats;
