import { useNavigation } from "@react-navigation/native";
import { useState } from "react";

import STATS from "../../../../assets/icons/BASE/STATS.svg";
import Box from "../../../components/Base/Box";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem/index";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList/index";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

import PerformanceSettings from "./ReportSettings";
import StatGoalsSettings from "./StatGoals/index";
interface PerformanceAndStatSettingsProps {
  goBack?: () => void;
}

const PerformanceAndStatSettings = ({ goBack }: PerformanceAndStatSettingsProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<string | undefined>();

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Statistiques & Rapports"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Statistiques"
              subText="Customiser vos objectifs"
              onPress={() => handleNavigation("STAT_SETTINGS")}
              bottomLineWidth={0}
              icon={<STATS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
            />
            {/* <SettingsButtonItem
              title="Rapports"
              subText="Personnaliser les rapports et destinataires "
              onPress={() => handleNavigation("PERFORMANCE_SETTINGS")}
              bottomLineWidth={0}
            /> */}
          </Box>
        </Box>
      </Box>
    );
  };

  const handleCloseSelectedTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "PERFORMANCE_SETTINGS":
        return <PerformanceSettings goBack={handleCloseSelectedTab} />;
      case "STAT_SETTINGS":
        return <StatGoalsSettings goBack={handleCloseSelectedTab} />;

      default:
        break;
    }
  };

  const displayContent = () => {
    if (selectedTab) return displaySelectedTab();

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default PerformanceAndStatSettings;
