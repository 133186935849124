import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import {
  GetMerchantPersonnelSettingsDocument,
  LoginStrategy,
  useGetMerchantPersonnelSettingsQuery,
  useUpdateMerchantPersonnelSettingsMutation,
} from "../../../../graphql/generated/schema";

const METHOD_CONNECTIONS = [
  {
    label: "Mot de passe",
    key: LoginStrategy.Password,
  },

  {
    label: "Code PIN",
    key: LoginStrategy.LoginCode,
  },
  {
    label: "Utilisateur & mot de passe",
    key: LoginStrategy.UserPassword,
  },
  {
    label: "Utilisateur & code pin",
    key: LoginStrategy.UserLoginCode,
  },
];

const AuthentificationMethod = () => {
  const navigation = useNavigation();
  const [selectedConnectionMethod, setSelectedConnectionMethod] =
    useState<LoginStrategy | null>(null);

  const { loading, data, error } = useGetMerchantPersonnelSettingsQuery();
  const [updateMerchantPersonnelSettings] = useUpdateMerchantPersonnelSettingsMutation();

  const handleChangeConnectionMethod = (methods: string[]) => {
    const [method] = methods;
    setSelectedConnectionMethod(method as LoginStrategy);
  };

  const handleSubmit = async () => {
    try {
      if (selectedConnectionMethod) {
        await updateMerchantPersonnelSettings({
          variables: {
            settings: {
              loginStrategy: selectedConnectionMethod,
            },
          },
          refetchQueries: [
            {
              query: GetMerchantPersonnelSettingsDocument,
            },
          ],
        });
      }
    } catch (err) {
      console.log("err update merchant personeel settings", err);
    } finally {
      navigation.goBack();
    }
  };

  useEffect(() => {
    if (data) {
      setSelectedConnectionMethod(data.getMerchantPersonnelSettings.loginStrategy);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          error...
        </CustomText>
      </Box>
    );
  }

  return (
    <Box flex={1} paddingHorizontal="s" backgroundColor="white">
      <Box mt="m" marginVertical="s">
        <ScreenHeader
          title="Méthode d'authentification"
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>

      {selectedConnectionMethod && (
        <Box mt="s">
          <SelectButton
            isMultiSelect={false}
            options={METHOD_CONNECTIONS}
            selectedOptions={[selectedConnectionMethod]}
            onPress={handleChangeConnectionMethod}
            placeHolder="Authentification"
          />
        </Box>
      )}

      <BottomButton onPress={handleSubmit} title="Enregistrer" />
    </Box>
  );
};

export default AuthentificationMethod;
