import { useContext, useState } from "react";
import { Platform } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import BLACK_EIWIE_ICON from "../../../assets/icons/BASE/BLACK_EIWIE_ICON.svg";
import MAIL from "../../../assets/icons/BASE/MAIL.svg";
import Box from "../../components/Base/Box";
import { CustomText } from "../../components/Base/Text";
import { CustomButton } from "../../components/Button";
import KeyboardAwareScrollView from "../../components/KeyboardAwareScrollView/index";
import SelectButton from "../../components/Select/SelectButton";
import { CustomTextInput } from "../../components/TextInput";
import { AppContext } from "../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MerchantPersonnelFragment } from "../../graphql/generated/schema";
import {
  Login_By_Licence_Key_Code,
  useGetMerchantPersonnelsByLicenceKeyLazyQuery,
  useLoginByLicenceKeyMutation,
  useLoginMerchantPersonnelMutation,
} from "../../graphql/generated/schema";
import { captureAndReportErrors } from "../../sentry";
import { PALETTE } from "../../theme/Palette";

const ICON_SIZE = 21;

const IS_WEB = Platform.OS === "web";

const Login = () => {
  useResizeMode();
  const appContext = useContext(AppContext);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [licenceKey, setLicenceKey] = useState("");
  const [isMerchantPersonnelLogin, setIsMerchantPersonnelLogin] = useState(false);
  const [merchantPersonnels, setMerchantPersonnels] = useState<
    MerchantPersonnelFragment[]
  >([]);
  const [staffId, setStaffId] = useState("");
  const [staffPassword, setStaffPassword] = useState("");

  const [loginByLicenceKey] = useLoginByLicenceKeyMutation();
  const [loginByStaffIdAndPassword] = useLoginMerchantPersonnelMutation();
  const [getMerchantPersonnels] = useGetMerchantPersonnelsByLicenceKeyLazyQuery();

  const merchantPersonnelOptions = merchantPersonnels.map(merchantPersonnel => ({
    label: `${merchantPersonnel.firstName || ""} ${merchantPersonnel.lastName || ""}`,
    key: merchantPersonnel._id,
  }));

  const handleGetMerchantPersonnels = async (key: string) => {
    try {
      const { error, data } = await getMerchantPersonnels({
        variables: {
          licenceKey: key,
        },
      });

      if (data?.getMerchantPersonnelsByLicenceKey) {
        setMerchantPersonnels(data.getMerchantPersonnelsByLicenceKey);
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GETTING_MERCHANT_PERSONNELS",
            message: "Une erreur est survenue lors de la récupérations des données.",
          },
        ],
        "error",
      );

      captureAndReportErrors(err);
      console.log("err", err);
    }
  };

  const handleOwnerLogin = async () => {
    try {
      const { data } = await loginByLicenceKey({
        variables: {
          licenceKey,
        },
      });

      if (data) {
        const isStaffLogin =
          data.loginByLicenceKey.code ===
          Login_By_Licence_Key_Code.LoginByLicenceKeySuccessAdditionalLoginRequired;

        if (isStaffLogin) {
          await handleGetMerchantPersonnels(licenceKey);
          setIsMerchantPersonnelLogin(true);
        } else {
          const apiToken = data.loginByLicenceKey.token;
          appContext?.loginUser(apiToken, licenceKey, "");
          // handleLoginUserAndAssociateDevice(data.loginByLicenceKey.token);
        }
      }
    } catch (err) {
      console.log("err owner login", JSON.stringify(err, null, 2));

      captureAndReportErrors(err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_LOGGING_OWNER",
            message:
              "La clé de licence que vous avez fourni ne correspond à aucun compte.",
          },
        ],
        "error",
      );
    }
  };

  const handleStaffLogin = async () => {
    if (!staffId || !staffPassword) return;

    try {
      const { data } = await loginByStaffIdAndPassword({
        variables: {
          licenceKey,
          staffId,
          password: staffPassword,
        },
      });

      if (data?.loginMerchantPersonnel) {
        const apiToken = data.loginMerchantPersonnel;
        appContext?.loginUser(apiToken, licenceKey, staffId);
      }
    } catch (err) {
      captureAndReportErrors(err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_LOGGING_IN",
            message: "Les identifants fournis ne sont pas valides.",
          },
        ],
        "error",
      );
      console.log("err staff login", JSON.stringify(err, null, 2));
    }
  };

  const handleLogin = async () => {
    if (!licenceKey) return;

    try {
      if (isMerchantPersonnelLogin) {
        await handleStaffLogin();
      } else {
        await handleOwnerLogin();
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err", err);
    }
  };

  const isDisabled =
    licenceKey.length === 0 ||
    (isMerchantPersonnelLogin && (!staffId || staffPassword.trim().length === 0));

  return (
    <Box
      flex={1}
      alignItems="center"
      justifyContent="center"
      backgroundColor="loginBackground"
    >
      <Box
        width="100%"
        height={{
          phone: "90%",
          tablet: "auto",
        }}
        maxWidth={IS_WEB ? 400 : undefined}
        position={{
          phone: "absolute",
          tablet: "relative",
        }}
        bottom={0}
        backgroundColor="loginForeground"
        borderRadius="modal"
      >
        <KeyboardAwareScrollView>
          <Box
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginVertical="xl"
          >
            <BLACK_EIWIE_ICON />
          </Box>

          <Box marginHorizontal="m">
            <CustomTextInput
              {...{
                onChangeText: setLicenceKey,
                placeHolder: "Identifiant",
                leftIcon: (
                  <MAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                ),
              }}
            />

            {isMerchantPersonnelLogin && (
              <Box mt="s">
                <SelectButton
                  isMultiSelect={false}
                  options={merchantPersonnelOptions}
                  selectedOptions={staffId ? [staffId] : []}
                  onPress={([id]) => setStaffId(id)}
                  placeHolder="Utilisateur"
                />

                <CustomTextInput
                  {...{
                    onChangeText: setStaffPassword,
                    placeHolder: "Mot de passe",
                    leftIcon: (
                      <MAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                    ),
                    secureTextEntry: true,
                    boxProps: {
                      mt: "s",
                    },
                  }}
                />
              </Box>
            )}

            {appContext.isSplittable && (
              <Box mt="xl" pb="m">
                {isMerchantPersonnelLogin && (
                  <CustomButton
                    buttonVariant="outlineButton"
                    borderColor="danger"
                    onPress={() => setIsMerchantPersonnelLogin(false)}
                    styles={{
                      mb: "s",
                    }}
                  >
                    <CustomText variant="outlineButtonText" color="danger">
                      Réinitialiser
                    </CustomText>
                  </CustomButton>
                )}

                <CustomButton
                  buttonVariant="primaryButton"
                  buttonColor={isDisabled ? "disabled" : "success"}
                  disabled={isDisabled}
                  onPress={handleLogin}
                >
                  <CustomText
                    variant="primaryButtonText"
                    color={isDisabled ? "lightGrey" : "white"}
                  >
                    Connexion
                  </CustomText>
                </CustomButton>
              </Box>
            )}
          </Box>
        </KeyboardAwareScrollView>
      </Box>

      {!appContext.isSplittable && (
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          p="m"
          pb="xl"
          backgroundColor="white"
        >
          {isMerchantPersonnelLogin && (
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={() => setIsMerchantPersonnelLogin(false)}
              styles={{
                mb: "s",
              }}
            >
              <CustomText variant="outlineButtonText" color="danger">
                Réinitialiser
              </CustomText>
            </CustomButton>
          )}

          <CustomButton
            buttonVariant="primaryButton"
            buttonColor={isDisabled ? "disabled" : "success"}
            disabled={isDisabled}
            onPress={handleLogin}
            styles={{
              mb: "s",
            }}
          >
            <CustomText
              variant="primaryButtonText"
              color={isDisabled ? "lightGrey" : "white"}
            >
              Connexion
            </CustomText>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default Login;
