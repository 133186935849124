import React from "react";
import { StyleSheet } from "react-native";

import DISALLOW from "../../../../../assets/icons/BASE/DISALLOW.svg";
import HOME from "../../../../../assets/icons/BASE/HOME.svg";
import OCCURENCES from "../../../../../assets/icons/BASE/OCCURENCES.svg";
import type { THEME_COLORS } from "../../../../theme";
import { ICON_SIZE } from "../../../../theme";
import type { PALETTE_COLORS } from "../../../../theme/Palette";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomButton } from "../../../Button";

interface TimeSlotSelectionProps {
  time: string;
  isSelected: boolean;
  onPress: (time: string) => void;
  slotText: string;
  serviceText: string;
  slotIconColor: PALETTE_COLORS;
  serviceIconColor: PALETTE_COLORS;
  textColor?: THEME_COLORS;
  areLimitsEnabled: boolean;
  showTimeSlotDisallowIcon?: boolean;
  disabled?: boolean;
}

const styles = StyleSheet.create({});

const TimeSlotSelection = ({
  time,
  isSelected,
  onPress,
  slotText,
  serviceText,
  slotIconColor,
  serviceIconColor,
  textColor = "lightGrey",
  areLimitsEnabled,
  showTimeSlotDisallowIcon = true,
  disabled = false,
}: TimeSlotSelectionProps) => {
  const SELECTED_STYLES: React.ComponentProps<typeof Box> = {
    height: 45,
    backgroundColor: "white",
    borderColor: "success",
    borderWidth: 1,
    ...styles,
  };

  const UNSELECTED_STYLES: React.ComponentProps<typeof Box> = {
    height: 45,
    backgroundColor: "disabled",
    borderWidth: 0,
    ...styles,
  };

  return (
    <CustomButton
      disabled={disabled}
      onPress={() => onPress(time)}
      buttonVariant="primaryButton"
      styles={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: "s",
        ...(isSelected ? SELECTED_STYLES : UNSELECTED_STYLES),
      }}
    >
      <Box flexDirection="row" alignItems="center">
        {showTimeSlotDisallowIcon && <DISALLOW fill={PALETTE[slotIconColor]} />}
        <CustomText ml="s" variant="label" color="primaryTextColor">
          {time}
        </CustomText>
      </Box>
      {
        // areLimitsEnabled
        serviceText || slotText ? (
          <Box flexDirection="row" alignItems="center">
            {slotText && (
              <Box flexDirection="row" alignItems="center">
                <OCCURENCES
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE[slotIconColor]}
                />
                <CustomText variant="paragraph" color={textColor} pl="s">
                  {slotText}
                </CustomText>
              </Box>
            )}

            {serviceText && (
              <Box flexDirection="row" alignItems="center" pl="s">
                <HOME
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE[serviceIconColor]}
                />
                <CustomText variant="paragraph" color={textColor} pl="s">
                  {serviceText}
                </CustomText>
              </Box>
            )}
          </Box>
        ) : (
          <></>
        )
      }
    </CustomButton>
  );
};

export default TimeSlotSelection;
