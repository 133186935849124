import { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import type { SmsPackFragment } from "../../graphql/generated/schema";
import { useGetSmsPacksLazyQuery } from "../../graphql/generated/schema";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";
import Loader from "../Loader";

import SmsPackItem from "./SmsPackItem";

interface SmsPacksPurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (smsPackId: string) => void;
}

const SmsPacksPurchaseModal = ({
  isOpen,
  onClose,
  onSubmit,
}: SmsPacksPurchaseModalProps) => {
  const [loading, setLoading] = useState(true);
  const [selectedSmsPackId, setSelectedSmsPackId] = useState("");
  const [smsPacks, setSmsPacks] = useState<SmsPackFragment[]>([]);

  const [getSmsPacks] = useGetSmsPacksLazyQuery();

  const handleGetSmsPacks = async () => {
    try {
      const { data } = await getSmsPacks();

      if (data?.getSmsPacks) {
        const allSmsPacks: SmsPackFragment[] = JSON.parse(
          JSON.stringify(data.getSmsPacks),
        );
        setSmsPacks(allSmsPacks?.sort((a, b) => a.price - b.price));
      }
    } catch (err) {
      console.log("err gettings sms packs", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSmsPacks();
  }, []);

  const handleOnClose = () => {
    onClose();
    setSelectedSmsPackId("");
  };

  const handleSubmit = () => {
    onSubmit(selectedSmsPackId);
    handleOnClose();
  };

  if (loading) return <Loader />;

  const isDisabled = !selectedSmsPackId;

  return (
    <ContentModal isVisible={isOpen} onClose={handleOnClose} title="Rechargez vos SMS">
      <>
        <Box>
          <FlatList
            data={smsPacks}
            keyExtractor={item => item._id}
            renderItem={({ item }) => {
              const IS_SELECTED = selectedSmsPackId === item._id;

              return (
                <TouchableOpacity onPress={() => setSelectedSmsPackId(item._id)}>
                  <Box mb="s">
                    <SmsPackItem smsPack={item} isSelected={IS_SELECTED} />
                  </Box>
                </TouchableOpacity>
              );
            }}
          />
        </Box>
        <Box mt="m">
          <CustomButton
            onPress={handleSubmit}
            buttonVariant="primaryButton"
            buttonColor={isDisabled ? "disabled" : "success"}
            disabled={isDisabled}
          >
            <CustomText
              variant="primaryButtonText"
              color={isDisabled ? "lightGrey" : "white"}
            >
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </>
    </ContentModal>
  );
};

export default SmsPacksPurchaseModal;
