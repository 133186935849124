/* eslint-disable @typescript-eslint/no-empty-function */
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import { CustomTextInput } from "../../TextInput";

const Location = () => {
  return (
    <Box>
      <Box mt="m">
        <CustomTextInput placeHolder="Lieu" onChangeText={text => {}} isRequired />
      </Box>

      <Box mt="m">
        <CustomButton
          buttonVariant="outlineButton"
          borderColor="danger"
          onPress={() => {}}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="outlineButtonText" color="danger">
            Supprimer
          </CustomText>
        </CustomButton>
      </Box>
      <Box mt="m">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="success"
          onPress={() => {}}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            ENREGISTRER
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Location;
