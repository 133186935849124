import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ActivityIndicator, FlatList } from "react-native";

import CALENDAR from "../../../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../../../assets/icons/BASE/CUTLERY.svg";
import OCCURENCES from "../../../../../assets/icons/BASE/OCCURENCES.svg";
import type { GetOrderTopClientsQuery } from "../../../../graphql/generated/schema";
import { useGetOrderTopClientsLazyQuery } from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../utils/common";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface OrdersTopClientsProps {
  startDate: Date;
  endDate: Date;
}

const OrdersTopClients = ({ startDate, endDate }: OrdersTopClientsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookingsStats, setBookingsStats] = useState<
    GetOrderTopClientsQuery["getOrderTopClients"] | null
  >(null);

  const [getBookingsTotalRecap] = useGetOrderTopClientsLazyQuery();

  const handleGetBookingsTopClients = async () => {
    try {
      const { data } = await getBookingsTotalRecap({
        fetchPolicy: "cache-and-network",
        variables: {
          startDate,
          endDate,
        },
      });

      if (data?.getOrderTopClients) {
        setBookingsStats(data.getOrderTopClients);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTopClients();
  }, [startDate, endDate]);

  if (isLoading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!bookingsStats) return null;

  return (
    <Box
      flex={1}
      borderRadius="button"
      borderColor="lightGrey"
      borderWidth={LINE_THICKNESS}
      p="s"
    >
      <Box p="s">
        <CustomText variant="content" color="primaryTextColor">
          Top 10 des clients
        </CustomText>
      </Box>

      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt="s"
        pb="s"
        borderBottomColor="disabled"
        borderBottomWidth={LINE_THICKNESS}
      >
        <Box flex={0.6}>
          <CUTLERY width={18} height={18} fill={PALETTE.darkBlue} />
        </Box>
        <Box flex={0.15} alignItems="flex-start">
          <OCCURENCES width={18} height={18} fill={PALETTE.darkBlue} />
        </Box>
        <Box flex={0.15} alignItems="flex-end">
          <CALENDAR width={18} height={18} fill={PALETTE.darkBlue} />
        </Box>
      </Box>

      <FlatList
        scrollEnabled={false}
        data={bookingsStats}
        keyExtractor={(_item, idx) => idx.toString()}
        ListEmptyComponent={
          <Box flex={1} alignItems="center" justifyContent="center" marginVertical="m">
            <CustomText variant="content" color="primaryTextColor">
              Aucune donnée
            </CustomText>
          </Box>
        }
        renderItem={({ item }) => (
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt="s"
          >
            <Box flex={0.6}>
              <CustomText variant="content" color="primaryTextColor">
                {item?.client?.lastName?.toUpperCase() || ""}{" "}
                {item?.client?.firstName || ""}
              </CustomText>
            </Box>
            <Box alignItems="flex-start">
              <CustomText variant="content" color="primaryTextColor">
                {format(new Date(item?.lastDate), "dd/MM/yyyy")}
              </CustomText>
            </Box>
            <Box alignItems="flex-end">
              <CustomText variant="label" color="primaryTextColor">
                {formatCurrencyPrice(item.amount, "fr", "eur")}
              </CustomText>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default OrdersTopClients;
