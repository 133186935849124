import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import TaxCard from "../../../../../components/TaxCard";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { TaxFragment } from "../../../../../graphql/generated/schema";
import {
  useDeleteTaxMutation,
  useGetTaxesLazyQuery,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import TaxListDetails from "../TaxListDetails";

interface TaxListProps {
  goBack?: () => void;
}

const TaxList = ({ goBack }: TaxListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState<TaxFragment[]>([]);
  const [taxId, setTaxId] = useState("");
  const [showTaxInline, setShowTaxInline] = useState(false);

  const [getTaxes, { fetchMore }] = useGetTaxesLazyQuery();
  const [deleteTax] = useDeleteTaxMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: taxes.length,
          },
        },
      });

      if (data?.getTaxes) {
        setTaxes([...taxes, ...data.getTaxes]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetTaxes = async () => {
    try {
      const { data } = await getTaxes({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getTaxes) {
        setTaxes(data.getTaxes);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetTaxes();
    }, []),
  );

  const handleDelete = async (id: string) => {
    try {
      await deleteTax({ variables: { taxId: id } });
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETE_TAX",
            path: "ERROR_DELETE_TAX",
            message: "Une erreur est survenue lors de la suppression de la taxe",
          },
        ],
        "error",
      );
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setShowTaxInline(true);
      setTaxId("");
    } else {
      navigation.navigate("TAX_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setTaxId(id);
      setShowTaxInline(true);
    } else {
      navigation.navigate("TAX_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetTaxes();
    setShowTaxInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <TaxListDetails taxId={taxId} newValue={!taxId} goBack={handleCloseSelectedTab} />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box>
          <ScreenHeader
            title="Taxes"
            rightButtons={
              <TouchableOpacity onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </TouchableOpacity>
            }
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>
        <Box marginVertical="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Taxes actives
          </CustomText>
        </Box>

        <FlatList
          data={taxes}
          keyExtractor={item => item._id}
          onEndReachedThreshold={0.5}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TaxCard
                name={item.name}
                taxRate={item.taxRate}
                onPress={() => handleEdit(item._id)}
                onDelete={() => handleDelete(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showTaxInline) {
      return displaySelectedTab();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default TaxList;
