import React from "react";
import { TouchableOpacity } from "react-native";

import CART from "../../../../assets/icons/BASE/CART.svg";
import DOWN_ARROW from "../../../../assets/icons/BASE/DOWN_ARROW.svg";
import OCCURENCES from "../../../../assets/icons/BASE/OCCURENCES.svg";
import PERSONS from "../../../../assets/icons/BASE/PERSONS.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { Modules } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

const HEIGHT = 27;
const INFO_WIDTH = 20;
const ICON_SIZE = 16;
export const COLLAPSE_ARROW_WIDTH = 15;

type Props = {
  moduleType: Modules;
  isClosed?: boolean;
  time: string;
  totalBookingsText: string;
  totalPersonsText: string;
  // leftIcon?: JSX.Element;
  onPress: () => void;
  boxProps?: React.ComponentProps<typeof Box>;
};

export const ServiceListHeaderItem = ({
  time,
  moduleType,
  totalBookingsText,
  totalPersonsText,
  // leftIcon,
  isClosed,
  boxProps,
  onPress,
}: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="disabled"
        paddingHorizontal="s"
        width={"100%"}
        height={HEIGHT}
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center">
          <CustomText variant="listTitle" color="primaryTextColor">
            {time}
          </CustomText>
        </Box>

        <Box flexDirection="row">
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            {moduleType === Modules.Reservation && (
              <PERSONS height={ICON_SIZE} fill={PALETTE.darkBlue} />
            )}
            {moduleType === Modules.OnlineSales && <CART fill={PALETTE.darkBlue} />}
            <CustomText
              variant="listTitle"
              textTransform={"capitalize"}
              paddingHorizontal="s"
              color="primaryTextColor"
            >
              {totalPersonsText}
            </CustomText>
          </Box>

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pl="s"
          >
            {/* {leftIcon} */}
            {moduleType === Modules.Reservation && (
              <OCCURENCES width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.darkBlue} />
            )}
            {moduleType === Modules.OnlineSales && (
              <OCCURENCES width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.darkBlue} />
            )}
            {/* <PaxIcon height={16} width={16} fill={PALETTE.darkBlue} /> */}

            <CustomText
              variant="listTitle"
              textTransform={"capitalize"}
              paddingHorizontal="s"
              color="primaryTextColor"
            >
              {totalBookingsText}
            </CustomText>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            pl="s"
            width={COLLAPSE_ARROW_WIDTH}
          >
            {isClosed ? (
              <RIGHT_ARROW height={16} width={16} fill={PALETTE.darkBlue} />
            ) : (
              <DOWN_ARROW height={16} width={16} fill={PALETTE.darkBlue} />
            )}
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
