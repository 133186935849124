import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import MODULE_AVAILABILITY from "../../../../../assets/icons/BASE/MODULE_AVAILABILITY.svg";
import MODULE_CLIENT_SETTINGS from "../../../../../assets/icons/BASE/MODULE_CLIENT_SETTINGS.svg";
import MODULE_CLOSING from "../../../../../assets/icons/BASE/MODULE_CLOSING.svg";
import MODULE_FORM from "../../../../../assets/icons/BASE/MODULE_FORM.svg";
import MODULE_LISTING from "../../../../../assets/icons/BASE/MODULE_LISTING.svg";
import MODULE_SETTINGS from "../../../../../assets/icons/BASE/MODULE_SETTINGS.svg";
import Box from "../../../../components/Base/Box";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import DeliveryClosuresSettings from "../Delivery/DeliveryClosuresSettings";
import DeliveryFormSettings from "../Delivery/DeliveryFormSettings";
import DeliveryGeneralSettings from "../Delivery/DeliveryGeneralSettings";
import DeliveryHoursSettings from "../Delivery/DeliveryHoursSettings";
import DeliveryListSettings from "../Delivery/DeliveryListSettings";
import TakeAwayClientSettings from "../TakeAway/TakeAwayClientSettings/index";
import TakeAwayClosuresSettings from "../TakeAway/TakeAwayClosuresSettings";
import TakeAwayFormSettings from "../TakeAway/TakeAwayFormSettings";
import TakeAwayGeneralSettings from "../TakeAway/TakeAwayGeneralSettings";
import TakeAwayListSettings from "../TakeAway/TakeAwayListSettings";
import TakeAwayServiceList from "../TakeAway/TakeAwayServiceList";

interface OnlineSalesSettingsListProps {
  goBack?: () => void;
}

const OnlineSalesSettingsList = ({ goBack }: OnlineSalesSettingsListProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseSelectedTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "TAKE_AWAY_GENERAL_SETTINGS":
        return <TakeAwayGeneralSettings goBack={handleCloseSelectedTab} />;
      case "TAKE_AWAY_CLIENT_SETTINGS":
        return <TakeAwayClientSettings goBack={handleCloseSelectedTab} />;
      case "TAKE_AWAY_SERVICE_LIST":
        return <TakeAwayServiceList goBack={handleCloseSelectedTab} />;
      case "TAKE_AWAY_CLOSURES_SETTINGS":
        return <TakeAwayClosuresSettings goBack={handleCloseSelectedTab} />;
      case "TAKE_AWAY_FORM_SETTINGS":
        return <TakeAwayFormSettings goBack={handleCloseSelectedTab} />;
      case "TAKE_AWAY_LIST_SETTINGS":
        return <TakeAwayListSettings goBack={handleCloseSelectedTab} />;

      case "DELIVERY_GENERAL_SETTINGS":
        return <DeliveryGeneralSettings goBack={handleCloseSelectedTab} />;

      case "DELIVERY_HOURS_SETTINGS":
        return <DeliveryHoursSettings goBack={handleCloseSelectedTab} />;
      case "DELIVERY_CLOSURES_SETTINGS":
        return <DeliveryClosuresSettings goBack={handleCloseSelectedTab} />;
      case "DELIVERY_FORM_SETTINGS":
        return <DeliveryFormSettings goBack={handleCloseSelectedTab} />;
      case "DELIVERY_LIST_SETTINGS":
        return <DeliveryListSettings goBack={handleCloseSelectedTab} />;

      default:
        break;
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Commande en ligne"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box mt="m">
            <InputSectionTitle text="Général" />
          </Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Paramètres"
              subText="Gérer les paramètres principaux"
              onPress={() => handleNavigation("TAKE_AWAY_GENERAL_SETTINGS")}
              bottomLineWidth={0}
              icon={
                <MODULE_SETTINGS
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="CLIENT" />
          </Box>

          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Disponibilités"
              subText="Customiser vos horaires de commande"
              onPress={() => handleNavigation("TAKE_AWAY_SERVICE_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_AVAILABILITY
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
            <SettingsButtonItem
              title="Module de commande"
              subText="Personnaliser les messages à l’attention de vos clients"
              onPress={() => handleNavigation("TAKE_AWAY_CLIENT_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_CLIENT_SETTINGS
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
            <SettingsButtonItem
              title="Fermetures exceptionnelles"
              subText="Fermer un ou plusieurs jours, une période"
              onPress={() => handleNavigation("TAKE_AWAY_CLOSURES_SETTINGS")}
              bottomLineWidth={0}
              icon={
                <MODULE_CLOSING
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="établissement" />
          </Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Formulaire de commande"
              subText="Personnaliser les champs du formulaire lors de la prise de réservation via l’application"
              onPress={() => handleNavigation("TAKE_AWAY_FORM_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_FORM width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              }
            />
            <SettingsButtonItem
              title="Listing des commandes"
              subText="Personnaliser l’affichage de vos commandes dans l’application"
              onPress={() => handleNavigation("TAKE_AWAY_LIST_SETTINGS")}
              bottomLineWidth={0}
              icon={
                <MODULE_LISTING
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default OnlineSalesSettingsList;
