import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useMemo, useState } from "react";
import { FlatList } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import Loader from "../../../../../components/Loader";
import ProductCard from "../../../../../components/ProductCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { ProductFragment } from "../../../../../graphql/generated/schema";
import {
  useGetProductsLazyQuery,
  useUpdateProductMutation,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import ProductListDetails from "../ProductListDetails";

interface ProductListProps {
  goBack?: () => void;
}

const ProductList = ({ goBack }: ProductListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProductFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getProducts, { fetchMore }] = useGetProductsLazyQuery();
  const [updateProduct] = useUpdateProductMutation();

  const filteredProducts = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    if (formattedSearch.length === 0) {
      return products;
    }

    return products.filter(product => {
      const formattedName = product.name.toLowerCase().trim();
      const formattedSlug = product.slug.toLowerCase().trim();

      return (
        formattedName.includes(formattedSearch) || formattedSlug.includes(formattedSearch)
      );
    });
  }, [products, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: products.length,
          },
        },
      });

      if (data?.getProducts) {
        setProducts([...products, ...data.getProducts]);
      }
    } catch (err) {}
  };

  const handleGetProducts = async () => {
    try {
      const { data, error } = await getProducts({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (error) {
        console.log("ERR GET PRODUCTS", JSON.stringify(error, null, 2));
      }
      if (data?.getProducts) {
        setProducts(data.getProducts);
      }
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "PRODUCTS_LIST_GET_PRODUCT_ERROR",
            path: "PRODUCTS_LIST_GET_PRODUCT_ERROR",
            message: "Une erreur est survenue lors de la récupération des produits",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetProducts();
    }, []),
  );

  const handleProductEnableUpdate = async (id: string, isEnabled: boolean) => {
    try {
      setProducts(p => {
        return p.map(product => {
          if (product._id === id) {
            return {
              ...product,
              isEnabled,
            };
          }
          return product;
        });
      });
      await updateProduct({
        variables: {
          productId: id,
          updates: {
            isEnabled,
          },
        },
      });

      await handleGetProducts();
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "PRODUCTS_LIST_UPDATE_PRODUCT_ERROR",
            path: "PRODUCTS_LIST_UPDATE_PRODUCT_ERROR",
            message: "Une erreur est survenue lors de la mise à jour du produit",
          },
        ],
        "error",
      );
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setSelectedId("");
      setShowInline(true);
    } else {
      navigation.navigate("PRODUCTS_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedId(id);
      setShowInline(true);
    } else {
      navigation.navigate("PRODUCTS_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetProducts();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <ProductListDetails
        newValue={!selectedId}
        selectedId={selectedId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Produits"
            hasBackButton
            rightButtons={
              <Touchable onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Touchable>
            }
            onBackPress={goBack || navigation.goBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        </Box>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={filteredProducts}
          keyExtractor={item => item._id}
          onEndReachedThreshold={0.8}
          onEndReached={handleFetchMore}
          contentContainerStyle={{ paddingTop: 5 }}
          renderItem={({ item }) => (
            <Touchable onPress={() => handleEdit(item._id)}>
              <Box marginVertical="s">
                <ProductCard
                  image={item.picture}
                  name={item.name}
                  price={item.basePrice.amount}
                  isEnabled={item.isEnabled}
                  isSelected={false}
                  nbCategories={item.categories.length}
                  nbGroupOptions={item.groupOptions.length}
                  updateEnabled={val => handleProductEnableUpdate(item._id, val)}
                />
              </Box>
            </Touchable>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default ProductList;
