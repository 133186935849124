import DOOR from "../../../../assets/icons/decorations/DOOR.svg";
import FLOWER_POT from "../../../../assets/icons/decorations/FLOWER_POT.svg";
import LEFT_DESCENDING_STAIRS from "../../../../assets/icons/decorations/LEFT_DESCENDING_STAIRS.svg";
import PALM from "../../../../assets/icons/decorations/PALM.svg";
import RIGHT_DESCENDING_STAIRS from "../../../../assets/icons/decorations/RIGHT_DESCENDING_STAIRS.svg";
import SHELF from "../../../../assets/icons/decorations/SHELF.svg";
import TREE from "../../../../assets/icons/decorations/TREE.svg";
import WINDOW from "../../../../assets/icons/decorations/WINDOW.svg";
import { Decoration_Type } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";

const SIZE = 70;

export const DECORATIONS = [
  {
    name: Decoration_Type.Door,
    icon: <DOOR height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.FlowerPot,
    icon: <FLOWER_POT height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.LeftDescendingStairs,
    icon: <LEFT_DESCENDING_STAIRS height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.PalmPlant,
    icon: <PALM height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.RightDescendingStairs,
    icon: <RIGHT_DESCENDING_STAIRS height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.Grating,
    icon: <SHELF height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.Tree,
    icon: <TREE height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
  {
    name: Decoration_Type.Window,
    icon: <WINDOW height={SIZE} width={SIZE} fill={PALETTE.gray} />,
  },
];
