import { format, setDay } from "date-fns";
import { TouchableOpacity } from "react-native";

import type { THEME_COLORS } from "../../theme";
import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface DaysSelectorProps {
  selectedDays: number[];
  isMulti: boolean;
  disabledDays?: number[];
  onDayPress: (days: number[] | number) => void;
}

const DaysSelector = ({
  selectedDays,
  onDayPress,
  isMulti = true,
  disabledDays = [],
}: DaysSelectorProps) => {
  const handleDayPress = (day: number) => {
    const newDays = selectedDays.includes(day)
      ? selectedDays.filter(d => d !== day)
      : [...selectedDays, day];

    onDayPress(isMulti ? newDays : day);
  };

  const getBgColor = (day: number): THEME_COLORS => {
    const isDisabled = disabledDays.includes(day);

    if (isDisabled) return "lightGrey";

    const isSelected = selectedDays.includes(day);

    return isSelected ? "primaryTextColor" : "white";
  };

  return (
    <Box width="100%" flexDirection="row" alignItems="center">
      {[1, 2, 3, 4, 5, 6, 0].map((day, idx) => {
        const IS_FIRST = idx === 0;
        const IS_LAST = idx === [1, 2, 3, 4, 5, 6, 0].length - 1;
        const date = setDay(new Date(), day);
        const dayName = format(date, "E");
        const isSelected = selectedDays.includes(day);
        const isDisabled = disabledDays.includes(day);
        const minWidth = 100 / 7;
        return (
          <TouchableOpacity
            disabled={isDisabled}
            key={day}
            onPress={() => handleDayPress(day)}
            style={{
              width: `${minWidth}%`,
            }}
          >
            <Box
              alignItems="center"
              justifyContent="center"
              minHeight={45}
              // minWidth={`${minWidth}%`}
              borderColor="disabled"
              backgroundColor={getBgColor(day)}
              borderWidth={LINE_THICKNESS}
              borderTopLeftRadius={IS_FIRST ? "button" : "none"}
              borderBottomLeftRadius={IS_FIRST ? "button" : "none"}
              borderTopRightRadius={IS_LAST ? "button" : "none"}
              borderBottomRightRadius={IS_LAST ? "button" : "none"}
            >
              <CustomText
                variant="content"
                color={isSelected ? "white" : "primaryTextColor"}
              >
                {dayName}
              </CustomText>
            </Box>
          </TouchableOpacity>
        );
      })}
    </Box>
  );
};

export default DaysSelector;
