import { useNavigation } from "@react-navigation/native";

import Box from "../../../components/Base/Box";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";

interface CashRegisterSettingsProps {
  goBack?: () => void;
}

const CashRegisterSettings = ({ goBack }: CashRegisterSettingsProps) => {
  const navigation = useNavigation();

  const handleNavigation = (screenName: string) => {
    navigation.navigate(screenName);
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Encaissement"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <Box mt="m" paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
        <SettingsButtonItem
          title="Bande de controle"
          subText="-"
          onPress={() => handleNavigation("PRESCRIBER_LIST")}
          bottomLineWidth={0}
        />
      </Box>
    </Box>
  );
};

export default CashRegisterSettings;
