import VALIDATE from "../../../assets/icons/BASE/VALIDATE.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";

interface RadioSelectButtonProps {
  isSelected: boolean;
  boxStyles?: React.ComponentProps<typeof Box>;
  iconSize?: number;
}

const BUTTON_SIZE = 30;

const RadioSelectButton = ({
  isSelected,
  boxStyles,
  iconSize = 20,
}: RadioSelectButtonProps) => {
  return (
    <Box
      width={BUTTON_SIZE}
      height={BUTTON_SIZE}
      alignItems="center"
      justifyContent="center"
      borderColor={isSelected ? "success" : "lightGrey"}
      borderWidth={1}
      style={{
        borderRadius: BUTTON_SIZE / 2,
      }}
      {...boxStyles}
    >
      <VALIDATE
        width={iconSize}
        height={iconSize}
        fill={isSelected ? PALETTE.green : PALETTE.lightGrey}
      />
    </Box>
  );
};

export default RadioSelectButton;
