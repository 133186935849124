import type { RouteProp } from "@react-navigation/native";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import { useContext, useEffect, useState } from "react";
import { Platform, TouchableOpacity } from "react-native";

import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import DOWN_ARROW from "../../../../assets/icons/BASE/DOWN_ARROW.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import ModulListDetails from "../../../components/ModuleList/ModulListDetails";
import OrderActionsModal from "../../../components/OnlinOrdersListDetails/OrderActionsModal/index";
import type { Tab } from "../../../components/OutlineTabs";
import ToggleInputLine from "../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  OrderFragment,
  UpdateOnlineSalesOrderProInput,
} from "../../../graphql/generated/schema";
import {
  Click_Status,
  Modules,
  Payment_Type,
  useDeleteOrderMutation,
  useGetOnlineSalesAvailabilitiesWithOrderInfoLazyQuery,
  useGetOrderLazyQuery,
  useUpdateOrderMutation,
} from "../../../graphql/generated/schema";
import type { SalesStackParamList } from "../../../navigation/AppStack/SalesStack/ParamList";
import { captureAndReportErrors } from "../../../sentry";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { formatCurrencyPrice, removeTypeNames } from "../../../utils/common";

const IS_ANDROID = Platform.OS === "android";

type OnlineOrderListDetailsScreenRouteProp = RouteProp<
  SalesStackParamList,
  "ONLINE_SALES_LIST_DETAILS"
>;

interface OrderListDetailsProps {
  orderId?: string;
  goBack?: () => void;
  showGoBack?: boolean;
  onUpdateComplete?: () => void;
  refreshDetails?: boolean;
}

const TABS: Tab[] = [
  {
    title: "Commande",
    key: "ONLINE_ORDER",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
  {
    title: "Client",
    key: "CLIENT",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
  {
    title: "Timeline",
    key: "TIMELINE",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
];

const OnlineOrdersListDetails = ({
  orderId: id,
  goBack,
  showGoBack = true,
  refreshDetails,
  onUpdateComplete,
}: OrderListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute<OnlineOrderListDetailsScreenRouteProp>();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const orderId = id || params?.orderId;
  const goBackTo = params?.goBackTo;

  const [inEditMode, setInEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const [updatedOrder, setUpdatedOrder] = useState<OrderFragment | null>(null);
  const [orderAvailability, setOrderAvailability] = useState([]);
  const [sendClientNotifications, setSendClientNotifications] = useState(true);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isUneditable, setIsUneditable] = useState(false);

  // const { loading, error, data } = useGetOrderQuery({ variables: { orderid: orderId } });

  const [loading, setLoading] = useState(true);
  const [getOrder] = useGetOrderLazyQuery();

  const [getOrdersAvailability] = useGetOnlineSalesAvailabilitiesWithOrderInfoLazyQuery();

  const [updateOrder] = useUpdateOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();

  const handleGetOrderAvailability = async () => {
    if (!updatedOrder) return;

    try {
      const { data } = await getOrdersAvailability({
        variables: {
          date: updatedOrder.date,
        },
      });

      if (data?.getOnlineSalesAvailabilitiesWithOrderInfo) {
        let services = data?.getOnlineSalesAvailabilitiesWithOrderInfo || [];

        services = services.filter(service => service.serviceId !== "HS_SERVICE");
        setOrderAvailability(services);
      }
    } catch (err) {
      console.log("err order availability", err);

      captureAndReportErrors(err);
    }
  };

  const handleGetOrder = async () => {
    try {
      const { data } = await getOrder({
        // fetchPolicy: "cache-and-network",
        variables: {
          orderid: orderId,
        },
      });

      if (data?.getOrderForPro) {
        const order = data.getOrderForPro;

        const hasOrderReachedEnd =
          (order?.status === Click_Status.Ended &&
            order?.payment?.paymentType !== Payment_Type.Card) ||
          (order?.status === Click_Status.Noshow &&
            order?.payment?.paymentType !== Payment_Type.Card) ||
          order?.status === Click_Status.Refunded ||
          order?.status === Click_Status.CancelledByOwner ||
          order?.status === Click_Status.CancelledByUser;

        setIsUneditable(hasOrderReachedEnd);

        setUpdatedOrder({
          ...order,
          client: order?.merchantClient,
        });
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_BOOKING",
            message: "Une erreur est survenue lors de la récupération de la réservation",
          },
        ],
        "error",
      );

      captureAndReportErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEditMode = () => {
    setInEditMode(false);
  };

  useEffect(() => {
    handleCancelEditMode();
  }, [orderId]);

  useEffect(() => {
    handleGetOrder();
  }, [orderId, refreshDetails]);

  useEffect(() => {
    handleGetOrderAvailability();
  }, [updatedOrder?.date]);

  const handleModifyButton = async () => {
    try {
      setInEditMode(false);
      if (inEditMode) {
        if (!updatedOrder) return;

        const {
          date,
          serviceId,
          slotId,
          commentOwner,
          merchantClient,
          status,
          refusalReason = "",
        } = updatedOrder;

        const client = {
          ...merchantClient,
        };

        const dateString = new Date(date).toISOString();
        const orderUpdates: UpdateOnlineSalesOrderProInput = {
          refusalReason,
          commentOwner,
          serviceId,
          slotId,
          date: dateString,
          client,
          status,
          sendClientNotifications,
        };

        const updates = removeTypeNames(orderUpdates);
        delete updates?.client?.bookingOccurences;
        delete updates?.client?.orderOccurences;
        delete updates?.client?.ticketOccurences;
        delete updates?.client?.generatedProfit;
        delete updates?.client?.averageProfit;
        delete updates?.client?.averageRating;

        const { data } = await updateOrder({
          variables: {
            orderId,
            order: updates,
          },
        });

        if (data?.updateOrderForPro) {
          setUpdatedOrder(data.updateOrderForPro);
        }

        onUpdateComplete?.();
      }
    } catch (err) {
      console.log("err update oerder", err);

      captureAndReportErrors(err);

      infoAlert?.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteOrder({
        variables: {
          orderId,
        },
      });

      if (response.data?.deleteOrderByPro) {
        navigation.goBack();
      }
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);
    }
  };

  const handleMainButtonAction = () => {
    if (inEditMode) {
      handleModifyButton();
    } else setInEditMode(true);
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        await handleDelete();
      } else if (key === "MODIFY") {
        await handleModifyButton();
      } else if (key === "CANCEL") {
        setInEditMode(false);
      }
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);
    }
  };

  if (loading || !updatedOrder?.date) {
    return <Loader />;
  }

  if (!updatedOrder) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue, veuillez réessayer plus tard" />
      </Box>
    );
  }

  const handleOpenReceiptUrl = async () => {
    if (!updatedOrder.receiptUrl) return;

    await WebBrowser.openBrowserAsync(updatedOrder.receiptUrl, {
      windowFeatures: {
        target: "_blank",
      },
    });
  };

  const renderBottomButton = () => {
    if (isUneditable || !showGoBack) return <></>;

    const formatTotalAmount = formatCurrencyPrice(
      updatedOrder?.totalDetails?.totalWithTaxes,
      "fr",
      "eur",
    );

    return (
      <Box
        p="s"
        pb="xl"
        backgroundColor="white"
        position="absolute"
        bottom={IS_ANDROID ? 10 : 0}
        left={0}
        right={0}
      >
        {inEditMode ? (
          <Box>
            <Box paddingHorizontal="s">
              <ToggleInputLine
                text="Envoyer notification au client"
                fontSize={12}
                value={sendClientNotifications}
                onValueChange={setSendClientNotifications}
              />
            </Box>
            <Box paddingHorizontal="s" mb="s" flexDirection="row" alignItems="center">
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  SMS
                </CustomText>

                {updatedOrder?.merchantClient?.phone ? (
                  <VALIDATE width={17} height={17} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  Email
                </CustomText>

                {updatedOrder?.merchantClient?.email ? (
                  <VALIDATE width={12} height={12} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            backgroundColor="white"
            paddingVertical="s"
            paddingHorizontal="m"
            width="100%"
            flex={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pb="l"
            // bottom={100}
          >
            <CustomText
              textTransform="uppercase"
              variant="content"
              color="primaryTextColor"
            >
              Total TTC
            </CustomText>
            <CustomText variant="label" color="primaryTextColor">
              {formatTotalAmount}
            </CustomText>
          </Box>
        )}

        <Box>
          <TouchableOpacity onPress={handleMainButtonAction}>
            <Box
              flexDirection="row"
              alignItems="center"
              backgroundColor="white"
              borderRadius="button"
              borderColor="success"
              borderWidth={LINE_THICKNESS}
              p="s"
              width="100%"
              style={{ minHeight: 45 }}
            >
              <CustomText variant="primaryButtonText" color="success" style={{ flex: 1 }}>
                {inEditMode ? "Valider" : "Modifier"}
              </CustomText>

              <Box
                marginHorizontal="s"
                height="70%"
                minHeight={30}
                width={2}
                backgroundColor="success"
              />

              <TouchableOpacity
                style={{
                  flex: 0.2,
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => setIsActionsModalOpen(true)}
              >
                <Box pr="s" alignItems="center" justifyContent="center">
                  <DOWN_ARROW
                    fill={PALETTE.green}
                    // fill={disabled ? PALETTE.gray : PALETTE[iconColor]}
                  />
                </Box>
              </TouchableOpacity>
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    );
  };

  const handleCancel = () => {
    setInEditMode(false);
    setIsActionsModalOpen(false);
  };
  const handleGoBack = () => {
    if (goBackTo) {
      navigation.navigate(goBackTo?.stack, {
        screen: goBackTo?.screen,
        params: {
          id: goBackTo?.id,
        },
      });
      return;
    } else if (goBack) {
      return goBack?.();
    }
    navigation.goBack();
  };

  return (
    <>
      <ModulListDetails
        tabNames={TABS}
        services={orderAvailability}
        moduleType={Modules.OnlineSales}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        onExtraTabPress={handleOpenReceiptUrl}
        bottomButton={renderBottomButton()}
        data={updatedOrder}
        updateData={setUpdatedOrder}
        inEditMode={inEditMode}
        setInEditMode={setInEditMode}
        goBack={handleGoBack}
        showGoBack={showGoBack}
      />

      {updatedOrder && (
        <OrderActionsModal
          order={updatedOrder}
          handleSubmit={handleOnSubmit}
          isOpen={isActionsModalOpen}
          onClose={() => setIsActionsModalOpen(false)}
          handleCancel={inEditMode ? handleCancel : undefined}
        />
      )}
    </>
  );
};

export default OnlineOrdersListDetails;
