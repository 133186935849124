import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../components/Base/Box";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../theme";

import InvoiceSettings from "./InvoiceSettings";
import QuoteSettings from "./QuoteSettings";

interface QuoteInvoiceSettingsProps {
  goBack?: () => void;
}

const QuoteInvoiceSettings = ({ goBack }: QuoteInvoiceSettingsProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseSelectedTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "QUOTE_SETTINGS":
        return <QuoteSettings goBack={handleCloseSelectedTab} />;
      case "INVOICE_SETTINGS":
        return <InvoiceSettings goBack={handleCloseSelectedTab} />;
      default:
        return null;
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Devis & Facturation"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Devis"
            subText="Paramétrer les bases de vos devis"
            onPress={() => handleNavigation("QUOTE_SETTINGS")}
            bottomLineWidth={LINE_THICKNESS}
          />
          <SettingsButtonItem
            title="Factures"
            subText="Paramétrer les bases de vos factures"
            onPress={() => handleNavigation("INVOICE_SETTINGS")}
            bottomLineWidth={0}
          />
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default QuoteInvoiceSettings;
