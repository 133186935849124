import { format } from "date-fns";
import type { CalendarProps, DateData, DateObject } from "react-native-calendars";
import { Calendar, CalendarList, LocaleConfig } from "react-native-calendars";
import type { MarkingTypes } from "react-native-calendars/src/types";

import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";

LocaleConfig.locales.en = LocaleConfig.locales[""];
LocaleConfig.locales.fr = {
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Janv.",
    "Févr.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ],
  dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
};

LocaleConfig.defaultLocale = "fr";

export enum CALENDAR_VIEW {
  MONTH,
  YEAR,
}

interface CustomCalendarProps {
  // selectedDate?: Date;
  minDate?: Date;
  onDateChange: (date: Date) => void;
  markingType: MarkingTypes;
  markedDates?: { [date: string]: DateObject };
  calendarView: CALENDAR_VIEW;
  onMonthChange?: (date: DateData) => void;
}

const CALENDAR_THEME: CalendarProps["theme"] = {
  backgroundColor: "white",
  calendarBackground: "white",
  textSectionTitleColor: PALETTE.darkBlue,
  textDayFontFamily: "Lexend-Light",
  textDayHeaderFontFamily: "Lexend-Regular",
  textMonthFontFamily: "Lexend-Bold",
  selectedDayBackgroundColor: PALETTE.darkBlue,
  selectedDayTextColor: PALETTE.white,
  todayTextColor: PALETTE.green,
  dayTextColor: PALETTE.darkBlue,
  textDisabledColor: "#ccc",
  dotColor: PALETTE.green,
  selectedDotColor: PALETTE.green,
  arrowColor: PALETTE.green,
  monthTextColor: PALETTE.green,
};

const SELECTED_DAY = {
  selected: true,
  marked: true,
  selectedColor: PALETTE.green,
};

const CustomCalendar = ({
  // selectedDate,
  minDate,
  onDateChange,
  markingType,
  markedDates,
  calendarView = CALENDAR_VIEW.MONTH,
  onMonthChange,
}: CustomCalendarProps) => {
  const formattedMinDate = minDate ? format(minDate, "yyyy-MM-dd") : undefined;
  const onPress = (date: DateData) => {
    const selectedDate = new Date(date.dateString);

    onDateChange(selectedDate);
  };

  const displayCalendarView = () => {
    switch (calendarView) {
      case CALENDAR_VIEW.MONTH:
        return (
          <Calendar
            firstDay={1}
            renderArrow={direction => {
              if (direction === "left") {
                return (
                  <LEFT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                );
              }
              return (
                <RIGHT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              );
            }}
            markingType={markingType}
            minDate={formattedMinDate}
            onDayPress={onPress}
            markedDates={markedDates}
            theme={CALENDAR_THEME}
            onMonthChange={onMonthChange}
          />
        );

      case CALENDAR_VIEW.YEAR:
        return (
          <CalendarList
            renderArrow={direction => {
              if (direction === "left") {
                return (
                  <LEFT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                );
              }
              return (
                <RIGHT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              );
            }}
            firstDay={1}
            markingType={markingType}
            minDate={formattedMinDate}
            onDayPress={onPress}
            theme={CALENDAR_THEME}
            markedDates={markedDates}
            onMonthChange={onMonthChange}
          />
        );

      default:
        break;
    }
  };
  return <Box>{displayCalendarView()}</Box>;
};

export default CustomCalendar;
