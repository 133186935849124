import { useContext, useMemo, useState } from "react";
import { FlatList } from "react-native";

import Box from "../../../../../../components/Base/Box";
import ListingStatusFilter from "../../../../../../components/ListingStatusFilter/index";
import ModuleListItemRenderer from "../../../../../../components/ModuleList/ModuleListItemRenderer";
import { getOrdersStatusFilter } from "../../../../../../components/ModuleList/StatusFilters/OnlineOrdersStatusFilter";
import { SplitViewContext } from "../../../../../../contexts/SpitViewContext/index";
import type { BookingListingSettings } from "../../../../../../graphql/generated/schema";
import { Booking_Status, Modules } from "../../../../../../graphql/generated/schema";

import {
  ORDERS,
  ORDER_LISTING_PREVIEW_DATA,
} from "./TakeAwayOrderListSettingsPreviewData";

interface TakeAwayOrderListingSettingsPreviewProps {
  settings: BookingListingSettings;
}

const TakeAwayOrderListingSettingsPreview = ({
  settings,
}: TakeAwayOrderListingSettingsPreviewProps) => {
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const splitViewContext = useContext(SplitViewContext);
  const {
    groupBySlotInterval,
    clientNameFormat,
    includeCancelledInAllFilter,
    groupBookingsByPersons,
    showClientComment,
    showCreationDate,
    showEventType,
  } = settings;

  const containerWidth = splitViewContext.rightContainer?.width;

  const statusFilterTabsData = useMemo(
    () => getOrdersStatusFilter(ORDERS, selectedFilter, [], includeCancelledInAllFilter),
    [selectedFilter, includeCancelledInAllFilter],
  );

  const filteredData = useMemo(() => {
    if (selectedFilter === "ALL") {
      return ORDER_LISTING_PREVIEW_DATA;
    }
    return {
      ...ORDER_LISTING_PREVIEW_DATA,
      slots: ORDER_LISTING_PREVIEW_DATA.slots.map(s => {
        return {
          ...s,
          data: s.data.filter(v => {
            if (selectedFilter === "PENDING") {
              return v.status === Booking_Status.New;
            }
            if (selectedFilter === "VALIDATED") {
              return (
                v.status === Booking_Status.Validated ||
                v.status === Booking_Status.AutomaticValidated
              );
            }
            return (
              v.status !== Booking_Status.New &&
              v.status !== Booking_Status.Validated &&
              v.status !== Booking_Status.AutomaticValidated
            );
          }),
        };
      }),
    };
  }, [selectedFilter]);

  return (
    <Box>
      <ListingStatusFilter
        {...{
          onSelected: setSelectedFilter,
          selectedStatus: selectedFilter,
          tabs: statusFilterTabsData,
          containerWidth,
        }}
      />
      <FlatList
        data={[filteredData]}
        scrollEventThrottle={16}
        keyExtractor={item => item.serviceName}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => {
          return (
            <ModuleListItemRenderer
              nameFormat={clientNameFormat}
              groupBySlotInterval={groupBySlotInterval}
              item={item}
              type={Modules.OnlineSales}
              isDisabled
              showClientComment={showClientComment}
              showCreationDate={showCreationDate}
              showEventType={showEventType}
            />
          );
        }}
      />
    </Box>
  );
};

export default TakeAwayOrderListingSettingsPreview;
