import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface ErrorMessageProps {
  message: string;
  isWarning?: boolean;
}

const ErrorMessage = ({ message, isWarning }: ErrorMessageProps) => {
  return (
    <Box
      borderRadius="button"
      borderColor={isWarning ? "yellow" : "danger"}
      backgroundColor={isWarning ? "yellowBackground" : "redBackground"}
      borderWidth={LINE_THICKNESS}
      minHeight={45}
      justifyContent="center"
      alignItems="center"
      m="s"
      p="s"
    >
      <CustomText variant="content" color={isWarning ? "yellow" : "danger"}>
        {message || "Une erreur est survenue."}
      </CustomText>
    </Box>
  );
};

export default ErrorMessage;
