import React from "react";
import { TouchableOpacity } from "react-native";

import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface ScreenHeaderProps {
  title: string;
  description?: string;
  hasBackButton?: boolean;
  onBackPress?: () => void;
  rightButtons?: JSX.Element;
  innerBoxStyle?: React.ComponentProps<typeof Box>;
  boxStyle?: React.ComponentProps<typeof Box>;
  textStyle?: React.ComponentProps<typeof CustomText>;
}

const ScreenHeader = ({
  title,
  description,
  onBackPress,
  hasBackButton,
  rightButtons,
  innerBoxStyle,
  boxStyle,
  textStyle,
}: ScreenHeaderProps) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pb="xs"
      {...boxStyle}
    >
      <Box flexDirection="row" alignItems="flex-start" {...innerBoxStyle}>
        {hasBackButton && (
          <TouchableOpacity onPress={onBackPress}>
            <Box mr="s">
              <LEFT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </TouchableOpacity>
        )}

        <Box>
          <TouchableOpacity disabled={!hasBackButton} onPress={onBackPress}>
            <CustomText
              numberOfLines={1}
              variant="title"
              color="primaryTextColor"
              {...textStyle}
            >
              {title}
            </CustomText>
          </TouchableOpacity>

          {description && (
            <Box width="90%">
              <CustomText variant="description" color="lightGrey">
                {description}
              </CustomText>
            </Box>
          )}
        </Box>
      </Box>

      {rightButtons}
    </Box>
  );
};

export default ScreenHeader;
