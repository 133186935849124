import Constants from "expo-constants";
import * as Linking from "expo-linking";

export const getUrlScheme = () => {
  const link =
    Constants.appOwnership === "expo" ? Linking.createURL("/--/") : Linking.createURL("");

  return link;
};

const canOpenLink = async (url: string) => {
  const supported = await Linking.canOpenURL(url);
  return supported;
};

export const handlePhoneLinking = async (phoneNumber: string) => {
  try {
    const link = `tel:${phoneNumber}`;
    const supported = await canOpenLink(link);

    if (!supported) return;

    Linking.openURL(link);
  } catch (err) {}
};

export const handleEmailLinking = async (email: string) => {
  try {
    const link = `mailto:${email}`;
    const supported = await canOpenLink(link);

    if (!supported) return;

    Linking.openURL(link);
  } catch (err) {}
};
