import { format } from "date-fns";
import React from "react";

import type { PrinterErrorFragment } from "../../graphql/generated/schema";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface PrinterErrorReportListItemProps {
  printerError: PrinterErrorFragment;
}

const PrinterErrorReportListItem = ({
  printerError,
}: PrinterErrorReportListItemProps) => {
  const { createdAt, printer, errorMessage } = printerError;
  return (
    <Box>
      <Box marginVertical="s">
        <CustomText variant="label" color="primaryTextColor">
          Date
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          {format(new Date(createdAt), "dd/MM/yyyy")}
        </CustomText>
      </Box>
      <Box marginVertical="s">
        <CustomText variant="label" color="primaryTextColor">
          Imprimante
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          {printer?.name}
        </CustomText>
      </Box>
      <Box marginVertical="s">
        <CustomText variant="label" color="primaryTextColor">
          Contenu
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          {errorMessage}
        </CustomText>
      </Box>
    </Box>
  );
};

export default PrinterErrorReportListItem;
