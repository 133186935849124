import { Text, View } from "react-native";

const DeliveryClosuresSettings = () => {
  return (
    <View>
      <Text>DeliveryClosuresSettings</Text>
    </View>
  );
};

export default DeliveryClosuresSettings;
