import React from "react";
import { KeyboardProvider } from "react-native-keyboard-controller";

interface KeyboardAvoidViewProps {
  children: React.ReactNode;
}

const KeyboardAvoidViewProvider = ({ children }: KeyboardAvoidViewProps) => {
  // if (!IS_IOS) return children;

  return (
    <KeyboardProvider statusBarTranslucent navigationBarTranslucent>
      {children}
    </KeyboardProvider>
  );
};

export default KeyboardAvoidViewProvider;
