import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";

import RECEIPT from "../../../assets/icons/BASE/RECEIPT.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { SubscriptionInvoiceFragment } from "../../graphql/generated/schema";
import {
  Merchant_Subscription_Payment_Status,
  useGetMerchantSubscriptionInvoicesLazyQuery,
} from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Loader from "../Loader";
import SubscriptionCard from "../SubscriptionCard/index";

const SubscriptionInvoicesList = () => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [subscriptionInvoices, setSubscriptionInvoices] = useState<
    SubscriptionInvoiceFragment[]
  >([]);

  const [loading, setLoading] = useState(true);

  const [getSubscriptionInvoices] = useGetMerchantSubscriptionInvoicesLazyQuery();

  const handleGetSubscriptionTransactions = async () => {
    try {
      const { data } = await getSubscriptionInvoices({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 1,
            offset: 0,
          },
        },
      });

      if (data) {
        setSubscriptionInvoices(data.getMerchantSubscriptionInvoices);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_SUBSCRIPTION_TRANSACTIONS",
            message: "Une erreur est survenue lors de la récupération des abonnements",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetSubscriptionTransactions();
    }, []),
  );

  if (loading) return <Loader />;

  const handlGoToInvoices = () => {
    navigation.navigate("INVOICE_LIST");
  };

  const [FIRST_INVOICE] = subscriptionInvoices;

  if (!FIRST_INVOICE) return <></>;

  const {
    merchantSubscriptionTransaction,
    paidDate,
    paymentAttemptDate,
    status,
    periodStartDate,
    periodEndDate,
    totalAmount,
  } = FIRST_INVOICE;

  const HAS_FAILED = status === Merchant_Subscription_Payment_Status.Failed;

  const getTitle = () => {
    if (HAS_FAILED) {
      return format(new Date(paymentAttemptDate), "dd MMMM yyyy");
    }
    return format(new Date(paidDate), "dd MMMM yyyy");
  };

  const getDescription = () => {
    const subscription = merchantSubscriptionTransaction?.subscription;
    const options = merchantSubscriptionTransaction?.options || [];

    const optionNames = options?.map(option => option.subscription.name);

    const subscriptionName = subscription?.name || "Abonnement";
    const allNames = [subscriptionName, ...optionNames].join(" + ");

    const formattedPeriodStart = format(new Date(periodStartDate), "dd/MM/yyyy");
    const formattedPeriodEnd = format(new Date(periodEndDate), "dd/MM/yyyy");

    return `${allNames} Période du ${formattedPeriodStart} au ${formattedPeriodEnd}`;
  };

  return (
    <Box>
      <Box paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
        <SubscriptionCard
          onPress={handlGoToInvoices}
          title={getTitle()}
          description={getDescription()}
          price={totalAmount}
          borderBottomWidth={0}
          icon={
            HAS_FAILED ? (
              <Box
                backgroundColor="danger"
                width={30}
                height={30}
                alignItems="center"
                justifyContent="center"
                style={{
                  borderRadius: 25,
                }}
              >
                <CustomText variant="title" color="white">
                  !
                </CustomText>
              </Box>
            ) : (
              <RECEIPT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            )
          }
        />
      </Box>

      <TouchableOpacity onPress={handlGoToInvoices}>
        <Box
          mt="m"
          backgroundColor="disabled"
          borderRadius="button"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          minHeight={45}
        >
          <CustomText ml="m" variant="content" color="primaryTextColor">
            Voir les autre factures
          </CustomText>

          <RIGHT_ARROW height={16} width={16} fill={PALETTE.green} />
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default SubscriptionInvoicesList;
