import { useState } from "react";

import Box from "../../../Base/Box";
import { CustomTextInput } from "../../../TextInput";

interface NewQuoteServiceLineProps {
  service: {
    title: string;
    quantity: number;
    price: { amount: number; taxRate: number };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateService: (key: string, value: any) => void;
}

const NewQuoteServiceLine = ({ service, updateService }: NewQuoteServiceLineProps) => {
  const [serviceTitle, setServiceTitle] = useState(service.title);

  return (
    <Box>
      <CustomTextInput
        isRequired
        keyboardType="default"
        placeHolder="Entrer une prestation"
        initialValue={serviceTitle}
        onChangeText={setServiceTitle}
        onBlur={() => updateService("title", serviceTitle)}
      />

      <Box mt="s" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomTextInput
          keyboardType="number-pad"
          isRequired
          placeHolder="Quantité"
          boxProps={{
            minWidth: 100,
          }}
          inputStyles={{ textAlign: "center" }}
          initialValue={service.quantity > 0 ? service.quantity.toString() : ""}
          onChangeText={t => updateService("quantity", +t)}
        />
        <Box flexDirection="row" alignItems="center">
          <CustomTextInput
            keyboardType="number-pad"
            isRequired
            placeHolder="TVA"
            boxProps={{
              minWidth: 100,
            }}
            inputStyles={{ textAlign: "center" }}
            initialValue={
              service.price.taxRate > 0 ? service.price.taxRate.toString() : ""
            }
            onChangeText={t => updateService("taxRate", +t)}
          />
          <Box ml="s">
            <CustomTextInput
              keyboardType="number-pad"
              isRequired
              placeHolder="Montant"
              boxProps={{
                minWidth: 100,
              }}
              inputStyles={{ textAlign: "center" }}
              initialValue={
                service.price.amount > 0 ? service.price.amount.toString() : ""
              }
              onChangeText={t => {
                updateService("amount", +t);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewQuoteServiceLine;
