import { createNativeStackNavigator } from "@react-navigation/native-stack";

import AddressDetails from "../../../components/AddressDetails";
import OnlineOrdersList from "../../../screens/Sales/OnlineOrdersList";
import OnlineOrdersListDetails from "../../../screens/Sales/OnlineOrdersListDetails";

import type { SalesStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<SalesStackParamList>();

const SalesStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
      }}
      initialRouteName="ONLINE_SALES_LIST"
    >
      <Stack.Screen name="ONLINE_SALES_LIST" component={OnlineOrdersList} />
      <Stack.Screen
        name="ONLINE_SALES_LIST_DETAILS"
        component={OnlineOrdersListDetails}
      />
      {/* <Stack.Screen name="DELIVERY_SALES_LIST" component={DeliveryOrdersList} /> */}
      {/* <Stack.Screen name="CASH_REGISTER_SALES_LIST" component={CashRegisterTicketList} /> */}

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="ADDRESS_DETAILS" component={AddressDetails} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default SalesStack;
