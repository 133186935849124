import { useState } from "react";

import type { OrderFragment } from "../../../graphql/generated/schema";
import { Click_Status, Payment_Type } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import Touchable from "../../Touchable";
import OnlineOrderStatusButtonItem, {
  ONLINE_STATUS_LAYOUT_TYPE,
} from "../OnlineOrderStatusButtonItem";

import OnlineOrderStatusListModal from "./OnlineOrderStatusListModal";

interface OnlineOrderStatusButtonProps {
  status: Click_Status;
  isDisabled?: boolean;
  type?: ONLINE_STATUS_LAYOUT_TYPE;
  onPress: (status: Click_Status, refualReason: string) => void;
  payment?: OrderFragment["payment"];
  setIsOpen?: (v: boolean) => void;
}

const OnlineOrderStatusButton = ({
  type = ONLINE_STATUS_LAYOUT_TYPE.ICON,
  isDisabled = false,
  onPress,
  status,
  payment,
  setIsOpen,
}: OnlineOrderStatusButtonProps) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const handleStatusChange = (newStatus: Click_Status, refualReason: string) => {
    setIsStatusModalOpen(false);
    onPress(newStatus, refualReason);
  };

  const handlePress = () => {
    setIsStatusModalOpen(true);
    setIsOpen?.(true);
  };

  const handleClose = () => {
    setIsStatusModalOpen(false);
    setIsOpen?.(false);
  };

  const hasOrderStatusReachedEnd =
    (status === Click_Status.Ended && payment?.paymentType !== Payment_Type.Card) ||
    (status === Click_Status.Noshow && payment?.paymentType !== Payment_Type.Card) ||
    status === Click_Status.CancelledByOwner ||
    status === Click_Status.CancelledByUser ||
    status === Click_Status.Refunded;

  const isStatusButtonDisabled = isDisabled || hasOrderStatusReachedEnd;

  return (
    <Box>
      <Touchable disabled={isStatusButtonDisabled} onPress={handlePress}>
        <OnlineOrderStatusButtonItem status={status} type={type} />
      </Touchable>
      <OnlineOrderStatusListModal
        showAllStatuses={type === ONLINE_STATUS_LAYOUT_TYPE.TEXT}
        isVisible={isStatusModalOpen}
        onClose={handleClose}
        status={status}
        onPress={handleStatusChange}
        payment={payment}
      />
    </Box>
  );
};

export default OnlineOrderStatusButton;
