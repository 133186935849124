 
import { FlatList, TouchableOpacity } from "react-native";

import { Subscription_Interval_Type } from "../../../../graphql/generated/schema";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface AddSubscriptionPaymentIntervalProps {
  interval: Subscription_Interval_Type;
  commitment: number;
  onSelectInterval: (interval: Subscription_Interval_Type) => void;
}

const PAYMENT_INTERVALS = [
  {
    interval: Subscription_Interval_Type.Monthly,
    label: "Mensuel",
  },
  {
    interval: Subscription_Interval_Type.Yearly,
    label: "Annuel",
  },
];

const AddSubscriptionPaymentInterval = ({
  interval,
  commitment,
  onSelectInterval,
}: AddSubscriptionPaymentIntervalProps) => {
  const getPaymentIntervals = () => {
    if (commitment === 1) {
      return PAYMENT_INTERVALS.filter(
        i => i.interval === Subscription_Interval_Type.Monthly,
      );
    }
    return PAYMENT_INTERVALS;
  };

  return (
    <Box flex={1} backgroundColor="white">
      <FlatList
        data={getPaymentIntervals()}
        keyExtractor={item => item.label}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
        renderItem={({ item }) => {
          const IS_SELECTED = interval === item.interval;

          return (
            <TouchableOpacity onPress={() => onSelectInterval(item.interval)}>
              <Box
                marginVertical="s"
                backgroundColor={IS_SELECTED ? "white" : "disabled"}
                borderRadius="button"
                paddingHorizontal="s"
                borderColor="success"
                borderWidth={IS_SELECTED ? 1 : 0}
                minHeight={75}
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <CustomText variant="content" color="primaryTextColor">
                    {item.label}
                  </CustomText>
                </Box>
              </Box>
            </TouchableOpacity>
          );
        }}
      />
    </Box>
  );
};

export default AddSubscriptionPaymentInterval;
