import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native";

import PAX_PERSONS from "../../../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";

const AccountSecurityView = () => {
  const navigation = useNavigation();

  return (
    <Box>
      <ScreenHeader
        title="Mot de passe et sécurité"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <TabTextInfo
          {...{
            title: "Mot de passe",
            content: "**********",
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Numéro de confiance",
            content: "**********",
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default AccountSecurityView;
