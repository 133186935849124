import { useRef } from "react";
import ReactNativePhoneInput from "react-native-phone-number-input";

import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatPhoneNumber } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface PhoneInputProps {
  value: string;
  placeHolder?: string;
  isRequired?: boolean;
  hasErrors?: boolean;
  onChangeCountry?: (country: string) => void;
  onChangeFormattedText: (value: string) => void;
  onChangeText?: (value: string) => void;
}

const PhoneInput = ({
  value,
  onChangeCountry,
  onChangeFormattedText,
  onChangeText,
  placeHolder = "Téléphone",
  hasErrors = false,
  isRequired = false,
}: PhoneInputProps) => {
  const phoneInputRef = useRef<ReactNativePhoneInput>(null);

  const handleOnChangeText = (text: string) => {
    const code = phoneInputRef.current?.getCallingCode() || "";

    const formattedText = text.slice(code.length + 1).replace(/[^0-9]/g, "");

    onChangeText?.(formatPhoneNumber(code, formattedText));
  };

  const handleChangeFormattedText = (text: string) => {
    const code = phoneInputRef.current?.getCallingCode() || "";

    const formattedText = text.slice(code.length + 1).replace(/[^0-9]/g, "");

    onChangeFormattedText(formatPhoneNumber(code, formattedText));
  };

  return (
    <Box>
      <ReactNativePhoneInput
        ref={phoneInputRef}
        defaultCode="FR"
        disableArrowIcon
        countryPickerButtonStyle={{
          borderColor: hasErrors ? PALETTE.red : PALETTE.lightGrey,
          borderRightWidth: LINE_THICKNESS,
          maxWidth: 50,
        }}
        codeTextStyle={{
          display: "none",
        }}
        containerStyle={{
          height: 45,
          width: "100%",
          borderRadius: 8,
          borderColor: hasErrors ? PALETTE.red : PALETTE.lightGrey,
          borderWidth: 1,
        }}
        textContainerStyle={{
          flex: 0.97,
          paddingVertical: 0,
          padding: 0,
          paddingLeft: 8,
          backgroundColor: "white",
        }}
        textInputProps={{
          returnKeyType: "done",
          clearButtonMode: "while-editing",
          style: {
            color: hasErrors ? PALETTE.red : PALETTE.blue,
            width: "100%",
            height: "100%",
            outlineStyle: "none",
          },
        }}
        defaultValue={value}
        value={value}
        onChangeCountry={onChangeCountry}
        onChangeFormattedText={handleChangeFormattedText}
        onChangeText={handleOnChangeText}
        placeholder={placeHolder}
      />

      <Box
        flexDirection="row"
        alignItems="center"
        backgroundColor="white"
        position="absolute"
        left={8}
        top={-7}
      >
        <CustomText variant="text" color="lightGrey" fontSize={10}>
          {placeHolder}
        </CustomText>
        {isRequired && (
          <CustomText
            pl="s"
            pr="xs"
            variant="defaultTextInput"
            color={hasErrors ? "danger" : "success"}
          >
            *
          </CustomText>
        )}
      </Box>
    </Box>
  );
};

export default PhoneInput;
