import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format, setDay } from "date-fns";
import React, { useMemo, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import BookingServiceListCard from "../../../../../components/BookingServiceListCard";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import type { OnlineSalesAvailabilityFragment } from "../../../../../graphql/generated/schema";
import {
  useGetOnlineSalesAvailabilitiesLazyQuery,
  useUpdateOnlineSalesAvailbilityMutation,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import { createDateWithTime } from "../../../../../utils/common";
import TakeAwayServiceListDetails from "../TakeAwayServiceListDetails";

interface TakeAwayServiceListProps {
  goBack?: () => void;
}

const TakeAwayServiceList = ({ goBack }: TakeAwayServiceListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<OnlineSalesAvailabilityFragment[]>([]);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [search, setSearch] = useState("");
  const [showServiceInline, setShowServiceInline] = useState(false);

  const [getOnlineSalesAvailabilities, { fetchMore }] =
    useGetOnlineSalesAvailabilitiesLazyQuery();
  const [updateAvailability] = useUpdateOnlineSalesAvailbilityMutation();

  const filteredServices = useMemo(() => {
    return services.filter(service => {
      const { name } = service;
      return name.toLowerCase().includes(search.toLowerCase());
    });
  }, [services, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: services.length,
          },
        },
      });
      setServices([...services, ...(data?.getOnlineSalesAvailabilities || [])]);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleGetOnlineSalesAvailabilities = async () => {
    try {
      const { data } = await getOnlineSalesAvailabilities({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      setServices(data?.getOnlineSalesAvailabilities || []);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetOnlineSalesAvailabilities();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setSelectedServiceId("");
      setShowServiceInline(true);
    } else {
      navigation.navigate("TAKE_AWAY_SERVICE_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedServiceId(id);
      setShowServiceInline(true);
    } else {
      navigation.navigate("TAKE_AWAY_SERVICE_LIST_DETAILS", { isNew: false, id });
    }
  };

  const handleUpdateAvailability = async (id: string, isEnabled: boolean) => {
    try {
      setServices(prev => {
        return prev.map(p => (p._id === id ? { ...p, isEnabled } : p));
      });
      await updateAvailability({
        variables: {
          availabilityId: id,
          onlineSalesAvailability: {
            isEnabled,
          },
        },
      });
    } catch (err) {
      console.log("err toggle online salss service", err);
    }
  };

  const displayDays = (item: OnlineSalesAvailabilityFragment) => {
    const { visibilitySetting } = item;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      return recurringDates.days
        .map(day => {
          const date = setDay(new Date(), day);
          return format(date, "EEEE.");
        })
        .join(" | ");
    } else {
      const start = format(new Date(specificDates.startDate), "dd/MM/yyyy");
      const end = format(new Date(specificDates.endDate), "dd/MM/yyyy");

      return `${start} - ${end}`;
    }
  };

  const displayTime = (item: OnlineSalesAvailabilityFragment) => {
    const { visibilitySetting } = item;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      const { startTime, endTime } = recurringDates;
      const start = createDateWithTime(new Date(), startTime);
      const end = createDateWithTime(new Date(), endTime);

      return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
    } else {
      const { startTime, endTime } = specificDates;
      const start = createDateWithTime(new Date(), startTime);
      const end = createDateWithTime(new Date(), endTime);

      return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
    }
  };

  const handleCloseSelectedTab = async () => {
    await handleGetOnlineSalesAvailabilities();
    setShowServiceInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <TakeAwayServiceListDetails
        newValue={!selectedServiceId}
        selectedServiceId={selectedServiceId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }
  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Services & expériences"
          hasBackButton
          rightButtons={
            <TouchableOpacity onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          onBackPress={goBack || navigation.goBack}
        />

        <Box marginVertical="s">
          <CustomTextInput
            {...{
              placeHolder: "Rechercher",
              leftIcon: (
                <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
              ),
              onChangeText: setSearch,
            }}
          />
        </Box>

        <FlatList
          showsVerticalScrollIndicator={false}
          data={filteredServices}
          onEndReached={handleFetchMore}
          onEndReachedThreshold={0.5}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <Touchable onPress={() => handleEdit(item._id)}>
                <BookingServiceListCard
                  name={item.name}
                  isEnabled={item.isEnabled}
                  areLimitsEnabled={item.totalOrdersAllowed.isEnabled}
                  updatedEnabled={isEnabled =>
                    handleUpdateAvailability(item._id, isEnabled)
                  }
                  capacity={`${item.totalOrdersAllowed?.value} commandes`}
                  days={displayDays(item)}
                  time={displayTime(item)}
                />
              </Touchable>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showServiceInline) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default TakeAwayServiceList;
