import * as Notifications from "expo-notifications";
import { useEffect } from "react";

import { useGetAlertsLazyQuery } from "../../graphql/generated/schema";
import { useAppState } from "../../hooks/useAppState";

export function AppStateProvider({
  children,
  apiToken,
}: {
  children: React.ReactNode;
  apiToken?: string;
}) {
  const { isInBackground } = useAppState();
  const [getAlerts] = useGetAlertsLazyQuery();

  const handleSetPendingNotificationBadgeAmount = async () => {
    if (!isInBackground || !apiToken) return;

    try {
      const response = await getAlerts({
        variables: {
          date: new Date().toISOString(),
        },
      });

      if (response.data?.getAlerts) {
        const { totalPendingBookings, totalPendingOrders } = response.data.getAlerts;
        const totalPendingAlerts = totalPendingBookings + totalPendingOrders;

        await Notifications.setBadgeCountAsync(totalPendingAlerts);
      }
    } catch (err) {
      console.log("Error getting alerts", err);
    }
  };

  useEffect(() => {
    handleSetPendingNotificationBadgeAmount();
  }, [isInBackground]);

  return <>{children}</>;
}
