import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";

import EMAIL from "../../../../../../assets/icons/BASE/EMAIL.svg";
import HOME from "../../../../../../assets/icons/BASE/HOME.svg";
import LOCATION_PIN from "../../../../../../assets/icons/BASE/LOCATION_PIN.svg";
import PAX_PERSONS from "../../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import PHONE from "../../../../../../assets/icons/BASE/PHONE.svg";
import USER_GROUP from "../../../../../../assets/icons/BASE/USER_GROUP.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import TabTextInfo from "../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import Loader from "../../../../../components/Loader";
import type { MODULE_TYPE } from "../../../../../components/ModuleList/common";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { MerchantClientCompany } from "../../../../../graphql/generated/schema";
import { useGetCompanyLazyQuery } from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../utils/common";
import ErrorMessage from "../../../../../components/ErrorMessage";

interface CompanyListDetailsViewProps {
  id: string;
  moduleType: MODULE_TYPE;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const CompanyListDetailsView = ({
  id,
  moduleType,
  goBack,
}: CompanyListDetailsViewProps) => {
  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<MerchantClientCompany | null>(null);

  const [getCompany] = useGetCompanyLazyQuery();

  const handleGetCompany = async () => {
    try {
      const { data, error } = await getCompany({
        variables: { companyId: id },
      });

      setCompany(data?.getMerchantClientCompany || null);

      console.log("error", error);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCompany();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!company) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations de l'entreprise." />
      </Box>
    );
  }

  const displayPicture = () => {
    const { picture } = company;

    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  const displayClientsAttached = () => {
    const { clientsAttached = [] } = company;
    return clientsAttached
      .map(client => {
        const name = `${client.firstName} ${client.lastName}`;
        return name;
      })
      .join("\n");
  };

  return (
    <Box flex={1}>
      <ScreenHeader
        title={company.companyName}
        rightButtons={displayPicture()}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />
      <Box mt="m" flexDirection="row">
        <Box
          mr="s"
          alignItems="center"
          justifyContent="center"
          backgroundColor="greenBackground"
          padding="s"
          borderRadius="button"
        >
          <CustomText variant="label" color="success">
            {formatCurrencyPrice(company?.generatedProfit || 0, "fr", "eur")}
          </CustomText>
          <CustomText variant="content" color="success">
            C.A Généré
          </CustomText>
        </Box>
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
        style={{ marginTop: 20 }}
      >
        <Box>
          {/* <Box mb="s" flexDirection="row" alignItems="center">
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />

            <CustomText ml="s" variant="label" color="primaryTextColor">
              Synthese
            </CustomText>
          </Box>

          {(moduleType === MODULE_TYPE.ACCOUNTING ||
            moduleType === MODULE_TYPE.CLIENTS) && (
            <Box>
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Facturation
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Devis
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Factures
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0,00€
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Ticket
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )}

          {(moduleType === MODULE_TYPE.ONLINE_SALES ||
            moduleType === MODULE_TYPE.CLIENTS) && (
            <Box mt="s">
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Commandes
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Commandes
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    No-Show
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0,00€
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Ticket
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )}

          {(moduleType === MODULE_TYPE.BOOKING || moduleType === MODULE_TYPE.CLIENTS) && (
            <Box mt="s">
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Réservations
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Résa
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    No-Show
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )} */}

          <TabTextInfo
            {...{
              title: "Siret",
              content: company.siret,
              icon: (
                <HOME height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
          <TabTextInfo
            {...{
              title: "Numéro de TVA",
              content: company.tvaNumber,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
          {/* <TabTextInfo
            {...{
              title: "Client VIP",
              content: isVIP ? "Oui" : "Non",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          /> */}
          <TabTextInfo
            {...{
              title: "Téléphone",
              content: company.phone,
              icon: (
                <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />

          {company?.email && (
            <TabTextInfo
              {...{
                title: "Mail",
                content: company.email,
                icon: (
                  <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
                ),
              }}
            />
          )}

          <TabTextInfo
            {...{
              title: "Adresse",
              content: "email",
              icon: (
                <LOCATION_PIN
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
          {/* <TabTextInfo
            {...{
              title: "Langue",
              content: "Francais",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          /> */}

          <TabTextInfo
            {...{
              title: "Note sur l'entreprise",
              content: company.comment,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
          {displayClientsAttached() && (
            <TabTextInfo
              {...{
                title: "Personnes rattachées",
                content: displayClientsAttached(),
                icon: (
                  <USER_GROUP
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                    fill={PALETTE.lightGrey}
                  />
                ),
              }}
            />
          )}

          {/* <TabTextInfo
            {...{
              title: "Accepte les newsletters",
              content: "non",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
          <TabTextInfo
            {...{
              title: "Accepte les campagnes SMS",
              content: "non",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
          <TabTextInfo
            {...{
              title: "Client blacklisté",
              content: "oui",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          /> */}
        </Box>
      </ScrollView>
    </Box>
  );
};

export default CompanyListDetailsView;
