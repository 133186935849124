import LINKS from "../../../assets/icons/BASE/LINKS.svg";
import PRICE_TAG from "../../../assets/icons/BASE/PRICE_TAG.svg";
import REQUIRED_LINK from "../../../assets/icons/BASE/REQUIRED_LINK.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface ProductGroupOptionCardProps {
  name: string;
  nbOptions: number;
  nbProducts: number;
  price: number;
  isSelected: boolean;
}

const HEIGHT = 85;

const ProductGroupOptionCard = ({
  name,
  nbOptions,
  nbProducts,
  price,
  isSelected,
}: ProductGroupOptionCardProps) => {
  const formattedPrice = formatCurrencyPrice(price, "fr", "eur");

  return (
    <Box
      p="s"
      minHeight={HEIGHT}
      borderWidth={LINE_THICKNESS}
      borderColor={isSelected ? "success" : "disabled"}
      borderRadius="button"
      backgroundColor={isSelected ? "white" : "disabled"}
    >
      <CustomText variant="label" color="primaryTextColor">
        {name}
      </CustomText>

      <Box flex={1} flexDirection="row" alignItems="flex-end">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          backgroundColor={isSelected ? "disabled" : "white"}
          borderRadius="button"
          p="s"
        >
          <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText ml="s" variant="label" color="primaryTextColor">
            {nbOptions}
          </CustomText>
        </Box>
        <Box
          ml="m"
          flexDirection="row"
          alignItems="center"
          backgroundColor={isSelected ? "disabled" : "white"}
          borderRadius="button"
          p="s"
        >
          <REQUIRED_LINK height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText ml="s" variant="label" color="primaryTextColor">
            {nbProducts}
          </CustomText>
        </Box>

        {price > 0 && (
          <Box
            ml="m"
            flexDirection="row"
            alignItems="center"
            backgroundColor={isSelected ? "disabled" : "white"}
            borderRadius="button"
            p="s"
          >
            <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="label" color="primaryTextColor">
              {formattedPrice}
            </CustomText>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductGroupOptionCard;
