import type { SmsPackFragment } from "../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../theme";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SmsPackItemProps {
  smsPack: SmsPackFragment;
  isSelected: boolean;
}

const SmsPackItem = ({ smsPack, isSelected }: SmsPackItemProps) => {
  const { nbOfSms, price } = smsPack;

  const formattedPrice = formatCurrencyPrice(price, "fr", "eur");

  return (
    <Box
      p="s"
      borderRadius="button"
      backgroundColor={isSelected ? "primaryTextColor" : "white"}
      borderColor="primaryTextColor"
      borderWidth={LINE_THICKNESS}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      minHeight={45}
    >
      <CustomText variant="content" color={isSelected ? "white" : "primaryTextColor"}>
        {nbOfSms} SMS
      </CustomText>
      <CustomText variant="content" color={isSelected ? "white" : "primaryTextColor"}>
        {formattedPrice} HT
      </CustomText>
    </Box>
  );
};

export default SmsPackItem;
