/// <reference types="@welldone-software/why-did-you-render" />

import React from "react";

import config from "./src/config/env";

if (config.APP_ENV === "testing") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
