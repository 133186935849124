 

import BOOKMARK from "../../../../assets/icons/BASE/BOOKMARK.svg";
import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import CASH from "../../../../assets/icons/BASE/CASH.svg";
import LOCK from "../../../../assets/icons/BASE/LOCK.svg";
import REFUNDED from "../../../../assets/icons/BASE/REFUNDED.svg";
import SEND from "../../../../assets/icons/BASE/SEND.svg";
import TICKET_ORDERED from "../../../../assets/icons/BASE/TICKET_ORDERED.svg";
import TICKET_PAID from "../../../../assets/icons/BASE/TICKET_PAID.svg";
import { Cash_Register_Ticket_Status } from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

export enum CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE {
  ICON = "ICON",
  TEXT = "TEXT",
}
interface CashRegisterTicketListCardStatusProps {
  status: Cash_Register_Ticket_Status;
  viewType: CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE;
}

const CashRegisterTicketListCardStatus = ({
  status,
  viewType = CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE.ICON,
}: CashRegisterTicketListCardStatusProps) => {
  const determineCorrectStatusColor = (): {
    borderColor: THEME_COLORS;
    iconColor: PALETTE_COLORS;
  } => {
    switch (status) {
      case Cash_Register_Ticket_Status.Open:
        return {
          borderColor: "orangeBackground",
          iconColor: "orange",
        };
      case Cash_Register_Ticket_Status.Ordered:
        return {
          borderColor: "greenBackground",
          iconColor: "green",
        };
      case Cash_Register_Ticket_Status.Sent:
        return {
          borderColor: "blueBackground",
          iconColor: "blueTertiary",
        };
      case Cash_Register_Ticket_Status.CurrentlyPaying:
        return {
          borderColor: "greenBackground",
          iconColor: "green",
        };
      case Cash_Register_Ticket_Status.Paid:
        return {
          borderColor: "lightGrey",
          iconColor: "ORDERONLINE_PAID",
        };
      case Cash_Register_Ticket_Status.Cancelled:
        return {
          borderColor: "offWhite",
          iconColor: "ORDERONLINE_CANCELLED",
        };
      case Cash_Register_Ticket_Status.Refunded:
        return {
          borderColor: "redBackground",
          iconColor: "red",
        };
      case Cash_Register_Ticket_Status.Closed:
        return {
          borderColor: "lightPurple",
          iconColor: "purple",
        };
    }
    return { borderColor: "greenBackground", iconColor: "green" };
  };

  const determineCorrectStatus = () => {
    switch (status) {
      case Cash_Register_Ticket_Status.Open:
        return <BOOKMARK fill={PALETTE.orange} />;
      case Cash_Register_Ticket_Status.Ordered:
        return <TICKET_ORDERED fill={PALETTE.green} />;
      case Cash_Register_Ticket_Status.Sent:
        return <SEND width={20} height={20} fill={PALETTE.blueTertiary} />;
      case Cash_Register_Ticket_Status.CurrentlyPaying:
        return <CASH width={20} height={20} fill={PALETTE.green} />;
      case Cash_Register_Ticket_Status.Paid:
        return (
          <TICKET_PAID
            // width={ICON_SIZE}
            // height={ICON_SIZE}
            fill={PALETTE.ORDERONLINE_PAID}
          />
        );
      case Cash_Register_Ticket_Status.Cancelled:
        return (
          <CANCEL
            // width={ICON_SIZE}
            // height={ICON_SIZE}
            fill={PALETTE.ORDERONLINE_CANCELLED}
          />
        );
      case Cash_Register_Ticket_Status.Refunded:
        return <REFUNDED fill={PALETTE.red} />;
      case Cash_Register_Ticket_Status.Closed:
        return <LOCK fill={PALETTE.purple} />;

      default:
        return <></>;
    }
  };

  const determineCorrectStatusText = (): string => {
    switch (status) {
      case Cash_Register_Ticket_Status.Open:
        return "Ouvert";

      case Cash_Register_Ticket_Status.Ordered:
        return "Commandé";
      case Cash_Register_Ticket_Status.CurrentlyPaying:
        return "En cours de paiement";

      case Cash_Register_Ticket_Status.Sent:
        return "Envoyé";

      case Cash_Register_Ticket_Status.Paid:
        return "Payé";

      case Cash_Register_Ticket_Status.Cancelled:
        return "Annulé";

      case Cash_Register_Ticket_Status.Refunded:
        return "Remboursé";

      case Cash_Register_Ticket_Status.Closed:
        return "Terminé";

      default:
        return "-";
    }
  };

  const displayContent = () => {
    return (
      <Box
        p="s"
        backgroundColor={determineCorrectStatusColor().borderColor}
        borderRadius="modal"
        flexDirection="row"
        alignItems="center"
      >
        {determineCorrectStatus()}
        {viewType === CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE.TEXT && (
          <CustomText
            ml="s"
            variant="content"
            style={{
              color: PALETTE[determineCorrectStatusColor().iconColor],
            }}
            // color={determineCorrectStatusColor().borderColor}
          >
            {determineCorrectStatusText()}
          </CustomText>
        )}
      </Box>
    );
  };

  return <Box>{displayContent()}</Box>;
};

export default CashRegisterTicketListCardStatus;
