import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as yup from "yup";

import SWITCH from "../../../../../assets/icons/BASE/SWITCH.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import SnackBarInfo from "../../../../components/SnackBarInfo";
import { CustomTextInput } from "../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  PrinterFragment,
  ProductCategoryFragment,
} from "../../../../graphql/generated/schema";
import {
  GetDevicesTotalSummaryDocument,
  GetPrintersDocument,
  Printer_Connection_Type,
  useCreatePrinterMutation,
  useDeletePrinterMutation,
  useGetPrinterLazyQuery,
  useGetProductCategoriesLazyQuery,
  useUpdatePrinterMutation,
} from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import type { ERROR } from "../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../utils/common";
import ThermalPrinter from "../../../../utils/ThermalPrinter";

interface AddPrinterProps {
  newValue?: boolean;
  printerId?: string;
  goBack?: () => void;
}

const DEFAULT_PRINTER: Omit<PrinterFragment, "_id"> = {
  name: "",
  connectionType: Printer_Connection_Type.Network,
  ipAddress: "",
  target: "",
  printerModelName: "",
  macAddress: "",
  secondaryDisplayCategories: [],
};

const schema = yup.object().shape({
  name: yup.string().required(),
  connectionType: yup.string().required(),
  ipAddress: yup.string().required(),
  target: yup.string().required(),
  printerModelName: yup.string().required(),
  macAddress: yup.string().required(),
});

const AddPrinter = ({ newValue = false, printerId = "", goBack }: AddPrinterProps) => {
  const navigation = useNavigation();
  const errorInfoContext = useContext(ErrorInfoSuccessAlertModalContext);
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = printerId || params?.id;

  const [loading, setLoading] = useState(!isNew);
  const [printer, setprinter] = useState(DEFAULT_PRINTER);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [printersInNetwork, setPrintersInNetwork] = useState<
    { label: string; key: string }[]
  >([]);
  const [printersFoundInNetwork, setPrintersFoundInNetwork] = useState([]);
  const [categories, setCategories] = useState<{ label: string; key: string }[]>([]);

  const [getCategories] = useGetProductCategoriesLazyQuery();
  const [getPrinter] = useGetPrinterLazyQuery();
  const [createPrinter] = useCreatePrinterMutation();
  const [updatePrinter] = useUpdatePrinterMutation();
  const [deletePrinter] = useDeletePrinterMutation();

  const handleGetCategories = async () => {
    try {
      const { data } = await getCategories({
        variables: {
          pagination: {
            limit: 30,
            offset: 0,
          },
        },
      });

      if (data) {
        const formattedCategories = data.getProductCategories.map(
          (category: ProductCategoryFragment) => ({
            label: category.name,
            key: category._id,
          }),
        );

        setCategories(formattedCategories);
      }
    } catch (err) {
      console.log("err get categories", err);
    }
  };

  const handleGetPrinter = async () => {
    try {
      if (!isNew) {
        const { data } = await getPrinter({
          variables: {
            id,
          },
        });

        if (data) {
          setprinter(data.getPrinter);
        }
      } else {
        setprinter(DEFAULT_PRINTER);
      }
    } catch (err) {
      console.log("get printer err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDiscoverPrinters = async () => {
    try {
      const printersFound = (await ThermalPrinter?.discoverPrinters()) || [];

      console.log("printersFound", printersFound);

      setPrintersFoundInNetwork(printersFound);
      const formattedPrinters = printersFound.map(p => {
        const label = `${p.name}  (${p.ip})`;
        return { label, key: p.mac };
      });
      setPrintersInNetwork(formattedPrinters);
    } catch (err) {
      console.log("err disvoer printer", err);
    }
  };

  useEffect(() => {
    handleGetCategories();
    handleGetPrinter();
    // handleDiscoverPrinters();
  }, []);

  const validateForm = async () => {
    try {
      const isValid = await schema.validate(printer, { abortEarly: false });

      return true;
    } catch (err) {
      const formattedErrors = formaYupErrors(err);
      setErrors(formattedErrors);

      // setIsSnackBarOpen(true);
      errorInfoContext?.openAlert("Erreur dans le formulaire", formattedErrors, "error");
    }
    return false;
  };

  const doesInputHaveError = (key: keyof PrinterFragment) => {
    return errors.some(err => err.path === key);
  };

  const handleSelectCategories = (keys: string[]) => {
    setprinter(prev => ({
      ...prev,
      secondaryDisplayCategories: keys,
    }));
  };

  const handleSelectPrinter = (keys: string[]) => {
    const [printerMacAddress] = keys;

    const selectedPrinter = printersFoundInNetwork.find(p => p.mac === printerMacAddress);

    if (selectedPrinter) {
      const { mac, target, ip, name } = selectedPrinter;

      setprinter(prev => ({
        ...prev,
        macAddress: mac,
        target,
        ipAddress: ip,
        printerModelName: name,
      }));
    }
  };
  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      if (isNew) {
        await createPrinter({
          variables: {
            printer,
          },
          refetchQueries: [
            {
              query: GetPrintersDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
            {
              query: GetDevicesTotalSummaryDocument,
            },
          ],
        });
      } else {
        const updates = removeTypeNames(printer);

        await updatePrinter({
          variables: {
            id,
            printer: updates,
          },
          refetchQueries: [
            {
              query: GetPrintersDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
            {
              query: GetDevicesTotalSummaryDocument,
            },
          ],
        });
      }
      navigation.goBack();
    } catch (err) {
      // setIsSnackBarOpen(true);

      const formattedErrors = [{ path: "submit", code: "submit", message: err.message }];
      errorInfoContext?.openAlert("Erreur dans le formulaire", formattedErrors, "error");

      setErrors(formattedErrors);
    } finally {
    }
  };

  const handleDelete = async () => {
    try {
      await deletePrinter({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: GetPrintersDocument,
            variables: { pagination: { limit: 10, offset: 0 } },
          },
          {
            query: GetDevicesTotalSummaryDocument,
          },
        ],
      });
    } catch (err) {
    } finally {
      navigation.goBack();
    }
  };

  const updatePrinterData = (key: keyof PrinterFragment, value: string) => {
    setprinter({
      ...printer,
      [key]: value,
    });
  };

  const displayTitle = () => {
    if (isNew) {
      return "Ajouter une imprimante";
    }
    return "Modifier une imprimante";
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="s" backgroundColor="white" paddingHorizontal="s">
      <Box mt="s">
        <ScreenHeader
          title={displayTitle()}
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      </Box>

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="m">
          <CustomTextInput
            placeHolder="Nom de l'imprimante"
            isRequired
            hasErrors={doesInputHaveError("name")}
            initialValue={printer.name}
            onChangeText={t => updatePrinterData("name", t)}
          />
        </Box>

        <TouchableOpacity onPress={handleDiscoverPrinters}>
          <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText textTransform="uppercase" variant="content" color="success">
              Recherche automatique des imprimantes
            </CustomText>
            <SWITCH width={20} height={20} fill={PALETTE.green} />
          </Box>
        </TouchableOpacity>

        {printersInNetwork?.length === 0 ? (
          <>
            {!isNew ? (
              <Box mt="m">
                <CustomTextInput
                  placeHolder="Addresse Mac"
                  isRequired
                  disabled
                  initialValue={printer.macAddress}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                />
              </Box>
            ) : (
              <Box
                mt="m"
                borderRadius="button"
                borderColor="danger"
                borderWidth={LINE_THICKNESS}
                minHeight={45}
                justifyContent="center"
                p="s"
              >
                <CustomText variant="content" color="danger">
                  Aucune imprimante trouvé
                </CustomText>
              </Box>
            )}
          </>
        ) : (
          <Box mt="s">
            <SelectButton
              isMultiSelect={false}
              placeHolder="Imprimantes"
              selectedOptions={printer.macAddress ? [printer.macAddress] : []}
              options={printersInNetwork}
              onPress={handleSelectPrinter}
            />
          </Box>
        )}

        <Box marginVertical="m">
          <CustomText textTransform="uppercase" variant="content" color="success">
            AFFICHER LES PLATS EN SUIVANT
          </CustomText>

          <CustomText variant="description" color="lightGrey">
            Les plats de ces lieux seront affichés en plus petits après les plats du lieu
            principal.
          </CustomText>
        </Box>
        <Box mt="s">
          <SelectButton
            isMultiSelect
            placeHolder="Catégories produits"
            selectedOptions={printer.secondaryDisplayCategories || []}
            options={categories}
            onPress={handleSelectCategories}
          />
        </Box>
      </ScrollView>

      {/* <Box mt="m">
        <CustomTextInput
          placeHolder="addresse IP"
          isRequired
          hasErrors={doesInputHaveError("ipAddress")}
          initialValue={printer.ipAddress}
          onChangeText={t => updatePrinterData("ipAddress", t)}
        />
      </Box> */}
      {/* <Box mt="m">
        <CustomButton
          buttonColor="disabled"
          buttonVariant="primaryButton"
          onPress={() => {}}
          styles={{ minHeight: 45 }}
        >
          <CustomText variant="content" color="success">
            Rechercher une imprimante
          </CustomText>
        </CustomButton>
      </Box> */}

      {/* <Box marginVertical="s">
        <SelectButton
          isMultiSelect
          options={PERMISSIONS_GROUPS}
          selectedOptions={printers}
          onPress={setPrinters}
          placeHolder="Imprimantes"
        />
      </Box> */}

      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        right={0}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            borderColor="danger"
            buttonVariant="outlineButton"
            onPress={handleDelete}
            styles={{ mb: "s" }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonColor="success"
          buttonVariant="primaryButton"
          onPress={handleSubmit}
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
      <SnackBarInfo
        isOpen={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
        type="error"
      >
        {errors.map(error => (
          <Box key={error.path}>
            <CustomText variant="content" color="danger">
              {error.message}
            </CustomText>
          </Box>
        ))}
      </SnackBarInfo>
    </Box>
  );
};

export default AddPrinter;
