import { useNavigation } from "@react-navigation/native";
import { isSameDay } from "date-fns";

import { Modules } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

interface BookingEmptyProps {
  module: Modules;
  selectedDate: Date;
  isOnAllTab: boolean;
}

const ListEmpty = ({ isOnAllTab, module, selectedDate }: BookingEmptyProps) => {
  const navigation = useNavigation();

  const isCurrentDaySameAsSelectedDay = isSameDay(new Date(), selectedDate);

  const displayLabel = () => {
    if (module === Modules.Reservation) {
      return "Aucune réservation";
    }
    if (module === Modules.OnlineSales) {
      return "Aucune commande";
    }
    if (module === Modules.Delivery) {
      return "Aucune commande";
    }
  };

  const handleGoToNewBooking = () => {
    navigation.navigate("NEW_BOOKING", {
      selectedDate,
    });
  };

  const handleNewLocalBooking = () => {
    navigation.navigate("NEW_LOCAL_BOOKING");
  };

  return (
    <Box flex={1} mt="xl" height="100%" alignItems="center" justifyContent="center">
      <CustomText variant="label" color="primaryTextColor">
        {displayLabel()}
      </CustomText>

      {module === Modules.Reservation && isOnAllTab && (
        <Box mt="m">
          {isCurrentDaySameAsSelectedDay && (
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleNewLocalBooking}
            >
              <CustomText
                paddingHorizontal="m"
                variant="primaryButtonText"
                color="success"
              >
                Réservation de passage
              </CustomText>
            </CustomButton>
          )}

          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="success"
            onPress={handleGoToNewBooking}
            styles={{
              mt: "m",
            }}
          >
            <CustomText paddingHorizontal="m" variant="primaryButtonText" color="white">
              Noter une réservation
            </CustomText>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default ListEmpty;
