import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { Dimensions, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../components/Base/Box";
import BookingFloorDetailsModal from "../../../../components/BookingFloor/BookingFloorDetailsModal";
import BookingFloorListCard from "../../../../components/BookingFloor/BookingFloorListCard";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import { BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT } from "../../../../constants/BookingFloor";
import type {
  BookingFloorFragment,
  CreateBookingFloorInput,
  GetBookingFloorsQuery,
} from "../../../../graphql/generated/schema";
import {
  useGetBookingFloorsLazyQuery,
  useSortBookingFloorsMutation,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import BookingFloor from "../BookingFloor";

interface BookingsFloorsProps {
  goBack?: () => void;
}

const { height, width } = Dimensions.get("window");

const DEFAULT_HEIGHT = height - BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT;

const DEFAULT_BOOKING_FLOOR: CreateBookingFloorInput = {
  decorations: [],
  width,
  height: DEFAULT_HEIGHT,
  name: "",
  description: "",
  position: 0,
  prefix: "",
  tables: [],
};

const BookingsFloors = ({ goBack }: BookingsFloorsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [bookingFloors, setBookingFloors] = useState<
    GetBookingFloorsQuery["getBookingFloors"]
  >([]);
  const [isBookingFloorEditOpen, setIsBookingFloorEditOpen] = useState(false);
  const [newFloor, setNewFloor] = useState(DEFAULT_BOOKING_FLOOR);
  const [selectedId, setSelectedId] = useState("");
  const [floorDetailsInline, setFloorDetailsInline] = useState<
    BookingFloorFragment | undefined
  >(undefined);
  const [showInline, setShowInline] = useState(false);

  const [getBookingsFloors, { fetchMore }] = useGetBookingFloorsLazyQuery();
  const [sortBookingFloors] = useSortBookingFloorsMutation();

  const handleFetchMore = async () => {
    try {
      const result = await fetchMore({
        variables: {
          pagination: {
            limit: 20,
            offset: bookingFloors.length,
          },
        },
      });
      if (result.data) {
        setBookingFloors([...bookingFloors, ...result.data.getBookingFloors]);
      }
    } catch (err) {}
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingFloors = async () => {
    setLoading(true);
    try {
      const result = await getBookingsFloors({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });
      if (result.data) {
        setBookingFloors([...result.data.getBookingFloors]);
      }
    } catch (err) {
      console.log("err get booking floors", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetBookingFloors();
    }, []),
  );

  const handleAddNew = () => {
    setIsBookingFloorEditOpen(true);
    // navigation.navigate("BOOKING_FLOOR", { isNew: true });
  };

  const handleSortFloors = async (floors: BookingFloorFragment[]) => {
    setBookingFloors(floors);
    try {
      const floorIds = floors.map(item => item._id);

      const { errors } = await sortBookingFloors({
        variables: {
          floor: {
            floorIds,
          },
        },
      });
      if (errors) {
        console.log("errors", errors);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleClose = () => {
    setIsBookingFloorEditOpen(false);
    setNewFloor(DEFAULT_BOOKING_FLOOR);
  };

  const handleNewFloorSubmit = (floor: BookingFloor) => {
    if (goBack) {
      setSelectedId("");
      setShowInline(true);
      setFloorDetailsInline(floor);
    } else {
      navigation.navigate("BOOKING_FLOOR", {
        isNew: true,
        floor: {
          ...newFloor,
          ...floor,
        },
      });
    }

    setIsBookingFloorEditOpen(false);
  };

  const handleGoToBookingFloor = (id: string) => {
    if (goBack) {
      setSelectedId(id);
      setShowInline(true);
    } else {
      navigation.navigate("BOOKING_FLOOR", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetBookingFloors();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <BookingFloor
        newValue={!selectedId}
        selectedId={selectedId}
        floor={floorDetailsInline}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Plan de salles"
          hasBackButton
          rightButtons={
            <TouchableOpacity onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          onBackPress={handleGoBack}
        />

        <Box flex={1} alignItems="flex-start">
          <DraggableFlatList
            scrollEnabled={false}
            data={[...bookingFloors]}
            style={{ width: "100%" }}
            containerStyle={{ width: "100%" }}
            onEndReached={handleFetchMore}
            renderItem={({ item, drag }) => (
              <TouchableOpacity
                onPress={() => handleGoToBookingFloor(item._id)}
                style={{
                  marginVertical: 8,
                }}
              >
                <BookingFloorListCard bookingFloor={item} onPressIn={drag} />
              </TouchableOpacity>
            )}
            keyExtractor={(item, index) => `draggable-item-${index}`}
            onDragEnd={({ data: sortedData }) => {
              handleSortFloors(sortedData);
            }}
          />
        </Box>
        <BookingFloorDetailsModal
          isOpen={isBookingFloorEditOpen}
          onClose={handleClose}
          initialFloor={newFloor}
          onSubmit={handleNewFloorSubmit}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default BookingsFloors;
