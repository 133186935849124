import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import ClientCard from "../../../../components/ClientCard";
import Loader from "../../../../components/Loader";
import PaginatedList from "../../../../components/PaginatedList";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  MerchantClientFragment,
  Pagination,
} from "../../../../graphql/generated/schema";
import {
  useGetMerchantClientsLazyQuery,
  useSearchForMerchantClientsLazyQuery,
} from "../../../../graphql/generated/schema";
import { useDebounce } from "../../../../hooks/useDebounce";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import ClientListDetails from "../ClientListDetails";
interface ClientListProps {
  goBack?: () => void;
}

const ClientList = ({ goBack }: ClientListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [clients, setClients] = useState<MerchantClientFragment[]>([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [showClienInline, setShowClienInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getMerchantClients, { fetchMore }] = useGetMerchantClientsLazyQuery();
  const [searchForMerchantClients, { fetchMore: fetchMoreSearchMerchantClients }] =
    useSearchForMerchantClientsLazyQuery();

  // const filteredClients = useMemo(() => {
  //   const formattedSearch = search.toLowerCase()?.trim();

  //   if (!formattedSearch) {
  //     return clients;
  //   }

  //   return clients.filter(client => {
  //     const formatteFirstName = client.firstName.toLowerCase()?.trim();
  //     const formatteLastName = client.lastName.toLowerCase()?.trim();
  //     const formatteEmail = client.email.toLowerCase()?.trim();
  //     const formattePhone = client.phone.toLowerCase()?.trim();

  //     return (
  //       formatteFirstName.includes(formattedSearch) ||
  //       formatteLastName.includes(formattedSearch) ||
  //       formatteEmail.includes(formattedSearch) ||
  //       formattePhone.includes(formattedSearch)
  //     );
  //   });
  // }, [clients, search]);

  const handleGetMerchantClients = async () => {
    try {
      const { data } = await getMerchantClients({
        fetchPolicy: "cache-and-network",
        variables: { pagination },
      });
      setClients(data?.getMerchantClients || []);
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_CLIENTS",
            path: "ERROR_GET_CLIENTS",
            message: "Une erreur est survenue lors de la récupération des clients",
          },
        ],
        "error",
      );
      console.log("err get clients", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMerchantClients();
    }, []),
  );

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setShowClienInline(true);
      setSelectedClientId("");
    } else {
      navigation.navigate("CLIENT_LIST_DETAILS", { isNew: true });
    }
  };

  const handleNavigationToClientDetails = (id: string) => {
    if (goBack) {
      setShowClienInline(true);
      setSelectedClientId(id);
    } else {
      navigation.navigate("CLIENT_LIST_DETAILS", { isNew: false, id });
    }
  };

  const handleSearchMerchantClients = async () => {
    try {
      if (search.trim().length <= 0) handleGetMerchantClients();
      else {
        const response = await searchForMerchantClients({
          variables: {
            searchQuery: search.trim(),
            pagination: {
              limit: 20,
              offset: 0,
            },
          },
          fetchPolicy: "cache-and-network",
        });

        if (response?.data?.searchForMerchantClients) {
          setClients(response.data.searchForMerchantClients);
        }
      }
    } catch (err) {
      console.log("AN_ERROR_OCCURED", err);
    }
  };

  useDebounce(
    () => {
      handleSearchMerchantClients();
    },
    400,
    [search],
  );

  const handleLoadMore = async () => {
    try {
      if (search.trim().length > 0) {
        const { data, error } = await fetchMoreSearchMerchantClients({
          variables: {
            pagination: {
              offset: clients.length,
            },
          },
        });

        if (error) {
          console.log("err load more", error);
        }

        if (data?.searchForMerchantClients) {
          setClients([...clients, ...data?.searchForMerchantClients]);
        }
      } else {
        const { data, error } = await fetchMore({
          variables: {
            pagination: {
              offset: clients.length,
            },
          },
        });

        if (error) {
          console.log("err load more", error);
        }

        if (data?.getMerchantClients) {
          setClients([...clients, ...data?.getMerchantClients]);
        }
      }
    } catch (err) {
      console.log("err load more", err);
    }
  };

  const handleCloseSelectedTab = async () => {
    await handleGetMerchantClients();
    setShowClienInline(false);
  };

  const displaySelectedClient = () => {
    return (
      <ClientListDetails
        goBack={handleCloseSelectedTab}
        clientId={selectedClientId}
        newValue={!selectedClientId}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Clients"
          rightButtons={
            <TouchableOpacity onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          hasBackButton
          onBackPress={handleGoBack}
        />

        <Box marginVertical="s">
          <CustomTextInput
            {...{
              placeHolder: "Rechercher",
              leftIcon: (
                <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.black} />
              ),
              onChangeText: setSearch,
            }}
          />
        </Box>

        <PaginatedList
          showsVerticalScrollIndicator={false}
          data={clients}
          refreshing={loading}
          keyExtractor={(_, index) => index.toString()}
          onEndReachedThreshold={0.5}
          handleLoadMore={handleLoadMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleNavigationToClientDetails(item._id)}>
                <ClientCard client={item} isSelected={false} />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showClienInline) {
      return displaySelectedClient();
    }
    return displaySettingsList();
  };

  if (loading) {
    return <Loader />;
  }

  return <>{displayContent()}</>;
};

export default ClientList;
