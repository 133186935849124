 
import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import EDIT from "../../../../assets/icons/BASE/EDIT.svg";
import MASK from "../../../../assets/icons/BASE/MASK.svg";
import PENDING from "../../../../assets/icons/BASE/PENDING.svg";
import PREPARING from "../../../../assets/icons/BASE/PREPARING.svg";
import REFUNDED from "../../../../assets/icons/BASE/REFUNDED.svg";
import SHOPPING_BAG from "../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import VALIDATION from "../../../../assets/icons/BASE/VALIDATION.svg";
import WAITING_LIST from "../../../../assets/icons/BASE/WAITING_LIST.svg";
import { Click_Status } from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

const ICON_SIZE = 15;

interface OnlineOrderStatusButtonItemProps {
  status: Click_Status;
  type?: ONLINE_STATUS_LAYOUT_TYPE;
}

export enum ONLINE_STATUS_LAYOUT_TYPE {
  ICON,
  TEXT,
}

const OnlineOrderStatusButtonItem = ({
  status,
  type = ONLINE_STATUS_LAYOUT_TYPE.ICON,
}: OnlineOrderStatusButtonItemProps) => {
  const determineCorrectStatusColor = (): {
    borderColor: THEME_COLORS;
    iconColor: PALETTE_COLORS;
  } => {
    switch (status) {
      case Click_Status.New:
        return {
          borderColor: "ORDERONLINE_NEW_BACKGROUND",
          iconColor: "ORDERONLINE_NEW",
        };
      case Click_Status.ModifiedByUser:
        return {
          borderColor: "ORDERONLINE_MODIFIED_BACKGROUND",
          iconColor: "ORDERONLINE_MODIFIED",
        };
      case Click_Status.ModifiedByOwner:
        return {
          borderColor: "ORDERONLINE_MODIFIED_BACKGROUND",
          iconColor: "ORDERONLINE_MODIFIED",
        };
      case Click_Status.Validated:
        return {
          borderColor: "ORDERONLINE_CONFIRMED_BACKGROUND",
          iconColor: "ORDERONLINE_CONFIRMED",
        };
      case Click_Status.AutomaticValidated:
        return {
          borderColor: "ORDERONLINE_CONFIRMED_BACKGROUND",
          iconColor: "ORDERONLINE_CONFIRMED",
        };
      case Click_Status.Ended:
        return {
          borderColor: "ORDERONLINE_ENDED_BACKGROUND",
          iconColor: "ORDERONLINE_ENDED",
        };
      case Click_Status.Noshow:
        return {
          borderColor: "ORDERONLINE_NO_SHOW_BACKGROUND",
          iconColor: "ORDERONLINE_NO_SHOW",
        };
      case Click_Status.CancelledByUser:
        return {
          borderColor: "ORDERONLINE_CANCELLED_BACKGROUND",
          iconColor: "ORDERONLINE_CANCELLED",
        };
      case Click_Status.CancelledByOwner:
        return {
          borderColor: "ORDERONLINE_CANCELLED_BACKGROUND",
          iconColor: "ORDERONLINE_CANCELLED",
        };
      case Click_Status.AutomaticCancel:
        return {
          borderColor: "ORDERONLINE_CANCELLED_BACKGROUND",
          iconColor: "ORDERONLINE_CANCELLED",
        };
      case Click_Status.PaymentFailure:
        return {
          borderColor: "ORDERONLINE_CANCELLED_BACKGROUND",
          iconColor: "ORDERONLINE_CANCELLED",
        };
      case Click_Status.Refunded:
        return {
          borderColor: "BOOKING_REFUNDED_BACKGROUND",
          iconColor: "BOOKING_REFUNDED",
        };
      case Click_Status.Preparing:
        return {
          borderColor: "ORDERONLINE_PREPARING_BACKGROUND",
          iconColor: "ORDERONLINE_PREPARING",
        };
      case Click_Status.Prepared:
        return {
          borderColor: "ORDERONLINE_PREPARED_BACKGROUND",
          iconColor: "ORDERONLINE_PREPARED",
        };
      case Click_Status.WaitingList:
        return {
          borderColor: "BOOKING_WAITING_LIST_BACKGROUND",
          iconColor: "BOOKING_WAITING_LIST",
        };
    }
    return { borderColor: "greenBackground", iconColor: "green" };
  };

  const determineCorrectStatus = (): JSX.Element | undefined => {
    const color = determineCorrectStatusColor().iconColor;
    switch (status) {
      case Click_Status.New:
        return <PENDING fill={PALETTE[color]} />;
      case Click_Status.WaitingList:
        return <WAITING_LIST fill={PALETTE[color]} />;
      case Click_Status.ModifiedByUser:
        return <EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Click_Status.ModifiedByOwner:
        return <EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Click_Status.Validated:
        return <VALIDATE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Click_Status.AutomaticValidated:
        return <VALIDATE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Click_Status.CancelledByOwner:
        return <CANCEL fill={PALETTE[color]} />;
      case Click_Status.CancelledByUser:
        return <CANCEL fill={PALETTE[color]} />;
      case Click_Status.AutomaticCancel:
        return <CANCEL fill={PALETTE[color]} />;
      case Click_Status.PaymentFailure:
        return <CANCEL fill={PALETTE[color]} />;
      case Click_Status.Noshow:
        return <MASK height={20} width={20} fill={PALETTE[color]} />;
      case Click_Status.Ended:
        return <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Click_Status.Refunded:
        return <REFUNDED height={20} width={20} fill={PALETTE[color]} />;
      case Click_Status.Refunded:
        return <REFUNDED height={20} width={20} fill={PALETTE[color]} />;
      case Click_Status.Prepared:
        return <SHOPPING_BAG height={20} width={20} fill={PALETTE[color]} />;
      case Click_Status.Preparing:
        return <PREPARING height={20} width={25} fill={PALETTE[color]} />;
      default:
        return <></>;
    }
  };

  const determineCorrectStatusText = (): string => {
    switch (status) {
      case Click_Status.New:
        return "Nouvelle";

      case Click_Status.ModifiedByUser:
        return "Modifié";

      case Click_Status.ModifiedByOwner:
        return "Modifié";

      case Click_Status.Validated:
        return "Validé";

      case Click_Status.AutomaticValidated:
        return "Validé";

      case Click_Status.Preparing:
        return "En préparation";
      case Click_Status.Prepared:
        return "Prêt";

      case Click_Status.Ended:
        return "Terminé";
      case Click_Status.Noshow:
        return "No show";
      case Click_Status.CancelledByOwner:
        return "Annulé par vous";
      case Click_Status.CancelledByUser:
        return "Annulé par le client";
      case Click_Status.Refunded:
        return "Remboursé";

      case Click_Status.AutomaticCancel:
        return "Annulé automatiquement";

      case Click_Status.PaymentFailure:
        return "Echec du paiement";

      case Click_Status.WaitingList:
        return "Liste d'attente";

      default:
        return "-";
    }
  };

  return (
    <Box
      p="s"
      backgroundColor={determineCorrectStatusColor().borderColor}
      borderRadius="modal"
      flexDirection="row"
      alignItems="center"
      justifyContent={type === ONLINE_STATUS_LAYOUT_TYPE.ICON ? "center" : undefined}
      minHeight={35}
      minWidth={35}
    >
      {determineCorrectStatus()}
      {type === ONLINE_STATUS_LAYOUT_TYPE.TEXT && (
        <CustomText
          ml="s"
          variant="content"
          style={{
            color: PALETTE[determineCorrectStatusColor().iconColor],
          }}
        >
          {determineCorrectStatusText()}
        </CustomText>
      )}
    </Box>
  );
};

export default OnlineOrderStatusButtonItem;
