import type { Dispatch, SetStateAction } from "react";

import type {
  BookingWaitingListSettingsFragment,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface ServiceWaitingListProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const ServiceWaitingList = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServiceWaitingListProps) => {
  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };
  const updateBookingWaitingListSettings = (
    key: keyof BookingWaitingListSettingsFragment,
    value: boolean | number,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      waitingListSettings: {
        ...prev.waitingListSettings,
        [key]: value,
      },
    }));
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          SURBOOKING
        </CustomText>
      </Box>

      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          text="Autoriser le surbooking"
          value={bookingAvailability.waitingListSettings.isEnabled}
          onValueChange={v => updateBookingWaitingListSettings("isEnabled", v)}
        />
        <CustomText variant="text" color="lightGrey">
          Permet à vos clients de s’inscrire à une liste d’attente lorsque la capacité de
          l'établissement est atteinte
        </CustomText>
      </Box>

      {bookingAvailability.waitingListSettings.isEnabled && (
        <Box>
          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <NumberInputLine
              hasErrors={doesInputHaveError("waitingListSettings.totalPersonsAllowed")}
              textVariant="content"
              text="Nombre de couverts"
              placeHolder="0"
              value={bookingAvailability.waitingListSettings.totalPersonsAllowed}
              onValueChange={v =>
                updateBookingWaitingListSettings(
                  "totalPersonsAllowed",
                  getSafeNumberFromInput(v),
                )
              }
            />

            <CustomText variant="text" color="lightGrey">
              Couverts disponibles à la réservation dans la liste d’attente
            </CustomText>
          </Box>

          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="content"
                color={
                  doesInputHaveError("waitingListSettings.minPersons") ||
                  doesInputHaveError("waitingListSettings.maxPersons")
                    ? "danger"
                    : "primaryTextColor"
                }
              >
                Couverts acceptés
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  hasErrors={doesInputHaveError("waitingListSettings.minPersons")}
                  placeHolder="0"
                  keyboardType="number-pad"
                  hideFloatingLabel
                  value={bookingAvailability.waitingListSettings.minPersons.toString()}
                  onChangeText={v =>
                    updateBookingWaitingListSettings(
                      "minPersons",
                      getSafeNumberFromInput(v),
                    )
                  }
                  boxProps={{
                    width: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  inputStyles={{ textAlign: "center" }}
                />

                <CustomTextInput
                  hasErrors={doesInputHaveError("waitingListSettings.maxPersons")}
                  placeHolder="0"
                  keyboardType="number-pad"
                  hideFloatingLabel
                  value={bookingAvailability.waitingListSettings.maxPersons.toString()}
                  onChangeText={v =>
                    updateBookingWaitingListSettings(
                      "maxPersons",
                      getSafeNumberFromInput(v),
                    )
                  }
                  boxProps={{
                    width: 50,
                    ml: "m",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  inputStyles={{ textAlign: "center" }}
                />
              </Box>
            </Box>
            <CustomText mt="s" variant="text" color="lightGrey">
              Autoriser un minimum et maximum de couverts pour une réservation
            </CustomText>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ServiceWaitingList;
