import React, { useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Platform,
  Pressable,
  TouchableOpacity,
} from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import CART from "../../../assets/icons/BASE/CART.svg";
import DISALLOW from "../../../assets/icons/BASE/DISALLOW.svg";
import PAX_PERSONS from "../../../assets/icons/BASE/PAX_PERSONS.svg";
import PowerButton from "../../../assets/icons/BASE/PowerButton.svg";
import type { AvailabilityServiceWithModuleInfoFragment } from "../../graphql/generated/schema";
import { Modules, Service_Visibility } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import type { PALETTE_COLORS } from "../../theme/Palette";
import { PALETTE } from "../../theme/Palette";
import { compactNumber, formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";
import TimeSlotSelection from "../NewBooking/TimeSelection/TimeSlotSelection";

const IS_WEB = Platform.OS === "web";

export enum SERVICE_VISIBILITY {
  ENABLED,
  PARTIALLY,
  DISABLED,
}

type Props = {
  moduleType: Modules;
  onPress: () => void;
  isSelected: boolean;
  serviceInfo: AvailabilityServiceWithModuleInfoFragment;
  selectedDate: Date;
  onCloseServiceSlot: (serviceId: string, slotId?: string) => void;
  isServiceSlotCloseInProgress: boolean;
};

export const ServiceButton = ({
  moduleType,
  onPress,
  isSelected,
  serviceInfo,
  selectedDate,
  onCloseServiceSlot,
  isServiceSlotCloseInProgress,
}: Props) => {
  useResizeMode();

  const {
    serviceVisibility,
    name: title,
    totalForService,
    totalCapacityForService,
    slots,
  } = serviceInfo;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIdForClosure, setSelectedIdForClosure] = useState("");

  const BASE_BOX_STYLES: React.ComponentProps<typeof Box> = {
    alignItems: "flex-start",
    justifyContent: "center",
    minWidth: 116,
    minHeight: 54,
  };
  const SELECTED_BOX_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_BOX_STYLES,
    borderWidth: 1,
    borderRadius: "button",
    borderColor: "success",
  };
  const UNSELECTED_BOX_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_BOX_STYLES,
    backgroundColor: "disabled",
    borderColor: "disabled",
    borderWidth: 1,
    borderRadius: "button",
  };

  const handleIconColor = (): PALETTE_COLORS => {
    const ARE_THERE_DISABLED_SLOTS = slots.some(
      slot => slot.slotVisibility === Service_Visibility.Disabled,
    );

    if (
      serviceVisibility === Service_Visibility.Partially ||
      (ARE_THERE_DISABLED_SLOTS && !Service_Visibility.Disabled)
    ) {
      return "brightOrange";
    }
    if (serviceVisibility === Service_Visibility.Disabled) {
      return "red";
    }
    if (serviceVisibility === Service_Visibility.Enabled) {
      return "green";
    }

    return "darkBlue";
  };

  const displayValue = () => {
    if (moduleType === Modules.Reservation) {
      const bookingSuffix =
        totalCapacityForService > 0 ? `/${totalCapacityForService}` : "";

      const value = `${compactNumber(totalForService, "fr")}${bookingSuffix}`.trim();

      return value;
    }

    // const orderSuffix =
    //   totalCapacityForService > 0
    //     ? `/${formatCurrencyPrice(totalCapacityForService, "fr", "eur")}`
    //     : "";

    // const suffix = moduleType === Modules.Reservation ? bookingSuffix : orderSuffix;

    // const value = `${compactNumber(totalForService, "fr")}${suffix}`.trim();

    return formatCurrencyPrice(totalForService, "fr", "eur");
  };

  // const slotsForDay = useMemo(() => {
  //   const day = getDay(selectedDate);

  //   const isRecurring = slots.some(slot => slot.date === null);

  //   if (isRecurring) {
  //     return slots.filter(slot => slot.day === day);
  //   }
  //   return slots.filter(slot => {
  //     return isSameDay(new Date(slot.date), selectedDate);
  //   });
  // }, [selectedDate, slots]);

  const handleToggleServiceVisibility = () => {
    onCloseServiceSlot(serviceInfo.serviceId);
    setSelectedIdForClosure(serviceInfo.serviceId);
    // setIsModalVisible(false);
  };

  const handleToggleSlotVisibility = (slotId: string) => {
    setSelectedIdForClosure(slotId);
    onCloseServiceSlot(serviceInfo.serviceId, slotId);
    // setIsModalVisible(false);
  };

  const getModalTitle = () => {
    // if (serviceInfo.serviceId === "ALL") {
    //   const suffix =
    //     serviceInfo.serviceVisibility === Service_Visibility.Enabled
    //       ? "fermer"
    //       : "ouvrir";

    //   return "Tout " + suffix;
    // }

    // const prefix =
    //   serviceInfo.serviceVisibility === Service_Visibility.Enabled ||
    //   serviceInfo.serviceVisibility === Service_Visibility.Partially
    //     ? "Fermeture"
    //     : "Ouverture";

    // return prefix + " de service";
    return "Affichage complet";
  };

  // const getModalMainButtonTitle = () => {
  //   // const prefix =
  //   //   serviceInfo.serviceVisibility === Service_Visibility.Enabled ||
  //   //   serviceInfo.serviceVisibility === Service_Visibility.Partially
  //   //     ? "Fermer"
  //   //     : "Ouvrir";

  //   // if (serviceInfo.serviceId === "ALL") {
  //   //   return prefix + " tous les services";
  //   // }
  //   return "Tout le service";
  // };

  const displayServiceSlotsListContent = () => {
    return (
      <Box paddingHorizontal="s" height="100%">
        <FlatList
          // data={slotsForDay}
          data={slots}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            <Box mt="s">
              <Box mb="s">
                <CustomText
                  variant="label"
                  fontSize={14}
                  color="primaryTextColor"
                  textTransform="uppercase"
                >
                  Par service
                </CustomText>
              </Box>

              {selectedIdForClosure === serviceInfo.serviceId &&
              isServiceSlotCloseInProgress ? (
                <Box
                  minHeight={45}
                  backgroundColor="disabled"
                  borderRadius="button"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ActivityIndicator size="small" color={PALETTE.darkBlue} />
                </Box>
              ) : (
                <Pressable
                  disabled={isServiceSlotCloseInProgress}
                  onPress={() => handleToggleServiceVisibility()}
                >
                  <Box
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={45}
                    flexDirection="row"
                  >
                    <DISALLOW
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                      fill={
                        serviceInfo.serviceVisibility === Service_Visibility.Enabled ||
                        serviceInfo.serviceVisibility === Service_Visibility.Partially
                          ? PALETTE.darkBlue
                          : PALETTE.red
                      }
                    />
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      Tout le service
                    </CustomText>
                  </Box>
                </Pressable>
              )}

              {serviceInfo.serviceId !== "ALL" && (
                <Box mt="s">
                  <CustomText
                    variant="label"
                    fontSize={14}
                    color="primaryTextColor"
                    textTransform="uppercase"
                  >
                    Par Créneau
                  </CustomText>
                </Box>
              )}
            </Box>
          }
          keyExtractor={item => item.slotId}
          renderItem={({ item, index }) => {
            const {
              startTime,
              totalForSlot,
              // totalForService,
              // totalCapacityForService,
              totalCapacityForSlot,
              areLimitsEnabled,
              slotVisibility,
            } = item;
            const IS_SELECTED_FOR_CLOSURE =
              selectedIdForClosure === item.slotId && isServiceSlotCloseInProgress;

            // const IS_SELECTED = slotId === selectedSlotId;
            const SLOT_INFO = areLimitsEnabled
              ? `${totalForSlot}/${totalCapacityForSlot}`
              : "";
            const SERVICE_INFO = serviceInfo?.areLimitsEnabled
              ? `${totalForService}/${totalCapacityForService}`
              : "";
            return (
              <Box mt={index === 0 ? "s" : "m"}>
                {IS_SELECTED_FOR_CLOSURE ? (
                  <Box
                    minHeight={45}
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ActivityIndicator size="small" color={PALETTE.darkBlue} />
                  </Box>
                ) : (
                  <>
                    <TimeSlotSelection
                      {...{
                        disabled: isServiceSlotCloseInProgress,
                        areLimitsEnabled: areLimitsEnabled,
                        time: startTime,
                        isSelected: false,
                        onPress: () => handleToggleSlotVisibility(item.slotId),
                        slotText: SLOT_INFO,
                        serviceText: SERVICE_INFO,
                        slotIconColor:
                          slotVisibility === Service_Visibility.Disabled
                            ? "red"
                            : "lightGrey",
                        serviceIconColor:
                          slotVisibility === Service_Visibility.Disabled
                            ? "red"
                            : "lightGrey",
                      }}
                    />
                  </>
                )}
              </Box>
            );
          }}
        />
      </Box>
    );
  };

  const displayModalContent = () => {
    return displayServiceSlotsListContent();
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const displayModal = () => {
    return (
      <ContentModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        title={getModalTitle()}
        styles={{
          maxWidth: 450,
          height: "100%",
        }}
      >
        <Box height="90%">{displayModalContent()}</Box>
      </ContentModal>
    );
  };

  return (
    <Box>
      <CustomButton
        buttonVariant="serviceButton"
        onPress={onPress}
        buttonColor={isSelected ? "white" : "disabled"}
        styles={{
          ...(isSelected ? SELECTED_BOX_STYLES : UNSELECTED_BOX_STYLES),
          // m:"s"
          // flex: 1,
          // width: "100%",
          height: 54,
          padding: "none",
          paddingLeft: "none",
          paddingRight: "none",
          minWidth: 145,
        }}
      >
        <Box
          width={{
            phone: IS_WEB ? "100%" : undefined,
            tablet: IS_WEB ? "100%" : undefined,
          }}
          height={65}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingHorizontal="s"
        >
          <Box flex={1} pr="xs">
            <Box flexDirection="row" alignItems="flex-start">
              <CustomText
                variant="primaryButtonText"
                // paddingRight="m"
                numberOfLines={1}
                color="primaryTextColor"
              >
                {title}
              </CustomText>
            </Box>
            {serviceInfo?.areLimitsEnabled && (
              <Box
                flexDirection="row"
                alignItems="center"
                // mt="s"
              >
                {moduleType === Modules.Reservation && (
                  <PAX_PERSONS height={16} width={16} fill={PALETTE.darkBlue} />
                )}
                {moduleType === Modules.OnlineSales && <CART fill={PALETTE.darkBlue} />}

                <CustomText variant="primaryButtonText" pl="s" color="primaryTextColor">
                  {displayValue()}
                </CustomText>
              </Box>
            )}
          </Box>
          <Box height="100%" width={3} backgroundColor="lightGrey" />

          <TouchableOpacity
            onPress={() => setIsModalVisible(true)}
            style={{
              // flex: 1,
              maxWidth: 35,
              paddingHorizontal: 8,
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PowerButton fill={PALETTE[handleIconColor()]} />
          </TouchableOpacity>
        </Box>
      </CustomButton>
      {isModalVisible && displayModal()}
    </Box>
  );
};
