import { FlatList, TouchableOpacity } from "react-native";

import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface AddSubscriptionCommitmentListProps {
  commitment: number;
  onSelectCommitment: (commitment: number) => void;
}

const COMMITMENTS = [
  {
    commitment: 1,
    label: "Sans engagement",
  },
  {
    commitment: 12,
    label: "Engagement 1 an (-5%)",
  },
  {
    commitment: 24,
    label: "Engagement 2 ans (-10%)",
  },
  {
    commitment: 36,
    label: "Engagement 3 ans (-15%)",
  },
];

const AddSubscriptionCommitmentList = ({
  commitment,
  onSelectCommitment,
}: AddSubscriptionCommitmentListProps) => {
  return (
    <Box flex={1} backgroundColor="white">
      <FlatList
        data={COMMITMENTS}
        keyExtractor={item => item.label}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
        renderItem={({ item }) => {
          const IS_SELECTED = commitment === item.commitment;

          return (
            <TouchableOpacity onPress={() => onSelectCommitment(item.commitment)}>
              <Box
                marginVertical="s"
                backgroundColor={IS_SELECTED ? "white" : "disabled"}
                borderRadius="button"
                paddingHorizontal="s"
                borderColor="success"
                borderWidth={IS_SELECTED ? 1 : 0}
                minHeight={75}
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <CustomText variant="content" color="primaryTextColor">
                    {item.label}
                  </CustomText>
                </Box>
              </Box>
            </TouchableOpacity>
          );
        }}
      />
    </Box>
  );
};

export default AddSubscriptionCommitmentList;
