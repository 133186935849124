import { useState } from "react";
import { TouchableOpacity } from "react-native-gesture-handler";

import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import { CustomTextInput } from "../../TextInput";
import type { TABLE } from "../common/TableList";
import { TABLES } from "../common/TableList";

interface AddTableModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSubmit: (table: TABLE) => void;
  handleCreateMultipleTables: (table: TABLE, nbOfTables: number) => void;
}

const AddTableModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  handleCreateMultipleTables,
}: AddTableModalProps) => {
  const [isOnFirstStep, setIsOnFirstStep] = useState(true);
  const [createSeriesOfTables, setCreateSeriesOfTables] = useState(false);
  const [nbOfTablesToCreate, setNbOfTablesToCreate] = useState(1);
  const [selectedTable, setselectedTable] = useState<(typeof TABLES)[0] | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setIsOnFirstStep(true);
    setCreateSeriesOfTables(false);
    setNbOfTablesToCreate(1);
    setselectedTable(null);
  };

  const handleTableSelected = (table: TABLE) => {
    setselectedTable(table);
    setIsOnFirstStep(false);
  };

  const handleCreateSingleTable = () => {
    if (!selectedTable) return handleClose();

    onSubmit(selectedTable);
    handleClose();
  };

  const handleCreateSeriesOfTables = () => {
    if (!selectedTable) return handleClose();
    handleCreateMultipleTables(selectedTable, nbOfTablesToCreate);
    handleClose();
  };

  const displayFirstStep = () => {
    return (
      <Box
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
      >
        {TABLES.map((table, idx) => {
          return (
            <Box key={idx} mb="s" width="33%" justifyContent="center" alignItems="center">
              <TouchableOpacity onPress={() => handleTableSelected(table)}>
                {table.icon}

                <Box
                  position="absolute"
                  justifyContent="center"
                  alignItems="center"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  height="100%"
                  width="100%"
                >
                  <CustomText variant="label" color="primaryTextColor">
                    {table.capacity}p
                  </CustomText>
                </Box>
              </TouchableOpacity>
            </Box>
          );
        })}
      </Box>
    );
  };

  const displayTableSeriesSettings = () => {
    return (
      <Box>
        <Box>
          <CustomTextInput
            placeHolder="Nombre de tables"
            value={nbOfTablesToCreate.toString()}
            keyboardType="number-pad"
            isRequired
            onChangeText={v => setNbOfTablesToCreate(getSafeNumberFromInput(v))}
          />
        </Box>

        <CustomButton
          onPress={handleCreateSeriesOfTables}
          buttonVariant="primaryButton"
          buttonColor="success"
          styles={{
            mt: "m",
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Créer
          </CustomText>
        </CustomButton>
      </Box>
    );
  };

  const displaySecondStep = () => {
    if (createSeriesOfTables) return displayTableSeriesSettings();

    return (
      <Box>
        <CustomButton
          onPress={() => setCreateSeriesOfTables(true)}
          buttonVariant="outlineButton"
          borderColor="success"
        >
          <CustomText variant="content" color="success">
            Créer une série de tables
          </CustomText>
        </CustomButton>
        <CustomButton
          onPress={handleCreateSingleTable}
          buttonVariant="outlineButton"
          borderColor="success"
          styles={{
            mt: "m",
          }}
        >
          <CustomText variant="content" color="success">
            Créer une table
          </CustomText>
        </CustomButton>
      </Box>
    );
  };

  const displayContent = () => {
    if (isOnFirstStep) {
      return displayFirstStep();
    }

    return displaySecondStep();
  };

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={handleClose}
      title="Ajout de table(s)"
      styles={{
        height: "auto",
      }}
    >
      {displayContent()}
    </ContentModal>
  );
};

export default AddTableModal;
