import { useNavigation } from "@react-navigation/native";
import { isBefore, isSameHour, isSameMinute } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../../components/Base/Box";
import type { BUTTON_ACTION } from "../../../../../../components/BottomButtomWithActions";
import BottomButtomWithActions from "../../../../../../components/BottomButtomWithActions";
import BottomButton from "../../../../../../components/BottomButton";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../../../components/InputSectionTitle";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import BaseService from "../../../../../../components/OnlineSalesListDetails/BaseService";
import OrderConfirmation from "../../../../../../components/OnlineSalesListDetails/OrderConfirmation/index";
import OrderLimits from "../../../../../../components/OnlineSalesListDetails/OrderLimits/index";
import OrderSlots from "../../../../../../components/OnlineSalesListDetails/OrderSlots/index";
import OrderVisibility from "../../../../../../components/OnlineSalesListDetails/OrderVisibility";
import OrderWaitingList from "../../../../../../components/OnlineSalesListDetails/OrderWaitingList/index";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateOnlineSalesAvailabilityInput,
  OnlineSalesAvailabilityFragment,
} from "../../../../../../graphql/generated/schema";
import {
  useCreateOnlineSalesAvailbilityMutation,
  useDeleteOlineSalesAvailbilityMutation,
  useGenerateOnlineSalesAvailabilitySlotsMutation,
  useGetOnlineSalesAvailabilityLazyQuery,
  useUpdateOnlineSalesAvailbilityMutation,
} from "../../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../../utils/common";
import {
  createDateWithTime,
  formaYupErrors,
  removeTypeNames,
} from "../../../../../../utils/common";

interface TakeAwayServiceListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const DEFAULT_SERVICE: CreateOnlineSalesAvailabilityInput = {
  color: "",
  automaticClickConfirmationSettings: {
    acceptAutomatically: false,
    acceptAutomaticallyWithLimits: false,
    minArticlesPerOrder: 1,
    minPricePerOrder: 1,
  },
  interval: 15,
  isEnabled: true,
  maxArticles: {
    isEnabled: false,
    value: 1,
  },
  maxOrdersAllowedForSlot: {
    isEnabled: false,
    value: 1,
  },
  maxPrice: {
    isEnabled: false,
    value: 1,
  },
  minArticles: {
    isEnabled: false,
    value: 1,
  },
  minPrice: {
    isEnabled: false,
    value: 1,
  },
  name: "",
  slots: [],
  totalOrdersAllowed: {
    isEnabled: false,
    value: 1,
  },
  visibilitySetting: {
    isRecurring: true,
    recurringDates: {
      days: [],
      endTime: "12:00",
      startTime: "10:00",
    },
    specificDates: {
      endDate: new Date().toISOString(),
      startDate: new Date().toISOString(),
      endTime: "12:00",
      startTime: "10:00",
    },
  },
  waitingListSettings: {
    isEnabled: false,
    totalOrdersAllowed: 1,
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est obligatoire"),
  color: yup.string().required("La couleur est obligatoire"),
  isEnabled: yup.boolean().required("Le statut est obligatoire"),
  interval: yup.number().min(1).required("L'intervalle est obligatoire"),
  visibilitySetting: yup.object().shape({
    isRecurring: yup.boolean().required(),
    recurringDates: yup.object().when("isRecurring", {
      is: true,
      then: yup.object().shape({
        days: yup
          .array()
          .of(yup.number())
          .min(1, "Un jour doit être sélectionné")
          .required("Un jour doit être sélectionné"),
        startTime: yup.string().required(),
        endTime: yup.string().required(),
      }),
      otherwise: yup.object().shape({
        days: yup.array().of(yup.number()),
        startTime: yup.string().required(),
        endTime: yup.string().required(),
      }),
    }),
    specificDates: yup.object().shape({
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      startTime: yup.string().required(),
      endTime: yup
        .string()
        .required()
        .test(
          "is-end-time-after-start",
          "L'heure de fin doit etre supérieur à l'heure de début",
          function (endTime) {
            const { startTime } = this.parent;

            if (!endTime) return false;

            const endDate = createDateWithTime(new Date(), endTime);
            const startDate = createDateWithTime(new Date(), startTime);

            const isValid =
              isBefore(endDate, startDate) ||
              (isSameHour(startDate, endDate) && isSameMinute(startDate, endDate));

            return !isValid;
          },
        ),
    }),
  }),
  totalOrdersAllowed: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le total des commandes doit etre au moins à 1"),
    }),
  }),
  maxOrdersAllowedForSlot: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "Le total des commandes par créneau doit etre au moins à 1"),
    }),
  }),
  minPrice: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le prix min doit etre au moin à 1"),
    }),
  }),
  maxPrice: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le prix max doit etre au moins à 1"),
    }),
  }),
  minArticles: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le min d'articles doit etre au moins à 1"),
    }),
  }),
  maxArticles: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le max d'articles doit etre au moins à 1"),
    }),
  }),
  automaticClickConfirmationSettings: yup.object().shape({
    acceptAutomatically: yup
      .boolean()
      .required("Le statut pour l'acceptation auto est obligatoire"),
    acceptAutomaticallyWithLimits: yup
      .boolean()
      .required("Le statut pour l'acceptation auto est obligatoire"),
    minArticlesPerOrder: yup.number().when("acceptAutomaticallyWithLimits", {
      is: true,
      then: yup
        .number()
        .min(1, "Le min d'articles pour la confirmation auto doit etre au moins à 1"),
    }),
    minPricePerOrder: yup.number().when("acceptAutomaticallyWithLimits", {
      is: true,
      then: yup
        .number()
        .min(1, "Le min prix pour la confirmation auto doit etre au moins à 1"),
    }),
  }),
  waitingListSettings: yup.object().shape({
    isEnabled: yup.boolean().required("Le statut est obligatoire"),
    totalOrdersAllowed: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le total de la liste d'attente doit etre au moins à 1"),
    }),
  }),
});

const TakeAwayServiceListDetailsEdit = ({
  id,
  goBack,
}: TakeAwayServiceListDetailsEditProps) => {
  useResizeMode();
  const isNew = !id;
  const [loading, setLoading] = useState(!isNew);
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [service, setService] =
    useState<CreateOnlineSalesAvailabilityInput>(DEFAULT_SERVICE);

  const [previousService, setPreviousService] = useState<
    OnlineSalesAvailabilityFragment | undefined
  >(undefined);

  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getService] = useGetOnlineSalesAvailabilityLazyQuery();
  const [createService] = useCreateOnlineSalesAvailbilityMutation();
  const [updateService] = useUpdateOnlineSalesAvailbilityMutation();
  const [deleteService] = useDeleteOlineSalesAvailbilityMutation();

  const [generateSlots] = useGenerateOnlineSalesAvailabilitySlotsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  // const minTime = useMemo(() => {
  //   const { startDate, startTime } = service.visibilitySetting.specificDates;
  //   const { interval } = service;
  //   const start = createDateWithTime(new Date(startDate), startTime);
  //   const date = addMinutes(new Date(start), interval);

  //   return date;
  // }, [service.visibilitySetting.specificDates, service.interval]);

  const validateForm = async (
    consolidatedService: CreateOnlineSalesAvailabilityInput,
    displayErrors = true,
  ) => {
    try {
      await schema.validate(consolidatedService, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err) || []);

      if (displayErrors)
        infoAlert.openAlert("Erreur", formaYupErrors(err) || [], "error");
    }
    return false;
  };

  const canViewSlots = async () => {
    try {
      const isValid = await validateForm(service, false);

      return isValid;
    } catch (_err) {}

    return false;
  };

  const handleGetService = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await getService({
          variables: {
            id: id,
          },
        });
        if (result.data) {
          setService(result.data.getOnlineSalesAvailability);
          setPreviousService(result.data.getOnlineSalesAvailability);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetService();
  }, []);

  const handleSubmit = async () => {
    try {
      const isFormValid = await validateForm(service);

      if (!isFormValid) return;

      if (isNew) {
        const { slots } = service;
        let consolidatedService = service;

        if (!slots || slots.length === 0) {
          const generatedSlots = (await handleGenerateSlots()) || [];

          consolidatedService = {
            ...service,
            slots: generatedSlots,
          };
        }

        consolidatedService = removeTypeNames(consolidatedService);

        await createService({
          variables: {
            onlineSalesAvailability: consolidatedService,
          },
        });

        handleGoBack();
      }
    } catch (err) {
      console.log("err", err);

      if (err?.message === "CONFLICTING_AVAILABILITY") {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "CONFLICTING_BOOKING_AVAILABILITY",
              message: "Une disponibilité existe avec les mêmes paramètres",
            },
          ],
          "error",
        );
      } else {
        infoAlert.openAlert(
          "Erreur création de service",
          [
            {
              code: "ERR_CREATE_ORDER_SERVICE",
              message: "Une erreur est survenue lors de la création du service",
            },
          ],
          "error",
        );
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteService({
          variables: {
            availabilityId: id,
          },
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "OnlineSalesAvailability",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err delete", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_DELETE_SERVICE",
            message: "Une erreur est survenue lors de la suppresion du service",
          },
        ],
        "error",
      );
    }
  };

  const handleGenerateSlots = async () => {
    try {
      let consolidateService = {
        ...service,
      };

      consolidateService = removeTypeNames(consolidateService);

      delete consolidateService._id;

      const { data } = await generateSlots({
        variables: {
          onlineSalesAvailability: consolidateService,
          availabilityId: id,
        },
      });

      return data?.generateOnlineSalesAvailabilitySlots || [];
    } catch (err) {
      console.log("err generate slots", err);
    }
  };

  const displayTitle = isNew ? "Nouveau service" : "Modifier le service";

  const checkIfArraysHaveSameContent = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.every(item => arr2.includes(item));
  };

  const shouldGenerateNewSlots = () => {
    if (!previousService) return true;

    const {
      interval,
      visibilitySetting,
      automaticClickConfirmationSettings,
      waitingListSettings,
      minArticles,
      minPrice,
      maxArticles,
      maxPrice,
      maxOrdersAllowedForSlot,
      totalOrdersAllowed,
    } = service;

    if (interval !== previousService.interval) return true;

    if (
      visibilitySetting.isRecurring !== previousService.visibilitySetting.isRecurring ||
      visibilitySetting.recurringDates.startTime !==
        previousService.visibilitySetting.recurringDates.startTime ||
      visibilitySetting.recurringDates.endTime !==
        previousService.visibilitySetting.recurringDates.endTime ||
      visibilitySetting.specificDates.startDate !==
        previousService.visibilitySetting.specificDates.startDate ||
      visibilitySetting.specificDates.endDate !==
        previousService.visibilitySetting.specificDates.endDate ||
      visibilitySetting.specificDates.startTime !==
        previousService.visibilitySetting.specificDates.startTime ||
      visibilitySetting.specificDates.endTime !==
        previousService.visibilitySetting.specificDates.endTime ||
      !checkIfArraysHaveSameContent(
        visibilitySetting.recurringDates.days,
        previousService.visibilitySetting.recurringDates.days,
      )
    ) {
      return true;
    }

    if (
      automaticClickConfirmationSettings.acceptAutomatically !==
        previousService.automaticClickConfirmationSettings.acceptAutomatically ||
      automaticClickConfirmationSettings.acceptAutomaticallyWithLimits !==
        previousService.automaticClickConfirmationSettings
          .acceptAutomaticallyWithLimits ||
      automaticClickConfirmationSettings.minArticlesPerOrder !==
        previousService.automaticClickConfirmationSettings.minArticlesPerOrder ||
      automaticClickConfirmationSettings.minPricePerOrder !==
        previousService.automaticClickConfirmationSettings.minPricePerOrder
    ) {
      return true;
    }

    if (
      waitingListSettings.isEnabled !== previousService.waitingListSettings.isEnabled ||
      waitingListSettings.totalOrdersAllowed !==
        previousService.waitingListSettings.totalOrdersAllowed
    )
      return true;

    if (
      minArticles.isEnabled !== previousService.minArticles.isEnabled ||
      minArticles.value !== previousService.minArticles.value ||
      maxArticles.isEnabled !== previousService.maxArticles.isEnabled ||
      maxArticles.value !== previousService.maxArticles.value ||
      minPrice.isEnabled !== previousService.minPrice.isEnabled ||
      minPrice.value !== previousService.minPrice.value ||
      maxPrice.isEnabled !== previousService.maxPrice.isEnabled ||
      maxPrice.value !== previousService.maxPrice.value ||
      maxOrdersAllowedForSlot.isEnabled !==
        previousService.maxOrdersAllowedForSlot.isEnabled ||
      maxOrdersAllowedForSlot.value !== previousService.maxOrdersAllowedForSlot.value ||
      totalOrdersAllowed.isEnabled !== previousService.totalOrdersAllowed.isEnabled ||
      totalOrdersAllowed.value !== previousService.totalOrdersAllowed.value
    )
      return true;

    return false;
  };

  const handleMainButtonPress = async () => {
    try {
      if (id) {
        const isFormValid = await validateForm(service);

        if (!isFormValid) return;

        let updates = {
          ...service,
        };

        if (shouldGenerateNewSlots()) {
          const generatedSlots = await handleGenerateSlots();

          updates.slots = generatedSlots || [];
        }

        updates = removeTypeNames(updates);
        delete updates._id;

        await updateService({
          variables: {
            availabilityId: id,
            onlineSalesAvailability: updates,
          },
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err", err);

      if (err?.message === "CONFLICTING_AVAILABILITY") {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "CONFLICTING_AVAILABILITY",
              message: "Une disponibilité existe avec les mêmes paramètres",
            },
          ],
          "error",
        );
      } else {
        infoAlert.openAlert(
          "Erreur création de service",
          [
            {
              code: "ERR_UPDATE_SERVICE",
              message: "Une erreur est survenue lors de la sauvegarde du service",
            },
          ],
          "error",
        );
      }
    }
  };

  const handleActionPress = async (key: string) => {
    try {
      if (key === "DELETE") handleDelete();
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UNKOWN",
            message: "Une erreur est survenue.",
          },
        ],
        "error",
      );
    }
  };

  const getButtonActions = (): BUTTON_ACTION[] => {
    return [
      {
        title: "SUPPRIMER LE SERVICE",
        key: "DELETE",
        variant: "outline",
        color: "danger",
      },
    ];
  };

  if (loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres de commande." />
      </Box>
    );
  }

  return (
    <Box flex={1} backgroundColor="white">
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box>
            <BaseService service={service} setService={setService} errors={errors} />

            <OrderVisibility service={service} setService={setService} errors={errors} />

            <Box mt="m">
              <InputSectionTitle text="PARAMÈTRES AVANCÉS" />
            </Box>

            <OrderLimits service={service} setService={setService} errors={errors} />

            <OrderConfirmation
              service={service}
              setService={setService}
              errors={errors}
            />

            <OrderWaitingList service={service} setService={setService} errors={errors} />

            <OrderSlots
              service={service}
              setService={setService}
              errors={errors}
              id={id}
              onPressSlots={canViewSlots}
            />
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      {isNew ? (
        <BottomButton title="Enregistrer" onPress={handleSubmit} />
      ) : (
        <BottomButtomWithActions
          title="Enregistrer"
          textVariant="primaryButtonText"
          // backGroundColor="success"
          // color="white"
          // iconColor="white"
          actions={getButtonActions()}
          onMainActionPress={handleMainButtonPress}
          onSubmit={handleActionPress}
        />
      )}
    </Box>
  );
};

export default TakeAwayServiceListDetailsEdit;
