import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlatList, ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import SelectButton from "../../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Touchable from "../../../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateOption,
  Pagination,
  ProductOptionFragment,
  TaxFragment,
} from "../../../../../../graphql/generated/schema";
import {
  GetProductOptionsDocument,
  useCreateProductOptionMutation,
  useDeleteProductOptionMutation,
  useGetProductOptionLazyQuery,
  useGetTaxesLazyQuery,
  useUpdateProductOptionMutation,
} from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../../utils/common";
interface OptionListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const DEFAULT_PRODUCT_OPTION: CreateOption = {
  baseAvailability: {
    dates: [],
    days: [],
    endTime: "10:00",
    startTime: "10:00",
  },
  basePrice: {
    isEnabled: true,
    amount: 0,
    tax: "",
    updatedTaxRate: 0,
  },
  description: "",
  isEnabled: true,
  name: "",
  pricingRates: [],
  groupOptions: [],
};

const schema = yup.object().shape({
  name: yup.string().required("Vous devez renseigner un nom"),
  description: yup.string(),
  basePrice: yup
    .object()
    .shape({
      isEnabled: yup.boolean().required(),
      amount: yup
        .number()
        .min(0)
        .required("Vous devez renseigner un prix supérieur ou égal à 0"),
      tax: yup.string().when("amount", {
        is: (amount: number) => amount > 0,
        then: yup.string().required("Vous devez sélectionner une taxe"),
      }),
    })
    .required(),
});

const OptionListDetailsEdit = ({ id, goBack }: OptionListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const isNew = !id;
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState<TaxFragment[]>([]);
  const [option, setOption] = useState(DEFAULT_PRODUCT_OPTION);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getTaxes] = useGetTaxesLazyQuery();
  const [getOption] = useGetProductOptionLazyQuery();
  const [updateOption] = useUpdateProductOptionMutation();
  const [createOption] = useCreateProductOptionMutation();
  const [deletOption] = useDeleteProductOptionMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const taxSelectOptions = useMemo(
    () => taxes.map(tax => ({ label: tax.name, key: tax._id })),
    [taxes],
  );

  const updateOptionData = (key: keyof ProductOptionFragment, value: string | number) => {
    setOption(prev => ({ ...prev, [key]: value }));
  };

  const updateOptionPriceData = (
    key: keyof ProductOptionFragment["basePrice"],
    value: string | number,
  ) => {
    setOption(prev => ({ ...prev, basePrice: { ...prev.basePrice, [key]: value } }));
  };

  const handleGetTaxes = async () => {
    try {
      const pagination: Pagination = {
        limit: 100,
        offset: 0,
      };
      const { data } = await getTaxes({
        variables: { pagination },
      });
      if (data) {
        setTaxes(data.getTaxes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetOption = async () => {
    try {
      if (id) {
        const { data } = await getOption({
          variables: {
            optionId: id,
          },
        });

        if (data?.getProductOption) {
          const formattedOption = {
            ...data.getProductOption,
            basePrice: {
              ...data.getProductOption.basePrice,
              tax: data?.getProductOption?.basePrice?.tax?._id || "",
            },
          };
          setOption(formattedOption);
        }
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_OPTION",
            path: "ERROR_GET_OPTION",
            message: "Une erreur est survenue lors de la récupération de l'option",
          },
        ],
        "error",
      );
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTaxes();
    handleGetOption();
  }, []);

  const validateForm = async (consolidatedOption: CreateOption) => {
    try {
      await schema.validate(consolidatedOption, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const { groupOptions } = option;
    const selectedGroupOptions = groupOptions.map(groupOption => groupOption._id);

    const consolidatedOption = removeTypeNames({
      ...option,
      groupOptions: selectedGroupOptions,
    });

    const isFormValid = await validateForm(consolidatedOption);

    if (!isFormValid) return;

    try {
      if (isNew) {
        await createOption({
          variables: {
            option: consolidatedOption,
          },
          refetchQueries: [
            {
              query: GetProductOptionsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consolidatedOption);
        delete updates._id;

        await updateOption({
          variables: {
            optionId: id,
            updates,
          },
          refetchQueries: [
            {
              query: GetProductOptionsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_SUBMIT_OPTION",
            path: "ERROR_SUBMIT_OPTION",
            message: "Une erreur est survenue lors de la sauvegarde de l'option",
          },
        ],
        "error",
      );
    }
  };

  const handleGoToAddGroupOptions = () => {
    navigation.navigate("PRODUCTS_LIST_DETAILS_ADD_CATEGORY_OR_GROUP_OPTION", {
      type: "groupOption",
      selectedGroupOptions: option.groupOptions,
      selectedCategories: [],
      onSubmit: (data: { id: string; name: string }[]) => {
        setOption(prev => ({
          ...prev,
          groupOptions: data,
        }));
      },
    });
  };

  const handleRemoveGroupOption = (gOptionId: string) => {
    setOption(prev => ({
      ...prev,
      groupOptions: prev.groupOptions.filter(o => o._id !== gOptionId),
    }));
  };

  const handleUpdateAmount = (value: string) => {
    const amt = +value;

    if (isNaN(amt)) return updateOptionPriceData("amount", 0);

    updateOptionPriceData("amount", amt);
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deletOption({
          variables: {
            optionId: id,
          },
          // refetchQueries: [
          //   {
          //     query: GetProductOptionsDocument,
          //     variables: { pagination: { limit: 10, offset: 0 } },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "Option",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handleGoBack();
      }
    } catch (error) {
      console.log("error dlete option", error);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETE_OPTION",
            path: "ERROR_DELETE_OPTION",
            message: "Une erreur est survenue lors de la suppression de l'option",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  if (loading) {
    return <Loader />;
  }

  const displayTitle = isNew ? "Nouvelle option" : "Modifier l'option";

  return (
    <>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <ScreenHeader
                title={displayTitle}
                hasBackButton
                onBackPress={handleGoBack}
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Nom de l'option"
                hasErrors={doesInputHaveError("name")}
                initialValue={option.name}
                onChangeText={t => updateOptionData("name", t)}
                isRequired
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Description de l'option"
                initialValue={option.description}
                onChangeText={t => updateOptionData("description", t)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <CustomText variant="label" color="primaryTextColor">
                Prix
              </CustomText>
              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  placeHolder="Prix"
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("amount")}
                  value={option.basePrice.amount.toString()}
                  onChangeText={t => updateOptionPriceData("amount", t)}
                  onBlur={t => {
                    updateOptionPriceData("amount", getSafeNumberFromInput(t));
                  }}
                  isRequired
                />
                <Box ml="s">
                  <SelectButton
                    isMultiSelect={false}
                    options={taxSelectOptions}
                    hasErrors={doesInputHaveError("tax")}
                    selectedOptions={option.basePrice.tax ? [option.basePrice.tax] : []}
                    onPress={([tax]) => updateOptionPriceData("tax", tax)}
                    placeHolder="Taxe"
                  />
                </Box>
              </Box>
            </Box>
            <Box mt="m">
              <CustomText variant="content" color="success" textTransform="uppercase">
                Groupes d'options
              </CustomText>

              <FlatList
                data={option.groupOptions}
                keyExtractor={item => item?._id || item}
                ListHeaderComponent={
                  <Touchable onPress={handleGoToAddGroupOptions}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="s"
                      marginVertical="s"
                    >
                      <Box flex={1} mr="s">
                        <InlineSelectButton
                          title="Associer à un groupe d'options"
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <PLUS fill={PALETTE.green} width={ICON_SIZE} height={ICON_SIZE} />
                    </Box>
                  </Touchable>
                }
                renderItem={({ item }) => (
                  <Box
                    mt="s"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box flex={1} mr="s">
                      <InlineSelectButton
                        options={[]}
                        title={item.name}
                        selectedOptionKeys={[]}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <Touchable onPress={() => handleRemoveGroupOption(item._id)}>
                      <TRASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
                    </Touchable>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </>
  );
};

export default OptionListDetailsEdit;
