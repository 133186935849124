import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface LabelIncrementerProps {
  label: string;
  value: number;
  incrementor?: number;
  isDisabled?: boolean;
  onValueChange: (value: number) => void;
  min: number;
  max: number;
}

const BUTTON_SIZE = 35;

const LabelIncrementer = ({
  label,
  value = 0,
  incrementor = 1,
  onValueChange,
  isDisabled,
  min = 0,
  max = 10,
}: LabelIncrementerProps) => {
  const handleChange = (type: "INCREMENT" | "DECREMENT") => {
    if (isDisabled) return;
    if (type === "INCREMENT" && value < max) {
      onValueChange(value + incrementor);
    } else if (type === "DECREMENT" && value > min) {
      onValueChange(value - incrementor);
    }
  };

  return (
    <Box flexDirection="row" alignItems="center">
      <Box mr="m">
        <CustomText variant="label" color="primaryTextColor">
          {label}
        </CustomText>
      </Box>
      <TouchableOpacity onPress={() => handleChange("DECREMENT")}>
        <Box
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          alignItems="center"
          justifyContent="center"
          p="s"
          backgroundColor="white"
          borderColor="success"
          borderWidth={LINE_THICKNESS}
          borderRadius="button"
          mr="s"
        >
          <CustomText variant="label" color="primaryTextColor">
            -
          </CustomText>
        </Box>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handleChange("INCREMENT")}>
        <Box
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          alignItems="center"
          justifyContent="center"
          p="s"
          backgroundColor="white"
          borderColor="success"
          borderWidth={LINE_THICKNESS}
          borderRadius="button"
          mr="s"
        >
          <CustomText variant="label" color="primaryTextColor">
            +
          </CustomText>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default LabelIncrementer;
