import { addHours, format, isAfter } from "date-fns";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";

import type {
  BookingAvailabilityFragment,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { createDateWithTime } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import DateInput from "../../DateInput";
import DaysSelector from "../../DaysSelector/index";
import type { Tab } from "../../OutlineTabs/index";
import OutlineTabs from "../../OutlineTabs/index";
import SelectButton from "../../Select/SelectButton";
import TimeInput from "../../TimeInput";

interface ServiceVisibilityProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const INTERVALS = [
  {
    label: "15 minutes",
    key: "15",
  },
  {
    label: "30 minutes",
    key: "30",
  },
  {
    label: "45 minutes",
    key: "45",
  },
  {
    label: "60 minutes",
    key: "60",
  },
];

const TABS: Tab[] = [
  {
    title: "Jour(s) récurrent(s)",
    key: "DAYS",
  },
  {
    title: "Jour(s) spécifique(s)",
    key: "SPECIFIC_DAYS",
  },
];

const ServiceVisibility = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServiceVisibilityProps) => {
  const [selectedTab, setSelectedTab] = useState(
    bookingAvailability?.visibilitySetting?.isRecurring ? TABS[0].key : TABS[1].key,
  );

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateBookingAvailabilityData = (
    key: keyof BookingAvailabilityFragment,
    value: number,
  ) => {
    setBookingAvailability(prev => ({ ...prev, [key]: value }));
  };

  const updateBookingRecurring = (value: boolean) => {
    setBookingAvailability(prev => ({
      ...prev,
      visibilitySetting: { ...prev.visibilitySetting, isRecurring: value },
    }));
  };

  const updateBookingRecurringVisibilitySettingsData = (
    key: keyof CreateBookingAvailabilityInput["visibilitySetting"]["recurringDates"],
    value: number[] | number,
  ) => {
    const days = Array.isArray(value) ? value : [value];
    setBookingAvailability(prev => ({
      ...prev,
      visibilitySetting: {
        ...prev.visibilitySetting,
        recurringDates: {
          ...prev.visibilitySetting.recurringDates,
          [key]: days,
        },
      },
    }));
  };

  const displayTimes = (type: "startTime" | "endTime") => {
    const { isRecurring, recurringDates, specificDates } =
      bookingAvailability.visibilitySetting;

    if (isRecurring) {
      return recurringDates[type];
    } else {
      return specificDates[type];
    }
  };

  const updateTimes = (type: "startTime" | "endTime", value: Date | undefined) => {
    if (value) {
      const time = format(value, "HH:mm");

      if (type === "startTime") {
        const startTime = new Date(value);
        const endTime = createDateWithTime(
          new Date(),
          bookingAvailability.visibilitySetting.recurringDates.endTime,
        );

        const consolidatedStartTime = time;
        const consolidatedEndTime = isAfter(startTime, endTime)
          ? format(addHours(startTime, 2), "HH:mm")
          : bookingAvailability.visibilitySetting.recurringDates.endTime;

        setBookingAvailability(prev => ({
          ...prev,
          visibilitySetting: {
            ...prev.visibilitySetting,
            recurringDates: {
              ...prev.visibilitySetting.recurringDates,
              endTime: consolidatedEndTime,
              startTime: consolidatedStartTime,
            },
            specificDates: {
              ...prev.visibilitySetting.specificDates,
              endTime: consolidatedEndTime,
              startTime: consolidatedStartTime,
            },
          },
        }));
      } else {
        setBookingAvailability(prev => ({
          ...prev,
          visibilitySetting: {
            ...prev.visibilitySetting,
            recurringDates: {
              ...prev.visibilitySetting.recurringDates,
              [type]: time,
            },
            specificDates: {
              ...prev.visibilitySetting.specificDates,
              [type]: time,
            },
          },
        }));
      }
    }
  };

  const updateBookingSpecificVisibilitySettingsData = (
    key: keyof CreateBookingAvailabilityInput["visibilitySetting"]["specificDates"],
    value: string,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      visibilitySetting: {
        ...prev.visibilitySetting,
        specificDates: {
          ...prev.visibilitySetting.specificDates,
          [key]: value,
        },
      },
    }));
  };

  const handleSelectTab = (key: string) => {
    setSelectedTab(key);

    if (key === "SPECIFIC_DAYS") {
      updateBookingRecurring(false);
      updateBookingRecurringVisibilitySettingsData("days", []);
    } else {
      updateBookingRecurring(true);
      updateBookingSpecificVisibilitySettingsData("startDate", new Date().toISOString());
      updateBookingSpecificVisibilitySettingsData("endDate", new Date().toISOString());
    }
  };

  const displayTimeInputs = () => {
    return (
      <Box mt="s">
        <Box>
          <CustomText variant="content" color="primaryTextColor">
            Horaires réservables
          </CustomText>
        </Box>
        <Box ml="m">
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText
              variant="content"
              color={
                doesInputHaveError("visibilitySettings.recurringDates.startTime") ||
                doesInputHaveError("visibilitySettings.specificDates.startTime")
                  ? "danger"
                  : "lightGrey"
              }
            >
              Heure de début du service
            </CustomText>

            <TimeInput
              date={createDateWithTime(new Date(), displayTimes("startTime"))}
              onChange={date => updateTimes("startTime", date)}
              placeHolder="Début du service"
              interval={bookingAvailability.interval}
            />

            {/* <RNDateTimePicker
              is24Hour
              minuteInterval={bookingAvailability.interval}
              mode="time"
              value={createDateWithTime(new Date(), displayTimes("startTime"))}
              onChange={(_, date) => updateTimes("startTime", date)}
            /> */}
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText
              variant="content"
              color={
                doesInputHaveError("visibilitySettings.recurringDates.endTime") ||
                doesInputHaveError("visibilitySettings.specificDates.endTime")
                  ? "danger"
                  : "lightGrey"
              }
            >
              Heure de fin du service
            </CustomText>
            <TimeInput
              date={createDateWithTime(
                new Date(),
                bookingAvailability.visibilitySetting.recurringDates.endTime,
              )}
              onChange={date => updateTimes("endTime", date)}
              placeHolder="Fin du service"
              interval={bookingAvailability.interval}
            />
            {/* <RNDateTimePicker
              onChange={(_, date) => updateTimes("endTime", date)}
              is24Hour
              minuteInterval={bookingAvailability.interval}
              mode="time"
              // minimumDate={createDateWithTime(new Date(), displayTimes("startTime"))}
              value={createDateWithTime(new Date(), displayTimes("endTime"))}
            /> */}
          </Box>
        </Box>
      </Box>
    );
  };

  const displayDaysSelect = () => {
    return (
      <Box mt="s">
        <DaysSelector
          isMulti
          selectedDays={bookingAvailability.visibilitySetting.recurringDates.days}
          onDayPress={days => updateBookingRecurringVisibilitySettingsData("days", days)}
        />
      </Box>
    );
  };

  const updateSpecificDate = (type: "startDate" | "endDate", date: Date) => {
    updateBookingSpecificVisibilitySettingsData(type, date?.toISOString());
    if (
      type === "startDate" &&
      isAfter(date, new Date(bookingAvailability.visibilitySetting.specificDates.endDate))
    ) {
      updateBookingSpecificVisibilitySettingsData("endDate", date?.toISOString());
    }
  };

  const displayDatesSelect = () => {
    return (
      <Box mt="s">
        <Box mt="s">
          <DateInput
            onChange={date => updateSpecificDate("startDate", date)}
            date={new Date(bookingAvailability.visibilitySetting.specificDates.startDate)}
            placeHolder="Debut du période"
          />
        </Box>
        <Box mt="s">
          <DateInput
            onChange={date => updateSpecificDate("endDate", date)}
            date={new Date(bookingAvailability.visibilitySetting.specificDates.endDate)}
            placeHolder="Fin du période"
          />
        </Box>
      </Box>
    );
  };

  const displayVisibilityContent = () => {
    if (selectedTab === "DAYS") {
      return <Box>{displayDaysSelect()}</Box>;
    }

    return <Box>{displayDatesSelect()}</Box>;
  };

  const displayVisibility = () => {
    return (
      <Box>
        <Box mt="m">
          <CustomText
            variant="content"
            color={
              doesInputHaveError("visibilitySetting.recurringDates.days") ||
              doesInputHaveError("visibilitySetting.specificDates.startDate") ||
              doesInputHaveError("visibilitySetting.specificDates.endDate")
                ? "danger"
                : "primaryTextColor"
            }
          >
            Disponibilité
          </CustomText>
          <Box mt="s">
            <CustomText variant="text" color="lightGrey">
              Sélectionnez les jours de la semaine concernés par ce service ou directement
              des dates bien précises
            </CustomText>
          </Box>
        </Box>

        <Box mt="m">
          <OutlineTabs
            tabs={TABS}
            onTabPress={handleSelectTab}
            selectedTab={selectedTab}
          />
        </Box>

        <Box>
          {displayVisibilityContent()}
          <Box mt="s">{displayTimeInputs()}</Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
      {displayVisibility()}

      <Box mt="m">
        <Box>
          <Box mb="s">
            <CustomText variant="label" color="primaryTextColor">
              Créneaux de réservations
            </CustomText>
          </Box>

          <SelectButton
            isMultiSelect={false}
            options={INTERVALS}
            selectedOptions={[bookingAvailability.interval.toString()]}
            onPress={([interval]) => updateBookingAvailabilityData("interval", +interval)}
            placeHolder="intervalle"
          />
          <Box mt="s">
            <CustomText variant="content" color="lightGrey">
              Intervalle entre 2 réservations
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceVisibility;
