import type { OrderFragment } from "../../../../../graphql/generated/schema";
import Box from "../../../../Base/Box";
import { CustomTextInput } from "../../../../TextInput";

interface OnlineOrderDetailsEditTabProps {
  data: OrderFragment;
  updateData: (data: OrderFragment) => void;
}

const OnlineOrderDetailsEditTab = ({
  data,
  updateData,
}: OnlineOrderDetailsEditTabProps) => {
  const { commentOwner } = data;

  return (
    <Box>
      <CustomTextInput
        placeHolder="Note sur la commande"
        initialValue={commentOwner}
        onChangeText={(text: string) => updateData({ ...data, commentOwner: text })}
        variant="defaultTextInput"
        boxProps={{
          borderColor: "disabled",
          borderWidth: 1,
          borderRadius: "button",
        }}
      />
    </Box>
  );
};

export default OnlineOrderDetailsEditTab;
