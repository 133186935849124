import { FlatList } from "react-native";

import Box from "../../../../components/Base/Box";
import { MODULE_TYPE } from "../../../../components/ModuleList/common";
import QuotesListCard from "../../../../components/Quotes/QuotesListCard";
import { ServiceListHeader } from "../../../../components/ServiceListHeader";
import type { InvoicesByMonth } from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import type { QuoteInvoicesByMonth } from "../../../../types/types";
import { formatCurrencyPrice } from "../../../../utils/common";

interface QuoteListItemRendererProps {
  quotesForMonth: QuoteInvoicesByMonth | InvoicesByMonth;
  onItemPress: (itemId: string) => void;
  onItemExtraPress: (itemId: string) => void;
  isForQuote: boolean;
}

const QuoteListItemRenderer = ({
  quotesForMonth,
  onItemExtraPress,
  onItemPress,
  isForQuote,
}: QuoteListItemRendererProps) => {
  const { month, accumulatedTotal } = quotesForMonth;
  const data = quotesForMonth.quotes || quotesForMonth.invoices || [];

  const formattedAccumulatedTotal = formatCurrencyPrice(accumulatedTotal, "fr", "eur");

  const totalQuotes = data.length;

  const displayTotalQuoteInvoiceText = () => {
    if (isForQuote) {
      return `${totalQuotes} devis`;
    }

    return `${totalQuotes} factures`;
  };

  return (
    <Box>
      <FlatList
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <ServiceListHeader
            moduleType={MODULE_TYPE.ACCOUNTING}
            serviceName={month}
            totalBookingsText={displayTotalQuoteInvoiceText()}
            totalPersonsText={formattedAccumulatedTotal}
          />
        }
        data={data}
        keyExtractor={item => item._id}
        renderItem={({ item }) => {
          return (
            <Box
              paddingHorizontal="s"
              borderBottomWidth={LINE_THICKNESS}
              borderBottomColor="disabled"
            >
              <QuotesListCard
                {...{
                  item,
                  isForQuote,
                  onPress: () => onItemPress(item._id),
                  onExtraActionPress: () => onItemExtraPress(item._id),
                }}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default QuoteListItemRenderer;
