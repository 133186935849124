import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

// import config from "../config/env";
import { STORAGE_KEYS, getFromStorage } from "../utils/Login";

// Sentry.init({
//   dsn: "https://e1d7f51c226442628fc7df14de1ae7f6@o1144402.ingest.sentry.io/6208439",
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production.
//   tracesSampleRate: 1.0,
//   enableInExpoDevelopment: true,
//   environment: config.APP_ENV,
// });

// const setBaseContext = async () => {
//   const licence = await getFromStorage(STORAGE_KEYS.LICENCE_KEY);
//   const staffId = await getFromStorage(STORAGE_KEYS.STAFF_ID);
//   const isOwner = staffId ? true : false;

//   Sentry.Browser.setContext("extra", {
//     licence,
//     staffId,
//     isOwner,
//   });
// };

// setBaseContext();

// console.log("called on web", Platform.OS);

export const captureAndReportErrors = async (err: unknown) => {
  const licence = await getFromStorage(STORAGE_KEYS.LICENCE_KEY);
  const staffId = await getFromStorage(STORAGE_KEYS.STAFF_ID);
  const isOwner = staffId ? true : false;

  const captureContext = {
    tags: {
      source: Platform.OS,
    },
    extra: {
      licence,
      staffId,
      isOwner,
    },
  };

  Sentry.Browser.captureException(err, captureContext);
};
