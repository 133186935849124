import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import DateInput from "../../../../../components/DateInput";
import ErrorMessage from "../../../../../components/ErrorMessage";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import AddNewImageModal from "../../../../../components/Media/AddNewImageMedia";
import PhoneInput from "../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import SingleSelectButton from "../../../../../components/SingleSelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import Toggle from "../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  MerchantClient,
  MerchantClientCompany,
  MerchantClientInput,
} from "../../../../../graphql/generated/schema";
import {
  GetMerchantClientsDocument,
  Sex,
  useCreateMerchantClientMutation,
  useDeleteMerchantClientMutation,
  useGetCompaniesLazyQuery,
  useGetMerchantClientLazyQuery,
  useSavePictureMutation,
  useUpdateMerchantClientMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";
interface ClientListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 65;

const DEFAULT_CLIENT: Omit<MerchantClientInput, "_id"> = {
  acceptedReceiveMarketingSms: false,
  acceptedReceiveNewsLetters: false,
  comment: "",
  dateOfBirth: null,
  email: "",
  firstName: "",
  isBlacklisted: false,
  isVIP: false,
  language: "",
  lastName: "",
  phone: "",
  picture: "",
  secondaryEmail: "",
  secondaryPhone: "",
  sex: Sex.Female,
  merchantCompanyId: "",
};

const schema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email(),
  secondaryEmail: yup.string().email(),
  sex: yup.string().required(),
});

const ClientListDetailsEdit = ({ id, goBack }: ClientListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const isNew = !id;
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [companies, setCompanies] = useState<MerchantClientCompany[]>([]);
  const [client, setClient] = useState(DEFAULT_CLIENT);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  const [getCompanies] = useGetCompaniesLazyQuery();

  const [getClient] = useGetMerchantClientLazyQuery();
  const [createMerchantClient] = useCreateMerchantClientMutation();
  const [updateMerchantClient] = useUpdateMerchantClientMutation();
  const [deleteMerchantClient] = useDeleteMerchantClientMutation();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const displayTitle = () => {
    if (isNew) {
      return "Nouveau client";
    }

    return "Modifier le client";
  };

  const handleGetCompanies = async () => {
    try {
      const { data } = await getCompanies({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getMerchantClientCompanies) {
        setCompanies(data?.getMerchantClientCompanies || []);
      }
    } catch (err) {}
  };

  useEffect(() => {
    handleGetCompanies();
  }, []);

  const handleGetClient = async () => {
    try {
      if (!isNew) {
        const { data } = await getClient({
          variables: {
            merchantClientId: id,
          },
        });

        if (data?.getMerchantClient) {
          setClient(data?.getMerchantClient);
          setSelectedCompany(data?.getMerchantClient?.merchantCompanyId?._id || "");
        }
      } else {
        setClient(DEFAULT_CLIENT);
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_CLIENT",
            path: "ERROR_GET_CLIENT",
            message: "Une erreur est survenue lors de la récupération du client",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClient();
  }, []);

  const displayCompanies = () => {
    return companies.map(company => {
      return {
        label: company.companyName,
        key: company._id,
      };
    });
  };

  const validateForm = async () => {
    try {
      await schema.validate(client, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      if (isNew) {
        await createMerchantClient({
          variables: {
            merchantClient: client,
          },
          refetchQueries: [
            {
              query: GetMerchantClientsDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(client);
        delete updates.bookingOccurences;
        delete updates.orderOccurences;
        delete updates.ticketOccurences;
        delete updates.generatedProfit;
        delete updates.averageRating;

        await updateMerchantClient({
          variables: {
            merchantClientId: id,
            merchantClient: {
              ...updates,
              merchantCompanyId: selectedCompany,
            },
          },
          refetchQueries: [
            {
              query: GetMerchantClientsDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err", err);
      setErrors([{ path: "submit", message: err.message }]);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_CLIENT",
            path: "ERROR_UPDATE_CLIENT",
            message: "Une erreur est survenue lors de la mise à jour du client",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleDeleteClient = async () => {
    try {
      if (id) {
        await deleteMerchantClient({
          variables: {
            merchantClientId: id,
          },
          // refetchQueries: [
          //   {
          //     query: GetMerchantClientsDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "MerchantClient",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handleGoBack();
      }
    } catch (err) {
      console.log("err delete", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETE_CLIENT",
            path: "ERROR_DELETE_CLIENT",
            message: "Une erreur est survenue lors de la suppression du client",
          },
        ],
        "error",
      );
    }
  };

  const handleUpdateClient = (
    key: keyof MerchantClient,
    value: MerchantClient[keyof MerchantClient],
  ) => {
    setClient(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleOpenAddressEdit = () => {
    navigation.navigate("ADDRESS_DETAILS", {
      address: client?.address,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit: (address: any) => {
        handleUpdateClient("address", address);
        navigation.goBack();
      },
    });
  };

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setClient(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = client;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (!client) {
    return (
      <Box>
        <ScreenHeader title="Client" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données." />
      </Box>
    );
  }

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle()} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>

            <Box
              mt="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <CustomText
                  variant="label"
                  color={doesInputHaveError("sex") ? "danger" : "primaryTextColor"}
                >
                  Civilité
                </CustomText>
              </Box>
              <Box flexDirection="row" alignItems="center">
                <Box marginHorizontal="s">
                  <SingleSelectButton
                    isSelected={client.sex === Sex.Female}
                    onPress={() => handleUpdateClient("sex", Sex.Female)}
                    title="Madame"
                  />
                </Box>
                <Box>
                  <SingleSelectButton
                    isSelected={client.sex === Sex.Male}
                    onPress={() => handleUpdateClient("sex", Sex.Male)}
                    title="Monsieur"
                  />
                </Box>
              </Box>
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Nom"
                isRequired
                hasErrors={doesInputHaveError("name")}
                initialValue={client.lastName}
                onChangeText={v => handleUpdateClient("lastName", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Prénom"
                initialValue={client.firstName}
                onChangeText={v => handleUpdateClient("firstName", v)}
              />
            </Box>

            {companies.length > 0 && (
              <Box mt="s">
                <SelectButton
                  isMultiSelect={false}
                  options={displayCompanies()}
                  selectedOptions={selectedCompany ? [selectedCompany] : []}
                  onPress={cmps => setSelectedCompany(cmps[0])}
                  placeHolder="Entreprise"
                />
              </Box>
            )}

            <Box mt="s">
              <PhoneInput
                value={client.phone}
                hasErrors={doesInputHaveError("phone")}
                onChangeFormattedText={t => handleUpdateClient("phone", t)}
              />
            </Box>
            <Box mt="s">
              <PhoneInput
                value={client.secondaryPhone}
                hasErrors={doesInputHaveError("secondaryPhone")}
                onChangeFormattedText={t => handleUpdateClient("secondaryPhone", t)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                isRequired
                initialValue={client.email}
                hasErrors={doesInputHaveError("email")}
                onChangeText={v => handleUpdateClient("email", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email secondaire"
                initialValue={client.secondaryEmail}
                hasErrors={doesInputHaveError("secondaryEmail")}
                onChangeText={v => handleUpdateClient("secondaryEmail", v)}
              />
            </Box>

            <Box mt="s">
              <DateInput
                date={client.dateOfBirth ? new Date(client.dateOfBirth) : undefined}
                onChange={date => handleUpdateClient("dateOfBirth", date?.toISOString())}
                placeHolder="Date d'anniversaire"
              />
            </Box>

            <Box mt="s">
              <TouchableOpacity onPress={handleOpenAddressEdit}>
                <CustomTextInput
                  disabled
                  boxProps={{
                    backgroundColor: "white",
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                  placeHolder="Adresse"
                  initialValue={client?.address?.formattedAddress || ""}
                  value={client?.address?.formattedAddress || ""}
                />
              </TouchableOpacity>
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Note sur le client"
                multiLine
                initialValue={client.comment}
                onChangeText={v => handleUpdateClient("comment", v)}
              />
            </Box>

            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Accepte les campagnes SMS
              </CustomText>
              <Toggle
                value={client.acceptedReceiveMarketingSms}
                onChange={v => handleUpdateClient("acceptedReceiveMarketingSms", v)}
              />
            </Box>
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Accepte les Newsletters
              </CustomText>
              <Toggle
                value={client.acceptedReceiveNewsLetters}
                onChange={v => handleUpdateClient("acceptedReceiveNewsLetters", v)}
              />
            </Box>
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Client Blacklisté
              </CustomText>
              <Toggle
                value={client.isBlacklisted}
                onChange={v => handleUpdateClient("isBlacklisted", v)}
              />
            </Box>
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                VIP
              </CustomText>
              <Toggle
                value={client.isVIP}
                onChange={v => handleUpdateClient("isVIP", v)}
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDeleteClient}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>

      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default ClientListDetailsEdit;
