import { useMemo, useState } from "react";
import { Dimensions, Platform, TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import type { Pagination, PopCallFragment } from "../../graphql/generated/schema";
import { useGetPopCallsQuery } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import { CustomTextInput } from "../TextInput";

import CallCard from "./CallCard";

const { height } = Dimensions.get("window");

const IS_WEB = Platform.OS === "web";
interface CallListProps {
  call?: PopCallFragment;
  onCallSelected: (call: PopCallFragment) => void;
  goBack: () => void;
}

const CallList = ({ call, onCallSelected, goBack }: CallListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState<Pagination>({
    limit: 40,
    offset: 0,
  });
  const { loading, error, data } = useGetPopCallsQuery({
    variables: {
      pagination,
    },
  });

  const calls = useMemo(
    () =>
      data?.getPopCalls?.filter(c => {
        const search = searchQuery.toLowerCase();

        if (!search) return true;

        return (
          c.merchantClientId?.firstName?.toLowerCase().includes(search) ||
          c.merchantClientId?.lastName?.toLowerCase().includes(search) ||
          c?.callPhoneNumber?.toLowerCase().includes(search)
        );
      }) || [],
    [data, searchQuery],
  );

  if (loading) {
    return <Loader />;
  }

  if (error || !data?.getPopCalls) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue lors du chargement des appels." />

        <Box
          position="absolute"
          p="s"
          pb="l"
          bottom={0}
          left={0}
          right={0}
          backgroundColor="white"
        >
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={goBack}
            overrideStyles={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box ml="s">
              <LEFT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Box>

            <CustomText variant="primaryButtonText" color="success">
              Retourner fiche client
            </CustomText>

            <Box />
          </CustomButton>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box flex={IS_WEB ? 1 : undefined}>
        <Box mt="s">
          <Box justifyContent="center" alignItems="center">
            <CustomText variant="label" color="primaryTextColor">
              Reçus EiwieCall
            </CustomText>
            {/* <CustomText variant="content" color="lightGrey">
            2590
          </CustomText> */}
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Rechercher un appel"
              onChangeText={setSearchQuery}
            />
          </Box>

          <Box maxHeight={height - 300}>
            <FlatList
              data={calls}
              keyExtractor={item => item._id}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                paddingBottom: 300,
                // maxHeight: height - 100,
                // flex: 1,
              }}
              renderItem={({ item }) => {
                const IS_SELECTED = call?._id === item._id;
                return (
                  <TouchableOpacity
                    // style={{ marginVertical: 8 }}
                    onPress={() => onCallSelected(item)}
                  >
                    <CallCard isSelected={IS_SELECTED} call={item} />
                  </TouchableOpacity>
                );
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        p="s"
        pb="xl"
        bottom={0}
        left={0}
        right={0}
        backgroundColor="white"
        borderTopColor="black"
        borderTopWidth={1}
      >
        <CustomButton
          buttonVariant="outlineButton"
          borderColor="success"
          onPress={goBack}
          overrideStyles={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box ml="s">
            <LEFT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <CustomText variant="primaryButtonText" color="success">
            Retourner fiche client
          </CustomText>

          <Box />
        </CustomButton>
      </Box>
    </>
  );
};

export default CallList;
