import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useMemo, useState } from "react";
import { ScrollView } from "react-native";

import COMMENT from "../../../../assets/icons/BASE/COMMENT.svg";
import PAX_PERSONS from "../../../../assets/icons/BASE/PAX_PERSONS.svg";
import SEARCH from "../../../../assets/icons/BASE/SEARCH.svg";
import STAR from "../../../../assets/icons/BASE/STAR.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import { CustomButton } from "../../../components/Button";
import CollapsibleList from "../../../components/CollapsibleList";
import Loader from "../../../components/Loader";
import ReviewListCard from "../../../components/Reviews/ReviewListCard";
import ScreenHeader from "../../../components/ScreenHeader";
import SplitView from "../../../components/SplitView";
import { CustomTextInput } from "../../../components/TextInput";
import { AppContext } from "../../../contexts/AppContext";
import type {
  GetReviewsOverallSummary,
  Pagination,
  ReviewFragment,
} from "../../../graphql/generated/schema";
import {
  useGetReviewsLazyQuery,
  useGetReviewsOverallSummaryLazyQuery,
} from "../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { compactNumber } from "../../../utils/common";
import ReviewsListDetails from "../ReviewsListDetails";

const ReviewsList = () => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUnansweredReviewsOpen, setIsUnansweredReviewsOpen] = useState(false);
  const [isAllReviewsOpen, setIsAllReviewsOpen] = useState(false);
  const [reviews, setReviews] = useState<ReviewFragment[]>([]);
  const [reviewSummary, setReviewSummary] = useState<GetReviewsOverallSummary>({});
  const [pagination, setPagination] = useState<Pagination>({ limit: 40, offset: 0 });
  const [selectedReviewId, setSelectedReviewId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [getReviews] = useGetReviewsLazyQuery();
  const [getReviewSummary] = useGetReviewsOverallSummaryLazyQuery();

  const unAnsweredReviews = useMemo(
    () =>
      reviews
        .filter(review => !review.answer)
        .filter(review => {
          const formattedQuery = searchQuery.trim().toLowerCase();
          if (formattedQuery) {
            const formattedTitle = review?.title?.trim()?.toLowerCase() || "";
            const formattedComment = review?.comment?.trim()?.toLowerCase() || "";
            const formattedFirstName =
              review?.merchantClient?.firstName?.trim().toLowerCase() || "";
            const formattedLastName =
              review?.merchantClient?.lastName?.trim()?.toLowerCase() || "";

            return (
              formattedTitle.includes(formattedQuery) ||
              formattedComment.includes(formattedQuery) ||
              formattedFirstName.includes(formattedQuery) ||
              formattedLastName.includes(formattedQuery)
            );
          }
          return true;
        }),
    [reviews, searchQuery],
  );
  const answeredReviews = useMemo(
    () =>
      reviews
        .filter(review => review.answer)
        .filter(review => {
          const formattedQuery = searchQuery.trim().toLowerCase();
          if (formattedQuery) {
            const formattedTitle = review?.title?.trim().toLowerCase() || "";
            const formattedComment = review?.comment?.trim().toLowerCase() || "";
            const formattedFirstName =
              review?.merchantClient?.firstName.trim().toLowerCase() || "";
            const formattedLastName =
              review?.merchantClient?.lastName.trim().toLowerCase() || "";

            return (
              formattedTitle.includes(formattedQuery) ||
              formattedComment.includes(formattedQuery) ||
              formattedFirstName.includes(formattedQuery) ||
              formattedLastName.includes(formattedQuery)
            );
          }
          return true;
        }),
    [reviews, searchQuery],
  );

  const handleGetReviewSummary = async () => {
    try {
      const { data } = await getReviewSummary({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setReviewSummary(data.getMerchangetReviewsOverallSummarytReview);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlGetReview = async () => {
    try {
      const { data } = await getReviews({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination,
        },
      });

      if (data) {
        setReviews(data.getMerchantReviews);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInit = async () => {
    try {
      await handlGetReview();
      await handleGetReviewSummary();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleInit();
    }, []),
  );

  const RIGHT_BUTTONS = (
    <Box flexDirection="row" alignItems="center">
      <CustomButton onPress={() => setIsSearchOpen(v => !v)} styles={{ mr: "s" }}>
        <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
      </CustomButton>
      {/* <CustomButton onPress={() => {}} styles={{ mr: "s" }}>
        <FILTER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
      </CustomButton> */}
    </Box>
  );

  const handleNavigationToReviewDetails = (id: string) => {
    if (appContext.isSplittable) {
      setSelectedReviewId(id);
    } else {
      navigation.navigate("REVIEWS_LIST_DETAILS", { id });
    }
  };

  const displayReviewList = () => {
    return (
      <Box flex={1} backgroundColor="white" paddingHorizontal="s" pt="m">
        <Box>
          <Box mb="s">
            <ScreenHeader title="Avis" rightButtons={RIGHT_BUTTONS} />
          </Box>

          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="disabled"
            borderRadius="button"
            p="s"
          >
            <Box justifyContent="center" alignItems="center">
              <Box flexDirection="row" alignItems="center">
                <Box p="s" backgroundColor="yellow" borderRadius="button">
                  <STAR height={ICON_SIZE} width={ICON_SIZE} fill="white" />
                </Box>
                <CustomText variant="label" ml="s">
                  {compactNumber(reviewSummary?.averageRating, "fr")}
                </CustomText>
              </Box>
              <CustomText mt="s" variant="content">
                Note Moyenne
              </CustomText>
            </Box>
            <Box justifyContent="center" alignItems="center">
              <Box flexDirection="row" alignItems="center">
                <Box p="s" backgroundColor="success" borderRadius="button">
                  <COMMENT height={ICON_SIZE} width={ICON_SIZE} fill="white" />
                </Box>
                <CustomText variant="label" ml="s">
                  {compactNumber(reviewSummary?.totalReviews, "fr")}
                </CustomText>
              </Box>
              <CustomText mt="s" variant="content">
                Nombre d'avis
              </CustomText>
            </Box>
            <Box justifyContent="center" alignItems="center">
              <Box flexDirection="row" alignItems="center">
                <Box p="s" backgroundColor="primaryTextColor" borderRadius="button">
                  <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill="white" />
                </Box>
                <CustomText variant="label" ml="s">
                  {compactNumber(reviewSummary?.totalVipMerchantClients, "fr")}
                </CustomText>
              </Box>
              <CustomText mt="s" variant="content">
                Clients VIP
              </CustomText>
            </Box>
          </Box>

          {isSearchOpen && (
            <Box marginVertical="s">
              <CustomTextInput
                {...{
                  placeHolder: "Rechercher",
                  onChangeText: setSearchQuery,
                }}
              />
            </Box>
          )}
        </Box>
        {/* <Box mt="s" flexDirection="row" alignItems="center">
        <MonthButton onPress={() => {}} month="Septembre" />
        <Box ml="s">
          <DayButton onPress={() => {}} day="Mer. 22" />
        </Box>
      </Box> */}
        <ScrollView showsVerticalScrollIndicator={false}>
          <Box marginVertical="s">
            <CollapsibleList
              onChange={setIsUnansweredReviewsOpen}
              isOpen={isUnansweredReviewsOpen}
              title="Avis sans réponses"
              nbItems={reviewSummary?.totalUnansweredReviews}
            >
              <>
                {unAnsweredReviews.map((item, idx) => {
                  const IS_LAST = idx === unAnsweredReviews.length - 1;
                  return (
                    <Box key={item._id} marginVertical="s">
                      <ReviewListCard
                        review={item}
                        onPress={handleNavigationToReviewDetails}
                        borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                      />
                    </Box>
                  );
                })}
              </>
            </CollapsibleList>
          </Box>
          <Box mt="s">
            <CollapsibleList
              onChange={setIsAllReviewsOpen}
              isOpen={isAllReviewsOpen}
              title="Avis répondus"
              nbItems={
                reviewSummary?.totalReviews - reviewSummary?.totalUnansweredReviews
              }
            >
              <>
                {answeredReviews.map((item, idx) => {
                  const IS_LAST = idx === unAnsweredReviews.length - 1;
                  return (
                    <Box key={item._id} marginVertical="s">
                      <ReviewListCard
                        review={item}
                        onPress={handleNavigationToReviewDetails}
                        borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                      />
                    </Box>
                  );
                })}
              </>
            </CollapsibleList>
          </Box>
        </ScrollView>
      </Box>
    );
  };

  if (loading) return <Loader />;

  const displayEmptySelectedListItem = () => {
    return (
      <Box flex={1} justifyContent="center" alignItems="center" backgroundColor="white">
        <CustomText variant="label" textAlign="center">
          Sélectionnez un avis pour le voir en détail
        </CustomText>
      </Box>
    );
  };

  const handleCloseSelectedTab = async () => {
    await handlGetReview();

    setSelectedReviewId("");
  };

  const displaySelectedReview = () => {
    if (!appContext.isSplittable) return undefined;

    if (!selectedReviewId) return displayEmptySelectedListItem();

    return (
      <ReviewsListDetails
        reviewId={selectedReviewId}
        goBack={handleCloseSelectedTab}
        onUpdateReviewComplete={handleGetReviewSummary}
      />
    );
  };

  return (
    <SplitView
      leftComponent={displayReviewList()}
      rightComponent={displaySelectedReview()}
    />
  );
};

export default ReviewsList;
