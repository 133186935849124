import { useNavigation, useRoute } from "@react-navigation/native";

import CREDIT_CARD from "../../../../../../../assets/icons/BASE/CREDIT_CARD.svg";
import HOME from "../../../../../../../assets/icons/BASE/HOME.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type {
  MerchantSettingsPaymentMethodBank,
  MerchantSettingsPaymentMethodCard,
} from "../../../../../../graphql/generated/schema";
import {
  useRemovePaymentMethodForMerchantMutation,
  useSetDefaultPaymentMethodForMerchantMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";

interface PaymentMethodDetailsProps {
  goBack?: () => void;
  pm?: MerchantSettingsPaymentMethodCard | MerchantSettingsPaymentMethodBank;
  defaultPaymentMethodId?: string;
}

const PaymentMethodDetails = ({
  goBack,
  pm,
  defaultPaymentMethodId: defaultPmId,
}: PaymentMethodDetailsProps) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { params } = route;

  const paymentMethod:
    | MerchantSettingsPaymentMethodBank
    | MerchantSettingsPaymentMethodCard = pm || params?.paymentMethod;

  const defaultPaymentMethodId: string = defaultPmId || params?.defaultPaymentMethodId;

  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodForMerchantMutation();
  const [removePaymentMethod] = useRemovePaymentMethodForMerchantMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleSetDefaultPaymentMethod = async () => {
    try {
      const { data } = await setDefaultPaymentMethod({
        variables: {
          stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
        },
      });

      if (data?.setDefaultPaymentMethodForMerchant) {
        handleGoBack();
      }
    } catch (err) {
      console.log("err set default payment method", err);
    }
  };

  const handleRemovePaymentMethod = async () => {
    try {
      const { data } = await removePaymentMethod({
        variables: {
          stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
        },
      });

      if (data?.removePaymentMethodForMerchant) {
        handleGoBack();
      }
    } catch (err) {
      console.log("err remove payment method", err);
    }
  };

  const displayBancAccount = () => {
    return (
      <Box>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <CustomText variant="label" color="primaryTextColor">
            Compte bancaire
          </CustomText>

          <Box>
            <HOME width={20} height={20} fill={PALETTE.black} />
          </Box>
        </Box>

        <Box marginVertical="s">
          <CustomText variant="content" color="lightGrey">
            **** **** **** {paymentMethod.lastFour}
          </CustomText>
        </Box>
      </Box>
    );
  };

  const displayCard = () => {
    if ("brand" in paymentMethod) {
      return (
        <Box paddingHorizontal="s" mt="m">
          <Box flexDirection="row" justifyContent="space-between" alignItems="center">
            <CustomText variant="label" color="primaryTextColor">
              {paymentMethod.brand?.toUpperCase()}
            </CustomText>

            <Box>
              <CREDIT_CARD width={20} height={20} fill={PALETTE.black} />
            </Box>
          </Box>

          <Box marginVertical="s">
            <CustomText variant="content" color="lightGrey">
              **** **** **** {paymentMethod.lastFour}
            </CustomText>
          </Box>

          <Box>
            <Box marginVertical="s">
              <CustomText variant="content" color="lightGrey">
                Date d'expiration
              </CustomText>
            </Box>
            <CustomText variant="text" color="lightGrey">
              {paymentMethod.expMonth}/{paymentMethod.expYear}
            </CustomText>
          </Box>
        </Box>
      );
    }
  };

  const displayContent = () => {
    if ("brand" in paymentMethod) return displayCard();

    return displayBancAccount();
  };

  return (
    <Box flex={1} backgroundColor="white" p="m" paddingHorizontal="s">
      <ScreenHeader title="Moyen de paiement" hasBackButton onBackPress={handleGoBack} />
      {displayContent()}

      <Box position="absolute" bottom={0} left={0} right={0} mb="m" paddingHorizontal="s">
        {paymentMethod.stripePaymentMethodId !== defaultPaymentMethodId && (
          <Box marginVertical="s">
            <CustomButton
              onPress={handleSetDefaultPaymentMethod}
              buttonVariant="outlineButton"
              borderColor="orange"
            >
              <CustomText variant="outlineButtonText" color="orange">
                Utiliser ce moyen de paiement par défault
              </CustomText>
            </CustomButton>
          </Box>
        )}

        <Box marginVertical="s">
          <CustomButton
            onPress={handleRemovePaymentMethod}
            buttonVariant="outlineButton"
            borderColor="danger"
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer ce moyen de paiement
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethodDetails;
