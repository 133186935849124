import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useMemo, useState } from "react";
import DraggableFlatList from "react-native-draggable-flatlist";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import Loader from "../../../../../components/Loader";
import ProductMenuCard from "../../../../../components/ProductMenuCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MenuFragment } from "../../../../../graphql/generated/schema";
import {
  useGetMenusLazyQuery,
  useSortMenusMutation,
  useUpdateMenuMutation,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import MenuListDetails from "../MenuListDetails";

interface MenuListProps {
  goBack?: () => void;
}

const MenuList = ({ goBack }: MenuListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState<MenuFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getMenus, { fetchMore }] = useGetMenusLazyQuery();
  const [updateMenu] = useUpdateMenuMutation();
  const [sortMenus] = useSortMenusMutation();

  const filteredMenus = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    if (formattedSearch.length === 0) {
      return menus;
    }

    return menus.filter(menu => {
      const formattedName = menu.name.toLowerCase().trim();
      const formattedSlug = menu.slug.toLowerCase().trim();

      return (
        formattedName.includes(formattedSearch) || formattedSlug.includes(formattedSearch)
      );
    });
  }, [menus, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: menus.length,
          },
        },
      });

      if (data?.getMenus) {
        const newMenus = JSON.parse(JSON.stringify(data.getMenus));
        setMenus([...menus, ...newMenus]);
      }
    } catch (err) {}
  };

  const handleGetMenus = async () => {
    try {
      const { data, error } = await getMenus({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: { limit: 20, offset: 0 },
        },
      });

      if (data?.getMenus) {
        setMenus(JSON.parse(JSON.stringify(data.getMenus)));
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_MENUS",
            path: "ERROR_GET_MENUS",
            message: "Une erreur est survenue lors de la récupération des menus",
          },
        ],
        "error",
      );
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEnabled = async (id: string, enabled: boolean) => {
    try {
      const newMenus = menus.map(menu => {
        if (menu._id === id) {
          return {
            ...menu,
            isEnabled: enabled,
          };
        }

        return menu;
      });

      setMenus(newMenus);

      await updateMenu({
        variables: {
          menuId: id,
          updates: {
            isEnabled: enabled,
          },
        },
      });
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_MENUS",
            path: "ERROR_UPDATE_MENUS",
            message: "Une erreur est survenue lors de la mise à jour du menu",
          },
        ],
        "error",
      );

      console.log("err", err);
    }
  };

  const handleSortMenus = async (sortedMenus: MenuFragment[]) => {
    setMenus(sortedMenus);

    try {
      const sortedMenuIds = sortedMenus.map(menu => menu._id);

      await sortMenus({
        variables: {
          menus: {
            menus: sortedMenuIds,
          },
        },
      });
    } catch (err) {
      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: "ERROR_SORT_MENUS",
      //       path: "ERROR_SORT_MENUS",
      //       message: "Une erreur est survenue lors du tri des menus",
      //     },
      //   ],
      //   "error",
      // );

      console.log("err sort menus", err);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMenus();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setSelectedId("");
      setShowInline(true);
    } else {
      navigation.navigate("MENUS_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedId(id);
      setShowInline(true);
    } else {
      navigation.navigate("MENUS_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetMenus();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <MenuListDetails
        newValue={!selectedId}
        selectedId={selectedId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Formules"
            hasBackButton
            rightButtons={
              <Touchable onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Touchable>
            }
            onBackPress={goBack || navigation.goBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        </Box>
        <DraggableFlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 150 }}
          data={filteredMenus}
          onEndReached={handleFetchMore}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item, drag }) => {
            const prices = item.prices.slice();
            const price =
              prices.sort((a, b) => a.basePrice.amount - b.basePrice.amount)[0].basePrice
                .amount || 0;

            return (
              <Box marginVertical="s">
                <Touchable onPress={() => handleEdit(item._id)}>
                  <ProductMenuCard
                    color={item.color}
                    name={item.name}
                    slug={item.slug}
                    image={item.picture}
                    price={price}
                    isEnabled={item.isEnabled}
                    updateEnabled={val => handleUpdateEnabled(item._id, val)}
                    isSelected={false}
                    onPressIn={drag}
                  />
                </Touchable>
              </Box>
            );
          }}
          onDragEnd={({ data }) => {
            handleSortMenus(data);
          }}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default MenuList;
