import { useNavigation, useRoute } from "@react-navigation/native";
import { format, isBefore, isSameHour, isSameMinute } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import DateVisibilitySection from "../../../../components/DateVisibilitySection";
import KeyboardAwareScrollView from "../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../components/Loader";
import ProductCategoryCard from "../../../../components/ProductCategoryCard/index";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../components/TextInput";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  CreateDigitalMenu,
  PricingRateFragment,
  ProductCategoryFragment,
} from "../../../../graphql/generated/schema";
import {
  useCreateDigitalMenuMutation,
  useDeleteDigitalMenuMutation,
  useGetAllCategoriesOrByIdsLazyQuery,
  useGetDigitalMenuLazyQuery,
  useGetPricingRatesLazyQuery,
  useUpdateDigitalMenuMutation,
} from "../../../../graphql/generated/schema";
import type { ERROR } from "../../../../utils/common";
import {
  createDateWithTime,
  formaYupErrors,
  removeTypeNames,
} from "../../../../utils/common";

interface DigitalMenuListDetailsProps {
  goBack?: () => void;
  isNew?: boolean;
  id?: string;
}

const DEFAULT_DIGITAL_MENU: CreateDigitalMenu = {
  baseAvailability: {
    isRecurring: false,
    recurringDates: {
      days: [],
      endTime: "22:00",
      startTime: "10:00",
    },
    specificDates: {
      endDate: new Date(),
      startDate: new Date(),
      endTime: "22:00",
      startTime: "10:00",
    },
  },
  description: "",
  includeAllCategories: false,
  isEnabled: true,
  isAlwaysVisible: false,
  name: "",
  position: 0,
  pricingRate: "",
  selectedCategories: [],
};

const DigitalMenuListDetails = ({
  goBack,
  isNew: isNewValue = false,
  id: idValue,
}: DigitalMenuListDetailsProps) => {
  useResizeMode();

  const { params } = useRoute();
  const isNew = params?.isNew || isNewValue;
  const id = params?.id || idValue;

  const [pricingRates, setPricingRates] = useState<PricingRateFragment[]>([]);
  const [loading, setLoading] = useState(!isNew);
  const navigation = useNavigation();
  const errorInfoSuccessAlertContext = useContext(ErrorInfoSuccessAlertModalContext);
  const [digitalMenu, setDigitalMenu] = useState(DEFAULT_DIGITAL_MENU);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    { _id: string; name: string }[]
  >([]);
  const [includeAllCategories, setIncludeAllCategories] = useState(false);
  const [categories, setCategories] = useState<ProductCategoryFragment[]>([]);

  const [getDigitalMenu] = useGetDigitalMenuLazyQuery();
  const [createDigitalMenu] = useCreateDigitalMenuMutation();
  const [updateDigitalMenu] = useUpdateDigitalMenuMutation();
  const [deleteDigitalMenu] = useDeleteDigitalMenuMutation();
  const [getCategoriesByIdsOrAll] = useGetAllCategoriesOrByIdsLazyQuery();
  const [getPricingRates] = useGetPricingRatesLazyQuery();

  const handleGoBack = () => {
    if (!goBack) {
      navigation.goBack();
    } else {
      goBack();
    }
  };

  const handleGetSelectedCategories = async (ids: string[], includeAll: boolean) => {
    try {
      const response = await getCategoriesByIdsOrAll({
        fetchPolicy: "cache-and-network",
        variables: {
          categories: ids,
          includeAllCategories: includeAll,
          pagination: {
            limit: 40,
            offset: 0,
          },
        },
      });

      if (response?.data?.getAllCategoriesOrByIds) {
        setCategories(response?.data.getAllCategoriesOrByIds);
      }
    } catch (err) {
      console.log("Error getting categories", err);
    }
  };

  useEffect(() => {
    const ids = selectedCategories.map(cat => cat._id);

    handleGetSelectedCategories(ids, includeAllCategories);
  }, [selectedCategories, includeAllCategories]);

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getPricingRates({
        variables: {
          pagination: {
            limit: 40,
            offset: 0,
          },
        },
      });
      if (data?.getPricingRates) {
        setPricingRates(data.getPricingRates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDigitalMenu = async () => {
    try {
      if (!isNew && !id) return;

      const { data } = await getDigitalMenu({
        fetchPolicy: "cache-and-network",
        variables: {
          digitalMenuId: id,
        },
      });

      if (data?.getDigitalMenu) {
        setDigitalMenu(data.getDigitalMenu);

        setSelectedCategories(data.getDigitalMenu.selectedCategories);
        setIncludeAllCategories(data.getDigitalMenu.includeAllCategories);

        const categories = data.getDigitalMenu.selectedCategories.map(cat => cat._id);

        handleGetSelectedCategories(categories, includeAllCategories);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPricingRates();
    handleGetDigitalMenu();
  }, []);

  const handleDeleteDigitalMenu = async () => {
    try {
      if (!id) return;

      const { data } = await deleteDigitalMenu({
        variables: {
          digitalMenuId: id,
        },
        update: cache => {
          const normalizedId = cache.identify({
            _id: id,
            __typename: "DigitalMenu",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      if (data?.deleteDigitalMenu) {
        handleGoBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getConsolidateDigitalMenu = () => {
    const pricingRate =
      typeof digitalMenu?.pricingRate === "string"
        ? digitalMenu?.pricingRate
        : digitalMenu?.pricingRate?._id || undefined;

    const menu = removeTypeNames({
      ...digitalMenu,
      pricingRate,
      selectedCategories: selectedCategories.map(cat => cat._id),
      includeAllCategories,
    });

    delete menu.createdAt;
    delete menu.updatedAt;
    delete menu._id;

    return menu;
  };

  const handleUpdateDigitalMenu = async () => {
    try {
      if (!id) return;

      const consolidatedDigitalMenu = getConsolidateDigitalMenu();

      const { data } = await updateDigitalMenu({
        variables: {
          digitalMenuId: id,
          digitalMenu: consolidatedDigitalMenu,
        },
      });
      if (data?.updateDigitalMenu) {
        handleGoBack();
      }
    } catch (error) {
      console.log(error);
      errorInfoSuccessAlertContext.openAlert(
        "Erreur lors de la sauvegarde de la carte digitale",
        [
          {
            code: "UPDATE_DIGITAL_MENU_ERROR",
            message: "Une erreur est survenue lors de la sauvegarde de la carte digitale",
          },
        ],
        "error",
      );
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateForm();

    if (!isValid) return;

    try {
      if (isNew) {
        await handleCreateDigitalMenu();
      } else {
        await handleUpdateDigitalMenu();
      }
    } catch (err) {
      console.log("AN_ERROR_OCCURED_SAVING_DIGITAL_MENU", err);
    }
  };

  const validateForm = async () => {
    try {
      const consolidatedDigitalMenu = getConsolidateDigitalMenu();

      const schema = yup.object().shape({
        isEnabled: yup.bool(),
        isAlwaysVisible: yup.bool(),
        name: yup.string().required("Le nom est requis"),
        description: yup.string(),
        pricingRate: yup.string(),
        includeAllCategories: yup.bool(),
        selectCategories: yup.array().of(yup.string()),
        baseAvailability: yup.object().shape({
          isRecurring: yup.boolean().required(),
          recurringDates: yup.object().when("isRecurring", {
            is: true,
            then: yup.object().shape({
              days: yup
                .array()
                .of(yup.number())
                .min(1, "Un jour doit être sélectionné")
                .required("Un jour doit être sélectionné"),
              startTime: yup.string().required(),
              endTime: yup.string().required(),
            }),
            otherwise: yup.object().shape({
              days: yup.array().of(yup.number()),
              startTime: yup.string().required(),
              endTime: yup.string().required(),
            }),
          }),
          specificDates: yup.object().shape({
            startDate: yup.string().required(),
            endDate: yup.string().required(),
            startTime: yup.string().required(),
            endTime: yup
              .string()
              .required()
              .test(
                "is-end-time-after-start",
                "L'heure de fin doit etre supérieur à l'heure de début",
                function (endTime) {
                  const { startTime } = this.parent;

                  if (!endTime) return false;

                  const endDate = createDateWithTime(new Date(), endTime);
                  const startDate = createDateWithTime(new Date(), startTime);

                  const isValid =
                    isBefore(endDate, startDate) ||
                    (isSameHour(startDate, endDate) && isSameMinute(startDate, endDate));

                  return !isValid;
                },
              ),
          }),
        }),
      });

      const { baseAvailability } = consolidatedDigitalMenu;

      const startTime = createDateWithTime(
        new Date(),
        baseAvailability.recurringDates.startTime,
      );
      const endTime = createDateWithTime(
        new Date(),
        baseAvailability.recurringDates.endTime,
      );

      if (isBefore(endTime, startTime)) {
        throw new Error("L'heure de fin  ne peut pas être inférieure à l'heure de début");
      }

      console.log(
        "before",
        !baseAvailability.isRecurring,
        isBefore(
          new Date(baseAvailability.specificDates.startDate),
          new Date(baseAvailability.specificDates.endDate),
        ),
      );
      if (
        !baseAvailability.isRecurring &&
        isBefore(
          new Date(baseAvailability.specificDates.endDate),
          new Date(baseAvailability.specificDates.startDate),
        )
      ) {
        throw new Error("La date de fin ne peut pas être inférieure à la date de début");
      }

      await schema.validate(consolidatedDigitalMenu, { abortEarly: false });

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const formattedErrors = formaYupErrors(err);
      setErrors(formattedErrors);

      errorInfoSuccessAlertContext.openAlert(
        "Erreur dans le formulaire",
        formattedErrors,
        "error",
      );

      // setIsSnackBarOpen(true);
    }
    return false;
  };

  const handleCreateDigitalMenu = async () => {
    try {
      const consolidatedDigitalMenu = getConsolidateDigitalMenu();

      const { data } = await createDigitalMenu({
        variables: {
          digitalMenu: consolidatedDigitalMenu,
        },
      });

      if (data?.createDigitalMenu) {
        handleGoBack();
      }
    } catch (error) {
      console.log("AN_ERROR_OCCURED_CREATING_DIGITAL_MENU", error);
      errorInfoSuccessAlertContext.openAlert(
        "Erreur lors de la création de la carte digitale",
        [
          {
            code: "CREATE_DIGITAL_MENU_ERROR",
            message: "Une erreur est survenue lors de la création de la carte digitale",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: keyof CreateDigitalMenu) => {
    return errors.some(err => err.path === key);
  };

  const handleUpdateDigitalMenuInfo = (
    key: keyof CreateDigitalMenu,
    value: string | boolean,
  ) => {
    setDigitalMenu({
      ...digitalMenu,
      [key]: value,
    });
  };

  const handleSelectCategories = () => {
    navigation.navigate("COMBINED_CATEGORIES_LIST_DETAILS_ADD_CATEGORY", {
      selectedCategories: selectedCategories,
      onSubmit: (p: { _id: string; name: string }[]) => {
        setIncludeAllCategories(false);
        setSelectedCategories(p);
      },
    });
  };

  const handleSelectAllCategories = () => {
    setIncludeAllCategories(true);
  };

  const handleUpdatePricingRate = (rateId: string) => {
    setDigitalMenu(prev => {
      return {
        ...prev,
        pricingRate: prev.pricingRate === rateId ? "" : rateId,
      };
    });
  };

  const handleDisplayPricingRates = () => {
    return pricingRates.map(pricingRate => ({
      label: pricingRate.name,
      key: pricingRate._id,
    }));
  };

  const getSelectedPricingRate = () => {
    const { pricingRate } = digitalMenu;

    const pricingRateId =
      typeof pricingRate === "string" ? pricingRate : pricingRate?._id;

    return pricingRate ? [pricingRateId] : [];
  };

  const getStartTime = () => {
    const { baseAvailability } = digitalMenu;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.startTime
      : baseAvailability?.specificDates?.startTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };
  const getEndTime = () => {
    const { baseAvailability } = digitalMenu;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.endTime
      : baseAvailability?.specificDates?.endTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };

  const updateDays = (days: number[]) => {
    setDigitalMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          recurringDates: {
            ...baseAvailability?.recurringDates,
            days,
          },
        },
      };
    });
  };

  const updateSpecificDates = (type: "startDate" | "endDate", date: Date | undefined) => {
    if (date) {
      setDigitalMenu(prev => {
        const { baseAvailability } = prev;
        return {
          ...prev,
          baseAvailability: {
            ...baseAvailability,
            specificDates: {
              ...baseAvailability?.specificDates,
              [type]: date?.toISOString(),
            },
          },
        };
      });
    }
  };

  const updateTimes = (type: "startTime" | "endTime", time: string) => {
    setDigitalMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          specificDates: {
            ...baseAvailability?.specificDates,
            [type]: time,
          },
          recurringDates: {
            ...baseAvailability?.recurringDates,
            [type]: time,
          },
        },
      };
    });
  };

  const updateIsRecurring = (isRecurring: boolean) => {
    setDigitalMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          isRecurring,
        },
      };
    });
  };

  const displayTitle = () => {
    if (isNew) return "Nouvelle carte digitale";

    return "Édition de la carte digitale";
  };

  if (loading) return <Loader />;

  return (
    <Box flex={1} p="m" backgroundColor="white">
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 150,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box marginVertical="m">
            <ScreenHeader
              title={displayTitle()}
              hasBackButton
              onBackPress={handleGoBack}
            />
          </Box>

          <Box mt="s">
            <ToggleInputLine
              text="Publier"
              value={digitalMenu.isEnabled}
              onValueChange={v => handleUpdateDigitalMenuInfo("isEnabled", v)}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Nom"
              isRequired
              hasErrors={doesInputHaveError("name")}
              initialValue={digitalMenu.name}
              onChangeText={v => handleUpdateDigitalMenuInfo("name", v)}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Description"
              multiLine
              hasErrors={doesInputHaveError("description")}
              initialValue={digitalMenu.description}
              onChangeText={v => handleUpdateDigitalMenuInfo("description", v)}
            />
          </Box>
          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Tarification
            </CustomText>
            <CustomText mt="s" variant="content" color="lightGrey">
              Choisissez le tarif que vous souhaitez afficher à vos clients. Par défaut,
              le prix standard des produits sera affiché.
            </CustomText>

            <Box marginVertical="s">
              <SelectButton
                isMultiSelect={false}
                options={handleDisplayPricingRates()}
                selectedOptions={getSelectedPricingRate()}
                onPress={([pricingRateId]) => handleUpdatePricingRate(pricingRateId)}
                placeHolder="Standard"
              />
            </Box>
          </Box>
          <Box mt="s">
            <ToggleInputLine
              text="Limiter la publication"
              value={digitalMenu.isAlwaysVisible === false}
              onValueChange={v => handleUpdateDigitalMenuInfo("isAlwaysVisible", !v)}
            />
            <CustomText mt="s" variant="content" color="lightGrey">
              Sélectionnez les jours de la semaine ou directement des dates bien précises
              pour que cette carte soit publiée.
            </CustomText>

            {digitalMenu.isAlwaysVisible === false && (
              <Box marginVertical="s">
                <DateVisibilitySection
                  days={digitalMenu.baseAvailability.recurringDates.days}
                  endDate={new Date(digitalMenu.baseAvailability.specificDates.endDate)}
                  startDate={
                    new Date(digitalMenu.baseAvailability.specificDates.startDate)
                  }
                  isRecurring={digitalMenu.baseAvailability.isRecurring}
                  startTime={getStartTime()}
                  endTime={getEndTime()}
                  updateDays={val => updateDays(val)}
                  updateStartDate={date => updateSpecificDates("startDate", date)}
                  updateEndDate={date => updateSpecificDates("endDate", date)}
                  updateStartTime={t => updateTimes("startTime", t)}
                  updateEndTime={t => updateTimes("endTime", t)}
                  updateReccuring={updateIsRecurring}
                />
              </Box>
            )}
          </Box>
          <Box mt="s">
            <Box
              paddingVertical="xs"
              style={{
                backgroundColor: "#EEEEEE",
              }}
            >
              <CustomText
                textAlign="center"
                variant="tabTitle"
                fontSize={18}
                color="black"
              >
                CONTENU
              </CustomText>
            </Box>
            <CustomText mt="s" variant="content" color="lightGrey">
              Vous pouvez sélectionner les catégories que vous souhaitez voir afficher sur
              votre carte digitale. Si vous souhaitez que vos clients voient toutes les
              catégories présentes dans DONNÉES, choisissez Toutes les catégories.
            </CustomText>
          </Box>

          <Box mt="m">
            <FlatList
              data={categories}
              scrollEnabled={false}
              renderItem={({ item }) => {
                return (
                  <Box mb="s">
                    <ProductCategoryCard
                      name={item.name}
                      isEnabled={item.isEnabled}
                      color={item.color}
                      isSelected={false}
                      nbProducts={item.products.length}
                      updateEnabled={() => {}}
                      isDraggable={false}
                      showEnabledToggle={false}
                    />
                  </Box>
                );
              }}
              keyExtractor={item => item._id}
              ListFooterComponent={
                <Box mt="xl">
                  <Box mt="s">
                    <CustomButton
                      buttonVariant="outlineButton"
                      borderColor="success"
                      onPress={handleSelectCategories}
                    >
                      <CustomText variant="primaryButtonText" color="success">
                        Sélectionner les catégories
                      </CustomText>
                    </CustomButton>
                  </Box>

                  {!includeAllCategories && (
                    <Box mt="s">
                      <CustomButton
                        buttonVariant="primaryButton"
                        buttonColor="success"
                        onPress={handleSelectAllCategories}
                      >
                        <CustomText variant="primaryButtonText" color="white">
                          Toutes les catégories
                        </CustomText>
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              }
            />
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>
      <Box
        paddingVertical="s"
        backgroundColor="white"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomButton
          containerStyles={{ width: !isNew ? "48%" : "100%" }}
          onPress={handleSubmit}
          buttonVariant="primaryButton"
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Sauvegarder
          </CustomText>
        </CustomButton>
        {!isNew && (
          <CustomButton
            containerStyles={{ width: "48%" }}
            onPress={handleDeleteDigitalMenu}
            buttonVariant="primaryButton"
            buttonColor="danger"
          >
            <CustomText variant="primaryButtonText" color="white">
              Supprimer
            </CustomText>
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default DigitalMenuListDetails;
