import type { DateObject } from "react-native-calendars";

import Box from "../../Base/Box";
import CustomCalendar, { CALENDAR_VIEW } from "../../CustomCalendar";

interface NewBookingDateSelectionProps {
  onDateSelected: (date: Date) => void;
  selectedDate: Date;
  markedDates?: { [date: string]: DateObject };
}

const NewBookingDateSelection = ({
  onDateSelected,
  selectedDate,
  markedDates,
}: NewBookingDateSelectionProps) => {
  return (
    <Box>
      <CustomCalendar
        markingType="simple"
        calendarView={CALENDAR_VIEW.MONTH}
        onDateChange={onDateSelected}
        selectedDate={selectedDate}
        markedDates={markedDates}
      />
    </Box>
  );
};

export default NewBookingDateSelection;
