import { useEffect, useState } from "react";

import Box from "../../../Base/Box";
import { CustomTextInput } from "../../../TextInput";

interface NewCommentItemLineProps {
  comment: string;
  updateComment: (text: string) => void;
}

const NewCommentItemLine = ({ comment, updateComment }: NewCommentItemLineProps) => {
  const [commentLine, setCommentLine] = useState(comment);

  useEffect(() => {
    updateComment(commentLine);
  }, [commentLine]);

  return (
    <Box flex={1} mr="s">
      <CustomTextInput
        placeHolder="Commentaire"
        onChangeText={setCommentLine}
        initialValue={commentLine}
      />
    </Box>
  );
};

export default NewCommentItemLine;
