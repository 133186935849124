import type { ViewStyle } from "react-native";
import { Dimensions, Platform, TouchableOpacity } from "react-native";

import CANCEL from "../../../assets/icons/BASE/CANCEL.svg";
import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Modal, { MODAL_POSITION } from "../Modal";

const IS_WEB = Platform.OS === "web";

interface ContentModalProps {
  isVisible: boolean;
  title: string;
  children: JSX.Element;
  onClose: () => void;
  styles?: ViewStyle;
  headerStyles?: ViewStyle;
  hasBackButton?: boolean;
  onGoBack?: () => void;
}

const { height, width } = Dimensions.get("window");

const ContentModal = ({
  isVisible,
  title,
  children,
  onClose,
  styles,
  headerStyles,
  onGoBack,
  hasBackButton = false,
}: ContentModalProps) => {
  const MAX_HEIGHT = height * 0.9;
  const MAX_WIDTH = width * 0.5;

  const POSITION = width > 600 ? MODAL_POSITION.CENTER : MODAL_POSITION.BOTTOM;

  const WIDTH_STYLES = {
    // width: POSITION === MODAL_POSITION.CENTER ? "100%" : "100%",
    maxWidth: POSITION === MODAL_POSITION.CENTER ? MAX_WIDTH : undefined,
    minWidth: POSITION === MODAL_POSITION.CENTER ? 400 : undefined,
  };

  return (
    <Modal onBackdropPress={onClose} isVisible={isVisible} position={POSITION}>
      <Box
        flex={POSITION === MODAL_POSITION.BOTTOM ? 1 : undefined}
        pb="xl"
        borderRadius="button"
        height="100%"
        p="s"
        paddingHorizontal="m"
        backgroundColor="white"
        maxHeight={MAX_HEIGHT}
        width="100%"
        // minWidth={400}
        // maxWidth={MAX_WIDTH}
        {...WIDTH_STYLES}
        style={{
          height: "auto",
          // maxWidth: IS_WEB ? 450 : undefined,
          maxWidth: 450,
          ...styles,
        }}
      >
        <Box
          marginVertical="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={headerStyles}
        >
          <Box flexDirection="row" alignItems="center">
            {onGoBack && hasBackButton && (
              <TouchableOpacity onPress={onGoBack}>
                <Box mr="s">
                  <LEFT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                </Box>
              </TouchableOpacity>
            )}
            <CustomText variant="label" color="primaryTextColor">
              {title}
            </CustomText>
          </Box>
          <TouchableOpacity onPress={onClose}>
            <Box height={40} width={40} alignItems="center" justifyContent="center">
              <CANCEL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
            </Box>
          </TouchableOpacity>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default ContentModal;
