import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";

import COMMENT from "../../../../../../assets/icons/BASE/COMMENT.svg";
import EMAIL from "../../../../../../assets/icons/BASE/EMAIL.svg";
import HOME from "../../../../../../assets/icons/BASE/HOME.svg";
import INFO from "../../../../../../assets/icons/BASE/INFO.svg";
import PERCENTAGE from "../../../../../../assets/icons/BASE/PERCENTAGE.svg";
import PHONE from "../../../../../../assets/icons/BASE/PHONE.svg";
import Box from "../../../../../components/Base/Box";
import TabTextInfo from "../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import type { MODULE_TYPE } from "../../../../../components/ModuleList/common";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { PrescriberFragment } from "../../../../../graphql/generated/schema";
import { useGetPrescriberLazyQuery } from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";

interface PrescriberListDetailsViewProps {
  id: string;
  moduleType: MODULE_TYPE;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const PrescriberListDetailsView = ({
  id,
  moduleType,
  goBack,
}: PrescriberListDetailsViewProps) => {
  // const { isVIP, phone, email, comment, isInCompany, company } = data;
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [prescriber, setPrescriber] = useState<PrescriberFragment | null>(null);

  const [getPrescriber] = useGetPrescriberLazyQuery();

  const handleGetPrescriber = async () => {
    try {
      const { data, error } = await getPrescriber({
        variables: { id },
      });

      setPrescriber(data?.getMerchantClientPrescriber || null);

      console.log("error", error);
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPrescriber();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!prescriber) {
    return (
      <Box>
        <ScreenHeader
          title="Prescripteur"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations du prescripteur." />
      </Box>
    );
  }

  const displayPicture = () => {
    const { picture } = prescriber;

    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  const displayPrincipalContact = () => {
    const { principalContact } = prescriber;

    if (!principalContact) return "";

    const { firstName, lastName } = principalContact;

    return `${firstName} ${lastName}`;
  };

  return (
    <Box flex={1}>
      <ScreenHeader
        title={prescriber.name}
        rightButtons={displayPicture()}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />
      {/* <Box mt="m" flexDirection="row">
        <Box
          mr="s"
          alignItems="center"
          justifyContent="center"
          backgroundColor="greenBackground"
          padding="s"
          borderRadius="button"
        >
          <CustomText variant="label" color="success">
            258, 800 €
          </CustomText>
          <CustomText variant="content" color="success">
            C.A Généré
          </CustomText>
        </Box>
        <Box
          mr="s"
          alignItems="center"
          justifyContent="center"
          backgroundColor="orangeBackground"
          padding="s"
          borderRadius="button"
        >
          <CustomText variant="label" color="orange">
            100 €
          </CustomText>
          <CustomText variant="content" color="orange">
            Retribution
          </CustomText>
        </Box>
      </Box> */}
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
        style={{ marginTop: 20 }}
      >
        <Box>
          {/* <Box mb="s" flexDirection="row" alignItems="center">
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />

            <CustomText ml="s" variant="label" color="primaryTextColor">
              Synthese
            </CustomText>
          </Box> */}

          {/* {(moduleType === MODULE_TYPE.ACCOUNTING ||
            moduleType === MODULE_TYPE.CLIENTS) && (
            <Box>
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Facturation
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Devis
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Factures
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0,00€
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Ticket
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )} */}

          {/* {(moduleType === MODULE_TYPE.ONLINE_SALES ||
            moduleType === MODULE_TYPE.CLIENTS) && (
            <Box mt="s">
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Commandes
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Commandes
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    No-Show
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0,00€
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Ticket
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )} */}

          {/* {(moduleType === MODULE_TYPE.BOOKING || moduleType === MODULE_TYPE.CLIENTS) && (
            <Box mt="s">
              <Box mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Réservations
                </CustomText>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="button"
                backgroundColor="disabled"
                p="s"
              >
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Résa
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    Annul
                  </CustomText>
                </Box>
                <Box alignItems="center" paddingHorizontal="s">
                  <CustomText variant="label" color="labelColor">
                    0
                  </CustomText>
                  <CustomText variant="description" color="labelColor">
                    No-Show
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )} */}

          <TabTextInfo
            {...{
              title: "Pourcentage de retritbution",
              content: `${prescriber.commissionPercentage}%`,
              icon: (
                <PERCENTAGE
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.darkGrey}
                />
              ),
            }}
          />
          <TabTextInfo
            {...{
              title: "Secteur d'activité",
              content: prescriber.sector,
              icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />,
            }}
          />
          <TabTextInfo
            {...{
              title: "Siret",
              content: prescriber.siret,
              icon: <HOME height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />,
            }}
          />
          {/* <TabTextInfo
            {...{
              title: "Numéro de TVA",
              content: prescriber.vatNumber,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.darkGrey}
                />
              ),
            }}
          /> */}
          {/* <TabTextInfo
            {...{
              title: "Client VIP",
              content: isVIP ? "Oui" : "Non",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.darkGrey}
                />
              ),
            }}
          /> */}
          <TabTextInfo
            {...{
              title: "Téléphone",
              content: prescriber.phone,
              icon: (
                <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
              ),
            }}
          />

          <TabTextInfo
            {...{
              title: "Mail",
              content: prescriber.email,
              icon: (
                <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
              ),
            }}
          />

          {/* <TabTextInfo
            {...{
              title: "Adresse",
              content: "email",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.darkGrey}
                />
              ),
            }}
          /> */}
          {/* <TabTextInfo
            {...{
              title: "Langue",
              content: "Francais",
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.darkGrey}
                />
              ),
            }}
          /> */}

          <TabTextInfo
            {...{
              title: "Note sur le prescripteur",
              content: prescriber.comment,
              icon: (
                <COMMENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
              ),
            }}
          />
          {displayPrincipalContact().length > 0 && (
            <TabTextInfo
              {...{
                title: "Contact principal",
                content: displayPrincipalContact(),
                icon: (
                  <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
                ),
              }}
            />
          )}
        </Box>
      </ScrollView>
    </Box>
  );
};

export default PrescriberListDetailsView;
