import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import type {
  BookingSettingsFragment,
  MerchantClientFragment,
} from "../../../../../../graphql/generated/schema";
import {
  Booking_Form_Input,
  Sex,
  useGetCompaniesLazyQuery,
} from "../../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../../theme";
import type { ERROR } from "../../../../../../utils/common";
import Box from "../../../../../Base/Box";
import { CustomText } from "../../../../../Base/Text";
import DateInput from "../../../../../DateInput";
import PhoneInput from "../../../../../PhoneInput";
import RadioSelect from "../../../../../RadioSelect";
import SelectButton from "../../../../../Select/SelectButton/index";
import SingleSelectButton from "../../../../../SingleSelectButton";
import { CustomTextInput } from "../../../../../TextInput";

interface ClientEditTabProps {
  data: MerchantClientFragment;
  updateData: (data: MerchantClientFragment) => void;
  errors: ERROR[];
  bookingSettings?: BookingSettingsFragment;
}

const ClientEditTab = ({
  data: client,
  updateData: setClient,
  errors,
  bookingSettings,
}: ClientEditTabProps) => {
  const navigation = useNavigation();
  const {
    firstName,
    lastName,
    sex,
    email,
    phone,
    secondaryEmail,
    secondaryPhone,
    comment,
    acceptedReceiveNewsLetters,
    acceptedReceiveMarketingSms,
    isBlacklisted,
    isVIP,
  } = client;

  const [merchantClientCompanies, setMerchantClientCompanies] = useState([]);

  const [getClientCompanies] = useGetCompaniesLazyQuery();

  const handleGetCompanies = async () => {
    try {
      const { data } = await getClientCompanies({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });
      setMerchantClientCompanies(data?.getMerchantClientCompanies || []);
    } catch (err) {}
  };

  useEffect(() => {
    handleGetCompanies();
  }, []);

  const isInputEnabled = (type: Booking_Form_Input) => {
    if (!bookingSettings) return true;

    const { bookingForm } = bookingSettings;

    return bookingForm.inputs.some(
      input => input.inputName === type && (input.isEnabled || input.isRequired),
    );
  };

  const isInputRequired = (type: Booking_Form_Input) => {
    if (!bookingSettings) return false;

    const { bookingForm } = bookingSettings;

    return bookingForm.inputs.some(input => input.inputName === type && input.isRequired);
  };

  const displayCompanies = () => {
    return merchantClientCompanies.map(company => {
      return {
        label: company.companyName,
        key: company._id,
      };
    });
  };

  const handleUpdateClient = (
    key: keyof MerchantClientFragment,
    value: MerchantClientFragment[keyof MerchantClientFragment],
  ) => {
    setClient({ ...client, [key]: value });
  };

  const handleUpdateCivility = (s: Sex) => {
    const newSex = client.sex === s ? Sex.NotSpecified : s;
    setClient({
      ...client,
      sex: newSex,
    });
  };

  const handleUpdateClientCompany = (companies: string[]) => {
    const [companyId] = companies;

    const selectedCompany = merchantClientCompanies.find(
      company => company._id === companyId,
    );

    if (!selectedCompany) return;

    const isExistingCompany = client.merchantCompanyId?._id === selectedCompany._id;

    setClient({
      ...client,
      merchantCompanyId: !isExistingCompany
        ? {
            _id: selectedCompany._id,
            companyName: selectedCompany.companyName,
          }
        : null,
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors?.some(err => err.path === key);
  };

  const doesAddressHaveErrors = () => {
    return (
      doesInputHaveError("client.address.formattedAddress") ||
      doesInputHaveError("client.address.number") ||
      doesInputHaveError("client.address.street") ||
      doesInputHaveError("client.address.zipCode") ||
      doesInputHaveError("client.address.city") ||
      doesInputHaveError("client.address.country")
    );
  };

  const handleOpenAddressEdit = () => {
    navigation.navigate("ADDRESS_DETAILS", {
      address: client?.address,
      onSubmit: (address: MerchantClientFragment["address"]) => {
        handleUpdateClient("address", address);
        navigation.goBack();
      },
    });
  };

  return (
    <Box flex={1}>
      <ScrollView>
        <Box paddingHorizontal="s">
          {isInputEnabled(Booking_Form_Input.Civility) && (
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex={0.4}>
                <Box flexDirection="row" alignItems="center">
                  <CustomText
                    variant="label"
                    color={
                      doesInputHaveError("client.sex") ? "danger" : "primaryTextColor"
                    }
                  >
                    Civilité
                  </CustomText>
                  {isInputRequired(Booking_Form_Input.Civility) && (
                    <CustomText
                      pl="s"
                      variant="defaultTextInput"
                      color={doesInputHaveError("client.sex") ? "danger" : "success"}
                    >
                      *
                    </CustomText>
                  )}
                </Box>
              </Box>
              <Box flex={1} flexDirection="row" alignItems="center">
                <Box flex={0.5} marginHorizontal="s">
                  <SingleSelectButton
                    onPress={() => handleUpdateCivility(Sex.Female)}
                    isSelected={sex === Sex.Female}
                    title="Madame"
                  />
                </Box>
                <Box flex={0.5}>
                  <SingleSelectButton
                    onPress={() => handleUpdateCivility(Sex.Male)}
                    isSelected={sex === Sex.Male}
                    title="Monsieur"
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              flex={!isInputEnabled(Booking_Form_Input.FirstName) ? 1 : 0.5}
              height={45}
            >
              <CustomTextInput
                placeHolder="Nom"
                hasErrors={doesInputHaveError("client.lastName")}
                isRequired
                initialValue={lastName}
                clearButtonMode="while-editing"
                onChangeText={value => handleUpdateClient("lastName", value)}
              />
            </Box>
            {isInputEnabled(Booking_Form_Input.FirstName) && (
              <Box flex={0.5} height={45} ml="s">
                <CustomTextInput
                  placeHolder="Prénom"
                  isRequired={isInputRequired(Booking_Form_Input.FirstName)}
                  initialValue={firstName}
                  clearButtonMode="while-editing"
                  hasErrors={doesInputHaveError("client.firstName")}
                  onChangeText={value => handleUpdateClient("firstName", value)}
                />
              </Box>
            )}
          </Box>
          <Box mt="s">
            <PhoneInput
              hasErrors={doesInputHaveError("client.phone")}
              onChangeFormattedText={value => {
                handleUpdateClient("phone", value);
              }}
              value={phone}
              isRequired={isInputRequired(Booking_Form_Input.Phone)}
              placeHolder="Téléphone"
            />
          </Box>
          {isInputEnabled(Booking_Form_Input.Email) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                initialValue={email}
                isRequired={isInputRequired(Booking_Form_Input.Email)}
                keyboardType="email-address"
                clearButtonMode="while-editing"
                hasErrors={doesInputHaveError("client.email")}
                onChangeText={value => handleUpdateClient("email", value)}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.MerchantClientNote) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Note permanente sur le client"
                initialValue={comment}
                clearButtonMode="while-editing"
                multiLine
                hasErrors={doesInputHaveError("client.comment")}
                isRequired={isInputRequired(Booking_Form_Input.MerchantClientNote)}
                onChangeText={value => handleUpdateClient("comment", value)}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.Company) &&
            merchantClientCompanies.length > 0 && (
              <Box mt="s">
                <SelectButton
                  isMultiSelect={false}
                  options={displayCompanies()}
                  selectedOptions={
                    client.merchantCompanyId ? [client.merchantCompanyId?._id] : []
                  }
                  onPress={handleUpdateClientCompany}
                  placeHolder="Entreprise"
                />
              </Box>
            )}

          {isInputEnabled(Booking_Form_Input.Address) && (
            <Box mt="s">
              <TouchableOpacity onPress={handleOpenAddressEdit}>
                <CustomTextInput
                  disabled
                  boxProps={{
                    backgroundColor: "white",
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                  placeHolder="Adresse"
                  clearButtonMode="while-editing"
                  hasErrors={doesAddressHaveErrors()}
                  isRequired={isInputRequired(Booking_Form_Input.Address)}
                  initialValue={client?.address?.formattedAddress || ""}
                  value={client?.address?.formattedAddress || ""}
                />
              </TouchableOpacity>
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.Birthday) && (
            <Box mt="s">
              <DateInput
                placeHolder="Date d'anniversaire"
                date={client.dateOfBirth}
                onChange={date => handleUpdateClient("dateOfBirth", date)}
                isRequired={isInputRequired(Booking_Form_Input.Birthday)}
                hasErrors={doesInputHaveError("client.dateOfBirth")}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.SecondaryPhone) && (
            <Box mt="s">
              <PhoneInput
                hasErrors={doesInputHaveError("client.secondaryPhone")}
                onChangeFormattedText={value =>
                  handleUpdateClient("secondaryPhone", value)
                }
                value={secondaryPhone}
                isRequired={isInputRequired(Booking_Form_Input.SecondaryPhone)}
                placeHolder="Téléphone secondaire"
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.SecondaryEmail) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email secondaire"
                keyboardType="email-address"
                initialValue={secondaryEmail}
                clearButtonMode="while-editing"
                isRequired={isInputRequired(Booking_Form_Input.SecondaryEmail)}
                hasErrors={doesInputHaveError("client.secondaryEmail")}
                onChangeText={value => handleUpdateClient("secondaryEmail", value)}
              />
            </Box>
          )}

          <Box mt="s" borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
            <RadioSelect
              iconOnRight
              isSelected={acceptedReceiveMarketingSms}
              label="Accepte de recevoir des SMS marketing"
              onPress={() =>
                handleUpdateClient(
                  "acceptedReceiveMarketingSms",
                  !acceptedReceiveMarketingSms,
                )
              }
            />
          </Box>
          <Box mt="s" borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
            <RadioSelect
              iconOnRight
              isSelected={acceptedReceiveNewsLetters}
              label="Accepte de recevoir des newsletters"
              onPress={() =>
                handleUpdateClient(
                  "acceptedReceiveNewsLetters",
                  !acceptedReceiveNewsLetters,
                )
              }
            />
          </Box>
          <Box mt="s" borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
            <RadioSelect
              iconOnRight
              isSelected={isBlacklisted}
              label="Client blacklisté"
              onPress={() => handleUpdateClient("isBlacklisted", !isBlacklisted)}
            />
          </Box>
          <Box mt="s" borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
            <RadioSelect
              iconOnRight
              isSelected={isVIP}
              label="Client VIP"
              isRequired={isInputRequired(Booking_Form_Input.Vip)}
              hasErrors={doesInputHaveError("client.isVIP")}
              onPress={() => handleUpdateClient("isVIP", !isVIP)}
            />
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default ClientEditTab;
