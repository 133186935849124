import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Dimensions, Image } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import STAR from "../../../../../../assets/icons/BASE/STAR.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import ClientViewTab from "../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/Client/View";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import type { Tab } from "../../../../../components/OutlineTabs";
import OutlineTabs from "../../../../../components/OutlineTabs";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { GetDetailedMerchantClientQuery } from "../../../../../graphql/generated/schema";
import {
  Modules,
  useGetDetailedMerchantClientLazyQuery,
} from "../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../theme/Palette";
import { compactNumber, formatCurrencyPrice } from "../../../../../utils/common";

import ClientHistoryTab from "./Tabs/ClientHistoryTab";

interface ClientListDetailsViewProps {
  id: string;
  showBackButton?: boolean;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const TABS: Tab[] = [
  {
    title: "Client",
    key: "CLIENT",
  },
  {
    title: "Historique",
    key: "HISTORY",
  },
];

const ClientListDetailsView = ({
  id,
  showBackButton = true,
  goBack,
}: ClientListDetailsViewProps) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const navigation = useNavigation();
  const [client, setClient] = useState<
    GetDetailedMerchantClientQuery["getDetailedMerchantClient"] | null
  >(null);
  const [container, setContainer] = useState({
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    x: 0,
    y: 0,
  });

  const [getMerchantClient] = useGetDetailedMerchantClientLazyQuery();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetMerchantClient = async () => {
    try {
      const { data } = await getMerchantClient({
        variables: { merchantClientId: id },
      });

      if (data?.getDetailedMerchantClient) {
        setClient(data?.getDetailedMerchantClient);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchantClient();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!client) {
    return (
      <Box>
        <ScreenHeader
          title="Clients"
          hasBackButton={showBackButton}
          onBackPress={handleGoBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données." />
      </Box>
    );
  }

  const displayTab = () => {
    if (!client || !client?.client) return <></>;

    switch (selectedTab) {
      case "CLIENT":
        const { bookingSummary, orderSummary, quoteSummary } = client;
        return (
          <ClientViewTab
            data={client.client}
            bookingSummary={bookingSummary}
            orderSummary={orderSummary}
            quoteSummary={quoteSummary}
            moduleType={Modules.MerchantClients}
          />
        );
      case "HISTORY":
        return <ClientHistoryTab merchantClientId={client.client._id} />;

      default:
        break;
    }
  };

  const displayName = () => {
    const { firstName, lastName } = client.client;

    return `${firstName} ${lastName}`;
  };

  const displayPicture = () => {
    const { picture } = client.client;

    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ paddingBottom: 100 }}
      style={{ marginTop: 20 }}
    >
      <Box onLayout={e => setContainer(e.nativeEvent.layout)}>
        <ScreenHeader
          title={displayName()}
          rightButtons={displayPicture()}
          hasBackButton={showBackButton}
          onBackPress={handleGoBack}
        />
        <Box mt="m" flexDirection="row">
          <Box
            mr="s"
            alignItems="center"
            justifyContent="center"
            backgroundColor="greenBackground"
            padding="s"
            borderRadius="button"
          >
            <CustomText variant="label" color="success">
              {formatCurrencyPrice(client?.client?.generatedProfit || 0, "fr", "eur")}
            </CustomText>
            <CustomText variant="content" color="success">
              C.A Généré
            </CustomText>
          </Box>
          <Box
            mr="s"
            alignItems="center"
            justifyContent="center"
            backgroundColor="yellowBackground"
            padding="s"
            borderRadius="button"
            minWidth={60}
          >
            <STAR height={15} width={15} fill={PALETTE.yellow} />

            <CustomText variant="content" color="yellow">
              {compactNumber(client?.client?.averageRating, "fr")}/5
            </CustomText>
          </Box>
          {client.client?.isVIP && (
            <Box
              mr="s"
              alignItems="center"
              justifyContent="center"
              backgroundColor="blueBackground"
              padding="s"
              borderRadius="button"
              minWidth={60}
            >
              <STAR height={15} width={15} fill={PALETTE.blue} />
              <CustomText variant="content" color="blue">
                VIP
              </CustomText>
            </Box>
          )}
        </Box>

        <Box marginVertical="m">
          <OutlineTabs
            tabs={TABS}
            onTabPress={setSelectedTab}
            selectedTab={selectedTab}
            boxStyle={{ width: container.width / 2 }}
          />
        </Box>

        <Box>{displayTab()}</Box>
      </Box>
    </ScrollView>
  );
};

export default ClientListDetailsView;
