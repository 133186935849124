/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";

import DOWN_ARROW from "../../../../../assets/icons/BASE/DOWN_ARROW.svg";
import LEFT_ARROW from "../../../../../assets/icons/BASE/LEFT_ARROW.svg";
import RECEIPT from "../../../../../assets/icons/BASE/RECEIPT.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../../../components/BookingList/BookingListCardStatusButtonItem";
import BookingListDetailsViewClientTab from "../../../../components/BookingListDetails/BookingListDetailsView/Tabs/Client";
import BookingListDetailsViewTimelineTab from "../../../../components/BookingListDetails/BookingListDetailsView/Tabs/Timeline";
import { CustomButton } from "../../../../components/Button";
import ContentModal from "../../../../components/ContentModal";
import Loader from "../../../../components/Loader";
import { MODULE_TYPE } from "../../../../components/ModuleList/common";
import type { Tab } from "../../../../components/OutlineTabs";
import OutlineTabs from "../../../../components/OutlineTabs";
import QuoteDetailsTab from "../../../../components/Quotes/QuoteDetails/Tabs/Quote";
import QuoteStatusButton from "../../../../components/Quotes/QuoteStatusButton";
import {
  useGetInvoiceLazyQuery,
  useGetQuoteLazyQuery,
  useUpdateInvoiceMutation,
  useUpdateQuoteMutation,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import type { Invoice, Quote } from "../../../../types/types";
import { displayModuleId } from "../../../../utils/common";

const IMAGE_HEIGHT = 29;

const TABS: Tab[] = [
  {
    title: "Devis",
    key: "QUOTE",
  },
  {
    title: "Client",
    key: "CLIENT",
  },
  {
    title: "Timeline",
    key: "TIMELINE",
  },
];

// !TODO SET PARAMS IN PARAM LIST
const QuotesListDetails = () => {
  const route = useRoute();
  const { itemId, isForQuote } = route.params;
  const [item, setItem] = useState<Quote | Invoice | null>(null);
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const navigation = useNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [getQuote] = useGetQuoteLazyQuery();
  const [getInvoice] = useGetInvoiceLazyQuery();
  const [updateQuote] = useUpdateQuoteMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();

  const handleNavigateToQuoteModifyDetails = () => {
    setIsModalOpen(false);
    const screenName = isForQuote ? "NEW_QUOTE" : "NEW_INVOICE";
    navigation.navigate(screenName, {
      itemId: itemId,
      isForQuote,
    });
  };

  const handleGetItem = async () => {
    try {
      if (isForQuote) {
        const result = await getQuote({
          variables: {
            quoteId: itemId,
          },
        });

        if (result.data?.getQuoteForPro) {
          setItem(result.data.getQuoteForPro);
          setIsLoading(false);
        }
      } else {
        const result = await getInvoice({
          variables: {
            invoiceId: itemId,
          },
        });

        console.log("result invoice", result);

        if (result.data?.getInvoiceForPro) {
          setItem(result.data.getInvoiceForPro);
          setIsLoading(false);
        }
      }
    } catch (err) {
      console.log("err get quote,invoice", err);
    } finally {
    }
  };

  useEffect(() => {
    handleGetItem();
  }, []);

  const handleStatusChange = async (newStatus: number, refusalReason: string) => {
    try {
      if (isForQuote) {
        const result = await updateQuote({
          variables: {
            quoteId: itemId,
            quote: {
              status: newStatus,
              refusalReason,
            },
          },
        });

        if (result.data?.updateQuoteForPro) {
          setItem(prev => ({ ...prev, status: newStatus }));
        }
      } else {
        const result = await updateInvoice({
          variables: {
            invoiceId: itemId,
            invoice: {
              status: newStatus,
              refusalReason,
            },
          },
        });
        if (result.data?.updateInvoiceForPro) {
          setItem(prev => ({ ...prev, status: newStatus }));
        }
      }
    } catch (err) {
      console.log("err change status", isForQuote, err);
    }
  };

  const displayTab = () => {
    switch (selectedTab) {
      case "QUOTE":
        return item && <QuoteDetailsTab quote={item} />;
      case "CLIENT":
        return (
          item && (
            <Box mt="m">
              <BookingListDetailsViewClientTab
                moduleType={MODULE_TYPE.ACCOUNTING}
                isEditable={false}
                // updateData={updateClient}
                data={item?.merchantClient}
              />
            </Box>
          )
        );
      case "TIMELINE":
        return (
          item && (
            <BookingListDetailsViewTimelineTab data={item} timeline={item.timeline} />
          )
        );
      default:
        return null;
    }
  };

  const displayName = () => {
    const { merchantClient } = item;
    const { lastName, firstName } = merchantClient;
    return `${lastName} ${firstName}`;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!item) {
    return (
      <Box>
        <CustomText variant="content" color="danger">
          Error
        </CustomText>
      </Box>
    );
  }

  const displayModal = () => {
    return (
      <ContentModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Action à effectuer"
      >
        <Box>
          {!isForQuote && (
            <Box mt="s">
              <CustomButton
                onPress={() => {}}
                buttonVariant="outlineButton"
                borderColor="success"
              >
                <CustomText variant="outlineButtonText" color="success">
                  Créer un avoir
                </CustomText>
              </CustomButton>
            </Box>
          )}
          <Box mt="s">
            <CustomButton
              onPress={() => {}}
              buttonVariant="outlineButton"
              borderColor="success"
            >
              <CustomText variant="outlineButtonText" color="success">
                Envoyer {isForQuote ? "le devis" : "la facture"}
              </CustomText>
            </CustomButton>
          </Box>

          <Box mt="s">
            <CustomButton
              onPress={handleNavigateToQuoteModifyDetails}
              buttonVariant="outlineButton"
              borderColor="success"
            >
              <CustomText variant="outlineButtonText" color="success">
                Modifier
              </CustomText>
            </CustomButton>
          </Box>
          <Box mt="s">
            <CustomButton
              onPress={() => {}}
              buttonVariant="outlineButton"
              borderColor="success"
            >
              <CustomText variant="outlineButtonText" color="success">
                Dupliquer {isForQuote ? "le devis" : "la facture"}
              </CustomText>
            </CustomButton>
          </Box>
          {isForQuote && (
            <Box mt="s">
              <CustomButton
                onPress={() => {}}
                buttonVariant="primaryButton"
                buttonColor="primaryButton"
              >
                <CustomText variant="primaryButtonText" color="white">
                  Transformer en facture
                </CustomText>
              </CustomButton>
            </Box>
          )}

          {!isForQuote && (
            <Box mt="s">
              <CustomButton
                onPress={() => {}}
                buttonVariant="primaryButton"
                buttonColor="success"
                styles={{
                  minHeight: 45,
                }}
              >
                <CustomText variant="primaryButtonText" color="white">
                  Relancer
                </CustomText>
              </CustomButton>
            </Box>
          )}

          {/* <Box mt="s">
            <CustomButton
              onPress={() => {}}
              buttonVariant="primaryButton"
              buttonColor="danger"
              styles={{
                minHeight: 45,
              }}
            >
              <CustomText variant="primaryButtonText" color="white">
                Annuler {isForQuote ? "le devis" : "la facture"}
              </CustomText>
            </CustomButton>
          </Box> */}
        </Box>
      </ContentModal>
    );
  };

  return (
    <Box flex={1} pt="s" backgroundColor="white" paddingHorizontal="s">
      <Box mt="s" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          <TouchableOpacity onPress={navigation.goBack}>
            <LEFT_ARROW height={20} width={20} fill={PALETTE.green} />
          </TouchableOpacity>

          <Box ml="s" flexDirection="row" alignItems="center">
            {item?.merchantClient?.picture && (
              <Image
                source={{
                  uri: item.merchantClient.picture,
                }}
                style={{
                  width: IMAGE_HEIGHT,
                  height: IMAGE_HEIGHT,
                  borderRadius: IMAGE_HEIGHT / 2,
                }}
              />
            )}

            <Box ml="s">
              <CustomText variant="label" color="primaryTextColor">
                {displayName()}
              </CustomText>
              <CustomText variant="description">{displayModuleId(item._id)}</CustomText>
            </Box>
          </Box>
        </Box>

        <QuoteStatusButton
          type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
          status={item.status}
          onPress={handleStatusChange}
        />
      </Box>

      <Box
        mt="m"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box>
          <OutlineTabs
            selectedTab={selectedTab}
            tabs={TABS}
            onTabPress={setSelectedTab}
          />
        </Box>
        <CustomButton
          onPress={() => {}}
          buttonVariant="primaryButton"
          buttonColor="disabled"
          styles={{
            p: "s",
            paddingHorizontal: "m",
          }}
        >
          <RECEIPT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
          <CustomText variant="content" color="primaryTextColor">
            Aperçu
          </CustomText>
        </CustomButton>
        <Box />
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
      >
        {displayTab()}
      </ScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        p="s"
        minHeight={80}
        backgroundColor="white"
      >
        <CustomButton
          onPress={() => setIsModalOpen(true)}
          buttonVariant="primaryButton"
          buttonColor="primaryButton"
        >
          <Box flexDirection="row" alignItems="center">
            <CustomText variant="primaryButtonText" color="white">
              {isForQuote ? "Transformer en facture" : "Envoyer la facture"}
            </CustomText>
            <Box ml="s">
              <DOWN_ARROW height={15} width={15} fill={PALETTE.white} />
            </Box>
          </Box>
        </CustomButton>
      </Box>
      {displayModal()}
    </Box>
  );
};

export default QuotesListDetails;
