import { useNavigation } from "@react-navigation/native";
import type { Dispatch, SetStateAction } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import PLUS from "../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../assets/icons/BASE/TRASH.svg";
import type {
  BookingProductExperienceFragment,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import InlineSelectButton from "../../InlineSelectButton";
import RadioSelectButton from "../../RadioSelectButton";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";
import Touchable from "../../Touchable";

interface ServiceProductSelectionProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const ServiceProductSelection = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServiceProductSelectionProps) => {
  const navigation = useNavigation();

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateBookingProductExperienceSettings = (
    key: keyof BookingProductExperienceFragment,
    value: boolean | number | string[],
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      bookingProductsSettings: {
        ...prev.bookingProductsSettings,
        [key]: value,
      },
    }));
  };

  const toggleOnlinePaymentRequirement = (value: boolean) => {
    setBookingAvailability(prev => ({
      ...prev,
      prepaymentSettings: {
        ...prev.prepaymentSettings,
        isEnabled: value ? false : prev.prepaymentSettings.isEnabled,
      },

      bookingProductsSettings: {
        ...prev.bookingProductsSettings,
        isPaymentRequired: value,
      },
    }));
  };

  const handleRemoveProduct = (type: "products" | "categories" | "menus", id: string) => {
    const { [type]: selectedItems } = bookingAvailability.bookingProductsSettings;

    const updatedSelectedItems = selectedItems.filter(item => item._id !== id);

    updateBookingProductExperienceSettings(type, updatedSelectedItems);
  };

  const handleGoToProductSelection = () => {
    navigation.navigate("BOOKING_SERVICES_PRODUCT_SELECT", {
      selectedProducts: bookingAvailability.bookingProductsSettings.products,
      selectedCategories: bookingAvailability.bookingProductsSettings.categories,
      selectedMenus: bookingAvailability.bookingProductsSettings.menus,
      onSubmit: ({ products, categories, menus }) => {
        setBookingAvailability(prev => ({
          ...prev,
          bookingProductsSettings: {
            ...prev.bookingProductsSettings,
            products,
            categories,
            menus,
          },
        }));
      },
    });
  };

  const handleFormatBookingProducts = () => {
    const { products, categories, menus } = bookingAvailability.bookingProductsSettings;

    const formattedProducts = products.map(product => ({
      _id: product._id,
      name: product.name,
      type: "products",
    }));

    const formattedCategories = categories.map(category => ({
      _id: category._id,
      name: category.name,
      type: "categories",
    }));

    const formattedMenus = menus.map(menu => ({
      _id: menu._id,
      name: menu.name,
      type: "menus",
    }));

    return [...formattedMenus, ...formattedCategories, ...formattedProducts];
  };

  const displayProductTypeName = (
    type: "products" | "categories" | "menus",
    name: string,
  ) => {
    switch (type) {
      case "products":
        return `P : ${name}`;
      case "categories":
        return `C : ${name}`;
      case "menus":
        return `F : ${name}`;
    }
  };

  const handleUpdateIsRequired = (value: boolean) => {
    setBookingAvailability(prev => ({
      ...prev,
      bookingProductsSettings: {
        ...prev.bookingProductsSettings,
        isRequired:
          prev.bookingProductsSettings.shouldOrderQuantityBeLinkedToNbPersons === true
            ? true
            : value,
      },
    }));
  };

  const handleUpdateShouldOrderQuantityBeLinkedToNbPersons = (value: boolean) => {
    setBookingAvailability(prev => ({
      ...prev,
      bookingProductsSettings: {
        ...prev.bookingProductsSettings,
        shouldOrderQuantityBeLinkedToNbPersons: value,
        isRequired: value === true ? true : prev.bookingProductsSettings.isRequired,
      },
    }));
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          PRÉSELECTION DES PRODUITS
        </CustomText>
      </Box>
      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          text="Activer la pré-sélection des produits"
          value={bookingAvailability.bookingProductsSettings.isEnabled}
          onValueChange={v => updateBookingProductExperienceSettings("isEnabled", v)}
        />
        <CustomText variant="text" color="lightGrey">
          Paramétrez les formules et produits disponibles
        </CustomText>
      </Box>

      {bookingAvailability.bookingProductsSettings.isEnabled && (
        <Box>
          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="content" color="primaryTextColor">
                A partir de{"\n"}combien de couverts
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  keyboardType="number-pad"
                  hasErrors={doesInputHaveError("bookingProductsSettings.minPersons")}
                  placeHolder="0"
                  hideFloatingLabel
                  value={bookingAvailability.bookingProductsSettings.minPersons.toString()}
                  onChangeText={v =>
                    updateBookingProductExperienceSettings(
                      "minPersons",
                      getSafeNumberFromInput(v),
                    )
                  }
                />

                {bookingAvailability.minMaxBookingPersons.isEnabled &&
                  bookingAvailability.minMaxBookingPersons.maxPersons > 0 && (
                    <Box flexDirection="row" alignItems="center">
                      <CustomText
                        marginHorizontal="xs"
                        variant="text"
                        fontSize={25}
                        color="primaryTextColor"
                      >
                        /
                      </CustomText>
                      <CustomTextInput
                        placeHolder="50"
                        hideFloatingLabel
                        disabled
                        value={bookingAvailability.minMaxBookingPersons.maxPersons.toString()}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onChangeText={() => {}}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
            <CustomText variant="text" color="lightGrey">
              Nombre de couverts à partir duquel vous proposez la préselection des
              produits
            </CustomText>
          </Box>
          <Box mt="m">
            <CustomText
              variant="content"
              color={
                doesInputHaveError("bookingProductsSettings.products") ||
                doesInputHaveError("bookingProductsSettings.categories") ||
                doesInputHaveError("bookingProductsSettings.menus")
                  ? "danger"
                  : "primaryTextColor"
              }
            >
              Produit(s) disponible(s)
            </CustomText>
            <FlatList
              scrollEnabled={false}
              data={handleFormatBookingProducts()}
              style={{ width: "100%" }}
              ListHeaderComponent={
                <TouchableOpacity onPress={handleGoToProductSelection}>
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mr="s"
                    marginVertical="s"
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title="Ajouter un produit, catégorie ou formule"
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <PLUS fill={PALETTE.green} width={15} height={15} />
                  </Box>
                </TouchableOpacity>
              }
              renderItem={({ item }) => (
                <Touchable
                  onPress={() => handleRemoveProduct(item.type, item._id)}
                  style={{
                    marginVertical: 8,
                    marginRight: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box flex={0.95}>
                    <InlineSelectButton
                      title={displayProductTypeName(item.type, item.name)}
                      options={[]}
                      selectedOptionKeys={[]}
                      isMultiSelect={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onPress={() => {}}
                    />
                  </Box>

                  <Box>
                    <TRASH fill={PALETTE.red} width={15} height={15} />
                  </Box>
                </Touchable>
              )}
              keyExtractor={(item, index) => `draggable-item-${index}`}
            />
          </Box>
          <TouchableOpacity
            onPress={() =>
              handleUpdateShouldOrderQuantityBeLinkedToNbPersons(
                !bookingAvailability.bookingProductsSettings
                  .shouldOrderQuantityBeLinkedToNbPersons,
              )
            }
          >
            <Box
              mt="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Lier la pré-sélection au nombre de couverts
              </CustomText>
              <RadioSelectButton
                isSelected={
                  !!bookingAvailability.bookingProductsSettings
                    .shouldOrderQuantityBeLinkedToNbPersons
                }
              />
            </Box>
            <Box mt="s">
              <CustomText variant="text" color="lightGrey">
                Cette option oblige vos clients à choisir les produits que vous avez rendu
                disponibles et à lier la quantité de produits au nombre de personnes
              </CustomText>
            </Box>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              handleUpdateIsRequired(
                !bookingAvailability.bookingProductsSettings.isRequired,
              )
            }
          >
            <Box
              mt="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Rendre obligatoire la pré-sélection
              </CustomText>
              <RadioSelectButton
                isSelected={bookingAvailability.bookingProductsSettings.isRequired}
              />
            </Box>
            <Box mt="s">
              <CustomText variant="text" color="lightGrey">
                Cette option oblige vos clients à choisir les produits que vous avez rendu
                disponibles
              </CustomText>
            </Box>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              toggleOnlinePaymentRequirement(
                !bookingAvailability.bookingProductsSettings.isPaymentRequired,
              )
            }
          >
            <Box>
              <Box
                mt="m"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  Obliger le paiement en ligne
                </CustomText>
                <RadioSelectButton
                  isSelected={
                    bookingAvailability.bookingProductsSettings.isPaymentRequired
                  }
                />
              </Box>
              <CustomText variant="text" color="lightGrey">
                Si vous souhaitez rendre le paiement en ligne obligatoire, cochez la case.
                En cochant cette case vous ne pourrez plus activer l’empreinte bancaire.
              </CustomText>
            </Box>
          </TouchableOpacity>
        </Box>
      )}
    </Box>
  );
};

export default ServiceProductSelection;
