import { useNavigation, useRoute } from "@react-navigation/native";
import { addDays, format, isBefore, isSameDay, startOfDay } from "date-fns";
import { useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import AgendaMonthView from "../../../../../components/AgendaMonthView";
import Box from "../../../../../components/Base/Box";
import BookingOrderServiceSlotCard from "../../../../../components/BookingOrderServiceSlotCard";
import BottomButton from "../../../../../components/BottomButton";
import DaysSelector from "../../../../../components/DaysSelector";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { OnlineSalesServiceSlot } from "../../../../../graphql/generated/schema";
import { Modules } from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";

const TakeAwayServiceSlotsList = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const {
    slots,
    visibilitySettings,
    totalService,
    onUpdateSlotsSubmit,
    totalSlot,
    service,
  } = params;
  const [updatedSlots, setUpdatedSlots] = useState(slots);

  const { isRecurring, recurringDates, specificDates } = visibilitySettings;

  const getFirstRecurringDay = () => {
    const { days } = recurringDates;
    const [day] = days;
    return day;
  };

  const getFirstSpecificDate = () => {
    const { startDate } = specificDates;
    return startDate;
  };

  const getSelectableDates = () => {
    if (isRecurring) {
      return {};
    }

    const { startDate, endDate } = specificDates;
    const dates: Date[] = [];

    let start = startDate;
    const end = addDays(new Date(endDate), 1);

    while (isBefore(new Date(start), end)) {
      dates.push(start);
      start = addDays(start, 1);
    }

    const formattedDates = dates.map(date => format(date, "yyyy-MM-dd"));

    const items = {};

    formattedDates.forEach(date => {
      items[date] = {
        selected: true,
      };
    });

    return items;
  };

  const [selectedDay, setSelectedDay] = useState(getFirstRecurringDay());
  const [selectedDate, setSelectedDate] = useState(getFirstSpecificDate());
  const [selectableDates, setSelectableDates] = useState(getSelectableDates());

  const getSlotsByDayOrDate = (): OnlineSalesServiceSlot[] => {
    if (isRecurring) {
      return updatedSlots.filter(slot => {
        return slot.day === selectedDay;
      });
    }

    return updatedSlots.filter(slot => {
      const startOfSlotDate = startOfDay(new Date(slot.date));
      const startOfSelectedDate = startOfDay(selectedDate);
      const isPartOfSelectedDate = isSameDay(startOfSlotDate, startOfSelectedDate);

      return isPartOfSelectedDate;
    });
  };

  const updateSlotEnabled = (id: string, isEnabled: boolean) => {
    const newSlots = updatedSlots.map(slot => {
      if (slot._id === id) {
        return { ...slot, isEnabled };
      }
      return slot;
    });

    setUpdatedSlots(newSlots);
  };

  const onUpdateSlotSubmit = (id: string, updatedSlot: OnlineSalesServiceSlot) => {
    const newSlots = updatedSlots.map(slot => {
      if (slot._id === id) {
        return updatedSlot;
      }
      return slot;
    });

    setUpdatedSlots(newSlots);
  };

  const handleSlotEdit = (slot: OnlineSalesServiceSlot) => {
    navigation.navigate("TAKE_AWAY_SERVICE_SLOTS_LIST_DETAILS", {
      slot,
      onUpdateSlotSubmit,
      totalService,
      service,
    });
  };

  const handleSubmit = () => {
    onUpdateSlotsSubmit(updatedSlots);
    navigation.goBack();
  };

  const getDisabledDays = () => {
    const DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6];

    const { days } = recurringDates;

    const disabled = DAYS_OF_WEEK.filter(d => !days.includes(d));

    return disabled;
  };

  const getSlotMinMaxText = (item: OnlineSalesServiceSlot) => {
    if (!totalSlot.isEnabled) return "";

    return `${item.totalOrdersAllowed} commandes`;
  };

  const getSlotCapacityText = (item: OnlineSalesServiceSlot) => {
    if (!totalSlot.isEnabled && !totalService.isEnabled) return "";

    if (!totalService.isEnabled && totalSlot.isEnabled) {
      return `${item.totalOrdersAllowed}`;
    }
    if (totalService.isEnabled && totalSlot.isEnabled) {
      return `${item.totalOrdersAllowed}/${totalService.value}`;
    }

    return "";
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Créneaux Disponibles"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <Box mt="m">
        {isRecurring ? (
          <DaysSelector
            isMulti={false}
            selectedDays={[selectedDay]}
            onDayPress={day => setSelectedDay(day)}
            disabledDays={getDisabledDays()}
          />
        ) : (
          <AgendaMonthView
            items={selectableDates}
            currentDate={selectedDate}
            onDayPress={setSelectedDate}
          />
        )}
      </Box>

      <FlatList
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
        data={getSlotsByDayOrDate()}
        keyExtractor={item => item._id}
        renderItem={({ item, index }) => {
          const IS_LAST = getSlotsByDayOrDate().length - 1 === index;

          return (
            <TouchableOpacity onPress={() => handleSlotEdit(item)}>
              <Box
                pb="s"
                borderBottomColor="disabled"
                borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
              >
                <BookingOrderServiceSlotCard
                  isEnabled={item.isEnabled}
                  time={item.startTime}
                  slotMinMax={getSlotMinMaxText(item)}
                  serviceSlotCapacity={getSlotCapacityText(item)}
                  updateEnabled={v => updateSlotEnabled(item._id, v)}
                  module={Modules.OnlineSales}
                />
              </Box>
            </TouchableOpacity>
          );
        }}
      />

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default TakeAwayServiceSlotsList;
