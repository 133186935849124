import { TouchableOpacity } from "react-native-gesture-handler";

import MODIFY from "../../../assets/icons/BASE/MODIFY.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface TaxCardProps {
  name: string;
  taxRate: number;
  onPress: () => void;
  onDelete: () => void;
}

const TaxCard = ({ name, taxRate, onPress, onDelete }: TaxCardProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="disabled"
          borderRadius="button"
          p="s"
          flex={1}
        >
          <CustomText variant="content" color="primaryTextColor">
            {taxRate}% - {name}
          </CustomText>
          <MODIFY height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
        </Box>
        {/* <TouchableOpacity onPress={onDelete}>
          <TRASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.red} />
        </TouchableOpacity> */}
      </Box>
    </TouchableOpacity>
  );
};

export default TaxCard;
