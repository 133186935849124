import { useState } from "react";
import type { KeyboardType } from "react-native";
import { FlatList, TouchableOpacity } from "react-native";

import CANCEL from "../../../assets/icons/BASE/CANCEL.svg";
import PLUS from "../../../assets/icons/BASE/PLUS.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomTextInput } from "../TextInput";

interface ButtonListProps {
  values: { label: string; key: string }[];
  onChange: (values: { label: string; key: string }[]) => void;
  placeHolder?: string;
  keyboardType?: KeyboardType;
  isInputValueValid?: (value: string) => boolean;
}

const BUTTON_HEIGHT = 45;

const ButtonList = ({
  values,
  onChange,
  placeHolder = "Tag",
  keyboardType,
  isInputValueValid,
}: ButtonListProps) => {
  const [newTagValue, setNewTagValue] = useState("");
  const handleRemovalOfOption = (key: string) => {
    const newOptions = values.filter(option => option.key !== key);
    onChange(newOptions);
  };

  const handleAdditionOfOption = () => {
    const key = newTagValue.trim();
    if (key.length > 0) {
      const newOptions = [...values, { key, label: key }];
      onChange(newOptions);
      setNewTagValue("");
    }
  };

  const isValid = !isInputValueValid || isInputValueValid(newTagValue);

  return (
    <Box>
      <FlatList
        data={values}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <Box
            minHeight={BUTTON_HEIGHT}
            backgroundColor="disabled"
            p="s"
            flexDirection="row"
            alignItems="center"
            mr="s"
            borderRadius="button"
          >
            <CustomText mr="s" variant="content" color="primaryTextColor">
              {item.label}
            </CustomText>
            <TouchableOpacity onPress={() => handleRemovalOfOption(item.key)}>
              <Box ml="s">
                <CANCEL fill={PALETTE.darkGrey} />
              </Box>
            </TouchableOpacity>
          </Box>
        )}
      />
      <Box mt="s" flexDirection="row" alignItems="center">
        <Box flex={1} mr="s">
          <CustomTextInput
            value={newTagValue}
            placeHolder={placeHolder}
            onChangeText={setNewTagValue}
            keyboardType={keyboardType}
          />
        </Box>
        <TouchableOpacity disabled={!isValid} onPress={handleAdditionOfOption}>
          <Box
            minHeight={45}
            minWidth={45}
            borderColor={isValid ? "success" : "lightGrey"}
            borderWidth={LINE_THICKNESS}
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
          >
            <PLUS
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={isValid ? PALETTE.green : PALETTE.lightGrey}
            />
          </Box>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default ButtonList;
