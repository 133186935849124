import { useEffect, useState } from "react";
import type { LayoutRectangle } from "react-native";
import { ActivityIndicator, Dimensions } from "react-native";
import { PieChart } from "react-native-chart-kit";

import MASK from "../../../../../assets/icons/BASE/MASK.svg";
import type { GetOrdersByOriginsQuery } from "../../../../graphql/generated/schema";
import { useGetOrdersByOriginsLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface OrdersByOriginsProps {
  startDate: Date;
  endDate: Date;
}

const screenWidth = Dimensions.get("window").width;

const ORIGIN_BALL_SIZE = 15;

const chartConfig = {
  // backgroundGradientFrom: "#1E2923",
  // backgroundGradientFromOpacity: 0,
  // backgroundGradientTo: "#08130D",
  // backgroundGradientToOpacity: 0.5,
  // backgroundColor: "#fff",
  backgroundGradientFrom: "#fff",
  backgroundGradientTo: "#fff",
  color: (opacity = 1) => `rgba(0,0,0,${opacity})`,
  strokeWidth: 2, // optional, default 3
  barPercentage: 0.5,
  useShadowColorFromDataset: false, // optional
};

const OrdersByOrigins = ({ startDate, endDate }: OrdersByOriginsProps) => {
  const [loading, setLoading] = useState(true);
  const [bookingStats, setBookingStats] = useState<
    GetOrdersByOriginsQuery["getOrdersByOrigins"] | null
  >(null);
  const [containerLayout, setcontainerLayout] = useState<LayoutRectangle | undefined>(
    undefined,
  );

  const [getBookingByOrigins] = useGetOrdersByOriginsLazyQuery();

  const handleGetBookingsTotalRecap = async () => {
    try {
      const { data } = await getBookingByOrigins({
        fetchPolicy: "cache-and-network",
        variables: {
          startDate,
          endDate,
        },
      });

      if (data?.getOrdersByOrigins) {
        setBookingStats(data.getOrdersByOrigins);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, [startDate, endDate]);

  if (loading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!bookingStats) return null;

  const getChartData = () => {
    return [
      {
        name: "En ligne",
        population: bookingStats.totalOnlineOrders,
        color: PALETTE.orange,
        legendFontColor: "#7F7F7F",
        legendFontSize: 15,
      },

      {
        name: "Phone",
        population: bookingStats.totalPhoneOrders,
        color: PALETTE.green,
        legendFontColor: "#7F7F7F",
        legendFontSize: 15,
      },
    ];
  };

  const INFO_LEFT_POS_EXTRA_POS = screenWidth > 500 ? 25 : 35;

  const INFO_LEFT_POS =
    (containerLayout?.width || screenWidth) / 2 - INFO_LEFT_POS_EXTRA_POS;

  return (
    <Box
      flex={1}
      borderRadius="button"
      borderColor="lightGrey"
      borderWidth={LINE_THICKNESS}
      p="s"
      onLayout={e => {
        setcontainerLayout(e.nativeEvent.layout);
      }}
    >
      <Box p="s">
        <CustomText variant="content" color="primaryTextColor">
          Provenance
        </CustomText>
      </Box>

      <Box>
        <PieChart
          data={getChartData()}
          width={screenWidth}
          height={280}
          chartConfig={chartConfig}
          accessor={"population"}
          backgroundColor={"transparent"}
          paddingLeft={"15"}
          center={[(containerLayout?.width || screenWidth) / 4, 10]}
          absolute
          avoidFalseZero
          hasLegend={false}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
          }}
        />

        <Box
          position="absolute"
          alignItems="center"
          justifyContent="center"
          alignSelf="center"
          backgroundColor="white"
          top="35%"
          // left="46%"
          left={INFO_LEFT_POS}
          width={100}
          height={100}
          style={{
            borderRadius: 70,
          }}
        >
          <CustomText variant="label" color="primaryTextColor">
            {bookingStats.totalOrders} Comm.
          </CustomText>
        </Box>
      </Box>

      <Box>
        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Box
              height={ORIGIN_BALL_SIZE}
              width={ORIGIN_BALL_SIZE}
              style={{ borderRadius: ORIGIN_BALL_SIZE / 2 }}
              backgroundColor="orange"
            />

            <CustomText ml="s" variant="content" color="primaryTextColor">
              Commandes en lignes
            </CustomText>
          </Box>
          <CustomText variant="content" fontSize={16} color="primaryTextColor">
            {bookingStats.totalOnlineOrders}
          </CustomText>
        </Box>

        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Box
              height={ORIGIN_BALL_SIZE}
              width={ORIGIN_BALL_SIZE}
              style={{ borderRadius: ORIGIN_BALL_SIZE / 2 }}
              backgroundColor="success"
            />

            <CustomText ml="s" variant="content" color="primaryTextColor">
              Commandes téléphone
            </CustomText>
          </Box>
          <CustomText variant="content" fontSize={16} color="primaryTextColor">
            {bookingStats.totalPhoneOrders}
          </CustomText>
        </Box>
      </Box>

      <Box
        marginVertical="m"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box alignItems="center" justifyContent="center">
          <Box
            p="s"
            height={50}
            width={50}
            backgroundColor="disabled"
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
          >
            <MASK width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <Box marginVertical="xs">
            <CustomText variant="text" color="primaryTextColor">
              No shows
            </CustomText>
          </Box>
          <Box>
            <CustomText variant="title" color="primaryTextColor">
              {bookingStats.totalNoShowOrders}
            </CustomText>
          </Box>
        </Box>

        <Box alignItems="center" justifyContent="center">
          <Box
            p="s"
            height={50}
            width={50}
            backgroundColor="disabled"
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
          >
            <MASK width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <Box marginVertical="xs">
            <CustomText variant="text" color="primaryTextColor">
              Annulations
            </CustomText>
          </Box>
          <Box>
            <CustomText variant="title" color="primaryTextColor">
              {bookingStats.totalCancelledOrders}
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrdersByOrigins;
