import { useEffect, useMemo, useState } from "react";
import { FlatList } from "react-native";

import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import type { Pagination, ProductFragment } from "../../../../graphql/generated/schema";
import { useGetProductsLazyQuery } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import AlphabetSectionList from "../../../AlphabetSectionList";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomButton } from "../../../Button";
import ContentModal from "../../../ContentModal";
import Loader from "../../../Loader";
import { CustomTextInput } from "../../../TextInput";
import QuoteProductCard from "../QuoteProductCard";

interface NewQuoteProductListModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (
    product: {
      _id: string;
      price: {
        amount: number;
        taxRate: number;
      };
      name: string;
      quantity: number;
    }[],
  ) => void;
}

const NewQuoteProductListModal = ({
  isVisible,
  onClose,
  onSubmit,
}: NewQuoteProductListModalProps) => {
  const [products, setProducts] = useState<
    {
      _id: string;
      price: {
        amount: number;
        taxRate: number;
      };
      name: string;
      quantity: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [availableProducts, setAvailableProducts] = useState<ProductFragment[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });

  const [getProducts] = useGetProductsLazyQuery();

  const handleGetProducts = async () => {
    try {
      const { data } = await getProducts({
        variables: { pagination },
      });

      if (data?.getProducts) {
        setAvailableProducts(data.getProducts);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetProducts();
  }, []);

  const filteredProducts = useMemo(() => {
    if (query.length === 0) return availableProducts;
    return availableProducts.filter(product =>
      product.name.toLowerCase().includes(query.toLowerCase()),
    );
  }, [query, availableProducts]);

  const handleAddProduct = (product: {
    _id: string;
    price: {
      amount: number;
      taxRate: number;
    };
    name: string;
    quantity: number;
  }) => {
    setProducts(prev => {
      // const p = prev.filter(prevp => prevp._id === product._id);
      const isExisting = prev.some(prevp => prevp._id === product._id);

      console.log("isExisting", isExisting);

      if (isExisting) {
        const updated = prev.map(prevp => {
          if (prevp._id === product._id) {
            return {
              ...prevp,
              quantity: product.quantity,
            };
          }
          return prevp;
        });

        console.log("updated", updated);

        return updated;
      }

      console.log("else", [...prev, product]);
      return [...prev, product];
    });
  };

  const handleSubmit = () => {
    onSubmit(products);
    onClose();
  };

  const handleLetterPress = (letter: string) => {
    console.log(letter);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ContentModal title="Vos prestations" isVisible={isVisible} onClose={onClose}>
      <Box>
        <Box mb="m">
          <CustomTextInput
            leftIcon={<SEARCH width={24} height={24} fill={PALETTE.lightGrey} />}
            onChangeText={setQuery}
            placeHolder="Rechercher un produit"
          />
        </Box>
        <Box
          minHeight={300}
          maxHeight={500}
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box flex={1} mr="s">
            <FlatList
              keyExtractor={item => item._id}
              showsVerticalScrollIndicator={false}
              data={filteredProducts}
              renderItem={({ item }) => {
                return (
                  <Box mb="s">
                    <QuoteProductCard product={item} addProduct={handleAddProduct} />
                  </Box>
                );
              }}
            />
          </Box>

          <AlphabetSectionList onLetterPress={handleLetterPress} />
        </Box>

        <CustomButton
          buttonColor="success"
          onPress={handleSubmit}
          buttonVariant="primaryButton"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
    </ContentModal>
  );
};

export default NewQuoteProductListModal;
