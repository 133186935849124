import { ErrorBoundary } from "@sentry/react";
import { fr } from "date-fns/locale";
import setDefaultOptions from "date-fns/setDefaultOptions";
import { useFonts } from "expo-font";
import { Platform } from "react-native";
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context";
import * as Sentry from "sentry-expo";
import "./wdyr";

import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/fr";
import "setimmediate";
import config from "./src/config/env";
import { AppApolloProvider } from "./src/providers/apollo";
import { AppStripeProvider } from "./src/providers/stripe";
import { AppThemeProvider } from "./src/providers/theme";

setDefaultOptions({ locale: fr });

import "expo-dev-client";
import CrashErrorBoundaryFallback from "./src/components/ErrorBoundary/CrashErrorBoundaryFallback";
import Navigation from "./src/navigation";

// import { setJSExceptionHandler } from "react-native-exception-handler";
// setJSExceptionHandler((error, isFatal) => {

//   alert(error?.message)
//   // This is your custom global error handler
//   // You do stuff like show an error dialog
//   // or hit google analytics to track crashes
//   // or hit a custom api to inform the dev team.
// },true);

// const exceptionhandler = (exceptionString) => {

//     alert(exceptionString)
//   // your exception handler code here
// };

// setNativeExceptionHandler(
//   exceptionhandler,
//   false,
// );

Sentry.init({
  dsn: config.SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 6.0,
  enableInExpoDevelopment: false,
  environment: config.APP_ENV,
  // integrations: [new Sentry.Native.ReactNativeTracing({ routingInstrumentation })],
});

const IS_WEB = Platform.OS === "web";
const SentryWrap = IS_WEB ? (v?: JSX.Element) => v : Sentry.Native.wrap;

function App() {
  const [fontsLoaded] = useFonts({
    "Lexend-Regular": require("./assets/fonts/Lexend-Regular.ttf"),
    "Lexend-Bold": require("./assets/fonts/Lexend-Bold.ttf"),
    "Lexend-Light": require("./assets/fonts/Lexend-Light.ttf"),
  });

  if (!fontsLoaded) {
    return <></>;
  }

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <ErrorBoundary showDialog fallback={<CrashErrorBoundaryFallback />}>
        <AppStripeProvider>
          <AppApolloProvider>
            <AppThemeProvider>
              <Navigation />
            </AppThemeProvider>
          </AppApolloProvider>
        </AppStripeProvider>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}

export default SentryWrap(App);
