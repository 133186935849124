import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";

import TakeAwayServiceListDetailsEdit from "./Edit";
import TakeAwayServiceListDetailsView from "./View";

interface TakeAwayServiceListDetailsProps {
  goBack?: () => void;
  newValue?: boolean;
  selectedServiceId?: string;
}

const TakeAwayServiceListDetails = ({
  goBack,
  newValue,
  selectedServiceId,
}: TakeAwayServiceListDetailsProps) => {
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = selectedServiceId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);
  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {!inEditMode ? (
        <TakeAwayServiceListDetailsView id={id} goBack={goBack} />
      ) : (
        <TakeAwayServiceListDetailsEdit id={id} goBack={goBack} />
      )}
      {!inEditMode && (
        <BottomButton onPress={() => setInEditMode(true)} title="Modifier" />
      )}
    </Box>
  );
};

export default TakeAwayServiceListDetails;
