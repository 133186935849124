import Box from "../Base/Box";
import ErrorMessage from "../ErrorMessage/index";

const CrashErrorBoundaryFallback = () => {
  return (
    <Box>
      <ErrorMessage message="Une erreur majeure est survenue" />
    </Box>
  );
};

export default CrashErrorBoundaryFallback;
