import { useState } from "react";

import type { QUOTE } from "../../../../Data";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomTextInput } from "../../../TextInput";
import Toggle from "../../../Toggle";

interface NewQuoteInformationProps {
  quote: QUOTE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateQuote: (key: keyof QUOTE, value: any) => void;
}

const NewQuoteInformation = ({ quote, updateQuote }: NewQuoteInformationProps) => {
  const [hasDeliveryAddress, setHasDeliveryAddress] = useState(!!quote.deliveryAddress);
  const [hasAcceptConditions, setHasAcceptConditions] = useState(
    !!quote.conditionOfAcceptance,
  );
  const [hasInfoLine, setHasInfoLine] = useState(!!quote.extraField);
  const [hasTotalDiscount, setHasTotalDiscount] = useState(false);

  return (
    <Box>
      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Adresse de livraision
          </CustomText>
          <Toggle value={hasDeliveryAddress} onChange={setHasDeliveryAddress} />
        </Box>
        {hasDeliveryAddress && (
          <Box mt="s">
            <CustomTextInput
              isRequired
              placeHolder="Adresse"
              initialValue={quote.deliveryAddress}
              onChangeText={text => updateQuote("deliveryAddress", text)}
            />
          </Box>
        )}
      </Box>
      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Conditions d'acceptation
          </CustomText>
          <Toggle value={hasAcceptConditions} onChange={setHasAcceptConditions} />
        </Box>
        {hasAcceptConditions && (
          <Box mt="s">
            <CustomTextInput
              isRequired
              placeHolder="Conditions d'acceptation"
              initialValue={quote.conditionOfAcceptance}
              onChangeText={text => updateQuote("conditionOfAcceptance", text)}
            />
          </Box>
        )}
      </Box>
      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Champ libre
          </CustomText>
          <Toggle value={hasInfoLine} onChange={setHasInfoLine} />
        </Box>
        {hasInfoLine && (
          <Box mt="s">
            <CustomTextInput
              isRequired
              placeHolder="Champ libre"
              initialValue={quote.extraField}
              onChangeText={text => updateQuote("extraField", text)}
            />
          </Box>
        )}
      </Box>
      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Remise global
          </CustomText>
          <Toggle value={hasTotalDiscount} onChange={setHasTotalDiscount} />
        </Box>
        {hasTotalDiscount && (
          <Box mt="s">
            <CustomTextInput
              isRequired
              placeHolder="Remise global"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChangeText={() => {}}
            />
          </Box>
        )}
      </Box>
      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Activer paiement en ligne
          </CustomText>
          <Toggle
            value={quote.isOnlinePaymentEnabled}
            onChange={value => updateQuote("isOnlinePaymentEnabled", value)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewQuoteInformation;
