import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ButtonList from "../../../../components/ButtonList";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import type { PerformanceSettingsFragment } from "../../../../graphql/generated/schema";
import {
  Frequency_Interval,
  useGetPerformanceSettingsLazyQuery,
  useUpdatePerformanceSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface PerformanceSettingsProps {
  goBack?: () => void;
}

const FREQUENCY_INTERVAL_OPTIONS = [
  {
    label: "Tous les jours",
    key: Frequency_Interval.EveryDay,
  },
  {
    label: "Une fois par semaine",
    key: Frequency_Interval.OnceAWeek,
  },
  {
    label: "Une fois par mois",
    key: Frequency_Interval.OnceAMonth,
  },
];

const PerformanceSettings = ({ goBack }: PerformanceSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<PerformanceSettingsFragment | null>(null);

  const [getPerformanceSettings] = useGetPerformanceSettingsLazyQuery();
  const [updatePerformanceSettings] = useUpdatePerformanceSettingsMutation();

  const handleGetSettings = async () => {
    try {
      const { data } = await getPerformanceSettings();

      if (data?.getPerformanceSettings) {
        setSettings(data.getPerformanceSettings);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  // const validateForm = async () => {
  //   try {
  //     await schema.validate(settings, { abortEarly: false });

  //     return true;
  //   } catch (err) {
  //     setErrors(formaYupErrors(err));

  //     setIsSnackBarOpen(true);
  //   }
  //   return false;
  // };

  const setEmails = (
    key:
      | "customerLoyaltyReportEmails"
      | "salesReportEmails"
      | "establishmentStatisticsReportEmails",
    vals: { label: string; key: string }[],
  ) => {
    const emails = vals.map(val => val.key);

    if (settings) {
      setSettings(prev =>
        prev === null
          ? null
          : {
              ...prev,
              [key]: emails,
            },
      );
    }
  };

  const formatEmails = (emails: string[]) => {
    return emails.map(email => ({ key: email, label: email }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSettingsData = (key: keyof PerformanceSettingsFragment, value: any) => {
    if (settings) {
      setSettings({
        ...settings,
        [key]: value,
      });
    }
  };

  const handlSubmit = async () => {
    // const isFormValid = await validateForm();

    // if (!isFormValid) return;

    try {
      const updates = removeTypeNames(settings);
      delete updates._id;

      await updatePerformanceSettings({
        variables: {
          updates,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("err submit", err);
    }
  };

  if (loading) return <Loader />;

  if (!settings)
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box>
          <ScreenHeader
            title="Rapports"
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>

        <ErrorMessage message="Une erreur s'est produite lors du chargement des paramètres de performance." />
      </Box>
    );

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Rapports"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      </Box>

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <Box mt="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de vente
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.salesReportEmails)}
            onChange={vals => setEmails("salesReportEmails", vals)}
            keyboardType="email-address"
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.salesReportFrequency]}
            onPress={([val]) => updateSettingsData("salesReportFrequency", val)}
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de fidélité
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.customerLoyaltyReportEmails)}
            onChange={vals => setEmails("customerLoyaltyReportEmails", vals)}
            keyboardType="email-address"
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.customerLoyaltyReportFrequency]}
            onPress={([val]) => updateSettingsData("customerLoyaltyReportFrequency", val)}
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de statistiques
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.establishmentStatisticsReportEmails)}
            onChange={vals => setEmails("establishmentStatisticsReportEmails", vals)}
            keyboardType="email-address"
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.establishmentStatisticsReportFrequency]}
            onPress={([val]) =>
              updateSettingsData("establishmentStatisticsReportFrequency", val)
            }
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>
      </ScrollView>

      <BottomButton title="Valider" onPress={handlSubmit} />
    </Box>
  );
};

export default PerformanceSettings;
