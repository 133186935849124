import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import ModuleFormInputLine from "../../../../../components/ModuleFormInputLine";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { OnlineSalesSettingsFragment } from "../../../../../graphql/generated/schema";
import {
  Booking_Form_Input,
  useGetOnlineSalesSettingsLazyQuery,
  useUpdateOnlineSalesSettingsMutation,
} from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";
import { removeTypeNames } from "../../../../../utils/common";

interface TakeAwayFormSettingsProps {
  goBack?: () => void;
}

const TakeAwayFormSettings = ({ goBack }: TakeAwayFormSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [settings, setSettings] = useState<OnlineSalesSettingsFragment | null>(null);

  const [getSettings] = useGetOnlineSalesSettingsLazyQuery();
  const [updateSettings] = useUpdateOnlineSalesSettingsMutation();

  const handlGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getSettings();

      if (data) {
        setSettings(data.getOnlineSalesSettings);
      }
    } catch (error) {
      console.log(error);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_ORDER_SETTINGS",
            message: "Une erreur est survenue",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handleUpdateSettings = async () => {
    try {
      if (!settings) return;

      const updates = removeTypeNames(settings);

      await updateSettings({
        variables: {
          updates,
        },
      });

      handlGoBack();
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATING_ORDER_SETTINGS",
            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
    }
  };

  const updateFormEnabled = (key: Booking_Form_Input, value: boolean) => {
    setSettings(prev => {
      if (prev) {
        const { orderForm } = prev;
        const newInputs = orderForm.inputs.map(input => {
          if (input.inputName === key) {
            const isRequired = value ? input.isRequired : false;
            return {
              ...input,
              isRequired,
              isEnabled: value,
            };
          }
          return input;
        });

        return {
          ...prev,
          orderForm: {
            inputs: newInputs,
          },
        };
      }
      return null;
    });
  };
  const updateFormRequired = (key: Booking_Form_Input, value: boolean) => {
    setSettings(prev => {
      if (prev) {
        const { orderForm } = prev;
        const newInputs = orderForm.inputs.map(input => {
          if (input.inputName === key) {
            const isEnabled = value ? true : input.isEnabled;
            return {
              ...input,
              isEnabled,

              isRequired: value,
            };
          }
          return input;
        });

        return {
          ...prev,
          orderForm: {
            inputs: newInputs,
          },
        };
      }
      return null;
    });
  };

  const getFormInputEnabled = (key: Booking_Form_Input) => {
    if (settings) {
      const { orderForm } = settings;
      const input = orderForm.inputs.find(v => v.inputName === key);
      if (input) {
        return input.isEnabled;
      }
    }
    return false;
  };

  const getFormInputRequired = (key: Booking_Form_Input) => {
    if (settings) {
      const { orderForm } = settings;
      const input = orderForm.inputs.find(v => v.inputName === key);
      if (input) {
        return input.isRequired;
      }
    }
    return false;
  };

  if (loading) {
    return <Loader />;
  }

  if (!settings) {
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Formulaire de commande"
        hasBackButton
        onBackPress={handlGoBack}
      />
      <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres" />
    </Box>;
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Formulaire de commande"
        hasBackButton
        onBackPress={handlGoBack}
      />

      <Box marginVertical="s">
        <CustomText variant="content" color="lightGrey">
          Veuillez choisir les champs que vous souhaitez utiliser lors de la prise de
          commande.
        </CustomText>
      </Box>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Civilité"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Civility)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Civility)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.Civility, v)}
            onRequiredChange={v => updateFormRequired(Booking_Form_Input.Civility, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Nom"
            isOptional={false}
            isRequired={true}
            isEnabled={getFormInputEnabled(Booking_Form_Input.LastName)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.LastName, v)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onRequiredChange={() => {}}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Prénom"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.FirstName)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.FirstName)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.FirstName, v)}
            onRequiredChange={v => updateFormRequired(Booking_Form_Input.FirstName, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Téléphone"
            isOptional={false}
            isRequired={true}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Phone)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.Phone, v)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onRequiredChange={() => {}}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Email"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Email)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Email)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.Email, v)}
            onRequiredChange={v => updateFormRequired(Booking_Form_Input.Email, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Addresse"
            isOptional={false}
            isRequired={true}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Address)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.Address, v)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onRequiredChange={v => {}}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Note sur le client"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.MerchantClientNote)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.MerchantClientNote)}
            onValueChange={v =>
              updateFormEnabled(Booking_Form_Input.MerchantClientNote, v)
            }
            onRequiredChange={v =>
              updateFormRequired(Booking_Form_Input.MerchantClientNote, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="VIP"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Vip)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Vip)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.Vip, v)}
            onRequiredChange={v => updateFormRequired(Booking_Form_Input.Vip, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Note sur la commande"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.CommentOwner)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.CommentOwner)}
            onValueChange={v => updateFormEnabled(Booking_Form_Input.CommentOwner, v)}
            onRequiredChange={v => updateFormRequired(Booking_Form_Input.CommentOwner, v)}
          />
        </Box>
      </ScrollView>

      <BottomButton onPress={handleUpdateSettings} title="Enregistrer" />
    </Box>
  );
};

export default TakeAwayFormSettings;
