import type { VariantProps } from "@shopify/restyle";
import { createRestyleComponent, createVariant } from "@shopify/restyle";
import React from "react";
import type { TouchableOpacityProps, ViewStyle } from "react-native";
import { TouchableOpacity } from "react-native";

import type { Theme, THEME_COLORS } from "../../theme";
import type { BUTTON_VARIANTS } from "../../theme/variants/ButtonVariants";
import Box from "../Base/Box";

const MIN_HEIGHT = 45;
const MIN_WIDTH = 323;

type Props = {
  onPress: () => void;
  buttonVariant?: BUTTON_VARIANTS;
  buttonColor?: THEME_COLORS;
  disabled?: boolean;
  borderColor?: THEME_COLORS;
  children: JSX.Element | JSX.Element[];
  styles?: React.ComponentProps<typeof Box>;
  containerStyles?: TouchableOpacityProps["style"];
  overrideStyles?: ViewStyle;
};

const Button = createRestyleComponent<
  VariantProps<Theme, "buttonVariants"> & React.ComponentProps<typeof Box>,
  Theme
>([createVariant({ themeKey: "buttonVariants" })], Box);

export const CustomButton = ({
  buttonVariant,
  // textVariant,
  onPress,
  // text,
  // textColor,
  buttonColor,
  disabled,
  borderColor,
  styles,
  children,
  overrideStyles,
  containerStyles,
}: Props) => {
  return (
    <TouchableOpacity style={containerStyles} onPress={onPress} disabled={disabled}>
      <Button
        variant={buttonVariant}
        borderColor={borderColor}
        backgroundColor={buttonColor}
        alignItems="center"
        justifyContent="center"
        minHeight={MIN_HEIGHT}
        // minWidth={MIN_WIDTH}
        {...styles}
        style={overrideStyles}
      >
        {children}
      </Button>
    </TouchableOpacity>
  );
};
