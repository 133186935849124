import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useMemo, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import NEW from "../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import { CustomButton } from "../../../components/Button";
import CollapsibleList from "../../../components/CollapsibleList";
import PublicationDetails from "../../../components/Publication/PublicationDetails";
import PublicationListCard from "../../../components/Publication/PublicationListCard";
import ScreenHeader from "../../../components/ScreenHeader";
import SplitView from "../../../components/SplitView";
import { CustomTextInput } from "../../../components/TextInput";
import { AppContext } from "../../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  GetPublicationsQuery,
  PublicationFragment,
} from "../../../graphql/generated/schema";
import {
  useGetPublicationsLazyQuery,
  useUpdatePublicationMutation,
} from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import PublicationListDetails from "../PublicationListDetails";

const PublicationList = () => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const appContext = useContext(AppContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [publications, setPublications] = useState<
    GetPublicationsQuery["getPublications"]
  >([]);
  const [isActivePublicationsOpen, setIsActivePublicationsOpen] = useState(true);
  const [isAllPublicationsOpen, setIsAllPublicationsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedPublicationId, setSelectedPublicationId] = useState("");
  const [isNew, setIsNew] = useState(false);

  const [getPublications] = useGetPublicationsLazyQuery();
  const [updatePublication] = useUpdatePublicationMutation();

  const activePublications = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();
    return publications.filter(publication => {
      const title = publication.title.toLowerCase().trim();
      const description = publication.description.toLowerCase().trim();

      return (
        publication.isPublished &&
        (title.includes(formattedSearch) || description.includes(formattedSearch))
      );
    });
  }, [publications, search]);
  const nonActivePublications = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();
    return publications.filter(publication => {
      const title = publication.title.toLowerCase().trim();
      const description = publication.description.toLowerCase().trim();

      return (
        !publication.isPublished &&
        (title.includes(formattedSearch) || description.includes(formattedSearch))
      );
    });
  }, [publications, search]);

  const handleGetPublications = async () => {
    try {
      const { data, error } = await getPublications({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 10,
            offset: 0,
          },
        },
      });

      if (error) {
        console.log("err", error);
        return console.log("err get publications", error);
      }

      if (data?.getPublications) {
        setPublications(data?.getPublications);
      }
    } catch (err) {
      console.log("err get publications", err);
    }
  };

  // useEffect(() => {
  //   handleGetPublications();
  // }, []);

  useFocusEffect(
    React.useCallback(() => {
      handleGetPublications();
    }, []),
  );

  const handleNewPublication = () => {
    if (appContext.isSplittable) {
      setIsNew(true);
    } else {
      navigation.navigate("NEW_PUBLICATION");
    }
  };

  const handleNavigationToPublicationDetails = (publicationId: string) => {
    if (appContext.isSplittable) {
      setSelectedPublicationId(publicationId);
    } else {
      navigation.navigate("PUBLICATION_LIST_DETAILS", { publicationId });
    }
  };

  const handleUpdatePublications = async (
    publicationId: string,
    key: keyof PublicationFragment,
    value: boolean,
  ) => {
    const newPublications = publications.map(publication => {
      if (publication._id === publicationId) {
        return {
          ...publication,
          [key]: value,
        };
      }

      return publication;
    });

    setPublications(newPublications);
  };

  const handleTogglePublication = async (
    publicationId: string,
    key: keyof PublicationFragment,
    value: boolean,
  ) => {
    try {
      const { data } = await updatePublication({
        variables: {
          publicationId,
          publication: {
            [key]: value,
          },
        },
      });

      if (data?.updatePublication) {
        handleUpdatePublications(publicationId, key, value);
      }
    } catch (err) {
      console.log("err update publish publication toggle", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_PUBLICATION",
            message: "Une erreur est survenue lors de la mise à jour de la publication",
          },
        ],
        "error",
      );
    }
  };

  const RIGHT_BUTTONS = (
    <Box flexDirection="row" alignItems="center">
      <CustomButton onPress={handleNewPublication} styles={{ mr: "s" }}>
        <NEW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
      </CustomButton>
      <CustomButton onPress={() => setIsSearchOpen(v => !v)} styles={{ mr: "s" }}>
        <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
      </CustomButton>
      {/* <CustomButton onPress={() => {}} styles={{ mr: "s" }}>
        <FILTER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
      </CustomButton> */}
    </Box>
  );

  const displayPublicationList = () => {
    return (
      <Box paddingHorizontal="s" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader title="Publications" rightButtons={RIGHT_BUTTONS} />
        </Box>

        {isSearchOpen && (
          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        )}

        <ScrollView showsVerticalScrollIndicator={false}>
          <Box marginVertical="s">
            <CollapsibleList
              onChange={setIsActivePublicationsOpen}
              isOpen={isActivePublicationsOpen}
              title="Publication actives"
              nbItems={activePublications.length}
            >
              <FlatList
                data={activePublications}
                keyExtractor={item => item._id}
                contentContainerStyle={{
                  paddingTop: 20,
                  paddingHorizontal: 8,
                }}
                renderItem={({ item }) => (
                  <Box mb="m">
                    <PublicationListCard
                      publication={item}
                      onTogglePublish={(_id, val) =>
                        handleTogglePublication(item._id, "isPublished", val)
                      }
                      onTogglePin={(_id, val) =>
                        handleTogglePublication(item._id, "isPinned", val)
                      }
                      onToggleBoost={(_id, val) =>
                        handleTogglePublication(item._id, "isBoosted", val)
                      }
                      onPress={handleNavigationToPublicationDetails}
                    />
                  </Box>
                )}
              />
            </CollapsibleList>
          </Box>
          <Box mt="s" marginVertical="s">
            <CollapsibleList
              onChange={setIsAllPublicationsOpen}
              isOpen={isAllPublicationsOpen}
              title="publications non actives"
              nbItems={nonActivePublications.length}
            >
              <FlatList
                data={nonActivePublications}
                keyExtractor={item => item._id}
                contentContainerStyle={{
                  paddingTop: 20,
                  paddingHorizontal: 8,
                }}
                renderItem={({ item }) => (
                  <Box mb="m">
                    <PublicationListCard
                      publication={item}
                      onTogglePublish={(_id, val) =>
                        handleTogglePublication(item._id, "isPublished", val)
                      }
                      onTogglePin={(_id, val) =>
                        handleTogglePublication(item._id, "isPinned", val)
                      }
                      onToggleBoost={(_id, val) =>
                        handleTogglePublication(item._id, "isBoosted", val)
                      }
                      onPress={handleNavigationToPublicationDetails}
                    />
                  </Box>
                )}
              />
            </CollapsibleList>
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const displayEmptySelection = () => {
    return (
      <Box flex={1} justifyContent="center" alignItems="center" backgroundColor="white">
        <CustomText variant="label" color="primaryTextColor">
          Sélectionnez une de vos publications pour l'afficher ou l'éditer
        </CustomText>

        <CustomText marginVertical="m" variant="content" color="primaryTextColor">
          ou
        </CustomText>

        <CustomButton
          onPress={handleNewPublication}
          buttonVariant="primaryButton"
          buttonColor="success"
          styles={{
            paddingHorizontal: "m",
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Nouvelle publication
          </CustomText>
        </CustomButton>
      </Box>
    );
  };

  const handleCloseSelectedTab = async () => {
    await handleGetPublications();

    setSelectedPublicationId("");
    setIsNew(false);
  };

  const displaySelectedPublication = () => {
    if (!appContext.isSplittable) return undefined;

    if (isNew) {
      return <PublicationDetails isNew onGoBack={handleCloseSelectedTab} />;
    }

    if (!selectedPublicationId) return displayEmptySelection();

    return (
      <PublicationListDetails
        publicationId={selectedPublicationId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  return (
    <SplitView
      leftComponent={displayPublicationList()}
      rightComponent={displaySelectedPublication()}
    />
  );
};

export default PublicationList;
