import { createNativeStackNavigator } from "@react-navigation/native-stack";

import Landing from "../../screens/Auth/Landing";
import Login from "../../screens/Auth/Login";

import type { AuthStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<AuthStackParamList>();

const AuthStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
      }}
      initialRouteName="LOGIN"
    >
      <Stack.Screen name="LANDING" component={Landing} />
      <Stack.Screen name="LOGIN" component={Login} />
      <Stack.Screen name="SIGN_UP" component={Landing} />
      <Stack.Screen name="VERIFY_NUMBER" component={Landing} />
      <Stack.Screen name="FORGOT_PASSWORD" component={Landing} />
    </Stack.Navigator>
  );
};

export default AuthStack;
