import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { TouchableOpacity } from "react-native";

import DARK_THEME from "../../../../assets/icons/BASE/DARK_THEME.svg";
import LIGHT_THEME from "../../../../assets/icons/BASE/LIGHT_THEME.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import RadioSelectButton from "../../../components/RadioSelectButton";
import ScreenHeader from "../../../components/ScreenHeader";
import { LINE_THICKNESS } from "../../../theme";

interface GeneralSettingsProps {
  goBack?: () => void;
}

const GeneralSettings = ({ goBack }: GeneralSettingsProps) => {
  const navigation = useNavigation();
  const [isLightThemeSelected, setIsLightThemeSelected] = useState(false);

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Général"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <Box mt="m">
        <CustomText mb="s" textTransform="uppercase" variant="content" color="success">
          Apparence
        </CustomText>

        <Box
          p="s"
          paddingVertical="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          borderRadius="button"
          backgroundColor="disabled"
        >
          <TouchableOpacity onPress={() => setIsLightThemeSelected(true)}>
            <Box mr="l" alignItems="center">
              <LIGHT_THEME />
              <CustomText marginVertical="s" variant="content" color="primaryTextColor">
                Light
              </CustomText>
              <RadioSelectButton isSelected={isLightThemeSelected} />
            </Box>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setIsLightThemeSelected(false)}>
            <Box ml="l" alignItems="center">
              <DARK_THEME />
              <CustomText marginVertical="s" variant="content" color="primaryTextColor">
                Dark
              </CustomText>
              <RadioSelectButton isSelected={!isLightThemeSelected} />
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>

      <Box mt="m">
        <CustomText mb="s" textTransform="uppercase" variant="content" color="success">
          Informations
        </CustomText>

        <Box paddingHorizontal="s" borderRadius="button" backgroundColor="disabled">
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            p="m"
          >
            <CustomText variant="content" color="primaryTextColor">
              Version du logiciel
            </CustomText>
            <CustomText variant="content" color="lightGrey">
              1.0.0
            </CustomText>
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            p="m"
          >
            <CustomText variant="content" color="primaryTextColor">
              Synchronisation
            </CustomText>
            <CustomText variant="content" color="lightGrey">
              01/08/2022 11:30
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
