/* eslint-disable @typescript-eslint/no-empty-function */

import APPLE from "../../../assets/icons/BASE/APPLE.svg";
import FACEBOOK from "../../../assets/icons/BASE/FACEBOOK.svg";
import GOOGLE from "../../../assets/icons/BASE/GOOGLE.svg";
import WHITE_EIWIE_ICON from "../../../assets/icons/BASE/WHITE_EIWIE_ICON.svg";
import Box from "../../components/Base/Box";
import { CustomText } from "../../components/Base/Text";
import { CustomButton } from "../../components/Button";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";

const Landing = () => {
  return (
    <Box
      backgroundColor="loginBackground"
      flex={1}
      justifyContent="center"
      alignItems="center"
    >
      <Box flexDirection="row" justifyContent="center" alignItems="center" mb="xl">
        <WHITE_EIWIE_ICON />
      </Box>

      <CustomButton
        buttonColor="primaryButton"
        buttonVariant="primaryButton"
        onPress={() => {}}
        styles={{
          p: "m",
          marginHorizontal: "m",
          mb: "s",
          width: "100%",
          height: 45,
          minWidth: 325,
        }}
      >
        <CustomText variant="primaryButtonText" color="white">
          Connexion
        </CustomText>
      </CustomButton>

      <CustomButton
        borderColor="primaryButton"
        buttonColor="loginBackground"
        buttonVariant="outlineButton"
        onPress={() => {}}
        styles={{
          // p: "m",
          width: "100%",
          minWidth: 325,
          marginHorizontal: "m",
        }}
      >
        <CustomText variant="secondaryButtonText" color="white">
          Inscription
        </CustomText>
      </CustomButton>

      <Box marginVertical="l" />

      <CustomButton
        buttonColor="white"
        buttonVariant="primaryButton"
        onPress={() => {}}
        styles={{
          // p: "m",
          marginHorizontal: "m",
          mb: "s",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: 45,
          minWidth: 325,
        }}
      >
        <APPLE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />

        <CustomText variant="secondaryButtonText" color="black" ml="m">
          Connexion avec Apple
        </CustomText>
      </CustomButton>

      <CustomButton
        buttonColor="white"
        buttonVariant="primaryButton"
        onPress={() => {}}
        styles={{
          // p: "m",
          marginHorizontal: "m",
          mb: "s",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: 45,
          minWidth: 325,
        }}
      >
        <GOOGLE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />

        <CustomText variant="secondaryButtonText" color="black" ml="m">
          Connexion avec Google
        </CustomText>
      </CustomButton>

      <CustomButton
        buttonColor="white"
        buttonVariant="primaryButton"
        onPress={() => {}}
        styles={{
          // p: "m",
          marginHorizontal: "m",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: 45,
          minWidth: 325,
        }}
      >
        <FACEBOOK height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />

        <CustomText variant="secondaryButtonText" color="black" ml="m">
          Connexion avec Facebook
        </CustomText>
      </CustomButton>
    </Box>
  );
};

export default Landing;
