import { ActivityIndicator } from "react-native";

import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
interface InProgressLoaderProps {
  isLoading: boolean;
}

const InProgressLoader = ({ isLoading }: InProgressLoaderProps) => {
  if (!isLoading) return <></>;

  return (
    <Box
      flex={1}
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <Box
        p="m"
        backgroundColor="white"
        borderRadius="button"
        alignItems="center"
        position="absolute"
      >
        <ActivityIndicator color={PALETTE.green} />
      </Box>
    </Box>
  );
};

export default InProgressLoader;
