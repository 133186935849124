import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../components/Base/Box";
import InputSectionTitle from "../../../components/InputSectionTitle";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";
import SubscriptionInvoicesList from "../../../components/SubscriptionInvoicesList/index";
import SubscriptionsList from "../../../components/SubscriptionsList/index";
import { LINE_THICKNESS } from "../../../theme";

import AccountBillingDetails from "./Account/AccountBillingDetails";
import AccountDetails from "./Account/AccountDetails";

interface AccountAndSubscriptionsProps {
  goBack?: () => void;
}

const AccountAndSubscriptions = ({ goBack }: AccountAndSubscriptionsProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState("");

  const handleNavigation = (screenName: string) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const displaySettingsList = () => {
    return (
      <Box p="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Compte & Abonnements"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Box mt="m" mb="s">
            <InputSectionTitle text="Compte" />
          </Box>
          <Box paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
            <SettingsButtonItem
              title="Nom et coordonnées"
              onPress={() => handleNavigation("ACCOUNT_DETAILS")}
              bottomLineWidth={LINE_THICKNESS}
            />

            {/* <SettingsButtonItem
            title="Mot de passe"
            onPress={() => handleNavigation("ACCOUNT_SECURITY")}
            bottomLineWidth={LINE_THICKNESS}
          /> */}

            <SettingsButtonItem
              title="Détails de facturation"
              onPress={() => handleNavigation("ACCOUNT_BILLING")}
              bottomLineWidth={0}
            />
          </Box>

          <Box mt="m" mb="s">
            <InputSectionTitle text="Abonnements" />
          </Box>

          <SubscriptionsList />

          <Box mt="m" mb="s">
            <InputSectionTitle text="Factures" />
          </Box>

          <SubscriptionInvoicesList />
        </ScrollView>
      </Box>
    );
  };

  const handleCloseSelectedTab = () => {
    setSelectedTab("");
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "ACCOUNT_DETAILS":
        return <AccountDetails goBack={handleCloseSelectedTab} />;
      case "ACCOUNT_BILLING":
        return <AccountBillingDetails goBack={handleCloseSelectedTab} />;

      default:
        break;
    }

    return <></>;
  };

  const displayContent = () => {
    if (!selectedTab) {
      return displaySettingsList();
    }
    return displaySelectedTab();
  };

  return displayContent();
};

export default AccountAndSubscriptions;
