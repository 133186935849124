/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";
import { FlatList } from "react-native";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import BookingListCard from "../../../../../../components/BookingList/BookingListCard";
import type { ListingTab } from "../../../../../../components/ListingTabs";
import { ListingTabs } from "../../../../../../components/ListingTabs";
import Loader from "../../../../../../components/Loader";
import OnlineOrdersListCard from "../../../../../../components/OnlineOrdersList/OnlineOrderListCard";
import ReviewListCard from "../../../../../../components/Reviews/ReviewListCard";
import type {
  BookingFragment,
  OrderFragment,
  ReviewFragment,
} from "../../../../../../graphql/generated/schema";
import {
  Module_Listing_Client_Format,
  useGetBookingsForMerchantClientLazyQuery,
  useGetOrdersForMerchantClientLazyQuery,
  useGetReviewsForMerchantClientLazyQuery,
} from "../../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../../theme";

interface ClientHistoryTabProps {
  merchantClientId: string;
}

const TABS: ListingTab[] = [
  {
    title: "Réservations",
    key: "BOOKINGS",
  },
  {
    title: "Commandes",
    key: "ORDERS",
  },
  {
    title: "Avis",
    key: "REVIEWS",
  },
];

const ClientHistoryTab = ({ merchantClientId }: ClientHistoryTabProps) => {
  const [currentTab, setCurrentTab] = useState(TABS[0].key);
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState<BookingFragment[]>([]);
  const [orders, setOrders] = useState<OrderFragment[]>([]);
  const [reviews, setReviews] = useState<ReviewFragment[]>([]);

  const [getBoookingsForMerchantClient] = useGetBookingsForMerchantClientLazyQuery();
  const [getOrdersForMerchantClient] = useGetOrdersForMerchantClientLazyQuery();
  const [getReviewsForMerchantClient] = useGetReviewsForMerchantClientLazyQuery();

  const handleGetBookings = async () => {
    try {
      const { data, error } = await getBoookingsForMerchantClient({
        fetchPolicy: "cache-and-network",
        variables: {
          merchantClientId,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (error) {
        console.log("err bookings", error);
      }

      if (data?.getBookingsForMerchantClient) {
        const sortedBookings = data.getBookingsForMerchantClient.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        setBookings(sortedBookings);
      }
    } catch (err) {
      console.log("err get bookings", err);
    }
  };

  const handeGetOrders = async () => {
    try {
      const { data, error } = await getOrdersForMerchantClient({
        fetchPolicy: "cache-and-network",
        variables: {
          merchantClientId,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (error) {
        console.log("err orders", error);
      }

      if (data?.getOrdersForMerchantClient) {
        const sortedOrders = data.getOrdersForMerchantClient.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        setOrders(sortedOrders);
      }
    } catch (err) {
      console.log("err get orders", err);
    }
  };

  const handleGetReviews = async () => {
    try {
      const { data, error } = await getReviewsForMerchantClient({
        fetchPolicy: "cache-and-network",
        variables: {
          merchantClientId,
          // pagination: {
          //   limit: 20,
          //   offset: 0,
          // },
        },
      });

      if (error) {
        console.log("err reviews", error);
      }

      if (data?.getReviewsForMerchantClient) {
        const sortedReviews = data.getReviewsForMerchantClient.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setReviews(sortedReviews);
      }
    } catch (err) {
      console.log("err get reviews", err);
    }
  };

  const handleInit = async () => {
    try {
      await handleGetBookings();
      await handeGetOrders();
      await handleGetReviews();
    } catch (err) {
      console.log("err init", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleItemExtraPress = () => {};

  const displayTab = () => {
    switch (currentTab) {
      case "BOOKINGS":
        return (
          <Box>
            <FlatList
              data={bookings}
              contentContainerStyle={{ paddingBottom: 100 }}
              ListEmptyComponent={
                <Box alignItems="center" justifyContent="center" paddingVertical="s">
                  <CustomText variant="label" color="primaryTextColor">
                    Aucune réservation
                  </CustomText>
                </Box>
              }
              renderItem={({ item }) => (
                <Box
                  marginVertical="s"
                  pb="s"
                  borderBottomColor="lightGrey"
                  borderBottomWidth={LINE_THICKNESS}
                >
                  <BookingListCard
                    item={item}
                    nameFormat={Module_Listing_Client_Format.LastFirstName}
                    dateFormat="dd/MM - HH:mm"
                    isDisabled
                    isStatusChangeDisabled
                    onPress={() => {}}
                    onDelete={() => {}}
                    onTablePress={() => {}}
                    onExtraActionPress={() => handleItemExtraPress()}
                    showActionButtons={false}
                  />
                </Box>
              )}
            />
          </Box>
        );
      case "ORDERS":
        return (
          <Box>
            <FlatList
              contentContainerStyle={{ paddingBottom: 100 }}
              data={orders}
              ListEmptyComponent={
                <Box alignItems="center" justifyContent="center" paddingVertical="s">
                  <CustomText variant="label" color="primaryTextColor">
                    Aucune commande
                  </CustomText>
                </Box>
              }
              renderItem={({ item }) => (
                <Box marginVertical="s">
                  <OnlineOrdersListCard
                    item={item}
                    nameFormat={Module_Listing_Client_Format.LastFirstName}
                    isDisabled
                    dateFormat="dd/MM - HH:mm"
                    onPress={() => {}}
                    onDelete={() => {}}
                    onExtraActionPress={() => handleItemExtraPress()}
                    showActionButtons={false}
                  />
                </Box>
              )}
            />
          </Box>
        );
      case "REVIEWS":
        return (
          <Box>
            <FlatList
              data={reviews}
              contentContainerStyle={{ paddingBottom: 100 }}
              ListEmptyComponent={
                <Box alignItems="center" justifyContent="center" paddingVertical="s">
                  <CustomText variant="label" color="primaryTextColor">
                    Aucun avis
                  </CustomText>
                </Box>
              }
              renderItem={({ item }) => (
                <Box marginVertical="s">
                  <ReviewListCard review={item} onPress={() => {}} />
                </Box>
              )}
            />
          </Box>
        );
      default:
        break;
    }
  };

  if (loading) return <Loader />;
  return (
    <Box paddingHorizontal="s">
      <ListingTabs
        tabs={TABS}
        currentTabName={currentTab}
        onSelectTab={setCurrentTab}
        boxProps={{
          minHeight: 45,
        }}
      />

      <Box mt="s">{displayTab()}</Box>
    </Box>
  );
};

export default ClientHistoryTab;
