import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";

import PHONE from "../../../../assets/icons/BASE/PHONE.svg";
import type { PopCallFragment } from "../../../graphql/generated/schema";
import { useUpdatePopCallMutation } from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import { CustomButton } from "../../Button";

const IS_WEB = Platform.OS === "web";

interface IncomingCallLocalNotificationProps {
  popCall: PopCallFragment;
  handleAckItem: (id: string) => void;
  onClose: () => void;
}

const IncomingCallLocalNotification = ({
  popCall,
  handleAckItem,
  onClose,
}: IncomingCallLocalNotificationProps) => {
  const navigation = useNavigation();
  const { _id: callId, isNewCustomer, merchantClientId, callPhoneNumber } = popCall;

  const [updateCall] = useUpdatePopCallMutation();

  const handleCallAck = async () => {
    try {
      await updateCall({
        variables: {
          popCallId: callId,
          popCall: {
            acknowledged: true,
          },
        },
      });
      handleAckItem(callId);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGoToBooking = async () => {
    try {
      const params = {
        merchantClientId: merchantClientId?._id || "",
        phone: callPhoneNumber || "",
      };
      navigation.navigate("BOOKING", {
        screen: "NEW_BOOKING",
        initial: false,
        params,
        // params: {
        //   screen: "NEW_BOOKING",
        // },
      });

      onClose();

      await handleCallAck();
    } catch (err) {
      console.log("err ack call", err);
    }
  };

  const displayContent = () => {
    if (!isNewCustomer || !merchantClientId) {
      return (
        <Box mt="s" flexDirection="row" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.yellow} />
            <Box ml="s">
              <Box flexDirection="row" alignItems="center">
                <CustomText variant="label" color="primaryTextColor">
                  Nouveau numéro
                </CustomText>
                <CustomText ml="s" variant="description" color="primaryTextColor">
                  ({callPhoneNumber})
                </CustomText>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    const { firstName, lastName, bookingOccurences } = merchantClientId;
    return (
      <>
        <Box mt="s" flexDirection="row" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.yellow} />
            <Box ml="s">
              <Box flexDirection="row" alignItems="center">
                <CustomText variant="label" color="primaryTextColor">
                  {lastName} {firstName}
                </CustomText>
                <CustomText ml="s" variant="description" color="primaryTextColor">
                  ({callPhoneNumber})
                </CustomText>
              </Box>
              <Box mt="s">
                <BookingLIstCardClientFidelity occurences={bookingOccurences} />
              </Box>
            </Box>
          </Box>
        </Box>
        {/*
        <Box mt="s">
          <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
            <CustomText variant="content" color="primaryTextColor">
              Derniere activité
            </CustomText>
          </Box>
          <Box
            pl="s"
            paddingVertical="s"
            flexDirection="row"
            alignItems="center"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <Box mr="m">
              <AGENDA fill={PALETTE.lightGrey} />
            </Box>
            <Box mr="m">
              <CustomText variant="content" color="primaryTextColor">
                19 juil. 2021
              </CustomText>
            </Box>
            <Box mr="m">
              <CustomText variant="content" color="primaryTextColor">
                x6
              </CustomText>
            </Box>
            <Box mr="m">
              <CustomText variant="content" color="primaryTextColor">
                11:30
              </CustomText>
            </Box>
          </Box>
        </Box>

        <Box mt="s">
          <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
            <CustomText variant="content" color="primaryTextColor">
              Infos client
            </CustomText>
          </Box>
          <Box
            pl="s"
            paddingVertical="s"
            flexDirection="row"
            alignItems="center"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
            <Box mr="m" flexDirection="row" alignItems="center">
              <AGENDA fill={PALETTE.lightGrey} />

              <CustomText ml="s" variant="content" color="primaryTextColor">
                8
              </CustomText>
            </Box>
          </Box>
        </Box> */}
      </>
    );
  };

  return (
    <Box
      borderRadius="button"
      p="s"
      backgroundColor="white"
      width="100%"
      alignSelf="flex-end"
      maxWidth={IS_WEB ? 370 : undefined}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="yellow">
          Appel entrant
        </CustomText>
        {/* <CustomText variant="content" color="primaryTextColor">
          Maintenant
        </CustomText> */}
      </Box>

      <Box paddingHorizontal="s">
        {displayContent()}

        <Box mt="m">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={handleGoToBooking}
          >
            <CustomText variant="outlineButtonText" color="success">
              RESERVATION
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default IncomingCallLocalNotification;
