import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import { FlatList, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import Touchable from "../../../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MenuStepFragment } from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  getUID,
} from "../../../../../../utils/common";

const schema = yup.object().shape({
  _id: yup.string().required(),
  name: yup.string().required(),
  description: yup.string(),
  isEnabled: yup.boolean().required(),
  products: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          _id: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
    )
    .min(1)
    .required(),
  selectionSettings: yup
    .object()
    .shape({
      allowDuplicate: yup.boolean().required(),
      authoriseExtraSelection: yup.boolean().required(),
      minSelection: yup.number().min(0).required(),
      maxSelection: yup
        .number()
        .min(0)
        .when("minSelection", {
          is: (minSelection: number) => minSelection > 0,
          then: yup.number().min(yup.ref("minSelection")).required(),
        }),
      maxExtraSelection: yup.number().when("authoriseExtraSelection", {
        is: true,
        then: yup.number().min(1).required(),
      }),
      extraPrice: yup
        .object()
        .shape({
          isEnabled: yup.boolean().required(),
          amount: yup.number().min(0).required(),
        })
        .required(),
    })
    .required(),
});

const MenuStep = () => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const { params } = useRoute();
  const { selectedStep, onSubmit, onDelete } = params;
  const isNew = !selectedStep;
  const [errors, setErrors] = useState<ERROR[]>([]);

  const handleGetDefaultStep = () => {
    return {
      _id: getUID(),
      name: "",
      description: "",
      products: [],
      isEnabled: true,
      selectionSettings: {
        maxSelection: 0,
        minSelection: 0,
        allowDuplicate: false,
        authoriseExtraSelection: false,
        maxExtraSelection: 0,
        extraPrice: {
          amount: 0,
          isEnabled: true,
        },
      },
    };
  };

  const [step, setStep] = useState<MenuStepFragment>(
    selectedStep || handleGetDefaultStep(),
  );

  const updateStepData = (key: keyof MenuStepFragment, value: string | boolean) => {
    setStep(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateStepSelection = (
    key: keyof MenuStepFragment["selectionSettings"],
    value: string | boolean | number,
  ) => {
    setStep(prev => ({
      ...prev,
      selectionSettings: {
        ...prev.selectionSettings,
        [key]: value,
      },
    }));
  };

  const updateStepSelectionPrice = (
    key: keyof MenuStepFragment["selectionSettings"]["extraPrice"],
    value: string | boolean | number,
  ) => {
    setStep(prev => ({
      ...prev,
      selectionSettings: {
        ...prev.selectionSettings,
        extraPrice: {
          ...prev.selectionSettings.extraPrice,
          [key]: value,
        },
      },
    }));
  };

  const handleRemoveProduct = (id: string) => {
    setStep(prev => ({
      ...prev,
      products: prev.products.filter(p => p._id !== id),
    }));
  };

  const handleGoToAddProduct = () => {
    navigation.navigate("CATEGORIES_LIST_DETAILS_ADD_PRODUCT", {
      selectedProducts: step.products,
      onSubmit: p => updateStepData("products", p),
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const validateForm = async () => {
    try {
      await schema.validate(step, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    const id = selectedStep?._id;

    onSubmit(id, step);
    navigation.goBack();
  };

  const handleDelete = () => {
    onDelete(selectedStep._id);
    navigation.goBack();
  };

  const displayTitle = isNew ? "Nouvelle étape" : "Modifier l'étape";

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <ScreenHeader
                title={displayTitle}
                hasBackButton
                onBackPress={navigation.goBack}
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Nom de l'étape"
                hasErrors={doesInputHaveError("name")}
                initialValue={step.name}
                onChangeText={t => updateStepData("name", t)}
                isRequired
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Description de l'étape"
                multiLine
                initialValue={step.description}
                onChangeText={t => updateStepData("description", t)}
                boxProps={{
                  minHeight: 100,
                  alignItems: "flex-start",
                  pt: "s",
                }}
              />
            </Box>
            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Activé
              </CustomText>
              <Toggle
                value={step.isEnabled}
                onChange={value => updateStepData("isEnabled", value)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Minimum
              </CustomText>
              <CustomTextInput
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("selectionSettings.minSelection")}
                value={step.selectionSettings.minSelection.toString()}
                onChangeText={t => updateStepSelection("minSelection", t)}
                onBlur={t =>
                  updateStepSelection("minSelection", getSafeNumberFromInput(t, false))
                }
              />
            </Box>
            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Maximum
              </CustomText>
              <CustomTextInput
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("selectionSettings.maxSelection")}
                value={step.selectionSettings.maxSelection.toString()}
                onChangeText={t => updateStepSelection("maxSelection", t)}
                onBlur={t =>
                  updateStepSelection("maxSelection", getSafeNumberFromInput(t, false))
                }
              />
            </Box>
            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 0.9, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Autoriser un produit à être sélectionné plusieurs fois
              </CustomText>
              <Toggle
                value={step.selectionSettings.allowDuplicate}
                onChange={v => updateStepSelection("allowDuplicate", v)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Dépasser la quantité maximum moyennant un supplément
              </CustomText>
              <Toggle
                value={step.selectionSettings.authoriseExtraSelection}
                onChange={v => updateStepSelection("authoriseExtraSelection", v)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Max extra selection
              </CustomText>
              <CustomTextInput
                placeHolder="1"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("selectionSettings.maxExtraSelection")}
                value={step.selectionSettings.maxExtraSelection.toString()}
                onChangeText={t => updateStepSelection("maxExtraSelection", t)}
                onBlur={t =>
                  updateStepSelection(
                    "maxExtraSelection",
                    getSafeNumberFromInput(t, false),
                  )
                }
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Supplément extra selection
              </CustomText>
              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  placeHolder="Prix"
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("selectionSettings.extraPrice.amount")}
                  value={step.selectionSettings.extraPrice.amount.toString()}
                  onChangeText={t => updateStepSelectionPrice("amount", t)}
                  onBlur={t =>
                    updateStepSelectionPrice("amount", getSafeNumberFromInput(t))
                  }
                />
                {/* <Box ml="s">
                <SelectButton
                  isMultiSelect={false}
                  options={taxSelectOptions}
                  selectedOptions={
                    groupOption.selectionSettings.extraPrice.tax
                      ? [groupOption.selectionSettings.extraPrice.tax]
                      : []
                  }
                  onPress={([tax]) => updateGroupOptionExtraPrieData("tax", tax)}
                  placeHolder="Taxe"
                />
              </Box> */}
              </Box>
            </Box>

            <Box mt="m">
              <CustomText
                mb="s"
                variant="content"
                color={doesInputHaveError("products") ? "danger" : "success"}
                textTransform="uppercase"
              >
                Contenu
              </CustomText>

              <FlatList
                scrollEnabled={false}
                data={step.products}
                style={{ width: "100%" }}
                ListHeaderComponent={
                  <TouchableOpacity onPress={handleGoToAddProduct}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="s"
                      marginVertical="s"
                    >
                      <Box flex={0.95}>
                        <InlineSelectButton
                          title="Ajouter un produit"
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <PLUS fill={PALETTE.green} width={15} height={15} />
                    </Box>
                  </TouchableOpacity>
                }
                renderItem={({ item }) => (
                  <Touchable
                    onPress={() => handleRemoveProduct(item._id)}
                    style={{
                      paddingVertical: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title={item.name}
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <Box p="s">
                      <TRASH fill={PALETTE.red} width={15} height={15} />
                    </Box>
                  </Touchable>
                )}
                keyExtractor={(item, index) => `draggable-item-${index}`}
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MenuStep;
