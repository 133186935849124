/* eslint-disable @typescript-eslint/no-empty-function */

import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import { Image, ScrollView } from "react-native";

import type { PublicationFragment } from "../../../graphql/generated/schema";
import { Publication_Type } from "../../../graphql/generated/schema";
import { convertPublicationType } from "../../../utils/Publication";
import { getWeekdayFromNumber } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ScreenHeader from "../../ScreenHeader";
import Toggle from "../../Toggle";

interface PublicationDetailsViewProps {
  publication: PublicationFragment;
  goBack?: () => void;
}

const IMAGE_HEIGHT = 207;

const PublicationDetailsView = ({ publication, goBack }: PublicationDetailsViewProps) => {
  const navigation = useNavigation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const {
    createdAt,
    title,
    description,
    isPublished,
    isPinned,
    isBoosted,
    cta,
    type,
    tags,
    images,
  } = publication;

  const displayImage = images?.length > 0 ? images[0] : undefined;

  const formattedCreatedAtDate = format(new Date(createdAt), "dd/MM/yyyy");

  const displayAvailability = () => {
    const { visibility } = publication;
    const { isRecurring, days, startDate, endDate, startTime, endTime } = visibility;

    if (isRecurring) {
      const formattedDays = days
        .map(day => {
          const weekDay = getWeekdayFromNumber(day);

          return `${weekDay}: ${startTime} - ${endTime}`;
        })
        .join("\n");

      return formattedDays;
    } else {
      const formattedStartDate = format(new Date(startDate), "dd/MM/yyyy");
      const formattedEndDate = format(new Date(endDate), "dd/MM/yyyy");

      return `${formattedStartDate} - ${formattedEndDate}: ${startTime} - ${endTime}`;
    }
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        paddingBottom: 200,
      }}
      showsVerticalScrollIndicator={false}
    >
      <Box mt="m">
        <ScreenHeader title={title} hasBackButton onBackPress={handleGoBack} />
        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box backgroundColor="yellow" borderRadius="button" p="s">
            <CustomText variant="content" color="white">
              {convertPublicationType(type)}
            </CustomText>
          </Box>

          {/* <Box flexDirection="row" alignItems="center">
            <Box ml="s">
              <FLAME fill={isBoosted ? PALETTE.yellow : PALETTE.lightGrey} />
            </Box>
            <Box ml="s">
              <PIN fill={isPinned ? PALETTE.yellow : PALETTE.lightGrey} />
            </Box>
          </Box> */}
        </Box>

        <Box mt="s">
          <CustomText variant="label" color="primaryTextColor">
            {title}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="content" color="disabled">
            {formattedCreatedAtDate}
          </CustomText>
          <Box flexDirection="row" alignItems="center">
            <CustomText
              mr="m"
              variant="content"
              color={isPublished ? "success" : "disabled"}
            >
              {isPublished ? "Publié" : "Non publié"}
            </CustomText>
            <Toggle disabled onChange={() => {}} value={isPublished} />
          </Box>
        </Box>

        {displayImage && (
          <Box mt="s">
            <Image
              source={{
                uri: displayImage,
              }}
              style={{
                width: "100%",
                height: IMAGE_HEIGHT,
                borderRadius: 8,
              }}
            />
          </Box>
        )}

        <Box mt="s">
          <CustomText variant="content" color="primaryTextColor">
            {description}
          </CustomText>
        </Box>

        <Box mt="s" flexDirection="row" flex={1} alignItems="center" flexWrap="wrap">
          {tags.map(tag => (
            <Box key={tag} mr="s" p="s" borderRadius="button" backgroundColor="disabled">
              <CustomText variant="description" color="primaryTextColor">
                {tag}
              </CustomText>
            </Box>
          ))}
        </Box>

        {displayAvailability() && type !== Publication_Type.NewInfo && (
          <Box mt="s" borderBottomColor="disabled" borderBottomWidth={1}>
            <CustomText variant="label" color="primaryTextColor">
              Periodicité
            </CustomText>
            <Box paddingVertical="s" flexDirection="row" alignItems="center">
              <CustomText variant="content" color="lightGrey">
                {displayAvailability()}
              </CustomText>
            </Box>
          </Box>
        )}

        <Box mt="s" borderBottomColor="disabled" borderBottomWidth={1}>
          <CustomText variant="label" color="primaryTextColor">
            Visibilité - Diffusion
          </CustomText>
          <Box paddingVertical="s" flexDirection="row" alignItems="center">
            <CustomText variant="content" color="lightGrey">
              {publication.isPublished ? "Publié" : "Non publié"}
            </CustomText>
          </Box>
        </Box>
        <Box mt="s" borderBottomColor="disabled" borderBottomWidth={1}>
          <CustomText variant="label" color="primaryTextColor">
            Call to Action
          </CustomText>
          <Box paddingVertical="s" flexDirection="row" alignItems="center">
            <CustomText variant="content" color="lightGrey">
              {cta}
            </CustomText>
          </Box>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default PublicationDetailsView;
