import { getSafeNumberFromInput } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomTextInput } from "../TextInput";

interface MenuStepPriceCardProps {
  name: string;
  price: number;
  updatePrice: (price: number) => void;
}

const MenuStepPriceCard = ({ name, price, updatePrice }: MenuStepPriceCardProps) => {
  return (
    <Box
      marginVertical="s"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <CustomText
        variant="content"
        color="primaryTextColor"
        style={{ flex: 1, flexWrap: "wrap" }}
      >
        {name}
      </CustomText>
      <Box flexDirection="row" alignItems="flex-start">
        <CustomTextInput
          placeHolder="Montant"
          keyboardType="decimal-pad"
          isRequired
          value={price.toString()}
          onChangeText={t => updatePrice(t)}
          onBlur={t => updatePrice(getSafeNumberFromInput(t))}
        />
      </Box>
    </Box>
  );
};

export default MenuStepPriceCard;
