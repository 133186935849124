import type { FieldPolicy } from "@apollo/client";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import { STORAGE_KEYS, getFromStorage } from "../utils/Login";
import { uniquefyCacheData } from "../utils/common";

import config from "./env";

const { GQL_URL, GQL_WS_URL } = config;

const httpLink = new HttpLink({
  uri: GQL_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GQL_WS_URL,
    connectionParams: async () => {
      const token = await getFromStorage(STORAGE_KEYS.API_TOKEN);
      return { token };
    },
  }),
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" && definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

const withToken = setContext(async () => {
  const token = await getFromStorage(STORAGE_KEYS.API_TOKEN);
  return { token };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const { token } = operation.getContext();

  operation.setContext(() => ({
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  }));

  return forward(operation);
});

export const link = ApolloLink.from([withToken, authMiddleware.concat(splitLink)]);

const PAGINATION_CONFIG: FieldPolicy = {
  keyArgs: false,
  read(
    existing,
    {
      args: {
        // Default to returning the entire cached list,
        // if offset and limit are not provided.
        offset = 0,
        limit = existing?.length,
      } = {},
    },
  ) {
    return existing && existing.slice(offset, offset + limit);
  },
  merge(existing = [], incoming = [], { args: { offset = 0 } }) {
    // const merged = existing ? existing.slice(0) : [];
    // for (let i = 0; i < incoming.length; ++i) {
    //   merged[offset + i] = incoming[i];
    // }

    // const unDeleted = existing.filter(item => !item?.isDeleted);
    // const uniqueMerged = merged.filter(item => {
    //   const itemId = item?._id;

    //   if (!itemId) return true;

    //   return !merged.some(item2 => item2._id === itemId);
    // });

    // console.log("existing merge", JSON.stringify(existing, null, 2));
    // console.log("incoming merge", JSON.stringify(incoming, null, 2));

    const merged = uniquefyCacheData([...existing, ...incoming]);

    // console.log(" merged", JSON.stringify(merged, null, 2));

    return merged;

    // return uniqueMerged;
  },
};

export const client = new ApolloClient({
  // uri: serverConfig.gqlUrl,
  // link: concat(authMiddleware, splitLink),
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          //           getBookingsByServices: {
          //             // ...PAGINATION_CONFIG,
          //             read(existing, { args }) {
          //               // console.log("existing read", JSON.stringify(existing, null, 2), args);
          //               return existing;
          //             },
          //             merge(existing = [], incoming = [], { args }) {
          //               // const merged = existing ? existing.slice(0) : [];
          //               // for (let i = 0; i < incoming.length; ++i) {
          //               //   merged[offset + i] = incoming[i];
          //               // }

          //               // const unDeleted = existing.filter(item => !item?.isDeleted);
          //               // const uniqueMerged = merged.filter(item => {
          //               //   const itemId = item?._id;

          //               //   if (!itemId) return true;

          //               //   return !merged.some(item2 => item2._id === itemId);
          //               // });

          //               // console.log("offset", args);

          //               if (args?.pagination?.offset === 0) return incoming;

          //               // console.log("existing merge", JSON.stringify(existing, null, 2));
          //               // console.log("incoming merge", JSON.stringify(incoming, null, 2));

          //               /**
          //                *
          //                * incoming merge [
          //   {
          //     "__typename": "BookingsAndServices",
          //     "serviceId": "64411168086abf61c8271054",
          //     "serviceName": "MIDI",
          //     "startTime": "10:00",
          //     "daysAvailable": [],
          //     "totalInService": 2,
          //     "accumulatedTotalInService": 4,
          //     "slots": [
          //       {
          //         "__typename": "BookingsAndServicesSlot",
          //         "slotId": "6291c063-8e79-4565-a63c-ff42b4c928a7",
          //         "slotTime": "10:00",
          //         "totalInSlot": 1,
          //         "accumulatedTotalInSlot": 2,
          //         "data": [
          //           {
          //             "__ref": "Booking:64623d2e2a72c677af4dc6b0"
          //           }
          //         ]
          //       },
          //       {
          //         "__typename": "BookingsAndServicesSlot",
          //         "slotId": "8db92b63-ff09-4550-9718-2a21018e4c6d",
          //         "slotTime": "10:30",
          //         "totalInSlot": 1,
          //         "accumulatedTotalInSlot": 2,
          //         "data": [
          //           {
          //             "__ref": "Booking:646240222a72c677af532cdd"
          //           }
          //         ]
          //       }
          //     ]
          //   }
          // ]
          //                *
          //                */

          //               let base = [...existing];

          //               incoming.forEach(item => {
          //                 const foundService = base.find(
          //                   service => service.serviceId === item.serviceId,
          //                 );

          //                 if (foundService) {
          //                   base = base.map(s => {
          //                     if (s.serviceId === foundService.serviceId) {
          //                       return {
          //                         ...foundService,
          //                         // totalInService: foundService.totalInService + item.totalInService,
          //                         // accumulatedTotalInService:
          //                         //   foundService.accumulatedTotalInService +
          //                         //   item.accumulatedTotalInService,
          //                         slots: [...foundService.slots, ...item.slots],
          //                       };
          //                     }
          //                     return s;
          //                   });
          //                 } else {
          //                   base.push(item);
          //                 }
          //               });

          //               // console.log("base", JSON.stringify(base, null, 2));

          //               return base;

          //               // const merged = [...existing, ...incoming].filter(
          //               //   (item, index, self) =>
          //               //     index === self.findIndex(t => t.startTime === item.startTime),
          //               // );

          //               // console.log(" merged", JSON.stringify(merged, null, 2));

          //               // return merged;

          //               // return uniqueMerged;
          //             },
          //             keyArgs: ["date"],
          //           },
          getOrdersAndServices: {
            read(existing, { args }) {
              return existing;
            },
            merge(existing = [], incoming = [], { args }) {
              if (args?.pagination?.offset === 0) return incoming;

              let base = [...existing];

              incoming.forEach(item => {
                const foundService = base.find(
                  service => service.serviceId === item.serviceId,
                );

                if (foundService) {
                  base = base.map(s => {
                    if (s.serviceId === foundService.serviceId) {
                      return {
                        ...foundService,
                        totalInService: foundService.totalInService + item.totalInService,
                        accumulatedTotalInService:
                          foundService.accumulatedTotalInService +
                          item.accumulatedTotalInService,
                        slots: [...foundService.slots, ...item.slots],
                      };
                    }
                    return s;
                  });
                } else {
                  base.push(item);
                }
              });

              return base;
            },
            keyArgs: ["date"],
          },
          getBookingAvailabilities: {
            ...PAGINATION_CONFIG,
          },
          // getPendingBookingsFromDate: {
          //   ...PAGINATION_CONFIG,
          // },
          // getBookingFloors: {
          //   ...PAGINATION_CONFIG,
          // },
          getMerchantPersonnels: {
            ...PAGINATION_CONFIG,
          },
          getMerchantPersonnelPermissionGroups: {
            ...PAGINATION_CONFIG,
          },
          getPrinters: {
            ...PAGINATION_CONFIG,
          },
          getProductGroupOptions: {
            ...PAGINATION_CONFIG,
          },
          getProductOptions: {
            ...PAGINATION_CONFIG,
          },
          getProducts: {
            ...PAGINATION_CONFIG,
          },
          // getProductCategories: {
          //   ...PAGINATION_CONFIG,
          // },
          getCombinedCategories: {
            ...PAGINATION_CONFIG,
          },
          getMenus: {
            ...PAGINATION_CONFIG,
          },
          getTaxes: {
            ...PAGINATION_CONFIG,
          },
          getMerchantDiscounts: {
            ...PAGINATION_CONFIG,
          },
          getPricingRates: {
            ...PAGINATION_CONFIG,
          },
          getMerchantClients: {
            ...PAGINATION_CONFIG,
          },
          getMerchantClientCompanies: {
            ...PAGINATION_CONFIG,
          },
          getMerchantClientPrescribers: {
            ...PAGINATION_CONFIG,
          },
          getBookingFloorsWithBookings: {
            ...PAGINATION_CONFIG,
          },
          getStockMovements: {
            ...PAGINATION_CONFIG,
          },
          MerchantClient: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  }),
});
