import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { Image, ScrollView } from "react-native";

import BURGER from "../../../../../../../assets/icons/BASE/BURGER.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import VALIDATION from "../../../../../../../assets/icons/BASE/VALIDATION.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { CombinedCategoryFragment } from "../../../../../../graphql/generated/schema";
import { useGetCombinedCategoryLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";

interface CombinedCategoryListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const CombinedCategoryListDetailsView = ({
  id,
  goBack,
}: CombinedCategoryListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<CombinedCategoryFragment | null>(null);

  const [getCategory] = useGetCombinedCategoryLazyQuery();

  const handleGetCategory = async () => {
    try {
      const { data, error } = await getCategory({
        variables: {
          combinedCategoryId: id,
        },
      });

      if (data?.getCombinedCategory) {
        setCategory(data.getCombinedCategory);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetCategory();
    }, []),
  );

  if (loading) {
    return <Loader />;
  }

  if (!category) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue lors de la récupération de la catégorie combinée." />
      </Box>
    );
  }

  const { name, description, isEnabled, categories, picture } = category;

  const displayCategories = categories.map(c => c.name).join("\n");

  const displayImage = () => {
    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  return (
    <Box flex={1}>
      <ScreenHeader
        title={name}
        rightButtons={displayImage()}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />
      {/* <Box
        mt="s"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="disabled"
        padding="s"
        borderRadius="button"
        width={80}
      >
        <PAX_PERSONS height={15} width={15} fill={PALETTE.green} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          -1 / -1
        </CustomText>
      </Box> */}

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        <TabTextInfo
          {...{
            title: "Description",
            content: description || "-",
            icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
        <TabTextInfo
          {...{
            title: "Contenu de la catégorie combinée",
            content: displayCategories,
            icon: (
              <BURGER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        <TabTextInfo
          {...{
            title: "Indisponible",
            content: isEnabled ? "Non" : "Oui",
            icon: (
              <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default CombinedCategoryListDetailsView;
