import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Alert } from "react-native";

import BLACKLISTED from "../../../../assets/icons/BASE/BLACKLISTED.svg";
import COMMENT from "../../../../assets/icons/BASE/COMMENT.svg";
import MESSAGE from "../../../../assets/icons/BASE/MESSAGE.svg";
import MORE_DOTS from "../../../../assets/icons/BASE/MORE_DOTS.svg";
import VIP from "../../../../assets/icons/BASE/VIP.svg";
import type { OrderFragment } from "../../../graphql/generated/schema";
import {
  Click_Status,
  Module_Listing_Client_Format,
  useUpdateOrderMutation,
} from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { displayModuleId, formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import OrderActionsModal from "../../OnlinOrdersListDetails/OrderActionsModal/index";
import Touchable from "../../Touchable";
import OnlineOrderCardPaymentType from "../OnlineOrderCardPaymentType";
import OnlineOrderStatusButton from "../OnlineOrderStatusButton";
import { ONLINE_STATUS_LAYOUT_TYPE } from "../OnlineOrderStatusButtonItem";
interface OnlineOrdersListCardProps {
  item: OrderFragment;
  isDisabled?: boolean;
  onPress: (itemId: string) => void;
  onExtraActionPress: (itemId: string) => void;
  onDelete: (itemId: string) => void;
  nameFormat: Module_Listing_Client_Format;
  showClientComment?: boolean;
  showCreationDate?: boolean;
  showEventType?: boolean;
  onCompleteHandler?: (itemId: string) => void;
  onErrorHandler?: (itemId: string) => void;
  dateFormat?: string;
  showActionButtons?: boolean;
}

const HEIGHT = 92;
const ICON_SIZE = 16;

const OnlineOrdersListCard = ({
  item,
  isDisabled = false,
  onPress,
  onExtraActionPress,
  onDelete,
  nameFormat,
  showClientComment = true,
  showCreationDate = true,
  showEventType = true,
  onCompleteHandler,
  onErrorHandler,
  dateFormat = "HH:mm",
  showActionButtons = true,
}: OnlineOrdersListCardProps) => {
  const [order, setOrder] = useState(item);
  const {
    _id: itemId,
    createdAt,
    source,
    status,
    merchantClient: client,
    payment,
    date,
    items,
    totalDetails,
    commentOwner,
    comment,
    orderQuantity,
  } = order;

  const [cardContainer, setCardContainer] = useState({ width: 0, height: 0, x: 0, y: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const formattedDate = format(new Date(date), dateFormat);
  const formattedCreatedDate = format(new Date(createdAt), "dd/MM/yyyy - HH:mm");

  // const formattedClientName = `${firstName} ${lastName}`;
  const formattedTotalAmount = formatCurrencyPrice(totalDetails.total, "fr", "eur");

  const [updateOrder] = useUpdateOrderMutation();

  useEffect(() => {
    setOrder(item);
  }, [item]);

  const displayClientName = () => {
    if (nameFormat === Module_Listing_Client_Format.LastFirstName) {
      const formattedClientName = `${client.lastName} ${client.firstName}`;
      return formattedClientName;
    } else if (nameFormat === Module_Listing_Client_Format.FirstLastName) {
      return `${client.firstName} ${client.lastName}`;
    } else if (nameFormat === Module_Listing_Client_Format.LastName) {
      return `${client.lastName}`;
    } else {
      return `${client.firstName}`;
    }
  };

  const handleOrderStatusChange = async (
    newStatus: Click_Status,
    refusalReason: string,
  ) => {
    try {
      const { data } = await updateOrder({
        variables: {
          orderId: itemId,
          order: {
            status: newStatus,
            refusalReason,
            sendClientNotifications: true,
          },
        },
      });

      if (data?.updateOrderForPro) {
        setOrder(data?.updateOrderForPro);
        onCompleteHandler?.(itemId);
      }
    } catch (err) {
      onErrorHandler?.(itemId);
      console.log("err updating order", err);

      Alert.alert("Erreur", "Une erreur est survenue " + err.message || "");
    }
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        onDelete(order._id);
        // await handleDeleteBooking();
      } else if (key === "DUPLICATE") {
        // await setIsDuplicateCalendarModalOpen(true);
      } else if (key === "MODIFY") {
        // await handleModifyValidateButton();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCardPress = () => {
    if (isStatusModalOpen) return;

    onPress(itemId);
  };

  const determineOrderQty = () => {
    return `x${orderQuantity}`;
  };

  const determinePaymentStatus = (): THEME_COLORS => {
    const { paid } = payment;

    const IS_CANCELLED =
      status === Click_Status.Noshow ||
      status === Click_Status.AutomaticCancel ||
      status === Click_Status.CancelledByOwner ||
      status === Click_Status.CancelledByUser;

    const IS_REFUNDED = status === Click_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "redBackground";

    return paid ? "greenBackground" : "orangeBackground";
  };

  const displayExtraActions = () => {
    return (
      <OrderActionsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        order={order}
        handleSubmit={handleOnSubmit}
      />
    );
  };

  const ICON_VIEW_TYPE =
    cardContainer.width >= 600
      ? ONLINE_STATUS_LAYOUT_TYPE.TEXT
      : ONLINE_STATUS_LAYOUT_TYPE.ICON;

  return (
    <Box
      flex={1}
      onLayout={e => setCardContainer(e.nativeEvent.layout)}
      flexDirection="row"
      alignItems="center"
    >
      <Touchable
        containerStyle={{ flex: 1 }}
        disabled={isDisabled}
        style={{ flex: 1 }}
        onPress={handleCardPress}
      >
        <Box>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flex={0.4}>
              <CustomText variant="label" numberOfLines={1}>
                {displayClientName()}
              </CustomText>
            </Box>
            <Box
              flex={0.6}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" pr="xs">
                {determineOrderQty()}
              </CustomText>
              <CustomText variant="content">{formattedDate}</CustomText>
              <CustomText variant="label">{formattedTotalAmount}</CustomText>

              <OnlineOrderStatusButton
                status={status}
                type={ICON_VIEW_TYPE}
                onPress={handleOrderStatusChange}
                isDisabled={isDisabled}
                payment={payment}
                setIsOpen={setIsStatusModalOpen}
              />
            </Box>
            {!isDisabled && showActionButtons && (
              <Touchable onPress={() => setIsModalOpen(true)}>
                <MORE_DOTS width={ICON_SIZE} fill={PALETTE.black} />
              </Touchable>
            )}
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Box mr="m">
              <BookingLIstCardClientFidelity occurences={client?.orderOccurences} />
            </Box>
            <Box mr="s">
              <BookingListCardOrigin origin={source} />
            </Box>
            {comment && (
              <Box mr="s">
                <COMMENT width={15} height={15} fill={PALETTE.darkBlue} />
              </Box>
            )}
            {commentOwner && (
              <Box mr="s">
                <MESSAGE fill={PALETTE.darkBlue} />
              </Box>
            )}
            {/* {eventType && eventType !== Event_Type.None && (
              <Box mr="s">
                <EVENT fill={PALETTE.darkBlue} />
              </Box>
            )} */}
            {client?.isVIP && (
              <Box mr="s">
                <VIP width={15} height={15} fill={PALETTE.green} />
              </Box>
            )}
            {client?.isBlacklisted && (
              <Box mr="s">
                <BLACKLISTED fill={PALETTE.red} />
              </Box>
            )}
            <Box backgroundColor={determinePaymentStatus()} p="s" borderRadius="icon">
              <OnlineOrderCardPaymentType payment={payment} status={status} />
            </Box>
            {/* {payment?.paid && (
              <Box mr="s">
                <CREDIT_CARD
                  width={12}
                  height={12}
                  fill={payment?.paid ? PALETTE.green : PALETTE.orange}
                />
              </Box>
            )} */}
          </Box>

          {showClientComment && comment && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Commentaire du client:
                <CustomText numberOfLines={1} variant="text" color="lightGrey">
                  {" "}
                  {comment}
                </CustomText>
              </CustomText>
            </Box>
          )}

          {commentOwner && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Note sur la commande:
                <CustomText numberOfLines={1} variant="text" color="lightGrey">
                  {" "}
                  {commentOwner}
                </CustomText>
              </CustomText>
            </Box>
          )}
          {/* {eventType && eventType !== Event_Type.None && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Évènement:
                <CustomText variant="text" color="lightGrey">
                  {" "}
                  {eventType}
                </CustomText>
              </CustomText>
            </Box>
          )} */}

          {showCreationDate && (
            <Box flexDirection="row" alignItems="center" mt="s">
              <CustomText variant="text" color="lightGrey">
                Commande créé le {formattedCreatedDate} - {displayModuleId(itemId)}
              </CustomText>
            </Box>
          )}
        </Box>
      </Touchable>

      {displayExtraActions()}
    </Box>
  );
};

export default OnlineOrdersListCard;
