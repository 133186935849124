import { format } from "date-fns";

import AGENDA_ADD from "../../../../../../assets/icons/BASE/AGENDA_ADD.svg";
import AGENDA_EDIT from "../../../../../../assets/icons/BASE/AGENDA_EDIT.svg";
import CASH from "../../../../../../assets/icons/BASE/CASH.svg";
import COMMENT from "../../../../../../assets/icons/BASE/COMMENT.svg";
import type { OrderFragment } from "../../../../../graphql/generated/schema";
import { Click_Status, Payment_Type } from "../../../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../../../theme";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import Box from "../../../../Base/Box";
import { CustomText } from "../../../../Base/Text";
import TabTextInfo from "../../../../BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import DisplayOrderItems from "../../../DisplayOrderItems";

interface OnlineOrderDetailsViewTabProps {
  order: OrderFragment;
}

const OnlineOrderDetailsViewTab = ({ order }: OnlineOrderDetailsViewTabProps) => {
  const { commentOwner, comment, payment, items, status, createdAt, updatedAt } = order;

  const formattedUpdatedDate = format(new Date(updatedAt), "dd/MM/yyyy HH:mm");
  const formattedCreatedDate = format(new Date(createdAt), "dd/MM/yyyy HH:mm");

  const determinePaymentColor = (): THEME_COLORS => {
    const { paid } = payment;

    const IS_CANCELLED =
      status === Click_Status.Noshow ||
      status === Click_Status.AutomaticCancel ||
      status === Click_Status.CancelledByOwner ||
      status === Click_Status.CancelledByUser;

    const IS_REFUNDED = status === Click_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "danger";

    return paid ? "success" : "orange";
  };

  const determinePaymentType = (): string => {
    if (payment.paymentType === Payment_Type.Card) {
      return "Carte bancaire";
    } else if (payment.paymentType === Payment_Type.Cash) {
      return "Espèces";
    }
    return "-";
  };

  const determinePaymentStatus = (): string => {
    if (payment.paymentType === Payment_Type.Card) {
      if (status === Click_Status.Refunded) return "Remboursé";

      if (
        status === Click_Status.CancelledByOwner ||
        status === Click_Status.CancelledByUser ||
        status === Click_Status.Noshow
      )
        return "Non prélevé";

      if (payment.paid) return "Payé";

      return "En att. de validation";
    } else if (payment.paymentType === Payment_Type.Cash) {
      if (payment.paid) return "Payé";

      return "A encaisser";
    }
    return "-";
  };

  return (
    <Box>
      <TabTextInfo
        {...{
          title: "Mode de paiement",
          content: determinePaymentType(),
          rightIcon: (
            <Box
              backgroundColor={determinePaymentColor()}
              borderRadius="icon"
              p="s"
              alignItems="center"
              justifyContent="center"
              minWidth={100}
            >
              <CustomText variant="description" color="white">
                {determinePaymentStatus()}
              </CustomText>
            </Box>
          ),
          icon: <CASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />,
        }}
      />

      {comment && (
        <TabTextInfo
          {...{
            title: "Commentaire client",
            content: comment,
            icon: (
              <COMMENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
            ),
          }}
        />
      )}

      {commentOwner && (
        <TabTextInfo
          {...{
            title: "Note de la commande",
            content: commentOwner,
            icon: (
              <COMMENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
            ),
          }}
        />
      )}

      <DisplayOrderItems title="Détails de la commande" items={items} />

      <TabTextInfo
        {...{
          title: "Date de modification",
          content: formattedUpdatedDate,
          icon: (
            <AGENDA_EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
      <TabTextInfo
        {...{
          title: "Date de création",
          content: formattedCreatedDate,
          icon: (
            <AGENDA_ADD height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
    </Box>
  );
};

export default OnlineOrderDetailsViewTab;
