import { StyleSheet, TouchableOpacity } from "react-native";

import CANCEL from "../../../../../assets/icons/BASE/CANCEL.svg";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomButton } from "../../../Button";
import Modal from "../../../Modal";

export enum NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE {
  BASIC_LINE,
  PRODUCT_LINE,
  COMMENT_LINE,
}

interface NewQuoteLineSelectionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onPress: (actionType: NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE) => void;
}

const styles = StyleSheet.create({});

const NewQuoteLineSelectionModal = ({
  onPress,
  isVisible,
  onClose,
}: NewQuoteLineSelectionModalProps) => {
  return (
    <Modal onBackdropPress={onClose} isVisible={isVisible}>
      <Box
        flex={1}
        pb="xl"
        borderRadius="button"
        height="100%"
        width="100%"
        p="s"
        paddingHorizontal="m"
        backgroundColor="white"
      >
        <Box
          marginVertical="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="paragraph" color="success">
            Ajouter une autre ligne
          </CustomText>
          <TouchableOpacity onPress={onClose}>
            <CANCEL height={20} width={20} fill={PALETTE.red} />
          </TouchableOpacity>
        </Box>

        <Box mt="s">
          <CustomButton
            onPress={() => onPress(NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.BASIC_LINE)}
            buttonVariant="outlineButton"
            borderColor="lightGrey"
          >
            <CustomText variant="label" color="primaryTextColor">
              Ligne simple
            </CustomText>
          </CustomButton>
        </Box>
        {/* <Box mt="s">
          <CustomButton
            onPress={() =>
              onPress(NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.PRODUCT_LINE)
            }
            buttonVariant="outlineButton"
            borderColor="lightGrey"
            styles={{
              minHeight: 45,
            }}
          >
            <CustomText variant="label" color="primaryTextColor">
              Ligne de prestation
            </CustomText>
          </CustomButton>
        </Box> */}
        <Box mt="s">
          <CustomButton
            onPress={() =>
              onPress(NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.COMMENT_LINE)
            }
            buttonVariant="outlineButton"
            borderColor="lightGrey"
          >
            <CustomText variant="label" color="primaryTextColor">
              Ligne de commentaire
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewQuoteLineSelectionModal;
