import { ActivityIndicator } from "react-native-paper";
import type { Theme } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

interface BottomButtonProps {
  onPress: () => void;
  title: string;
  variant?: keyof Theme["buttonVariants"];
  color?: keyof Theme["colors"];
  minHeight?: number;
  isDisabled?: boolean;
  styles?: React.ComponentProps<typeof Box>;
  isLoading?: boolean
}

const BottomButton = ({
  title,
  onPress,
  variant = "primaryButton",
  color = "success",
  minHeight,
  isDisabled = false,
  styles,
  isLoading = false,
}: BottomButtonProps) => {
  const getButtonColor = (): keyof Theme["colors"] => {
    if (isDisabled) return "lightGrey";

    return variant === "primaryButton" ? color : "transparent";
  };
  return (
    <Box
      borderRadius="button"
      backgroundColor="white"
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      p="s"
      pb="xl"
      alignSelf="center"
      {...styles}
    >
      <CustomButton
        disabled={isDisabled}
        buttonVariant={variant}
        buttonColor={getButtonColor()}
        borderColor={variant === "outlineButton" ? color : "transparent"}
        onPress={onPress}
        styles={{ minHeight: 45 }}
      >
        {isLoading ?
          <ActivityIndicator color="#fff" />
          :
          <CustomText
            variant="primaryButtonText"
            color={variant === "primaryButton" ? "white" : color}
          >
            {title}
          </CustomText>
        }
      </CustomButton>
    </Box>
  );
};

export default BottomButton;
