import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { TAB_CONFIG } from "../TabsConfig";

import AlertSummary from "./AlertSummary";
import BookingAlerts from "./BookingAlerts";
import OrderAlerts from "./OrderAlerts";
import type { AlertTabsParamList } from "./ParamList";

const Tab = createMaterialTopTabNavigator<AlertTabsParamList>();

function AlertTabs() {
  return (
    <Tab.Navigator
      sceneContainerStyle={{ overflow: "visible" }}
      screenOptions={TAB_CONFIG}
    >
      <Tab.Screen
        options={{ title: "Résumé" }}
        name="ALERTS_SUMMARY_TAB"
        component={AlertSummary}
      />
      <Tab.Screen
        options={{ title: "Résas" }}
        name="ALERTS_BOOKINGS_TAB"
        component={BookingAlerts}
      />
      <Tab.Screen
        options={{ title: "Commandes" }}
        name="ALERTS_ORDER_TABS"
        component={OrderAlerts}
      />
      {/* <Tab.Screen
        options={{ title: "Messages" }}
        name="MESSAGES_TAB"
        component={MessageStack}
      /> */}
    </Tab.Navigator>
  );
}

export default AlertTabs;
