import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import PRICE_TAG from "../../../../../assets/icons/BASE/PRICE_TAG.svg";
import SHOPPING_BAG from "../../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import type { GetTotalOnlineOrdersRecapQuery } from "../../../../graphql/generated/schema";
import { useGetTotalOnlineOrdersRecapLazyQuery } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../utils/common";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

const OrderTotalRecap = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orderStats, setOrderStats] = useState<
    GetTotalOnlineOrdersRecapQuery["getTotalOnlineOrdersRecap"] | null
  >(null);

  const [getOrdersRecap] = useGetTotalOnlineOrdersRecapLazyQuery();

  const handleGetBookingsTotalRecap = async () => {
    try {
      const { data } = await getOrdersRecap();

      if (data?.getTotalOnlineOrdersRecap) {
        setOrderStats(data.getTotalOnlineOrdersRecap);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, []);

  if (isLoading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!orderStats) return null;

  return (
    <Box flex={1}>
      <Box mb="m">
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Récap depuis le début
        </CustomText>
      </Box>

      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="disabled"
        borderRadius="button"
        minHeight={70}
        p="s"
      >
        <Box>
          <CustomText variant="label" fontSize={24} color="primaryTextColor">
            {formatCurrencyPrice(orderStats.totalOrdersAmount, "fr", "eur")} /{" "}
            {orderStats.totalOrders}
          </CustomText>
          <CustomText variant="content" color="primaryTextColor">
            C.A / Commandes
          </CustomText>
        </Box>

        <Box
          p="s"
          alignItems="center"
          justifyContent="center"
          backgroundColor="success"
          style={{ borderRadius: 25 }}
        >
          <SHOPPING_BAG width={18} height={18} fill={PALETTE.white} />
        </Box>
      </Box>

      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="disabled"
        borderRadius="button"
        minHeight={70}
        p="s"
        mt="s"
      >
        <Box>
          <CustomText variant="label" fontSize={24} color="primaryTextColor">
            {formatCurrencyPrice(orderStats.averageBasketAmount, "fr", "eur")}
          </CustomText>
          <CustomText variant="content" color="primaryTextColor">
            Panier moyen
          </CustomText>
        </Box>

        <Box
          p="s"
          alignItems="center"
          justifyContent="center"
          backgroundColor="success"
          style={{ borderRadius: 25 }}
        >
          <PRICE_TAG width={18} height={18} fill={PALETTE.white} />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderTotalRecap;
