import React from "react";

import { TABLE_SIZE } from "../../../constants/BookingFloor";
import type { DecorationFragment } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { DECORATIONS } from "../common/DecorationList";

interface DecorationProps {
  decoration: DecorationFragment;
  scale?: number;
  isSelected?: boolean;
}
const Decoration = ({ decoration, isSelected, scale = 1 }: DecorationProps) => {
  const tableIcon = DECORATIONS.find(t => {
    return t.name === decoration.type;
  })?.icon;

  const TABLE_WIDTH = TABLE_SIZE * scale + 10;
  const TABLE_HEIGHT = TABLE_SIZE * scale + 10;

  return (
    <Box
      p="xs"
      borderColor="success"
      borderRadius="button"
      width={TABLE_WIDTH}
      height={TABLE_HEIGHT}
      borderWidth={isSelected ? 2 : 0}
      alignItems="center"
      justifyContent="center"
    >
      {tableIcon &&
        React.cloneElement(tableIcon, {
          fill: PALETTE.lightGrey,
          transform: [
            {
              scale,
            },
            {
              rotate: `${decoration?.rotationDeg}deg`,
            },
          ],
        })}
    </Box>
  );
};

export default Decoration;
