import { createNativeStackNavigator } from "@react-navigation/native-stack";

import AddressDetails from "../../../components/AddressDetails";
import ListMultiSelect from "../../../components/ListMultiSelect";
import BookingFloors from "../../../screens/Booking/BookingDetailsFloorsTableSelection";
import BookingList from "../../../screens/Booking/BookingList";
import BookingListDetails from "../../../screens/Booking/BookingListDetails";
import BookingListFloorCapacityConfiguration from "../../../screens/Booking/BookingListFloorCapacityConfiguration";
import NewBooking from "../../../screens/Booking/NewBooking";
import NewLocalBooking from "../../../screens/Booking/NewLocalBooking";

import type { BookingStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<BookingStackParamList>();

const BookingStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
        // header: () => <MyTabs />,
      }}
      initialRouteName="BOOKINGS_LIST"
    >
      <Stack.Screen name="BOOKINGS_LIST" component={BookingList} />
      <Stack.Screen name="BOOKINGS_LIST_DETAILS" component={BookingListDetails} />

      {/* <Stack.Group screenOptions={{ presentation: "modal" }}> */}
      <Stack.Screen name="NEW_BOOKING" component={NewBooking} />
      <Stack.Screen name="NEW_LOCAL_BOOKING" component={NewLocalBooking} />
      <Stack.Screen name="BOOKING_FLOORS" component={BookingFloors} />
      <Stack.Screen
        name="BOOKINGS_LIST_FLOOR_CAPACITY_EDIT"
        component={BookingListFloorCapacityConfiguration}
      />
      {/* </Stack.Group> */}
      {/* <Stack.Screen name="BOOKING_AGENDA" component={BookingList} /> */}
      {/* <Stack.Screen name="BOOKING_SCHEDULE" component={BookingList} /> */}

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="LIST_SELECT" component={ListMultiSelect} />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="ADDRESS_DETAILS" component={AddressDetails} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default BookingStack;
