 

import type { OrderFragment } from "../../../graphql/generated/schema";
import { Click_Status } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import ButtonActionsListModal from "../../BottomButtomWithActions/ButtonActionsListModal";
import type { BUTTON_ACTION } from "../../BottomButtomWithActions/index";
import ContentModal from "../../ContentModal";

interface OrderActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (key: string) => void;
  handleCancel?: () => void;
  order: OrderFragment;
}

const OrderActionsModal = ({
  isOpen,
  onClose,
  handleSubmit,
  order,
  handleCancel,
}: OrderActionsModalProps) => {
  const { status } = order;
  const getButtonActions = () => {
    if (status === Click_Status.Refunded) return [];

    const BUTTON_ACTIONS: BUTTON_ACTION[] = [];

    const CAN_DELETE =
      status === Click_Status.CancelledByOwner || status === Click_Status.CancelledByUser;

    if (CAN_DELETE) {
      BUTTON_ACTIONS.unshift({
        title: "SUPPRIMER LA COMMANDE",
        key: "DELETE",
        variant: "outline",
        color: "danger",
      });
    }

    if (handleCancel) {
      BUTTON_ACTIONS.push({
        title: "ANNULER",
        key: "CANCEL",
        variant: "primary",
        color: "danger",
      });
    }

    return BUTTON_ACTIONS;
  };

  const handleClose = () => {
    onClose();
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        handleSubmit(key);
        handleClose();
      } else if (key === "MODIFY") {
        handleSubmit(key);
        handleClose();
      } else if (key === "CANCEL") {
        handleCancel?.();
        handleClose();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <ContentModal title="" isVisible={isOpen} onClose={handleClose}>
      <Box>
        <ButtonActionsListModal actions={getButtonActions()} onPress={handleOnSubmit} />
      </Box>
    </ContentModal>
  );
};

export default OrderActionsModal;
