import { createNativeStackNavigator } from "@react-navigation/native-stack";

import InvoicesList from "../../../screens/Accounting/Invoices/InvoicesList";
import NewQuote from "../../../screens/Accounting/Quotes/NewQuote";
import QuotesListDetails from "../../../screens/Accounting/Quotes/QuotesListDetails";

import type { InvoicesStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<InvoicesStackParamList>();

const InvoicesStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
        // header: () => <MyTabs />,
      }}
      initialRouteName="INVOICES_LIST"
    >
      <Stack.Screen name="INVOICES_LIST" component={InvoicesList} />
      <Stack.Screen name="INVOICES_LIST_DETAILS" component={QuotesListDetails} />

      {/* <Stack.Group screenOptions={{ presentation: "modal" }}> */}
      <Stack.Screen name="NEW_INVOICE" component={NewQuote} />
      {/* </Stack.Group> */}
      {/* <Stack.Screen name="BOOKING_AGENDA" component={BookingList} /> */}
      {/* <Stack.Screen name="BOOKING_SCHEDULE" component={BookingList} /> */}
    </Stack.Navigator>
  );
};

export default InvoicesStack;
