import type { ImagePickerAsset } from "expo-image-picker";

import { pickImageFromGallery, takePictureFromCamera } from "../../utils/Image";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";

interface AddNewImageModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (image?: ImagePickerAsset) => void;
}

const AddNewImageModal = ({ isOpen, setIsOpen, onSubmit }: AddNewImageModalProps) => {
  const handleClose = (image?: ImagePickerAsset) => {
    setIsOpen(false);
    onSubmit(image);
  };

  const handleImageGallery = async () => {
    try {
      const result = await pickImageFromGallery();

      const image =
        result.assets && result.assets.length > 0 ? result.assets[0] : undefined;

      if (!result.canceled) handleClose(image);

      handleClose();
    } catch (err) {
      console.log("err gallery", err);
    }
  };

  const handleImageCamera = async () => {
    try {
      const result = await takePictureFromCamera();

      const image =
        result.assets && result.assets.length > 0 ? result.assets[0] : undefined;

      if (!result.canceled) handleClose(image);

      handleClose();
    } catch (err) {
      console.log("err gallery", err);
    }
  };

  return (
    <ContentModal isVisible={isOpen} onClose={handleClose} title="Ajouter une image">
      <Box>
        <Box mt="m">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={handleImageCamera}
          >
            <CustomText variant="outlineButtonText" color="success">
              Prendre une photo
            </CustomText>
          </CustomButton>
        </Box>
        <Box mt="m">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={handleImageGallery}
          >
            <CustomText variant="outlineButtonText" color="success">
              Choisir une photo
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default AddNewImageModal;
