import { Print_Selected_Field } from "../graphql/generated/schema";

export const getLabelForPrintSelectedField = (type: Print_Selected_Field) => {
  switch (type) {
    case Print_Selected_Field.Hour:
      return "Heure";
    case Print_Selected_Field.Client:
      return "Client";
    case Print_Selected_Field.Table:
      return "Table";
    case Print_Selected_Field.Persons:
      return "Nombre de personnes";
    case Print_Selected_Field.OrderAmount:
      return "Montant";
    case Print_Selected_Field.Status:
      return "Statut";
    case Print_Selected_Field.CreatedDate:
      return "Date de création";
    case Print_Selected_Field.UpdatedDate:
      return "Date de modification";
    case Print_Selected_Field.Origin:
      return "Origine";
    case Print_Selected_Field.ClientPhone:
      return "Téléphone";
    case Print_Selected_Field.Floor:
      return "Salle";
    case Print_Selected_Field.CommentOwner:
      return "Note";
    case Print_Selected_Field.CommentClient:
      return "Commentaire client";
    case Print_Selected_Field.OrderQuantity:
      return "Quantité";

    default:
      break;
  }
  return "-";
};
