import DraggableFlatList from "react-native-draggable-flatlist";

import PLUS from "../../../../assets/icons/BASE/PLUS.svg";
import type {
  BookingPdfPrintTemplateFragment,
  PrintTempateSelectedField,
} from "../../../graphql/generated/schema";
import { Print_Selected_Field } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import { getLabelForPrintSelectedField } from "../../../utils/PdfTemplates";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import Touchable from "../../Touchable";
import SelectedFieldItem from "../SelectedFieldItem/index";

interface SelectedFieldItemsProps {
  pdfTemplate: Omit<BookingPdfPrintTemplateFragment, "_id" | "createdAt" | "updatedAt">;
  onChange: (
    updatedItem: Omit<BookingPdfPrintTemplateFragment, "_id" | "createdAt" | "updatedAt">,
  ) => void;
}

const SelectedFieldItems = ({ pdfTemplate, onChange }: SelectedFieldItemsProps) => {
  const getUnSelectedFields = () => {
    const BASE_SELECTED_FIELDS = [
      Print_Selected_Field.Hour,
      Print_Selected_Field.Client,
      Print_Selected_Field.Status,
      Print_Selected_Field.Persons,
      Print_Selected_Field.Table,
      Print_Selected_Field.Origin,
      Print_Selected_Field.CreatedDate,
      Print_Selected_Field.UpdatedDate,
    ];

    return BASE_SELECTED_FIELDS.map(field => ({
      label: getLabelForPrintSelectedField(field),
      key: field,
    })).filter(({ key }) => {
      return !pdfTemplate.selectedFields.find(item => item.field === key);
    });
  };

  const handleAddSelectedFieldItem = (fieldType: string) => {
    onChange({
      ...pdfTemplate,
      selectedFields: [
        ...pdfTemplate.selectedFields,
        {
          field: fieldType as Print_Selected_Field,
          fontSize: 12,
        },
      ],
    });
  };

  const handleUpdateSelectedFieldItem = (
    fieldType: Print_Selected_Field,
    updatedItem: PrintTempateSelectedField,
  ) => {
    onChange({
      ...pdfTemplate,
      selectedFields: pdfTemplate.selectedFields.map(item => {
        if (item.field === fieldType) {
          return updatedItem;
        }
        return item;
      }),
    });
  };

  const handleRemoveSelectedFieldItem = (fieldType: Print_Selected_Field) => {
    onChange({
      ...pdfTemplate,
      selectedFields: pdfTemplate.selectedFields.filter(item => item.field !== fieldType),
    });
  };

  const handleSortSelectedFieldItems = (selectedFields: PrintTempateSelectedField[]) => {
    onChange({
      ...pdfTemplate,
      selectedFields,
    });
  };

  return (
    <Box>
      {getUnSelectedFields().map(({ label, key }) => {
        return (
          <Touchable key={key} onPress={() => handleAddSelectedFieldItem(key)}>
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingHorizontal="s"
              backgroundColor="lightGrey"
              borderRadius="icon"
              height={40}
              mb="s"
            >
              <CustomText variant="content" color="primaryTextColor">
                {label}
              </CustomText>
              <PLUS height={19} width={19} fill={PALETTE.darkBlue} />
            </Box>
          </Touchable>
        );
      })}

      <Box mt="m">
        <DraggableFlatList
          showsVerticalScrollIndicator={false}
          data={pdfTemplate.selectedFields}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item, drag }) => (
            <Box marginVertical="s">
              <SelectedFieldItem
                item={item}
                onChange={v => handleUpdateSelectedFieldItem(item.field, v)}
                onPressIn={drag}
                onRemove={() => handleRemoveSelectedFieldItem(item.field)}
              />
            </Box>
          )}
          onDragEnd={({ data }) => {
            handleSortSelectedFieldItems(data);
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectedFieldItems;
