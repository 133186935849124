import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, RefreshControl, TouchableOpacity } from "react-native";

import FILTER from "../../../../../assets/icons/BASE/FILTER.svg";
import MORE_DOTS from "../../../../../assets/icons/BASE/MORE_DOTS.svg";
import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import PENDING from "../../../../../assets/icons/BASE/PENDING.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import VALIDATE from "../../../../../assets/icons/BASE/VALIDATE.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import ListingStatusFilter from "../../../../components/ListingStatusFilter";
import ScreenHeader from "../../../../components/ScreenHeader";
import { useGetQuotesByMonthLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import type { Pagination } from "../../../../types/types";

import QuoteListItemRenderer from "./QuoteListItemRenderer";

const QuotesList = () => {
  const navigation = useNavigation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [quotesByMonth, setquotesByMonth] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [getQuotesByMonth] = useGetQuotesByMonthLazyQuery();

  const handleGetQuotesByMonth = async () => {
    try {
      const result = await getQuotesByMonth({
        variables: {
          pagination,
        },
      });

      if (result.data) {
        setquotesByMonth(result.data.getQuotesForPro);
      }
    } catch (err) {
      console.log("err get quoets", err);
    }
  };

  useEffect(() => {
    handleGetQuotesByMonth();
  }, []);

  const handleNewQuote = () => {
    navigation.navigate("NEW_QUOTE", { isForQuote: true });
  };

  const handleItemPress = (itemId: string) => {
    navigation.navigate("QUOTES_LIST_DETAILS", { itemId, isForQuote: true });
  };

  const handleItemExtraPress = (itemId: string) => {
    navigation.navigate("QUOTE_DETAILS", { itemId });
  };

  const handleRefresh = async () => {
    try {
      const refreshPagination: Pagination = {
        limit: 10,
        offset: 0,
      };
      const result = await getQuotesByMonth({
        variables: {
          pagination: refreshPagination,
        },
        fetchPolicy: "network-only",
      });

      if (result.data) {
        setquotesByMonth(result.data.getQuotesForPro);
      }
    } catch (err) {
      console.log("err refresh", err);
    }
  };

  const RIGHT_BUTTONS = (
    <Box flexDirection="row" alignItems="center">
      <TouchableOpacity onPress={handleNewQuote}>
        <Box mr="s">
          <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </Box>
      </TouchableOpacity>
      <Box mr="s">
        <TouchableOpacity>
          <SEARCH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </TouchableOpacity>
      </Box>
      <Box mr="s">
        <TouchableOpacity>
          <FILTER width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </TouchableOpacity>
      </Box>
    </Box>
  );
  return (
    <Box flex={1} pt="m" backgroundColor="white">
      <Box mb="m" paddingHorizontal="s">
        <ScreenHeader title="Devis" rightButtons={RIGHT_BUTTONS} />
      </Box>

      <ListingStatusFilter
        {...{
          onSelected: setSelectedFilter,
          selectedStatus: selectedFilter,
          tabs: [
            {
              label: "Tous",
              value: "6",
              key: "ALL",
            },
            {
              value: "2",
              key: "PENDING",
              icon: (
                <>
                  <PENDING height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                </>
              ),
            },
            {
              value: "4",
              key: "VALIDATED",
              icon: (
                <>
                  <VALIDATE
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                    fill={PALETTE.darkBlue}
                  />
                </>
              ),
            },
            {
              value: "4",
              key: "OTHERS",
              icon: (
                <>
                  <MORE_DOTS
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                    fill={PALETTE.darkBlue}
                  />
                </>
              ),
            },
          ],
        }}
      />

      <FlatList
        data={quotesByMonth}
        keyExtractor={item => item.month}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />
        }
        ListEmptyComponent={
          <Box flex={1} mt="xl" height="100%" alignItems="center" justifyContent="center">
            <CustomText variant="label" color="primaryTextColor">
              Aucun devis
            </CustomText>
          </Box>
        }
        renderItem={({ item }) => (
          <QuoteListItemRenderer
            isForQuote
            quotesForMonth={item}
            onItemPress={handleItemPress}
            onItemExtraPress={handleItemExtraPress}
          />
        )}
      />
    </Box>
  );
};

export default QuotesList;
