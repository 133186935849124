import HORIZONTAL_MORE_DOTS from "../../../../assets/icons/BASE/HORIZONTAL_MORE_DOTS.svg";
import OCCURENCES from "../../../../assets/icons/BASE/OCCURENCES.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import type { BookingFragment } from "../../../graphql/generated/schema";
import { Booking_Status } from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { compactNumber } from "../../../utils/common";

const calculateAndFormatBookingPersons = (booking: BookingFragment[]) => {
  const totalPersons = booking.reduce((acc, curr) => acc + curr.nbPersons, 0);

  return compactNumber(totalPersons, "fr");
};

export const getBookingListStatusFilters = (
  data: BookingFragment[],
  selectedFilter: string,
  selectedStatusFilters: Booking_Status[],
  includeCancelledInAllFilter?: boolean,
) => {
  const HAS_SELECTED_STATUS_FILTERS = selectedStatusFilters.length > 0;

  const getAllStatusAmount = () => {
    return includeCancelledInAllFilter
      ? calculateAndFormatBookingPersons(data)
      : calculateAndFormatBookingPersons(
          data.filter(
            item =>
              item.status !== Booking_Status.CancelledByOwner &&
              item.status !== Booking_Status.CancelledByUser,
          ),
        );
  };

  const all = {
    label: "Tous",
    key: "ALL",
    value: getAllStatusAmount(),
  };

  const pending = {
    key: "PENDING",
    value: calculateAndFormatBookingPersons(
      data.filter(
        item =>
          item.status === Booking_Status.New ||
          item.status === Booking_Status.ModifiedByOwner ||
          item.status === Booking_Status.ModifiedByUser,
      ),
    ),
    icon: (
      <>
        <OCCURENCES
          height={ICON_SIZE}
          width={ICON_SIZE}
          fill={selectedFilter === "PENDING" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  const validated = {
    key: "VALIDATED",
    value: calculateAndFormatBookingPersons(
      data.filter(
        item =>
          item.status === Booking_Status.Validated ||
          item.status === Booking_Status.AutomaticValidated,
      ),
    ),
    icon: (
      <>
        <VALIDATE
          height={ICON_SIZE}
          width={ICON_SIZE}
          fill={selectedFilter === "VALIDATED" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  const getOthersAmount = () => {
    if (HAS_SELECTED_STATUS_FILTERS) {
      return calculateAndFormatBookingPersons(
        data.filter(d => {
          return selectedStatusFilters.includes(d.status);
        }),
      );
    }
    return calculateAndFormatBookingPersons(
      data.filter(
        item =>
          item.status !== Booking_Status.New &&
          item.status !== Booking_Status.Validated &&
          item.status !== Booking_Status.AutomaticValidated &&
          item.status !== Booking_Status.ModifiedByOwner &&
          item.status !== Booking_Status.ModifiedByUser,
      ),
    );
  };

  const others = {
    key: "OTHERS",
    value: getOthersAmount(),
    icon: (
      <>
        <HORIZONTAL_MORE_DOTS
          // height={ICON_SIZE}
          // width={ICON_SIZE}
          fill={selectedFilter === "OTHERS" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  return [all, pending, validated, others];
};
