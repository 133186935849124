import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import TABLE from "../../../../assets/icons/BASE/TABLE.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";
import type { BookingFloorsTotalSummary } from "../../../graphql/generated/schema";
import { useGetBookingFloorSummaryLazyQuery } from "../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

import BookingsFloors from "./BookingFloors";

interface BookingFloorGeneralSettingsProps {
  goBack?: () => void;
}

const BookingFloorGeneralSettings = ({ goBack }: BookingFloorGeneralSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [floorSummary, setFloorSummary] = useState<BookingFloorsTotalSummary | null>(
    null,
  );
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const [getBookingFloorSummary] = useGetBookingFloorSummaryLazyQuery();

  const handleGetBookingFloorSummary = async () => {
    try {
      const { data } = await getBookingFloorSummary({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setFloorSummary(data.getFloorsSummary);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingFloorSummary();
  }, []);

  if (loading) return <Loader />;

  if (!floorSummary)
    return (
      <Box>
        <CustomText variant="content" color="danger">
          Erreur
        </CustomText>
      </Box>
    );

  const displaySelectedtab = () => {
    switch (selectedTab) {
      case "BOOKING_FLOORS_LIST":
        return <BookingsFloors goBack={() => setSelectedTab(undefined)} />;

      default:
        break;
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Salles & Tables"
          // eslint-disable-next-line max-len
          description="Vous pouvez créer vos salles qui sera le reflet de votre établissement. Une fois la salle créée, vous pouvez y attacher des tables ainsi que des décorations."
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Salles"
            subText={`${floorSummary?.totalFloors} salles`}
            onPress={() => handleNavigation("BOOKING_FLOORS_LIST")}
            bottomLineWidth={0}
            icon={<TABLE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
          />

          {/* <SettingsButtonItem
            title="Tables"
            subText={`${floorSummary?.totalTables} tables`}
            onPress={() => handleNavigation("OPTIONS_LIST")}
            bottomLineWidth={0}
          /> */}
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedtab();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default BookingFloorGeneralSettings;
