import { FlatList, TouchableOpacity } from "react-native";

import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface InlineSelectButtonProps {
  options: {
    key: string;
    label: string;
  }[];
  title: string;
  description?: string;
  isDraggable?: boolean;
  selectedOptionKeys: string[];
  isMultiSelect?: boolean;
  onPress: (selectedOptionKeys: string[]) => void;
}

const MIN_HEIGHT = 45;

const InlineSelectButton = ({
  title,
  description,
  options,
  selectedOptionKeys,
  isMultiSelect = false,
  onPress,
  isDraggable,
}: InlineSelectButtonProps) => {
  const isItemSelected = (optionKey: string) => {
    return selectedOptionKeys.includes(optionKey);
  };

  const handleOnPress = (optionKey: string) => {
    if (isMultiSelect) {
      if (isItemSelected(optionKey)) {
        onPress(selectedOptionKeys.filter(key => key !== optionKey));
      } else {
        onPress([...selectedOptionKeys, optionKey]);
      }
    } else {
      onPress([optionKey]);
    }
  };

  return (
    <Box
      p="s"
      borderRadius="button"
      backgroundColor="disabled"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      minHeight={MIN_HEIGHT}
    >
      <Box flex={1} flexDirection="row" alignItems="center">
        {isDraggable && (
          <Box mr="s">
            <DRAGGABLE height={15} width={15} fill={PALETTE.lightGrey} />
          </Box>
        )}
        <Box>
          <CustomText variant="content" color="primaryTextColor">
            {title}
          </CustomText>
          {description && (
            <CustomText variant="description" color="lightGrey">
              {description}
            </CustomText>
          )}
        </Box>
      </Box>

      <Box>
        <FlatList
          data={options}
          horizontal
          keyExtractor={item => item.key}
          renderItem={({ item }) => {
            const isSelected = isItemSelected(item.key);
            return (
              <TouchableOpacity
                onPress={() => handleOnPress(item.key)}
                style={{ marginRight: 8 }}
              >
                <Box
                  minWidth={30}
                  minHeight={30}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="button"
                  borderColor="success"
                  borderWidth={isSelected ? 1 : 0}
                >
                  <CustomText variant="content" color="primaryTextColor">
                    {item.label}
                  </CustomText>
                </Box>
              </TouchableOpacity>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default InlineSelectButton;
