import type { RouteProp } from "@react-navigation/native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { Platform, TouchableOpacity } from "react-native";

import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import DOWN_ARROW from "../../../../assets/icons/BASE/DOWN_ARROW.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BookingActionsModal from "../../../components/BookingListDetails/BookingActionsModal";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import ModulListDetails from "../../../components/ModuleList/ModulListDetails";
import type { Tab } from "../../../components/OutlineTabs";
import ToggleInputLine from "../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { BookingFragment } from "../../../graphql/generated/schema";
import {
  Booking_Status,
  Modules,
  useDeleteBookingMutation,
  useDuplicateBookingMutation,
  useGetBookingAvailabilitiesWithBookingInfoLazyQuery,
  useGetBookingLazyQuery,
  useRequestOtpForBookingMutation,
  useRequestPrepaidPaymentForBookingMutation,
  useUpdateBookingMutation,
} from "../../../graphql/generated/schema";
import type { BookingStackParamList } from "../../../navigation/AppStack/BookingStack/ParamList";
import { captureAndReportErrors } from "../../../sentry";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { removeTypeNames } from "../../../utils/common";

const IS_ANDROID = Platform.OS === "android";

interface BookingListDetailsProps {
  bookingId?: string;
  goBack?: () => void;
  onUpdateComplete?: () => void;
  refreshDetails?: boolean;
  showGoBack?: boolean;
}

type BookingListScreenRouteProp = RouteProp<
  BookingStackParamList,
  "BOOKINGS_LIST_DETAILS"
>;

enum MODAL_TYPE {
  DATE,
  TIME,
  PERSONS,
}

const TABS: Tab[] = [
  {
    title: "Réservation",
    key: "BOOKING",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
  {
    title: "Client",
    key: "CLIENT",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
  {
    title: "Timeline",
    key: "TIMELINE",
    // icon: <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
  },
];

const BookingListDetailsView = ({
  bookingId: id,
  goBack,
  onUpdateComplete,
  refreshDetails,
  showGoBack = true,
}: BookingListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute<BookingListScreenRouteProp>();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const bookingId = id || params?.bookingId;
  const goBackTo = params?.goBackTo;

  // const {
  //   params: { bookingId },
  // } = route;
  const [updatedBooking, setUpdatedBooking] = useState<BookingFragment | null>(null);
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const [inEditMode, setInEditMode] = useState(false);
  const [bookingAvailability, setBookingAvailability] = useState([]);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [sendClientNotifications, setSendClientNotifications] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isUneditable, setIsUneditable] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const [getBooking] = useGetBookingLazyQuery();

  const [getBookingAvailability] = useGetBookingAvailabilitiesWithBookingInfoLazyQuery();
  const [updateBooking] = useUpdateBookingMutation();
  const [duplicateBooking] = useDuplicateBookingMutation();
  const [deleteBooking] = useDeleteBookingMutation();
  const [requestOtp] = useRequestOtpForBookingMutation();
  const [requestPrepaidPayment] = useRequestPrepaidPaymentForBookingMutation();

  const handleGetBooking = async (refresh = false) => {
    try {
      const { data } = await getBooking({
        fetchPolicy: refresh ? "cache-and-network" : "cache-first",
        variables: { bookingId },
      });
      if (data?.getBookingForPro) {
        const booking = data?.getBookingForPro;

        setUpdatedBooking({
          ...booking,
          client: booking?.merchantClient,
        });

        const HAS_PAID = booking?.prepaidPayment?.paid || booking?.payment?.paid;

        const hasBookingStatusChangeReachedEnd =
          (booking?.status === Booking_Status.Ended && !HAS_PAID) ||
          booking?.status === Booking_Status.Noshow ||
          booking?.status === Booking_Status.Refunded;

        setIsUneditable(hasBookingStatusChangeReachedEnd);
      }
    } catch (err) {
      captureAndReportErrors(err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_BOOKING",
            message: "Une erreur est survenue lors de la récupération de la réservation",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEditMode = () => {
    setInEditMode(false);
  };

  useEffect(() => {
    handleCancelEditMode();
  }, [bookingId]);

  useEffect(() => {
    handleGetBooking(refreshDetails);
  }, [bookingId, refreshDetails]);

  const handleGetBookingAvailability = async () => {
    if (!updatedBooking) return;

    try {
      const { data } = await getBookingAvailability({
        variables: { date: updatedBooking.date },
      });

      if (data?.getBookingAvailabilitiesWithBookingInfo) {
        let services = data?.getBookingAvailabilitiesWithBookingInfo || [];

        services = services.filter(service => service.serviceId !== "HS_SERVICE");

        setBookingAvailability(services);
      }
    } catch (err) {
      captureAndReportErrors(err);

      console.log("err handleGetBookingAvailability", err);
    }
  };

  useEffect(() => {
    handleGetBookingAvailability();
  }, [updatedBooking?.date]);

  const handleModifyValidateButton = async () => {
    try {
      setInEditMode(false);
      setIsInProgress(true);
      if (inEditMode) {
        if (!updatedBooking) return;

        const {
          date,
          nbPersons,
          serviceId,
          slotId,
          commentOwner,
          merchantClient,
          status,
          tables,
          refusalReason = "",
          eventType,
          source,
        } = updatedBooking;

        const dateString = new Date(date).toISOString();
        const bookingUpdates = {
          date: dateString,
          nbPersons,
          serviceId,
          slotId,
          commentOwner,
          client: {
            ...merchantClient,
            dateOfBirth: merchantClient?.dateOfBirth
              ? new Date(merchantClient?.dateOfBirth)?.toISOString()
              : null,
            merchantCompanyId: merchantClient?.merchantCompanyId?._id || "",
          },
          status,
          tables,
          refusalReason,
          sendClientNotifications,
          eventType,
          source,
        };

        const updates = removeTypeNames(bookingUpdates);
        delete updates?.client?.bookingOccurences;
        delete updates?.client?.orderOccurences;
        delete updates?.client?.ticketOccurences;
        delete updates?.client?.generatedProfit;
        delete updates?.client?.averageProfit;
        delete updates?.client?.averageRating;

        const completeUpdateBooking = await updateBooking({
          variables: {
            bookingId,
            booking: updates,
          },
        });

        if (completeUpdateBooking?.data?.updateBookingForPro) {
          await handleGetBooking();

          // setUpdatedBooking({
          //   ...completeUpdateBooking?.data?.updateBookingForPro,
          //   client: completeUpdateBooking?.data?.updateBookingForPro?.merchantClient,
          // });
        }

        onUpdateComplete?.();
      }
    } catch (err) {
      console.log("err update booking", JSON.stringify(err, null, 2));

      captureAndReportErrors(err);

      infoAlert?.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    } finally {
      setIsInProgress(false);
    }
  };

  if (loading || !updatedBooking?.date) {
    return <Loader />;
  }

  if (!updatedBooking) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue, veuillez réessayer plus tard" />
      </Box>
    );
  }

  const handleUndoLastStatusChange = async (status: Booking_Status) => {
    try {
      const completeUpdateBooking = await updateBooking({
        variables: {
          bookingId,
          booking: {
            status,
            sendClientNotifications: false,
          },
        },
      });

      if (completeUpdateBooking?.data?.updateBookingForPro) {
        await handleGetBooking();
      }
    } catch (err) {
      captureAndReportErrors(err);
    }
  };

  const handleDuplicationBookings = async (dates: Date[]) => {
    try {
      await duplicateBooking({
        variables: {
          bookingId,
          duplication: {
            dates,
          },
        },
      });
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);

      infoAlert?.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    }
  };

  const handleDelete = async () => {
    try {
      const { data: deleteData } = await deleteBooking({
        variables: {
          bookingId,
        },
      });

      if (deleteData?.deleteBookingForPro) {
        navigation.goBack();
      }
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    }
  };

  const handleRequstOtp = async () => {
    try {
      await requestOtp({
        variables: {
          bookingId,
        },
      });
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    } finally {
      onUpdateComplete?.();
      handleGetBooking();
    }
  };

  const handleRequstPrepaidPayment = async () => {
    try {
      await requestPrepaidPayment({
        variables: {
          bookingId,
        },
      });
    } catch (err) {
      console.log("err", err);

      captureAndReportErrors(err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: err?.code || "error",
            message: err?.message || "Une erreur est survenue",
            path: err?.message || "Une erreur est survenue",
          },
        ],
        "error",
      );
    } finally {
      onUpdateComplete?.();
      handleGetBooking();
    }
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        await handleDelete();
      } else if (key === "PREPAID_REQUEST") {
        await handleRequstPrepaidPayment();
      } else if (key === "OTP_REQUEST") {
        await handleRequstOtp();
      } else if (key === "MODIFY") {
        await handleModifyValidateButton();
      } else if (key === "CANCEL") {
        setInEditMode(false);
      }
    } catch (err) {
      captureAndReportErrors(err);

      console.log("err", err);
    }
  };

  const handleExtraTabPress = () => {
    const { ticketId } = updatedBooking;
    navigation.navigate("SALES", {
      screen: "CASH_REGISTER_TAB",
      initial: false,
      params: {
        screen: "CASH_REGISTER_TICKET_LIST_DETAILS",
        params: {
          id: ticketId,
        },
      },
    });
  };

  const handleMainButtonAction = () => {
    if (inEditMode) {
      handleModifyValidateButton();
    } else setInEditMode(true);
  };

  const handleGoBack = () => {
    if (goBackTo) {
      navigation.navigate(goBackTo?.stack, {
        screen: goBackTo?.screen,
        params: {
          id: goBackTo?.id,
        },
      });
      return;
    } else if (goBack) {
      return goBack?.();
    }
    navigation.goBack();
  };

  const renderBottomButtons = () => {
    if (isUneditable || !showGoBack) return <></>;

    // return <></>;

    const isDisabled = isInProgress && inEditMode;

    return (
      <Box
        p="s"
        pb="xl"
        backgroundColor="white"
        position="absolute"
        bottom={IS_ANDROID ? 10 : 0}
        left={0}
        right={0}
      >
        {inEditMode && (
          <Box>
            <Box paddingHorizontal="s">
              <ToggleInputLine
                text="Envoyer notification au client"
                fontSize={12}
                value={sendClientNotifications}
                onValueChange={setSendClientNotifications}
              />
            </Box>
            <Box paddingHorizontal="s" mb="s" flexDirection="row" alignItems="center">
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  SMS
                </CustomText>

                {updatedBooking.merchantClient?.phone ? (
                  <VALIDATE width={17} height={17} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
              <Box mr="s" flexDirection="row" alignItems="center">
                <CustomText mr="s" variant="text" color="primaryTextColor">
                  Email
                </CustomText>

                {updatedBooking.merchantClient?.email ? (
                  <VALIDATE width={12} height={12} fill={PALETTE.green} />
                ) : (
                  <CANCEL fill={PALETTE.red} />
                )}
              </Box>
            </Box>
          </Box>
        )}

        <TouchableOpacity
          // style={{
          //   width: "100%",
          //   maxWidth: 370,
          //   alignSelf: "flex-end",
          // }}
          disabled={isDisabled}
          onPress={handleMainButtonAction}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            backgroundColor="white"
            borderRadius="button"
            borderColor="success"
            borderWidth={LINE_THICKNESS}
            p="s"
            width="100%"
            style={{ minHeight: 45 }}
            // maxWidth={{
            //   tablet: 370,
            // }}
            // alignSelf={{
            //   tablet: "flex-end",
            // }}
          >
            <CustomText variant="primaryButtonText" color="success" style={{ flex: 1 }}>
              {inEditMode ? "Valider" : "Modifier"}
            </CustomText>

            <Box
              marginHorizontal="s"
              height="70%"
              minHeight={30}
              width={2}
              backgroundColor="success"
            />

            <TouchableOpacity
              style={{
                flex: 0.2,
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={isDisabled}
              onPress={() => setIsActionsModalOpen(true)}
            >
              <Box pr="s" alignItems="center" justifyContent="center">
                <DOWN_ARROW
                  fill={PALETTE.green}
                  // width={24}
                  // fill={disabled ? PALETTE.gray : PALETTE[iconColor]}
                />
              </Box>
            </TouchableOpacity>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  };

  const getTabs = () => {
    const tabs = TABS;

    if (!updatedBooking.merchantClient) return tabs.filter(tab => tab.key !== "CLIENT");

    return tabs;
  };

  const handleCancel = () => {
    setInEditMode(false);
    setIsActionsModalOpen(false);
  };

  return (
    <>
      <ModulListDetails
        tabNames={getTabs()}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        onExtraTabPress={handleExtraTabPress}
        moduleType={Modules.Reservation}
        services={bookingAvailability}
        data={updatedBooking}
        updateData={setUpdatedBooking}
        inEditMode={inEditMode}
        setInEditMode={setInEditMode}
        bottomButton={renderBottomButtons()}
        goBack={handleGoBack}
        showGoBack={showGoBack}
        undoLastStatusChange={handleUndoLastStatusChange}
      />

      {updatedBooking && (
        <BookingActionsModal
          booking={updatedBooking}
          handleSubmit={handleOnSubmit}
          isOpen={isActionsModalOpen}
          onClose={() => setIsActionsModalOpen(false)}
          onDuplicateConfirm={handleDuplicationBookings}
          handleCancel={inEditMode ? handleCancel : undefined}
        />
      )}
    </>
  );
};

export default BookingListDetailsView;
