import { format } from "date-fns";

import { createDateWithTime } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import DateInput from "../DateInput/index";
import DaysSelector from "../DaysSelector";
import TimeInput from "../TimeInput/index";
import ToggleInputLine from "../ToggleInputLine";

interface DateVisibilitySectionProps {
  isRecurring: boolean;
  updateReccuring: (value: boolean) => void;
  days: number[];
  updateDays: (value: number[]) => void;
  startDate: Date;
  updateStartDate: (value: Date) => void;
  endDate: Date;
  updateEndDate: (value: Date) => void;
  startTime: string;
  updateStartTime: (value: string) => void;
  endTime: string;
  updateEndTime: (value: string) => void;
}

const DateVisibilitySection = ({
  isRecurring,
  updateReccuring,
  days,
  updateDays,
  startDate = new Date(),
  updateStartDate,
  endDate = new Date(),
  updateEndDate,
  startTime,
  updateStartTime,
  endTime,
  updateEndTime,
}: DateVisibilitySectionProps) => {
  const handleUpdateStartDate = (date: Date | undefined) => {
    if (date) {
      updateStartDate(date);
    }
  };
  const handleUpdateEndDate = (date: Date | undefined) => {
    if (date) {
      updateEndDate(date);
    }
  };

  const handleUpdateStartTime = (date: Date | undefined) => {
    if (date) {
      const time = format(date, "HH:mm");
      updateStartTime(time);
    }
  };

  const handleUpdateEndTime = (date: Date | undefined) => {
    if (date) {
      const time = format(date, "HH:mm");
      updateEndTime(time);
    }
  };

  const handleUpdateDays = (value: number | number[]) => {
    if (Array.isArray(value)) {
      updateDays(value);
    } else {
      updateDays([value]);
    }
  };

  return (
    <>
      <Box>
        {/* <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor">
            Personnaliser la période
          </CustomText>
        </Box> */}
        <Box mb="s">
          <ToggleInputLine
            textVariant="content"
            text="À une date spécifique"
            value={!isRecurring}
            onValueChange={() => updateReccuring(false)}
          />
        </Box>
        <Box mb="s">
          <ToggleInputLine
            textVariant="content"
            text="Récurrent"
            value={isRecurring}
            onValueChange={() => updateReccuring(true)}
          />
        </Box>
      </Box>
      {!isRecurring && (
        <Box mt="m">
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>
              <CustomText variant="content" color="primaryTextColor">
                Début
              </CustomText>
            </Box>
            <Box flexDirection="row" alignItems="center">
              <Box mr="s">
                <DateInput
                  date={new Date(startDate) || new Date()}
                  onChange={handleUpdateStartDate}
                  placeHolder="Date de début"
                />
              </Box>
              <Box>
                <TimeInput
                  date={createDateWithTime(new Date(), startTime)}
                  onChange={handleUpdateStartTime}
                  placeHolder="Heure de début"
                />
              </Box>
            </Box>
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <CustomText variant="content" color="primaryTextColor">
                Fin
              </CustomText>
            </Box>
            <Box
              // backgroundColor="danger"
              flexDirection="row"
              alignItems="center"
            >
              <Box mr="s">
                <DateInput
                  date={new Date(endDate) || new Date()}
                  onChange={handleUpdateEndDate}
                  placeHolder="Date de fin"
                />
              </Box>
              <Box>
                <TimeInput
                  date={createDateWithTime(new Date(), endTime)}
                  onChange={handleUpdateEndTime}
                  placeHolder="Heure de fin"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {isRecurring && (
        <>
          <Box mt="m">
            <DaysSelector
              isMulti
              selectedDays={days || []}
              onDayPress={handleUpdateDays}
            />
          </Box>

          <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Heure de début
            </CustomText>
            <Box>
              <TimeInput
                date={createDateWithTime(new Date(), startTime)}
                onChange={handleUpdateStartTime}
                placeHolder="Heure de début"
              />
            </Box>
          </Box>
          <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Heure de fin
            </CustomText>
            <Box>
              <TimeInput
                date={createDateWithTime(new Date(), endTime)}
                onChange={handleUpdateEndTime}
                placeHolder="Heure de fin"
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default DateVisibilitySection;
