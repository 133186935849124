import { StackActions, useNavigation } from "@react-navigation/native";
import type { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { StatusBar } from "expo-status-bar";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Image, Platform, TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import ALERT_BELL from "../../../assets/icons/BASE/ALERT_BELL.svg";
import BOOK from "../../../assets/icons/BASE/BOOK.svg";
import CASH_REGISTER from "../../../assets/icons/BASE/CASH_REGISTER.svg";
import EDIT from "../../../assets/icons/BASE/EDIT.svg";
import EIWIE_HEADER_ICON from "../../../assets/icons/BASE/EIWIE_HEADER_ICON.svg";
import PERSON from "../../../assets/icons/BASE/PAX_PERSONS.svg";
import REVIEW from "../../../assets/icons/BASE/REVIEW.svg";
import SETTINGS from "../../../assets/icons/BASE/SETTINGS.svg";
import STATS from "../../../assets/icons/BASE/STATS.svg";
import SWITCH from "../../../assets/icons/BASE/SWITCH.svg";
import { AppContext } from "../../contexts/AppContext";
import { SwitchStaffAccountContext } from "../../contexts/SwitchStaffAccount";
import { Modules, PermissionLevel } from "../../graphql/generated/schema";
import type { AppStackParamList } from "../../navigation/AppStack/ParamList";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

import type { HeaderItemButtonProps } from "./HeaderItemButton";
import HeaderItemButton from "./HeaderItemButton";

const IMAGE_HEIGHT = 30;
const HEADER_BUTTON_ICON = 24;

const IS_WEB = Platform.OS === "web";

export const HEADER_HEIGHT = IS_WEB ? 45 : 110;

type Props = {
  // boxProps?: React.ComponentProps<typeof Box>;
};

type MODULE_HEADER_TYPE = "MODULE_HEADER" | "PROFILE_HEADER";

export const Header = ({ route }: NativeStackHeaderProps) => {
  const navigation = useNavigation();

  const currentScreenName = route.name;
  const appContext = useContext(AppContext);
  const staffSwitchAccountContext = useContext(SwitchStaffAccountContext);
  const [isModuleHeaderOpen, setIsModuleHeaderOpen] = useState(false);
  const [isProfileHeaderOpen, setIsProfileHeaderOpen] = useState(false);
  const [isThereProblemWithProfileImage, setIsThereProblemWithProfileImage] =
    useState(false);
  const mainHeaderScrollRef = useRef<FlatList | null>(null);
  // const { width } = useDeviceInformation();

  // const HORIZONTAL = true;

  // // const HORIZONTAL = IS_WEB && width > 800;

  const handleScrollToSelectedHeaderItem = () => {
    if (mainHeaderScrollRef.current) {
      const index = headerItems.findIndex(item => item.screenName === currentScreenName);

      if (index === -1) return;

      mainHeaderScrollRef.current?.scrollToIndex({ index, animated: true });
    }
  };

  useEffect(() => {
    handleScrollToSelectedHeaderItem();
  }, [currentScreenName]);

  const handleOpenHeader = (type: MODULE_HEADER_TYPE) => {
    if (isModuleHeaderOpen && type === "MODULE_HEADER") {
      closeHeader();
    } else if (isProfileHeaderOpen && type === "PROFILE_HEADER") {
      closeHeader();
    } else if (type === "MODULE_HEADER") {
      closeHeader();
      setIsModuleHeaderOpen(true);
    } else if (type === "PROFILE_HEADER") {
      closeHeader();
      setIsProfileHeaderOpen(true);
    }
  };

  const handleGoToAccount = () => {
    navigation.navigate("SETTINGS", {
      screen: "ACCOUNT_AND_SUBSCRIPTION_SETTINGS_LIST",
    });
    closeHeader();
  };

  // const handleGoToHelp = () => {
  //   navigation.navigate("SETTINGS", {
  //     screen: "HELP",
  //   });
  //   closeHeader();
  // };

  const handleOpenAccountSwitch = () => {
    closeHeader();
    staffSwitchAccountContext.setIsStaffAccountModalOpen(true);
  };

  const handleNavigateToScreen = (screenName: keyof AppStackParamList) => {
    // const isCurrentlyOnRequestedScreen = currentScreenName === screenName;

    // if (isCurrentlyOnRequestedScreen) {
    //   closeHeader();
    //   return;
    // }

    navigation.dispatch(StackActions.replace(screenName));

    // if (screenName === "SETTINGS" && route.name === "SETTINGS") {
    //   navigation.dispatch(StackActions.replace("SETTINGS"));
    // } else if (screenName === "BOOKING") {
    //   navigation.dispatch(StackActions.replace("BOOKING"));
    // } else if (screenName === "SALES") {
    //   navigation.dispatch(StackActions.replace("SALES"));
    // } else if (screenName === "ALERTS") {
    //   navigation.dispatch(StackActions.replace("ALERTS"));
    // } else if (screenName === "STATS") {
    //   navigation.dispatch(StackActions.replace("STATS"));
    // }
    // else {
    //   navigation.navigate(screenName);
    // }
    closeHeader();
  };

  const MODULE_HEADER_ITEMS: HeaderItemButtonProps[] = [
    // {
    //   icon: (
    //     <HOME
    //       height={HEADER_BUTTON_ICON}
    //       width={HEADER_BUTTON_ICON}
    //       fill={PALETTE.green}
    //     />
    //   ),
    //   title: "HOME",
    //   onPress: () => handleNavigateToScreen("DASHBOARD"),
    // },

    {
      icon: (
        <BOOK
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Résa",
      screenName: "BOOKING",
      onPress: () => handleNavigateToScreen("BOOKING"),
    },
    {
      icon: (
        <CASH_REGISTER
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Vente",
      screenName: "SALES",
      onPress: () => handleNavigateToScreen("SALES"),
    },
    // {
    //   icon: (
    //     <RECEIPT
    //       height={HEADER_BUTTON_ICON}
    //       width={HEADER_BUTTON_ICON}
    //       fill={PALETTE.green}
    //     />
    //   ),
    //   title: "Compta.",
    //   onPress: () => handleNavigateToScreen("ACCOUNTING"),
    // },
    {
      icon: (
        <STATS
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Stats",
      screenName: "STATS",
      onPress: () => handleNavigateToScreen("STATS"),
    },
    {
      icon: (
        <EDIT
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Publier",
      screenName: "PUBLICATION",
      onPress: () => handleNavigateToScreen("PUBLICATION"),
    },
    {
      icon: (
        <REVIEW
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Avis",
      screenName: "REVIEWS",
      onPress: () => handleNavigateToScreen("REVIEWS"),
    },
  ];

  const PROFILE_HEADER_ITEMS: HeaderItemButtonProps[] = [
    {
      icon: (
        <SETTINGS
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Réglages",
      screenName: "SETTINGS",
      onPress: () => handleNavigateToScreen("SETTINGS"),
    },

    {
      icon: (
        <PERSON
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Compte",
      screenName: "",
      onPress: handleGoToAccount,
    },
    // {
    //   icon: (
    //     <FLAG
    //       height={HEADER_BUTTON_ICON}
    //       width={HEADER_BUTTON_ICON}
    //       fill={PALETTE.green}
    //     />
    //   ),
    //   title: "Aide",
    //   onPress: handleGoToHelp,
    // },
    {
      icon: (
        <SWITCH
          height={HEADER_BUTTON_ICON}
          width={HEADER_BUTTON_ICON}
          fill={PALETTE.green}
        />
      ),
      title: "Switcher",
      screenName: "",
      onPress: handleOpenAccountSwitch,
    },
  ];

  const getMainModuleItems = () => {
    const filteredHeaderItemsByPermissions = MODULE_HEADER_ITEMS.filter(item => {
      if (item.title === "Vente") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);
        const hasTakeAway = appContext?.merchantPermissions?.includes(
          Modules.OnlineSales,
        );
        const hasCashRegister = appContext?.merchantPermissions?.includes(
          Modules.CashRegister,
        );
        const hasDelivery = appContext?.merchantPermissions?.includes(Modules.Delivery);
        const hasTablePayment = appContext?.merchantPermissions?.includes(
          Modules.TablePayment,
        );
        const hasTableOrder = appContext?.merchantPermissions?.includes(
          Modules.TableOrder,
        );

        const merchantPermissions =
          hasAllPermissions ||
          hasTakeAway ||
          hasCashRegister ||
          hasDelivery ||
          hasTablePayment ||
          hasTableOrder;

        const personnelPermission =
          appContext?.connectedUser?.permissionGroup?.permissions?.some(p => {
            const correctPermission =
              p.module === Modules.OnlineSales ||
              p.module === Modules.CashRegister ||
              p.module === Modules.Delivery ||
              p.module === Modules.TablePayment ||
              p.module === Modules.TableOrder;

            if (correctPermission && p.permissionLevel !== PermissionLevel.Disabled)
              return true;
            return false;
          });

        if (appContext.connectedUser?.isOwner) return merchantPermissions;

        return merchantPermissions && personnelPermission;
      }

      if (item.title === "Résa") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);
        const hasBookingPermission = appContext?.merchantPermissions?.includes(
          Modules.Reservation,
        );

        const merchantPermission = hasAllPermissions || hasBookingPermission;

        const personnelPermission =
          appContext?.connectedUser?.permissionGroup?.permissions?.some(p => {
            const correctPermission = p.module === Modules.Reservation;

            if (correctPermission && p.permissionLevel !== PermissionLevel.Disabled)
              return true;
            return false;
          });

        if (appContext.connectedUser?.isOwner) return merchantPermission;

        return merchantPermission && personnelPermission;
      }

      if (item.title === "Compta.") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);

        const hasQuotes = appContext?.merchantPermissions?.includes(Modules.Quotes);
        const hasInvoices = appContext?.merchantPermissions?.includes(Modules.Invoices);

        const merchantPermission = hasAllPermissions || hasQuotes || hasInvoices;

        const personnelPermission =
          appContext?.connectedUser?.permissionGroup?.permissions?.some(p => {
            const correctPermission =
              p.module === Modules.Quotes || p.module === Modules.Invoices;

            if (correctPermission && p.permissionLevel !== PermissionLevel.Disabled)
              return true;
            return false;
          });

        if (appContext.connectedUser?.isOwner) return merchantPermission;

        return merchantPermission && personnelPermission;
      }

      if (item.title === "Publier") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);

        const hasPublishingPermission = appContext?.merchantPermissions?.includes(
          Modules.Publishing,
        );

        const merchantPermission = hasAllPermissions || hasPublishingPermission;

        const personnelPermission =
          appContext?.connectedUser?.permissionGroup?.permissions?.some(p => {
            const correctPermission = p.module === Modules.Publishing;

            if (correctPermission && p.permissionLevel !== PermissionLevel.Disabled)
              return true;
            return false;
          });

        if (appContext.connectedUser?.isOwner) return merchantPermission;

        return merchantPermission && personnelPermission;
      }
      if (item.title === "Avis") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);

        const hasCashRegister = appContext?.merchantPermissions?.includes(
          Modules.CashRegister,
        );
        const hasBooking = appContext?.merchantPermissions?.includes(Modules.Reservation);
        const hasOnlineSales = appContext?.merchantPermissions?.includes(
          Modules.OnlineSales,
        );

        const merchantPermission =
          hasAllPermissions || hasBooking || hasOnlineSales || hasCashRegister;

        const personnelPermission =
          appContext?.connectedUser?.permissionGroup?.permissions?.some(p => {
            const correctPermission = p.module === Modules.Publishing;

            if (correctPermission && p.permissionLevel !== PermissionLevel.Disabled)
              return true;
            return false;
          });

        if (appContext.connectedUser?.isOwner) return merchantPermission;

        return merchantPermission && personnelPermission;
      }

      if (item.title === "Stats") {
        const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);

        const hasCashRegister = appContext?.merchantPermissions?.includes(
          Modules.CashRegister,
        );
        const hasBooking = appContext?.merchantPermissions?.includes(Modules.Reservation);
        const hasOnlineSales = appContext?.merchantPermissions?.includes(
          Modules.OnlineSales,
        );

        const merchantPermission =
          hasAllPermissions || hasBooking || hasOnlineSales || hasCashRegister;

        const isStaffAdmin = appContext?.connectedUser?.isAdministrator;

        return merchantPermission && (isStaffAdmin || appContext?.connectedUser?.isOwner);
      }
      return true;
    });

    return filteredHeaderItemsByPermissions;
  };

  const getProfileHeaderItems = () => {
    const filteredHeaderItemsByPermissions = PROFILE_HEADER_ITEMS.filter(item => {
      if (item.title === "Compte") {
        return appContext?.connectedUser?.isOwner;
      }

      if (item.title === "Switcher") {
        return !appContext?.connectedUser?.isOwner;
      }
      return true;
    });

    return filteredHeaderItemsByPermissions;
  };

  const headerItems = useMemo(() => {
    return getMainModuleItems();
  }, [appContext.merchantPermissions, appContext.connectedUser]);

  const profileItems = useMemo(() => {
    return getProfileHeaderItems();
  }, []);

  const closeHeader = () => {
    setIsModuleHeaderOpen(false);
    setIsProfileHeaderOpen(false);
  };

  const displayHomeButton = () => {
    const isSelected = currentScreenName === "DASHBOARD";

    const iconFill = isSelected ? PALETTE.green : PALETTE.lightGrey;

    return (
      <TouchableOpacity onPress={() => handleNavigateToScreen("DASHBOARD")}>
        <Box flexDirection="column" alignItems="center" justifyContent="center">
          <EIWIE_HEADER_ICON
            height={HEADER_BUTTON_ICON}
            width={HEADER_BUTTON_ICON}
            fill={iconFill}
          />
          <CustomText mt="xs" variant="content" color="white">
            Accueil
          </CustomText>
        </Box>
      </TouchableOpacity>
    );
  };

  const displayInlineMenuItems = () => {
    return (
      <Box>
        {!isProfileHeaderOpen && (
          <FlatList
            ref={mainHeaderScrollRef}
            data={headerItems}
            horizontal
            ListHeaderComponent={displayHomeButton}
            showsHorizontalScrollIndicator={false}
            keyExtractor={item => item.title}
            onScrollToIndexFailed={info => {
              const wait = new Promise(resolve => setTimeout(resolve, 500));
              wait.then(() => {
                mainHeaderScrollRef?.current?.scrollToIndex({
                  index: info.index,
                  animated: true,
                });
              });
            }}
            renderItem={({ item }) => {
              const isSelected = currentScreenName === item.screenName;

              return (
                <Box marginHorizontal="m">
                  <HeaderItemButton {...item} isSelected={isSelected} />
                </Box>
              );
            }}
          />
        )}
        {isProfileHeaderOpen && (
          <FlatList
            data={profileItems}
            horizontal
            ListHeaderComponent={displayHomeButton}
            showsHorizontalScrollIndicator={false}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box marginHorizontal="m">
                <HeaderItemButton {...item} />
              </Box>
            )}
          />
        )}
      </Box>
    );
  };

  const displayAlertsAndSettingsButtons = () => {
    return (
      <Box flexDirection="row" alignItems="flex-end">
        <CustomButton
          onPress={() => handleNavigateToScreen("ALERTS")}
          styles={{ ml: "s" }}
        >
          <Box>
            <ALERT_BELL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.white} />
            {appContext?.totalAlerts > 0 && (
              <Box
                position="absolute"
                top={-10}
                right={-10}
                backgroundColor="danger"
                borderRadius="button"
                width={20}
                height={20}
                justifyContent="center"
                alignItems="center"
              >
                <CustomText variant="content" color="white">
                  {appContext?.totalAlerts > 9 ? "+9" : appContext?.totalAlerts}
                </CustomText>
              </Box>
            )}
          </Box>
        </CustomButton>
        <CustomButton
          onPress={() => handleOpenHeader("PROFILE_HEADER")}
          styles={{ ml: "s" }}
        >
          {appContext?.connectedUser?.picture && !isThereProblemWithProfileImage ? (
            <Image
              source={{
                uri: appContext?.connectedUser?.picture,
              }}
              style={{
                width: IMAGE_HEIGHT,
                height: IMAGE_HEIGHT,
                borderRadius: IMAGE_HEIGHT / 2,
              }}
              onError={() => {
                setIsThereProblemWithProfileImage(true);
              }}
            />
          ) : (
            <Box>
              <SETTINGS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.white} />
            </Box>
          )}
        </CustomButton>
      </Box>
    );
  };

  if (appContext.hideHeaders) return <></>;

  return (
    <Box
      backgroundColor="headerBackground"
      width={"100%"}
      minHeight={HEADER_HEIGHT}
      justifyContent="flex-end"
      borderBottomLeftRadius="modal"
      borderBottomRightRadius="modal"
      p="s"
      // pb="m"
    >
      <StatusBar style="light" />
      <Box
        paddingHorizontal="s"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
        // pt="s"
        // mt="xl"
      >
        <Box width="80%" alignSelf="flex-start">
          {displayInlineMenuItems()}
        </Box>
        <Box>{displayAlertsAndSettingsButtons()}</Box>
      </Box>
    </Box>
  );
};
