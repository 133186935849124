import React from "react";
import { TouchableOpacity } from "react-native";

import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

export interface HeaderItemButtonProps {
  onPress: () => void;
  icon: React.ReactNode;
  title: string;
  isSelected?: boolean;
  screenName: string;
}

const HeaderItemButton = ({
  onPress,
  icon,
  title,
  isSelected,
}: HeaderItemButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box flexDirection="column" alignItems="center" justifyContent="center">
        <Box alignItems="center" justifyContent="center">
          {React.cloneElement(icon, {
            fill: isSelected ? PALETTE.green : PALETTE.lightGrey,
          })}
        </Box>
        <CustomText mt="xs" variant="content" color="white">
          {title}
        </CustomText>
      </Box>
    </TouchableOpacity>
  );
};

export default HeaderItemButton;
