import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useReducer, useState } from "react";
import { Image, ScrollView, StyleSheet, TouchableOpacity } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage/index";
import Loader from "../../../../components/Loader";
import MerchantCarouselPicker from "../../../../components/MerchantCarouselPicker";
import ScreenHeader from "../../../../components/ScreenHeader";
import type { MerchantFragment } from "../../../../graphql/generated/schema";
import {
  useGetMerchantLazyQuery,
  useUpdateMerchantMutation,
} from "../../../../graphql/generated/schema";
import theme from "../../../../theme";

type ReducerStateFocus = null | {
  imgUrl: string;
  isMain: boolean;
  index: number;
  previousImg: string | null;
  nextImg: string | null;
};

export type FocusReducerPayload =
  | { action: "ON_FOCUS"; value: { img: string } }
  | { action: "ON_UNFOCUS" };

const initialReducerStateFocus = null;

interface MerchantPhotosProps {
  goBack?: () => void;
}

const MerchantPhotos = ({ goBack }: MerchantPhotosProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [validationLoading, setValidationLoading] = useState(false);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);

  const [getMerchant] = useGetMerchantLazyQuery();
  const [updateMerchant] = useUpdateMerchantMutation();

  const handleGetMerchant = async () => {
    setLoading(true);
    try {
      const { data } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetMerchant();
  }, []);

  const focusReducer = (
    state: ReducerStateFocus,
    payload: FocusReducerPayload,
  ): ReducerStateFocus => {
    if (!merchant) return state;
    const orderedImages = [merchant?.coverImage, ...[...merchant.images].reverse()];
    let foundIndex = -1;
    switch (payload.action) {
      case "ON_FOCUS":
        foundIndex = orderedImages.findIndex(x => x == payload.value.img);
        return {
          imgUrl: payload.value.img,
          isMain: payload.value.img === merchant?.coverImage,
          index: foundIndex,
          previousImg: foundIndex !== 0 ? orderedImages[foundIndex - 1] : null,
          nextImg:
            foundIndex !== orderedImages.length - 2
              ? orderedImages[foundIndex + 1]
              : null,
        };
      case "ON_UNFOCUS":
        return null;
      default:
        return state;
    }
  };

  const [reducerStateFocus, dispatchFocus] = useReducer(
    focusReducer,
    initialReducerStateFocus as ReducerStateFocus,
    () => initialReducerStateFocus as ReducerStateFocus,
  );

  const handleAddCoverImage = (image?: string) => {
    if (!image) return;

    setMerchant(prev => {
      if (!prev) return null;
      return {
        ...prev,
        coverImage: image,
      };
    });
  };

  const handleRemoveCoverImage = () => {
    setMerchant(prev => {
      if (!prev) return null;
      return {
        ...prev,
        coverImage: "",
      };
    });
  };

  const handleAddGalleryImage = (image?: string) => {
    if (!image) return;

    setMerchant(prev => {
      if (!prev) return null;
      return {
        ...prev,

        images: [...prev.images, image],
      };
    });
  };

  const handleRemoveGalleryImage = (image?: string) => {
    setMerchant(prev => {
      if (!prev) return null;
      return {
        ...prev,
        images: prev.images.filter(i => i !== image),
      };
    });
  };

  const handleUpdateMerchant = async () => {
    try {
      if (!merchant) return;

      setValidationLoading(true);
      await updateMerchant({
        variables: {
          updates: {
            coverImage: merchant.coverImage,
            images: merchant.images,
          },
        },
      });

      if (goBack) {
        goBack();
      } else {
        navigation.goBack();
      }
    } catch (err) {
      console.log("err update merchant", err);
    }
  };

  const getFocus = () => {
    if (!reducerStateFocus) return <></>;
    return (
      <Box style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Box style={styles.focusPictureWrapper}>
          <Image
            source={{
              uri: reducerStateFocus?.imgUrl,
            }}
            resizeMode="contain"
            style={styles.picture}
          />
          {reducerStateFocus?.index === 0 && false && (
            <Box style={styles.pictureLabel}>
              <CustomText style={styles.pictureLabelText} variant={"content"}>
                Photo de couverture
              </CustomText>
            </Box>
          )}
          <Box style={styles.paginationLabel}>
            <CustomText style={styles.pictureLabelText} variant={"content"}>
              {reducerStateFocus.index + 1}/{merchant ? merchant.images.length : "--"}
            </CustomText>
          </Box>
          {reducerStateFocus && reducerStateFocus.previousImg != null && (
            <TouchableOpacity
              style={[styles.pictureButton, styles.previousPictureButton]}
              onPress={() =>
                reducerStateFocus.previousImg
                  ? dispatchFocus({
                      action: "ON_FOCUS",
                      value: { img: reducerStateFocus.previousImg },
                    })
                  : () => {}
              }
            >
              <CustomText style={styles.pictureNavigationText} variant={"content"}>
                {"<"}
              </CustomText>
            </TouchableOpacity>
          )}
          {reducerStateFocus && reducerStateFocus.nextImg != null && (
            <TouchableOpacity
              style={[styles.pictureButton, styles.nextPictureButton]}
              onPress={() =>
                reducerStateFocus.nextImg
                  ? dispatchFocus({
                      action: "ON_FOCUS",
                      value: { img: reducerStateFocus.nextImg },
                    })
                  : () => {}
              }
            >
              <CustomText style={styles.pictureNavigationText} variant={"content"}>
                {">"}
              </CustomText>
            </TouchableOpacity>
          )}
        </Box>
        <BottomButton
          onPress={() => dispatchFocus({ action: "ON_UNFOCUS" })}
          title="Retour"
        />
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }
  if (!merchant) {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader
          title="Photos de l'établissement"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations de l'établissement." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader
          title="Photos de l'établissement"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      </Box>
      {reducerStateFocus ? (
        getFocus()
      ) : (
        <>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 100 }}
          >
            <Box mt="s">
              <Box marginVertical="m">
                <CustomText variant="content" color="success" textTransform="uppercase">
                  GALLERIE DE L’ETABLISSEMENT
                </CustomText>
                <CustomText variant="description" color="lightGrey">
                  Ces photos appairantront dans la gallerie
                </CustomText>
              </Box>
              {!merchant?.coverImage && (
                <ErrorMessage message="En l'absence de photo de couverture, votre établissement n'apparaîtra pas en ligne" />
              )}
              <Box width="100%">
                <MerchantCarouselPicker
                  setFocus={(img: string) => {
                    dispatchFocus({ action: "ON_FOCUS", value: { img: img } });
                  }}
                  mainImage={merchant?.coverImage}
                  otherImages={[...merchant.images].reverse()}
                  onAddMainImage={handleAddCoverImage}
                  onRemoveMainImage={handleRemoveCoverImage}
                  onAddImage={handleAddGalleryImage}
                  onRemoveImage={handleRemoveGalleryImage}
                  maxImages={15}
                />
              </Box>
            </Box>
          </ScrollView>
          <BottomButton
            onPress={validationLoading ? () => {} : handleUpdateMerchant}
            title="Valider"
            isLoading={validationLoading}
          />
        </>
      )}
    </Box>
  );
};

export default MerchantPhotos;

const styles = StyleSheet.create({
  focusPictureWrapper: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginBottom: 96,
    backgroundColor: "#000",
    borderRadius: 8,
    width: "100%",
  },
  picture: {
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#0001",
    height: "100%",
    width: "100%",
    borderRadius: 16,
  },
  pictureLabel: {
    backgroundColor: theme.colors.primaryButton,
    position: "absolute",
    bottom: 8,
    right: "30%",
    left: "30%",
    height: 26,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationLabel: {
    backgroundColor: theme.colors.primaryButton,
    position: "absolute",
    bottom: 8,
    right: 8,
    height: 32,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  pictureLabelText: {
    color: "#fff",
    fontSize: 13,
    marginHorizontal: 8,
  },
  pictureButton: {
    backgroundColor: theme.colors.primaryButton,
    position: "absolute",
    bottom: "50%",
    height: 40,
    width: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  previousPictureButton: {
    left: 16,
  },
  nextPictureButton: {
    right: 16,
  },
  pictureNavigationText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 22,
    lineHeight: 22,
  },
});
