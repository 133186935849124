import { createNativeStackNavigator } from "@react-navigation/native-stack";

import NewQuote from "../../../screens/Accounting/Quotes/NewQuote";
import QuotesList from "../../../screens/Accounting/Quotes/QuotesList";
import QuotesListDetails from "../../../screens/Accounting/Quotes/QuotesListDetails";

import type { QuotesStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<QuotesStackParamList>();

const QuotesStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
        // header: () => <MyTabs />,
      }}
      initialRouteName="QUOTES_LIST"
    >
      <Stack.Screen name="QUOTES_LIST" component={QuotesList} />
      <Stack.Screen name="QUOTES_LIST_DETAILS" component={QuotesListDetails} />

      {/* <Stack.Group screenOptions={{ presentation: "modal" }}> */}
      <Stack.Screen name="NEW_QUOTE" component={NewQuote} />
      {/* </Stack.Group> */}
      {/* <Stack.Screen name="BOOKING_AGENDA" component={BookingList} /> */}
      {/* <Stack.Screen name="BOOKING_SCHEDULE" component={BookingList} /> */}
    </Stack.Navigator>
  );
};

export default QuotesStack;
