import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import DateVisibilitySection from "../../../../../components/DateVisibilitySection";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import type {
  DeliverySettingsFragment,
  PricingRateFragment,
  VisibilitySettings,
} from "../../../../../graphql/generated/schema";
import {
  GetDeliverySettingsDocument,
  useGetDeliverySettingsLazyQuery,
  useGetPricingRatesLazyQuery,
  useUpdateDeliverySettingsMutation,
} from "../../../../../graphql/generated/schema";
import { CLICK_STATUS } from "../../../../../types";
import { removeTypeNames } from "../../../../../utils/common";

interface DeliveryGeneralSettingsProps {
  goBack?: () => void;
}

const DEFAULT_STATUSES = [
  {
    label: "Validé",
    key: CLICK_STATUS.VALIDATED.toString(),
  },
  {
    label: "Préparé",
    key: CLICK_STATUS.PREPARED.toString(),
  },
  {
    label: "Términé",
    key: CLICK_STATUS.ENDED.toString(),
  },
];

const DeliveryGeneralSettings = ({ goBack }: DeliveryGeneralSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<DeliverySettingsFragment | null>(null);
  const [pricingRates, setPricingRates] = useState<PricingRateFragment[]>([]);

  const [getOnlineSalesSettings] = useGetDeliverySettingsLazyQuery();
  const [updateOnlineSalesSettings] = useUpdateDeliverySettingsMutation();
  const [getPricingRates] = useGetPricingRatesLazyQuery();

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getPricingRates({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      setPricingRates(data?.getPricingRates || []);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getOnlineSalesSettings();

      if (data) {
        setSettings(data.getDeliverySettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPricingRates();
    handleGetSettings();
  }, []);

  const handleUpdatettings = async () => {
    try {
      const updates = removeTypeNames(settings);
      delete updates._id;

      await updateOnlineSalesSettings({
        variables: {
          updates,
        },
        refetchQueries: [
          {
            query: GetDeliverySettingsDocument,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettingsData = (
    key: keyof DeliverySettingsFragment,
    value: DeliverySettingsFragment[keyof DeliverySettingsFragment],
  ) => {
    setSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  const updateVisibilitySettingsData = (
    key: keyof VisibilitySettings,
    value: VisibilitySettings[keyof VisibilitySettings],
  ) => {
    setSettings(prev => {
      if (prev) {
        return {
          ...prev,
          visibilitySettings: {
            ...(prev.visibilitySettings || {}),
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  if (loading) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          Loading...
        </CustomText>
      </Box>
    );
  }

  if (!settings) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          Error...
        </CustomText>
      </Box>
    );
  }

  const getStartTime = () => {
    const { visibilitySettings } = settings;

    const isRecurring = visibilitySettings?.isRecurring || false;

    const time = isRecurring
      ? visibilitySettings?.recurringDates?.startTime
      : visibilitySettings?.specificDates?.startTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };

  const getEndTime = () => {
    const { visibilitySettings } = settings;

    const isRecurring = visibilitySettings?.isRecurring || false;

    const time = isRecurring
      ? visibilitySettings?.recurringDates?.endTime
      : visibilitySettings?.specificDates?.endTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };

  const updateDays = (days: number[]) => {
    setSettings(prev => {
      if (prev) {
        const { visibilitySettings } = prev;
        return {
          ...prev,
          visibilitySettings: {
            ...visibilitySettings,
            recurringDates: {
              ...visibilitySettings?.recurringDates,
              days,
            },
          },
        };
      }
      return null;
    });
  };

  const updateSpecificDates = (type: "startDate" | "endDate", date: Date) => {
    setSettings(prev => {
      if (prev) {
        const { visibilitySettings = {} } = prev;
        return {
          ...prev,
          visibilitySettings: {
            ...visibilitySettings,
            specificDates: {
              ...visibilitySettings?.specificDates,
              [type]: date?.toISOString(),
            },
          },
        };
      }
      return null;
    });
  };

  const updateTimes = (type: "startTime" | "endTime", time: string) => {
    setSettings(prev => {
      if (prev) {
        const { visibilitySettings } = prev;
        return {
          ...prev,
          visibilitySettings: {
            ...visibilitySettings,
            specificDates: {
              ...visibilitySettings?.specificDates,
              [type]: time,
            },
            recurringDates: {
              ...visibilitySettings?.recurringDates,
              [type]: time,
            },
          },
        };
      }
      return null;
    });
  };

  const updateIsRecurring = (isRecurring: boolean) => {
    setSettings(prev => {
      if (prev) {
        const { visibilitySettings = {} } = prev;
        return {
          ...prev,
          visibilitySettings: {
            ...visibilitySettings,
            isRecurring,
          },
        };
      }
      return null;
    });
  };

  const handleDisplayPricingRates = () => {
    return pricingRates.map(pricingRate => ({
      label: pricingRate.name,
      key: pricingRate._id,
    }));
  };

  const getSelectedPricingRate = () => {
    const { pricingRate } = settings;

    const rateId = typeof pricingRate === "string" ? pricingRate : pricingRate?._id;

    return rateId ? [rateId] : [];
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Paramètres"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="s">
          <ToggleInputLine
            text="Activer le module"
            value={settings.isEnabled}
            onValueChange={value => updateSettingsData("isEnabled", value)}
          />
        </Box>
        <Box mt="s">
          <SelectButton
            isMultiSelect={false}
            options={handleDisplayPricingRates()}
            selectedOptions={getSelectedPricingRate()}
            onPress={([id]) => updateSettingsData("pricingRate", id)}
            placeHolder="Tarification"
          />
        </Box>

        <Box mt="s">
          <ToggleInputLine
            text="Autoriser le paiement à la livraison"
            value={settings.isLocalPaymentEnabled}
            onValueChange={value => updateSettingsData("isLocalPaymentEnabled", value)}
          />
        </Box>

        <Box mt="s">
          <ToggleInputLine
            text="Autoriser le paiement en ligne"
            value={settings.isOnlinePaymentEnabled}
            onValueChange={value => updateSettingsData("isOnlinePaymentEnabled", value)}
          />
        </Box>

        <Box mt="s">
          <ToggleInputLine
            text="Obliger le paiement en ligne a partir de"
            value={settings.arePaymentLimitsEnabled}
            onValueChange={value => updateSettingsData("arePaymentLimitsEnabled", value)}
          />
        </Box>

        {settings.arePaymentLimitsEnabled && (
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                placeHolder="0"
                initialValue={settings.minArticlesPerOrder.toString()}
                onChangeText={v => updateSettingsData("minArticlesPerOrder", +v)}
                boxProps={{
                  minWidth: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Box
                ml="s"
                p="s"
                backgroundColor="disabled"
                borderRadius="button"
                alignItems="center"
                justifyContent="center"
                minHeight={50}
                minWidth={50}
              >
                <CustomText variant="content" color="primaryTextColor">
                  Articles
                </CustomText>
              </Box>
            </Box>
            <CustomText variant="content" color="primaryTextColor">
              ou
            </CustomText>
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                placeHolder="0"
                initialValue={settings.minPricePerOrder.toString()}
                onChangeText={v => updateSettingsData("minPricePerOrder", +v)}
                boxProps={{
                  minWidth: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <Box
                ml="s"
                p="s"
                backgroundColor="disabled"
                borderRadius="button"
                alignItems="center"
                justifyContent="center"
                minHeight={50}
                minWidth={50}
              >
                <CustomText variant="content" color="primaryTextColor">
                  €
                </CustomText>
              </Box>
            </Box>
          </Box>
        )}

        {/* <Box mt="s">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Statut par défaut d'une commande de passage
          </CustomText>
          <SelectButton
            isMultiSelect={false}
            options={DEFAULT_STATUSES}
            selectedOptions={[]}
            onPress={() => {}}
            placeHolder="Statut"
          />
        </Box> */}

        <Box mt="m">
          <InputSectionTitle text="Zones" />
        </Box>

        <Box>
          <ToggleInputLine text="Délimiter le périmètre de livraison" />
        </Box>

        <Box mt="m">
          <Box mt="s">
            <Box p="s" backgroundColor="disabled" borderRadius="button">
              <CustomText variant="content" color="primaryTextColor">
                Ajouter un zone
              </CustomText>
            </Box>
          </Box>
          <Box mt="s">
            <Box p="s" backgroundColor="disabled" borderRadius="button">
              <CustomText variant="content" color="primaryTextColor">
                Zone 1
              </CustomText>
            </Box>
          </Box>
        </Box>

        <Box mt="m">
          <InputSectionTitle text="Horaires" />
        </Box>

        <Box>
          <ToggleInputLine
            text="Autoriser livraison “Dès que possible”"
            value={settings.asapDelivery}
            onValueChange={value => updateSettingsData("asapDelivery", value)}
          />
        </Box>
        <Box>
          <ToggleInputLine
            text="Spécifier les jours et heures
de livraison"
            value={settings.hasDeliveryTimes}
            onValueChange={value => updateSettingsData("hasDeliveryTimes", value)}
          />
        </Box>

        {settings.hasDeliveryTimes && (
          <Box mt="m">
            <DateVisibilitySection
              days={settings.visibilitySettings?.recurringDates.days || []}
              startDate={settings.visibilitySettings?.specificDates.startDate}
              endDate={settings.visibilitySettings?.specificDates.endDate}
              startTime={getStartTime()}
              endTime={getEndTime()}
              isRecurring={!!settings.visibilitySettings?.isRecurring}
              updateDays={val => updateDays(val)}
              updateStartDate={date => updateSpecificDates("startDate", date)}
              updateEndDate={date => updateSpecificDates("endDate", date)}
              updateStartTime={t => updateTimes("startTime", t)}
              updateEndTime={t => updateTimes("endTime", t)}
              updateReccuring={updateIsRecurring}
            />
          </Box>
        )}

        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Message"
            initialValue={settings.message}
            onChangeText={t => updateSettingsData("message", t)}
            boxProps={{
              minHeight: 100,
              alignItems: "flex-start",
              pt: "s",
            }}
          />
        </Box>
        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Information complémentaire"
            initialValue={settings.complimentaryInformation}
            onChangeText={t => updateSettingsData("complimentaryInformation", t)}
            boxProps={{
              minHeight: 100,
              alignItems: "flex-start",
              pt: "s",
            }}
          />
        </Box>
        <Box marginVertical="s">
          <CustomTextInput
            placeHolder="Pied de page"
            initialValue={settings.footerInformation}
            onChangeText={t => updateSettingsData("footerInformation", t)}
            boxProps={{
              minHeight: 100,
              alignItems: "flex-start",
              pt: "s",
            }}
          />
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleUpdatettings}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DeliveryGeneralSettings;
