import { TouchableOpacity } from "react-native";

import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface MenuStepCardProps {
  name: string;
  nbProducts: number;
  isEnabled: boolean;
  updatedEnabled: (value: boolean) => void;
  onPressIn?: () => void;
}

const MenuStepCard = ({
  name,
  nbProducts,
  isEnabled,
  updatedEnabled,
  onPressIn,
}: MenuStepCardProps) => {
  return (
    <Box
      style={{
        marginVertical: 8,
        marginRight: 8,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flex={0.95}
      >
        <Box flexDirection="row" alignItems="center">
          <TouchableOpacity onPressIn={onPressIn}>
            <Box minHeight={25} minWidth={25} paddingHorizontal="xs">
              <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
            </Box>
          </TouchableOpacity>
          <CustomText ml="s" variant="content" color="primaryTextColor">
            {name}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box
            mr="s"
            width={20}
            height={20}
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            backgroundColor="danger"
          >
            <CustomText variant="content" color="white">
              {nbProducts}
            </CustomText>
          </Box>
          <Toggle value={isEnabled} onChange={updatedEnabled} />
        </Box>
      </Box>

      <RIGHT_ARROW fill={PALETTE.green} width={15} height={15} />
    </Box>
  );
};

export default MenuStepCard;
