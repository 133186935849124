import CASH from "../../../../assets/icons/BASE/CASH.svg";
import CREDIT_CARD from "../../../../assets/icons/BASE/CREDIT_CARD.svg";
import type { PaymentOrder } from "../../../graphql/generated/schema";
import { Click_Status, Payment_Type } from "../../../graphql/generated/schema";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";

interface OnlineOrderCardPaymentTypeProps {
  payment: Omit<PaymentOrder, "paymentIntentId">;
  status: Click_Status;
}
const ICON_SIZE = 16;

const OnlineOrderCardPaymentType = ({
  payment,
  status,
}: OnlineOrderCardPaymentTypeProps) => {
  const { paid, paymentType } = payment;
  const determinePaymentStatus = (): PALETTE_COLORS => {
    const IS_CANCELLED =
      status === Click_Status.Noshow ||
      status === Click_Status.AutomaticCancel ||
      status === Click_Status.CancelledByOwner ||
      status === Click_Status.CancelledByUser;

    const IS_REFUNDED = status === Click_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "red";

    return paid ? "green" : "orange";
  };
  const determinePaymentType = () => {
    const color = determinePaymentStatus();

    switch (paymentType) {
      case Payment_Type.Cash:
        return <CASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case Payment_Type.Card:
        return <CREDIT_CARD width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case Payment_Type.None:
        return <CASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;

      default:
        break;
    }
  };

  return <Box>{determinePaymentType()}</Box>;
};

export default OnlineOrderCardPaymentType;
