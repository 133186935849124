import { format } from "date-fns";
import * as WebBrowser from "expo-web-browser";

import PDF from "../../../assets/icons/BASE/PDF.svg";
import type { GetSmsPaymentTransactionsQuery } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Touchable from "../Touchable/index";

interface SmsPaymentInvoiceCardProps {
  smsInvoice: GetSmsPaymentTransactionsQuery["getSmsPaymentTransactions"][number];
}

const SmsPaymentInvoiceCard = ({ smsInvoice }: SmsPaymentInvoiceCardProps) => {
  const handleViewInvoice = async () => {
    if (smsInvoice.receiptUrl) {
      await WebBrowser.openBrowserAsync(smsInvoice.receiptUrl, {
        windowFeatures: {
          target: "_blank",
        },
      });
    }
  };

  return (
    <Touchable onPress={handleViewInvoice}>
      <Box p="s">
        <Box flexDirection="row" alignItems="flex-start" justifyContent="space-between">
          <Box>
            <CustomText variant="label" color="primaryTextColor">
              {format(new Date(smsInvoice?.createdAt), "dd MMMM yyyy")}
            </CustomText>
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Box mr="m" flexDirection="row" alignItems="center">
              <CustomText mr="m" variant="label" color="primaryTextColor">
                {formatCurrencyPrice(smsInvoice?.smsPack?.price, "fr", "eur")}
              </CustomText>
              <PDF height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </Box>
        </Box>

        <Box>
          <CustomText variant="content" color="primaryTextColor">
            {smsInvoice?.smsPack?.nbOfSms} SMS
          </CustomText>
        </Box>
      </Box>
    </Touchable>
  );
};

export default SmsPaymentInvoiceCard;
