import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import type { THEME_COLORS } from "../../../theme";
import Box from "../../Base/Box";
import SettingsButton from "../SettingsButton";

export interface SETTING_BUTTON {
  icon: JSX.Element;
  title: string;
  hasOptions: boolean;
  screenName: keyof SettingsStackParamList;
  color?: THEME_COLORS;
  action?: string;
}

interface SettingsButtonBlockProps {
  buttons: SETTING_BUTTON[];
  selectedButton?: string;
  onPress: (button: SETTING_BUTTON) => void;
  boxStyles?: React.ComponentProps<typeof Box>;
  disallowedScreens?: string[];
}

const SettingsButtonBlock = ({
  buttons,
  boxStyles,
  onPress,
  selectedButton,
  disallowedScreens,
}: SettingsButtonBlockProps) => {
  return (
    <Box backgroundColor="disabled" borderRadius="button" {...boxStyles}>
      {buttons.map((button, idx) => {
        const IS_SELECTED = button.screenName === selectedButton;

        if (disallowedScreens?.includes(button.screenName)) {
          return null;
        }

        return (
          <Box
            key={idx}
            borderRadius="button"
            borderWidth={1}
            borderColor={IS_SELECTED ? "success" : "disabled"}
            paddingHorizontal="s"
          >
            <SettingsButton
              {...{
                color: button.color,
                leftIcon: button.icon,
                text: button.title,
                onPress: () => onPress(button),
                shouldDisplayRightIcon: button.hasOptions,
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default SettingsButtonBlock;
