import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import Loader from "../../../../../components/Loader";
import ProductCard from "../../../../../components/ProductCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { ProductFragment } from "../../../../../graphql/generated/schema";
import { useGetProductsLazyQuery } from "../../../../../graphql/generated/schema";

const CategoryAddProduct = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { selectedProducts = [], onSubmit } = params;
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, offset: 0 });
  const [products, setProducts] = useState<ProductFragment[]>([]);
  const [finalProducts, setFinalProducts] =
    useState<{ _id: string; name: string }[]>(selectedProducts);

  const [getProducts] = useGetProductsLazyQuery();

  const handleSubmit = () => {
    onSubmit(finalProducts);
    navigation.goBack();
  };

  const handleAddProduct = (id: string, name: string) => {
    setFinalProducts(prev => {
      const isExists = prev.find(p => p._id === id);

      if (isExists) {
        return prev.filter(p => p._id !== id);
      }

      return [...prev, { _id: id, name }];
    });
  };

  const handleGetProducts = async () => {
    try {
      const { data } = await getProducts({
        variables: {
          pagination,
        },
      });

      if (data?.getProducts) {
        setProducts(data.getProducts);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetProducts();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Séléctionner les produits"
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>
      <Box mt="m" flex={1}>
        <FlatList
          data={products}
          keyExtractor={item => item._id}
          contentContainerStyle={{ paddingBottom: 150 }}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleAddProduct(item._id, item.name)}>
                <ProductCard
                  showEnableToggle={false}
                  isSelected={!!finalProducts.find(o => o._id === item._id)}
                  name={item.name}
                  nbGroupOptions={item.groupOptions.length}
                  image={item.picture}
                  isEnabled={item.isEnabled}
                  nbCategories={item.categories.length}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  updateEnabled={() => {}}
                  price={item.basePrice.amount}
                />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
      <BottomButton onPress={handleSubmit} title="Valider" />
    </Box>
  );
};

export default CategoryAddProduct;
