import { format, isBefore } from "date-fns";
import React, { useState } from "react";

import type { BookingFragment } from "../../../graphql/generated/schema";
import { Booking_Status, Origin } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ButtonActionsListModal from "../../BottomButtomWithActions/ButtonActionsListModal";
import type { BUTTON_ACTION } from "../../BottomButtomWithActions/index";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import CustomCalendar, { CALENDAR_VIEW } from "../../CustomCalendar";

interface BookingActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (key: string) => void;
  handleCancel?: () => void;
  onDuplicateConfirm: (dates: Date[]) => void;
  booking: BookingFragment;
}

enum BOOKING_ACTIONS {
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE",
  MODIFY = "MODIFY",
}

const BookingActionsModal = ({
  isOpen,
  onClose,
  handleSubmit,
  handleCancel,
  onDuplicateConfirm,
  booking,
}: BookingActionsModalProps) => {
  const {
    merchantClient,
    status,
    source,
    prepaidPayment,
    payment,
    otp,
    otpRequested,
    prepaidPaymentRequested,
  } = booking;
  const [action, setAction] = useState<BOOKING_ACTIONS | undefined>(undefined);
  const [dates, setDates] = React.useState<Date[]>([]);

  const getButtonActions = () => {
    if (status === Booking_Status.Refunded) return [];
    if (!merchantClient) return [];

    const IS_BEFORE_CURRENT_DATE = isBefore(new Date(booking?.date), new Date());

    const BUTTON_ACTIONS: BUTTON_ACTION[] = [];

    const CAN_DUPLICATE = source !== Origin.Google;

    if (handleCancel) {
      BUTTON_ACTIONS.push({
        title: "ANNULER",
        key: "CANCEL",
        variant: "primary",
        color: "danger",
      });
    }

    if (CAN_DUPLICATE) {
      BUTTON_ACTIONS.unshift({
        title: "DUPLIQUER LA RÉSERVATION",
        key: "DUPLICATE",
        variant: "outline",
        color: "success",
      });
    }

    const CAN_DELETE =
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    if (CAN_DELETE) {
      BUTTON_ACTIONS.unshift({
        title: "SUPPRIMER LA RÉSERVATION",
        key: "DELETE",
        variant: "outline",
        color: "danger",
      });
    }

    const CAN_ASK_FOR_OTP =
      status !== Booking_Status.Ended &&
      status !== Booking_Status.CancelledByOwner &&
      status !== Booking_Status.CancelledByUser &&
      status !== Booking_Status.ModifiedByOwner &&
      status !== Booking_Status.ModifiedByUser &&
      status !== Booking_Status.Seated &&
      !prepaidPayment?.paid &&
      !prepaidPaymentRequested &&
      !otpRequested &&
      !otp?.code;

    if (CAN_ASK_FOR_OTP && !IS_BEFORE_CURRENT_DATE) {
      BUTTON_ACTIONS.unshift({
        title: "VÉRIFICATION TÉLÉPHONIQUE",
        key: "OTP_REQUEST",
        variant: "outline",
        color: "orange",
      });
    }

    const CAN_ASK_FOR_PREPAID =
      status !== Booking_Status.Ended &&
      status !== Booking_Status.CancelledByOwner &&
      status !== Booking_Status.CancelledByUser &&
      status !== Booking_Status.ModifiedByOwner &&
      status !== Booking_Status.ModifiedByUser &&
      status !== Booking_Status.Seated &&
      !prepaidPayment?.paid &&
      !prepaidPaymentRequested &&
      !payment?.paid &&
      !otpRequested;

    if (CAN_ASK_FOR_PREPAID && !IS_BEFORE_CURRENT_DATE) {
      BUTTON_ACTIONS.unshift({
        title: "DEMANDER UNE EMPREINTE BANCAIRE",
        key: "PREPAID_REQUEST",
        variant: "outline",
        color: "orange",
      });
    }

    return BUTTON_ACTIONS;
  };

  const handleClose = () => {
    setDates([]);
    onClose();
    setAction(undefined);
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        handleSubmit(key);
        handleClose();
      } else if (key === "PREPAID_REQUEST") {
        handleSubmit(key);
        handleClose();
      } else if (key === "OTP_REQUEST") {
        handleSubmit(key);
        handleClose();
      } else if (key === "DUPLICATE") {
        setAction(BOOKING_ACTIONS.DUPLICATE);
      } else if (key === "MODIFY") {
        handleSubmit(key);
        handleClose();
      } else if (key === "CANCEL") {
        handleCancel?.();
        handleClose();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleConfirm = () => {
    onDuplicateConfirm(dates);
    handleClose();
  };

  const onDateSelected = (date: Date) => {
    const existingDate = dates.find(d => d.getTime() === date.getTime());

    if (existingDate) {
      setDates(dates.filter(d => d.getTime() !== date.getTime()));
    } else {
      setDates([...dates, date]);
    }
  };

  const getMarkedDates = () => {
    const markedDates: Record<string, unknown> = {};

    dates.forEach(date => {
      markedDates[format(date, "yyyy-MM-dd")] = {
        selected: true,
        marked: true,
        selectedColor: PALETTE.green,
      };
    });

    markedDates[format(new Date(booking.date), "yyyy-MM-dd")] = {
      disabled: true,
      disableTouchEvent: true,
    };

    return markedDates;
  };

  const displayDuplicateCalendarModal = () => {
    return (
      <>
        <CustomCalendar
          onDateChange={onDateSelected}
          calendarView={CALENDAR_VIEW.MONTH}
          minDate={new Date()}
          markedDates={getMarkedDates()}
          markingType="simple"
        />

        <Box mt="m">
          <CustomButton
            disabled={dates.length === 0}
            buttonVariant="primaryButton"
            buttonColor={dates.length === 0 ? "lightGrey" : "success"}
            onPress={handleConfirm}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </>
    );
  };

  const dislayContent = () => {
    const BUTTON_ACTIONS: BUTTON_ACTION[] = getButtonActions();

    if (action === BOOKING_ACTIONS.DUPLICATE) {
      return displayDuplicateCalendarModal();
    }

    return <ButtonActionsListModal actions={BUTTON_ACTIONS} onPress={handleOnSubmit} />;
  };

  return (
    <ContentModal
      title=""
      isVisible={isOpen}
      onClose={handleClose}
      styles={{
        maxWidth: 450,
        height: "auto",
      }}
    >
      <>{isOpen && dislayContent()}</>
    </ContentModal>
  );
};

export default BookingActionsModal;
