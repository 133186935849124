import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import KeyboardAwareScrollView from "../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../components/Loader";
import NumberInputLine from "../../../../components/NumberInputLine";
import ScreenHeader from "../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  GetBookingPersonsGoalsQuery,
  GetOnlineOrderGoalsQuery,
} from "../../../../graphql/generated/schema";
import {
  useGetBookingPersonsGoalsLazyQuery,
  useGetOnlineOrderGoalsLazyQuery,
  useUpdateBookingPersonsGoalsMutation,
  useUpdateOnlineOrderGoalsMutation,
} from "../../../../graphql/generated/schema";
import { getSafeNumberFromInput, removeTypeNames } from "../../../../utils/common";

interface StatGoalsSettingsProps {
  goBack?: () => void;
}

const StatGoalsSettings = ({ goBack }: StatGoalsSettingsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [bookignPersonsGoalsSettings, setBookignPersonsGoalsSettings] = useState<
    GetBookingPersonsGoalsQuery["getBookingPersonsGoals"] | null
  >(null);
  const [ordersGoals, setOrdersGoals] = useState<
    GetOnlineOrderGoalsQuery["getOnlineOrderGoals"] | null
  >(null);

  const [getBookingPersonsGoals] = useGetBookingPersonsGoalsLazyQuery();
  const [getOrdersGoals] = useGetOnlineOrderGoalsLazyQuery();
  const [updateBookingPersonGoals] = useUpdateBookingPersonsGoalsMutation();
  const [updateOrderGoals] = useUpdateOnlineOrderGoalsMutation();

  const handleGetBookingPersonsGoals = async () => {
    try {
      const { data } = await getBookingPersonsGoals();

      if (data?.getBookingPersonsGoals) {
        setBookignPersonsGoalsSettings(data.getBookingPersonsGoals);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetOrderGoals = async () => {
    try {
      const { data } = await getOrdersGoals();

      if (data?.getOnlineOrderGoals) {
        setOrdersGoals(data.getOnlineOrderGoals);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleInit = async () => {
    try {
      await handleGetBookingPersonsGoals();
      await handleGetOrderGoals();
    } catch (err) {
      console.log("err init", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleSubmit = async () => {
    try {
      if (!bookignPersonsGoalsSettings || !ordersGoals) return;

      const bookingGoals = removeTypeNames(bookignPersonsGoalsSettings);
      delete bookingGoals._id;

      const updatedAtOrderGoals = removeTypeNames(ordersGoals);
      delete updatedAtOrderGoals._id;

      await updateBookingPersonGoals({
        variables: {
          updates: bookingGoals,
        },
      });

      await updateOrderGoals({
        variables: {
          updates: updatedAtOrderGoals,
        },
      });

      handleGoBack();
    } catch (err) {
      console.log("update err stat goals", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_STAT_GOALS",
            message: "Une erreur s'est produite lors de la mise à jour des objectifs.",
          },
        ],
        "error",
      );
    }
  };

  if (loading) return <Loader />;

  if (!bookignPersonsGoalsSettings || !ordersGoals)
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box>
          <ScreenHeader
            title="Statistiques et objectifs"
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>

        <ErrorMessage message="Une erreur s'est produite lors du chargement des paramètres de performance." />
      </Box>
    );

  const handleBookingGoalChange = (
    key: keyof GetBookingPersonsGoalsQuery["getBookingPersonsGoals"],
    value: number,
  ) => {
    setBookignPersonsGoalsSettings(prev =>
      !prev
        ? null
        : {
            ...prev,
            [key]: value,
          },
    );
  };

  const handleOrderGoalChange = (
    key: keyof GetOnlineOrderGoalsQuery["getOnlineOrderGoals"],
    value: number,
  ) => {
    setOrdersGoals(prev =>
      !prev
        ? null
        : {
            ...ordersGoals,
            [key]: value,
          },
    );
  };

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Statistiques et objectifs"
          hasBackButton
          // eslint-disable-next-line max-len
          description="Pour optimiser les performances de votre établissement, vous avez la possibilité de spécifier des objectifs afin de pouvoir les comparer dans le module de STATISTIQUES."
          onBackPress={handleGoBack}
        />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box mt="m">
            <InputSectionTitle text="RÉSERVATIONS" />
            <CustomText variant="content" color="primaryTextColor">
              Veuillez saisir les objectifs de couverts
            </CustomText>

            <Box ml="s">
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Lundi"
                  placeHolder="Lundi"
                  value={bookignPersonsGoalsSettings.monday}
                  onValueChange={v =>
                    handleBookingGoalChange("monday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Mardi"
                  placeHolder="Mardi"
                  value={bookignPersonsGoalsSettings.tuesday}
                  onValueChange={v =>
                    handleBookingGoalChange("tuesday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Mercredi"
                  placeHolder="Mercredi"
                  value={bookignPersonsGoalsSettings.wednesday}
                  onValueChange={v =>
                    handleBookingGoalChange("wednesday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Jeudi"
                  placeHolder="Jeudi"
                  value={bookignPersonsGoalsSettings.thursday}
                  onValueChange={v =>
                    handleBookingGoalChange("thursday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Vendredi"
                  placeHolder="Vendredi"
                  value={bookignPersonsGoalsSettings.friday}
                  onValueChange={v =>
                    handleBookingGoalChange("friday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Samedi"
                  placeHolder="Samedi"
                  value={bookignPersonsGoalsSettings.saturday}
                  onValueChange={v =>
                    handleBookingGoalChange("saturday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Dimanche"
                  placeHolder="Dimanche"
                  value={bookignPersonsGoalsSettings.sunday}
                  onValueChange={v =>
                    handleBookingGoalChange("sunday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box mt="m">
            <InputSectionTitle text="VENTE EN LIGNE" />
            <CustomText variant="content" color="primaryTextColor">
              Veuillez saisir le chiffre d'affaire souhaité
            </CustomText>

            <Box ml="s">
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Lundi"
                  placeHolder="Lundi"
                  value={ordersGoals.monday}
                  onValueChange={v =>
                    handleOrderGoalChange("monday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Mardi"
                  placeHolder="Mardi"
                  value={ordersGoals.tuesday}
                  onValueChange={v =>
                    handleOrderGoalChange("tuesday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Mercredi"
                  placeHolder="Mercredi"
                  value={ordersGoals.wednesday}
                  onValueChange={v =>
                    handleOrderGoalChange("wednesday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Jeudi"
                  placeHolder="Jeudi"
                  value={ordersGoals.thursday}
                  onValueChange={v =>
                    handleOrderGoalChange("thursday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Vendredi"
                  placeHolder="Vendredi"
                  value={ordersGoals.friday}
                  onValueChange={v =>
                    handleOrderGoalChange("friday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Samedi"
                  placeHolder="Samedi"
                  value={ordersGoals.saturday}
                  onValueChange={v =>
                    handleOrderGoalChange("saturday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
              <Box mb="xs">
                <NumberInputLine
                  textVariant="content"
                  color="lightGrey"
                  text="Dimanche"
                  placeHolder="Dimanche"
                  value={ordersGoals.sunday}
                  onValueChange={v =>
                    handleOrderGoalChange("sunday", getSafeNumberFromInput(v))
                  }
                />
              </Box>
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default StatGoalsSettings;
