/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native";

import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import PeriodSelection from "../../PeriodSelection";
import ScreenHeader from "../../ScreenHeader";
import Toggle from "../../Toggle";

const PublicationVisbility = () => {
  const navigation = useNavigation();
  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <ScreenHeader hasBackButton onBackPress={navigation.goBack} title="Visibilité" />

        <Box mt="m">
          <CustomText variant="label" color="primaryTextColor">
            Periodicité
          </CustomText>
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="content" color="primaryTextColor">
            Récurrent
          </CustomText>
          <Toggle onChange={() => {}} value={true} />
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="content" color="primaryTextColor">
            A date spécifique
          </CustomText>
          <Toggle onChange={() => {}} value={true} />
        </Box>

        <Box mt="m">
          <CustomText variant="label" color="primaryTextColor">
            Services
          </CustomText>
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="content" color="primaryTextColor">
            Tous les matins
          </CustomText>
          <Toggle onChange={() => {}} value={true} />
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="content" color="primaryTextColor">
            Tous les midis
          </CustomText>
          <Toggle onChange={() => {}} value={false} />
        </Box>
        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="content" color="primaryTextColor">
            Tous les soirs
          </CustomText>
          <Toggle onChange={() => {}} value={false} />
        </Box>

        <Box mt="s">
          <PeriodSelection />
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        minHeight={90}
        pb="m"
        justifyContent="center"
        backgroundColor="white"
        paddingHorizontal="s"
      >
        <CustomButton
          styles={{ minHeight: 45 }}
          onPress={navigation.goBack}
          buttonVariant="primaryButton"
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PublicationVisbility;
