import * as ScreenOrientation from "expo-screen-orientation";
import { useEffect, useState } from "react";
import { Dimensions } from "react-native";
export enum ORIENTATION {
  PORTRAIT,
  LANDSCAPE,
}

export function useDeviceInformation() {
  const [width, setWidth] = useState(Dimensions.get("window").width);
  const [height, setHeight] = useState(Dimensions.get("window").height);
  const [orientation, setOrientation] = useState<ORIENTATION>(
    width > height ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT,
  );
  const [isSmallDevice, setIsSmallDevice] = useState(height <= 700);

  const checkIfFullScreen = () => {
    return width <= 700 || orientation === ORIENTATION.PORTRAIT;
  };

  useEffect(() => {
    const dimensionsListener = Dimensions.addEventListener(
      "change",
      ({
        window: { width: windowWidth, height: windowHeight },
        screen: { width: screenWidth, height: screenHeight },
      }) => {
        const finalWidth = windowWidth || screenWidth;
        const finalHeight = windowHeight || screenHeight;

        console.log("df", finalWidth, finalHeight);

        setWidth(windowWidth);
        setHeight(windowHeight);
        setIsSmallDevice(windowHeight <= 700);
        console.log("change", windowWidth, windowHeight);
        if (windowWidth > windowHeight) {
          setOrientation(ORIENTATION.PORTRAIT);
        } else {
          setOrientation(ORIENTATION.LANDSCAPE);
        }
      },
    );

    return dimensionsListener.remove();
  }, []);

  useEffect(() => {
    const subscription = ScreenOrientation.addOrientationChangeListener(evt => {
      const IS_PORTRAIT =
        evt.orientationInfo.orientation === ScreenOrientation.Orientation.PORTRAIT_UP ||
        ScreenOrientation.Orientation.PORTRAIT_DOWN ||
        ScreenOrientation.Orientation.UNKNOWN;

      console.log("evt", evt, IS_PORTRAIT);

      setOrientation(IS_PORTRAIT ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE);
    });

    return subscription.remove;
  }, []);

  return {
    width,
    height,
    orientation,
    checkIfFullScreen,
    isSmallDevice,
  };
}
