import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import EMAIL from "../../../../../../assets/icons/BASE/EMAIL.svg";
import INFO from "../../../../../../assets/icons/BASE/INFO.svg";
import PASSWORD_STAR from "../../../../../../assets/icons/BASE/PASSWORD_STAR.svg";
import PHONE from "../../../../../../assets/icons/BASE/PHONE.svg";
import Box from "../../../../../components/Base/Box";
import TabTextInfo from "../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import BottomButton from "../../../../../components/BottomButton";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { MerchantFragment } from "../../../../../graphql/generated/schema";
import { useGetMerchantLazyQuery } from "../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../theme/Palette";

interface MerchantInfoViewProps {
  handleEditPress: () => void;
  goBack?: () => void;
}

const ICON_SIZE = 20;

const MerchantInfoView = ({ handleEditPress, goBack }: MerchantInfoViewProps) => {
  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);

  const [getMerchant] = useGetMerchantLazyQuery();

  const handleGetMerchant = async () => {
    setLoading(true);

    try {
      const { data } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchant();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!merchant) {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader
          title="Établissement"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations de l'établissement." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScrollView showsVerticalScrollIndicator={false}>
        <Box marginVertical="s">
          <ScreenHeader
            title={merchant?.label || ""}
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>

        <Box>
          <TabTextInfo
            title="Nom de l’établissement"
            content={merchant?.label || "-"}
            icon={<INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />}
          />
        </Box>
        <Box>
          <TabTextInfo
            title="Téléphone"
            content={merchant?.phone || "-"}
            icon={<PHONE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />}
          />
        </Box>
        <Box>
          <TabTextInfo
            title="Adresse"
            content={merchant?.address?.formattedAddress || "-"}
            icon={<EMAIL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />}
          />
        </Box>
        {merchant?.description && (
          <Box>
            <TabTextInfo
              title="Description de l’établissement"
              content={merchant?.description}
              icon={
                <PASSWORD_STAR
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              }
            />
          </Box>
        )}

        {merchant?.website && (
          <Box>
            <TabTextInfo
              title="Siteweb"
              content={merchant?.website || "-"}
              icon={
                <INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
              }
            />
          </Box>
        )}

        {/* <Box>
          <TabTextInfo
            title="Langue"
            content={merchant?.language || "-"}
            icon={<INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />}
          />
        </Box> */}
      </ScrollView>
      <BottomButton title="Modifier" onPress={handleEditPress} />
    </Box>
  );
};

export default MerchantInfoView;
