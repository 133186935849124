import { format } from "date-fns";
import { useState } from "react";

import type { OpenHour } from "../../../graphql/generated/schema";
import { createDateWithTime } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import DaysSelector from "../../DaysSelector";
import TimeInput from "../../TimeInput/index";

interface HourProps {
  hour?: OpenHour;
  onChange: (newHour: OpenHour, prevHour?: OpenHour) => void;
  onDelete: (ewHour: OpenHour) => void;
}

const DEFAULT_OPEN_HOUR: OpenHour = {
  days: [],
  openAt: "10:00",
  closeAt: "10:00",
};

const Hour = ({ hour, onChange, onDelete }: HourProps) => {
  const [openHour, setOpenHour] = useState(hour ? hour : DEFAULT_OPEN_HOUR);
  const { days, openAt, closeAt } = openHour;

  const updateDays = (val: number[]) => {
    setOpenHour(prev => ({ ...prev, days: val }));
  };

  const updateTime = (type: "openAt" | "closeAt", time: Date | undefined) => {
    if (time) {
      const formattedTime = format(time, "HH:mm");

      setOpenHour(prev => ({ ...prev, [type]: formattedTime }));
    }
  };

  const handleSubmit = () => {
    onChange(openHour, hour);
  };

  const displayTime = (time: string) => {
    return time ? createDateWithTime(new Date(), time) : new Date();
  };

  return (
    <Box>
      <Box mt="m">
        <DaysSelector selectedDays={days} onDayPress={updateDays} />
      </Box>
      <Box mt="m" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="content" color="primaryTextColor">
          Heure de début
        </CustomText>
        {/* <Box flex={0.5}> */}
        <TimeInput
          date={createDateWithTime(new Date(), openAt)}
          onChange={time => updateTime("openAt", time)}
          placeHolder="Heure de début"
        />
        {/* <RNDateTimePicker
            onChange={(_, date) => updateTime("openAt", date)}
            is24Hour
            minuteInterval={30}
            mode="time"
            value={displayTime(openAt)}
          /> */}
        {/* </Box> */}
      </Box>
      <Box mt="m" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="content" color="primaryTextColor">
          Heure de fin
        </CustomText>
        {/* <Box> */}
        <TimeInput
          date={createDateWithTime(new Date(), closeAt)}
          onChange={time => updateTime("closeAt", time)}
          placeHolder="Heure de fin"
        />
        {/* <RNDateTimePicker
            onChange={(_, date) => updateTime("closeAt", date)}
            minuteInterval={30}
            mode="time"
            value={displayTime(closeAt)}
          /> */}
        {/* </Box> */}
      </Box>

      {hour && (
        <Box mt="m">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="danger"
            onPress={() => onDelete(openHour)}
            styles={{
              minHeight: 45,
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        </Box>
      )}

      <Box mt="m">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="success"
          onPress={handleSubmit}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            ENREGISTRER
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Hour;
