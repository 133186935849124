import React, { useContext } from "react";

import type {
  InternalNotification,
  PopCallFragment,
} from "../../graphql/generated/schema";
import {
  useNewBookingSubscription,
  useNewCallSubscription,
  useNewOrderSubscription,
  useNewReviewSubscription,
} from "../../graphql/generated/schema";
import { AppContext } from "../AppContext/index";

import { SubscriptionsContext } from ".";

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const appContext = useContext(AppContext);
  const [calls, setCalls] = React.useState<PopCallFragment[]>([]);
  const [bookings, setBookings] = React.useState<InternalNotification[]>([]);
  const [orders, setOrders] = React.useState<InternalNotification[]>([]);
  const [reviews, setReviews] = React.useState<InternalNotification[]>([]);

  useNewBookingSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.bookingCreated) {
        const newBooking = subscriptionData.data.bookingCreated;

        setBookings(prev => [...prev, newBooking]);

        appContext.refreshAlerts();
      }
    },
  });

  useNewCallSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.incomingCall) {
        const newCall = subscriptionData.data.incomingCall;

        setCalls(prev => [newCall, ...prev]);
        appContext.refreshAlerts();
      }
    },
  });
  useNewOrderSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.orderCreated) {
        const newOrder = subscriptionData.data.orderCreated;

        setOrders(prev => [...prev, newOrder]);
        appContext.refreshAlerts();
      }
    },
    // onData: ({ data }) => {
    //   if (data.data?.orderCreated) {
    //     const newOrder = data.data.orderCreated;

    //     console.log("onData newOrder", newOrder);

    //     setOrders(prev => [newOrder, ...prev]);
    //     appContext.refreshAlerts();
    //   }
    // },
  });
  useNewReviewSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newReview) {
        const { newReview } = subscriptionData.data;

        setReviews(prev => [...prev, newReview]);
        appContext.refreshAlerts();
      }
    },
  });

  return (
    <SubscriptionsContext.Provider
      value={{
        reviews,
        calls,
        bookings,
        orders,
        setBookings,
        setCalls,
        setOrders,
        setReviews,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionProvider;
