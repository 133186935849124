import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import ErrorMessage from "../../../../../components/ErrorMessage/index";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import type { OnlineSalesSettingsFragment } from "../../../../../graphql/generated/schema";
import {
  GetOnlineSalesSettingsDocument,
  useGetOnlineSalesSettingsLazyQuery,
  useUpdateOnlineSalesSettingsMutation,
} from "../../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../../utils/common";

interface TakeAwayClientSettingsProps {
  goBack?: () => void;
}

const TakeAwayClientSettings = ({ goBack }: TakeAwayClientSettingsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [onlineSalesSettings, setOnlineSalesSettings] =
    useState<OnlineSalesSettingsFragment | null>(null);

  const [getOnlineSalesSettings] = useGetOnlineSalesSettingsLazyQuery();
  const [updateOnlineSalesSettings] = useUpdateOnlineSalesSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getOnlineSalesSettings();

      if (data) {
        setOnlineSalesSettings(data.getOnlineSalesSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handleUpdatettings = async () => {
    if (!onlineSalesSettings) return;

    try {
      const { message, complimentaryInformation, footerInformation } =
        onlineSalesSettings;

      const updates = removeTypeNames({
        message,
        complimentaryInformation,
        footerInformation,
      });

      await updateOnlineSalesSettings({
        variables: {
          updates,
        },
        refetchQueries: [
          {
            query: GetOnlineSalesSettingsDocument,
          },
        ],
      });

      handleGoBack();
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettingsData = (
    key: keyof OnlineSalesSettingsFragment,
    value: OnlineSalesSettingsFragment[keyof OnlineSalesSettingsFragment],
  ) => {
    setOnlineSalesSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!onlineSalesSettings) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Module de commande"
          hasBackButton
          onBackPress={handleGoBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres" />
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Module de commande"
        hasBackButton
        onBackPress={handleGoBack}
        // eslint-disable-next-line max-len
        description="Vous pouvez personnaliser l’ensemble des textes que vos clients verront sur votre module de commande Widget."
      />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 150,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box>
            <Box mt="m">
              <InputSectionTitle text="COMMENTAIRE EN HAUT DE LA PAGE" />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Message"
                initialValue={onlineSalesSettings.message}
                onChangeText={t => updateSettingsData("message", t)}
                multiLine
              />
            </Box>
            <Box mt="m">
              <InputSectionTitle
                text="INFORMATION COMPLÉMENTAIRE
"
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Information complémentaire"
                initialValue={onlineSalesSettings.complimentaryInformation}
                onChangeText={t => updateSettingsData("complimentaryInformation", t)}
                multiLine
              />
            </Box>
            <Box mt="m">
              <InputSectionTitle text="BAS DE PAGE" />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Pied de page"
                initialValue={onlineSalesSettings.footerInformation}
                onChangeText={t => updateSettingsData("footerInformation", t)}
                multiLine
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Enregistrer" onPress={handleUpdatettings} />
    </Box>
  );
};

export default TakeAwayClientSettings;
