import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../components/TextInput";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import type { InvoiceSettingsFragment } from "../../../../graphql/generated/schema";
import {
  useGetInvoiceSettingsLazyQuery,
  useUpdateInvoiceSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface InvoiceSettingsProps {
  goBack?: () => void;
}

const VALIDITY = [
  {
    label: "A la réception",
    key: "0",
  },
  {
    label: "30 jours",
    key: "30",
  },
  {
    label: "60 jours",
    key: "60",
  },
  {
    label: "90 jours",
    key: "90",
  },
];

const InvoiceSettings = ({ goBack }: InvoiceSettingsProps) => {
  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [invoiceSettings, setInvoiceSettings] = useState<InvoiceSettingsFragment | null>(
    null,
  );
  const [acceptConditions, setAcceptConditions] = useState(
    !!invoiceSettings?.acceptConditions,
  );
  const [extraField, setExtraField] = useState(!!invoiceSettings?.extraField);
  const [bankDetails, setBankDetails] = useState(!!invoiceSettings?.bankDetails);

  const [getInvoiceSettings] = useGetInvoiceSettingsLazyQuery();
  const [updateInvoiceSettings] = useUpdateInvoiceSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetInvoiceSettings = async () => {
    try {
      const { data } = await getInvoiceSettings();

      if (data) {
        setInvoiceSettings(data.getInvoiceSettings);
        setExtraField(!!data.getInvoiceSettings?.extraField);
        setBankDetails(!!data.getInvoiceSettings?.bankDetails);
        setAcceptConditions(!!data.getInvoiceSettings?.acceptConditions);
      }
    } catch (err) {
      console.log("err get invoice settings", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetInvoiceSettings();
  }, []);

  const handleSubmit = async () => {
    try {
      const updates = removeTypeNames(invoiceSettings);

      await updateInvoiceSettings({
        variables: {
          updates,
        },
      });
    } catch (err) {
      console.log("err submit", err);
    }
  };

  const updateInvoiceData = (
    key: keyof InvoiceSettingsFragment,
    value: InvoiceSettingsFragment[keyof InvoiceSettingsFragment],
  ) => {
    setInvoiceSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  if (loading) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          Loading...
        </CustomText>
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 150,
        }}
      >
        <Box flex={1}>
          <Box marginVertical="s">
            <ScreenHeader title="Factures" hasBackButton onBackPress={handleGoBack} />
          </Box>

          <Box mt="m">
            <SelectButton
              isMultiSelect={false}
              options={VALIDITY}
              selectedOptions={[invoiceSettings?.paymentPeriod?.toString()]}
              onPress={([option]) => updateInvoiceData("paymentPeriod", +option)}
              placeHolder="Date de règlement"
            />
          </Box>

          <Box mt="m">
            <ToggleInputLine
              text="Autoriser le paiement en ligne"
              value={!!invoiceSettings?.isOnlinePaymentEnabled}
              onValueChange={value => updateInvoiceData("isOnlinePaymentEnabled", value)}
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="Mentions légales" />
          </Box>
          <Box mt="m">
            <ToggleInputLine
              text="Coordonnées bancaires"
              value={!!bankDetails}
              onValueChange={setBankDetails}
            />

            {bankDetails && (
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Coordonnées bancaires"
                  initialValue={invoiceSettings?.bankDetails}
                  onChangeText={t => updateInvoiceData("bankDetails", t)}
                  boxProps={{
                    minHeight: 100,
                    alignItems: "flex-start",
                    pt: "s",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box mt="m">
            <ToggleInputLine
              text="Condition d’acceptation"
              value={!!acceptConditions}
              onValueChange={setAcceptConditions}
            />

            {acceptConditions && (
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Condition d’acceptation"
                  initialValue={invoiceSettings?.acceptConditions}
                  onChangeText={t => updateInvoiceData("acceptConditions", t)}
                  boxProps={{
                    minHeight: 100,
                    alignItems: "flex-start",
                    pt: "s",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box mt="m">
            <ToggleInputLine
              text="Champ libre"
              value={!!extraField}
              onValueChange={setExtraField}
            />

            {extraField && (
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Champ libre"
                  initialValue={invoiceSettings?.extraField}
                  onChangeText={t => updateInvoiceData("extraField", t)}
                  boxProps={{
                    minHeight: 100,
                    alignItems: "flex-start",
                    pt: "s",
                  }}
                />
              </Box>
            )}
          </Box>

          <Box mt="m">
            <InputSectionTitle text="Modèles de mail" />
          </Box>

          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Envoi de facture
            </CustomText>
          </Box>
          <Box mt="m">
            <CustomTextInput
              placeHolder="Objet du mail"
              initialValue={invoiceSettings?.newInvoiceEmailSubjectLine}
              onChangeText={t => updateInvoiceData("newInvoiceEmailSubjectLine", t)}
            />
          </Box>
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Contenu du mail"
              initialValue={invoiceSettings?.newInvoiceEmailBody}
              onChangeText={t => updateInvoiceData("newInvoiceEmailBody", t)}
              boxProps={{
                minHeight: 200,
                alignItems: "flex-start",
                pt: "s",
              }}
            />
          </Box>

          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Envoi de relance de facture
            </CustomText>
          </Box>
          <Box mt="m">
            <CustomTextInput
              placeHolder="Objet du mail"
              initialValue={invoiceSettings?.reminderInvoiceEmailSubjectLine}
              onChangeText={t => updateInvoiceData("reminderInvoiceEmailSubjectLine", t)}
            />
          </Box>
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Contenu du mail"
              initialValue={invoiceSettings?.reminderInvoiceEmailBody}
              onChangeText={t => updateInvoiceData("reminderInvoiceEmailBody", t)}
              boxProps={{
                minHeight: 200,
                alignItems: "flex-start",
                pt: "s",
              }}
            />
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default InvoiceSettings;
