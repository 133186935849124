import { format } from "date-fns";
import { Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import CALENDAR from "../../../../assets/icons/BASE/CALENDAR.svg";
import COMMENT from "../../../../assets/icons/BASE/COMMENT.svg";
import STAR from "../../../../assets/icons/BASE/STAR.svg";
// import GOOGLE from "../../../../assets/icons/BASE/GOOGLE.svg";
import type { ReviewFragment } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";

interface ReviewListCardProps {
  review: ReviewFragment;
  onPress: (id: string) => void;
  borderBottomWidth?: number;
}

const IMAGE_HEIGHT = 40;
const STATUS_BUTTON = 30;

const ReviewListCard = ({
  onPress,
  review,
  borderBottomWidth = LINE_THICKNESS,
}: ReviewListCardProps) => {
  const { _id, dateOfModule, merchantClient, title, comment, reviewRating, source } =
    review;

  const displayDate = () => {
    return format(new Date(dateOfModule), "dd/MM/yyyy");
  };

  return (
    <TouchableOpacity onPress={() => onPress(_id)}>
      <Box
        flexDirection="row"
        alignItems="center"
        minHeight={84}
        // width="100%"
        borderBottomColor="lightGrey"
        borderBottomWidth={borderBottomWidth}
      >
        {merchantClient?.picture && (
          <Image
            source={{
              uri: merchantClient?.picture,
            }}
            style={{
              width: IMAGE_HEIGHT,
              height: IMAGE_HEIGHT,
              borderRadius: 8,
              marginLeft: 8,
            }}
          />
        )}

        <Box flex={1} pl="s">
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flexDirection="row" alignItems="center" mb="xs">
              <CustomText variant="label" color="primaryTextColor">
                {title}
              </CustomText>

              <Box pl="m" flexDirection="row" alignItems="center">
                <STAR height={12} width={12} fill={PALETTE.yellow} />
                <CustomText pl="s" variant="content" color="lightGrey">
                  {reviewRating}/5
                </CustomText>
              </Box>

              <Box ml="m">
                <BookingListCardOrigin origin={source} />
              </Box>
            </Box>
          </Box>
          <Box pt="s" flexDirection="row" alignItems="center">
            <Box flexDirection="row" alignItems="center">
              <CALENDAR width={18} height={18} fill={PALETTE.darkBlue} />
              <CustomText pl="s" variant="description">
                {displayDate()}
              </CustomText>
            </Box>
            <Box flex={1} ml="m" flexDirection="row" alignItems="center">
              <COMMENT height={18} width={18} fill={PALETTE.darkBlue} />
              <CustomText
                pl="s"
                variant="description"
                numberOfLines={3}
                style={{ width: "80%" }}
              >
                {comment}
              </CustomText>
            </Box>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default ReviewListCard;
