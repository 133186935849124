import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useResizeMode } from "react-native-keyboard-controller";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ButtonList from "../../../../components/ButtonList";
import KeyboardAwareScrollView from "../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectModal from "../../../../components/SelectModal";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { MerchantFragment } from "../../../../graphql/generated/schema";
import {
  Merchant_Price_Level,
  useGetMerchantAmbiancesListLazyQuery,
  useGetMerchantCharacteristicsListLazyQuery,
  useGetMerchantLazyQuery,
  useGetMerchantSpokenLanguagesListLazyQuery,
  useGetMerchantTypesListLazyQuery,
  useUpdateMerchantMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";
import {
  convertMerchantAmbiances,
  convertMerchantCharacteristics,
  convertSpokenLanguage,
  merchantTypes,
} from "../../../../utils/merchant";
import MerchantChef from "../MerchantChef";

interface CharacteristicsProps {
  goBack?: () => void;
}

const Characteristics = ({ goBack }: CharacteristicsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);
  const [showChef, setShowChef] = useState(false);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [getMerchant] = useGetMerchantLazyQuery();
  const [updateMerchant] = useUpdateMerchantMutation();
  const [getMerchantTypesList] = useGetMerchantTypesListLazyQuery();
  const [getMerchantCharacteristics] = useGetMerchantCharacteristicsListLazyQuery();
  const [getMerchantAmbiances] = useGetMerchantAmbiancesListLazyQuery();
  const [getMerchantSpokenLanguages] = useGetMerchantSpokenLanguagesListLazyQuery();

  const handleGetMerchant = async () => {
    setLoading(true);

    try {
      const { data } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchant();
  }, []);

  const handleGoBack = () => {
    if (goBack) goBack();
    else navigation.goBack();
  };

  const handleSelectPriceLevel = (priceLevel: Merchant_Price_Level) => {
    setMerchant(prev => (prev === null ? null : { ...prev, priceLevel }));
  };

  const handleSubmit = async () => {
    // const isFormValid = await validateForm();

    // if (!isFormValid) return;

    if (!merchant) return;

    try {
      const updates = removeTypeNames(merchant);
      delete updates._id;
      delete updates.permissions;

      const mTypes = merchant?.merchantTypes.map(type => type._id);
      const characteristics = merchant?.characteristics.map(
        characteristic => characteristic._id,
      );
      const ambiances = merchant?.ambiances.map(ambiance => ambiance._id);
      const spokenLanguages = merchant?.spokenLanguages.map(
        spokenLanguage => spokenLanguage._id,
      );

      const merchantPaymentMethods = merchant?.paymentMethods.map(pm => pm._id);
      const merchantLabels = merchant?.labelsCertifications.map(lc => lc._id);

      if (merchantPaymentMethods) updates.paymentMethods = merchantPaymentMethods;
      if (merchantLabels) updates.labelsCertifications = merchantLabels;

      if (mTypes) updates.merchantTypes = mTypes;
      if (characteristics) updates.characteristics = characteristics;
      if (ambiances) updates.ambiances = ambiances;
      if (spokenLanguages) updates.spokenLanguages = spokenLanguages;

      await updateMerchant({
        variables: { updates },
      });

      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_MERCHANT",
            message: "Une erreur est survenue",
          },
        ],
        "error",
      );

      // setErrors([{ path: "submit", message: err.message }]);

      // setIsSnackBarOpen(true);
    }
  };

  const displayListSelect = (items: { _id: string; name: string }[]) => {
    if (items.length === 0) return "";

    const [firstItem] = items;
    const itemsLength = items.length;

    if (itemsLength === 1) return firstItem.name;

    return `${firstItem.name} (+${itemsLength - 1})`;
  };

  const getTitleByType = (type: string) => {
    if (type === "spokenLanguages") {
      return "Langues parlées";
    }
    if (type === "characteristics") {
      return "Caractéristiques";
    }
    if (type === "merchantTypes") {
      return "Type de restaurant";
    }
    if (type === "ambiances") {
      return "Cadre & Ambiances";
    }
    return "";
  };

  const handleGoToListSelect = async (
    type:
      | "spokenLanguages"
      | "characteristics"
      // | "paymentMethods"
      // | "labelsCertifications"
      | "merchantTypes"
      | "ambiances",
  ) => {
    try {
      let listItems: { _id: string; name: string; originalName: string }[] = [];
      let filteredListItems: { _id: string; name: string; originalName: string }[] = [];
      let selectedItems: { _id: string; name: string }[] = [];

      if (type === "spokenLanguages") {
        const { data } = await getMerchantSpokenLanguages({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        listItems =
          data?.getMerchantSpokenLanguagesList.map(pm => ({
            _id: pm._id,
            originalName: pm.name,
            name: convertSpokenLanguage(pm.name),
          })) || [];
        selectedItems =
          merchant?.spokenLanguages?.map(pm => ({
            _id: pm._id,
            name: convertSpokenLanguage(pm.name),
          })) || [];
        filteredListItems = listItems.filter(
          lc => !selectedItems.some(si => si._id === lc._id),
        );
      }
      if (type === "characteristics") {
        const { data } = await getMerchantCharacteristics({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        listItems =
          data?.getMerchantCharacteristicsList.map(pm => ({
            _id: pm._id,
            originalName: pm.name,
            name: convertMerchantCharacteristics(pm.name),
          })) || [];
        selectedItems =
          merchant?.characteristics?.map(pm => ({
            _id: pm._id,
            name: convertMerchantCharacteristics(pm.name),
          })) || [];
        filteredListItems = listItems.filter(
          lc => !selectedItems.some(si => si._id === lc._id),
        );
      }
      if (type === "merchantTypes") {
        const { data } = await getMerchantTypesList({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        listItems =
          data?.getMerchantTypesList.map(pm => ({
            _id: pm._id,
            originalName: pm.name,
            name: merchantTypes(pm.name),
          })) || [];
        selectedItems =
          merchant?.merchantTypes?.map(pm => ({
            _id: pm._id,
            name: merchantTypes(pm.name),
          })) || [];
        filteredListItems = listItems.filter(
          lc => !selectedItems.some(si => si._id === lc._id),
        );
      }
      if (type === "ambiances") {
        const { data } = await getMerchantAmbiances({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        listItems =
          data?.getMerchantAmbiancesList.map(pm => ({
            _id: pm._id,
            originalName: pm.name,
            name: convertMerchantAmbiances(pm.name),
          })) || [];
        selectedItems =
          merchant?.ambiances?.map(pm => ({
            _id: pm._id,
            name: convertMerchantAmbiances(pm.name),
          })) || [];
        filteredListItems = listItems.filter(
          lc => !selectedItems.some(si => si._id === lc._id),
        );
      }

      navigation.navigate("LIST_SELECT", {
        title: getTitleByType(type),
        listItems: filteredListItems,
        selectedItems,
        onSubmit: (sItems: { _id: string; name: string }[]) => {
          const consolidatedSelectedItems = sItems.map(item => {
            const foundItem = listItems.find(listItem => listItem._id === item._id);

            if (foundItem) {
              return {
                _id: foundItem._id,
                name: foundItem.originalName,
              };
            }
            return item;
          });

          setMerchant(prev => {
            if (prev) {
              return {
                ...prev,
                [type]: consolidatedSelectedItems,
              };
            }
            return null;
          });
          navigation.goBack();
          // handleGoBack();
        },
      });
    } catch (err) {
      console.log("err go to list select", err, JSON.stringify(err, null, 2));
    }
  };

  const handleGoToChefEdit = () => {
    if (goBack) {
      setShowChef(true);
    } else {
      navigation.navigate("MERCHANT_CHEF");
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseChefDisplay = () => setShowChef(false);

  const displayChef = () => {
    return <MerchantChef goBack={handleCloseChefDisplay} />;
  };

  const displayCharacteristics = () => {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box mb="s">
          <ScreenHeader
            title="Caractéristiques"
            hasBackButton
            onBackPress={handleGoBack}
          />
        </Box>

        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              paddingBottom: 100,
            }}
          >
            <Box mt="s">
              <TouchableOpacity onPress={handleGoToChefEdit}>
                <SelectModal
                  hasTopBorder={false}
                  title="Chef"
                  value={merchant?.chef?.name || ""}
                />
              </TouchableOpacity>
            </Box>
            <Box mt="s">
              <TouchableOpacity onPress={() => handleGoToListSelect("merchantTypes")}>
                <SelectModal
                  hasTopBorder={false}
                  title="Type de restaurant"
                  value={displayListSelect(
                    merchant?.merchantTypes?.map(mt => ({
                      _id: mt._id,
                      name: merchantTypes(mt.name),
                    })) || [],
                  )}
                />
              </TouchableOpacity>
            </Box>
            <Box mt="s">
              <TouchableOpacity onPress={() => handleGoToListSelect("characteristics")}>
                <SelectModal
                  hasTopBorder={false}
                  title="Caractéristiques"
                  value={displayListSelect(
                    merchant?.characteristics?.map(mc => ({
                      _id: mc._id,
                      name: convertMerchantCharacteristics(mc.name),
                    })) || [],
                  )}
                />
              </TouchableOpacity>
            </Box>
            <Box mt="s">
              <TouchableOpacity onPress={() => handleGoToListSelect("ambiances")}>
                <SelectModal
                  hasTopBorder={false}
                  title="Cadre & Ambiance"
                  value={displayListSelect(
                    merchant?.ambiances?.map(ma => ({
                      _id: ma._id,
                      name: convertMerchantAmbiances(ma.name),
                    })) || [],
                  )}
                />
              </TouchableOpacity>
            </Box>
            <Box mt="s">
              <TouchableOpacity onPress={() => handleGoToListSelect("spokenLanguages")}>
                <SelectModal
                  hasTopBorder={false}
                  title="Langues parlées"
                  value={displayListSelect(
                    merchant?.spokenLanguages?.map(ms => ({
                      _id: ms._id,
                      name: convertSpokenLanguage(ms.name),
                    })) || [],
                  )}
                />
              </TouchableOpacity>
            </Box>

            <Box mt="s">
              <CustomText mb="s" variant="label" color="primaryTextColor">
                Fourchette de prix
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                {Object.keys(Merchant_Price_Level).map((key, index) => {
                  const IS_SELECTED = merchant?.priceLevel === Merchant_Price_Level[key];

                  return (
                    <TouchableOpacity
                      key={key}
                      onPress={() => handleSelectPriceLevel(Merchant_Price_Level[key])}
                    >
                      <Box
                        mr="s"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={IS_SELECTED ? "primaryTextColor" : "disabled"}
                        minHeight={45}
                        minWidth={60}
                        borderRadius="button"
                      >
                        <CustomText
                          variant="content"
                          color={IS_SELECTED ? "white" : "primaryTextColor"}
                        >
                          {"€".repeat(index + 1)}
                        </CustomText>
                      </Box>
                    </TouchableOpacity>
                  );
                })}
              </Box>
            </Box>
            <Box mt="m">
              <CustomText mb="s" variant="label" color="primaryTextColor">
                Services proposées
              </CustomText>

              <ButtonList
                values={
                  merchant?.extraServices?.map(service => ({
                    key: service,
                    label: service,
                  })) || []
                }
                onChange={values => {
                  setMerchant(prev => {
                    if (prev) {
                      return {
                        ...prev,
                        extraServices: values.map(value => value.key),
                      };
                    }
                    return null;
                  });
                }}
              />
            </Box>
          </ScrollView>
        </KeyboardAwareScrollView>

        {/* <Box mt="s">
        <SelectModal hasTopBorder={false} title="Services proposées" value="Mait(+3)" />
      </Box> */}
        <BottomButton title="Valider" onPress={handleSubmit} />
      </Box>
    );
  };

  const displayContent = () => {
    if (showChef) return displayChef();

    return displayCharacteristics();
  };

  return <>{displayContent()}</>;
};

export default Characteristics;
