import React from "react";

import HOME from "../../../../../assets/icons/BASE/HOME.svg";
import { LINE_THICKNESS } from "../../../../theme";
import type { PALETTE_COLORS } from "../../../../theme/Palette";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface ServiceSelectionProps {
  isSelected: boolean;
  label: string;
  areLimitsEnabled: boolean;
  iconColor: PALETTE_COLORS;
  maxCapacity: number;
  capacity: number;
  styles?: React.ComponentProps<typeof Box>;
}

const ServiceSelection = ({
  isSelected,
  label,
  areLimitsEnabled,
  iconColor,
  maxCapacity,
  capacity,
  styles,
}: ServiceSelectionProps) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      paddingVertical="xs"
      height={45}
      minWidth={165}
      backgroundColor={isSelected ? "white" : "disabled"}
      borderWidth={LINE_THICKNESS}
      borderColor={isSelected ? "success" : "disabled"}
      borderRadius="button"
      {...styles}
    >
      <CustomText color="primaryTextColor" variant="content">
        {label}
      </CustomText>
      {areLimitsEnabled && (
        <Box flexDirection="row" alignItems="center">
          <HOME height={20} width={20} fill={PALETTE[iconColor]} />
          <CustomText ml="s" color="primaryTextColor" variant="content">
            {capacity}/{maxCapacity}
          </CustomText>
        </Box>
      )}
    </Box>
  );
};

export default ServiceSelection;
