import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SelectModalProps {
  title: string;
  value: string;
  hasTopBorder?: boolean;
  hasBottomBorder?: boolean;
  isRequired?: boolean;
  hasErrors?: boolean;
}

const SelectModal = ({
  title,
  value,
  hasBottomBorder = true,
  hasTopBorder = true,
  isRequired = false,
  hasErrors = false,
}: SelectModalProps) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderTopColor="lightGrey"
      borderTopWidth={hasTopBorder ? LINE_THICKNESS : 0}
      borderBottomColor="lightGrey"
      borderBottomWidth={hasBottomBorder ? LINE_THICKNESS : 0}
      paddingVertical="m"
    >
      <Box flexDirection="row" alignItems="center">
        <CustomText variant="label" color="primaryTextColor">
          {title}
        </CustomText>
        {isRequired && (
          <CustomText variant="defaultTextInput" color={hasErrors ? "danger" : "success"}>
            *
          </CustomText>
        )}
      </Box>
      <Box>
        <Box flexDirection="row" alignItems="center">
          <CustomText mr="s" variant="content" color="primaryTextColor">
            {value}
          </CustomText>
          <RIGHT_ARROW width={15} height={15} fill={PALETTE.green} />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectModal;
