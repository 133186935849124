import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { Header } from "../../components/Header";
import SubscriptionsHandler from "../../components/SubscriptionsHandler/index";
import SubscriptionProvider from "../../contexts/SubscriptionsContext/SubscriptionProvider";
import Accounting from "../../screens/Accounting/index";
import Alerts from "../../screens/Alerts/index";
import Dashboard from "../../screens/Dashboard/index";
import MerchantStripeAccountReturn from "../../screens/MerchantStripeAccountReturn/index";
import NotFound from "../../screens/NotFound/index";
import Stats from "../../screens/Stats/index";

import BookingStack from "./BookingStack/index";
import type { AppStackParamList } from "./ParamList";
import PublicationStack from "./PublicationStack/index";
import ReviewsStack from "./ReviewStack/index";
import SalesStack from "./SalesStack/index";
import SettingsStack from "./SettingsStack/index";

const Stack = createNativeStackNavigator<AppStackParamList>();

const AppStack = () => (
  <SubscriptionProvider>
    <Stack.Navigator
      screenOptions={{
        header: ({ route }) => <Header route={route} />,
        animation:"none"
        // header: Header,
      }}
      initialRouteName="DASHBOARD"
    >
      <Stack.Screen name="DASHBOARD" component={Dashboard} />

      <Stack.Screen name="BOOKING" component={BookingStack} />
      {/* <Stack.Screen name="SALES" component={Sales} /> */}
      <Stack.Screen name="SALES" component={SalesStack} />
      <Stack.Screen name="STATS" component={Stats} />
      <Stack.Screen name="SETTINGS" component={SettingsStack} />
      {/* <Stack.Screen name="PUBLICATION" component={Publication} /> */}
      <Stack.Screen name="PUBLICATION" component={PublicationStack} />
      <Stack.Screen name="ACCOUNTING" component={Accounting} />
      <Stack.Screen name="ALERTS" component={Alerts} />
      <Stack.Screen name="REVIEWS" component={ReviewsStack} />
      <Stack.Screen
        name="MERCHANT_STRIPE_ACCOUNT_RETURN"
        component={MerchantStripeAccountReturn}
      />
      <Stack.Screen name="NOT_FOUND" component={NotFound} />
    </Stack.Navigator>
    <SubscriptionsHandler />
  </SubscriptionProvider>
);

export default AppStack;
