import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";

import PAX_PERSONS from "../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import USER_GROUP from "../../../../../assets/icons/BASE/USER_GROUP.svg";
import Box from "../../../../components/Base/Box";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import type { MerchantPersonnelSettingsSummary } from "../../../../graphql/generated/schema";
import {
  LoginStrategy,
  useGetMerchantPersonnelSettingsSummaryLazyQuery,
} from "../../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import PermissionsGroupList from "../PermissionsGroupList";
import StaffList from "../StaffList";

interface StaffSettingsListProps {
  goBack?: () => void;
}

const StaffSettingsList = ({ goBack }: StaffSettingsListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [settingsSummary, setSettingsSummary] =
    useState<MerchantPersonnelSettingsSummary | null>(null);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const [getSettings] = useGetMerchantPersonnelSettingsSummaryLazyQuery();

  const handleGetSettingsSummary = async () => {
    try {
      const { data } = await getSettings();

      if (data?.getMerchantPersonnelSettingsSummary) {
        setSettingsSummary(data.getMerchantPersonnelSettingsSummary);
      }
    } catch (err) {
      console.log("err get settings", err);
    } finally {
      setLoading(false);
    }
  };

  const displayLoginStategy = () => {
    if (settingsSummary?.loginStrategy === LoginStrategy.Password) {
      return "Mot de passe";
    }
    if (settingsSummary?.loginStrategy === LoginStrategy.UserLoginCode) {
      return "User + Code pin";
    }
    if (settingsSummary?.loginStrategy === LoginStrategy.LoginCode) {
      return "Code pin";
    }
    if (settingsSummary?.loginStrategy === LoginStrategy.UserPassword) {
      return "User + Mot de passe";
    }
    return "";
  };

  useEffect(() => {
    handleGetSettingsSummary();
  }, []);

  if (loading) return <Loader />;

  if (!settingsSummary)
    return (
      <Box flex={1} backgroundColor="white">
        <ScreenHeader
          title="Utilisateurs & groupes"
          hasBackButton
          onBackPress={navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors du chargement des paramètres. Veuillez réessayer." />
      </Box>
    );

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Utilisateurs & groupes"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          {/* <SettingsButtonItem
          title="Méthode d'authentiification"
          subText={displayLoginStategy()}
          onPress={() => handleNavigation("STAFF_METHOD_CONNECTION")}
          bottomLineWidth={LINE_THICKNESS}
        /> */}
          <SettingsButtonItem
            title="Utilisateurs"
            subText={`${settingsSummary.totalMerchantPersonel} utilisateurs`}
            onPress={() => handleNavigation("STAFF_LIST")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<PAX_PERSONS fill={PALETTE.green} width={15} height={15} />}
          />
          <SettingsButtonItem
            title="Groupes"
            subText={`${settingsSummary.totalPermissionGroups} groupes`}
            onPress={() => handleNavigation("PERMISSIONS_GROUPS_LIST")}
            bottomLineWidth={0}
            icon={<USER_GROUP fill={PALETTE.green} width={15} height={15} />}
          />
        </Box>
      </Box>
    );
  };

  const handleGoBack = () => setSelectedTab(undefined);

  const displaytSelectedTab = () => {
    switch (selectedTab) {
      case "STAFF_LIST":
        return <StaffList goBack={handleGoBack} />;

      case "PERMISSIONS_GROUPS_LIST":
        return <PermissionsGroupList goBack={handleGoBack} />;

      default:
        break;
    }
  };

  const displayContent = () => {
    if (!selectedTab) return displaySettingsList();

    return displaytSelectedTab();
  };

  return <>{displayContent()}</>;
};

export default StaffSettingsList;
