import { useFocusEffect, useNavigation } from "@react-navigation/native";
import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";

import type {
  BookingSettingsFragment,
  PrepaymentCancelInput,
} from "../../../graphql/generated/schema";
import {
  PrepaymentCancelDelimiter,
  PrepaymentCancelMesaurementType,
  PrepaymentType,
  useCanMerchantInitiatePrepaidBookingLazyQuery,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine";
import SelectButton from "../../Select/SelectButton/index";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";
import Touchable from "../../Touchable";

interface DefaultBookingPrepaymentProps {
  bookingSettings: BookingSettingsFragment;
  setBookingSettings: Dispatch<SetStateAction<BookingSettingsFragment>>;
  errors: ERROR[];
}

const PREPAYMENT_TYPES = [
  {
    label: "Par Réservation",
    key: PrepaymentType.FixedRate,
  },
  {
    label: "Par Personne",
    key: PrepaymentType.PerPerson,
  },
];

const CANCEL_DELIMITER = [
  {
    label: "La réservation",
    key: PrepaymentCancelDelimiter.Reservation,
  },
  {
    label: "Le service",
    key: PrepaymentCancelDelimiter.Service,
  },
];

const CANCEL_DELIMITER_TYPE = [
  {
    label: "Minute(s)",
    key: PrepaymentCancelMesaurementType.Minutes,
  },
  {
    label: "Heure(s)",
    key: PrepaymentCancelMesaurementType.Hours,
  },
  {
    label: "Jour(s)",
    key: PrepaymentCancelMesaurementType.Days,
  },
];

const PREPAYMENT_PAYMENT_TYPE = [
  {
    label: "Pas automatique",
    key: "MANUAL",
  },
  {
    label: "Automatique",
    key: "AUTOMATIC",
  },
];

const DefaultBookingPrepayment = ({
  bookingSettings,
  setBookingSettings,
  errors,
}: DefaultBookingPrepaymentProps) => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [isMerchantAbleToAcceptPayments, setIsMerchantAbleToAcceptPayments] =
    useState(false);

  const [canMerchantInitiatePrepaidBooking] =
    useCanMerchantInitiatePrepaidBookingLazyQuery();

  const handleCanMerchantIntitatePrepaidBooking = async () => {
    try {
      const response = await canMerchantInitiatePrepaidBooking({
        fetchPolicy: "cache-and-network",
      });

      if (response.data?.canMerchantInitiatePrepaidBooking) {
        setIsMerchantAbleToAcceptPayments(
          response.data.canMerchantInitiatePrepaidBooking.isMerchantAbleToAcceptPayments,
        );
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleCanMerchantIntitatePrepaidBooking();
    }, []),
  );

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateBookingSettings = (
    key: keyof BookingSettingsFragment["defaultPrepaymentSettings"],
    value: string | number | boolean | PrepaymentType,
  ) => {
    setBookingSettings(prev => ({
      ...prev,
      defaultPrepaymentSettings: {
        ...prev.defaultPrepaymentSettings,
        [key]: value,
      },
    }));
  };

  const updateCancelSettings = (
    key: keyof PrepaymentCancelInput,
    value: string | number | boolean,
  ) => {
    setBookingSettings(prev => ({
      ...prev,
      defaultPrepaymentSettings: {
        ...prev.defaultPrepaymentSettings,
        prepaymentCancelSettings: {
          ...prev.defaultPrepaymentSettings.prepaymentCancelSettings,
          [key]: value,
        },
      },
    }));
  };

  const handleCancelPercentages = (
    key: keyof BookingSettingsFragment["defaultPrepaymentSettings"],
    value: string | number,
  ) => {
    let nb = getSafeNumberFromInput(value);

    if (nb > 100) {
      nb = 100;
    } else if (nb < 0) {
      nb = 0;
    }

    updateBookingSettings(key, nb);
  };

  const handleGoPartnersSettings = () => {
    navigation.navigate("PARTNERS");
  };

  const displayContent = () => {
    if (!isMerchantAbleToAcceptPayments) {
      return (
        <Touchable onPress={handleGoPartnersSettings}>
          <Box
            mt="s"
            p="s"
            minHeight={45}
            backgroundColor="yellowBackground"
            borderColor="yellow"
            borderWidth={1}
            borderRadius="button"
          >
            <CustomText variant="text" color="yellow" fontWeight="bold">
              Vous n'etes pas encore éligible à l'empreinte bancaire. Veuillez vous
              créer/configurer un compte Stripe pour activer cette fonctionnalité en
              cliquant ici.
            </CustomText>
          </Box>
        </Touchable>
      );
    }

    return (
      <>
        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ToggleInputLine
            textVariant="content"
            text="Activer l'empreinte bancaire"
            value={bookingSettings?.defaultPrepaymentSettings?.isEnabled}
            onValueChange={v => updateBookingSettings("isEnabled", v)}
          />
        </Box>

        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <NumberInputLine
            hasErrors={doesInputHaveError(
              "defaultPrepaymentSettings.amountForPrepayment",
            )}
            textVariant="content"
            text="Montant à demander en euro"
            placeHolder="Montant à demander en euro"
            value={bookingSettings.defaultPrepaymentSettings.amountForPrepayment}
            onValueChange={v =>
              updateBookingSettings("amountForPrepayment", getSafeNumberFromInput(v))
            }
          />
        </Box>

        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <CustomText mb="s" variant="content" color="primaryTextColor">
            Type de prélèvement
          </CustomText>

          <SelectButton
            hasErrors={doesInputHaveError("defaultPrepaymentSettings.prepaymentType")}
            isMultiSelect={false}
            options={PREPAYMENT_TYPES}
            selectedOptions={[bookingSettings.defaultPrepaymentSettings.prepaymentType]}
            onPress={([type]) => updateBookingSettings("prepaymentType", type)}
            placeHolder="Type de prélèvement"
          />
        </Box>

        <Box mt="m">
          <CustomText variant="content" color="primaryTextColor">
            Délai maximum pour l’annulation
          </CustomText>
          <CustomText variant="text" color="lightGrey">
            Limite fixée au client pour annuler sa réservation avec empreinte bancaire.
            Aucun prélèvement ne sera effectué si le client annule pendant ce laps de
            temps.
          </CustomText>
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <CustomTextInput
            keyboardType="number-pad"
            hasErrors={doesInputHaveError(
              "defaultPrepaymentSettings.prepaymentCancelSettings.amount",
            )}
            value={bookingSettings.defaultPrepaymentSettings.prepaymentCancelSettings.amount.toString()}
            onChangeText={v => updateCancelSettings("amount", getSafeNumberFromInput(v))}
            hideFloatingLabel
            placeHolder=""
            inputStyles={{
              textAlign: "center",
            }}
          />
          <Box flexDirection="row" alignItems="flex-start">
            <SelectButton
              isMultiSelect={false}
              options={CANCEL_DELIMITER_TYPE}
              selectedOptions={[
                bookingSettings.defaultPrepaymentSettings.prepaymentCancelSettings
                  .delimiterMesaurementType,
              ]}
              onPress={([delimiter]) =>
                updateCancelSettings("delimiterMesaurementType", delimiter)
              }
              placeHolder="Avant"
              styles={{
                mr: "s",
              }}
            />
            <SelectButton
              isMultiSelect={false}
              options={CANCEL_DELIMITER}
              selectedOptions={[
                bookingSettings.defaultPrepaymentSettings.prepaymentCancelSettings
                  .delimiter,
              ]}
              onPress={([delimiter]) => updateCancelSettings("delimiter", delimiter)}
              placeHolder="Avant"
            />
          </Box>
        </Box>

        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <NumberInputLine
            hasErrors={doesInputHaveError(
              "defaultPrepaymentSettings.percentBookingCancel",
            )}
            textVariant="content"
            text="Pourcentage annulation"
            // eslint-disable-next-line max-len
            subTitle="Si le client annule sa réservation en dehors du délai d'annulation, un pourcentage sur le montant demandé sera prélevé"
            placeHolder="Pourcentage annulation"
            value={bookingSettings.defaultPrepaymentSettings.percentBookingCancel}
            onValueChange={v =>
              handleCancelPercentages("percentBookingCancel", getSafeNumberFromInput(v))
            }
          />
        </Box>

        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <NumberInputLine
            hasErrors={doesInputHaveError(
              "defaultPrepaymentSettings.percentBookingNoShow",
            )}
            textVariant="content"
            text="Pourcentage NO SHOW"
            placeHolder="Pourcentage NO SHOW"
            subTitle="Si le client ne se présente pas (noshow), un pourcentage sur le montant demandé sera prélevé."
            value={bookingSettings.defaultPrepaymentSettings.percentBookingNoShow}
            onValueChange={v =>
              handleCancelPercentages("percentBookingNoShow", getSafeNumberFromInput(v))
            }
          />
        </Box>

        <Box
          mt="m"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <CustomText mb="s" variant="content" color="primaryTextColor">
            Prélèvement suite à l'empreinte
          </CustomText>
          <SelectButton
            isMultiSelect={false}
            options={PREPAYMENT_PAYMENT_TYPE}
            selectedOptions={[
              bookingSettings.defaultPrepaymentSettings.automaticPayment
                ? "AUTOMATIC"
                : "MANUAL",
            ]}
            onPress={([val]) =>
              updateBookingSettings("automaticPayment", val === "AUTOMATIC")
            }
            placeHolder="Prélèvement suite à l'empreinte"
          />
        </Box>
      </>
    );
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          EMPREINTE BANCAIRE
        </CustomText>
      </Box>

      <Box mt="s">
        <CustomText variant="text" color="lightGrey">
          Si vous souhaitez demander une empreinte bancaire lors que vous prenez une
          réservation ou sur une réservation à laquelle il n’y avait pas d’empreinte
          bancaire, veuillez paramétrer les informations ci-dessous. Un SMS sera
          automatiquement envoyé au client afin qu’il valide son empreinte pour confirmer
          sa réservation.
        </CustomText>
      </Box>

      {displayContent()}
    </Box>
  );
};

export default DefaultBookingPrepayment;
