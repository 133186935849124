export const TextVariants = {
  title: {
    fontSize: 24,
    fontFamily: "Lexend-Bold",
    // textTransform: "capitalize",
    color: "primaryTextColor",
  },
  headerTitle: {
    fontSize: 24,
    fontFamily: "Lexend-Regular",
    textTransform: "uppercase",
    color: "white",
  },
  navTitle: {
    fontSize: 16,
    // color: PALETTE.blue,
    fontFamily: "Lexend-Regular",
    textTransform: "uppercase",
  },
  paragraph: {
    fontSize: 16,
    fontFamily: "Lexend-Regular",
    color: "primaryTextColor",
    // textTransform: "capitalize",
  },
  label: {
    fontSize: 16,
    fontFamily: "Lexend-Bold",
    // color: PALETTE.blue,
    // textTransform: "capitalize",
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "Lexend-Regular",
    textTransform: "uppercase",
    // color: PALETTE.green,
  },
  content: {
    fontSize: 14,
    // color: PALETTE.blue,
    fontFamily: "Lexend-Regular",
    // textTransform: "capitalize",
  },
  text: {
    fontSize: 12,
    // color: PALETTE.blue,
    fontFamily: "Lexend-Light",
    // textTransform: "capitalize",
  },
  description: {
    fontSize: 12,
    color: "descriptionColor",
    fontFamily: "Lexend-Regular",
    // textTransform: "uppercase",
  },
  tabTitle: {
    fontSize: 16,
    fontFamily: "Lexend-Regular",
    textTransform: "uppercase",
    color: "primaryTextColor",
  },
  listTitle: {
    fontSize: 14,
    fontFamily: "Lexend-Bold",
    textTransform: "uppercase",
    color: "white",
  },
  listActiveTab: {
    fontSize: 12,
    fontFamily: "Lexend-Bold",
    // textTransform: "capitalize",
    color: "primaryTextColor",
  },
  listInactiveTab: {
    fontSize: 12,
    fontFamily: "Lexend-Regular",
    // textTransform: "capitalize",
    color: "primaryTextColor",
  },
  primaryButtonText: {
    fontSize: 14,
    fontFamily: "Lexend-Regular",
    textTransform: "uppercase",
    textAlign: "center",
  },
  secondaryButtonText: {
    fontSize: 14,
    fontFamily: "Lexend-Regular",
    // textTransform: "capitalize",
    textAlign: "center",
  },
  outlineButtonText: {
    fontSize: 14,
    fontFamily: "Lexend-Regular",
    // textTransform: "uppercase",
    textAlign: "center",
  },
  defaultTextInput: {
    fontSize: 16,
    fontFamily: "Lexend-Regular",
    color: "primaryTextColor",
    // textTransform: "capitalize",
  },
  placeHolderTextInput: {
    fontSize: 16,
    fontFamily: "Lexend-Bold",
    color: "primaryTextColor",
    // textTransform: "capitalize",
  },
  errorTextInput: {
    fontSize: 16,
    fontFamily: "Lexend-Bold",
    color: "danger",
    // placeholderTextColor: "danger",
    // textTransform: "capitalize",
  },
  listItemMainText: {
    fontSize: 16,
    fontFamily: "Lexend-Bold",
    color: "primaryTextColor",
    textTransform: "uppercase",
  },
  listItemSubText: {
    fontSize: 14,
    fontFamily: "Lexend-Light",
    color: "primaryTextColor",
    // textTransform: "capitalize",
  },
  listItemComments: {
    fontSize: 14,
    fontFamily: "Lexend-Light",
    color: "disabled",
    // textTransform: "capitalize",
  },
  underlineText: {
    fontSize: 14,
    fontFamily: "Lexend-Regular",
    // textTransform: "capitalize",
    textDecorationLine: "underline",
  },
};

export type TEXT_VARIANTS = keyof typeof TextVariants;
