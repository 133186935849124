import DOWN_ARROW from "../../../assets/icons/BASE/DOWN_ARROW.svg";
import TOP_ARROW from "../../../assets/icons/BASE/TOP_ARROW.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface StatRecapColumnProps {
  icon: JSX.Element;
  title: string;
  value: string;
  variation: string;
  isPositififVariation: boolean;
  showVariantion?: boolean;
}

const StatRecapColumn = ({
  icon,
  title,
  value,
  variation,
  isPositififVariation,
  showVariantion = true,
}: StatRecapColumnProps) => {
  return (
    <Box justifyContent="center">
      <Box
        backgroundColor="disabled"
        borderRadius="button"
        height={60}
        width={60}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
      >
        {icon}
      </Box>

      <Box mt="s">
        <CustomText variant="text" color="primaryTextColor" textAlign="center">
          {title}
        </CustomText>
      </Box>
      <Box mt="s">
        <CustomText textAlign="center" variant="title" color="primaryTextColor">
          {value}
        </CustomText>
      </Box>

      {showVariantion && (
        <Box mt="s" flexDirection="row" alignItems="center">
          <Box
            mr="s"
            p="s"
            backgroundColor={isPositififVariation ? "greenBackground" : "redBackground"}
            style={{ borderRadius: 25 }}
            alignItems="center"
            justifyContent="center"
          >
            {isPositififVariation ? (
              <TOP_ARROW width={12} height={12} fill={PALETTE.green} />
            ) : (
              <DOWN_ARROW width={12} height={12} fill={PALETTE.red} />
            )}
          </Box>
          <CustomText
            fontSize={10}
            textAlign="center"
            variant="text"
            color="primaryTextColor"
          >
            {variation}
          </CustomText>
        </Box>
      )}
    </Box>
  );
};

export default StatRecapColumn;
