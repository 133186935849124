import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import CUTLERY from "../../../../../assets/icons/BASE/CUTLERY.svg";
import OCCURENCES from "../../../../../assets/icons/BASE/OCCURENCES.svg";
import PAX_PERSONS from "../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import type { GetBasicBookingStatsQuery } from "../../../../graphql/generated/schema";
import { useGetBasicBookingStatsLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import StatRecapColumn from "../../../StatRecapColumn/index";

interface QuickLookBookingStatsProps {
  startDate: Date;
  endDate: Date;
}

const QuickLookBookingStats = ({ startDate, endDate }: QuickLookBookingStatsProps) => {
  const [loading, setLoading] = useState(true);
  const [bookingStats, setBookingStats] = useState<
    GetBasicBookingStatsQuery["getBasicBookingStats"] | null
  >(null);
  const [getBasicBookingStats] = useGetBasicBookingStatsLazyQuery();

  const handleGetBasicBookingStats = async () => {
    try {
      const { data } = await getBasicBookingStats({
        fetchPolicy: "cache-and-network",
        variables: {
          startDate: startDate,
          endDate: endDate,
        },
      });

      if (data?.getBasicBookingStats) {
        setBookingStats(data.getBasicBookingStats);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBasicBookingStats();
  }, [startDate, endDate]);

  if (loading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!bookingStats) return null;

  return (
    <Box
      flex={1}
      borderRadius="button"
      borderColor="lightGrey"
      borderWidth={LINE_THICKNESS}
      justifyContent="center"
      p="s"
    >
      <Box p="s">
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Coup d'oeil
        </CustomText>
      </Box>

      <Box
        flexWrap="wrap"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box p="s">
          <StatRecapColumn
            icon={
              <OCCURENCES width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            }
            title="Réservations"
            value={Math.ceil(bookingStats.totalBookings).toString()}
            variation="10 jours (30 jours)"
            isPositififVariation={true}
            showVariantion={false}
          />
        </Box>
        <Box p="s">
          <StatRecapColumn
            icon={
              <PAX_PERSONS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            }
            title="Couverts total"
            value={Math.ceil(bookingStats.totalPaxBookings).toString()}
            variation="10 jours (30 jours)"
            isPositififVariation={true}
            showVariantion={false}
          />
        </Box>
        <Box p="s">
          <StatRecapColumn
            icon={<CUTLERY width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
            title="Couverts/service"
            value={Math.ceil(bookingStats.personsByServices).toString()}
            variation="10 jours (30 jours)"
            isPositififVariation={true}
            showVariantion={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuickLookBookingStats;
