import type { ImageInfo } from "expo-image-picker";
import React, { useState } from "react";
import { FlatList, Image, StyleSheet, TouchableOpacity } from "react-native";

import PLUS from "../../../assets/icons/BASE/PLUS.svg";
import { useSavePictureMutation } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import AddNewImageModal from "../Media/AddNewImageMedia";

interface ImageCarouselPickerProps {
    images: string[];
    maxImages: number;
    onAddImage: (imageData?: string) => void;
    onRemoveImage: (imageData?: string) => void;
}

const styles = StyleSheet.create({});
const DISPLAY_IMAGE_HEIGHT = 150;
const OTHER_IMAGES_HEIGHT_WIDTH = 103;
const IMAGE_LIST_SIZE = 50;

const ImageCarouselPicker = ({
    images,
    maxImages,
    onAddImage,
    onRemoveImage,
}: ImageCarouselPickerProps) => {
    const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
    const [savePicture] = useSavePictureMutation();

    const handleAddImage = async (image?: ImageInfo) => {
        const picture = image?.base64;
        if (!picture) return;
        try {
            const { data } = await savePicture({ variables: { picture } });

            onAddImage(data?.savePicture);
        } catch (err) {
            console.log("err add image", err);
        }
    };

    const displayMainImage = () => {
        const [firstImage] = images;

        if (!firstImage) {
            return (
                <CustomButton
                    styles={{
                        minHeight: DISPLAY_IMAGE_HEIGHT,
                    }}
                    buttonVariant="outlineButton"
                    borderColor="success"
                    onPress={() => setIsImagePickerOpen(true)}
                >
                    <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                    <CustomText variant="title" color="success">
                        Ajouter une photo
                    </CustomText>
                </CustomButton>
            );
        }

        return (
            <Box height={DISPLAY_IMAGE_HEIGHT} width="100%" borderRadius="button">
                <TouchableOpacity
                    style={{
                        zIndex: 1,
                    }}
                    onPress={() => onRemoveImage(firstImage)}
                >
                    <Box
                        position="absolute"
                        right={-5}
                        top={-15}
                        backgroundColor="danger"
                        height={30}
                        width={30}
                        borderRadius="button"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CustomText variant="content" color="white">
                            X
                        </CustomText>
                    </Box>
                </TouchableOpacity>

                <Image
                    source={{
                        uri: firstImage,
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 8,
                    }}
                />
            </Box>
        );
    };

    const displayOtherImages = () => {
        const otherImages = images.slice(1);
        const CAN_SELECT_MORE_IMAGES = maxImages > 1 && images.length < maxImages;

        return (
            <>
                <Box>
                    <FlatList
                        data={otherImages}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={item => item}
                        // contentContainerStyle={{ zIndex: 1 }}
                        renderItem={({ item }) => (
                            <Box
                                height={OTHER_IMAGES_HEIGHT_WIDTH}
                                width={OTHER_IMAGES_HEIGHT_WIDTH}
                                borderRadius="button"
                                mr="s"
                            >
                                <TouchableOpacity
                                    style={{
                                        zIndex: 2,
                                    }}
                                    onPress={() => onRemoveImage(item)}
                                >
                                    <Box
                                        position="absolute"
                                        right={-5}
                                        top={0}
                                        backgroundColor="danger"
                                        height={30}
                                        width={30}
                                        borderRadius="button"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CustomText variant="content" color="white">
                                            X
                                        </CustomText>
                                    </Box>
                                </TouchableOpacity>

                                <Image
                                    source={{
                                        uri: item,
                                    }}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 8,
                                    }}
                                />
                            </Box>
                        )}
                    />
                </Box>

                {CAN_SELECT_MORE_IMAGES && (
                    <CustomButton
                        // buttonColor="danger"
                        buttonVariant="outlineButton"
                        borderColor="success"
                        onPress={() => setIsImagePickerOpen(true)}
                        styles={{
                            mt: "s",
                            height: IMAGE_LIST_SIZE,
                            width: IMAGE_LIST_SIZE,
                        }}
                    >
                        <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                    </CustomButton>
                )}
            </>
        );
    };

    return (
        <Box>
            {/* <CustomButton
        styles={{
          minHeight: DISPLAY_IMAGE_HEIGHT,
        }}
        buttonVariant="outlineButton"
        borderColor="success"
        onPress={handleAddImage}
      >
        <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        <CustomText variant="title" color="success">
          Ajouter une photo
        </CustomText>
      </CustomButton> */}

            {displayMainImage()}
            <Box mt="s" alignSelf="flex-end">
                <CustomText variant="description" color="lightGrey">
                    {images.length}/{maxImages}
                </CustomText>
            </Box>
            {displayOtherImages()}

            <AddNewImageModal
                isOpen={isImagePickerOpen}
                setIsOpen={val => setIsImagePickerOpen(val)}
                onSubmit={handleAddImage}
            />
        </Box>
    );
};

export default ImageCarouselPicker;