import DateTimePicker from "@react-native-community/datetimepicker";
import { format, isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { Platform } from "react-native";

import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import BottomButton from "../BottomButton";
import ContentModal from "../ContentModal";
import { CustomTextInput } from "../TextInput";
import Touchable from "../Touchable";

interface DateInputProps {
  date?: Date;
  placeHolder: string;
  onChange: (date: Date) => void;
  disabled?: boolean;
  minDate?: Date;
  isRequired?: boolean;
  hasErrors?: boolean;
}

const DateInput = ({
  placeHolder,
  date,
  onChange,
  disabled = false,
  minDate,
  isRequired = false,
  hasErrors = false,
}: DateInputProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const IS_ANDROID = Platform.OS === "android";
  const IS_IOS = Platform.OS === "ios";
  const IS_WEB = Platform.OS === "web";

  const displayDateInputWeb = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnChangeWeb = (event: any) => {
      const formattedDate = new Date(event.target.value);
      setSelectedDate(formattedDate);
    };

    const formattedDate = format(
      selectedDate && isValid(selectedDate) ? new Date(selectedDate) : new Date(),
      "yyyy-MM-dd",
    );

    return React.createElement("input", {
      type: "date",
      value: formattedDate,
      onInput: handleOnChangeWeb,
      style: {
        borderRadius: 8,
        padding: 10,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderWidth: "1px",
      },
    });
  };

  const displayDateInputIos = () => {
    return (
      <Box style={{ width: "100%" }}>
        <DateTimePicker
          value={selectedDate || new Date()}
          minimumDate={minDate}
          mode="date"
          textColor={PALETTE.darkBlue}
          display="spinner"
          onChange={(_event, newDate) => {
            setSelectedDate(newDate || new Date());

            if (IS_ANDROID) {
              onChange(newDate || new Date());
              setIsModalOpen(false);
            }
          }}
        />
      </Box>
    );
  };

  const displayDateInputAndroid = () => {
    if (IS_ANDROID && isModalOpen) {
      return displayDateInputIos();
    }
  };

  const handleSubmit = () => {
    onChange(selectedDate || new Date());
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedDate(date);
  };

  const displayModalContent = () => {
    if (IS_WEB || IS_IOS) {
      return (
        <ContentModal
          title="Sélectionner une date"
          isVisible={isModalOpen}
          onClose={handleClose}
          styles={{
            maxWidth: 450,
            height: "auto",
          }}
        >
          <Box minHeight={300}>
            {IS_WEB ? displayDateInputWeb() : displayDateInputIos()}

            <BottomButton title="Valider" onPress={handleSubmit} />
          </Box>
        </ContentModal>
      );
    }
    return displayDateInputAndroid();
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const formattedDate =
    selectedDate && isValid(selectedDate)
      ? format(new Date(selectedDate), "dd/MM/yyyy")
      : "";

  return (
    <>
      <Touchable disabled={disabled} onPress={handleOpenModal} style={{ width: "100%" }}>
        <CustomTextInput
          placeHolder={placeHolder}
          // initialValue={formattedDate}
          value={formattedDate}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChangeText={() => {}}
          disabled
          boxProps={{
            backgroundColor: "white",
          }}
          isRequired={isRequired}
          hasErrors={hasErrors}
        />
      </Touchable>
      {displayModalContent()}
    </>
  );
};

export default DateInput;
