import { TouchableOpacity } from "react-native";

import type { Decoration_Type } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import ContentModal from "../../ContentModal";
import { DECORATIONS } from "../common/DecorationList";

interface AddDecorationModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSubmit: (decorationName: Decoration_Type) => void;
}

const AddDecorationModal = ({ isOpen, setIsOpen, onSubmit }: AddDecorationModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelection = (decorationName: Decoration_Type) => {
    onSubmit(decorationName);
    handleClose();
  };

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={handleClose}
      title="Sélectionner une décoration"
    >
      <Box
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
      >
        {DECORATIONS.map((decoration, idx) => {
          return (
            <Box key={idx} mb="s" width="33%" justifyContent="center" alignItems="center">
              <TouchableOpacity onPress={() => handleSelection(decoration.name)}>
                {decoration.icon}
              </TouchableOpacity>
            </Box>
          );
        })}
      </Box>
    </ContentModal>
  );
};

export default AddDecorationModal;
