import BAN from "../../../../assets/icons/BASE/BAN.svg";
import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import CREATE from "../../../../assets/icons/BASE/CREATE.svg";
import EDIT from "../../../../assets/icons/BASE/EDIT.svg";
import SEND from "../../../../assets/icons/BASE/SEND.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import type { THEME_COLORS } from "../../../theme";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import { QUOTE_STATUS } from "../../../types";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../BookingList/BookingListCardStatusButtonItem";

interface QuoteStatusButtonItemProps {
  status: QUOTE_STATUS;
  type?: BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE;
}

const QuoteStatusButtonItem = ({
  status,
  type = BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON,
}: QuoteStatusButtonItemProps) => {
  const determineCorrectStatusColor = (): {
    borderColor: THEME_COLORS;
    iconColor: PALETTE_COLORS;
  } => {
    switch (status) {
      case QUOTE_STATUS.NEW:
        return {
          borderColor: "QUOTE_NEW",
          iconColor: "QUOTE_NEW",
        };
      case QUOTE_STATUS.SENT:
        return {
          borderColor: "QUOTE_SENT",
          iconColor: "QUOTE_SENT",
        };
      case QUOTE_STATUS.MODIFIED:
        return {
          borderColor: "QUOTE_MODIFIED",
          iconColor: "QUOTE_MODIFIED",
        };
      case QUOTE_STATUS.ACCEPTED:
        return {
          borderColor: "QUOTE_ACCEPTED",
          iconColor: "QUOTE_ACCEPTED",
        };
      case QUOTE_STATUS.CANCELLED:
        return {
          borderColor: "QUOTE_CANCELLED",
          iconColor: "QUOTE_CANCELLED",
        };
      case QUOTE_STATUS.REJECTED:
        return {
          borderColor: "QUOTE_REJECTED",
          iconColor: "QUOTE_REJECTED",
        };
    }
    return { borderColor: "success", iconColor: "green" };
  };

  const determineCorrectStatus = (): JSX.Element => {
    const color = determineCorrectStatusColor().iconColor;
    switch (status) {
      case QUOTE_STATUS.NEW:
        return <CREATE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case QUOTE_STATUS.SENT:
        return <SEND width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case QUOTE_STATUS.MODIFIED:
        return <EDIT width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case QUOTE_STATUS.ACCEPTED:
        return <VALIDATE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case QUOTE_STATUS.CANCELLED:
        return <CANCEL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
      case QUOTE_STATUS.REJECTED:
        return <BAN width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE[color]} />;
    }
  };

  const determineCorrectStatusText = (): string => {
    switch (status) {
      case QUOTE_STATUS.NEW:
        return "NEW";

      case QUOTE_STATUS.SENT:
        return "SENT";
      case QUOTE_STATUS.MODIFIED:
        return "MODIFIED";

      case QUOTE_STATUS.ACCEPTED:
        return "ACCEPTED";

      case QUOTE_STATUS.CANCELLED:
        return "CANCELLED";

      case QUOTE_STATUS.REJECTED:
        return "REJECTED";

      default:
        return "-";
    }
  };

  return (
    <Box
      p="xs"
      paddingHorizontal={type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON ? "xs" : "s"}
      borderColor={determineCorrectStatusColor().borderColor}
      borderWidth={LINE_THICKNESS}
      borderRadius="modal"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box p="xs" alignItems="center" justifyContent="center">
        {determineCorrectStatus()}
      </Box>

      {type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT && (
        <CustomText
          ml="s"
          variant="content"
          color={determineCorrectStatusColor().borderColor}
        >
          {determineCorrectStatusText()}
        </CustomText>
      )}
    </Box>
  );
};

export default QuoteStatusButtonItem;
