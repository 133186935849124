 
import { useNavigation } from "@react-navigation/native";
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import BottomButton from "../../../../../../components/BottomButton/index";
import ScreenHeader from "../../../../../../components/ScreenHeader/index";
import SingleSelectButton from "../../../../../../components/SingleSelectButton/index";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { MerchantSettingsFragment } from "../../../../../../graphql/generated/schema";
import {
  useAddPaymetMethodForMerchantMutation,
  useGetMerchantSettingsLazyQuery,
} from "../../../../../../graphql/generated/schema";

interface AddPaymentMethodProps {
  goBack?: () => void;
}

const AddPaymentMethod = ({ goBack }: AddPaymentMethodProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [isCard, setIsCard] = useState(true);
  const [merchantSettings, setMerchantSettings] = useState<
    MerchantSettingsFragment | undefined
  >(undefined);
  const [isPaymentMethodSetupInProgress, setIsPaymentMethodSetupInProgress] =
    useState(false);
  const elements = useElements();
  const stripe = useStripe();

  const [addPaymentMethod] = useAddPaymetMethodForMerchantMutation();
  const [getMerchantSettings] = useGetMerchantSettingsLazyQuery();

  const handelGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetMerchantSettings = async () => {
    try {
      const { data } = await getMerchantSettings();

      if (data?.getMerchantSettings) {
        setMerchantSettings(data?.getMerchantSettings);
      }
    } catch (err) {
      console.log("err get merchant settings", err);
    }
  };

  useEffect(() => {
    handleGetMerchantSettings();
  }, []);

  const handleCreateStripePaymentMethod = async () => {
    if (isCard) {
      if (!stripe || !elements) throw new Error("STRIPE_NOT_INITIALIZED");

      const card = elements.getElement(CardElement);

      if (!card) throw new Error("CARD_ELEMENT_NOT_FOUND");

      const result = await stripe?.createPaymentMethod({
        type: "card",
        card,
      });

      console.log("result", result);

      if (result?.error) throw new Error(result.error.message);

      const stripePaymentMethodId = result.paymentMethod.id;

      return stripePaymentMethodId;
    } else {
      if (!stripe || !elements) throw new Error("STRIPE_NOT_INITIALIZED");

      const iban = elements.getElement(IbanElement);

      if (!iban) throw new Error("IBAN_ELEMENT_NOT_FOUND");

      const name = merchantSettings?.billingDetails.name || "";
      const email = merchantSettings?.billingDetails.email || "";

      const result = await stripe?.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: iban,
        billing_details: {
          name,
          email,
        },
      });

      console.log("result", result);

      if (result?.error) throw new Error(result.error.message);

      const stripePaymentMethodId = result.paymentMethod.id;

      return stripePaymentMethodId;
    }
  };

  const handleAddPaymentMethod = async () => {
    setIsPaymentMethodSetupInProgress(true);
    try {
      const stripePaymentMethodId = await handleCreateStripePaymentMethod();

      const { data } = await addPaymentMethod({
        variables: {
          stripePaymentMethodId,
          isCard,
        },
      });
      if (data?.addPaymetMethodForMerchant) {
        handelGoBack();
      }
    } catch (err) {
      setIsPaymentMethodSetupInProgress(false);
      console.log("err add payment method", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_ADD_PAYMENT_METHOD",
            message:
              err?.message ||
              "Une erreur est survenue lors de l'ajout du moyen de paiement",
          },
        ],
        "error",
      );
    }
  };

  const displayCardForm = () => {
    return (
      <Box
        marginHorizontal="m"
        style={{
          padding: 15,
          borderColor: "#eee",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 8,
        }}
      >
        <CardElement
          options={{
            hidePostalCode: true,
          }}
        />
      </Box>
    );
  };

  const displayBankForm = () => {
    const IBAN_STYLE = {
      base: {
        color: "#707070",
        fontSize: "14px",
        "::placeholder": {
          color: "#707070",
        },
        ":-webkit-autofill": {
          color: "#707070",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
        ":-webkit-autofill": {
          color: "#fa755a",
        },
      },
    };
    const IBAN_ELEMENT_OPTIONS = {
      supportedCountries: ["SEPA"],
      placeholderCountry: "FR",
      style: IBAN_STYLE,
    };

    return (
      <Box>
        <Box
          marginHorizontal="m"
          style={{
            padding: 15,
            borderColor: "#eee",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 8,
          }}
        >
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
          {/* <CustomTextInput
          placeHolder="IBAN"
          keyboardType="default"
          onChangeText={t => setIban(t.toLowerCase())}
          initialValue=""
        /> */}
        </Box>

        <Box mt="s" p="s">
          <CustomText variant="text" color="lightGrey">
            En fournissant vos informations de paiement et en confirmant ce paiement, vous
            autorisez (A) SAS EIWIE et Stripe, notre prestataire de services de paiement
            et/ou PPRO, son prestataire de services local, à envoyer des instructions à
            votre banque pour débiter votre compte et (B) votre banque à débiter votre
            compte conformément à ces instructions. Vous avez, entre autres, le droit de
            vous faire rembourser par votre banque selon les modalités et conditions du
            contrat conclu avec votre banque. La demande de remboursement doit être
            soumise dans un délai de 8 semaines à compter de la date à laquelle votre
            compte a été débité. Vos droits sont expliqués dans une déclaration disponible
            auprès de votre banque. Vous acceptez de recevoir des notifications des débits
            à venir dans les 2 jours précédant leur réalisation.
          </CustomText>
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (isCard) {
      return displayCardForm();
    } else {
      return displayBankForm();
    }
  };

  return (
    <Box flex={1} p="m" backgroundColor="white">
      <ScreenHeader title="Mode de paiement" hasBackButton onBackPress={handelGoBack} />

      <Box mt="s" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flex={0.4}>
          <CustomText variant="label" color="primaryTextColor">
            Moyen de paiement
          </CustomText>
        </Box>
        <Box flex={1} flexDirection="row" alignItems="center">
          <Box width="48%" marginHorizontal="s">
            <SingleSelectButton
              onPress={() => setIsCard(false)}
              isSelected={!isCard}
              title="IBAN"
            />
          </Box>
          <Box width="48%">
            <SingleSelectButton
              onPress={() => setIsCard(true)}
              isSelected={isCard}
              title="CB"
            />
          </Box>
        </Box>
      </Box>

      <Box marginVertical="s">{displayContent()}</Box>

      <BottomButton
        isDisabled={isPaymentMethodSetupInProgress}
        onPress={handleAddPaymentMethod}
        title="Enregistrer"
      />
    </Box>
  );
};

export default AddPaymentMethod;
