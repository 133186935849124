/* eslint-disable @typescript-eslint/no-shadow */
import BACON from "../../../../assets/icons/CATEGORIES/BACON.svg";
import BEER from "../../../../assets/icons/CATEGORIES/BEER.svg";
import BREAD from "../../../../assets/icons/CATEGORIES/BREAD.svg";
import BURGER from "../../../../assets/icons/CATEGORIES/BURGER.svg";
import BURRITO from "../../../../assets/icons/CATEGORIES/BURRITO.svg";
import CAKE from "../../../../assets/icons/CATEGORIES/CAKE.svg";
import CAKE_SLICE from "../../../../assets/icons/CATEGORIES/CAKE_SLICE.svg";
import CANDY from "../../../../assets/icons/CATEGORIES/CANDY.svg";
import CANNED_FOOD from "../../../../assets/icons/CATEGORIES/CANNED_FOOD.svg";
import CAVIAR from "../../../../assets/icons/CATEGORIES/CAVIAR.svg";
import CHEESE from "../../../../assets/icons/CATEGORIES/CHEESE.svg";
import CHICKEN_LEGS from "../../../../assets/icons/CATEGORIES/CHICKEN_LEGS.svg";
import CHOCOLATE from "../../../../assets/icons/CATEGORIES/CHOCOLATE.svg";
import COCKTAIL from "../../../../assets/icons/CATEGORIES/COCKTAIL.svg";
import COFFEE from "../../../../assets/icons/CATEGORIES/COFFEE.svg";
import COLA from "../../../../assets/icons/CATEGORIES/COLA.svg";
import COOKIES from "../../../../assets/icons/CATEGORIES/COOKIES.svg";
import CORN_DOG from "../../../../assets/icons/CATEGORIES/CORN_DOG.svg";
import CRAB from "../../../../assets/icons/CATEGORIES/CRAB.svg";
import CROISSANT from "../../../../assets/icons/CATEGORIES/CROISSANT.svg";
import DISH from "../../../../assets/icons/CATEGORIES/DISH.svg";
import DONUTS from "../../../../assets/icons/CATEGORIES/DONUTS.svg";
import EGGS from "../../../../assets/icons/CATEGORIES/EGGS.svg";
import FILET from "../../../../assets/icons/CATEGORIES/FILET.svg";
import FISH from "../../../../assets/icons/CATEGORIES/FISH.svg";
import FRESH_COCKTAIL from "../../../../assets/icons/CATEGORIES/FRESH_COCKTAIL.svg";
import FRIED_EGGS from "../../../../assets/icons/CATEGORIES/FRIED_EGGS.svg";
import FRIES from "../../../../assets/icons/CATEGORIES/FRIES.svg";
import FRUITS from "../../../../assets/icons/CATEGORIES/FRUITS.svg";
import FULL_CHICKEN from "../../../../assets/icons/CATEGORIES/FULL_CHICKEN.svg";
import HOT_DOG from "../../../../assets/icons/CATEGORIES/HOT_DOG.svg";
import ICE_CREAM from "../../../../assets/icons/CATEGORIES/ICE_CREAM.svg";
import JAM from "../../../../assets/icons/CATEGORIES/JAM.svg";
import JUICE from "../../../../assets/icons/CATEGORIES/JUICE.svg";
import KEBAB from "../../../../assets/icons/CATEGORIES/KEBAB.svg";
import LOLLIPOP from "../../../../assets/icons/CATEGORIES/LOLLIPOP.svg";
import MEATBALL from "../../../../assets/icons/CATEGORIES/MEATBALL.svg";
import MEAT_LEG from "../../../../assets/icons/CATEGORIES/MEAT_LEG.svg";
import MILK from "../../../../assets/icons/CATEGORIES/MILK.svg";
import MUFFIN from "../../../../assets/icons/CATEGORIES/MUFFIN.svg";
import NUTS from "../../../../assets/icons/CATEGORIES/NUTS.svg";
import OYSTER from "../../../../assets/icons/CATEGORIES/OYSTER.svg";
import PIE from "../../../../assets/icons/CATEGORIES/PIE.svg";
import PIZZA from "../../../../assets/icons/CATEGORIES/PIZZA.svg";
import POPCORN from "../../../../assets/icons/CATEGORIES/POPCORN.svg";
import RHUM from "../../../../assets/icons/CATEGORIES/RHUM.svg";
import ROLLS from "../../../../assets/icons/CATEGORIES/ROLLS.svg";
import SALMON from "../../../../assets/icons/CATEGORIES/SALMON.svg";
import SALT_PEPPER from "../../../../assets/icons/CATEGORIES/SALT_PEPPER.svg";
import SANDWICH from "../../../../assets/icons/CATEGORIES/SANDWICH.svg";
import SAUSAGE from "../../../../assets/icons/CATEGORIES/SAUSAGE.svg";
import SAUSAGES from "../../../../assets/icons/CATEGORIES/SAUSAGES.svg";
import SHOT_DRINK from "../../../../assets/icons/CATEGORIES/SHOT_DRINK.svg";
import SHRIMP from "../../../../assets/icons/CATEGORIES/SHRIMP.svg";
import SODA from "../../../../assets/icons/CATEGORIES/SODA.svg";
import STEAK from "../../../../assets/icons/CATEGORIES/STEAK.svg";
import SUSHI from "../../../../assets/icons/CATEGORIES/SUSHI.svg";
import TACO from "../../../../assets/icons/CATEGORIES/TACO.svg";
import TEA from "../../../../assets/icons/CATEGORIES/TEA.svg";
import TISANE from "../../../../assets/icons/CATEGORIES/TISANE.svg";
import VEGETABLES from "../../../../assets/icons/CATEGORIES/VEGETABLES.svg";
import WAFFLES from "../../../../assets/icons/CATEGORIES/WAFFLES.svg";
import WATER from "../../../../assets/icons/CATEGORIES/WATER.svg";
import WINE from "../../../../assets/icons/CATEGORIES/WINE.svg";
import Box from "../../Base/Box";

export enum CATEGORY_TYPE {
  BACON = "BACON",
  BEER = "BEER",
  BREAD = "BREAD",
  BURGER = "BURGER",
  BURRITO = "BURRITO",
  CAKE_SLICE = "CAKE_SLICE",
  CAKE = "CAKE",
  CANDY = "CANDY",
  CAVIAR = "CAVIAR",
  CANNED_FOOD = "CANNED_FOOD",
  CHICKEN_LEGS = "CHICKEN_LEGS",
  CHEESE = "CHEESE",
  COCKTAIL = "COCKTAIL",
  CORN_DOG = "CORN_DOG",
  COLA = "COLA",
  COOKIES = "COOKIES",
  CRAB = "CRAB",
  CHOCOLATE = "CHOCOLATE",
  COFFEE = "COFFEE",
  CROISSANT = "CROISSANT",
  DISH = "DISH",
  DONUTS = "DONUTS",
  EGGS = "EGGS",
  FILET = "FILET",
  FULL_CHICKEN = "FULL_CHICKEN",
  HOT_DOG = "HOT_DOG",
  ICE_CREAM = "ICE_CREAM",
  JAM = "JAM",
  JUICE = "JUICE",
  FISH = "FISH",
  FRESH_COCKTAIL = "FRESH_COCKTAIL",
  FRIED_EGGS = "FRIED_EGGS",
  FRIES = "FRIES",
  FRUITS = "FRUITS",
  KEBAB = "KEBAB",
  LOLLIPOP = "LOLLIPOP",
  MEATBALL = "MEATBALL",
  MEAT_LEG = "MEAT_LEG",
  MILK = "MILK",
  MUFFIN = "MUFFIN",
  NUTS = "NUTS",
  OYSTER = "OYSTER",
  PIE = "PIE",
  PIZZA = "PIZZA",
  POPCORN = "POPCORN",
  RHUM = "RHUM",
  ROLLS = "ROLLS",
  SALMON = "SALMON",
  SALT_PEPPER = "SALT_PEPPER",
  SANDWICH = "SANDWICH",
  SAUSAGE = "SAUSAGE",
  SAUSAGES = "SAUSAGES",
  SHOT_DRINK = "SHOT_DRINK",
  SHRIMP = "SHRIMP",
  SODA = "SODA",
  STEAK = "STEAK",
  SUSHI = "SUSHI",
  TACO = "TACO",
  TEA = "TEA",
  TISANE = "TISANE",
  VEGETABLES = "VEGETABLES",
  WAFFLES = "WAFFLES",
  WATER = "WATER",
  WINE = "WINE",
}

interface CategoryTypeItemProps {
  type: CATEGORY_TYPE;
}

const ICON_SIZE = 45;

const CategoryTypeItem = ({ type }: CategoryTypeItemProps) => {
  const displayIcon = () => {
    switch (type) {
      case "BACON":
        return <BACON width={ICON_SIZE} height={ICON_SIZE} />;
      case "BEER":
        return <BEER width={ICON_SIZE} height={ICON_SIZE} />;
      case "BREAD":
        return <BREAD width={ICON_SIZE} height={ICON_SIZE} />;
      case "BURGER":
        return <BURGER width={ICON_SIZE} height={ICON_SIZE} />;
      case "BURRITO":
        return <BURRITO width={ICON_SIZE} height={ICON_SIZE} />;
      case "CAKE_SLICE":
        return <CAKE_SLICE width={ICON_SIZE} height={ICON_SIZE} />;
      case "CAKE":
        return <CAKE width={ICON_SIZE} height={ICON_SIZE} />;
      case "CANDY":
        return <CANDY width={ICON_SIZE} height={ICON_SIZE} />;
      case "CANNED_FOOD":
        return <CANNED_FOOD width={ICON_SIZE} height={ICON_SIZE} />;
      case "CAVIAR":
        return <CAVIAR width={ICON_SIZE} height={ICON_SIZE} />;
      case "CHEESE":
        return <CHEESE width={ICON_SIZE} height={ICON_SIZE} />;
      case "CHICKEN_LEGS":
        return <CHICKEN_LEGS width={ICON_SIZE} height={ICON_SIZE} />;
      case "CHOCOLATE":
        return <CHOCOLATE width={ICON_SIZE} height={ICON_SIZE} />;
      case "COCKTAIL":
        return <COCKTAIL width={ICON_SIZE} height={ICON_SIZE} />;
      case "COFFEE":
        return <COFFEE width={ICON_SIZE} height={ICON_SIZE} />;
      case "COLA":
        return <COLA width={ICON_SIZE} height={ICON_SIZE} />;
      case "COOKIES":
        return <COOKIES width={ICON_SIZE} height={ICON_SIZE} />;
      case "CORN_DOG":
        return <CORN_DOG width={ICON_SIZE} height={ICON_SIZE} />;
      case "CRAB":
        return <CRAB width={ICON_SIZE} height={ICON_SIZE} />;
      case "CROISSANT":
        return <CROISSANT width={ICON_SIZE} height={ICON_SIZE} />;
      case "DISH":
        return <DISH width={ICON_SIZE} height={ICON_SIZE} />;
      case "DONUTS":
        return <DONUTS width={ICON_SIZE} height={ICON_SIZE} />;
      case "EGGS":
        return <EGGS width={ICON_SIZE} height={ICON_SIZE} />;
      case "FILET":
        return <FILET width={ICON_SIZE} height={ICON_SIZE} />;
      case "FISH":
        return <FISH width={ICON_SIZE} height={ICON_SIZE} />;
      case "FRESH_COCKTAIL":
        return <FRESH_COCKTAIL width={ICON_SIZE} height={ICON_SIZE} />;
      case "FRIED_EGGS":
        return <FRIED_EGGS width={ICON_SIZE} height={ICON_SIZE} />;
      case "FRIES":
        return <FRIES width={ICON_SIZE} height={ICON_SIZE} />;
      case "FRUITS":
        return <FRUITS width={ICON_SIZE} height={ICON_SIZE} />;
      case "FULL_CHICKEN":
        return <FULL_CHICKEN width={ICON_SIZE} height={ICON_SIZE} />;
      case "HOT_DOG":
        return <HOT_DOG width={ICON_SIZE} height={ICON_SIZE} />;
      case "ICE_CREAM":
        return <ICE_CREAM width={ICON_SIZE} height={ICON_SIZE} />;
      case "JAM":
        return <JAM width={ICON_SIZE} height={ICON_SIZE} />;
      case "JUICE":
        return <JUICE width={ICON_SIZE} height={ICON_SIZE} />;
      case "KEBAB":
        return <KEBAB width={ICON_SIZE} height={ICON_SIZE} />;
      case "LOLLIPOP":
        return <LOLLIPOP width={ICON_SIZE} height={ICON_SIZE} />;
      case "MEAT_LEG":
        return <MEAT_LEG width={ICON_SIZE} height={ICON_SIZE} />;
      case "MEATBALL":
        return <MEATBALL width={ICON_SIZE} height={ICON_SIZE} />;
      case "MILK":
        return <MILK width={ICON_SIZE} height={ICON_SIZE} />;
      case "MUFFIN":
        return <MUFFIN width={ICON_SIZE} height={ICON_SIZE} />;
      case "NUTS":
        return <NUTS width={ICON_SIZE} height={ICON_SIZE} />;
      case "OYSTER":
        return <OYSTER width={ICON_SIZE} height={ICON_SIZE} />;
      case "PIE":
        return <PIE width={ICON_SIZE} height={ICON_SIZE} />;
      case "PIZZA":
        return <PIZZA width={ICON_SIZE} height={ICON_SIZE} />;
      case "POPCORN":
        return <POPCORN width={ICON_SIZE} height={ICON_SIZE} />;
      case "RHUM":
        return <RHUM width={ICON_SIZE} height={ICON_SIZE} />;
      case "ROLLS":
        return <ROLLS width={ICON_SIZE} height={ICON_SIZE} />;
      case "SALMON":
        return <SALMON width={ICON_SIZE} height={ICON_SIZE} />;
      case "SALT_PEPPER":
        return <SALT_PEPPER width={ICON_SIZE} height={ICON_SIZE} />;
      case "SANDWICH":
        return <SANDWICH width={ICON_SIZE} height={ICON_SIZE} />;
      case "SAUSAGE":
        return <SAUSAGE width={ICON_SIZE} height={ICON_SIZE} />;
      case "SAUSAGES":
        return <SAUSAGES width={ICON_SIZE} height={ICON_SIZE} />;
      case "SHOT_DRINK":
        return <SHOT_DRINK width={ICON_SIZE} height={ICON_SIZE} />;
      case "SHRIMP":
        return <SHRIMP width={ICON_SIZE} height={ICON_SIZE} />;
      case "SODA":
        return <SODA width={ICON_SIZE} height={ICON_SIZE} />;
      case "STEAK":
        return <STEAK width={ICON_SIZE} height={ICON_SIZE} />;
      case "SUSHI":
        return <SUSHI width={ICON_SIZE} height={ICON_SIZE} />;
      case "TACO":
        return <TACO width={ICON_SIZE} height={ICON_SIZE} />;
      case "TEA":
        return <TEA width={ICON_SIZE} height={ICON_SIZE} />;
      case "TISANE":
        return <TISANE width={ICON_SIZE} height={ICON_SIZE} />;
      case "VEGETABLES":
        return <VEGETABLES width={ICON_SIZE} height={ICON_SIZE} />;
      case "WAFFLES":
        return <WAFFLES width={ICON_SIZE} height={ICON_SIZE} />;
      case "WATER":
        return <WATER width={ICON_SIZE} height={ICON_SIZE} />;
      case "WINE":
        return <WINE width={ICON_SIZE} height={ICON_SIZE} />;

      default:
        break;
    }
    return <></>;
  };

  return (
    <Box width={ICON_SIZE} height={ICON_SIZE} justifyContent="center" alignItems="center">
      {displayIcon()}
    </Box>
  );
};

export default CategoryTypeItem;
