import { CustomText } from "../Base/Text";

interface InputSectionTitleProps {
  text: string;
}

const InputSectionTitle = ({ text }: InputSectionTitleProps) => {
  return (
    <CustomText mb="s" variant="content" color="success" textTransform="uppercase">
      {text}
    </CustomText>
  );
};

export default InputSectionTitle;
