import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import Loader from "../../../../../../components/Loader";
import AddNewImageModal from "../../../../../../components/Media/AddNewImageMedia";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import CategoryListSelect from "../../../../../../components/Settings/CategoryListSelect";
import ColorListSelect from "../../../../../../components/Settings/ColorListSelect";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateProductCategory,
  ProductCategoryFragment,
} from "../../../../../../graphql/generated/schema";
import {
  GetProductCategoriesDocument,
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoryLazyQuery,
  useSavePictureMutation,
  useUpdateProductCategoryMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../../utils/common";

interface CategoryListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 95;

const DEFAULT_CATEGORY: CreateProductCategory = {
  color: "",
  description: "",
  iconUrl: "",
  isEnabled: true,
  name: "",
  picture: "",
  position: 0,
  printerLocations: [],
  products: [],
  iconType: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est requis."),
  description: yup.string(),
  color: yup.string().required("La couleur est requise."),
  isEnabled: yup.boolean().required(),
  iconType: yup.string().required("L'icone est requise."),
  printerLocations: yup.array().of(yup.string()),
});

const CategoryListDetailsEdit = ({ id, goBack }: CategoryListDetailsEditProps) => {
  const navigation = useNavigation();
  const isNew = !id;
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  // const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const [category, setCategory] = useState(DEFAULT_CATEGORY);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  // const [getPrinters] = useGetPrintersLazyQuery();

  const [getCategory] = useGetProductCategoryLazyQuery();
  const [createCategory] = useCreateProductCategoryMutation();
  const [updateCategory] = useUpdateProductCategoryMutation();
  const [deleteCategory] = useDeleteProductCategoryMutation();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  // const printersSelectOptions = useMemo(
  //   () => printers.map(p => ({ key: p._id, label: p.name })),
  //   [printers],
  // );

  // const handleGetPrinters = async () => {
  //   try {
  //     const pagination = {
  //       limit: 100,
  //       offset: 0,
  //     };
  //     const { data } = await getPrinters({
  //       variables: { pagination },
  //     });

  //     setPrinters(data?.getPrinters || []);
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };

  const handleGetCategory = async () => {
    try {
      if (id) {
        const { data } = await getCategory({
          variables: {
            categoryId: id,
          },
        });

        if (data?.getProductCategory) {
          const printerLocations = data.getProductCategory.printerLocations.map(
            p => p._id,
          );
          const consolidatedCategory = {
            ...data.getProductCategory,
            printerLocations,
          };

          setCategory(consolidatedCategory);
        }
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // handleGetPrinters();
    handleGetCategory();
  }, []);

  const updateCategoryData = (
    key: keyof ProductCategoryFragment,
    value: string | string[] | number | boolean,
  ) => {
    setCategory(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleRemoveProduct = (productId: string) => {
    setCategory(prev => ({
      ...prev,
      products: prev.products.filter(p => p._id !== productId),
    }));
  };

  const handleGoToAddOption = () => {
    navigation.navigate("CATEGORIES_LIST_DETAILS_ADD_PRODUCT", {
      selectedProducts: category.products,
      onSubmit: (p: string[]) => updateCategoryData("products", p),
    });
  };

  const validateForm = async () => {
    try {
      await schema.validate(category, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      const products = category.products.map(p => p._id);
      const consolidatedCategory = {
        ...category,
        products,
      };

      if (isNew) {
        await createCategory({
          variables: {
            category: consolidatedCategory,
          },
          refetchQueries: [
            {
              query: GetProductCategoriesDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consolidatedCategory);
        delete updates._id;
        await updateCategory({
          variables: {
            categoryId: id,
            updates,
          },
          refetchQueries: [
            {
              query: GetProductCategoriesDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      const errs = [
        {
          path: "submit",
          code: "submit",
          message: "Une erreur est survenue.",
        },
      ];

      setErrors(errs);

      infoAlert.openAlert("Erreur", errs, "error");
    }
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteCategory({
          variables: {
            categoryId: id,
          },
          // refetchQueries: [
          //   {
          //     query: GetProductCategoriesDocument,
          //     variables: { pagination: { limit: 10, offset: 0 } },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "ProductCategory",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });

        handleGoBack();
      }
    } catch (err) {
      console.log("err", err);

      const errs = [
        {
          path: "submit",
          code: "submit",
          message: "Une erreur est survenue.",
        },
      ];

      setErrors(errs);

      infoAlert.openAlert("Erreur", errs, "error");
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setCategory(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      const errs = [
        {
          path: "submit",
          code: "submit",
          message: "Une erreur est survenue.",
        },
      ];

      setErrors(errs);

      infoAlert.openAlert("Erreur", errs, "error");
    }
  };

  const displayPicture = () => {
    const { picture } = category;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  const displayTitle = isNew ? "Nouvelle catégorie" : "Modifier la catégorie";

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 150,
        }}
      >
        <Box flex={1}>
          <Box marginVertical="s">
            <Box>{displayPicture()}</Box>
          </Box>

          <Box>
            <CustomTextInput
              placeHolder="Nom de la catégorie"
              initialValue={category.name}
              hasErrors={doesInputHaveError("name")}
              onChangeText={t => updateCategoryData("name", t)}
              isRequired
            />
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Description du produit"
              multiLine
              initialValue={category.description}
              onChangeText={t => updateCategoryData("description", t)}
            />
          </Box>

          <Box marginVertical="s">
            <CustomText
              mb="s"
              variant="label"
              color={doesInputHaveError("color") ? "danger" : "primaryTextColor"}
            >
              Couleur
            </CustomText>
            <ColorListSelect
              selectedColor={category.color}
              onPress={color => updateCategoryData("color", color)}
            />
          </Box>

          <Box marginVertical="s">
            <CustomText
              mb="s"
              variant="label"
              color={doesInputHaveError("iconType") ? "danger" : "primaryTextColor"}
            >
              Icone
            </CustomText>
            <CategoryListSelect
              selectedType={category.iconType}
              onPress={type => updateCategoryData("iconType", type)}
            />
          </Box>

          <Box
            marginVertical="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText
              style={{ flex: 1, flexWrap: "wrap" }}
              variant="label"
              color="primaryTextColor"
            >
              Activé
            </CustomText>
            <Toggle
              value={category.isEnabled}
              onChange={v => updateCategoryData("isEnabled", v)}
            />
          </Box>

          {/* {printersSelectOptions.length > 0 && (
            <Box marginVertical="s">
              <SelectButton
                isMultiSelect
                options={printersSelectOptions}
                hasErrors={doesInputHaveError("printerLocations")}
                selectedOptions={category.printerLocations}
                onPress={v => updateCategoryData("printerLocations", v)}
                placeHolder="Point de fabrication"
              />
            </Box>
          )} */}

          {/* <Box
            marginVertical="s"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <CustomText variant="label" color="primaryTextColor">
              Supplément
            </CustomText>
            <Box flexDirection="row" alignItems="flex-start">
              <CustomTextInput placeHolder="Prix" onChangeText={() => {}} />
              <Box ml="s">
                <SelectButton
                  isMultiSelect={false}
                  options={TAXES}
                  selectedOptions={tax}
                  onPress={setTax}
                  placeHolder="Taxe"
                />
              </Box>
            </Box>
          </Box> */}

          <Box mt="m">
            <CustomText
              mb="s"
              variant="content"
              color="success"
              textTransform="uppercase"
            >
              Produits
            </CustomText>

            <DraggableFlatList
              scrollEnabled={false}
              data={category.products}
              style={{ width: "100%" }}
              ListHeaderComponent={
                <TouchableOpacity onPress={handleGoToAddOption}>
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mr="s"
                    marginVertical="s"
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title="Ajouter un produit"
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <PLUS fill={PALETTE.green} width={15} height={15} />
                  </Box>
                </TouchableOpacity>
              }
              renderItem={({ item, drag }) => (
                <TouchableOpacity
                  onPressIn={drag}
                  style={{
                    marginVertical: 8,
                    marginRight: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box flex={0.95}>
                    <InlineSelectButton
                      title={item.name}
                      options={[]}
                      isDraggable
                      selectedOptionKeys={[]}
                      isMultiSelect={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onPress={() => {}}
                    />
                  </Box>

                  <TouchableOpacity onPress={() => handleRemoveProduct(item._id)}>
                    <TRASH fill={PALETTE.red} width={15} height={15} />
                  </TouchableOpacity>
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => `draggable-item-${index}`}
              onDragEnd={({ data }) => updateCategoryData("products", data)}
            />
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default CategoryListDetailsEdit;
