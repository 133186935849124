import { useNavigation } from "@react-navigation/native";
import { useState } from "react";

import MerchantInfoEdit from "./Edit";
import MerchantInfoView from "./View";

interface MerchantInfoProps {
  goBack?: () => void;
}

const MerchantInfo = ({ goBack }: MerchantInfoProps) => {
  const [inEditMode, setInEditMode] = useState(false);
  const navigation = useNavigation();

  const handleEditPress = () => {
    setInEditMode(true);
  };

  return (
    <>
      {!inEditMode ? (
        <MerchantInfoView goBack={goBack} handleEditPress={handleEditPress} />
      ) : (
        <MerchantInfoEdit goBack={goBack} />
      )}
    </>
  );
};

export default MerchantInfo;
