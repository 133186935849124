import { TouchableOpacity } from "react-native";

import MODIFY from "../../../assets/icons/BASE/MODIFY.svg";
import TRASH from "../../../assets/icons/BASE/TRASH.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface MerchantDiscountCardProps {
  name: string;
  discountValue: number;
  isPercentage: boolean;
  onPress: () => void;
  onDelete: () => void;
}

const MerchantDiscountCard = ({
  name,
  discountValue,
  isPercentage,
  onPress,
  onDelete,
}: MerchantDiscountCardProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="disabled"
          borderRadius="button"
          p="s"
          flex={1}
          mr="m"
        >
          <CustomText variant="content" color="primaryTextColor">
            {name} - {discountValue} {isPercentage ? "%" : "€"}
          </CustomText>
          <MODIFY height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
        </Box>
        <TouchableOpacity onPress={onDelete}>
          <TRASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.red} />
        </TouchableOpacity>
      </Box>
    </TouchableOpacity>
  );
};

export default MerchantDiscountCard;
