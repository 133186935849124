import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../components/Base/Box";
import ScreenHeader from "../../../components/ScreenHeader";
import DateSelector from "../../../components/Stats/DateSelector";
import OrderTotalRecap from "../../../components/Stats/Orders/OrderTotalRecap/index";
import OrdersByGoals from "../../../components/Stats/Orders/OrdersByGoals";
import OrdersByOrigins from "../../../components/Stats/Orders/OrdersByOrigins";
import OrdersFrequencyByDay from "../../../components/Stats/Orders/OrdersFrequencyByDay";
import OrdersTopClients from "../../../components/Stats/Orders/OrdersTopClients/index";
import QuickLookOrderStats from "../../../components/Stats/Orders/QuickLookOrderStats";

const OnlineOrderStats = () => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  return (
    <Box paddingHorizontal="s" flex={1} pt="s" backgroundColor="white">
      <Box>
        <ScreenHeader title="Vente en ligne" />
      </Box>

      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt="m"
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems={{ tablet: "flex-start" }}
          justifyContent={{ phone: "flex-start", tablet: "space-between" }}
          marginHorizontal={{
            tablet: "m",
          }}
        >
          <Box
            flex={1}
            paddingRight={{
              tablet: "m",
            }}
          >
            <OrderTotalRecap />
          </Box>

          <Box
            flex={1}
            mt={{
              phone: "s",
            }}
            borderTopColor="lightGrey"
            borderTopWidth={{ phone: 1, tablet: 0 }}
            pt={{ phone: "m", tablet: "none" }}
            borderLeftColor="lightGrey"
            borderLeftWidth={{ tablet: 1 }}
            pl={{ tablet: "m" }}
          >
            <OrdersFrequencyByDay />
          </Box>
        </Box>

        <Box borderTopColor="lightGrey" borderTopWidth={1} mt="s" paddingVertical="m">
          <DateSelector
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>

        <Box
          mt="m"
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems={{ tablet: "center" }}
          justifyContent={{ phone: "flex-start", tablet: "space-between" }}
        >
          <Box
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
          >
            <OrdersByGoals startDate={startDate} endDate={endDate} />
          </Box>

          <Box
            height="100%"
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
            mt={{
              phone: "m",
              tablet: "none",
            }}
          >
            <QuickLookOrderStats startDate={startDate} endDate={endDate} />
          </Box>
        </Box>

        <Box
          mt="m"
          marginHorizontal={{
            tablet: "m",
          }}
        >
          <OrdersByOrigins startDate={startDate} endDate={endDate} />
        </Box>
        <Box
          mt="m"
          marginHorizontal={{
            tablet: "m",
          }}
        >
          <OrdersTopClients startDate={startDate} endDate={endDate} />
        </Box>
      </ScrollView>
    </Box>
  );
};

export default OnlineOrderStats;
