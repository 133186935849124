export enum BOOKING_ORIGIN {
  PHONE = 10,
  LOCAL = 20,
  APP_WEB = 30,
  APP_MOBILE = 40,
  IFRAME = 50,
  GOOGLE = 60,
}

export enum BOOKING_STATUS {
  NEW = 10,
  DELAYED = 20,
  WAITING_LIST = 25,
  MODIFIED_BY_USER = 30,
  MODIFIED_BY_OWNER = 40,
  VALIDATED = 50,
  AUTOMATIC_VALIDATED = 52,
  RECONFIMED = 53,
  ARRIVED = 54,
  ARRIVED_WAITING = 55,
  SEATED = 58,
  ENDED = 60,
  NOSHOW = 70,
  CANCELLED_BY_USER = 80,
  CANCELLED_BY_OWNER = 90,
  REFUNDED = 100,
}

export enum PAYMENT_CARD_STATUS {
  NOT_CHARGED = 10,
  CHARGED = 20,
  CANCELLED = 30,
  FAILED = 40,
}

export enum PAYMENT_TYPE {
  NONE = "NONE",
  CARD = "CARD",
  CASH = "CASH",
}

export enum CLICK_STATUS {
  NEW = 10,
  WAITING_LIST = 15,
  MODIFIED_BY_USER = 20,
  MODIFIED_BY_OWNER = 30,
  VALIDATED = 40,
  AUTOMATIC_VALIDATED = 45,
  PREPARING = 50,
  PREPARED = 55,
  ENDED = 60,
  NOSHOW = 70,
  CANCELLED_BY_USER = 80,
  CANCELLED_BY_OWNER = 90,
  PAYMENT_FAILURE = 100,
  AUTOMATIC_CANCEL = 110,
  REFUNDED = 120,
}

export enum QUOTE_STATUS {
  NEW = 0,
  SENT = 10,
  MODIFIED = 15,
  ACCEPTED = 20,
  CANCELLED = 25,
  REJECTED = 30,
}

export enum BOOKING_FLOOR_VIEW_TYPE {
  TABLE_ASSIGNMENT = "TABLE_ASSIGNMENT",
  TABLE_VIEW = "TABLE_VIEW",
  TABLE_VIEW_HOVER = "TABLE_VIEW_HOVER",
}
