import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext } from "react";
import { TouchableOpacity } from "react-native";

import PENDING from "../../../../assets/icons/BASE/PENDING.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import ScreenHeader from "../../../components/ScreenHeader";
import { AppContext } from "../../../contexts/AppContext";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

const AlertSummary = () => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);

  useFocusEffect(
    React.useCallback(() => {
      appContext.refreshAlerts();
    }, []),
  );

  const goToBookingAlerts = () => navigation.navigate("ALERTS_BOOKINGS_TAB");

  const goToOrderAlerts = () => navigation.navigate("ALERTS_ORDER_TABS");

  return (
    <Box
      maxWidth={1024}
      width="100%"
      alignSelf="center"
      flex={1}
      pt="s"
      paddingHorizontal="s"
      backgroundColor="white"
    >
      <ScreenHeader title="Résumé" />

      <TouchableOpacity onPress={goToBookingAlerts}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          mt="s"
          borderRadius="button"
          borderWidth={LINE_THICKNESS}
          borderColor="disabled"
          style={{ minHeight: 45 }}
        >
          <Box flexDirection="row" alignItems="center">
            <Box
              minWidth={30}
              minHeight={30}
              alignItems="center"
              justifyContent="center"
              borderRadius="modal"
              backgroundColor="yellowBackground"
            >
              <PENDING fill={PALETTE.yellow} />
            </Box>

            <Box ml="s" flexDirection="row" alignItems="center">
              <CustomText variant="label" color="primaryTextColor">
                {appContext?.totalPendingBookings} Réservations
              </CustomText>
              <CustomText pl="s" variant="content" color="primaryTextColor">
                en attente
              </CustomText>
            </Box>
          </Box>
          <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </Box>
      </TouchableOpacity>

      <TouchableOpacity onPress={goToOrderAlerts}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          mt="s"
          borderRadius="button"
          borderWidth={LINE_THICKNESS}
          borderColor="disabled"
          style={{ minHeight: 45 }}
        >
          <Box flexDirection="row" alignItems="center">
            <Box
              minWidth={30}
              minHeight={30}
              alignItems="center"
              justifyContent="center"
              borderRadius="modal"
              backgroundColor="yellowBackground"
            >
              <PENDING fill={PALETTE.yellow} />
            </Box>

            <Box ml="s" flexDirection="row" alignItems="center">
              <CustomText variant="label" color="primaryTextColor">
                {appContext?.totalPendingOrders} Commandes
              </CustomText>
              <CustomText pl="s" variant="content" color="primaryTextColor">
                en attente
              </CustomText>
            </Box>
          </Box>
          <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </Box>
      </TouchableOpacity>

      {/* <TouchableOpacity>
        <Box mt="s" flexDirection="row" alignItems="center">
          <Box
            minWidth={30}
            minHeight={30}
            alignItems="center"
            justifyContent="center"
            borderRadius="modal"
            backgroundColor="redBackground"
          >
            <CANCEL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
          </Box>
          <Box
            // backgroundColor="black"
            flex={1}
            ml="s"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
          >
            <CustomText variant="label" color="primaryTextColor">
              TOWO Malusi
            </CustomText>
            <CustomText pl="s" variant="content" color="primaryTextColor">
              a annulé sa réservation pour 6 personnes le 10 juin
            </CustomText>
          </Box>
          <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </Box>
      </TouchableOpacity> */}
    </Box>
  );
};

export default AlertSummary;
