import { useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import DOWN_ARROW from "../../../../assets/icons/BASE/DOWN_ARROW.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import type { TEXT_VARIANTS } from "../../../theme/variants/TextVariants";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

interface SelectionOption {
  label: string;
  key: string;
}
interface SelectButton {
  placeHolder: string;
  isMultiSelect: boolean;
  selectedOptions: string[];
  options: SelectionOption[];
  onPress: (selectionOptionKeys: string[]) => void;
  styles?: React.ComponentProps<typeof Box>;
  hasErrors?: boolean;
  selectedTextVariant?: TEXT_VARIANTS;
  placeHolderTextVariant?: TEXT_VARIANTS;
}

const SelectButton = ({
  placeHolder,
  isMultiSelect,
  options = [],
  selectedOptions = [],
  onPress,
  styles,
  hasErrors = false,
  selectedTextVariant = "paragraph",
  placeHolderTextVariant = "placeHolderTextInput",
}: SelectButton) => {
  const [isOpen, setIsOpen] = useState(false);

  const displayTitle = () => {
    if (selectedOptions.length === 0) {
      return placeHolder;
    }
    const nbSelectionOptions = selectedOptions.length - 1;
    const firstSelectionOption = options.find(
      option => option.key === selectedOptions[0],
    );
    const suffix = nbSelectionOptions >= 1 ? `(+${nbSelectionOptions})` : "";
    return `${firstSelectionOption?.label} ${suffix}`;
  };

  const handleOptionSelection = (optionkey: string) => {
    let selectedKeys = selectedOptions;
    const existingKeyFound = selectedOptions.includes(optionkey);
    const isEmpty = selectedOptions.length === 0;

    if (!isMultiSelect) {
      selectedKeys = [optionkey];
      setIsOpen(false);
    } else if (existingKeyFound) {
      selectedKeys = selectedOptions.filter(key => key !== optionkey);
    } else if (isMultiSelect || isEmpty) {
      selectedKeys = [...selectedOptions, optionkey];
    }

    onPress(selectedKeys);
  };

  return (
    <Box>
      <CustomButton
        onPress={() => setIsOpen(v => !v)}
        buttonVariant="primaryButton"
        styles={{
          minHeight: 45,
          paddingHorizontal: "s",
          flexDirection: "row",
          backgroundColor: "white",
          borderColor: hasErrors ? "danger" : "lightGrey",
          borderWidth: LINE_THICKNESS,
          alignItems: "center",
          justifyContent: "space-between",
          borderBottomLeftRadius: isOpen ? "none" : "button",
          borderBottomRightRadius: isOpen ? "none" : "button",
          borderBottomWidth: isOpen ? 0 : 1,
          ...styles,
        }}
      >
        <CustomText
          variant={
            selectedOptions.length === 0 ? placeHolderTextVariant : selectedTextVariant
          }
          color={hasErrors ? "danger" : "primaryTextColor"}
        >
          {displayTitle()}
        </CustomText>

        {!isOpen ? (
          <RIGHT_ARROW width={20} height={20} />
        ) : (
          <DOWN_ARROW width={20} height={20} fill={PALETTE.green} />
        )}
      </CustomButton>
      {isOpen && (
        <FlatList
          contentContainerStyle={
            {
              // paddingBottom: 50,
            }
          }
          showsVerticalScrollIndicator={false}
          data={options}
          renderItem={({ item, index }) => {
            const IS_SELECTED = selectedOptions.includes(item.key);
            const IS_LAST = index === options.length - 1;

            return (
              <TouchableOpacity onPress={() => handleOptionSelection(item.key)}>
                <Box
                  minHeight={45}
                  p="s"
                  backgroundColor="white"
                  borderColor="lightGrey"
                  borderWidth={1}
                  borderTopWidth={0}
                  borderBottomLeftRadius={IS_LAST ? "button" : "none"}
                  borderBottomRightRadius={IS_LAST ? "button" : "none"}
                  borderBottomWidth={IS_LAST ? 1 : 0}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText variant={selectedTextVariant} color="primaryTextColor">
                    {item.label}
                  </CustomText>

                  {IS_SELECTED && (
                    <VALIDATE width={16} height={16} fill={PALETTE.green} />
                  )}
                </Box>
              </TouchableOpacity>
            );
          }}
        />
      )}
    </Box>
  );
};

export default SelectButton;
