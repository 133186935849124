import { View } from "react-native";

import STAR from "../../../../assets/icons/BASE/STAR.svg";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface BookingLIstCardClientFidelityProps {
  occurences?: number;
}

const BookingLIstCardClientFidelity = ({
  occurences = 0,
}: BookingLIstCardClientFidelityProps) => {
  const getClientFidelity = () => {
    let cpt = <></>;

    if (!occurences) return cpt;

    if (occurences === 1) {
      cpt = (
        <Box
          p="xs"
          backgroundColor="yellow"
          borderRadius="button"
          alignItems="center"
          justifyContent="center"
        >
          <CustomText variant="description" color="white">
            New
          </CustomText>
        </Box>
      );
    } else if (occurences > 1 && occurences <= 7) {
      cpt = (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <STAR height={15} width={15} fill="gold" />
        </View>
      );
    } else if (occurences > 7 && occurences <= 11) {
      cpt = (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <STAR height={15} width={15} fill="gold" />
          <STAR height={15} width={15} fill="gold" />
        </View>
      );
    } else {
      cpt = (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <STAR height={15} width={15} fill="gold" />
          <STAR height={15} width={15} fill="gold" />
          <STAR height={15} width={15} fill="gold" />
        </View>
      );
    }

    return <View style={{ width: 40 }}>{cpt}</View>;
  };

  return <Box>{getClientFidelity()}</Box>;
};

export default BookingLIstCardClientFidelity;
