 
import { useEffect, useState } from "react";
import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import type { SubscriptionFragment } from "../../../../graphql/generated/schema";
import {
  Subscription_Type,
  useGetMerchantSubscriptionsLazyQuery,
} from "../../../../graphql/generated/schema";
import { formatCurrencyPrice } from "../../../../utils/common";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

interface AddSubscriptionOptionsListSelectProps {
  transactionSubscriptionIds: string[];
  selectedSubscriptionOptions: SubscriptionFragment[];
  onSelectSubscriptionOption: (subscription: SubscriptionFragment) => void;
  isCompleteSubscriptionSelected: boolean;
}

const AddSubscriptionOptionsListSelect = ({
  transactionSubscriptionIds,
  selectedSubscriptionOptions,
  onSelectSubscriptionOption,
  isCompleteSubscriptionSelected,
}: AddSubscriptionOptionsListSelectProps) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionFragment[]>(
    [],
  );

  const [getMerchantSubscriptionOptions] = useGetMerchantSubscriptionsLazyQuery();

  const handleGetMerchantSubscriptions = async () => {
    try {
      const { data } = await getMerchantSubscriptionOptions();

      if (data?.getMerchantSubscriptions) {
        setSubscriptionOptions(
          data?.getMerchantSubscriptions
            .filter(s => s.type === Subscription_Type.SubscriptionOption)
            .filter(s => !transactionSubscriptionIds.includes(s._id)),
        );
      }
    } catch (err) {
      console.log("Err get merchant subscriptions", err);
    }
  };

  useEffect(() => {
    handleGetMerchantSubscriptions();
  }, []);

  const getMinPrice = (prices: SubscriptionFragment["prices"]) => {
    let minPrice = prices?.[0]?.price || 0;

    prices.forEach(price => {
      if (price.price < minPrice) {
        minPrice = price.price;
      }
    });

    return minPrice;
  };

  if (isCompleteSubscriptionSelected) {
    return (
      <Box flex={1} backgroundColor="white">
        <Box alignItems="center" justifyContent="center">
          <CustomText variant="content" color="primaryTextColor">
            Vous avez sélectionné un abonnement qui comprend toutes les options.
          </CustomText>
        </Box>
      </Box>
    );
  }

  return (
    <Box flex={1} backgroundColor="white">
      <FlatList
        data={subscriptionOptions}
        keyExtractor={item => item._id}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
        renderItem={({ item }) => {
          const minPrice = getMinPrice(item.prices);

          const IS_SELECTED = selectedSubscriptionOptions.find(s => s._id === item._id);

          return (
            <Box
              marginVertical="s"
              backgroundColor={IS_SELECTED ? "white" : "disabled"}
              borderRadius="button"
              paddingHorizontal="s"
              borderColor="success"
              borderWidth={IS_SELECTED ? 1 : 0}
            >
              <TouchableOpacity onPress={() => onSelectSubscriptionOption(item)}>
                <Box
                  marginVertical="m"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Box flexDirection="row" alignItems="flex-start">
                    <Box>
                      <CustomText variant="content" color="primaryTextColor">
                        {item.name}
                      </CustomText>
                      {item.description && (
                        <CustomText variant="text" color="primaryTextColor">
                          {item.description}
                        </CustomText>
                      )}
                    </Box>
                  </Box>
                  <Box flexDirection="row" alignItems="center">
                    <CustomText mr="s" variant="label" color="primaryTextColor">
                      {formatCurrencyPrice(minPrice, "fr", "eur")}
                    </CustomText>
                  </Box>
                </Box>
              </TouchableOpacity>
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default AddSubscriptionOptionsListSelect;
