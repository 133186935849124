import type { Dispatch, SetStateAction } from "react";

import CREDIT_CARD from "../../../../assets/icons/BASE/CREDIT_CARD.svg";
import type {
  BookingPrepaymentFragment,
  CreateBookingAvailabilityInput,
  PrepaymentCancelInput,
} from "../../../graphql/generated/schema";
import {
  PrepaymentCancelDelimiter,
  PrepaymentCancelMesaurementType,
  PrepaymentType,
} from "../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine";
import SelectButton from "../../Select/SelectButton";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface ServicePrepaymentProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const PREPAYMENT_TYPES = [
  {
    label: "Par Réservation",
    key: PrepaymentType.FixedRate,
  },
  {
    label: "Par Personne",
    key: PrepaymentType.PerPerson,
  },
];

const CANCEL_DELIMITER = [
  {
    label: "La réservation",
    key: PrepaymentCancelDelimiter.Reservation,
  },
  {
    label: "Le service",
    key: PrepaymentCancelDelimiter.Service,
  },
];

const CANCEL_DELIMITER_TYPE = [
  {
    label: "Minute(s)",
    key: PrepaymentCancelMesaurementType.Minutes,
  },
  {
    label: "Heure(s)",
    key: PrepaymentCancelMesaurementType.Hours,
  },
  {
    label: "Jour(s)",
    key: PrepaymentCancelMesaurementType.Days,
  },
];

const PREPAYMENT_PAYMENT_TYPE = [
  {
    label: "Pas automatique",
    key: "MANUAL",
  },
  {
    label: "Automatique",
    key: "AUTOMATIC",
  },
];

const ServicePrepayment = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServicePrepaymentProps) => {
  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };
  const updateBoookingAvailabilityPrepaymentSettings = (
    key: keyof BookingPrepaymentFragment,
    value: string | number | boolean | PrepaymentType,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      prepaymentSettings: {
        ...prev.prepaymentSettings,
        [key]: value,
      },
    }));
  };

  const togglePrepaymentEnabled = (value: boolean) => {
    setBookingAvailability(prev => ({
      ...prev,
      bookingProductsSettings: {
        ...prev.bookingProductsSettings,
        isPaymentRequired: value ? false : prev.bookingProductsSettings.isPaymentRequired,
      },
      prepaymentSettings: {
        ...prev.prepaymentSettings,
        isEnabled: value,
      },
    }));
  };

  const updateBoookingAvailabilityPrepaymentCancelSettings = (
    key: keyof PrepaymentCancelInput,
    value: string | number | boolean,
  ) => {
    setBookingAvailability(prev => ({
      ...prev,
      prepaymentSettings: {
        ...prev.prepaymentSettings,
        prepaymentCancelSettings: {
          ...prev.prepaymentSettings.prepaymentCancelSettings,
          [key]: value,
        },
      },
    }));
  };

  const handleCancelPercentages = (
    key: keyof BookingPrepaymentFragment,
    value: string | number,
  ) => {
    let nb = getSafeNumberFromInput(value);

    if (nb > 100) {
      nb = 100;
    } else if (nb < 0) {
      nb = 0;
    }

    updateBoookingAvailabilityPrepaymentSettings(key, nb);
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          EMPREINTE BANCAIRE
        </CustomText>
      </Box>

      <Box
        mt="m"
        p="m"
        backgroundColor="orangeBackground"
        borderRadius="button"
        borderColor="orange"
        borderWidth={LINE_THICKNESS}
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <Box mr="s" backgroundColor="orange" p="s" borderRadius="button">
          <CREDIT_CARD width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.white} />
        </Box>
        <Box flex={1}>
          <CustomText variant="content" color="orange">
            L’empreinte bancaire dans un service, déclenche automatiquement les
            notifications clients SMS afin de sécuriser la réception des messages clients.
          </CustomText>
        </Box>
      </Box>

      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          text="Activer l'empreinte bancaire"
          value={bookingAvailability?.prepaymentSettings?.isEnabled}
          onValueChange={v => togglePrepaymentEnabled(v)}
        />
        <CustomText mt="s" variant="text" color="lightGrey">
          Demande automatique d’empreinte bancaire pour les réservations en ligne
        </CustomText>
      </Box>

      {bookingAvailability?.prepaymentSettings?.isEnabled && (
        <Box>
          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="content" color="primaryTextColor">
                A partir de
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  placeHolder="0"
                  keyboardType="number-pad"
                  hideFloatingLabel
                  hasErrors={doesInputHaveError(
                    "prepaymentSettings.minCapacityForPrepayment",
                  )}
                  value={bookingAvailability?.prepaymentSettings?.minCapacityForPrepayment.toString()}
                  onChangeText={v =>
                    updateBoookingAvailabilityPrepaymentSettings(
                      "minCapacityForPrepayment",
                      getSafeNumberFromInput(v),
                    )
                  }
                  inputStyles={{ textAlign: "center" }}
                />
                {bookingAvailability?.minMaxBookingPersons?.isEnabled &&
                  bookingAvailability?.minMaxBookingPersons?.maxPersons > 0 && (
                    <Box flexDirection="row" alignItems="center">
                      <CustomText
                        fontSize={25}
                        marginHorizontal="xs"
                        variant="text"
                        color="primaryTextColor"
                      >
                        /
                      </CustomText>
                      <CustomTextInput
                        placeHolder="50"
                        hideFloatingLabel
                        disabled
                        initialValue={bookingAvailability?.minMaxBookingPersons?.maxPersons?.toString()}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onChangeText={() => {}}
                        inputStyles={{ textAlign: "center" }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
            <CustomText mt="s" variant="text" color="lightGrey">
              Nombre de couverts à partir duquel l'empreinte bancaire est demandée
            </CustomText>
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <NumberInputLine
              hasErrors={doesInputHaveError("prepaymentSettings.amountForPrepayment")}
              textVariant="content"
              text="Montant à demander en euro"
              keyboardType="decimal-pad"
              placeHolder="Montant à demander en euro"
              value={bookingAvailability.prepaymentSettings.amountForPrepayment}
              onValueChange={v =>
                updateBoookingAvailabilityPrepaymentSettings(
                  "amountForPrepayment",
                  getSafeNumberFromInput(v),
                )
              }
            />
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomText mb="s" variant="content" color="primaryTextColor">
              Type de prélèvement
            </CustomText>

            <SelectButton
              hasErrors={doesInputHaveError("prepaymentSettings.prepaymentType")}
              isMultiSelect={false}
              options={PREPAYMENT_TYPES}
              selectedOptions={[bookingAvailability.prepaymentSettings.prepaymentType]}
              onPress={([type]) =>
                updateBoookingAvailabilityPrepaymentSettings("prepaymentType", type)
              }
              placeHolder="Type de prélèvement"
            />
          </Box>

          <Box mt="m">
            <CustomText variant="content" color="primaryTextColor">
              Délai maximum pour l’annulation
            </CustomText>
            <CustomText variant="text" color="lightGrey">
              Limite fixée au client pour annuler sa réservation avec empreinte bancaire
            </CustomText>
          </Box>

          <Box
            mt="m"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <CustomTextInput
              keyboardType="number-pad"
              hasErrors={doesInputHaveError(
                "prepaymentSettings.prepaymentCancelSettings.amount",
              )}
              value={bookingAvailability.prepaymentSettings.prepaymentCancelSettings.amount.toString()}
              onChangeText={v =>
                updateBoookingAvailabilityPrepaymentCancelSettings(
                  "amount",
                  getSafeNumberFromInput(v),
                )
              }
              hideFloatingLabel
              placeHolder=""
              inputStyles={{ textAlign: "center" }}
            />
            <SelectButton
              isMultiSelect={false}
              options={CANCEL_DELIMITER_TYPE}
              selectedOptions={[
                bookingAvailability.prepaymentSettings.prepaymentCancelSettings
                  .delimiterMesaurementType,
              ]}
              onPress={([delimiter]) =>
                updateBoookingAvailabilityPrepaymentCancelSettings(
                  "delimiterMesaurementType",
                  delimiter,
                )
              }
              placeHolder="Avant"
            />
            <SelectButton
              isMultiSelect={false}
              options={CANCEL_DELIMITER}
              selectedOptions={[
                bookingAvailability.prepaymentSettings.prepaymentCancelSettings.delimiter,
              ]}
              onPress={([delimiter]) =>
                updateBoookingAvailabilityPrepaymentCancelSettings("delimiter", delimiter)
              }
              placeHolder="Avant"
            />
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <NumberInputLine
              hasErrors={doesInputHaveError("prepaymentSettings.percentBookingCancel")}
              textVariant="content"
              text="Pourcentage annulation"
              // eslint-disable-next-line max-len
              subTitle="Si le client annule sa réservation en dehors du délai d'annulation, un pourcentage sur le montant demandé sera prélevé"
              placeHolder="Pourcentage annulation"
              value={bookingAvailability.prepaymentSettings.percentBookingCancel}
              onValueChange={v =>
                handleCancelPercentages("percentBookingCancel", getSafeNumberFromInput(v))
              }
            />
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <NumberInputLine
              hasErrors={doesInputHaveError("prepaymentSettings.percentBookingNoShow")}
              textVariant="content"
              text="Pourcentage NO SHOW"
              subTitle="Si le client ne se présente pas (noshow), un pourcentage sur le montant demandé sera prélevé."
              placeHolder="Pourcentage NO SHOW"
              value={bookingAvailability.prepaymentSettings.percentBookingNoShow}
              onValueChange={v =>
                handleCancelPercentages("percentBookingNoShow", getSafeNumberFromInput(v))
              }
            />
          </Box>

          <Box
            mt="m"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomText mb="s" variant="content" color="primaryTextColor">
              Prélèvement suite à l'empreinte
            </CustomText>
            <SelectButton
              isMultiSelect={false}
              options={PREPAYMENT_PAYMENT_TYPE}
              selectedOptions={[
                bookingAvailability.prepaymentSettings.automaticPayment
                  ? "AUTOMATIC"
                  : "MANUAL",
              ]}
              onPress={([val]) =>
                updateBoookingAvailabilityPrepaymentSettings(
                  "automaticPayment",
                  val === "AUTOMATIC",
                )
              }
              placeHolder="Prélèvement suite à l'empreinte"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ServicePrepayment;
