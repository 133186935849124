import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import { MODULE_TYPE } from "../../../../components/ModuleList/common";
import { CLIENTS } from "../../../../Data";

import CompanyListDetailsEdit from "./Edit";
import CompanyListDetailsView from "./View";

interface CompanyListDetailsProps {
  newValue?: boolean;
  companyId?: string;
  goBack?: () => void;
}

const CompanyListDetails = ({ newValue, companyId, goBack }: CompanyListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = companyId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <CompanyListDetailsEdit id={id} goBack={goBack} />
      ) : (
        <CompanyListDetailsView
          moduleType={MODULE_TYPE.CLIENTS}
          id={id}
          goBack={goBack}
        />
      )}
      {!inEditMode && (
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height={100}
          backgroundColor="white"
          p="m"
        >
          <CustomButton
            onPress={() => setInEditMode(true)}
            buttonVariant="primaryButton"
            buttonColor="success"
            styles={{
              minHeight: 45,
            }}
          >
            <CustomText variant="primaryButtonText" color="white">
              Modifier
            </CustomText>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default CompanyListDetails;
