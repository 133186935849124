import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useMemo, useState } from "react";
import DraggableFlatList from "react-native-draggable-flatlist";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import Loader from "../../../../../components/Loader";
import ProductCategoryCard from "../../../../../components/ProductCategoryCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { ProductCategoryFragment } from "../../../../../graphql/generated/schema";
import {
  useGetProductCategoriesLazyQuery,
  useSortCategoriesMutation,
  useUpdateProductCategoryMutation,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import CategoryListDetails from "../CategoryListDetails";

interface CategoryListProps {
  goBack?: () => void;
}

const CategoryList = ({ goBack }: CategoryListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<ProductCategoryFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getCategories, { fetchMore }] = useGetProductCategoriesLazyQuery();
  const [updateCategory] = useUpdateProductCategoryMutation();
  const [sortCategories] = useSortCategoriesMutation();

  const filteredCategories = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    if (formattedSearch.length === 0) {
      return categories;
    }

    return categories.filter(category => {
      const formattedName = category.name.toLowerCase().trim();

      return formattedName.includes(formattedSearch);
    });
  }, [categories, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: categories.length,
          },
        },
      });

      if (data?.getProductCategories) {
        setCategories([...categories, ...data.getProductCategories]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetCategories = async () => {
    try {
      const { data } = await getCategories({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getProductCategories) {
        setCategories([...data.getProductCategories]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetCategories();
    }, []),
  );

  const handleSortCategories = async (
    sortedProductCategories: ProductCategoryFragment[],
  ) => {
    setCategories(sortedProductCategories);
    try {
      const sortedCategoryIds = sortedProductCategories.map(category => category._id);

      await sortCategories({
        variables: {
          categories: {
            categories: sortedCategoryIds,
          },
        },
      });
    } catch (err) {
      console.log("err sort categories", err);
    }
  };

  const handleUpdateCategoryEnable = async (id: string, isEnabled: boolean) => {
    try {
      setCategories(
        categories.map(category => {
          if (category._id === id) {
            return {
              ...category,
              isEnabled,
            };
          }
          return category;
        }),
      );

      await updateCategory({
        variables: {
          categoryId: id,
          updates: {
            isEnabled,
          },
        },
      });
      await handleGetCategories();
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            path: "ERROR_UPDATE_CATEGORY",
            code: "ERROR_UPDATE_CATEGORY",
            message: "Une erreur est survenue lors de la mise à jour de la catégorie",
          },
        ],
        "error",
      );
      console.log("err", err);
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setSelectedId("");
      setShowInline(true);
    } else {
      navigation.navigate("CATEGORIES_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedId(id);
      setShowInline(true);
    } else {
      navigation.navigate("CATEGORIES_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetCategories();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <CategoryListDetails
        newValue={!selectedId}
        selectedId={selectedId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Catégories"
            hasBackButton
            rightButtons={
              <Touchable onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Touchable>
            }
            onBackPress={goBack || navigation.goBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        </Box>
        <DraggableFlatList
          contentContainerStyle={{ paddingBottom: 150 }}
          showsVerticalScrollIndicator={false}
          data={filteredCategories}
          keyExtractor={(_, index) => index.toString()}
          onEndReachedThreshold={0.8}
          onEndReached={handleFetchMore}
          renderItem={({ item, drag }) => (
            <Box marginVertical="s">
              <Touchable onPress={() => handleEdit(item._id)}>
                <ProductCategoryCard
                  name={item.name}
                  color={item.color}
                  isEnabled={item.isEnabled}
                  isSelected={false}
                  nbProducts={item.products.length}
                  updateEnabled={val => handleUpdateCategoryEnable(item._id, val)}
                  onPressIn={drag}
                />
              </Touchable>
            </Box>
          )}
          onDragEnd={({ data }) => {
            handleSortCategories(data);
          }}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default CategoryList;
