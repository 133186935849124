 
import type {
  BookingsAndServices,
  BookingsAndServicesSlot,
  OrderFragment,
} from "../../../../../../graphql/generated/schema";
import { Click_Status, Origin, Sex } from "../../../../../../graphql/generated/schema";

const CREATED_AT_DATE = new Date("2022-10-08T10:00:00.000+00:00");
const BOOKING_DATE = new Date("2022-11-08T10:00:00.000+00:00");

const PAYMENT: OrderFragment["payment"] = {
  paid: false,
  cardPaymentStatus: 0,
  paymentErrorReason: "",
  paymentType: "CARD",
};

const ITEMS: OrderFragment["items"] = {
  menus: [],
  products: [],
};

export const ORDERS: OrderFragment[] = [
  {
    _id: "636a83db31fe2f2360d5ebba1",
    createdAt: CREATED_AT_DATE,
    payment: PAYMENT,
    totalDetails: {
      currency: "eur",
      totalWithTaxes: 14,
      total: 14,
    },
    items: ITEMS,
    commentOwner: "",
    date: BOOKING_DATE,
    source: Origin.Mobile,
    serviceId: "",
    slotId: "",
    status: Click_Status.New,
    comment: "Beaucoup de sauce s'il vous plait",
    merchantClient: {
      picture: "",
      _id: "BOOKING_1_CLIENT_1",
      sex: Sex.Male,
      firstName: "Barack",
      lastName: "OBAMA",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: true,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,

      address: undefined,
      merchantCompanyId: undefined,
    },
    timeline: {
      events: [],
      messages: [],
    },
    receiptUrl: "",
    orderQuantity: 1,
  },
  {
    _id: "636a83db31fe2f2360d5ebba2",
    createdAt: CREATED_AT_DATE,
    payment: PAYMENT,
    totalDetails: {
      currency: "eur",
      totalWithTaxes: 80,
      total: 80,
    },
    items: ITEMS,
    commentOwner: "",
    date: BOOKING_DATE,
    source: Origin.Mobile,
    serviceId: "",
    slotId: "",
    status: Click_Status.Validated,
    comment: "Pas de sel s'il vous plait",
    merchantClient: {
      picture: "",
      _id: "BOOKING_2_CLIENT_1",
      sex: Sex.Female,
      firstName: "Scarlett",
      lastName: "JOHANSSON",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: true,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,

      address: undefined,
      merchantCompanyId: undefined,
    },
    timeline: {
      events: [],
      messages: [],
    },
    receiptUrl: "",
    orderQuantity: 1,
  },
  {
    _id: "636a83db31fe2f2360d5ebba3",
    createdAt: CREATED_AT_DATE,
    payment: PAYMENT,
    totalDetails: {
      currency: "eur",
      totalWithTaxes: 20,
      total: 20,
    },
    items: ITEMS,
    commentOwner: "",
    date: BOOKING_DATE,
    source: Origin.Mobile,
    serviceId: "",
    slotId: "",
    status: Click_Status.Noshow,
    comment: "Pas de cornichons",
    merchantClient: {
      picture: "",
      _id: "BOOKING_3_CLIENT_1",
      sex: Sex.Female,
      firstName: "Keanu",
      lastName: "REEVES",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: false,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,
      address: undefined,
      merchantCompanyId: undefined,
    },
    timeline: {
      events: [],
      messages: [],
    },
    receiptUrl: "",
    orderQuantity: 1,
  },
];

const ORDER_LISTING_SLOTS_PREVIEW_DATA: BookingsAndServicesSlot = {
  accumulatedTotalInSlot: ORDERS.reduce((acc, val) => acc + val.orderQuantity, 0),
  data: ORDERS,
  slotId: "SLOT_1",
  slotTime: "11h00",
  totalInSlot: ORDERS.length,
};

export const ORDER_LISTING_PREVIEW_DATA: BookingsAndServices = {
  accumulatedTotalInService: ORDERS.reduce((acc, val) => acc + val.orderQuantity, 0),
  daysAvailable: [0, 1, 2, 3, 4, 5, 6],
  serviceId: "SERVICE_1",
  serviceName: "MATIN",
  slots: [ORDER_LISTING_SLOTS_PREVIEW_DATA],
  startTime: "11h00",
  totalInService: ORDERS.length,
};
