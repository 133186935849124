import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

import type { Booking_Status } from "../../../../../EIWIE_CLIENT_FRONTEND/src/graphql/generated/schema";
import type {
  BookingFragment,
  InternalNotification,
} from "../../../graphql/generated/schema";
import {
  useGetBookingLazyQuery,
  useUpdateBookingMutation,
} from "../../../graphql/generated/schema";
import { displayModuleId } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import BookingListCardStatusButton from "../../BookingList/BookingListCardStatusButton";

const IS_WEB = Platform.OS === "web";

interface NewBookingLocalNotificationProps {
  notification: InternalNotification;
  handleAckItem: (id: string) => void;
}

const NewBookingLocalNotification = ({
  notification,
  handleAckItem,
}: NewBookingLocalNotificationProps) => {
  const [booking, setBooking] = useState<BookingFragment | undefined>(undefined);

  const [getBooking] = useGetBookingLazyQuery();
  const [updateBooking] = useUpdateBookingMutation();

  const handleGetBooking = async () => {
    try {
      const { data, error } = await getBooking({
        fetchPolicy: "cache-and-network",
        variables: {
          bookingId: notification.resourceId,
        },
      });

      if (data?.getBookingForPro) {
        setBooking(data.getBookingForPro);
      }
    } catch (err) {
      console.log("err gettings booking for local notification", err);
    }
  };

  useEffect(() => {
    handleGetBooking();
  }, []);

  const handleBookingStatusChange = async (
    newStatus: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => {
    try {
      const result = await updateBooking({
        variables: {
          bookingId: notification.resourceId,
          booking: {
            status: newStatus,
            refusalReason,
            applyAutomaticPrepaymentPercentage,
            sendClientNotifications: true,
          },
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      handleAckItem(itemId);
    }
  };

  if (!booking) {
    return null;
  }

  const {
    _id: itemId,
    merchantClient: client,
    date,
    source,
    status,
    nbPersons,
  } = booking;
  const formattedDate = format(new Date(date), "dd/MM HH:mm");

  const formattedClientName = `${client?.lastName || ""} ${
    client?.firstName || ""
  }`.trim();

  return (
    <Box
      borderRadius="button"
      p="s"
      backgroundColor="white"
      width="100%"
      alignSelf="flex-end"
      maxWidth={IS_WEB ? 370 : undefined}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="yellow">
          {notification.title}
        </CustomText>
        {/* <CustomText variant="content" color="primaryTextColor">
          Maintenant
        </CustomText> */}
      </Box>

      <Box
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          minHeight: 92,
        }}
      >
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <BookingListCardOrigin origin={source} />

            <CustomText numberOfLines={1} ml="s" variant="listItemMainText">
              {formattedClientName}
            </CustomText>
          </Box>
          <CustomText variant="content">x{nbPersons}</CustomText>
          <CustomText variant="content">{formattedDate}</CustomText>

          <BookingListCardStatusButton
            status={status}
            onPress={handleBookingStatusChange}
            isDisabled={false}
          />
        </Box>

        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Box>
              <BookingLIstCardClientFidelity
                occurences={client?.bookingOccurences || 0}
              />
            </Box>

            <Box ml="s">
              <CustomText paddingHorizontal="m" variant="listItemSubText">
                {displayModuleId(itemId)}
              </CustomText>
            </Box>
          </Box>
          {/* {comment && (
            <Box flexDirection="row" alignItems="center" flex={1} flexWrap="wrap">
              <MESSAGE fill={PALETTE.darkBlue} />
              <Box ml="s">
                <CustomText variant="content" color="primaryTextColor">
                  {comment}
                </CustomText>
              </Box>
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default NewBookingLocalNotification;
