import { format } from "date-fns";
import { useState } from "react";
import { TouchableOpacity } from "react-native";

import BLACKLISTED from "../../../../assets/icons/BASE/BLACKLISTED.svg";
import MESSAGE from "../../../../assets/icons/BASE/MESSAGE.svg";
import MORE_DOTS from "../../../../assets/icons/BASE/MORE_DOTS.svg";
import VIP from "../../../../assets/icons/BASE/VIP.svg";
import type { TicketFragment } from "../../../graphql/generated/schema";
import { Origin } from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { displayTicketClientName } from "../../../utils/CashRegister";
import {
  displayModuleId,
  displayTables,
  formatCurrencyPrice,
} from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import { CustomButton } from "../../Button";

import CashRegisterTicketListCardStatus, {
  CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE,
} from "./CashRegisterTicketListCardStatus";

interface CashRegisterTicketListCardProps {
  item: TicketFragment;
  onPress: (itemId: string) => void;
  onExtraActionPress: (itemId: string) => void;
  disabled?: boolean;
}

const HEIGHT = 92;

const CashRegisterTicketListCard = ({
  item,
  onPress,
  onExtraActionPress,
  disabled = false,
}: CashRegisterTicketListCardProps) => {
  const [cardContainer, setCardContainer] = useState({ width: 0, height: 0, x: 0, y: 0 });

  const {
    _id: itemId,
    createdAt,
    merchantClient,
    date,
    tables = [],
    commentOwner = "",
    status,
    paymentDetails,
    nbPersons = 0,
  } = item;

  const formattedCreatedDate = format(new Date(createdAt), "dd/MM/yyyy - HH:mm");
  const formattedDate = format(new Date(date), "HH:mm");

  const ICON_VIEW_TYPE =
    cardContainer.width >= 600
      ? CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE.TEXT
      : CASH_REGISTER_TICKET_CARD_STATUS_LAYOUT_TYPE.ICON;

  return (
    <Box
      onLayout={e => setCardContainer(e.nativeEvent.layout)}
      flexDirection="row"
      alignItems="center"
    >
      <TouchableOpacity
        disabled={disabled}
        style={{ flex: 1 }}
        onPress={() => onPress(itemId)}
      >
        <Box>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flex={0.4}>
              <CustomText variant="label">
                {displayTicketClientName(merchantClient)}
              </CustomText>
            </Box>
            <Box
              flex={0.8}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content">x{nbPersons}</CustomText>
              <Box alignItems="center" minWidth={50}>
                <CustomText variant="content">
                  {tables?.length > 0 ? displayTables(tables || []) : "-"}
                </CustomText>
              </Box>
              <CustomText variant="content">{formattedDate}</CustomText>

              <Box marginHorizontal="s" alignItems="flex-end">
                {paymentDetails?.paidAmount > 0 && (
                  <CustomText variant="listItemSubText">
                    {formatCurrencyPrice(paymentDetails.remainingAmount, "fr", "eur")}
                  </CustomText>
                )}

                <CustomText variant="listItemMainText">
                  {formatCurrencyPrice(paymentDetails.total, "fr", "eur")}
                </CustomText>
              </Box>

              <CashRegisterTicketListCardStatus
                status={status}
                viewType={ICON_VIEW_TYPE}
              />
            </Box>
            {!disabled && (
              <CustomButton
                styles={{ ml: "s" }}
                onPress={() => onExtraActionPress(itemId)}
              >
                <MORE_DOTS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} />
              </CustomButton>
            )}
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Box mr="m">
              <BookingLIstCardClientFidelity
                occurences={merchantClient?.ticketOccurences}
              />
            </Box>
            <Box mr="s">
              <BookingListCardOrigin origin={Origin.Local} />
            </Box>

            {commentOwner && (
              <Box mr="s">
                <MESSAGE fill={PALETTE.darkBlue} />
              </Box>
            )}

            {merchantClient?.isVIP && (
              <Box mr="s">
                <VIP width={15} height={15} fill={PALETTE.green} />
              </Box>
            )}
            {merchantClient?.isBlacklisted && (
              <Box mr="s">
                <BLACKLISTED fill={PALETTE.red} />
              </Box>
            )}
          </Box>

          {commentOwner && (
            <Box flexDirection="row" alignItems="center" mt="xs">
              <CustomText variant="content" color="lightGrey">
                Note sur le ticket:
                <CustomText variant="text" color="lightGrey">
                  {" "}
                  {commentOwner}
                </CustomText>
              </CustomText>
            </Box>
          )}

          <Box flexDirection="row" alignItems="center" mt="s">
            <CustomText variant="text" color="lightGrey">
              Commande créé le {formattedCreatedDate} - {displayModuleId(itemId)}
            </CustomText>
          </Box>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default CashRegisterTicketListCard;
