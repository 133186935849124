import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import CALENDAR from "../../../../../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../../../../../assets/icons/BASE/CUTLERY.svg";
import OCCURENCES from "../../../../../../../assets/icons/BASE/OCCURENCES.svg";
import PAX_PERSONS from "../../../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import PENDING from "../../../../../../../assets/icons/BASE/PENDING.svg";
import PRICE_TAG from "../../../../../../../assets/icons/BASE/PRICE_TAG.svg";
import SHOPPING_BAG from "../../../../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../../../components/InputSectionTitle";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { OnlineSalesAvailabilityFragment } from "../../../../../../graphql/generated/schema";
import { useGetOnlineSalesAvailabilityLazyQuery } from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import { getWeekdayFromNumber } from "../../../../../../utils/common";

const ICON_SIZE = 16;

interface TakeAwayServiceListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const TakeAwayServiceListDetailsView = ({
  id,
  goBack,
}: TakeAwayServiceListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<OnlineSalesAvailabilityFragment | null>(null);

  const [getService] = useGetOnlineSalesAvailabilityLazyQuery();

  const handleGetService = async () => {
    setLoading(true);
    try {
      const result = await getService({
        variables: {
          id,
        },
      });
      if (result.data) {
        setService(result.data.getOnlineSalesAvailability);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetService();
  }, []);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!service) {
    return (
      <Box>
        <ScreenHeader title="Service" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations du service." />
      </Box>
    );
  }

  const displayServiceStartDaysAndEndDays = () => {
    const { visibilitySetting } = service;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      return recurringDates.days.map(getWeekdayFromNumber).join("\n");
    }
    const { startDate, endDate } = specificDates;

    return `${format(new Date(startDate), "dd/mm/yyyy")} - ${format(
      new Date(endDate),
      "dd/mm/yyyy",
    )}`;
  };

  const displayServiceStartTimesAndEndTimes = () => {
    const { visibilitySetting } = service;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      const { startTime, endTime } = recurringDates;
      return `${startTime} - ${endTime}`;
    }
    const { startTime, endTime } = specificDates;
    return `${startTime} - ${endTime}`;
  };

  const {
    name,
    interval,
    totalOrdersAllowed,
    maxOrdersAllowedForSlot,
    minArticles,
    minPrice,
    maxArticles,
    maxPrice,
    automaticClickConfirmationSettings,
    waitingListSettings,
  } = service;

  return (
    <Box flex={1}>
      <ScreenHeader title={name} hasBackButton onBackPress={handleGoBack} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150 }}
      >
        <Box mt="m">
          <InputSectionTitle text="PARAMÈTRES SERVICES" />
        </Box>
        <TabTextInfo
          {...{
            title: "Jours actifs",
            content: displayServiceStartDaysAndEndDays(),
            icon: (
              <CALENDAR height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Horaires de commande",
            content: displayServiceStartTimesAndEndTimes(),
            icon: (
              <OCCURENCES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        <TabTextInfo
          {...{
            title: "Intervale entre 2 créneaux",
            content: `${interval} minutes`,
            icon: (
              <PENDING height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        {totalOrdersAllowed.isEnabled && (
          <TabTextInfo
            {...{
              title: "Nombre de commandes",
              content: `${totalOrdersAllowed.value}`,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}

        {maxOrdersAllowedForSlot.isEnabled && (
          <TabTextInfo
            {...{
              title: "Commandes max/créneau",
              content: `${maxOrdersAllowedForSlot.value}`,
              icon: (
                <CUTLERY height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        {minArticles.isEnabled && (
          <TabTextInfo
            {...{
              title: "Articles minimum",
              content: `${minArticles.value}`,
              icon: (
                <SHOPPING_BAG
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}

        {maxArticles.isEnabled && (
          <TabTextInfo
            {...{
              title: "Articles maximum",
              content: `${maxArticles.value}`,
              icon: (
                <SHOPPING_BAG
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}
        {minPrice.isEnabled && (
          <TabTextInfo
            {...{
              title: "Prix minimum",
              content: `${minPrice.value}`,
              icon: (
                <PRICE_TAG
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}
        {maxPrice.isEnabled && (
          <TabTextInfo
            {...{
              title: "Prix maximum",
              content: `${maxPrice.value}`,
              icon: (
                <PRICE_TAG
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}

        <Box mt="m">
          <InputSectionTitle text="Avancé" />
        </Box>
        {automaticClickConfirmationSettings?.acceptAutomatically && (
          <TabTextInfo
            {...{
              title: "Accepter automatiquement toutes les commandes",
              content: "Oui",
              icon: <></>,
            }}
          />
        )}
        {automaticClickConfirmationSettings?.acceptAutomaticallyWithLimits && (
          <TabTextInfo
            {...{
              title:
                "Accepter automatiquement toutes les commandes par rapport aux limites",
              // eslint-disable-next-line max-len
              content: `${automaticClickConfirmationSettings.minArticlesPerOrder} articles ou ${automaticClickConfirmationSettings.minPricePerOrder} euro`,
              icon: <></>,
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Liste d’attente (sur commande)",
            content: `${waitingListSettings?.isEnabled ? "Oui" : "Non"}\n${
              waitingListSettings?.totalOrdersAllowed || 0
            } commandes\n`,
            icon: <></>,
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default TakeAwayServiceListDetailsView;
