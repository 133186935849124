 
// eslint-disable-next-line max-len
import type { ProductGroupOptionsSelection } from "../components/CashRegister/CashRegisterProductSelection/ProductGroupOptionsModalContent";
import type {
  CashRegisterTicketItemProduct,
  ProductFragment,
  TicketFragment,
  TicketGroupOptionFragment,
  TicketOptionFragment,
  TicketProductFragment,
} from "../graphql/generated/schema";
import { Cash_Register_Ticket_Status } from "../graphql/generated/schema";
import type { THEME_COLORS } from "../theme";
import type { PALETTE_COLORS } from "../theme/Palette";

import { getUID } from "./common";

export const handleFormatAndAddProduct = (
  product: ProductFragment,
  groupOptionsSelection: ProductGroupOptionsSelection[],
  inMenu = false,
) => {
  const { basePrice, menuExtraPrice } = product;
  const { amount, tax } = basePrice;
  const { amount: menuExtraPriceAmount = 0 } = menuExtraPrice;
  const taxRate = tax?.taxRate || 0;

  const productPrice = !inMenu ? amount : menuExtraPriceAmount;

  const consolidatedGroupOptions = product.groupOptions
    .filter(productGroupOption =>
      groupOptionsSelection.find(
        selection => selection.groupOptionId === productGroupOption._id,
      ),
    )
    .map(productGroupOption => {
      const { options } = productGroupOption;
      const selectedOptionsForCurrentGroupOption = groupOptionsSelection.find(
        o => o.groupOptionId === productGroupOption._id,
      );

      if (!selectedOptionsForCurrentGroupOption) return null;
      const { options: selectedOptionsForGroupOption } =
        selectedOptionsForCurrentGroupOption;

      const selectedOptions = options.filter(option =>
        selectedOptionsForGroupOption.includes(option._id),
      );

      return {
        ...productGroupOption,
        options: selectedOptions,
      };
    })
    .filter(val => val) as ProductFragment["groupOptions"];

  const groupOptions: TicketGroupOptionFragment[] = consolidatedGroupOptions.map(
    groupOption => {
      const {
        _id: groupOptionId,
        name,
        options,
        selectionSettings,
        basePrice: groupOptionsBasePrice,
      } = groupOption;

      const { maxSelection, extraPrice } = selectionSettings;
      const selectedOptionsLength = options.length;
      const amountToAdd = selectedOptionsLength >= maxSelection ? extraPrice?.amount : 0;
      const price = {
        amount: groupOptionsBasePrice.amount + amountToAdd,
        taxRate: groupOptionsBasePrice.tax?.taxRate || 0,
      };

      const formattedOptions: TicketOptionFragment[] = options.map(option => {
        const { _id: optionId, name: optionName, basePrice: optionBasePrice } = option;
        const optionPrice = {
          amount: optionBasePrice.amount,
          taxRate: optionBasePrice.tax?.taxRate || 0,
        };
        return {
          optionId,
          name: optionName,
          price: optionPrice,
        };
      });

      return {
        groupOptionId,
        name,
        price,
        options: formattedOptions,
      };
    },
  );

  let formattedProduct: Omit<
    CashRegisterTicketItemProduct,
    "categoryId" | "ticketStepId"
  > = {
    uid: getUID(),
    productId: product._id,
    name: product.name,
    price: {
      amount: productPrice,
      taxRate,
    },
    quantity: 1,
    accumulatedTotalAmount: productPrice,
    comment: "",
    groupOptions,
    totalFreeDiscountAmount: 0,
  };

  const accumulatedTotalAmount = calculateAccumulatedProductPrice(formattedProduct);

  formattedProduct = {
    ...formattedProduct,
    accumulatedTotalAmount,
  };

  return formattedProduct;
};

export const calculateAccumulatedProductPrice = (
  product: Omit<TicketProductFragment, "categoryId" | "ticketStepId">,
  inMenu = false,
) => {
  const productPrice = product.price.amount || 0;

  const groupOptionsTotalPrice = product.groupOptions.reduce((acc, groupOption) => {
    const groupOptionPrice = groupOption.price.amount;
    const optionsPrice = groupOption.options.reduce(
      (oacc, option) => oacc + (option?.price?.amount || 0),
      0,
    );
    return acc + groupOptionPrice + optionsPrice;
  }, 0);

  const accumulatedTotalAmount = productPrice + groupOptionsTotalPrice;

  return accumulatedTotalAmount;
};

export const displayTicketClientName = (client?: TicketFragment["merchantClient"]) => {
  if (!client) return "PASSAGE";

  const { firstName = "", lastName = "" } = client;

  return `${firstName} ${lastName}`.trim();
};

export const determineCorrectStatusColorForCashRegister = (
  status: Cash_Register_Ticket_Status,
): {
  borderColor: THEME_COLORS;
  iconColor: PALETTE_COLORS;
} => {
  switch (status) {
    case Cash_Register_Ticket_Status.Open:
      return {
        borderColor: "orangeBackground",
        iconColor: "orange",
      };
    case Cash_Register_Ticket_Status.Ordered:
      return {
        borderColor: "greenBackground",
        iconColor: "green",
      };
    case Cash_Register_Ticket_Status.Sent:
      return {
        borderColor: "blueBackground",
        iconColor: "blueTertiary",
      };
    case Cash_Register_Ticket_Status.CurrentlyPaying:
      return {
        borderColor: "greenBackground",
        iconColor: "green",
      };
    case Cash_Register_Ticket_Status.Paid:
      return {
        borderColor: "lightGrey",
        iconColor: "ORDERONLINE_PAID",
      };
    case Cash_Register_Ticket_Status.Cancelled:
      return {
        borderColor: "offWhite",
        iconColor: "ORDERONLINE_CANCELLED",
      };
    case Cash_Register_Ticket_Status.Refunded:
      return {
        borderColor: "redBackground",
        iconColor: "red",
      };
    case Cash_Register_Ticket_Status.Closed:
      return {
        borderColor: "lightPurple",
        iconColor: "purple",
      };
  }
  return { borderColor: "success", iconColor: "green" };
};
