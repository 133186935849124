import { useState } from "react";

import type { BookingFloor } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import { CustomTextInput } from "../../TextInput";

interface BookingFloorDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialFloor: BookingFloor;
  onSubmit: (floor: BookingFloor) => void;
}

const BookingFloorDetailsModal = ({
  isOpen,
  onClose,
  initialFloor,
  onSubmit,
}: BookingFloorDetailsModalProps) => {
  const [floor, setFloor] = useState(initialFloor);

  const handleOnClose = () => {
    setFloor(initialFloor);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(floor);
    onClose();
  };

  const isDisabled =
    floor?.name?.trim()?.length === 0 || floor?.prefix?.trim()?.length === 0;

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={handleOnClose}
      title="Confgurer la salle"
      styles={{
        height: "auto",
      }}
    >
      <Box>
        <Box mt="m">
          <Box mt="s">
            <CustomTextInput
              initialValue={floor?.name}
              onChangeText={t => setFloor({ ...floor, name: t })}
              placeHolder="Nom de la salle"
              isRequired
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              initialValue={floor?.prefix}
              onChangeText={t => setFloor({ ...floor, prefix: t })}
              placeHolder="Préfixe de la salle"
              isRequired
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              initialValue={floor?.description}
              onChangeText={t => setFloor({ ...floor, description: t })}
              placeHolder="Commentaire"
            />
          </Box>
        </Box>

        <Box mt="l">
          <Box mb="s">
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={handleOnClose}
            >
              <CustomText variant="outlineButtonText" color="danger">
                Annuler
              </CustomText>
            </CustomButton>
          </Box>
          <Box mb="s">
            <CustomButton
              disabled={isDisabled}
              buttonVariant="primaryButton"
              buttonColor={isDisabled ? "disabled" : "success"}
              onPress={handleSubmit}
            >
              <CustomText
                variant="primaryButtonText"
                color={isDisabled ? "lightGrey" : "white"}
              >
                Valider
              </CustomText>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default BookingFloorDetailsModal;
