import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";

import CAMERA from "../../../../../assets/icons/BASE/CAMERA.svg";
import EIWIE from "../../../../../assets/icons/BASE/EIWIE.svg";
import IDENTIFICATION from "../../../../../assets/icons/BASE/IDENTIFICATION.svg";
import OCCURENCES from "../../../../../assets/icons/BASE/OCCURENCES.svg";
import Box from "../../../../components/Base/Box";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import Characteristics from "../Characteristics";
import HoursAndLocations from "../HoursAndLocations";
import MerchantInfo from "../MerchantInfo";
import MerchantPhotos from "../MerchantPhotos";

interface EstablishmentSettingsListProps {
  goBack?: () => void;
}

const EstablishmentSettingsList = ({ goBack }: EstablishmentSettingsListProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: string) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Informations"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Établissement"
            subText="Générales, administratives, pratiques"
            onPress={() => handleNavigation("MERCHANT_INFO")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<IDENTIFICATION fill={PALETTE.green} height={15} width={15} />}
          />
          <SettingsButtonItem
            title="Photos"
            subText="Couverture & autres photos"
            onPress={() => handleNavigation("MERCHANT_PHOTOS")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<CAMERA fill={PALETTE.green} height={18} width={18} />}
          />
          <SettingsButtonItem
            title="Caractéristiques"
            subText="En fonction de votre établissement"
            onPress={() => handleNavigation("MERCHANT_CHARACTERISTICS")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<EIWIE fill={PALETTE.green} height={18} width={18} />}
          />
          <SettingsButtonItem
            title="Horaires & Lieux"
            subText="En fonction de votre établissement"
            onPress={() => handleNavigation("MERCHANT_HOURS")}
            bottomLineWidth={0}
            icon={<OCCURENCES fill={PALETTE.green} height={15} width={15} />}
          />
        </Box>
      </Box>
    );
  };

  const handleClose = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "MERCHANT_INFO":
        return <MerchantInfo goBack={handleClose} />;
      case "MERCHANT_PHOTOS":
        return <MerchantPhotos goBack={handleClose} />;
      case "MERCHANT_CHARACTERISTICS":
        return <Characteristics goBack={handleClose} />;
      case "MERCHANT_HOURS":
        return <HoursAndLocations goBack={handleClose} />;

      default:
        break;
    }
  };

  const displayContent = () => {
    if (!selectedTab) {
      return displaySettingsList();
    }

    return displaySelectedTab();
  };

  return <>{displayContent()}</>;
};

export default EstablishmentSettingsList;
