/* eslint-disable @typescript-eslint/no-empty-function */
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { format, isSameDay } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import { Alert } from "react-native";

import ModuleList from "../../../components/ModuleList";
import SplitView from "../../../components/SplitView";
import { AppContext } from "../../../contexts/AppContext";
import { SubscriptionsContext } from "../../../contexts/SubscriptionsContext";
import type {
  Click_Status,
  GetOnlineSalesAvailabilitiesWithOrderInfoQuery,
  GetOrdersAndServicesQuery,
  OnlineSalesSettingsFragment,
} from "../../../graphql/generated/schema";
import {
  Closure_Types,
  Modules,
  useDeleteOrderMutation,
  useGetOnlineSalesAvailabilitiesWithOrderInfoLazyQuery,
  useGetOnlineSalesSettingsLazyQuery,
  useGetOrdersAndServicesLazyQuery,
  useGetOrdersForMonthLazyQuery,
  useSearchOrdersLazyQuery,
  useToggleServiceVisibilityMutation,
  useToggleSlotVisibilityMutation,
} from "../../../graphql/generated/schema";
import type { SalesStackParamList } from "../../../navigation/AppStack/SalesStack/ParamList";
import { captureAndReportErrors } from "../../../sentry";
import OnlineOrdersListDetails from "../OnlineOrdersListDetails/index";

type OnlineSalesListScreenNavigationProps = NativeStackNavigationProp<
  SalesStackParamList,
  "ONLINE_SALES_LIST"
>;

enum RIGHT_COMPONENT {
  NONE = "NONE",
  ORDER_DETAILS = "ORDER_DETAILS",
}

const OnlineOrdersList = () => {
  const appContext = useContext(AppContext);
  const navigation = useNavigation<OnlineSalesListScreenNavigationProps>();
  const subscriptionsHandlerContext = useContext(SubscriptionsContext);
  const [selectedService, setSelectedService] = useState("ALL");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [ordersByService, setOrdersByService] = useState<
    GetOrdersAndServicesQuery["getOrdersAndServices"]
  >([]);
  const [orderAvailability, setOrderAvailability] = useState<
    GetOnlineSalesAvailabilitiesWithOrderInfoQuery["getOnlineSalesAvailabilitiesWithOrderInfo"]
  >([]);
  const [ordersByMonth, setOrdersByMonth] = useState({});
  const [orderSettings, setOrderSettings] = useState<
    OnlineSalesSettingsFragment["orderListSettings"] | null
  >(null);
  const [selectedId, setSelectedId] = useState("");
  const [rightComponentViewType, setRightComponentViewType] = useState(
    RIGHT_COMPONENT.NONE,
  );
  const [isServiceSlotCloseInProgress, setIsServiceSlotCloseInProgress] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<Click_Status[]>([]);
  const [shouldShowStatusFilterBadge, setShouldShowStatusFilterBadge] = useState(false);

  const [getOrdersAvailability] = useGetOnlineSalesAvailabilitiesWithOrderInfoLazyQuery();
  const [getOnlineSalesSettings] = useGetOnlineSalesSettingsLazyQuery();
  const [toggleServiceVisibility] = useToggleServiceVisibilityMutation();
  const [toggleServiceSlotVisibility] = useToggleSlotVisibilityMutation();
  const [deleteOrder] = useDeleteOrderMutation();

  const [getOrdersByMonth] = useGetOrdersForMonthLazyQuery();
  const [getOrders, { fetchMore }] = useGetOrdersAndServicesLazyQuery();
  const [searchOrders, { fetchMore: fetchMoreSearchOrders }] = useSearchOrdersLazyQuery();

  const handleSearch = async (search: string) => {
    try {
      if (!search) {
        handleRefresh(false);
      } else {
        const response = await searchOrders({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 30,
              offset: 0,
            },
            searchQuery: search,
          },
          fetchPolicy: "cache-and-network",
        });

        if (response.data?.searchOrders) {
          setOrdersByService(response.data.searchOrders);
        }
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("AN_ERROR_OCCURED_SEARCH_BOOKINGS", err);
    }
  };

  const handleGetOrderSettings = async () => {
    try {
      const { data } = await getOnlineSalesSettings({
        fetchPolicy: "cache-and-network",
      });
      if (data?.getOnlineSalesSettings) {
        setOrderSettings(data.getOnlineSalesSettings.orderListSettings);
      }
    } catch (err) {
      console.log("err", err);
      captureAndReportErrors(err);
    }
  };

  const handleGetOrdersForMonth = async (date: Date) => {
    try {
      const result = await getOrdersByMonth({
        variables: {
          date,
        },
      });

      if (result.data) {
        const ordersByDates = result.data.getOrdersByMonth.dates;
        const items: Record<string, unknown> = {};

        ordersByDates
          .map(d => format(new Date(d), "yyyy-MM-dd"))
          .forEach(d => {
            items[d] = {
              selected: true,
            };
          });

        setOrdersByMonth(items);
      }
    } catch (err) {
      console.log("err get order month", err);
      captureAndReportErrors(err);
    }
  };

  useEffect(() => {
    handleGetOrderSettings();
  }, []);

  useEffect(() => {
    if (subscriptionsHandlerContext?.orders?.length > 0) {
      handleRefresh(true);
    }
  }, [subscriptionsHandlerContext.orders]);

  useFocusEffect(
    useCallback(() => {
      handleRefresh(true);

      // handleGetOrderSettings();

      setRightComponentViewType(RIGHT_COMPONENT.NONE);
      setSelectedId("");
    }, [selectedDate, selectedService, selectedStatuses]),
  );

  const handleGetOrdersAvailability = async () => {
    try {
      const { data } = await getOrdersAvailability({
        variables: { date: selectedDate },
        fetchPolicy: "cache-and-network",
      });

      if (data?.getOnlineSalesAvailabilitiesWithOrderInfo) {
        setOrderAvailability(data?.getOnlineSalesAvailabilitiesWithOrderInfo || []);
      }
    } catch (err) {
      console.log("err handleGetOrdersAvailability", err);
      captureAndReportErrors(err);
    }
  };

  const handleFetchMore = async () => {
    if (isRefreshing) return;

    try {
      const offset = ordersByService?.reduce((acc, curr) => {
        return (
          acc +
          curr?.slots?.reduce((slotAacc, slotCurr) => {
            return slotAacc + slotCurr?.data?.length || 0;
          }, 0)
        );
      }, 0);

      let newOrders: GetOrdersAndServicesQuery["getOrdersAndServices"] = [];

      if (isSearchOpen) {
        const result = await fetchMoreSearchOrders({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 50,
              offset,
            },
          },
        });
        const { data } = result;

        if (data.searchOrders) {
          newOrders = JSON.parse(JSON.stringify(data.searchOrders));
        }
      } else {
        const result = await fetchMore({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 50,
              offset,
            },
          },
        });
        const { data } = result;

        if (data.getOrdersAndServices) {
          newOrders = JSON.parse(JSON.stringify(data.getOrdersAndServices));
        }
      }

      if (newOrders) {
        let base = [...ordersByService];

        newOrders.forEach(item => {
          const foundService = base.find(service => service.serviceId === item.serviceId);

          if (foundService) {
            base = base.map(s => {
              if (s.serviceId === foundService.serviceId) {
                const combinedSlots = [...foundService.slots, ...item.slots];

                const uniqueSlots = combinedSlots.reduce((acc, curr) => {
                  const foundSlot = acc.find(slot => slot.slotTime === curr.slotTime);

                  if (foundSlot) {
                    const updatedFoundSlots = acc.map(slot => {
                      if (slot.slotTime === foundSlot.slotTime) {
                        const updatedTotalInSlot = slot.totalInSlot + curr.totalInSlot;
                        const updatedAccumulatedTotalInSlot =
                          slot.accumulatedTotalInSlot + curr.accumulatedTotalInSlot;
                        return {
                          ...slot,
                          totalInSlot: updatedTotalInSlot,
                          accumulatedTotalInSlot: updatedAccumulatedTotalInSlot,
                          data: [...slot.data, ...curr.data],
                        };
                      }
                      return slot;
                    });

                    return updatedFoundSlots;
                  }
                  return [...acc, curr];
                }, []);

                const uniqueSlotsWithUniqueData = uniqueSlots.map(slot => {
                  const uniqueData = [];

                  slot.data.forEach(data => {
                    const foundData = uniqueData.find(d => d?._id === data._id);

                    if (!foundData) {
                      uniqueData.push(data);
                    }
                  });

                  return {
                    ...slot,
                    data: uniqueData,
                  };
                });

                return {
                  ...foundService,
                  // totalInService: foundService.totalInService + item.totalInService,
                  // accumulatedTotalInService:
                  //   foundService.accumulatedTotalInService +
                  //   item.accumulatedTotalInService,
                  // slots: [...foundService.slots, ...item.slots],
                  slots: uniqueSlotsWithUniqueData,
                };
              }
              return s;
            });
          } else {
            base.push(item);
          }
        });

        setOrdersByService(base);
      }
    } catch (err) {
      console.log("err fetch more", err);
      captureAndReportErrors(err);
    }
  };

  const handleGetOrders = async (
    date = new Date(),
    serviceId: string,
    refresh = false,
    statuses: Click_Status[] = [],
  ) => {
    try {
      const consolidatedSelectedService = serviceId === "ALL" ? null : serviceId;

      const result = await getOrders({
        variables: {
          date: date?.toISOString(),
          serviceId: consolidatedSelectedService,
          statuses,
          pagination: {
            limit: 50,
            offset: 0,
          },
        },
        fetchPolicy: refresh ? "cache-and-network" : "cache-first",
      });

      const { data } = result;

      setOrdersByService(data?.getOrdersAndServices || []);
    } catch (err) {
      console.log("err handleGetOrders", err);
      captureAndReportErrors(err);
    }
  };

  const handleRefresh = async (refresh = true) => {
    if (refresh) {
      setIsRefreshing(true);

      setSelectedId("");
    }

    try {
      await handleGetOrders(selectedDate, selectedService, refresh);
      await handleGetOrdersAvailability();
    } catch (err) {
      console.log("err refresh", err);
      captureAndReportErrors(err);
    } finally {
      setIsRefreshing(false);
    }
  };

  // const handleRefreshOrderAvailability = async () => {
  //   try {
  //     const result = await getOrdersAvailability({
  //       variables: { date: selectedDate },
  //       fetchPolicy: "cache-and-network",
  //     });

  //     if (result.data?.getOnlineSalesAvailabilitiesWithOrderInfo) {
  //       setOrderAvailability(result.data?.getOnlineSalesAvailabilitiesWithOrderInfo);
  //     }
  //   } catch (err) {
  //     console.log("err refresh handleRefreshOrderAvailability", err);
  //   }
  // };

  const handleCloseAllServices = async () => {
    try {
      const serviceIds = orderAvailability.map(service => service.serviceId);

      await Promise.all(
        serviceIds.map(
          async serviceId =>
            await toggleServiceVisibility({
              variables: {
                serviceId,
                type: Closure_Types.OnlineSales,
                date: selectedDate,
              },
            }),
        ),
      );
    } catch (err) {
      console.log("AN ERROR OCCURED", err);
      captureAndReportErrors(err);
    }
  };

  const handleCloseServiceSlot = async (serviceId: string, slotId?: string) => {
    try {
      setIsServiceSlotCloseInProgress(true);
      if (slotId) {
        await toggleServiceSlotVisibility({
          variables: {
            serviceId,
            slotId,
            type: Closure_Types.OnlineSales,
            date: selectedDate,
          },
        });
      } else {
        if (serviceId === "ALL") {
          await handleCloseAllServices();
        } else {
          await toggleServiceVisibility({
            variables: {
              serviceId,
              type: Closure_Types.OnlineSales,
              date: selectedDate,
            },
          });
        }
      }
      await handleGetOrdersAvailability();
    } catch (err) {
      console.log("err", err);
      captureAndReportErrors(err);
    } finally {
      setIsServiceSlotCloseInProgress(false);
    }
  };

  const handleOrderDetailsPress = (itemId: string) => {
    if (appContext.isSplittable) {
      setRightComponentViewType(RIGHT_COMPONENT.ORDER_DETAILS);
      setSelectedId(itemId);
    } else {
      navigation.navigate("ONLINE_SALES_LIST_DETAILS", { orderId: itemId });
    }
  };

  const handleCloseSplitView = () => {
    if (appContext.isSplittable) {
      setSelectedId("");
      setRightComponentViewType(RIGHT_COMPONENT.NONE);
    }
  };

  const displayRightComponent = () => {
    if (rightComponentViewType === RIGHT_COMPONENT.ORDER_DETAILS && selectedId)
      return (
        <OnlineOrdersListDetails
          orderId={selectedId}
          goBack={handleCloseSplitView}
          onUpdateComplete={handleRefresh}
          refreshDetails={refreshDetails}
        />
      );
  };

  const handleDeleteOrder = async (itemId: string) => {
    try {
      const { data: deleteData } = await deleteOrder({
        variables: {
          orderId: itemId,
        },
      });

      if (deleteData?.deleteOrderByPro) {
        await handleRefresh(false);
      }
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));

      captureAndReportErrors(err);

      Alert.alert("Erreur", "Une erreur est survenue " + err.message || "");

      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: err?.code || "error",
      //       message: err?.message || "Une erreur est survenue",
      //       path: err?.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );
    }
  };

  const handleItemUpdateComplete = async () => {
    try {
      await handleRefresh(false);
      // if (
      //   appContext.isSplittable &&
      //   rightComponentViewType === RIGHT_COMPONENT.BOOKING_DETAILS
      // ) {
      // }
      setRefreshDetails(v => !v);
      // if (
      //   appContext.isSplittable &&
      //   rightComponentViewType === RIGHT_COMPONENT.BOOKING_FLOORS
      // ) {
      // }
    } catch (err) {}
  };

  const handleOnDateChange = async (date: Date) => {
    setSelectedDate(date);
    setSelectedService("ALL");

    if (!isSameDay(date, selectedDate)) {
      setOrdersByService([]);
      handleCloseSplitView();
      try {
        await handleGetOrders(date, "ALL");
      } catch (err) {
        captureAndReportErrors(err);
        console.log("AN_ERROR_GETTING_ORDERS", err);
      }
    }
  };

  const handleSelectOrder = (itemId: string) => {
    setSelectedId(prev => {
      if (prev === itemId) return "";
      return itemId;
    });
  };

  const handleSelectFilter = async (statuses: Click_Status[]) => {
    try {
      setIsRefreshing(true);
      await handleGetOrders(selectedDate, selectedService, true, statuses);
    } catch (err) {
      console.log("AN_ERROR_OCCURED_WHILE_SELECTING_FILTER", err);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <SplitView
      leftComponent={
        <ModuleList
          title="A Emporter"
          selectedDate={selectedDate}
          onDateChange={handleOnDateChange}
          type={Modules.OnlineSales}
          selectedServiceId={selectedService}
          onServiceSelected={setSelectedService}
          services={orderAvailability}
          onSearchAction={handleSearch}
          onFilterAction={() => {}}
          listData={ordersByService}
          onListItemExtraPress={() => {}}
          onListItemPress={handleOrderDetailsPress}
          isRefreshing={isRefreshing}
          onRefresh={() => handleRefresh(true)}
          dataByMonth={ordersByMonth}
          listSettings={orderSettings}
          onCloseServiceSlot={handleCloseServiceSlot}
          onMonthChange={handleGetOrdersForMonth}
          selectedId={selectedId}
          setSelectedId={handleSelectOrder}
          onDelete={handleDeleteOrder}
          onEndReached={handleFetchMore}
          onListItemUpdateCompleteHandler={handleItemUpdateComplete}
          isServiceSlotCloseInProgress={isServiceSlotCloseInProgress}
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          shouldShowStatusFilterBadge={shouldShowStatusFilterBadge}
          setShouldShowStatusFilterBadge={setShouldShowStatusFilterBadge}
          handleSelectFilter={handleSelectFilter}
        />
      }
      rightComponent={displayRightComponent()}
    />
  );
};

export default OnlineOrdersList;
