import { format } from "date-fns";

import CALENDAR from "../../../assets/icons/BASE/CALENDAR.svg";
import CASH from "../../../assets/icons/BASE/CASH.svg";
import GROUP from "../../../assets/icons/BASE/GROUP.svg";
import INFO from "../../../assets/icons/BASE/INFO.svg";
import type { SubscriptionTransactionFragment } from "../../graphql/generated/schema";
import {
  Merchant_Subscription_Status,
  Subscription_Interval_Type,
} from "../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { displayModuleId, formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SubscriptionDetailsCardProps {
  subscriptionTransaction: SubscriptionTransactionFragment;
}

const ICON_CONTAINER_SIZE = 45;

const SubscriptionDetailsCard = ({
  subscriptionTransaction,
}: SubscriptionDetailsCardProps) => {
  const { subscription, interval, commitment, total } = subscriptionTransaction;

  const options = subscriptionTransaction.options.filter(option => {
    return option.status !== Merchant_Subscription_Status.Expired;
  });

  const totalAmount = total.subscriptionAmount + total.subscriptionOptionsAmount;

  const paymentInterval =
    interval === Subscription_Interval_Type.Monthly ? "par mois" : "par an";

  const commitmentText = commitment > 1 ? "Avec engagement" : "Sans engagement";

  const IS_SUBSCRIPTION_CANCELLED =
    subscriptionTransaction.status === Merchant_Subscription_Status.Cancelled;

  return (
    <Box borderRadius="button" backgroundColor="disabled" padding="s">
      <Box
        flexDirection="row"
        alignItems="center"
        borderBottomColor="lightGrey"
        borderBottomWidth={LINE_THICKNESS}
        paddingVertical="m"
      >
        {/* <Box
          width={ICON_CONTAINER_SIZE}
          height={ICON_CONTAINER_SIZE}
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          borderRadius="button"
          p="s"
        >
          <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
        </Box> */}

        <CustomText ml="m" variant="label" color="primaryTextColor">
          {subscription.name}
        </CustomText>
      </Box>
      <Box pt="s">
        <Box flexDirection="row" alignItems="center">
          <Box
            width={ICON_CONTAINER_SIZE}
            height={ICON_CONTAINER_SIZE}
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            p="s"
          >
            <CASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <CustomText ml="m" variant="content" color="primaryTextColor">
            {formatCurrencyPrice(totalAmount || 0, "fr", "eur")} {paymentInterval} -{" "}
            {commitmentText}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box
            width={ICON_CONTAINER_SIZE}
            height={ICON_CONTAINER_SIZE}
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            p="s"
          >
            <GROUP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <CustomText ml="m" variant="content" color="primaryTextColor">
            {options.length} options
          </CustomText>
        </Box>
        {!IS_SUBSCRIPTION_CANCELLED && (
          <Box flexDirection="row" alignItems="center">
            <Box
              width={ICON_CONTAINER_SIZE}
              height={ICON_CONTAINER_SIZE}
              alignItems="center"
              justifyContent="center"
              borderRadius="button"
              p="s"
            >
              <CALENDAR height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            </Box>

            <CustomText ml="m" variant="content" color="primaryTextColor">
              Renouvellement le{" "}
              {format(new Date(subscriptionTransaction.endDate), "dd MMMM yyyy")}
            </CustomText>
          </Box>
        )}

        <Box flexDirection="row" alignItems="center">
          <Box
            width={ICON_CONTAINER_SIZE}
            height={ICON_CONTAINER_SIZE}
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            p="s"
          >
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </Box>

          <CustomText ml="m" variant="content" color="primaryTextColor">
            Abonnement numéro {displayModuleId(subscriptionTransaction._id)}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionDetailsCard;
