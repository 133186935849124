import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";

import GroupOptionsListDetailsEdit from "./Edit";
import GroupOptionsListDetailsView from "./View";

interface GroupOptionsListDetailsProps {
  newValue?: boolean;
  selectedId?: string;
  goBack?: () => void;
}

const GroupOptionsListDetails = ({
  newValue,
  selectedId,
  goBack,
}: GroupOptionsListDetailsProps) => {
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = selectedId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <GroupOptionsListDetailsEdit id={id} goBack={goBack} />
      ) : (
        <GroupOptionsListDetailsView id={id} goBack={goBack} />
      )}
      {!inEditMode && (
        <BottomButton title="Modifier" onPress={() => setInEditMode(true)} />
      )}
    </Box>
  );
};

export default GroupOptionsListDetails;
