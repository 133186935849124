import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { FlatList } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import Loader from "../../../../../components/Loader";
import ProductGroupOptionCard from "../../../../../components/ProductGroupOptionCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import type { ProductGroupOptionFragment } from "../../../../../graphql/generated/schema";
import { useGetProductGroupOptionsLazyQuery } from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import GroupOptionsListDetails from "../GroupOptionListDetails";

interface GroupOptionsListProps {
  goBack?: () => void;
}

const GroupOptionsList = ({ goBack }: GroupOptionsListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [groupOptions, setGroupOptions] = useState<ProductGroupOptionFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getGroupOptions, { fetchMore }] = useGetProductGroupOptionsLazyQuery();

  const filteredGroupOptions = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    if (formattedSearch.length === 0) {
      return groupOptions;
    }

    return groupOptions.filter(groupOption => {
      const formattedName = groupOption.name.toLowerCase().trim();

      return formattedName.includes(formattedSearch);
    });
  }, [groupOptions, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: groupOptions.length,
          },
        },
      });

      if (data?.getProductGroupOptions) {
        setGroupOptions([...groupOptions, ...data.getProductGroupOptions]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetGroupOptions = async () => {
    try {
      const { data } = await getGroupOptions({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getProductGroupOptions) {
        setGroupOptions(data.getProductGroupOptions);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetGroupOptions();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setShowInline(true);
      setSelectedId("");
    } else {
      navigation.navigate("GROUP_OPTIONS_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setShowInline(true);
      setSelectedId(id);
    } else {
      navigation.navigate("GROUP_OPTIONS_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetGroupOptions();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <GroupOptionsListDetails
        newValue={!selectedId}
        selectedId={selectedId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Groupe d'options"
            rightButtons={
              <Touchable onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Touchable>
            }
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        </Box>

        <FlatList
          showsVerticalScrollIndicator={false}
          data={filteredGroupOptions}
          keyExtractor={(_, index) => index.toString()}
          onEndReachedThreshold={0.5}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <Touchable onPress={() => handleEdit(item._id)}>
                <ProductGroupOptionCard
                  isSelected={false}
                  name={item.name}
                  nbOptions={item.options.length}
                  nbProducts={item.nbProducts}
                  price={item.basePrice.amount}
                />
              </Touchable>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default GroupOptionsList;
