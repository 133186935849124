import { format } from "date-fns";
import * as WebBrowser from "expo-web-browser";
import { useContext } from "react";

import PDF from "../../../assets/icons/BASE/PDF.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { SubscriptionInvoiceFragment } from "../../graphql/generated/schema";
import { Merchant_Subscription_Payment_Status } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import Touchable from "../Touchable";

interface InvoiceListCardProps {
  invoice: SubscriptionInvoiceFragment;
}

const InvoiceListCard = ({ invoice }: InvoiceListCardProps) => {
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const {
    merchantSubscriptionTransaction,
    paidDate,
    paymentAttemptDate,
    periodStartDate,
    periodEndDate,
    totalAmount,
    status,
    stripeRetryUrl,
    invoiceUrl,
  } = invoice;

  const HAS_FAILED = status === Merchant_Subscription_Payment_Status.Failed;

  const handleViewInvoice = async () => {
    try {
      if (HAS_FAILED) return handleRetryPayment();

      if (!HAS_FAILED && invoiceUrl) {
        const result = await WebBrowser.openBrowserAsync(invoiceUrl, {
          windowFeatures: {
            target: "_blank",
          },
        });
      } else {
        throw new Error("NO_VALID_URL");
      }
    } catch (err) {
      console.log("err open browser: ", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_OPEN_BROWSER",
            message: "Une erreur est survenue.",
          },
        ],
        "error",
      );
    }
  };

  const handleRetryPayment = async () => {
    try {
      if (HAS_FAILED && stripeRetryUrl) {
        const result = await WebBrowser.openBrowserAsync(stripeRetryUrl, {
          windowFeatures: {
            target: "_blank",
          },
        });
      } else {
        throw new Error("NO_VALID_URL");
      }
    } catch (err) {
      console.log("err open browser: ", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_OPEN_BROWSER",
            message: "Une erreur est survenue.",
          },
        ],
        "error",
      );
    }
  };

  const getTitle = () => {
    if (HAS_FAILED) {
      return format(new Date(paymentAttemptDate), "dd MMMM yyyy");
    }

    return format(new Date(paidDate), "dd MMMM yyyy");
  };

  const getDescription = () => {
    const subscription = merchantSubscriptionTransaction?.subscription;
    const options = merchantSubscriptionTransaction?.options || [];

    const optionNames = options?.map(option => option.subscription.name);

    const subscriptionName = subscription?.name || "Abonnement";
    const allNames = [subscriptionName, ...optionNames].join(" + ");

    const formattedPeriodStart = format(new Date(periodStartDate), "dd/MM/yyyy");
    const formattedPeriodEnd = format(new Date(periodEndDate), "dd/MM/yyyy");

    return `${allNames} Période du ${formattedPeriodStart} au ${formattedPeriodEnd}`;
  };

  return (
    <Touchable onPress={handleViewInvoice}>
      <Box pb="m">
        <Box
          paddingVertical="m"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <CustomText variant="label" color="primaryTextColor">
              {getTitle()}
            </CustomText>

            {HAS_FAILED && (
              <CustomText mt="s" variant="content" color="primaryTextColor">
                Echec du paiement.{"\n"}Veuillez régulariser la facture
              </CustomText>
            )}
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Box mr="m" flexDirection="row" alignItems="center">
              <CustomText mr="m" variant="label" color="primaryTextColor">
                {formatCurrencyPrice(totalAmount, "fr", "eur")}
              </CustomText>
              {HAS_FAILED ? (
                <Box
                  backgroundColor="danger"
                  width={30}
                  height={30}
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    borderRadius: 25,
                  }}
                >
                  <CustomText variant="title" color="white">
                    !
                  </CustomText>
                </Box>
              ) : (
                <PDF height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              )}
            </Box>
          </Box>
        </Box>
        <Box p="s">
          <CustomText variant="content" color="primaryTextColor">
            {getDescription()}
          </CustomText>
        </Box>
        {HAS_FAILED && (
          <Touchable onPress={handleRetryPayment}>
            <CustomButton
              buttonVariant="primaryButton"
              buttonColor="danger"
              onPress={handleRetryPayment}
            >
              <CustomText variant="primaryButtonText" color="white">
                Régulariser
              </CustomText>
            </CustomButton>
          </Touchable>
        )}
      </Box>
    </Touchable>
  );
};

export default InvoiceListCard;
