import { format, getDay, isSameDay, startOfHour } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";

import type { AvailabilityServiceWithModuleInfo } from "../../../graphql/generated/schema";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import { createDateWithTime, isBetween } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BottomButton from "../../BottomButton";
import { CustomButton } from "../../Button";
import SelectableButton from "../../SelectableButton";
import TimeInput from "../../TimeInput/index";

import ServiceSelection from "./ServiceSelection";
import TimeSlotSelection from "./TimeSlotSelection";

const IS_WEB = Platform.OS === "web";

interface NewBookingTimeSelectionProps {
  services: AvailabilityServiceWithModuleInfo[];
  selectedServiceId: string;
  selectedSlotId: string;
  setServiceId: (id: string) => void;
  onSlotSelect: (serviceId: string, slotId: string, time: string) => void;
  selectedDate: Date | null;
  nbPersons: number;
  isLoading: boolean;
  isNewBooking?: boolean;
}

const NewBookingTimeSelection = ({
  services,
  selectedServiceId,
  selectedSlotId,
  onSlotSelect,
  selectedDate,
  nbPersons,
  isLoading,
  isNewBooking = false,
}: NewBookingTimeSelectionProps) => {
  const [isOverBooking, setIsOverBooking] = useState(false);
  const [slotId, setSlotId] = useState(selectedSlotId || "");
  const [selectedtime, setSelectedtime] = useState("");
  const [hsDate, setHsDate] = useState(startOfHour(new Date()));

  const [serviceId, setServiceId] = useState(selectedServiceId);
  const [selectedService, setSelectedService] =
    useState<AvailabilityServiceWithModuleInfo | null>(null);

  useEffect(() => {
    handleServiceSelect(selectedServiceId);
  }, [selectedServiceId]);

  const slots = useMemo(() => {
    if (!selectedDate) return [];

    const selectedDateDay = getDay(selectedDate);

    return (
      selectedService?.slots?.filter(slot => {
        if (slot.isRecurring) {
          return slot.day === selectedDateDay;
        }
        return isSameDay(new Date(slot?.date), selectedDate);
      }) || []
    );
  }, [selectedService, selectedDate]);

  const handleServiceSelect = (id: string | number) => {
    const foundService = services.find(service => service.serviceId === id);
    setSelectedService(foundService ?? null);
    setServiceId(foundService ? (id as string) : "");
  };

  const handlSlotSelect = (id: string, time: string, overBooking: boolean) => {
    setSlotId(id);
    setSelectedtime(time);

    if (!overBooking) {
      onSlotSelect(selectedService?.serviceId || "", id, time);
    } else setIsOverBooking(true);
  };

  const handleOnConfirm = () => {
    setIsOverBooking(false);
    onSlotSelect(selectedService?.serviceId || "", slotId, selectedtime);
  };

  const handleOpenHsDatePicker = () => {
    setServiceId("");
    setSlotId("");
  };

  const handleConfirmDateTimeSelection = () => {
    const time = serviceId && slotId ? selectedtime : format(hsDate, "HH:mm");
    onSlotSelect(serviceId, slotId, time);
  };

  const handleSetHsDate = (date: Date) => {
    setHsDate(date);

    if (!selectedDate) return;

    const serviceWithDateRange = services.find(service =>
      isBetween(
        new Date(date),
        createDateWithTime(selectedDate, service.startTime),
        createDateWithTime(selectedDate, service.endTime),
        "[]",
      ),
    );

    if (serviceWithDateRange) {
      const selectedDateDay = getDay(selectedDate);

      const slotWithHsDate = serviceWithDateRange.slots
        .filter(slot => {
          if (slot.isRecurring) {
            return slot.day === selectedDateDay;
          }
          return isSameDay(new Date(slot?.date), selectedDate);
        })
        .find(slot => {
          const dateTime = format(new Date(date), "HH:mm");

          return dateTime === slot.startTime;
        });

      if (slotWithHsDate) {
        setServiceId(serviceWithDateRange.serviceId);
        handlSlotSelect(slotWithHsDate.slotId, slotWithHsDate.startTime, false);
      }
    }
  };

  const getServiceSlotAvailabilityColor = (
    isSelected: boolean,
    maxCapacity: number,
    capacity: number,
    areLimitsEnabled: boolean,
  ): PALETTE_COLORS => {
    if (isSelected) {
      return "green";
    } else if (capacity >= maxCapacity && areLimitsEnabled) {
      return "red";
    }
    return "gray";
  };

  const displayHSButton = () => {
    return (
      <Box mr="s">
        <SelectableButton
          isSelected={!serviceId}
          onChange={handleOpenHsDatePicker}
          value=""
          label="Hors service"
          styles={{
            height: 45,
            width: 165,
          }}
        />
      </Box>
    );
  };

  if (isLoading)
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <ActivityIndicator color={PALETTE.green} />
      </Box>
    );

  return (
    <>
      <Box flex={1} paddingHorizontal="s">
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: serviceId ? 150 : 0 }}
        >
          <CustomText
            textTransform="uppercase"
            variant="content"
            color="success"
            marginVertical="m"
          >
            Sélectionnez un service
          </CustomText>

          <Box marginVertical="s">
            <FlatList
              data={services}
              keyExtractor={item => item.serviceId}
              horizontal
              showsHorizontalScrollIndicator={false}
              ListFooterComponent={displayHSButton}
              renderItem={({ item }) => {
                const {
                  serviceId: _id,
                  name,
                  areLimitsEnabled,
                  totalCapacityForService,
                  totalForService,
                } = item;
                const IS_SELECTED = _id === serviceId;
                return (
                  <TouchableOpacity onPress={() => handleServiceSelect(_id)}>
                    <Box mr="s">
                      <ServiceSelection
                        label={name}
                        areLimitsEnabled={areLimitsEnabled}
                        capacity={totalForService}
                        maxCapacity={totalCapacityForService}
                        isSelected={IS_SELECTED}
                        iconColor={getServiceSlotAvailabilityColor(
                          IS_SELECTED,
                          item.totalCapacityForService,
                          item.totalForService + nbPersons,
                          areLimitsEnabled,
                        )}
                      />
                    </Box>
                  </TouchableOpacity>
                );
              }}
            />
          </Box>

          <CustomText
            textTransform="uppercase"
            variant="content"
            color="success"
            paddingVertical="s"
          >
            Sélectionnez un créneau
          </CustomText>

          <Box
          // maxHeight={IS_WEB && !isNewBooking ? 500 : undefined}
          >
            {serviceId ? (
              <FlatList
                data={slots}
                scrollEnabled={false}
                keyExtractor={item => item.slotId}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ paddingBottom: 100 }}
                renderItem={({ item }) => {
                  const {
                    slotId: currentSlotId,
                    startTime,
                    totalForSlot,
                    totalForService,
                    totalCapacityForService,
                    totalCapacityForSlot,
                    areLimitsEnabled,
                  } = item;

                  const IS_SELECTED = currentSlotId === slotId;
                  const SLOT_INFO = areLimitsEnabled
                    ? `${totalForSlot}/${totalCapacityForSlot}`
                    : "";
                  const SERVICE_INFO = selectedService?.areLimitsEnabled
                    ? // areLimitsEnabled
                      `${totalForService}/${totalCapacityForService}`
                    : "";

                  const IS_OVER_BOOKING = areLimitsEnabled
                    ? totalForSlot + nbPersons > totalCapacityForSlot
                    : false;

                  return (
                    <Box mt="m">
                      <TimeSlotSelection
                        {...{
                          time: startTime,
                          areLimitsEnabled,
                          isSelected: IS_SELECTED,
                          onPress: time =>
                            handlSlotSelect(currentSlotId, time, IS_OVER_BOOKING),
                          slotText: SLOT_INFO,
                          serviceText: SERVICE_INFO,
                          slotIconColor: getServiceSlotAvailabilityColor(
                            IS_SELECTED,
                            item.totalCapacityForSlot,
                            item.totalForSlot + nbPersons,
                            areLimitsEnabled,
                          ),
                          serviceIconColor: getServiceSlotAvailabilityColor(
                            IS_SELECTED,
                            item.totalCapacityForService,
                            item.totalForService + nbPersons,
                            areLimitsEnabled,
                          ),
                          showTimeSlotDisallowIcon: false,
                        }}
                      />
                    </Box>
                  );
                }}
              />
            ) : (
              <Box alignItems="center" minHeight={120}>
                <TimeInput
                  date={new Date(hsDate)}
                  placeHolder="Heure"
                  onChange={handleSetHsDate}
                  interval={15}
                />
              </Box>
            )}
          </Box>
        </ScrollView>
      </Box>

      {isOverBooking ? (
        <Box
          position="absolute"
          pb="l"
          bottom={0}
          left={0}
          right={0}
          backgroundColor="white"
        >
          <Box marginVertical="s" p="s" borderRadius="button" backgroundColor="disabled">
            <CustomText variant="text" color="danger">
              D’après les informations de ce service, vous n’êtes pas en mesure d’accepter
              cette réservation.
            </CustomText>
          </Box>

          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="danger"
            onPress={handleOnConfirm}
          >
            <CustomText variant="primaryButtonText" color="white">
              Confirmer
            </CustomText>
          </CustomButton>
        </Box>
      ) : (
        <BottomButton title="Valider" onPress={handleConfirmDateTimeSelection} />
      )}
    </>
  );
};

export default NewBookingTimeSelection;
