import { useContext, useEffect, useMemo, useState } from "react";
import { Dimensions, FlatList } from "react-native";
import RNModal from "react-native-modal";

import CANCEL from "../../../assets/icons/BASE/CANCEL.svg";
import { AppContext } from "../../contexts/AppContext";
import { SubscriptionsContext } from "../../contexts/SubscriptionsContext";
import type {
  InternalNotification,
  PopCallFragment,
} from "../../graphql/generated/schema";
import { Internal_Notification_Types } from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import IncomingCallLocalNotification from "../LocalNotifications/IncomingCall";
import NewBookingLocalNotification from "../LocalNotifications/NewBooking";
import NewLocalOrderNotification from "../LocalNotifications/NewOrder";
import NewReview from "../LocalNotifications/NewReview/index";
import Touchable from "../Touchable";

const { height, width } = Dimensions.get("window");

// interface RENDER_ITEM {
//   type: "BOOKING" | "ORDER" | "CALL",
//   data:BOOKGIN
// }

const SubscriptionsHandler = () => {
  const {
    reviews,
    calls,
    bookings,
    orders,
    setReviews,
    setBookings,
    setCalls,
    setOrders,
  } = useContext(SubscriptionsContext);
  const appContext = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  const handlOnClose = () => {
    setIsOpen(false);

    setBookings([]);
    setCalls([]);
    setOrders([]);
    setReviews([]);
  };

  const items = useMemo(() => {
    const filteredBookings = bookings.filter(b => b.shouldShow);
    const filteredOrders = orders.filter(b => b.shouldShow);
    const filteredReviews = reviews.filter(b => b.shouldShow);
    return [...filteredReviews, ...filteredBookings, ...calls, ...filteredOrders];
  }, [bookings, calls, orders, reviews]);

  useEffect(() => {
    appContext.refreshAlerts();
    if (items.length > 0) {
      setIsOpen(true);
    }
  }, [bookings, orders]);

  const handleAckItem = (id: string, type: "BOOKING" | "ORDER" | "CALL" | "REVIEWS") => {
    const newBookings =
      type === "BOOKING" ? bookings.filter(b => b.resourceId !== id) : bookings;

    setBookings(newBookings);

    const newCalls = type === "CALL" ? calls.filter(b => b._id !== id) : calls;

    setCalls(newCalls);

    const newOrders = type === "ORDER" ? orders.filter(b => b.resourceId !== id) : orders;

    setOrders(newOrders);

    if (newBookings.length === 0 && newCalls.length === 0 && newOrders.length === 0) {
      setIsOpen(false);
    }
  };

  const handleClearAll = () => {
    setBookings([]);
    setCalls([]);
    setOrders([]);
    setReviews([]);
    setIsOpen(false);
  };

  const handleRenderItem = (item: InternalNotification | PopCallFragment) => {
    if ("isNewCustomer" in item) {
      return (
        <IncomingCallLocalNotification
          popCall={item}
          handleAckItem={(id: string) => handleAckItem(id, "CALL")}
          onClose={handlOnClose}
        />
      );
    } else if (
      "resourceType" in item &&
      item.resourceType === Internal_Notification_Types.Booking
    ) {
      return (
        <NewBookingLocalNotification
          handleAckItem={(id: string) => handleAckItem(id, "BOOKING")}
          notification={item}
        />
      );
    } else if (
      "resourceType" in item &&
      item.resourceType === Internal_Notification_Types.TakeAwayOrder
    ) {
      return (
        <NewLocalOrderNotification
          handleAckItem={(id: string) => handleAckItem(id, "ORDER")}
          notification={item}
        />
      );
    } else if (
      "resourceType" in item &&
      item.resourceType === Internal_Notification_Types.Reviews
    ) {
      return (
        <NewReview
          handleAckItem={(id: string) => handleAckItem(id, "REVIEWS")}
          notification={item}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box>
      <RNModal
        deviceHeight={height}
        hasBackdrop
        isVisible={isOpen}
        animationIn="slideInDown"
        onBackdropPress={handlOnClose}
        deviceWidth={width}
        style={{
          padding: 0,
          margin: 0,
          marginTop: 50,
          marginHorizontal: 4,
        }}
      >
        <Box position="absolute" top={0} left={0} right={0}>
          <FlatList
            data={items}
            scrollEnabled={false}
            ListHeaderComponent={
              <Box alignItems="flex-end" mb="s">
                <Touchable onPress={handleClearAll}>
                  <CANCEL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
                </Touchable>
              </Box>
            }
            renderItem={({ item }) => <Box mb="s">{handleRenderItem(item)}</Box>}
          />
        </Box>
      </RNModal>
    </Box>
  );
};

export default SubscriptionsHandler;
