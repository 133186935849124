import { TouchableOpacity } from "react-native-gesture-handler";

import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import SHOPPING_BAG from "../../../assets/icons/BASE/SHOPPING_BAG.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface ProductCategoryCardProps {
  name: string;
  isEnabled: boolean;
  updateEnabled: (isEnabled: boolean) => void;
  color: string;
  nbProducts: number;
  isSelected: boolean;
  isDraggable?: boolean;
  showEnabledToggle?: boolean;
  onPressIn?: () => void;
}

const HEIGHT = 85;

const ProductCategoryCard = ({
  name,
  isEnabled,
  updateEnabled,
  color,
  nbProducts,
  isSelected,
  isDraggable = true,
  showEnabledToggle = true,
  onPressIn,
}: ProductCategoryCardProps) => {
  return (
    <Box
      p="s"
      minHeight={HEIGHT}
      borderWidth={LINE_THICKNESS}
      borderColor={isSelected ? "success" : "disabled"}
      borderRadius="button"
      backgroundColor={isSelected ? "white" : "disabled"}
      flexDirection="row"
      alignItems="flex-start"
    >
      {isDraggable && (
        <TouchableOpacity onPressIn={onPressIn}>
          <Box height={HEIGHT} paddingHorizontal="xs">
            <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
          </Box>
        </TouchableOpacity>
      )}
      <Box ml="s" flex={1}>
        <Box flexDirection="row" alignItems="center" mb="s">
          <CustomText ml="s" variant="label" color="primaryTextColor">
            {name}
          </CustomText>
        </Box>

        <Box flex={1} flexDirection="row" alignItems="center">
          {showEnabledToggle && (
            <Box flexDirection="row" alignItems="center" pr="s">
              <Toggle value={isEnabled} onChange={updateEnabled} />

              <CustomText variant="content" pl="s" color="primaryTextColor">
                Activé
              </CustomText>
            </Box>
          )}

          <Box
            marginHorizontal="s"
            borderRadius="button"
            style={{
              height: 20,
              width: 20,
              backgroundColor: color,
            }}
          />

          <Box
            ml="m"
            flexDirection="row"
            alignItems="center"
            backgroundColor={isSelected ? "disabled" : "white"}
            borderRadius="button"
            p="s"
          >
            <SHOPPING_BAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="label" color="primaryTextColor">
              {nbProducts}
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCategoryCard;
