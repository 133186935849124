import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { FlatList, RefreshControl, TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import PermissionGroupCard from "../../../../components/Settings/PermissionGroupCard";
import { CustomTextInput } from "../../../../components/TextInput";
import type {
  MerchantPersonnelPermissionGroupFragment,
  Pagination,
} from "../../../../graphql/generated/schema";
import { useGetMerchantPersonnelPermissionGroupsLazyQuery } from "../../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import PermissionsGroupListDetails from "../PermissionsGroupListDetails";

interface PermissionsGroupListProps {
  goBack?: () => void;
}

const PermissionsGroupList = ({ goBack }: PermissionsGroupListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [permissionGroups, setPermissionGroups] = useState<
    MerchantPersonnelPermissionGroupFragment[]
  >([]);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);
  const [isNew, setIsNew] = useState(false);
  const [id, setId] = useState("");

  const [getPermissionGroups] = useGetMerchantPersonnelPermissionGroupsLazyQuery({
    variables: { pagination },
  });

  const handleGetPermissionGroup = async () => {
    setLoading(true);
    try {
      const groups = await getPermissionGroups({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (groups.data) {
        setPermissionGroups(groups.data.getMerchantPersonnelPermissionGroups);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPermissionGroup();
  }, []);

  const filteredGroups = useMemo(() => {
    const formattedSearch = search.toLowerCase()?.trim();

    if (!formattedSearch) {
      return permissionGroups;
    }

    return permissionGroups.filter(group => {
      const { name } = group;

      return name.toLowerCase().includes(formattedSearch);
    });
  }, [search, permissionGroups]);

  const handleNewPermissionGroup = () => {
    if (goBack) {
      setIsNew(true);
      setId("");
      setSelectedTab("PERMISSIONS_GROUPS_LIST_DETAILS");
    } else {
      navigation.navigate("PERMISSIONS_GROUPS_LIST_DETAILS", {
        isNew: true,
      });
    }
  };

  const handleEditPermissionGroup = (permissionGroupId: string) => {
    if (goBack) {
      setIsNew(false);
      setId(permissionGroupId);
      setSelectedTab("PERMISSIONS_GROUPS_LIST_DETAILS");
    } else {
      navigation.navigate("PERMISSIONS_GROUPS_LIST_DETAILS", {
        isNew: false,
        id: permissionGroupId,
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <FlatList
          ListHeaderComponent={
            <>
              <ScreenHeader
                title="Groupes"
                rightButtons={
                  <TouchableOpacity onPress={handleNewPermissionGroup}>
                    <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                  </TouchableOpacity>
                }
                hasBackButton
                onBackPress={goBack || navigation.goBack}
              />

              <Box marginVertical="s">
                <CustomTextInput
                  {...{
                    placeHolder: "Rechercher",
                    leftIcon: (
                      <SEARCH
                        height={ICON_SIZE}
                        width={ICON_SIZE}
                        fill={PALETTE.darkBlue}
                      />
                    ),
                    onChangeText: setSearch,
                  }}
                />
              </Box>
            </>
          }
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleGetPermissionGroup} />
          }
          showsVerticalScrollIndicator={false}
          data={filteredGroups}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <PermissionGroupCard
                permissionGroup={item}
                isSelected={false}
                onPress={() => handleEditPermissionGroup(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const handleGoBack = async () => {
    await handleGetPermissionGroup();
    setSelectedTab(undefined);
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "PERMISSIONS_GROUPS_LIST_DETAILS":
        return (
          <PermissionsGroupListDetails isNew={isNew} id={id} onGoBack={handleGoBack} />
        );
      default:
        break;
    }
    return <></>;
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return displayContent();
};

export default PermissionsGroupList;
