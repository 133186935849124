import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import BURGER from "../../../../assets/icons/BASE/BURGER.svg";
import COMBINED_CATEGORY from "../../../../assets/icons/BASE/COMBINED_CATEGORY.svg";
import GROUP from "../../../../assets/icons/BASE/GROUP.svg";
import LINKS from "../../../../assets/icons/BASE/LINKS.svg";
import OPTION from "../../../../assets/icons/BASE/OPTION.svg";
import SHOPPING_BAG from "../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import STOCK_MOVEMENT from "../../../../assets/icons/BASE/STOCK_MOVEMENT.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

import CategoryList from "./Categories/CategoryList";
import CombinedCategoryList from "./CombinedCategories/CombinedCategoryList";
import GroupOptionsList from "./GroupOptions/GroupOptionsList";
import MenuList from "./Menus/MenuList";
import OptionsList from "./Options/OptionsList";
import ProductList from "./Products/ProductList";
import ProductsWithStockEnabledList from "./ProductsWithStockEnabledList";

interface DataSettingsListProps {
  goBack?: () => void;
}

const DataSettingsList = ({ goBack }: DataSettingsListProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: string) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseSelectedTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "MENUS_LIST":
        return <MenuList goBack={handleCloseSelectedTab} />;

      case "COMBINED_CATEGORIES_LIST":
        return <CombinedCategoryList goBack={handleCloseSelectedTab} />;

      case "CATEGORIES_LIST":
        return <CategoryList goBack={handleCloseSelectedTab} />;

      case "PRODUCTS_LIST":
        return <ProductList goBack={handleCloseSelectedTab} />;

      case "GROUP_OPTIONS_LIST":
        return <GroupOptionsList goBack={handleCloseSelectedTab} />;

      case "OPTIONS_LIST":
        return <OptionsList goBack={handleCloseSelectedTab} />;

      case "PRODUCTS_WITH_STOCK_ENABLED_LIST":
        return <ProductsWithStockEnabledList goBack={handleCloseSelectedTab} />;

      default:
        break;
    }
  };

  const displayDataSettings = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Données"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Box
            mt="m"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Formules"
              subText="Choix multiples avec notion de steps"
              onPress={() => handleNavigation("MENUS_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<GROUP fill={PALETTE.green} height={19} width={19} />}
            />
            <SettingsButtonItem
              title="Catégories combinées"
              subText="Regroupe plusieurs catégories"
              onPress={() => handleNavigation("COMBINED_CATEGORIES_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<COMBINED_CATEGORY fill={PALETTE.green} height={19} width={19} />}
            />
            <SettingsButtonItem
              title="Catégories"
              subText="Peut contenir un ou plusieurs produits"
              onPress={() => handleNavigation("CATEGORIES_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<BURGER fill={PALETTE.green} height={19} width={19} />}
            />
            <SettingsButtonItem
              title="Produits"
              subText="Produit, prestation, catalogue"
              onPress={() => handleNavigation("PRODUCTS_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<SHOPPING_BAG fill={PALETTE.green} height={19} width={19} />}
            />
            <SettingsButtonItem
              title="Groupes d'options"
              subText="Peut contenir une ou plusieurs options"
              onPress={() => handleNavigation("GROUP_OPTIONS_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={<LINKS fill={PALETTE.green} height={19} width={19} />}
            />
            <SettingsButtonItem
              title="Options"
              subText="Se rattache à un groupe d'options"
              onPress={() => handleNavigation("OPTIONS_LIST")}
              bottomLineWidth={0}
              icon={<OPTION fill={PALETTE.green} height={19} width={19} />}
            />
          </Box>

          <Box mt="m">
            <CustomText
              paddingHorizontal="s"
              mb="s"
              variant="label"
              color="primaryTextColor"
            >
              Stocks
            </CustomText>

            <Box paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
              <SettingsButtonItem
                title="Mouvements de stock"
                subText="Gérer directement le stock de vos produits"
                onPress={() => handleNavigation("PRODUCTS_WITH_STOCK_ENABLED_LIST")}
                bottomLineWidth={0}
                icon={<STOCK_MOVEMENT fill={PALETTE.green} height={19} width={19} />}
              />
            </Box>
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) return displaySelectedTab();

    return displayDataSettings();
  };

  return <>{displayContent()}</>;
};

export default DataSettingsList;
