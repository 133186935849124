import type { PrinterFragment } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

interface MerchantDeviceCardProps {
  printer: PrinterFragment;
  onDelete: () => void;
}

const MerchantDeviceCard = ({ printer, onDelete }: MerchantDeviceCardProps) => {
  return (
    <Box>
      <Box backgroundColor="disabled" borderRadius="button" p="s">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          borderBottomColor="lightGrey"
          borderBottomWidth={LINE_THICKNESS}
          mb="s"
        >
          <CustomText variant="content" color="primaryTextColor">
            Nom
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {printer.name}
          </CustomText>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          borderBottomColor="lightGrey"
          borderBottomWidth={LINE_THICKNESS}
          mb="s"
        >
          <CustomText variant="content" color="primaryTextColor">
            Type de connection
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {printer.connectionType}
          </CustomText>
        </Box>
        <Box
          borderBottomColor="lightGrey"
          borderBottomWidth={LINE_THICKNESS}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
        >
          <CustomText variant="content" color="primaryTextColor">
            Ip address
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {printer.ipAddress}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between" p="s">
          <CustomText variant="content" color="primaryTextColor">
            Mac address
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {printer.target}
          </CustomText>
        </Box>
      </Box>

      <Box mt="s">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="disabled"
          onPress={onDelete}
        >
          <CustomText variant="primaryButtonText" color="danger">
            Supprimer l'imprimante
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MerchantDeviceCard;
