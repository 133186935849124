import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import Loader from "../../../../components/Loader";
import NumberInputLine from "../../../../components/NumberInputLine";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import type {
  BookingSettingsFragment,
  VisibilitySettings,
} from "../../../../graphql/generated/schema";
import {
  DisallowBookingType,
  PrepaymentCancelMesaurementType,
  useGetBookingSettingsLazyQuery,
  useUpdateBookingSettingsMutation,
} from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import { BOOKING_STATUS } from "../../../../types";
// import { BOOKING_STATUS } from "../../../../types";
import DefaultBookingPrepayment from "../../../../components/BookingSettings/DefaultBookingPrepayment/index";
import ErrorMessage from "../../../../components/ErrorMessage/index";
import KeyboardAwareScrollView from "../../../../components/KeyboardAwareScrollView";
import { CustomTextInput } from "../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { ERROR } from "../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../utils/common";

interface BookingGeneralSettingsProps {
  goBack?: () => void;
}

const DEFAULT_STATUSES = [
  {
    label: "Validé",
    key: BOOKING_STATUS.VALIDATED.toString(),
  },
  {
    label: "Arrivé",
    key: BOOKING_STATUS.ARRIVED.toString(),
  },
];

const DISALLOW_DELIMETER = [
  {
    label: "À l'approche du service",
    key: DisallowBookingType.Service,
  },
  {
    label: "À l'approche du créneau",
    key: DisallowBookingType.Slot,
  },
];

const DISALLOW_DELIMETER_TYPE = [
  {
    label: "Minute(s)",
    key: PrepaymentCancelMesaurementType.Minutes,
  },
  {
    label: "Heure(s)",
    key: PrepaymentCancelMesaurementType.Hours,
  },
  // {
  //   label: "Jour(s)",
  //   key: PrepaymentCancelMesaurementType.Days,
  // },
];

const BookingGeneralSettings = ({ goBack }: BookingGeneralSettingsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [loading, setLoading] = useState(true);
  const [bookingSettings, setBookingSettings] = useState<BookingSettingsFragment | null>(
    null,
  );

  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings();

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  const validateForm = async (settings: BookingSettingsFragment) => {
    try {
      const shouldCheckPrepaymentSettings = settings.defaultPrepaymentSettings?.isEnabled;

      const schema = yup.object().shape({
        isEnabled: yup.boolean().required(),
        isBookingEnabledForGoogle: yup.boolean().required(),
        deductPendingBookingsFromAvailability: yup.boolean().required(),
        defaultPrepaymentSettings: yup.object().shape({
          isEnabled: yup.boolean().required(),
          automaticPayment: yup.boolean().required(),
          amountForPrepayment: shouldCheckPrepaymentSettings
            ? yup
                .number()
                .min(1, "Le montant pour l'empreinte bancaire doit etre supérieur à 0")
            : yup.number().notRequired(),
          percentBookingCancel: shouldCheckPrepaymentSettings
            ? yup
                .number()
                .min(0, "Le pourcentage d'annulation doit etre supérieur ou égal à 0")
                .max(100, "Le pourcentage d'annulation doit etre inférieur à 100")
            : yup.number().notRequired(),
          percentBookingNoShow: shouldCheckPrepaymentSettings
            ? yup
                .number()
                .min(0, "Le pourcentage de no-show doit etre supérieur ou égal à 0")
                .max(100, "Le pourcentage de no-show doit etre inférieur à 100")
            : yup.number().notRequired(),
          prepaymentCancelSettings: yup.object().shape({
            delimiter: yup.string().required(),
            delimiterMesaurementType: yup.string().required(),
            amount: shouldCheckPrepaymentSettings
              ? yup
                  .number()
                  .min(1, "Le délai pour l'annulation/no-show doit etre supérieur à 0")
              : yup.number().notRequired(),
          }),
          prepaymentType: yup.string().required(),
        }),
        visibilitySettings: yup.object().shape({
          isDelimitationEnabled: yup.boolean().required(),
          isEnabled: yup.boolean().required(),
          advanceDays: yup
            .number()
            .required()
            .when("isEnabled", {
              is: true,
              then: yup
                .number()
                .required()
                .min(1, "La limite des jours doit etre au moins égal à 1"),
            }),
          amount: yup
            .number()
            .required()
            .when("isDelimitationEnabled", {
              is: true,
              then: yup
                .number()
                .required()
                .min(1, "La délimitation doit etre au moins égal à 1"),
            }),
        }),
      });

      await schema.validate(settings, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleUpdateBookingSettings = async () => {
    if (!bookingSettings) return;

    const isFormValid = await validateForm(bookingSettings);

    if (!isFormValid) return;

    try {
      const updates = removeTypeNames(bookingSettings);
      delete updates._id;

      await updateBookingSettings({
        variables: {
          updates,
        },
      });

      handleGoBack();
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_BOOKING_SETTINGS",
            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
    }
  };

  const updateBookingSettingsData = (
    key: keyof BookingSettingsFragment,
    value: BookingSettingsFragment[keyof BookingSettingsFragment],
  ) => {
    setBookingSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  const updateBookingSettingsVisibilityData = (
    key: keyof VisibilitySettings,
    value: VisibilitySettings[keyof VisibilitySettings],
  ) => {
    setBookingSettings(prev => {
      if (prev) {
        return {
          ...prev,
          visibilitySettings: {
            ...prev.visibilitySettings,
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  if (loading) {
    return <Loader />;
  }

  if (!bookingSettings) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres de réservation." />
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 150,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Activer le widget de réservation en ligne"
              value={!!bookingSettings?.isEnabled}
              onValueChange={value => updateBookingSettingsData("isEnabled", value)}
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Activer la réservation Google"
              value={!!bookingSettings?.isBookingEnabledForGoogle}
              onValueChange={value =>
                updateBookingSettingsData("isBookingEnabledForGoogle", value)
              }
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Déduire les résas en attente du stock"
              value={!!bookingSettings?.deductPendingBookingsFromAvailability}
              onValueChange={value =>
                updateBookingSettingsData("deductPendingBookingsFromAvailability", value)
              }
            />
          </Box>
          {/* <Box mt="s">
          <ToggleInputLine
            text="Sélection obligatoire d’une table
au moment de la réservation"
            value={!!bookingSettings?.tableSelectionRequiredForNewBooking}
            onValueChange={value =>
              updateBookingSettingsData("tableSelectionRequiredForNewBooking", value)
            }
          />
        </Box> */}
          {/* <Box mt="s">
          <ToggleInputLine
            text="Les réservations en retard clignotent"
            value={!!bookingSettings?.alertLateBookings}
            onValueChange={v => updateBookingSettingsData("alertLateBookings", v)}
          />
        </Box> */}
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Limiter les réservations à l’avance"
              value={!!bookingSettings?.visibilitySettings.isEnabled}
              onValueChange={v => updateBookingSettingsVisibilityData("isEnabled", v)}
            />

            {bookingSettings?.visibilitySettings.isEnabled && (
              <Box>
                <Box mt="s" pl="m">
                  <NumberInputLine
                    hasErrors={doesInputHaveError("visibilitySettings.advanceDays")}
                    textVariant="content"
                    color="lightGrey"
                    text="Veuillez entrer un nombre de jours"
                    placeHolder="0"
                    value={bookingSettings?.visibilitySettings.advanceDays || 0}
                    onValueChange={days =>
                      updateBookingSettingsVisibilityData(
                        "advanceDays",
                        getSafeNumberFromInput(days),
                      )
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Cloturer la prise de réservation"
              value={!!bookingSettings?.visibilitySettings.isDelimitationEnabled}
              onValueChange={v =>
                updateBookingSettingsVisibilityData("isDelimitationEnabled", v)
              }
            />

            {bookingSettings?.visibilitySettings.isDelimitationEnabled && (
              <Box>
                <Box
                  mt="m"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <CustomTextInput
                    keyboardType="number-pad"
                    hasErrors={doesInputHaveError("visibilitySettings.amount")}
                    value={bookingSettings.visibilitySettings.amount.toString()}
                    onChangeText={v =>
                      updateBookingSettingsVisibilityData(
                        "amount",
                        getSafeNumberFromInput(v),
                      )
                    }
                    hideFloatingLabel
                    placeHolder=""
                    inputStyles={{
                      textAlign: "center",
                    }}
                  />
                  <Box flexDirection="row" alignItems="center">
                    <SelectButton
                      selectedTextVariant="text"
                      isMultiSelect={false}
                      options={DISALLOW_DELIMETER_TYPE}
                      selectedOptions={[
                        bookingSettings.visibilitySettings.delimiterMesaurementType,
                      ]}
                      onPress={([delimiter]) =>
                        updateBookingSettingsVisibilityData(
                          "delimiterMesaurementType",
                          delimiter,
                        )
                      }
                      placeHolder="Avant"
                      styles={{ mr: "s" }}
                    />
                    <SelectButton
                      selectedTextVariant="text"
                      isMultiSelect={false}
                      options={DISALLOW_DELIMETER}
                      selectedOptions={[
                        bookingSettings.visibilitySettings.disallowBookingByType,
                      ]}
                      onPress={([delimiter]) =>
                        updateBookingSettingsVisibilityData(
                          "disallowBookingByType",
                          delimiter,
                        )
                      }
                      placeHolder="Avant"
                    />
                  </Box>
                </Box>

                <Box mt="s">
                  <CustomText variant="text" color="lightGrey">
                    La prise de réservation ne sera plus possible en fonction des
                    informations ajoutées ci-dessus.
                  </CustomText>
                </Box>
              </Box>
            )}
          </Box>

          <Box mt="s">
            <CustomText mb="s" variant="content" color="primaryTextColor">
              Statut par défaut d'une réservation de passage
            </CustomText>
            <SelectButton
              isMultiSelect={false}
              options={DEFAULT_STATUSES}
              selectedOptions={[
                bookingSettings?.defaultStatusForNewBooking?.toString() ||
                  BOOKING_STATUS.ARRIVED?.toString(),
              ]}
              onPress={([status]) =>
                updateBookingSettingsData("defaultStatusForNewBooking", +status)
              }
              placeHolder="Statut"
            />
          </Box>

          {bookingSettings?.defaultPrepaymentSettings && (
            <DefaultBookingPrepayment
              bookingSettings={bookingSettings}
              setBookingSettings={setBookingSettings}
              errors={errors}
            />
          )}
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton onPress={handleUpdateBookingSettings} title="Enregistrer" />
    </Box>
  );
};

export default BookingGeneralSettings;
