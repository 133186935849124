import { Text, View } from "react-native";

const DeliveryHoursSettings = () => {
  return (
    <View>
      <Text>DeliveryHoursSettings</Text>
    </View>
  );
};

export default DeliveryHoursSettings;
