import { useState } from "react";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";

import AccountSecurityEdit from "./Edit";
import AccountSecurityView from "./View";

const AccountSecurity = () => {
  const [inEditMode, setInEditMode] = useState(false);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? <AccountSecurityEdit /> : <AccountSecurityView />}
      {!inEditMode && (
        <BottomButton title="Modifier" onPress={() => setInEditMode(true)} />
      )}
    </Box>
  );
};

export default AccountSecurity;
