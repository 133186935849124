import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useResizeMode } from "react-native-keyboard-controller";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import BottomButton from "../../../../../../components/BottomButton";
import ButtonList from "../../../../../../components/ButtonList/index";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView/index";
import Loader from "../../../../../../components/Loader";
import PhoneInput from "../../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../../components/TextInput";
import type { GetMerchantSettingsQuery } from "../../../../../../graphql/generated/schema";
import {
  useGetMerchantSettingsLazyQuery,
  useUpdateMerchantSettingsMutation,
} from "../../../../../../graphql/generated/schema";
import { isEmailValid, removeTypeNames } from "../../../../../../utils/common";

interface BillingDetailsProps {
  goBack?: () => void;
}

const BillingDetails = ({ goBack }: BillingDetailsProps) => {
  useResizeMode();

  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [merchantSettings, setMerchantSettings] = useState<
    GetMerchantSettingsQuery["getMerchantSettings"] | undefined
  >(undefined);

  const [updateMerchantSettings] = useUpdateMerchantSettingsMutation();
  const [getMerchantSettings] = useGetMerchantSettingsLazyQuery();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const updateMerchantSettingsData = (key: "siret" | "vatNumber", value: string) => {
    setMerchantSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  const updateMerchantSettingsBillingData = (key: string, value: string) => {
    setMerchantSettings(prev => {
      if (prev) {
        return {
          ...prev,
          billingDetails: {
            ...prev.billingDetails,

            [key]: value,
          },
        };
      }
    });
  };

  const handleGetMerchantSettings = async () => {
    try {
      const { data } = await getMerchantSettings();

      if (data?.getMerchantSettings) {
        setMerchantSettings(data.getMerchantSettings);
      }
    } catch (err) {
      console.log("err get merchant settings", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchantSettings();
  }, []);

  const handleUpdateMerchantSettings = async () => {
    try {
      if (merchantSettings) {
        const updates = removeTypeNames({
          billingDetails: merchantSettings.billingDetails,
        });

        const { data } = await updateMerchantSettings({
          variables: {
            updates,
          },
        });

        if (data?.updateMerchantSettings) {
          handleGoBack();
        }
      }
    } catch (err) {
      console.log("ERR update merchant settings", err);
    }
  };

  const handleOpenAddressEdit = () => {
    navigation.navigate("ADDRESS_DETAILS", {
      address: merchantSettings?.billingDetails?.address,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit: (address: any) => {
        updateMerchantSettingsBillingData("address", address);
        navigation.goBack();
      },
    });
  };

  const formatEmails = (emails: string[]) => {
    return emails.map(email => ({ key: email, label: email }));
  };

  const handleSetAdditionalEmailsForBilling = (
    vals: { label: string; key: string }[],
  ) => {
    const emails = vals.map(val => val.key?.toLowerCase());

    if (merchantSettings) {
      setMerchantSettings({
        ...merchantSettings,
        billingDetails: {
          ...merchantSettings.billingDetails,
          additionalEmailsForBilling: emails,
        },
      });
    }
  };

  if (loading) return <Loader />;

  return (
    <Box flex={1} p="m" backgroundColor="white">
      <ScreenHeader
        title="Détails de facturation"
        hasBackButton
        onBackPress={handleGoBack}
      />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Nom"
              initialValue={merchantSettings?.billingDetails?.name}
              onChangeText={value => updateMerchantSettingsBillingData("name", value)}
            />
          </Box>

          <Box>
            <TouchableOpacity onPress={handleOpenAddressEdit}>
              <CustomTextInput
                disabled
                boxProps={{
                  backgroundColor: "white",
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChangeText={() => {}}
                placeHolder="Adresse"
                initialValue={
                  merchantSettings?.billingDetails?.address?.formattedAddress || ""
                }
              />
            </TouchableOpacity>
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Email"
              initialValue={merchantSettings?.billingDetails?.email}
              onChangeText={value => updateMerchantSettingsBillingData("email", value)}
            />
          </Box>
          <Box marginVertical="s">
            <PhoneInput
              placeHolder="Numéro de téléphone"
              value={merchantSettings?.billingDetails?.phone || ""}
              onChangeFormattedText={value =>
                updateMerchantSettingsBillingData("phone", value)
              }
            />
          </Box>
          <Box mt="m">
            <Box mb="s">
              <CustomText mb="xs" variant="label" color="primaryTextColor">
                Destinataires supplémentaires pour les factures
              </CustomText>
              <CustomText variant="content" color="lightGrey">
                Les factures seront envoyées à ces adresses email en plus de l’adresse
                email principale.
              </CustomText>
            </Box>

            <ButtonList
              placeHolder="Email"
              values={formatEmails(
                merchantSettings?.billingDetails?.additionalEmailsForBilling || [],
              )}
              onChange={vals => handleSetAdditionalEmailsForBilling(vals)}
              keyboardType="email-address"
              isInputValueValid={value => !!isEmailValid(value)}
            />
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Enregistrer" onPress={handleUpdateMerchantSettings} />
    </Box>
  );
};

export default BillingDetails;
