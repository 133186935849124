import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import AddImageModal from "../../../../../components/AddImageModal";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import PhoneInput from "../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectModal from "../../../../../components/SelectModal";
import { CustomTextInput } from "../../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MerchantFragment } from "../../../../../graphql/generated/schema";
import {
  useGetMerchantLabelsCertificationsListLazyQuery,
  useGetMerchantLazyQuery,
  useGetMerchantPaymentMethodsListLazyQuery,
  useUpdateMerchantMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";
import {
  convertMerchantLabels,
  convertMerchantPaymentMethod,
} from "../../../../../utils/merchant";

interface MerchantInfoEditProps {
  goBack?: () => void;
}

const schema = yup.object().shape({
  label: yup.string().required("Votre établissement doit avoir un nom."),
  phone: yup.string().required("Votre établissement doit avoir un numéro de contact."),
  description: yup.string().required("Votre établissement doit avoir une description."),
  picture: yup.string(),
  email: yup
    .string()
    .email()
    .required("Votre établissement doit avoir une adresse email."),
});

const ICON_SIZE = 100;

const MerchantInfoEdit = ({ goBack }: MerchantInfoEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlertContext = useContext(ErrorInfoSuccessAlertModalContext);

  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getMerchant] = useGetMerchantLazyQuery();
  const [updateMerchant] = useUpdateMerchantMutation();
  const [getMerchantPaymentMethodsList] = useGetMerchantPaymentMethodsListLazyQuery();
  const [getMerchantLabelsList] = useGetMerchantLabelsCertificationsListLazyQuery();

  const handleGetMerchant = async () => {
    setLoading(true);

    try {
      const { data } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchant();
  }, []);

  const validateForm = async () => {
    try {
      await schema.validate(merchant, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));
      infoAlertContext.openAlert("erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleGoBack = () => {
    if (goBack) goBack();
    else navigation.goBack();
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const updates = removeTypeNames(merchant);
      delete updates._id;
      delete updates.permissions;

      const merchantTypes = merchant?.merchantTypes.map(type => type._id);
      const characteristics = merchant?.characteristics.map(
        characteristic => characteristic._id,
      );
      const ambiances = merchant?.ambiances.map(ambiance => ambiance._id);
      const spokenLanguages = merchant?.spokenLanguages.map(
        spokenLanguage => spokenLanguage._id,
      );

      const merchantPaymentMethods = merchant?.paymentMethods.map(pm => pm._id);
      const merchantLabels = merchant?.labelsCertifications.map(lc => lc._id);

      if (merchantPaymentMethods) updates.paymentMethods = merchantPaymentMethods;
      if (merchantLabels) updates.labelsCertifications = merchantLabels;

      if (merchantTypes) updates.merchantTypes = merchantTypes;
      if (characteristics) updates.characteristics = characteristics;
      if (ambiances) updates.ambiances = ambiances;
      if (spokenLanguages) updates.spokenLanguages = spokenLanguages;

      await updateMerchant({
        variables: { updates },
      });

      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors(formaYupErrors(err));

      infoAlertContext.openAlert("erreur", formaYupErrors(err), "error");
    }
  };

  const handleAddImage = (image?: string) => {
    if (image) setMerchant(prev => (prev === null ? null : { ...prev, icon: image }));
  };

  const doesInputHaveError = (key: keyof MerchantFragment) => {
    return errors.some(err => err.path === key);
  };

  const updateMerchantData = (key: keyof MerchantFragment, value: string) => {
    setMerchant(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  const handleOpenAddressEdit = () => {
    navigation.navigate("ADDRESS_DETAILS", {
      address: merchant?.address,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit: (address: any) => {
        updateMerchantData("address", address);
        navigation.goBack();
      },
    });
  };

  const displayListSelect = (items: { _id: string; name: string }[]) => {
    if (items.length === 0) return "";

    const [firstItem] = items;
    const itemsLength = items.length;

    if (itemsLength === 1) return firstItem.name;

    return `${firstItem.name} (+${itemsLength - 1})`;
  };

  const handleGoToListSelect = async (
    type: // | "spokenLanguages"
    // | "characteristics"
    "paymentMethods" | "labelsCertifications",
    // | "merchantTypes"
    // | "ambiances",
  ) => {
    try {
      const title =
        type === "paymentMethods" ? "Moyens de paiements" : "Labels et certifications";

      let listItems: { _id: string; name: string; originalName: string }[] = [];
      let filteredListItems: { _id: string; name: string; originalName: string }[] = [];
      let selectedItems: { _id: string; name: string }[] = [];

      if (type === "paymentMethods") {
        const { data } = await getMerchantPaymentMethodsList({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        if (data) {
          listItems = data.getMerchantPaymentMethodsList.map(pm => ({
            _id: pm._id,
            originalName: pm.name,
            name: convertMerchantPaymentMethod(pm.name),
          }));
          selectedItems =
            merchant?.paymentMethods?.map(pm => ({
              _id: pm._id,
              name: convertMerchantPaymentMethod(pm.name),
            })) || [];
          filteredListItems = listItems.filter(
            lc => !selectedItems.some(si => si._id === lc._id),
          );
        }
      } else if (type === "labelsCertifications") {
        const { data } = await getMerchantLabelsList({
          variables: {
            pagination: {
              limit: 100,
              offset: 0,
            },
          },
        });
        if (data) {
          selectedItems =
            merchant?.labelsCertifications?.map(pm => ({
              _id: pm._id,
              name: convertMerchantLabels(pm.name),
            })) || [];

          listItems = data.getMerchantLabelsCertificationsList.map(lc => ({
            _id: lc._id,
            originalName: lc.name,
            name: convertMerchantLabels(lc.name),
          }));

          filteredListItems = listItems.filter(
            lc => !selectedItems.some(si => si._id === lc._id),
          );
        }
      }

      navigation.navigate("LIST_SELECT", {
        title,
        listItems: filteredListItems,
        selectedItems,
        onSubmit: (sItems: { _id: string; name: string }[]) => {
          const consolidatedSelectedItems = sItems.map(item => {
            const foundItem = listItems.find(listItem => listItem._id === item._id);

            if (foundItem) {
              return {
                _id: foundItem._id,
                name: foundItem.originalName,
              };
            }
            return item;
          });

          setMerchant(prev => {
            if (prev) {
              return {
                ...prev,
                [type]: consolidatedSelectedItems,
              };
            }
            return null;
          });
          navigation.goBack();
        },
      });
    } catch (err) {
      console.log("err get list info", err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader title="Informations " hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 150 }}
        >
          <Box mt="s">
            <CustomText variant="content" color="success" textTransform="uppercase">
              Essentielles
            </CustomText>
          </Box>

          {!merchant?.icon && (
            <Box
              mt="s"
              borderRadius="button"
              borderWidth={1}
              borderColor="yellow"
              backgroundColor="yellowBackground"
              minHeight={45}
              justifyContent="center"
              p="s"
            >
              <CustomText variant="content" color="yellow">
                Veuillez s'assurer que votre image soit de bonne qualité et que les
                dimensions soient de 524x524 pixels.
              </CustomText>
            </Box>
          )}

          <AddImageModal
            width={ICON_SIZE}
            height={ICON_SIZE}
            picture={merchant?.icon}
            onSubmit={handleAddImage}
            shouldResize={false}
            displayImageStyle={{
              height: 80,
              width: 80,
              borderRadius: undefined,
            }}
          />

          <Box mt="s">
            <CustomTextInput
              placeHolder="Nom du merchant"
              hasErrors={doesInputHaveError("label")}
              initialValue={merchant?.label}
              onChangeText={t => updateMerchantData("label", t)}
              isRequired
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Email"
              hasErrors={doesInputHaveError("email")}
              initialValue={merchant?.email}
              onChangeText={t => updateMerchantData("email", t)}
              isRequired
            />
          </Box>
          <Box mt="s">
            <PhoneInput
              value={merchant?.phone || ""}
              hasErrors={doesInputHaveError("phone")}
              onChangeFormattedText={t => updateMerchantData("phone", t)}
            />
          </Box>
          <Box mt="s">
            <TouchableOpacity onPress={handleOpenAddressEdit}>
              <CustomTextInput
                disabled
                boxProps={{
                  backgroundColor: "white",
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChangeText={() => {}}
                placeHolder="Adresse"
                isRequired
                initialValue={merchant?.address?.formattedAddress || ""}
              />
            </TouchableOpacity>
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Description"
              multiLine
              initialValue={merchant?.description}
              onChangeText={t => updateMerchantData("description", t)}
              isRequired
              // boxProps={{
              //   minHeight: 100,
              //   alignItems: "flex-start",
              //   // paddingTop: "s",
              // }}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Site Web"
              initialValue={merchant?.website}
              onChangeText={t => updateMerchantData("website", t)}
            />
          </Box>
          <Box mt="s">
            <SelectModal title="Langue" value="Francais" />
          </Box>
          <Box mt="s">
            <SelectModal title="Devise" value="Euro" />
          </Box>

          <Box mt="s">
            <CustomText variant="content" color="success" textTransform="uppercase">
              Pratiques
            </CustomText>
          </Box>

          <Box mt="s">
            <CustomTextInput
              placeHolder="Métro"
              initialValue={merchant?.metro || ""}
              onChangeText={t => updateMerchantData("metro", t)}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Bus"
              initialValue={merchant?.bus || ""}
              onChangeText={t => updateMerchantData("bus", t)}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Parking"
              initialValue={merchant?.parking || ""}
              onChangeText={t => updateMerchantData("parking", t)}
            />
          </Box>
          <Box mt="s">
            <CustomTextInput
              placeHolder="Station de vélos"
              initialValue={merchant?.bikeParking || ""}
              onChangeText={t => updateMerchantData("bikeParking", t)}
            />
          </Box>

          <Box mt="s">
            <TouchableOpacity onPress={() => handleGoToListSelect("paymentMethods")}>
              <SelectModal
                title="Moyen de paiement"
                value={displayListSelect(
                  merchant?.paymentMethods?.map(pm => ({
                    _id: pm._id,
                    name: convertMerchantPaymentMethod(pm.name),
                  })) || [],
                )}
                hasBottomBorder={false}
              />
            </TouchableOpacity>
          </Box>
          <Box mt="s">
            <TouchableOpacity
              onPress={() => handleGoToListSelect("labelsCertifications")}
            >
              <SelectModal
                title="Label & certifications"
                value={displayListSelect(
                  merchant?.labelsCertifications.map(lc => ({
                    _id: lc._id,
                    name: convertMerchantLabels(lc.name),
                  })) || [],
                )}
              />
            </TouchableOpacity>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default MerchantInfoEdit;
