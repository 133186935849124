import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { FlatList, RefreshControl, TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import ReviewResponseCard from "../../../../components/ReviewResponseCard";
import ScreenHeader from "../../../../components/ScreenHeader";
import type {
  Pagination,
  ReviewResponseFragment,
} from "../../../../graphql/generated/schema";
import {
  useDeleteReviewResponseMutation,
  useGetReviewResponsesLazyQuery,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import ReviewResponsesListDetails from "../ReviewResponsesListDetails";

interface ReviewResponsesListProps {
  goBack?: () => void;
}

const ReviewResponsesList = ({ goBack }: ReviewResponsesListProps) => {
  const navigation = useNavigation();

  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [loading, setLoading] = useState(true);
  const [reviewResponses, setReviewResponses] = useState<ReviewResponseFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);

  const [getReviewResponses, { fetchMore }] = useGetReviewResponsesLazyQuery();
  const [deleteReviewResponse] = useDeleteReviewResponseMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            limit: 20,
            offset: reviewResponses.length,
          },
        },
      });
    } catch (err) {}
  };

  const handleGetReviewResponses = async () => {
    try {
      const { data } = await getReviewResponses({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination,
        },
      });

      if (data) {
        setReviewResponses(data.getReviewAutomaticAnswers);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetReviewResponses();
    }, []),
  );

  const handleAddReviewResponse = () => {
    if (goBack) {
      setShowInline(true);
      setSelectedId("");
    } else {
      navigation.navigate("REVIEW_RESPONSES_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEditReviewResponse = (id: string) => {
    if (goBack) {
      setShowInline(true);
      setSelectedId(id);
    } else {
      navigation.navigate("REVIEW_RESPONSES_LIST_DETAILS", { isNew: false, id });
    }
  };

  const handleDeleteReviewResponse = async (id: string) => {
    try {
      await deleteReviewResponse({
        variables: {
          id,
        },
        update: cache => {
          const normalizedId = cache.identify({
            _id: id,
            __typename: "ReviewResponse",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });

      await handleGetReviewResponses();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSelectedTab = async () => {
    await handleGetReviewResponses();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <ReviewResponsesListDetails
        newValue={!selectedId}
        selectedId={selectedId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box marginVertical="m">
          <ScreenHeader
            title="Avis"
            rightButtons={
              <TouchableOpacity onPress={handleAddReviewResponse}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </TouchableOpacity>
            }
            hasBackButton={!goBack}
            onBackPress={goBack || navigation.goBack}
          />
        </Box>
        <Box marginVertical="s">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Réponses aux avis
          </CustomText>
        </Box>

        <FlatList
          data={reviewResponses}
          showsVerticalScrollIndicator={false}
          keyExtractor={({ _id }) => _id}
          onEndReached={handleFetchMore}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleGetReviewResponses} />
          }
          renderItem={({ item }) => (
            <Box mb="s">
              <ReviewResponseCard
                reviewResponse={item}
                onDelete={() => handleDeleteReviewResponse(item._id)}
                onPress={() => handleEditReviewResponse(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default ReviewResponsesList;
