import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView/index";
import Loader from "../../../../../components/Loader";
import AddNewImageModal from "../../../../../components/Media/AddNewImageMedia";
import PhoneInput from "../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import Toggle from "../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  CreateMerchantPersonnelInput,
  MerchantPersonnelFragment,
  MerchantPersonnelPermissionGroupFragment,
  MerchantPersonnelSettingsFragment,
} from "../../../../../graphql/generated/schema";
import {
  GetMerchantPersonnelsDocument,
  LoginStrategy,
  useCreateMerchantPersonnelMutation,
  useDeleteMerchantPersonnelMutation,
  useGetMerchantPersonnelLazyQuery,
  useGetMerchantPersonnelPermissionGroupsLazyQuery,
  useGetMerchantPersonnelSettingsLazyQuery,
  useSavePictureMutation,
  useUpdateMerchantPersonnelMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../utils/common";

interface StaffListDetailsEditProps {
  onGoBack: () => void;
  isNew: boolean;
  id?: string;
}

const IMAGE_SIZE = 65;

const DEFAULT_MERCHANT_PERSONNEL: CreateMerchantPersonnelInput = {
  phone: "",
  picture: "",
  jobTitle: "",
  lastName: "",
  firstName: "",
  loginCode: "",
  isAdministrator: false,
  shouldReceiveNotifications: false,
  isDisabled: false,
  permissionGroup: "",
  workHours: [],
  email: "",
};

const StaffListDetailsEdit = ({ onGoBack, isNew, id }: StaffListDetailsEditProps) => {
  useResizeMode();

  const [loading, setLoading] = useState(!isNew);
  const navigation = useNavigation();
  const errorInfoSuccessAlertContext = useContext(ErrorInfoSuccessAlertModalContext);
  const [merchantPersonnel, setMerchantPersonnel] =
    useState<CreateMerchantPersonnelInput>(DEFAULT_MERCHANT_PERSONNEL);
  const [merchantPersonnelSettings, setMerchantPersonnelSettings] =
    useState<MerchantPersonnelSettingsFragment | null>(null);
  const [permissionGroups, setpermissionGroups] = useState<
    MerchantPersonnelPermissionGroupFragment[]
  >([]);
  const [loginCode, setLoginCode] = useState("");
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickeOpen, setIsImagePickeOpen] = useState(false);

  const [getMerchantPersonnel] = useGetMerchantPersonnelLazyQuery();
  const [getMerchantPersonnelSettings] = useGetMerchantPersonnelSettingsLazyQuery();
  const [getMerchantPermissionGroups] =
    useGetMerchantPersonnelPermissionGroupsLazyQuery();

  const [savePicture] = useSavePictureMutation();
  const [createMerchantPersonnel] = useCreateMerchantPersonnelMutation();
  const [updateMerchantPersonnel] = useUpdateMerchantPersonnelMutation();
  const [deleteMerchantPersonnel] = useDeleteMerchantPersonnelMutation();

  const handleDeleteMerchantPersonnel = async () => {
    try {
      if (id) {
        await deleteMerchantPersonnel({
          variables: {
            merchantPersonnelId: id,
          },
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "MerchantPersonnel",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        onGoBack();
      }
    } catch (err) {
      console.log("err delete personnel", err);
    }
  };

  const validateForm = async () => {
    try {
      const consolidatedMerchantPersonnel = {
        ...merchantPersonnel,
        loginCode,
      };

      const schema = yup.object().shape({
        phone: yup.string(),
        picture: yup.string(),
        jobTitle: yup.string(),
        firstName: yup.string(),
        lastName: yup.string().required("Nom du personnel est requis"),
        loginCode: isNew
          ? yup.string().length(4, "Code pin doit contenir 4 chiffres")
          : yup.string(),
        email: yup.string().email("Email invalide"),
        permissionGroup: yup.string().required("Groupe de permission est requis"),
        isAdministrator: yup.bool(),
      });

      await schema.validate(consolidatedMerchantPersonnel, { abortEarly: false });

      return true;
    } catch (err) {
      const formattedErrors = formaYupErrors(err);
      setErrors(formattedErrors);

      errorInfoSuccessAlertContext.openAlert(
        "Erreur dans le formulaire",
        formattedErrors,
        "error",
      );

      // setIsSnackBarOpen(true);
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      const isFormValid = await validateForm();

      if (!isFormValid) return;

      if (isNew) {
        const consolidatedMerchantPersonnel = {
          ...merchantPersonnel,
          loginCode,
        };
        await createMerchantPersonnel({
          variables: {
            merchantPersonnel: consolidatedMerchantPersonnel,
          },
          refetchQueries: [
            {
              query: GetMerchantPersonnelsDocument,
              variables: {
                pagination: {
                  limit: 20,
                  offset: 0,
                },
              },
            },
          ],
        });
      }
      if (!isNew && id) {
        const updates = removeTypeNames(merchantPersonnel);

        delete updates._id;
        delete updates.isDeleted;
        delete updates.generatedProfitForMerchant;

        const trimmedLoginCode = loginCode.trim();

        if (trimmedLoginCode.length > 0) {
          updates.loginCode = trimmedLoginCode;
        }

        await updateMerchantPersonnel({
          variables: {
            merchantPersonnelId: id,
            merchantPersonnel: updates,
          },
          refetchQueries: [
            {
              query: GetMerchantPersonnelsDocument,
              variables: {
                pagination: {
                  limit: 20,
                  offset: 0,
                },
              },
            },
          ],
        });
      }

      onGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      const formattedErrors = [{ path: "submit", code: "submit", message: err.message }];
      setErrors(formattedErrors);

      errorInfoSuccessAlertContext.openAlert(
        "Erreur dans le formulaire",
        errors,
        "error",
      );

      // setIsSnackBarOpen(true);
    }
  };

  const handleUpdateMerchantPersonnel = (
    key: keyof MerchantPersonnelFragment,
    value: MerchantPersonnelFragment[keyof MerchantPersonnelFragment],
  ) => {
    setMerchantPersonnel(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleGetPermissionGroups = async () => {
    try {
      const groups = await getMerchantPermissionGroups({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
        fetchPolicy: "network-only",
      });

      setpermissionGroups(groups.data?.getMerchantPersonnelPermissionGroups || []);
    } catch (err) {
      console.log("err get permssions", err);
    }
  };

  const handleFormatPermissionGroups = () => {
    const formatted = permissionGroups.map(group => ({
      label: group.name,
      key: group._id,
    }));

    return formatted;
  };

  const handleGetMerchantPersonnelSettings = async () => {
    try {
      const settings = await getMerchantPersonnelSettings();

      if (settings.data) {
        setMerchantPersonnelSettings(settings.data.getMerchantPersonnelSettings);
      }
    } catch (err) {
      console.log("err settings", err);
    }
  };

  const handleInit = async () => {
    try {
      if (id) {
        const staff = await getMerchantPersonnel({
          variables: {
            merchantPersonnelId: id,
          },
        });

        if (staff.data) {
          const personnel = staff.data.getMerchantPersonnel;
          const formattedPersonnel = {
            ...personnel,
            permissionGroup: personnel.permissionGroup?._id,
          };

          setMerchantPersonnel(formattedPersonnel);
        }
      } else {
        setMerchantPersonnel(DEFAULT_MERCHANT_PERSONNEL);
      }
    } catch (err) {
      console.log("err init", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
    handleGetMerchantPersonnelSettings();
    handleGetPermissionGroups();
  }, []);

  const displayTitle = () => {
    if (isNew) {
      return "Ajouter un utilisateur";
    }
    return "Edition de l'utilisateur";
  };

  const displayPasswordTitle = () => {
    if (
      merchantPersonnelSettings?.loginStrategy === LoginStrategy.LoginCode ||
      merchantPersonnelSettings?.loginStrategy === LoginStrategy.UserLoginCode
    ) {
      return "Code pin";
    }
    return "Mot de passe";
  };

  const getSelectedPermissionGroup = () => {
    if (!merchantPersonnel.permissionGroup) return [];
    const permissionGroupId =
      typeof merchantPersonnel.permissionGroup === "string"
        ? merchantPersonnel.permissionGroup
        : merchantPersonnel.permissionGroup?._id;

    return [permissionGroupId];
  };

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        handleUpdateMerchantPersonnel("picture", data.savePicture);
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const handleLoginCodeChange = (code: string) => {
    const formattedCode = getSafeNumberFromInput(code.trim().slice(0, 4))?.toString();

    setLoginCode(formattedCode);
  };

  const doesInputHaveError = (key: keyof MerchantPersonnelFragment) => {
    return errors.some(err => err.path === key);
  };

  const displayPicture = () => {
    const { picture } = merchantPersonnel;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickeOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickeOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 150,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <ScreenHeader title={displayTitle()} hasBackButton onBackPress={onGoBack} />
            </Box>

            {displayPicture()}

            <Box mt="s">
              <CustomTextInput
                placeHolder="Nom"
                isRequired
                hasErrors={doesInputHaveError("lastName")}
                initialValue={merchantPersonnel.lastName}
                onChangeText={v => handleUpdateMerchantPersonnel("lastName", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Prénom"
                hasErrors={doesInputHaveError("firstName")}
                initialValue={merchantPersonnel.firstName || ""}
                onChangeText={v => handleUpdateMerchantPersonnel("firstName", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Titre"
                hasErrors={doesInputHaveError("jobTitle")}
                initialValue={merchantPersonnel.jobTitle || ""}
                onChangeText={v => handleUpdateMerchantPersonnel("jobTitle", v)}
              />
            </Box>
            <Box mt="s">
              <PhoneInput
                value={merchantPersonnel.phone || ""}
                hasErrors={doesInputHaveError("phone")}
                onChangeFormattedText={v => handleUpdateMerchantPersonnel("phone", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                hasErrors={doesInputHaveError("email")}
                initialValue={merchantPersonnel.email || ""}
                onChangeText={v => handleUpdateMerchantPersonnel("email", v)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder={displayPasswordTitle()}
                isRequired={isNew}
                hasErrors={doesInputHaveError("loginCode")}
                value={loginCode}
                onChangeText={handleLoginCodeChange}
                keyboardType="number-pad"
              />
            </Box>
            {/* <Box mt="s">
            <CustomTextInput
              placeHolder={displayConfirmPasswordTitle()}
              isRequired
              onChangeText={() => {}}
            />
          </Box> */}

            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="label" color="primaryTextColor">
                Administrateur
              </CustomText>
              <Toggle
                value={merchantPersonnel.isAdministrator}
                onChange={v => handleUpdateMerchantPersonnel("isAdministrator", v)}
              />
            </Box>
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                variant="label"
                color="primaryTextColor"
                style={{ flex: 1, flexWrap: "wrap" }}
              >
                Recevoir les notifications de l'etablissement
              </CustomText>
              <Toggle
                value={merchantPersonnel.shouldReceiveNotifications}
                onChange={v =>
                  handleUpdateMerchantPersonnel("shouldReceiveNotifications", v)
                }
              />
            </Box>

            {permissionGroups.length > 0 && (
              <Box mt="s">
                <SelectButton
                  isMultiSelect={false}
                  hasErrors={doesInputHaveError("permissionGroup")}
                  options={handleFormatPermissionGroups()}
                  selectedOptions={getSelectedPermissionGroup()}
                  onPress={([groups]) =>
                    handleUpdateMerchantPersonnel("permissionGroup", groups)
                  }
                  placeHolder="Groupe de permissions"
                />
              </Box>
            )}
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDeleteMerchantPersonnel}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>

      <AddNewImageModal
        isOpen={isImagePickeOpen}
        setIsOpen={val => setIsImagePickeOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default StaffListDetailsEdit;
