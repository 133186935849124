import { useNavigation } from "@react-navigation/native";

import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import ScreenHeader from "../../../components/ScreenHeader";

const Help = () => {
  const navigation = useNavigation();

  return (
    <Box flex={1} backgroundColor="white">
      <Box mt="m">
        <ScreenHeader title="Aide" onBackPress={navigation.goBack} hasBackButton />
      </Box>

      <Box alignItems="center" justifyContent="center">
        <CustomText variant="content" color="primaryTextColor">
          Help
        </CustomText>
      </Box>
    </Box>
  );
};

export default Help;
