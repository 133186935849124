import { FlatList, TouchableOpacity } from "react-native";

import Box from "../../Base/Box";

import CategoryTypeItem, { CATEGORY_TYPE } from "./CategoryTypeItem";

interface CategoryListSelectProps {
  onPress: (type: CATEGORY_TYPE) => void;
  selectedType: CATEGORY_TYPE;
}

const CATEGORIES_ICONS = Object.values(CATEGORY_TYPE);

const CategoryListSelect = ({ onPress, selectedType }: CategoryListSelectProps) => {
  return (
    <Box>
      <FlatList
        data={CATEGORIES_ICONS}
        // contentContainerStyle={{ alignSelf: "flex-start", flex: 1 }}
        // numColumns={Math.ceil(CATEGORIES_ICONS.length / 2)}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal
        keyExtractor={item => item}
        contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap" }}
        renderItem={({ item }) => {
          const IS_SELECTED = item === selectedType;

          return (
            <TouchableOpacity onPress={() => onPress(item)} style={{ margin: 8 }}>
              <Box
                borderColor="success"
                borderRadius="button"
                borderWidth={IS_SELECTED ? 2 : 0}
                alignItems="center"
                justifyContent="center"
              >
                <CategoryTypeItem type={item} />
              </Box>
            </TouchableOpacity>
          );
        }}
      />
    </Box>
  );
};

export default CategoryListSelect;
