import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import PLUS from "../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import InlineSelectButton from "../../../../components/InlineSelectButton";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import type { ClosureFragment } from "../../../../graphql/generated/schema";
import {
  Closure_Types,
  useDeleteClosureMutation,
  useGetClosuresLazyQuery,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import BookingClosureDetails from "../BookingClosureDetails";

interface BookingClosuresSettingsProps {
  goBack?: () => void;
}

const BookingClosuresSettings = ({ goBack }: BookingClosuresSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [closures, setClosures] = useState<ClosureFragment[]>([]);
  const [selectedClosureId, setSelectedClosureId] = useState("");
  const [showClosureInline, setShowClosureInline] = useState(false);

  const [getClosures, { fetchMore }] = useGetClosuresLazyQuery();
  const [deleteClosure] = useDeleteClosureMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: closures.length,
          },
        },
      });

      if (data.getClosures) {
        setClosures([...closures, ...data.getClosures]);
      }
    } catch (err) {}
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetClosures = async (refresh = false) => {
    setLoading(true);
    try {
      const result = await getClosures({
        fetchPolicy: "cache-and-network",
        variables: {
          type: Closure_Types.Booking,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (result.data) {
        setClosures(result.data.getClosures);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetClosures();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setSelectedClosureId("");
      setShowClosureInline(true);
    } else {
      navigation.navigate("BOOKING_CLOSURES_DETAILS", {
        isNew: true,
      });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedClosureId(id);
      setShowClosureInline(true);
    } else {
      navigation.navigate("BOOKING_CLOSURES_DETAILS", {
        isNew: false,
        id,
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteClosure({
        variables: {
          closureId: id,
        },
      });

      await handleGetClosures(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseDetails = async () => {
    try {
      await handleGetClosures(true);
      setShowClosureInline(false);
    } catch (err) {}
  };

  const displaySelectedTab = () => {
    return (
      <BookingClosureDetails
        goBack={handleCloseDetails}
        selectedClosureId={selectedClosureId}
        newValue={!selectedClosureId}
      />
    );
  };

  const displayClosureDetails = (closure: ClosureFragment) => {
    const { title, dates, isPeriod, startDate, endDate } = closure;

    if (isPeriod) {
      return (
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="disabled"
          borderRadius="button"
          p="s"
          minHeight={45}
          flex={1}
          mr="m"
        >
          <CustomText variant="content" color="primaryTextColor">
            {title}
          </CustomText>
          <CustomText variant="content" color="primaryTextColor">
            {format(new Date(startDate), "dd/MM/yyyy")} -{" "}
            {format(new Date(endDate), "dd/MM/yyyy")}
          </CustomText>
        </Box>
      );
    }

    const slicedDates = dates?.slice(0, 2) || [];
    const remainingDates = (dates?.length || 0) > 0 ? (dates?.length || 0) - 2 : 0;
    const slicedDatesString = slicedDates
      .map(date => format(new Date(date), "dd/MM/yyyy"))
      .join(", ");

    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="disabled"
        borderRadius="button"
        p="s"
        minHeight={45}
        flex={1}
        mr="m"
      >
        <CustomText variant="content" color="primaryTextColor">
          {title}
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          {slicedDatesString} {remainingDates > 0 ? `+${remainingDates}` : ""}
        </CustomText>
      </Box>
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Fermetures exceptionnelles"
          hasBackButton
          onBackPress={handleGoBack}
        />

        <Box mt="m" flex={1}>
          <FlatList
            data={closures}
            onEndReached={handleFetchMore}
            ListHeaderComponent={
              <TouchableOpacity onPress={handleAddNew}>
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mr="s"
                  marginVertical="s"
                >
                  <Box flex={0.95}>
                    <InlineSelectButton
                      title="Ajouter une fermeture"
                      options={[]}
                      selectedOptionKeys={[]}
                      isMultiSelect={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onPress={() => {}}
                    />
                  </Box>

                  <PLUS fill={PALETTE.green} width={15} height={15} />
                </Box>
              </TouchableOpacity>
            }
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handleEdit(item._id)}>
                <Box
                  marginVertical="s"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {displayClosureDetails(item)}
                  <TouchableOpacity onPress={() => handleDelete(item._id)}>
                    <TRASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.red} />
                  </TouchableOpacity>
                </Box>
              </TouchableOpacity>
            )}
            keyExtractor={item => item._id}
          />
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (showClosureInline) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default BookingClosuresSettings;
