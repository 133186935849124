import { useMemo, useState } from "react";
import { Dimensions, TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import SELECTED_TABLE from "../../../assets/icons/BASE/SELECTED_TABLE.svg";
import { BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT } from "../../constants/BookingFloor";
import {
  Booking_Status,
  type BookingFloorFragment,
  type TableFragment,
} from "../../graphql/generated/schema";
import type { THEME_COLORS } from "../../theme";
import { LINE_THICKNESS } from "../../theme";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../types";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { HEADER_HEIGHT } from "../Header";
import { MODULE_TYPE } from "../ModuleList/common";
import { CustomTextInput } from "../TextInput";
import Touchable from "../Touchable";

const { height: SCREEN_HEIGHT } = Dimensions.get("window");

enum TABLE_VIEW_FILTER_TYPE {
  NOT_ASSIGNED = "Non assignées",
  ASSIGNED = "Assignées",
  ALL = "Toutes",
}

interface TablesViewProps {
  bookingFloors: BookingFloorFragment[];
  tableIds: string[];
  selectedIds: string[];
  moduleType: MODULE_TYPE;
  handleTableSelection: (tableId: string) => void;
  bookingFloorViewType: BOOKING_FLOOR_VIEW_TYPE;
  selectedBookingServiceId?: string;
}

const TablesView = ({
  bookingFloors,
  tableIds,
  handleTableSelection,
  bookingFloorViewType,
  selectedIds,
  moduleType,
  selectedBookingServiceId,
}: TablesViewProps) => {
  const [selectedFilterType, setSelectedFilterType] = useState(
    TABLE_VIEW_FILTER_TYPE.NOT_ASSIGNED,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const allTables = bookingFloors.reduce((acc, floor) => {
    const floorTables = floor.tables.map(table => ({
      ...table,
      prefix: floor.prefix,
    }));
    return [...acc, ...floorTables];
  }, [] as BookingFloorFragment["tables"]);

  const tables = useMemo(() => {
    const filteredTables = bookingFloors.reduce((acc, floor) => {
      const floorTables = floor.tables
        .filter(table => {
          const tableBookings = table.bookings || [];
          const nbTableBookings = tableBookings.length;

          if (selectedFilterType === TABLE_VIEW_FILTER_TYPE.NOT_ASSIGNED) {
            return nbTableBookings === 0;
          }
          if (selectedFilterType === TABLE_VIEW_FILTER_TYPE.ASSIGNED) {
            return nbTableBookings > 0;
          }

          return true;
        })
        .map(table => ({
          ...table,
          prefix: floor.prefix,
        }));
      return [...acc, ...floorTables];
    }, [] as BookingFloorFragment["tables"]);

    return filteredTables;
  }, [bookingFloors, selectedFilterType]);

  const PADDING =
    bookingFloorViewType === BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT ? 200 : 100;

  const FLOOR_AVAILABLE_HEIGHT =
    SCREEN_HEIGHT - HEADER_HEIGHT - BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT - PADDING;

  const consolidatedTables = useMemo(() => {
    return tables.filter(t => {
      if (!searchQuery) return true;

      return (
        t.prefix?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        t.tableNumber.toString().includes(searchQuery?.toLowerCase())
      );
    });
  }, [tables, searchQuery]);

  const displaySelectedTable = (tableId: string) => {
    const table = allTables.find(t => t._id === tableId);

    return (
      <TouchableOpacity onPress={() => handleTableSelection(tableId)}>
        <Box mr="s">
          <SELECTED_TABLE />

          <Box
            position="absolute"
            top="35%"
            left="35%"
            alignItems="center"
            justifyContent="center"
          >
            <CustomText variant="content" fontSize={12} color="primaryTextColor">
              {table?.prefix}-{table?.tableNumber}
            </CustomText>
          </Box>
        </Box>
      </TouchableOpacity>
    );
  };

  const getTableBookings = (table: TableFragment) => {
    const bookings = table.bookings || [];
    // if (selectedBookingServiceId === "ALL") {
    //   bookings = table.bookings || [];
    // } else {
    //   bookings =
    //     table.bookings?.filter(
    //       booking => booking?.serviceId === selectedBookingServiceId,
    //     ) || [];
    // }

    return bookings.filter(b => {
      const statuses = [
        Booking_Status.New,
        Booking_Status.ModifiedByUser,
        Booking_Status.ModifiedByOwner,
        Booking_Status.Validated,
        Booking_Status.AutomaticValidated,
        Booking_Status.Delayed,
        Booking_Status.Seated,
        Booking_Status.Arrived,
        Booking_Status.ArrivedWaiting,
      ];
      return statuses.includes(b.status);
    });
  };

  const isTableAlreadyAssigned = (table: TableFragment) => {
    const filteredBookings = getTableBookings(table);
    if (moduleType === MODULE_TYPE.BOOKING && filteredBookings) {
      return filteredBookings.length > 0;
    } else if (moduleType === MODULE_TYPE.CASH_REGISTER && table?.cashRegisterTickets) {
      return table?.cashRegisterTickets?.length > 0;
    }
    return false;
  };

  return (
    <Box flex={1}>
      <Box paddingHorizontal="s">
        <CustomTextInput
          placeHolder="Entrez numéro de(s) table(s)"
          onChangeText={setSearchQuery}
        />
      </Box>

      <Box m="s" flexDirection="row" alignItems="center">
        {Object.keys(TABLE_VIEW_FILTER_TYPE).map(key => {
          const value = TABLE_VIEW_FILTER_TYPE[key];

          const isSelected = selectedFilterType === value;

          return (
            <Touchable key={key} onPress={() => setSelectedFilterType(value)}>
              <Box
                borderColor="success"
                borderWidth={isSelected ? LINE_THICKNESS : 0}
                borderRadius="button"
                backgroundColor={isSelected ? "white" : "disabled"}
                alignItems="center"
                justifyContent="center"
                p="m"
                minHeight={45}
                minWidth={100}
                mr="s"
              >
                <CustomText variant="label" fontSize={12} color="primaryTextColor">
                  {value}
                </CustomText>
              </Box>
            </Touchable>
          );
        })}
      </Box>

      {bookingFloorViewType === BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT && (
        <Box mt="s" paddingHorizontal="s">
          <CustomText variant="label" fontSize={12} color="primaryTextColor">
            Tables assignés
          </CustomText>

          <Box justifyContent="center" minHeight={80}>
            <FlatList
              horizontal
              showsHorizontalScrollIndicator={false}
              data={tableIds}
              keyExtractor={item => item}
              renderItem={({ item }) => displaySelectedTable(item)}
            />
          </Box>
        </Box>
      )}

      <Box flex={1}>
        <Box mt="xs" backgroundColor="disabled" p="s">
          <CustomText variant="label" fontSize={12} color="primaryTextColor">
            Toutes les tables
          </CustomText>
        </Box>

        <Box paddingHorizontal="s" maxHeight={FLOOR_AVAILABLE_HEIGHT}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={consolidatedTables}
            contentContainerStyle={{ paddingBottom: 50 }}
            keyExtractor={item => item._id}
            renderItem={({ item }) => {
              const IS_TABLE_ALREADY_ASSIGNED = isTableAlreadyAssigned(item);
              const IS_TABLE_SELECTED = tableIds.includes(item._id);

              let suffix = "";
              let color: THEME_COLORS = "primaryTextColor";

              if (IS_TABLE_ALREADY_ASSIGNED) {
                suffix = " | Cette table est déjà assignée";
                color = "orange";
              }
              if (IS_TABLE_SELECTED) {
                const resource =
                  moduleType === MODULE_TYPE.BOOKING ? "la réservation" : "le ticket";
                suffix = ` | Table utilisée pour ${resource}`;
                color = "success";
              }

              return (
                <TouchableOpacity onPress={() => handleTableSelection(item._id)}>
                  <Box
                    borderBottomColor="lightGrey"
                    borderBottomWidth={LINE_THICKNESS}
                    paddingVertical="xs"
                  >
                    <CustomText variant="content" color={color}>
                      {item.prefix}-{item.tableNumber} {suffix}
                    </CustomText>
                    <CustomText variant="text" color="lightGrey">
                      Capacité: {item.minCapacity} - {item.maxCapacity}
                    </CustomText>
                  </Box>
                </TouchableOpacity>
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TablesView;
