import type { THEME_COLORS } from "../../theme";
import type { TEXT_VARIANTS } from "../../theme/variants/TextVariants";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface ToggleInputLineProps {
  text: string;
  fontSize?: number;
  textVariant?: TEXT_VARIANTS;
  textColor?: THEME_COLORS;
  value: boolean;
  onValueChange: (value: boolean) => void;
  hasErrors?:boolean
}

const ToggleInputLine = ({
  text,
  value,
  onValueChange,
  fontSize,
  textVariant = "label",
  textColor = "primaryTextColor",
  hasErrors
}: ToggleInputLineProps) => {

  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between">
      <CustomText
        mr="s"
        style={{ flex: 1, flexWrap: "wrap" }}
        fontSize={fontSize}
        variant={textVariant}
        color={hasErrors ? "danger":textColor}
      >
        {text}
      </CustomText>
      <Toggle value={value} onChange={onValueChange} />
    </Box>
  );
};

export default ToggleInputLine;
