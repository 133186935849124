import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";

import HOME from "../../../../assets/icons/BASE/HOME.svg";
import PRESCRIBER from "../../../../assets/icons/BASE/PRESCRIBER.svg";
import USER_GROUP from "../../../../assets/icons/BASE/USER_GROUP.svg";
import Box from "../../../components/Base/Box";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import SettingsButtonItem from "../../../components/Settings/SettingsButtonItem";
import type { GetMerchantClientsTotalSummaryQuery } from "../../../graphql/generated/schema";
import { useGetMerchantClientsTotalSummaryLazyQuery } from "../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

import ClientList from "./ClientList";
import CompanyList from "./CompanyList";
import PrescriberList from "./PrescriberList";

interface ClientSettingsProps {
  goBack?: () => void;
}

const ClientSettings = ({ goBack }: ClientSettingsProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const [merchantClientTotalSummary, setmerchantClientTotalSummary] = useState<
    GetMerchantClientsTotalSummaryQuery["getMerchantClientsTotalSummary"] | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);

  const [getMerchantClientTotalSummary] = useGetMerchantClientsTotalSummaryLazyQuery();

  const handleGetMerchantClientTotalSummary = async () => {
    try {
      const response = await getMerchantClientTotalSummary({
        fetchPolicy: "cache-and-network",
      });

      if (response.data) {
        setmerchantClientTotalSummary(response.data.getMerchantClientsTotalSummary);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMerchantClientTotalSummary();
    }, []),
  );

  const handleNavigation = (screenName: string) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!merchantClientTotalSummary) {
    return (
      <Box>
        <ScreenHeader
          title="Clients"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données." />
      </Box>
    );
  }

  const handleCloseSelectedTab = () => {
    setSelectedTab(undefined);
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "CLIENT_LIST":
        return <ClientList goBack={handleCloseSelectedTab} />;
      case "COMPANY_LIST":
        return <CompanyList goBack={handleCloseSelectedTab} />;
      case "PRESCRIBER_LIST":
        return <PrescriberList goBack={handleCloseSelectedTab} />;
      default:
        break;
    }
  };

  const displayClientSettings = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Clients"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Clients"
            subText={`${merchantClientTotalSummary.totalMerchantClients} clients existants`}
            onPress={() => handleNavigation("CLIENT_LIST")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<USER_GROUP fill={PALETTE.green} width={19} height={19} />}
          />
          <SettingsButtonItem
            title="Entreprises"
            subText={`${merchantClientTotalSummary.totalMerchantClientCompanies} entreprises existantes`}
            onPress={() => handleNavigation("COMPANY_LIST")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<HOME fill={PALETTE.green} width={19} height={19} />}
          />
          <SettingsButtonItem
            title="Prescripteurs"
            subText={`${merchantClientTotalSummary.totalMerchantClientPrescribers} prescripteurs existantes`}
            onPress={() => handleNavigation("PRESCRIBER_LIST")}
            bottomLineWidth={0}
            icon={<PRESCRIBER fill={PALETTE.green} width={19} height={19} />}
          />
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    }

    return displayClientSettings();
  };

  return <>{displayContent()}</>;
};

export default ClientSettings;
