import { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Platform, TouchableOpacity } from "react-native";
import type { TextInput } from "react-native-gesture-handler";

import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import VALIDATE from "../../../assets/icons/BASE/VALIDATE.svg";
import type { MerchantClientFragment } from "../../graphql/generated/schema";
import { useSearchForMerchantClientsLazyQuery } from "../../graphql/generated/schema";
import { useDebounce } from "../../hooks/useDebounce";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { uniquefyArray } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import Loader from "../Loader";
import PaginatedList from "../PaginatedList";
import { CustomTextInput } from "../TextInput";

const IS_WEB = Platform.OS === "web";
const { height } = Dimensions.get("window");

interface ClientListProps {
  client?: MerchantClientFragment | null;
  onClientSelected: (client: MerchantClientFragment) => void;
  onSearchEmptyClient: (q: string) => void;
  goBack?: () => void;
}

const ClientList = ({
  client,
  onClientSelected,
  goBack,
  onSearchEmptyClient,
}: ClientListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [clients, setClients] = useState<MerchantClientFragment[]>([]);
  const [loading, setLoading] = useState(false);
  const [isSearchOnGoing, setIsSearchOnGoing] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);

  // const [getMerchantClients, { fetchMore }] = useGetMerchantClientsLazyQuery();
  const [searchForMerchantClients, { fetchMore: fetchMoreSearchMerchantClients }] =
    useSearchForMerchantClientsLazyQuery();

  const handleSearchMerchantClients = async () => {
    try {
      if (searchQuery.trim().length <= 0) return setClients([]);

      setIsEmptySearch(false);
      setIsSearchOnGoing(true);

      const response = await searchForMerchantClients({
        variables: {
          searchQuery,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
        fetchPolicy: "cache-and-network",
      });

      if (response?.data?.searchForMerchantClients) {
        const clients = response.data.searchForMerchantClients;
        setClients(clients);
        setIsEmptySearch(clients.length <= 0);
      }
    } catch (err) {
      console.log("AN_ERROR_OCCURED", err);
    } finally {
      setIsSearchOnGoing(false);
    }
  };

  useDebounce(
    () => {
      handleSearchMerchantClients();
    },
    400,
    [searchQuery],
  );

  const handleLoadMore = async () => {
    try {
      if (searchQuery.trim().length > 0) {
        const { data: newData } = await fetchMoreSearchMerchantClients({
          variables: {
            pagination: {
              limit: 20,
              offset: clients.length,
            },
          },
        });

        if (newData?.searchForMerchantClients) {
          setClients(uniquefyArray([...clients, ...newData.searchForMerchantClients]));
        }
      }
      // else {
      //   const { data: newData } = await fetchMore({
      //     variables: {
      //       pagination: {
      //         limit: 20,
      //         offset: clients.length,
      //       },
      //     },
      //   });

      //   if (newData?.getMerchantClients) {
      //     setClients(uniquefyArray([...clients, ...newData.getMerchantClients]));
      //   }
      // }
    } catch (err) {
      console.log("an error occured", err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const displayEmaptyComponent = () => {
    return (
      <Box mt="l" justifyContent="center" alignItems="center">
        <CustomText variant="label" color="primaryTextColor">
          Aucun resultat
        </CustomText>

        {isEmptySearch && searchQuery.trim().length > 0 && (
          <Box mt="s">
            <CustomButton
              buttonVariant="primaryButton"
              buttonColor="success"
              onPress={() => onSearchEmptyClient(searchQuery)}
              containerStyles={{
                paddingHorizontal: 20,
              }}
              styles={{
                paddingHorizontal: "m",
              }}
            >
              <CustomText variant="primaryButtonText" color="white">
                Créer un nouveau client
              </CustomText>
            </CustomButton>
          </Box>
        )}
      </Box>
    );
  };

  const displayContent = () => {
    if (isSearchOnGoing) {
      return (
        <Box backgroundColor={"white"} alignItems="center" justifyContent="center">
          <ActivityIndicator size="small" color={PALETTE.green} />
        </Box>
      );
    }

    return (
      <Box maxHeight={height - 300}>
        <PaginatedList
          data={clients}
          keyExtractor={item => item._id}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 300,
          }}
          handleLoadMore={handleLoadMore}
          ListEmptyComponent={displayEmaptyComponent}
          renderItem={({ item }) => {
            const {
              _id: clientId,
              firstName = "",
              lastName = "",
              phone,
              email,
              isBlacklisted,
            } = item;
            const IS_SELECTED = clientId === client?._id;

            const formattedName = `${firstName} ${lastName}`.trim();

            const clientName = `${formattedName} (${phone})`;

            return (
              <TouchableOpacity
                // style={{ marginVertical: 8 }}
                onPress={() => onClientSelected(item)}
              >
                <Box
                  p="s"
                  minHeight={45}
                  borderBottomColor="lightGrey"
                  borderBottomWidth={LINE_THICKNESS}
                  width="100%"
                >
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box width="100%">
                      <CustomText
                        numberOfLines={1}
                        variant="content"
                        color="primaryTextColor"
                      >
                        {clientName}
                      </CustomText>

                      <Box
                        mt="xs"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CustomText variant="content" color="lightGrey">
                          {email ? email : "@"}
                        </CustomText>
                        {isBlacklisted && (
                          <Box p="xs" borderRadius="icon" backgroundColor="redBackground">
                            <CustomText variant="content" color="danger">
                              blacklisté
                            </CustomText>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {IS_SELECTED && (
                      <VALIDATE height={15} width={15} fill={PALETTE.green} />
                    )}
                  </Box>
                </Box>
                {/* <ClientCard isSelected={IS_SELECTED} client={item} /> */}
              </TouchableOpacity>
            );
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Box flex={IS_WEB ? 1 : undefined} backgroundColor="white">
        <Box mt="s">
          <Box justifyContent="center" alignItems="center">
            <CustomText variant="label" color="primaryTextColor">
              Base de données
            </CustomText>
            {/* <CustomText variant="content" color="lightGrey">
            2590
          </CustomText> */}
          </Box>

          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Rechercher un client"
              onChangeText={setSearchQuery}
              clearButtonMode="while-editing"
              autoFocus
            />
          </Box>

          {displayContent()}
        </Box>
      </Box>
      {goBack && (
        <Box
          position="absolute"
          p="s"
          pb="xl"
          bottom={0}
          left={0}
          right={0}
          backgroundColor="white"
          borderTopColor="lightGrey"
          borderTopWidth={1}
        >
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={goBack}
            overrideStyles={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box ml="s">
              <LEFT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Box>

            <CustomText variant="primaryButtonText" color="success">
              Retourner fiche client
            </CustomText>

            <Box />
          </CustomButton>
        </Box>
      )}
    </>
  );
};

export default ClientList;
