 
import ARRIVED_WAITING_LIST from "../../../../assets/icons/BASE/ARRIVED_WAITING_LIST.svg";
import CANCEL from "../../../../assets/icons/BASE/CANCEL.svg";
import EDIT from "../../../../assets/icons/BASE/EDIT.svg";
import LOCATION from "../../../../assets/icons/BASE/LOCATION.svg";
import MASK from "../../../../assets/icons/BASE/MASK.svg";
import PENDING from "../../../../assets/icons/BASE/PENDING.svg";
import REFUNDED from "../../../../assets/icons/BASE/REFUNDED.svg";
import SEATED from "../../../../assets/icons/BASE/SEATED.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import VALIDATION from "../../../../assets/icons/BASE/VALIDATION.svg";
import WAITING_LIST from "../../../../assets/icons/BASE/WAITING_LIST.svg";
import { Booking_Status } from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import type { PALETTE_COLORS } from "../../../theme/Palette";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

export enum BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE {
  ICON,
  TEXT,
}
interface BookingListCardStatusButtonItemProps {
  status: Booking_Status;
  type?: BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE;
}

const ICON_SIZE = 15;

const BookingListCardStatusButtonItem = ({
  type = BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON,
  status,
}: BookingListCardStatusButtonItemProps) => {
  const determineCorrectStatusColor = (): {
    borderColor: THEME_COLORS;
    iconColor: PALETTE_COLORS;
  } => {
    switch (status) {
      case Booking_Status.New:
        return {
          borderColor: "BOOKING_NEW_BACKGROUND",
          iconColor: "BOOKING_NEW",
        };
      case Booking_Status.WaitingList:
        return {
          borderColor: "BOOKING_WAITING_LIST_BACKGROUND",
          iconColor: "BOOKING_WAITING_LIST",
        };
      case Booking_Status.ModifiedByUser:
        return {
          borderColor: "BOOKING_MODIFIED_BACKGROUND",
          iconColor: "BOOKING_MODIFIED",
        };
      case Booking_Status.ModifiedByOwner:
        return {
          borderColor: "BOOKING_MODIFIED_BACKGROUND",
          iconColor: "BOOKING_MODIFIED",
        };
      case Booking_Status.Validated:
        return {
          borderColor: "BOOKING_CONFIRMED_BACKGROUND",
          iconColor: "BOOKING_CONFIRMED",
        };
      case Booking_Status.AutomaticValidated:
        return {
          borderColor: "BOOKING_CONFIRMED_BACKGROUND",
          iconColor: "BOOKING_CONFIRMED",
        };
      case Booking_Status.Arrived:
        return {
          borderColor: "BOOKING_ARRIVED_BACKGROUND",
          iconColor: "BOOKING_ARRIVED",
        };
      case Booking_Status.ArrivedWaiting:
        return {
          borderColor: "BOOKING_ARRIVED_WAITING_LIST_BACKGROUND",
          iconColor: "BOOKING_ARRIVED_WAITING_LIST",
        };
      case Booking_Status.Seated:
        return {
          borderColor: "BOOKING_SEATED_BACKGROUND",
          iconColor: "BOOKING_SEATED",
        };
      case Booking_Status.Ended:
        return {
          borderColor: "BOOKING_ENDED_BACKGROUND",
          iconColor: "BOOKING_ENDED",
        };
      case Booking_Status.Noshow:
        return {
          borderColor: "BOOKING_NO_SHOW_BACKGROUND",
          iconColor: "BOOKING_NO_SHOW",
        };
      case Booking_Status.CancelledByUser:
        return {
          borderColor: "BOOKING_CANCELLED_BACKGROUND",
          iconColor: "BOOKING_CANCELLED",
        };
      case Booking_Status.CancelledByOwner:
        return {
          borderColor: "BOOKING_CANCELLED_BACKGROUND",
          iconColor: "BOOKING_CANCELLED",
        };
      case Booking_Status.Refunded:
        return {
          borderColor: "BOOKING_REFUNDED_BACKGROUND",
          iconColor: "BOOKING_REFUNDED",
        };
      case Booking_Status.Refunded:
        return {
          borderColor: "BOOKING_REFUNDED_BACKGROUND",
          iconColor: "BOOKING_REFUNDED",
        };
    }
    return { borderColor: "greenBackground", iconColor: "green" };
  };

  const determineCorrectStatus = (): JSX.Element => {
    const color = determineCorrectStatusColor().iconColor;
    switch (status) {
      case Booking_Status.New:
        return <PENDING fill={PALETTE[color]} />;
      case Booking_Status.WaitingList:
        return <WAITING_LIST fill={PALETTE[color]} />;
      case Booking_Status.ModifiedByOwner:
        return <EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Booking_Status.ModifiedByUser:
        return <EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Booking_Status.Validated:
        return <VALIDATE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Booking_Status.AutomaticValidated:
        return <VALIDATE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Booking_Status.CancelledByOwner:
        return <CANCEL fill={PALETTE[color]} />;
      case Booking_Status.CancelledByUser:
        return <CANCEL fill={PALETTE[color]} />;
      case Booking_Status.Arrived:
        return <LOCATION fill={PALETTE[color]} />;
      case Booking_Status.ArrivedWaiting:
        return <ARRIVED_WAITING_LIST fill={PALETTE[color]} />;
      case Booking_Status.Seated:
        return <SEATED fill={PALETTE[color]} />;
      case Booking_Status.Noshow:
        return <MASK height={20} width={20} fill={PALETTE[color]} />;
      case Booking_Status.Ended:
        return <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE[color]} />;
      case Booking_Status.Refunded:
        return <REFUNDED height={20} width={20} fill={PALETTE[color]} />;
    }
    return <></>;
  };

  const determineCorrectStatusText = (): string => {
    switch (status) {
      case Booking_Status.New:
        return "Nouvelle";

      case Booking_Status.WaitingList:
        return "List d'attente";
      case Booking_Status.ModifiedByUser:
        return "Modifié";

      case Booking_Status.ModifiedByOwner:
        return "Modifié";

      case Booking_Status.Validated:
        return "Validé";

      case Booking_Status.AutomaticValidated:
        return "Validé";

      // case Booking_Status.Reconfimed:
      //   return "Reconfirmé";

      case Booking_Status.Arrived:
        return "Arrivé";

      case Booking_Status.ArrivedWaiting:
        return "Arri. en attente";
      case Booking_Status.Seated:
        return "Assis";
      case Booking_Status.Ended:
        return "Terminé";
      case Booking_Status.Noshow:
        return "No show";
      case Booking_Status.CancelledByOwner:
        return "Annulé par vous";
      case Booking_Status.CancelledByUser:
        return "Annulé par le client";
      case Booking_Status.Refunded:
        return "Remboursé";

      default:
        return "-";
    }
  };

  return (
    <Box
      p="s"
      backgroundColor={determineCorrectStatusColor().borderColor}
      borderRadius="modal"
      flexDirection="row"
      alignItems="center"
      justifyContent={
        type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON ? "center" : undefined
      }
      minHeight={35}
      minWidth={35}
    >
      {determineCorrectStatus()}
      {type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT && (
        <CustomText
          ml="s"
          variant="content"
          style={{
            color: PALETTE[determineCorrectStatusColor().iconColor],
          }}
        >
          {determineCorrectStatusText()}
        </CustomText>
      )}
    </Box>
  );
};

export default BookingListCardStatusButtonItem;
