import { useState } from "react";
import { TouchableOpacity } from "react-native";

import DOWN_ARROW from "../../../assets/icons/BASE/DOWN_CHEVRON.svg";
import type { THEME_COLORS } from "../../theme";
import { LINE_THICKNESS } from "../../theme";
import type { PALETTE_COLORS } from "../../theme/Palette";
import { PALETTE } from "../../theme/Palette";
import type { TEXT_VARIANTS } from "../../theme/variants/TextVariants";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import type { BUTTON_ACTION } from "../BottomButtomWithActions";
import ButtonActionsListModal from "../BottomButtomWithActions/ButtonActionsListModal";
import ContentModal from "../ContentModal";
interface ButtonWithActionsProps {
  title: string;
  actions: BUTTON_ACTION[];
  onExtraActionButtonPress: (key: string) => void;
  onMainButtonPress: () => void;
  backgroundColor?: THEME_COLORS;
  iconColor?: PALETTE_COLORS;
  textColor?: THEME_COLORS;
  minHeight?: number;
  disabled?: boolean;
  textVariant?: TEXT_VARIANTS;
}

const ButtonWithActions = ({
  actions,
  onExtraActionButtonPress,
  title,
  backgroundColor = "white",
  textColor = "success",
  iconColor = "green",
  disabled = false,
  textVariant = "content",
  onMainButtonPress,
}: ButtonWithActionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnSubmit = (key: string) => {
    onExtraActionButtonPress(key);
    setIsModalOpen(false);
  };

  return (
    <>
      <TouchableOpacity disabled={disabled} onPress={onMainButtonPress}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={backgroundColor}
          borderRadius="button"
          borderColor={disabled ? "disabled" : "success"}
          borderWidth={LINE_THICKNESS}
          p="s"
          width="100%"
          style={{ minHeight: 45 }}
        >
          <CustomText
            variant={textVariant}
            color={disabled ? "disabled" : textColor}
            style={{ flex: 1 }}
          >
            {title}
          </CustomText>

          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box
              marginHorizontal="s"
              height="70%"
              minHeight={30}
              width={2}
              backgroundColor={disabled ? "disabled" : textColor}
            />

            <TouchableOpacity
              // style={{ flex: 0.2 }}
              disabled={disabled}
              onPress={() => setIsModalOpen(true)}
            >
              <Box mr="s" pr="s" alignItems="center" justifyContent="center">
                <DOWN_ARROW
                  // width={ICON_SIZE}
                  // height={ICON_SIZE}
                  stroke={disabled ? PALETTE.gray : PALETTE[iconColor]}
                />
              </Box>
            </TouchableOpacity>
          </Box>
        </Box>
      </TouchableOpacity>

      <ContentModal
        title=""
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        styles={{
          maxWidth: 400,
          height: "auto",
        }}
      >
        <ButtonActionsListModal actions={actions} onPress={handleOnSubmit} />
      </ContentModal>
    </>
  );
};

export default ButtonWithActions;
