import { Dimensions } from "react-native";
import type { PanGestureHandlerGestureEvent } from "react-native-gesture-handler";
import { PanGestureHandler } from "react-native-gesture-handler";
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { clamp } from "react-native-redash";

import {
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
  BOOKING_FLOOR_HEADER_HEIGHT,
  BOOKING_FLOOR_TABLE_BASE_SIZE,
  BOOKING_FLOOR_TABLE_MAX_SCALE,
  BOOKING_FLOOR_TABLE_MIN_SCALE,
  TABLE_SIZE,
} from "../../../constants/BookingFloor";
import type { DecorationFragment } from "../../../graphql/generated/schema";
import { HEADER_HEIGHT } from "../../Header/index";
import Touchable from "../../Touchable/index";
import Decoration from "../Decoration/index";

const { width, height: SCREEN_HEIGHT } = Dimensions.get("window");

interface AnimatedDecorationProps {
  decoration: DecorationFragment;
  updateDecoration: (id: string, decoration: DecorationFragment) => void;
  disabledMovement?: boolean;
  selectDecoration: (id: string) => void;
  isSplittable?: boolean;
  isSelected?: boolean;
  floorHeight: number;
  floorWidth: number;
}

const AnimatedDecoration = ({
  decoration,
  updateDecoration,
  disabledMovement,
  selectDecoration,
  isSplittable,
  isSelected,
  floorHeight,
  floorWidth,
}: AnimatedDecorationProps) => {
  const SCREEN_WIDTH = isSplittable ? width * 0.7 : width;

  const FLOOR_AVAILABLE_WIDTH = SCREEN_WIDTH - TABLE_SIZE;

  const FLOOR_AVAILABLE_HEIGHT =
    SCREEN_HEIGHT -
    HEADER_HEIGHT -
    BOOKING_FLOOR_HEADER_HEIGHT -
    BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT -
    TABLE_SIZE -
    20;

  const LOWER_BOUND_X = 0;
  const LOWER_BOUND_Y = 0;

  const UPPER_BOUND_X = FLOOR_AVAILABLE_WIDTH;
  const UPPER_BOUND_Y = FLOOR_AVAILABLE_HEIGHT;

  const translateX = useSharedValue(
    clamp(decoration?.positionX, LOWER_BOUND_X, UPPER_BOUND_X),
  );
  const translateY = useSharedValue(
    clamp(decoration?.positionY, LOWER_BOUND_Y, UPPER_BOUND_Y),
  );

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    {
      offsetX: number;
      offsetY: number;
      positionX: number;
      positionY: number;
    }
  >({
    onStart: (_, ctx) => {
      ctx.offsetX = translateX.value;
      ctx.offsetY = translateY.value;
    },
    onActive: (event, ctx) => {
      const x = event.translationX;
      const y = event.translationY;

      const xValue = clamp(ctx.offsetX + x, LOWER_BOUND_X, UPPER_BOUND_X);
      const yValue = clamp(ctx.offsetY + y, LOWER_BOUND_Y, UPPER_BOUND_Y);

      translateX.value = xValue;
      translateY.value = yValue;
    },
    onEnd: (event, ctx) => {
      const positionX = Math.round(translateX.value);
      const positionY = Math.round(translateY.value);

      runOnJS(updateDecoration)(decoration._id, {
        ...decoration,
        positionX,
        positionY,
      });
    },
  });

  // console.log("pos", table.tableNumber, table?.positionY, table?.positionX);
  // console.log("pos translated", table.tableNumber, translateY.value, translateX.value);

  // console.log("floor", floorHeight, floorWidth);
  // console.log("scrren widtg", FLOOR_AVAILABLE_HEIGHT, SCREEN_WIDTH);

  const animatedStyles = useAnimatedStyle(() => {
    // const rotationStopped = `${item?.rotationDeg}deg`;

    return {
      transform: [{ translateX: translateX.value }, { translateY: translateY.value }],
    };
  });

  const handleTabeleScaleBoundaryCheck = (s: number) => {
    if (s < BOOKING_FLOOR_TABLE_MIN_SCALE) {
      return BOOKING_FLOOR_TABLE_MIN_SCALE;
    }

    if (s > BOOKING_FLOOR_TABLE_MAX_SCALE) {
      return BOOKING_FLOOR_TABLE_MAX_SCALE;
    }

    return s;
  };

  const getScale = () => {
    const xScale = handleTabeleScaleBoundaryCheck(SCREEN_WIDTH / floorWidth);
    const yScale = handleTabeleScaleBoundaryCheck(FLOOR_AVAILABLE_HEIGHT / floorHeight);

    return Math.max(xScale, yScale);
  };
  const scale = getScale();

  return (
    <PanGestureHandler enabled={!disabledMovement} {...{ onGestureEvent }}>
      <Animated.View
        style={[
          animatedStyles,
          {
            width: BOOKING_FLOOR_TABLE_BASE_SIZE,
            height: BOOKING_FLOOR_TABLE_BASE_SIZE,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          },
        ]}
      >
        <Touchable
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: BOOKING_FLOOR_TABLE_BASE_SIZE,
            height: BOOKING_FLOOR_TABLE_BASE_SIZE,
          }}
          onPress={() => selectDecoration(decoration._id)}
        >
          <Decoration decoration={decoration} isSelected={isSelected} scale={scale} />
        </Touchable>
      </Animated.View>
    </PanGestureHandler>
  );
};

export default AnimatedDecoration;
