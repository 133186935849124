 

import React from "react";

import Box from "../../../../../components/Base/Box";
import Table from "../../../../../components/BookingFloor/Table";
import { MODULE_TYPE } from "../../../../../components/ModuleList/common";
import type { TableFragment } from "../../../../../graphql/generated/schema";
import { Table_Form } from "../../../../../graphql/generated/schema";
import { BOOKINGS } from "../../BookingListSettings/BookingListingSettingsPreview/BookingListSettingsPreviewData";

interface BookingFloorSettingsPreviewProps {
  showClientName: boolean;
  showVIP: boolean;
}

const TABLES_PREVIEW_DATA: TableFragment[] = [
  {
    _id: "TABLE_1",
    minCapacity: 4,
    maxCapacity: 4,
    tableNumber: "1",
    shape: Table_Form.Square,
    averageDurationInMinutes: 0,
    isEligibleForBooking: false,
    isOrderAtTableEnabled: false,
    isPaymentAtTableEnabled: false,
    positionX: 0,
    positionY: 0,
    rotationDeg: 0,
    bookings: [BOOKINGS[0]],
  },
  {
    _id: "TABLE_2",
    minCapacity: 8,
    maxCapacity: 4,
    tableNumber: "2",
    shape: Table_Form.Rectangle,
    averageDurationInMinutes: 0,
    isEligibleForBooking: false,
    isOrderAtTableEnabled: false,
    isPaymentAtTableEnabled: false,
    positionX: 0,
    positionY: 0,
    rotationDeg: 0,
    bookings: [BOOKINGS[1]],
  },
  {
    _id: "TABLE_3",
    minCapacity: 2,
    maxCapacity: 4,
    tableNumber: "3",
    shape: Table_Form.Circle,
    averageDurationInMinutes: 0,
    isEligibleForBooking: false,
    isOrderAtTableEnabled: false,
    isPaymentAtTableEnabled: false,
    positionX: 0,
    positionY: 0,
    rotationDeg: 0,
    bookings: [BOOKINGS[2]],
  },
];

const BookingFloorSettingsPreview = ({
  showClientName,
  showVIP,
}: BookingFloorSettingsPreviewProps) => {
  return (
    <Box
      paddingHorizontal="s"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="white"
      borderColor="lightGrey"
      borderWidth={1}
      height={200}
    >
      {TABLES_PREVIEW_DATA.map((table, idx) => (
        <Box key={table._id}>
          <Table
            moduleType={MODULE_TYPE.BOOKING}
            table={table}
            isSelected={false}
            showClientName={showClientName}
            showVIP={showVIP}
          />
        </Box>
      ))}
    </Box>
  );
};

export default BookingFloorSettingsPreview;
