import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import * as yup from "yup";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../components/TextInput";
import Toggle from "../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  CreateUpdateMerchantPersonnelPermissionsGroupInput,
  MerchantPersonnelFragment,
  MerchantPersonnelPermissionsGroups,
  Pagination,
} from "../../../../graphql/generated/schema";
import {
  GetMerchantPersonnelPermissionGroupsDocument,
  Modules,
  PermissionLevel,
  useCreateMerchantPersonnelPermissionGroupMutation,
  useDeleteMerchantPersonnelPermissionGroupMutation,
  useGetMerchantPersonnelPermissionGroupLazyQuery,
  useGetMerchantPersonnelsLazyQuery,
  useUpdateMerchantPersonnelPermissionGroupMutation,
} from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import type { ERROR } from "../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../utils/common";

interface PermissionsGroupListDetailsProps {
  onGoBack?: () => void;
  isNew?: boolean;
  id?: string;
}

type PermissionGroup = Omit<
  MerchantPersonnelPermissionsGroups,
  "_id" | "createdAt" | "updatedAt" | "merchantId" | "isDeleted"
>;

const DEFAULT_PERMISSIONS_GROUP: PermissionGroup = {
  name: "",
  permissions: [],
  staffIds: [],
};

const schema = yup.object().shape({
  name: yup.string().required(),
  permissions: yup.array().of(
    yup.object().shape({
      module: yup.string().required(),
      permissionLevel: yup.string().required(),
    }),
  ),
});

const PermissionsGroupListDetails = ({
  onGoBack,
  isNew: isNewGroup,
  id: permissionGroupId,
}: PermissionsGroupListDetailsProps) => {
  const { params } = useRoute();
  const isNew = params?.isNew || isNewGroup;
  const id = params?.id || permissionGroupId;
  const [loading, setLoading] = useState(!isNew);
  const errorInfoSuccessAlertContext = useContext(ErrorInfoSuccessAlertModalContext);

  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [users, setUsers] = useState<MerchantPersonnelFragment[]>([]);
  const [permissionGroup, setpermissionGroup] = useState<PermissionGroup | null>(null);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const navigation = useNavigation();

  const [getPermissionGroup] = useGetMerchantPersonnelPermissionGroupLazyQuery();
  const [getStaff] = useGetMerchantPersonnelsLazyQuery();
  const [deletePermissionGroup] = useDeleteMerchantPersonnelPermissionGroupMutation();
  const [createPermissionGroup] = useCreateMerchantPersonnelPermissionGroupMutation();
  const [updatePermissionGroup] = useUpdateMerchantPersonnelPermissionGroupMutation();

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      navigation.goBack();
    }
  };
  const handleDeletePermissionGroup = async () => {
    try {
      await deletePermissionGroup({
        variables: {
          permissionGroupId: id,
        },
        // refetchQueries: [
        //   {
        //     query: GetMerchantPersonnelPermissionGroupsDocument,
        //     variables: { pagination },
        //   },
        // ],
        update: cache => {
          const normalizedId = cache.identify({
            _id: id,
            __typename: "MerchantPersonnelPermissionsGroups",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      handleGoBack();
    } catch (err) {
      console.log("err delete group", err);
    }
  };

  const validateForm = async () => {
    try {
      await schema.validate(permissionGroup, { abortEarly: false });

      return true;
    } catch (err) {
      const formattedErrors = formaYupErrors(err);
      setErrors(formattedErrors);

      errorInfoSuccessAlertContext.openAlert(
        "Erreur dans le formulaire",
        formattedErrors,
        "error",
      );
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      if (isNew) {
        if (permissionGroup) {
          await createPermissionGroup({
            variables: {
              permissionGroup,
            },
            refetchQueries: [
              {
                query: GetMerchantPersonnelPermissionGroupsDocument,
                variables: { pagination },
              },
            ],
          });
        }
      } else {
        if (permissionGroup) {
          const { name, permissions, staffIds } = permissionGroup;

          let updates: CreateUpdateMerchantPersonnelPermissionsGroupInput = {
            name,
            permissions,
            staffIds,
          };

          updates = removeTypeNames(updates);
          delete updates._id;

          await updatePermissionGroup({
            variables: {
              permissionGroupId: id,
              permissionGroup: updates,
            },
            refetchQueries: [
              {
                query: GetMerchantPersonnelPermissionGroupsDocument,
                variables: { pagination },
              },
            ],
          });
        }
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err);
      const formattedErrors = [{ path: "submit", code: "submit", message: err.message }];

      errorInfoSuccessAlertContext.openAlert(
        "Erreur dans le formulaire",
        formattedErrors,
        "error",
      );
    }
  };

  const handleGetPermissionGroup = async () => {
    try {
      const group = await getPermissionGroup({
        variables: {
          id,
        },
      });

      if (group.data) {
        setpermissionGroup(
          JSON.parse(JSON.stringify(group.data.getMerchantPersonnelPermissionGroup)),
        );
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleGetStaff = async () => {
    try {
      const staff = await getStaff({
        variables: { pagination },
      });

      if (staff.data) {
        setUsers(staff.data.getMerchantPersonnels);
      }
    } catch (err) {
      console.log("err get staff", err);
    }
  };

  useEffect(() => {
    handleGetStaff();
  }, []);

  useEffect(() => {
    if (!isNew) {
      handleGetPermissionGroup();
    } else {
      setpermissionGroup(DEFAULT_PERMISSIONS_GROUP);
    }
  }, []);

  const displayTitle = () => {
    if (isNew) {
      return "Ajout du groupe de permissions";
    }
    return "Modification du groupe de permissions";
  };

  const handleUpdatePermissionGroupData = (
    key: keyof PermissionGroup,
    value: string | string[],
  ) => {
    setpermissionGroup(v => {
      if (v) {
        return {
          ...v,
          [key]: value,
        };
      }
      return null;
    });
  };

  const handleToggleModule = (module: Modules, value: boolean) => {
    const permissionLevel = value ? PermissionLevel.Execute : PermissionLevel.Disabled;

    setpermissionGroup(v => {
      if (v) {
        const permissions = v?.permissions ? [...v?.permissions] : [];

        const hasPermission = permissions.find(p => p.module === module);
        let newPermissions = [];

        if (hasPermission) {
          newPermissions = permissions.map(p => {
            const newPermission = p;
            if (p.module === module) {
              newPermission.permissionLevel = permissionLevel;
            }
            return newPermission;
          });
        } else {
          newPermissions = [...permissions, { module, permissionLevel }];
        }

        return {
          ...v,
          permissions: newPermissions,
        };
      }
      return null;
    });
  };

  const handleFormatStaffList = () => {
    if (users) {
      return users.map(u => ({ label: `${u.firstName} ${u.lastName}`, key: u._id }));
    }
    return [];
  };

  const doesInputHaveError = (key: keyof PermissionGroup) => {
    return errors.some(err => err.path === key);
  };

  const isModuleActive = (module: Modules) => {
    const selectedModule = permissionGroup?.permissions.find(p => p.module === module);

    return selectedModule?.permissionLevel === PermissionLevel.Execute;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader title={displayTitle()} hasBackButton onBackPress={handleGoBack} />
      </Box>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150 }}
      >
        <Box mt="m" paddingHorizontal="s">
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Nom du groupe"
              hasErrors={doesInputHaveError("name")}
              isRequired
              initialValue={permissionGroup?.name}
              onChangeText={name => handleUpdatePermissionGroupData("name", name)}
            />
          </Box>
          <Box marginVertical="s">
            <SelectButton
              isMultiSelect
              options={handleFormatStaffList()}
              selectedOptions={permissionGroup?.staffIds ? permissionGroup?.staffIds : []}
              onPress={staffIds => handleUpdatePermissionGroupData("staffIds", staffIds)}
              placeHolder="Utilisateurs"
            />
          </Box>
          <Box
            marginVertical="s"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            pb="s"
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                CAISSE
              </CustomText>
              <Toggle
                value={isModuleActive(Modules.CashRegister)}
                onChange={v => handleToggleModule(Modules.CashRegister, v)}
              />
            </Box>
            <CustomText variant="content" color="lightGrey">
              Donne accès à la partie caisse enregistreuse.
            </CustomText>
          </Box>
          <Box
            marginVertical="s"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            pb="s"
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                RÉSERVATION
              </CustomText>
              <Toggle
                value={isModuleActive(Modules.Reservation)}
                onChange={v => handleToggleModule(Modules.Reservation, v)}
              />
            </Box>
            <CustomText variant="content" color="lightGrey">
              Donne accès à la partie Réservation. Réservation en ligne, Bouton Google
              etc...
            </CustomText>
          </Box>
          <Box
            marginVertical="s"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            pb="s"
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                CLICK AND COLLECT
              </CustomText>
              <Toggle
                value={isModuleActive(Modules.OnlineSales)}
                onChange={v => handleToggleModule(Modules.OnlineSales, v)}
              />
            </Box>
            <CustomText variant="content" color="lightGrey">
              Donne accès à la gestion de la commande en ligne pour vous et vos clients.
            </CustomText>
          </Box>
          <Box
            marginVertical="s"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            pb="s"
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                AVIS
              </CustomText>
              <Toggle
                value={isModuleActive(Modules.ReviewResponse)}
                onChange={v => handleToggleModule(Modules.ReviewResponse, v)}
              />
            </Box>
            <CustomText variant="content" color="lightGrey">
              Donne accès à la gestion de vos avis avec la possibilité de répondre aux
              avis laissés par vos clients.
            </CustomText>
          </Box>
          <Box
            marginVertical="s"
            borderBottomColor="lightGrey"
            borderBottomWidth={LINE_THICKNESS}
            pb="s"
          >
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText variant="label" color="primaryTextColor">
                PUBLICATION
              </CustomText>
              <Toggle
                value={isModuleActive(Modules.Publishing)}
                onChange={v => handleToggleModule(Modules.Publishing, v)}
              />
            </Box>
            <CustomText variant="content" color="lightGrey">
              Donne accès à la gestion de vos avis avec la possibilité de répondre aux
              avis laissés par vos clients.
            </CustomText>
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="danger"
            onPress={handleDeletePermissionGroup}
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="success"
          onPress={handleSubmit}
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
      {/* <SnackBarInfo
        isOpen={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
        type="error"
      >
        {errors.map(error => (
          <Box key={error.path}>
            <CustomText variant="content" color="danger">
              {error.message}
            </CustomText>
          </Box>
        ))}
      </SnackBarInfo> */}
    </Box>
  );
};

export default PermissionsGroupListDetails;
