import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";

import INFO from "../../../../../../assets/icons/BASE/INFO.svg";
import MAIL from "../../../../../../assets/icons/BASE/MAIL.svg";
import PASSWORD_STAR from "../../../../../../assets/icons/BASE/PASSWORD_STAR.svg";
import PHONE from "../../../../../../assets/icons/BASE/PHONE.svg";
import USER_ADMIN from "../../../../../../assets/icons/BASE/USER_ADMIN.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import TabTextInfo from "../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { MerchantPersonnelFragment } from "../../../../../graphql/generated/schema";
import { useGetMerchantPersonnelQuery } from "../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../utils/common";

interface StaffListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;
const ICON_SIZE = 20;

const StaffListDetailsView = ({ id, goBack }: StaffListDetailsViewProps) => {
  const navigation = useNavigation();
  const [merchantPersonnel, setMerchantPersonnel] =
    useState<MerchantPersonnelFragment | null>(null);

  const { loading, error, data } = useGetMerchantPersonnelQuery({
    variables: {
      merchantPersonnelId: id,
    },
  });

  useEffect(() => {
    if (data?.getMerchantPersonnel) {
      setMerchantPersonnel(data.getMerchantPersonnel);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          error...
        </CustomText>
      </Box>
    );
  }

  const displayName = () => {
    if (merchantPersonnel) {
      const { firstName = "", lastName = "" } = merchantPersonnel;
      return `${lastName} ${firstName}`;
    }
    return "";
  };

  const displayProfit = () => {
    const profit = merchantPersonnel?.generatedProfitForMerchant || 0;

    return formatCurrencyPrice(profit, "fr", "eur");
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Box>
        <Box marginVertical="s">
          <ScreenHeader
            rightButtons={
              merchantPersonnel?.picture ? (
                <Image
                  source={{
                    uri: merchantPersonnel?.picture,
                  }}
                  style={{
                    width: IMAGE_SIZE,
                    height: IMAGE_SIZE,
                    borderRadius: IMAGE_SIZE / 2,
                  }}
                />
              ) : (
                <></>
              )
            }
            title={displayName()}
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>

        <Box flexDirection="row">
          <Box
            backgroundColor="greenBackground"
            padding="m"
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
          >
            <CustomText variant="label" color="success">
              {displayProfit()}
            </CustomText>
            <CustomText variant="content" color="success">
              C.A Généré
            </CustomText>
          </Box>
        </Box>

        {merchantPersonnel?.jobTitle && (
          <Box>
            <TabTextInfo
              title="Description de l'utilisateur"
              content={merchantPersonnel?.jobTitle}
              icon={
                <INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
              }
            />
          </Box>
        )}

        {merchantPersonnel?.phone && (
          <Box>
            <TabTextInfo
              title="Téléphone"
              content={merchantPersonnel?.phone}
              icon={
                <PHONE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
              }
            />
          </Box>
        )}

        {merchantPersonnel?.email && (
          <Box>
            <TabTextInfo
              title="Mail"
              content={merchantPersonnel?.email}
              icon={
                <MAIL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
              }
            />
          </Box>
        )}

        <Box>
          <TabTextInfo
            title="Mot de passe"
            content="*********"
            icon={
              <PASSWORD_STAR
                width={ICON_SIZE}
                height={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            }
          />
        </Box>
        <Box>
          <TabTextInfo
            title="Adminstrateur"
            content={merchantPersonnel?.isAdministrator ? "Oui" : "Non"}
            icon={
              <USER_ADMIN width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
            }
          />
        </Box>

        {merchantPersonnel?.permissionGroup?.name && (
          <Box>
            <TabTextInfo
              title="Groupe"
              content={merchantPersonnel?.permissionGroup?.name}
              icon={
                <INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.lightGrey} />
              }
            />
          </Box>
        )}
      </Box>
    </ScrollView>
  );
};

export default StaffListDetailsView;
