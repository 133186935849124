/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-shadow */
import RNDateTimePicker from "@react-native-community/datetimepicker";
import { useNavigation } from "@react-navigation/native";
import { addMinutes, format, setHours, setMinutes } from "date-fns";
import { useContext, useState } from "react";
import { Dimensions, Image, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import BOOKING_FLOOR from "../../../../assets/icons/BASE/BOOKING_FLOOR.svg";
import CALENDAR from "../../../../assets/icons/BASE/CALENDAR.svg";
import LEFT_ARROW from "../../../../assets/icons/BASE/LEFT_ARROW.svg";
import PAX_PERSONS from "../../../../assets/icons/BASE/PAX_PERSONS.svg";
import RECEIPT from "../../../../assets/icons/BASE/RECEIPT.svg";
import TIME from "../../../../assets/icons/BASE/TIME.svg";
import { SPLIT_VIEW_TYPE, SplitViewContext } from "../../../contexts/SpitViewContext";
import type {
  MerchantClientFragment,
  SelectedBookingTableFragment,
} from "../../../graphql/generated/schema";
import {
  Booking_Status,
  Click_Status,
  Modules,
  Origin,
} from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import { displayModuleId, displayTables } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingListCardStatusButton from "../../BookingList/BookingListCardStatusButton";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../BookingList/BookingListCardStatusButtonItem";
import BookingListDetailsViewBookingTab from "../../BookingListDetails/BookingListDetailsView/Tabs/Booking";
import BookingListDetailsViewClientTab from "../../BookingListDetails/BookingListDetailsView/Tabs/Client";
import BookingListDetailsViewTimelineTab from "../../BookingListDetails/BookingListDetailsView/Tabs/Timeline";
import { CustomButton } from "../../Button";
import type { ORDER_TAB_VIEW } from "../../CashRegister/OrderTab";
import ContentModal from "../../ContentModal";
import CustomCalendar, { CALENDAR_VIEW } from "../../CustomCalendar";
import KeyboardAwareScrollView from "../../KeyboardAwareScrollView";
import NewBookingPersonsSelection from "../../NewBooking/PersonsSelection";
import NewBookingTimeSelection from "../../NewBooking/TimeSelection";
import OnlineOrderDetailsTab from "../../OnlinOrdersListDetails/Tabs/Order";
import OnlineOrderStatusButton from "../../OnlineOrdersList/OnlineOrderStatusButton";
import { ONLINE_STATUS_LAYOUT_TYPE } from "../../OnlineOrdersList/OnlineOrderStatusButtonItem";
import type { TAB_KEYS } from "../../OutlineTabs";
import OutlineTabs from "../../OutlineTabs";

interface ModulListDetailsProps {
  moduleType: Modules;
  tabNames: {
    title: string;
    key: TAB_KEYS;
  }[];
  services: any;
  inEditMode: boolean;
  setInEditMode: (inEditMode: boolean) => void;
  bottomButton: JSX.Element;
  data: any;
  updateData: (data: any) => void;
  selectedTab: TAB_KEYS;
  setSelectedTab: (selectedTab: TAB_KEYS) => void;
  orderLayoutView?: ORDER_TAB_VIEW;
  onExtraTabPress?: (ticketId: string) => void;
  goBack?: () => void;
  showGoBack?: boolean;
  undoLastStatusChange?: (status: Booking_Status) => void;
}

const IMAGE_HEIGHT_WIDTH = 30;
const ICON_LIST_ACTION = 15;

// const screenWidth = Dimensions.get("window").width;

// const EXTRA_TAB_WIDTH = 40;

enum MODAL_TYPE {
  DATE,
  TIME,
  DELAY,
  PERSONS,
}

const ModulListDetails = ({
  moduleType,
  tabNames,
  bottomButton,
  data,
  services,
  updateData,
  inEditMode,
  setInEditMode,
  onExtraTabPress,
  selectedTab,
  setSelectedTab,
  orderLayoutView,
  goBack,
  undoLastStatusChange,
  showGoBack = true,
}: ModulListDetailsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  // const [selectedTab, setSelectedTab] = useState<TAB_KEYS>(tabNames[0].key);
  // const [inEditMode, setInEditMode] = useState(false);
  const { merchantClient: client, date, nbPersons, tables, serviceId, slotId } = data;

  const splitViewContext = useContext(SplitViewContext);

  const moduleDate = new Date(date);
  let screenWidth =
    splitViewContext.viewType === SPLIT_VIEW_TYPE.FULL_LEFT
      ? splitViewContext.screenContainer?.width
      : splitViewContext.rightContainer?.width;

  screenWidth = screenWidth || Dimensions.get("window").width;

  const TAB_WITH = screenWidth / tabNames.length;

  // const formattedClientName = !client
  //   ? "PASSAGE"
  //   : `${client?.lastName?.toUpperCase()} ${client?.firstName}`;
  const formattedDate = format(moduleDate, "E dd MMM");
  const formattedTime = format(moduleDate, "HH:mm");

  const [newDate, setNewDate] = useState(moduleDate);
  const [newNbPerson, setNewNbPerson] = useState(nbPersons);
  const [newTimeConfig, setNewTimeConfig] = useState({
    time: formattedTime,
    serviceId: serviceId,
    slotId: slotId,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState<MODAL_TYPE>(MODAL_TYPE.DATE);
  const [delayAmount, setDelayAmount] = useState(0);

  const displayClientName = () => {
    if (!client) return "PASSAGE";

    let suffix = "";

    if (client?.merchantCompanyId) {
      suffix = ` - ${client.merchantCompanyId.companyName}`;
    }

    const clientName = `${client.lastName} ${client.firstName}`;

    return `${clientName}${suffix}`;
  };
  const displayDate = () => {
    return {
      [format(newDate, "yyyy-MM-dd")]: {
        selected: true,
        marked: true,
        selectedColor: PALETTE.green,
      },
    };
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else if (moduleType === Modules.OnlineSales) {
      navigation.navigate("SALES", {
        screen: "ONLINE_SALES_LIST",
        // screen: "ONLINE_SALES_TAB",
        // params: {
        //   screen: "ONLINE_SALES_LIST",
        // },
      });
    } else if (moduleType === Modules.Reservation) {
      navigation.navigate("BOOKING", {
        screen: "BOOKINGS_LIST",
      });
    } else {
      navigation.goBack();
    }
  };

  // const displayTables = () => {
  //   const nbTables = tables.length;
  //   const [firstTable] = tables;
  //   const suffix = nbTables > 1 ? `(+${nbTables - 1})` : "";
  //   return `${firstTable?.tableNumber || "-"} ${suffix} `;
  // };

  const openModal = (type: MODAL_TYPE) => {
    setModalType(type);
    setIsModalVisible(true);
  };

  const openDelayModal = (amount: number) => {
    setDelayAmount(amount);
    openModal(MODAL_TYPE.DELAY);
  };

  const updateClient = (newClient: MerchantClientFragment) => {
    updateData({
      ...data,
      merchantClient: {
        ...client,
        ...newClient,
      },
    });
  };

  const handleTimeSelect = (serviceId: string, slotId: string, newTime: string) => {
    const [hour, minute] = newTime.split(":");

    const formattedDate = setMinutes(
      setHours(newDate, parseInt(hour, 10)),
      parseInt(minute, 10),
    ).toISOString();

    setNewTimeConfig(data => ({
      ...data,
      serviceId,
      slotId,
      date: formattedDate,
    }));

    updateData({
      ...data,
      serviceId,
      slotId,
      date: formattedDate,
    });

    setIsModalVisible(false);
  };

  const goToBookingFloor = () => {
    const tables = data.tables || [];

    navigation.navigate("BOOKING_FLOORS", {
      selectedTables: tables,
      selectedDate: newDate?.toISOString(),
      bookingPersons: newNbPerson,
      bookingFloorViewType: BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT,
      onSubmit: (selectedTables: SelectedBookingTableFragment[]) => {
        updateData({ ...data, tables: selectedTables });
        navigation.goBack();
      },
    });
  };

  const handleDateChange = (changedDate: Date) => {
    const time = format(moduleDate, "HH:mm");
    const [hour, minute] = time.split(":");

    const formattedDate = setMinutes(
      setHours(changedDate, parseInt(hour, 10)),
      parseInt(minute, 10),
    );

    setNewDate(formattedDate);
  };

  const handleStatusChange = (
    status: Booking_Status | Click_Status,
    refusalReason: string,
  ) => {
    updateData({ ...data, status, refusalReason });
  };

  const areTabButtonsDisabled = (tabName?: "date" | "time" | "persons" | "tables") => {
    const SHOULD_DISABLE_BTN_FOR_GOOGLE =
      moduleType === Modules.Reservation &&
      data.source === Origin.Google &&
      tabName === "date";

    const IS_BOOKING_OVER =
      (moduleType === Modules.Reservation &&
        data.status === Booking_Status.CancelledByOwner) ||
      data.status === Booking_Status.CancelledByUser ||
      data.status === Booking_Status.Noshow ||
      data.status === Booking_Status.Refunded ||
      data.status === Booking_Status.Ended;

    const IS_ONLINE_ORDER_OVER =
      (moduleType === Modules.OnlineSales &&
        data.status === Click_Status.CancelledByOwner) ||
      data.status === Click_Status.CancelledByUser ||
      data.status === Click_Status.Refunded ||
      data.status === Click_Status.Noshow ||
      data.status === Click_Status.Ended;

    return (
      !inEditMode ||
      IS_BOOKING_OVER ||
      IS_ONLINE_ORDER_OVER ||
      SHOULD_DISABLE_BTN_FOR_GOOGLE
    );
  };

  const displayTab = () => {
    switch (selectedTab) {
      case "BOOKING":
        return (
          <BookingListDetailsViewBookingTab
            updateData={updateData}
            data={data}
            isEditable={inEditMode}
          />
        );
      case "ONLINE_ORDER":
        return (
          <OnlineOrderDetailsTab
            updateData={updateData}
            data={data}
            isEditable={inEditMode}
          />
        );
      case "CLIENT":
        return (
          <Box>
            {data?.merchantClient && (
              <BookingListDetailsViewClientTab
                moduleType={moduleType}
                updateData={updateClient}
                data={data?.merchantClient}
                isEditable={inEditMode}
              />
            )}
          </Box>
        );

      case "TIMELINE":
        return (
          <BookingListDetailsViewTimelineTab
            moduleType={moduleType}
            timeline={data.timeline}
            data={data}
            undoLastStatusChange={undoLastStatusChange}
          />
        );

      default:
        break;
    }
  };

  const displayExtraTab = () => {
    if (moduleType === Modules.Reservation && data?.ticketId) {
      return (
        <TouchableOpacity onPress={() => onExtraTabPress?.(data?.ticktId)}>
          <Box
            ml="s"
            p="s"
            paddingHorizontal="m"
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            backgroundColor="disabled"
          >
            <RECEIPT width={18} height={18} fill={PALETTE.darkBlue} />

            <CustomText fontSize={10} variant="content">
              Ticket
            </CustomText>
          </Box>
        </TouchableOpacity>
      );
    }

    if (moduleType === Modules.OnlineSales && data?.receiptUrl) {
      return (
        <TouchableOpacity onPress={() => onExtraTabPress?.(data?.ticktId)}>
          <Box
            ml="s"
            p="s"
            paddingHorizontal="m"
            alignItems="center"
            justifyContent="center"
            borderRadius="button"
            backgroundColor="disabled"
          >
            <RECEIPT width={18} height={18} fill={PALETTE.darkBlue} />

            <CustomText fontSize={10} variant="content">
              Reçu
            </CustomText>
          </Box>
        </TouchableOpacity>
      );
    }
  };

  const getTabWidth = () => {
    let screenWidth =
      splitViewContext.viewType === SPLIT_VIEW_TYPE.FULL_LEFT
        ? splitViewContext.screenContainer?.width
        : splitViewContext.rightContainer?.width;

    screenWidth = screenWidth || Dimensions.get("window").width;

    const HAS_EXTRA_TAB = !!(
      (moduleType === Modules.Reservation && data?.ticketId) ||
      (moduleType === Modules.OnlineSales && data?.receiptUrl)
    );

    let TAB_WITH = screenWidth / (tabNames.length + (HAS_EXTRA_TAB ? 1 : 0));

    if (TAB_WITH > screenWidth) {
      TAB_WITH = screenWidth;
    }

    if (!HAS_EXTRA_TAB) {
      TAB_WITH -= 8;
    }

    return TAB_WITH;
  };

  const displayModal = () => {
    switch (modalType) {
      case MODAL_TYPE.DATE:
        return (
          <ContentModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            title="Proposer une autre date"
          >
            <Box>
              <CustomCalendar
                calendarView={CALENDAR_VIEW.MONTH}
                markingType="simple"
                markedDates={displayDate()}
                minDate={new Date()}
                onDateChange={handleDateChange}
              />
              <Box mt="m">
                <CustomButton
                  buttonVariant="primaryButton"
                  buttonColor="success"
                  onPress={() => {
                    updateData({ ...data, date: newDate });
                    setIsModalVisible(false);
                  }}
                >
                  <CustomText variant="primaryButtonText" color="white">
                    Valider
                  </CustomText>
                </CustomButton>
              </Box>
            </Box>
          </ContentModal>
        );

      case MODAL_TYPE.TIME:
        return (
          <ContentModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            title="Proposer un autre horaire"
          >
            <Box height="95%" pb="xl">
              <NewBookingTimeSelection
                setServiceId={serviceId =>
                  setNewTimeConfig(data => ({ ...data, serviceId }))
                }
                nbPersons={newNbPerson}
                selectedSlotId={newTimeConfig.slotId}
                selectedServiceId={newTimeConfig.serviceId}
                services={services}
                selectedDate={newDate}
                onSlotSelect={handleTimeSelect}
                isLoading={false}
              />
              {/* <Box mt="m">
                <CustomButton
                  buttonVariant="primaryButton"
                  buttonColor="success"
                  onPress={() => {
                    updateData({ ...data, ...newTimeConfig });

                    setIsModalVisible(false);
                  }}
                >
                  <CustomText variant="primaryButtonText" color="white">
                    Validerdd
                  </CustomText>
                </CustomButton>
              </Box> */}
            </Box>
          </ContentModal>
        );
      case MODAL_TYPE.PERSONS:
        return (
          <ContentModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            title="Modifier le nombre de personnes"
          >
            <Box>
              <NewBookingPersonsSelection
                onChange={setNewNbPerson}
                personsSelected={newNbPerson}
              />
              <Box mt="m">
                <CustomButton
                  buttonVariant="primaryButton"
                  buttonColor="success"
                  onPress={() => {
                    updateData({ ...data, nbPersons: newNbPerson });

                    setIsModalVisible(false);
                  }}
                  styles={{
                    minHeight: 45,
                  }}
                >
                  <CustomText variant="primaryButtonText" color="white">
                    Valider
                  </CustomText>
                </CustomButton>
              </Box>
            </Box>
          </ContentModal>
        );
      case MODAL_TYPE.DELAY:
        const formattedDelayDate = addMinutes(new Date(date), delayAmount);
        const minDate = new Date(date);
        const maxDate = addMinutes(new Date(date), 60);
        return (
          <ContentModal
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            title="Proposer un autre horaire"
          >
            <Box>
              <Box backgroundColor="danger">
                <RNDateTimePicker
                  value={formattedDelayDate}
                  is24Hour
                  mode="time"
                  minimumDate={minDate}
                  maximumDate={maxDate}
                  minuteInterval={5}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />
              </Box>
              <Box mt="m">
                <CustomButton
                  buttonVariant="primaryButton"
                  buttonColor="success"
                  onPress={() => {
                    updateData({ ...data });

                    setIsModalVisible(false);
                  }}
                  styles={{
                    minHeight: 45,
                  }}
                >
                  <CustomText variant="primaryButtonText" color="white">
                    Valider
                  </CustomText>
                </CustomButton>
              </Box>
            </Box>
          </ContentModal>
        );
      default:
        return null;
    }
  };

  return (
    <Box flex={1} pt="m" backgroundColor="white">
      <Box>
        {/* START HEADER */}

        <Box
          paddingHorizontal="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            {showGoBack && (
              <TouchableOpacity onPress={handleGoBack}>
                <LEFT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              </TouchableOpacity>
            )}

            <Box flexDirection="row" alignItems="center" pl={showGoBack ? "s" : "none"}>
              {client?.picture && (
                <Image
                  source={{
                    uri: client?.picture,
                  }}
                  style={{
                    width: IMAGE_HEIGHT_WIDTH,
                    height: IMAGE_HEIGHT_WIDTH,
                    borderRadius: IMAGE_HEIGHT_WIDTH / 2,
                  }}
                />
              )}

              <Box pl="s">
                <CustomText variant="label" color="primaryTextColor">
                  {displayClientName()}
                </CustomText>
                <CustomText variant="description" color="lightGrey">
                  {displayModuleId(data._id)}
                </CustomText>
              </Box>
            </Box>
          </Box>

          <Box>
            {moduleType === Modules.Reservation && (
              <BookingListCardStatusButton
                type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
                status={data.status}
                onPress={handleStatusChange}
                isDisabled={!inEditMode}
                prepaidPayment={data.prepaidPayment}
                payment={data.payment}
              />
            )}

            {moduleType === Modules.OnlineSales && (
              <OnlineOrderStatusButton
                type={ONLINE_STATUS_LAYOUT_TYPE.TEXT}
                status={data.status}
                onPress={handleStatusChange}
                isDisabled={!inEditMode}
                payment={data.payment}
              />
            )}
          </Box>
        </Box>
        {/* END HEADER */}

        {/* START BOOKING INFO HEADER */}

        <ScrollView
          horizontal
          scrollEnabled={inEditMode}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            flex: 1,
            marginTop: 8,
            marginLeft: 8,
          }}
        >
          <TouchableOpacity
            disabled={areTabButtonsDisabled("date")}
            onPress={() => openModal(MODAL_TYPE.DATE)}
          >
            <Box
              // flex={1}
              flexDirection="row"
              alignItems="center"
              p="s"
              height={42}
              backgroundColor={!areTabButtonsDisabled("date") ? "disabled" : "white"}
              borderRadius="button"
              mr="s"
            >
              <CALENDAR
                height={ICON_LIST_ACTION}
                width={ICON_LIST_ACTION}
                fill={PALETTE.darkBlue}
              />

              <CustomText pl="s" variant="content">
                {formattedDate}
              </CustomText>
            </Box>
          </TouchableOpacity>

          <TouchableOpacity
            disabled={areTabButtonsDisabled("time")}
            onPress={() => openModal(MODAL_TYPE.TIME)}
          >
            <Box
              // flex={1}
              flexDirection="row"
              alignItems="center"
              p="s"
              height={42}
              backgroundColor={!areTabButtonsDisabled("time") ? "disabled" : "white"}
              borderRadius="button"
              mr="s"
            >
              <TIME
                // height={ICON_LIST_ACTION}
                // width={ICON_LIST_ACTION}
                fill={PALETTE.darkBlue}
              />

              <CustomText pl="s" variant="content">
                {formattedTime}
              </CustomText>
            </Box>
          </TouchableOpacity>
          {(moduleType === Modules.Reservation ||
            moduleType === Modules.CashRegister) && (
            <TouchableOpacity
              disabled={!inEditMode}
              onPress={() => openModal(MODAL_TYPE.PERSONS)}
            >
              <Box
                // flex={1}
                flexDirection="row"
                alignItems="center"
                p="s"
                height={42}
                backgroundColor={inEditMode ? "disabled" : "white"}
                borderRadius="button"
                mr="s"
              >
                <PAX_PERSONS
                  height={ICON_LIST_ACTION}
                  width={ICON_LIST_ACTION}
                  fill={PALETTE.darkBlue}
                />

                <CustomText pl="s" variant="content">
                  {nbPersons}
                </CustomText>
              </Box>
            </TouchableOpacity>
          )}
          {moduleType === Modules.OnlineSales && inEditMode && (
            <>
              {/* <TouchableOpacity onPress={() => openDelayModal(10)} disabled={!inEditMode}>
                <Box
                  // flex={1}
                  flexDirection="row"
                  alignItems="center"
                  p="s"
                  height={42}
                  backgroundColor={inEditMode ? "disabled" : "white"}
                  borderRadius="button"
                  mr="s"
                >
                  <PLUS
                    height={ICON_LIST_ACTION}
                    width={ICON_LIST_ACTION}
                    fill={PALETTE.darkBlue}
                  />

                  <CustomText pl="s" variant="content">
                    10min
                  </CustomText>
                </Box>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => openDelayModal(15)} disabled={!inEditMode}>
                <Box
                  // flex={1}
                  flexDirection="row"
                  alignItems="center"
                  p="s"
                  height={42}
                  backgroundColor={inEditMode ? "disabled" : "white"}
                  borderRadius="button"
                  mr="s"
                >
                  <PLUS
                    height={ICON_LIST_ACTION}
                    width={ICON_LIST_ACTION}
                    fill={PALETTE.darkBlue}
                  />

                  <CustomText pl="s" variant="content">
                    15min
                  </CustomText>
                </Box>
              </TouchableOpacity> */}
            </>
          )}
          {(moduleType === Modules.Reservation ||
            moduleType === Modules.CashRegister) && (
            <TouchableOpacity disabled={!inEditMode} onPress={goToBookingFloor}>
              <Box
                // flex={1}
                flexDirection="row"
                alignItems="center"
                p="s"
                height={42}
                backgroundColor={inEditMode ? "disabled" : "white"}
                borderRadius="button"
                mr="s"
              >
                <BOOKING_FLOOR
                  // height={ICON_LIST_ACTION}
                  // width={ICON_LIST_ACTION}
                  fill={PALETTE.darkBlue}
                />

                <CustomText pl="s" variant="content">
                  {displayTables(data.tables || [])}
                </CustomText>
              </Box>
            </TouchableOpacity>
          )}
        </ScrollView>

        {/* END BOOKING INFO HEADER */}

        {/* START TABS*/}

        <Box
          p="s"
          pt={inEditMode ? "s" : "none"}
          flexDirection="row"
          alignItems="flex-start"
          justifyContent={"space-between"}
        >
          <Box flex={1}>
            <OutlineTabs
              tabs={tabNames}
              onTabPress={setSelectedTab}
              selectedTab={selectedTab}
              boxStyle={{ width: getTabWidth() }}
            />
          </Box>

          <Box>{displayExtraTab()}</Box>
        </Box>

        {/* END TABS*/}
      </Box>
      <Box flex={1}>
        <KeyboardAwareScrollView style={{ flex: 1 }}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: 150, flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <Box flex={1} mt="s">
              {displayTab()}
            </Box>
          </ScrollView>
        </KeyboardAwareScrollView>
      </Box>

      {selectedTab !== "TIMELINE" && bottomButton}
      {displayModal()}
    </Box>
  );
};

export default ModulListDetails;
