import React from "react";
import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
export type TAB_KEYS =
  | "BOOKING"
  | "ONLINE_ORDER"
  | "TICKET"
  | "PAYMENT"
  | "DELIVERY"
  | "CLIENT"
  | "HISTORY"
  | "QUOTE"
  | "INVOICE"
  | "TIMELINE";
const HEIGHT = 45;
const MIN_WIDTH = 90;
export interface Tab {
  title: string;
  icon?: JSX.Element;
  key: string;
}

interface OutlineTabsProps {
  tabs: Tab[];
  selectedTab: TAB_KEYS | string;
  onTabPress: (key: TAB_KEYS) => void;
  boxStyle?: React.ComponentProps<typeof Box>;
  textStyle?: React.ComponentProps<typeof CustomText>;
}

const OutlineTabs = ({
  tabs,
  selectedTab,
  onTabPress,
  boxStyle,
  textStyle,
}: OutlineTabsProps) => {
  const minWidth = boxStyle?.minWidth ? boxStyle?.minWidth : `${100 / tabs.length}%`;

  return (
    <Box width="100%" flexDirection="row" alignItems="center">
      {tabs.map((tab, idx) => {
        const IS_FIRST = idx === 0;
        const IS_LAST = idx === tabs.length - 1;
        const IS_SELECTED = tab.key === selectedTab;

        const finalBoxStyle: React.ComponentProps<typeof Box> = {
          borderTopLeftRadius: IS_FIRST ? "button" : "none",
          borderBottomLeftRadius: IS_FIRST ? "button" : "none",
          borderTopRightRadius: IS_LAST ? "button" : "none",
          borderBottomRightRadius: IS_LAST ? "button" : "none",
          borderColor: "disabled",
          borderWidth: LINE_THICKNESS,
          alignItems: "center",
          justifyContent: "center",
          // width: "100%",
          minHeight: HEIGHT,
          // minWidth: MIN_WIDTH,
          // minWidth: "33%",
          // width: minWidth,
          // ...boxStyle,
        };

        return (
          <TouchableOpacity
            key={tab.key}
            // containerStyle={{
            //   width: `${100 / tabs.length}%`,
            // }}
            style={{
              width: `${100 / tabs.length}%`,
            }}
            onPress={() => onTabPress(tab.key)}
          >
            <Box
              backgroundColor={IS_SELECTED ? "primaryTextColor" : "white"}
              borderColor="primaryTextColor"
              {...finalBoxStyle}
            >
              {tab.icon ? tab.icon : <></>}

              <CustomText
                variant="listInactiveTab"
                color={IS_SELECTED ? "white" : "primaryTextColor"}
                {...textStyle}
              >
                {tab.title}
              </CustomText>
            </Box>
          </TouchableOpacity>
        );
      })}
    </Box>
  );
};

export default OutlineTabs;
