import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";

import LAPTOP from "../../../../assets/icons/BASE/LAPTOP.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import type { DevicesTotalSummary } from "../../../graphql/generated/schema";
import { useGetDevicesTotalSummaryLazyQuery } from "../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../navigation/AppStack/SettingsStack/ParamList";
import { PALETTE } from "../../../theme/Palette";

import MerchantDevicesList from "./MerchantDevicesList";
import PrinterList from "./PrintersList";

interface DevicesSettingsListProps {
  goBack?: () => void;
}

const ICON_SIZE = 20;

const DevicesSettingsList = ({ goBack }: DevicesSettingsListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [devicesTotalSummary, setDevicesTotalSummary] =
    useState<DevicesTotalSummary | null>(null);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const [getDevicesTotalSummary] = useGetDevicesTotalSummaryLazyQuery();

  const handleGetDevicesSummary = async () => {
    try {
      const { data } = await getDevicesTotalSummary();

      if (data) {
        setDevicesTotalSummary(data.getDevicesTotalSummary);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDevicesSummary();
  }, []);

  if (loading) return <Loader />;

  if (!devicesTotalSummary) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <ScreenHeader
          title="Matériel"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données." />
      </Box>
    );
  }

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Matériel"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <TouchableOpacity onPress={() => handleNavigation("DEVICES_LIST")}>
            <Box
              p="s"
              paddingVertical="m"
              borderBottomColor="lightGrey"
              // borderBottomWidth={LINE_THICKNESS}
              borderBottomWidth={0}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <CustomText variant="label" color="primaryTextColor">
                  Appareils connectés
                </CustomText>
                <Box mt="s" flexDirection="row" alignItems="center">
                  <LAPTOP width={15} height={15} fill={PALETTE.green} />
                  <CustomText ml="s" variant="content" color="primaryTextColor">
                    {devicesTotalSummary.totalDevices} appareils
                  </CustomText>
                </Box>
              </Box>
              <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => handleNavigation("PRINTERS_LIST")}>
            <Box
              p="s"
              paddingVertical="m"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <CustomText variant="label" color="primaryTextColor">
                  Imprimantes
                </CustomText>
                <Box mt="s" flexDirection="row" alignItems="center">
                  <RIGHT_ARROW
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    fill={PALETTE.green}
                  />
                  <CustomText ml="s" variant="content" color="primaryTextColor">
                    {devicesTotalSummary.totalPrinters} imprimantes
                  </CustomText>
                </Box>
              </Box>
              <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </TouchableOpacity> */}
        </Box>
      </Box>
    );
  };

  const handleCloseSlecteTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "DEVICES_LIST":
        return <MerchantDevicesList goBack={handleCloseSlecteTab} />;
      case "PRINTERS_LIST":
        return <PrinterList goBack={handleCloseSlecteTab} />;

      default:
        break;
    }
  };

  const displayContent = () => {
    if (!selectedTab) return displaySettingsList();

    return displaySelectedTab();
  };

  return <>{displayContent()}</>;
};

export default DevicesSettingsList;
