import { useNavigation } from "@react-navigation/native";
import type { Dispatch, SetStateAction } from "react";
import { useContext } from "react";
import { TouchableOpacity } from "react-native";

import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  BookingSlotInput,
  CreateBookingAvailabilityInput,
} from "../../../graphql/generated/schema";
import { useGenerateBookingsSlotsMutation } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import { removeTypeNames } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface ServiceSlotsProps {
  id?: string;
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  onPressSlots: () => Promise<boolean>;
}

const ServiceSlots = ({
  id,
  bookingAvailability,
  setBookingAvailability,
  onPressSlots,
}: ServiceSlotsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [generateBookingSlots] = useGenerateBookingsSlotsMutation();

  const handleConsolidateBookingAvailability = () => {
    let bookingAvailabilityToSave = removeTypeNames(bookingAvailability);
    delete bookingAvailabilityToSave._id;

    const { bookingFloorSettings, bookingProductsSettings } = bookingAvailability;

    const consolidatedBookingFloorSettings = {
      ...bookingFloorSettings,
      bookingFloors: bookingFloorSettings.bookingFloors.map(floor => floor._id),
    };

    const consolidatedBookingProductsSettings = {
      ...bookingProductsSettings,
      categories: bookingProductsSettings.categories.map(category => category._id),
      menus: bookingProductsSettings.menus.map(menu => menu._id),
      products: bookingProductsSettings.products.map(product => product._id),
    };

    bookingAvailabilityToSave = {
      ...bookingAvailabilityToSave,
      bookingFloorSettings: consolidatedBookingFloorSettings,
      bookingProductsSettings: consolidatedBookingProductsSettings,
    };

    return bookingAvailabilityToSave;
  };

  const handleGenerateSlots = async () => {
    try {
      const consolidatedBookingService = handleConsolidateBookingAvailability();
      const { data } = await generateBookingSlots({
        variables: {
          bookingAvailability: consolidatedBookingService,
          availabilityId: id,
        },
      });

      return data?.generatedBookingSlots || [];
    } catch (err) {
      console.log("err generate slots", err);
    }
  };

  const handleUpdateSlotsSubmit = (slots: BookingSlotInput[]) => {
    setBookingAvailability(prev => ({
      ...prev,
      slots,
    }));
  };

  const handleViewSlots = async () => {
    try {
      const canViewSlots = await onPressSlots();

      if (!canViewSlots)
        return infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "INVALID_SERVICE_FOR_SLOT_GENERATION",
              message:
                "Le service n'est pas rempli correctement pour générer les créneaux",
            },
          ],
          "error",
        );

      const {
        slots,
        visibilitySetting: visibilitySettings,
        totalPersonsAllowed,
        maxCapacityAllowedForSlot,
      } = bookingAvailability;

      let consolidatedSlots = slots;

      if (!slots?.length) {
        const generatedSlots = await handleGenerateSlots();

        consolidatedSlots = generatedSlots;
      }

      navigation.navigate("BOOKING_SERVICES_SLOTS_LIST", {
        slots: consolidatedSlots,
        visibilitySettings,
        totalService: totalPersonsAllowed,
        totalSlot: maxCapacityAllowedForSlot,
        service: bookingAvailability,
        onUpdateSlotsSubmit: handleUpdateSlotsSubmit,
      });
    } catch (err) {}
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          PERSONNALISER LES CRÉNEAUX
        </CustomText>
      </Box>
      <Box mt="s">
        <CustomText variant="text" color="lightGrey">
          Lorsque vous créez un service, celui-ci est automatiquement découpé en créneaux
          de 15 minutes. Tous les créneaux sont activés et prennent les valeurs par défaut
          de votre service. Vous pouvez fermer ces créneaux ou bien les personnaliser
          selon votre besoin. Fermer un créneau revient à le rendre indisponible sur votre
          module de réservation, mais ne vous empêche pas de prendre des réservations
          manuellement dans votre cahier de réservation.
        </CustomText>
        <TouchableOpacity onPress={handleViewSlots}>
          <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Liste des créneaux
            </CustomText>

            <RIGHT_ARROW height={16} width={16} fill={PALETTE.green} />
          </Box>
          <CustomText variant="text" color="lightGrey">
            Personnaliser les créneaux
          </CustomText>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default ServiceSlots;
