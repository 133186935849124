import Constants from "expo-constants";
import * as Updates from "expo-updates";

const {
  APP_ENV,
  GQL_URL,
  GQL_WS_URL,
  ROOT_URL,
  STRIPE_MERCHANT_IDENTIFIER,
  STRIPE_DEV_PUBLISHABLE_KEY,
  STRIPE_PROD_PUBLISHABLE_KEY,
  SENTRY_DSN,
} = Constants.expoConfig?.extra || {};

const getConfig = () => {
  if (Updates.channel === "production" || APP_ENV === "production") {
    return {
      name: "Eiwie Pro",
      bundleIdentifier: "com.eiwie.pro",
      packageName: "com.eiwie.pro",
      GQL_URL: GQL_URL,
      GQL_WS_URL: GQL_WS_URL,
      ROOT_URL: ROOT_URL,
      STRIPE_MERCHANT_IDENTIFIER,
      STRIPE_PUBLISHABLE_KEY: STRIPE_PROD_PUBLISHABLE_KEY,
      APP_ENV,
      SENTRY_DSN,
    };
  } else if (Updates.channel === "staging" || APP_ENV === "staging") {
    return {
      name: "Eiwie Pro (Staging)",
      bundleIdentifier: "com.eiwie.staging",
      packageName: "com.eiwie.staging",
      GQL_URL: GQL_URL,
      GQL_WS_URL: GQL_WS_URL,
      ROOT_URL: ROOT_URL,
      STRIPE_MERCHANT_IDENTIFIER,
      STRIPE_PUBLISHABLE_KEY: STRIPE_DEV_PUBLISHABLE_KEY,
      APP_ENV,
      SENTRY_DSN,
    };
  }
  return {
    name: "Eiwie Pro (Dev)",
    bundleIdentifier: "com.eiwie.dev",
    packageName: "com.eiwie.dev",
    GQL_URL: GQL_URL,
    GQL_WS_URL: GQL_WS_URL,
    ROOT_URL: ROOT_URL,
    STRIPE_MERCHANT_IDENTIFIER,
    STRIPE_PUBLISHABLE_KEY: STRIPE_DEV_PUBLISHABLE_KEY,
    APP_ENV: APP_ENV,
    SENTRY_DSN,
  };
};

const config = getConfig();

console.log("config", config);

export default config;
