import React from "react";
import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface SelectableButtonProps {
  value: number | string;
  label: string;
  onChange: (value: number | string) => void;
  isSelected: boolean;
  styles?: React.ComponentProps<typeof Box>;
}

const SelectableButton = ({
  value,
  label,
  onChange,
  isSelected,
  styles,
}: SelectableButtonProps) => {
  return (
    <TouchableOpacity onPress={() => onChange(value)}>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height={45}
        width={75}
        backgroundColor={isSelected ? "white" : "disabled"}
        borderWidth={LINE_THICKNESS}
        borderColor={isSelected ? "success" : "disabled"}
        borderRadius="button"
        {...styles}
      >
        <CustomText color="primaryTextColor" variant="content">
          {label}
        </CustomText>
      </Box>
    </TouchableOpacity>
  );
};

export default SelectableButton;
