import { useFocusEffect } from "@react-navigation/native";
import { startOfDay } from "date-fns";
import React, { useContext, useState } from "react";
import { FlatList, Platform } from "react-native";

import OrderAlertCard from "../../../components/Alerts/OrderAlertCard";
import Box from "../../../components/Base/Box";
import CollapsibleList from "../../../components/CollapsibleList";
import ContentModal from "../../../components/ContentModal";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import Touchable from "../../../components/Touchable";
import { AppContext } from "../../../contexts/AppContext";
import type { OrderFragment } from "../../../graphql/generated/schema";
import {
  Click_Status,
  useGetPendingOrdersFromDateLazyQuery,
  useUpdateOrderMutation,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { uniquefyArray } from "../../../utils/common";
import OnlineOrdersListDetails from "../../Sales/OnlineOrdersListDetails";

const IS_WEB = Platform.OS === "web";

const OrderAlerts = () => {
  const [orders, setOrders] = useState<OrderFragment[]>([]);
  const [loading, setLoading] = useState(true);
  const [isListOpen, setIsListOpen] = useState(false);
  const appContext = useContext(AppContext);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [pendingAmount, setPendingAmount] = useState(appContext.totalPendingOrders);

  const [getOrders, { fetchMore }] = useGetPendingOrdersFromDateLazyQuery();
  const [updateOrder] = useUpdateOrderMutation();

  const handleFetchMore = async () => {
    const date = startOfDay(new Date()).toISOString();

    try {
      const { data } = await fetchMore({
        variables: {
          date,
          pagination: {
            limit: 20,
            offset: orders.length,
          },
        },
      });

      if (data?.getPendingOrdersFromDate) {
        const pendingOrders = data?.getPendingOrdersFromDate;

        const allBookings = [...orders, ...pendingOrders].filter(o => {
          const statuses = [
            Click_Status.New,
            Click_Status.WaitingList,
            Click_Status.ModifiedByOwner,
            Click_Status.ModifiedByUser,
          ];
          return statuses.includes(o.status);
        });

        const arr = uniquefyArray(allBookings);

        setOrders(arr);
      }
    } catch (err) {}
  };

  const handleGetOrders = async () => {
    try {
      const date = startOfDay(new Date()).toISOString();

      const { data } = await getOrders({
        fetchPolicy: "cache-and-network",
        variables: {
          date,
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getPendingOrdersFromDate) {
        const pendingOrders = data?.getPendingOrdersFromDate;

        const isOpen = pendingOrders.length > 0;
        setIsListOpen(isOpen);

        setOrders(
          data.getPendingOrdersFromDate.filter(o => {
            const statuses = [
              Click_Status.New,
              Click_Status.WaitingList,
              Click_Status.ModifiedByOwner,
              Click_Status.ModifiedByUser,
            ];
            return statuses.includes(o.status);
          }),
        );
      }
    } catch (err) {
      console.log("err getting pending orders", err);
    } finally {
      setLoading(false);
    }
  };

  // const handleOrderUpdates = async () => {
  //   await handleGetOrders();

  //   appContext.refreshAlerts();
  // };

  // useEffect(() => {
  //   handleOrderUpdates();
  // }, [appContext.totalPendingOrders]);

  useFocusEffect(
    React.useCallback(() => {
      handleGetOrders();

      // appContext.refreshAlerts();
    }, []),
  );

  const handleOrderStatusChange = async (
    itemId: string,
    newStatus: Click_Status,
    refusalReason: string,
  ) => {
    try {
      await updateOrder({
        variables: {
          orderId: itemId,
          order: {
            status: newStatus,
            refusalReason,
            sendClientNotifications: true,
          },
        },
      });

      setPendingAmount(p => (p - 1 < 0 ? 0 : p - 1));
    } catch (err) {
      console.log("err updating order", err);
    } finally {
      await handleGetOrders();

      // appContext.refreshAlerts();
    }
  };

  const displaySelectedOrderModal = () => {
    return (
      <ContentModal
        title="Commande"
        isVisible={!!selectedOrderId}
        onClose={() => setSelectedOrderId("")}
        hasBackButton={false}
      >
        <Box height="100%" pb="xl">
          <OnlineOrdersListDetails
            showGoBack={false}
            orderId={selectedOrderId}
            onUpdateComplete={handleGetOrders}
          />
        </Box>
      </ContentModal>
    );
  };

  if (loading) return <Loader />;

  return (
    <Box
      maxWidth={1024}
      width="100%"
      alignSelf="center"
      flex={1}
      pt="s"
      paddingHorizontal="s"
      backgroundColor="white"
    >
      <ScreenHeader title="Commandes" />

      <Box mt="s">
        <CollapsibleList
          isOpen={isListOpen}
          nbItems={pendingAmount}
          title="Commandes en attente"
          onChange={() => orders.length > 0 && setIsListOpen(v => !v)}
        >
          <FlatList
            data={orders}
            keyExtractor={item => item._id}
            onEndReached={handleFetchMore}
            contentContainerStyle={{
              paddingBottom: orders.length > 0 && !IS_WEB ? 150 : 0,
            }}
            renderItem={({ item, index: idx }) => {
              const IS_LAST = idx === orders.length - 1;

              return (
                <Touchable onPress={() => setSelectedOrderId(item._id)}>
                  <Box
                    key={item._id}
                    borderBottomColor="disabled"
                    borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                  >
                    <OrderAlertCard
                      {...{
                        item,
                        boxStyles: { paddingHorizontal: "s" },
                        onPress: (newStatus: Click_Status, refusalReason: string) =>
                          handleOrderStatusChange(item._id, newStatus, refusalReason),
                      }}
                    />
                  </Box>
                </Touchable>
              );
            }}
          />
        </CollapsibleList>
      </Box>
      {selectedOrderId && displaySelectedOrderModal()}
    </Box>
  );
};

export default OrderAlerts;
