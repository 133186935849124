 
import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ButtonList from "../../../../components/ButtonList";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { AccountingReportSettingsFragment } from "../../../../graphql/generated/schema";
import {
  Frequency_Interval,
  GetAccountingReportSettingsDocument,
  useGetAccountingReportSettingsLazyQuery,
  useUpdateAccountingReportSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface AccountingReportsProps {
  goBack?: () => void;
}

const FREQUENCY_INTERVAL_OPTIONS = [
  {
    label: "Tous les jours",
    key: Frequency_Interval.EveryDay,
  },
  {
    label: "Une fois par semaine",
    key: Frequency_Interval.OnceAWeek,
  },
  {
    label: "Une fois par mois",
    key: Frequency_Interval.OnceAMonth,
  },
];

const AccountingReports = ({ goBack }: AccountingReportsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<AccountingReportSettingsFragment | null>(null);

  const [getAccountingReportSettings] = useGetAccountingReportSettingsLazyQuery();
  const [updateSettings] = useUpdateAccountingReportSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getAccountingReportSettings();

      if (data?.getAccountingReportSettings) {
        setSettings(data.getAccountingReportSettings);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handlSubmit = async () => {
    try {
      if (settings) {
        const updates = removeTypeNames(settings);
        delete updates._id;
        updateSettings({
          variables: {
            updates,
          },
          refetchQueries: [{ query: GetAccountingReportSettingsDocument }],
        });
        handleGoBack();
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATE_ACCOUNTING_REPORT_SETTINGS",
            path: "ERROR_UPDATE_ACCOUNTING_REPORT_SETTINGS",
            message:
              "Une erreur est survenue lors de la mise à jour des paramètres de rapport comptable",
          },
        ],
        "error",
      );
    }
  };

  const setEmails = (
    key: "accountingReportEmails" | "salesReportEmails" | "cashRegisterReportEmails",
    vals: { label: string; key: string }[],
  ) => {
    const emails = vals.map(val => val.key);

    if (settings) {
      setSettings(prev =>
        prev === null
          ? null
          : {
              ...prev,
              [key]: emails,
            },
      );
    }
  };

  const formatEmails = (emails: string[]) => {
    return emails.map(email => ({ key: email, label: email }));
  };

  const updateSettingsData = (
    key: keyof AccountingReportSettingsFragment,
    value: Frequency_Interval | string,
  ) => {
    if (settings) {
      setSettings({
        ...settings,
        [key]: value,
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!settings) {
    return (
      <Box>
        <Box marginVertical="s">
          <ScreenHeader title="Rapports" hasBackButton onBackPress={handleGoBack} />
        </Box>

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres de rapports." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box marginVertical="s">
        <ScreenHeader title="Rapports" hasBackButton onBackPress={handleGoBack} />
      </Box>

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box>
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de caisse
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.cashRegisterReportEmails)}
            onChange={vals => setEmails("cashRegisterReportEmails", vals)}
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.cashRegisterFrequency]}
            onPress={([val]) => updateSettingsData("cashRegisterFrequency", val)}
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de vente
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.salesReportEmails)}
            onChange={vals => setEmails("salesReportEmails", vals)}
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.cashRegisterFrequency]}
            onPress={([val]) => updateSettingsData("salesReportFrequency", val)}
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Destinataires des rapports pour les rapports de comptable
          </CustomText>

          <ButtonList
            placeHolder="Email"
            values={formatEmails(settings.accountingReportEmails)}
            onChange={vals => setEmails("accountingReportEmails", vals)}
          />
        </Box>

        <Box mt="m">
          <SelectButton
            isMultiSelect={false}
            options={FREQUENCY_INTERVAL_OPTIONS}
            selectedOptions={[settings.cashRegisterFrequency]}
            onPress={([val]) => updateSettingsData("accountingReportFrequency", val)}
            placeHolder="Sélectionner la période d’envoi"
          />
        </Box>
      </ScrollView>

      <BottomButton title="Valider" onPress={handlSubmit} />
    </Box>
  );
};

export default AccountingReports;
