import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";

import AccountDetailsEdit from "./Edit";
import AccountDetailsView from "./View";

interface AccountDetailsProps {
  goBack?: () => void;
}

const AccountDetails = ({ goBack }: AccountDetailsProps) => {
  const [inEditMode, setInEditMode] = useState(false);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <AccountDetailsEdit goBack={goBack} />
      ) : (
        <AccountDetailsView goBack={goBack} />
      )}
      {!inEditMode && (
        <BottomButton title="Modifier" onPress={() => setInEditMode(true)} />
      )}
    </Box>
  );
};

export default AccountDetails;
