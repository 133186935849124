import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

interface AlphabetSectionListProps {
  onLetterPress: (letter: string) => void;
}

const AlphabetSectionList = ({ onLetterPress }: AlphabetSectionListProps) => {
  return (
    <Box alignItems="flex-end">
      <FlatList
        showsVerticalScrollIndicator={false}
        data={ALPHABET.split("")}
        keyExtractor={item => item}
        renderItem={({ item: letter }) => (
          <Box marginBottom="s">
            <TouchableOpacity onPress={() => onLetterPress(letter)}>
              <CustomText variant="description">{letter}</CustomText>
            </TouchableOpacity>
          </Box>
        )}
      />
    </Box>
  );
};

export default AlphabetSectionList;
