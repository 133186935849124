import type { ViewStyle } from "react-native";
import RNModal from "react-native-modal";

import Box from "../Base/Box";

export enum MODAL_POSITION {
  CENTER,
  BOTTOM,
}

interface ModalProps {
  isVisible: boolean;
  children: JSX.Element;
  position?: MODAL_POSITION;
  onBackdropPress: () => void;
}

const Modal = ({
  onBackdropPress,
  isVisible,
  children,
  position = MODAL_POSITION.BOTTOM,
}: ModalProps) => {
  const CENTER_POSITION_STYLES: ViewStyle = {
    width: "100%", //
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const BOTTOM_POSITION_STYLES: ViewStyle = {
    flex: 1,
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
  };

  const STYLES =
    position === MODAL_POSITION.CENTER ? CENTER_POSITION_STYLES : BOTTOM_POSITION_STYLES;

  return (
    <RNModal
      avoidKeyboard
      onBackButtonPress={onBackdropPress}
      onBackdropPress={onBackdropPress}
      style={{ flex: 1, margin: position === MODAL_POSITION.CENTER ? 20 : 0, padding: 0 }}
      isVisible={isVisible}
      // useNativeDriver
      // useNativeDriverForBackdrop
    >
      <Box style={STYLES}>{children}</Box>
    </RNModal>
  );
};

export default Modal;
