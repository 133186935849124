import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

interface PrepaymentPercentageModalContentProps {
  onSubmit: (applyAutomaticPrepaymentPercentage: boolean, reimburse: boolean) => void;
}

const PrepaymentPercentageModalContent = ({
  onSubmit,
}: PrepaymentPercentageModalContentProps) => {
  return (
    <Box height={300} justifyContent="space-between">
      <CustomText variant="label" color="primaryTextColor">
        Ce client a payé l'empreinte bancaire pour cette réservation. Quel action
        voulez-vous effectuer ?
      </CustomText>

      <Box>
        <Box marginVertical="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="danger"
            onPress={() => onSubmit(true, false)}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Appliquer l'empreinte bancaire
            </CustomText>
          </CustomButton>
        </Box>
        {/* <Box marginVertical="s">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="success"
          onPress={handleActionChoice}
        >
          <CustomText variant="primaryButtonText" color="white">
           Appliquer un autr pourcentage deja défini
          </CustomText>
        </CustomButton>
      </Box> */}
        <Box marginVertical="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="success"
            onPress={() => onSubmit(false, true)}
          >
            <CustomText variant="outlineButtonText" color="success">
              Rembourser l'empreinte bancaire
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PrepaymentPercentageModalContent;
