import { ScrollView, StyleSheet } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import BottomButton from "../BottomButton";
import ContentModal from "../ContentModal";

interface MessageAlertData {
  code: string;
  message: string;
}

interface ErrorInfoMessageAlertProps {
  type: "error" | "info" | "success";
  data: MessageAlertData[];
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const styles = StyleSheet.create({});

const ErrorInfoMessageAlert = ({
  type,
  data,
  title,
  isOpen,
  onClose,
}: ErrorInfoMessageAlertProps) => {
  const BASE_STYLES: React.ComponentProps<typeof Box> = {
    p: "s",
    borderRadius: "button",
    borderWidth: LINE_THICKNESS,
  };

  const ERROR_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_STYLES,
    borderColor: "danger",
    backgroundColor: "redBackground",
  };
  const SUCCESS: React.ComponentProps<typeof Box> = {
    ...BASE_STYLES,
    borderColor: "success",
    backgroundColor: "greenBackground",
  };
  const INFO_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_STYLES,
    borderColor: "yellow",
    backgroundColor: "yellowBackground",
  };

  const getStyles = () => {
    switch (type) {
      case "error":
        return ERROR_STYLES;
      case "success":
        return SUCCESS;
      case "info":
        return INFO_STYLES;
      default:
        return {};
    }
  };

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={onClose}
      title={title}
      styles={{
        maxWidth: 400,
        height: "auto",
        alignSelf: "center",
      }}
    >
      <>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Box height={400}>
            <Box {...getStyles()}>
              {data.map((item, index) => {
                return (
                  <Box pb="s" key={index}>
                    <CustomText variant="content" color="danger">
                      {item.message}
                    </CustomText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </ScrollView>
        <BottomButton onPress={onClose} title="Ok, j'ai compris" />
      </>
    </ContentModal>
  );
};

export default ErrorInfoMessageAlert;
