import { isBefore } from "date-fns";
import { useState } from "react";
import { FlatList } from "react-native-gesture-handler";

import {
  Origin,
  type BookingFragment,
  type Booking_Status,
  type Modules,
  type OrderFragment,
} from "../../../../../graphql/generated/schema";
import type { Timeline } from "../../../../../types/types";
import Box from "../../../../Base/Box";
import { CustomText } from "../../../../Base/Text";
import type { ListingTab } from "../../../../ListingTabs";
import { ListingTabs } from "../../../../ListingTabs";

import TimelineEvent from "./TimelineEvent";
import TimelineMessageItem from "./TimelineMessage";

const MIN_WIDTH = 340;

interface BookingListDetailsViewTimelineTabProps {
  timeline: Timeline;
  data: BookingFragment | OrderFragment;
  moduleType: Modules;
  undoLastStatusChange?: (status: Booking_Status) => void;
}

const TABS: ListingTab[] = [
  {
    title: "Actions",
    key: "ACTIONS",
  },
  {
    title: "Messages",
    key: "MESSAGES",
  },
];

const BookingListDetailsViewTimelineTab = ({
  timeline,
  data,
  moduleType,
  undoLastStatusChange,
}: BookingListDetailsViewTimelineTabProps) => {
  const [currentTab, setCurrentTab] = useState(TABS[0].key);

  const getDisplayName = () => {
    const { merchantClient } = data;

    if (!merchantClient) return "PASSAGE";
    const { firstName = "", lastName = "" } = merchantClient;
    return `${firstName} ${lastName}`;
  };

  const events = JSON.parse(JSON.stringify(timeline?.events));
  const messages = JSON.parse(JSON.stringify(timeline?.messages));

  const sortedEvents = events?.sort((a, b) =>
    isBefore(new Date(b.eventDate), new Date(a.eventDate)) ? -1 : 1,
  );

  const sortedMessages = messages?.sort((a, b) =>
    isBefore(new Date(b.messageDate), new Date(a.messageDate)) ? -1 : 1,
  );

  const displayTab = () => {
    switch (currentTab) {
      case "ACTIONS":
        return (
          <Box>
            <FlatList
              data={sortedEvents}
              ListEmptyComponent={
                <Box alignItems="center" justifyContent="center">
                  <CustomText variant="label" color="primaryTextColor">
                    Aucun événement
                  </CustomText>
                </Box>
              }
              renderItem={({ item, index }) => (
                <Box marginVertical="s">
                  <TimelineEvent
                    createdAt={data.createdAt}
                    merchantClientName={getDisplayName()}
                    merchantClientImage={data.merchantClient?.picture}
                    timelineEvent={item}
                    date={data.date}
                    source={data.source}
                    nbPersons={data.nbPersons}
                    module={moduleType}
                    currentStatus={data.status}
                    isLastEvent={index === 0}
                    canUndoChanges={data?.source !== Origin.Google}
                    undoLastStatusChange={undoLastStatusChange}
                  />
                </Box>
              )}
            />
          </Box>
        );
      case "MESSAGES":
        return (
          <Box>
            <FlatList
              data={sortedMessages}
              ListEmptyComponent={
                <Box alignItems="center" justifyContent="center">
                  <CustomText variant="label" color="primaryTextColor">
                    Aucun message
                  </CustomText>
                </Box>
              }
              renderItem={({ item }) => (
                <Box marginVertical="s">
                  <TimelineMessageItem message={item} />
                </Box>
              )}
            />
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Box paddingHorizontal="s">
      <ListingTabs
        tabs={TABS}
        currentTabName={currentTab}
        onSelectTab={setCurrentTab}
        boxProps={{
          minHeight: 45,
        }}
      />

      <Box mt="s">{displayTab()}</Box>
    </Box>
  );
};

export default BookingListDetailsViewTimelineTab;
