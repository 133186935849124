import React from "react";

import CART from "../../../assets/icons/BASE/CART.svg";
import PERSONS from "../../../assets/icons/BASE/PERSONS.svg";
import { Modules } from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

import { COLLAPSE_ARROW_WIDTH } from "./ServiceListHeaderItem";

const HEIGHT = 27;
const ICON_SIZE = 16;

type Props = {
  moduleType: Modules;
  serviceName: string;
  totalBookingsText: string;
  totalPersonsText: string;
  boxProps?: React.ComponentProps<typeof Box>;
  // rightIcon?: JSX.Element;
};

export const ServiceListHeader = ({
  moduleType,
  serviceName,
  totalBookingsText,
  totalPersonsText,
  boxProps,
}: // rightIcon = <></>,
Props) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="secondaryTab"
      paddingHorizontal="s"
      width={"100%"}
      height={HEIGHT}
      {...boxProps}
    >
      <Box flexDirection={"row"}>
        <CustomText variant="listTitle" color="primaryTextColor">
          {serviceName}{" "}
        </CustomText>
        <CustomText
          variant="tabTitle"
          color="primaryTextColor"
          textTransform={"capitalize"}
        >
          - {totalBookingsText}
        </CustomText>
      </Box>

      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        {moduleType === Modules.Reservation && (
          <PERSONS height={ICON_SIZE} fill={PALETTE.darkBlue} />
        )}
        {moduleType === Modules.OnlineSales && <CART fill={PALETTE.darkBlue} />}
        <CustomText
          color="primaryTextColor"
          variant="listTitle"
          textTransform={"capitalize"}
          paddingHorizontal="s"
        >
          {totalPersonsText}
        </CustomText>

        {/* {rightIcon} */}

        <Box pl="s" width={COLLAPSE_ARROW_WIDTH} />
      </Box>
    </Box>
  );
};
