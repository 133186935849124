/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";

import type {
  NewBookingSubscription,
  NewCallSubscription,
  NewOrderSubscription,
  NewReviewSubscription,
} from "../../graphql/generated/schema";

export interface SubscriptionContextInterface {
  calls: NewCallSubscription["incomingCall"][];
  bookings: NewBookingSubscription["bookingCreated"][];
  orders: NewOrderSubscription["orderCreated"][];
  reviews: NewReviewSubscription["newReview"][];

  setCalls: (calls: NewCallSubscription["incomingCall"][]) => void;
  setBookings: (bookings: NewBookingSubscription["bookingCreated"][]) => void;
  setOrders: (orders: NewOrderSubscription["orderCreated"][]) => void;
  setReviews: (reviews: NewReviewSubscription["newReview"][]) => void;
}

export const SubscriptionsContext = createContext<SubscriptionContextInterface>({
  calls: [],
  bookings: [],
  orders: [],
  reviews: [],
  setCalls: () => {},
  setBookings: () => {},
  setOrders: () => {},
  setReviews: () => {},
});
