import { useState } from "react";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import BottomButton from "../BottomButton";
import CashRegisterNumberKeyboard from "../CashRegister/CashRegisterNumberKeyboard";
import ContentModal from "../ContentModal";

interface NbPersonsInputProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  nbPersons: number;
  onConfirm: (nbPersons: number) => void;
}

const NbPersonsInput = ({
  isOpen,
  setIsOpen,
  nbPersons,
  onConfirm,
}: NbPersonsInputProps) => {
  const [value, setValue] = useState(nbPersons.toString());

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(+value);
    handleClose();
  };

  const handleAddPersons = (person: string) => {
    const newPersons = +(value + person);
    setValue(newPersons.toString());
  };

  const handleClear = () => {
    setValue("0");
  };

  return (
    <ContentModal title="Nombre de personnes" isVisible={isOpen} onClose={handleClose}>
      <>
        <Box height="100%" minHeight={500}>
          <Box
            marginVertical="s"
            width="100%"
            minHeight={45}
            borderRadius="button"
            borderColor="disabled"
            justifyContent="center"
            alignItems="center"
            borderWidth={LINE_THICKNESS}
          >
            <CustomText variant="label" color="primaryTextColor">
              {value}
            </CustomText>
          </Box>

          <Box>
            <CashRegisterNumberKeyboard
              handleAmountChange={handleAddPersons}
              handleClear={handleClear}
              showComma={false}
            />
          </Box>
        </Box>
        <BottomButton title="Valider" onPress={handleConfirm} />
      </>
    </ContentModal>
  );
};

export default NbPersonsInput;
