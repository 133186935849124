import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import StaffListCard from "../../../../components/Settings/StaffListCard";
import { CustomTextInput } from "../../../../components/TextInput";
import type { MerchantPersonnelFragment } from "../../../../graphql/generated/schema";
import { useGetMerchantPersonnelsLazyQuery } from "../../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import { uniquefyArray } from "../../../../utils/common";
import StaffListDetails from "../StaffListDetails";

interface StaffListProps {
  goBack?: () => void;
}

const StaffList = ({ goBack }: StaffListProps) => {
  const navigation = useNavigation();
  const [staff, setStaff] = useState<MerchantPersonnelFragment[]>([]);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [isNew, setIsNew] = useState(false);
  const [staffId, setStaffId] = useState("");

  const [getMerchantPersonnels, { fetchMore }] = useGetMerchantPersonnelsLazyQuery();

  const handleFetchMore = async () => {
    try {
      const result = await fetchMore({
        variables: {
          pagination: {
            limit: 20,
            offset: staff.length,
          },
        },
      });

      if (result.data?.getMerchantPersonnels) {
        setStaff(prev => uniquefyArray([...prev, ...result.data.getMerchantPersonnels]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMerchantPersonnels = async () => {
    setLoading(true);
    try {
      const { data } = await getMerchantPersonnels({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getMerchantPersonnels) {
        setStaff(data.getMerchantPersonnels);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMerchantPersonnels();
    }, []),
  );

  const handleAddNewStaff = () => {
    if (goBack) {
      setIsNew(true);
      setStaffId("");
      setSelectedTab("STAFF_LIST_DETAILS");
    } else {
      navigation.navigate("STAFF_LIST_DETAILS", {
        isNew: true,
      });
    }
  };

  const handleEditStaff = (id: string) => {
    if (goBack) {
      setIsNew(false);
      setStaffId(id);
      setSelectedTab("STAFF_LIST_DETAILS");
    } else {
      navigation.navigate("STAFF_LIST_DETAILS", {
        isNew: false,
        id,
      });
    }
  };

  const filteredStaff = useMemo(() => {
    const formattedSearch = search.toLowerCase()?.trim();

    if (!formattedSearch) return staff;

    return staff.filter(item => {
      const { lastName = "", firstName = "", email = "", phone = "" } = item;

      const formattedLastName = lastName.toLowerCase()?.trim();
      const formattedFirstName = firstName?.toLowerCase()?.trim();
      const formattedEmail = email?.toLowerCase()?.trim();
      const formattedPhone = phone.toLowerCase()?.trim();

      return (
        formattedLastName.includes(formattedSearch) ||
        formattedFirstName?.includes(formattedSearch) ||
        formattedEmail?.includes(formattedSearch) ||
        formattedPhone.includes(formattedSearch)
      );
    });
  }, [staff, search]);

  if (loading) {
    return <Loader />;
  }

  // if (error) {
  //   return (
  //     <Box>
  //       <ScreenHeader
  //         title="Utilisateurs"
  //         hasBackButton
  //         onBackPress={goBack || navigation.goBack}
  //       />

  //       <ErrorMessage
  //         message="Une erreur est survenue lors du chargement des utilisateurs."
  //       />
  //     </Box>
  //   );
  // }

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" flex={1} backgroundColor="white">
        <Box mt="m">
          <>
            <ScreenHeader
              title="Utilisateurs"
              rightButtons={
                <TouchableOpacity onPress={handleAddNewStaff}>
                  <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                </TouchableOpacity>
              }
              hasBackButton
              onBackPress={goBack || navigation.goBack}
            />

            {staff.length > 0 && (
              <Box marginVertical="s">
                <CustomTextInput
                  {...{
                    placeHolder: "Rechercher",
                    leftIcon: (
                      <SEARCH
                        height={ICON_SIZE}
                        width={ICON_SIZE}
                        fill={PALETTE.darkBlue}
                      />
                    ),
                    onChangeText: setSearch,
                  }}
                />
              </Box>
            )}
          </>
        </Box>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={filteredStaff}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={handleFetchMore}
          contentContainerStyle={{ paddingTop: 5 }}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <StaffListCard
                isSelected={false}
                merchantPersonnel={item}
                onPress={() => handleEditStaff(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const handleCloseSelectedTab = async () => {
    await handleGetMerchantPersonnels();
    setSelectedTab(undefined);
  };

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "STAFF_LIST_DETAILS":
        return (
          <StaffListDetails
            isNewValue={isNew}
            staffId={staffId}
            goBack={handleCloseSelectedTab}
          />
        );

      default:
        break;
    }
  };

  const displayContent = () => {
    if (!selectedTab) {
      return displaySettingsList();
    }

    return displaySelectedTab();
  };

  return <>{displayContent()}</>;
};

export default StaffList;
