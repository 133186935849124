import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useState } from "react";
import { Image, ScrollView } from "react-native";

import BARCODE from "../../../../../../../assets/icons/BASE/BARCODE.svg";
import GROUP from "../../../../../../../assets/icons/BASE/GROUP.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import PRICE_TAG from "../../../../../../../assets/icons/BASE/PRICE_TAG.svg";
import TAGS from "../../../../../../../assets/icons/BASE/TAGS.svg";
import TIMER from "../../../../../../../assets/icons/BASE/TIMER.svg";
import VALIDATION from "../../../../../../../assets/icons/BASE/VALIDATION.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { MenuFragment } from "../../../../../../graphql/generated/schema";
import { useGetMenuLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import {
  formatCurrencyPrice,
  getWeekdayFromNumber,
} from "../../../../../../utils/common";

interface MenuListDetailsViewProps {
  id: string;
  hideHeader?: boolean;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const MenuListDetailsView = ({
  id,
  goBack,
  hideHeader = false,
}: MenuListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState<MenuFragment | null>(null);

  const [getMenu] = useGetMenuLazyQuery();

  const handleGetMenu = async () => {
    try {
      const { data } = await getMenu({
        variables: {
          menuId: id,
        },
      });

      if (data?.getMenu) {
        setMenu(JSON.parse(JSON.stringify(data.getMenu)));
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMenu();
    }, [id]),
  );

  if (loading) {
    return <Loader />;
  }

  if (!menu) {
    return (
      <Box>
        <ScreenHeader
          title="Menu"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération du menu." />
      </Box>
    );
  }

  const displayAvailability = () => {
    const { baseAvailability } = menu;

    const { isRecurring, recurringDates, specificDates } = baseAvailability;

    if (isRecurring) {
      const { days, startTime, endTime } = recurringDates;

      const formattedDays = days
        .map(day => {
          const weekDay = getWeekdayFromNumber(day);

          return `${weekDay}: ${startTime} - ${endTime}`;
        })
        .join("\n");

      return formattedDays;
    } else {
      const { startDate, endDate, startTime, endTime } = specificDates;

      const formattedStartDate = format(new Date(startDate), "dd/MM/yyyy");
      const formattedEndDate = format(new Date(endDate), "dd/MM/yyyy");

      return `${formattedStartDate} - ${formattedEndDate}: ${startTime} - ${endTime}`;
    }
  };

  const displaySteps = () => {
    const { savedMenuSteps } = menu;

    return savedMenuSteps
      .map(step => {
        const { name, products } = step;
        const stepName = name.toUpperCase();

        const productNames = products.map(product => `\t- ${product.name}`).join("\n");

        const formattedStep = `${stepName}\n${productNames}`;

        return formattedStep;
      })
      .join("\n\n");
  };

  const getStepNames = (ids: string[]) => {
    const { savedMenuSteps } = menu;

    const stepNames = savedMenuSteps
      .filter(step => ids.includes(step._id))
      .map(step => step.name)
      .join(" + ");

    return stepNames;
  };

  const displayPrices = () => {
    const { prices } = menu;

    return prices
      .map(price => {
        const { menuStepIds, basePrice } = price;

        const formattedPrice = formatCurrencyPrice(basePrice.amount, "fr", "eur");

        const stepNames = getStepNames(menuStepIds);

        return `${stepNames} : ${formattedPrice}`;
      })
      .join("\n");
  };

  const displayTags = () => {
    const { tags } = menu;

    return tags.join(" - ");
  };

  const displayImage = () => {
    const { picture } = menu;

    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  return (
    <Box flex={1}>
      {!hideHeader && (
        <ScreenHeader
          title={menu.name}
          rightButtons={displayImage()}
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      )}

      {menu.productCode && (
        <Box ml="s" mt="s" flexDirection="row" alignItems="center">
          <BARCODE height={15} width={15} fill={PALETTE.green} />
          <CustomText ml="s" variant="content" color="primaryTextColor">
            {menu.productCode}
          </CustomText>
        </Box>
      )}

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        <TabTextInfo
          {...{
            title: "Description",
            content: menu.description || "-",
            icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
        <TabTextInfo
          {...{
            title: "Contenu de la formule",
            content: displaySteps(),
            icon: <GROUP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />

        <TabTextInfo
          {...{
            title: "Prix",
            content: displayPrices(),
            icon: (
              <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        <TabTextInfo
          {...{
            title: "Activé",
            content: menu.isEnabled ? "Oui" : "Non",
            icon: (
              <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Disponibilité",
            content: displayAvailability(),
            icon: <TIMER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />

        {menu.tags.length > 0 && (
          <TabTextInfo
            {...{
              title: "Tags",
              content: displayTags(),
              icon: (
                <TAGS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}
      </ScrollView>
    </Box>
  );
};

export default MenuListDetailsView;
