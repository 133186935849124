import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  ReviewResponseFragment,
  UpdateReviewAutomaticAnswersInput,
} from "../../../../graphql/generated/schema";
import {
  useCreateReviewResponseMutation,
  useGetReviewResponseLazyQuery,
  useUpdateReviewResponseMutation,
} from "../../../../graphql/generated/schema";

interface ReviewResponsesListDetailsProps {
  newValue?: boolean;
  selectedId?: string;
  goBack?: () => void;
}

const DEFAULT_REVIEW_RESPONSE: Omit<ReviewResponseFragment, "_id"> = {
  name: "",
  answer: "",
};

const ReviewResponsesListDetails = ({
  newValue,
  selectedId,
  goBack,
}: ReviewResponsesListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const isNew = newValue || params?.isNew;
  const id = selectedId || params?.id;

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const [loading, setLoading] = useState(!isNew);
  const [reviewResponse, setReviewResponse] = useState<
    Omit<ReviewResponseFragment, "_id">
  >(DEFAULT_REVIEW_RESPONSE);

  const [getReviewResponse] = useGetReviewResponseLazyQuery();
  const [createReviewReponse] = useCreateReviewResponseMutation();
  const [updateReviewResponse] = useUpdateReviewResponseMutation();

  const handleGetReviewResponse = async () => {
    try {
      if (!isNew) {
        const { data } = await getReviewResponse({ variables: { id } });

        if (data) {
          setReviewResponse(data.getReviewAutomaticAnswer);
        }
      } else {
        setReviewResponse(DEFAULT_REVIEW_RESPONSE);
      }
    } catch (err) {
      console.log("err get review response", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetReviewResponse();
  }, []);

  const handleSubmit = async () => {
    try {
      if (isNew) {
        await createReviewReponse({ variables: { reviewAnswer: reviewResponse } });
      } else {
        if (id) {
          const { name, answer } = reviewResponse;
          const updates: UpdateReviewAutomaticAnswersInput = {
            name,
            answer,
          };

          await updateReviewResponse({ variables: { id, updates } });
        }
      }

      handleGoBack();
    } catch (err) {
      console.log("err submit", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_CREATE_REVIEW_RESPONSE",
            message:
              "Une erreur est survenue lors de la mise à jour de la réponse automatique.",
          },
        ],
        "error",
      );
    }
  };

  const updateReviewResponseData = (key: keyof ReviewResponseFragment, value: string) => {
    setReviewResponse(prev => ({ ...prev, [key]: value }));
  };

  if (loading) {
    return <Loader />;
  }

  const displayTitle = () => {
    if (isNew) {
      return "Ajouter une réponse automatique";
    }
    return "Modifier une réponse automatique";
  };

  const isDisabled = () => {
    return (
      reviewResponse.name.trim().length === 0 || reviewResponse.answer.trim().length === 0
    );
  };

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box marginVertical="m">
        <ScreenHeader title={displayTitle()} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Nom"
          isRequired
          initialValue={reviewResponse.name}
          onChangeText={t => updateReviewResponseData("name", t)}
        />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Message"
          isRequired
          initialValue={reviewResponse.answer}
          onChangeText={t => updateReviewResponseData("answer", t)}
          multiLine
        />
      </Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        backgroundColor="white"
        p="m"
        pb="l"
      >
        <CustomButton
          onPress={handleSubmit}
          buttonVariant="primaryButton"
          buttonColor={isDisabled() ? "disabled" : "success"}
          disabled={isDisabled()}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText
            variant="primaryButtonText"
            color={isDisabled() ? "lightGrey" : "white"}
          >
            Valider
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ReviewResponsesListDetails;
