/* eslint-disable @typescript-eslint/no-shadow */
import { TouchableOpacity } from "react-native";

import FILTER from "../../../assets/icons/BASE/FILTER.svg";
import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import LOCAL_BOOKING from "../../../assets/icons/BASE/LOCAL_BOOKING.svg";
import NEW from "../../../assets/icons/BASE/NEW.svg";
import PDF from "../../../assets/icons/BASE/PDF.svg";
import SEARCH from "../../../assets/icons/BASE/SEARCH.svg";
import TABLE from "../../../assets/icons/BASE/TABLE.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

export enum HEADER_ACTIONS {
  NONE,
  BACK,
  NEW,
  SEARCH,
  FILTER,
  BOOKING_FLOOR,
}

interface ListingScreenHeaderProps {
  title: string;
  // currentAction: HEADER_ACTIONS;
  hasBackButton?: boolean;
  onBackPress?: () => void;
  // onActionButtonPress: (action: HEADER_ACTIONS) => void;
  onLocalBookingPress?: () => void;
  onNewAction?: () => void;
  onSearchAction?: () => void;
  onBookingFloorAction?: () => void;
  onFilterAction?: () => void;
  onPrintAction?: () => void;
  filterBadgeCount: number;
}

const ListingScreenHeader = ({
  title,
  hasBackButton,
  onBackPress,
  // currentAction,
  // onActionButtonPress,
  onLocalBookingPress,
  onNewAction,
  onSearchAction,
  onBookingFloorAction,
  onFilterAction,
  onPrintAction,
  filterBadgeCount,
}: ListingScreenHeaderProps) => {
  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
      <Box flexDirection="row" alignItems="center">
        {hasBackButton && (
          <TouchableOpacity onPress={onBackPress}>
            <Box mr="s">
              <LEFT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </TouchableOpacity>
        )}

        <CustomText variant="title" color="primaryTextColor">
          {title}
        </CustomText>
      </Box>

      <Box flexDirection="row" alignItems="center" justifyContent="center">
        {onNewAction && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onNewAction}
          >
            <NEW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </CustomButton>
        )}
        {onLocalBookingPress && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onLocalBookingPress}
          >
            <LOCAL_BOOKING fill={PALETTE.green} />
          </CustomButton>
        )}
        {onSearchAction && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onSearchAction}
          >
            <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </CustomButton>
        )}
        {onBookingFloorAction && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onBookingFloorAction}
          >
            <TABLE height={30} width={30} fill={PALETTE.green} />
          </CustomButton>
        )}
        {onFilterAction && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onFilterAction}
          >
            <FILTER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            {filterBadgeCount > 0 ? (
              <Box
                backgroundColor="danger"
                borderRadius="button"
                position="absolute"
                top={-5}
                right={-5}
                justifyContent="center"
                alignItems="center"
                width={20}
                height={20}
              >
                <CustomText variant="content" color="white">
                  {filterBadgeCount}
                </CustomText>
              </Box>
            ) : (
              <></>
            )}
          </CustomButton>
        )}
        {onPrintAction && (
          <CustomButton
            styles={{
              mr: "s",
            }}
            onPress={onPrintAction}
          >
            <PDF height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default ListingScreenHeader;
