 
import type {
  BookingFragment,
  BookingsAndServices,
  BookingsAndServicesSlot,
} from "../../../../../graphql/generated/schema";
import {
  Booking_Status,
  Event_Type,
  Origin,
  Sex,
} from "../../../../../graphql/generated/schema";

const CREATED_AT_DATE = new Date("2022-10-08T10:00:00.000+00:00");
const BOOKING_DATE = new Date("2022-11-08T10:00:00.000+00:00");

const PAYMENT: BookingFragment["payment"] = {
  paid: false,
  cardPaymentStatus: 0,
  paymentErrorReason: "",
  paymentType: "CARD",
};

const ITEMS: BookingFragment["items"] = {
  menus: [],
  products: [],
};

const TOTAL_DETAILS: BookingFragment["totalDetails"] = {
  currency: "eur",
  total: 0,
  totalWithTaxes: 0,
};

export const BOOKINGS: BookingFragment[] = [
  {
    _id: "636a83db31fe2f2360d5ebba",
    createdAt: CREATED_AT_DATE,
    updatedAt: CREATED_AT_DATE,
    refusalReason: "",
    otpRequested: false,
    isDeleted: false,
    prepaidPaymentRequested: false,
    payment: PAYMENT,
    totalDetails: TOTAL_DETAILS,
    items: ITEMS,
    commentOwner: "",
    nbPersons: 3,
    date: BOOKING_DATE,
    source: Origin.Phone,
    serviceId: "",
    slotId: "",
    status: Booking_Status.New,
    comment: "Une table à coté de la fenêtre, s'il vous plait",
    merchantClient: {
      picture: "",
      _id: "BOOKING_1_CLIENT_1",
      sex: Sex.Male,
      firstName: "Barack",
      lastName: "OBAMA",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: true,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,

      address: undefined,
      merchantCompanyId: undefined,
    },
    tables: [],
    timeline: {
      events: [],
      messages: [],
    },
  },
  {
    _id: "63566721ab1b0694daefc1fb",
    createdAt: CREATED_AT_DATE,
    updatedAt: CREATED_AT_DATE,
    refusalReason: "",
    otpRequested: false,
    isDeleted: false,
    prepaidPaymentRequested: false,
    payment: PAYMENT,
    totalDetails: TOTAL_DETAILS,
    items: ITEMS,
    commentOwner: "",
    nbPersons: 4,
    date: BOOKING_DATE,
    source: Origin.Local,
    serviceId: "",
    slotId: "",
    status: Booking_Status.Validated,
    comment: "",
    eventType: Event_Type.Anniversary,
    merchantClient: {
      picture: "",
      _id: "BOOKING_2_CLIENT_1",
      sex: Sex.Female,
      firstName: "Scarlett",
      lastName: "JOHANSSON",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: true,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,

      address: undefined,
      merchantCompanyId: undefined,
    },
    tables: [
      {
        tableId: "TABLE_1",
        floorId: "FLOOR_1",
        prefix: "BAR",
        tableNumber: "1",
        minCapacity: 1,
      },
      {
        tableId: "TABLE_1",
        floorId: "FLOOR_1",
        prefix: "BAR",
        tableNumber: "2",
        minCapacity: 2,
      },
    ],
    timeline: {
      events: [],
      messages: [],
    },
  },
  {
    _id: "6363a51fd67de5e42e8dac92",
    createdAt: CREATED_AT_DATE,
    updatedAt: CREATED_AT_DATE,
    refusalReason: "",
    otpRequested: false,
    isDeleted: false,
    prepaidPaymentRequested: false,
    payment: PAYMENT,
    totalDetails: TOTAL_DETAILS,
    items: ITEMS,
    commentOwner: "",
    nbPersons: 2,
    date: BOOKING_DATE,
    source: Origin.Google,
    serviceId: "",
    slotId: "",
    status: Booking_Status.Seated,
    comment: "",
    merchantClient: {
      picture: "",
      _id: "BOOKING_3_CLIENT_1",
      sex: Sex.Female,
      firstName: "Keanu",
      lastName: "REEVES",
      email: "",
      secondaryEmail: "",
      phone: "",
      secondaryPhone: "",
      dateOfBirth: "",
      isVIP: false,
      comment: "",
      language: "",
      acceptedReceiveNewsLetters: false,
      acceptedReceiveMarketingSms: false,
      isBlacklisted: false,
      bookingOccurences: 20,
      ticketOccurences: 20,
      orderOccurences: 20,
      averageRating: 4.5,
      generatedProfit: 200,
      address: undefined,
      merchantCompanyId: undefined,
    },
    tables: [
      {
        tableId: "TABLE_1",
        floorId: "FLOOR_1",
        prefix: "P",
        tableNumber: "100",
        minCapacity: 3,
      },
    ],
    timeline: {
      events: [],
      messages: [],
    },
  },
];

const BOOKING_LISTING_SLOTS_PREVIEW_DATA: BookingsAndServicesSlot = {
  accumulatedTotalInSlot: BOOKINGS.reduce((acc, val) => acc + val.nbPersons, 0),
  data: BOOKINGS,
  slotId: "SLOT_1",
  slotTime: "11h00",
  totalInSlot: BOOKINGS.length,
};

export const BOOKING_LISTING_PREVIEW_DATA: BookingsAndServices = {
  accumulatedTotalInService: BOOKINGS.reduce((acc, val) => acc + val.nbPersons, 0),
  daysAvailable: [0, 1, 2, 3, 4, 5, 6],
  serviceId: "SERVICE_1",
  serviceName: "MATIN",
  slots: [BOOKING_LISTING_SLOTS_PREVIEW_DATA],
  startTime: "11h00",
  totalInService: BOOKINGS.length,
};
