import type { OrderFragment } from "../../../../graphql/generated/schema";
import Box from "../../../Base/Box";

import OnlineOrderDetailsEditTab from "./Edit";
import OnlineOrderDetailsViewTab from "./View";

interface OnlineOrderDetailsTabProps {
  isEditable: boolean;
  data: OrderFragment;
  updateData: (data: OrderFragment) => void;
}

const OnlineOrderDetailsTab = ({
  isEditable,
  data,
  updateData,
}: OnlineOrderDetailsTabProps) => {
  return (
    <Box paddingHorizontal="s">
      {isEditable ? (
        <OnlineOrderDetailsEditTab data={data} updateData={updateData} />
      ) : (
        <OnlineOrderDetailsViewTab order={data} />
      )}
    </Box>
  );
};

export default OnlineOrderDetailsTab;
