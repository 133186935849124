import { format, isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";

import PREMIUM from "../../../../assets/icons/BASE/PREMIUM.svg";
import { TABLE_SIZE } from "../../../constants/BookingFloor";
import { Booking_Status, type TableFragment } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import type { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import { determineCorrectStatusColorForCashRegister } from "../../../utils/CashRegister";
import { determineCorrectStatusColorForBooking } from "../../../utils/booking";
import { formatTimeForSeatedBooking } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { MODULE_TYPE } from "../../ModuleList/common";
import { TABLES } from "../common/TableList";

interface TableProps {
  table: TableFragment;
  isSelected: boolean;
  moduleType: MODULE_TYPE;
  showVIP?: boolean;
  showClientName?: boolean;
  showTimer?: boolean;
  scale?: number;
  selectedBookingServiceId?: string;
  bookingFloorViewType?: BOOKING_FLOOR_VIEW_TYPE;
}

const Table = ({
  table,
  isSelected,
  moduleType,
  showClientName,
  showTimer,
  showVIP,
  scale = 1,
  selectedBookingServiceId,
  bookingFloorViewType,
}: TableProps) => {
  const getBookings = () => {
    const { bookings = [] } = table;

    if (!bookings || bookings.length === 0) return [];

    return bookings;

    // let consolidatedBookings = bookings;

    // console.log("selectedBookingServiceId", selectedBookingServiceId);

    // console.log("bookings", JSON.stringify(bookings, null, 2));

    // if (!selectedBookingServiceId || selectedBookingServiceId === "ALL") {
    //   consolidatedBookings = bookings;
    // } else {
    //   consolidatedBookings = bookings?.filter(
    //     b => b.serviceId === selectedBookingServiceId,
    //   );
    // }

    // consolidatedBookings =
    //   consolidatedBookings?.filter(b => {
    //     const statuses = [
    //       Booking_Status.New,
    //       Booking_Status.ModifiedByUser,
    //       Booking_Status.ModifiedByOwner,
    //       Booking_Status.Validated,
    //       Booking_Status.AutomaticValidated,
    //       Booking_Status.Seated,
    //       Booking_Status.Arrived,
    //       Booking_Status.ArrivedWaiting,
    //       Booking_Status.Delayed,
    //     ];
    //     return statuses.includes(b.status);
    //   }) || [];

    // return consolidatedBookings;
  };

  const [seatedTimerDipslay, setSeatedTimerDipslay] = useState("");
  const [booking] = getBookings();
  const [isBookingOnCurrentDay] = useState(
    booking?.seatedAt && showTimer && isSameDay(new Date(), new Date(booking?.seatedAt)),
  );

  useEffect(() => {
    let interval: NodeJS.Timer;

    const shouldShowTimer =
      showTimer &&
      isBookingOnCurrentDay &&
      booking.seatedAt &&
      booking?.status === Booking_Status.Seated;

    if (!shouldShowTimer) return;

    interval = setInterval(() => {
      const currentDate = new Date();
      const seatedAt = new Date(booking.seatedAt);

      const seatedTimeDiff = formatTimeForSeatedBooking(currentDate, seatedAt);

      setSeatedTimerDipslay(seatedTimeDiff);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getTableLevel = () => {
    const { maxCapacity } = table;

    if (maxCapacity === 1) return 1;
    if (maxCapacity === 2) return 2;
    if (maxCapacity > 10) return 12;
    if (maxCapacity > 8) return 10;
    if (maxCapacity > 6) return 8;
    if (maxCapacity > 4) return 6;
    if (maxCapacity > 2) return 4;
  };

  const tableIcon = TABLES.find(t => {
    const level = getTableLevel();
    return t.form === table.shape && t.capacity === level;
  })?.icon;

  const getBookingFloorTableColor = () => {
    const filteredBookings = getBookings();
    const status = filteredBookings?.[0]?.status;
    const color = PALETTE[determineCorrectStatusColorForBooking(status)?.iconColor];

    return color;
  };

  const getCashRegisterFloorTableColor = () => {
    const { cashRegisterTickets = [] } = table;
    if (!cashRegisterTickets || cashRegisterTickets?.length === 0) return PALETTE.gray;

    const status = cashRegisterTickets[0]?.status;
    const color = PALETTE[determineCorrectStatusColorForCashRegister(status)?.iconColor];

    return color;
  };

  const getTableColor = () => {
    if (moduleType === MODULE_TYPE.BOOKING) return getBookingFloorTableColor();
    return getCashRegisterFloorTableColor();
  };

  const getBookingInfo = () => {
    const { maxCapacity } = table;

    if (!booking) {
      return {
        isVIP: false,
        element: (
          <Box>
            <CustomText variant="content" color="primaryTextColor">
              {maxCapacity}p
            </CustomText>
          </Box>
        ),
      };
    }

    const { merchantClient, date, nbPersons } = booking;

    const name = showClientName ? merchantClient?.lastName || "PASSAGE" : "";
    const isVIP = showVIP && !!merchantClient?.isVIP;

    let clientName = name.slice(0, 3).toUpperCase();
    clientName = clientName ? `${clientName}.` : "";

    const formattedDate = format(new Date(date), "HH:mm");

    const element = (
      <Box alignItems="center" justifyContent="center">
        <CustomText variant="text" fontSize={11} color="white">
          {clientName} {nbPersons}p
        </CustomText>
        <CustomText variant="text" fontSize={11} color="white">
          {formattedDate}
        </CustomText>
      </Box>
    );

    return {
      element,
      isVIP,
    };
  };

  const getCashRegisterInfo = () => {
    const { cashRegisterTickets = [], maxCapacity } = table;

    if (!cashRegisterTickets || cashRegisterTickets.length === 0) {
      return {
        isVIP: false,
        element: (
          <Box>
            <CustomText variant="content" color="primaryTextColor">
              {maxCapacity}p
            </CustomText>
          </Box>
        ),
      };
    }

    const [ticket] = cashRegisterTickets;

    const { merchantClient, date, nbPersons } = ticket;

    const name = merchantClient?.lastName || "PASSAGE";
    const isVIP = !!merchantClient?.isVIP;

    const clientName = name.slice(0, 3).toUpperCase();

    const formattedDate = format(new Date(date), "HH:mm");

    const element = (
      <Box alignItems="center" justifyContent="center">
        <CustomText variant="text" fontSize={11} color="white">
          {clientName}. {nbPersons}p
        </CustomText>
        <CustomText variant="text" fontSize={11} color="white">
          {formattedDate}
        </CustomText>
      </Box>
    );

    return {
      element,
      isVIP,
    };
  };

  const getTableInfo = () => {
    const { maxCapacity, cashRegisterTickets } = table;
    const filteredBookings = getBookings();

    let info = <></>;

    const HAS_INFO =
      (moduleType === MODULE_TYPE.BOOKING &&
        filteredBookings &&
        filteredBookings?.length > 0) ||
      (moduleType === MODULE_TYPE.CASH_REGISTER &&
        cashRegisterTickets &&
        cashRegisterTickets?.length > 0);

    let isClientVIP = false;

    if (moduleType === MODULE_TYPE.BOOKING) {
      const { element, isVIP } = getBookingInfo();

      info = element;
      isClientVIP = !!isVIP;
    } else if (moduleType === MODULE_TYPE.CASH_REGISTER) {
      const { element, isVIP } = getCashRegisterInfo();

      info = element;
      isClientVIP = isVIP;
    }

    if (!HAS_INFO) {
      return (
        <CustomText variant="content" color="primaryTextColor">
          {maxCapacity}p
        </CustomText>
      );
    }

    const color = HAS_INFO ? getTableColor() : PALETTE.lightGrey;

    const widthHeight = seatedTimerDipslay ? 50 : 40;

    const top = seatedTimerDipslay ? 10 : 0;

    return (
      <Box
        alignItems="center"
        justifyContent="center"
        width="60%"
        height="60%"
        top={top}
        minWidth={widthHeight}
        minHeight={widthHeight}
        borderRadius="button"
        style={{ backgroundColor: color }}
      >
        {info}

        {isClientVIP && (
          <Box position="absolute" top={-18}>
            <PREMIUM width={17} height={15} fill={PALETTE.yellow} />
          </Box>
        )}

        {seatedTimerDipslay && (
          <Box>
            <CustomText variant="text" color="white">
              {seatedTimerDipslay}
            </CustomText>
          </Box>
        )}
      </Box>
    );
  };

  const TABLE_WIDTH = TABLE_SIZE * scale + 10;
  const TABLE_HEIGHT = TABLE_SIZE * scale + 10;

  return (
    <Box
      p="xs"
      borderColor="success"
      borderRadius="button"
      width={TABLE_WIDTH}
      height={TABLE_HEIGHT}
      borderWidth={isSelected ? 2 : 0}
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        {tableIcon &&
          React.cloneElement(tableIcon, {
            fill: PALETTE.lightGrey,
            transform: [
              {
                scale,
              },
              {
                rotate: `${table?.rotationDeg}deg`,
              },
            ],
            // getTableColor()
          })}
        <Box
          position="absolute"
          top={0}
          right={-1}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            minHeight={20}
            minWidth={20}
            alignItems="center"
            justifyContent="center"
            backgroundColor="primaryTextColor"
            borderRadius="icon"
          >
            <CustomText
              variant="text"
              color="white"
              fontSize={{
                phone: 10,
                tablet: 12,
                largeTablet: 14,
              }}
            >
              {table.tableNumber}
            </CustomText>
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        alignItems="center"
        justifyContent="center"
      >
        {getTableInfo()}
      </Box>
    </Box>
  );
};

export default Table;
