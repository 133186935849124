import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { FlatList } from "react-native";

import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import Loader from "../../../../components/Loader";
import ProductCard from "../../../../components/ProductCard";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import Touchable from "../../../../components/Touchable";
import type { GetProductsWithStockEnabledQuery } from "../../../../graphql/generated/schema";
import { useGetProductsWithStockEnabledLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import StockList from "../StockList";

interface ProductsWithStockEnabledListProps {
  goBack?: () => void;
}

const ProductsWithStockEnabledList = ({ goBack }: ProductsWithStockEnabledListProps) => {
  const navigation = useNavigation();
  const [products, setProducts] = useState<
    GetProductsWithStockEnabledQuery["getProductsWithStockEnabled"]
  >([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const [getProductsWithStockEnabled] = useGetProductsWithStockEnabledLazyQuery();

  const filteredProducts = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    return products.filter(product => {
      const formattedName = product.name.toLowerCase().trim();

      return formattedName.includes(formattedSearch);
    });
  }, [search, products]);

  const handleGetProductsWithStockEnabled = async () => {
    try {
      const { data, error } = await getProductsWithStockEnabled();

      if (data?.getProductsWithStockEnabled) {
        setProducts(data?.getProductsWithStockEnabled);
      }

      setLoading(false);
    } catch (err) {
      console.log("err geting products with stock", err);
    }
  };

  useEffect(() => {
    handleGetProductsWithStockEnabled();
  }, []);

  if (loading) return <Loader />;

  const handleGoToProductStockList = (productId: string) => {
    if (goBack) {
      setSelectedId(productId);
    } else {
      navigation.navigate("STOCK_LIST", { productId });
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Mouvement de stock"
            hasBackButton
            onBackPress={handleGoBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>

          <Box mt="s">
            <FlatList
              data={filteredProducts}
              keyExtractor={item => item._id}
              renderItem={({ item }) => (
                <Touchable onPress={() => handleGoToProductStockList(item._id)}>
                  <Box mb="s">
                    <ProductCard
                      image={item.picture}
                      name={item.name}
                      price={item?.basePrice?.amount}
                      isEnabled={item.isEnabled}
                      isSelected={false}
                      disabled
                      nbCategories={item?.categories?.length}
                      nbGroupOptions={item?.groupOptions?.length}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      updateEnabled={() => {}}
                    />
                  </Box>
                </Touchable>
              )}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const handleCloseSelectedTab = async () => {
    await handleGetProductsWithStockEnabled();
    setSelectedId("");
  };

  const displaySelectedTab = () => {
    return <StockList productId={selectedId} goBack={handleCloseSelectedTab} />;
  };

  const displayContent = () => {
    if (selectedId) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return displayContent();
};

export default ProductsWithStockEnabledList;
