import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format, setDay } from "date-fns";
import React, { useContext, useState } from "react";
import { TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";

import CALENDAR from "../../../../../assets/icons/BASE/CALENDAR.svg";
import DRAGGABLE from "../../../../../assets/icons/BASE/DRAGGABLE.svg";
import LANGUAGE_FLAG from "../../../../../assets/icons/BASE/LANGUAGE_FLAG.svg";
import MODIFY from "../../../../../assets/icons/BASE/MODIFY.svg";
import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import OCCURENCES from "../../../../../assets/icons/BASE/OCCURENCES.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import Toggle from "../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { DigitalMenuFragment } from "../../../../graphql/generated/schema";
import {
  useGetDigitalMenusLazyQuery,
  useSortDigitalMenusMutation,
  useUpdateDigitalMenuMutation,
} from "../../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import { createDateWithTime, uniquefyArray } from "../../../../utils/common";
import DigitalMenuListDetails from "../DigitalMenuListDetails/index";

interface DigitalMenuListProps {
  goBack?: () => void;
}

const DigitalMenuList = ({ goBack }: DigitalMenuListProps) => {
  const navigation = useNavigation();
  const errorInfoSuccessAlertContext = useContext(ErrorInfoSuccessAlertModalContext);

  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState<DigitalMenuFragment[]>([]);
  const [menuId, setMenuId] = useState("");
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);
  const [isNew, setIsNew] = useState(false);

  const [getMenus, { fetchMore }] = useGetDigitalMenusLazyQuery();
  const [updateMenu] = useUpdateDigitalMenuMutation();
  const [sortMenus] = useSortDigitalMenusMutation();

  const handleFetchMore = async () => {
    try {
      const result = await fetchMore({
        variables: {
          pagination: {
            limit: 20,
            offset: menus.length,
          },
        },
      });

      if (result.data?.getDigitalMenus) {
        setMenus(prev => uniquefyArray([...prev, ...result.data.getDigitalMenus]));
      }
    } catch (err) {}
  };

  const handleGetMenus = async () => {
    try {
      const result = await getMenus({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (result.data?.getDigitalMenus) {
        setMenus(result.data.getDigitalMenus);
      }
    } catch (err) {
      console.log("AN_ERROR_OCCURED", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetMenus();
    }, []),
  );

  const handleNavigateToCreateMenu = () => {
    if (goBack) {
      setIsNew(true);
      setMenuId("");
      setSelectedTab("DIGITAL_MENUS_LIST_DETAILS");
    } else {
      navigation.navigate("DIGITAL_MENUS_LIST_DETAILS", {
        isNew: true,
      });
    }
  };

  const handleEditMenu = (id: string) => {
    if (goBack) {
      setSelectedTab("DIGITAL_MENUS_LIST_DETAILS");
      setMenuId(id);
      setIsNew(false);
    } else {
      navigation.navigate("DIGITAL_MENUS_LIST_DETAILS", {
        isNew: false,
        id,
      });
    }
  };

  const handleToggleMenu = async (id: string, isEnabled: boolean) => {
    try {
      setMenus(prev =>
        prev.map(menu => {
          if (menu._id === id) {
            return {
              ...menu,
              isEnabled,
            };
          }
          return menu;
        }),
      );

      await updateMenu({
        variables: {
          digitalMenuId: id,
          digitalMenu: {
            isEnabled,
          },
        },
      });
    } catch (err) {
      console.log("AN_ERROR_OCCURED_WHILE_UPDATING_DIGITAL_MENU", err);
      errorInfoSuccessAlertContext.openAlert(
        "Erreur",
        [
          {
            code: "UPDATE_DIGITAL_MENU_ERROR",
            message: "Une erreur est survenue lors de la mise à jour du menu.",
          },
        ],
        "error",
      );
    }
  };

  const displayDays = (item: DigitalMenuFragment["baseAvailability"]) => {
    const { isRecurring, recurringDates, specificDates } = item;

    if (isRecurring) {
      return recurringDates.days
        .slice()
        .sort((a, b) => a - b)
        .map(day => {
          const date = setDay(new Date(), day);
          return format(date, "EEEE.");
        })
        .join(" | ");
    } else {
      const start = format(new Date(specificDates.startDate), "dd/MM/yyyy");
      const end = format(new Date(specificDates.endDate), "dd/MM/yyyy");

      return `${start} - ${end}`;
    }
  };

  const displayTime = (item: DigitalMenuFragment["baseAvailability"]) => {
    const { isRecurring, recurringDates, specificDates } = item;

    if (isRecurring) {
      const { startTime, endTime } = recurringDates;
      const start = createDateWithTime(new Date(), startTime);
      const end = createDateWithTime(new Date(), endTime);

      return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
    } else {
      const { startTime, endTime } = specificDates;
      const start = createDateWithTime(new Date(), startTime);
      const end = createDateWithTime(new Date(), endTime);

      return `${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
    }
  };

  const showAvailability = (availability: DigitalMenuFragment["baseAvailability"]) => {
    const days = displayDays(availability);
    const times = displayTime(availability);

    return `${days} | ${times}`;
  };

  const handleSortMenus = async (sortedMenus: DigitalMenuFragment[]) => {
    setMenus(sortedMenus);

    try {
      const sortedMenuIds = sortedMenus.map(menu => menu._id);

      await sortMenus({
        variables: {
          sortedDigitalMenus: {
            digitalMenuIds: sortedMenuIds,
          },
        },
      });
    } catch (err) {
      console.log("ERROR_SORTING_DIGITAL_MENUS", err);
    }
  };

  const displayMenuList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Carte digitale"
          description="Déplacez les cartes pour changer leur ordre d'apparition en ligne."
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
          rightButtons={
            <TouchableOpacity onPress={handleNavigateToCreateMenu}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
        />

        <Box flex={1}>
          <DraggableFlatList
            data={menus}
            keyExtractor={item => item._id}
            onEndReached={handleFetchMore}
            onDragEnd={({ data }) => handleSortMenus(data)}
            renderItem={({ item, drag }) => (
              <TouchableOpacity
                style={{
                  marginTop: 10,
                }}
                onPress={() => handleEditMenu(item._id)}
              >
                <Box
                  flex={1}
                  backgroundColor="disabled"
                  borderRadius="button"
                  flexDirection="row"
                  alignItems="center"
                  p="s"
                >
                  <TouchableOpacity onPressIn={drag}>
                    <Box
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      paddingHorizontal="xs"
                    >
                      <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
                    </Box>
                  </TouchableOpacity>
                  <Box pl="s" flex={1}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CustomText variant="label" color="primaryTextColor">
                        {item.name}
                      </CustomText>
                      <Box mb="s" flexDirection="row" alignItems="center">
                        <Box mr="s">
                          <Toggle
                            value={item.isEnabled}
                            onChange={v => handleToggleMenu(item._id, v)}
                          />
                        </Box>
                        <MODIFY width={18} height={18} fill={PALETTE.darkBlue} />
                      </Box>
                    </Box>
                    <Box mb="s" flexDirection="row" alignItems="center">
                      <LANGUAGE_FLAG width={18} height={18} fill={PALETTE.green} />
                      <CustomText ml="s" variant="text" color="primaryTextColor">
                        Langue: Française
                      </CustomText>
                    </Box>

                    {!item.isAlwaysVisible && (
                      <Box mb="s" flexDirection="row" alignItems="center">
                        <CALENDAR width={18} height={18} fill={PALETTE.green} />
                        <CustomText ml="s" variant="text" color="primaryTextColor">
                          Disponibilité: {showAvailability(item.baseAvailability)}
                        </CustomText>
                      </Box>
                    )}

                    <Box mb="s" flexDirection="row" alignItems="center">
                      <OCCURENCES width={18} height={18} fill={PALETTE.green} />
                      <CustomText ml="s" variant="text" color="primaryTextColor">
                        Derniére mise à jour:{" "}
                        {format(new Date(item.updatedAt), "dd/MM/yyyy")}
                      </CustomText>
                    </Box>
                  </Box>
                </Box>
              </TouchableOpacity>
            )}
          />
        </Box>
      </Box>
    );
  };

  const handleGoBack = () => setSelectedTab(undefined);

  const displaytSelectedTab = () => {
    switch (selectedTab) {
      case "DIGITAL_MENUS_LIST_DETAILS":
        return <DigitalMenuListDetails isNew={isNew} id={menuId} goBack={handleGoBack} />;

      default:
        break;
    }
  };

  if (loading) return <Loader />;

  const displayContent = () => {
    if (!selectedTab) return displayMenuList();

    return displaytSelectedTab();
  };

  return <>{displayContent()}</>;
};

export default DigitalMenuList;
