import { useNavigation } from "@react-navigation/native";
import type { Dispatch, SetStateAction} from "react";
import { useContext } from "react";
import { TouchableOpacity } from "react-native";

import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateOnlineSalesAvailabilityInput,
  OnlineSalesServiceSlot
} from "../../../graphql/generated/schema";
import { useGenerateOnlineSalesAvailabilitySlotsMutation } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import type { ERROR } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface OrderSlotsProps {
  service: CreateOnlineSalesAvailabilityInput;
  setService: Dispatch<SetStateAction<CreateOnlineSalesAvailabilityInput>>;
  errors: ERROR[];
  id?: string;
  onPressSlots: () => Promise<boolean>;
}

const OrderSlots = ({ service, setService, errors, id ,onPressSlots}: OrderSlotsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext)

  const [generateSlots] = useGenerateOnlineSalesAvailabilitySlotsMutation();

  const handleGenerateSlots = async () => {
    try {
      const { data } = await generateSlots({
        variables: {
          onlineSalesAvailability: service,
          availabilityId: id,
        },
      });

      return data?.generateOnlineSalesAvailabilitySlots || [];
    } catch (err) {
      console.log("err generate slots", err);
    }
  };

  const handleUpdateSlotsSubmit = (slots: OnlineSalesServiceSlot[]) => {
    setService(prev => ({
      ...prev,
      slots,
    }));
  };

  const handleViewSlots = async () => {
    try {

      const canViewSlots = await onPressSlots();


      if (!canViewSlots) return infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "INVALID_SERVICE_FOR_SLOT_GENERATION",
            message:"Le service n'est pas rempli correctement pour générer les créneaux"
          }
        ],
        "error"
      )

      const {
        slots = [],
        visibilitySetting: visibilitySettings,
        totalOrdersAllowed,
        maxOrdersAllowedForSlot
      } = service;

      let consolidatedSlots = slots;

      if (!slots?.length) {
        const generatedSlots = await handleGenerateSlots();

        if (generatedSlots) consolidatedSlots = generatedSlots;
      }

      navigation.navigate("TAKE_AWAY_SERVICE_SLOTS_LIST", {
        slots: consolidatedSlots,
        visibilitySettings,
        totalService: totalOrdersAllowed,
        totalSlot: maxOrdersAllowedForSlot,
        service,
        onUpdateSlotsSubmit: handleUpdateSlotsSubmit,
      });

    } catch (err) {}
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          PERSONNALISER LES CRÉNEAUX
        </CustomText>
      </Box>
      <Box mt="s">
        <CustomText variant="text" color="lightGrey">
          Lorsque vous créez un service, celui-ci est automatiquement découpé en créneaux
          de 15 minutes. Tous les créneaux sont activés et prennent les valeurs par défaut
          de votre service. Vous pouvez fermer ces créneaux ou bien les personnaliser
          selon votre besoin. Fermer un créneau revient à le rendre indisponible sur votre
          module de commande, mais ne vous empêche pas de prendre des commandes
          manuellement dans votre cahier de commande.
        </CustomText>
        <TouchableOpacity onPress={handleViewSlots}>
          <Box
            mt="m"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Liste des créneaux
            </CustomText>

            <RIGHT_ARROW height={16} width={16} fill={PALETTE.green} />
          </Box>
          <CustomText variant="text" color="lightGrey">
            Personnaliser les créneaux
          </CustomText>
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default OrderSlots;
