import { useEffect, useState } from "react";
import { Platform } from "react-native";

import TABLE from "../../../assets/icons/BASE/TABLE.svg";
import TAGS from "../../../assets/icons/BASE/TAGS.svg";
import type { Booking_Status } from "../../graphql/generated/schema";
import { useGetPersonsRangeBookingsLazyQuery } from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

const IS_ANDROID = Platform.OS === "android";

interface BookingSummaryByPersonsProps {
  date: Date;
  serviceId: string | null;
  styles?: React.ComponentProps<typeof Box>;
  itemMinWidth?: number | string;
  refreshData?: boolean;
  selectedStatuses?: Booking_Status[];
  selectedFloorId?: string | null;
}

export const BookingSummaryKeys = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12+",
];
interface BookingSummary {
  key: string;
  value: number;
}

const BookingSummaryByPersons = ({
  date,
  serviceId,
  styles,
  itemMinWidth,
  refreshData,
  selectedStatuses,
  selectedFloorId,
}: BookingSummaryByPersonsProps) => {
  const [bookingsByPersonsRange, setBookingsByPersonsRange] = useState<BookingSummary[]>(
    [],
  );

  const [getBookingsByPersonsRange] = useGetPersonsRangeBookingsLazyQuery();

  const handleGetPersonsRangeBookings = async () => {
    try {
      const { data } = await getBookingsByPersonsRange({
        fetchPolicy: "cache-and-network",
        variables: {
          date,
          serviceId: serviceId === "ALL" ? null : serviceId,
          statuses: selectedStatuses,
          selectedFloorId,
        },
      });

      setBookingsByPersonsRange(data?.getPersonsRangeBookings || []);
    } catch (err) {}
  };

  useEffect(() => {
    handleGetPersonsRangeBookings();
  }, [date, serviceId, refreshData, selectedStatuses, selectedFloorId]);

  return (
    <Box
      p="s"
      backgroundColor="black"
      {...styles}
      flex={1}
      maxHeight={70}
      paddingHorizontal="l"
      mb={IS_ANDROID ? "l" : "none"}
    >
      <Box flexDirection="row" alignItems="center">
        <Box>
          <TABLE width={16} height={16} fill={PALETTE.white} />
        </Box>
        <Box flexDirection="row" alignItems="center">
          {bookingsByPersonsRange.map(item => {
            return (
              <Box
                key={item.key}
                minWidth={itemMinWidth}
                alignItems="center"
                justifyContent="flex-start"
              >
                <CustomText variant="content" color="white">
                  {item.key}
                </CustomText>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box flexDirection="row" alignItems="center">
        <Box>
          <TAGS width={16} height={16} fill={PALETTE.white} />
        </Box>
        <Box flexDirection="row" alignItems="center">
          {bookingsByPersonsRange.map(item => {
            return (
              <Box
                key={item.key}
                minWidth={itemMinWidth}
                alignItems="center"
                justifyContent="flex-start"
              >
                <CustomText variant="content" color="white">
                  {item.value}
                </CustomText>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingSummaryByPersons;
