import type { MaterialTopTabNavigationOptions } from "@react-navigation/material-top-tabs";

import { PALETTE } from "../../theme/Palette";

export const TAB_CONFIG: MaterialTopTabNavigationOptions = {
  swipeEnabled: false,
  tabBarActiveTintColor: PALETTE.green,
  tabBarInactiveTintColor: PALETTE.darkBlue,
  tabBarIndicatorStyle: {
    backgroundColor: PALETTE.green,
  },
  tabBarContentContainerStyle: {
    flex: 1,
  },
  tabBarItemStyle: {
    height: 55,
  },
  tabBarLabelStyle: {
    textTransform: "capitalize",
  },
};
