import { Image } from "react-native";

import GROUP from "../../../assets/icons/BASE/GROUP.svg";
import LINKS from "../../../assets/icons/BASE/LINKS.svg";
import PAX_PERSONS from "../../../assets/icons/BASE/PAX_PERSONS.svg";
import PRICE_TAG from "../../../assets/icons/BASE/PRICE_TAG.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface ProductCardProps {
  name: string;
  price: number;
  isEnabled: boolean;
  updateEnabled: (isEnabled: boolean) => void;
  image: string;
  nbCategories: number;
  nbGroupOptions: number;
  disabled?: boolean;
  isSelected: boolean;
  stock?: string;
  showEnableToggle?: boolean;
}

const IMAGE_HEIGHT = 47;
const CARD_HEIGHT = 140;

const ProductCard = ({
  name,
  image,
  isEnabled,
  updateEnabled,
  price,
  nbCategories,
  nbGroupOptions,
  disabled = false,
  isSelected,
  stock,
  showEnableToggle = true,
}: ProductCardProps) => {
  const formattedPrice = formatCurrencyPrice(price, "fr", "eur");

  return (
    <Box
      p="s"
      borderColor={isSelected ? "success" : "disabled"}
      backgroundColor={isSelected ? "white" : "disabled"}
      borderRadius="button"
      borderWidth={LINE_THICKNESS}
      // height={CARD_HEIGHT}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="primaryTextColor" mb="s">
          {name}
        </CustomText>

        {image && (
          <Image
            source={{
              uri: image,
            }}
            style={{
              width: IMAGE_HEIGHT,
              height: IMAGE_HEIGHT,
              position: "absolute",
              right: 0,
              top: -18,
              borderRadius: IMAGE_HEIGHT / 2,
            }}
          />
        )}
      </Box>

      {showEnableToggle ? (
        <>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="s"
          >
            <Box flexDirection="row" alignItems="center" pr="s">
              <Toggle value={isEnabled} onChange={updateEnabled} />

              <CustomText variant="content" pl="s" color="primaryTextColor">
                Activé
              </CustomText>
            </Box>

            <Box flexDirection="row" alignItems="center">
              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <GROUP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {nbCategories}
                </CustomText>
              </Box>

              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {nbGroupOptions}
                </CustomText>
              </Box>
            </Box>
          </Box>

          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box
              flexDirection="row"
              alignItems="center"
              backgroundColor={isSelected ? "disabled" : "white"}
              borderRadius="button"
              p="s"
            >
              <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              <CustomText ml="s" variant="label" color="primaryTextColor">
                {formattedPrice}
              </CustomText>
            </Box>
            {stock && (
              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {stock}
                </CustomText>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="s"
          >
            <Box
              flexDirection="row"
              alignItems="center"
              backgroundColor={isSelected ? "disabled" : "white"}
              borderRadius="button"
              p="s"
            >
              <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              <CustomText ml="s" variant="label" color="primaryTextColor">
                {formattedPrice}
              </CustomText>
            </Box>
            {stock && (
              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {stock}
                </CustomText>
              </Box>
            )}

            <Box flexDirection="row" alignItems="center">
              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {nbCategories}
                </CustomText>
              </Box>

              <Box
                ml="m"
                flexDirection="row"
                alignItems="center"
                backgroundColor={isSelected ? "disabled" : "white"}
                borderRadius="button"
                p="s"
              >
                <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText ml="s" variant="label" color="primaryTextColor">
                  {nbGroupOptions}
                </CustomText>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProductCard;
