import { TouchableOpacity } from "react-native";

import INFO from "../../../../assets/icons/BASE/INFO.svg";
import type { MerchantPersonnelPermissionGroupFragment } from "../../../graphql/generated/schema";
import { Modules } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

const ICON_SIZE = 20;
interface PermissionGroupCardProps {
  isSelected: boolean;
  permissionGroup: MerchantPersonnelPermissionGroupFragment;
  onPress: () => void;
}

const PermissionGroupCard = ({
  isSelected,
  permissionGroup,
  onPress,
}: PermissionGroupCardProps) => {
  const formatModuleName = (moduleName: Modules) => {
    switch (moduleName) {
      case Modules.All:
        return "Tous les modules";
      case Modules.CashRegister:
        return "Caisse enregistreuse";
      case Modules.Delivery:
        return "Livraison";
      case Modules.DigitalMenu:
        return "Menu digital";
      case Modules.Invoices:
        return "Factures";
      case Modules.MerchantClients:
        return "Clients";
      case Modules.QrCode:
        return "QR Code";
      case Modules.Quotes:
        return "Devis";
      case Modules.OnlineSales:
        return "Ventes en ligne";
      case Modules.Publishing:
        return "Publication";
      case Modules.PopCall:
        return "PopCall";
      case Modules.Reservation:
        return "Réservation";
      case Modules.ReviewResponse:
        return "Réponse aux avis";
      case Modules.TableOrder:
        return "Commande de table";
      case Modules.TablePayment:
        return "Paiement à table";

      default:
        break;
    }
    return "-";
  };

  const displayPerimissions = () => {
    const { permissions } = permissionGroup;
    if (permissions.length === 0) {
      return "-";
    }
    return permissions.map(permission => formatModuleName(permission.module)).join(", ");
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        width="100%"
        borderColor={"success"}
        backgroundColor={isSelected ? "white" : "disabled"}
        borderWidth={isSelected ? 1 : 0}
        borderRadius="button"
        p="s"
      >
        <Box mb="s" flexDirection="row" alignItems="center">
          <CustomText pr="s" variant="label">
            {permissionGroup.name}
          </CustomText>
        </Box>
        <Box mb="s" flexDirection="row" alignItems="center">
          <INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />

          <CustomText variant="description" pl="s">
            {displayPerimissions()}
          </CustomText>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default PermissionGroupCard;
