import { format } from "date-fns";
import React from "react";

import COMMENT from "../../../../assets/icons/BASE/COMMENT.svg";
import type { BookingFragment, Booking_Status } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import { displayModuleId } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import BookingListCardStatusButton from "../../BookingList/BookingListCardStatusButton";
interface BookingOrderAlertCardProps {
  data: BookingFragment;
  boxStyles?: React.ComponentProps<typeof Box>;
  onCardPress: (id: string) => void;
  onPress: (
    newStatus: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => void;
}

const HEIGHT = 92;
const ICON_SIZE = 15;

const BookingAlertCard = ({
  data,
  boxStyles,
  onPress,
  onCardPress,
}: BookingOrderAlertCardProps) => {
  const {
    _id: itemId,
    merchantClient,
    date,
    nbPersons,
    source,
    status,
    tables,
    commentOwner,
  } = data;

  const formattedDate = format(new Date(date), "dd/MM/yyy - HH:mm");
  const formattedClientName = `${merchantClient?.lastName} ${merchantClient?.firstName}`;

  return (
    <Box flexDirection="row" alignItems="center" {...boxStyles}>
      {/* <TouchableOpacity style={{ flex: 1 }} onPress={() => onCardPress(itemId)}> */}
      <Box
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          minHeight: HEIGHT,
        }}
      >
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <BookingListCardOrigin origin={source} />

          <CustomText
            variant="listItemMainText"
            numberOfLines={1}
            style={{
              width: 110,
            }}
          >
            {formattedClientName}
          </CustomText>
          <CustomText variant="listItemSubText">{`x${nbPersons}`}</CustomText>
          <CustomText variant="listItemSubText">{formattedDate}</CustomText>

          <BookingListCardStatusButton status={status} onPress={onPress} />
        </Box>
        <Box flexDirection="row" alignItems="center" pt="s">
          <BookingLIstCardClientFidelity
            occurences={merchantClient?.bookingOccurences || 0}
          />

          <CustomText paddingHorizontal="m" variant="listItemSubText">
            {displayModuleId(itemId)}
          </CustomText>

          {commentOwner && (
            <Box flexDirection="row" paddingHorizontal="m">
              <COMMENT width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.darkBlue} />

              <CustomText pl="s" variant="listItemSubText">
                {commentOwner}
              </CustomText>
            </Box>
          )}
        </Box>
      </Box>
      {/* </TouchableOpacity> */}
    </Box>
  );
};

export default BookingAlertCard;
