import { useEffect } from "react";
import { Dimensions } from "react-native";
import type { PanGestureHandlerGestureEvent } from "react-native-gesture-handler";
import { PanGestureHandler } from "react-native-gesture-handler";
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { clamp } from "react-native-redash";

import {
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
  BOOKING_FLOOR_HEADER_HEIGHT,
  BOOKING_FLOOR_TABLE_BASE_SIZE,
  BOOKING_FLOOR_TABLE_MAX_SCALE,
  BOOKING_FLOOR_TABLE_MIN_SCALE,
  TABLE_SIZE,
} from "../../../constants/BookingFloor";
import type { TableFragment } from "../../../graphql/generated/schema";
import type { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import { HEADER_HEIGHT } from "../../Header/index";
import type { MODULE_TYPE } from "../../ModuleList/common";
import Touchable from "../../Touchable";
import Table from "../Table";

const { width, height: SCREEN_HEIGHT } = Dimensions.get("window");

interface AnimatedFloorItemProps {
  isSelected: boolean;
  selectTable: (id: string) => void;
  table: TableFragment;
  updateTable: (id: string, table: TableFragment) => void;
  disableMovement?: boolean;
  moduleType: MODULE_TYPE;
  showVIP?: boolean;
  showClientName?: boolean;
  showTimer?: boolean;
  floorWidth: number;
  floorHeight: number;
  selectedBookingServiceId?: string;
  isSplittable?: boolean;
  bookingFloorViewType?: BOOKING_FLOOR_VIEW_TYPE;
}

const AnimatedFloorItem = ({
  table,
  selectTable,
  isSelected,
  updateTable,
  disableMovement = false,
  moduleType,
  showVIP,
  showClientName,
  showTimer,
  floorHeight,
  floorWidth,
  selectedBookingServiceId,
  isSplittable = false,
  bookingFloorViewType,
}: AnimatedFloorItemProps) => {
  // const { x, y } = floorContainerDimensions;
  // const boundX = width - 100;
  // const boundY = height - 100;

  // console.log("floor", floorLayout);
  // console.log("table", table.tableNumber, table?.positionX, table?.positionY);
  const SCREEN_WIDTH = isSplittable ? width * 0.7 : width;

  const FLOOR_AVAILABLE_WIDTH = SCREEN_WIDTH - TABLE_SIZE;

  const FLOOR_AVAILABLE_HEIGHT =
    SCREEN_HEIGHT -
    HEADER_HEIGHT -
    BOOKING_FLOOR_HEADER_HEIGHT -
    BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT -
    TABLE_SIZE -
    20;

  const handleTabeleScaleBoundaryCheck = (s: number) => {
    if (s < BOOKING_FLOOR_TABLE_MIN_SCALE) {
      return BOOKING_FLOOR_TABLE_MIN_SCALE;
    }

    if (s > BOOKING_FLOOR_TABLE_MAX_SCALE) {
      return BOOKING_FLOOR_TABLE_MAX_SCALE;
    }

    return s;
  };

  const tableScale = () => {
    const xScale = handleTabeleScaleBoundaryCheck(SCREEN_WIDTH / floorWidth);
    const yScale = handleTabeleScaleBoundaryCheck(FLOOR_AVAILABLE_HEIGHT / floorHeight);

    return Math.max(xScale, yScale);
  };
  const scaleForTable = tableScale();

  // const FLOOR_HEIGHT =
  //   floorHeight > FLOOR_AVAILABLE_HEIGHT ? FLOOR_AVAILABLE_HEIGHT : floorHeight;
  // const FLOOR_WIDTH =
  //   floorWidth > FLOOR_AVAILABLE_WIDTH ? FLOOR_AVAILABLE_WIDTH : floorWidth;

  // console.log("FLOOR_AVAILABLE_WIDTH", FLOOR_AVAILABLE_WIDTH, FLOOR_AVAILABLE_HEIGHT);

  const LOWER_BOUND_X = 0;
  const LOWER_BOUND_Y = 0;

  const UPPER_BOUND_X = FLOOR_AVAILABLE_WIDTH;
  const UPPER_BOUND_Y = FLOOR_AVAILABLE_HEIGHT;

  const repositionTableXPosition = (x: number) => {
    // const x = table?.positionX;

    if (!x) return 50;

    // return x;

    if (!disableMovement) return x;

    return ((x / floorWidth) * SCREEN_WIDTH) / scaleForTable;
  };

  const repositionTableYPosition = (y: number) => {
    // const y = table?.positionY;

    if (!y) return 50;
    // return y;

    if (!disableMovement) return y;

    return ((y / floorHeight) * FLOOR_AVAILABLE_HEIGHT) / scaleForTable;
  };

  const isTableMoving = useSharedValue(false);
  const translateX = useSharedValue(
    clamp(repositionTableXPosition(table?.positionX), LOWER_BOUND_X, UPPER_BOUND_X),
  );
  const translateY = useSharedValue(
    clamp(repositionTableYPosition(table?.positionY), LOWER_BOUND_Y, UPPER_BOUND_Y),
  );

  useEffect(() => {
    translateX.value = clamp(
      repositionTableXPosition(table?.positionX),
      LOWER_BOUND_X,
      UPPER_BOUND_X,
    );
    translateY.value = clamp(
      repositionTableYPosition(table?.positionY),
      LOWER_BOUND_Y,
      UPPER_BOUND_Y,
    );
  }, [floorHeight, floorWidth]);

  // console.log("SCREEN_WIDTH", SCREEN_WIDTH);

  // console.log("pos", table.tableNumber, translateX.value, translateY.value);

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    {
      offsetX: number;
      offsetY: number;
      positionX: number;
      positionY: number;
    }
  >({
    onStart: (_, ctx) => {
      ctx.offsetX = translateX.value;
      ctx.offsetY = translateY.value;
      isTableMoving.value = true;
    },
    onActive: (event, ctx) => {
      const x = event.translationX;
      const y = event.translationY;

      const xValue = clamp(ctx.offsetX + x, LOWER_BOUND_X, UPPER_BOUND_X);
      const yValue = clamp(ctx.offsetY + y, LOWER_BOUND_Y, UPPER_BOUND_Y);

      translateX.value = xValue;
      translateY.value = yValue;
    },
    onEnd: (event, ctx) => {
      isTableMoving.value = false;
      const positionX = Math.round(translateX.value);
      const positionY = Math.round(translateY.value);

      runOnJS(updateTable)(table._id, {
        ...table,
        positionX,
        positionY,
      });
    },
  });

  // console.log("pos", table.tableNumber, table?.positionY, table?.positionX);
  // console.log("pos translated", table.tableNumber, translateY.value, translateX.value);

  // console.log("floor", floorHeight, floorWidth);
  // console.log("scrren widtg", FLOOR_AVAILABLE_HEIGHT, SCREEN_WIDTH);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateX.value }, { translateY: translateY.value }],
    };
  });

  return (
    <PanGestureHandler enabled={!disableMovement} {...{ onGestureEvent }}>
      <Animated.View
        style={[
          animatedStyles,
          {
            width: BOOKING_FLOOR_TABLE_BASE_SIZE,
            height: BOOKING_FLOOR_TABLE_BASE_SIZE,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          },
        ]}
      >
        <Touchable
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: BOOKING_FLOOR_TABLE_BASE_SIZE,
            height: BOOKING_FLOOR_TABLE_BASE_SIZE,
          }}
          onPress={() => selectTable(table._id)}
        >
          <Table
            table={table}
            isSelected={isSelected}
            moduleType={moduleType}
            showClientName={showClientName}
            showVIP={showVIP}
            showTimer={showTimer}
            scale={scaleForTable}
            selectedBookingServiceId={selectedBookingServiceId}
            bookingFloorViewType={bookingFloorViewType}
          />
        </Touchable>
      </Animated.View>
    </PanGestureHandler>
  );
};

export default AnimatedFloorItem;
