import type { FlatListProps } from "react-native";
import { FlatList } from "react-native";

interface PaginatedListProps<T> extends FlatListProps<T> {
  handleLoadMore: () => void;
}

const PaginatedList = <T,>({ handleLoadMore, ...props }: PaginatedListProps<T>) => {
  return (
    <FlatList {...props} onEndReachedThreshold={0.5} onEndReached={handleLoadMore} />
  );
};

export default PaginatedList;
