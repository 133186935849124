import { useRoute } from "@react-navigation/native";
import { useState } from "react";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import { CustomTextInput } from "../TextInput";

const AddressDetails = () => {
  const route = useRoute();
  const { params } = route;

  const address = params?.address;
  const onSubmit = params?.onSubmit;
  const onDelete = params?.onDelete;

  const [number, setNumber] = useState(address?.number || "");
  const [street, setStreet] = useState(address?.street || "");
  const [city, setCity] = useState(address?.city || "");
  const [country, setCountry] = useState(address?.country || "");
  const [zipCode, setZipCode] = useState(address?.zipCode || "");

  const handleOnSubmit = () => {
    const formattedNumber = number.trim();
    const formattedStreet = street.trim();
    const formattedCity = city.trim();
    const formattedCountry = country.trim();
    const formattedZipCode = zipCode.trim();

    // eslint-disable-next-line max-len
    const formattedAddress = `${formattedNumber} ${formattedStreet}, ${formattedZipCode} ${formattedCity}, ${formattedCountry}`;

    onSubmit({
      number: formattedNumber,
      street: formattedStreet,
      city: formattedCity,
      country: formattedCountry,
      zipCode: formattedZipCode,
      formattedAddress,
    });
  };

  const handleOnDelete = () => {
    // onDelete(userAddress?._id);
  };

  return (
    <Box flex={1} backgroundColor="white" paddingHorizontal="s">
      <Box mt="m" p="s">
        <CustomTextInput
          placeHolder="Numéro"
          initialValue={number}
          onChangeText={setNumber}
        />
      </Box>
      <Box p="s">
        <CustomTextInput
          placeHolder="Rue"
          initialValue={street}
          onChangeText={setStreet}
        />
      </Box>
      <Box p="s">
        <CustomTextInput
          placeHolder="Code postal"
          initialValue={zipCode}
          onChangeText={setZipCode}
        />
      </Box>
      <Box p="s">
        <CustomTextInput placeHolder="Ville" initialValue={city} onChangeText={setCity} />
      </Box>
      <Box p="s">
        <CustomTextInput
          placeHolder="Pays"
          initialValue={country}
          onChangeText={setCountry}
        />
      </Box>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        pb="l"
        p="s"
        backgroundColor="white"
      >
        {onSubmit && (
          <CustomButton
            buttonVariant="primaryButton"
            onPress={handleOnSubmit}
            buttonColor="success"
          >
            <CustomText variant="primaryButtonText" color="white">
              Enregister
            </CustomText>
          </CustomButton>
        )}

        {onDelete && (
          <CustomButton
            styles={{ mt: "s" }}
            buttonVariant="outlineButton"
            onPress={handleOnDelete}
            borderColor="danger"
          >
            <CustomText variant="primaryButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default AddressDetails;
