import { format } from "date-fns";
import { TouchableOpacity } from "react-native";

import DUPLICATE from "../../../../assets/icons/BASE/DUPLICATE.svg";
import PDF from "../../../../assets/icons/BASE/PDF.svg";
import TRASH from "../../../../assets/icons/BASE/TRASH.svg";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import Toggle from "../../Toggle";

interface PdfTemplateItemCardProps {
  name: string;
  updatedAt: Date;
  isEnabled: boolean;
  onUpdatedEnabled: (isEnabled: boolean) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
  // onPreview: () => void;
}

const PdfTemplateItemCard = ({
  name,
  updatedAt,
  isEnabled,
  onUpdatedEnabled,
  onDelete,
  onDuplicate,
  onEdit,
}: // onPreview,
PdfTemplateItemCardProps) => {
  return (
    <TouchableOpacity onPress={onEdit}>
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          <Toggle value={isEnabled} onChange={onUpdatedEnabled} />

          <Box ml="s">
            <PDF height={19} width={19} fill={PALETTE.darkGrey} />
          </Box>
          <Box ml="s">
            <CustomText variant="label" color="primaryTextColor">
              {name}
            </CustomText>
            <CustomText variant="content" color="lightGrey">
              Modifié le {format(new Date(updatedAt), "dd MMM yyyy")}
            </CustomText>
          </Box>
        </Box>

        <Box flexDirection="row" alignItems="center">
          {/* <Box ml="s">
          <TouchableOpacity onPress={onPreview}>
            <SEARCH height={20} width={20} fill={PALETTE.lightGrey} />
          </TouchableOpacity>
        </Box> */}
          <Box ml="s">
            <TouchableOpacity onPress={onDuplicate}>
              <DUPLICATE height={24} width={24} fill={PALETTE.lightGrey} />
            </TouchableOpacity>
          </Box>
          <Box ml="s">
            <TouchableOpacity onPress={onDelete}>
              <TRASH height={20} width={20} fill={PALETTE.red} />
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default PdfTemplateItemCard;
