import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import MODULE_AVAILABILITY from "../../../../../assets/icons/BASE/MODULE_AVAILABILITY.svg";
import MODULE_CLIENT_SETTINGS from "../../../../../assets/icons/BASE/MODULE_CLIENT_SETTINGS.svg";
import MODULE_CLOSING from "../../../../../assets/icons/BASE/MODULE_CLOSING.svg";
import MODULE_FORM from "../../../../../assets/icons/BASE/MODULE_FORM.svg";
import MODULE_LISTING from "../../../../../assets/icons/BASE/MODULE_LISTING.svg";
import MODULE_SETTINGS from "../../../../../assets/icons/BASE/MODULE_SETTINGS.svg";
import PDF from "../../../../../assets/icons/BASE/PDF.svg";
import TABLE from "../../../../../assets/icons/BASE/TABLE.svg";
import Box from "../../../../components/Base/Box";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE, LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import BookingClientSettings from "../BookingClientSettings/index";
import BookingClosuresSettings from "../BookingClosuresSettings";
import BookingFloorSettings from "../BookingFloorSettings";
import BookingFormSettings from "../BookingForm";
import BookingGeneralSettings from "../BookingGeneralSettings";
import BookingListSettings from "../BookingListSettings";
import BookingServiceList from "../BookingServicesSettings/BookingServiceList";
import BookingPdfPrintTemplatesSettings from "../BookingPdfPrintTemplatesSettings/index";

interface BookingSettingsListProps {
  goBack?: () => void;
}

const BookingSettingsList = ({ goBack }: BookingSettingsListProps) => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const handleCloseSelectedTab = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "BOOKING_GENERAL_SETTINGS":
        return <BookingGeneralSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_FORM_SETTINGS":
        return <BookingFormSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_LIST_SETTINGS":
        return <BookingListSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_CLOSURES_SETTINGS":
        return <BookingClosuresSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_FLOOR_SETTINGS":
        return <BookingFloorSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_SERVICES_LIST":
        return <BookingServiceList goBack={handleCloseSelectedTab} />;
      case "BOOKING_CLIENT_SETTINGS":
        return <BookingClientSettings goBack={handleCloseSelectedTab} />;
      case "BOOKING_PDF_SETTINGS":
        return <BookingPdfPrintTemplatesSettings goBack={handleCloseSelectedTab} />;
      default:
        return null;
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Réservation"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box mt="m">
            <InputSectionTitle text="Général" />
          </Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Paramètres Généraux"
              subText="Gérer les paramètres principaux"
              onPress={() => handleNavigation("BOOKING_GENERAL_SETTINGS")}
              bottomLineWidth={0}
              icon={
                <MODULE_SETTINGS
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="CLIENT" />
          </Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Services & expériences"
              subText="Customiser vos horaires
de réservations"
              onPress={() => handleNavigation("BOOKING_SERVICES_LIST")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_AVAILABILITY
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
            <SettingsButtonItem
              title="Module de réservation"
              subText="Personnaliser les messages à
l'attention de vos clients"
              onPress={() => handleNavigation("BOOKING_CLIENT_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_CLIENT_SETTINGS
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
            <SettingsButtonItem
              title="Fermetures exceptionnelles"
              subText="Fermer un ou plusieurs jours,
une période"
              onPress={() => handleNavigation("BOOKING_CLOSURES_SETTINGS")}
              bottomLineWidth={0}
              icon={
                <MODULE_CLOSING
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="Établissement" />
          </Box>
          <Box
            mt="s"
            paddingHorizontal="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <SettingsButtonItem
              title="Formulaire de réservation"
              subText="Personnaliser les champs du
formulaire lors de la prise
de réservation via l’application"
              onPress={() => handleNavigation("BOOKING_FORM_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_FORM width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              }
            />
            <SettingsButtonItem
              title="Listing des réservations"
              subText="Personnaliser l’affichage de vos
réservations dans l’application"
              onPress={() => handleNavigation("BOOKING_LIST_SETTINGS")}
              bottomLineWidth={LINE_THICKNESS}
              icon={
                <MODULE_LISTING
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  fill={PALETTE.green}
                />
              }
            />
            <SettingsButtonItem
              title="Affichage du plan de salle"
              subText="Personnaliser l’affichage de votre
plan de salle dans l’application"
              bottomLineWidth={LINE_THICKNESS}
              onPress={() => handleNavigation("BOOKING_FLOOR_SETTINGS")}
              icon={<TABLE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
            />
            <SettingsButtonItem
              title="Impression des réservations"
              subText="Personnaliser l’impression de vos réservations"
              onPress={() => handleNavigation("BOOKING_PDF_SETTINGS")}
              bottomLineWidth={0}
              icon={<PDF width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
            />
          </Box>
        </ScrollView>
      </Box>
    );
  };

  const displayContent = () => {
    if (selectedTab) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default BookingSettingsList;
