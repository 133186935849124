import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

import type {
  Click_Status,
  InternalNotification,
  OrderFragment,
} from "../../../graphql/generated/schema";
import {
  useGetOrderLazyQuery,
  useUpdateOrderMutation,
} from "../../../graphql/generated/schema";
import { displayModuleId, formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import OnlineOrderStatusButton from "../../OnlineOrdersList/OnlineOrderStatusButton";

const IS_WEB = Platform.OS === "web";

interface NewLocalOrderNotificationProps {
  notification: InternalNotification;
  handleAckItem: (id: string) => void;
}

const NewLocalOrderNotification = ({
  notification,
  handleAckItem,
}: NewLocalOrderNotificationProps) => {
  const [order, setOrder] = useState<OrderFragment | undefined>(undefined);

  const [getOrder] = useGetOrderLazyQuery();
  const [updateOrder] = useUpdateOrderMutation();

  const handleGetOrder = async () => {
    try {
      const { data, error } = await getOrder({
        fetchPolicy: "cache-and-network",
        variables: {
          orderid: notification.resourceId,
        },
      });

      if (data?.getOrderForPro) {
        setOrder(data.getOrderForPro);
      }
    } catch (err) {
      console.log("err gettings order for local notification", err);
    }
  };

  useEffect(() => {
    handleGetOrder();
  }, []);

  const handleOrderStatusChange = async (
    newStatus: Click_Status,
    refusalReason: string,
  ) => {
    try {
      const result = await updateOrder({
        variables: {
          orderId: notification.resourceId,
          order: {
            status: newStatus,
            refusalReason,
            sendClientNotifications: true,
          },
        },
      });
    } catch (err) {
      console.log("err updating order status", err);
    } finally {
      handleAckItem(notification.resourceId);
    }
  };

  if (!order) {
    return null;
  }

  const {
    _id: itemId,
    merchantClient: client,
    date,
    source,
    status,
    orderQuantity,
    totalDetails,
  } = order;
  const formattedDate = format(new Date(date), "dd/MM HH:mm");

  const formattedClientName = `${client?.lastName || ""}`.trim();
  const formattedTotalAmount = formatCurrencyPrice(
    totalDetails.totalWithTaxes,
    "fr",
    "eur",
  );

  return (
    <Box
      borderRadius="button"
      p="s"
      backgroundColor="white"
      width="100%"
      alignSelf="flex-end"
      maxWidth={IS_WEB ? 370 : undefined}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="yellow">
          {notification.title}
        </CustomText>
        {/* <CustomText variant="content" color="primaryTextColor">
          Maintenant
        </CustomText> */}
      </Box>

      <Box
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          minHeight: 92,
        }}
      >
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <BookingListCardOrigin origin={source} />

            <CustomText numberOfLines={1} ml="s" variant="listItemMainText">
              {formattedClientName}
            </CustomText>
          </Box>
          <CustomText variant="content">{formattedDate}</CustomText>
          <CustomText variant="label">{formattedTotalAmount}</CustomText>

          <OnlineOrderStatusButton
            status={status}
            onPress={handleOrderStatusChange}
            isDisabled={false}
          />
        </Box>

        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Box>
              <BookingLIstCardClientFidelity occurences={client?.orderOccurences || 0} />
            </Box>

            <Box ml="s">
              <CustomText paddingHorizontal="m" variant="listItemSubText">
                {displayModuleId(itemId)}
              </CustomText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewLocalOrderNotification;
