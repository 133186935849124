import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  BookingListingSettings,
  BookingSettingsFragment,
} from "../../../../graphql/generated/schema";
import {
  Module_Listing_Client_Format,
  useGetBookingSettingsLazyQuery,
  useUpdateBookingSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

import BookingListingSettingsPreview from "./BookingListingSettingsPreview";

interface BookingListSettingsProps {
  goBack?: () => void;
}

const NAME_FORMATS = [
  {
    label: "DOE John",
    key: Module_Listing_Client_Format.LastFirstName,
  },
  {
    label: "DOE",
    key: Module_Listing_Client_Format.LastName,
  },
  {
    label: "John",
    key: Module_Listing_Client_Format.FirstName,
  },
  {
    label: "John DOE",
    key: Module_Listing_Client_Format.FirstLastName,
  },
];

const BookingListSettings = ({ goBack }: BookingListSettingsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);

  const [bookingSettings, setBookingSettings] = useState<BookingSettingsFragment | null>(
    null,
  );

  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings();

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (error) {
      console.log("err get gooking settings", error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_BOOKING_SETTINGS",
            message: "Une erreur est survenue.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  const handleUpdateBookingSettings = async () => {
    try {
      const updates = removeTypeNames(bookingSettings);
      delete updates._id;

      await updateBookingSettings({
        variables: {
          updates,
        },
      });
    } catch (error) {
      console.log("err update booking settings", error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_BOOKING_SETTINGS",
            message:
              "Une erreur est survenue lors de la mise à jour des paramètres de réservation.",
          },
        ],
        "error",
      );
    } finally {
      handleGoBack();
    }
  };

  const updateBookingSettingsData = (
    key: keyof BookingListingSettings,
    value: BookingListingSettings[keyof BookingListingSettings],
  ) => {
    setBookingSettings(prev => {
      if (prev) {
        return {
          ...prev,
          bookingListingSettings: {
            ...prev.bookingListingSettings,
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!bookingSettings) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Affichage des réservations"
          hasBackButton
          onBackPress={handleGoBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres de réservation." />
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Affichage des réservations"
        hasBackButton
        onBackPress={handleGoBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 150,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box marginVertical="s">
          <CustomText mb="s" variant="content" color="primaryTextColor">
            Format d'affichage du nom des clients
          </CustomText>
          <SelectButton
            isMultiSelect={false}
            options={NAME_FORMATS}
            selectedOptions={[
              bookingSettings?.bookingListingSettings?.clientNameFormat ||
                Module_Listing_Client_Format.LastFirstName,
            ]}
            onPress={([format]) => updateBookingSettingsData("clientNameFormat", format)}
            placeHolder="Format"
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher le récapitulatif du nombre
de résa par nombre de couverts"
            value={!!bookingSettings?.bookingListingSettings.groupBookingsByPersons}
            onValueChange={value =>
              updateBookingSettingsData("groupBookingsByPersons", value)
            }
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher les réservations au statut
'Annulé' dans le filtre 'TOUS'"
            value={!!bookingSettings?.bookingListingSettings.includeCancelledInAllFilter}
            onValueChange={value =>
              updateBookingSettingsData("includeCancelledInAllFilter", value)
            }
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher et grouper par créneaux
dans la liste des réservations"
            value={!!bookingSettings?.bookingListingSettings.groupBySlotInterval}
            onValueChange={value =>
              updateBookingSettingsData("groupBySlotInterval", value)
            }
          />
        </Box>

        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher la date de création de la réservation"
            value={!!bookingSettings.bookingListingSettings.showCreationDate}
            onValueChange={value => updateBookingSettingsData("showCreationDate", value)}
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher le commentaire du client directement dans la liste des réservations"
            value={!!bookingSettings.bookingListingSettings.showClientComment}
            onValueChange={value => updateBookingSettingsData("showClientComment", value)}
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher l'événement de la réservation directement dans la liste des réservations"
            value={!!bookingSettings.bookingListingSettings.showEventType}
            onValueChange={value => updateBookingSettingsData("showEventType", value)}
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher les allergies du client directement dans la liste des réservations"
            value={!!bookingSettings.bookingListingSettings.showAllergies}
            onValueChange={value => updateBookingSettingsData("showAllergies", value)}
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher le détail des produits sélectionnés directement dans la liste des réservations"
            value={!!bookingSettings.bookingListingSettings.showBasket}
            onValueChange={value => updateBookingSettingsData("showBasket", value)}
          />
        </Box>
        <Box mt="s">
          <InputSectionTitle text="Aperçu des réservations" />
        </Box>
        <Box mt="s">
          <BookingListingSettingsPreview
            settings={bookingSettings.bookingListingSettings}
          />
        </Box>
      </ScrollView>

      <BottomButton onPress={handleUpdateBookingSettings} title="Enregistrer" />
    </Box>
  );
};

export default BookingListSettings;
