import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage";
import Loader from "../../../../components/Loader";
import ModuleFormInputLine from "../../../../components/ModuleFormInputLine";
import ScreenHeader from "../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { BookingSettingsFragment } from "../../../../graphql/generated/schema";
import {
  Booking_Form_Input,
  useGetBookingSettingsLazyQuery,
  useUpdateBookingSettingsMutation,
} from "../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../theme";
import { removeTypeNames } from "../../../../utils/common";

interface BookingFormSettingsProps {
  goBack?: () => void;
}

const BookingFormSettings = ({ goBack }: BookingFormSettingsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);

  const [bookingSettings, setBookingSettings] = useState<BookingSettingsFragment | null>(
    null,
  );

  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  const handlGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings();

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (error) {
      console.log("err get booking settings", error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_BOOKING_SETTINGS",
            message: "Une erreur est survenue",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  const handleUpdateBookingSettings = async () => {
    try {
      const updates = removeTypeNames(bookingSettings);
      delete updates._id;

      await updateBookingSettings({
        variables: {
          updates,
        },
      });
      handlGoBack();
    } catch (error) {
      console.log("err update booking settings", error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATING_BOOKING_SETTINGS",
            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
    }
  };

  const updateBookingFormEnabled = (key: Booking_Form_Input, value: boolean) => {
    setBookingSettings(prev => {
      if (prev) {
        const { bookingForm } = prev;

        const doesInputExist = bookingForm.inputs.some(input => input.inputName === key);

        let newInputs = bookingForm.inputs;

        if (doesInputExist) {

          newInputs = bookingForm.inputs.map(input => {
            if (input.inputName === key) {
              const isRequired = value ? input.isRequired : false;
              return {
                ...input,
                isRequired,

                isEnabled: value,
              };
            }
            return input;
          });
        }

        else {
          newInputs = [
            ...bookingForm.inputs,
            {
              inputName: key,
              isEnabled: value,
              isRequired: false,
            },
          ];
        }

        return {
          ...prev,
          bookingForm: {
            inputs: newInputs,
          },
        };
      }
      return null;
    });
  };
  const updateBookingFormRequired = (key: Booking_Form_Input, value: boolean) => {
    setBookingSettings(prev => {
      if (prev) {
        const { bookingForm } = prev;


        const doesInputExist = bookingForm.inputs.some(input => input.inputName === key);

        let newInputs = bookingForm.inputs;

        if (doesInputExist) {


           newInputs = bookingForm.inputs.map(input => {
            if (input.inputName === key) {
              const isEnabled = value ? true : input.isEnabled;
              return {
                ...input,
                isEnabled,

                isRequired: value,
              };
            }
            return input;
          });

        }

        else {
          newInputs = [
            ...bookingForm.inputs,
            {
              inputName: key,
              isEnabled: true,
              isRequired: value,
            },
          ];
        }

        return {
          ...prev,
          bookingForm: {
            inputs: newInputs,
          },
        };
      }
      return null;
    });
  };

  const getFormInputEnabled = (key: Booking_Form_Input) => {
    if (bookingSettings) {
      const { bookingForm } = bookingSettings;
      const input = bookingForm.inputs.find(v => v.inputName === key);
      if (input) {
        return input.isEnabled;
      }
    }
    return false;
  };

  const getFormInputRequired = (key: Booking_Form_Input) => {
    if (bookingSettings) {
      const { bookingForm } = bookingSettings;
      const input = bookingForm.inputs.find(v => v.inputName === key);
      if (input) {
        return input.isRequired;
      }
    }
    return false;
  };

  if (loading) {
    return <Loader />;
  }

  if (!bookingSettings) {
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Formulaire de réservation"
        hasBackButton
        onBackPress={handlGoBack}
      />
      <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres" />
    </Box>;
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Formulaire de réservation"
        hasBackButton
        onBackPress={handlGoBack}
      />

      <Box marginVertical="s">
        <CustomText variant="content" color="lightGrey">
          Veuillez choisir les champs que vous souhaitez utiliser lors de la prise de
          réservation.
        </CustomText>
      </Box>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 150,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Civilité"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Civility)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Civility)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Civility, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.Civility, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Nom"
            isOptional={false}
            isRequired={true}
            isEnabled={getFormInputEnabled(Booking_Form_Input.LastName)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.LastName, v)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onRequiredChange={() => {}}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Prénom"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.FirstName)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.FirstName)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.FirstName, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.FirstName, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Téléphone"
            isOptional={false}
            isRequired={true}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Phone)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Phone, v)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onRequiredChange={() => {}}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Email"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Email)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Email)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Email, v)}
            onRequiredChange={v => updateBookingFormRequired(Booking_Form_Input.Email, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Addresse"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Address)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Address)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Address, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.Address, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Note sur le client"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.MerchantClientNote)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.MerchantClientNote)}
            onValueChange={v =>
              updateBookingFormEnabled(Booking_Form_Input.MerchantClientNote, v)
            }
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.MerchantClientNote, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="VIP"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Vip)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Vip)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Vip, v)}
            onRequiredChange={v => updateBookingFormRequired(Booking_Form_Input.Vip, v)}
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Note sur la réservation"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.CommentOwner)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.CommentOwner)}
            onValueChange={v =>
              updateBookingFormEnabled(Booking_Form_Input.CommentOwner, v)
            }
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.CommentOwner, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Type d'évènement"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.EventType)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.EventType)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.EventType, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.EventType, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Entreprise"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Company)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Company)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Company, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.Company, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Date d'anniversaire"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Birthday)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Birthday)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Birthday, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.Birthday, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Email secondaire"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.SecondaryEmail)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.SecondaryEmail)}
            onValueChange={v =>
              updateBookingFormEnabled(Booking_Form_Input.SecondaryEmail, v)
            }
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.SecondaryEmail, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Téléphone secondaire"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.SecondaryPhone)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.SecondaryPhone)}
            onValueChange={v =>
              updateBookingFormEnabled(Booking_Form_Input.SecondaryPhone, v)
            }
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.SecondaryPhone, v)
            }
          />
        </Box>
        <Box
          mt="s"
          pb="s"
          borderBottomColor="disabled"
          borderBottomWidth={LINE_THICKNESS}
        >
          <ModuleFormInputLine
            text="Origine de la réservation"
            isOptional
            isRequired={getFormInputRequired(Booking_Form_Input.Origin)}
            isEnabled={getFormInputEnabled(Booking_Form_Input.Origin)}
            onValueChange={v => updateBookingFormEnabled(Booking_Form_Input.Origin, v)}
            onRequiredChange={v =>
              updateBookingFormRequired(Booking_Form_Input.Origin, v)
            }
          />
        </Box>
      </ScrollView>

      <BottomButton onPress={handleUpdateBookingSettings} title="Enregistrer" />
    </Box>
  );
};

export default BookingFormSettings;
