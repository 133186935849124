import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { isBefore } from "date-fns";
import React, { useState } from "react";
import { ScrollView } from "react-native";

import PREPAID from "../../../../assets/icons/BASE/PREPAID.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import type {
  BookingSettingsFragment,
  CanMerchantInitiatePrepaidBooking,
  CreateBookingProInput,
  ProductFragment,
  SelectedBookingTableFragment,
} from "../../../graphql/generated/schema";
import {
  PrepaymentCancelDelimiter,
  PrepaymentCancelMesaurementType,
  PrepaymentType,
  useCanMerchantInitiatePrepaidBookingLazyQuery,
} from "../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import { formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
interface NewBookingAdvancedSelectionProps {
  booking: CreateBookingProInput;
  handleAdvancedSelection: (
    tables: SelectedBookingTableFragment[],
    products: ProductFragment[],
    includePrepaid: boolean,
  ) => void;
  bookingSettings?: BookingSettingsFragment;
}

const NewBookingAdvancedSelection = ({
  booking,
  handleAdvancedSelection,
  bookingSettings,
}: NewBookingAdvancedSelectionProps) => {
  const [selectedTables, setSelectedTables] = useState(booking.tables || []);
  const navigation = useNavigation();
  const [bookingPrepaidInfo, setBookingPrepaidInfo] =
    useState<CanMerchantInitiatePrepaidBooking | null>(null);
  const [canMerchantInitiatePrepaidBooking] =
    useCanMerchantInitiatePrepaidBookingLazyQuery();

  const handleCanMerchantIntitatePrepaidBooking = async () => {
    try {
      const response = await canMerchantInitiatePrepaidBooking();

      if (response.data?.canMerchantInitiatePrepaidBooking) {
        setBookingPrepaidInfo(response.data.canMerchantInitiatePrepaidBooking);
      }
    } catch (err) {}
  };

  useFocusEffect(
    React.useCallback(() => {
      handleCanMerchantIntitatePrepaidBooking();
    }, []),
  );

  const handleGoToBookingFloor = () => {
    const tableIds = selectedTables.map(table => table.tableId);
    navigation.navigate("BOOKING_FLOORS", {
      selectedTables: tableIds,
      selectedDate: booking.date,
      bookingPersons: booking.nbPersons,
      selectedBookingServiceId: booking.serviceId,
      bookingFloorViewType: BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT,
      onSubmit: (tables: SelectedBookingTableFragment[]) => {
        setSelectedTables(tables);
        navigation.goBack();
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleGoToProductSelection = () => {};

  const handlePrepaidSelection = (include: boolean) => {
    handleAdvancedSelection(selectedTables, [], include);
  };

  const displaySelectedTables = () => {
    if (selectedTables?.length === 0) {
      return "Accéder au plan de salles";
    }

    return selectedTables.map(table => `${table.prefix}-${table.tableNumber}`).join("; ");
  };

  const getPrepaymentCancelDelimiterMeasureType = (
    type: PrepaymentCancelMesaurementType,
  ) => {
    switch (type) {
      case PrepaymentCancelMesaurementType.Days:
        return "jours";
      case PrepaymentCancelMesaurementType.Hours:
        return "heures";
      case PrepaymentCancelMesaurementType.Minutes:
        return "minutes";
      default:
        return "jours";
    }
  };

  const getPrepaymentCancelDelimiter = (type: PrepaymentCancelDelimiter) => {
    switch (type) {
      case PrepaymentCancelDelimiter.Service:
        return "le service";
      case PrepaymentCancelDelimiter.Reservation:
        return "la réservation";
      default:
        return "";
    }
  };

  const displayNoPrepayment = () => {
    return (
      <Box paddingHorizontal="s" mt="m">
        <Box>
          <CustomText textTransform="uppercase" variant="content" color="success" mb="s">
            Empreinte bancaire
          </CustomText>
        </Box>

        <CustomButton
          styles={{ mt: "s" }}
          onPress={() => handlePrepaidSelection(false)}
          buttonVariant="outlineButton"
          borderColor="success"
        >
          <CustomText variant="primaryButtonText" color="success">
            Continuer sans l'empreinte
          </CustomText>
        </CustomButton>
      </Box>
    );
  };

  const displayPrepayment = () => {
    const bookingDate = new Date(booking.date);
    const currentDate = new Date();

    if (!bookingSettings || isBefore(bookingDate, currentDate))
      return displayNoPrepayment();

    const { defaultPrepaymentSettings } = bookingSettings;
    const { amountForPrepayment, prepaymentCancelSettings, prepaymentType } =
      defaultPrepaymentSettings;

    const { amount, delimiter, delimiterMesaurementType } = prepaymentCancelSettings;

    const prepaymentAmount =
      prepaymentType === PrepaymentType.FixedRate
        ? amountForPrepayment
        : amountForPrepayment * (booking.nbPersons || 1);

    return (
      <>
        <Box paddingHorizontal="s" mt="m">
          <Box>
            <CustomText
              textTransform="uppercase"
              variant="content"
              color="success"
              mb="s"
            >
              Empreinte bancaire
            </CustomText>
          </Box>

          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            p="s"
            backgroundColor="disabled"
            borderRadius="button"
            borderColor="lightGrey"
            borderWidth={LINE_THICKNESS}
          >
            <Box
              p="s"
              borderRadius="button"
              backgroundColor="lightGrey"
              alignItems="center"
              justifyContent="center"
            >
              <PREPAID width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.white} />
            </Box>
            <Box ml="s" flex={1}>
              <CustomText
                style={{
                  flexWrap: "wrap",
                }}
                variant="text"
                color="primaryTextColor"
              >
                Une empreinte de carte bancaire sera demandée à votre client pour valider
                la réservation (aucun débit ne sera effectué, cela reste indépendant du
                moyen de paiement le jour J). Une fois la réservation confirmée,
                l'annulation est possible jusqu'à{" "}
                <CustomText variant="label" fontSize={14} color="primaryTextColor">
                  {" "}
                  {amount}{" "}
                  {getPrepaymentCancelDelimiterMeasureType(delimiterMesaurementType)}{" "}
                  avant {getPrepaymentCancelDelimiter(delimiter)}
                </CustomText>
              </CustomText>
              <CustomText
                mt="s"
                style={{
                  flexWrap: "wrap",
                }}
                variant="text"
                color="primaryTextColor"
              >
                Votre client dispose de
                <CustomText variant="label" fontSize={14} color="primaryTextColor">
                  {" "}
                  15 minute(s){" "}
                </CustomText>
                pour déposer son empreinte bancaire à compter de la réception de cette
                réservation par le client.
              </CustomText>
              <CustomText
                mt="s"
                style={{
                  flexWrap: "wrap",
                }}
                variant="label"
                fontSize={14}
                color="primaryTextColor"
              >
                Montant: {formatCurrencyPrice(prepaymentAmount, "fr", "eur")}
              </CustomText>
            </Box>
          </Box>
        </Box>
        {displayPrepaidBookingButtons()}
      </>
    );
  };

  const displayPrepaidBookingButtons = () => {
    return (
      <Box mt="s" paddingHorizontal="s">
        {bookingPrepaidInfo === null ? (
          <></>
        ) : (
          <>
            {bookingPrepaidInfo?.isMerchantAbleToAcceptPayments &&
              bookingPrepaidInfo?.isMerchantAbleToSendSms && (
                <CustomButton
                  onPress={() => handlePrepaidSelection(true)}
                  buttonVariant="primaryButton"
                  buttonColor="success"
                >
                  <CustomText variant="primaryButtonText" color="white">
                    Continuer avec l'empreinte
                  </CustomText>
                </CustomButton>
              )}

            {!bookingPrepaidInfo?.isMerchantAbleToAcceptPayments && (
              <Box
                borderRadius="button"
                backgroundColor="disabled"
                alignItems="center"
                justifyContent="center"
                p="s"
              >
                <CustomText variant="content" color="danger">
                  Veuillez connecter votre compte stripe et activer les paiements en ligne
                  pour pouvoir utiliser cette fonctionnalité.
                </CustomText>
              </Box>
            )}

            {!bookingPrepaidInfo?.isMerchantAbleToSendSms && (
              <Box
                borderRadius="button"
                backgroundColor="disabled"
                alignItems="center"
                justifyContent="center"
                p="s"
              >
                <CustomText variant="content" color="danger">
                  Veuillez acheter des crédits SMS pour pouvoir utiliser cette
                  fonctionnalité.
                </CustomText>
              </Box>
            )}
          </>
        )}

        <CustomButton
          styles={{ mt: "s" }}
          onPress={() => handlePrepaidSelection(false)}
          buttonVariant="outlineButton"
          borderColor="success"
        >
          <CustomText variant="primaryButtonText" color="success">
            Continuer sans l'empreinte
          </CustomText>
        </CustomButton>
      </Box>
    );
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingBottom: 50,
      }}
    >
      <Box flex={1}>
        <Box paddingHorizontal="s" mt="s">
          <Box>
            <CustomText
              textTransform="uppercase"
              variant="content"
              color="success"
              mb="s"
            >
              PLACEMENT
            </CustomText>
          </Box>

          <CustomButton
            onPress={handleGoToBookingFloor}
            styles={{
              p: "s",
              borderRadius: "button",
              borderColor: "lightGrey",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomText
              variant={selectedTables.length > 0 ? "content" : "label"}
              color="primaryTextColor"
            >
              {displaySelectedTables()}
            </CustomText>
            <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </CustomButton>
        </Box>
        {/* <Box paddingHorizontal="s" mt="m">
          <Box>
            <CustomText
              textTransform="uppercase"
              variant="content"
              color="success"
              mb="s"
            >
              Sélection de produits
            </CustomText>
          </Box>

          <CustomButton
            onPress={handleGoToProductSelection}
            styles={{
              p: "s",
              borderRadius: "button",
              borderColor: "lightGrey",
              borderWidth: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomText variant="label" color="primaryTextColor">
              Accéder à la carte du restaurant
            </CustomText>
            <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
          </CustomButton>
        </Box> */}

        {displayPrepayment()}
      </Box>
    </ScrollView>
  );
};

export default NewBookingAdvancedSelection;
