import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

import type { SelectedBookingTableFragment } from "../../graphql/generated/schema";

export interface BookingCardAssignTableModalInterface {
  tablesForBookingAssignment: SelectedBookingTableFragment[];

  setTablesForBookingAssignment: Dispatch<SetStateAction<SelectedBookingTableFragment[]>>;

  selectedTablesForHover: SelectedBookingTableFragment[];
  setSelectedTablesForHover: Dispatch<SetStateAction<SelectedBookingTableFragment[]>>;
}

export const BookingContext = createContext<BookingCardAssignTableModalInterface>(
  {} as BookingCardAssignTableModalInterface,
);
