import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";

import ProductListDetailsEdit from "./Edit";
import ProductListDetailsView from "./View";

interface ProductListDetailsProps {
  newValue?: boolean;
  selectedId?: string;
  goBack?: () => void;
}

const ProductListDetails = ({
  goBack,
  newValue,
  selectedId,
}: ProductListDetailsProps) => {
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = selectedId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <ProductListDetailsEdit id={id} goBack={goBack} />
      ) : (
        <ProductListDetailsView id={id} goBack={goBack} />
      )}
      {!inEditMode && (
        <BottomButton title="Modifier" onPress={() => setInEditMode(true)} />
      )}
    </Box>
  );
};

export default ProductListDetails;
