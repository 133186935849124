// import { isSameDay } from "date-fns";
// import { useContext, useEffect, useMemo, useState } from "react";
// import { RefreshControl, SectionList } from "react-native";
// import { FlatList } from "react-native-gesture-handler";

// import SEARCH from "../../../assets/icons/BASE/SEARCH.svg";
// import { SplitViewContext } from "../../contexts/SpitViewContext";
// import type {
//   AvailabilityServiceWithModuleInfo,
//   BookingListingSettings,
//   BookingsAndServices,
//   OrdersAndServices,
// } from "../../graphql/generated/schema";
// import { Booking_Status, Click_Status, Modules } from "../../graphql/generated/schema";
// import { useDebounce } from "../../hooks/useDebounce";
// import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
// import { PALETTE } from "../../theme/Palette";
// import { formatCurrencyPrice } from "../../utils/common";
// import Box from "../Base/Box";
// import BookingListCard from "../BookingList/BookingListCard";
// import BookingServices from "../BookingList/BookingListHeader/BookingServices";
// import ListEmpty from "../BookingList/ListEmpty";
// import BookingListStatusFilter from "../BookingListStatusFilter/index";
// import BookingSummaryByPersons from "../BookingSummaryByPersons";
// import CashRegisterTicketListCard from "../CashRegister/CashRegisterTicketListCard";
// import ListingScreenHeader from "../ListingScreenHeader";
// import OnlineOrderListStatusFilter from "../OnlineOrderListStatusFilter/index";
// import OnlineOrdersListCard from "../OnlineOrdersList/OnlineOrderListCard";
// import { ServiceListHeaderItem } from "../ServiceListHeader/ServiceListHeaderItem";
// import { CustomTextInput } from "../TextInput";

// import FilterModal from "./FilterModal";

// interface ModuleListProps {
//   title: string;
//   type: Modules;
//   services: AvailabilityServiceWithModuleInfo[];
//   selectedServiceId: string;
//   onServiceSelected: (serviceId: string) => void;
//   onNewAction?: () => void;
//   onSearchAction?: (query: string) => void | Promise<void>;
//   onBookingFloorAction?: () => void;
//   onLocalBookingPress?: () => void;
//   onFilterAction?: () => void;
//   selectedDate: Date;
//   onDateChange: (date: Date) => void;
//   statuses: string[];
//   listData: (BookingsAndServices | OrdersAndServices)[];
//   onListItemPress: (itemId: string) => void;
//   onListItemExtraPress: (itemId: string) => void;
//   onDelete: (itemId: string) => void;
//   isRefreshing: boolean;
//   onRefresh: () => void;
//   dataByMonth: Record<string, unknown>;
//   listSettings: BookingListingSettings | null;
//   onCloseServiceSlot: (serviceId: string, slotId?: string) => void;
//   onMonthChange?: (date: Date) => void;
//   selectedId: string;
//   setSelectedId: (id: string) => void;
//   onEndReached: () => void;
//   onListItemUpdateCompleteHandler: (itemId: string) => void;
//   isServiceSlotCloseInProgress: boolean;
//   isSearchOpen: boolean;
//   setIsSearchOpen: (value: boolean) => void;
//   selectedStatuses: (Booking_Status | Click_Status)[];
//   setSelectedStatuses: (value: (Booking_Status | Click_Status)[]) => void;
//   shouldShowStatusFilterBadge: boolean;
//   setShouldShowStatusFilterBadge: (value: boolean) => void;
//   handleSelectFilter: (filter: (Booking_Status | Click_Status)[]) => void;
// }

// const ModuleList = ({
//   title,
//   onNewAction,
//   onSearchAction,
//   onBookingFloorAction,
//   onFilterAction,
//   onLocalBookingPress,
//   type,
//   services,
//   selectedServiceId,
//   onServiceSelected,
//   selectedDate,
//   onDateChange,
//   statuses,
//   listData,
//   onListItemPress,
//   onListItemExtraPress,
//   onDelete,
//   isRefreshing,
//   onRefresh,
//   dataByMonth,
//   listSettings,
//   onCloseServiceSlot,
//   onMonthChange,
//   selectedId,
//   setSelectedId,
//   onEndReached,
//   onListItemUpdateCompleteHandler,
//   isServiceSlotCloseInProgress,
//   isSearchOpen,
//   setIsSearchOpen,
//   selectedStatuses,
//   setSelectedStatuses,
//   shouldShowStatusFilterBadge,
//   setShouldShowStatusFilterBadge,
//   handleSelectFilter,
// }: ModuleListProps) => {
//   const [selectedFilter, setSelectedFilter] = useState("ALL");
//   const [isFilterOpen, setIsFilterOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   // const [selectedStatuses, setselectedStatuses] = useState<
//   //   (Booking_Status | Click_Status)[]
//   // >([]);
//   const splitViewContext = useContext(SplitViewContext);
//   const [refreshDataInteralComponents, setRefreshDataInteralComponents] = useState(false);
//   const [slotsVisble, setSlotsVisble] = useState([]);

//   useDebounce(
//     () => {
//       onSearchAction?.(searchQuery);
//     },
//     400,
//     [searchQuery],
//   );

//   useEffect(() => {
//     if (isRefreshing) {
//       setRefreshDataInteralComponents(v => !v);
//     }
//   }, [isRefreshing]);

//   const isCurrentDay = isSameDay(selectedDate, new Date());

//   const handleListItemUpdateComplete = (itemId: string) => {
//     setRefreshDataInteralComponents(v => !v);
//     onListItemUpdateCompleteHandler(itemId);
//   };

//   const totalCapacityForAllServices = useMemo(() => {
//     return listData.reduce((dataAcc, item) => {
//       return (
//         dataAcc +
//         item.slots.reduce((acc, slot) => {
//           return (
//             acc +
//             slot.data.reduce((innerAcc, innerData) => {
//               if (type === Modules.Reservation) {
//                 return innerAcc + innerData.nbPersons;
//               } else {
//                 return innerAcc + 1;
//               }
//             }, 0)
//           );
//         }, 0)
//       );
//     }, 0);
//   }, [listData]);

//   const filteredData = listData
//     .map(item => {
//       const slots = item.slots
//         .map(slot => {
//           const filteredItems = slot.data.filter(data => {
//             const IS_ON_ALL_TAB = selectedFilter === "ALL";

//             if (
//               type === Modules.Reservation &&
//               IS_ON_ALL_TAB &&
//               !listSettings?.includeCancelledInAllFilter
//             ) {
//               return (
//                 data.status !== Booking_Status.CancelledByOwner &&
//                 data.status !== Booking_Status.CancelledByUser
//               );
//             } else if (
//               type === Modules.OnlineSales &&
//               IS_ON_ALL_TAB &&
//               !listSettings?.includeCancelledInAllFilter
//             ) {
//               return (
//                 data.status !== Click_Status.CancelledByOwner &&
//                 data.status !== Click_Status.CancelledByUser
//               );
//             }

//             return true;
//           });

//           return { ...slot, data: filteredItems };
//         })
//         .filter(slot => slot.data.length > 0);

//       return { ...item, slots };
//     })
//     .filter(item => item.slots.length > 0);

//   const onFilterConfirm = (data: { statuses: (Booking_Status | Click_Status)[] }) => {
//     const { statuses: filterStatuses } = data;

//     setSelectedStatuses(filterStatuses);

//     if (filterStatuses.length > 0) {
//       setSelectedFilter("OTHERS");

//       handleSelectFilter(filterStatuses);
//       setShouldShowStatusFilterBadge(true);
//     }
//   };

//   const handleSelectedFilter = (filter: string) => {
//     setSelectedFilter(filter);

//     if (type === Modules.Reservation) {
//       let statusesByFilter: Booking_Status[] = [];

//       switch (filter) {
//         case "ALL":
//           statusesByFilter = [];
//           break;
//         case "PENDING":
//           statusesByFilter = [
//             Booking_Status.New,
//             Booking_Status.ModifiedByOwner,
//             Booking_Status.ModifiedByUser,
//             Booking_Status.WaitingList,
//             Booking_Status.Delayed,
//           ];
//           break;
//         case "VALIDATED":
//           statusesByFilter = [
//             Booking_Status.Validated,
//             Booking_Status.AutomaticValidated,
//           ];
//           break;
//         case "OTHERS":
//           statusesByFilter = [
//             Booking_Status.CancelledByOwner,
//             Booking_Status.CancelledByUser,
//             Booking_Status.Noshow,
//             Booking_Status.Arrived,
//             Booking_Status.ArrivedWaiting,
//             Booking_Status.Delayed,
//             Booking_Status.WaitingList,
//             Booking_Status.Ended,
//             Booking_Status.Seated,
//             Booking_Status.Refunded,
//           ];
//           break;
//         default:
//           break;
//       }

//       handleSelectFilter(statusesByFilter);
//     } else if (type === Modules.OnlineSales) {
//       let statusesByFilter: Click_Status[] = [];

//       switch (filter) {
//         case "ALL":
//           statusesByFilter = [];
//           break;
//         case "PENDING":
//           statusesByFilter = [
//             Click_Status.New,
//             Click_Status.ModifiedByOwner,
//             Click_Status.ModifiedByUser,
//             Click_Status.WaitingList,
//           ];
//           break;
//         case "VALIDATED":
//           statusesByFilter = [Click_Status.Validated, Click_Status.AutomaticValidated];
//           break;
//         case "OTHERS":
//           statusesByFilter = [
//             Click_Status.CancelledByOwner,
//             Click_Status.CancelledByUser,
//             Click_Status.Noshow,
//             Click_Status.Ended,
//             Click_Status.Refunded,
//             Click_Status.AutomaticCancel,
//             Click_Status.PaymentFailure,
//             Click_Status.Prepared,
//             Click_Status.Preparing,
//           ];
//           break;

//         default:
//           break;
//       }

//       handleSelectFilter(statusesByFilter);
//     }

//     setShouldShowStatusFilterBadge(false);
//   };

//   const displayEmptyList = () => {
//     return (
//       <ListEmpty
//         isOnAllTab={selectedFilter === "ALL"}
//         module={type}
//         selectedDate={selectedDate}
//       />
//     );
//   };

//   const formattedDataForSectionList = listData.map(item => ({
//     ...item,
//     data: item.slots,
//   }));

//   const renderItem = (itemData: any) => {
//     if (type === Modules.Reservation) {
//       return (
//         <BookingListCard
//           item={itemData}
//           onTablePress={() => handleGoToBookingFloor(itemData)}
//           onPress={() => onListItemPress(itemData?._id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
//           onDelete={() => onDelete(itemData?._id)}
//           isDisabled={false}
//           isExtraActionDisabled={false}
//           isTablePressDisabled={false}
//           isStatusChangeDisabled={false}
//           nameFormat={listSettings?.clientNameFormat}
//           showClientComment={false}
//           showCreationDate={false}
//           showEventType={false}
//           onCompleteHandler={() => {}}
//           onErrorHandler={() => {}}
//         />
//       );
//     }
//     if (type === Modules.OnlineSales) {
//       return (
//         <OnlineOrdersListCard
//           item={itemData}
//           onPress={() => onListItemPress(itemData?._id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
//           isDisabled={false}
//           nameFormat={listSettings?.clientNameFormat}
//           showClientComment={false}
//           showCreationDate={false}
//           showEventType={false}
//           onDelete={() => onDelete(itemData?._id)}
//           onCompleteHandler={() => {}}
//           onErrorHandler={() => {}}
//         />
//       );
//     }
//     if (type === Modules.CashRegister) {
//       return (
//         <CashRegisterTicketListCard
//           {...itemData}
//           onPress={() => onListItemPress(itemData.id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData.id)}
//           isDisabled={false}
//         />
//       );
//     }
//     return <></>;
//   };

//   const isSlotOpen = (slotId: string) => {
//     return !!slotsVisble.find(s => s.slotId === slotId)?.isOpen;
//   };

//   const toggleSlot = (slotId: string) => {
//     setSlotsVisble(prev =>
//       prev.map(s => (s.slotId === slotId ? { ...s, isOpen: !s.isOpen } : s)),
//     );
//   };

//   const formatServiceTotal = (total: number) => {
//     const suffix = type === Modules.Reservation ? "Réservations" : "Commandes";

//     return `${total} ${suffix}`;
//   };

//   const displayAccumulatedInSlotTotal = (accumulatedTotalInSlot: number) => {
//     if (type === Modules.Reservation) return `${accumulatedTotalInSlot} `;
//     if (type === Modules.OnlineSales) {
//       return `${formatCurrencyPrice(accumulatedTotalInSlot, "fr", "eur")}`;
//     }
//     return `${accumulatedTotalInSlot}`;
//   };

//   return (
//     <Box flex={1} pt="s" backgroundColor="white">
//       <Box paddingHorizontal="s" marginVertical="s">
//         <ListingScreenHeader
//           title={title}
//           onNewAction={onNewAction}
//           onLocalBookingPress={isCurrentDay ? onLocalBookingPress : undefined}
//           onSearchAction={() => setIsSearchOpen(v => !v)}
//           onBookingFloorAction={onBookingFloorAction}
//           onFilterAction={() => {
//             setIsFilterOpen(true);
//           }}
//           filterBadgeCount={
//             shouldShowStatusFilterBadge && selectedStatuses.length > 0
//               ? selectedStatuses.length
//               : 0
//           }
//         />
//         <Box marginVertical="s">
//           <BookingServices
//             {...{
//               onCloseServiceSlot,
//               moduleType: type,
//               selectedDate,
//               onDayPress: onDateChange,
//               selectedServiceId,
//               onSelectService: onServiceSelected,
//               services,
//               dataByMonth,
//               onMonthChange,
//               isServiceSlotCloseInProgress,
//               totalCapacityForAllServices,
//             }}
//           />
//         </Box>

//         {isSearchOpen && (
//           <Box marginVertical="xs">
//             <CustomTextInput
//               {...{
//                 clearButtonMode: "while-editing",
//                 placeHolder: "Rechercher",
//                 leftIcon: (
//                   <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
//                 ),
//                 onChangeText: t => {
//                   setSearchQuery?.(t);
//                 },
//               }}
//             />
//           </Box>
//         )}
//       </Box>

//       {type === Modules.Reservation ? (
//         <BookingListStatusFilter
//           date={selectedDate}
//           serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
//           handleSelectedFilter={handleSelectedFilter}
//           selectedFilter={selectedFilter}
//           selectedStatusFilters={selectedStatuses}
//           containerWidth={splitViewContext.leftContainer?.width}
//           includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
//           refreshData={refreshDataInteralComponents}
//         />
//       ) : (
//         <OnlineOrderListStatusFilter
//           date={selectedDate}
//           serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
//           handleSelectedFilter={handleSelectedFilter}
//           selectedFilter={selectedFilter}
//           selectedStatusFilters={selectedStatuses}
//           containerWidth={splitViewContext.leftContainer?.width}
//           includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
//           refreshData={refreshDataInteralComponents}
//         />
//       )}

//       <SectionList
//         sections={formattedDataForSectionList}
//         contentContainerStyle={{
//           paddingBottom: 50,
//         }}
//         showsVerticalScrollIndicator={false}
//         // ListHeaderComponent={
//         //   listSettings?.groupBySlotInterval ? (
//         //     <ServiceListHeader
//         //       moduleType={type}

//         //       serviceName={section.serviceName}
//         //       totalBookingsText={formatServiceTotal(section.totalInService)}
//         //       totalPersonsText={section.accumulatedTotalInService.toString()}
//         //     />
//         //   ) : (
//         //     <></>
//         //   )
//         // }
//         ListEmptyComponent={displayEmptyList()}
//         onEndReached={onEndReached}
//         refreshControl={
//           <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
//         }
//         keyExtractor={(item, idx) => item.slotId || idx.toString()}
//         renderItem={({ item: sectionListItem, section }) => {
//           // console.log("section", JSON.stringify(section, null, 2));
//           return (
//             <FlatList
//               data={sectionListItem.data}
//               // estimatedItemSize={sectionListItem.data.length}
//               ListHeaderComponent={
//                 listSettings?.groupBySlotInterval ? (
//                   <ServiceListHeaderItem
//                     moduleType={type}
//                     time={sectionListItem.slotTime}
//                     isClosed={isSlotOpen(sectionListItem.slotId)}
//                     onPress={() => toggleSlot(sectionListItem.slotId)}
//                     totalBookingsText={`${sectionListItem.totalInSlot}`}
//                     totalPersonsText={displayAccumulatedInSlotTotal(
//                       sectionListItem.accumulatedTotalInSlot,
//                     )}

//                     // serviceName={section.serviceName}
//                     // totalBookingsText={formatServiceTotal(section.totalInService)}
//                     // totalPersonsText={section.accumulatedTotalInService.toString()}
//                   />
//                 ) : (
//                   <></>
//                 )
//               }
//               renderItem={({ item, index }) => {
//                 const IS_SELECTED = item._id === selectedId;

//                 return (
//                   <Box
//                     paddingVertical="xs"
//                     borderBottomColor="disabled"
//                     borderBottomWidth={LINE_THICKNESS}
//                   >
//                     <Box
//                       paddingVertical="xs"
//                       paddingHorizontal="s"
//                       marginHorizontal="xs"
//                       borderRadius="button"
//                       borderColor="success"
//                       borderWidth={IS_SELECTED ? LINE_THICKNESS : 0}
//                     >
//                       {renderItem(item)}
//                     </Box>
//                   </Box>
//                 );
//               }}
//             />
//           );
//         }}
//       />

//       {/* <FlatList
//         style={{ flex: 1 }}
//         data={filteredData}
//         // scrollEventThrottle={16}
//         keyExtractor={(item, idx) => item.serviceId || idx.toString()}
//         contentContainerStyle={{ paddingBottom: 50 }}
//         showsVerticalScrollIndicator={false}
//         ListEmptyComponent={displayEmptyList()}
//         onEndReached={onEndReached}
//         refreshControl={
//           <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
//         }
//         renderItem={({ item }) => {
//           return (
//             <ModuleListItemRenderer
//               item={item}
//               onDelete={onDelete}
//               onListItemExtraPress={onListItemExtraPress}
//               onListItemPress={onListItemPress}
//               type={type}
//               groupBySlotInterval={listSettings?.groupBySlotInterval}
//               nameFormat={listSettings?.clientNameFormat}
//               selectedId={selectedId}
//               setSelectedId={setSelectedId}
//               isDisabled={false}
//               selectedDate={selectedDate}
//               showClientComment={listSettings?.showClientComment}
//               showCreationDate={listSettings?.showCreationDate}
//               showEventType={listSettings?.showEventType}
//               onListItemOnUpdateComplete={handleListItemUpdateComplete}
//               onListItemOnUpdateError={onRefresh}
//               selectedFilter={selectedFilter}
//               includeCancelled={listSettings?.includeCancelledInAllFilter}
//               selectedServiceId={selectedServiceId}
//               selectedStatuses={selectedStatuses}
//             />
//           );
//         }}
//       /> */}

//       {listSettings?.groupBookingsByPersons && type === Modules.Reservation && (
//         <Box height={70}>
//           <BookingSummaryByPersons
//             date={selectedDate}
//             serviceId={selectedServiceId}
//             refreshData={refreshDataInteralComponents}
//             itemMinWidth={
//               splitViewContext?.leftContainer?.width
//                 ? splitViewContext?.leftContainer?.width / 15
//                 : "100%"
//             }
//           />
//         </Box>
//       )}
//       <FilterModal
//         isOpen={isFilterOpen}
//         setIsOpen={setIsFilterOpen}
//         onConfirm={onFilterConfirm}
//         selectedStatuses={selectedStatuses}
//         type={type}
//       />
//     </Box>
//   );
// };

// export default ModuleList;

// // import { isSameDay } from "date-fns";
// // import { useContext, useEffect, useMemo, useState } from "react";
// // import { FlatList, RefreshControl } from "react-native";

// // import SEARCH from "../../../assets/icons/BASE/SEARCH.svg";
// // import { SplitViewContext } from "../../contexts/SpitViewContext";
// // import type {
// //   AvailabilityServiceWithModuleInfo,
// //   BookingListingSettings,
// //   BookingsAndServices,
// //   OrdersAndServices,
// // } from "../../graphql/generated/schema";
// // import { Booking_Status, Click_Status, Modules } from "../../graphql/generated/schema";
// // import { useDebounce } from "../../hooks/useDebounce";
// // import { ICON_SIZE } from "../../theme";
// // import { PALETTE } from "../../theme/Palette";
// // import Box from "../Base/Box";
// // import BookingServices from "../BookingList/BookingListHeader/BookingServices";
// // import ListEmpty from "../BookingList/ListEmpty";
// // import BookingListStatusFilter from "../BookingListStatusFilter/index";
// // import BookingSummaryByPersons from "../BookingSummaryByPersons";
// // import ListingScreenHeader from "../ListingScreenHeader";
// // import OnlineOrderListStatusFilter from "../OnlineOrderListStatusFilter/index";
// // import { CustomTextInput } from "../TextInput";

// // import FilterModal from "./FilterModal";
// // import ModuleListItemRenderer from "./ModuleListItemRenderer";

// // interface ModuleListProps {
// //   title: string;
// //   type: Modules;
// //   services: AvailabilityServiceWithModuleInfo[];
// //   selectedServiceId: string;
// //   onServiceSelected: (serviceId: string) => void;
// //   onNewAction?: () => void;
// //   onSearchAction?: (query: string) => void | Promise<void>;
// //   onBookingFloorAction?: () => void;
// //   onLocalBookingPress?: () => void;
// //   onFilterAction?: () => void;
// //   selectedDate: Date;
// //   onDateChange: (date: Date) => void;
// //   statuses: string[];
// //   listData: (BookingsAndServices | OrdersAndServices)[];
// //   onListItemPress: (itemId: string) => void;
// //   onListItemExtraPress: (itemId: string) => void;
// //   onDelete: (itemId: string) => void;
// //   isRefreshing: boolean;
// //   onRefresh: () => void;
// //   dataByMonth: Record<string, unknown>;
// //   listSettings: BookingListingSettings | null;
// //   onCloseServiceSlot: (serviceId: string, slotId?: string) => void;
// //   onMonthChange?: (date: Date) => void;
// //   selectedId: string;
// //   setSelectedId: (id: string) => void;
// //   onEndReached: () => void;
// //   onListItemUpdateCompleteHandler: (itemId: string) => void;
// //   isServiceSlotCloseInProgress: boolean;
// //   isSearchOpen: boolean;
// //   setIsSearchOpen: (value: boolean) => void;
// //   selectedStatuses: (Booking_Status | Click_Status)[];
// //   setSelectedStatuses: (value: (Booking_Status | Click_Status)[]) => void;
// //   shouldShowStatusFilterBadge: boolean;
// //   setShouldShowStatusFilterBadge: (value: boolean) => void;
// //   handleSelectFilter: (filter: (Booking_Status | Click_Status)[]) => void;
// // }

// // const ModuleList = ({
// //   title,
// //   onNewAction,
// //   onSearchAction,
// //   onBookingFloorAction,
// //   onFilterAction,
// //   onLocalBookingPress,
// //   type,
// //   services,
// //   selectedServiceId,
// //   onServiceSelected,
// //   selectedDate,
// //   onDateChange,
// //   statuses,
// //   listData,
// //   onListItemPress,
// //   onListItemExtraPress,
// //   onDelete,
// //   isRefreshing,
// //   onRefresh,
// //   dataByMonth,
// //   listSettings,
// //   onCloseServiceSlot,
// //   onMonthChange,
// //   selectedId,
// //   setSelectedId,
// //   onEndReached,
// //   onListItemUpdateCompleteHandler,
// //   isServiceSlotCloseInProgress,
// //   isSearchOpen,
// //   setIsSearchOpen,
// //   selectedStatuses,
// //   setSelectedStatuses,
// //   shouldShowStatusFilterBadge,
// //   setShouldShowStatusFilterBadge,
// //   handleSelectFilter,
// // }: ModuleListProps) => {
// //   const [selectedFilter, setSelectedFilter] = useState("ALL");
// //   const [isFilterOpen, setIsFilterOpen] = useState(false);
// //   const [searchQuery, setSearchQuery] = useState("");
// //   // const [selectedStatuses, setselectedStatuses] = useState<
// //   //   (Booking_Status | Click_Status)[]
// //   // >([]);
// //   const splitViewContext = useContext(SplitViewContext);
// //   const [refreshDataInteralComponents, setRefreshDataInteralComponents] = useState(false);

// //   useDebounce(
// //     () => {
// //       onSearchAction?.(searchQuery);
// //     },
// //     400,
// //     [searchQuery],
// //   );

// //   useEffect(() => {
// //     if (isRefreshing) {
// //       setRefreshDataInteralComponents(v => !v);
// //     }
// //   }, [isRefreshing]);

// //   const isCurrentDay = isSameDay(selectedDate, new Date());

// //   const handleListItemUpdateComplete = (itemId: string) => {
// //     setRefreshDataInteralComponents(v => !v);
// //     onListItemUpdateCompleteHandler(itemId);
// //   };

// //   const totalCapacityForAllServices = useMemo(() => {
// //     return listData.reduce((dataAcc, item) => {
// //       return (
// //         dataAcc +
// //         item.slots.reduce((acc, slot) => {
// //           return (
// //             acc +
// //             slot.data.reduce((innerAcc, innerData) => {
// //               if (type === Modules.Reservation) {
// //                 return innerAcc + innerData.nbPersons;
// //               } else {
// //                 return innerAcc + 1;
// //               }
// //             }, 0)
// //           );
// //         }, 0)
// //       );
// //     }, 0);
// //   }, [listData]);

// //   const filteredData = listData
// //     .map(item => {
// //       const slots = item.slots
// //         .map(slot => {
// //           const filteredItems = slot.data.filter(data => {
// //             const IS_ON_ALL_TAB = selectedFilter === "ALL";

// //             if (
// //               type === Modules.Reservation &&
// //               IS_ON_ALL_TAB &&
// //               !listSettings?.includeCancelledInAllFilter
// //             ) {
// //               return (
// //                 data.status !== Booking_Status.CancelledByOwner &&
// //                 data.status !== Booking_Status.CancelledByUser
// //               );
// //             } else if (
// //               type === Modules.OnlineSales &&
// //               IS_ON_ALL_TAB &&
// //               !listSettings?.includeCancelledInAllFilter
// //             ) {
// //               return (
// //                 data.status !== Click_Status.CancelledByOwner &&
// //                 data.status !== Click_Status.CancelledByUser
// //               );
// //             }

// //             return true;
// //           });

// //           return { ...slot, data: filteredItems };
// //         })
// //         .filter(slot => slot.data.length > 0);

// //       return { ...item, slots };
// //     })
// //     .filter(item => item.slots.length > 0);

// //   const onFilterConfirm = (data: { statuses: (Booking_Status | Click_Status)[] }) => {
// //     const { statuses: filterStatuses } = data;

// //     setSelectedStatuses(filterStatuses);

// //     if (filterStatuses.length > 0) {
// //       setSelectedFilter("OTHERS");

// //       handleSelectFilter(filterStatuses);
// //       setShouldShowStatusFilterBadge(true);
// //     }
// //   };

// //   const handleSelectedFilter = (filter: string) => {
// //     setSelectedFilter(filter);

// //     if (type === Modules.Reservation) {
// //       let statusesByFilter: Booking_Status[] = [];

// //       switch (filter) {
// //         case "ALL":
// //           statusesByFilter = [];
// //           break;
// //         case "PENDING":
// //           statusesByFilter = [
// //             Booking_Status.New,
// //             Booking_Status.ModifiedByOwner,
// //             Booking_Status.ModifiedByUser,
// //             Booking_Status.WaitingList,
// //             Booking_Status.Delayed,
// //           ];
// //           break;
// //         case "VALIDATED":
// //           statusesByFilter = [
// //             Booking_Status.Validated,
// //             Booking_Status.AutomaticValidated,
// //           ];
// //           break;
// //         case "OTHERS":
// //           statusesByFilter = [
// //             Booking_Status.CancelledByOwner,
// //             Booking_Status.CancelledByUser,
// //             Booking_Status.Noshow,
// //             Booking_Status.Arrived,
// //             Booking_Status.ArrivedWaiting,
// //             Booking_Status.Delayed,
// //             Booking_Status.WaitingList,
// //             Booking_Status.Ended,
// //             Booking_Status.Seated,
// //             Booking_Status.Refunded,
// //           ];
// //           break;
// //         default:
// //           break;
// //       }

// //       handleSelectFilter(statusesByFilter);
// //     } else if (type === Modules.OnlineSales) {
// //       let statusesByFilter: Click_Status[] = [];

// //       switch (filter) {
// //         case "ALL":
// //           statusesByFilter = [];
// //           break;
// //         case "PENDING":
// //           statusesByFilter = [
// //             Click_Status.New,
// //             Click_Status.ModifiedByOwner,
// //             Click_Status.ModifiedByUser,
// //             Click_Status.WaitingList,
// //           ];
// //           break;
// //         case "VALIDATED":
// //           statusesByFilter = [Click_Status.Validated, Click_Status.AutomaticValidated];
// //           break;
// //         case "OTHERS":
// //           statusesByFilter = [
// //             Click_Status.CancelledByOwner,
// //             Click_Status.CancelledByUser,
// //             Click_Status.Noshow,
// //             Click_Status.Ended,
// //             Click_Status.Refunded,
// //             Click_Status.AutomaticCancel,
// //             Click_Status.PaymentFailure,
// //             Click_Status.Prepared,
// //             Click_Status.Preparing,
// //           ];
// //           break;

// //         default:
// //           break;
// //       }

// //       handleSelectFilter(statusesByFilter);
// //     }

// //     setShouldShowStatusFilterBadge(false);
// //   };

// //   const displayEmptyList = () => {
// //     return (
// //       <ListEmpty
// //         isOnAllTab={selectedFilter === "ALL"}
// //         module={type}
// //         selectedDate={selectedDate}
// //       />
// //     );
// //   };

// //   return (
// //     <Box flex={1} pt="s" backgroundColor="white">
// //       <Box paddingHorizontal="s" marginVertical="s">
// //         <ListingScreenHeader
// //           title={title}
// //           onNewAction={onNewAction}
// //           onLocalBookingPress={isCurrentDay ? onLocalBookingPress : undefined}
// //           onSearchAction={() => setIsSearchOpen(v => !v)}
// //           onBookingFloorAction={onBookingFloorAction}
// //           onFilterAction={() => {
// //             setIsFilterOpen(true);
// //           }}
// //           filterBadgeCount={
// //             shouldShowStatusFilterBadge && selectedStatuses.length > 0
// //               ? selectedStatuses.length
// //               : 0
// //           }
// //         />
// //         <Box marginVertical="s">
// //           <BookingServices
// //             {...{
// //               onCloseServiceSlot,
// //               moduleType: type,
// //               selectedDate,
// //               onDayPress: onDateChange,
// //               selectedServiceId,
// //               onSelectService: onServiceSelected,
// //               services,
// //               dataByMonth,
// //               onMonthChange,
// //               isServiceSlotCloseInProgress,
// //               totalCapacityForAllServices,
// //             }}
// //           />
// //         </Box>

// //         {isSearchOpen && (
// //           <Box marginVertical="xs">
// //             <CustomTextInput
// //               {...{
// //                 clearButtonMode: "while-editing",
// //                 placeHolder: "Rechercher",
// //                 leftIcon: (
// //                   <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
// //                 ),
// //                 onChangeText: t => {
// //                   setSearchQuery?.(t);
// //                 },
// //               }}
// //             />
// //           </Box>
// //         )}
// //       </Box>

// //       {type === Modules.Reservation ? (
// //         <BookingListStatusFilter
// //           date={selectedDate}
// //           serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
// //           handleSelectedFilter={handleSelectedFilter}
// //           selectedFilter={selectedFilter}
// //           selectedStatusFilters={selectedStatuses}
// //           containerWidth={splitViewContext.leftContainer?.width}
// //           includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
// //           refreshData={refreshDataInteralComponents}
// //         />
// //       ) : (
// //         <OnlineOrderListStatusFilter
// //           date={selectedDate}
// //           serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
// //           handleSelectedFilter={handleSelectedFilter}
// //           selectedFilter={selectedFilter}
// //           selectedStatusFilters={selectedStatuses}
// //           containerWidth={splitViewContext.leftContainer?.width}
// //           includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
// //           refreshData={refreshDataInteralComponents}
// //         />
// //       )}

// //       <FlatList
// //         style={{ flex: 1 }}
// //         data={filteredData}
// //         // scrollEventThrottle={16}
// //         keyExtractor={(item, idx) => item.serviceId || idx.toString()}
// //         contentContainerStyle={{ paddingBottom: 50 }}
// //         showsVerticalScrollIndicator={false}
// //         ListEmptyComponent={displayEmptyList()}
// //         onEndReached={onEndReached}
// //         refreshControl={
// //           <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
// //         }
// //         renderItem={({ item }) => {
// //           return (
// //             <ModuleListItemRenderer
// //               item={item}
// //               onDelete={onDelete}
// //               onListItemExtraPress={onListItemExtraPress}
// //               onListItemPress={onListItemPress}
// //               type={type}
// //               groupBySlotInterval={listSettings?.groupBySlotInterval}
// //               nameFormat={listSettings?.clientNameFormat}
// //               selectedId={selectedId}
// //               setSelectedId={setSelectedId}
// //               isDisabled={false}
// //               selectedDate={selectedDate}
// //               showClientComment={listSettings?.showClientComment}
// //               showCreationDate={listSettings?.showCreationDate}
// //               showEventType={listSettings?.showEventType}
// //               onListItemOnUpdateComplete={handleListItemUpdateComplete}
// //               onListItemOnUpdateError={onRefresh}
// //               selectedFilter={selectedFilter}
// //               includeCancelled={listSettings?.includeCancelledInAllFilter}
// //               selectedServiceId={selectedServiceId}
// //               selectedStatuses={selectedStatuses}
// //             />
// //           );
// //         }}
// //       />
// //       {listSettings?.groupBookingsByPersons && type === Modules.Reservation && (
// //         <BookingSummaryByPersons
// //           date={selectedDate}
// //           serviceId={selectedServiceId}
// //           refreshData={refreshDataInteralComponents}
// //           itemMinWidth={
// //             splitViewContext?.leftContainer?.width
// //               ? splitViewContext?.leftContainer?.width / 15
// //               : "100%"
// //           }
// //         />
// //       )}
// //       <FilterModal
// //         isOpen={isFilterOpen}
// //         setIsOpen={setIsFilterOpen}
// //         onConfirm={onFilterConfirm}
// //         selectedStatuses={selectedStatuses}
// //         type={type}
// //       />
// //     </Box>
// //   );
// // };

// // export default ModuleList;

import { isSameDay } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlatList, RefreshControl } from "react-native";

import SEARCH from "../../../assets/icons/BASE/SEARCH.svg";
import { SplitViewContext } from "../../contexts/SpitViewContext";
import type {
  AvailabilityServiceWithModuleInfo,
  BookingListingSettings,
  BookingsAndServices,
  CreateBookingFloorExperienceForSelectedDateInput,
  OrdersAndServices,
} from "../../graphql/generated/schema";
import { Booking_Status, Click_Status, Modules } from "../../graphql/generated/schema";
import { useDebounce } from "../../hooks/useDebounce";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import BookingServices from "../BookingList/BookingListHeader/BookingServices";
import ListEmpty from "../BookingList/ListEmpty";
import BookingListStatusFilter from "../BookingListStatusFilter/index";
import BookingSummaryByPersons from "../BookingSummaryByPersons";
import ListingScreenHeader from "../ListingScreenHeader";
import OnlineOrderListStatusFilter from "../OnlineOrderListStatusFilter/index";
import { CustomTextInput } from "../TextInput";

import FilterModal from "./FilterModal";
import ModuleListItemRenderer from "./ModuleListItemRenderer";
import PrintPdfModal from "./PrintPdfModal/index";

interface ModuleListProps {
  title: string;
  type: Modules;
  services: AvailabilityServiceWithModuleInfo[];
  selectedServiceId: string;
  onServiceSelected: (serviceId: string) => void;
  onNewAction?: () => void;
  onSearchAction?: (query: string) => void | Promise<void>;
  onBookingFloorAction?: () => void;
  onLocalBookingPress?: () => void;
  onFilterAction?: () => void;
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  statuses: string[];
  listData: (BookingsAndServices | OrdersAndServices)[];
  onListItemPress: (itemId: string) => void;
  onListItemExtraPress: (itemId: string) => void;
  onDelete: (itemId: string) => void;
  isRefreshing: boolean;
  onRefresh: () => void;
  dataByMonth: Record<string, unknown>;
  listSettings: BookingListingSettings | null;
  onCloseServiceSlot: (serviceId: string, slotId?: string) => void;
  onMonthChange?: (date: Date) => void;
  selectedId: string;
  setSelectedId: (id: string) => void;
  onEndReached: () => void;
  onListItemUpdateCompleteHandler: (itemId: string) => void;
  isServiceSlotCloseInProgress: boolean;
  isSearchOpen: boolean;
  setIsSearchOpen: (value: boolean) => void;
  selectedStatuses: (Booking_Status | Click_Status)[];
  setSelectedStatuses: (value: (Booking_Status | Click_Status)[]) => void;
  shouldShowStatusFilterBadge: boolean;
  setShouldShowStatusFilterBadge: (value: boolean) => void;
  handleSelectFilter: (filter: (Booking_Status | Click_Status)[]) => void;
  setSelectedFloorId: (floorId: string) => void;
  selectedFloorId: string;
}

const ModuleList = ({
  title,
  onNewAction,
  onSearchAction,
  onBookingFloorAction,
  onFilterAction,
  onLocalBookingPress,
  type,
  services,
  selectedServiceId,
  onServiceSelected,
  selectedDate,
  onDateChange,
  statuses,
  listData,
  onListItemPress,
  onListItemExtraPress,
  onDelete,
  isRefreshing,
  onRefresh,
  dataByMonth,
  listSettings,
  onCloseServiceSlot,
  onMonthChange,
  selectedId,
  setSelectedId,
  onEndReached,
  onListItemUpdateCompleteHandler,
  isServiceSlotCloseInProgress,
  isSearchOpen,
  setIsSearchOpen,
  selectedStatuses,
  setSelectedStatuses,
  shouldShowStatusFilterBadge,
  setShouldShowStatusFilterBadge,
  handleSelectFilter,
  setSelectedFloorId,
  selectedFloorId,
}: ModuleListProps) => {
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedStatuses, setselectedStatuses] = useState<
  //   (Booking_Status | Click_Status)[]
  // >([]);
  const splitViewContext = useContext(SplitViewContext);
  const [refreshDataInteralComponents, setRefreshDataInteralComponents] = useState(false);

  useDebounce(
    () => {
      onSearchAction?.(searchQuery);
    },
    400,
    [searchQuery],
  );

  useEffect(() => {
    if (isRefreshing) {
      setRefreshDataInteralComponents(v => !v);
    }
  }, [isRefreshing]);

  const isCurrentDay = isSameDay(selectedDate, new Date());

  const handleListItemUpdateComplete = (itemId: string) => {
    setRefreshDataInteralComponents(v => !v);
    onListItemUpdateCompleteHandler(itemId);
  };

  const totalCapacityForAllServices = useMemo(() => {
    return listData.reduce((dataAcc, item) => {
      return (
        dataAcc +
        item.slots.reduce((acc, slot) => {
          return (
            acc +
            slot.data.reduce((innerAcc, innerData) => {
              if (type === Modules.Reservation) {
                return innerAcc + innerData.nbPersons;
              } else {
                return innerAcc + 1;
              }
            }, 0)
          );
        }, 0)
      );
    }, 0);
  }, [listData]);

  const filteredData = listData
    .map(item => {
      const slots = item.slots
        .map(slot => {
          const filteredItems = slot.data.filter(data => {
            const IS_ON_ALL_TAB = selectedFilter === "ALL";

            if (
              type === Modules.Reservation &&
              IS_ON_ALL_TAB &&
              !listSettings?.includeCancelledInAllFilter
            ) {
              return (
                data.status !== Booking_Status.CancelledByOwner &&
                data.status !== Booking_Status.CancelledByUser
              );
            } else if (
              type === Modules.OnlineSales &&
              IS_ON_ALL_TAB &&
              !listSettings?.includeCancelledInAllFilter
            ) {
              return (
                data.status !== Click_Status.CancelledByOwner &&
                data.status !== Click_Status.CancelledByUser
              );
            }

            return true;
          });

          return { ...slot, data: filteredItems };
        })
        .filter(slot => slot.data.length > 0);

      return { ...item, slots };
    })
    .filter(item => item.slots.length > 0);

  const onFilterConfirm = (data: { statuses: (Booking_Status | Click_Status)[] }) => {
    const { statuses: filterStatuses } = data;

    setSelectedStatuses(filterStatuses);

    if (filterStatuses.length > 0) {
      setSelectedFilter("OTHERS");

      handleSelectFilter(filterStatuses);
      setShouldShowStatusFilterBadge(true);
    }
  };

  const handleSelectedFilter = (filter: string) => {
    setSelectedFilter(filter);

    if (type === Modules.Reservation) {
      let statusesByFilter: Booking_Status[] = [];

      switch (filter) {
        case "ALL":
          statusesByFilter = [];
          break;
        case "PENDING":
          statusesByFilter = [
            Booking_Status.New,
            Booking_Status.ModifiedByOwner,
            Booking_Status.ModifiedByUser,
            Booking_Status.WaitingList,
            Booking_Status.Delayed,
          ];
          break;
        case "VALIDATED":
          statusesByFilter = [
            Booking_Status.Validated,
            Booking_Status.AutomaticValidated,
          ];
          break;
        case "OTHERS":
          statusesByFilter = [
            Booking_Status.CancelledByOwner,
            Booking_Status.CancelledByUser,
            Booking_Status.Noshow,
            Booking_Status.Arrived,
            Booking_Status.ArrivedWaiting,
            Booking_Status.Delayed,
            Booking_Status.WaitingList,
            Booking_Status.Ended,
            Booking_Status.Seated,
            Booking_Status.Refunded,
          ];
          break;
        default:
          break;
      }

      handleSelectFilter(statusesByFilter);
    } else if (type === Modules.OnlineSales) {
      let statusesByFilter: Click_Status[] = [];

      switch (filter) {
        case "ALL":
          statusesByFilter = [];
          break;
        case "PENDING":
          statusesByFilter = [
            Click_Status.New,
            Click_Status.ModifiedByOwner,
            Click_Status.ModifiedByUser,
            Click_Status.WaitingList,
          ];
          break;
        case "VALIDATED":
          statusesByFilter = [Click_Status.Validated, Click_Status.AutomaticValidated];
          break;
        case "OTHERS":
          statusesByFilter = [
            Click_Status.CancelledByOwner,
            Click_Status.CancelledByUser,
            Click_Status.Noshow,
            Click_Status.Ended,
            Click_Status.Refunded,
            Click_Status.AutomaticCancel,
            Click_Status.PaymentFailure,
            Click_Status.Prepared,
            Click_Status.Preparing,
          ];
          break;

        default:
          break;
      }

      handleSelectFilter(statusesByFilter);
    }

    setShouldShowStatusFilterBadge(false);
  };

  const displayEmptyList = () => {
    return (
      <ListEmpty
        isOnAllTab={selectedFilter === "ALL"}
        module={type}
        selectedDate={selectedDate}
      />
    );
  };

  const onPrintAction = () => {
    if (type !== Modules.Reservation) return undefined;

    return () => setIsPrintModalOpen(true);
  };

  return (
    <Box flex={1} pt="s" backgroundColor="white">
      <Box paddingHorizontal="s" marginVertical="s">
        <ListingScreenHeader
          title={title}
          onNewAction={onNewAction}
          onLocalBookingPress={isCurrentDay ? onLocalBookingPress : undefined}
          onSearchAction={() => setIsSearchOpen(v => !v)}
          onBookingFloorAction={onBookingFloorAction}
          onFilterAction={() => {
            setIsFilterOpen(true);
          }}
          onPrintAction={onPrintAction()}
          filterBadgeCount={
            shouldShowStatusFilterBadge && selectedStatuses.length > 0
              ? selectedStatuses.length
              : 0
          }
        />
        <Box marginVertical="s">
          <BookingServices
            {...{
              onCloseServiceSlot,
              moduleType: type,
              selectedDate,
              onDayPress: onDateChange,
              selectedServiceId,
              onSelectService: onServiceSelected,
              services,
              dataByMonth,
              onMonthChange,
              isServiceSlotCloseInProgress,
              totalCapacityForAllServices,
              selectedFloorId,
              setSelectedFloorId,
            }}
          />
        </Box>

        {isSearchOpen && (
          <Box marginVertical="xs">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
                ),
                onChangeText: t => {
                  setSearchQuery?.(t);
                },
              }}
            />
          </Box>
        )}
      </Box>

      {type === Modules.Reservation ? (
        <BookingListStatusFilter
          date={selectedDate}
          serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
          handleSelectedFilter={handleSelectedFilter}
          selectedFilter={selectedFilter}
          selectedStatusFilters={selectedStatuses}
          containerWidth={splitViewContext.leftContainer?.width}
          includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
          refreshData={refreshDataInteralComponents}
          selectedFloorId={selectedFloorId}
        />
      ) : (
        <OnlineOrderListStatusFilter
          date={selectedDate}
          serviceId={selectedServiceId === "ALL" ? null : selectedServiceId}
          handleSelectedFilter={handleSelectedFilter}
          selectedFilter={selectedFilter}
          selectedStatusFilters={selectedStatuses}
          containerWidth={splitViewContext.leftContainer?.width}
          includeCancelledInAllFilter={listSettings?.includeCancelledInAllFilter}
          refreshData={refreshDataInteralComponents}
        />
      )}

      <FlatList
        style={{ flex: 1 }}
        data={filteredData}
        // scrollEventThrottle={16}
        keyExtractor={(item, idx) => item.serviceId || idx.toString()}
        contentContainerStyle={{ paddingBottom: 50 }}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={displayEmptyList()}
        onEndReached={onEndReached}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
        }
        renderItem={({ item }) => {
          return (
            <ModuleListItemRenderer
              item={item}
              onDelete={onDelete}
              onListItemExtraPress={onListItemExtraPress}
              onListItemPress={onListItemPress}
              type={type}
              groupBySlotInterval={listSettings?.groupBySlotInterval}
              nameFormat={listSettings?.clientNameFormat}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              isDisabled={false}
              selectedDate={selectedDate}
              showClientComment={listSettings?.showClientComment}
              showCreationDate={listSettings?.showCreationDate}
              showEventType={listSettings?.showEventType}
              showAllergies={listSettings?.showAllergies}
              showBasket={listSettings?.showBasket}
              onListItemOnUpdateComplete={handleListItemUpdateComplete}
              onListItemOnUpdateError={onRefresh}
              selectedFilter={selectedFilter}
              includeCancelled={listSettings?.includeCancelledInAllFilter}
              selectedServiceId={selectedServiceId}
              selectedStatuses={selectedStatuses}
            />
          );
        }}
      />
      {listSettings?.groupBookingsByPersons && type === Modules.Reservation && (
        <BookingSummaryByPersons
          date={selectedDate}
          serviceId={selectedServiceId}
          refreshData={refreshDataInteralComponents}
          selectedStatuses={selectedStatuses}
          selectedFloorId={selectedFloorId}
          itemMinWidth={
            splitViewContext?.leftContainer?.width
              ? splitViewContext?.leftContainer?.width / 15
              : "100%"
          }
        />
      )}
      <FilterModal
        isOpen={isFilterOpen}
        setIsOpen={setIsFilterOpen}
        onConfirm={onFilterConfirm}
        selectedStatuses={selectedStatuses}
        type={type}
      />

      <PrintPdfModal
        isOpen={isPrintModalOpen}
        selectedDate={selectedDate}
        selectedServiceId={selectedServiceId === "ALL" ? undefined : selectedServiceId}
        setIsOpen={setIsPrintModalOpen}
      />
    </Box>
  );
};

export default ModuleList;
