import { format, isAfter, isSameDay } from "date-fns";
import { useState } from "react";
import { Image } from "react-native";

import CUTLERY from "../../../../../../../assets/icons/BASE/CUTLERY.svg";
import EDIT from "../../../../../../../assets/icons/BASE/EDIT.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import MODIFY from "../../../../../../../assets/icons/BASE/MODIFY.svg";
import OCCURENCES from "../../../../../../../assets/icons/BASE/OCCURENCES.svg";
import RIGHT_ARROW from "../../../../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import type {
  Origin,
  TimelineEvent as TimelineEventType,
} from "../../../../../../graphql/generated/schema";
import {
  Booking_Status,
  Click_Status,
  Event_Action,
  Event_Action_Type,
  Modules,
  UserType,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
// import type { TimelineEvent as TimelineEventType } from "../../../../../../types/types";
import {
  convertOriginNbToEnum,
  getLabelsForEvent,
  getLabelsForOrigin,
} from "../../../../../../utils/common";
import Box from "../../../../../Base/Box";
import { CustomText } from "../../../../../Base/Text";
import BookingListCardOrigin from "../../../../../BookingList/BookingListCardOrigin";
import BookingListCardStatusButton from "../../../../../BookingList/BookingListCardStatusButton";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../../../../BookingList/BookingListCardStatusButtonItem";
import { CustomButton } from "../../../../../Button";
import ContentModal from "../../../../../ContentModal";
import OnlineOrderStatusButton from "../../../../../OnlineOrdersList/OnlineOrderStatusButton";
import { ONLINE_STATUS_LAYOUT_TYPE } from "../../../../../OnlineOrdersList/OnlineOrderStatusButtonItem";

interface TimelineEventProps {
  timelineEvent: TimelineEventType;
  merchantClientName: string;
  merchantClientImage?: string;
  date?: Date;
  source?: Origin;
  createdAt: Date;
  nbPersons?: number;
  module: Modules;
  currentStatus: Booking_Status;
  isLastEvent: boolean;
  canUndoChanges?: boolean;
  undoLastStatusChange?: (status: Booking_Status) => void;
}

const ICON_SIZE = 15;

const TimelineEvent = ({
  timelineEvent,
  source,
  merchantClientImage,
  merchantClientName,
  date,
  nbPersons,
  createdAt,
  module,
  currentStatus,
  canUndoChanges,
  undoLastStatusChange,
  isLastEvent,
}: TimelineEventProps) => {
  const [isUndoModalOpen, setIsUndoModalOpen] = useState(false);
  const { eventDate, eventAuthor, eventAction, eventType, previousState, modifiedState } =
    timelineEvent;
  const { firstName = "", lastName = "", userType } = eventAuthor;
  const formattedName =
    userType === UserType.System ? "Eiwie" : `${firstName} ${lastName}`;

  const shouldShowUndoButton = () => {
    const consolidatedDate = date ? new Date(date) : new Date();
    const isOnCurrentDayOrInFuture =
      isSameDay(new Date(consolidatedDate), new Date()) ||
      isAfter(new Date(consolidatedDate), new Date());
    const isStatusChange = eventAction === Event_Action.Status;

    const BOOKING_END_STATUSES = ["60", "70", "90"];

    const IS_CURRENT_STATUS_IN_END_STATUS =
      currentStatus === Booking_Status.Ended ||
      currentStatus === Booking_Status.Noshow ||
      currentStatus === Booking_Status.CancelledByOwner;

    const isEndStatus =
      module === Modules.Reservation && BOOKING_END_STATUSES.includes(modifiedState);

    if (!canUndoChanges) return false;

    return (
      isStatusChange &&
      IS_CURRENT_STATUS_IN_END_STATUS &&
      isEndStatus &&
      isLastEvent &&
      undoLastStatusChange &&
      isOnCurrentDayOrInFuture
    );
  };

  const getTimelineEventActionTitle = () => {
    switch (eventAction) {
      case Event_Action.Time:
        return "l'heure";
      case Event_Action.Date:
        return "de la date";
      case Event_Action.Comment:
        return "du commentaire client";
      case Event_Action.CommentOwner:
        return "de la note de la réservation";
      case Event_Action.Tables:
        return "des tables";
      case Event_Action.Allergies:
        return "de l'allergie";
      case Event_Action.Status:
        return "du statut";
      case Event_Action.NbPersons:
        return "de nombre de personnes";
      case Event_Action.Source:
        return "de l'origine";
      case Event_Action.Event:
        return "de l'évènement";

      default:
        break;
    }
    return "";
  };

  const convertState = (state: string) => {
    if (eventAction === Event_Action.Source) {
      return getLabelsForOrigin(state, convertOriginNbToEnum(+state));
    }
    if (eventAction === Event_Action.Event) {
      return getLabelsForEvent(state, state);
    }

    return state || "-";
  };

  const getAddAndDeleteTimelineEventActionContent = () => {
    const moduleType = module === Modules.Reservation ? "réservation" : "commande";

    if (
      eventType === Event_Action_Type.Add &&
      eventAction === Event_Action.AskPhoneVerification
    ) {
      return "Demande de vérification du numéro de téléphone";
    }
    if (
      eventType === Event_Action_Type.Add &&
      eventAction === Event_Action.AskPrepayment
    ) {
      return "Demande de prépaiement";
    }

    if (eventType === Event_Action_Type.Add) {
      return "Création de la " + moduleType;
    }

    if (eventType === Event_Action_Type.Delete) {
      return `Suppression de la ${moduleType}`;
    }
    if (eventType === Event_Action_Type.Modification) {
      const formatteEventAction = getTimelineEventActionTitle();
      return `Modification ${formatteEventAction} de la ${moduleType} (${convertState(
        previousState,
      )} => ${convertState(modifiedState)})`;
    }
  };

  const convertStatus = (status: string) => {
    if (module === Modules.Reservation) {
      switch (status) {
        case "10":
          return Booking_Status.New;
        case "20":
          return Booking_Status.Delayed;
        case "25":
          return Booking_Status.WaitingList;
        case "30":
          return Booking_Status.ModifiedByUser;
        case "40":
          return Booking_Status.ModifiedByOwner;
        case "50":
          return Booking_Status.Validated;
        case "52":
          return Booking_Status.AutomaticValidated;
        // case "53":
        //   return Booking_Status.Reconfimed;
        case "54":
          return Booking_Status.Arrived;
        case "55":
          return Booking_Status.ArrivedWaiting;
        case "58":
          return Booking_Status.Seated;
        case "60":
          return Booking_Status.Ended;
        case "70":
          return Booking_Status.Noshow;
        case "80":
          return Booking_Status.CancelledByUser;
        case "90":
          return Booking_Status.CancelledByOwner;
        case "100":
          return Booking_Status.Refunded;

        default:
          break;
      }
    } else if (module === Modules.OnlineSales) {
      switch (status) {
        case "10":
          return Click_Status.New;
        case "15":
          return Click_Status.WaitingList;
        case "20":
          return Click_Status.ModifiedByUser;
        case "30":
          return Click_Status.ModifiedByOwner;
        case "40":
          return Click_Status.Validated;
        case "45":
          return Click_Status.AutomaticValidated;
        case "50":
          return Click_Status.Preparing;
        case "55":
          return Click_Status.Prepared;
        case "60":
          return Click_Status.Ended;
        case "70":
          return Click_Status.Noshow;
        case "80":
          return Click_Status.CancelledByUser;
        case "90":
          return Click_Status.CancelledByOwner;
        case "100":
          return Click_Status.PaymentFailure;
        case "110":
          return Click_Status.AutomaticCancel;
        case "120":
          return Click_Status.Refunded;

        default:
          break;
      }
    }
  };

  const displayStatusChanges = () => {
    if (module === Modules.Reservation) {
      return (
        <Box flexDirection="row" alignItems="center" pt="s">
          <Box>
            <BookingListCardStatusButton
              isDisabled
              status={convertStatus(previousState)}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onPress={() => {}}
              type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
            />
          </Box>
          <Box marginHorizontal="s">
            <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.darkGrey} />
          </Box>
          <Box>
            <BookingListCardStatusButton
              isDisabled
              status={convertStatus(modifiedState)}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onPress={() => {}}
              type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
            />
          </Box>
        </Box>
      );
    } else if (module === Modules.OnlineSales) {
      return (
        <Box flexDirection="row" alignItems="center" pt="s">
          <Box>
            <OnlineOrderStatusButton
              isDisabled
              status={convertStatus(previousState)}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onPress={() => {}}
              type={ONLINE_STATUS_LAYOUT_TYPE.TEXT}
            />
          </Box>
          <Box marginHorizontal="s">
            <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.darkGrey} />
          </Box>
          <Box>
            <OnlineOrderStatusButton
              isDisabled
              status={convertStatus(modifiedState)}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onPress={() => {}}
              type={ONLINE_STATUS_LAYOUT_TYPE.TEXT}
            />
          </Box>
        </Box>
      );
    }

    return <></>;
  };

  const displayTimelineEventAction = () => {
    if (
      eventType === Event_Action_Type.Modification &&
      eventAction === Event_Action.Status
    )
      return displayStatusChanges();

    const content = getAddAndDeleteTimelineEventActionContent();

    if (!content) return <></>;

    return (
      <Box flex={1} flexWrap="wrap" pt="s" flexDirection="row" alignItems="center">
        <EDIT width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        <CustomText ml="s" variant="content" fontSize={14} color="primaryTextColor">
          {getAddAndDeleteTimelineEventActionContent()}
        </CustomText>
      </Box>
    );
  };

  const handleSubmitUndoLastStatusChange = () => {
    undoLastStatusChange?.(convertStatus(previousState));
    setIsUndoModalOpen(false);
  };

  const displayUndoModal = () => {
    return (
      <ContentModal
        isVisible={isUndoModalOpen}
        onClose={() => setIsUndoModalOpen(false)}
        title="Annuler la modification"
      >
        <Box>
          <Box>
            <CustomText variant="content" color="primaryTextColor">
              Êtes-vous sur d’annuler la dernière modification effectuée sur cette
              réservation ?
            </CustomText>
          </Box>

          <Box
            mt="m"
            p="m"
            pb="l"
            backgroundColor="blueBackground"
            borderRadius="button"
            borderColor="blue"
          >
            <Box
              backgroundColor="blue"
              borderRadius="button"
              height={50}
              width={50}
              alignSelf="center"
              alignItems="center"
              justifyContent="center"
            >
              <INFO width={24} height={24} fill={PALETTE.white} />
            </Box>

            <Box mt="m">
              <CustomText variant="content" color="blue">
                Aucun message ne sera renvoyé au client
              </CustomText>
              <CustomText mt="m" variant="content" color="blue">
                Si des messages avaient été envoyés suite à la dernière modification, ils
                ne seront pas annulés.
              </CustomText>
            </Box>
          </Box>

          <Box mt="m">
            <CustomButton
              onPress={() => setIsUndoModalOpen(false)}
              buttonVariant="outlineButton"
              borderColor="danger"
            >
              <CustomText variant="content" color="danger">
                Annuler
              </CustomText>
            </CustomButton>
          </Box>
          <Box mt="s">
            <CustomButton
              onPress={handleSubmitUndoLastStatusChange}
              buttonVariant="primaryButton"
              buttonColor="success"
            >
              <CustomText variant="primaryButtonText" color="white">
                Annuler cette modification
              </CustomText>
            </CustomButton>
          </Box>
        </Box>
      </ContentModal>
    );
  };

  return (
    <Box>
      <Box width="100%">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            <CustomText variant="label" fontSize={14} mr="s" color="primaryTextColor">
              {format(new Date(eventDate), "dd/MM/yyyy")}
            </CustomText>
            <CustomText variant="content" color="primaryTextColor">
              {format(new Date(eventDate), "(HH:mm)")}
            </CustomText>
          </Box>
          <Box flexDirection="row" alignItems="center">
            <MODIFY height={14} width={14} fill={PALETTE.green} />
            <CustomText pl="s" variant="label">
              {formattedName}
            </CustomText>
          </Box>
        </Box>
        {displayTimelineEventAction()}
        <Box backgroundColor="disabled" borderRadius="button" p="s" mt="s">
          <Box>
            <CustomText variant="label" color="labelColor">
              {merchantClientName}
            </CustomText>

            {merchantClientImage && (
              <Image
                source={{
                  uri: merchantClientImage,
                }}
                style={{
                  width: 45,
                  height: 45,
                  position: "absolute",
                  right: 0,
                  top: -18,
                  borderRadius: 45 / 2,
                }}
              />
            )}
          </Box>

          <Box flexDirection="row" alignItems="center" pt="s">
            {date && (
              <Box flexDirection="row" alignItems="center">
                <OCCURENCES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText pl="s" variant="description" color="primaryTextColor">
                  {format(new Date(date), "HH:mm")}
                </CustomText>
              </Box>
            )}

            {nbPersons && (
              <Box flexDirection="row" alignItems="center" ml="s">
                <CUTLERY height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                <CustomText pl="s" variant="description" color="primaryTextColor">
                  {nbPersons}
                </CustomText>
              </Box>
            )}
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pt="s"
          >
            <Box flexDirection="row" alignItems="center">
              <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
              <CustomText pl="s" variant="description" color="primaryTextColor">
                Crée le {format(new Date(createdAt), "dd/MM/yyyy à HH:mm")}
              </CustomText>
            </Box>
            {source && (
              <Box flexDirection="row" alignItems="center" ml="s">
                <BookingListCardOrigin origin={source} />

                <CustomText pl="s" variant="description" color="primaryTextColor">
                  {source}
                </CustomText>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {shouldShowUndoButton() && (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsUndoModalOpen(true)}
            borderColor="yellow"
            buttonVariant="outlineButton"
          >
            <CustomText variant="content" color="yellow">
              Annuler la modification
            </CustomText>
          </CustomButton>
        </Box>
      )}

      {isUndoModalOpen && displayUndoModal()}
    </Box>
  );
};

export default TimelineEvent;
