import DateTimePicker from "@react-native-community/datetimepicker";
import { addHours, format, isValid, startOfHour } from "date-fns";
import React, { useEffect, useState } from "react";
import { Platform } from "react-native";

import { PALETTE } from "../../theme/Palette";
import { createDateWithTime } from "../../utils/common";
import Box from "../Base/Box";
import BottomButton from "../BottomButton";
import ContentModal from "../ContentModal";
import { CustomTextInput } from "../TextInput";
import Touchable from "../Touchable";

interface TimeInputProps {
  date: Date;
  placeHolder: string;
  onChange: (date: Date) => void;
  disabled?: boolean;
  interval?: number;
}

const IS_ANDROID = Platform.OS === "android";
const IS_IOS = Platform.OS === "ios";
const IS_WEB = Platform.OS === "web";

const TimeInput = ({
  date,
  placeHolder,
  onChange,
  disabled = false,
  interval = 30,
}: TimeInputProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const displayDateInputWeb = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnChangeWeb = (event: any) => {
      const possibleIntervalsMins = [];
      for (let i = 0; i <= 60; i += interval) {
        possibleIntervalsMins.push(i);
      }

      let newDate = createDateWithTime(new Date(), event.target.value);

      if (possibleIntervalsMins.length === 0) {
        newDate = startOfHour(new Date());
      } else {
        const closedInterval = possibleIntervalsMins.find(i => i >= newDate.getMinutes());

        if (closedInterval) {
          const hour = newDate.getHours();
          const minutes = closedInterval;
          const time = `${hour}:${minutes}`;

          if (minutes === 60) {
            newDate = addHours(startOfHour(new Date(newDate)), 1);
          } else {
            newDate = createDateWithTime(new Date(), time);
          }
        }
      }

      setSelectedDate(newDate);
    };

    const formattedDate = isValid(selectedDate)
      ? format(selectedDate, "HH:mm")
      : format(new Date(date), "HH:mm");
    return React.createElement("input", {
      type: "time",
      value: formattedDate,
      onInput: handleOnChangeWeb,
      style: {
        borderRadius: 8,
        padding: 10,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderWidth: "1px",
      },
    });
  };

  const handleSubmitAndroid = (d: Date) => {
    onChange(d);
    setIsModalOpen(false);
  };

  const displayDateInputIos = () => {
    return (
      <Box style={{ width: "100%", backgroundColor: "danger" }}>
        <DateTimePicker
          value={selectedDate}
          mode="time"
          minuteInterval={interval}
          textColor={PALETTE.darkBlue}
          display="spinner"
          onChange={(event, newDate) => {
            newDate && setSelectedDate(newDate);

            if (IS_ANDROID) {
              newDate && handleSubmitAndroid(newDate);
            }
          }}
        />
      </Box>
    );
  };

  const displayDateInputAndroid = () => {
    if (IS_ANDROID && isModalOpen) {
      return displayDateInputIos();
    }
  };

  const handleSubmit = () => {
    const d = isValid(selectedDate)
      ? selectedDate
      : createDateWithTime(new Date(), format(new Date(date), "HH:mm"));
    onChange(d);
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedDate(date);
  };

  const displayModalContent = () => {
    if (IS_WEB || IS_IOS) {
      return (
        <ContentModal
          title="Sélectionner une heure"
          isVisible={isModalOpen}
          onClose={handleClose}
          styles={{
            maxWidth: 450,
            height: "auto",
          }}
        >
          <Box minHeight={300}>
            {IS_WEB ? displayDateInputWeb() : displayDateInputIos()}

            <BottomButton title="Valider" onPress={handleSubmit} />
          </Box>
        </ContentModal>
      );
    }
    return displayDateInputAndroid();
  };

  const formattedDate = isValid(new Date(selectedDate))
    ? format(new Date(selectedDate), "HH:mm")
    : format(new Date(date), "HH:mm");

  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <Touchable disabled={disabled} onPress={handleOpenModal}>
        <CustomTextInput
          placeHolder={placeHolder}
          value={formattedDate}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChangeText={() => {}}
          disabled
          boxProps={{
            backgroundColor: "white",
          }}
        />
      </Touchable>
      {displayModalContent()}
    </>
  );
};

export default TimeInput;
