import * as ImagePicker from "expo-image-picker";

const DEFAULT_MEDIA_LIBRARY_OPTIONS: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: false,
  base64: true,
  quality: 0.2,
  allowsMultipleSelection: false,
};

export const pickImageFromGallery = async (
  options: ImagePicker.ImagePickerOptions = DEFAULT_MEDIA_LIBRARY_OPTIONS,
) => {
  const currentPermission = await ImagePicker.getMediaLibraryPermissionsAsync();

  if (!currentPermission.granted) {
    const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (!permission.granted) {
      throw new Error("PERMISSION_NOT_GRANTED");
    }
  }

  const result = await ImagePicker.launchImageLibraryAsync(options);

  return result;
};

const DEFAULT_CAMERA_OPTIONS: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: false,
  base64: true,
  quality: 0.2,
  allowsMultipleSelection: false,
};

export const takePictureFromCamera = async (
  options: ImagePicker.ImagePickerOptions = DEFAULT_CAMERA_OPTIONS,
) => {
  const currentPermission = await ImagePicker.getCameraPermissionsAsync();

  if (!currentPermission.granted) {
    const permission = await ImagePicker.requestCameraPermissionsAsync();

    if (!permission.granted) {
      throw new Error("PERMISSION_NOT_GRANTED");
    }
  }

  const result = await ImagePicker.launchCameraAsync(options);

  return result;
};
