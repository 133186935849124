import { useState } from "react";
import { Platform } from "react-native";

import type { THEME_COLORS } from "../../theme";
import type { PALETTE_COLORS } from "../../theme/Palette";
import type { TEXT_VARIANTS } from "../../theme/variants/TextVariants";
import Box from "../Base/Box";
import ButtonWithActions from "../ButtonWithActions";
import ContentModal from "../ContentModal";

import ButtonActionsListModal from "./ButtonActionsListModal";

export interface BUTTON_ACTION {
  title: string;
  key: string;
  variant: "primary" | "outline";
  color: THEME_COLORS;
}

const IS_ANDROID = Platform.OS === "android";

interface BottomButtomWithActionsProps {
  title: string;
  actions: BUTTON_ACTION[];
  onSubmit: (key: string) => void;
  onMainActionPress: () => void;
  backGroundColor?: THEME_COLORS;
  minHeight?: number;
  textVariant?: TEXT_VARIANTS;
  color?: THEME_COLORS;
  iconColor?: PALETTE_COLORS;
  styles?: React.ComponentProps<typeof Box>;
}

const BottomButtomWithActions = ({
  actions,
  onSubmit,
  onMainActionPress,
  title,
  backGroundColor = "white",
  minHeight,
  textVariant,
  color,
  iconColor,
  styles,
}: BottomButtomWithActionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnSubmit = (key: string) => {
    onSubmit(key);
    setIsModalOpen(false);
  };

  return (
    <Box
      justifyContent="center"
      p="s"
      pb="xl"
      backgroundColor="white"
      position="absolute"
      bottom={IS_ANDROID ? 20 : 0}
      left={0}
      right={0}
      minHeight={minHeight}
      {...styles}
    >
      <ButtonWithActions
        actions={actions}
        onMainButtonPress={onMainActionPress}
        title={title}
        backgroundColor={backGroundColor}
        onExtraActionButtonPress={onSubmit}
        textVariant={textVariant}
        textColor={color}
        iconColor={iconColor}
      />

      <ContentModal
        title=""
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        styles={{
          maxWidth: 400,
          height: "auto",
        }}
      >
        <ButtonActionsListModal actions={actions} onPress={handleOnSubmit} />
      </ContentModal>
    </Box>
  );
};

export default BottomButtomWithActions;
