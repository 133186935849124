/* eslint-disable max-len */
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage/index";
import InlineSelectButton from "../../../../components/InlineSelectButton";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import type { MerchantClientNotificationsFragment } from "../../../../graphql/generated/schema";
import {
  Merchant_Notification_Options,
  useGetMerchantClientNotificationsLazyQuery,
  useUpdateMerchantClientNotificationsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface ClientNotificationsProps {
  goBack?: () => void;
}

const OPTIONS = [
  {
    key: Merchant_Notification_Options.Sms,
    label: "S",
  },
];

interface Notification {
  title: string;
  description: string;
  options: typeof OPTIONS;
  key: keyof MerchantClientNotificationsFragment;
}

const BOOKING_NOTIFICATIONS: Notification[] = [
  {
    title: "En attente",
    description:
      "Vos clients reçoivent un SMS leur informant que leur réservation et en attente de confirmation",
    options: OPTIONS,
    key: "pendingBooking",
  },
  {
    title: "Confirmée",
    description: "Vos clients reçoivent un SMS lorsque vous confirmer leur réservation",
    options: OPTIONS,
    key: "validatedBooking",
  },
  {
    title: "Annulée ",
    description: "Notification à chaque fois que vous annulez sa réservation",
    options: OPTIONS,
    key: "rejectedBooking",
  },
  {
    title: "Mise à jour",
    description: "Notification à chaque fois que vous modifiez une réservation",
    options: OPTIONS,
    key: "updatedBooking",
  },
  {
    title: "Nouvel horaire proposé",
    description:
      "Notification lorsque vous leur proposez un nouvel horaire de réservation",
    options: OPTIONS,
    key: "newHourProposalAcceptedBooking",
  },
  {
    title: "Rappel de réservation",
    description:
      "Notification de rappel envoyé au client pour lui rappeler sa réservation",
    options: OPTIONS,
    key: "bookingReminder",
  },
  {
    title: "Réservation non honorée (no show)",
    description: "Notification lorsque vous passez leur réservation au statut No-show",
    options: OPTIONS,
    key: "bookingNoShow",
  },
  // {
  //   title: "Menu digital",
  //   description: "Notification lorsque vous leur envoyez la carte du service",
  //   options: OPTIONS,
  // },
];

const ORDER_NOTIFICATIONS: Notification[] = [
  {
    title: "En attente",
    description: "Notification lors d’une nouvelle commande en attente de confirmation",
    options: OPTIONS,
    key: "pendingOrder",
  },
  {
    title: "Confirmée auto",
    description: "Nouvelle commande confirmée automatiquement",
    options: OPTIONS,
    key: "validatedOrder",
  },
  {
    title: "Annulée ",
    description: "Notification à chaque fois que vous annulez sa commande",
    options: OPTIONS,
    key: "rejectedOrder",
  },
  {
    title: "Nouvel horaire accepté",
    description: "Notification à chaque fois que votre client accepte un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalAcceptedOrder",
  },
  {
    title: "Nouvel horaire refusé",
    description: "Notification à chaque fois que votre client refuse un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalRejectedOrder",
  },
  // {
  //   title: "Commande à table",
  //   description:
  //     "Notification détaillant les informations de commande lorsque vous passez leur réservation au statut 'Arrivé' ou 'Assis'",
  //   options: OPTIONS,
  // },
  // {
  //   title: "Instruction de paiement",
  //   description:
  //     "détaillant les instructions de paiement lorsque vous passez leur commande au statut “Paiement à table”",
  //   options: OPTIONS,
  // },
];

const MESSAGES_NOTIFICATIONS: Notification[] = [
  {
    title: "Nouvelle demande",
    description: "Notification suite à sa nouvelle demande",
    options: OPTIONS,
    key: "newMessage",
  },
  {
    title: "Réponse au message",
    description: "Notification suite votre réponse",
    options: OPTIONS,
    key: "messageResponse",
  },
];

const QUOTE_INVOICE_NOTIFICATIONS: Notification[] = [
  {
    title: "Devis confirmé",
    description: "Notification à chaque fois que votre client accepte un devis",
    options: OPTIONS,
    key: "validatedQuoteInvoice",
  },
  {
    title: "Devis refusé",
    description: "Notification à chaque fois que votre client refuse un devis",
    options: OPTIONS,
    key: "rejectedQuoteInvoice",
  },
  {
    title: "Devis modifié en attente",
    description:
      "Notification à chaque fois qu’un client propose une modification du devis",
    options: OPTIONS,
    key: "modifiedQuoteInvoice",
  },
];

// const REVIEW_NOTIFICATIONS: Notification[] = [
//   {
//     title: "Nouvel avis de réservation",
//     description:
//       "Notification à chaque fois qu’un client vous laisse un avis après effectuer une réservation",
//     options: OPTIONS,
//   },
//   {
//     title: "Nouvel avis de commande",
//     description:
//       "Notification à chaque fois qu’un client vous laisse un avis après avoir commandé",
//     options: OPTIONS,
//   },
//   {
//     title: "Nouvel avis d’un paiement à table",
//     description:
//       "Notification à chaque fois qu’un client vous laisse un avis après avoir payé l’addition à table",
//     options: OPTIONS,
//   },
// ];

const ClientNotifications = ({ goBack }: ClientNotificationsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<MerchantClientNotificationsFragment | null>(
    null,
  );

  const [getSettings] = useGetMerchantClientNotificationsLazyQuery();
  const [updateSettings] = useUpdateMerchantClientNotificationsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetNotifications = async () => {
    try {
      const { data } = await getSettings();

      if (data) {
        setSettings(data.getMerchantClientNotifications);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  const handleSubmit = async () => {
    try {
      const updates = removeTypeNames(settings);
      delete updates._id;

      await updateSettings({
        variables: {
          updates,
        },
      });
    } catch (err) {
      console.log("err udpate notifications", err);
    } finally {
      handleGoBack();
    }
  };

  const getSelectedOptions = (key: keyof MerchantClientNotificationsFragment) => {
    if (settings) {
      return settings[key];
    }

    return [];
  };

  const updateNotifications = (
    key: keyof MerchantClientNotificationsFragment,
    options: Merchant_Notification_Options[],
  ) => {
    console.log("options", options);

    setSettings(prev => ({
      ...prev,
      [key]: options,
    }));
  };

  if (loading) {
    return <Loader />;
  }

  if (!settings) {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader title="Client" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue. Veuillez réessayer." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScreenHeader title="Client" hasBackButton onBackPress={handleGoBack} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <Box marginVertical="m">
          <CustomText variant="content" color="lightGrey">
            Vous pouvez choisir si vos clients reçoivent un SMS en fonction du type de
            notification.{"\n"}A chaque notification activée, un SMS sera envoyé à vos
            clients.{"\n"}S = Notification par SMS{"\n"}
          </CustomText>
        </Box>

        <Box>
          <CustomText variant="content" color="success" textTransform="uppercase">
            Réservation
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={BOOKING_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Commandes
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={ORDER_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Demandes / Messages
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={MESSAGES_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Devis / Factures
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={QUOTE_INVOICE_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        {/* <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Avis
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={REVIEW_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  selectedOptionKeys={[]}
                  onPress={() => {}}
                />
              </Box>
            )}
          />
        </Box> */}
      </ScrollView>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default ClientNotifications;
