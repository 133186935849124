/* eslint-disable @typescript-eslint/no-empty-function */
import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import RadioSelect from "../RadioSelect";

enum DAYS {
  MONDAY = "Lundi",
  TUESDAY = "Mardi",
  WEDNESDAY = "Mercredi",
  THURSDAY = "Jeudi",
  FRIDAY = "Vendredi",
  SATURDAY = "Samedi",
  SUNDAY = "Dimanche",
}

enum PERIODS {
  MORNING = "Matin",
  AFTERNOON = "Midi",
  EVENING = "Soir",
}

interface PeriodSelectionLineProps {
  day: DAYS;
  onChange: (day: DAYS, periods: PERIODS[]) => void;
}

const PeriodSelectionLine = ({ day, onChange }: PeriodSelectionLineProps) => {
  return (
    <Box flexDirection="row" justifyContent="space-between" alignItems="center">
      <Box>
        <CustomText variant="label" color="primaryTextColor">
          {day}
        </CustomText>
      </Box>
      <Box flexDirection="row" alignItems="center">
        {Object.keys(PERIODS).map(key => (
          <Box mr="s" flexDirection="row" alignItems="center" key={key}>
            <RadioSelect
              iconOnRight={false}
              label={PERIODS[key]}
              isSelected
              onPress={() => {}}
              iconSize={11}
              btnStyles={{
                mr: "s",
                height: 20,
                width: 20,
                borderRadius: "button",
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const PeriodSelection = () => {
  return (
    <Box borderRadius="button" borderWidth={LINE_THICKNESS} borderColor="disabled">
      {Object.keys(DAYS).map((key, idx) => {
        const IS_LAST = idx === Object.keys(DAYS).length - 1;
        return (
          <Box
            paddingHorizontal="s"
            borderBottomColor="disabled"
            borderBottomWidth={IS_LAST ? 0 : 1}
          >
            <PeriodSelectionLine key={key} day={DAYS[key]} onChange={() => {}} />
          </Box>
        );
      })}
    </Box>
  );
};

export default PeriodSelection;
