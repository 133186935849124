import { isBefore } from "date-fns";
import { useState } from "react";

import type { ClosedDate } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import DateInput from "../../DateInput/index";
import { CustomTextInput } from "../../TextInput";

interface ClosureProps {
  closedDate?: ClosedDate;
  onChange: (closedDate: ClosedDate, prevClosedDate?: ClosedDate) => void;
  onDelete: (closedDate: ClosedDate) => void;
}

const DEFAULT_CLOSED_DATE: Omit<ClosedDate, "_id"> = {
  endDate: new Date(),
  startDate: new Date(),
  reason: "",
};

const Closure = ({ onChange, onDelete, closedDate }: ClosureProps) => {
  const [closure, setClosure] = useState(closedDate ? closedDate : DEFAULT_CLOSED_DATE);

  const updateDate = (type: "startDate" | "endDate", date: Date | undefined) => {
    if (date) {
      setClosure(prev => ({ ...prev, [type]: date?.toISOString() }));
    }
  };

  const handleSubmit = () => {
    onChange(closure, closedDate);
  };

  const isDisabled = () => {
    return (
      !closure.endDate ||
      !closure.startDate ||
      !closure.reason ||
      isBefore(new Date(closure.endDate), new Date(closure.startDate))
    );
  };

  return (
    <Box>
      <Box mt="m" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="content" color="primaryTextColor">
          Date de début
        </CustomText>
        <Box flex={1} maxWidth={150}>
          <DateInput
            date={new Date(closure.startDate)}
            onChange={date => updateDate("startDate", date)}
            placeHolder="Date de début"
          />
        </Box>

        {/* <Box flex={0.5}>
          <RNDateTimePicker
            onChange={(_, date) => updateDate("startDate", date)}
            mode="date"
            dateFormat="day month year"
            value={new Date(closure.startDate)}
          />
        </Box> */}
      </Box>
      <Box mt="m" flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="content" color="primaryTextColor">
          Date de fin
        </CustomText>
        <Box flex={1} maxWidth={150}>
          <DateInput
            date={new Date(closure.endDate)}
            onChange={date => updateDate("endDate", date)}
            placeHolder="Date de fin"
          />
        </Box>
        {/* <Box flex={0.5}>
          <RNDateTimePicker
            onChange={(_, date) => updateDate("endDate", date)}
            mode="date"
            dateFormat="day month year"
            value={new Date(closure.endDate)}
          />
        </Box> */}
      </Box>
      <Box mt="m">
        <CustomTextInput
          onChangeText={text => setClosure(prev => ({ ...prev, reason: text }))}
          initialValue={closure.reason}
          placeHolder="Raison"
          isRequired
        />
      </Box>

      {closedDate && (
        <Box mt="m">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="danger"
            onPress={() => onDelete(closedDate)}
            styles={{
              minHeight: 45,
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        </Box>
      )}

      <Box mt="m">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor={isDisabled() ? "disabled" : "success"}
          disabled={isDisabled()}
          onPress={handleSubmit}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText
            variant="primaryButtonText"
            color={isDisabled() ? "lightGrey" : "white"}
          >
            ENREGISTRER
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Closure;
