import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlatList, Image, ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import ButtonList from "../../../../../../components/ButtonList";
import DateVisibilitySection from "../../../../../../components/DateVisibilitySection";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import AddNewImageModal from "../../../../../../components/Media/AddNewImageMedia";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import SelectButton from "../../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import ToggleInputLine from "../../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateProduct,
  Pagination,
  PricingRateFragment,
  ProductFragment,
  TaxFragment,
} from "../../../../../../graphql/generated/schema";
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetPricingRatesLazyQuery,
  useGetProductLazyQuery,
  useGetTaxesLazyQuery,
  useSavePictureMutation,
  useUpdateProductMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../../utils/common";
interface ProductListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 95;

const DEFAULT_PRODUCT: CreateProduct = {
  basePrice: {
    isEnabled: true,
    amount: 0,
    tax: "",
    updatedTaxRate: 0,
  },
  color: "",
  description: "",
  groupOptions: [],
  isEnabled: true,
  isPhysicalItem: true,
  menuExtraPrice: {
    isEnabled: false,
    amount: 0,
  },
  name: "",
  physicalSettings: {
    alertLimitUnavailableForOnlineSales: 0,
    enableStock: false,
    initialStock: 0,
    isStockDepletedByPro: false,
    unavailableLimitForOnlineSales: 0,
    depletedLimitForOnlineSales: 0,
  },
  picture: "",
  pricingRates: [],
  productCode: "",
  serviceSettings: {
    durationInMinutes: 0,
    hasDuration: false,
    isPhysical: false,
    personnel: [],
  },
  slug: "",
  soldOnlyInMenu: false,
  suggestedProducts: [],
  tags: [],
  baseAvailability: {
    isRecurring: false,
    recurringDates: {
      days: [],
      endTime: "10:00",
      startTime: "10:00",
    },
    specificDates: {
      endDate: new Date().toISOString(),
      endTime: "10:00",
      startDate: new Date().toISOString(),
      startTime: "10:00",
    },
  },
  printerLocations: [],
  categories: [],
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom du produit est requis."),
  description: yup.string(),
  slug: yup.string(),
  productCode: yup.string().required("La référence du produit est requise."),
  isEnabled: yup.boolean().required(),
  basePrice: yup
    .object()
    .shape({
      isEnabled: yup.boolean().required(),
      amount: yup
        .number()
        .min(1, "Le prix du produit etre supérieur à 0")
        .required("Le prix du produit est requis."),
      tax: yup.string().when("amount", {
        is: (amount: number) => amount > 0,
        then: yup.string().required(),
      }),
    })
    .required(),
  menuExtraPrice: yup
    .object()
    .shape({
      isEnabled: yup.boolean().required(),
      amount: yup.number().min(0).required(),
    })
    .required(),
  physicalSettings: yup
    .object()
    .shape({
      enableStock: yup.boolean().required(),
      isStockDepletedByPro: yup.boolean().required(),
      alertLimitUnavailableForOnlineSales: yup.number().min(0).required(),
      initialStock: yup.number().min(0).required(),
      unavailableLimitForOnlineSales: yup.number().min(0).required(),
      depletedLimitForOnlineSales: yup.number().min(0).required(),
    })
    .required(),
});

const ProductListDetailsEdit = ({ id, goBack }: ProductListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const isNew = !id;
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState<TaxFragment[]>([]);
  const [product, setProduct] = useState(DEFAULT_PRODUCT);
  const [errors, setErrors] = useState<ERROR[]>([]);
  // const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const [pricingRates, setPricingRates] = useState<PricingRateFragment[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  // const [getPrinters] = useGetPrintersLazyQuery();
  const [getTaxes] = useGetTaxesLazyQuery();
  const [getProduct] = useGetProductLazyQuery();
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const [getPricingRates] = useGetPricingRatesLazyQuery();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getPricingRates({
        variables: { pagination: { limit: 20, offset: 0 } },
      });

      setPricingRates(data?.getPricingRates || []);
    } catch (err) {
      console.log("err", err);
    }
  };

  // const printersSelectOptions = useMemo(
  //   () => printers.map(p => ({ key: p._id, label: p.name })),
  //   [printers],
  // );

  // const handleGetPrinters = async () => {
  //   try {
  //     const pagination = {
  //       limit: 100,
  //       offset: 0,
  //     };
  //     const { data } = await getPrinters({
  //       variables: { pagination },
  //     });

  //     setPrinters(data?.getPrinters || []);
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };

  const updateProductData = (
    key: keyof ProductFragment,
    value: string | string[] | boolean,
  ) => {
    setProduct(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const getStartTime = () => {
    const { baseAvailability } = product;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.startTime
      : baseAvailability?.specificDates?.startTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };
  const getEndTime = () => {
    const { baseAvailability } = product;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.endTime
      : baseAvailability?.specificDates?.endTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };

  const updateDays = (days: number[]) => {
    setProduct(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          recurringDates: {
            ...baseAvailability?.recurringDates,
            days,
          },
        },
      };
    });
  };

  const updateSpecificDates = (type: "startDate" | "endDate", date: Date | undefined) => {
    if (date) {
      setProduct(prev => {
        const { baseAvailability } = prev;
        return {
          ...prev,
          baseAvailability: {
            ...baseAvailability,
            specificDates: {
              ...baseAvailability?.specificDates,
              [type]: date?.toISOString(),
            },
          },
        };
      });
    }
  };

  const updateTimes = (type: "startTime" | "endTime", time: string) => {
    setProduct(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          specificDates: {
            ...baseAvailability?.specificDates,
            [type]: time,
          },
          recurringDates: {
            ...baseAvailability?.recurringDates,
            [type]: time,
          },
        },
      };
    });
  };

  const updateIsRecurring = (isRecurring: boolean) => {
    setProduct(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          isRecurring,
        },
      };
    });
  };

  const updateProductPrice = (
    key: keyof ProductFragment["basePrice"],
    value: string | number,
  ) => {
    setProduct(prev => ({
      ...prev,
      basePrice: {
        ...prev.basePrice,
        [key]: value,
      },
    }));
  };

  const updateMenuPrice = (
    key: keyof ProductFragment["menuExtraPrice"],
    value: string | number | boolean,
  ) => {
    setProduct(prev => ({
      ...prev,
      menuExtraPrice: {
        ...prev.menuExtraPrice,
        [key]: value,
      },
    }));
  };

  const updatePhysicalSettings = (
    key: keyof ProductFragment["physicalSettings"],
    value: string | number | boolean,
  ) => {
    setProduct(prev => ({
      ...prev,
      physicalSettings: {
        ...prev.physicalSettings,
        [key]: value,
      },
    }));
  };

  const updateTags = (tags: { key: string; label: string }[]) => {
    setProduct(prev => ({
      ...prev,
      tags: tags.map(tag => tag.label),
    }));
  };

  const removeGroupOption = (groupOptionId: string) => {
    setProduct(prev => ({
      ...prev,
      groupOptions: prev.groupOptions.filter(option => option._id !== groupOptionId),
    }));
  };

  const removeCategory = (categoryId: string) => {
    setProduct(prev => ({
      ...prev,
      categories: prev.categories.filter(category => category._id !== categoryId),
    }));
  };

  const tags = useMemo(
    () => product.tags.map(tag => ({ key: tag, label: tag })),
    [product.tags],
  );

  const taxSelectOptions = useMemo(
    () => taxes.map(tax => ({ label: tax.name, key: tax._id })),
    [taxes],
  );

  const handleGetTaxes = async () => {
    try {
      const pagination: Pagination = {
        limit: 100,
        offset: 0,
      };
      const { data } = await getTaxes({
        variables: { pagination },
      });
      if (data) {
        setTaxes(data.getTaxes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToAddCategoryOrGroupOption = (type: "category" | "groupOption") => {
    navigation.navigate("PRODUCTS_LIST_DETAILS_ADD_CATEGORY_OR_GROUP_OPTION", {
      type,
      selectedGroupOptions: product.groupOptions,
      selectedCategories: product.categories,
      onSubmit: (data: { id: string; name: string }[]) => {
        setProduct(prev => ({
          ...prev,
          [type === "category" ? "categories" : "groupOptions"]: data,
        }));
      },
    });
  };

  const handleGetProduct = async () => {
    try {
      if (id) {
        const { data } = await getProduct({
          variables: { productId: id },
        });
        if (data?.getProduct) {
          const basePrice = {
            ...data.getProduct.basePrice,
            tax: data.getProduct.basePrice.tax?._id || "",
          };

          const consolidatedProduct = {
            ...data.getProduct,
            basePrice,
            printerLocations: data.getProduct.printerLocations.map(
              printerLocation => printerLocation._id,
            ),
          };

          setProduct(consolidatedProduct);
        }
      }
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));
    } finally {
      setLoading(false);
    }
  };

  const handleInit = async () => {
    try {
      await handleGetPricingRates();
      // await handleGetPrinters();
      await handleGetTaxes();
      await handleGetProduct();
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "PRODUCTS_LIST_DETAILS_INIT_ERROR",
            path: "PRODUCTS_LIST_DETAILS_INIT_ERROR",
            message: "Une erreur est survenue lors de la récupération du produit.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const validateForm = async (consolidatedProduct: CreateProduct) => {
    try {
      await schema.validate(consolidatedProduct, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const { groupOptions, categories, pricingRates: productPricingRates } = product;
    const consolidatedGroupOptions = groupOptions.map(g => g._id);
    const consolidatedCategories = categories.map(g => g._id);

    const consolidatedPricingRates = productPricingRates.map(pr => {
      const rateId =
        typeof pr.pricingRate === "string" ? pr.pricingRate : pr.pricingRate?._id;
      return {
        isEnabled: pr.isEnabled,
        pricingRate: rateId,
        availability: pr.availability,
        price: pr.price,
      };
    });

    const consolidatedProduct = {
      ...product,
      groupOptions: consolidatedGroupOptions,
      categories: consolidatedCategories,
      pricingRates: consolidatedPricingRates,
    };

    const isFormValid = await validateForm(consolidatedProduct);

    if (!isFormValid) return;
    try {
      if (isNew) {
        await createProduct({
          variables: { product: consolidatedProduct },
          // refetchQueries: [
          //   {
          //     query: GetProductsDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
        });
      } else {
        const updates = removeTypeNames(consolidatedProduct);
        delete updates._id;
        delete updates.stockAmount;

        console.log("updates", updates);

        await updateProduct({
          variables: {
            productId: id,
            updates,
          },
          // refetchQueries: [
          //   {
          //     query: GetProductsDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([
        {
          code: "submit",
          path: "submit",
          message:
            err.message || "Une erreur est survenue lors de la mise à jour du produit.",
        },
      ]);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "submit",
            path: "submit",
            message:
              err.message || "Une erreur est survenue lors de la mise à jour du produit.",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteProduct({
          variables: { productId: id },
          // refetchQueries: [
          //   {
          //     query: GetProductsDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "Product",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handleGoBack();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDisplayPricingRates = () => {
    const { pricingRates: selectedPricingRates } = product;

    const ratesOptions = pricingRates
      .filter(rate => {
        const isRateSelected = selectedPricingRates.some(selectedRate => {
          const rateId =
            typeof selectedRate.pricingRate === "string"
              ? selectedRate.pricingRate
              : selectedRate.pricingRate?._id;
          return rateId === rate._id;
        });

        return !isRateSelected;
      })
      .map(rate => ({
        label: rate.name,
        key: rate._id,
      }));

    return ratesOptions;
  };

  const handlePricingRateSelection = (rates: string[]) => {
    const [pricingRate] = rates;

    const selectedPricingRate = pricingRates.find(rate => rate._id === pricingRate);

    if (!selectedPricingRate) return;

    setProduct(prev => ({
      ...prev,
      pricingRates: [
        ...prev.pricingRates,
        {
          availability: {
            dates: [],
            days: [],
            startTime: "10:00",
            endTime: "10:00",
          },
          isEnabled: true,
          price: {
            amount: 0,
            isEnabled: true,
          },
          pricingRate: selectedPricingRate?._id,
        },
      ],
    }));
  };

  const handleUpdatePricingRateAmount = (amount: number, pricingRateId: string) => {
    setProduct(prev => ({
      ...prev,
      pricingRates: prev.pricingRates.map(pricingRate => {
        if (pricingRate.pricingRate === pricingRateId) {
          return {
            ...pricingRate,
            price: {
              ...pricingRate.price,
              amount,
            },
          };
        }
        return pricingRate;
      }),
    }));
  };

  const handleRemovePricingRate = (pricingRateId: string) => {
    setProduct(prev => ({
      ...prev,
      pricingRates: prev.pricingRates.filter(
        pricingRate => pricingRate.pricingRate !== pricingRateId,
      ),
    }));
  };

  if (loading) {
    return <Loader />;
  }

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setProduct(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = product;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  const displayTitle = isNew ? "Nouveau produit" : "Modifier le produit";

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>
            <Box>
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Nom du produit"
                  initialValue={product.name}
                  hasErrors={doesInputHaveError("name")}
                  onChangeText={t => updateProductData("name", t)}
                  isRequired
                />
              </Box>

              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Slug"
                  initialValue={product.slug}
                  onChangeText={t => updateProductData("slug", t)}
                />
              </Box>
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Référence du produit"
                initialValue={product.productCode}
                isRequired
                hasErrors={doesInputHaveError("productCode")}
                onChangeText={t => updateProductData("productCode", t)}
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Description du produit"
                multiLine
                initialValue={product.description}
                onChangeText={t => updateProductData("description", t)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Uniquement disponible en formule
              </CustomText>
              <Toggle
                value={product.soldOnlyInMenu}
                onChange={v => updateProductData("soldOnlyInMenu", v)}
              />
            </Box>

            <Box mt="m">
              <CustomText
                mb="s"
                variant="content"
                color="success"
                textTransform="uppercase"
              >
                Catégories
              </CustomText>

              <DraggableFlatList
                scrollEnabled={false}
                data={product.categories}
                style={{ width: "100%" }}
                ListHeaderComponent={
                  <TouchableOpacity
                    onPress={() => handleGoToAddCategoryOrGroupOption("category")}
                  >
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="s"
                      marginVertical="s"
                    >
                      <Box flex={0.95}>
                        <InlineSelectButton
                          title="Ajouter une catégorie"
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <PLUS fill={PALETTE.green} width={15} height={15} />
                    </Box>
                  </TouchableOpacity>
                }
                renderItem={({ item }) => (
                  <TouchableOpacity
                    disabled
                    style={{
                      marginVertical: 8,
                      marginRight: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title={item.name}
                        options={[]}
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <TouchableOpacity onPress={() => removeCategory(item._id)}>
                      <TRASH fill={PALETTE.red} width={15} height={15} />
                    </TouchableOpacity>
                  </TouchableOpacity>
                )}
                keyExtractor={(item, index) => `draggable-item-${index}`}
              />
            </Box>

            <Box mt="m">
              <CustomText
                mb="s"
                variant="content"
                color="success"
                textTransform="uppercase"
              >
                Groupes d'options
              </CustomText>

              <DraggableFlatList
                scrollEnabled={false}
                data={[...product.groupOptions]}
                style={{ width: "100%" }}
                ListHeaderComponent={
                  <TouchableOpacity
                    onPress={() => handleGoToAddCategoryOrGroupOption("groupOption")}
                  >
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="s"
                      marginVertical="s"
                    >
                      <Box flex={0.95}>
                        <InlineSelectButton
                          title="Ajouter un groupe d'options"
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <PLUS fill={PALETTE.green} width={15} height={15} />
                    </Box>
                  </TouchableOpacity>
                }
                renderItem={({ item, drag }) => (
                  <TouchableOpacity
                    onPressIn={drag}
                    style={{
                      marginVertical: 8,
                      marginRight: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box flex={0.95}>
                      <InlineSelectButton
                        title={item.name}
                        options={[]}
                        isDraggable
                        selectedOptionKeys={[]}
                        isMultiSelect={false}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onPress={() => {}}
                      />
                    </Box>

                    <TouchableOpacity onPress={() => removeGroupOption(item._id)}>
                      <TRASH fill={PALETTE.red} width={15} height={15} />
                    </TouchableOpacity>
                  </TouchableOpacity>
                )}
                keyExtractor={(item, index) => `draggable-item-${index}`}
                onDragEnd={({ data }) => updateProductData("groupOptions", data)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Prix
              </CustomText>
              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  placeHolder="Montant"
                  isRequired
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("basePrice.amount")}
                  value={product.basePrice.amount.toString()}
                  onChangeText={t => updateProductPrice("amount", t)}
                  onBlur={t => updateProductPrice("amount", getSafeNumberFromInput(t))}
                />
                <Box ml="s">
                  <SelectButton
                    isMultiSelect={false}
                    options={taxSelectOptions}
                    hasErrors={doesInputHaveError("basePrice.tax")}
                    selectedOptions={product.basePrice.tax ? [product.basePrice.tax] : []}
                    onPress={([tax]) => updateProductPrice("tax", tax)}
                    placeHolder="Taxe"
                  />
                </Box>
              </Box>
            </Box>

            {handleDisplayPricingRates().length > 0 && (
              <Box>
                <SelectButton
                  isMultiSelect={false}
                  options={handleDisplayPricingRates()}
                  // hasErrors={doesInputHaveError("basePrice.tax")}
                  selectedOptions={[]}
                  onPress={handlePricingRateSelection}
                  placeHolder="Autres Prix"
                />
              </Box>
            )}

            <Box>
              <FlatList
                data={product.pricingRates}
                keyExtractor={item => item.pricingRate?._id || item.pricingRate}
                renderItem={({ item }) => {
                  const { pricingRates: productPricingRates } = product;
                  const selectedPricingRate = productPricingRates.find(
                    pr => pr.pricingRate === item.pricingRate,
                  );

                  const foundPricingRate = pricingRates.find(
                    pr => pr._id === item.pricingRate,
                  );

                  const name =
                    foundPricingRate?.name || selectedPricingRate?.pricingRate?.name;

                  const priceRateId =
                    foundPricingRate?._id || selectedPricingRate?.pricingRate?._id;

                  return (
                    <Box marginVertical="s">
                      <Box
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginVertical="s"
                      >
                        <CustomText variant="label" color="primaryTextColor">
                          {name}
                        </CustomText>

                        <TouchableOpacity
                          onPress={() => handleRemovePricingRate(item.pricingRate)}
                        >
                          <TRASH fill={PALETTE.red} width={15} height={15} />
                        </TouchableOpacity>
                      </Box>
                      <Box
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginVertical="s"
                      >
                        <CustomText variant="content" color="primaryTextColor">
                          Prix
                        </CustomText>
                        <CustomTextInput
                          placeHolder="Montant"
                          isRequired
                          boxProps={{
                            maxWidth: 80,
                          }}
                          keyboardType="decimal-pad"
                          // hasErrors={doesInputHaveError("basePrice.amount")}
                          value={selectedPricingRate?.price?.amount.toString()}
                          onChangeText={t =>
                            handleUpdatePricingRateAmount(t, priceRateId)
                          }
                          onBlur={t =>
                            handleUpdatePricingRateAmount(
                              getSafeNumberFromInput(t),
                              priceRateId,
                            )
                          }
                        />
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex={1} mr="s">
                <ToggleInputLine
                  textVariant="content"
                  text="Prix extra menu"
                  value={!!product.menuExtraPrice.isEnabled}
                  onValueChange={v => updateMenuPrice("isEnabled", v)}
                />
              </Box>

              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  placeHolder="Montant"
                  isRequired
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("menuExtraPrice.amount")}
                  value={product.menuExtraPrice.amount.toString()}
                  onChangeText={t => updateMenuPrice("amount", t)}
                  onBlur={t => updateMenuPrice("amount", getSafeNumberFromInput(t))}
                  boxProps={{
                    maxWidth: 80,
                  }}
                />
              </Box>
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Activé
              </CustomText>
              <Toggle
                value={product.isEnabled}
                onChange={v => updateProductData("isEnabled", v)}
              />
            </Box>

            <Box>
              <Box
                marginVertical="s"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomText variant="label" color="primaryTextColor">
                  Disponibilité
                </CustomText>
              </Box>

              <DateVisibilitySection
                days={product.baseAvailability.recurringDates.days}
                endDate={new Date(product.baseAvailability.specificDates.endDate)}
                startDate={new Date(product.baseAvailability.specificDates.startDate)}
                isRecurring={product.baseAvailability.isRecurring}
                startTime={getStartTime()}
                endTime={getEndTime()}
                updateDays={val => updateDays(val)}
                updateStartDate={date => updateSpecificDates("startDate", date)}
                updateEndDate={date => updateSpecificDates("endDate", date)}
                updateStartTime={t => updateTimes("startTime", t)}
                updateEndTime={t => updateTimes("endTime", t)}
                updateReccuring={updateIsRecurring}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="label"
                color="primaryTextColor"
              >
                Gestion de stock
              </CustomText>
              <Toggle
                value={product.physicalSettings.enableStock}
                onChange={v => updatePhysicalSettings("enableStock", v)}
              />
            </Box>

            {isNew && (
              <Box
                marginVertical="s"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  Stock
                </CustomText>
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  keyboardType="number-pad"
                  value={product.physicalSettings.initialStock.toString()}
                  onChangeText={t => updatePhysicalSettings("initialStock", t)}
                  onBlur={t =>
                    updatePhysicalSettings(
                      "initialStock",
                      getSafeNumberFromInput(t, false),
                    )
                  }
                  inputStyles={{ textAlign: "center" }}
                  boxProps={{
                    maxWidth: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Box>
            )}

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Seuil d’indisponibilité
              </CustomText>
              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  keyboardType="number-pad"
                  value={product.physicalSettings.unavailableLimitForOnlineSales.toString()}
                  onChangeText={t =>
                    updatePhysicalSettings("unavailableLimitForOnlineSales", t)
                  }
                  onBlur={t =>
                    updatePhysicalSettings(
                      "unavailableLimitForOnlineSales",
                      getSafeNumberFromInput(t, false),
                    )
                  }
                  inputStyles={{ textAlign: "center" }}
                  boxProps={{
                    maxWidth: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <CustomText variant="label" color="primaryTextColor">
                  /
                </CustomText>
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  disabled
                  initialValue={product.physicalSettings.initialStock.toString()}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                  inputStyles={{ textAlign: "center" }}
                  boxProps={{
                    maxWidth: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Box>
            </Box>
            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Seuil de rupture
              </CustomText>
              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  keyboardType="number-pad"
                  value={product.physicalSettings.depletedLimitForOnlineSales.toString()}
                  onChangeText={t =>
                    updatePhysicalSettings("depletedLimitForOnlineSales", t)
                  }
                  onBlur={t =>
                    updatePhysicalSettings(
                      "depletedLimitForOnlineSales",
                      getSafeNumberFromInput(t, false),
                    )
                  }
                  inputStyles={{ textAlign: "center" }}
                  boxProps={{
                    maxWidth: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <CustomText variant="label" color="primaryTextColor">
                  /
                </CustomText>
                <CustomTextInput
                  placeHolder="0"
                  hideFloatingLabel
                  disabled
                  initialValue={product.physicalSettings.initialStock.toString()}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                  inputStyles={{ textAlign: "center" }}
                  boxProps={{
                    maxWidth: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Box>
            </Box>

            {/* {printersSelectOptions.length > 0 && (
            <Box marginVertical="s">
              <SelectButton
                isMultiSelect
                options={printersSelectOptions}
                hasErrors={doesInputHaveError("printerLocations")}
                selectedOptions={product.printerLocations}
                onPress={v => updateProductData("printerLocations", v)}
                placeHolder="Point de fabrication"
              />
            </Box>
          )} */}

            <Box mt="m">
              <CustomText mb="s" variant="label" color="primaryTextColor">
                Tags
              </CustomText>

              <ButtonList values={tags} onChange={updateTags} />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default ProductListDetailsEdit;
