import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { endOfDay, startOfDay } from "date-fns";
import { useCallback, useState } from "react";
import { ActivityIndicator, FlatList } from "react-native";

import DISALLOW from "../../../../assets/icons/BASE/DISALLOW.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import { CustomButton } from "../../../components/Button";
import TimeSlotSelection from "../../../components/NewBooking/TimeSelection/TimeSlotSelection/index";
import ScreenHeader from "../../../components/ScreenHeader";
import { CustomTextInput } from "../../../components/TextInput/index";
import ToggleInputLine from "../../../components/ToggleInputLine/index";
import Touchable from "../../../components/Touchable/index";
import {
  useCreateBookingFloorExperienceForSelectedDateMutation,
  useGetBookingAvailabilityWithBookingInfoLazyQuery,
  useRemoveAllBookingFloorConfigurationsForSelectedDateLazyQuery,
  useToggleBookingFloorByServiceForSelectedDateMutation,
  useToggleBookingFloorBySlotForSelectedDateMutation,
  type AvailabilityServiceWithModuleInfo,
  type AvailbilityServiceBookingFloorCapacityConfiguration,
} from "../../../graphql/generated/schema";
import { captureAndReportErrors } from "../../../sentry";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { getSafeNumberFromInput, removeTypeNames } from "../../../utils/common";

interface BookingListFloorCapacityConfigurationProps {}

const BookingListFloorCapacityConfiguration = (
  props: BookingListFloorCapacityConfigurationProps,
) => {
  const { params } = useRoute();
  const floor: AvailbilityServiceBookingFloorCapacityConfiguration | undefined =
    params?.floor;
  const service: AvailabilityServiceWithModuleInfo | undefined = params?.service;
  const selectedDate: Date = params?.selectedDate || new Date();
  const navigation = useNavigation();
  const [updatedFloor, setUpdatedFloor] = useState<
    Partial<AvailbilityServiceBookingFloorCapacityConfiguration> | undefined
  >(floor);
  const [isSetFloorConfigUpdateInProgress, setIsSetFloorConfigUpdateInProgress] =
    useState(false);
  const [updatedService, setUpdatedService] = useState(service);
  const [showSlotsList, setShowSlotsList] = useState(true);
  const [isEnabledToggleInProgress, setIsEnabledToggleInProgress] = useState(false);
  const [selectedSlotId, setSelectedSlotId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [setFloorConfigurationForSelectedDate] =
    useCreateBookingFloorExperienceForSelectedDateMutation();
  const [resetFloorConfigurationForSelectedDate] =
    useRemoveAllBookingFloorConfigurationsForSelectedDateLazyQuery();
  const [toggleBookingFloorEnableFoService] =
    useToggleBookingFloorByServiceForSelectedDateMutation();
  const [toggleBookingFloorEnableForSlot] =
    useToggleBookingFloorBySlotForSelectedDateMutation();
  const [getBookingAvailability] = useGetBookingAvailabilityWithBookingInfoLazyQuery();

  const handleGetBookingAvailability = async () => {

    try {
      if (!service?.serviceId || !floor?.floorId) return;

      setIsLoading(true);

      const { data } = await getBookingAvailability({
        fetchPolicy: "network-only",
        variables: {
          serviceId: service?.serviceId,
          date: selectedDate,
          selectedFloorId: floor?.floorId,
        },
      });

      const bookingService = data?.getBookingAvailabilityWithBookingInfo;

      if (bookingService) {
        setUpdatedService(bookingService);

        const selectedFloor = bookingService.bookingFloorExperience?.bookingFloorsCapacityConfiguration?.find(
          f => f.floorId === floor?.floorId,
        );

        setUpdatedFloor(selectedFloor);

      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err refech", err);
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      handleGetBookingAvailability();
    }, [service?.serviceId, floor?.floorId, selectedDate]),
  );

  const displayContentHeader = () => {
    const bookingFloorConfigurations =
      service?.bookingFloorExperience?.bookingFloorsCapacityConfiguration || [];

    const HAS_BOOKING_EXPERIENCE = bookingFloorConfigurations?.length > 0;

    if (!HAS_BOOKING_EXPERIENCE) return <></>;

    return (
      <Box m="s" flexDirection="row" alignItems="center">
        <Touchable onPress={() => setShowSlotsList(true)}>
          <Box
            borderColor="success"
            borderWidth={showSlotsList ? LINE_THICKNESS : 0}
            borderRadius="button"
            backgroundColor={showSlotsList ? "white" : "disabled"}
            alignItems="center"
            justifyContent="center"
            p="m"
            minHeight={45}
            minWidth={100}
            mr="s"
          >
            <CustomText variant="label" fontSize={12} color="primaryTextColor">
              Créneaux
            </CustomText>
          </Box>
        </Touchable>
        <Touchable onPress={() => setShowSlotsList(false)}>
          <Box
            borderColor="success"
            borderWidth={!showSlotsList ? LINE_THICKNESS : 0}
            borderRadius="button"
            backgroundColor={!showSlotsList ? "white" : "disabled"}
            alignItems="center"
            justifyContent="center"
            p="m"
            minHeight={45}
            minWidth={100}
            mr="s"
          >
            <CustomText variant="label" fontSize={12} color="primaryTextColor">
              Capacité
            </CustomText>
          </Box>
        </Touchable>
      </Box>
    );
  };

  const handleToggleBookingFloorEnableForService = async () => {
    const floorId = floor?.floorId;
    const serviceId = service?.serviceId;

    if (!floorId || !serviceId) return;

    try {
      setSelectedSlotId("");
      setIsEnabledToggleInProgress(true);

      await toggleBookingFloorEnableFoService({
        variables: {
          floorId,
          serviceId,
          selectedDate: new Date(selectedDate),
        },
      });

      if (updatedService) {
        setUpdatedService({
          ...updatedService,
          isFloorEnabled: !updatedService?.isFloorEnabled,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsEnabledToggleInProgress(false);
    }
  };

  const handleToggleBookingFloorEnableForSlot = async (slotId: string) => {
    const floorId = floor?.floorId;
    const serviceId = service?.serviceId;

    if (!floorId || !serviceId) return;

    try {
      setSelectedSlotId(slotId);
      setIsEnabledToggleInProgress(true);

      await toggleBookingFloorEnableForSlot({
        variables: {
          floorId,
          serviceId,
          selectedDate: new Date(selectedDate),
          slotId,
        },
      });

      if (updatedService) {
        setUpdatedService({
          ...updatedService,
          slots: updatedService?.slots?.map(slot => {
            if (slot.slotId === slotId) {
              return {
                ...slot,
                isFloorEnabled: !slot.isFloorEnabled,
              };
            }

            return slot;
          }),
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsEnabledToggleInProgress(false);
    }
  };

  const displayServiceSlotsListContent = () => {
    const slots = updatedService?.slots || [];

    return (
      <Box paddingHorizontal="s" height="90%">
        <FlatList
          data={slots}
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={
            <Box mt="s">
              <Box mb="s">
                <CustomText
                  variant="label"
                  fontSize={14}
                  color="primaryTextColor"
                  textTransform="uppercase"
                >
                  Par service
                </CustomText>
              </Box>

              {isEnabledToggleInProgress && !selectedSlotId ? (
                <Box
                  minHeight={45}
                  backgroundColor="disabled"
                  borderRadius="button"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ActivityIndicator size="small" color={PALETTE.darkBlue} />
                </Box>
              ) : (
                <Touchable
                  disabled={isEnabledToggleInProgress}
                  onPress={() => handleToggleBookingFloorEnableForService()}
                >
                  <Box
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={45}
                    flexDirection="row"
                  >
                    <DISALLOW
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                      fill={
                        updatedService?.isFloorEnabled ? PALETTE.darkBlue : PALETTE.red
                      }
                    />
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      Tout le service
                    </CustomText>
                  </Box>
                </Touchable>
              )}

              <Box mt="s">
                <CustomText
                  variant="label"
                  fontSize={14}
                  color="primaryTextColor"
                  textTransform="uppercase"
                >
                  Par Créneau
                </CustomText>
              </Box>
            </Box>
          }
          keyExtractor={item => item.slotId}
          renderItem={({ item, index }) => {
            const { startTime, areLimitsEnabled } = item;
            return (
              <Box mt={index === 0 ? "s" : "m"}>
                {isEnabledToggleInProgress && item.slotId === selectedSlotId ? (
                  <Box
                    minHeight={45}
                    backgroundColor="disabled"
                    borderRadius="button"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ActivityIndicator size="small" color={PALETTE.darkBlue} />
                  </Box>
                ) : (
                  <>
                    <TimeSlotSelection
                      {...{
                        disabled: isEnabledToggleInProgress,
                        areLimitsEnabled: areLimitsEnabled,
                        time: startTime,
                        isSelected: false,
                        onPress: () => handleToggleBookingFloorEnableForSlot(item.slotId),
                        slotText: "",
                        serviceText: "",
                        slotIconColor: !item?.isFloorEnabled ? "red" : "lightGrey",
                        serviceIconColor: false ? "red" : "lightGrey",
                      }}
                    />
                  </>
                )}
              </Box>
            );
          }}
        />
      </Box>
    );
  };

  const handleUpdateFloorConfiguration = (
    key: keyof AvailbilityServiceBookingFloorCapacityConfiguration,
    value: any,
  ) => {
    setUpdatedFloor({
      ...(updatedFloor ? updatedFloor : {}),
      [key]: value,
    });
  };

  const handleToggleCapacityLimitEnabled = (v: boolean) => {
    handleUpdateFloorConfiguration("isCapacityLimitEnabled", v);

    // if (!v) {
    //   handleUpdateFloorConfiguration("capacity", 0);
    // }
  };

  const isSubmitFloorsConfigDisabled = () => {
    return updatedFloor?.isCapacityLimitEnabled && (updatedFloor?.capacity || 0) <= 0;
  };

  const handleSubmitFloorConfigurationChanges = async () => {
    try {
      setIsSetFloorConfigUpdateInProgress(true);

      const startDate = startOfDay(new Date(selectedDate)).toISOString();
      const endDate = endOfDay(new Date(selectedDate)).toISOString();

      const createParams = removeTypeNames({
        startDate,
        endDate,
        isEnabled: true,
        floorId: floor?.floorId,
        floorName: floor?.floorName,
        serviceId: updatedService?.serviceId,
        ...updatedFloor,
      });

      await setFloorConfigurationForSelectedDate({
        variables: {
          createBookingExperience: createParams,
        },
      });

      navigation.goBack();

    } catch (err) {
      console.log("ERROR_SET_FLOOR_CONFIG", err);
    } finally {
      setIsSetFloorConfigUpdateInProgress(false);
    }
  };

  const handleResetFloorConfiguration = async () => {
    try {
      await resetFloorConfigurationForSelectedDate({
        variables: {
          floorId: floor?.floorId,
          selectedDate: new Date(selectedDate),
          serviceId: service?.serviceId,
        },
      });

      navigation.goBack();
    } catch (err) {
      console.log("ERROR_RESET_FLOOR_CONFIG", err);
    }
  };

  const handleFloorCapacityChange = (value: number) => {
    const capacity =
      updatedService?.areLimitsEnabled && updatedService?.totalCapacityForService > 0
        ? Math.min(value, updatedService?.totalCapacityForService)
        : value;

    handleUpdateFloorConfiguration("capacity", capacity);
  };

  const displayBookingFloorsConfigurationsContent = () => {
    if (!floor) return <></>;



    const selectedUpdatedFloorCapacity = updatedFloor?.capacity;

    return (
      <Box flex={1} height="100%">
        <Box flex={1} height="100%" pb="s" my="s">
          <Box mt="xs">
            <ToggleInputLine
              text="Limiter le nombre de couverts disponibles pour cet emplacement"
              value={!!updatedFloor?.isCapacityLimitEnabled}
              textVariant="content"
              onValueChange={v => handleToggleCapacityLimitEnabled(v)}
            />
          </Box>

          {!!updatedFloor?.isCapacityLimitEnabled && (
            <Box mt="xs">
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <CustomText variant="content" color="primaryTextColor">
                  Nombre de couverts
                </CustomText>
                <Box flexDirection="row" alignItems="center">
                  <CustomTextInput
                    placeHolder="0"
                    hideFloatingLabel
                    keyboardType="number-pad"
                    value={selectedUpdatedFloorCapacity?.toString()}
                    onChangeText={v =>
                      handleFloorCapacityChange(getSafeNumberFromInput(v))
                    }
                    boxProps={{
                      width: 100,
                    }}
                    // inputStyles={{ textAlign: "center" }}
                  />

                  {updatedService?.areLimitsEnabled &&
                    updatedService?.totalCapacityForService > 0 && (
                      <Box ml="s">
                        <CustomTextInput
                          placeHolder="50"
                          hideFloatingLabel
                          disabled
                          initialValue={service?.totalCapacityForService?.toString()}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onChangeText={() => {}}
                          inputStyles={{ textAlign: "center" }}
                        />
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box position="absolute" bottom={30} mt="m" width="100%" minHeight={100}>
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="yellow"
            onPress={handleResetFloorConfiguration}
            styles={{ mb: "s" }}
          >
            <CustomText variant="primaryButtonText" color="yellow">
              Réinitialiser
            </CustomText>
          </CustomButton>
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor={
              isSetFloorConfigUpdateInProgress || isSubmitFloorsConfigDisabled()
                ? "disabled"
                : "success"
            }
            disabled={isSetFloorConfigUpdateInProgress || isSubmitFloorsConfigDisabled()}
            onPress={handleSubmitFloorConfigurationChanges}
          >
            {isSetFloorConfigUpdateInProgress ? (
              <ActivityIndicator size="small" color="white" />
            ) : (
              <CustomText variant="primaryButtonText" color="white">
                Valider
              </CustomText>
            )}
          </CustomButton>
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (isLoading)
      return (
        <Box flex={1} alignItems="center" justifyContent="center">
          <ActivityIndicator size="large" color={PALETTE.darkBlue} />
        </Box>
      );

    const content = showSlotsList
      ? displayServiceSlotsListContent()
      : displayBookingFloorsConfigurationsContent();

    return (
      <Box flex={1} height="100%">
        {displayContentHeader()}

        {content}
      </Box>
    );
  };

  const getScreenTitle = () => {
    const floorName = floor?.floorName;

    return floorName ? `${floorName}` : "Emplacement";
  };

  return (
    <Box flex={1} height="100%" pt="m" backgroundColor="white" p="s">
      <ScreenHeader
        title={getScreenTitle()}
        hasBackButton
        onBackPress={navigation.goBack}
      />

      {displayContent()}
    </Box>
  );
};

export default BookingListFloorCapacityConfiguration;
