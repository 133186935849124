/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import { FlatList } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import InlineSelectButton from "../../../../components/InlineSelectButton";
import ScreenHeader from "../../../../components/ScreenHeader";

interface CashRegisterSettingsPaymentMethodsProps {
  goBack?: () => void;
}

const OPTIONS = [
  {
    // key: Merchant_Notification_Options.Sms,
    key: "T",
    label: "T",
  },
  {
    // key: Merchant_Notification_Options.Sms,
    key: "M",
    label: "M",
  },
  {
    // key: Merchant_Notification_Options.Sms,
    key: "A",
    label: "A",
  },
];

interface Notification {
  title: string;
  options: typeof OPTIONS;
  // key: keyof MerchantClientNotificationsFragment;
  key: string;
}

const PAYMENT_METHODS: Notification[] = [
  {
    title: "Carte Bleue",
    options: OPTIONS,
    key: "validatedQuoteInvoice",
  },
  {
    title: "Espèces",
    options: OPTIONS,
    key: "rejectedQuoteInvoice",
  },
  {
    title: "Cheque",
    options: OPTIONS,
    key: "modifiedQuoteInvoice",
  },
  {
    title: "Compte client",
    options: OPTIONS,
    key: "modifiedQuoteInvoice",
  },
  {
    title: "Virement bancaire",
    options: OPTIONS,
    key: "modifiedQuoteInvoice",
  },
];

const CashRegisterSettingsPaymentMethods = ({
  goBack,
}: CashRegisterSettingsPaymentMethodsProps) => {
  const navigation = useNavigation();

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box marginVertical="m">
        <ScreenHeader
          title="Moyens de paiement"
          description="Vous pouvez choisir le type de notifications en fonction de votre utilisation."
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
      </Box>
      <Box mt="m">
        <CustomText variant="content" color="success" textTransform="uppercase">
          Moyens de paiements actifs
        </CustomText>
      </Box>
      <Box>
        <FlatList
          data={PAYMENT_METHODS}
          keyExtractor={item => item.title}
          renderItem={({ item }) => (
            <Box mt="s">
              <InlineSelectButton
                options={item.options}
                title={item.title}
                isDraggable
                selectedOptionKeys={[]}
                onPress={() => {}}
              />
            </Box>
          )}
        />
      </Box>
      <Box mt="m">
        <CustomText variant="content" color="success" textTransform="uppercase">
          Moyens de paiements disponibles
        </CustomText>
      </Box>
      <Box>
        <FlatList
          data={[PAYMENT_METHODS[0]]}
          keyExtractor={item => item.title}
          renderItem={({ item }) => (
            <Box mt="s">
              <InlineSelectButton
                options={item.options}
                title={item.title}
                isDraggable
                selectedOptionKeys={[]}
                onPress={() => {}}
              />
            </Box>
          )}
        />
      </Box>
      <BottomButton title="Valider" onPress={() => {}} />
    </Box>
  );
};

export default CashRegisterSettingsPaymentMethods;
