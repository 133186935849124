export const BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT = 90;

export const BOOKING_FLOOR_HEADER_HEIGHT = 110;

export const BOOKING_FLOOR_WIDTH_MIN = 300;
export const BOOKING_FLOOR_WIDTH_MAX = 1700;

export const BOOKING_FLOOR_HEIGHT_MIN = 300;
export const BOOKING_FLOOR_HEIGHT_MAX = 1000;

export const BOOKING_FLOOR_HEIGHT_WIDTH_INCREMENT_AMOUNT = 20;

export const BOOKING_FLOOR_TABLE_MIN_SIZE = 40;
export const BOOKING_FLOOR_TABLE_MAX_SIZE = 70;
export const BOOKING_FLOOR_TABLE_BASE_SIZE = 70;

export const BOOKING_FLOOR_TABLE_SCALE_INCREMENT = 0.02;
export const BOOKING_FLOOR_TABLE_MIN_SCALE = 0.3;
export const BOOKING_FLOOR_TABLE_MAX_SCALE = 1;

export const TABLE_SIZE = 70;
