import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import Loader from "../../../../../components/Loader";
import ProductOptionCard from "../../../../../components/ProductOptionCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { ProductOptionFragment } from "../../../../../graphql/generated/schema";
import { useGetProductOptionsLazyQuery } from "../../../../../graphql/generated/schema";

const GroupOptionAddOption = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { selectedOptions = [], onSubmitOptions } = params;
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<ProductOptionFragment[]>([]);
  const [finalOptions, setFinalOptions] =
    useState<{ _id: string; name: string }[]>(selectedOptions);

  const [getOptions, { fetchMore }] = useGetProductOptionsLazyQuery();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: options.length,
          },
        },
      });

      if (data?.getProductOptions) {
        setOptions([...options, ...data.getProductOptions]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleAddOption = (id: string, name: string) => {
    setFinalOptions(prev => {
      const isExists = prev.find(option => option._id === id);

      if (isExists) {
        return prev.filter(option => option._id !== id);
      }

      return [...prev, { _id: id, name }];
    });
  };

  const handleSubmit = () => {
    onSubmitOptions(finalOptions);
    navigation.goBack();
  };

  const handleGetOptions = async () => {
    try {
      const { data } = await getOptions({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getProductOptions) {
        setOptions(data.getProductOptions);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOptions();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Séléctionner les options"
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>
      <Box mt="m" flex={1}>
        <FlatList
          data={options}
          onEndReached={handleFetchMore}
          contentContainerStyle={{ paddingBottom: 100 }}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleAddOption(item._id, item.name)}>
                <ProductOptionCard
                  isSelected={!!finalOptions.find(o => o._id === item._id)}
                  name={item.name}
                  nbGroupOptions={item.groupOptions.length}
                  price={item.basePrice.amount}
                />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
      <BottomButton onPress={handleSubmit} title="Valider" />
    </Box>
  );
};

export default GroupOptionAddOption;
