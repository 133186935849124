import { useNavigation, useRoute } from "@react-navigation/native";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";

import RIGHT_ARROW from "../../../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SubscriptionDetailsCard from "../../../../../components/SubscriptionDetailsCard";
import SubscriptionOptionsModal from "../../../../../components/SubscriptionOptionsModal";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import {
  Merchant_Subscription_Status,
  useCancelMerchantSubscriptionOptionTransactionMutation,
  useCancelMerchantSubscriptionTransactionMutation,
  useGetMerchantSubscriptionTransactionLazyQuery,
  type SubscriptionTransactionFragment,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";

const SubscriptionDetails = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { params } = route;
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const subscriptionTransactionParam: SubscriptionTransactionFragment =
    params?.subscriptionTransaction;
  const [subscriptionTransaction, setSubscriptionTransaction] = useState<
    SubscriptionTransactionFragment | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [isSubscriptionCancelBtnDisabled, setIsSubscriptionCancelBtnDisabled] =
    useState(false);

  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [cancelMerchantSubscription] = useCancelMerchantSubscriptionTransactionMutation();
  const [cancelMerchantSubscriptionOption] =
    useCancelMerchantSubscriptionOptionTransactionMutation();

  const [getSubscriptionTransaction] = useGetMerchantSubscriptionTransactionLazyQuery();

  const handleGetSubscriptionTransaction = async () => {
    try {
      const { data } = await getSubscriptionTransaction({
        fetchPolicy: "cache-and-network",
        variables: {
          transactionId: subscriptionTransactionParam._id,
        },
      });

      if (data) {
        setSubscriptionTransaction(data.getMerchantSubscriptionTransaction);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_SUBSCRIPTION_TRANSACTIONS",
            message: "Une erreur est survenue lors de la récupération des abonnements",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSubscriptionTransaction();
  }, []);

  if (loading) return <Loader />;

  if (!subscriptionTransaction) {
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue lors de la récupération de l'abonnement" />
      </Box>
    );
  }

  const options = subscriptionTransaction.options.filter(option => {
    return option.status !== Merchant_Subscription_Status.Expired;
  });

  const IS_SUBSCRIPTION_CANCELLED =
    subscriptionTransaction.status === Merchant_Subscription_Status.Cancelled;

  const handleCancelSubscription = async () => {
    setIsSubscriptionCancelBtnDisabled(true);
    try {
      await cancelMerchantSubscription({
        variables: {
          transactionId: subscriptionTransaction._id,
        },
      });
    } catch (err) {
      console.log("err cancel subscription: ", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_CANCEL_SUBSCRIPTION",
            message: "Une erreur est survenue lors de la résiliation de votre abonnement",
          },
        ],
        "error",
      );
    } finally {
      handleGetSubscriptionTransaction();
      setIsSubscriptionCancelBtnDisabled(false);
    }
  };

  const handleCancelOption = async (optionIds: string[]) => {
    try {
      await Promise.all(
        optionIds.map(optionId => {
          cancelMerchantSubscriptionOption({
            variables: {
              transactionId: subscriptionTransaction._id,
              transactionOptionId: optionId,
            },
          });
        }),
      );
    } catch (err) {
      console.log("err cancel subscription option: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_CANCEL_SUBSCRIPTION_OPTION",
            message: "Une erreur est survenue lors de la résiliation de votre abonnement",
          },
        ],
        "error",
      );
    } finally {
      handleGetSubscriptionTransaction();
    }
  };

  const handleOptionEdit = () => {
    setIsOptionsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsOptionsModalOpen(false);
  };

  const displayCancelledBanner = () => {
    if (IS_SUBSCRIPTION_CANCELLED) {
      return (
        <Box
          marginVertical="s"
          minHeight={50}
          backgroundColor="disabled"
          borderRadius="button"
          alignItems="center"
          justifyContent="center"
        >
          <CustomText variant="content" color="danger">
            Annulé - (module activé jusqu'au{" "}
            {format(new Date(subscriptionTransaction.endDate), "dd/MM/yyyy")}
          </CustomText>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <Box p="m" flex={1} backgroundColor="white">
      <ScreenHeader title="Abonnement" hasBackButton onBackPress={navigation.goBack} />

      {displayCancelledBanner()}

      <Box>
        <Box marginVertical="m">
          <SubscriptionDetailsCard subscriptionTransaction={subscriptionTransaction} />
        </Box>

        {options.length > 0 && (
          <TouchableOpacity onPress={handleOptionEdit}>
            <Box
              mt="m"
              backgroundColor="disabled"
              borderRadius="button"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              minHeight={45}
              paddingHorizontal="s"
            >
              <CustomText variant="content" color="primaryTextColor">
                Voir vos options
              </CustomText>

              <RIGHT_ARROW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            </Box>
          </TouchableOpacity>
        )}

        {!IS_SUBSCRIPTION_CANCELLED && (
          <Box>
            <Box marginVertical="m">
              <CustomText ml="m" variant="content" color="lightGrey">
                Si vous résiliez votre abonnement maintenant, vous continuerez à en
                bénéficier jusqu’au{" "}
                {format(new Date(subscriptionTransaction.endDate), "dd MMMM yyyy")}.
              </CustomText>
            </Box>

            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={handleCancelSubscription}
              disabled={isSubscriptionCancelBtnDisabled}
            >
              <CustomText variant="outlineButtonText" color="danger">
                Résilier l'abonnement
              </CustomText>
            </CustomButton>
          </Box>
        )}

        <SubscriptionOptionsModal
          subscriptionTransaction={subscriptionTransaction}
          isOpen={isOptionsModalOpen}
          onClose={handleCloseModal}
          handleCancellationOfSubscriptionOptions={handleCancelOption}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionDetails;
