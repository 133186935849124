import { useState } from "react";

import type { TableFragment } from "../../../graphql/generated/schema";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import Incrementer from "../../Incrementer";
import { CustomTextInput } from "../../TextInput";
import Toggle from "../../Toggle";

interface EditTableModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  table: TableFragment;
  onSubmit: (id: string, table: TableFragment) => void;
  deleteTable: (id: string) => void;
  duplicateTable: (id: string) => void;
}

const EditTableModal = ({
  isOpen,
  setIsOpen,
  table,
  onSubmit,
  deleteTable,
  duplicateTable,
}: EditTableModalProps) => {
  const [updatedTable, setUpdatedTable] = useState(table);

  const handleClose = () => {
    setUpdatedTable(table);
    setIsOpen(false);
  };

  const updateTableData = (
    key: keyof TableFragment,
    value: string | number | boolean,
  ) => {
    setUpdatedTable({
      ...updatedTable,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    onSubmit(table._id, updatedTable);
    handleClose();
  };

  const handleDelete = () => {
    deleteTable(table._id);
    handleClose();
  };

  const handleDuplicate = () => {
    duplicateTable(table._id);
    handleClose();
  };

  const isDisabled = () => {
    return updatedTable?.tableNumber?.toString()?.trim()?.length === 0;
  };

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={handleClose}
      title="Edition de la table"
      styles={{
        height: "auto",
      }}
    >
      <Box>
        <Box mt="s">
          <CustomTextInput
            isRequired
            value={updatedTable.tableNumber}
            keyboardType="number-pad"
            onChangeText={t =>
              updateTableData("tableNumber", getSafeNumberFromInput(t).toString())
            }
            boxProps={{
              alignItems: "center",
              justifyContent: "center",
            }}
            inputStyles={{ textAlign: "center" }}
            placeHolder="Numéro de la table"
          />
        </Box>

        <Box mt="m">
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Min
            </CustomText>

            <Incrementer
              min={1}
              max={updatedTable.maxCapacity}
              value={updatedTable.minCapacity}
              onValueChange={v => updateTableData("minCapacity", v)}
            />
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Max
            </CustomText>

            <Incrementer
              min={updatedTable.minCapacity}
              max={12}
              value={updatedTable.maxCapacity}
              onValueChange={v => updateTableData("maxCapacity", v)}
            />
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Rotation
            </CustomText>

            <Incrementer
              min={0}
              max={360}
              value={updatedTable.rotationDeg}
              incrementor={30}
              onValueChange={v => updateTableData("rotationDeg", v)}
            />
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Réservable
            </CustomText>

            <Toggle
              value={updatedTable.isEligibleForBooking}
              onChange={v => updateTableData("isEligibleForBooking", v)}
            />
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Commande à table
            </CustomText>

            <Toggle
              value={updatedTable.isOrderAtTableEnabled}
              onChange={v => updateTableData("isOrderAtTableEnabled", v)}
            />
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Paiement à table
            </CustomText>

            <Toggle
              value={updatedTable.isPaymentAtTableEnabled}
              onChange={v => updateTableData("isPaymentAtTableEnabled", v)}
            />
          </Box>
        </Box>

        <Box mt="l">
          <Box width="100%" flexDirection="row" alignItems="center" mb="m">
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={handleDelete}
              containerStyles={{
                width: "48%",
                marginRight: 8,
              }}
            >
              <CustomText variant="primaryButtonText" color="danger">
                Supprimer
              </CustomText>
            </CustomButton>
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleDuplicate}
              containerStyles={{
                width: "48%",
                marginLeft: 8,
              }}
            >
              <CustomText variant="primaryButtonText" color="success">
                Dupliquer
              </CustomText>
            </CustomButton>
          </Box>
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor={isDisabled() ? "disabled" : "success"}
            disabled={isDisabled()}
            onPress={handleSubmit}
          >
            <CustomText
              variant="primaryButtonText"
              color={isDisabled() ? "lightGrey" : "white"}
            >
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default EditTableModal;
