import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import Loader from "../../../../../components/Loader";
import PricingRateCard from "../../../../../components/PricingRateCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { PricingRateFragment } from "../../../../../graphql/generated/schema";
import {
  useDeletPricingRateMutation,
  useGetPricingRatesLazyQuery,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import PricingRatesListDetails from "../PricingRatesListDetails";

interface PricingRatesListProps {
  goBack?: () => void;
}

const PricingRatesList = ({ goBack }: PricingRatesListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [rates, setRates] = useState<PricingRateFragment[]>([]);
  const [selectedRateId, setSelectedRateId] = useState("");
  const [showRateInline, setShowRateInline] = useState(false);

  const [getRates, { fetchMore }] = useGetPricingRatesLazyQuery();
  const [deleteRate] = useDeletPricingRateMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: rates.length,
          },
        },
      });

      if (data?.getPricingRates) {
        setRates([...rates, ...data.getPricingRates]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getRates({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getPricingRates) {
        setRates(data.getPricingRates);
      }
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_PRICING_RATES",
            message:
              "Une erreur est survenue lors de la récupération des tarifs de prix.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetPricingRates();
    }, []),
  );

  const handleDelete = async (id: string) => {
    try {
      await deleteRate({
        variables: { pricingRateId: id },
        update: cache => {
          const normalizedId = cache.identify({
            _id: id,
            __typename: "PricingRate",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });

      await handleGetPricingRates();
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELTE_PRICING_RATE",
            message: "Une erreur est survenue lors de la suppression du tarif de prix.",
          },
        ],
        "error",
      );
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setShowRateInline(true);
      setSelectedRateId("");
    } else {
      navigation.navigate("PRICING_RATES_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setShowRateInline(true);
      setSelectedRateId(id);
    } else {
      navigation.navigate("PRICING_RATES_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleCloseSelectedTab = async () => {
    await handleGetPricingRates();
    setShowRateInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <PricingRatesListDetails
        selectedRateId={selectedRateId}
        newValue={!selectedRateId}
        goBack={handleCloseSelectedTab}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box>
          <ScreenHeader
            title="Tarification"
            rightButtons={
              <TouchableOpacity onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </TouchableOpacity>
            }
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>
        <Box marginVertical="s">
          <CustomText variant="content" color="success" textTransform="uppercase">
            tarifs actives
          </CustomText>
        </Box>

        <FlatList
          data={rates}
          keyExtractor={item => item._id}
          onEndReachedThreshold={0.5}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <PricingRateCard
                name={item.name}
                taxRate={item.tax.taxRate}
                onPress={() => handleEdit(item._id)}
                onDelete={() => handleDelete(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showRateInline) {
      return displaySelectedTab();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default PricingRatesList;
