import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import CALENDAR from "../../../../../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../../../../../assets/icons/BASE/CUTLERY.svg";
import OCCURENCES from "../../../../../../../assets/icons/BASE/OCCURENCES.svg";
import PAX_PERSONS from "../../../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import PENDING from "../../../../../../../assets/icons/BASE/PENDING.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../../../components/InputSectionTitle";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { BookingAvailabilityFragment } from "../../../../../../graphql/generated/schema";
import {
  PrepaymentCancelDelimiter,
  PrepaymentCancelMesaurementType,
  PrepaymentType,
  useGetBookingAvailabilityLazyQuery,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import {
  formatCurrencyPrice,
  getWeekdayFromNumber,
} from "../../../../../../utils/common";

const ICON_SIZE = 16;
interface BookingServiceListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const BookingServiceListDetailsView = ({
  id,
  goBack,
}: BookingServiceListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [bookingAvailability, setBookingAvailability] =
    useState<BookingAvailabilityFragment | null>(null);

  const [getBookingAvailability] = useGetBookingAvailabilityLazyQuery();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetBookingAvailability = async () => {
    try {
      const { data } = await getBookingAvailability({
        variables: { availabilityId: id },
      });
      setBookingAvailability(data?.getBookingAvailability || null);
    } catch (err) {
      console.log("err get booking availability", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingAvailability();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!bookingAvailability) {
    return (
      <Box>
        <ScreenHeader title="Service" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations du service." />
      </Box>
    );
  }

  const displayServiceStartDaysAndEndDays = () => {
    const { visibilitySetting } = bookingAvailability;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      return recurringDates.days
        .slice()
        .sort((a, b) => a - b)
        .map(getWeekdayFromNumber)
        .join("\n");
    }
    const { startDate, endDate } = specificDates;

    return `${format(new Date(startDate), "dd/mm/yyyy")} - ${format(
      new Date(endDate),
      "dd/mm/yyyy",
    )}`;
  };

  const displayServiceStartTimesAndEndTimes = () => {
    const { visibilitySetting } = bookingAvailability;
    const { isRecurring, recurringDates, specificDates } = visibilitySetting;

    if (isRecurring) {
      const { startTime, endTime } = recurringDates;
      return `${startTime} - ${endTime}`;
    }
    const { startTime, endTime } = specificDates;
    return `${startTime} - ${endTime}`;
  };

  const displaySlotsTimes = () => {
    // const { visibilitySetting, slots } = bookingAvailability;
    // const { isRecurring, recurringDates, specificDates } = visibilitySetting;
    // slots.filter(slot => slot.isEnabled)
  };

  const displayPrepaymenCancellationPolicy = () => {
    const { prepaymentSettings } = bookingAvailability;

    if (!prepaymentSettings) return "";

    const { prepaymentCancelSettings } = prepaymentSettings;
    const { amount, delimiterMesaurementType } = prepaymentCancelSettings;

    const suffix =
      // eslint-disable-next-line no-nested-ternary
      delimiterMesaurementType === PrepaymentCancelMesaurementType.Minutes
        ? "minutes"
        : delimiterMesaurementType === PrepaymentCancelMesaurementType.Hours
        ? "heures"
        : "jours";

    return `${amount} ${suffix}`;
  };

  const {
    name,
    interval,
    totalPersonsAllowed,
    minMaxBookingPersons,
    prepaymentSettings,
    maxCapacityAllowedForSlot,
    automaticConfirmationSettings,
    waitingListSettings,
    bookingFloorSettings,
    bookingProductsSettings,
  } = bookingAvailability;

  return (
    <Box flex={1}>
      <ScreenHeader title={name} hasBackButton onBackPress={handleGoBack} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150 }}
      >
        <Box mt="m">
          <InputSectionTitle text="PARAMÈTRES SERVICES" />
        </Box>
        <TabTextInfo
          {...{
            title: "Jours actifs",
            content: displayServiceStartDaysAndEndDays(),
            icon: (
              <CALENDAR height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        <TabTextInfo
          {...{
            title: "Horaires de réservation",
            content: displayServiceStartTimesAndEndTimes(),
            icon: (
              <OCCURENCES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        <TabTextInfo
          {...{
            title: "Intervale entre 2 créneaux",
            content: `${interval} minutes`,
            icon: (
              <PENDING height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />

        {totalPersonsAllowed.isEnabled && (
          <TabTextInfo
            {...{
              title: "Nombre de couverts",
              content: `${totalPersonsAllowed.value}`,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}

        {maxCapacityAllowedForSlot.isEnabled && (
          <TabTextInfo
            {...{
              title: "Couverts max/résa",
              content: `${maxCapacityAllowedForSlot.value}`,
              icon: (
                <CUTLERY height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        {minMaxBookingPersons.isEnabled && (
          <TabTextInfo
            {...{
              title: "Couverts acceptés/résa",
              content: `${minMaxBookingPersons.minPersons}-${minMaxBookingPersons.maxPersons}`,
              icon: (
                <PAX_PERSONS
                  height={ICON_SIZE}
                  width={ICON_SIZE}
                  fill={PALETTE.lightGrey}
                />
              ),
            }}
          />
        )}

        <Box mt="m">
          <InputSectionTitle text="Avancé" />
        </Box>
        {automaticConfirmationSettings?.isEnabled && (
          <TabTextInfo
            {...{
              title: "Accepter automatiquement toutes les réservations",
              content: "Non",
              icon: (
                <></>
                // <PAX_PERSONS
                //   height={ICON_SIZE}
                //   width={ICON_SIZE}
                //   fill={PALETTE.lightGrey}
                // />
              ),
            }}
          />
        )}

        {automaticConfirmationSettings?.isEnabledForMaxCapacity && (
          <TabTextInfo
            {...{
              title:
                "Accepter automatiquement toutes les réservations jusqu’à un certain nombre de couverts/résa",
              content: `${automaticConfirmationSettings.maxCapacityForAutomaticConfirmation}px max`,
              icon: (
                <></>

                // <PAX_PERSONS
                //   height={ICON_SIZE}
                //   width={ICON_SIZE}
                //   fill={PALETTE.lightGrey}
                // />
              ),
            }}
          />
        )}

        {/* <TabTextInfo
          {...{
            title: "Reconfirmation automatique",
            content: "Oui",
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        /> */}
        <TabTextInfo
          {...{
            title: "Liste d’attente (sur booking)",
            content: `${waitingListSettings?.isEnabled ? "Oui" : "Non"}\n${
              waitingListSettings?.totalPersonsAllowed || 0
            } couverts\n${waitingListSettings?.minPersons} min -${
              waitingListSettings?.maxPersons
            } max`,
            icon: (
              <></>
              // <PAX_PERSONS
              //   height={ICON_SIZE}
              //   width={ICON_SIZE}
              //   fill={PALETTE.lightGrey}
              // />
            ),
          }}
        />
        {prepaymentSettings && (
          <TabTextInfo
            {...{
              title: "Empreinte bancaire",
              content: `${prepaymentSettings.isEnabled ? "Oui" : "Non"}\n${
                formatCurrencyPrice(
                  prepaymentSettings.amountForPrepayment || 0,
                  "fr",
                  "eur",
                ) || 0
              } minimum`,
              // content: "Oui\n1,00 € minimum",
              icon: <></>,
            }}
          />
        )}

        {prepaymentSettings && prepaymentSettings.isEnabled && (
          <TabTextInfo
            {...{
              title: "Type de prélèvement",
              content:
                prepaymentSettings.prepaymentType === PrepaymentType.FixedRate
                  ? "Par réservation"
                  : "Par personne",
              icon: <></>,
            }}
          />
        )}

        {/* <TabTextInfo
          {...{
            title: "Montant du prélèvement",
            content: "50,00 €",
            icon: (
              <PAX_PERSONS
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        /> */}
        {prepaymentSettings && prepaymentSettings.isEnabled && (
          <TabTextInfo
            {...{
              title: "Délai maximum annulation",
              content: displayPrepaymenCancellationPolicy(),
              icon: <></>,
            }}
          />
        )}

        {prepaymentSettings && prepaymentSettings.isEnabled && (
          <TabTextInfo
            {...{
              title: "Délimitation annulation",
              content:
                prepaymentSettings.prepaymentCancelSettings.delimiter ===
                PrepaymentCancelDelimiter.Reservation
                  ? "Réservation"
                  : "Service",
              icon: <></>,
            }}
          />
        )}

        {prepaymentSettings && prepaymentSettings.isEnabled && (
          <TabTextInfo
            {...{
              title: "Pourcentage annulation",
              content: `${prepaymentSettings.percentBookingCancel}%`,
              icon: <></>,
            }}
          />
        )}

        {prepaymentSettings && prepaymentSettings.isEnabled && (
          <TabTextInfo
            {...{
              title: "Pourcentage NO SHOW",
              content: `${prepaymentSettings.percentBookingNoShow}%`,
              icon: <></>,
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Autoriser le placement en salle",
            content: bookingFloorSettings?.isEnabled ? "Oui" : "Non",
            icon: <></>,
          }}
        />

        <TabTextInfo
          {...{
            title: "Autoriser le choix de la table dans la salle",
            content: bookingFloorSettings?.isTableSelectionEnabled ? "Oui" : "Non",
            icon: <></>,
          }}
        />
        {/* <TabTextInfo
          {...{
            title: "Salles disponibles",
            content: ""
            icon: (
              <PAX_PERSONS
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        /> */}
        <TabTextInfo
          {...{
            title: "Obligation de se placer",
            content: bookingFloorSettings?.isRequired ? "Oui" : "Non",
            icon: <></>,
          }}
        />
        <TabTextInfo
          {...{
            title: "Active pré-sélection des produits",
            content: bookingProductsSettings?.isEnabled ? "Oui" : "Non",
            icon: <></>,
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default BookingServiceListDetailsView;
