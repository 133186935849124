/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import AddNewImageModal from "../../../../../components/Media/AddNewImageMedia";
import PhoneInput from "../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Toggle from "../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  CreateMerchantClientCompany,
  MerchantClient,
  MerchantClientCompany,
  Pagination,
} from "../../../../../graphql/generated/schema";
import {
  GetCompaniesDocument,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompanyLazyQuery,
  useGetMerchantClientsLazyQuery,
  useSavePictureMutation,
  useUpdateCompanyMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";

interface CompanyListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 65;

const DEFAULT_COMPANY: CreateMerchantClientCompany = {
  address: {
    city: "",
    country: "",
    formattedAddress: "",
    number: "",
    street: "",
    zipCode: "",
  },
  clients: [],
  comment: "",
  companyName: "",
  isBlacklisted: false,
  phone: "",
  picture: "",
  secondaryPhone: "",
  siret: "",
  tvaNumber: "",
  description: "",
  email: "",
};

const schema = yup.object().shape({
  companyName: yup.string().required(),
  phone: yup.string().required(),
  siret: yup.string().required(),
  tvaNumber: yup.string().required(),
  email: yup.string().email(),
});

const CompanyListDetailsEdit = ({ id, goBack }: CompanyListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const [isNew] = useState(!id);
  const [loading, setLoading] = useState(!isNew);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [clients, setClients] = useState<MerchantClient[]>([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [savePicture] = useSavePictureMutation();
  const [company, setCompany] = useState(DEFAULT_COMPANY);

  const [getClients] = useGetMerchantClientsLazyQuery();

  const [getCompany] = useGetCompanyLazyQuery();
  const [updateCompany] = useUpdateCompanyMutation();
  const [createCompany] = useCreateCompanyMutation();
  const [deleteCompany] = useDeleteCompanyMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetClients = async () => {
    try {
      const { data, error } = await getClients({
        variables: { pagination },
      });
      setClients(data?.getMerchantClients || []);
    } catch (err) {
      console.log("err get clients", err);
    }
  };

  useEffect(() => {
    handleGetClients();
  }, []);

  const handleGetCompany = async () => {
    try {
      if (id) {
        const { data } = await getCompany({
          variables: { companyId: id },
        });
        setCompany(data?.getMerchantClientCompany);
      } else {
        setCompany(DEFAULT_COMPANY);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCompany();
  }, []);

  const validateForm = async () => {
    try {
      await schema.validate(company, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      if (isNew) {
        await createCompany({
          variables: {
            company,
          },
          refetchQueries: [
            {
              query: GetCompaniesDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      } else {
        if (id) {
          const updates = removeTypeNames(company);

          delete updates._id;

          await updateCompany({
            variables: {
              companyId: id,
              company: updates,
            },
            refetchQueries: [
              {
                query: GetCompaniesDocument,
                variables: {
                  pagination: {
                    limit: 10,
                    offset: 0,
                  },
                },
              },
            ],
          });
        }
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ code: "ERR_SUBMIT_COMPANY_DETAILS", message: err?.message }]);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_SUBMIT_COMPANY_DETAILS",
            message: "Une erreur est survenue",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleDeleteCompany = async () => {
    try {
      if (id) {
        await deleteCompany({
          variables: { companyId: id },
          // refetchQueries: [
          //   {
          //     query: GetCompaniesDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "MerchantClientCompany",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handleGoBack();
      }
    } catch (err) {
      console.log("err delete company", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_DELETE_COMPANY",
            message: "Une erreur est survenue",
          },
        ],
        "error",
      );
    }
  };

  const displayTitle = () => {
    if (isNew) {
      return "Nouvelle entreprise";
    }

    return "Modifier l'entreprise";
  };

  const updateCompanyData = (
    key: keyof MerchantClientCompany,
    value: MerchantClientCompany[keyof MerchantClientCompany],
  ) => {
    setCompany({ ...company, [key]: value });
  };

  if (loading) {
    return <Loader />;
  }

  if (!company) {
    return (
      <Box>
        <Box marginVertical="s">
          <ScreenHeader title="Entreprise" hasBackButton onBackPress={handleGoBack} />
        </Box>
        <ErrorMessage message="Une erreur est survenue." />
      </Box>
    );
  }

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setCompany(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = company;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle()} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Nom"
                hasErrors={doesInputHaveError("companyName")}
                isRequired
                initialValue={company.companyName}
                onChangeText={value => updateCompanyData("companyName", value)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Description de l'entreprise"
                initialValue={company.description}
                onChangeText={value => updateCompanyData("description", value)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Siret"
                isRequired
                hasErrors={doesInputHaveError("siret")}
                initialValue={company.siret || ""}
                onChangeText={value => updateCompanyData("siret", value)}
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Numero de Tva"
                isRequired
                hasErrors={doesInputHaveError("tvaNumber")}
                initialValue={company.tvaNumber}
                onChangeText={value => updateCompanyData("tvaNumber", value)}
              />
            </Box>

            <Box mt="s">
              <PhoneInput
                value={company.phone}
                hasErrors={doesInputHaveError("phone")}
                isRequired
                onChangeFormattedText={t => updateCompanyData("phone", t)}
              />
            </Box>
            <Box mt="s">
              <PhoneInput
                value={company.secondaryPhone}
                onChangeFormattedText={t => updateCompanyData("secondaryPhone", t)}
              />
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                isRequired
                initialValue={company.email}
                hasErrors={doesInputHaveError("email")}
                onChangeText={value => updateCompanyData("email", value)}
              />
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Addresse"
                isRequired
                onChangeText={() => {}}
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Note sur l'entreprise"
                multiLine
                isRequired
                initialValue={company.comment || ""}
                onChangeText={value => updateCompanyData("comment", value)}
              />
            </Box>

            {/* <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                mb="s"
                variant="content"
                color="success"
                textTransform="uppercase"
              >
                personnes rattachees
              </CustomText>
            </Box>

            <FlatList
              scrollEnabled={false}
              data={clients}
              style={{ width: "100%" }}
              // ListFooterComponent={
              //   <TouchableOpacity>
              //     <Box
              //       flexDirection="row"
              //       alignItems="center"
              //       justifyContent="space-between"
              //       mr="s"
              //       marginVertical="s"
              //     >
              //       <Box flex={0.95}>
              //         <InlineSelectButton
              //           title="Ajouter une étape"
              //           options={[]}
              //           selectedOptionKeys={[]}
              //           isMultiSelect={false}
              //           onPress={() => {}}
              //         />
              //       </Box>

              //       <PLUS fill={PALETTE.green} width={15} height={15} />
              //     </Box>
              //   </TouchableOpacity>
              // }
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={{
                    marginVertical: 8,
                    // marginRight: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="disabled"
                    borderRadius="button"
                    p="s"
                    flex={0.95}
                  >
                    <CustomText variant="content" color="primaryTextColor">
                      KHANANO Joel
                    </CustomText>
                  </Box>

                  <TouchableOpacity>
                    <TRASH fill={PALETTE.red} width={15} height={15} />
                  </TouchableOpacity>
                </TouchableOpacity>
              )}
              keyExtractor={(item, index) => `draggable-item-${index}`}
            />
          </Box> */}

            {/* <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Accepte les campagnes SMS
            </CustomText>
            <Toggle  />
          </Box> */}
            {/* <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Accepte les Newsletters
            </CustomText>
            <Toggle value={true} />
          </Box> */}
            <Box
              mt="l"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Entreprise Blacklisté
              </CustomText>
              <Toggle
                value={company.isBlacklisted}
                onChange={t => updateCompanyData("isBlacklisted", t)}
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDeleteCompany}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default CompanyListDetailsEdit;
