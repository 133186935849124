import type { VariantProps } from "@shopify/restyle";
import { createRestyleComponent, createVariant } from "@shopify/restyle";
import React, { useEffect, useRef, useState } from "react";
import type { KeyboardType, ViewStyle } from "react-native";
import { Platform, TextInput } from "react-native";

import type { Theme } from "../../theme";
import { LINE_THICKNESS } from "../../theme";
import type { TEXT_VARIANTS } from "../../theme/variants/TextVariants";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Touchable from "../Touchable";

const HEIGHT = 45;
const ICON_WIDTH = 30;

const IS_WEB = Platform.OS === "web";

const Input = createRestyleComponent<
  VariantProps<Theme, "textVariants"> & React.ComponentProps<typeof TextInput>,
  Theme
>([createVariant({ themeKey: "textVariants" })], TextInput);

type Props = {
  multiLine?: boolean;
  keyboardType?: KeyboardType;
  leftIcon?: JSX.Element;
  onChangeText: (text: string) => void;
  onEndEditing?: (t: string) => void;
  onBlur?: (text: string) => void;
  placeHolder: string;
  isRequired?: boolean;
  hasErrors?: boolean;
  initialValue?: string;
  disabled?: boolean;
  variant?: TEXT_VARIANTS;
  boxProps?: React.ComponentProps<typeof Box>;
  inputStyles?: React.ComponentProps<typeof Input>;
  clearButtonMode?: "never" | "while-editing" | "unless-editing" | "always";
  secureTextEntry?: boolean;
  returnKeyType?: "done" | "go" | "next" | "search" | "send";
  hideFloatingLabel?: boolean;
  value?: string;
  autoFocus?: boolean;
};

export const CustomTextInput = ({
  leftIcon,
  onChangeText,
  onEndEditing,
  onBlur,
  placeHolder,
  isRequired = false,
  hasErrors = false,
  initialValue = "",
  variant = "defaultTextInput",
  disabled = false,
  boxProps = {},
  inputStyles,
  multiLine = false,
  keyboardType = "default",
  clearButtonMode = "while-editing",
  secureTextEntry = false,
  returnKeyType,
  hideFloatingLabel = false,
  value,
  autoFocus = false,
}: Props) => {
  const textInputRef = useRef<TextInput>(null);

  const handleIsInputOpen = () => {
    if (textInputRef?.current?.isFocused() || autoFocus || IS_WEB) return true;

    if (value) {
      return value.trim().length > 0;
    }
    return initialValue.trim().length > 0;
  };

  const handleInitValue = () => {
    if (value) {
      return value.trim();
    }
    return initialValue.trim();
  };
  const [textValue, setTextValue] = useState(handleInitValue());
  const [isInputOpen, setIsInputOpen] = useState(handleIsInputOpen());
  const CONTAINER_STYLES: React.ComponentProps<typeof Box> = {
    borderColor: hasErrors ? "danger" : "lightGrey",
    borderWidth: LINE_THICKNESS,
    borderRadius: "button",
    backgroundColor: disabled ? "disabled" : "white",
    minWidth: 70,
    width: "100%",
    minHeight: multiLine ? 100 : HEIGHT,
    ...boxProps,
  };
  const INPUT_STYLES: ViewStyle = {
    // width: "100%",
    // height: "100%",
    paddingHorizontal: 8,
    // flex: 1,
    // flexWrap: "wrap",
    display: isInputOpen ? "flex" : "none",
    outlineStyle: "none",
    minHeight: multiLine ? 100 : undefined,
    textAlignVertical: multiLine ? "top" : undefined,
    ...inputStyles,
  };

  // useEffect(() => {
  //   if (
  //     isInputOpen &&
  //     !textValue &&
  //     keyboardType !== "decimal-pad" &&
  //     keyboardType !== "number-pad"
  //   ) {
  //     textInputRef.current?.focus();
  //   }
  // }, [isInputOpen, initialValue, value, textValue, keyboardType]);

  useEffect(() => {
    setIsInputOpen(handleIsInputOpen());
  }, [initialValue, value]);

  useEffect(() => {
    if (autoFocus) {
      textInputRef.current?.focus();
    }
  }, [textInputRef]);

  const handleOnBlur = () => {
    if (
      textValue.trim().length === 0 &&
      keyboardType !== "decimal-pad" &&
      keyboardType !== "number-pad"
    ) {
      setIsInputOpen(false);
    }
    onBlur?.(textValue);
  };

  const handleOnChangeText = (text: string) => {
    onChangeText(text);
    setTextValue(text);
  };

  const handleOpenInput = () => {
    if (disabled) return;
    setIsInputOpen(true);
    textInputRef.current?.focus();
  };

  return (
    <Box>
      {!isInputOpen ? (
        <Touchable
          disabled={disabled}
          style={{
            display: isInputOpen ? "none" : "flex",
          }}
          onPress={handleOpenInput}
        >
          <Box
            flexDirection="row"
            alignItems={multiLine ? "flex-start" : "center"}
            paddingHorizontal="s"
            {...CONTAINER_STYLES}
          >
            {leftIcon && (
              <Box mr="s" width={ICON_WIDTH}>
                {leftIcon}
              </Box>
            )}
            <CustomText
              variant="placeHolderTextInput"
              color={hasErrors ? "danger" : "primaryTextColor"}
            >
              {placeHolder}
            </CustomText>
            {isRequired && (
              <CustomText
                variant="defaultTextInput"
                color={hasErrors ? "danger" : "success"}
              >
                *
              </CustomText>
            )}
          </Box>
        </Touchable>
      ) : (
        <Box
          style={{
            display: isInputOpen ? "flex" : "none",
          }}
          flexDirection={leftIcon ? "row" : "column"}
          alignItems={leftIcon ? "center" : "flex-start"}
          paddingHorizontal="s"
          pt="xs"
          {...CONTAINER_STYLES}
        >
          {!hideFloatingLabel && (
            <Box
              flexDirection="row"
              alignItems="flex-start"
              backgroundColor="white"
              position="absolute"
              top={-7}
              left={8}
            >
              <CustomText variant="text" color="lightGrey" fontSize={10}>
                {placeHolder}
              </CustomText>
              {isRequired && (
                <CustomText
                  variant="defaultTextInput"
                  color={hasErrors ? "danger" : "success"}
                >
                  *
                </CustomText>
              )}
            </Box>
          )}

          {leftIcon && <Box width={ICON_WIDTH}>{leftIcon}</Box>}
          <Box
            flex={1}
            // height="100%"
            width="100%"
            justifyContent="center"
          >
            <Input
              keyboardType={keyboardType}
              ref={textInputRef}
              autoFocus={autoFocus || !handleIsInputOpen()}
              // autoFocus={autoFocus}
              editable={!disabled}
              variant={variant}
              onChangeText={handleOnChangeText}
              onEndEditing={() => {
                onEndEditing?.(textValue);
              }}
              defaultValue={initialValue}
              onBlur={handleOnBlur}
              returnKeyType="done"
              secureTextEntry={secureTextEntry}
              value={value}
              multiline={multiLine}
              clearButtonMode={clearButtonMode}
              returnKeyLabel={returnKeyType}
              style={INPUT_STYLES}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
