import { useState } from "react";

import {
  Booking_Status,
  Cash_Register_Ticket_Status,
  Click_Status,
  Modules,
} from "../../../graphql/generated/schema";
import { getLabelForBookingStatus } from "../../../utils/booking";
import { getLabelForTakeAwayOrderStatus } from "../../../utils/takeAwayOrder";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import SelectButton from "../../Select/SelectButton";

interface FilterModalProps {
  isOpen: boolean;
  type: Modules;
  setIsOpen: (isOpen: boolean) => void;
  selectedStatuses: number[];
  onConfirm: (data: { statuses: number[] }) => void;
}

const FilterModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  type,
  selectedStatuses,
}: FilterModalProps) => {
  const [statusFilters, setStatusFilters] = useState(
    selectedStatuses.map(s => s.toString()),
  );

  const handleClose = () => {
    setIsOpen(false);
    setStatusFilters([]);
  };

  const handleConfirm = () => {
    if (type === Modules.CashRegister) {
      onConfirm({ statuses: statusFilters });
    } else {
      onConfirm({ statuses: statusFilters });
    }
    handleClose();
  };

  const getStatuses = () => {
    if (type === Modules.OnlineSales) {
      return [
        Click_Status.New,
        Click_Status.Validated,
        Click_Status.AutomaticValidated,
        Click_Status.Preparing,
        Click_Status.Prepared,
        Click_Status.CancelledByOwner,
        Click_Status.CancelledByUser,
        Click_Status.Noshow,
        Click_Status.Ended,
        Click_Status.Refunded,
      ].map(status => ({
        label: getLabelForTakeAwayOrderStatus(status),
        key: status.toString(),
      }));
    }
    if (type === Modules.Reservation) {
      return [
        Booking_Status.New,
        Booking_Status.WaitingList,
        Booking_Status.Validated,
        Booking_Status.AutomaticValidated,
        // BOOKING_STATUS.RECONFIMED,
        Booking_Status.Seated,
        Booking_Status.Arrived,
        Booking_Status.ArrivedWaiting,
        Booking_Status.CancelledByUser,
        Booking_Status.CancelledByOwner,
        Booking_Status.Noshow,
        Booking_Status.Ended,
      ].map(status => ({
        label: getLabelForBookingStatus(status),
        key: status.toString(),
      }));
    }
    if (type === Modules.CashRegister) {
      return [
        Cash_Register_Ticket_Status.Open,
        Cash_Register_Ticket_Status.CurrentlyPaying,
        Cash_Register_Ticket_Status.Ordered,
        Cash_Register_Ticket_Status.Sent,
        Cash_Register_Ticket_Status.Paid,
        Cash_Register_Ticket_Status.Cancelled,
        Cash_Register_Ticket_Status.Closed,
        Cash_Register_Ticket_Status.Refunded,
      ].map(status => ({
        label: status,
        key: status.toString(),
      }));
    }
    return [];
  };

  const handleSelectStatusFilter = (statuses: string[]) => {
    setStatusFilters(statuses);
  };

  const handleReset = () => {
    setStatusFilters([]);
  };

  return (
    <ContentModal
      title="Filtrer"
      isVisible={isOpen}
      onClose={handleClose}
      styles={{
        maxWidth: 450,
        height: "auto",
      }}
    >
      <Box height="auto">
        <Box height="auto">
          <SelectButton
            placeHolder="Filtrer par statut"
            selectedOptions={statusFilters}
            isMultiSelect
            onPress={handleSelectStatusFilter}
            options={getStatuses()}
          />
        </Box>

        <Box mt="m" backgroundColor="white" minHeight={100}>
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="yellow"
            onPress={handleReset}
            styles={{ mb: "s" }}
          >
            <CustomText variant="primaryButtonText" color="yellow">
              Réinitialiser
            </CustomText>
          </CustomButton>
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="success"
            onPress={handleConfirm}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default FilterModal;
