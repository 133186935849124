/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { KeyboardAvoidingView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import TRASH from "../../../../../assets/icons/BASE/TRASH.svg";
import type { QUOTE } from "../../../../Data";
import type { Quote, QuoteProductItem } from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import { formatCurrencyPrice, uniqueInArr } from "../../../../utils/common";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import { CustomButton } from "../../../Button";
import { CustomTextInput } from "../../../TextInput";
import NewQuoteLineSelectionModal, {
  NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE,
} from "../NewQuoteLineSelectionModal";
import NewQuoteProductListModal from "../NewQuoteProductList";

import NewCommentItemLine from "./NewCommentItemLine";
import NewQuoteServiceLine from "./NewQuoteServiceLine";

interface NewQuoteServicesProps {
  quote: Quote;
  updateQuote: (key: keyof QUOTE, value: any) => void;
}

const NEW_SERVICE_LINE = {
  quantity: 0,
  title: "",
  price: {
    amount: 0,
    taxRate: 0,
  },
  position: 0,
};

const NewQuoteServices = ({ quote, updateQuote }: NewQuoteServicesProps) => {
  const [services, setServices] = useState(quote.items.basicItems || []);
  const [productServiceLines, setProductServiceLines] = useState(
    quote.items.productItems || [],
  );
  const [comments, setComments] = useState(quote.items.commentItems || []);
  const [showModal, setShowModal] = useState(false);
  const [isProductSelectionModalOpen, setIsProductSelectionModalOpen] = useState(false);

  useEffect(() => {
    handleUpdateQuoteItems();
  }, [services, productServiceLines]);

  const handleUpdateQuoteItems = () => {
    const items = {
      basicItems: services,
      commentItems: comments,
      productItems: productServiceLines,
    };
    updateQuote("items", items);
  };

  const updateComments = (index: number, value: string) => {
    const newComments = [...comments];
    newComments[index] = {
      ...newComments[index],
      comment: value,
    };
    setComments(newComments);
    handleUpdateQuoteItems();
  };

  const updateService = (index: number, key: string, value: any) => {
    setServices(prev => {
      const newServices = prev.map((prevService, idx) => {
        if (idx === index) {
          if (key === "amount") {
            return {
              ...prevService,
              price: {
                ...prevService.price,
                amount: value,
              },
            };
          }

          if (key === "taxRate") {
            return {
              ...prevService,
              price: {
                ...prevService.price,
                taxRate: value,
              },
            };
          }

          return {
            ...prevService,
            [key]: value,
          };
        }
        return prevService;
      });

      return newServices;
    });
  };

  const handleAddNewBasicServiceLine = () => {
    setServices(prev => [...prev, NEW_SERVICE_LINE]);
  };

  const handleAddNewCommentLine = () => {
    setComments(prev => [...prev, { comment: "", position: 1 }]);
  };

  const handleDeleteCommentLine = (idx: number) => {
    setComments(prev => prev.filter((_, index) => index !== idx));
  };

  const handleDeleteServiceLine = (idx: number) => {
    setServices(prev => prev.filter((_, index) => index !== idx));
  };

  const handleDeleteProductLine = (idx: number) => {
    setProductServiceLines(prev => prev.filter((_, index) => index !== idx));
  };

  const handleAddProductLines = (products: any) => {
    console.log("products", products);
    const incomingProdudctIds = products.map(
      pp => pp?.productId?._id || pp.productId || pp._id,
    );
    const existingProductIds = productServiceLines.map(
      pp => pp?.productId?._id || pp.productId || pp._id,
    );
    const productIds = uniqueInArr([...incomingProdudctIds, ...existingProductIds]);

    console.log("productIds", productIds);

    const allProducts = [...productServiceLines, ...products].map(t => ({
      ...t,
      _id: t._id || t.productId._id,
    }));

    console.log("allProducts", allProducts);

    const consolidatedProducts = productIds.map(productId => {
      const product = allProducts.find(pp => pp._id === productId);

      console.log("product", product, productId);

      const quantity = allProducts
        .filter(pp => pp._id === productId)
        .reduce((acc, curr) => acc + curr.quantity, 0);

      if (typeof product?.productId !== "string") {
        return {
          ...product,
          quantity,
        };
      }

      const { price, name } = product;

      return {
        productId,
        name,
        price,
        quantity,
        position: 0,
      };
    });

    setProductServiceLines(consolidatedProducts);
  };

  const handleTotalDetalsCalculation = () => {
    const productsTotal = productServiceLines.reduce((acc, curr) => {
      const { price, _id, productId, quantity } = curr;
      const amount =
        productId && typeof productId === "object"
          ? productId?.basePrice?.amount
          : price?.amount;

      const total = amount * quantity;

      return acc + total;
    }, 0);

    const productsTotalTaxAmount = productServiceLines.reduce((acc, curr) => {
      const { price, _id, productId, quantity } = curr;
      const amount =
        productId && typeof productId === "object"
          ? productId?.basePrice?.amount
          : price?.amount;
      const taxRate =
        productId && typeof productId === "object"
          ? productId?.basePrice?.tax?.taxRate
          : price?.taxRate;

      const total = amount * quantity;

      const tax = total * (taxRate / 100);
      return acc + tax;
    }, 0);

    const basicItemsTotal = services.reduce((acc, curr) => {
      const { price, quantity } = curr;
      const { amount } = price;

      const total = amount * quantity;

      return acc + total;
    }, 0);

    const basicItemsTotalTaxAmount = services.reduce((acc, curr) => {
      const { price, quantity } = curr;

      const { amount, taxRate } = price;
      const total = amount * quantity;
      const tax = total * (taxRate / 100);
      return acc + tax;
    }, 0);

    const totalAmountWithoutTaxes = productsTotal + basicItemsTotal;
    const totalTaxAmount = productsTotalTaxAmount + basicItemsTotalTaxAmount;
    const totalAmomuntWithTaxes = totalAmountWithoutTaxes - totalTaxAmount;

    return {
      totalAmountWithoutTaxes,
      totalTaxAmount,
      totalAmomuntWithTaxes,
    };
  };

  const displayProductLineName = (item: QuoteProductItem) => {
    if (!item.productId || typeof item.productId === "string") {
      return item.name;
    }

    return item.productId.name;
  };
  const displayProductLinePrice = (item: QuoteProductItem) => {
    let price = 0;
    if (!item.productId || typeof item.productId === "string") {
      price = item.price.amount;
    } else {
      price = item.productId.basePrice.amount;
    }

    return formatCurrencyPrice(price, "fr", "eur");
  };

  const displayTotalDetails = () => {
    const { totalAmountWithoutTaxes, totalTaxAmount, totalAmomuntWithTaxes } =
      handleTotalDetalsCalculation();

    return (
      <Box mt="m">
        <CustomText mb="s" variant="paragraph" color="success">
          Synthèse
        </CustomText>

        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="label" color="primaryTextColor">
            Total HT
          </CustomText>
          <CustomText variant="paragraph" color="primaryTextColor">
            {formatCurrencyPrice(totalAmomuntWithTaxes, "fr", "eur")}
          </CustomText>
        </Box>
        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="label" color="primaryTextColor">
            TVA
          </CustomText>
          <CustomText variant="paragraph" color="primaryTextColor">
            {formatCurrencyPrice(totalTaxAmount, "fr", "eur")}
          </CustomText>
        </Box>
        <Box
          mt="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="label" color="primaryTextColor">
            Total TTC
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {formatCurrencyPrice(totalAmountWithoutTaxes, "fr", "eur")}
          </CustomText>
        </Box>
      </Box>
    );
  };

  return (
    <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={100}>
      <Box>
        <Box mt="s">
          <CustomText mb="s" variant="paragraph" color="success">
            Intitulé
          </CustomText>

          <CustomTextInput
            isRequired
            placeHolder="Entre l'objet de votre devis"
            onChangeText={text => updateQuote("title", text)}
            initialValue={quote.title}
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="paragraph" color="success">
            Prestation
          </CustomText>

          {productServiceLines.map((productLine, idx) => (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              key={displayProductLineName(productLine)}
            >
              <Box flex={1} mr="s">
                <Box
                  minHeight={45}
                  mt="s"
                  paddingHorizontal="s"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="button"
                  borderColor="disabled"
                  borderWidth={1}
                >
                  <CustomText variant="paragraph" color="primaryTextColor">
                    {productLine.quantity}x - {displayProductLineName(productLine)}
                  </CustomText>
                  <CustomText variant="paragraph" color="primaryTextColor">
                    {displayProductLinePrice(productLine)}
                  </CustomText>
                </Box>
              </Box>

              <TouchableOpacity onPress={() => handleDeleteProductLine(idx)}>
                <TRASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
              </TouchableOpacity>
            </Box>
          ))}

          {services.map((service, idx) => (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginVertical="s"
              key={service.title}
            >
              <Box flex={1} mr="s">
                <NewQuoteServiceLine
                  service={service}
                  updateService={(key, value) => {
                    updateService(idx, key, value);
                  }}
                />
              </Box>
              <TouchableOpacity onPress={() => handleDeleteServiceLine(idx)}>
                <TRASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
              </TouchableOpacity>
            </Box>
          ))}

          {comments.map((comment, idx) => (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginVertical="s"
              key={comment.comment + idx}
            >
              <NewCommentItemLine
                comment={comment.comment}
                updateComment={text => updateComments(idx, text)}
              />
              <TouchableOpacity onPress={() => handleDeleteCommentLine(idx)}>
                <TRASH width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.red} />
              </TouchableOpacity>
            </Box>
          ))}
        </Box>

        <Box mt="m" flexDirection="row" alignItems="center" justifyContent="space-around">
          <Box flex={0.5} mr="s">
            <CustomButton
              buttonVariant="primaryButton"
              onPress={() => setIsProductSelectionModalOpen(true)}
              buttonColor="success"
            >
              <CustomText variant="primaryButtonText" color="white">
                + ligne produit
              </CustomText>
            </CustomButton>
          </Box>

          <Box flex={0.5} ml="s">
            <CustomButton
              buttonVariant="outlineButton"
              onPress={() => setShowModal(true)}
              borderColor="success"
            >
              <CustomText variant="outlineButtonText" color="success">
                + Autre ligne
              </CustomText>
            </CustomButton>
          </Box>
        </Box>

        {displayTotalDetails()}

        <NewQuoteProductListModal
          isVisible={isProductSelectionModalOpen}
          onClose={() => setIsProductSelectionModalOpen(false)}
          onSubmit={products => {
            handleAddProductLines(products);
          }}
        />

        <NewQuoteLineSelectionModal
          onClose={() => setShowModal(false)}
          isVisible={showModal}
          onPress={action => {
            if (action === NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.BASIC_LINE) {
              handleAddNewBasicServiceLine();
            }
            // if (action === NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.PRODUCT_LINE) {
            //   setIsProductSelectionModalOpen(true);
            // }
            if (action === NEW_QUOTE_LINE_SELECTION_MODAL_ACTION_TYPE.COMMENT_LINE) {
              handleAddNewCommentLine();
            }
            setShowModal(false);
          }}
        />
      </Box>
    </KeyboardAvoidingView>
  );
};

export default NewQuoteServices;
