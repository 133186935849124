import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import ProductCard from "../../../../../components/ProductCard";
import ProductCategoryCard from "../../../../../components/ProductCategoryCard";
import ProductMenuCard from "../../../../../components/ProductMenuCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import {
  useGetMenusLazyQuery,
  useGetProductCategoriesLazyQuery,
  useGetProductsLazyQuery,
} from "../../../../../graphql/generated/schema";

const BookingServiceProductSelectList = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { type, data, onSubmit } = params;
  const [finalData, setFinalData] = useState(data);
  const [listItems, setListItems] = useState([]);

  const [getProducts] = useGetProductsLazyQuery();
  const [getCategories] = useGetProductCategoriesLazyQuery();
  const [getMenus] = useGetMenusLazyQuery();

  const handleGetListItems = async () => {
    try {
      if (type === "products") {
        const { data: response } = await getProducts({
          variables: {
            pagination: {
              limit: 30,
              offset: 0,
            },
          },
        });

        if (response) {
          setListItems(response.getProducts);
        }
      } else if (type === "categories") {
        const { data: response } = await getCategories({
          variables: {
            pagination: {
              limit: 30,
              offset: 0,
            },
          },
        });

        if (response) {
          setListItems(response.getProductCategories);
        }
      } else if (type === "menus") {
        const { data: response } = await getMenus({
          variables: {
            pagination: {
              limit: 30,
              offset: 0,
            },
          },
        });

        if (response) {
          setListItems(response.getMenus);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetListItems();
  }, []);

  const handleRenderItem = item => {
    const isSelected = finalData.find(dataItem => dataItem._id === item._id);
    if (type === "products") {
      return (
        <ProductCard
          showEnableToggle={false}
          image={item.picture}
          name={item.name}
          price={item.basePrice.amount}
          isEnabled={item.isEnabled}
          isSelected={isSelected}
          nbCategories={item.categories.length}
          nbGroupOptions={item.groupOptions.length}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateEnabled={() => {}}
        />
      );
    } else if (type === "categories") {
      return (
        <ProductCategoryCard
          name={item.name}
          color={item.color}
          isEnabled={item.isEnabled}
          isSelected={isSelected}
          nbProducts={item.products.length}
          showEnabledToggle={false}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateEnabled={() => {}}
          isDraggable={false}
        />
      );
    } else {
      const price =
        item.prices?.slice().sort((a, b) => a.basePrice.amount - b.basePrice.amount)[0]
          .basePrice.amount || 0;
      return (
        <ProductMenuCard
          color={item.color}
          name={item.name}
          slug={item.slug}
          image={item.picture}
          price={price}
          isEnabled={item.isEnabled}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateEnabled={() => {}}
          isSelected={isSelected}
          isDraggable={false}
          showEnabledToggle={false}
        />
      );
    }
  };

  const handleSelectItem = item => {
    const isSelected = finalData.find(dataItem => dataItem._id === item._id);
    if (isSelected) {
      setFinalData(finalData.filter(dataItem => dataItem._id !== item._id));
    } else {
      setFinalData([...finalData, item]);
    }
  };

  const handleSubmit = () => {
    onSubmit(type, finalData);
    navigation.goBack();
  };

  const displayTitle = () => {
    if (type === "products") {
      return "Sélectionner des produits";
    } else if (type === "categories") {
      return "Sélectionner des catégories";
    } else {
      return "Sélectionner des menus";
    }
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <FlatList
        ListHeaderComponent={
          <>
            <ScreenHeader
              title={displayTitle()}
              hasBackButton
              onBackPress={navigation.goBack}
            />

            {/* <Box marginVertical="s">
              <CustomTextInput
                {...{
                  placeHolder: "Rechercher",
                  // leftIcon: (
                  //   <SEARCH
                  //     height={ICON_SIZE}
                  //     width={ICON_SIZE}
                  //     fill={PALETTE.darkBlue}
                  //   />
                  // ),
                  onChangeText: (text: string) => {},
                  variant: "defaultTextInput",
                  boxProps: {
                    backgroundColor: "disabled",
                    padding: "s",
                    borderRadius: "button",
                  },
                }}
              />
            </Box> */}
          </>
        }
        showsVerticalScrollIndicator={false}
        data={listItems}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => handleSelectItem(item)}>
            <Box marginVertical="s">{handleRenderItem(item)}</Box>
          </TouchableOpacity>
        )}
      />
      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default BookingServiceProductSelectList;
