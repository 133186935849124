import { createContext } from "react";
import type { LayoutRectangle } from "react-native";

export enum SPLIT_VIEW_TYPE {
  SPLIT = "SPLIT",
  FULL_LEFT = "FULL_LEFT",
  FULL_RIGHT = "FULL_RIGHT",
}

export interface SplitViewContextInterface {
  screenContainer?: LayoutRectangle;
  leftContainer?: LayoutRectangle;
  rightContainer?: LayoutRectangle;

  viewType: SPLIT_VIEW_TYPE;
  isSplittable: boolean;

  setViewType: (type: SPLIT_VIEW_TYPE) => void;
}

export const SplitViewContext = createContext<SplitViewContextInterface>(
  {} as SplitViewContextInterface,
);
