import HOME from "../../../assets/icons/BASE/HOME.svg";
import MODIFY from "../../../assets/icons/BASE/MODIFY.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import SHOPPING_BAG from "../../../assets/icons/BASE/SHOPPING_BAG.svg";
import { Modules } from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface BookingOrderServiceSlotCardProps {
  time: string;
  slotMinMax?: string;
  serviceSlotCapacity?: string;
  isEnabled: boolean;
  updateEnabled: (value: boolean) => void;
  module: Modules;
}

const BookingOrderServiceSlotCard = ({
  time,
  slotMinMax,
  serviceSlotCapacity,
  isEnabled,
  updateEnabled,
  module,
}: BookingOrderServiceSlotCardProps) => {
  const getSlotMinMaxIcon = () => {
    if (module === Modules.OnlineSales)
      return <SHOPPING_BAG width={16} height={16} fill={PALETTE.lightGrey} />;

    return <OCCURENCES width={16} height={16} fill={PALETTE.lightGrey} />;
  };

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      // p="s"
      paddingVertical="s"
      borderRadius="button"
      // backgroundColor="disabled"
    >
      <CustomText variant="content" color="primaryTextColor">
        {time}
      </CustomText>

      {slotMinMax && (
        <Box flexDirection="row" alignItems="center">
          <Box height={20} backgroundColor="primaryTextColor" width={2} mr="s" />

          {getSlotMinMaxIcon()}

          <CustomText ml="s" variant="content" color="lightGrey">
            {slotMinMax}
          </CustomText>
        </Box>
      )}

      {serviceSlotCapacity && (
        <Box flexDirection="row" alignItems="center">
          <Box height={20} backgroundColor="primaryTextColor" width={2} mr="s" />

          <HOME width={16} height={16} fill={PALETTE.lightGrey} />

          <CustomText ml="s" variant="content" color="lightGrey">
            {serviceSlotCapacity}
          </CustomText>
        </Box>
      )}

      <Box flexDirection="row" alignItems="center">
        <Box mr="s">
          <MODIFY width={16} height={16} fill={PALETTE.darkBlue} />
        </Box>

        <Toggle value={isEnabled} onChange={v => updateEnabled(v)} />
      </Box>
    </Box>
  );
};

export default BookingOrderServiceSlotCard;
