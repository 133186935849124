import { format } from "date-fns";

import BLACKLISTED from "../../../../../../../assets/icons/BASE/BLACKLISTED.svg";
import CAKE from "../../../../../../../assets/icons/BASE/CAKE.svg";
import CLIENT_COMMENT from "../../../../../../../assets/icons/BASE/CLIENT_COMMENT.svg";
import EMAIL from "../../../../../../../assets/icons/BASE/EMAIL.svg";
import HEART from "../../../../../../../assets/icons/BASE/HEART.svg";
import HOME from "../../../../../../../assets/icons/BASE/HOME.svg";
import LAPTOP from "../../../../../../../assets/icons/BASE/LAPTOP.svg";
import LOCATION_PIN from "../../../../../../../assets/icons/BASE/LOCATION_PIN.svg";
import MOBILE_PHONE from "../../../../../../../assets/icons/BASE/MOBILE_PHONE.svg";
import PHONE from "../../../../../../../assets/icons/BASE/PHONE.svg";
import SECONDARY_EMAIL from "../../../../../../../assets/icons/BASE/SECONDARY_EMAIL.svg";
import SECONDARY_PHONE from "../../../../../../../assets/icons/BASE/SECONDARY_PHONE.svg";
import SUMMARY from "../../../../../../../assets/icons/BASE/SUMMARY.svg";
import type {
  BookingSummary,
  MerchantClientFragment,
  OrderSummary,
  QuoteSummary,
} from "../../../../../../graphql/generated/schema";
import { Modules } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../../utils/common";
import Box from "../../../../../Base/Box";
import { CustomText } from "../../../../../Base/Text";
import TabTextInfo from "../../TabTextInfo";

interface ClientViewTabProps {
  moduleType: Modules;
  data: MerchantClientFragment;
  bookingSummary?: BookingSummary;
  orderSummary?: OrderSummary;
  quoteSummary?: QuoteSummary;
  hideHeaders?: boolean;
}

const ClientViewTab = ({
  moduleType,
  data,
  bookingSummary,
  orderSummary,
  quoteSummary,
  hideHeaders = false,
}: ClientViewTabProps) => {
  const { isVIP, phone, email, comment, merchantCompanyId, address } = data;
  return (
    <Box>
      {!hideHeaders && (
        <Box mb="s" flexDirection="row" alignItems="center">
          <SUMMARY fill={PALETTE.darkGrey} />

          <CustomText ml="s" variant="label" color="primaryTextColor">
            Synthese
          </CustomText>
        </Box>
      )}

      {(moduleType === Modules.Quotes ||
        moduleType === Modules.Invoices ||
        moduleType === Modules.MerchantClients) && (
        <Box>
          <Box mb="s" alignItems="center">
            <CustomText ml="s" variant="description" color="lightGrey">
              Facturation
            </CustomText>
          </Box>

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="button"
            backgroundColor="disabled"
            p="s"
          >
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {quoteSummary?.totalQuotes || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Devis
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {quoteSummary?.totalQuotes || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Factures
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {quoteSummary?.totalCancelledQuotes || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Annul
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {formatCurrencyPrice(quoteSummary?.totalAmount || 0, "fr", "eur")}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Ticket
              </CustomText>
            </Box>
          </Box>
        </Box>
      )}

      {(moduleType === Modules.OnlineSales || moduleType === Modules.MerchantClients) && (
        <Box mt="s">
          <Box mb="s" alignItems="center">
            <CustomText ml="s" variant="description" color="lightGrey">
              Commandes
            </CustomText>
          </Box>

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="button"
            backgroundColor="disabled"
            p="s"
          >
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {orderSummary?.totalOrders || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Commandes
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {orderSummary?.totalCancelledOrders || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Annul
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {orderSummary?.totalNoShowOrders || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                No-Show
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {formatCurrencyPrice(orderSummary?.totalAmount || 0, "fr", "eur")}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Ticket
              </CustomText>
            </Box>
          </Box>
        </Box>
      )}

      {(moduleType === Modules.Reservation || moduleType === Modules.MerchantClients) && (
        <Box>
          {!hideHeaders &&
            (moduleType === Modules.Reservation ||
              moduleType === Modules.MerchantClients) && (
              <Box mt="s" mb="s" alignItems="center">
                <CustomText ml="s" variant="description" color="lightGrey">
                  Réservations
                </CustomText>
              </Box>
            )}

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="button"
            backgroundColor="disabled"
            p="s"
          >
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {bookingSummary?.totalBookings || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Résa
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {bookingSummary?.totalCancelledBookings || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                Annul
              </CustomText>
            </Box>
            <Box alignItems="center" paddingHorizontal="s">
              <CustomText variant="label" color="labelColor">
                {bookingSummary?.totalNoShowBookings || 0}
              </CustomText>
              <CustomText variant="description" color="labelColor">
                No-Show
              </CustomText>
            </Box>
          </Box>
        </Box>
      )}

      <TabTextInfo
        {...{
          title: "Client VIP",
          content: isVIP ? "Oui" : "Non",
          icon: <HEART height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
        }}
      />
      <TabTextInfo
        {...{
          title: "Téléphone",
          content: phone,
          icon: <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
        }}
      />
      {data?.secondaryPhone && (
        <TabTextInfo
          {...{
            title: "Téléphone secondaire",
            content: data?.secondaryPhone,
            icon: (
              <SECONDARY_PHONE
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {data?.email && (
        <TabTextInfo
          {...{
            title: "Mail",
            content: email,
            icon: <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {data?.secondaryEmail && (
        <TabTextInfo
          {...{
            title: "Mail secondaire",
            content: data?.secondaryEmail,
            icon: (
              <SECONDARY_EMAIL
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {data?.dateOfBirth && (
        <TabTextInfo
          {...{
            title: "Date d'anniversaire",
            content: format(new Date(data?.dateOfBirth), "dd/MM/yyyy"),
            icon: <CAKE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {address?.formattedAddress && (
        <TabTextInfo
          {...{
            title: "Adresse",
            content: address?.formattedAddress,
            icon: (
              <LOCATION_PIN
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {/* <TabTextInfo
        {...{
          title: "Langue",
          content: "Francais",
          icon: (
            <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      /> */}
      {merchantCompanyId?.companyName && (
        <TabTextInfo
          {...{
            title: "Entreprise",
            content: merchantCompanyId.companyName,
            icon: <HOME height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {comment && (
        <TabTextInfo
          {...{
            title: "Note sur le client",
            content: comment,
            icon: (
              <CLIENT_COMMENT
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      <TabTextInfo
        {...{
          title: "Accepte les newsletters",
          content: data.acceptedReceiveNewsLetters ? "Oui" : "Non",
          icon: <LAPTOP height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
        }}
      />
      <TabTextInfo
        {...{
          title: "Accepte les campagnes SMS",
          content: data.acceptedReceiveMarketingSms ? "Oui" : "Non",
          icon: (
            <MOBILE_PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
      <TabTextInfo
        {...{
          title: "Client blacklisté",
          content: data.isBlacklisted ? "Oui" : "Non",
          icon: (
            <BLACKLISTED height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
    </Box>
  );
};

export default ClientViewTab;
