 
import { Click_Status } from "../graphql/generated/schema";
export const getLabelForTakeAwayOrderStatus = (status: Click_Status): string => {
  switch (status) {
    case Click_Status.New:
      return "Nouvelle";
    case Click_Status.Validated:
      return "Validée";
    case Click_Status.AutomaticValidated:
      return "Validée automatiquement";
    case Click_Status.Preparing:
      return "En préparation";
    case Click_Status.Prepared:
      return "Prête";
    case Click_Status.Ended:
      return "Terminée";
    case Click_Status.Noshow:
      return "No show";
    case Click_Status.CancelledByUser:
      return "Annulée par l’utilisateur";
    case Click_Status.CancelledByOwner:
      return "Annulée par l'établissement";
    case Click_Status.Refunded:
      return "Remboursée";
    default:
      break;
  }
  return "-";
};
