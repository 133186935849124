import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import BottomButton from "../../../../components/BottomButton";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  BookingFloorSettings as BookingFloorSettingsType,
  BookingSettingsFragment,
} from "../../../../graphql/generated/schema";
import {
  useGetBookingSettingsLazyQuery,
  useUpdateBookingSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

import BookingFloorSettingsPreview from "./BookingFloorSettingsPreview";

interface BookingFloorSettingsProps {
  goBack?: () => void;
}

const BookingFloorSettings = ({ goBack }: BookingFloorSettingsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [loading, setLoading] = useState(true);

  const [bookingSettings, setBookingSettings] = useState<BookingSettingsFragment | null>(
    null,
  );

  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [updateBookingSettings] = useUpdateBookingSettingsMutation();

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings();

      if (data) {
        setBookingSettings(data.getBookingSettings);
      }
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_BOOKING_SETTINGS",
            message:
              "Une erreur est survenue lors de la récupération des paramètres de réservation.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleUpdateBookingSettings = async () => {
    try {
      const updates = removeTypeNames(bookingSettings);
      delete updates._id;

      await updateBookingSettings({
        variables: {
          updates,
        },
      });

      handleGoBack();
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_BOOKING_SETTINGS",
            message:
              "Une erreur est survenue lors de la mise à jour des paramètres de réservation.",
          },
        ],
        "error",
      );
    }
  };

  const updateBookingSettingsData = (
    key: keyof BookingFloorSettingsType,
    value: boolean,
  ) => {
    setBookingSettings(prev => {
      if (prev) {
        return {
          ...prev,
          bookingFloorSettings: {
            ...prev.bookingFloorSettings,
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Affichage du plan de salle"
        hasBackButton
        onBackPress={handleGoBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 150,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="s">
          <InputSectionTitle text="APERÇU DE LA VUE D’UNE TABLE" />
        </Box>
        <Box mt="s">
          <BookingFloorSettingsPreview
            showClientName={!!bookingSettings?.bookingFloorSettings?.showClientName}
            showVIP={!!bookingSettings?.bookingFloorSettings?.showVipIcon}
          />
        </Box>

        {/* <Box mt="s">
          <ToggleInputLine text="Afficher le plan de salle sur les résa"
            value={!!bookingSettings?.bookingFloorSettings?.}
          />
        </Box> */}
        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher le nom du client"
            value={!!bookingSettings?.bookingFloorSettings?.showClientName}
            onValueChange={value => updateBookingSettingsData("showClientName", value)}
          />
        </Box>
        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher une icône étoile sur la table
si un client est VIP"
            value={!!bookingSettings?.bookingFloorSettings?.showVipIcon}
            onValueChange={value => updateBookingSettingsData("showVipIcon", value)}
          />
        </Box>
        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher un timer sur la table dès
que la réservation passe en 'Assis'"
            value={!!bookingSettings?.bookingFloorSettings?.showTimerForSeatedBookings}
            onValueChange={value =>
              updateBookingSettingsData("showTimerForSeatedBookings", value)
            }
          />
        </Box>
      </ScrollView>

      <BottomButton title="Enregistrer" onPress={handleUpdateBookingSettings} />
    </Box>
  );
};

export default BookingFloorSettings;
