import { Image } from "react-native";

import EMAIL from "../../../assets/icons/BASE/EMAIL.svg";
import PHONE from "../../../assets/icons/BASE/PHONE.svg";
import USER_GROUP from "../../../assets/icons/BASE/USER_GROUP.svg";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface CompanyCardProps {
  name: string;
  subText: string;
  phone: string;
  email: string;
  image: string;
  isSelected: boolean;
}

const CompanyCard = ({
  name,
  subText,
  phone,
  email,
  image,
  isSelected,
}: CompanyCardProps) => {
  const SELECTED_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "white",
    borderColor: "success",
    borderRadius: "button",
    borderWidth: 1,
  };
  const NOT_SELECTED_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "disabled",
    borderRadius: "button",
    borderWidth: 0,
  };

  const SELECTED_INFO_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "disabled",
    borderRadius: "button",
    borderWidth: 0,
  };
  const NOT_SELECTED_INFO_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "white",
    borderRadius: "button",
    borderWidth: 0,
  };

  const displayPicture = () => {
    if (!image) return <></>;

    return (
      <Image
        source={{
          uri: image,
        }}
        style={{
          width: 47,
          height: 47,
          position: "absolute",
          right: 0,
          top: -18,
          borderRadius: 47 / 2,
        }}
      />
    );
  };

  return (
    <Box
      p="s"
      justifyContent="center"
      {...(isSelected ? SELECTED_STYLES : NOT_SELECTED_STYLES)}
    >
      <Box>
        <CustomText variant="label" color="primaryTextColor">
          {name}
        </CustomText>
        {displayPicture()}
      </Box>
      <Box flexDirection="row" alignItems="center" marginVertical="s">
        <USER_GROUP width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          {subText}
        </CustomText>
      </Box>

      <Box mt="s" flexDirection="row" alignItems="center">
        {phone && (
          <Box flexDirection="row" alignItems="center">
            <PHONE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="primaryTextColor">
              {phone}
            </CustomText>
          </Box>
        )}

        {email && (
          <Box flexDirection="row" alignItems="center" ml="s">
            <EMAIL width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="primaryTextColor">
              {email}
            </CustomText>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CompanyCard;
