import { useState } from "react";

import type { DecorationFragment } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal/index";
import Incrementer from "../../Incrementer/index";

interface EditDecorationModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  decoration: DecorationFragment;
  onSubmit: (id: string, decoration: DecorationFragment) => void;
  deleteDecoration: (id: string) => void;
  duplicateDecoration: (id: string) => void;
}

const EditDecorationModal = ({
  decoration,
  isOpen,
  setIsOpen,
  deleteDecoration,
  duplicateDecoration,
  onSubmit,
}: EditDecorationModalProps) => {
  const [updatedDecoration, setUpdatedDecoration] = useState(decoration);

  const handleClose = () => {
    setUpdatedDecoration(decoration);
    setIsOpen(false);
  };

  const updateTableData = (
    key: keyof DecorationFragment,
    value: string | number | boolean,
  ) => {
    setUpdatedDecoration(v => ({
      ...v,
      [key]: value,
    }));
  };
  const handleSubmit = () => {
    onSubmit(decoration._id, updatedDecoration);
    handleClose();
  };

  const handleDelete = () => {
    deleteDecoration(decoration._id);
    handleClose();
  };

  const handleDuplicate = () => {
    duplicateDecoration(decoration._id);
    handleClose();
  };

  return (
    <ContentModal
      isVisible={isOpen}
      onClose={handleClose}
      title="Edition de la décoration"
      styles={{
        height: "auto",
      }}
    >
      <Box>
        <Box mt="m">
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="primaryTextColor">
              Rotation
            </CustomText>

            <Incrementer
              min={0}
              max={360}
              value={updatedDecoration.rotationDeg}
              incrementor={30}
              onValueChange={v => updateTableData("rotationDeg", v)}
            />
          </Box>
        </Box>

        <Box mt="l">
          <Box width="100%" flexDirection="row" alignItems="center" mb="m">
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="danger"
              onPress={handleDelete}
              containerStyles={{
                width: "48%",
                marginRight: 8,
              }}
            >
              <CustomText variant="primaryButtonText" color="danger">
                Supprimer
              </CustomText>
            </CustomButton>
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleDuplicate}
              containerStyles={{
                width: "48%",
                marginLeft: 8,
              }}
            >
              <CustomText variant="primaryButtonText" color="success">
                Dupliquer
              </CustomText>
            </CustomButton>
          </Box>
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="success"
            onPress={handleSubmit}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default EditDecorationModal;
