import { format } from "date-fns";
import { TouchableOpacity } from "react-native";

import CALENDAR from "../../../assets/icons/BASE/CALENDAR.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

interface DayMonthButtonProps {
  selectedDate: Date;
  onPress: () => void;
  goToToDay: () => void;
}

const AGENDA_SIZE = 18;
const ARROW_SIZE = 14;

const DayMonthButton = ({ selectedDate, onPress, goToToDay }: DayMonthButtonProps) => {
  const formattedDay = format(selectedDate, "E dd");
  const formattedMonth = format(selectedDate, "MMMM");

  return (
    <CustomButton
      buttonVariant="primaryButton"
      onPress={onPress}
      styles={{
        // p: "xs",
        height: 54,
        maxWidth: 145,
        width: "100%",
        backgroundColor: "disabled",
        borderColor: "listItemActiveBackground",
        borderRadius: "button",
        borderWidth: 0,
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <Box
        width="100%"
        height="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingHorizontal="s"
      >
        <Box
          flex={1}
          // minWidth={110}
          width={100}
          flexDirection="column"
          // // alignItems="center"
          // width={{
          //   largeTablet: "100%",
          // }}
        >
          <CustomText
            textAlign="left"
            variant="primaryButtonText"
            // pr="m"
            color="primaryTextColor"
          >
            {formattedDay}
          </CustomText>

          <CustomText
            textAlign="left"
            variant="primaryButtonText"
            // pr="m"
            numberOfLines={1}
            style={{ maxWidth: 100 }}
            color="primaryTextColor"
          >
            {formattedMonth}
          </CustomText>
        </Box>
        <Box height="100%" width={3} backgroundColor="lightGrey" />
        <Box
          height="100%"
          minWidth={35}
          alignItems="center"
          justifyContent="center"
          // width={{
          //   largeTablet: "100%",
          // }}
        >
          <TouchableOpacity onPress={goToToDay}>
            <Box alignItems="center" justifyContent="center">
              <CALENDAR
                height={AGENDA_SIZE}
                width={AGENDA_SIZE}
                fill={PALETTE.darkBlue}
              />
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    </CustomButton>
  );
};

export default DayMonthButton;
