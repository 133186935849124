import { useNavigation } from "@react-navigation/native";

import Box from "../../components/Base/Box";
import { CustomText } from "../../components/Base/Text";
import { CustomButton } from "../../components/Button/index";

const NotFound = () => {
  const navigation = useNavigation();
  return (
    <Box flex={1} backgroundColor="white" justifyContent="center" alignItems="center">
      <Box>
        <CustomText variant="content" color="primaryTextColor">
          Cette page n'existe pas
        </CustomText>
      </Box>

      <CustomButton
        styles={{ mt: "m", paddingHorizontal: "m" }}
        onPress={() => navigation.navigate("DASHBOARD")}
        buttonVariant="primaryButton"
        buttonColor="success"
      >
        <CustomText variant="primaryButtonText" color="white">
          Retourner à l'acceuil{" "}
        </CustomText>
      </CustomButton>
    </Box>
  );
};

export default NotFound;
