import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../components/Base/Box";
import CompanyCard from "../../../../components/CompanyCard";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import type {
  Pagination,
  PrescriberFragment,
} from "../../../../graphql/generated/schema";
import { useGetPrescribersLazyQuery } from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import PrescriberListDetails from "../PrescriberListDetails";

interface PrescriberListProps {
  goBack?: () => void;
}

const PrescriberList = ({ goBack }: PrescriberListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [prescribers, setPrescribers] = useState<PrescriberFragment[]>([]);
  const [selectedPrescriberId, setSelectedPrescriberId] = useState("");
  const [showPrescriberInline, setShowPrescriberInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getPrescribers] = useGetPrescribersLazyQuery();

  const filteredPrescribers = useMemo(() => {
    const formattedSearch = search.toLowerCase()?.trim();

    if (!formattedSearch) {
      return prescribers;
    }

    return prescribers.filter(prescriber => {
      const formatteName = prescriber.name.toLowerCase()?.trim();
      const formatteEmail = prescriber.email.toLowerCase()?.trim();
      const formattePhone = prescriber.phone.toLowerCase()?.trim();

      return (
        formatteName.includes(formattedSearch) ||
        formatteEmail.includes(formattedSearch) ||
        formattePhone.includes(formattedSearch)
      );
    });
  }, [search, prescribers]);

  const handleGetPrescribers = async () => {
    try {
      const { data } = await getPrescribers({
        fetchPolicy: "cache-and-network",
        variables: { pagination },
      });

      setPrescribers(data?.getMerchantClientPrescribers || []);
    } catch (err) {
      console.log("err get prescribers", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetPrescribers();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setShowPrescriberInline(true);
      setSelectedPrescriberId("");
    } else {
      navigation.navigate("PRESCRIBER_LIST_DETAILS", { isNew: true });
    }
  };

  const handleNavigationToDetails = (id: string) => {
    if (goBack) {
      setShowPrescriberInline(true);
      setSelectedPrescriberId(id);
    } else {
      navigation.navigate("PRESCRIBER_LIST_DETAILS", { isNew: false, id });
    }
  };

  const handleCloseSelectedTab = async () => {
    await handleGetPrescribers();
    setShowPrescriberInline(false);
  };

  const displaySelectedPrescriber = () => {
    return (
      <PrescriberListDetails
        goBack={handleCloseSelectedTab}
        selectedPrescriberId={selectedPrescriberId}
        newValue={selectedPrescriberId === ""}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Prescripteurs"
          hasBackButton
          rightButtons={
            <TouchableOpacity onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          onBackPress={goBack || navigation.goBack}
        />

        <FlatList
          ListHeaderComponent={
            <Box>
              <Box marginVertical="s">
                <CustomTextInput
                  {...{
                    placeHolder: "Rechercher",
                    leftIcon: (
                      <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.black} />
                    ),
                    onChangeText: setSearch,
                  }}
                />
              </Box>
            </Box>
          }
          showsVerticalScrollIndicator={false}
          data={filteredPrescribers}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleNavigationToDetails(item._id)}>
                <CompanyCard
                  name={item.name}
                  email={item.email}
                  phone={item.phone}
                  image={item.picture}
                  subText={`${item.commissionPercentage}%`}
                  isSelected={false}
                />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showPrescriberInline) {
      return displaySelectedPrescriber();
    }

    return displaySettingsList();
  };

  return <>{displayContent()}</>;
};

export default PrescriberList;
