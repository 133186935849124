 
import { Publication_Type } from "../types/types";

export const convertPublicationType = (type: Publication_Type): string => {
  switch (type) {
    case Publication_Type.Event:
      return "Événement";
    case Publication_Type.NewInfo:
      return "Nouvelle information";
    case Publication_Type.Offer:
      return "Offre";

    default:
      break;
  }
  return "-";
};
