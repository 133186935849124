import type { Dispatch, SetStateAction } from "react";

import type { CreateOnlineSalesAvailabilityInput } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ColorListSelect from "../../Settings/ColorListSelect";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface BaseServiceProps {
  service: CreateOnlineSalesAvailabilityInput;
  setService: Dispatch<SetStateAction<CreateOnlineSalesAvailabilityInput>>;
  errors: ERROR[];
}

const BaseService = ({ service, setService, errors }: BaseServiceProps) => {
  const updateBookingAvailabilityData = (
    key: keyof CreateOnlineSalesAvailabilityInput,
    value: boolean | string,
  ) => {
    setService(prev => ({ ...prev, [key]: value }));
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  return (
    <Box>
      <Box mt="s" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          textColor={doesInputHaveError("isEnabled") ? "danger" : "primaryTextColor"}
          text="Activé"
          value={service.isEnabled}
          onValueChange={v => updateBookingAvailabilityData("isEnabled", v)}
        />
      </Box>
      <Box mt="s" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <CustomTextInput
          placeHolder="Nom du service"
          initialValue={service.name}
          onChangeText={t => updateBookingAvailabilityData("name", t)}
          isRequired
          hasErrors={doesInputHaveError("name")}
        />
      </Box>

      <Box
        marginVertical="s"
        pb="s"
        borderBottomColor="disabled"
        borderBottomWidth={LINE_THICKNESS}
      >
        <CustomText
          mb="s"
          variant="content"
          color={doesInputHaveError("color") ? "danger" : "primaryTextColor"}
        >
          Couleur du service
        </CustomText>
        <ColorListSelect
          selectedColor={service.color}
          onPress={color => updateBookingAvailabilityData("color", color)}
        />
      </Box>
    </Box>
  );
};

export default BaseService;
