import { useNavigation } from "@react-navigation/native";
import { isBefore } from "date-fns";
import { useContext, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import EDIT from "../../../../../assets/icons/BASE/EDIT.svg";
import { SplitViewContext } from "../../../../contexts/SpitViewContext";
import type {
  AvailabilityServiceWithModuleInfo,
  AvailbilityServiceBookingFloorCapacityConfiguration,
  Modules,
} from "../../../../graphql/generated/schema";
import { Service_Visibility } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import { createDateWithTime } from "../../../../utils/common";
import AgendaMonthView from "../../../AgendaMonthView";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import DayMonthButton from "../../../DayMonthButton";
import { ServiceButton } from "../../../ServiceButton";
import Touchable from "../../../Touchable";

interface BookingServicesProps {
  moduleType: Modules;
  selectedDate: Date;
  onDayPress: (date: Date) => void;
  selectedServiceId: string;
  onSelectService: (serviceId: string) => void;
  services: AvailabilityServiceWithModuleInfo[];
  dataByMonth: Record<string, unknown>;
  onCloseServiceSlot: (serviceId: string, slotId?: string) => void;
  onMonthChange?: (date: Date) => void;
  isServiceSlotCloseInProgress: boolean;
  totalCapacityForAllServices: number;
  setSelectedFloorId: (floorId: string) => void;
  selectedFloorId: string;
}

const BookingServices = ({
  moduleType,
  services,
  onDayPress,
  selectedDate,
  selectedServiceId,
  onSelectService,
  dataByMonth,
  onCloseServiceSlot,
  onMonthChange,
  isServiceSlotCloseInProgress,
  totalCapacityForAllServices,
  selectedFloorId,
  setSelectedFloorId,
}: BookingServicesProps) => {
  const [isDaySelectorOpen, setIsDaySelectorOpen] = useState(false);
  const splitViewContext = useContext(SplitViewContext);
  const navigation = useNavigation();

  const toggleDaySelector = () => {
    setIsDaySelectorOpen(v => !v);
  };

  const handleDayPress = (date: Date) => {
    onDayPress(date);
    setIsDaySelectorOpen(false);
  };

  const isSelected = (serviceId: string) => {
    return selectedServiceId === serviceId;
  };

  const getAllServiceInfo = (): AvailabilityServiceWithModuleInfo => {
    // const totalForService: number = services.reduce((acc, service) => {
    //   return acc + service.totalForService;
    // }, 0);

    const allService = services.find(s => s.serviceId === "HS_SERVICE");

    const ARE_ALL_SERVICES_DISABLED = services.every(
      service => service.serviceVisibility === Service_Visibility.Disabled,
    );

    const ARE_LIMITS_ENABLED = services.some(s => s.areLimitsEnabled);

    if (allService) {
      return {
        ...allService,
        serviceId: "ALL",
        name: "Tous",
        serviceVisibility: ARE_ALL_SERVICES_DISABLED
          ? Service_Visibility.Disabled
          : Service_Visibility.Enabled,
        areLimitsEnabled: ARE_LIMITS_ENABLED,
      };
    }

    const totalCapacityForService: number = services.reduce((acc, service) => {
      return acc + service.totalCapacityForService;
    }, 0);

    return {
      serviceId: "ALL",
      name: "Tous",
      totalForService: totalCapacityForAllServices,
      totalCapacityForService,
      slots: [],
      serviceVisibility: ARE_ALL_SERVICES_DISABLED
        ? Service_Visibility.Disabled
        : Service_Visibility.Enabled,
      areLimitsEnabled: ARE_LIMITS_ENABLED,
      startTime: "",
      endTime: "",
      isEnabled: true,
    };
  };

  const getNotHsServices = () => {
    return services
      .filter(s => s.serviceId !== "HS_SERVICE")
      .sort((a, b) => {
        const aDate = createDateWithTime(new Date(), a.startTime);
        const bDate = createDateWithTime(new Date(), b.startTime);

        return isBefore(aDate, bDate) ? -1 : 1;
      });
  };

  const goToToDay = () => {
    const today = new Date();
    onDayPress(today);
  };

  const getBookingFloorsForSelectedService = () => {
    const selectedService = services.find(
      service => service.serviceId === selectedServiceId,
    );

    return (
      selectedService?.bookingFloorExperience?.bookingFloorsCapacityConfiguration || []
    );
  };

  const handleGoToFloorCapacityConfiguration = (
    floor: AvailbilityServiceBookingFloorCapacityConfiguration,
  ) => {
    const selectedService = services.find(
      service => service.serviceId === selectedServiceId,
    );

    setSelectedFloorId(floor.floorId);

    navigation.navigate("BOOKINGS_LIST_FLOOR_CAPACITY_EDIT", {
      floor,
      service: selectedService,
      selectedDate: selectedDate?.toISOString(),
    });
  };

  const displayBookingFloorsLinkedToSelectedService = () => {
    const floors = getBookingFloorsForSelectedService();

    return (
      <Box mt="s">
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {floors.map(floor => {
            const isFloorSelected = selectedFloorId === floor.floorId;

            return (
              <Touchable
                key={floor.floorId}
                onPress={() => {
                  setSelectedFloorId(isFloorSelected ? "" : floor.floorId);
                }}
              >
                <Box
                  mr="s"
                  minHeight={40}
                  minWidth={80}
                  p="s"
                  borderRadius="button"
                  borderColor={isFloorSelected ? "success" : "primaryTextColor"}
                  borderWidth={1}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText mr="s" variant="content" color="primaryTextColor">
                    {floor.floorName}
                  </CustomText>

                  <Touchable
                    hitSlop={{
                      top: 10,
                      bottom: 10,
                      left: 10,
                      right: 10,
                    }}
                    onPress={() => handleGoToFloorCapacityConfiguration(floor)}
                  >
                    <Box p="xs">
                      <EDIT height={18} width={18} fill={PALETTE.darkBlue} />
                    </Box>
                  </Touchable>
                </Box>
              </Touchable>
            );
          })}
        </ScrollView>
      </Box>
    );
  };

  return (
    <Box>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <Box mr="s">
          <DayMonthButton
            goToToDay={goToToDay}
            selectedDate={selectedDate}
            onPress={toggleDaySelector}
          />
        </Box>

        {getNotHsServices().length > 0 && (
          <Box mr="s">
            <ServiceButton
              {...{
                moduleType,
                onCloseServiceSlot,
                selectedDate,
                onPress: () => {
                  onSelectService("ALL");
                  setSelectedFloorId("");
                },

                serviceInfo: getAllServiceInfo(),
                isSelected: isSelected("ALL"),
                isServiceSlotCloseInProgress,
              }}
            />
          </Box>
        )}

        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          data={getNotHsServices()}
          renderItem={({ item: service }) => {
            const { serviceId } = service;

            const ARE_THERE_DISABLED_SLOTS = service?.slots?.some(
              slot =>
                slot.slotVisibility === Service_Visibility.Disabled ||
                slot.slotVisibility === Service_Visibility.Partially,
            );

            return (
              <Box key={serviceId} mr="s">
                <ServiceButton
                  {...{
                    moduleType,
                    selectedDate,
                    onPress: () => {
                      onSelectService(serviceId);
                      setSelectedFloorId("");
                    },
                    onCloseServiceSlot,
                    serviceInfo: {
                      ...service,
                      serviceVisibility:
                        ARE_THERE_DISABLED_SLOTS &&
                        service.serviceVisibility === Service_Visibility.Enabled
                          ? Service_Visibility.Partially
                          : service.serviceVisibility,
                    },
                    isSelected: isSelected(serviceId),
                    isServiceSlotCloseInProgress,
                  }}
                />
              </Box>
            );
          }}
        />
      </ScrollView>

      {displayBookingFloorsLinkedToSelectedService()}

      {isDaySelectorOpen && (
        <Box mt="m">
          <AgendaMonthView
            onDayPress={handleDayPress}
            currentDate={selectedDate}
            items={dataByMonth}
            onMonthChange={onMonthChange}
            width={splitViewContext.leftContainer?.width}
          />
        </Box>
      )}
    </Box>
  );
};

export default BookingServices;
