import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { ScrollView } from "react-native";

import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import LINKS from "../../../../../../../assets/icons/BASE/LINKS.svg";
import PRICE_TAG from "../../../../../../../assets/icons/BASE/PRICE_TAG.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { ProductOptionFragment } from "../../../../../../graphql/generated/schema";
import { useGetProductOptionLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../../utils/common";

interface OptionsListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const OptionsListDetailsView = ({ id, goBack }: OptionsListDetailsViewProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState<ProductOptionFragment | null>(null);

  const [getOption] = useGetProductOptionLazyQuery();

  const handleGetOption = async () => {
    try {
      const { data } = await getOption({
        variables: {
          optionId: id,
        },
      });

      if (data?.getProductOption) {
        setOption(data.getProductOption);
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_OPTION",
            path: "ERROR_GET_OPTION",
            message: "Une erreur est survenue lors de la récupération de l'option.",
          },
        ],
        "error",
      );
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetOption();
    }, [id]),
  );

  if (loading) {
    return <Loader />;
  }

  if (!option) {
    return (
      <Box>
        <ScreenHeader
          title="Option"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération de l'option." />
      </Box>
    );
  }

  const formattedPrice = formatCurrencyPrice(option.basePrice.amount || 0, "fr", "eur");

  const displayGroupOptions = option.groupOptions
    .map(groupOption => groupOption.name)
    .join("\n");

  return (
    <Box>
      <ScreenHeader
        title={option.name}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        {option.description && (
          <TabTextInfo
            {...{
              title: "Description",
              content: option.description,
              icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
            }}
          />
        )}

        {option.basePrice.amount > 0 && (
          <TabTextInfo
            {...{
              title: "Supplément",
              content: formattedPrice,
              icon: (
                <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
              ),
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Groupes d'options",
            content: displayGroupOptions,
            icon: <LINKS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
          }}
        />
      </ScrollView>
    </Box>
  );
};

export default OptionsListDetailsView;
