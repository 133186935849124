import { createNativeStackNavigator } from "@react-navigation/native-stack";

import ReviewsList from "../../../screens/Reviews/ReviewsList";
import ReviewsListDetails from "../../../screens/Reviews/ReviewsListDetails";

import type { ReviewStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<ReviewStackParamList>();

const ReviewsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
      }}
      initialRouteName="REVIEWS_LIST"
    >
      <Stack.Screen name="REVIEWS_LIST" component={ReviewsList} />
      <Stack.Screen name="REVIEWS_LIST_DETAILS" component={ReviewsListDetails} />
    </Stack.Navigator>
  );
};

export default ReviewsStack;
