import React from "react";
import { TouchableOpacity } from "react-native";

import type { THEME_COLORS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

const HEIGHT = 40;

export interface ListingTab {
  title: string;
  value?: string;
  key: string;
  icon?: JSX.Element;
  color?: THEME_COLORS;
}

type Props = {
  tabs: ListingTab[];
  inactiveTabStyles?: React.ComponentProps<typeof Box>;
  activeTabStyles?: React.ComponentProps<typeof Box>;
  activeTabTextStyles?: React.ComponentProps<typeof CustomText>;
  inactiveTabTextStyles?: React.ComponentProps<typeof CustomText>;
  currentTabName: string;
  onSelectTab: (tabKey: string) => void;
  boxProps?: React.ComponentProps<typeof Box>;
  horizontal?: boolean;
};

export const ListingTabs = ({
  tabs,
  currentTabName,
  onSelectTab,
  boxProps,
  activeTabStyles,
  inactiveTabStyles,
  activeTabTextStyles,
  inactiveTabTextStyles,
  horizontal = true,
}: Props) => {
  const NB_OF_TABS = tabs.length;
  const TAB_WIDTH = 100 / NB_OF_TABS;
  const getBoxStyles = (tabName: string): React.ComponentProps<typeof Box> => {
    const BASE_STYLES: React.ComponentProps<typeof Box> = {
      alignItems: "center",
      justifyContent: "center",
      height: HEIGHT,
      paddingHorizontal: "s",
      // width: TAB_WIDTH + "%",
    };
    if (tabName === currentTabName) {
      return {
        ...BASE_STYLES,
        borderBottomColor: "success",
        borderLeftColor: "success",
        borderBottomWidth: horizontal ? 2 : 0,
        borderLeftWidth: !horizontal ? 2 : 0,
      };
    }
    return {
      ...BASE_STYLES,
      borderBottomColor: "disabled",
      borderBottomWidth: 2,
    };
  };

  return (
    <Box flexDirection={horizontal ? "row" : "column"} {...boxProps}>
      {tabs.map(tab => {
        const { key, title, value = null, icon } = tab;
        const IS_SELECTED = key === currentTabName;
        return (
          <TouchableOpacity
            key={key}
            onPress={() => {
              onSelectTab(key);
            }}
            style={{
              flex: horizontal ? 1 : undefined,
              height: HEIGHT,
            }}
          >
            <Box
              height={HEIGHT}
              key={key}
              width="100%"
              // flex={1}
              {...(IS_SELECTED ? activeTabStyles : inactiveTabStyles)}
              {...getBoxStyles(key)}
              flexDirection={horizontal ? "column" : "row"}
              alignItems="center"
              justifyContent={horizontal ? "center" : "space-between"}
            >
              <CustomText
                variant={IS_SELECTED ? "listActiveTab" : "listInactiveTab"}
                {...(IS_SELECTED ? activeTabTextStyles : inactiveTabTextStyles)}
                color={tab.color}
              >
                {title}
              </CustomText>
              {value && (
                <CustomText
                  pb="s"
                  variant={IS_SELECTED ? "listActiveTab" : "listInactiveTab"}
                  {...(IS_SELECTED ? activeTabTextStyles : inactiveTabTextStyles)}
                  color={tab.color}
                >
                  {value}
                </CustomText>
              )}
            </Box>
          </TouchableOpacity>
        );
      })}
    </Box>
  );
};
