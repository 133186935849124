/* eslint-disable @typescript-eslint/no-explicit-any */

import HORIZONTAL_MORE_DOTS from "../../../../assets/icons/BASE/HORIZONTAL_MORE_DOTS.svg";
import OCCURENCES from "../../../../assets/icons/BASE/OCCURENCES.svg";
import VALIDATE from "../../../../assets/icons/BASE/VALIDATE.svg";
import { Click_Status } from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

export const getOrdersStatusFilter = (
  data: any[],
  selectedFilter: string,
  selectedStatusFilters: Click_Status[],
  includeCancelledInAllFilter?: boolean,
) => {
  const HAS_SELECTED_STATUS_FILTERS = selectedStatusFilters.length > 0;

  const getAllStatusAmount = () => {
    return includeCancelledInAllFilter
      ? data.length
      : data.filter(
          item =>
            item.status !== Click_Status.CancelledByOwner &&
            item.status !== Click_Status.CancelledByUser,
        ).length;
  };

  const all = {
    label: "Tous",
    key: "ALL",
    value: getAllStatusAmount(),
  };

  const pending = {
    key: "PENDING",
    value: data.filter(
      item =>
        item.status === Click_Status.New ||
        item.status === Click_Status.ModifiedByOwner ||
        item.status === Click_Status.ModifiedByUser,
    ).length,
    icon: (
      <>
        <OCCURENCES
          height={ICON_SIZE}
          width={ICON_SIZE}
          fill={selectedFilter === "PENDING" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  const validated = {
    key: "VALIDATED",
    value: data.filter(
      item =>
        item.status === Click_Status.Validated ||
        item.status === Click_Status.AutomaticValidated,
    ).length,
    icon: (
      <>
        <VALIDATE
          height={ICON_SIZE}
          width={ICON_SIZE}
          fill={selectedFilter === "VALIDATED" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  const getOthersAmount = () => {
    if (HAS_SELECTED_STATUS_FILTERS) {
      return data.filter(d => {
        return selectedStatusFilters.includes(d.status);
      }).length;
    }

    return data.filter(
      item =>
        item.status !== Click_Status.New &&
        item.status !== Click_Status.Validated &&
        item.status !== Click_Status.AutomaticValidated &&
        item.status !== Click_Status.ModifiedByOwner &&
        item.status !== Click_Status.ModifiedByUser,
    ).length;
  };
  const others = {
    key: "OTHERS",
    value: getOthersAmount(),
    icon: (
      <>
        <HORIZONTAL_MORE_DOTS
          // height={ICON_SIZE}
          // width={ICON_SIZE}
          fill={selectedFilter === "OTHERS" ? PALETTE.white : PALETTE.darkBlue}
        />
      </>
    ),
  };

  return [all, pending, validated, others];
};
