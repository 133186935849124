const PAYMENT_METHODS = [
  "Espèces",
  "Eurocard/Mastercard",
  "Visa",
  "American Express",
  "Amex",
  "Apple Pay",
  "Google Pay",
  "Carte Bleue",
  "Cheque Gourmet",
  "Cheque Sodexo",
  "Chèques",
  "Chèques Vacances",
  "Chèques vacances digitaux",
  "Chèques Cadeaux",
  "Paiement Mobile",
  "Paiement Sans Contact",
  "Lyf",
  "Maestro",
  "Monnaie Racine",
  "Monéo",
  "Paypal",
  "Ticket Restaurant",
  "Tiers Payants",
  "Titres restaurant",
  "Titres restaurant (uniquement le midi)",
  "Virement bancaire",
];

const LABELS = [
  "1 Etoile Michelin",
  "2 Etoiles Michelin",
  "3 Etoiles Michelin",
  "Bib Gourmand",
  "Etoile Verte Michelin",
  "Fait Maison",
  "Toques Françaises",
  "Maitre Restaurateur",
];

const MERCHANT_TYPES = [
  "Traditionnel",
  "Brasserie",
  "Bistrot",
  "Bistronomique",
  "Semi Gastronomique",
  "Gastronomique",
  "Etoilé",
  "Bar Restaurant",
  "Pub Restaurant",
  "Fast Food",
  "Restaurant Traiteur",
  "Restaurant à thème",
  "Boulangerie",
  "Restauration rapide",
  "Food Truck",
];

const CHARACTERISTICS = [
  "Français",
  "Asiatique",
  "Italien",
  "Japonais",
  "Oriental",
  "Indien",
  "Thaïlandais",
  "Chinois",
  "Coréen",
  "Latino",
  "Libanais",
  "Cuisine du monde",
  "Vietnamien",
  "Méditérranéen",
  "Africain",
  "Crêperie",
  "Américain",
  "Fusion",
  "Marocain",
  "Végétarien",
  "Péruvien",
  "Argentin",
  "Fruits de mer",
  "Espagnol",
  "Sud-Ouest",
  "Hawaien",
  "Turc",
  "Cuisine des îles",
  "Grec",
  "Créole",
  "Vegan",
  "Corse",
  "Mexicain",
  "Ethiopien",
  "Brésilien",
  "Canadien",
  "Algérien",
  "Tunisien",
  "Iranien",
  "Savoyard",
  "Colombien",
  "Europe de l'Est",
  "Steakhouse",
  "Anglais",
  "Alsacien",
  "Russe",
  "Portugais",
  "Israelien",
  "Cambodgien",
  "Bouchon lyonnais",
  "Syrien",
  "Afghan",
  "Cubain",
  "Provençal",
  "Basque",
  "Pakistanais",
  "Vénézuelien",
  "Suisse",
  "Scandinave",
  "Allemand",
  "Egyptien",
];

const AMBIANCES = [
  "En famille",
  "Entre amis",
  "Repas d'affaires",
  "Romantique",
  "A la montagne",
  "Au bord de l'eau",
  "Bord de mer",
  "Sur la plage",
  "Anniversaire",
  "Communion",
  "Evénements d'entreprise",
  "Vue exceptionnelle",
  "Bar à vin, Oenothèque",
  "Restaurant d'hôtel",
  "Au vert",
  "Mariages",
  "Terrasse",
  "Bistrot",
  "Brasserie",
  "Branché",
  "Traditionnel",
  "Gastronomique",
  "Sur une péniche",
  "Cuisine contemporaine",
  "Buffet à volonté",
  "Bistronomique",
  "Enterrement de vie de garçon, jeune fille",
  "Brunch",
  "Spécial groupe",
  "Trattoria",
  "Enfants bienvenus !",
  "Cosy",
  "Estaminet",
  "Déjeuner",
  "Carte Cadeau",
];

const SPOKEN_LANGUAGES = [
  "français",
  "anglais",
  "allemand",
  "portugais",
  "espagnol",
  "italien",
  "chinois mandarin",
  "hindi /ourdou",
  "arabe",
  "bengali",
  "grec",
  "russe",
  "panjabi",
  "japonais",
  "haoussa",
  "malais",
  "coréen",
  "chinois cantonais",
  "néerlandais",
  "marathi",
  "turc",
  "vietnamien",
  "tamoul",
  "persan",
  "thaï",
  "polonais",
  "ukrainien",
  "filipin",
  "roumain",
  "kurde",
  "malgache",
  "hongrois",
  "zoulou",
  "tchèque",
  "créole",
];

export const getListByType = (
  type:
    | "spokenLanguages"
    | "characteristics"
    | "paymentMethods"
    | "labelsCertifications"
    | "merchantTypes"
    | "ambiances",
) => {
  switch (type) {
    case "spokenLanguages":
      return SPOKEN_LANGUAGES;
    case "characteristics":
      return CHARACTERISTICS;
    case "paymentMethods":
      return PAYMENT_METHODS;
    case "labelsCertifications":
      return LABELS;
    case "merchantTypes":
      return MERCHANT_TYPES;
    case "ambiances":
      return AMBIANCES;

    default:
      return [];
  }
};

export const convertMerchantCharacteristics = (label: string) => {
  switch (label) {
    case "FRENCH":
      return "Français";
    case "INDIAN":
      return "Indien";
    case "ORIENTAL":
      return "Oriental";
    case "ITALIAN":
      return "Italien";
    case "JAPANESE":
      return "Japonais";
    case "ASIAN":
      return "Asiatique";
    case "THAI":
      return "Thaïlandais";
    case "LIBANESE":
      return "Libanais";
    case "CHINESES":
      return "Chinois";
    case "INTERNATIONAL":
      return "International";
    case "LATION":
      return "Latino";
    case "KOREAN":
      return "Coréen";
    case "MEXICAN":
      return "Mexicain";
    case "MAROCAN":
      return "Marocain";
    case "FUSION":
      return "Fusion";
    case "SPANISH":
      return "Espagnol";
    case "AFRICAIN":
      return "Africain";
    case "MEDITERANEAN":
      return "Méditerranéen";
    case "AMERICAN":
      return "Américain";
    case "SOUTH_WEST":
      return "Sud-Ouest";
    case "PERUVIAN":
      return "Péruvien";
    case "ISLAND_CUISINE":
      return "Cuisine des îles";
    case "CORSICAN":
      return "Corse";
    case "GREEK":
      return "Grec";
    case "VEGETARIAN":
      return "Végétarien";
    case "CREOLE":
      return "Créole";
    case "ETHOPIAN":
      return "Éthiopien";
    case "TURKISH":
      return "Turc";
    case "BRASILIAN":
      return "Brésilien";
    case "SEAFOOD":
      return "Fruits de mer";
    case "VEGAN":
      return "Végan";
    case "CANADIAN":
      return "Canadien";
    case "ARGENTIAN":
      return "Argentin";
    case "ALGERIAN":
      return "Algérien";
    case "EAST_EUROPE":
      return "Europe de l'Est";
    case "IRANIAN":
      return "Iranien";
    case "SAVOY":
      return "Savoyard";
    case "COLOMBIAN":
      return "Colombien";
    case "STEAK_HOUS":
      return "Steak House";
    case "PORTUGUESE":
      return "Portugais";
    case "ENGLISH":
      return "Anglais";
    case "RUSSIAN":
      return "Russe";
    case "ALSATION":
      return "Alsacien";
    case "ISRAELIAN":
      return "Israélien";
    case "VIETNAMESE":
      return "Vietnamien";
    case "CAMBODIAN":
      return "Cambodgien";
    case "PAKISTINIAN":
      return "Pakistanais";
    case "SYRIAN":
      return "Syrien";
    case "PROVENCAL":
      return "Provençal";
    case "VENEZUELAN":
      return "Vénézuélien";
    case "EYGPTIAN":
      return "Égyptien";
    case "SCANDIVIAN":
      return "Scandinave";
    case "GERMAN":
      return "Allemand";
    case "SWISS":
      return "Suisse";
    case "HAIWIEN":
      return "Hawaïen";
    case "TUNISIAN":
      return "Tunisien";
    case "AFGHAN":
      return "Afghan";
    case "CUBAN":
      return "Cubain";
    case "AFHAN":
      return "Afghan";

    default:
      break;
  }
  return "";
};

export const convertMerchantAmbiances = (label: string) => {
  switch (label) {
    case "FRIENDS":
      return "Entre amis";
    case "FAMILY":
      return "En famille";
    case "BUSINESS":
      return "Repas d'affaires";
    case "ROMANTIC":
      return "Romantique";
    case "ON_THE_BEACH":
      return "Au bord de la plage";
    case "BIRTHDAY":
      return "Anniversaire";
    case "COMPANY_EVENT":
      return "Événement d'entreprise";
    case "COMMUNION":
      return "Communion";
    case "WINE_BAR":
      return "Bar à vin";
    case "EXCEPTIONNAL_VIEW":
      return "Vue exceptionnelle";
    case "MARRIAGE":
      return "Mariage";
    case "PATIO":
      return "Terrasse";
    case "TRADITIONNEL":
      return "Traditionnel";
    case "IN_THE_MOUNTAINS":
      return "À la montagne";
    case "NEAR_THE_SEA":
      return "Près de la mer";
    case "GASTRONOMIC":
      return "Gastronomique";
    case "BISTRONOMIC":
      return "Bistronomique";
    case "BUFFET":
      return "Buffet";
    case "ON_A_BARGE":
      return "Sur une péniche";
    case "CONTEMPORARY_CUISINE":
      return "Cuisine contemporaine";
    case "BACHELOR_PARTY":
      return "Enterrement de vie de garçon";
    case "BRUNCH":
      return "Brunch";
    case "LUNCH":
      return "Déjeuner";
    case "GROUP_SPECIAL":
      return "Groupe";
    case "COSY":
      return "Cosy";
    case "KID_FRIENDLY":
      return "Les enfants sont les bienvenus";
    case "GIFT_CARD":
      return "Carte cadeau";
    case "BISTROT":
      return "Bistrot";
    case "NEAR_THE_WATER":
      return "Près de l'eau";
    case "BRASSERIE":
      return "Brasserie";
    case "HOTEL_RESTAURANT":
      return "Restaurant d'hôtel";

    default:
      break;
  }
  return "";
};

export const convertMerchantLabels = (label: string) => {
  switch (label) {
    case "BIB_GOURMAND":
      return "Bib Gourmand";
    case "3_STAR_MICHELIN":
      return "3 étoiles Michelin";
    case "1_STAR_MICHELIN":
      return "1 étoile Michelin";
    case "HOME_MADE":
      return "Fait maison";
    case "GREEN_STAR_MICHELIN":
      return "Étoile verte Michelin";
    case "2_STAR_MICHELIN":
      return "2 étoiles Michelin";
    case "MASTER_RESTAURATEUR":
      return "Maître restaurateur";

    default:
      break;
  }

  return "";
};

export const convertSpokenLanguage = (label: string) => {
  switch (label) {
    case "GREEK":
      return "Grec";
    case "HAOUSSA":
      return "Haoussa";
    case "PANJABI":
      return "Panjabi";
    case "ITALIAN":
      return "Italien";
    case "FRENCH":
      return "Français";
    case "BENGALI":
      return "Bengali";
    case "ENGLISH":
      return "Anglais";
    case "MANDARIN":
      return "Mandarin";
    case "SPANISH":
      return "Espagnol";
    case "MARATHI":
      return "Marathi";
    case "MALAY":
      return "Malais";
    case "CANTONESE":
      return "Cantonais";
    case "KOREAN":
      return "Coréen";
    case "PORTUGUESE":
      return "Portugais";
    case "ARABIC":
      return "Arabe";
    case "RUSSIAN":
      return "Russe";
    case "TURKISH":
      return "Turc";
    case "HINIDI":
      return "Hindi";
    case "VIETNAMESE":
      return "Vietnamien";
    case "UKRAINIAN":
      return "Ukrainien";
    case "POLISH":
      return "Polonais";
    case "THAI":
      return "Thaï";
    case "PERSIAN":
      return "Persan";
    case "TAMAL":
      return "Tamoul";
    case "KURDISH":
      return "Kurde";
    case "DUTCH":
      return "Néerlandais";
    case "GERMAN":
      return "Allemand";
    case "FILIPINO":
      return "Philippin";
    case "ROMANIAN":
      return "Roumain";
    case "MALAGASY":
      return "Malgache";
    case "CZECH":
      return "Tchèque";
    case "CREOLE":
      return "Créole";
    case "HUNGARIAN":
      return "Hongrois";
    case "JAPANESE":
      return "Japonais";
    case "ZULU":
      return "Zoulou";

    default:
      break;
  }
  return "";
};

export const merchantTypes = (label: string) => {
  switch (label) {
    case "THEMED_RESTAURANT":
      return "Restaurant à thème";
    case "BREWERY":
      return "Brasserie";
    case "PUB_RESTAURANT":
      return "Pub restaurant";
    case "BISTRONOMIC":
      return "Bistronomique";
    case "GASTRONOMIC":
      return "Gastronomique";
    case "BISTROT":
      return "Bistrot";
    case "STARRED_RESTAURANT":
      return "Restaurant étoilé";
    case "TRADITIONNEL":
      return "Traditionnel";
    case "SEMI_GASTRONOMIC":
      return "Semi gastronomique";
    case "FAST_FOOD":
      return "Fast food";
    case "RESTAURANT_CATERING":
      return "Restaurant traiteur";
    case "BAR_RESTAURANT":
      return "Bar restaurant";
    case "FOOD_TRUCK":
      return "Food truck";
    case "BAKERY":
      return "Boulangerie";
    case "BUTCHER":
      return "Boucherie";

    default:
      break;
  }
  return "";
};

export const convertMerchantPaymentMethod = (label: string) => {
  switch (label) {
    case "VISA":
      return "Visa";
    case "CASH":
      return "Espèces";
    case "EURO_CARD":
      return "Eurocard";
    case "MASTER_CARD":
      return "Mastercard";
    case "APPLE_PAY":
      return "Apple Pay";
    case "AMEX":
      return "Amex";
    case "GIFT_CARD":
      return "Carte cadeau";
    case "HOLIDAY_CHECK":
      return "Chèque vacances";
    case "GOURMET_CHECK":
      return "Chèque Gourmet";
    case "BANK_TRANSFER":
      return "Virement bancaire";
    case "SODEXO_CHECK":
      return "Chèque Sodexo";
    case "RESTAURANT_TICKET":
      return "Ticket restaurant";
    case "AMERICAN_EXPRESS":
      return "American Express";
    case "RESTAURANT_TICKET_ONLY_LUNCH":
      return "Ticket restaurant (déjeuner uniquement)";
    case "LYF":
      return "Lyf Pay";
    case "MONNEO":
      return "Monéo";
    case "CONTACTLESS_PAYMENT":
      return "Paiement sans contact";
    case "GOOGLE_PAY":
      return "Google Pay";
    case "CHECK":
      return "Chèque";
    case "CREDIT_CARD":
      return "Carte de crédit";
    case "PAYPAL":
      return "Paypal";
    case "MAESTRO":
      return "Maestro";
    case "MOBILE_PAYMENT":
      return "Paiement mobile";
    default:
      break;
  }
  return "-";
};
