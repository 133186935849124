import { format } from "date-fns";
import { TouchableOpacity } from "react-native";

import MESSAGE from "../../../../assets/icons/BASE/MESSAGE.svg";
import MORE_DOTS from "../../../../assets/icons/BASE/MORE_DOTS.svg";
import type { QUOTE } from "../../../Data";
import {
  useUpdateInvoiceMutation,
  useUpdateQuoteMutation,
} from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { displayModuleId, formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import { CustomButton } from "../../Button";
import QuoteStatusButton from "../QuoteStatusButton";

interface QuotesListCardProps {
  item: QUOTE;
  onPress: (itemId: string) => void;
  onExtraActionPress: (itemId: string) => void;
  isForQuote: boolean;
}

const HEIGHT = 92;

const QuotesListCard = ({
  item,
  onPress,
  onExtraActionPress,
  isForQuote,
}: QuotesListCardProps) => {
  const [updateQuote] = useUpdateQuoteMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();

  const {
    _id: itemId,
    merchantClient: client,
    sentDate,
    itemQty,
    source,
    status,
    totalDetails,
  } = item;

  const formattedClientName = `${client?.firstName} ${client?.lastName}`;
  const formmatedDate = format(new Date(sentDate), "dd/MM/yyyy");

  const formatTotalWithTaxes = formatCurrencyPrice(
    totalDetails.totalWithTaxes,
    "fr",
    totalDetails.currency,
  );
  const formatTotalWithoutTaxes = formatCurrencyPrice(
    totalDetails.totalWithoutTaxes,
    "fr",
    totalDetails.currency,
  );

  const handleStatusChange = async (newStatus: number, refusalReason: string) => {
    try {
      if (isForQuote) {
        const result = await updateQuote({
          variables: {
            quoteId: itemId,
            quote: {
              status: newStatus,
              refusalReason,
            },
          },
        });
        console.log("result quote", result);
      } else {
        const result = await updateInvoice({
          variables: {
            invoiceId: itemId,
            invoice: {
              status: newStatus,
              refusalReason,
            },
          },
        });
        console.log("result invoice", result);
      }
    } catch (err) {
      console.log("err change status", isForQuote, err);
    }
  };

  return (
    <Box flexDirection="row" alignItems="center">
      <TouchableOpacity style={{ flex: 1 }} onPress={() => onPress(itemId)}>
        <Box
          style={{
            flex: 1,
            width: "100%",
            justifyContent: "center",
            minHeight: HEIGHT,
          }}
        >
          <Box flexDirection="row" justifyContent="space-between" alignItems="center">
            <BookingListCardOrigin origin={source} />

            <CustomText variant="listItemMainText">{formattedClientName}</CustomText>
            <CustomText variant="listItemSubText">{itemQty}</CustomText>
            <CustomText variant="listItemSubText" textTransform="uppercase">
              {formatTotalWithoutTaxes} HT
            </CustomText>
            <CustomText variant="listItemMainText" color="success">
              {formatTotalWithTaxes} TTC
            </CustomText>
            <QuoteStatusButton onPress={handleStatusChange} status={status} />
          </Box>
          <Box flexDirection="row" alignItems="center" pt="s">
            <BookingLIstCardClientFidelity occurences={client?.occurences} />

            <CustomText paddingHorizontal="m" variant="listItemSubText">
              {displayModuleId(itemId)}
            </CustomText>

            <Box flexDirection="row" paddingHorizontal="m" alignItems="center">
              <MESSAGE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} />

              <CustomText pl="s" variant="listItemSubText">
                Devis du {formmatedDate}
              </CustomText>
            </Box>
          </Box>
        </Box>
      </TouchableOpacity>

      <CustomButton onPress={() => onExtraActionPress(itemId)}>
        <MORE_DOTS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} />
      </CustomButton>
    </Box>
  );
};

export default QuotesListCard;
