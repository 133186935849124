import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import ErrorMessage from "../../../../components/ErrorMessage";
import InlineSelectButton from "../../../../components/InlineSelectButton";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import { AppContext } from "../../../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { DeviceNotificationsFragment } from "../../../../graphql/generated/schema";
import {
  Merchant_Notification_Options,
  useGetDeviceNotificationsLazyQuery,
  useUpdateDeviceNotificationsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface DeviceNotificationsProps {
  goBack?: () => void;
}

const OPTIONS = [
  {
    key: Merchant_Notification_Options.PushNotification,
    label: "P",
  },
  {
    key: Merchant_Notification_Options.InternalNotification,
    label: "I",
  },
  // {
  //   key: Merchant_Notification_Options.Email,
  //   label: "E",
  // },
  // {
  //   key: Merchant_Notification_Options.Sms,
  //   label: "S",
  // },
];

interface Notification {
  title: string;
  description: string;
  options: typeof OPTIONS;
  key: keyof DeviceNotificationsFragment;
}

const CALL_NOTIFICATIONS: Notification[] = [
  {
    title: "Appel entrant",
    description: "Notification à chaque fois qu'un client appelle votre établissement",
    options: OPTIONS,
    key: "incomingCall",
  },
  {
    title: "Boitier déconnecté",
    description: "Notification lorsque votre boitier est déconnecté",
    options: OPTIONS,
    key: "disconnectedCallBox",
  },
];

const DeviceNotifications = ({ goBack }: DeviceNotificationsProps) => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<DeviceNotificationsFragment | undefined>(
    undefined,
  );

  const [getDeviceSettings] = useGetDeviceNotificationsLazyQuery();
  const [updateDeviceSettings] = useUpdateDeviceNotificationsMutation();

  const deviceId = appContext?.deviceToken;

  const handleGetDeviceSettings = async () => {
    try {
      const { data, error } = await getDeviceSettings({
        variables: {
          deviceId,
        },
      });

      console.log("data", data, error);

      if (data?.getDeviceNotifications) {
        setSettings(data.getDeviceNotifications);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDeviceSettings();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!settings) return;

      if (!deviceId) return;

      const { incomingCall, disconnectedCallBox } = settings;

      const updates = removeTypeNames({
        incomingCall,
        disconnectedCallBox,
      });

      const { data } = await updateDeviceSettings({
        variables: {
          deviceId,
          updates,
        },
      });

      if (data?.updateDeviceNotifications) {
        if (goBack) goBack();
        else navigation.goBack();
      }
    } catch (err) {
      console.log("err updateing device settings", err);

      infoAlert.openAlert(
        "Une erreur est survenue",
        [
          {
            code: "ERROR",
            path: "ERROR",

            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
    }
  };

  const getSelectedOptions = (key: keyof DeviceNotificationsFragment) => {
    if (settings) {
      return settings[key];
    }

    return [];
  };

  const updateNotifications = (
    key: keyof DeviceNotificationsFragment,
    options: Merchant_Notification_Options[],
  ) => {
    if (!settings) return;

    setSettings({
      ...settings,
      [key]: options,
    });
  };

  if (loading) return <Loader />;

  if (!settings)
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <ScreenHeader
          title="Périphérique"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue" />
      </Box>
    );

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScreenHeader
        title="Périphérique"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView showsVerticalScrollIndicator={false}>
        <Box marginVertical="m">
          <CustomText variant="content" color="lightGrey">
            Vous pouvez choisir le type de notifications en fonction de votre utilisation.{" "}
            {"\n"}P = Notification PUSH {"\n"}I = Notification INTERNE {"\n"}E =
            Notification par EMAIL {"\n"}S = Notification par SMS {"\n"}
          </CustomText>
        </Box>

        <Box>
          <CustomText variant="content" color="success" textTransform="uppercase">
            Appel téléphonique
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={CALL_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>
      </ScrollView>

      <Box
        position={"absolute"}
        bottom={0}
        left={0}
        right={0}
        paddingHorizontal="s"
        paddingBottom="l"
        p="m"
        backgroundColor="white"
      >
        <CustomButton
          buttonColor="success"
          buttonVariant="primaryButton"
          onPress={handleSubmit}
        >
          <CustomText variant="primaryButtonText" color="white">
            Valider
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DeviceNotifications;
