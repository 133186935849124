import { Switch } from "react-native";

import { PALETTE } from "../../theme/Palette";

interface Props {
  disabled?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
}

const Toggle = ({ disabled = false, onChange, value }: Props) => {
  return (
    // <Touchable disabled={disabled} onPress={() => onChange(!value)}>
    <Switch
      disabled={disabled}
      thumbColor={PALETTE.white}
      trackColor={{ false: PALETTE.grey, true: PALETTE.green }}
      value={value}
      onValueChange={onChange}
    />
    // {/* </Touchable> */}
  );
};

export default Toggle;
