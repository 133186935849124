/* eslint-disable max-len */

import { useNavigation } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import type { ListingTab } from "../../../../../components/ListingTabs";
import { ListingTabs } from "../../../../../components/ListingTabs";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SplitView from "../../../../../components/SplitView";
import AddSubscriptionCommitmentList from "../../../../../components/Subscriptions/AddSubscription/Tabs/AddSubscriptionCommitmentList";
import AddSubscriptionListSelect from "../../../../../components/Subscriptions/AddSubscription/Tabs/AddSubscriptionListSelect";
import AddSubscriptionOptionsListSelect from "../../../../../components/Subscriptions/AddSubscription/Tabs/AddSubscriptionOptionsListSelect";
import AddSubscriptionPaymentInterval from "../../../../../components/Subscriptions/AddSubscription/Tabs/AddSubscriptionPaymentInterval";
import { AppContext } from "../../../../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { SubscriptionFragment } from "../../../../../graphql/generated/schema";
import {
  Subscription_Interval_Type,
  useAddMerchantSubscriptionTransactionsMutation,
  useGetMerchantSubscriptionTransactionsLazyQuery,
} from "../../../../../graphql/generated/schema";
import { formatCurrencyPrice } from "../../../../../utils/common";

const TABS: ListingTab[] = [
  {
    title: "Abonnement",
    value: "",
    key: "SUBSCRIPTION",
  },
  {
    title: "Options",
    value: "",
    key: "SUBSCRIPTION_OPTIONS",
  },
  {
    title: "Engagement",
    value: "",
    key: "COMMITMENT",
  },
  {
    title: "Echéance",
    value: "",
    key: "PAYMENT_INTERVAL",
  },
];

const AddSubscription = () => {
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [listTabs, setListTabs] = useState(TABS);
  const [currentTab, setCurrentTab] = useState(TABS[0].key);
  const appContext = useContext(AppContext);
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  const [merchantSubscriptionTransactionIds, setMerchantSubscriptionTransactionIds] =
    useState<string[]>([]);
  const [selectedSubscriptions, setselectedSubscriptions] = useState<
    SubscriptionFragment[]
  >([]);
  const [selectedSubscriptionOptions, setSelectedSubscriptionOptions] = useState<
    SubscriptionFragment[]
  >([]);
  const [commitment, setCommitment] = useState(1);
  const [paymentInterval, setPaymentInterval] = useState<Subscription_Interval_Type>(
    Subscription_Interval_Type.Monthly,
  );
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const [getSubscriptionTransactions] = useGetMerchantSubscriptionTransactionsLazyQuery();
  const [addSubscription] = useAddMerchantSubscriptionTransactionsMutation();

  const handleGetSubscriptionTransactions = async () => {
    try {
      const { data } = await getSubscriptionTransactions();

      if (data) {
        const transactions = data.getMerchantSubscriptionTransactions;

        const transactionsSubscriptionIds = transactions
          .map(tr => {
            const subscriptionId = tr.subscription._id;

            const subscriptionOptionsIds = tr.options.map(o => o.subscription._id);

            return [subscriptionId, ...subscriptionOptionsIds];
          })
          .flat();

        setMerchantSubscriptionTransactionIds(transactionsSubscriptionIds);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSubscriptionTransactions();
  }, []);

  const getListTabs = () => {
    const IS_SUBSCRIPTION_SELECTED = selectedSubscriptions.length > 0;
    const IS_SUBSCRIPTION_OPTION_SELECTED = selectedSubscriptionOptions.length > 0;

    const SHOW_SUBSCRIPTION_ADVANCED_DETAILS =
      IS_SUBSCRIPTION_SELECTED || IS_SUBSCRIPTION_OPTION_SELECTED;

    const newTabs = listTabs.map(tab => {
      if (tab.key === "SUBSCRIPTION") {
        return {
          ...tab,
          value: selectedSubscriptions.length > 0 ? "Oui" : "",
        };
      }
      if (tab.key === "SUBSCRIPTION_OPTIONS") {
        return {
          ...tab,
          value: selectedSubscriptionOptions.length > 0 ? "Oui" : "",
        };
      }
      if (tab.key === "COMMITMENT") {
        return {
          ...tab,
          value: SHOW_SUBSCRIPTION_ADVANCED_DETAILS && commitment.toString() + " mois",
        };
      }

      if (tab.key === "PAYMENT_INTERVAL") {
        const formattedPaymentInterval =
          paymentInterval === Subscription_Interval_Type.Monthly ? "Mensuel" : "Annuel";
        return {
          ...tab,
          value:
            SHOW_SUBSCRIPTION_ADVANCED_DETAILS && paymentInterval
              ? formattedPaymentInterval
              : "",
        };
      }
      return tab;
    });

    return newTabs;
  };

  const isSwitchToNextTab = (nexTab?: ListingTab) => {
    if (!nexTab) return false;

    const HAS_SELECTED_SUBSCRIPTION_OR_OPTION =
      selectedSubscriptions.length > 0 || selectedSubscriptionOptions.length > 0;

    if (currentTab === "SUBSCRIPTION" && nexTab.key === "SUBSCRIPTION_OPTIONS") {
      return true;
    } else if (
      currentTab === "SUBSCRIPTION_OPTIONS" &&
      ((nexTab.key === "COMMITMENT" && HAS_SELECTED_SUBSCRIPTION_OR_OPTION) ||
        nexTab.key === "SUBSCRIPTION")
    ) {
      return true;
    } else if (
      currentTab === "COMMITMENT" &&
      (nexTab.key === "PAYMENT_INTERVAL" || nexTab.key === "SUBSCRIPTION_OPTIONS")
    ) {
      return true;
    }
  };

  const handleTabSwitch = (tab: string) => {
    const nextTab = getListTabs().find(t => t.key === tab);

    const isToNextToTab = isSwitchToNextTab(nextTab);

    if (nextTab?.value || isToNextToTab) {
      setCurrentTab(tab);
    }
  };

  const hasCompleteSubscriptionBeenSelected = () => {
    return selectedSubscriptions.some(s => s.isCompleteSubscription);
  };

  const handleAddSubscription = (subscription: SubscriptionFragment) => {
    if (subscription.isCompleteSubscription) {
      setselectedSubscriptions([subscription]);
      return;
    }
    const isSubscriptionSelected = selectedSubscriptions.find(
      s => s._id === subscription._id,
    );

    const removedCompleteSubscription = selectedSubscriptions.filter(
      s => !s.isCompleteSubscription,
    );

    if (isSubscriptionSelected) {
      const newSelectedSubscriptions = removedCompleteSubscription.filter(
        s => s._id !== subscription._id,
      );
      setselectedSubscriptions(newSelectedSubscriptions);
    } else {
      setselectedSubscriptions([...removedCompleteSubscription, subscription]);
    }
  };

  const handleAddSubscriptionOptions = (option: SubscriptionFragment) => {
    const isOptionSelected = selectedSubscriptionOptions.find(s => s._id === option._id);

    if (isOptionSelected) {
      const newSelectedOptions = selectedSubscriptionOptions.filter(
        s => s._id !== option._id,
      );
      setSelectedSubscriptionOptions(newSelectedOptions);
    } else {
      setSelectedSubscriptionOptions([...selectedSubscriptionOptions, option]);
    }
  };

  const handleAddCommitment = (cmt: number) => {
    setCommitment(cmt);

    if (cmt === 1) {
      setPaymentInterval(Subscription_Interval_Type.Monthly);
    }
  };

  const handleAddPaymentInterval = (interval: Subscription_Interval_Type) => {
    setPaymentInterval(interval);
  };

  const handleGoNextTab = () => {
    const current = listTabs.findIndex(t => t.key === currentTab);

    if (current === listTabs.length - 1) return;

    const nextTab = listTabs[current + 1];

    const isToNextToTab = isSwitchToNextTab(nextTab);

    if (nextTab?.value || isToNextToTab) {
      setCurrentTab(nextTab.key);
    }
  };

  const isLastTab = () => {
    const current = listTabs.findIndex(t => t.key === currentTab);

    return current === listTabs.length - 1;
  };

  const isDisabledNextButton = () => {
    if (isPaymentInProgress) return true;

    if (currentTab === "SUBSCRIPTION_OPTIONS") {
      return (
        selectedSubscriptions.length === 0 && selectedSubscriptionOptions.length === 0
      );
    }

    if (currentTab === "COMMITMENT") {
      return (
        commitment === 0 &&
        selectedSubscriptions.length === 0 &&
        selectedSubscriptionOptions.length === 0
      );
    } else if (currentTab === "PAYMENT_INTERVAL") {
      return !paymentInterval;
    }
    return false;
  };

  const handleAddNewSubscriptionAndOptions = async () => {
    setIsPaymentInProgress(true);
    try {
      const selectedSubscriptionIds = selectedSubscriptions.map(s => s._id);
      const selectedSubscriptionOptionsIds = selectedSubscriptionOptions.map(s => s._id);

      const { data } = await addSubscription({
        variables: {
          merchantSubscriptionTransaction: {
            commitment,
            paymentInterval,
            merchantSubscriptionIds: selectedSubscriptionIds,
            merchantSubscriptionOptionIds: selectedSubscriptionOptionsIds,
          },
        },
      });

      if (data?.addMerchantSubscriptionTransactions) {
        setIsComplete(true);
        if (Platform.OS === "web") {
          navigation.goBack();
        } else {
          WebBrowser.dismissBrowser();
        }
      }
    } catch (err) {
      setIsPaymentInProgress(false);
      console.log("ERR ADD NEW SUBSCRIPTION AND OPTIONS: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_ADD_NEW_SUBSCRIPTION_AND_OPTIONS",
            message: "Une erreur est survenue lors de l'ajout de l'abonnement",
          },
        ],
        "error",
      );
    } finally {
      appContext.refreshMerchantPermissions();
    }
  };

  const handleSubmitSubscription = async () => {
    if (isLastTab()) {
      handleAddNewSubscriptionAndOptions();
    } else {
      handleGoNextTab();
    }
  };

  const getDiscountAmount = () => {
    if (commitment === 12) {
      return 5;
    }
    if (commitment === 24) {
      return 10;
    }
    if (commitment >= 36) {
      return 15;
    }
    return 0;
  };

  const calculatePercentageOfAmount = (amount: number, percentage: number) =>
    (amount * percentage) / 100;

  const removePercentageAmount = (amount: number, percentage: number) => {
    const percentageAmount = calculatePercentageOfAmount(amount, percentage);

    return amount - percentageAmount;
  };

  const getTotalAmount = () => {
    const subscriptionsAmount = selectedSubscriptions.reduce((acc, sub) => {
      const subPrice =
        sub.prices.find(p => p.paymentInterval === paymentInterval)?.price || 0;
      return acc + subPrice;
    }, 0);

    const optionsAmount = selectedSubscriptionOptions.reduce((acc, sub) => {
      const subPrice =
        sub.prices.find(p => p.paymentInterval === paymentInterval)?.price || 0;
      return acc + subPrice;
    }, 0);

    const totalAmomunt = subscriptionsAmount + optionsAmount;

    const discountPercentage = getDiscountAmount();

    const consolidatedAmount =
      discountPercentage > 0
        ? removePercentageAmount(totalAmomunt, discountPercentage)
        : totalAmomunt;

    return consolidatedAmount;
  };

  const displayCompletedSubscription = () => {
    return (
      <Box
        flex={1}
        backgroundColor="white"
        pt="m"
        alignItems="center"
        justifyContent="center"
      >
        <CustomText variant="label" color="primaryTextColor">
          Abonnement confirmé avec succés
        </CustomText>
      </Box>
    );
  };

  const displayTabContent = () => {
    switch (currentTab) {
      case "SUBSCRIPTION":
        return (
          <AddSubscriptionListSelect
            transactionSubscriptionIds={merchantSubscriptionTransactionIds}
            onSelectSubscription={handleAddSubscription}
            selectedSubscriptions={selectedSubscriptions}
          />
        );
      case "SUBSCRIPTION_OPTIONS":
        return (
          <AddSubscriptionOptionsListSelect
            transactionSubscriptionIds={merchantSubscriptionTransactionIds}
            selectedSubscriptionOptions={selectedSubscriptionOptions}
            onSelectSubscriptionOption={handleAddSubscriptionOptions}
            isCompleteSubscriptionSelected={hasCompleteSubscriptionBeenSelected()}
          />
        );
      case "COMMITMENT":
        return (
          <AddSubscriptionCommitmentList
            commitment={commitment}
            onSelectCommitment={handleAddCommitment}
          />
        );
      case "PAYMENT_INTERVAL":
        return (
          <AddSubscriptionPaymentInterval
            interval={paymentInterval}
            commitment={commitment}
            onSelectInterval={handleAddPaymentInterval}
          />
        );

      default:
        break;
    }
  };

  const displayNonSplittableContent = () => {
    if (appContext.isSplittable) return;

    return displayContent();
  };

  const displayDetailedTotalSummary = () => {
    if (!isLastTab()) return;

    const SERVICE_TAX = 20;

    let totalAmount = getTotalAmount();
    totalAmount = totalAmount + (totalAmount * SERVICE_TAX) / 100;

    const totalAmountWithoutTaxes = totalAmount / (1 + 0.2);
    const totalTaxAmount = totalAmount - totalAmountWithoutTaxes;

    return (
      <Box borderTopColor="disabled" borderTopWidth={1} pt="s" mb="s">
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <CustomText variant="label">Total HT</CustomText>
          <CustomText variant="content">
            {formatCurrencyPrice(totalAmountWithoutTaxes, "fr", "eur")}
          </CustomText>
        </Box>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <CustomText variant="label">TVA</CustomText>
          <CustomText variant="content">
            {formatCurrencyPrice(totalTaxAmount, "fr", "eur")}
          </CustomText>
        </Box>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <CustomText variant="content">TOTAL TTC</CustomText>
          <CustomText variant="label">
            {formatCurrencyPrice(totalAmount, "fr", "eur")}
          </CustomText>
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    const totalAmount = getTotalAmount();

    const IS_FINAL_TAB = currentTab === "PAYMENT_INTERVAL";
    const mainButtonContent = IS_FINAL_TAB ? "Valider" : "Suivant";

    const suffix =
      totalAmount > 0 ? `(${formatCurrencyPrice(totalAmount, "fr", "eur")})` : "";

    const title = IS_FINAL_TAB
      ? mainButtonContent
      : `${mainButtonContent} ${suffix}`.trim();

    if (isComplete) {
      return displayCompletedSubscription();
    }

    return (
      <Box flex={1} backgroundColor="white" pt="m">
        {displayTabContent()}

        <Box
          justifyContent="center"
          p="s"
          pb="l"
          backgroundColor="white"
          position="absolute"
          bottom={0}
          left={0}
          right={0}
        >
          {displayDetailedTotalSummary()}

          <CustomButton
            buttonVariant="primaryButton"
            buttonColor={isDisabledNextButton() ? "lightGrey" : "success"}
            onPress={handleSubmitSubscription}
            disabled={isDisabledNextButton()}
          >
            <CustomText variant="primaryButtonText" color="white">
              {title}
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    );
  };

  const displayRightComponent = () => {
    if (!appContext.isSplittable) return;

    return displayContent();
  };

  if (loading) return <Loader />;

  return (
    <SplitView
      leftComponent={
        <Box flex={1} paddingHorizontal="s" pt="m" backgroundColor="white">
          <Box mb="s">
            <ScreenHeader
              title="Ajouter un abonnement"
              hasBackButton
              onBackPress={navigation.goBack}
            />
          </Box>

          <Box mb="s">
            <ListingTabs
              tabs={getListTabs()}
              horizontal={!appContext.isSplittable}
              currentTabName={currentTab}
              onSelectTab={handleTabSwitch}
              activeTabTextStyles={{
                variant: "text",
              }}
              inactiveTabTextStyles={{
                variant: "text",
              }}
            />
          </Box>

          {displayNonSplittableContent()}
        </Box>
      }
      rightComponent={displayRightComponent()}
    />
  );
};

export default AddSubscription;
