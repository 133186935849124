import { useNavigation } from "@react-navigation/native";
import { ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";

import PLUS from "../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import InlineSelectButton from "../../../components/InlineSelectButton";
import ScreenHeader from "../../../components/ScreenHeader";
import { PALETTE } from "../../../theme/Palette";

interface DashbaordSettingsProps {
  goBack?: () => void;
}

const OPTIONS = [
  {
    key: "S",
    label: "S",
  },
  {
    key: "M",
    label: "M",
  },
  {
    key: "L",
    label: "L",
  },
];

const modules = [
  {
    title: "Statistiques",
    options: OPTIONS,
  },
  {
    title: "Réservation",
    options: OPTIONS,
  },
  {
    title: "Calendrier",
    options: OPTIONS,
  },
  {
    title: "Overview",
    options: OPTIONS,
  },
  {
    title: "Notes & avis",
    options: OPTIONS,
  },
];

const DashbaordSettings = ({ goBack }: DashbaordSettingsProps) => {
  const navigation = useNavigation();
  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <ScreenHeader
          title="Dashboard"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <Box mt="m">
          <CustomText mb="s" variant="content" color="success" textTransform="uppercase">
            Vos Modules
          </CustomText>

          <DraggableFlatList
            scrollEnabled={false}
            data={modules}
            style={{ width: "100%" }}
            containerStyle={{ width: "100%" }}
            renderItem={({ item, drag }) => (
              <TouchableOpacity
                // onPress={handleGoToBookingFloor}
                onPressIn={drag}
                style={{
                  marginVertical: 8,
                  marginRight: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box flex={0.95}>
                  <InlineSelectButton
                    title={item.title}
                    options={item.options}
                    selectedOptionKeys={[]}
                    isMultiSelect={false}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onPress={() => {}}
                    isDraggable
                  />
                </Box>

                <TouchableOpacity>
                  <TRASH fill={PALETTE.red} width={15} height={15} />
                </TouchableOpacity>
              </TouchableOpacity>
            )}
            keyExtractor={(item, index) => `draggable-item-${index}`}
            onDragEnd={({ data: sortedData }) => {
              console.log("data", sortedData);
            }}
          />
        </Box>

        <Box mt="m">
          <CustomText mb="s" variant="content" color="success" textTransform="uppercase">
            modules disponibles
          </CustomText>

          <DraggableFlatList
            scrollEnabled={false}
            data={modules}
            style={{ width: "100%" }}
            containerStyle={{ width: "100%" }}
            renderItem={({ item, drag }) => (
              <TouchableOpacity
                // onPress={handleGoToBookingFloor}
                onPressIn={drag}
                style={{
                  marginVertical: 8,
                  marginRight: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box flex={0.95}>
                  <InlineSelectButton
                    title={item.title}
                    options={item.options}
                    selectedOptionKeys={[]}
                    isMultiSelect={false}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onPress={() => {}}
                    isDraggable
                  />
                </Box>

                <TouchableOpacity>
                  <PLUS fill={PALETTE.green} width={15} height={15} />
                </TouchableOpacity>
              </TouchableOpacity>
            )}
            keyExtractor={(item, index) => `draggable-item-${index}`}
            onDragEnd={({ data: sortedData }) => {
              console.log("data", sortedData);
            }}
          />
        </Box>
      </ScrollView>
    </Box>
  );
};

export default DashbaordSettings;
