import { TouchableOpacity } from "react-native";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import RadioSelectButton from "../RadioSelectButton";
import Toggle from "../Toggle";

interface ModuleFormInputLineProps {
  text: string;
  isRequired: boolean;
  isOptional: boolean;
  isEnabled: boolean;
  onValueChange: (value: boolean) => void;
  onRequiredChange: (value: boolean) => void;
}

const ModuleFormInputLine = ({
  text,
  isOptional,
  isRequired,
  isEnabled,
  onValueChange,
  onRequiredChange,
}: ModuleFormInputLineProps) => {
  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between">
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mr="s"
      >
        <CustomText variant="content" color="primaryTextColor">
          {text}
        </CustomText>
        {isOptional && <Toggle value={isEnabled} onChange={onValueChange} />}
      </Box>
      <TouchableOpacity onPress={() => onRequiredChange(!isRequired)}>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <RadioSelectButton isSelected={isRequired} />
          <CustomText
            ml="s"
            variant="content"
            color={isRequired ? "primaryTextColor" : "lightGrey"}
          >
            Obligatoire
          </CustomText>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default ModuleFormInputLine;
