import React from "react";
import { Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import DRAGGABLE from "../../../assets/icons/BASE/DRAGGABLE.svg";
import PRICE_TAG from "../../../assets/icons/BASE/PRICE_TAG.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { formatCurrencyPrice } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import Toggle from "../Toggle";

interface ProductMenuCardProps {
  name: string;
  slug: string;
  image: string;
  isEnabled: boolean;
  updateEnabled: (isEnabled: boolean) => void;
  price: number;
  color: string;
  isSelected: boolean;
  isDraggable?: boolean;
  showEnabledToggle?: boolean;
  onPressIn?: () => void;
}

const IMAGE_HEIGHT = 47;
const CARD_HEIGHT = 140;

const ProductMenuCard = ({
  name,
  slug,
  image,
  isEnabled,
  updateEnabled,
  price,
  color,
  isSelected,
  isDraggable = true,
  showEnabledToggle = true,
  onPressIn,
}: ProductMenuCardProps) => {
  const formattedPrice = formatCurrencyPrice(price, "fr", "eur");

  return (
    <Box
      p="s"
      borderColor={isSelected ? "success" : "disabled"}
      backgroundColor={isSelected ? "white" : "disabled"}
      borderRadius="button"
      borderWidth={LINE_THICKNESS}
      height={CARD_HEIGHT}
      flexDirection="row"
      alignItems="flex-start"
    >
      {isDraggable && (
        <TouchableOpacity onPressIn={onPressIn}>
          <Box height={CARD_HEIGHT} paddingHorizontal="xs">
            <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
          </Box>
        </TouchableOpacity>
      )}
      <Box ml="s" flex={1}>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="label" color="primaryTextColor" mb="s">
            {name} ({slug})
          </CustomText>

          {image && (
            <Image
              source={{
                uri: image,
              }}
              style={{
                width: IMAGE_HEIGHT,
                height: IMAGE_HEIGHT,
                position: "absolute",
                right: 0,
                top: -18,
                borderRadius: IMAGE_HEIGHT / 2,
              }}
            />
          )}
        </Box>

        <Box flexDirection="row" alignItems="center" mb="s">
          {showEnabledToggle && (
            <Box flexDirection="row" alignItems="center" pr="s">
              <Toggle value={isEnabled} onChange={updateEnabled} />

              <CustomText variant="content" pl="s" color="primaryTextColor">
                Activé
              </CustomText>
            </Box>
          )}

          <Box
            marginHorizontal="s"
            borderRadius="button"
            style={{
              height: 20,
              width: 20,
              backgroundColor: color,
            }}
          />
        </Box>

        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box
            flexDirection="row"
            alignItems="center"
            backgroundColor={isSelected ? "disabled" : "white"}
            borderRadius="button"
            p="s"
          >
            <PRICE_TAG height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="label" color="primaryTextColor">
              {formattedPrice}
            </CustomText>
          </Box>
          {/* <Box
            ml="m"
            flexDirection="row"
            alignItems="center"
            backgroundColor={isSelected ? "disabled" : "white"}
            borderRadius="button"
            p="s"
          >
            <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="label" color="primaryTextColor">
              {nbSteps}
            </CustomText>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductMenuCard;
