import { useState } from "react";
import { TouchableOpacity } from "react-native";

import type { QUOTE_STATUS } from "../../../types";
import Box from "../../Base/Box";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../BookingList/BookingListCardStatusButtonItem";
import QuoteStatusButtonItem from "../QuoteStatusButtonItem";

import QuoteStatusListModal from "./QuoteStatusListModal";

interface QuoteStatusButtonProps {
  status: QUOTE_STATUS;
  isDisabled?: boolean;
  type?: BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE;
  onPress: (status: QUOTE_STATUS, refualReason: string) => void;
}

const QuoteStatusButton = ({
  type = BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON,
  isDisabled = false,
  onPress,
  status,
}: QuoteStatusButtonProps) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const handlePress = () => {
    setIsStatusModalOpen(true);
  };

  const handleStatusChange = (newStatus: QUOTE_STATUS, refualReason: string) => {
    setIsStatusModalOpen(false);
    onPress(newStatus, refualReason);
  };

  return (
    <Box>
      <TouchableOpacity onPress={handlePress}>
        <QuoteStatusButtonItem status={status} type={type} />
      </TouchableOpacity>
      <QuoteStatusListModal
        showAllStatuses={type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
        isVisible={!isDisabled && isStatusModalOpen}
        status={status}
        onClose={() => setIsStatusModalOpen(false)}
        onPress={handleStatusChange}
      />
    </Box>
  );
};

export default QuoteStatusButton;
