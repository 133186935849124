import { FlatList } from "react-native";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

import type { BUTTON_ACTION } from ".";

interface ButtonActionsListModalProps {
  actions: BUTTON_ACTION[];
  onPress: (key: string) => void;
}

const ButtonActionsListModal = ({ actions, onPress }: ButtonActionsListModalProps) => {
  return (
    <FlatList
      scrollEnabled={false}
      data={actions}
      ListEmptyComponent={
        <Box alignItems="center" justifyContent="center">
          <CustomText variant="content" color="primaryTextColor">
            Aucune action possible
          </CustomText>
        </Box>
      }
      renderItem={({ item }) => (
        <Box mb="s">
          <CustomButton
            buttonVariant={item.variant === "primary" ? "primaryButton" : "outlineButton"}
            borderColor={item.color}
            buttonColor={item.variant === "primary" ? item.color : "transparent"}
            onPress={() => onPress(item.key)}
          >
            <CustomText
              variant="outlineButtonText"
              color={item.variant === "primary" ? "white" : item.color}
            >
              {item.title}
            </CustomText>
          </CustomButton>
        </Box>
      )}
    />
  );
};

export default ButtonActionsListModal;
