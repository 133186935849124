import type { ImageInfo } from "expo-image-picker";
import React, { useMemo, useReducer, useState } from "react";
import type { LayoutRectangle } from "react-native";
import { FlatList, Image, TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import PLUS from "../../../assets/icons/BASE/PLUS.svg";
import { useSavePictureMutation } from "../../graphql/generated/schema";
import theme, { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import AddNewImageModal from "../Media/AddNewImageMedia";

interface MerchantCarouselPickerProps {
  setFocus: (image: string) => void;
  mainImage: string;
  otherImages: string[];
  maxImages: number;
  onAddMainImage: (imageData?: string) => void;
  onRemoveMainImage: (imageData?: string) => void;
  onAddImage: (imageData?: string) => void;
  onRemoveImage: (imageData?: string) => void;
}

type ReducerStateLayout =
  | {
      layout: null;
      cols: null;
      secondaryImageSide: null;
      primaryImageSide: null;
      sideStart: null;
      sideEnd: null;
    }
  | {
      layout: LayoutRectangle;
      cols: number;
      secondaryImageSide: number;
      primaryImageSide: number;
      sideStart: number;
      sideEnd: number;
    };

export type LayoutReducerPayload = { action: "ON_LAYOUT"; value: LayoutRectangle };

const DEFAULT_COLS_NUMBER = 4;
const SMALL_IMAGE_SIDE_MINIMAL = 96;
const IMAGE_MARGIN = 8;
const SMALL_IMAGE_SIDE_MINIMAL_WITH_MARGIN = SMALL_IMAGE_SIDE_MINIMAL + IMAGE_MARGIN;
const initialReducerStateLayout = {
  layout: null,
  cols: null,
  secondaryImageSide: null,
  primaryImageSide: null,
  sideStart: null,
  sideEnd: null,
};

const MerchantCarouselPicker = ({
  setFocus,
  mainImage,
  otherImages,
  maxImages,
  onAddImage,
  onRemoveImage,
  onAddMainImage,
  onRemoveMainImage,
}: MerchantCarouselPickerProps) => {
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const [isCoverImagePickerOpen, setIsCoverImagePickerOpen] = useState(false);
  const [savePicture] = useSavePictureMutation();

  const layoutReducer = (
    state: ReducerStateLayout,
    payload: LayoutReducerPayload,
  ): ReducerStateLayout => {
    switch (payload.action) {
      case "ON_LAYOUT":
        const cols = Math.floor(
          payload.value.width / SMALL_IMAGE_SIDE_MINIMAL_WITH_MARGIN,
        );
        const secondaryImageSide = payload.value.width / cols - 8;
        const primaryImageSide = secondaryImageSide * 2 + 8;
        return {
          layout: payload.value,
          cols: cols,
          secondaryImageSide: secondaryImageSide,
          primaryImageSide: primaryImageSide,
          sideStart: 0,
          sideEnd: (cols - 2) * 2 - 1,
        };
        break;
      default:
        return { ...state };
        break;
    }
  };

  const [reducerStateLayout, dispatchLayout] = useReducer(
    layoutReducer,
    initialReducerStateLayout as ReducerStateLayout,
    () => initialReducerStateLayout as ReducerStateLayout,
  );

  const handleAddImage = async (image?: ImageInfo) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      onAddImage(data?.savePicture);
    } catch (err) {}
  };
  const handleAddCoverImage = async (image?: ImageInfo) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture, shouldResize: false } });

      onAddMainImage(data?.savePicture);
    } catch (err) {}
  };

  const addPictureButton = useMemo(() => {
    return (
      <CustomButton
        styles={{
          marginRight: "s",
          marginBottom: "s",
          minHeight: reducerStateLayout.secondaryImageSide,
          minWidth: reducerStateLayout.secondaryImageSide,
        }}
        buttonVariant="outlineButton"
        borderColor="success"
        onPress={() => setIsImagePickerOpen(true)}
      >
        <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        {otherImages.length == 0 ? (
          <CustomText variant="title" color="success" p={"m"}>
            Ajouter une photo
          </CustomText>
        ) : (
          <></>
        )}
      </CustomButton>
    );
  }, [setIsImagePickerOpen, otherImages, reducerStateLayout.secondaryImageSide]);

  const addCoverPictureButton = useMemo(() => {
    return (
      <CustomButton
        styles={{
          marginRight: "s",
          marginBottom: "s",
          minHeight: reducerStateLayout.primaryImageSide,
          minWidth: reducerStateLayout.primaryImageSide,
        }}
        buttonVariant="outlineButton"
        borderColor="success"
        onPress={() => setIsCoverImagePickerOpen(true)}
      >
        <PLUS width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        {otherImages.length == 0 ? (
          <CustomText variant="title" color="success" p={"m"}>
            Ajouter une photo
          </CustomText>
        ) : (
          <></>
        )}
        <Box
          style={{
            backgroundColor: theme.colors.primaryButton,
            position: "absolute",
            bottom: 8,
            right: 8,
            height: 26,
            borderRadius: 4,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomText
            style={{
              color: "#fff",
              fontSize: 13,
              marginHorizontal: 8,
            }}
            variant={"content"}
          >
            Photo de couverture
          </CustomText>
        </Box>
      </CustomButton>
    );
  }, [setIsCoverImagePickerOpen, mainImage, reducerStateLayout.primaryImageSide]);

  const displayMainImage = () => {
    if (!mainImage) {
      return addCoverPictureButton;
    }
    return (
      <Box
        mr={"s"}
        height={reducerStateLayout.primaryImageSide}
        width={reducerStateLayout.primaryImageSide}
        borderRadius="button"
      >
        <TouchableOpacity
          style={{
            zIndex: 1,
            flex: 1,
          }}
          onPress={() => onRemoveMainImage(mainImage)}
        >
          <Box
            position="absolute"
            right={-4}
            top={-6}
            backgroundColor="danger"
            height={30}
            width={30}
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
          >
            <CustomText variant="content" color="white">
              X
            </CustomText>
          </Box>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: "100%", height: "100%" }}
          onPress={() => setFocus(mainImage)}
        >
          <Image
            source={{
              uri: mainImage,
            }}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 8,
            }}
          />
        </TouchableOpacity>
        <Box
          style={{
            backgroundColor: theme.colors.primaryButton,
            position: "absolute",
            bottom: 8,
            right: 8,
            height: 26,
            borderRadius: 4,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomText
            style={{
              color: "#fff",
              fontSize: 13,
              marginHorizontal: 8,
            }}
            variant={"content"}
          >
            Photo de couverture
          </CustomText>
        </Box>
      </Box>
    );
  };

  const displayOtherImages = (addButton: boolean, start: number, end: number) => {
    const cols = (reducerStateLayout.cols ?? DEFAULT_COLS_NUMBER) - (addButton ? 2 : 0);
    let images = [] as string[];
    if (addButton) {
      images = ["plus", ...otherImages.slice(start, end)];
    } else {
      images = otherImages.slice(start);
    }
    return (
      <>
        <Box style={{ flex: 1 }}>
          <FlatList
            numColumns={cols}
            scrollEnabled={false}
            data={images}
            style={{ overflow: "visible" }}
            showsHorizontalScrollIndicator={false}
            keyExtractor={item => item}
            contentContainerStyle={{ overflow: "visible" }}
            // contentContainerStyle={{ zIndex: 1 }}
            renderItem={({ item, index }) => {
              if (item == "plus") {
                return addPictureButton;
              }
              return (
                <>
                  <Box
                    height={reducerStateLayout.secondaryImageSide}
                    width={reducerStateLayout.secondaryImageSide}
                    borderRadius="button"
                    mr="s"
                    mb="s"
                    overflow={"visible"}
                  >
                    <TouchableOpacity
                      style={{
                        zIndex: 2,
                        flex: 1,
                      }}
                      onPress={() => onRemoveImage(item)}
                    >
                      <Box
                        position="absolute"
                        right={-4}
                        top={-6}
                        backgroundColor="danger"
                        height={28}
                        width={28}
                        borderRadius="button"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CustomText variant="content" color="white">
                          X
                        </CustomText>
                      </Box>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ width: "100%", height: "100%" }}
                      onPress={() => setFocus(item)}
                    >
                      <Image
                        source={{
                          uri: item,
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 8,
                        }}
                      />
                    </TouchableOpacity>
                  </Box>
                </>
              );
            }}
          />
        </Box>
      </>
    );
  };

  const carouselContent = () => {
    if (reducerStateLayout.layout == null) {
      return <ActivityIndicator color={theme.colors.primaryButton} size={"large"} />;
    } else {
      return (
        <>
          <Box flexDirection={"column"}>
            <Box flexDirection={"row"}>
              {displayMainImage()}
              <Box style={{}}>
                {displayOtherImages(
                  true,
                  reducerStateLayout.sideStart,
                  reducerStateLayout.sideEnd,
                )}
              </Box>
            </Box>
            <Box flex={1}>
              {displayOtherImages(false, reducerStateLayout.sideEnd, otherImages.length)}
            </Box>
          </Box>
          <Box alignSelf="flex-end">
            <CustomText variant="description" color="lightGrey">
              {otherImages.length + (mainImage ? 1 : 0)}/{maxImages}
            </CustomText>
          </Box>
        </>
      );
    }
  };

  return (
    <Box
      pt="s"
      flexDirection={"row"}
      onLayout={e => dispatchLayout({ action: "ON_LAYOUT", value: e.nativeEvent.layout })}
    >
      {carouselContent()}
      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
      <AddNewImageModal
        isOpen={isCoverImagePickerOpen}
        setIsOpen={val => setIsCoverImagePickerOpen(val)}
        onSubmit={handleAddCoverImage}
      />
    </Box>
  );
};

export default MerchantCarouselPicker;
