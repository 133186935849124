import React, { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NbPersonsInput from "../../NbPersonsInput";
import SelectableButton from "../../SelectableButton";

const PERSONS_LIST_1 = [1, 2, 3, 4];
const PERSONS_LIST_2 = [5, 6, 7, 8];
const PERSONS_LIST_3 = [9, 10, 11, 12];
const BOOKING_TYPES = ["Repas de groupe", "A deux", "Romantique"];

interface NewBookingPersonsSelectionProps {
  personsSelected: number;
  onChange: (persons: number) => void;
  extraPersons?: number;
  isSplittable?: boolean;
}

const NewBookingPersonsSelection = ({
  personsSelected,
  onChange,
  isSplittable,
}: NewBookingPersonsSelectionProps) => {
  const [nbPersonsModal, setNbPersonsModal] = useState(false);

  const handlePersonsChange = (persons: string | number) => {
    onChange(+persons);
  };
  return (
    <ScrollView>
      <Box paddingHorizontal="s" mt="s">
        <CustomText textTransform="uppercase" variant="content" color="success" mb="s">
          Sélectionnez un nombre de couverts
        </CustomText>

        <Box width="100%" maxWidth={400} alignSelf="center">
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            // justifyContent="center"
            justifyContent="space-between"
            width="100%"
          >
            {PERSONS_LIST_1.map(person => {
              return (
                <Box key={person}>
                  <SelectableButton
                    label={person.toString()}
                    value={person}
                    onChange={handlePersonsChange}
                    isSelected={personsSelected === person}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            // justifyContent="center"
            width="100%"
            justifyContent="space-between"
          >
            {PERSONS_LIST_2.map(person => {
              return (
                <Box key={person}>
                  <SelectableButton
                    label={person.toString()}
                    value={person}
                    onChange={handlePersonsChange}
                    isSelected={personsSelected === person}
                  />
                </Box>
              );
            })}
          </Box>
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            // justifyContent="center"
            width="100%"
            justifyContent="space-between"
          >
            {PERSONS_LIST_3.map((person, idx) => {
              return (
                <Box key={person}>
                  <SelectableButton
                    label={person.toString()}
                    value={person}
                    onChange={handlePersonsChange}
                    isSelected={personsSelected === person}
                  />
                </Box>
              );
            })}
          </Box>

          <Box mt="s">
            <SelectableButton
              value={-1}
              label="+ de 12"
              onChange={() => setNbPersonsModal(true)}
              isSelected={personsSelected > 12}
              styles={{
                width: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
      <NbPersonsInput
        isOpen={nbPersonsModal}
        setIsOpen={setNbPersonsModal}
        nbPersons={personsSelected}
        onConfirm={p => handlePersonsChange(p)}
      />
    </ScrollView>
  );
};

export default NewBookingPersonsSelection;
