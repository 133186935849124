import { useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";

import HOME from "../../../../../assets/icons/BASE/HOME.svg";
import MOBILE_PHONE from "../../../../../assets/icons/BASE/MOBILE_PHONE.svg";
import PAX_PERSONS from "../../../../../assets/icons/BASE/PAX_PERSONS.svg";
import Box from "../../../../components/Base/Box";
import ScreenHeader from "../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../components/Settings/SettingsButtonItem";
import { AppContext } from "../../../../contexts/AppContext/index";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { LINE_THICKNESS } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import ClientNotifications from "../ClientNotifications";
import DeviceNotifications from "../DeviceNotifications";
import ProNotifications from "../ProNotifications";

interface NotificationsSettingsListProps {
  goBack?: () => void;
}

const NotificationsSettingsList = ({ goBack }: NotificationsSettingsListProps) => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);

  const HAS_VALID_DEVICE_TOKEN = !!appContext?.deviceToken;

  const handleNavigation = (screenName: keyof SettingsStackParamList) => {
    if (goBack) {
      setSelectedTab(screenName);
    } else {
      navigation.navigate(screenName);
    }
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Notifications"
          hasBackButton={!goBack}
          onBackPress={goBack || navigation.goBack}
        />

        <Box
          mt="m"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Établissement"
            subText="Push, Interne, Email, SMS"
            onPress={() => handleNavigation("NOTIFICATIONS_PRO_LIST")}
            bottomLineWidth={LINE_THICKNESS}
            icon={<HOME fill={PALETTE.green} height={15} width={15} />}
          />
          <SettingsButtonItem
            title="Client"
            subText="SMS"
            onPress={() => handleNavigation("NOTIFICATIONS_CLIENT_LIST")}
            bottomLineWidth={HAS_VALID_DEVICE_TOKEN ? LINE_THICKNESS : 0}
            icon={<PAX_PERSONS fill={PALETTE.green} height={15} width={15} />}
          />
          {HAS_VALID_DEVICE_TOKEN && (
            <SettingsButtonItem
              title="Périphérique"
              subText="Appel"
              onPress={() => handleNavigation("NOTIFICATIONS_DEVICE_LIST")}
              bottomLineWidth={0}
              icon={<MOBILE_PHONE fill={PALETTE.green} height={15} width={15} />}
            />
          )}
        </Box>
      </Box>
    );
  };

  const handleClose = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "NOTIFICATIONS_PRO_LIST":
        return <ProNotifications goBack={handleClose} />;
      case "NOTIFICATIONS_CLIENT_LIST":
        return <ClientNotifications goBack={handleClose} />;

      case "NOTIFICATIONS_DEVICE_LIST":
        return <DeviceNotifications goBack={handleClose} />;

      default:
        break;
    }
  };

  const displayContent = () => {
    if (!selectedTab) return displaySettingsList();

    return displaySelectedTab();
  };

  return <>{displayContent()}</>;
};

export default NotificationsSettingsList;
