import { TouchableOpacity } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface IncrementerProps {
  value: number;
  incrementor?: number;
  isInputDisabled?: boolean;
  isDisabled?: boolean;
  onValueChange: (value: number) => void;
  min: number;
  max: number;
}

const BUTTON_SIZE = 35;

const Incrementer = ({
  value = 0,
  incrementor = 1,
  onValueChange,
  isInputDisabled,
  isDisabled,
  min = 0,
  max = 10,
}: IncrementerProps) => {
  const handleChange = (type: "INCREMENT" | "DECREMENT") => {
    if (isDisabled) return;
    if (type === "INCREMENT" && value < max) {
      onValueChange(value + incrementor);
    } else if (type === "DECREMENT" && value > min) {
      onValueChange(value - incrementor);
    }
  };

  return (
    <Box flexDirection="row" alignItems="center">
      <TouchableOpacity onPress={() => handleChange("DECREMENT")}>
        <Box
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          alignItems="center"
          justifyContent="center"
          p="s"
          backgroundColor="white"
          borderColor="success"
          borderWidth={LINE_THICKNESS}
          borderRadius="button"
          mr="s"
        >
          <CustomText variant="label" color="primaryTextColor">
            -
          </CustomText>
        </Box>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handleChange("INCREMENT")}>
        <Box
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          borderColor="success"
          borderWidth={LINE_THICKNESS}
          borderRadius="button"
          mr="s"
        >
          <CustomText variant="label" color="primaryTextColor">
            +
          </CustomText>
        </Box>
      </TouchableOpacity>
      <Box
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        alignItems="center"
        justifyContent="center"
        backgroundColor="white"
        borderColor="primaryTextColor"
        borderWidth={LINE_THICKNESS}
        borderRadius="button"
        mr="s"
      >
        <CustomText variant="text" color="black">
          {value}{" "}
        </CustomText>
        {/* <TextInput
          editable={!isInputDisabled}
          keyboardType="decimal-pad"
          returnKeyType="done"
          value={`${value}`}
        /> */}
      </Box>
    </Box>
  );
};

export default Incrementer;
