import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import { MODULE_TYPE } from "../../../../components/ModuleList/common";

import PrescriberListDetailsEdit from "./Edit";
import PrescriberListDetailsView from "./View";

interface PrescriberListDetailsProps {
  goBack?: () => void;
  selectedPrescriberId?: string;
  newValue?: boolean;
}

const PrescriberListDetails = ({
  goBack,
  selectedPrescriberId,
  newValue,
}: PrescriberListDetailsProps) => {
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = selectedPrescriberId || params?.id;

  const [inEditMode, setInEditMode] = useState(isNew);

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <PrescriberListDetailsEdit id={id} goBack={goBack} />
      ) : (
        <PrescriberListDetailsView
          id={id}
          goBack={goBack}
          moduleType={MODULE_TYPE.CLIENTS}
        />
      )}
      {!inEditMode && (
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height={100}
          backgroundColor="white"
          p="m"
        >
          <CustomButton
            onPress={() => setInEditMode(true)}
            buttonVariant="primaryButton"
            buttonColor="success"
            styles={{
              minHeight: 45,
            }}
          >
            <CustomText variant="primaryButtonText" color="white">
              Modifier
            </CustomText>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default PrescriberListDetails;
