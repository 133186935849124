import { Booking_Status } from "../graphql/generated/schema";
import type { THEME_COLORS } from "../theme";
import type { PALETTE_COLORS } from "../theme/Palette";

export const determineCorrectStatusColorForBooking = (
  status: Booking_Status,
): {
  borderColor: THEME_COLORS;
  iconColor: PALETTE_COLORS;
} => {
  switch (status) {
    case Booking_Status.New:
      return {
        borderColor: "BOOKING_NEW",
        iconColor: "BOOKING_NEW",
      };
    case Booking_Status.WaitingList:
      return {
        borderColor: "BOOKING_WAITING_LIST",
        iconColor: "BOOKING_WAITING_LIST",
      };
    case Booking_Status.ModifiedByUser:
      return {
        borderColor: "BOOKING_MODIFIED",
        iconColor: "BOOKING_MODIFIED",
      };
    case Booking_Status.ModifiedByOwner:
      return {
        borderColor: "BOOKING_MODIFIED",
        iconColor: "BOOKING_MODIFIED",
      };
    case Booking_Status.Validated:
      return {
        borderColor: "BOOKING_CONFIRMED",
        iconColor: "BOOKING_CONFIRMED",
      };
    case Booking_Status.AutomaticValidated:
      return {
        borderColor: "BOOKING_CONFIRMED",
        iconColor: "BOOKING_CONFIRMED",
      };
    case Booking_Status.Arrived:
      return {
        borderColor: "BOOKING_ARRIVED",
        iconColor: "BOOKING_ARRIVED",
      };
    case Booking_Status.ArrivedWaiting:
      return {
        borderColor: "BOOKING_ARRIVED_WAITING_LIST",
        iconColor: "BOOKING_ARRIVED_WAITING_LIST",
      };
    case Booking_Status.Seated:
      return {
        borderColor: "BOOKING_SEATED",
        iconColor: "BOOKING_SEATED",
      };
    case Booking_Status.Ended:
      return {
        borderColor: "BOOKING_ENDED",
        iconColor: "BOOKING_ENDED",
      };
    case Booking_Status.Noshow:
      return {
        borderColor: "BOOKING_NO_SHOW",
        iconColor: "BOOKING_NO_SHOW",
      };
    case Booking_Status.CancelledByUser:
      return {
        borderColor: "BOOKING_CANCELLED",
        iconColor: "BOOKING_CANCELLED",
      };
    case Booking_Status.CancelledByOwner:
      return {
        borderColor: "BOOKING_CANCELLED",
        iconColor: "BOOKING_CANCELLED",
      };
  }
  return { borderColor: "success", iconColor: "green" };
};

export const getLabelForBookingStatus = (status: Booking_Status): string => {
  switch (status) {
    case Booking_Status.New:
      return "Nouvelle";
    case Booking_Status.WaitingList:
      return "Liste d'attente";
    case Booking_Status.ModifiedByUser:
      return "Modifiée par l'utilisateur";
    case Booking_Status.ModifiedByOwner:
      return "Modifiée par l'établissement";
    case Booking_Status.Validated:
      return "Validée";
    case Booking_Status.AutomaticValidated:
      return "Validée automatiquement";
    case Booking_Status.Arrived:
      return "Arrivée";
    case Booking_Status.ArrivedWaiting:
      return "Arrivée en liste d'attente";
    case Booking_Status.Seated:
      return "Assis";
    case Booking_Status.Ended:
      return "Terminée";
    case Booking_Status.Noshow:
      return "No show";
    case Booking_Status.CancelledByUser:
      return "Annulée par l'utilisateur";
    case Booking_Status.CancelledByOwner:
      return "Annulée par l'établissement";

    default:
      break;
  }
  return "-";
};

export const getBookingStatusFromLabel = (label: string): Booking_Status | undefined => {
  switch (label) {
    case "Nouvelle":
      return Booking_Status.New;
    case "Liste d'attente":
      return Booking_Status.WaitingList;
    case "Modifiée par l'utilisateur":
      return Booking_Status.ModifiedByUser;
    case "Modifiée par l'établissement":
      return Booking_Status.ModifiedByOwner;
    case "Validée":
      return Booking_Status.Validated;
    case "Validée automatiquement":
      return Booking_Status.AutomaticValidated;
    case "Arrivée":
      return Booking_Status.Arrived;
    case "Arrivée en liste d'attente":
      return Booking_Status.ArrivedWaiting;
    case "Assis":
      return Booking_Status.Seated;
    case "Terminée":
      return Booking_Status.Ended;
    case "No show":
      return Booking_Status.Noshow;
    case "Annulée par l'utilisateur":
      return Booking_Status.CancelledByUser;
    case "Annulée par l'établissement":
      return Booking_Status.CancelledByOwner;

    default:
      break;
  }
};
