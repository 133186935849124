import { useState } from "react";

import type { THEME_COLORS } from "../../theme";
import type { TEXT_VARIANTS } from "../../theme/variants/TextVariants";
import { getSafeNumberFromInput } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomTextInput } from "../TextInput";

interface NumberInputLineProps {
  text: string;
  subTitle?: string;
  value: number;
  placeHolder: string;
  onValueChange: (value: number) => void;
  textVariant?: TEXT_VARIANTS;
  color?: THEME_COLORS;
  hasErrors?: boolean;
  keyboardType?: "number-pad" | "decimal-pad";
}

const NUMBER_INPUT_HEIGHT = 35;
const NUMBER_INPUT_WIDTH = 80;

const NumberInputLine = ({
  text,
  subTitle,
  placeHolder,
  value,
  onValueChange,
  textVariant = "label",
  color = "primaryTextColor",
  hasErrors = false,
  keyboardType = "number-pad",
}: NumberInputLineProps) => {
  const [textValue, setTextValue] = useState(value.toString());
  return (
    <Box
      marginVertical="s"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box mr="s" maxWidth="80%">
        <CustomText
          style={{
            flex: 1,
            flexWrap: "wrap",
          }}
          variant={textVariant}
          color={hasErrors ? "danger" : color}
        >
          {text}
        </CustomText>
        {subTitle && (
          <CustomText
            style={{
              flex: 1,
              flexWrap: "wrap",
            }}
            variant="text"
            color="lightGrey"
          >
            {subTitle}
          </CustomText>
        )}
      </Box>

      <CustomTextInput
        hasErrors={hasErrors}
        placeHolder={placeHolder}
        hideFloatingLabel
        value={textValue.toString()}
        keyboardType={keyboardType}
        // initialValue={value.toString()}
        onBlur={v => {
          onValueChange(getSafeNumberFromInput(v));
          setTextValue(getSafeNumberFromInput(v).toString());
        }}
        onEndEditing={v => {
          onValueChange(getSafeNumberFromInput(v));
          setTextValue(getSafeNumberFromInput(v).toString());
        }}
        onChangeText={v => setTextValue(v || "0")}
        boxProps={{
          // minWidth: NUMBER_INPUT_WIDTH,
          height: NUMBER_INPUT_HEIGHT,
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </Box>
  );
};

export default NumberInputLine;
