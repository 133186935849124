import type { MerchantDeviceFragment } from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface MerchantConnectedDeviceCardProps {
  merchantDevice: MerchantDeviceFragment;
  // onDelete: () => void;
}

const MerchantConnectedDeviceCard = ({
  merchantDevice,
}: // onDelete,
MerchantConnectedDeviceCardProps) => {
  const { owner, device, merchantPersonnel } = merchantDevice;
  const { manufacturer, modelName } = device;

  const displayName = () => {
    if (merchantPersonnel) {
      return `${merchantPersonnel.firstName || ""} ${
        merchantPersonnel.lastName || ""
      }`.trim();
    }
    return `${owner?.profile.firstName || ""} ${owner?.profile.lastName || ""}`;
  };

  return (
    <Box>
      <Box backgroundColor="disabled" borderRadius="button" p="s">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          borderBottomColor="lightGrey"
          borderBottomWidth={LINE_THICKNESS}
          mb="s"
        >
          <CustomText variant="content" color="primaryTextColor">
            Marque
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {manufacturer}
          </CustomText>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p="s"
          borderBottomColor="lightGrey"
          borderBottomWidth={LINE_THICKNESS}
          mb="s"
        >
          <CustomText variant="content" color="primaryTextColor">
            Nom
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {modelName}
          </CustomText>
        </Box>

        <Box flexDirection="row" alignItems="center" justifyContent="space-between" p="s">
          <CustomText variant="content" color="primaryTextColor">
            Associé par
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {displayName()}
          </CustomText>
        </Box>
      </Box>

      {/* <Box mt="s">
        <CustomButton
          buttonVariant="primaryButton"
          buttonColor="disabled"
          onPress={onDelete}
          styles={{ minHeight: 45 }}
        >
          <CustomText variant="primaryButtonText" color="danger">
            Supprimer l'appareil
          </CustomText>
        </CustomButton>
      </Box> */}
    </Box>
  );
};

export default MerchantConnectedDeviceCard;
