 
import { useEffect, useState } from "react";
import { FlatList } from "react-native";

import type { SubscriptionFragment } from "../../../../graphql/generated/schema";
import {
  Subscription_Type,
  useGetMerchantSubscriptionsLazyQuery,
} from "../../../../graphql/generated/schema";
import Box from "../../../Base/Box";
import SubscriptionCard from "../../../SubscriptionCard";

interface AddSubscriptionListSelectProps {
  transactionSubscriptionIds: string[];
  selectedSubscriptions: SubscriptionFragment[];
  onSelectSubscription: (subscription: SubscriptionFragment) => void;
}

const AddSubscriptionListSelect = ({
  transactionSubscriptionIds,
  selectedSubscriptions,
  onSelectSubscription,
}: AddSubscriptionListSelectProps) => {
  const [subsriptions, setSubsriptions] = useState<SubscriptionFragment[]>([]);

  const [getMerchantSubscriptions] = useGetMerchantSubscriptionsLazyQuery();

  const handleGetMerchantSubscriptions = async () => {
    try {
      const { data } = await getMerchantSubscriptions();

      if (data?.getMerchantSubscriptions) {
        setSubsriptions(
          data?.getMerchantSubscriptions
            .filter(s => s.type === Subscription_Type.Subscription)
            .filter(s => !transactionSubscriptionIds.includes(s._id))
            .sort(a => (a.isCompleteSubscription ? -1 : 1)),
        );
      }
    } catch (err) {
      console.log("Err get merchant subscriptions", err);
    }
  };

  useEffect(() => {
    handleGetMerchantSubscriptions();
  }, []);

  const getMinPrice = (prices: SubscriptionFragment["prices"]) => {
    let minPrice = prices?.[0]?.price || 0;

    prices.forEach(price => {
      if (price.price < minPrice) {
        minPrice = price.price;
      }
    });

    return minPrice;
  };

  return (
    <Box flex={1} backgroundColor="white">
      <FlatList
        data={subsriptions}
        keyExtractor={item => item._id}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
        renderItem={({ item }) => {
          const { name, description, prices } = item;

          const minPrice = getMinPrice(prices);

          const IS_SELECTED = selectedSubscriptions.find(s => s._id === item._id);

          return (
            <Box
              marginVertical="s"
              backgroundColor={IS_SELECTED ? "white" : "disabled"}
              borderRadius="button"
              paddingHorizontal="s"
              borderColor="success"
              borderWidth={IS_SELECTED ? 1 : 0}
            >
              <SubscriptionCard
                onPress={() => onSelectSubscription(item)}
                title={name}
                description={description}
                price={minPrice}
                borderBottomWidth={0}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default AddSubscriptionListSelect;
