import DRAGGABLE from "../../../../assets/icons/BASE/DRAGGABLE.svg";
import type { PrintTemplateOrderItem } from "../../../graphql/generated/schema";
import {
  Print_Order_Sort_Type,
  Print_Order_Type,
} from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import SelectButton from "../../Select/SelectButton/index";
import Touchable from "../../Touchable";

interface SortItemsByItemProps {
  item: PrintTemplateOrderItem;
  onChange: (updatedItem: PrintTemplateOrderItem) => void;
  onPressIn: () => void;
}

const HEIGHT = 20;

const SortItemsByItem = ({ item, onChange, onPressIn }: SortItemsByItemProps) => {
  const displayPrintOrderType = (type: Print_Order_Type) => {
    switch (type) {
      case Print_Order_Type.Name:
        return "Nom";
      case Print_Order_Type.Time:
        return "Heure";
      case Print_Order_Type.Status:
        return "Statut";
      case Print_Order_Type.Table:
        return "Table";
      case Print_Order_Type.Persons:
        return "Nombre de personnes";
      case Print_Order_Type.OrderAmount:
        return "Montant";
    }
    return "-";
  };

  const getLabelForPrintOrderSortType = (type: Print_Order_Sort_Type) => {
    switch (type) {
      case Print_Order_Sort_Type.Ascending:
        return "Croissant";
      case Print_Order_Sort_Type.Descending:
        return "Décroissant";
      case Print_Order_Sort_Type.Disactivated:
        return "Désactivé";
    }
    return "-";
  };

  const getOptions = () => {
    return [
      Print_Order_Sort_Type.Ascending,
      Print_Order_Sort_Type.Descending,
      Print_Order_Sort_Type.Disactivated,
    ].map(status => ({
      label: getLabelForPrintOrderSortType(status),
      key: status.toString(),
    }));
  };

  const handleSelectStatusFilter = (items: string[]) => {
    const [orderSortType] = items;

    onChange({
      ...item,
      orderSortType,
    });
  };

  return (
    <Box flex={1} flexDirection="row" alignItems="center">
      <Touchable onPressIn={onPressIn}>
        <Box height={HEIGHT} paddingHorizontal="xs">
          <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
        </Box>
      </Touchable>

      <Box
        flex={1}
        flexDirection="row"
        paddingHorizontal="s"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex={0.5}>
          <CustomText variant="content" color="primaryTextColor">
            {displayPrintOrderType(item.orderType)}
          </CustomText>
        </Box>
        <Box flex={0.5} maxWidth={200}>
          <SelectButton
            placeHolder=""
            selectedOptions={[item.orderSortType]}
            isMultiSelect={false}
            onPress={handleSelectStatusFilter}
            options={getOptions()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SortItemsByItem;
