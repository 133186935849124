/* eslint-disable @typescript-eslint/no-empty-function */
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { format, isSameDay } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import { Alert, Platform } from "react-native";

import ModuleList from "../../../components/ModuleList";
import SplitView from "../../../components/SplitView";
import { AppContext } from "../../../contexts/AppContext";
import { SubscriptionsContext } from "../../../contexts/SubscriptionsContext/index";
import type {
  BookingAndServiceFragment,
  BookingFloorWithBookingsFragment,
  BookingListingSettings,
  Booking_Status,
  GetBookingAvailabilitiesWithBookingInfoQuery,
  GetBookingsByServicesQuery,
} from "../../../graphql/generated/schema";
import {
  Closure_Types,
  Modules,
  useDeleteBookingMutation,
  useGetBookingAvailabilitiesWithBookingInfoLazyQuery,
  useGetBookingFloorsWithBookingsLazyQuery,
  useGetBookingSettingsLazyQuery,
  useGetBookingsByMonthLazyQuery,
  useGetBookingsByServicesLazyQuery,
  useSearchBookingsLazyQuery,
  useToggleServiceVisibilityMutation,
  useToggleSlotVisibilityMutation,
} from "../../../graphql/generated/schema";
import type { BookingStackParamList } from "../../../navigation/AppStack/BookingStack/ParamList";
import { captureAndReportErrors } from "../../../sentry";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import BookingFloors from "../BookingDetailsFloorsTableSelection";
import BookingListDetailsView from "../BookingListDetails";

type BookingListScreenNavigationProps = NativeStackNavigationProp<
  BookingStackParamList,
  "BOOKINGS_LIST"
>;

enum RIGHT_COMPONENT {
  NONE = "NONE",
  BOOKING_FLOORS = "BOOKING_FLOORS",
  BOOKING_DETAILS = "BOOKING_DETAILS",
}

const IS_WEB = Platform.OS === "web";

const BookingList = () => {
  // const route = useRoute();
  const navigation = useNavigation<BookingListScreenNavigationProps>();
  // const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const appContext = useContext(AppContext);

  const subscriptionsHandlerContext = useContext(SubscriptionsContext);

  const [refreshDetails, setRefreshDetails] = useState(false);
  const [selectedService, setSelectedService] = useState("ALL");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingsByServices, setBookingsByServices] = useState<
    GetBookingsByServicesQuery["getBookingsByServices"]
  >([]);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [bookingAvailability, setBookingAvailability] = useState<
    GetBookingAvailabilitiesWithBookingInfoQuery["getBookingAvailabilitiesWithBookingInfo"]
  >([]);
  const [bookingsByMonth, setBookingsByMonth] = useState({});
  const [bookingSettings, setBookingSettings] = useState<BookingListingSettings | null>(
    null,
  );
  const [rightComponentViewType, setRightComponentViewType] = useState(
    appContext.isSplittable ? RIGHT_COMPONENT.BOOKING_FLOORS : RIGHT_COMPONENT.NONE,
  );
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookingFloors, setBookingFloors] = useState<BookingFloorWithBookingsFragment[]>(
    [],
  );
  const [selectedStatuses, setSelectedStatuses] = useState<Booking_Status[]>([]);
  const [selectedFloorId, setSelectedFloorId] = useState("");

  const [shouldShowStatusFilterBadge, setShouldShowStatusFilterBadge] = useState(false);
  const [getBookingFloorsWithBookings] = useGetBookingFloorsWithBookingsLazyQuery();

  const [getBookingAvailability] = useGetBookingAvailabilitiesWithBookingInfoLazyQuery({
    variables: { date: selectedDate },
  });
  const [isServiceSlotCloseInProgress, setIsServiceSlotCloseInProgress] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [areBookingsBeingFetched, setAreBookingsBeingFetched] = useState(false);

  const [getBookingsByMonth] = useGetBookingsByMonthLazyQuery();
  const [getBookingSettings] = useGetBookingSettingsLazyQuery();
  const [toggleServiceVisibility] = useToggleServiceVisibilityMutation();
  const [toggleServiceSlotVisibility] = useToggleSlotVisibilityMutation();
  const [deleteBooking] = useDeleteBookingMutation();
  const [getBookings, { fetchMore }] = useGetBookingsByServicesLazyQuery();
  const [searchBookings, { fetchMore: fetchMoreSearchBookings }] =
    useSearchBookingsLazyQuery();

  const handleSearch = async (search: string) => {
    try {
      if (!search) {
        handleRefresh(false);
      } else {
        const response = await searchBookings({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 30,
              offset: 0,
            },
            searchQuery: search,
          },
          fetchPolicy: "cache-and-network",
        });

        if (response.data?.searchBookings) {
          setBookingsByServices(response.data.searchBookings);
        }
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("AN_ERROR_OCCURED_SEARCH_BOOKINGS", err);
    }
  };

  const handleGetBookingsFloors = async () => {
    try {
      const { data } = await getBookingFloorsWithBookings({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
          selectedDate: new Date(selectedDate).toISOString(),
          selectedServiceId:
            selectedService && selectedService !== "ALL" ? selectedService : null,
        },
        fetchPolicy: "cache-and-network",
      });

      if (data?.getBookingFloors) {
        const floors = data.getBookingFloors.filter(floor => floor.tables.length > 0);

        setBookingFloors(floors);
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("error", JSON.stringify(err, null, 2));
    } finally {
      // setLoading(false);
    }
  };

  const handleFetchMore = async () => {
    if (isRefreshing) return;

    try {
      const offset = bookingsByServices?.reduce((acc, curr) => {
        return (
          acc +
          curr?.slots?.reduce((slotAacc, slotCurr) => {
            return slotAacc + slotCurr?.data?.length || 0;
          }, 0)
        );
      }, 0);

      let newBookings: BookingAndServiceFragment[] = [];

      if (isSearchOpen) {
        const result = await fetchMoreSearchBookings({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 20,
              offset: offset,
            },
          },
        });
        newBookings = JSON.parse(JSON.stringify(result.data.searchBookings));
      } else {
        const result = await fetchMore({
          variables: {
            date: selectedDate?.toISOString(),
            pagination: {
              limit: 20,
              offset: offset,
            },
          },
        });

        newBookings = JSON.parse(JSON.stringify(result.data.getBookingsByServices));
      }

      if (newBookings) {
        let base: GetBookingsByServicesQuery["getBookingsByServices"] = [
          ...bookingsByServices,
        ];

        newBookings.forEach(item => {
          const foundService = base.find(service => service.serviceId === item.serviceId);

          if (foundService) {
            base = base.map(s => {
              if (s.serviceId === foundService.serviceId) {
                const combinedSlots = [...foundService.slots, ...item.slots];

                const uniqueSlots = combinedSlots.reduce((acc, curr) => {
                  const foundSlot = acc.find(slot => slot.slotTime === curr.slotTime);

                  if (foundSlot) {
                    const updatedFoundSlots = acc.map(slot => {
                      if (slot.slotTime === foundSlot.slotTime) {
                        const updatedTotalInSlot = slot.totalInSlot + curr.totalInSlot;
                        const updatedAccumulatedTotalInSlot =
                          slot.accumulatedTotalInSlot + curr.accumulatedTotalInSlot;
                        return {
                          ...slot,
                          totalInSlot: updatedTotalInSlot,
                          accumulatedTotalInSlot: updatedAccumulatedTotalInSlot,
                          data: [...slot.data, ...curr.data],
                        };
                      }
                      return slot;
                    });

                    return updatedFoundSlots;
                  }
                  return [...acc, curr];
                }, []);

                const uniqueSlotsWithUniqueData = uniqueSlots.map(slot => {
                  const uniqueData = [];

                  slot.data.forEach(data => {
                    const foundData = uniqueData.find(d => d?._id === data._id);

                    if (!foundData) {
                      uniqueData.push(data);
                    }
                  });

                  return {
                    ...slot,
                    data: uniqueData,
                  };
                });

                return {
                  ...foundService,
                  // totalInService: foundService.totalInService + item.totalInService,
                  // accumulatedTotalInService:
                  //   foundService.accumulatedTotalInService +
                  //   item.accumulatedTotalInService,
                  // slots: [...foundService.slots, ...item.slots],
                  slots: uniqueSlotsWithUniqueData,
                };
              }
              return s;
            });
          } else {
            base.push(item);
          }
        });

        setBookingsByServices(base);
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err fetch more", err);
    }
  };

  const handleGetBookingSettings = async () => {
    try {
      const { data } = await getBookingSettings({
        fetchPolicy: "cache-and-network",
      });
      if (data?.getBookingSettings) {
        setBookingSettings(data.getBookingSettings.bookingListingSettings);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetBookingsForMonth = async (monthDate: Date) => {
    try {
      const result = await getBookingsByMonth({
        variables: {
          date: monthDate,
        },
      });

      if (result.data) {
        const bookingDates = result.data.getBookingsByMonth.dates;
        const items: Record<string, unknown> = {};

        bookingDates
          .map(date => format(new Date(date), "yyyy-MM-dd"))
          .forEach(date => {
            items[date] = {
              selected: true,
            };
          });

        setBookingsByMonth(items);
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err get bookings month", err);
    }
  };

  const handleGetBookingAvailability = async (refresh = false) => {
    try {
      const { data } = await getBookingAvailability({
        fetchPolicy: refresh ? "cache-and-network" : "cache-first",
        variables: {
          date: selectedDate,
          selectedFloorId,
        },
      });

      const services = data?.getBookingAvailabilitiesWithBookingInfo || [];

      setBookingAvailability(services);
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err refech", err);
    }
  };

  useEffect(() => {
    handleGetBookingSettings();
  }, []);

  useEffect(() => {
    // handleRefresh(false);

    handleGetBookingsFloors();
  }, [selectedDate, selectedService]);

  useEffect(() => {
    if (subscriptionsHandlerContext.bookings.length > 0) {
      handleRefresh(true);
    }
  }, [subscriptionsHandlerContext.bookings]);

  useFocusEffect(
    useCallback(() => {
      handleRefresh(true);
      // handleGetBookingAvailability();
      // handleGetBookingSettings();

      // const routes = navigation.getState()?.routes;
      // const prevRoute = routes[routes.length - 2];

      // console.log("route", route, prevRoute);
      // setRightComponentViewType(
      //   appContext.isSplittable ? RIGHT_COMPONENT.BOOKING_FLOORS : RIGHT_COMPONENT.NONE,
      // );
      // setSelectedBookingId("");
    }, [selectedDate, selectedService, selectedStatuses, selectedFloorId]),
  );

  const handleGetBookingsAndServices = async (
    date = new Date(),
    serviceId: string,
    refresh = false,
    statuses: Booking_Status[] = [],
    floorId?: string,
  ) => {
    try {
      if (areBookingsBeingFetched) return;
      setAreBookingsBeingFetched(true);

      const consolidatedSelectedService = serviceId === "ALL" ? null : serviceId;

      const result = await getBookings({
        variables: {
          date: date?.toISOString(),
          serviceId: consolidatedSelectedService,
          statuses: statuses,
          selectedFloorId: floorId || selectedFloorId,
          pagination: {
            limit: 15,
            offset: 0,
          },
        },
        // fetchPolicy: refresh ? "cache-and-network" : "cache-first",
        fetchPolicy: IS_WEB ? "network-only" : "cache-and-network",
      });
      const { data } = result;

      // const bookings = JSON.parse(JSON.stringify(data?.getBookingsByServices || []));

      // console.log("bookings", JSON.stringify(bookings, null, 2));

      const bookings = data?.getBookingsByServices || [];

      setBookingsByServices(bookings);
    } catch (err) {
      console.log("err handleGetBookingsAndServices", err);
      captureAndReportErrors(err);
    } finally {
      setAreBookingsBeingFetched(false);
    }
  };

  const handleRefresh = async (refresh = false) => {
    if (refresh) {
      setIsRefreshing(true);
      // setSelectedBookingId("");
    }

    try {
      await handleGetBookingsAndServices(
        selectedDate,
        selectedService,
        refresh,
        selectedStatuses,
        selectedFloorId,
      );
      await handleGetBookingAvailability(refresh);
    } catch (err) {
      console.log("err refresh", err);
      captureAndReportErrors(err);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleItemUpdateComplete = async () => {
    try {
      // await handleRefresh(true);
      // if (
      //   appContext.isSplittable &&
      //   rightComponentViewType === RIGHT_COMPONENT.BOOKING_DETAILS
      // ) {
      // }
      setRefreshDetails(v => !v);
      // if (
      //   appContext.isSplittable &&
      //   rightComponentViewType === RIGHT_COMPONENT.BOOKING_FLOORS
      // ) {
      // }
      await handleGetBookingsFloors();
      await handleRefresh(false);
    } catch (err) {
      captureAndReportErrors(err);
    }
  };

  const handleCloseAllServices = async () => {
    try {
      const serviceIds = bookingAvailability.map(service => service.serviceId);

      await Promise.all(
        serviceIds.map(
          async serviceId =>
            await toggleServiceVisibility({
              variables: {
                serviceId,
                type: Closure_Types.Booking,
                date: selectedDate,
              },
            }),
        ),
      );
    } catch (err) {
      console.log("AN ERROR OCCURED", err);
      captureAndReportErrors(err);
    }
  };

  const handleCloseServiceSlot = async (serviceId: string, slotId?: string) => {
    try {
      setIsServiceSlotCloseInProgress(true);
      if (slotId) {
        await toggleServiceSlotVisibility({
          variables: {
            serviceId,
            slotId,
            type: Closure_Types.Booking,
            date: selectedDate,
          },
        });
      } else {
        if (serviceId === "ALL") {
          await handleCloseAllServices();
        } else {
          await toggleServiceVisibility({
            variables: {
              serviceId,
              type: Closure_Types.Booking,
              date: selectedDate,
            },
          });
        }
      }
      await handleGetBookingAvailability(true);
    } catch (err) {
      console.log("err", err);
      captureAndReportErrors(err);
    } finally {
      setIsServiceSlotCloseInProgress(false);
    }
  };

  const handleNewBookingPress = () => {
    navigation.navigate("NEW_BOOKING", {
      selectedDate,
    });
  };

  const handleLocalBookingPress = () => {
    navigation.navigate("NEW_LOCAL_BOOKING");
  };

  const handleBookingDetailsPress = (bookingId: string) => {
    if (appContext.isSplittable) {
      setRightComponentViewType(RIGHT_COMPONENT.BOOKING_DETAILS);
      handleSelectBooking(bookingId);
    } else {
      navigation.navigate("BOOKINGS_LIST_DETAILS", { bookingId });
    }
  };

  const handleBookingFloorAction = () => {
    if (appContext.isSplittable) {
      setRightComponentViewType(prev =>
        prev === RIGHT_COMPONENT.BOOKING_FLOORS
          ? RIGHT_COMPONENT.NONE
          : RIGHT_COMPONENT.BOOKING_FLOORS,
      );
    } else {
      navigation.navigate("BOOKING_FLOORS", {
        selectedDate: selectedDate?.toISOString(),
        bookinglFloorViewType: BOOKING_FLOOR_VIEW_TYPE.TABLE_VIEW,
        selectedBookingServiceId: selectedService,
      });
    }
  };

  const handleCloseSplitView = () => {
    setSelectedBookingId("");
    setRightComponentViewType(RIGHT_COMPONENT.NONE);
  };

  const handleCloseBookingDetails = () => {
    if (appContext.isSplittable) {
      setSelectedBookingId("");
      setRightComponentViewType(RIGHT_COMPONENT.BOOKING_FLOORS);
    }
  };

  const displayRightComponent = () => {
    if (rightComponentViewType === RIGHT_COMPONENT.BOOKING_DETAILS && selectedBookingId)
      return (
        <BookingListDetailsView
          bookingId={selectedBookingId}
          goBack={handleCloseBookingDetails}
          onUpdateComplete={handleItemUpdateComplete}
          refreshDetails={refreshDetails}
        />
      );
    else if (rightComponentViewType === RIGHT_COMPONENT.BOOKING_FLOORS)
      return (
        <BookingFloors
          selectedDate={selectedDate}
          bookingFloorViewType={BOOKING_FLOOR_VIEW_TYPE.TABLE_VIEW}
          goBack={handleCloseSplitView}
          bookingFloors={bookingFloors}
          selectedBookingServiceId={selectedService}
        />
      );
  };

  const handleSelectBooking = (bookingId: string) => {
    if (selectedBookingId === bookingId) {
      setRightComponentViewType(RIGHT_COMPONENT.BOOKING_FLOORS);
      setSelectedBookingId("");
    } else {
      setSelectedBookingId(bookingId);
    }
  };

  const handleDeleteBooking = async (itemId: string) => {
    try {
      const { data: deleteData } = await deleteBooking({
        variables: {
          bookingId: itemId,
        },
      });

      if (deleteData?.deleteBookingForPro) {
        await handleRefresh(false);
      }
    } catch (err) {
      captureAndReportErrors(err);
      console.log("err", JSON.stringify(err, null, 2));

      Alert.alert("Erreur", "Une erreur est survenue " + err.message || "");

      // infoAlert.openAlert(
      //   "Erreur",
      //   [
      //     {
      //       code: err?.code || "error",
      //       message: err?.message || "Une erreur est survenue",
      //       path: err?.message || "Une erreur est survenue",
      //     },
      //   ],
      //   "error",
      // );
    }
  };

  const handleOnDateChange = async (date: Date) => {
    setSelectedDate(date);
    setSelectedService("ALL");

    if (!isSameDay(date, selectedDate)) {
      setBookingsByServices([]);
      handleCloseBookingDetails();
      try {
        await handleGetBookingsAndServices(date, "ALL");
      } catch (err) {
        captureAndReportErrors(err);
        console.log("AN_ERROR_OCCURED_GETTING_BOOKINGS_AND_SERVICES", err);
      }
    }
  };

  const handleSelectService = async (serviceId: string) => {
    try {
      setIsRefreshing(true);
      setSelectedService(serviceId);

      await handleGetBookingsAndServices(selectedDate, serviceId);
    } catch (err) {
      captureAndReportErrors(err);
      console.log("AN_ERROR_OCCURED_WHILE_SWITHCING_SERVICE", err);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleSelectFloor = async (floorId: string) => {
    try {
      setSelectedFloorId(floorId);

      if (!floorId) return;

      setIsRefreshing(true);
      await handleGetBookingsAndServices(
        selectedDate,
        selectedService,
        true,
        selectedStatuses,
        floorId,
      );
    } catch (err) {
      captureAndReportErrors(err);
      console.log("AN_ERROR_OCCURED_WHILE_SWITHCING_SERVICE", err);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleSelectFilter = async (statuses: Booking_Status[]) => {
    try {
      setIsRefreshing(true);

      setSelectedStatuses(statuses);

      await handleGetBookingsAndServices(selectedDate, selectedService, true, statuses);
    } catch (err) {
      console.log("AN_ERROR_OCCURED_WHILE_SELECTING_FILTER", err);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <SplitView
      leftComponent={
        <ModuleList
          title="Réservations"
          selectedDate={selectedDate}
          onDateChange={handleOnDateChange}
          type={Modules.Reservation}
          services={bookingAvailability}
          selectedServiceId={selectedService}
          onServiceSelected={handleSelectService}
          onNewAction={handleNewBookingPress}
          onLocalBookingPress={handleLocalBookingPress}
          onBookingFloorAction={handleBookingFloorAction}
          onSearchAction={handleSearch}
          onFilterAction={() => {}}
          listData={bookingsByServices}
          onListItemExtraPress={() => {}}
          onListItemPress={handleBookingDetailsPress}
          isRefreshing={isRefreshing}
          onRefresh={() => handleRefresh(true)}
          dataByMonth={bookingsByMonth}
          listSettings={bookingSettings}
          onCloseServiceSlot={handleCloseServiceSlot}
          onMonthChange={handleGetBookingsForMonth}
          selectedId={selectedBookingId}
          setSelectedId={handleSelectBooking}
          onDelete={handleDeleteBooking}
          onEndReached={handleFetchMore}
          onListItemUpdateCompleteHandler={handleItemUpdateComplete}
          isServiceSlotCloseInProgress={isServiceSlotCloseInProgress}
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          shouldShowStatusFilterBadge={shouldShowStatusFilterBadge}
          setShouldShowStatusFilterBadge={setShouldShowStatusFilterBadge}
          handleSelectFilter={handleSelectFilter}
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={handleSelectFloor}
        />
      }
      rightComponent={displayRightComponent()}
    />
  );
};

export default BookingList;
