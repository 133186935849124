import React from "react";
import type { ViewStyle } from "react-native";
import { Snackbar } from "react-native-paper";

import Box from "../Base/Box";

interface SnackBarInfoProps {
  isOpen: boolean;
  onClose: () => void;
  action?: {
    label: string;
    onPress: () => void;
  };
  type: "success" | "error" | "info";
  styles?: ViewStyle;
  children: React.ReactNode;
}

const SnackBarInfo = ({
  isOpen,
  onClose,
  action,
  children,
  type,
  styles = {},
}: SnackBarInfoProps) => {
  const SUCCESS_STYLES: ViewStyle = {
    backgroundColor: "black",
    width: "100%",
    flex: 1,
    ...styles,
  };
  const ERROR_STYLES: ViewStyle = {};
  const INFO_STYLES: ViewStyle = {};

  const getStyles = () => {
    switch (type) {
      case "success":
        return SUCCESS_STYLES;
      case "error":
        return ERROR_STYLES;
      case "info":
        return INFO_STYLES;
      default:
        return {};
    }
  };

  return (
    <Snackbar visible={isOpen} onDismiss={onClose} action={action} style={getStyles()}>
      <Box>{children}</Box>
    </Snackbar>
  );
};

export default SnackBarInfo;
