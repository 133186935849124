import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import type { THEME_COLORS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

const HEIGHT = 49;
interface SettingsButtonProps {
  onPress: () => void;
  leftIcon: JSX.Element;
  shouldDisplayRightIcon?: boolean;
  text: string;
  color?: THEME_COLORS;
}

const SettingsButton = ({
  onPress,
  leftIcon,
  shouldDisplayRightIcon,
  text,
  color = "primaryTextColor",
}: SettingsButtonProps) => {
  const displayRightIcon = () => {
    if (shouldDisplayRightIcon) {
      return <RIGHT_ARROW height={18} width={18} fill={PALETTE.green} />;
    }
    return <></>;
  };

  return (
    <CustomButton
      onPress={onPress}
      buttonVariant="primaryButton"
      styles={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: HEIGHT,
      }}
    >
      <Box flexDirection="row" alignItems="center">
        {leftIcon}

        <CustomText color={color} variant="paragraph" pl="s">
          {text}
        </CustomText>
      </Box>

      {displayRightIcon()}
    </CustomButton>
  );
};

export default SettingsButton;
