import { format } from "date-fns";

import AGENDA from "../../../../../../assets/icons/BASE/AGENDA.svg";
import INFO from "../../../../../../assets/icons/BASE/INFO.svg";
import PENDING from "../../../../../../assets/icons/BASE/PENDING.svg";
import type { Quote } from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../../utils/common";
import Box from "../../../../Base/Box";
import { CustomText } from "../../../../Base/Text";
import TabTextInfo from "../../../../BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";

interface QuoteDetailsTabProps {
  quote: Quote;
}

const QuoteDetailsTab = ({ quote }: QuoteDetailsTabProps) => {
  const { title, totalDetails, validityInDays, sentDate, items } = quote;
  const formattedDate = format(new Date(sentDate), "dd/MM/yyyy");

  const displayQuoteItems = () => {
    const { productItems, basicItems, commentItems } = items;

    return (
      <>
        <Box>
          {productItems?.map(product => {
            const { quantity, productId } = product;
            const { _id, basePrice, name } = productId;
            const formattedPrice = formatCurrencyPrice(basePrice.amount, "fr", "EUR");

            return (
              <Box
                key={_id}
                mt="s"
                paddingHorizontal="s"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  {quantity}x
                </CustomText>
                <Box flexDirection="row" alignItems="center" minWidth="40%">
                  <Box>
                    <CustomText variant="content" color="primaryTextColor">
                      {name}
                    </CustomText>
                  </Box>
                </Box>
                <CustomText variant="content" color="primaryTextColor">
                  {basePrice?.tax?.taxRate}%
                </CustomText>
                <CustomText variant="content" color="primaryTextColor">
                  {formattedPrice}
                </CustomText>
              </Box>
            );
          })}
        </Box>

        <Box>
          {basicItems.map((basicItem, idx) => {
            const { title: basicItemTitle, quantity, price } = basicItem;
            const formattedPrice = formatCurrencyPrice(price.amount, "fr", "EUR");

            return (
              <Box
                key={`${basicItemTitle}-${idx}`}
                mt="s"
                paddingHorizontal="s"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  {quantity}x
                </CustomText>
                <Box alignItems="flex-start" minWidth="40%">
                  <CustomText variant="content" color="primaryTextColor">
                    {basicItemTitle}
                  </CustomText>
                </Box>
                <CustomText variant="content" color="primaryTextColor">
                  {price.taxRate}%
                </CustomText>
                <CustomText variant="content" color="primaryTextColor">
                  {formattedPrice}
                </CustomText>
              </Box>
            );
          })}
        </Box>

        <Box>
          {commentItems.map((itemComment, idx) => {
            const { comment } = itemComment;

            return (
              <Box
                key={`${comment}-${idx}`}
                mt="s"
                paddingHorizontal="s"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <CustomText variant="content" color="primaryTextColor">
                  {comment}
                </CustomText>
              </Box>
            );
          })}
        </Box>
        {/*
        {menus.map(menu => {
          const { name, price, quantity } = menu;
          const formattedPrice = formatCurrencyPrice(price.amount, "fr", "EUR");

          return (
            <Box
              mt="s"
              paddingHorizontal="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <CustomText variant="content" color="primaryTextColor">
                  {quantity}x
                </CustomText>
              </Box>
              <Box flex={0.6} minWidth="40%">
                <Box>
                  <CustomText variant="content" color="primaryTextColor">
                    {name}
                  </CustomText>

                </Box>
              </Box>
              <Box>
                <CustomText variant="content" color="primaryTextColor">
                  {price.taxRate}%
                </CustomText>
              </Box>
              <Box>
                <CustomText variant="content" color="primaryTextColor">
                  {formattedPrice}
                </CustomText>
              </Box>
            </Box>
          );
        })} */}
      </>
    );
  };

  const displayTaxRates = () => {
    const { taxes } = totalDetails;

    return (
      <>
        {taxes.map(tax => {
          const { taxRate, amount } = tax;
          const formattedPrice = formatCurrencyPrice(amount, "fr", "EUR");
          return (
            <Box
              key={taxRate}
              paddingHorizontal="s"
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CustomText variant="content" color="primaryTextColor">
                {taxRate}%
              </CustomText>
              <CustomText ml="s" variant="content" color="primaryTextColor">
                {formattedPrice}
              </CustomText>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box>
      <TabTextInfo
        {...{
          title: "Initulé",
          content: title,
          icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
        }}
      />

      <TabTextInfo
        {...{
          title: "Date d'émision",
          content: formattedDate,
          icon: <AGENDA height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
        }}
      />

      <TabTextInfo
        {...{
          title: "Validité",
          content: `${validityInDays} jours`,
          icon: <PENDING height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />,
        }}
      />

      <Box
        minHeight={80}
        justifyContent="center"
        mt="s"
        borderBottomColor="disabled"
        borderBottomWidth={1}
        pb="s"
      >
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box>
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
          </Box>
          <Box minWidth="40%">
            <CustomText variant="label" color="primaryTextColor">
              Détails
            </CustomText>
          </Box>
          <Box>
            <CustomText variant="label" color="primaryTextColor">
              TVA
            </CustomText>
          </Box>
          <Box>
            <CustomText variant="label" color="primaryTextColor">
              Montant HT
            </CustomText>
          </Box>
        </Box>

        {displayQuoteItems()}

        {displayTaxRates()}
      </Box>

      <Box mt="m">
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText textTransform="uppercase" variant="label" color="primaryTextColor">
            Total HT
          </CustomText>
          <CustomText variant="content" color="primaryTextColor">
            {formatCurrencyPrice(
              totalDetails.totalWithoutTaxes,
              "fr",
              totalDetails.currency,
            )}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText textTransform="uppercase" variant="label" color="primaryTextColor">
            TVA
          </CustomText>
          <CustomText variant="content" color="primaryTextColor">
            {formatCurrencyPrice(
              totalDetails.totalTaxAmount,
              "fr",
              totalDetails.currency,
            )}
          </CustomText>
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <CustomText variant="content" color="primaryTextColor">
            TOTAL TTC
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {formatCurrencyPrice(
              totalDetails.totalWithTaxes,
              "fr",
              totalDetails.currency,
            )}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteDetailsTab;
