import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import BottomButton from "../../../../../components/BottomButton";
import ErrorMessage from "../../../../../components/ErrorMessage/index";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import NumberInputLine from "../../../../../components/NumberInputLine";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  OnlineSalesSettingsFragment,
  PricingRateFragment,
  VisibilitySettings,
} from "../../../../../graphql/generated/schema";
import {
  DisallowBookingType,
  GetOnlineSalesSettingsDocument,
  PrepaymentCancelMesaurementType,
  useGetOnlineSalesSettingsLazyQuery,
  useGetPricingRatesLazyQuery,
  useUpdateOnlineSalesSettingsMutation,
} from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";
import { CLICK_STATUS } from "../../../../../types";
import type { ERROR } from "../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../utils/common";

interface TakeAwayGeneralSettingsProps {
  goBack?: () => void;
}

const DEFAULT_STATUSES = [
  {
    label: "Validé",
    key: CLICK_STATUS.VALIDATED.toString(),
  },
  // {
  //   label: "Préparé",
  //   key: CLICK_STATUS.PREPARED.toString(),
  // },
  // {
  //   label: "Términé",
  //   key: CLICK_STATUS.ENDED.toString(),
  // },
];

const DISALLOW_DELIMETER = [
  {
    label: "À l'approche du service",
    key: DisallowBookingType.Service,
  },
  {
    label: "À l'approche du créneau",
    key: DisallowBookingType.Slot,
  },
];

const DISALLOW_DELIMETER_TYPE = [
  {
    label: "Minute(s)",
    key: PrepaymentCancelMesaurementType.Minutes,
  },
  {
    label: "Heure(s)",
    key: PrepaymentCancelMesaurementType.Hours,
  },
];

const schema = yup.object().shape({
  minPricePerOrder: yup.number().min(0, "Le prix min doit etre supérieur ou égal à 0"),
  minArticlesPerOrder: yup
    .number()
    .min(0, "Le min d'articles doit etre supérieur ou égal à 0"),
});

const TakeAwayGeneralSettings = ({ goBack }: TakeAwayGeneralSettingsProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [loading, setLoading] = useState(true);
  const [onlineSalesSettings, setOnlineSalesSettings] =
    useState<OnlineSalesSettingsFragment | null>(null);
  const [pricingRates, setPricingRates] = useState<PricingRateFragment[]>([]);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getOnlineSalesSettings] = useGetOnlineSalesSettingsLazyQuery();
  const [updateOnlineSalesSettings] = useUpdateOnlineSalesSettingsMutation();
  const [getPricingRates] = useGetPricingRatesLazyQuery();

  const validateForm = async (
    consolidatedService: OnlineSalesSettingsFragment,
    displayErrors = true,
  ) => {
    try {
      await schema.validate(consolidatedService, { abortEarly: false });

      return true;
    } catch (err) {
      console.log("err", err);
      setErrors(formaYupErrors(err) || []);

      if (displayErrors)
        infoAlert.openAlert("Erreur", formaYupErrors(err) || [], "error");
    }
    return false;
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getPricingRates({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      setPricingRates(data?.getPricingRates || []);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDisplayPricingRates = () => {
    return pricingRates.map(pricingRate => ({
      label: pricingRate.name,
      key: pricingRate._id,
    }));
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getOnlineSalesSettings();

      if (data) {
        setOnlineSalesSettings(data.getOnlineSalesSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPricingRates();
    handleGetSettings();
  }, []);

  const handleUpdatettings = async () => {
    try {
      if (!onlineSalesSettings) return;

      const isFormValid = await validateForm(onlineSalesSettings);

      if (!isFormValid) return;

      let updates = removeTypeNames(onlineSalesSettings);

      const pricingRateId =
        typeof updates?.pricingRate === "string"
          ? updates?.pricingRate
          : updates?.pricingRate?._id;

      updates = {
        ...updates,
        pricingRate: pricingRateId,
      };

      await updateOnlineSalesSettings({
        variables: {
          updates,
        },
        refetchQueries: [
          {
            query: GetOnlineSalesSettingsDocument,
          },
        ],
      });

      handleGoBack();
    } catch (error) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_ONLINE_SALES_SETTINGS",
            message: "Une erreur est survenue lors de la mise à jour des paramètres",
          },
        ],
        "error",
      );
      console.log("err update online sales settings", error);
    }
  };

  const updateSettingsData = (
    key: keyof OnlineSalesSettingsFragment,
    value: OnlineSalesSettingsFragment[keyof OnlineSalesSettingsFragment],
  ) => {
    setOnlineSalesSettings(prev => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return null;
    });
  };

  const toggleOnlinePaymentRequired = (value: boolean) => {
    setOnlineSalesSettings(prev => {
      if (prev) {
        return {
          ...prev,
          isOnlinePaymentEnabled:
            value && !prev.isOnlinePaymentEnabled ? true : prev.isOnlinePaymentEnabled,
          arePaymentLimitsEnabled: value,
        };
      }
      return null;
    });
  };

  const togglePaymentTypes = (
    key: "isOnlinePaymentEnabled" | "isLocalPaymentEnabled",
    value: boolean,
  ) => {
    setOnlineSalesSettings(prev => {
      if (prev) {
        let localPayment =
          key === "isLocalPaymentEnabled" ? value : prev.isLocalPaymentEnabled;
        const onlinePayment =
          key === "isOnlinePaymentEnabled" ? value : prev.isOnlinePaymentEnabled;

        localPayment = !localPayment && !onlinePayment ? true : localPayment;

        return {
          ...prev,
          isOnlinePaymentEnabled: onlinePayment,
          isLocalPaymentEnabled: localPayment,
          arePaymentLimitsEnabled: !onlinePayment ? false : prev.arePaymentLimitsEnabled,
        };
      }
      return null;
    });
  };

  const updateVisibilitySettingsData = (
    key: keyof VisibilitySettings,
    value: VisibilitySettings[keyof VisibilitySettings],
  ) => {
    setOnlineSalesSettings(prev => {
      if (prev) {
        return {
          ...prev,
          visibilitySettings: {
            ...prev.visibilitySettings,
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  if (loading) {
    return <Loader />;
  }

  if (!onlineSalesSettings) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres" />
      </Box>
    );
  }

  const getSelectedPricingRate = () => {
    const { pricingRate } = onlineSalesSettings;

    const rateId = typeof pricingRate === "string" ? pricingRate : pricingRate?._id;

    return rateId ? [rateId] : [];
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Activer le module de commande en ligne"
              value={onlineSalesSettings.isEnabled}
              onValueChange={value => updateSettingsData("isEnabled", value)}
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Activer le module de commande sur Google"
              value={onlineSalesSettings.isOnlineSalesEnabledForGoogle}
              onValueChange={value =>
                updateSettingsData("isOnlineSalesEnabledForGoogle", value)
              }
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Déduire les commandes en attente du stock"
              value={onlineSalesSettings.deductPendingOrdersFromAvailability}
              onValueChange={value =>
                updateSettingsData("deductPendingOrdersFromAvailability", value)
              }
            />
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Limiter les commandes à l’avance"
              value={!!onlineSalesSettings?.visibilitySettings.isEnabled}
              onValueChange={v => updateVisibilitySettingsData("isEnabled", v)}
            />

            {onlineSalesSettings?.visibilitySettings.isEnabled && (
              <Box>
                <Box mt="s" pl="m">
                  <NumberInputLine
                    textVariant="content"
                    color="lightGrey"
                    text="Veuillez entrer un nombre de jours"
                    placeHolder="0"
                    value={onlineSalesSettings?.visibilitySettings.advanceDays || 0}
                    onValueChange={days =>
                      updateVisibilitySettingsData(
                        "advanceDays",
                        getSafeNumberFromInput(days),
                      )
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <ToggleInputLine
              textVariant="content"
              text="Cloturer la prise de commande"
              value={!!onlineSalesSettings?.visibilitySettings.isDelimitationEnabled}
              onValueChange={v =>
                updateVisibilitySettingsData("isDelimitationEnabled", v)
              }
            />

            {onlineSalesSettings?.visibilitySettings.isDelimitationEnabled && (
              <Box>
                <Box
                  mt="m"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <CustomTextInput
                    hasErrors={doesInputHaveError(
                      "onlineSalesSettings.visibilitySettings.amount",
                    )}
                    keyboardType="number-pad"
                    value={onlineSalesSettings.visibilitySettings.amount.toString()}
                    onChangeText={v =>
                      updateVisibilitySettingsData("amount", getSafeNumberFromInput(v))
                    }
                    hideFloatingLabel
                    placeHolder=""
                    inputStyles={{
                      textAlign: "center",
                    }}
                  />
                  <SelectButton
                    selectedTextVariant="text"
                    isMultiSelect={false}
                    options={DISALLOW_DELIMETER_TYPE}
                    selectedOptions={[
                      onlineSalesSettings.visibilitySettings.delimiterMesaurementType,
                    ]}
                    onPress={([delimiter]) =>
                      updateVisibilitySettingsData("delimiterMesaurementType", delimiter)
                    }
                    placeHolder="Avant"
                  />
                  <SelectButton
                    selectedTextVariant="text"
                    isMultiSelect={false}
                    options={DISALLOW_DELIMETER}
                    selectedOptions={[
                      onlineSalesSettings.visibilitySettings.disallowBookingByType,
                    ]}
                    onPress={([delimiter]) =>
                      updateVisibilitySettingsData("disallowBookingByType", delimiter)
                    }
                    placeHolder="Avant"
                  />
                </Box>

                <Box mt="s">
                  <CustomText variant="text" color="lightGrey">
                    La prise de commande ne sera plus possible en fonction des
                    informations ajoutées ci-dessus.
                  </CustomText>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomText marginVertical="s" variant="content" color="primaryTextColor">
              Tarification choisie
            </CustomText>
            <SelectButton
              isMultiSelect={false}
              options={handleDisplayPricingRates()}
              selectedOptions={getSelectedPricingRate()}
              onPress={([id]) => updateSettingsData("pricingRate", id)}
              placeHolder="Tarification"
            />
          </Box>

          <Box
            mt="s"
            pb="s"
            borderBottomColor="disabled"
            borderBottomWidth={LINE_THICKNESS}
          >
            <CustomText marginVertical="s" variant="content" color="primaryTextColor">
              Paiement
            </CustomText>
            <Box ml="s">
              <Box mt="s">
                <ToggleInputLine
                  textVariant="content"
                  textColor="lightGrey"
                  text="Autoriser le paiement sur place"
                  value={onlineSalesSettings.isLocalPaymentEnabled}
                  onValueChange={value =>
                    togglePaymentTypes("isLocalPaymentEnabled", value)
                  }
                />
              </Box>

              <Box mt="s">
                <ToggleInputLine
                  textVariant="content"
                  textColor="lightGrey"
                  text="Autoriser le paiement en ligne"
                  value={onlineSalesSettings.isOnlinePaymentEnabled}
                  onValueChange={value =>
                    togglePaymentTypes("isOnlinePaymentEnabled", value)
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box mt="s">
            <ToggleInputLine
              textVariant="content"
              text="Obliger le paiement en ligne a partir de"
              value={onlineSalesSettings.arePaymentLimitsEnabled}
              onValueChange={value => toggleOnlinePaymentRequired(value)}
            />
          </Box>

          {onlineSalesSettings.arePaymentLimitsEnabled && (
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  hideFloatingLabel
                  placeHolder="0"
                  keyboardType="number-pad"
                  value={onlineSalesSettings.minArticlesPerOrder.toString()}
                  onChangeText={v =>
                    updateSettingsData("minArticlesPerOrder", getSafeNumberFromInput(v))
                  }
                  boxProps={{
                    minWidth: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <Box
                  ml="s"
                  p="s"
                  backgroundColor="disabled"
                  borderRadius="button"
                  alignItems="center"
                  justifyContent="center"
                  minHeight={50}
                  minWidth={50}
                >
                  <CustomText variant="content" color="primaryTextColor">
                    Articles
                  </CustomText>
                </Box>
              </Box>
              <CustomText variant="content" color="primaryTextColor">
                ou
              </CustomText>
              <Box flexDirection="row" alignItems="center">
                <CustomTextInput
                  hideFloatingLabel
                  placeHolder="0"
                  keyboardType="number-pad"
                  value={onlineSalesSettings.minPricePerOrder.toString()}
                  onChangeText={v =>
                    updateSettingsData("minPricePerOrder", getSafeNumberFromInput(v))
                  }
                  boxProps={{
                    minWidth: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <Box
                  ml="s"
                  p="s"
                  backgroundColor="disabled"
                  borderRadius="button"
                  alignItems="center"
                  justifyContent="center"
                  minHeight={50}
                  minWidth={50}
                >
                  <CustomText variant="content" color="primaryTextColor">
                    €
                  </CustomText>
                </Box>
              </Box>
            </Box>
          )}

          <Box mt="s">
            <CustomText mb="s" variant="content" color="primaryTextColor">
              Statut par défaut d'une commande de passage
            </CustomText>
            <SelectButton
              isMultiSelect={false}
              options={DEFAULT_STATUSES}
              selectedOptions={[onlineSalesSettings.defaultStatusForNewOrder?.toString()]}
              onPress={([status]) =>
                updateSettingsData("defaultStatusForNewOrder", +status)
              }
              placeHolder="Statut"
            />
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Enregistrer" onPress={handleUpdatettings} />
    </Box>
  );
};

export default TakeAwayGeneralSettings;
