import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import * as WebBrowser from "expo-web-browser";
import { useCallback, useContext, useState } from "react";
import { Platform } from "react-native";

import NEW from "../../../assets/icons/BASE/NEW.svg";
import config from "../../config/env";
import { AppContext } from "../../contexts/AppContext/index";
import { ErrorInfoSuccessAlertModalContext } from "../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { SubscriptionTransactionFragment } from "../../graphql/generated/schema";
import {
  Merchant_Subscription_Status,
  Modules,
  Subscription_Interval_Type,
  useGetMerchantSubscriptionTransactionsLazyQuery,
} from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import Loader from "../Loader";
import NewSubscriptionCard from "../NewSubscriptionCard";
import SubscriptionCard from "../SubscriptionCard";

const SubscriptionsList = () => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [subscriptionsTransactions, setSubscriptionsTransactions] = useState<
    SubscriptionTransactionFragment[]
  >([]);
  const [loading, setLoading] = useState(true);

  const [getSubscriptionTransactions] = useGetMerchantSubscriptionTransactionsLazyQuery();

  const hasAllPermissions = appContext.merchantPermissions?.includes(Modules.All);

  const handleGetSubscriptionTransactions = async () => {
    try {
      const { data } = await getSubscriptionTransactions({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setSubscriptionsTransactions(data.getMerchantSubscriptionTransactions);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_SUBSCRIPTION_TRANSACTIONS",
            message: "Une erreur est survenue lors de la récupération des abonnements",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      handleGetSubscriptionTransactions();
    }, []),
  );

  if (loading) return <Loader />;

  const handleSubscriptionDetails = (transaction: SubscriptionTransactionFragment) => {
    navigation.navigate("SUBSCRIPTION_DETAILS", {
      subscriptionTransaction: transaction,
    });
  };

  const handleAddSubscription = async () => {
    if (Platform.OS === "web") {
      navigation.navigate("ADD_SUBSCRIPTION");
    } else {
      const URL = `${config.ROOT_URL}/settings/add-subscription`;

      await WebBrowser.openBrowserAsync(URL);

      console.log("closed");
    }
  };

  return (
    <Box paddingHorizontal="s" backgroundColor="disabled" borderRadius="button">
      {subscriptionsTransactions.map(subscriptionTransaction => {
        const { subscription, interval, commitment, endDate, total } =
          subscriptionTransaction;

        const totalAmount = total.subscriptionAmount + total.subscriptionOptionsAmount;

        const paymentInterval =
          interval === Subscription_Interval_Type.Monthly ? "Mensuel" : "Annuel";

        const commitmentText = commitment > 1 ? "Avec engagement" : "Sans engagement";

        const description = `${commitmentText} - ${paymentInterval}`;

        let cancelledText = "";

        const IS_SUBSCRIPTION_CANCELLED =
          subscriptionTransaction.status === Merchant_Subscription_Status.Cancelled;

        if (IS_SUBSCRIPTION_CANCELLED) {
          const formattedCancelledDate = format(new Date(endDate), "dd/MM/yyyy");
          cancelledText = `Annulé - (module activé jusqu'au ${formattedCancelledDate})`;
        }

        return (
          <Box mb="s" key={subscription._id}>
            <SubscriptionCard
              onPress={() => handleSubscriptionDetails(subscriptionTransaction)}
              title={subscriptionTransaction.subscription.name}
              description={description}
              price={totalAmount}
              cancelledText={cancelledText}
            />
          </Box>
        );
      })}

      {!hasAllPermissions && (
        <NewSubscriptionCard
          text="Ajouter un abonnement"
          icon={<NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />}
          onPress={handleAddSubscription}
        />
      )}
    </Box>
  );
};

export default SubscriptionsList;
