import { FlatList, TouchableOpacity, View } from "react-native";

import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";

interface ColorListSelectProps {
  onPress: (color: string) => void;
  selectedColor: string;
}

const COLORS = [
  {
    name: "BRIGHT_BLUE",
    value: PALETTE.brightBlue,
  },
  {
    name: "GREY",
    value: PALETTE.gray,
  },
  {
    name: "LIGHT_GREEN",
    value: PALETTE.lightGreen,
  },
  {
    name: "LIGHT_PURPLE",
    value: PALETTE.lightPurple,
  },
  {
    name: "LIGHT_ORANGE",
    value: PALETTE.lightOrange,
  },
  {
    name: "BLUE_SECONDARY",
    value: PALETTE.blueSecondary,
  },
  {
    name: "DARK_BLUE",
    value: PALETTE.darkBlue,
  },
  {
    name: "RED_BACKGROUND",
    value: PALETTE.redBackground,
  },
  {
    name: "RED",
    value: PALETTE.red,
  },
  {
    name: "YELLOW",
    value: PALETTE.yellow,
  },
  {
    name: "SALMON_STATUS",
    value: PALETTE.saumonStatus,
  },
  {
    name: "MID_ORANGE",
    value: PALETTE.BOOKING_CONFIRMED,
  },
  {
    name: "MID_GREEN",
    value: PALETTE.BOOKING_MODIFIED,
  },
];

const ColorListSelect = ({ onPress, selectedColor }: ColorListSelectProps) => {
  return (
    <Box>
      <FlatList
        data={COLORS}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={item => item.name}
        renderItem={({ item }) => {
          const IS_SELECTED = item.value === selectedColor;
          const bgColor = item.value;

          return (
            <TouchableOpacity
              onPress={() => onPress(item.value)}
              style={{ marginRight: 8 }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 8,
                  borderColor: IS_SELECTED ? PALETTE.green : "none",
                  borderWidth: IS_SELECTED ? 2 : 0,
                  backgroundColor: bgColor,
                }}
              />
            </TouchableOpacity>
          );
        }}
      />
    </Box>
  );
};

export default ColorListSelect;
