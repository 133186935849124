import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { FlatList } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import Loader from "../../../../components/Loader/index";
import PdfTemplateItemCard from "../../../../components/PdfTemplatesSettings/PdfTemplateItemCard";
import ScreenHeader from "../../../../components/ScreenHeader";
import Touchable from "../../../../components/Touchable";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { BookingPdfPrintTemplateFragment } from "../../../../graphql/generated/schema";
import {
  useDeleteBookingPdfPrintTemplateMutation,
  useGetBookingPdfPrintTemplatesLazyQuery,
  useUpdateBookingPdfPrintTemplateMutation,
} from "../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";

import BookingPdfPrintTemplatesSettingsDetails from "./BookingPdfPrintTemplatesSettingsDetails";

interface BookingPdfPrintTemplatesSettingsProps {
  goBack?: () => void;
}

const BookingPdfPrintTemplatesSettings = ({
  goBack,
}: BookingPdfPrintTemplatesSettingsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(false);
  const [bookingPdfTemplates, setBookingPdfTemplates] = useState<
    BookingPdfPrintTemplateFragment[]
  >([]);
  const [showInline, setShowInline] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [getBookingPdfPrintTemplates, { fetchMore }] =
    useGetBookingPdfPrintTemplatesLazyQuery();
  const [updateBookingPdfPrintTemplate] = useUpdateBookingPdfPrintTemplateMutation();
  const [deletePdfTemplate] = useDeleteBookingPdfPrintTemplateMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: bookingPdfTemplates.length,
          },
        },
      });

      setBookingPdfTemplates(services => {
        return [...services, ...(data?.getBookingPdfPrintTemplates || [])];
      });
    } catch (err) {
      console.log("ERROR_FETCHING_MORE_BOOKING_PDF_PRINT_TEMPLATES", err);
    }
  };

  const handleGePdfPrintTemplates = async () => {
    try {
      const response = await getBookingPdfPrintTemplates({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            offset: 0,
            limit: 10,
          },
        },
      });

      if (response.data?.getBookingPdfPrintTemplates) {
        setBookingPdfTemplates(response.data.getBookingPdfPrintTemplates);
      }
    } catch (err) {
      console.log("ERROR_FETCHING_BOOKING_PDF_PRINT_TEMPLATES", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGePdfPrintTemplates();
    }, []),
  );

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setShowInline(true);
      setSelectedId("");
    } else {
      navigation.navigate("BOOKING_PDF_TEMPLATE_DETAILS");
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setShowInline(true);
      setSelectedId(id);
    } else {
      navigation.navigate("BOOKING_PDF_TEMPLATE_DETAILS", { id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const displaySelectedTab = () => {
    return (
      <Box flex={1} height="100%">
        <BookingPdfPrintTemplatesSettingsDetails
          id={selectedId}
          goBack={handleGoBack}
          isDuplicate={isDuplicate}
        />
      </Box>
    );
  };

  const handleUpdatedEnabled = async (id: string, isEnabled: boolean) => {
    try {
      const updatedTemplate = await updateBookingPdfPrintTemplate({
        variables: {
          updateBookingPdfPrintTemplateId: id,
          bookingPdfPrintTemplate: {
            isEnabled,
          },
        },
      });

      if (updatedTemplate.data?.updateBookingPdfPrintTemplate) {
        setBookingPdfTemplates(templates => {
          return templates.map(t => {
            if (t._id === id) {
              return {
                ...t,
                isEnabled,
              };
            }
            return t;
          });
        });
      }
    } catch (err) {
      console.log("ERROR_UPDATING_BOOKING_PDF_PRINT_TEMPLATE", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATING_BOOKING_PDF_PRINT_TEMPLATE",
            message:
              "Une erreur est survenue lors de la mise à jour du modèle d'impression",
          },
        ],
        "error",
      );
    }
  };

  const handleTemplateDelete = async (id: string) => {
    try {
      const response = await deletePdfTemplate({
        variables: {
          deleteBookingPdfPrintTemplateId: id,
        },
      });

      if (response.data?.deleteBookingPdfPrintTemplate) {
        await handleGePdfPrintTemplates();
      }
    } catch (err) {
      console.log("ERROR_DELETING_BOOKING_PDF_PRINT_TEMPLATE", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETING_BOOKING_PDF_PRINT_TEMPLATE",
            message:
              "Une erreur est survenue lors de la suppression du modèle d'impression",
          },
        ],
        "error",
      );
    }
  };

  const handleTemplateDuplicate = async (id: string) => {
    if (goBack) {
      setShowInline(true);
      setSelectedId(id);
    } else {
      navigation.navigate("BOOKING_PDF_TEMPLATE_DETAILS", { id, isDuplicate: true });
    }
  };

  // const handleTemplatePreview = async (id: string) => {};

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Modèles d'impression"
          description="Personnalisez vos modèles d'impression"
          hasBackButton
          rightButtons={
            <Touchable onPress={handleAddNew}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </Touchable>
          }
          onBackPress={handleGoBack}
        />

        <Box mt="m" mb="s">
          <CustomText variant="label" color="primaryTextColor">
            Liste des modeles d'impressions
          </CustomText>
        </Box>

        <FlatList
          showsVerticalScrollIndicator={false}
          data={bookingPdfTemplates}
          keyExtractor={item => item._id}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              {/* <TouchableOpacity onPress={() => handleEdit(item._id)}> */}
              <PdfTemplateItemCard
                isEnabled={item.isEnabled}
                name={item.name}
                updatedAt={item.updatedAt}
                onUpdatedEnabled={isEnabled => handleUpdatedEnabled(item._id, isEnabled)}
                onDelete={() => handleTemplateDelete(item._id)}
                onDuplicate={() => handleTemplateDuplicate(item._id)}
                onEdit={() => handleEdit(item._id)}
                // onPreview={() => handleTemplatePreview(item._id)}
              />
              {/* </TouchableOpacity> */}
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    }
    return displaySettingsList();
  };

  return displayContent();
};

export default BookingPdfPrintTemplatesSettings;
