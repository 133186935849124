// /* eslint-disable @typescript-eslint/no-explicit-any */
// import { useContext, useEffect, useState } from "react";

// import { BookingContext } from "../../contexts/BookingContext";
// import type {
//   BookingFragment,
//   SelectedBookingTableFragment,
// } from "../../graphql/generated/schema";
// import {
//   Booking_Status,
//   Click_Status,
//   Module_Listing_Client_Format,
//   Modules,
//   useUpdateBookingMutation,
// } from "../../graphql/generated/schema";
// import { LINE_THICKNESS } from "../../theme";
// import { formatCurrencyPrice, removeTypeNames } from "../../utils/common";
// import Box from "../Base/Box";
// import BookingCardAssignTablesModal from "../BookingList/BookingCardAssignTablesModal";
// import BookingListCard from "../BookingList/BookingListCard";
// import CashRegisterTicketListCard from "../CashRegister/CashRegisterTicketListCard";
// import OnlineOrdersListCard from "../OnlineOrdersList/OnlineOrderListCard";
// import { ServiceListHeader } from "../ServiceListHeader";
// import { ServiceListHeaderItem } from "../ServiceListHeader/ServiceListHeaderItem";

// import type { ModuleListData } from "./common";

// interface ModuleListItemRendererProps {
//   type: Modules;
//   item: ModuleListData;
//   onListItemPress: (itemId: string) => void;
//   onListItemExtraPress: (itemId: string) => void;
//   onListItemOnUpdateComplete: (itemId: string) => void;
//   onListItemOnUpdateError: (itemId: string) => void;
//   onDelete: (itemId: string) => void;
//   isDisabled?: boolean;
//   isStatusChangeDisabled?: boolean;
//   isTablePressDisabled?: boolean;
//   isExtraActionButtonDisabled?: boolean;
//   groupBySlotInterval?: boolean;
//   includeCancelled?: boolean;
//   nameFormat?: Module_Listing_Client_Format;
//   selectedId: string;
//   setSelectedId: (v: string) => void;
//   selectedDate: Date;
//   selectedFilter: string;
//   showClientComment?: boolean;
//   showCreationDate?: boolean;
//   showEventType?: boolean;
//   selectedServiceId?: string;
//   selectedStatusFilters: (Booking_Status | Click_Status)[];
// }

// const ModuleListItemRenderer = ({
//   type,
//   item,
//   onListItemExtraPress,
//   onListItemPress,
//   onDelete,
//   groupBySlotInterval = true,
//   nameFormat = Module_Listing_Client_Format.LastFirstName,
//   isDisabled = false,
//   isStatusChangeDisabled = false,
//   isTablePressDisabled = false,
//   isExtraActionButtonDisabled = false,
//   selectedId,
//   setSelectedId,
//   selectedDate,
//   showClientComment,
//   showCreationDate,
//   showEventType,
//   selectedFilter,
//   includeCancelled,
//   onListItemOnUpdateComplete,
//   onListItemOnUpdateError,
//   selectedServiceId,
//   selectedStatusFilters,
// }: ModuleListItemRendererProps) => {
//   const bookingContext = useContext(BookingContext);
//   // const navigation = useNavigation();
//   const [isBookingTableAssignModalOpen, setIsBookingTableAssignModalOpen] =
//     useState(false);
//   const [bookingAssignTableId, setBookingAssignTableId] = useState("");

//   const [updateBooking] = useUpdateBookingMutation();

//   const { serviceName, accumulatedTotalInService, totalInService, slots } = item;

//   const [slotsVisble, setslotsVisble] = useState(
//     slots.map(s => ({ slotId: s.slotId, isOpen: true })),
//   );

//   useEffect(() => {
//     setslotsVisble(prev => {
//       return slots.map(s => {
//         const existingSlot = prev.find(p => p.slotId === s.slotId);

//         if (existingSlot) {
//           return existingSlot;
//         }

//         return { slotId: s.slotId, isOpen: true };
//       });
//     });
//   }, [slots]);

//   const formatServiceTotal = () => {
//     const suffix = type === Modules.Reservation ? "Réservations" : "Commandes";

//     return `${totalInService} ${suffix}`;
//   };

//   const toggleSlot = (slotId: string) => {
//     setslotsVisble(prev =>
//       prev.map(s => (s.slotId === slotId ? { ...s, isOpen: !s.isOpen } : s)),
//     );
//   };

//   const isSlotOpen = (slotId: string) => {
//     return !!slotsVisble.find(s => s.slotId === slotId)?.isOpen;
//   };

//   const handleUpdateBooking = async (
//     bookingId: string,
//     selectedTables: SelectedBookingTableFragment[],
//   ) => {
//     try {
//       const updates = removeTypeNames(selectedTables) || [];

//       await updateBooking({
//         variables: {
//           bookingId: bookingId,
//           booking: {
//             tables: updates,
//           },
//         },
//       });

//       onListItemOnUpdateComplete(bookingId);
//     } catch (err) {
//       console.log("err updating booking tables", err);

//       onListItemOnUpdateError(bookingId);
//     }
//   };

//   const handleGoToBookingFloor = (booking: BookingFragment) => {
//     const { _id: bookingId } = booking;

//     bookingContext.setTablesForBookingAssignment(booking.tables);
//     setBookingAssignTableId(bookingId);
//     setIsBookingTableAssignModalOpen(true);

//     // const selectedTables = tables.map(t => t.tableId);

//     // navigation.navigate("BOOKING_FLOORS", {
//     //   selectedTables,
//     //   selectedDate: date,
//     //   bookingPersons: nbPersons,
//     //   onSubmit: (consolidatedSelectedTables: SelectedBookingTableFragment[]) => {
//     //     handleUpdateBooking(bookingId, consolidatedSelectedTables);
//     //     navigation.goBack();
//     //   },
//     // });
//   };

//   const renderItem = (itemData: any) => {
//     if (type === Modules.Reservation) {
//       return (
//         <BookingListCard
//           item={itemData}
//           onTablePress={() => handleGoToBookingFloor(itemData)}
//           onPress={() => onListItemPress(itemData?._id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
//           onDelete={() => onDelete(itemData?._id)}
//           isDisabled={isDisabled}
//           isExtraActionDisabled={isExtraActionButtonDisabled}
//           isTablePressDisabled={isTablePressDisabled}
//           isStatusChangeDisabled={isStatusChangeDisabled}
//           nameFormat={nameFormat}
//           showClientComment={showClientComment}
//           showCreationDate={showCreationDate}
//           showEventType={showEventType}
//           onCompleteHandler={onListItemOnUpdateComplete}
//           onErrorHandler={onListItemOnUpdateError}
//         />
//       );
//     }
//     if (type === Modules.OnlineSales) {
//       return (
//         <OnlineOrdersListCard
//           item={itemData}
//           onPress={() => onListItemPress(itemData?._id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
//           isDisabled={isDisabled}
//           nameFormat={nameFormat}
//           showClientComment={showClientComment}
//           showCreationDate={showCreationDate}
//           showEventType={showEventType}
//           onDelete={() => onDelete(itemData?._id)}
//           onCompleteHandler={onListItemOnUpdateComplete}
//           onErrorHandler={onListItemOnUpdateError}
//         />
//       );
//     }
//     if (type === Modules.CashRegister) {
//       return (
//         <CashRegisterTicketListCard
//           {...itemData}
//           onPress={() => onListItemPress(itemData.id)}
//           onExtraActionPress={() => onListItemExtraPress(itemData.id)}
//           isDisabled={isDisabled}
//         />
//       );
//     }
//   };

//   const displayAccumulatedTotal = (total: number) => {
//     if (type === Modules.OnlineSales) {
//       const reducedDataFromSlots = slots.reduce((acc, curr) => {
//         return [...acc, ...curr.data];
//       }, []);

//       if (selectedFilter === "ALL") {
//         const allAmount = reducedDataFromSlots
//           ?.filter(item => {
//             if (
//               (item.status === Click_Status.CancelledByOwner ||
//                 item.status === Click_Status.CancelledByUser) &&
//               !includeCancelled
//             )
//               return false;

//             if (item.status === Click_Status.Noshow) return false;

//             return true;
//           })
//           ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);
//         const formattedPrice = formatCurrencyPrice(allAmount, "fr", "eur");

//         return `${formattedPrice}`;
//       }

//       if (selectedFilter === "PENDING") {
//         const pendingAmount = reducedDataFromSlots
//           ?.filter(
//             item =>
//               item.status === Click_Status.New ||
//               item.status === Click_Status.ModifiedByOwner ||
//               item.status === Click_Status.ModifiedByUser,
//           )
//           ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

//         const formattedPrice = formatCurrencyPrice(pendingAmount, "fr", "eur");

//         return `${formattedPrice}`;
//       }

//       if (selectedFilter === "VALIDATED") {
//         const validatedAmount = reducedDataFromSlots
//           ?.filter(
//             item =>
//               item.status === Booking_Status.Validated ||
//               item.status === Booking_Status.AutomaticValidated,
//           )
//           ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

//         const formattedPrice = formatCurrencyPrice(validatedAmount, "fr", "eur");

//         return `${formattedPrice}`;
//       }

//       const otherAmount = reducedDataFromSlots
//         ?.filter(
//           item =>
//             item.status !== Booking_Status.New &&
//             item.status !== Booking_Status.Validated &&
//             item.status !== Booking_Status.AutomaticValidated &&
//             item.status !== Booking_Status.ModifiedByOwner &&
//             item.status !== Booking_Status.ModifiedByUser,
//         )
//         ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

//       const formattedPrice = formatCurrencyPrice(otherAmount, "fr", "eur");

//       return `${formattedPrice}`;
//     }

//     if (type === Modules.Reservation) {
//       const reducedDataFromSlots = slots.reduce((acc, curr) => {
//         return [...acc, ...curr.data];
//       }, []);

//       if (selectedFilter === "ALL") {
//         const allAmount = reducedDataFromSlots
//           ?.filter(item => {
//             if (
//               (item.status === Booking_Status.CancelledByOwner ||
//                 item.status === Booking_Status.CancelledByUser) &&
//               !includeCancelled
//             )
//               return false;

//             if (item.status === Booking_Status.Noshow) return false;

//             return true;
//           })
//           ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

//         return `${allAmount}`;
//       }

//       if (selectedFilter === "PENDING") {
//         const pendingDataLength = reducedDataFromSlots
//           ?.filter(
//             item =>
//               item.status === Booking_Status.New ||
//               item.status === Booking_Status.ModifiedByOwner ||
//               item.status === Booking_Status.ModifiedByUser,
//           )
//           ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

//         return `${pendingDataLength}`;
//       }

//       if (selectedFilter === "VALIDATED") {
//         const validatedDataLength = reducedDataFromSlots
//           ?.filter(
//             item =>
//               item.status === Booking_Status.Validated ||
//               item.status === Booking_Status.AutomaticValidated,
//           )
//           ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

//         return `${validatedDataLength}`;
//       }

//       const otherDataLength = reducedDataFromSlots
//         ?.filter(
//           item =>
//             item.status !== Booking_Status.New &&
//             item.status !== Booking_Status.Validated &&
//             item.status !== Booking_Status.AutomaticValidated &&
//             item.status !== Booking_Status.ModifiedByOwner &&
//             item.status !== Booking_Status.ModifiedByUser,
//         )
//         ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

//       return `${otherDataLength}`;
//     }
//     return `${total}`;
//   };

//   const displayAccumulatedInSlotTotal = (accumulatedTotalInSlot: number) => {
//     if (type === Modules.Reservation) return `${accumulatedTotalInSlot} `;
//     if (type === Modules.OnlineSales) {
//       return `${formatCurrencyPrice(accumulatedTotalInSlot, "fr", "eur")}`;
//     }
//     return `${accumulatedTotalInSlot}`;
//   };

//   const displaySlotData = (slotId: string, data: any[]) => {
//     if (!isSlotOpen(slotId)) return <></>;

//     return (
//       <>
//         {data.map(itemData => {
//           const IS_SELECTED = itemData._id === selectedId;

//           return (
//             <Box
//               key={`${selectedDate}-${slotId}-${itemData._id}`}
//               paddingVertical="xs"
//               borderBottomColor="disabled"
//               borderBottomWidth={LINE_THICKNESS}
//             >
//               <Box
//                 paddingVertical="xs"
//                 paddingHorizontal="s"
//                 marginHorizontal="xs"
//                 borderRadius="button"
//                 borderColor="success"
//                 borderWidth={IS_SELECTED ? LINE_THICKNESS : 0}
//               >
//                 {renderItem(itemData)}
//               </Box>
//             </Box>
//           );
//         })}
//       </>
//     );

//     // return (
//     //   <FlatList
//     //     data={data}
//     //     listKey={slotId}
//     //     // listKey={item.servicId}
//     //     scrollEnabled={false}
//     //     showsVerticalScrollIndicator={false}
//     //     keyExtractor={i => i._id}
//     //     renderItem={({ item: itemData }) => {
//     //       const IS_SELECTED = itemData._id === selectedId;

//     //       return (
//     //         <Box
//     //           paddingVertical="xs"
//     //           borderBottomColor="disabled"
//     //           borderBottomWidth={LINE_THICKNESS}
//     //         >
//     //           <Box
//     //             paddingVertical="xs"
//     //             paddingHorizontal="s"
//     //             marginHorizontal="xs"
//     //             borderRadius="button"
//     //             borderColor="success"
//     //             borderWidth={IS_SELECTED ? LINE_THICKNESS : 0}
//     //           >
//     //             {renderItem(itemData)}
//     //           </Box>
//     //         </Box>
//     //       );
//     //     }}
//     //   />
//     // );
//   };

//   return (
//     <Box>
//       {groupBySlotInterval && (
//         <ServiceListHeader
//           moduleType={type}
//           serviceName={serviceName}
//           totalBookingsText={formatServiceTotal()}
//           totalPersonsText={displayAccumulatedTotal(accumulatedTotalInService)}
//         />
//       )}

//       {/* <FlatList
//         data={slots}
//         renderItem={({ item: slot }) => {
//           const { slotId, accumulatedTotalInSlot, data, slotTime, totalInSlot } = slot;

//           return (
//             <Box key={`${selectedDate}-${slotId}`}>
//               {groupBySlotInterval && (
//                 <ServiceListHeaderItem
//                   time={slotTime}
//                   isClosed={isSlotOpen(slotId)}
//                   moduleType={type}
//                   onPress={() => toggleSlot(slotId)}
//                   totalBookingsText={`${totalInSlot}`}
//                   totalPersonsText={displayAccumulatedInSlotTotal(accumulatedTotalInSlot)}
//                 />
//               )}

//               {displaySlotData(slotId, data)}
//             </Box>
//           );
//         }}
//       /> */}

//       {slots.map((slot, idx) => {
//         const { slotId, accumulatedTotalInSlot, data, slotTime, totalInSlot } = slot;

//         return (
//           <Box key={`${selectedDate}-${slotId}`}>
//             {groupBySlotInterval && (
//               <ServiceListHeaderItem
//                 time={slotTime}
//                 isClosed={isSlotOpen(slotId)}
//                 moduleType={type}
//                 onPress={() => toggleSlot(slotId)}
//                 totalBookingsText={`${totalInSlot}`}
//                 totalPersonsText={displayAccumulatedInSlotTotal(accumulatedTotalInSlot)}
//               />
//             )}

//             {displaySlotData(slotId, data)}
//           </Box>
//         );
//       })}

//       {isBookingTableAssignModalOpen && (
//         <BookingCardAssignTablesModal
//           isOpen={isBookingTableAssignModalOpen}
//           setIsOpen={setIsBookingTableAssignModalOpen}
//           bookingId={bookingAssignTableId}
//           onSubmit={consolidatedSelectedTables => {
//             handleUpdateBooking(bookingAssignTableId, consolidatedSelectedTables);
//           }}
//           selectedServiceId={selectedServiceId}
//         />
//       )}
//     </Box>
//   );
// };

// export default ModuleListItemRenderer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";

import { BookingContext } from "../../contexts/BookingContext";
import type {
  BookingFragment,
  SelectedBookingTableFragment,
} from "../../graphql/generated/schema";
import {
  Booking_Status,
  Click_Status,
  Module_Listing_Client_Format,
  Modules,
  useUpdateBookingMutation,
} from "../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../theme";
import { formatCurrencyPrice, removeTypeNames } from "../../utils/common";
import Box from "../Base/Box";
import BookingCardAssignTablesModal from "../BookingList/BookingCardAssignTablesModal";
import BookingListCard from "../BookingList/BookingListCard";
import CashRegisterTicketListCard from "../CashRegister/CashRegisterTicketListCard";
import OnlineOrdersListCard from "../OnlineOrdersList/OnlineOrderListCard";
import { ServiceListHeader } from "../ServiceListHeader";
import { ServiceListHeaderItem } from "../ServiceListHeader/ServiceListHeaderItem";

import type { ModuleListData } from "./common";

interface ModuleListItemRendererProps {
  type: Modules;
  item: ModuleListData;
  onListItemPress: (itemId: string) => void;
  onListItemExtraPress: (itemId: string) => void;
  onListItemOnUpdateComplete: (itemId: string) => void;
  onListItemOnUpdateError: (itemId: string) => void;
  onDelete: (itemId: string) => void;
  isDisabled?: boolean;
  isStatusChangeDisabled?: boolean;
  isTablePressDisabled?: boolean;
  isExtraActionButtonDisabled?: boolean;
  groupBySlotInterval?: boolean;
  includeCancelled?: boolean;
  nameFormat?: Module_Listing_Client_Format;
  selectedId: string;
  setSelectedId: (v: string) => void;
  selectedDate: Date;
  selectedFilter: string;
  showClientComment?: boolean;
  showCreationDate?: boolean;
  showEventType?: boolean;
  showAllergies?: boolean | null;
  showBasket?: boolean | null;
  selectedServiceId?: string;
  selectedStatusFilters: (Booking_Status | Click_Status)[];
}

const ModuleListItemRenderer = ({
  type,
  item,
  onListItemExtraPress,
  onListItemPress,
  onDelete,
  groupBySlotInterval = true,
  nameFormat = Module_Listing_Client_Format.LastFirstName,
  isDisabled = false,
  isStatusChangeDisabled = false,
  isTablePressDisabled = false,
  isExtraActionButtonDisabled = false,
  selectedId,
  setSelectedId,
  selectedDate,
  showClientComment,
  showCreationDate,
  showEventType,
  selectedFilter,
  includeCancelled,
  onListItemOnUpdateComplete,
  onListItemOnUpdateError,
  selectedServiceId,
  selectedStatusFilters,
  showAllergies,
  showBasket,
}: ModuleListItemRendererProps) => {
  const bookingContext = useContext(BookingContext);
  // const navigation = useNavigation();
  const [isBookingTableAssignModalOpen, setIsBookingTableAssignModalOpen] =
    useState(false);
  const [bookingAssignTableId, setBookingAssignTableId] = useState("");

  const [updateBooking] = useUpdateBookingMutation();

  const { serviceName, accumulatedTotalInService, totalInService, slots } = item;

  const [slotsVisble, setslotsVisble] = useState(
    slots.map(s => ({ slotId: s.slotId, isOpen: true })),
  );

  useEffect(() => {
    setslotsVisble(prev => {
      return slots.map(s => {
        const existingSlot = prev.find(p => p.slotId === s.slotId);

        if (existingSlot) {
          return existingSlot;
        }

        return { slotId: s.slotId, isOpen: true };
      });
    });
  }, [slots]);

  const formatServiceTotal = () => {
    const suffix = type === Modules.Reservation ? "Réservations" : "Commandes";

    // if (type === Modules.OnlineSales) {
    //   const reducedDataFromSlots = slots.reduce((acc, curr) => {
    //     return [...acc, ...curr.data];
    //   }, []);

    //   if (selectedFilter === "ALL") {
    //     const allDataLength = reducedDataFromSlots?.filter(item => {
    //       if (
    //         (item.status === Click_Status.CancelledByOwner ||
    //           item.status === Click_Status.CancelledByUser) &&
    //         !includeCancelled
    //       )
    //         return false;

    //       if (item.status === Click_Status.Noshow) return false;

    //       return true;
    //     })?.length;

    //     return `${allDataLength} ${suffix}`;
    //   }

    //   if (selectedFilter === "PENDING") {
    //     const pendingDataLength = reducedDataFromSlots?.filter(
    //       item =>
    //         item.status === Click_Status.New ||
    //         item.status === Click_Status.ModifiedByOwner ||
    //         item.status === Click_Status.ModifiedByUser,
    //     )?.length;

    //     return `${pendingDataLength} ${suffix}`;
    //   }

    //   if (selectedFilter === "VALIDATED") {
    //     const validatedDataLength = reducedDataFromSlots?.filter(
    //       item =>
    //         item.status === Click_Status.Validated ||
    //         item.status === Click_Status.AutomaticValidated,
    //     )?.length;

    //     return `${validatedDataLength} ${suffix}`;
    //   }

    //   const otherDataLength = reducedDataFromSlots?.filter(
    //     item =>
    //       item.status !== Click_Status.New &&
    //       item.status !== Click_Status.Validated &&
    //       item.status !== Click_Status.AutomaticValidated &&
    //       item.status !== Click_Status.ModifiedByOwner &&
    //       item.status !== Click_Status.ModifiedByUser,
    //   )?.length;

    //   return `${otherDataLength} ${suffix}`;
    // }

    // if (type === Modules.Reservation) {
    //   const reducedDataFromSlots = slots.reduce((acc, curr) => {
    //     return [...acc, ...curr.data];
    //   }, []);

    //   // console.log("reducedDataFromSlots", reducedDataFromSlots);

    //   if (selectedFilter === "ALL") {
    //     const allDataLength = reducedDataFromSlots?.filter(item => {
    //       if (
    //         (item.status === Booking_Status.CancelledByOwner ||
    //           item.status === Booking_Status.CancelledByUser) &&
    //         !includeCancelled
    //       )
    //         return false;

    //       if (item.status === Booking_Status.Noshow) return false;

    //       return true;
    //     })?.length;

    //     return `${allDataLength} ${suffix}`;
    //   }

    //   if (selectedFilter === "PENDING") {
    //     const pendingDataLength = reducedDataFromSlots?.filter(
    //       item =>
    //         item.status === Booking_Status.New ||
    //         item.status === Booking_Status.ModifiedByOwner ||
    //         item.status === Booking_Status.ModifiedByUser,
    //     )?.length;

    //     return `${pendingDataLength} ${suffix}`;
    //   }

    //   if (selectedFilter === "VALIDATED") {
    //     const validatedDataLength = reducedDataFromSlots?.filter(
    //       item =>
    //         item.status === Booking_Status.Validated ||
    //         item.status === Booking_Status.AutomaticValidated,
    //     )?.length;

    //     return `${validatedDataLength} ${suffix}`;
    //   }

    //   const otherDataLength = reducedDataFromSlots?.filter(
    //     item =>
    //       item.status !== Booking_Status.New &&
    //       item.status !== Booking_Status.Validated &&
    //       item.status !== Booking_Status.AutomaticValidated &&
    //       item.status !== Booking_Status.ModifiedByOwner &&
    //       item.status !== Booking_Status.ModifiedByUser,
    //   )?.length;

    //   return `${otherDataLength} ${suffix}`;
    // }

    return `${totalInService} ${suffix}`;
  };

  const toggleSlot = (slotId: string) => {
    setslotsVisble(prev =>
      prev.map(s => (s.slotId === slotId ? { ...s, isOpen: !s.isOpen } : s)),
    );
  };

  const isSlotOpen = (slotId: string) => {
    return !!slotsVisble.find(s => s.slotId === slotId)?.isOpen;
  };

  const handleUpdateBooking = async (
    bookingId: string,
    selectedTables: SelectedBookingTableFragment[],
  ) => {
    try {
      const updates = removeTypeNames(selectedTables) || [];

      await updateBooking({
        variables: {
          bookingId: bookingId,
          booking: {
            tables: updates,
          },
        },
      });

      onListItemOnUpdateComplete(bookingId);
    } catch (err) {
      console.log("err updating booking tables", err);

      onListItemOnUpdateError(bookingId);
    }
  };

  const handleGoToBookingFloor = (booking: BookingFragment) => {
    const { _id: bookingId } = booking;

    bookingContext.setTablesForBookingAssignment(booking.tables);
    setBookingAssignTableId(bookingId);
    setIsBookingTableAssignModalOpen(true);

    // const selectedTables = tables.map(t => t.tableId);

    // navigation.navigate("BOOKING_FLOORS", {
    //   selectedTables,
    //   selectedDate: date,
    //   bookingPersons: nbPersons,
    //   onSubmit: (consolidatedSelectedTables: SelectedBookingTableFragment[]) => {
    //     handleUpdateBooking(bookingId, consolidatedSelectedTables);
    //     navigation.goBack();
    //   },
    // });
  };

  const renderItem = (itemData: any) => {
    if (type === Modules.Reservation) {
      return (
        <BookingListCard
          item={itemData}
          onTablePress={() => handleGoToBookingFloor(itemData)}
          onPress={() => onListItemPress(itemData?._id)}
          onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
          onDelete={() => onDelete(itemData?._id)}
          isDisabled={isDisabled}
          isExtraActionDisabled={isExtraActionButtonDisabled}
          isTablePressDisabled={isTablePressDisabled}
          isStatusChangeDisabled={isStatusChangeDisabled}
          nameFormat={nameFormat}
          showClientComment={showClientComment}
          showCreationDate={showCreationDate}
          showEventType={showEventType}
          showAllergies={showAllergies}
          showBasket={showBasket}
          onCompleteHandler={onListItemOnUpdateComplete}
          onErrorHandler={onListItemOnUpdateError}
        />
      );
    }
    if (type === Modules.OnlineSales) {
      return (
        <OnlineOrdersListCard
          item={itemData}
          onPress={() => onListItemPress(itemData?._id)}
          onExtraActionPress={() => onListItemExtraPress(itemData?._id)}
          isDisabled={isDisabled}
          nameFormat={nameFormat}
          showClientComment={showClientComment}
          showCreationDate={showCreationDate}
          showEventType={showEventType}
          onDelete={() => onDelete(itemData?._id)}
          onCompleteHandler={onListItemOnUpdateComplete}
          onErrorHandler={onListItemOnUpdateError}
        />
      );
    }
    if (type === Modules.CashRegister) {
      return (
        <CashRegisterTicketListCard
          {...itemData}
          onPress={() => onListItemPress(itemData.id)}
          onExtraActionPress={() => onListItemExtraPress(itemData.id)}
          isDisabled={isDisabled}
        />
      );
    }
  };

  const displayAccumulatedTotal = (total: number) => {
    if (type === Modules.OnlineSales) {
      const reducedDataFromSlots = slots.reduce((acc, curr) => {
        return [...acc, ...curr.data];
      }, []);

      if (selectedFilter === "ALL") {
        const allAmount = reducedDataFromSlots
          ?.filter(item => {
            if (
              (item.status === Click_Status.CancelledByOwner ||
                item.status === Click_Status.CancelledByUser) &&
              !includeCancelled
            )
              return false;

            if (item.status === Click_Status.Noshow) return false;

            return true;
          })
          ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);
        const formattedPrice = formatCurrencyPrice(allAmount, "fr", "eur");

        return `${formattedPrice}`;
      }

      if (selectedFilter === "PENDING") {
        const pendingAmount = reducedDataFromSlots
          ?.filter(
            item =>
              item.status === Click_Status.New ||
              item.status === Click_Status.ModifiedByOwner ||
              item.status === Click_Status.ModifiedByUser,
          )
          ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

        const formattedPrice = formatCurrencyPrice(pendingAmount, "fr", "eur");

        return `${formattedPrice}`;
      }

      if (selectedFilter === "VALIDATED") {
        const validatedAmount = reducedDataFromSlots
          ?.filter(
            item =>
              item.status === Booking_Status.Validated ||
              item.status === Booking_Status.AutomaticValidated,
          )
          ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

        const formattedPrice = formatCurrencyPrice(validatedAmount, "fr", "eur");

        return `${formattedPrice}`;
      }

      const otherAmount = reducedDataFromSlots
        ?.filter(
          item =>
            item.status !== Booking_Status.New &&
            item.status !== Booking_Status.Validated &&
            item.status !== Booking_Status.AutomaticValidated &&
            item.status !== Booking_Status.ModifiedByOwner &&
            item.status !== Booking_Status.ModifiedByUser,
        )
        ?.reduce((acc, curr) => acc + curr.totalDetails.totalWithTaxes, 0);

      const formattedPrice = formatCurrencyPrice(otherAmount, "fr", "eur");

      return `${formattedPrice}`;
    }

    if (type === Modules.Reservation) {
      const reducedDataFromSlots = slots.reduce((acc, curr) => {
        return [...acc, ...curr.data];
      }, []);

      if (selectedFilter === "ALL") {
        const allAmount = reducedDataFromSlots
          ?.filter(item => {
            if (
              (item.status === Booking_Status.CancelledByOwner ||
                item.status === Booking_Status.CancelledByUser) &&
              !includeCancelled
            )
              return false;

            if (item.status === Booking_Status.Noshow) return false;

            return true;
          })
          ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

        return `${allAmount}`;
      }

      if (selectedFilter === "PENDING") {
        const pendingDataLength = reducedDataFromSlots
          ?.filter(
            item =>
              item.status === Booking_Status.New ||
              item.status === Booking_Status.ModifiedByOwner ||
              item.status === Booking_Status.ModifiedByUser,
          )
          ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

        return `${pendingDataLength}`;
      }

      if (selectedFilter === "VALIDATED") {
        const validatedDataLength = reducedDataFromSlots
          ?.filter(
            item =>
              item.status === Booking_Status.Validated ||
              item.status === Booking_Status.AutomaticValidated,
          )
          ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

        return `${validatedDataLength}`;
      }

      const otherDataLength = reducedDataFromSlots
        ?.filter(
          item =>
            item.status !== Booking_Status.New &&
            item.status !== Booking_Status.Validated &&
            item.status !== Booking_Status.AutomaticValidated &&
            item.status !== Booking_Status.ModifiedByOwner &&
            item.status !== Booking_Status.ModifiedByUser,
        )
        ?.reduce((acc, curr) => acc + curr.nbPersons, 0);

      return `${otherDataLength}`;
    }
    return `${total}`;
  };

  const displayAccumulatedInSlotTotal = (accumulatedTotalInSlot: number) => {
    if (type === Modules.Reservation) return `${accumulatedTotalInSlot} `;
    if (type === Modules.OnlineSales) {
      return `${formatCurrencyPrice(accumulatedTotalInSlot, "fr", "eur")}`;
    }
    return `${accumulatedTotalInSlot}`;
  };

  const displaySlotData = (slotId: string, data: any[]) => {
    if (!isSlotOpen(slotId)) return <></>;

    return (
      <>
        {data.map(itemData => {
          const IS_SELECTED = itemData._id === selectedId;

          return (
            <Box
              key={`${selectedDate}-${slotId}-${itemData._id}`}
              paddingVertical="xs"
              borderBottomColor="disabled"
              borderBottomWidth={LINE_THICKNESS}
            >
              <Box
                paddingVertical="xs"
                paddingHorizontal="s"
                marginHorizontal="xs"
                borderRadius="button"
                borderColor="success"
                borderWidth={IS_SELECTED ? LINE_THICKNESS : 0}
              >
                {renderItem(itemData)}
              </Box>
            </Box>
          );
        })}
      </>
    );

    // return (
    //   <FlatList
    //     data={data}
    //     listKey={slotId}
    //     // listKey={item.servicId}
    //     scrollEnabled={false}
    //     showsVerticalScrollIndicator={false}
    //     keyExtractor={i => i._id}
    //     renderItem={({ item: itemData }) => {
    //       const IS_SELECTED = itemData._id === selectedId;

    //       return (
    //         <Box
    //           paddingVertical="xs"
    //           borderBottomColor="disabled"
    //           borderBottomWidth={LINE_THICKNESS}
    //         >
    //           <Box
    //             paddingVertical="xs"
    //             paddingHorizontal="s"
    //             marginHorizontal="xs"
    //             borderRadius="button"
    //             borderColor="success"
    //             borderWidth={IS_SELECTED ? LINE_THICKNESS : 0}
    //           >
    //             {renderItem(itemData)}
    //           </Box>
    //         </Box>
    //       );
    //     }}
    //   />
    // );
  };

  return (
    <Box>
      {groupBySlotInterval && (
        <ServiceListHeader
          moduleType={type}
          serviceName={serviceName}
          totalBookingsText={formatServiceTotal()}
          totalPersonsText={displayAccumulatedTotal(accumulatedTotalInService)}
        />
      )}

      {/* <FlatList
        data={slots}
        renderItem={({ item: slot }) => {
          const { slotId, accumulatedTotalInSlot, data, slotTime, totalInSlot } = slot;

          return (
            <Box key={`${selectedDate}-${slotId}`}>
              {groupBySlotInterval && (
                <ServiceListHeaderItem
                  time={slotTime}
                  isClosed={isSlotOpen(slotId)}
                  moduleType={type}
                  onPress={() => toggleSlot(slotId)}
                  totalBookingsText={`${totalInSlot}`}
                  totalPersonsText={displayAccumulatedInSlotTotal(accumulatedTotalInSlot)}
                />
              )}

              {displaySlotData(slotId, data)}
            </Box>
          );
        }}
      /> */}

      {slots.map((slot, idx) => {
        const { slotId, accumulatedTotalInSlot, data, slotTime, totalInSlot } = slot;

        return (
          <Box key={`${selectedDate}-${slotId}`}>
            {groupBySlotInterval && (
              <ServiceListHeaderItem
                time={slotTime}
                isClosed={isSlotOpen(slotId)}
                moduleType={type}
                onPress={() => toggleSlot(slotId)}
                totalBookingsText={`${totalInSlot}`}
                totalPersonsText={displayAccumulatedInSlotTotal(accumulatedTotalInSlot)}
              />
            )}

            {displaySlotData(slotId, data)}
          </Box>
        );
      })}

      {isBookingTableAssignModalOpen && (
        <BookingCardAssignTablesModal
          isOpen={isBookingTableAssignModalOpen}
          setIsOpen={setIsBookingTableAssignModalOpen}
          bookingId={bookingAssignTableId}
          onSubmit={consolidatedSelectedTables => {
            handleUpdateBooking(bookingAssignTableId, consolidatedSelectedTables);
          }}
          selectedServiceId={selectedServiceId}
        />
      )}
    </Box>
  );
};

export default ModuleListItemRenderer;
