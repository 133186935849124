import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import type { GetTotalOrdersDispersedByDayOfWeekQuery } from "../../../../graphql/generated/schema";
import { useGetTotalOrdersDispersedByDayOfWeekLazyQuery } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";

const OrdersFrequencyByDay = () => {
  const [loading, setLoading] = useState(true);
  const [orderStats, setOrderStats] = useState<
    GetTotalOrdersDispersedByDayOfWeekQuery["getTotalOrdersDispersedByDayOfWeek"] | null
  >(null);

  const [getBookingsByDayOfWeek] = useGetTotalOrdersDispersedByDayOfWeekLazyQuery();

  const handleGetBookingsTotalRecap = async () => {
    try {
      const { data } = await getBookingsByDayOfWeek();

      if (data?.getTotalOrdersDispersedByDayOfWeek) {
        setOrderStats(data.getTotalOrdersDispersedByDayOfWeek);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingsTotalRecap();
  }, []);

  if (loading) {
    return <ActivityIndicator color={PALETTE.green} size="small" />;
  }

  if (!orderStats) return null;

  return (
    <Box flex={1}>
      <Box>
        <CustomText variant="content" fontSize={16} color="primaryTextColor">
          Moyenne de commande
        </CustomText>
      </Box>
      <Box>
        <CustomText variant="text" color="lightGrey">
          En euro par jour de la semaine
        </CustomText>
      </Box>

      <Box mt="s" ml="m" mr="l">
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Lundi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.monday}
          </CustomText>
        </Box>

        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Mardi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.tuesday}
          </CustomText>
        </Box>

        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Mecredi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.wednesday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Jeudi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.thursday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Vendredi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.friday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Samedi
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.saturday}
          </CustomText>
        </Box>
        <Box
          mb="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomText variant="text" color="primaryTextColor">
            Dimanche
          </CustomText>
          <CustomText variant="label" color="primaryTextColor">
            {orderStats.sunday}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default OrdersFrequencyByDay;
