import { createContext } from "react";

import type { ERROR } from "../../utils/common";

export interface ErrorInfoSuccessAlertModalInterface {
  isOpen: boolean;
  title: string;
  errors: ERROR[];
  type: "error" | "info" | "success";

  openAlert: (title: string, errors: ERROR[], type: "error" | "info" | "success") => void;
  closeAlert: () => void;

  isPendingLoaderOpen: boolean;
  openInProgressLoader: () => void;
  closeInProgressLoader: () => void;
}

export const ErrorInfoSuccessAlertModalContext =
  createContext<ErrorInfoSuccessAlertModalInterface>(
    {} as ErrorInfoSuccessAlertModalInterface,
  );
