import React from "react";
import { Image } from "react-native";

import EMAIL from "../../../assets/icons/BASE/EMAIL.svg";
import INFO from "../../../assets/icons/BASE/INFO.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import ONLINE_SALES from "../../../assets/icons/BASE/ONLINE_SALES.svg";
import PHONE from "../../../assets/icons/BASE/PHONE.svg";
import REVIEW from "../../../assets/icons/BASE/REVIEW.svg";
import type { MerchantClientFragment } from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import { compactNumber } from "../../utils/common";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface ClientCardProps {
  isSelected: boolean;
  client: MerchantClientFragment;
}

const HEIGHT = 140;
const ICON_SIZE = 20;

const ClientCard = ({ isSelected, client }: ClientCardProps) => {
  const SELECTED_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "white",
    borderColor: "success",
    borderRadius: "button",
    borderWidth: 1,
  };
  const NOT_SELECTED_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "disabled",
    borderRadius: "button",
    borderWidth: 0,
  };

  const SELECTED_INFO_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "disabled",
    borderRadius: "button",
    borderWidth: 0,
  };
  const NOT_SELECTED_INFO_STYLES: React.ComponentProps<typeof Box> = {
    backgroundColor: "white",
    borderRadius: "button",
    borderWidth: 0,
  };

  const {
    firstName = "",
    picture,
    lastName,
    phone,
    email,
    isVIP,
    bookingOccurences,
    orderOccurences,
  } = client;

  const name = `${firstName} ${lastName}`;

  const displayPicture = () => {
    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: 47,
          height: 47,
          position: "absolute",
          right: 0,
          top: -18,
          borderRadius: 47 / 2,
        }}
      />
    );
  };

  return (
    <Box
      p="s"
      justifyContent="center"
      {...(isSelected ? SELECTED_STYLES : NOT_SELECTED_STYLES)}
    >
      <Box>
        <CustomText variant="label" color="labelColor">
          {name}
        </CustomText>

        {displayPicture()}
      </Box>
      <Box flexDirection="row" alignItems="center" pt="s">
        <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
        <CustomText pl="s" variant="description" color="primaryTextColor">
          {phone}
        </CustomText>
      </Box>
      {email && (
        <Box flexDirection="row" alignItems="center" pt="s">
          <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText pl="s" variant="description" color="primaryTextColor">
            {email}
          </CustomText>
        </Box>
      )}

      <Box flexDirection="row" alignItems="center" pt="s">
        {isVIP && (
          <Box
            flexDirection="row"
            alignItems="center"
            mr="s"
            p="s"
            {...(isSelected ? SELECTED_INFO_STYLES : NOT_SELECTED_INFO_STYLES)}
          >
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText
              pl="s"
              variant="label"
              color="labelColor"
              textTransform="uppercase"
            >
              VIP
            </CustomText>
          </Box>
        )}

        {client.averageRating > 0 && (
          <Box
            flexDirection="row"
            alignItems="center"
            mr="s"
            p="s"
            {...(isSelected ? SELECTED_INFO_STYLES : NOT_SELECTED_INFO_STYLES)}
          >
            <REVIEW height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
            <CustomText pl="s" variant="label" color="labelColor">
              {compactNumber(client.averageRating, "fr")}/5
            </CustomText>
          </Box>
        )}

        <Box
          flexDirection="row"
          alignItems="center"
          mr="s"
          p="s"
          {...(isSelected ? SELECTED_INFO_STYLES : NOT_SELECTED_INFO_STYLES)}
        >
          <OCCURENCES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText pl="s" variant="label" color="labelColor">
            {bookingOccurences}
          </CustomText>
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          mr="s"
          p="s"
          {...(isSelected ? SELECTED_INFO_STYLES : NOT_SELECTED_INFO_STYLES)}
        >
          <ONLINE_SALES height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText pl="s" variant="label" color="labelColor">
            {orderOccurences}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientCard;
