import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useMemo, useState } from "react";
import * as yup from "yup";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreatePricingRateInput,
  Pagination,
  TaxFragment,
} from "../../../../../graphql/generated/schema";
import {
  GetPricingRatesDocument,
  useCreatePricingRateMutation,
  useGetPricingRateLazyQuery,
  useGetTaxesLazyQuery,
  useUpdatePricingRateMutation,
} from "../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";
interface PricingRatesListDetailsProps {
  selectedRateId?: string;
  newValue?: boolean;
  goBack?: () => void;
}

const DEFAULT_PRICING_RATE: CreatePricingRateInput = {
  availability: {
    dates: [],
    days: [],
    endTime: "10:00",
    startTime: "10:00",
  },
  name: "",
  tax: "",
};

const schema = yup.object().shape({
  name: yup.string().required(),
  tax: yup.string().required(),
});

const PricingRatesListDetails = ({
  selectedRateId,
  newValue,
  goBack,
}: PricingRatesListDetailsProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const { params } = useRoute();

  const isNew = newValue || params?.isNew;
  const id = selectedRateId || params?.id;

  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState<TaxFragment[]>([]);
  const [rate, setRate] = useState(DEFAULT_PRICING_RATE);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getRate] = useGetPricingRateLazyQuery();
  const [getTaxes] = useGetTaxesLazyQuery();
  const [createRate] = useCreatePricingRateMutation();
  const [updateRate] = useUpdatePricingRateMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const taxSelectOptions = useMemo(
    () =>
      taxes.map(tax => ({
        label: tax.name,
        key: tax._id,
      })),
    [taxes],
  );

  const handleGetRate = async () => {
    try {
      if (id) {
        const { data } = await getRate({
          variables: {
            pricingRateId: id,
          },
        });

        if (data?.getPricingRateById) {
          const formattedRate = {
            name: data.getPricingRateById.name,
            tax: data.getPricingRateById.tax._id,
          };
          setRate(formattedRate);
        }
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTaxes = async () => {
    try {
      const pagination: Pagination = {
        limit: 50,
        offset: 0,
      };
      const { data } = await getTaxes({
        variables: {
          pagination,
        },
      });

      if (data?.getTaxes) {
        setTaxes(data.getTaxes);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetTaxes();
    handleGetRate();
  }, []);

  const updateRateData = (key: keyof CreatePricingRateInput, value: string) => {
    setRate(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = async () => {
    try {
      await schema.validate(rate, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;
    try {
      if (isNew) {
        await createRate({
          variables: {
            pricingRate: rate,
          },
          refetchQueries: [
            {
              query: GetPricingRatesDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(rate);
        delete updates._id;
        await updateRate({
          variables: {
            pricingRateId: id,
            pricingRateUpdates: updates,
          },
          refetchQueries: [
            {
              query: GetPricingRatesDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_UPDATING_PRICING_RATE",
            message: "Une erreur est survenue lors de la mise à jour du tarif",
          },
        ],
        "error",
      );
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const displayTitle = isNew ? "Nouveau tarif" : "Modifier le tarif";

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <Box marginVertical="s">
        <CustomTextInput
          placeHolder="Nom"
          isRequired
          hasErrors={doesInputHaveError("name")}
          initialValue={rate.name}
          onChangeText={t => updateRateData("name", t)}
        />
      </Box>

      <Box marginVertical="s">
        <SelectButton
          hasErrors={doesInputHaveError("tax")}
          isMultiSelect={false}
          options={taxSelectOptions}
          selectedOptions={rate.tax ? [rate.tax] : []}
          onPress={([tax]) => updateRateData("tax", tax)}
          placeHolder="Taxe"
        />
      </Box>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default PricingRatesListDetails;
