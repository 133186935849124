import { useEffect, useState } from "react";

import type {
  InternalNotification,
  ReviewFragment,
} from "../../../graphql/generated/schema";
import { Modules, useGetReviewLazyQuery } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface NewReviewProps {
  notification: InternalNotification;
  handleAckItem: (id: string) => void;
}

const NewReview = ({ notification, handleAckItem }: NewReviewProps) => {
  const [review, setReview] = useState<ReviewFragment | undefined>(undefined);

  const [getReview] = useGetReviewLazyQuery();

  const handleGetReview = async () => {
    try {
      const { data } = await getReview({
        fetchPolicy: "cache-and-network",
        variables: {
          reviewId: notification.resourceId,
        },
      });

      if (data?.getMerchantReview) {
        setReview(data.getMerchantReview);
      }
    } catch (err) {}
  };

  useEffect(() => {
    handleGetReview();
  }, []);

  if (!review) return null;

  const formattedClientName = `${review.merchantClient?.lastName || ""} ${
    review.merchantClient?.firstName || ""
  }`.trim();

  const getModuleType = () => {
    switch (review.module) {
      case Modules.Reservation:
        return "Réservation";
      case Modules.OnlineSales:
        return "Vente en ligne";
      case Modules.TableOrder:
        return "Commande à table";
      case Modules.TablePayment:
        return "Paiement à table";

      default:
        break;
    }
  };

  return (
    <Box
      borderRadius="button"
      p="s"
      backgroundColor="white"
      width="100%"
      alignSelf="flex-end"
      maxWidth={370}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <CustomText variant="label" color="yellow">
          Nouvel avis
        </CustomText>
        <CustomText variant="content" color="primaryTextColor">
          Maintenant
        </CustomText>
      </Box>
      <CustomText variant="content" color="primaryTextColor">
        Laissé par: {formattedClientName}
      </CustomText>
      {getModuleType() && (
        <CustomText variant="content" color="primaryTextColor">
          Module: {getModuleType()}
        </CustomText>
      )}

      <CustomText variant="content" color="primaryTextColor">
        Évaluation: {review.reviewRating}/5
      </CustomText>
      <CustomText numberOfLines={1} variant="content" color="primaryTextColor">
        Commentaire: {review.comment}
      </CustomText>
    </Box>
  );
};

export default NewReview;
