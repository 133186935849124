import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { FlatList } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import SEARCH from "../../../../../../assets/icons/BASE/SEARCH.svg";
import Box from "../../../../../components/Base/Box";
import Loader from "../../../../../components/Loader";
import ProductOptionCard from "../../../../../components/ProductOptionCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../components/TextInput";
import Touchable from "../../../../../components/Touchable";
import type { ProductOptionFragment } from "../../../../../graphql/generated/schema";
import { useGetProductOptionsLazyQuery } from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import OptionsListDetails from "../OptionsListDetails";

interface OptionsListProps {
  goBack?: () => void;
}

const OptionsList = ({ goBack }: OptionsListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<ProductOptionFragment[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [showInline, setShowInline] = useState(false);
  const [search, setSearch] = useState("");

  const [getOptions, { fetchMore }] = useGetProductOptionsLazyQuery();

  const filteredOptions = useMemo(() => {
    const formattedSearch = search.toLowerCase().trim();

    if (formattedSearch.length === 0) {
      return options;
    }

    return options.filter(option => {
      const formattedName = option.name.toLowerCase().trim();

      return formattedName.includes(formattedSearch);
    });
  }, [options, search]);

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: options.length,
          },
        },
      });

      if (data?.getProductOptions) {
        setOptions([...options, ...data.getProductOptions]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGetOptions = async () => {
    try {
      const { data } = await getOptions({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getProductOptions) {
        setOptions(data.getProductOptions);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetOptions();
    }, []),
  );

  const handleAddNew = () => {
    if (goBack) {
      setSelectedId("");
      setShowInline(true);
    } else {
      navigation.navigate("OPTIONS_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedId(id);
      setShowInline(true);
    } else {
      navigation.navigate("OPTIONS_LIST_DETAILS", { isNew: false, id });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleGoBack = async () => {
    await handleGetOptions();
    setShowInline(false);
  };

  const displaySelectedTab = () => {
    return (
      <OptionsListDetails
        selectedId={selectedId}
        newValue={!selectedId}
        goBack={handleGoBack}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box marginVertical="s">
          <ScreenHeader
            title="Options"
            rightButtons={
              <Touchable onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Touchable>
            }
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />

          <Box marginVertical="s">
            <CustomTextInput
              {...{
                placeHolder: "Rechercher",
                leftIcon: (
                  <SEARCH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkBlue} />
                ),
                onChangeText: setSearch,
              }}
            />
          </Box>
        </Box>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={filteredOptions}
          keyExtractor={item => item._id}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <Touchable onPress={() => handleEdit(item._id)}>
                <ProductOptionCard
                  isSelected={false}
                  name={item.name}
                  nbGroupOptions={item.groupOptions.length}
                  price={item.basePrice.amount}
                />
              </Touchable>
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showInline) {
      return displaySelectedTab();
    } else {
      return displaySettingsList();
    }
  };

  return <>{displayContent()}</>;
};

export default OptionsList;
