import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import InlineSelectButton from "../../../../components/InlineSelectButton";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import type { MerchantNotificationsFragment } from "../../../../graphql/generated/schema";
import {
  Merchant_Notification_Options,
  useGetMerchantNotificationsLazyQuery,
  useUpdateMerchantNotificationsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface ProNotificationsProps {
  goBack?: () => void;
}

const OPTIONS = [
  {
    key: Merchant_Notification_Options.PushNotification,
    label: "P",
  },
  {
    key: Merchant_Notification_Options.InternalNotification,
    label: "I",
  },
  {
    key: Merchant_Notification_Options.Email,
    label: "E",
  },
  {
    key: Merchant_Notification_Options.Sms,
    label: "S",
  },
];

interface Notification {
  title: string;
  description: string;
  options: typeof OPTIONS;
  key: keyof MerchantNotificationsFragment;
}

const BOOKING_NOTIFICATIONS: Notification[] = [
  {
    title: "En attente",
    description: "Nouvelle réservation en attente de confirmation",
    options: OPTIONS,
    key: "pendingBooking",
  },
  {
    title: "Confirmée auto",
    description: "Nouvelle réservation confirmée automatiquement",
    options: OPTIONS,
    key: "automaticValidationBooking",
  },
  {
    title: "Annulée par le client",
    description: "Notification à chaque fois qu'un client annule sa réservation",
    options: OPTIONS,
    key: "cancelledByClientBooking",
  },
  {
    title: "Nouvel horaire accepté",
    description: "Notification à chaque fois qu'un client accepte un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalAcceptedBooking",
  },
  {
    title: "Nouvel horaire refusé",
    description: "Notification à chaque fois qu'un client refuse un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalRejectedBooking",
  },
];

const ORDER_NOTIFICATIONS: Notification[] = [
  {
    title: "En attente",
    description: "Nouvelle commande en attente de confirmation",
    options: OPTIONS,
    key: "pendingOrder",
  },
  {
    title: "Confirmée auto",
    description: "Nouvelle commande confirmée automatiquement",
    options: OPTIONS,
    key: "automaticValidationOrder",
  },
  {
    title: "Annulée par le client",
    description: "Notification à chaque fois qu'un client annule sa commande",
    options: OPTIONS,
    key: "cancelledByClientOrder",
  },
  {
    title: "Nouvel horaire accepté",
    description: "Notification à chaque fois qu'un client accepte un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalAcceptedOrder",
  },
  {
    title: "Nouvel horaire refusé",
    description: "Notification à chaque fois qu'un client refuse un nouvel horaire",
    options: OPTIONS,
    key: "newHourProposalRejectedOrder",
  },
  // {
  //   title: "Nouveau paiement",
  //   description:
  //     "Notification à chaque fois qu'un client effectue un paiement à table qui est transmis à votre caisse",
  //   options: [
  //     {
  //       key: Merchant_Notification_Options.PushNotification,
  //       label: "P",
  //     },
  //     {
  //       key: Merchant_Notification_Options.InternalNotification,
  //       label: "I",
  //     },
  //   ],
  //   key: "newTablePayment",
  // },
];
const MESSAGE_NOTIFICATIONS: Notification[] = [
  {
    title: "Nouvelle demande",
    description: "Nouvelle demande envoyé par un client",
    options: OPTIONS,
    key: "newMessage",
  },
  {
    title: "Réponse au message",
    description: "Notification suite à une réponse client",
    options: OPTIONS,
    key: "messageResponses",
  },
];
const QUOTE_INVOICE_NOTIFICATIONS: Notification[] = [
  {
    title: "Devis/facture confirmé",
    description: "Notification à chaque fois qu’un client accepte un devis/facture",
    options: OPTIONS,
    key: "validatedQuoteInvoice",
  },
  {
    title: "Devis/facture refusé",
    description: "Notification à chaque fois qu’un client refuse un devis/facture",
    options: OPTIONS,
    key: "rejectedQuoteInvoice",
  },
  {
    title: "Devis/facture en attente",
    description:
      "Notification à chaque fois qu’un client propose une modification du devis/facture",
    options: OPTIONS,
    key: "modifiedQuoteInvoice",
  },
  {
    title: "Devis/facture payé",
    description: "Notification à chaque fois qu’un client à payer sa facture en ligne",
    options: OPTIONS,
    key: "quoteInvoicePaid",
  },
];

const REVIEW_NOTIFICATIONS: Notification[] = [
  {
    title: "Nouvel avis",
    description:
      "Vous êtes informés dès lors qu’un client laisse un avis sur votre établissement",
    options: [
      {
        key: Merchant_Notification_Options.PushNotification,
        label: "P",
      },
      {
        key: Merchant_Notification_Options.InternalNotification,
        label: "I",
      },
      {
        key: Merchant_Notification_Options.Email,
        label: "E",
      },
    ],
    key: "newReview",
  },
];

const ProNotifications = ({ goBack }: ProNotificationsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [merchantNotifications, setmerchantNotifications] =
    useState<MerchantNotificationsFragment | null>(null);

  const [getMerchantNotifications] = useGetMerchantNotificationsLazyQuery();
  const [updateMerchantNotifications] = useUpdateMerchantNotificationsMutation();

  const handleGetNotifications = async () => {
    try {
      const { data } = await getMerchantNotifications();

      if (data) {
        setmerchantNotifications(data.getMerchantNotifications);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  const handleSubmit = async () => {
    try {
      const updates = removeTypeNames(merchantNotifications);
      delete updates._id;

      await updateMerchantNotifications({
        variables: {
          updates,
        },
      });
    } catch (err) {
      console.log("err udpate notifications", err);
    } finally {
      if (goBack) {
        goBack();
      } else {
        navigation.goBack();
      }
    }
  };

  const getSelectedOptions = (key: keyof MerchantNotificationsFragment) => {
    if (merchantNotifications) {
      return merchantNotifications[key];
    }

    return [];
  };

  const updateNotifications = (
    key: keyof MerchantNotificationsFragment,
    options: Merchant_Notification_Options[],
  ) => {
    setmerchantNotifications(prev => ({
      ...prev,
      [key]: options,
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScreenHeader
        title="Établissement"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 150,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box marginVertical="m">
          <CustomText variant="content" color="lightGrey">
            Vous pouvez choisir le type de notifications en fonction de votre utilisation.{" "}
            {"\n"}P = Notification PUSH {"\n"}I = Notification INTERNE {"\n"}E =
            Notification par EMAIL {"\n"}S = Notification par SMS {"\n"}
          </CustomText>
        </Box>

        <Box>
          <CustomText variant="content" color="success" textTransform="uppercase">
            Réservation
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={BOOKING_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Commandes
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={ORDER_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>

        {/* <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Demandes / messages
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={MESSAGE_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box> */}
        {/* <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Devis / factures
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={QUOTE_INVOICE_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box> */}
        <Box mt="m">
          <CustomText variant="content" color="success" textTransform="uppercase">
            AVIS
          </CustomText>

          <FlatList
            scrollEnabled={false}
            data={REVIEW_NOTIFICATIONS}
            keyExtractor={item => item.title}
            renderItem={({ item }) => (
              <Box mt="s">
                <InlineSelectButton
                  options={item.options}
                  description={item.description}
                  title={item.title}
                  isDraggable={false}
                  isMultiSelect
                  selectedOptionKeys={getSelectedOptions(item.key)}
                  onPress={options => updateNotifications(item.key, options)}
                />
              </Box>
            )}
          />
        </Box>
      </ScrollView>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default ProNotifications;
