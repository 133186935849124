/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import RIGHT_ARROW from "../../../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import KeyboardAwareScrollView from "../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../components/Loader";
import AddNewImageModal from "../../../../../components/Media/AddNewImageMedia";
import NumberInputLine from "../../../../../components/NumberInputLine";
import PhoneInput from "../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../../components/TextInput";
import type {
  AddMerchantClientPrescriberInput,
  MerchantClientFragment,
} from "../../../../../graphql/generated/schema";
import {
  GetPrescribersDocument,
  useCreatePrescriberMutation,
  useDeletePrescriberMutation,
  useGetPrescriberLazyQuery,
  useSavePictureMutation,
  useUpdatePrescriberMutation,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import type { ERROR } from "../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../utils/common";

interface PrescriberListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 65;

const ACTIVITIES = [
  {
    label: "Centre de formation",
    key: "TRAINING_CENTER",
  },
  {
    label: "Club",
    key: "CLUB",
  },
  {
    label: "Hôtel",
    key: "HOTEL",
  },
  {
    label: "Office de tourisme",
    key: "TOURIST_OFFICE",
  },
  {
    label: "Restaurateur",
    key: "RESTAURANT",
  },
  {
    label: "Autre",
    key: "OTHER",
  },
];

const DEFAULT_PRESCRIBER: AddMerchantClientPrescriberInput = {
  comment: "",
  commissionPercentage: 0,
  email: "",
  isBlacklisted: false,
  name: "",
  phone: "",
  picture: "",
  principalContact: "",
  sector: "",
  siret: "",
};

const schema = yup.object().shape({
  name: yup.string().required(),
  commissionPercentage: yup.number().min(1),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  principalContact: yup.string().required(),
  sector: yup.string().required(),
  siret: yup.string().required(),
});

const PrescriberListDetailsEdit = ({ id, goBack }: PrescriberListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const isNew = !id;
  const [loading, setLoading] = useState(true);
  const [prescriber, setPrescriber] =
    useState<AddMerchantClientPrescriberInput>(DEFAULT_PRESCRIBER);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  const [getPrescriber] = useGetPrescriberLazyQuery();
  const [createPrescriber] = useCreatePrescriberMutation();
  const [updatePrescriber] = useUpdatePrescriberMutation();
  const [deletePrescriber] = useDeletePrescriberMutation();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetPrescriber = async () => {
    try {
      if (id) {
        const { data, error } = await getPrescriber({
          variables: { id },
        });
        console.log("error", error);

        setPrescriber(data?.getMerchantClientPrescriber);
      } else {
        setPrescriber(DEFAULT_PRESCRIBER);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPrescriber();
  }, []);

  const validateForm = async () => {
    try {
      const principalContactId = prescriber?.principalContact?._id || "";
      const consolidatedPrescriber = {
        ...prescriber,
        principalContact: principalContactId,
      };

      await schema.validate(consolidatedPrescriber, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      setIsSnackBarOpen(true);
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const principalContactId = prescriber?.principalContact?._id || "";
      const consolidatedPrescriber = {
        ...prescriber,
        principalContact: principalContactId,
      };
      if (id) {
        const updates = removeTypeNames(consolidatedPrescriber);
        delete updates._id;

        await updatePrescriber({
          variables: {
            id,
            updates,
          },
          refetchQueries: [
            {
              query: GetPrescribersDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      } else {
        await createPrescriber({
          variables: {
            prescriber: consolidatedPrescriber,
          },
          refetchQueries: [
            {
              query: GetPrescribersDocument,
              variables: {
                pagination: {
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
      }
      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([{ path: "submit", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deletePrescriber({
          variables: {
            id,
          },
          // refetchQueries: [
          //   {
          //     query: GetPrescribersDocument,
          //     variables: {
          //       pagination: {
          //         limit: 10,
          //         offset: 0,
          //       },
          //     },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "MerchantClientPrescriber",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });

        handleGoBack();
      }
    } catch (err) {
      console.log("err delete", err);
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  if (loading) {
    return <Loader />;
  }

  if (!prescriber) {
    return (
      <Box>
        <Box marginVertical="s">
          <ScreenHeader title="Prescripteur" hasBackButton onBackPress={handleGoBack} />
        </Box>

        <ErrorMessage message="Une erreur est survenue lors de la récupération des données du prescripteur." />
      </Box>
    );
  }

  const displayTitle = () => {
    if (isNew) {
      return "Ajouter un prescripteur";
    }

    return "Modifier un prescripteur";
  };
  const updatePrescriberData = (
    key: keyof AddMerchantClientPrescriberInput,
    value: string | number | boolean | MerchantClientFragment,
  ) => {
    setPrescriber(prev => ({ ...prev, [key]: value }));
  };

  const handleOnSubmit = (contact: MerchantClientFragment | null) => {
    const value = contact ? contact : "";
    updatePrescriberData("principalContact", value);
  };

  const handleGoToAddPricinpalContact = () => {
    navigation.navigate("PRESCRIBER_LIST_DETAILS_ADD_PRINCIPAL_CONTACT", {
      principalContact: prescriber.principalContact,
      onSubmit: handleOnSubmit,
    });
  };

  const displayContactName = () => {
    const { principalContact } = prescriber;

    if (principalContact) {
      const { firstName, lastName } = principalContact;
      return `${firstName} ${lastName}`;
    }

    return "";
  };

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setPrescriber(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = prescriber;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle()} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Nom"
                isRequired
                hasErrors={doesInputHaveError("name")}
                initialValue={prescriber.name}
                onChangeText={t => updatePrescriberData("name", t)}
              />
            </Box>
            <Box mt="s">
              <NumberInputLine
                placeHolder="Pourcentage"
                text="Pourcentage de retribution"
                hasErrors={doesInputHaveError("commissionPercentage")}
                value={prescriber.commissionPercentage}
                onValueChange={t => updatePrescriberData("commissionPercentage", t)}
              />
            </Box>
            <Box mt="s">
              <SelectButton
                isMultiSelect={false}
                options={ACTIVITIES}
                hasErrors={doesInputHaveError("sector")}
                selectedOptions={!prescriber.sector ? [] : [prescriber.sector]}
                onPress={([sector]) => updatePrescriberData("sector", sector)}
                placeHolder="Sector d'activité"
              />
            </Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Siret"
                isRequired
                hasErrors={doesInputHaveError("siret")}
                initialValue={prescriber.siret}
                onChangeText={t => updatePrescriberData("siret", t)}
              />
            </Box>
            {/* <Box mt="s">
            <CustomTextInput
              placeHolder="Numero de Tva"
              initialValue={prescriber.tvaNumber}
              onChangeText={t => updatePrescriberData("tvaNumber", t)}
            />
          </Box> */}

            <Box mt="s">
              <PhoneInput
                value={prescriber.phone}
                hasErrors={doesInputHaveError("phone")}
                onChangeFormattedText={t => updatePrescriberData("phone", t)}
              />
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                isRequired
                hasErrors={doesInputHaveError("email")}
                initialValue={prescriber.email}
                onChangeText={t => updatePrescriberData("email", t)}
              />
            </Box>

            <Box mt="s">
              <CustomTextInput
                placeHolder="Addresse"
                isRequired
                onChangeText={() => {}}
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Note sur le prescripteur"
                multiLine
                isRequired
                initialValue={prescriber.comment}
                onChangeText={t => updatePrescriberData("comment", t)}
              />
            </Box>
            <TouchableOpacity onPress={handleGoToAddPricinpalContact}>
              <Box
                mt="s"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomText
                  variant="label"
                  color={
                    doesInputHaveError("principalContact") ? "danger" : "primaryTextColor"
                  }
                >
                  Contact Principal
                </CustomText>
                <Box flexDirection="row" alignItems="center">
                  <CustomText variant="content" color="primaryTextColor" mr="s">
                    {displayContactName()}
                  </CustomText>
                  <RIGHT_ARROW
                    height={ICON_SIZE}
                    width={ICON_SIZE}
                    fill={PALETTE.green}
                  />
                </Box>
              </Box>
            </TouchableOpacity>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>

      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default PrescriberListDetailsEdit;
