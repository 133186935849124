import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import Loader from "../../../../../components/Loader/index";
import ProductCategoryCard from "../../../../../components/ProductCategoryCard";
import ProductGroupOptionCard from "../../../../../components/ProductGroupOptionCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type {
  ProductCategoryFragment,
  ProductGroupOptionFragment,
} from "../../../../../graphql/generated/schema";
import {
  useGetProductCategoriesLazyQuery,
  useGetProductGroupOptionsLazyQuery,
} from "../../../../../graphql/generated/schema";

const ProductAddCategoryGroupOption = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { type, selectedGroupOptions, selectedCategories, onSubmit } = params;
  const [loading, setLoading] = useState(true);
  const [groupOptions, setGroupOptions] = useState<ProductGroupOptionFragment[]>([]);
  const [categories, setCategories] = useState<ProductCategoryFragment[]>([]);
  const [data, setData] = useState<{ _id: string; name: string }[]>(
    type === "category" ? selectedCategories : selectedGroupOptions,
  );

  const [getCategories, { fetchMore: fetchMoreCategories }] =
    useGetProductCategoriesLazyQuery();
  const [getGroupOptions, { fetchMore: fetchMoreGroupOptions }] =
    useGetProductGroupOptionsLazyQuery();

  const handleFetchMoreCategories = async () => {
    try {
      const { data: response } = await fetchMoreCategories({
        variables: {
          pagination: {
            offset: categories.length,
          },
        },
      });

      if (response?.getProductCategories) {
        setCategories([...categories, ...response.getProductCategories]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleFetchMoreGroupOptions = async () => {
    try {
      const { data: response } = await fetchMoreGroupOptions({
        variables: {
          pagination: {
            offset: groupOptions.length,
          },
        },
      });

      if (response?.getProductGroupOptions) {
        setGroupOptions([...groupOptions, ...response.getProductGroupOptions]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleFetchMore =
    type === "category" ? handleFetchMoreCategories : handleFetchMoreGroupOptions;

  const handleGetCategories = async () => {
    try {
      const { data: response } = await getCategories({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (response?.getProductCategories) {
        setCategories(response.getProductCategories);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetGroupOptions = async () => {
    try {
      const { data: response } = await getGroupOptions({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (response?.getProductGroupOptions) {
        setGroupOptions(response.getProductGroupOptions);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    onSubmit(data);
    navigation.goBack();
  };

  useEffect(() => {
    handleGetCategories();
    handleGetGroupOptions();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const displayType = type === "category" ? "Catégories" : "Groupes d'options";

  const handleAdd = (id: string, name: string) => {
    setData(prev => {
      const isExists = prev.find(option => option._id === id);

      if (isExists) {
        return prev.filter(option => option._id !== id);
      }

      return [...prev, { _id: id, name }];
    });
  };

  const renderItem = (item: ProductCategoryFragment | ProductGroupOptionFragment) => {
    const { _id, name } = item;

    return (
      <Box marginVertical="s">
        <TouchableOpacity onPress={() => handleAdd(item._id, item.name)}>
          {type === "category" ? (
            <ProductCategoryCard
              name={name}
              isSelected={!!data.find(d => d._id === _id)}
              nbProducts={item.products.length}
              color={item.color}
              isEnabled={true}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              updateEnabled={() => {}}
              isDraggable={false}
            />
          ) : (
            <ProductGroupOptionCard
              name={name}
              isSelected={!!data.find(d => d._id === _id)}
              nbOptions={item.options.length}
              nbProducts={item.nbProducts}
              price={item.basePrice.amount}
            />
          )}
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title={`Séléctionner les ${displayType}`}
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>
      <Box mt="m" flex={1}>
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
          data={type === "category" ? categories : groupOptions}
          keyExtractor={item => item._id}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => renderItem(item)}
        />
      </Box>
      <BottomButton onPress={handleSubmit} title="Valider" />
    </Box>
  );
};

export default ProductAddCategoryGroupOption;
