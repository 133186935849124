import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import InvoiceListCard from "../../../../../components/InvoiceListCard";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SmsPaymentInvoiceCard from "../../../../../components/SmsPaymentInvoiceCard/index";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  GetSmsPaymentTransactionsQuery,
  SubscriptionInvoiceFragment,
} from "../../../../../graphql/generated/schema";
import {
  useGetMerchantSubscriptionInvoicesLazyQuery,
  useGetSmsPaymentTransactionsLazyQuery,
} from "../../../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../../../theme";

const InvoicesList = () => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [subscriptionInvoices, setSubscriptionInvoices] = useState<
    SubscriptionInvoiceFragment[]
  >([]);
  const [smsPaymentInvoices, setSmsPaymentInvoices] = useState<
    GetSmsPaymentTransactionsQuery["getSmsPaymentTransactions"]
  >([]);
  const [loading, setLoading] = useState(true);

  const [getSubscriptionInvoices, { fetchMore }] =
    useGetMerchantSubscriptionInvoicesLazyQuery();

  const [getSmsPaymentInvoices] = useGetSmsPaymentTransactionsLazyQuery();

  const handleGetSmsPaymentTransactions = async () => {
    try {
      const { data } = await getSmsPaymentInvoices({
        fetchPolicy: "cache-and-network",
      });

      console.log("data", data);

      if (data) {
        setSmsPaymentInvoices(data.getSmsPaymentTransactions);
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_SMS_PAYMENT_INVOICES",
            message:
              "Une erreur est survenue lors de la récupération des factures de paiement SMS",
          },
        ],
        "error",
      );
    }
  };

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: subscriptionInvoices.length,
          },
        },
      });

      if (data) {
        setSubscriptionInvoices([
          ...subscriptionInvoices,
          ...data.getMerchantSubscriptionInvoices,
        ]);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);
    }
  };

  const handleGetSubscriptionTransactions = async () => {
    try {
      const { data } = await getSubscriptionInvoices({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data) {
        setSubscriptionInvoices(data.getMerchantSubscriptionInvoices);
      }
    } catch (err) {
      console.log("err get subscription transactions: ", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_SUBSCRIPTION_TRANSACTIONS",
            message: "Une erreur est survenue lors de la récupération des abonnements",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSubscriptionTransactions();
    handleGetSmsPaymentTransactions();
  }, []);

  if (loading) return <Loader />;

  return (
    <Box p="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Historique de vos factures"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <ScrollView>
        {smsPaymentInvoices.length > 0 && (
          <>
            <Box mt="m" mb="s">
              <InputSectionTitle text="Factures sms" />
            </Box>

            <Box backgroundColor="disabled" borderRadius="button" p="s">
              <FlatList
                scrollEnabled={false}
                data={smsPaymentInvoices}
                keyExtractor={item => item._id}
                onEndReachedThreshold={0.5}
                onEndReached={handleFetchMore}
                renderItem={({ item, index }) => {
                  const IS_LAST = index === smsPaymentInvoices.length - 1;

                  return (
                    <Box
                      borderBottomColor="lightGrey"
                      borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                      paddingHorizontal="s"
                    >
                      <SmsPaymentInvoiceCard smsInvoice={item} />
                    </Box>
                  );
                }}
              />
            </Box>
          </>
        )}

        <Box mt="m" mb="s">
          <InputSectionTitle text="Factures abonnements" />
        </Box>

        <Box backgroundColor="disabled" borderRadius="button" p="s">
          <FlatList
            scrollEnabled={false}
            data={subscriptionInvoices}
            keyExtractor={item => item._id}
            onEndReachedThreshold={0.5}
            onEndReached={handleFetchMore}
            renderItem={({ item, index }) => {
              const IS_LAST = index === subscriptionInvoices.length - 1;

              return (
                <Box
                  borderBottomColor="lightGrey"
                  borderBottomWidth={IS_LAST ? 0 : LINE_THICKNESS}
                  paddingHorizontal="s"
                >
                  <InvoiceListCard invoice={item} />
                </Box>
              );
            }}
          />
        </Box>
      </ScrollView>
    </Box>
  );
};

export default InvoicesList;
