import DRAGGABLE from "../../../../assets/icons/BASE/DRAGGABLE.svg";
import TRASH from "../../../../assets/icons/BASE/TRASH.svg";
import type { PrintTempateSelectedField } from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import { getLabelForPrintSelectedField } from "../../../utils/PdfTemplates";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import SelectButton from "../../Select/SelectButton/index";
import Touchable from "../../Touchable/index";

interface SelectedFieldItemProps {
  item: PrintTempateSelectedField;
  onChange: (updatedItem: PrintTempateSelectedField) => void;
  onPressIn: () => void;
  onRemove: () => void;
}

const HEIGHT = 20;

const SelectedFieldItem = ({
  item,
  onChange,
  onPressIn,
  onRemove,
}: SelectedFieldItemProps) => {
  const getOptions = () => {
    return [10, 12, 14, 16].map(size => ({
      label: size.toString(),
      key: size.toString(),
    }));
  };

  const handleSelectStatusFilter = (items: string[]) => {
    const [size] = items;

    if (isNaN(parseInt(size, 10))) return;

    onChange({
      ...item,
      fontSize: parseInt(size, 10),
    });
  };

  return (
    <Box flex={1} flexDirection="row" alignItems="center">
      <Touchable onPressIn={onPressIn}>
        <Box height={HEIGHT} paddingHorizontal="xs">
          <DRAGGABLE height={19} width={19} fill={PALETTE.darkGrey} />
        </Box>
      </Touchable>

      <Box
        flex={1}
        flexDirection="row"
        paddingHorizontal="xs"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex={0.5}>
          <CustomText variant="content" color="primaryTextColor">
            {getLabelForPrintSelectedField(item.field)}
          </CustomText>
        </Box>
        <Box flex={0.5} flexDirection="row" alignItems="center" maxWidth={200}>
          <Box flex={1}>
            <SelectButton
              placeHolder=""
              selectedOptions={[item.fontSize.toString()]}
              isMultiSelect={false}
              onPress={handleSelectStatusFilter}
              options={getOptions()}
            />
          </Box>

          <Box ml="xs">
            <Touchable
              onPress={onRemove}
              hitSlop={{
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
              }}
            >
              <TRASH height={19} width={19} fill={PALETTE.red} />
            </Touchable>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedFieldItem;
