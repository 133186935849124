import { useNavigation, useRoute } from "@react-navigation/native";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Image, Platform, ScrollView } from "react-native";

import CALENDAR from "../../../../assets/icons/BASE/CALENDAR.svg";
import OCCURENCES from "../../../../assets/icons/BASE/OCCURENCES.svg";
import PAX_PERSONS from "../../../../assets/icons/BASE/PAX_PERSONS.svg";
import STAR from "../../../../assets/icons/BASE/STAR.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import type { BUTTON_ACTION } from "../../../components/BottomButtomWithActions";
import BottomButtomWithActions from "../../../components/BottomButtomWithActions";
import BottomButton from "../../../components/BottomButton";
import { CustomButton } from "../../../components/Button";
import ContentModal from "../../../components/ContentModal";
import ErrorMessage from "../../../components/ErrorMessage/index";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import { CustomTextInput } from "../../../components/TextInput";
import type { ReviewFragment, UpdateReview } from "../../../graphql/generated/schema";
import {
  GetReviewsDocument,
  Modules,
  useGetReviewLazyQuery,
  useUpdateReviewMutation,
} from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";

const IS_ANDROID = Platform.OS === "android";

interface ReviewsListDetailsProps {
  goBack?: () => void;
  reviewId?: string;
  onUpdateReviewComplete?: () => void;
}

const IMAGE_HEIGHT = 70;

const ReviewsListDetails = ({
  goBack,
  reviewId,
  onUpdateReviewComplete,
}: ReviewsListDetailsProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  // const appContext = useContext(AppContext);

  const id = reviewId || params?.id;
  const [loading, setLoading] = useState(true);
  const [review, setReview] = useState<ReviewFragment | null>(null);
  const [isUpdateReviewModalOpen, setIsUpdateReviewModalOpen] = useState(false);
  const [isReviewReported, setIsReviewReported] = useState(false);
  const [reviewAnswer, setReviewAnswer] = useState("");

  const [getReview] = useGetReviewLazyQuery();
  const [updateReview] = useUpdateReviewMutation();

  // const canMerchantRespondToReview = () => {
  //   const hasAllPermissions = appContext?.merchantPermissions?.includes(Modules.All);
  //   const hasMerchantResponsePermission = appContext?.merchantPermissions?.includes(
  //     Modules.ReviewResponse,
  //   );

  //   return hasAllPermissions || hasMerchantResponsePermission;
  // };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.navigate("REVIEWS_LIST");
    }
  };

  const handleGetReview = async () => {
    try {
      const { data } = await getReview({ variables: { reviewId: id } });

      if (data) {
        setReview(data.getMerchantReview);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetReview();
  }, [id]);

  const handleUpdateReview = async () => {
    try {
      const updates: UpdateReview = {
        answer: isReviewReported ? review?.answer || "" : reviewAnswer,
        reportComment: isReviewReported ? reviewAnswer : review?.reportComment || "",
        reported: review?.reported || isReviewReported,
      };

      await updateReview({
        variables: {
          reviewId: id,
          updates,
        },
        refetchQueries: [
          {
            query: GetReviewsDocument,
            variables: {
              pagination: {
                limit: 40,
                offset: 0,
              },
            },
          },
        ],
      });
      handleGoBack();
      onUpdateReviewComplete?.();
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsUpdateReviewModalOpen(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!review) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <Box mb="s">
          <ScreenHeader title={"Avis"} hasBackButton onBackPress={handleGoBack} />
        </Box>

        <ErrorMessage message="Une erreur est survenue lors du chargement de la page" />
      </Box>
    );
  }

  const {
    title,
    createdAt,
    dateOfModule,
    comment,
    answer,
    merchantClient,
    reviewRating,
    reportComment,
    reported,
    adminApprovesReportOfOwner,
  } = review;

  const displayMerchantClientName = () => {
    const firstName = merchantClient?.firstName || "";
    const lastName = merchantClient?.lastName || "";
    return `${lastName} ${firstName}`.trim();
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "REPORT") {
        setIsReviewReported(true);
        setTimeout(() => {
          setIsUpdateReviewModalOpen(true);
        }, 1000);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleMainActionButtonPress = () => {
    setIsReviewReported(false);
    setIsUpdateReviewModalOpen(true);
  };

  const displayButtons = () => {
    const notAllowedKeys: string[] = [];

    if (reported) {
      notAllowedKeys.push("REPORT");
    }
    if (answer) {
      notAllowedKeys.push("RESPOND");
    }

    const BUTTON_ACTIONS: BUTTON_ACTION[] = [
      {
        title: "Signaler",
        key: "REPORT",
        variant: "outlineButton",
        color: "danger",
      },
      // {
      //   title: "Répondre",
      //   key: "RESPOND",
      //   variant: "outlineButton",
      //   color: "success",
      // },
      {
        title: "Annuler",
        key: "CANCEL",
        variant: "primary",
        color: "danger",
      },
    ].filter(item => {
      return !notAllowedKeys.includes(item.key);
    });

    const resourceType =
      review?.module === Modules.Reservation ? "réservation" : "commande";

    const shouldShowButton = !review.answer && !review.reported;

    return (
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        // minHeight={130}
        p="s"
        backgroundColor="white"
      >
        <Box
          mb={IS_ANDROID ? "l" : "s"}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="48%">
            <CustomButton
              onPress={handleGoToMerchantClient}
              buttonVariant="outlineButton"
              borderColor="success"
            >
              <CustomText variant="content" color="success">
                Voir la fiche client
              </CustomText>
            </CustomButton>
          </Box>
          <Box width="48%">
            <CustomButton
              onPress={handleGoToReviewResource}
              buttonVariant="outlineButton"
              borderColor="success"
            >
              <CustomText variant="content" color="success">
                Afficher la {resourceType}
              </CustomText>
            </CustomButton>
          </Box>
        </Box>
        {shouldShowButton && (
          <BottomButtomWithActions
            title="Répondre"
            onMainActionPress={handleMainActionButtonPress}
            actions={BUTTON_ACTIONS}
            onSubmit={handleOnSubmit}
            backGroundColor="transparent"
            minHeight={50}
            styles={{
              position: "relative",
              p: "none",
            }}
          />
        )}
      </Box>
    );
  };

  const handleGoToMerchantClient = () => {
    if (!merchantClient?._id) return;

    navigation.navigate("SETTINGS", {
      screen: "CLIENT_LIST_DETAILS",
      params: {
        id: merchantClient?._id,
      },
    });
  };

  const handleGoToReviewResource = () => {
    if (!review?.module) return;
    if (review.module === Modules.Reservation) {
      navigation.navigate("BOOKING", {
        screen: "BOOKINGS_LIST_DETAILS",
        params: {
          bookingId: review?.resourceId,
          goBackTo: {
            stack: "REVIEWS",
            screen: "REVIEWS_LIST_DETAILS",
            id: review?._id,
          },
        },
      });
    } else if (review.module === Modules.OnlineSales) {
      navigation.navigate("SALES", {
        // screen: "ONLINE_SALES_TAB",
        screen: "ONLINE_SALES_LIST_DETAILS",
        // params: {
        // screen: "ONLINE_SALES_LIST_DETAILS",
        params: {
          orderId: review?.resourceId,
          goBackTo: {
            stack: "REVIEWS",
            screen: "REVIEWS_LIST_DETAILS",
            id: review?._id,
          },
        },
        // },
      });
    }
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScrollView showsVerticalScrollIndicator={false}>
        <Box mb="s">
          <ScreenHeader
            title={`Avis de ${displayMerchantClientName()}`}
            hasBackButton
            onBackPress={handleGoBack}
          />
        </Box>

        <Box
          marginVertical="m"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box flex={0.8}>
            <Box flexDirection="row" alignItems="center">
              <STAR height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.yellow} />
              <CustomText marginHorizontal="s" variant="content" color="primaryTextColor">
                {reviewRating}/5
              </CustomText>
              {/* <GOOGLE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} /> */}
            </Box>
            <CustomText mt="s" variant="title" color="primaryTextColor">
              {title}
            </CustomText>
          </Box>

          {merchantClient?.picture && (
            <Image
              source={{
                uri: merchantClient?.picture,
              }}
              style={{
                width: IMAGE_HEIGHT,
                height: IMAGE_HEIGHT,
                borderRadius: IMAGE_HEIGHT / 2,
              }}
            />
          )}
        </Box>

        <Box flexDirection="row" alignItems="center">
          <Box flexDirection="row" alignItems="center" mr="s">
            <CALENDAR height={15} width={15} fill={PALETTE.darkBlue} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {format(new Date(createdAt), "dd/MM/yyyy")}
            </CustomText>
          </Box>
          <Box flexDirection="row" alignItems="center" mr="s">
            <OCCURENCES height={15} width={15} fill={PALETTE.darkBlue} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {format(new Date(dateOfModule), "dd/MM/yyyy")}
            </CustomText>
          </Box>
          <Box flexDirection="row" alignItems="center" mr="s">
            <PAX_PERSONS height={15} width={15} fill={PALETTE.darkBlue} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {displayMerchantClientName()}
            </CustomText>
          </Box>
        </Box>

        <Box marginVertical="m">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Commentaire de l'avis
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            {comment}
          </CustomText>
        </Box>

        {answer && (
          <Box mt="m">
            <CustomText mb="s" variant="subTitle" color="success">
              Votre réponse
            </CustomText>
            <CustomText variant="content" color="primaryTextColor">
              {answer}
            </CustomText>
          </Box>
        )}
        {reported && (
          <Box mt="m">
            <CustomText mb="s" variant="subTitle" color="success">
              Votre réponse lors du signalement
            </CustomText>
            <CustomText variant="content" color="primaryTextColor">
              {reportComment}
            </CustomText>
          </Box>
        )}
        {typeof adminApprovesReportOfOwner === "boolean" && (
          <Box mt="m">
            <CustomText variant="label" color="primaryTextColor">
              {adminApprovesReportOfOwner
                ? "Votre signalement a été validé par l'administrateur"
                : "Votre signalement a été refusé par l'administrateur"}
            </CustomText>
          </Box>
        )}
      </ScrollView>
      {displayButtons()}

      <ContentModal
        isVisible={isUpdateReviewModalOpen}
        onClose={() => {
          setIsUpdateReviewModalOpen(false);
          setReviewAnswer("");
        }}
        title={isReviewReported ? "Signaler l'avis" : "Répondre à l'avis"}
        styles={{
          maxWidth: 450,
          height: "auto",
        }}
      >
        <>
          <Box height={300}>
            <CustomTextInput
              placeHolder="Contenu"
              isRequired
              onChangeText={text => setReviewAnswer(text)}
            />
          </Box>
          <BottomButton
            isDisabled={reviewAnswer.trim().length === 0}
            title="Valider"
            onPress={handleUpdateReview}
          />
        </>
      </ContentModal>
    </Box>
  );
};

export default ReviewsListDetails;
