import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import InputSectionTitle from "../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../components/TextInput";
import ToggleInputLine from "../../../../components/ToggleInputLine";
import type { QuoteSettingsFragment } from "../../../../graphql/generated/schema";
import {
  useGetQuoteSettingsLazyQuery,
  useUpdateQuoteSettingsMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface QuoteSettingsProps {
  goBack?: () => void;
}

const VALIDITY = [
  {
    label: "30 jours",
    key: "30",
  },
  {
    label: "60 jours",
    key: "60",
  },
  {
    label: "90 jours",
    key: "90",
  },
];

const QuoteSettings = ({ goBack }: QuoteSettingsProps) => {
  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [quoteSettings, setquoteSettings] = useState<QuoteSettingsFragment | null>(null);
  const [acceptConditions, setAcceptConditions] = useState(
    !!quoteSettings?.acceptConditions,
  );
  const [extraField, setExtraField] = useState(!!quoteSettings?.extraField);

  const [getQuoteSettings] = useGetQuoteSettingsLazyQuery();
  const [updateQuoteSettings] = useUpdateQuoteSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetQuoteSettings = async () => {
    try {
      const { data } = await getQuoteSettings();

      if (data) {
        setquoteSettings(data.getQuoteSettings);

        setAcceptConditions(!!data.getQuoteSettings?.acceptConditions);
        setExtraField(!!data.getQuoteSettings?.extraField);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetQuoteSettings();
  }, []);

  const handleSubmit = async () => {
    if (!quoteSettings) return;

    try {
      const updates = removeTypeNames(quoteSettings);

      await updateQuoteSettings({
        variables: {
          updates,
        },
      });
    } catch (err) {
    } finally {
      handleGoBack();
    }
  };

  const updateQuoteSettingsData = (
    key: keyof QuoteSettingsFragment,
    value: QuoteSettingsFragment[keyof QuoteSettingsFragment],
  ) => {
    setquoteSettings(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  if (loading) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          Loading...
        </CustomText>
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 150,
        }}
      >
        <Box flex={1}>
          <Box marginVertical="s">
            <ScreenHeader
              title="Devis"
              hasBackButton
              onBackPress={goBack || navigation.goBack}
            />
          </Box>

          <Box mt="m">
            <SelectButton
              isMultiSelect={false}
              options={VALIDITY}
              selectedOptions={[quoteSettings?.validityPeriod?.toString()]}
              onPress={([option]) => updateQuoteSettingsData("validityPeriod", +option)}
              placeHolder="Période de validité"
            />
          </Box>

          <Box mt="m">
            <InputSectionTitle text="Mentions légales" />
          </Box>
          <Box mt="m">
            <ToggleInputLine
              text="Condition d’acceptation"
              value={acceptConditions}
              onValueChange={setAcceptConditions}
            />

            {acceptConditions && (
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Condition d’acceptation"
                  initialValue={quoteSettings?.acceptConditions}
                  onChangeText={t => updateQuoteSettingsData("acceptConditions", t)}
                />
              </Box>
            )}
          </Box>
          <Box mt="m">
            <ToggleInputLine
              text="Champ libre"
              value={extraField}
              onValueChange={setExtraField}
            />

            {extraField && (
              <Box marginVertical="s">
                <CustomTextInput
                  placeHolder="Champ libre"
                  initialValue={quoteSettings?.extraField}
                  onChangeText={t => updateQuoteSettingsData("extraField", t)}
                />
              </Box>
            )}
          </Box>

          <Box mt="m">
            <InputSectionTitle text="Modèles de mail" />
          </Box>
          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Envoi du devis
            </CustomText>
          </Box>
          <Box mt="m">
            <CustomTextInput
              placeHolder="Objet du mail"
              initialValue={quoteSettings?.newQuoteEmailSubjectLine}
              onChangeText={t => updateQuoteSettingsData("newQuoteEmailSubjectLine", t)}
            />
          </Box>
          <Box marginVertical="s">
            <CustomTextInput
              placeHolder="Contenu du mail"
              initialValue={quoteSettings?.newQuoteEmailBody}
              onChangeText={t => updateQuoteSettingsData("newQuoteEmailBody", t)}
              multiLine
            />
          </Box>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default QuoteSettings;
