import type { Dispatch, SetStateAction } from "react";

import type {
  CreateOnlineSalesAvailabilityInput,
  MinMaxConfiguration,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine/index";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine/index";

interface OrderLimitsProps {
  service: CreateOnlineSalesAvailabilityInput;
  setService: Dispatch<SetStateAction<CreateOnlineSalesAvailabilityInput>>;
  errors: ERROR[];
}

const OrderLimits = ({ service, setService, errors }: OrderLimitsProps) => {
  const updateMinMaxConfigByKey = (
    key:
      | "totalOrdersAllowed"
      | "maxOrdersAllowedForSlot"
      | "minArticles"
      | "maxArticles"
      | "minPrice"
      | "maxPrice",
    innerKey: keyof MinMaxConfiguration,
    value: boolean | number,
  ) => {
    setService(prev => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          [innerKey]: value,
        },
      };
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          LIMITATION COMMANDE
        </CustomText>
      </Box>

      <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le nombre de commande"
            value={service.totalOrdersAllowed.isEnabled}
            onValueChange={v =>
              updateMinMaxConfigByKey("totalOrdersAllowed", "isEnabled", v)
            }
          />
          <CustomText variant="text" color="lightGrey">
            Cela vous permet de gérer le nombre de commandes autorisés pour cette période.
          </CustomText>
        </Box>

        {service.totalOrdersAllowed.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("totalOrdersAllowed.value")}
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Maximum de commande"
              value={service.totalOrdersAllowed.value}
              onValueChange={v =>
                updateMinMaxConfigByKey(
                  "totalOrdersAllowed",
                  "value",
                  getSafeNumberFromInput(v),
                )
              }
            />

            <CustomText variant="text" color="lightGrey">
              Couverts disponibles à la réservation dans votre établissement
            </CustomText>
          </Box>
        )}
      </Box>

      <Box>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le nombre de commande par créneau"
            value={service.maxOrdersAllowedForSlot.isEnabled}
            onValueChange={v =>
              updateMinMaxConfigByKey("maxOrdersAllowedForSlot", "isEnabled", v)
            }
          />
          <CustomText variant="text" color="lightGrey">
            Indiquez le maximum de commandes autorisés pour chaque créneau horaire
          </CustomText>
        </Box>

        {service.maxOrdersAllowedForSlot.isEnabled && (
          <Box
            mt="m"
            ml="m"
            flexDirection="row"
            alignItems="center"
            // justifyContent="space-between"
          >
            <Box flex={1}>
              <NumberInputLine
                hasErrors={doesInputHaveError("maxOrdersAllowedForSlot.value")}
                color="lightGrey"
                textVariant="content"
                placeHolder="0"
                text="Nombre de commandes"
                value={service.maxOrdersAllowedForSlot.value}
                onValueChange={v =>
                  updateMinMaxConfigByKey(
                    "maxOrdersAllowedForSlot",
                    "value",
                    getSafeNumberFromInput(v),
                  )
                }
              />
            </Box>

            {service?.totalOrdersAllowed?.isEnabled &&
              service?.totalOrdersAllowed?.value > 0 && (
                <Box ml="s">
                  <CustomTextInput
                    placeHolder="50"
                    hideFloatingLabel
                    disabled
                    value={service.totalOrdersAllowed.value?.toString()}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChangeText={() => {}}
                    inputStyles={{ textAlign: "center" }}
                  />
                </Box>
              )}
          </Box>
        )}
      </Box>

      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          LIMITATION ARTICLE
        </CustomText>
      </Box>

      <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le nombre minimum d’articles"
            value={service.minArticles.isEnabled}
            onValueChange={v => updateMinMaxConfigByKey("minArticles", "isEnabled", v)}
          />
          <CustomText variant="text" color="lightGrey">
            Cela vous permet de gérer le nombre d’articles minimum demandée pour commander
            pour ce service.
          </CustomText>
        </Box>

        {service.minArticles.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("minArticles.value")}
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Minimum d’articles"
              value={service.minArticles.value}
              onValueChange={v =>
                updateMinMaxConfigByKey("minArticles", "value", getSafeNumberFromInput(v))
              }
            />
          </Box>
        )}
      </Box>
      <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le prix minimum pour une commande"
            value={service.minPrice.isEnabled}
            onValueChange={v => updateMinMaxConfigByKey("minPrice", "isEnabled", v)}
          />
        </Box>

        {service.minPrice.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("minPrice.value")}
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Prix minimum"
              keyboardType="decimal-pad"
              value={service.minPrice.value}
              onValueChange={v =>
                updateMinMaxConfigByKey("minPrice", "value", getSafeNumberFromInput(v))
              }
            />
            <CustomText variant="text" color="lightGrey">
              Entrez le montant minimum requis pour effectuer une commande. Le nombre doit
              être supérieur à 0€.
            </CustomText>
          </Box>
        )}
      </Box>
      <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le nombre maximum d’articles"
            value={service.maxArticles.isEnabled}
            onValueChange={v => updateMinMaxConfigByKey("maxArticles", "isEnabled", v)}
          />
          <CustomText variant="text" color="lightGrey">
            Cela vous permet de gérer le nombre d’articles minimum demandée pour commander
            pour ce service.
          </CustomText>
        </Box>

        {service.maxArticles.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("maxArticles.value")}
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Maximum d'articles"
              value={service.maxArticles.value}
              onValueChange={v =>
                updateMinMaxConfigByKey("maxArticles", "value", getSafeNumberFromInput(v))
              }
            />
            <CustomText variant="text" color="lightGrey">
              Entrez le nombre maximum d’articles autorisés pour effectuer une commande.
              Le nombre doit être supérieur à 0.
            </CustomText>
          </Box>
        )}
      </Box>
      <Box>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter le prix maximum pour une commande"
            value={service.maxPrice.isEnabled}
            onValueChange={v => updateMinMaxConfigByKey("maxPrice", "isEnabled", v)}
          />
        </Box>

        {service.maxPrice.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("maxPrice.value")}
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Prix maximum"
              keyboardType="decimal-pad"
              value={service.maxPrice.value}
              onValueChange={v =>
                updateMinMaxConfigByKey("maxPrice", "value", getSafeNumberFromInput(v))
              }
            />
            <CustomText variant="text" color="lightGrey">
              Entrez le montant maximum à ne pas dépasser pour effectuer une commande. Le
              nombre doit être supérieur à 0€.
            </CustomText>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderLimits;
