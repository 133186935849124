import { format, isSameDay, sub } from "date-fns";

import VALIDATE from "../../../assets/icons/BASE/VALIDATE.svg";
import type { PopCallFragment } from "../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface CallCardProps {
  call: PopCallFragment;
  isSelected: boolean;
  // onPress: (call: PopCallFragment) => void;
}

const CallCard = ({ call, isSelected }: CallCardProps) => {
  const { callPhoneNumber, createdAt, merchantClientId } = call;

  const displayDate = (date: string) => {
    const currentDate = new Date();
    const yesterday = sub(currentDate, { days: 1 });
    const twoDaysAgo = sub(currentDate, { days: 2 });
    const callTime = format(new Date(date), "HH:mm");

    if (isSameDay(currentDate, new Date(date))) {
      return `Aujourd'hui à ${callTime}`;
    } else if (isSameDay(yesterday, new Date(date))) {
      return `Hier à ${callTime}`;
    } else if (isSameDay(twoDaysAgo, new Date(date))) {
      return `Avant-hier à ${callTime}`;
    }

    return format(new Date(date), "dd/MM/yyyy HH:mm");
  };

  const displayCallInfo = () => {
    const clientName = `${merchantClientId?.lastName || ""} ${
      merchantClientId?.firstName || ""
    }`.trim();

    if (clientName) {
      return `${clientName} (${callPhoneNumber})`;
    }

    return `${callPhoneNumber}`;
  };

  return (
    <Box
      p="s"
      minHeight={45}
      borderBottomColor="lightGrey"
      borderBottomWidth={LINE_THICKNESS}
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <CustomText variant="content" color="primaryTextColor">
            {displayCallInfo()}
          </CustomText>

          <CustomText mt="xs" variant="content" color="lightGrey">
            {displayDate(createdAt)}
          </CustomText>
        </Box>
        {isSelected && <VALIDATE height={15} width={15} fill={PALETTE.green} />}
      </Box>
    </Box>
  );
};

export default CallCard;
