import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";

import MESSAGE from "../../../../assets/icons/BASE/MESSAGE.svg";
import type { OrderFragment } from "../../../graphql/generated/schema";
import { Click_Status } from "../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../theme";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { displayModuleId, formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import BookingLIstCardClientFidelity from "../../BookingList/BookingLIstCardClientFidelity";
import BookingListCardOrigin from "../../BookingList/BookingListCardOrigin";
import OnlineOrderCardPaymentType from "../../OnlineOrdersList/OnlineOrderCardPaymentType";
import OnlineOrderStatusButton from "../../OnlineOrdersList/OnlineOrderStatusButton";

interface OrderAlertCardProps {
  item: OrderFragment;
  boxStyles?: React.ComponentProps<typeof Box>;
  onPress: (newStatus: Click_Status, refusalReason: string) => void;
}

const HEIGHT = 92;

const OrderAlertCard = ({ item, boxStyles, onPress }: OrderAlertCardProps) => {
  const navigation = useNavigation();
  const {
    _id: itemId,
    source,
    status,
    merchantClient: client,
    payment,
    date,
    totalDetails,
    commentOwner,
    orderQuantity,
  } = item;
  const formattedDate = format(new Date(date), "dd/MM/yyyy - HH:mm");
  const formattedClientName = `${client.lastName || ""} ${client.firstName || ""}`;
  const formattedTotalAmount = formatCurrencyPrice(totalDetails.total, "fr", "eur");

  // const handleCardPress = () => {
  //   navigation.navigate("SALES", {
  //     screen: "ONLINE_SALES_LIST_DETAILS",
  //     params: {
  //       orderId: itemId,
  //     },
  //   });
  // };

  const determinePaymentStatus = (): THEME_COLORS => {
    const { paid } = payment;

    const IS_CANCELLED =
      status === Click_Status.AutomaticCancel ||
      status === Click_Status.CancelledByOwner ||
      status === Click_Status.CancelledByUser;

    const IS_REFUNDED = status === Click_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "redBackground";

    return paid ? "greenBackground" : "orangeBackground";
  };

  return (
    <Box flexDirection="row" alignItems="center" {...boxStyles}>
      {/* <TouchableOpacity style={{ flex: 1 }} onPress={handleCardPress}> */}
      <Box
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          minHeight: HEIGHT,
        }}
      >
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box flexDirection="row" alignItems="center">
            <BookingListCardOrigin origin={source} />

            <CustomText
              variant="listItemMainText"
              numberOfLines={1}
              style={{
                width: 110,
              }}
            >
              {formattedClientName}
            </CustomText>
          </Box>
          <CustomText variant="listItemMainText">{formattedTotalAmount}</CustomText>

          <OnlineOrderStatusButton status={status} onPress={onPress} />

          {/* <PENDING width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} /> */}
        </Box>
        <Box flexDirection="row" alignItems="center" pt="s">
          <BookingLIstCardClientFidelity occurences={client.orderOccurences} />

          <CustomText paddingHorizontal="m" variant="listItemSubText">
            {displayModuleId(itemId)}
          </CustomText>
          <CustomText pr="s" variant="listItemSubText">
            {formattedDate}
          </CustomText>

          <Box backgroundColor={determinePaymentStatus()} p="s" borderRadius="icon">
            <OnlineOrderCardPaymentType payment={payment} status={status} />
          </Box>

          {commentOwner && (
            <Box flexDirection="row" paddingHorizontal="s" alignItems="center">
              <MESSAGE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.black} />

              <CustomText pl="s" variant="listItemSubText">
                {commentOwner}
              </CustomText>
            </Box>
          )}
        </Box>
      </Box>
      {/* </TouchableOpacity> */}
    </Box>
  );
};

export default OrderAlertCard;
