import type { Dispatch, SetStateAction } from "react";

import type { CreateOnlineSalesAvailabilityInput } from "../../../graphql/generated/schema";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine";
import ToggleInputLine from "../../ToggleInputLine";

interface OrderWaitingListProps {
  service: CreateOnlineSalesAvailabilityInput;
  setService: Dispatch<SetStateAction<CreateOnlineSalesAvailabilityInput>>;
  errors: ERROR[];
}

const OrderWaitingList = ({ service, setService, errors }: OrderWaitingListProps) => {
  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateServiceWatingListData = (
    key: keyof CreateOnlineSalesAvailabilityInput["waitingListSettings"],
    value: boolean | number,
  ) => {
    setService(prev => ({
      ...prev,
      waitingListSettings: {
        ...prev.waitingListSettings,
        [key]: value,
      },
    }));
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          SURCOMMANDE
        </CustomText>
      </Box>

      <Box mt="m" >
        <ToggleInputLine
          textVariant="content"
          text="Autoriser la surcommande"
          value={service.waitingListSettings.isEnabled}
          onValueChange={v => updateServiceWatingListData("isEnabled", v)}
        />

        {service.waitingListSettings.isEnabled && (
          <Box mt="m" ml="m">
            <NumberInputLine
              color="lightGrey"
              textVariant="content"
              placeHolder="0"
              text="Nombre de commandes"
              value={service.waitingListSettings.totalOrdersAllowed}
              onValueChange={v =>
                updateServiceWatingListData(
                  "totalOrdersAllowed",
                  getSafeNumberFromInput(v),
                )
              }
            />

            <CustomText variant="text" color="lightGrey">
              Commandes supplémentaires autorisés qui seront automatiquement dans la liste
              d’attente
            </CustomText>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderWaitingList;
