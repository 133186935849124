import BURGER from "../../../assets/icons/BASE/BURGER.svg";
import { ICON_SIZE, LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface ProductCombinedCategoryCardProps {
  name: string;
  nbCategories: number;
  isSelected: boolean;
}

const HEIGHT = 85;

const ProductCombinedCategoryCard = ({
  name,
  nbCategories,
  isSelected,
}: ProductCombinedCategoryCardProps) => {
  return (
    <Box
      p="s"
      minHeight={HEIGHT}
      borderWidth={LINE_THICKNESS}
      borderColor={isSelected ? "success" : "disabled"}
      borderRadius="button"
      backgroundColor={isSelected ? "white" : "disabled"}
    >
      <CustomText variant="label" color="primaryTextColor">
        {name}
      </CustomText>

      <Box flex={1} flexDirection="row" alignItems="flex-end">
        <Box
          flexDirection="row"
          alignItems="center"
          backgroundColor={isSelected ? "disabled" : "white"}
          borderRadius="button"
          p="s"
        >
          <BURGER height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          <CustomText ml="s" variant="label" color="primaryTextColor">
            {nbCategories}
          </CustomText>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCombinedCategoryCard;
