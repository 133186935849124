export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  country: Scalars["String"];
  formattedAddress: Scalars["String"];
  number: Scalars["String"];
  street: Scalars["String"];
  zipCode: Scalars["String"];
};

export type AddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  formattedAddress?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type Author = {
  __typename?: "Author";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  phone: Scalars["String"];
};

export type AutomaticConfirmationSettings = {
  __typename?: "AutomaticConfirmationSettings";
  isEnabled: Scalars["Boolean"];
  isEnabledForMaxCapacity: Scalars["Boolean"];
  maxCapacityForAutomaticConfirmation: Scalars["Float"];
};

export type AutomaticOnlineSalesConfirmationSettings = {
  __typename?: "AutomaticOnlineSalesConfirmationSettings";
  acceptAutomatically: Scalars["Boolean"];
  areLimitsEnabledForConfirmation: Scalars["Boolean"];
  maxArticlesForConfirmation: MinMaxConfiguration;
  maxPriceForConfirmation: MinMaxConfiguration;
  minArticlesForConfirmation: MinMaxConfiguration;
  minPriceForConfirmation: MinMaxConfiguration;
};

export type Availability = {
  __typename?: "Availability";
  dates: Array<Scalars["DateTime"]>;
  days: Array<Days>;
  times: Array<Scalars["String"]>;
};

export type AvailabilityInput = {
  dates: Array<Scalars["DateTime"]>;
  days: Array<Days>;
  times: Array<Display_Time_Of_Day>;
};

export type AvailabilityServiceWithModuleInfo = {
  __typename?: "AvailabilityServiceWithModuleInfo";
  endTime: Scalars["String"];
  name: Scalars["String"];
  serviceId: Scalars["String"];
  serviceVisibility: Service_Visibility;
  slots: Array<AvailabilitySlotWithModuleInfo>;
  startTime: Scalars["String"];
  totalCapacityForService: Scalars["Float"];
  totalForService: Scalars["Float"];
};

export type AvailabilitySlotWithModuleInfo = {
  __typename?: "AvailabilitySlotWithModuleInfo";
  endTime: Scalars["String"];
  slotId: Scalars["String"];
  startTime: Scalars["String"];
  totalCapacityForService: Scalars["Float"];
  totalCapacityForSlot: Scalars["Float"];
  totalForService: Scalars["Float"];
  totalForSlot: Scalars["Float"];
};

export enum Booking_Type {
  Persons = "PERSONS",
  Service = "SERVICE"
}

export type BaseClient = {
  __typename?: "BaseClient";
  _id: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  secondaryPhone: Scalars["String"];
};

export type Booking = {
  __typename?: "Booking";
  _id: Scalars["String"];
  bookingType: Booking_Type;
  comment: Scalars["String"];
  commentOwner: Scalars["String"];
  createdAt: Scalars["DateTime"];
  date: Scalars["DateTime"];
  eventType: Scalars["String"];
  googleIdempotencyToken?: Maybe<Scalars["String"]>;
  googleUserId?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
  isSync: Scalars["Boolean"];
  items: OrderItems;
  merchantClient: MerchantClient;
  merchantId: Scalars["String"];
  nbPersons: Scalars["Float"];
  occurences?: Maybe<Scalars["Float"]>;
  payment: PaymentOrder;
  refusalReason: Scalars["String"];
  serviceId: Scalars["String"];
  shareCode: Scalars["String"];
  slotId: Scalars["String"];
  source: Scalars["Float"];
  status: Scalars["Float"];
  tables: Array<Table>;
  timeline: Timeline;
  updatedAt: Scalars["DateTime"];
};

export type BookingAutomaticConfirmationSettingsInput = {
  isEnabled: Scalars["Boolean"];
  isEnabledForMaxCapacity: Scalars["Boolean"];
  maxCapacityForAutomaticConfirmation: Scalars["Float"];
};

export type BookingAvailability = {
  __typename?: "BookingAvailability";
  _id: Scalars["String"];
  areLimitsEnabled: Scalars["Boolean"];
  automaticConfirmationSettings: AutomaticConfirmationSettings;
  createdAt: Scalars["DateTime"];
  daysAvailable: Array<Days>;
  durationPerCapacity?: Maybe<Array<DurationConfigurationPerCapacity>>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxCapacityAllowedForSlot: Scalars["Float"];
  maxPersonsForBooking: Scalars["Float"];
  merchantId: Scalars["String"];
  minPersonsForBooking: Scalars["Float"];
  name: Scalars["String"];
  prepaymentSettings: Prepayment;
  requireReconfirmation: Scalars["Boolean"];
  slots: Array<BookingSlot>;
  startTime: Scalars["String"];
  totalPersonsAllowed: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  waitingListSettings: WaitingList;
};

export type BookingSettings = {
  __typename?: "BookingSettings";
  _id: Scalars["String"];
  alertLateBookings: Scalars["Boolean"];
  automaticConfirmationSettings: AutomaticConfirmationSettings;
  bookingType: Booking_Type;
  createdAt: Scalars["DateTime"];
  defaultPrepaymentSettings: Prepayment;
  isBookingEnabledForGoogle: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  merchantId: Scalars["String"];
  message: Scalars["String"];
  pricingRate?: Maybe<PricingRate>;
  updatedAt: Scalars["DateTime"];
  visibilitySettings: VisibilitySettings;
};

export type BookingSlot = {
  __typename?: "BookingSlot";
  _id: Scalars["String"];
  automaticConfirmationSettings: AutomaticConfirmationSettings;
  day: Days;
  endTime: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
  startTime: Scalars["String"];
  totalPersonsAllowed: Scalars["Float"];
  waitingListSettings: WaitingList;
};

export type BookingSlotInput = {
  _id: Scalars["String"];
  automaticConfirmationSettings: BookingAutomaticConfirmationSettingsInput;
  day: Days;
  endTime: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
  startTime: Scalars["String"];
  totalPersonsAllowed: Scalars["Float"];
  waitingListSettings: WaitingListInput;
};

/** Create Booking table by pro */
export type BookingTableInput = {
  _id: Scalars["String"];
  averageDurationInMinutes: Scalars["Float"];
  isEligibleForBooking: Scalars["Boolean"];
  maxCapacity: Scalars["Float"];
  minCapacity: Scalars["Float"];
  positionX: Scalars["Float"];
  positionY: Scalars["Float"];
  prefix: Scalars["String"];
  quantity: Scalars["Float"];
  rotationDeg: Scalars["Float"];
  shape?: InputMaybe<Table_Form>;
  tableNumber: Scalars["String"];
};

export type BookingsAndServices = {
  __typename?: "BookingsAndServices";
  accumulatedTotalInService: Scalars["Float"];
  daysAvailable: Array<Scalars["Float"]>;
  serviceId: Scalars["String"];
  serviceName: Scalars["String"];
  slots: Array<BookingsAndServicesSlot>;
  startTime: Scalars["String"];
  totalInService: Scalars["Float"];
};

export type BookingsAndServicesSlot = {
  __typename?: "BookingsAndServicesSlot";
  accumulatedTotalInSlot: Scalars["Float"];
  data: Array<Booking>;
  slotId: Scalars["String"];
  slotTime: Scalars["String"];
  totalInSlot: Scalars["Float"];
};

export type BookingsByMonth = {
  __typename?: "BookingsByMonth";
  dates: Array<Scalars["DateTime"]>;
};

export enum Click_Status {
  AutomaticCancel = "AUTOMATIC_CANCEL",
  AutomaticValidated = "AUTOMATIC_VALIDATED",
  CancelledByOwner = "CANCELLED_BY_OWNER",
  CancelledByUser = "CANCELLED_BY_USER",
  Ended = "ENDED",
  ModifiedByOwner = "MODIFIED_BY_OWNER",
  ModifiedByUser = "MODIFIED_BY_USER",
  New = "NEW",
  Noshow = "NOSHOW",
  PaymentFailure = "PAYMENT_FAILURE",
  Prepared = "PREPARED",
  Preparing = "PREPARING",
  Validated = "VALIDATED"
}

export type ComputedDiscounts = {
  __typename?: "ComputedDiscounts";
  discountAmount: Scalars["Float"];
  discountId: Scalars["String"];
  discountName: Scalars["String"];
};

export type ComputedTaxes = {
  __typename?: "ComputedTaxes";
  amount: Scalars["Float"];
  taxRate: Scalars["Float"];
};

export type Conversation = {
  __typename?: "Conversation";
  _id: Scalars["String"];
  client: MerchantClient;
  createdAt: Scalars["DateTime"];
  isDeleted: Scalars["Boolean"];
  merchantId: Scalars["String"];
  messages: Array<Message>;
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CreateBookingAvailabilityInput = {
  areLimitsEnabled: Scalars["Boolean"];
  automaticConfirmationSettings: BookingAutomaticConfirmationSettingsInput;
  daysAvailable: Array<Days>;
  durationPerCapacity: Array<DurationConfigurationPerCapacityInput>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxCapacityAllowedForSlot: Scalars["Float"];
  maxPersonsForBooking: Scalars["Float"];
  minPersonsForBooking: Scalars["Float"];
  name: Scalars["String"];
  prepaymentSettings: Array<PrepaymentInput>;
  requireReconfirmation: Scalars["Boolean"];
  slots: Array<BookingSlotInput>;
  startTime: Scalars["String"];
  totalPersonsAllowed: Scalars["Float"];
  waitingListSettings: WaitingListInput;
};

export type CreateBookingFloorInput = {
  decorations: Array<DecorationInput>;
  height: Scalars["Float"];
  name: Scalars["String"];
  position: Scalars["Float"];
  prefix: Scalars["String"];
  tables: Array<TableInput>;
  width: Scalars["Float"];
};

/** Create Booking by pro */
export type CreateBookingProInput = {
  client: MerchantClientInput;
  commentOwner: Scalars["String"];
  date: Scalars["DateTime"];
  nbPersons: Scalars["Float"];
  serviceId: Scalars["String"];
  slotId: Scalars["String"];
  source: Scalars["Float"];
  status: Scalars["Float"];
  tables: Array<BookingTableInput>;
};

export type CreateDeliveryOrderProInput = {
  amount: Scalars["Float"];
  commentOwner: Scalars["String"];
  date: Scalars["DateTime"];
  deliveryAddress: Scalars["String"];
  googleIdempotencyToken?: InputMaybe<Scalars["String"]>;
  googleUserId?: InputMaybe<Scalars["String"]>;
  items: OrderItemsInput;
  merchantClient: Scalars["String"];
  merchantTip: Scalars["Float"];
  serviceId: Scalars["String"];
  slotId: Scalars["String"];
  source: Origin;
  status: Click_Status;
};

export type CreateGroupOption = {
  baseAvailability: AvailabilityInput;
  basePrice: PriceInput;
  description: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  name: Scalars["String"];
  options: Array<Scalars["String"]>;
  pricingRates: Array<PricingRateAvailabilityInput>;
  selectionSettings: SelectionInput;
};

export type CreateInvoiceProInput = {
  acceptedDate: Scalars["DateTime"];
  client: Scalars["String"];
  conditionOfAcceptance: Scalars["String"];
  deliveryAddress: Scalars["String"];
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  exipresAt: Scalars["DateTime"];
  extraField: Scalars["String"];
  isOnlinePaymentEnabled: Scalars["Boolean"];
  isSignatureRequired: Scalars["Boolean"];
  items: QuoteItemsInput;
  notes: Scalars["String"];
  number: Scalars["String"];
  pdfUrl: Scalars["String"];
  quote: Scalars["String"];
  recepientEmails: Array<Scalars["String"]>;
  sentDate: Scalars["DateTime"];
  signedAt: Scalars["DateTime"];
  status: Quote_Status;
  title: Scalars["String"];
  validityInDays: Scalars["Float"];
};

export type CreateMenu = {
  baseAvailability: AvailabilityInput;
  color: Scalars["String"];
  description: Scalars["String"];
  draftMenuSteps: Array<MenuStepInput>;
  isEnabled: Scalars["Boolean"];
  isPhysicalItem: Scalars["Boolean"];
  name: Scalars["String"];
  picture: Scalars["String"];
  position: Scalars["Float"];
  prices: Array<MenuPriceInput>;
  productCode: Scalars["String"];
  savedMenuSteps: Array<MenuStepInput>;
  slug: Scalars["String"];
};

export type CreateMerchantClientCompany = {
  address: AddressInput;
  companyName: Scalars["String"];
  phone: Scalars["String"];
  secondaryPhone: Scalars["String"];
};

export type CreateMerchantPersonnelInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  isDisabled: Scalars["Boolean"];
  jobTitle: Scalars["String"];
  lastName: Scalars["String"];
  login?: InputMaybe<Scalars["String"]>;
  loginCode: Scalars["String"];
  loginStrategy: Scalars["String"];
  merchantId: Scalars["String"];
  permissionGroup: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  workHours: Array<OpenHourInput>;
};

export type CreateOnlineSalesAvailabilityInput = {
  areLimitsEnabled: Scalars["Boolean"];
  automaticClickConfirmationSettings: OnlineSalesAutomaticConfirmationSettingsInput;
  daysAvailable: Array<Scalars["Float"]>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfigurationInput;
  maxOrdersAllowedForSlot: Scalars["Float"];
  maxPrice: MinMaxConfigurationInput;
  minArticles: MinMaxConfigurationInput;
  minPrice: MinMaxConfigurationInput;
  name: Scalars["String"];
  slots: Array<OnlineSalesServiceSlotInput>;
  startTime: Scalars["String"];
  totalOrdersAllowed: Scalars["Float"];
  waitingListSettings: OnlineSalesWaitingListInput;
};

export type CreateOnlineSalesOrderProInput = {
  client: MerchantClientInput;
  commentOwner: Scalars["String"];
  date: Scalars["DateTime"];
  googleIdempotencyToken?: InputMaybe<Scalars["String"]>;
  googleUserId?: InputMaybe<Scalars["String"]>;
  items: OrderItemsInput;
  merchantTip: Scalars["Float"];
  serviceId: Scalars["String"];
  slotId: Scalars["String"];
  source: Origin;
  status: Click_Status;
};

export type CreateOption = {
  baseAvailability: AvailabilityInput;
  basePrice: PriceInput;
  description: Scalars["String"];
  groupOptions: Array<Scalars["String"]>;
  isEnabled: Scalars["Boolean"];
  name: Scalars["String"];
  pricingRates: Array<PricingRateAvailabilityInput>;
};

export type CreateProduct = {
  baseAvailability: AvailabilityInput;
  basePrice: PriceInput;
  categories: Array<Scalars["String"]>;
  color: Scalars["String"];
  description: Scalars["String"];
  groupOptions: Array<Scalars["String"]>;
  isEnabled: Scalars["Boolean"];
  isPhysicalItem: Scalars["Boolean"];
  menuExtraPrice: PriceInput;
  name: Scalars["String"];
  physicalSettings: PhysicalProductInput;
  picture: Scalars["String"];
  pricingRates: Array<PricingRateAvailabilityInput>;
  productCode: Scalars["String"];
  serviceSettings: ServiceProductInput;
  slug: Scalars["String"];
  soldOnlyInMenu: Scalars["Boolean"];
  suggestedProducts: Array<Scalars["String"]>;
};

export type CreateProductCategory = {
  color: Scalars["String"];
  description: Scalars["String"];
  iconUrl: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  name: Scalars["String"];
  position: Scalars["Float"];
  products: Array<Scalars["String"]>;
};

export type CreatePublicationInput = {
  cta: Publication_Cta;
  ctaLink: Scalars["String"];
  description: Scalars["String"];
  eventCode: Scalars["String"];
  eventPrice: Scalars["Float"];
  images: Array<Scalars["String"]>;
  isBoosted: Scalars["Boolean"];
  isPinned: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  isPublishedOnFacebook: Scalars["Boolean"];
  isPublishedOnInstagram: Scalars["Boolean"];
  isRecurring: Scalars["Boolean"];
  isVisibleOnline: Scalars["Boolean"];
  promoCode: Scalars["String"];
  recurringDates?: InputMaybe<RecurringDatesInput>;
  specificDates?: InputMaybe<SpecificDatesInput>;
  tags: Array<Scalars["String"]>;
  title: Scalars["String"];
  type: Publication_Type;
  usageConditions: Scalars["String"];
};

export type CreateQuoteProInput = {
  client: MerchantClientInput;
  conditionOfAcceptance?: InputMaybe<Scalars["String"]>;
  deliveryAddress?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  extraField?: InputMaybe<Scalars["String"]>;
  isOnlinePaymentEnabled: Scalars["Boolean"];
  items: QuoteItemsInput;
  number?: InputMaybe<Scalars["Float"]>;
  recepientEmails?: InputMaybe<Array<Scalars["String"]>>;
  sentDate: Scalars["DateTime"];
  status: Quote_Status;
  title: Scalars["String"];
  validityInDays: Scalars["Float"];
};

export type CreateReview = {
  comment: Scalars["String"];
  module: Modules;
  resourceId: Scalars["String"];
};

export type CreateUpdateMerchantPersonnelPermissionsGroupInput = {
  name: Scalars["String"];
  permissions: Array<PermissionInput>;
};

export enum Days {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY"
}

export enum Decoration_Type {
  Door = "DOOR",
  FlowerPot = "FLOWER_POT",
  LeftDescendingStairs = "LEFT_DESCENDING_STAIRS",
  PalmPlant = "PALM_PLANT",
  RightDescendingStairs = "RIGHT_DESCENDING_STAIRS",
  Tree = "TREE",
  Window = "WINDOW"
}

export enum Display_Time_Of_Day {
  Breakfast = "BREAKFAST",
  Dinner = "DINNER",
  Lunch = "LUNCH"
}

export type Decoration = {
  __typename?: "Decoration";
  _id: Scalars["String"];
  positionX: Scalars["Float"];
  positionY: Scalars["Float"];
  rotationDeg: Scalars["Float"];
  type: Decoration_Type;
};

export type DecorationInput = {
  _id: Scalars["String"];
  positionX: Scalars["Float"];
  positionY: Scalars["Float"];
  rotationDeg: Scalars["Float"];
  type: Decoration_Type;
};

export type DeliveryOrder = {
  __typename?: "DeliveryOrder";
  _id: Scalars["String"];
  comment: Scalars["String"];
  commentOwner: Scalars["String"];
  createdAt: Scalars["DateTime"];
  date: Scalars["DateTime"];
  deliveryAddress: Scalars["String"];
  googleIdempotencyToken?: Maybe<Scalars["String"]>;
  googleUserId?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
  isSync: Scalars["Boolean"];
  items: OrderItems;
  merchantClient: MerchantClient;
  merchantId: Scalars["String"];
  merchantTip: Scalars["Float"];
  orderNumber: Scalars["String"];
  orderQuantity: Scalars["Float"];
  payment: PaymentOrder;
  refusalReason: Scalars["String"];
  serviceId: Scalars["String"];
  shareCode: Scalars["String"];
  slotId: Scalars["String"];
  source: Scalars["Float"];
  status: Scalars["Float"];
  timeline: Timeline;
  totalDetails: TotalDetails;
  updatedAt: Scalars["DateTime"];
};

export type DeliveryPerimeter = {
  __typename?: "DeliveryPerimeter";
  description: Scalars["String"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  price: Price;
  region: DeliveryPolygonPerimeter;
};

export type DeliveryPerimeterInput = {
  description: Scalars["String"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  price: PriceInput;
  region: DeliveryPolygonPerimeterInput;
};

export type DeliveryPolygonPerimeter = {
  __typename?: "DeliveryPolygonPerimeter";
  coordinates: Array<Array<Array<Scalars["Float"]>>>;
  type: Scalars["String"];
};

export type DeliveryPolygonPerimeterInput = {
  coordinates: Array<Array<Array<Scalars["Float"]>>>;
  type: Scalars["String"];
};

export type DeliverySettings = {
  __typename?: "DeliverySettings";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  deliveryRegions: Array<DeliveryPerimeter>;
  isEnabled: Scalars["Boolean"];
  merchantId: Scalars["String"];
  message: Scalars["String"];
  pricingRate?: Maybe<PricingRate>;
  updatedAt: Scalars["DateTime"];
};

export type Device = {
  __typename?: "Device";
  deviceToken: Scalars["String"];
  manufacturer: Scalars["String"];
  modelName: Scalars["String"];
};

export enum DisallowBookingType {
  Service = "SERVICE",
  Slot = "SLOT"
}

export type DurationConfigurationPerCapacity = {
  __typename?: "DurationConfigurationPerCapacity";
  durationInMinutes: Scalars["Float"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
};

export type DurationConfigurationPerCapacityInput = {
  durationInMinutes: Scalars["Float"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
};

export type GroupOption = {
  __typename?: "GroupOption";
  _id: Scalars["String"];
  baseAvailability: Availability;
  basePrice: Price;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  isDeleted: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  options: Array<Option>;
  pricingRates: Array<PricingRateAvailability>;
  selectionSettings: Selection;
  updatedAt: Scalars["DateTime"];
};

export type GroupOptionsOrder = {
  __typename?: "GroupOptionsOrder";
  extraPrice: Price;
  groupOptionId: Scalars["String"];
  name: Scalars["String"];
  options: Array<OptionOrder>;
  quantity: Scalars["Float"];
};

export type GroupOptionsOrderInput = {
  extraPrice: PriceInput;
  groupOptionId: Scalars["String"];
  name: Scalars["String"];
  options: Array<OptionOrderInput>;
  quantity: Scalars["Float"];
};

export type Invoice = {
  __typename?: "Invoice";
  _id: Scalars["String"];
  acceptedDate: Scalars["DateTime"];
  conditionOfAcceptance: Scalars["String"];
  createdAt: Scalars["DateTime"];
  deliveryAddress: Scalars["String"];
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  exipresAt: Scalars["DateTime"];
  extraField: Scalars["String"];
  invoiceUrl: Scalars["String"];
  isOnlinePaymentEnabled: Scalars["Boolean"];
  isSignatureRequired: Scalars["Boolean"];
  items: QuoteItems;
  merchantClient: MerchantClient;
  merchantId: Scalars["String"];
  notes: Scalars["String"];
  number: Scalars["String"];
  pdfUrl: Scalars["String"];
  quote?: Maybe<Quote>;
  recepientEmails: Array<Scalars["String"]>;
  refusalReason: Scalars["String"];
  revisionNumber: Scalars["Float"];
  sentDate: Scalars["DateTime"];
  signedAt: Scalars["DateTime"];
  status: Scalars["Float"];
  title: Scalars["String"];
  totalDetails: TotalDetails;
  updatedAt: Scalars["DateTime"];
  validityInDays: Scalars["Float"];
};

export enum LoginStrategy {
  LoginCode = "LOGIN_CODE",
  Password = "PASSWORD"
}

export enum Merchant_Personnel_Roles {
  MerchantAdmin = "MERCHANT_ADMIN",
  MerchantPersonnel = "MERCHANT_PERSONNEL"
}

export enum Modules {
  Delivery = "DELIVERY",
  DigitalMenu = "DIGITAL_MENU",
  MerchantClients = "MERCHANT_CLIENTS",
  OnlineSales = "ONLINE_SALES",
  PopCall = "POP_CALL",
  Publication = "PUBLICATION",
  QrCode = "QR_CODE",
  Reservation = "RESERVATION",
  ReviewResponse = "REVIEW_RESPONSE",
  Sms = "SMS"
}

export type Menu = {
  __typename?: "Menu";
  _id: Scalars["String"];
  baseAvailability: Availability;
  color: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  draftMenuSteps: Array<MenuStep>;
  isDeleted: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  isPhysicalItem: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  picture: Scalars["String"];
  position: Scalars["Float"];
  prices: Array<MenuPrice>;
  productCode: Scalars["String"];
  savedMenuSteps: Array<MenuStep>;
  slug: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type MenuOrder = {
  __typename?: "MenuOrder";
  menuId: Scalars["String"];
  name: Scalars["String"];
  price: Price;
  quantity: Scalars["Float"];
  steps: Array<MenuStepOrder>;
};

export type MenuOrderInput = {
  menuId: Scalars["String"];
  name: Scalars["String"];
  price: PriceInput;
  quantity: Scalars["Float"];
  steps: Array<MenuStepOrderInput>;
};

export type MenuPrice = {
  __typename?: "MenuPrice";
  basePrice: Price;
  menuStepIds: Array<Scalars["String"]>;
  pricingRates: Array<PricingRateAvailability>;
};

export type MenuPriceInput = {
  basePrice: PriceInput;
  menuStepIds: Array<Scalars["String"]>;
  pricingRates: Array<PricingRateAvailabilityInput>;
};

export type MenuStep = {
  __typename?: "MenuStep";
  _id: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  products: Array<Product>;
  selectionSettings: Selection;
};

export type MenuStepInput = {
  _id: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  products: Array<Scalars["String"]>;
  selectionSettings: SelectionInput;
};

export type MenuStepOrder = {
  __typename?: "MenuStepOrder";
  menuStepId: Scalars["String"];
  name: Scalars["String"];
  products: Array<ProductOrder>;
};

export type MenuStepOrderInput = {
  menuStepId: Scalars["String"];
  name: Scalars["String"];
  products: Array<ProductOrderInput>;
};

export type Merchant = {
  __typename?: "Merchant";
  _id: Scalars["String"];
  address: Address;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  email: Scalars["String"];
  gps: PointCoordinates;
  images: Array<Scalars["String"]>;
  indexedLabel: Scalars["String"];
  isActive: Scalars["Boolean"];
  isDisabled: Scalars["Boolean"];
  label: Scalars["String"];
  openingHours: Array<OpenHour>;
  owner: User;
  permissions: Array<Scalars["String"]>;
  phone: Scalars["String"];
  siret: Scalars["String"];
  slug: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type MerchantClient = {
  __typename?: "MerchantClient";
  _id: Scalars["String"];
  acceptedReceiveMarketingSms: Scalars["Boolean"];
  acceptedReceiveNewsLetters: Scalars["Boolean"];
  address?: Maybe<Address>;
  comment: Scalars["String"];
  dateOfBirth: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  isBlacklisted: Scalars["Boolean"];
  isDeleted: Scalars["Boolean"];
  isVIP: Scalars["Boolean"];
  language: Scalars["String"];
  lastName: Scalars["String"];
  merchantCompanyId?: Maybe<MerchantClientCompany>;
  merchantId: Scalars["String"];
  phone: Scalars["String"];
  picture: Scalars["String"];
  secondaryEmail: Scalars["String"];
  secondaryPhone: Scalars["String"];
  sex: Sex;
  userId: Scalars["String"];
};

export type MerchantClientCompany = {
  __typename?: "MerchantClientCompany";
  _id: Scalars["String"];
  address: Address;
  companyName: Scalars["String"];
  isDeleted: Scalars["Boolean"];
  merchantId: Scalars["String"];
  phone: Scalars["String"];
  secondaryPhone: Scalars["String"];
};

export type MerchantClientInput = {
  _id?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<AddressInput>;
  comment?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  isVIP?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  merchantCompanyId?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
  picture?: InputMaybe<Scalars["String"]>;
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  sex: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type MerchantPersonnel = {
  __typename?: "MerchantPersonnel";
  _id: Scalars["String"];
  additionalPermissions: Array<Permission>;
  createdAt: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
  isDisabled: Scalars["Boolean"];
  jobTitle: Scalars["String"];
  lastName: Scalars["String"];
  loginCode: Scalars["String"];
  loginStrategy: Scalars["String"];
  merchantId: Scalars["String"];
  permissionGroup?: Maybe<MerchantPersonnelPermissionsGroups>;
  phone: Scalars["String"];
  role: Scalars["String"];
  secondaryPhone: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  workHours: Array<OpenHour>;
};

export type MerchantPersonnelPermissionsGroups = {
  __typename?: "MerchantPersonnelPermissionsGroups";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  isDeleted: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  permissions: Array<Permission>;
  updatedAt: Scalars["DateTime"];
};

export type MerchantPersonnelSettings = {
  __typename?: "MerchantPersonnelSettings";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  loginStrategy: Scalars["String"];
  merchantId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type MerchantSettingSocialsInput = {
  facebook: Scalars["String"];
  instagram: Scalars["String"];
  twitter: Scalars["String"];
  website: Scalars["String"];
  youtube: Scalars["String"];
};

export type MerchantSettings = {
  __typename?: "MerchantSettings";
  _id: Scalars["String"];
  billingDetails: MerchantSettingsBillingDetails;
  closedDates: Array<RangeDate>;
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  language: Scalars["String"];
  merchantId: Scalars["String"];
  paymentMethod: MerchantSettingsPaymentMethod;
  socials: MerchantSettingsSocials;
  stripeConnectedCustomerId: Scalars["String"];
  stripeSubscriptionCustomerId: Scalars["String"];
  timeZone: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type MerchantSettingsBillingDetails = {
  __typename?: "MerchantSettingsBillingDetails";
  address: Address;
  name: Scalars["String"];
};

export type MerchantSettingsBillingDetailsInput = {
  address: AddressInput;
  name: Scalars["String"];
};

export type MerchantSettingsPaymentMethod = {
  __typename?: "MerchantSettingsPaymentMethod";
  brand: Scalars["String"];
  expMonth: Scalars["String"];
  expYear: Scalars["String"];
  id: Scalars["String"];
  lastFour: Scalars["String"];
};

export type MerchantSettingsSocials = {
  __typename?: "MerchantSettingsSocials";
  facebook: Scalars["String"];
  instagram: Scalars["String"];
  twitter: Scalars["String"];
  website: Scalars["String"];
  youtube: Scalars["String"];
};

export type Message = {
  __typename?: "Message";
  _id: Scalars["String"];
  author: Author;
  content: Scalars["String"];
  conversationId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  isDeleted: Scalars["Boolean"];
  isRead: Scalars["Boolean"];
  merchantId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type MinMaxConfiguration = {
  __typename?: "MinMaxConfiguration";
  isEnabled: Scalars["Boolean"];
  value: Scalars["Float"];
};

export type MinMaxConfigurationInput = {
  isEnabled: Scalars["Boolean"];
  value: Scalars["Float"];
};

export type Mutation = {
  __typename?: "Mutation";
  addMessageToConversation: Conversation;
  createBookingAvailability: BookingAvailability;
  createBookingFloor: BookingSettings;
  createBookingForPro: Booking;
  createBookingService: BookingSettings;
  createConversation: Conversation;
  createDeliveryOrder: DeliveryOrder;
  createInvoiceForPro: Invoice;
  createMenu: Option;
  createMerchantClient: MerchantClient;
  createMerchantClientCompany: MerchantClientCompany;
  createMerchantPersonnel: MerchantPersonnel;
  createMerchantPersonnelPermissionGroup: MerchantPersonnelPermissionsGroups;
  createOnlineSalesAvailability: OnlineSalesAvailability;
  createOrderForPro: Order;
  createPricingRate: PricingRate;
  createProduct: Option;
  createProductCategory: ProductCategory;
  createProductGroupOption: GroupOption;
  createProductOption: Option;
  createPublication: Publication;
  createQuoteForPro: Quote;
  createReview: Review;
  deleteBookingAvailability: BookingAvailability;
  deleteBookingFloor: BookingSettings;
  deleteBookingForPro: Booking;
  deleteBookingService: BookingSettings;
  deleteDeliveryOrder: DeliveryOrder;
  deleteInvoiceForPro: Invoice;
  deleteMenu: Option;
  deleteMerchantClient: MerchantClient;
  deleteMerchantClientCompany: MerchantClientCompany;
  deleteMerchantPersonnel: MerchantPersonnel;
  deleteMerchantPersonnelPermissionGroup: MerchantPersonnelPermissionsGroups;
  deleteOnlineSalesAvailability: OnlineSalesAvailability;
  deleteOrderByPro: Order;
  deletePopCall: Popcall;
  deletePricingRate: PricingRate;
  deleteProduct: Option;
  deleteProductGroupOption: GroupOption;
  deleteProductOption: Option;
  deletePublication: Publication;
  deleteQuoteForPro: Quote;
  delteProductCategory: ProductCategory;
  linkMerchantClientToMerchantCompany: MerchantClient;
  loginPro: Scalars["String"];
  register: Scalars["String"];
  updateBookingAvailability: BookingAvailability;
  updateBookingFloor: BookingSettings;
  updateBookingForPro: Booking;
  updateBookingService: BookingSettings;
  updateBookingSettings: BookingSettings;
  updateDeliveryOrder: DeliveryOrder;
  updateDeliverySettings: DeliverySettings;
  updateInvoiceForPro: Invoice;
  updateMenu: Option;
  updateMerchant: MerchantPersonnelPermissionsGroups;
  updateMerchantClient: MerchantClient;
  updateMerchantClientCompany: MerchantClientCompany;
  updateMerchantPersonnel: MerchantPersonnel;
  updateMerchantSettings: MerchantSettings;
  updateOnlineSalesAvailability: OnlineSalesAvailability;
  updateOrderForPro: Order;
  updatePopCall: Popcall;
  updatePricingRate: PricingRate;
  updateProduct: Option;
  updateProductCategory: ProductCategory;
  updateProductGroupOption: GroupOption;
  updateProductOption: Option;
  updatePublication: Publication;
  updateQuoteForPro: Quote;
  updateReview: Review;
};


export type MutationAddMessageToConversationArgs = {
  content: Scalars["String"];
  conversationId: Scalars["String"];
};


export type MutationCreateBookingAvailabilityArgs = {
  bookingAvailability: CreateBookingAvailabilityInput;
};


export type MutationCreateBookingFloorArgs = {
  floor: CreateBookingFloorInput;
};


export type MutationCreateBookingForProArgs = {
  booking: CreateBookingProInput;
};


export type MutationCreateBookingServiceArgs = {
  service: CreateBookingAvailabilityInput;
};


export type MutationCreateConversationArgs = {
  merchantClientId: Scalars["String"];
};


export type MutationCreateDeliveryOrderArgs = {
  order: CreateDeliveryOrderProInput;
};


export type MutationCreateInvoiceForProArgs = {
  invoice: CreateInvoiceProInput;
};


export type MutationCreateMenuArgs = {
  menu: CreateMenu;
};


export type MutationCreateMerchantClientArgs = {
  merchantClient: MerchantClientInput;
};


export type MutationCreateMerchantClientCompanyArgs = {
  company: CreateMerchantClientCompany;
};


export type MutationCreateMerchantPersonnelArgs = {
  merchantPersonnel: CreateMerchantPersonnelInput;
};


export type MutationCreateMerchantPersonnelPermissionGroupArgs = {
  permissionGroup: CreateUpdateMerchantPersonnelPermissionsGroupInput;
};


export type MutationCreateOnlineSalesAvailabilityArgs = {
  onlineSalesAvailability: CreateOnlineSalesAvailabilityInput;
};


export type MutationCreateOrderForProArgs = {
  order: CreateOnlineSalesOrderProInput;
};


export type MutationCreatePricingRateArgs = {
  pricingRateName: Scalars["String"];
};


export type MutationCreateProductArgs = {
  product: CreateProduct;
};


export type MutationCreateProductCategoryArgs = {
  category: CreateProductCategory;
};


export type MutationCreateProductGroupOptionArgs = {
  groupOption: CreateGroupOption;
};


export type MutationCreateProductOptionArgs = {
  option: CreateOption;
};


export type MutationCreatePublicationArgs = {
  publication: CreatePublicationInput;
};


export type MutationCreateQuoteForProArgs = {
  quote: CreateQuoteProInput;
};


export type MutationCreateReviewArgs = {
  rating: CreateReview;
};


export type MutationDeleteBookingAvailabilityArgs = {
  availabilityId: Scalars["String"];
};


export type MutationDeleteBookingFloorArgs = {
  floorId: Scalars["String"];
};


export type MutationDeleteBookingForProArgs = {
  bookingId: Scalars["String"];
};


export type MutationDeleteBookingServiceArgs = {
  serviceId: Scalars["String"];
};


export type MutationDeleteDeliveryOrderArgs = {
  orderId: Scalars["String"];
};


export type MutationDeleteInvoiceForProArgs = {
  invoiceId: Scalars["String"];
};


export type MutationDeleteMenuArgs = {
  menuId: Scalars["String"];
};


export type MutationDeleteMerchantClientArgs = {
  merchantClientId: Scalars["String"];
};


export type MutationDeleteMerchantClientCompanyArgs = {
  companyId: Scalars["String"];
};


export type MutationDeleteMerchantPersonnelArgs = {
  merchantPersonnelId: Scalars["String"];
};


export type MutationDeleteMerchantPersonnelPermissionGroupArgs = {
  permissionGroupId: Scalars["String"];
};


export type MutationDeleteOnlineSalesAvailabilityArgs = {
  availabilityId: Scalars["String"];
};


export type MutationDeleteOrderByProArgs = {
  orderId: Scalars["String"];
};


export type MutationDeletePopCallArgs = {
  popCallId: Scalars["String"];
};


export type MutationDeletePricingRateArgs = {
  pricingRateId: Scalars["String"];
};


export type MutationDeleteProductArgs = {
  productId: Scalars["String"];
};


export type MutationDeleteProductGroupOptionArgs = {
  id: Scalars["String"];
};


export type MutationDeleteProductOptionArgs = {
  optionId: Scalars["String"];
};


export type MutationDeletePublicationArgs = {
  publicationId: Scalars["String"];
};


export type MutationDeleteQuoteForProArgs = {
  quoteId: Scalars["String"];
};


export type MutationDelteProductCategoryArgs = {
  categoryId: Scalars["String"];
};


export type MutationLinkMerchantClientToMerchantCompanyArgs = {
  merchantClientCompanyId: Scalars["String"];
  merchantClientId: Scalars["String"];
};


export type MutationLoginProArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};


export type MutationRegisterArgs = {
  registerUser: RegisterUserInput;
};


export type MutationUpdateBookingAvailabilityArgs = {
  availabilityId: Scalars["String"];
  bookingAvailability: UpdateBookingAvailabilityInput;
};


export type MutationUpdateBookingFloorArgs = {
  floor: UpdateBookingFloorInput;
  floorId: Scalars["String"];
};


export type MutationUpdateBookingForProArgs = {
  booking: UpdateBookingProInput;
  bookingId: Scalars["String"];
};


export type MutationUpdateBookingServiceArgs = {
  service: UpdateBookingAvailabilityInput;
  serviceId: Scalars["String"];
};


export type MutationUpdateBookingSettingsArgs = {
  updates: UpdateBookingSettings;
};


export type MutationUpdateDeliveryOrderArgs = {
  order: UpdateDeliveryOrderProInput;
  orderId: Scalars["String"];
};


export type MutationUpdateDeliverySettingsArgs = {
  updates: UpdateDeliverySettings;
};


export type MutationUpdateInvoiceForProArgs = {
  invoice: UpdateInvoiceProInput;
  invoiceId: Scalars["String"];
};


export type MutationUpdateMenuArgs = {
  menuId: Scalars["String"];
  updates: UpdateMenu;
};


export type MutationUpdateMerchantArgs = {
  permissionGroup: CreateUpdateMerchantPersonnelPermissionsGroupInput;
  permissionGroupId: Scalars["String"];
};


export type MutationUpdateMerchantClientArgs = {
  merchantClient: UpdateMerchantClient;
  merchantClientId: Scalars["String"];
};


export type MutationUpdateMerchantClientCompanyArgs = {
  company: UpdateMerchantClientCompany;
  companyId: Scalars["String"];
};


export type MutationUpdateMerchantPersonnelArgs = {
  merchantPersonnel: UpdateMerchantPersonnelInput;
  merchantPersonnelId: Scalars["String"];
};


export type MutationUpdateMerchantSettingsArgs = {
  updates: UpdateMerchantSettings;
};


export type MutationUpdateOnlineSalesAvailabilityArgs = {
  availabilityId: Scalars["String"];
  onlineSalesAvailability: UpdateOnlineSalesAvailabilityInput;
};


export type MutationUpdateOrderForProArgs = {
  order: UpdateOnlineSalesOrderProInput;
  orderId: Scalars["String"];
};


export type MutationUpdatePopCallArgs = {
  popCallId: Scalars["String"];
  popcall: UpdatePopcall;
};


export type MutationUpdatePricingRateArgs = {
  pricingRateId: Scalars["String"];
  pricingRateName: Scalars["String"];
};


export type MutationUpdateProductArgs = {
  productId: Scalars["String"];
  updates: UpdateProduct;
};


export type MutationUpdateProductCategoryArgs = {
  categoryId: Scalars["String"];
  updates: UpdateProductCategory;
};


export type MutationUpdateProductGroupOptionArgs = {
  groupOption: UpdateOption;
  id: Scalars["String"];
};


export type MutationUpdateProductOptionArgs = {
  optionId: Scalars["String"];
  updates: UpdateOption;
};


export type MutationUpdatePublicationArgs = {
  publication: UpdatePublicationInput;
  publicationId: Scalars["String"];
};


export type MutationUpdateQuoteForProArgs = {
  quote: UpdateQuoteProInput;
  quoteId: Scalars["String"];
};


export type MutationUpdateReviewArgs = {
  reviewId: Scalars["String"];
  updates: UpdateReview;
};

export enum Origin {
  AppMobile = "APP_MOBILE",
  AppWeb = "APP_WEB",
  Google = "GOOGLE",
  Iframe = "IFRAME",
  Local = "LOCAL",
  Phone = "PHONE"
}

export type OnlineSalesAutomaticConfirmationSettingsInput = {
  acceptAutomatically: Scalars["Boolean"];
  areLimitsEnabledForConfirmation: Scalars["Boolean"];
  maxArticlesForConfirmation: MinMaxConfigurationInput;
  maxPriceForConfirmation: MinMaxConfigurationInput;
  minArticlesForConfirmation: MinMaxConfigurationInput;
  minPriceForConfirmation: MinMaxConfigurationInput;
};

export type OnlineSalesAvailability = {
  __typename?: "OnlineSalesAvailability";
  _id: Scalars["String"];
  areLimitsEnabled: Scalars["Boolean"];
  automaticClickConfirmationSettings: AutomaticOnlineSalesConfirmationSettings;
  createdAt: Scalars["DateTime"];
  daysAvailable: Array<Scalars["Float"]>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfiguration;
  maxOrdersAllowedForSlot: Scalars["Float"];
  maxPrice: MinMaxConfiguration;
  merchantId: Scalars["String"];
  minArticles: MinMaxConfiguration;
  minPrice: MinMaxConfiguration;
  name: Scalars["String"];
  slots: Array<OnlineSalesServiceSlot>;
  startTime: Scalars["String"];
  totalOrdersAllowed: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  waitingListSettings: OnlineSalesWaitingListSettings;
};

export type OnlineSalesServiceSlot = {
  __typename?: "OnlineSalesServiceSlot";
  _id: Scalars["String"];
  areLimitsEnabled: Scalars["Boolean"];
  automaticClickConfirmationSettings: AutomaticOnlineSalesConfirmationSettings;
  day: Scalars["Float"];
  endTime: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfiguration;
  maxPrice: MinMaxConfiguration;
  minArticles: MinMaxConfiguration;
  minPrice: MinMaxConfiguration;
  startTime: Scalars["String"];
  totalOrdersAllowed: Scalars["Float"];
  waitingListSettings: OnlineSalesWaitingListSettings;
};

export type OnlineSalesServiceSlotInput = {
  _id: Scalars["String"];
  areLimitsEnabled: Scalars["Boolean"];
  automaticClickConfirmationSettings: OnlineSalesAutomaticConfirmationSettingsInput;
  day: Scalars["Float"];
  endTime: Scalars["String"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfigurationInput;
  maxPrice: MinMaxConfigurationInput;
  minArticles: MinMaxConfigurationInput;
  minPrice: MinMaxConfigurationInput;
  startTime: Scalars["String"];
  totalOrdersAllowed: Scalars["Float"];
  waitingListSettings: OnlineSalesWaitingListInput;
};

export type OnlineSalesWaitingListInput = {
  areLimitsEnabled: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfigurationInput;
  maxPrice: MinMaxConfigurationInput;
  minArticles: MinMaxConfigurationInput;
  minPrice: MinMaxConfigurationInput;
  totalOrdersAllowed: Scalars["Float"];
};

export type OnlineSalesWaitingListSettings = {
  __typename?: "OnlineSalesWaitingListSettings";
  areLimitsEnabled: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfiguration;
  maxPrice: MinMaxConfiguration;
  minArticles: MinMaxConfiguration;
  minPrice: MinMaxConfiguration;
  totalOrdersAllowed: Scalars["Float"];
};

export type OpenHour = {
  __typename?: "OpenHour";
  closeAt: Scalars["String"];
  day: Array<Scalars["Float"]>;
  openAt: Scalars["String"];
};

export type OpenHourInput = {
  closeAt: Scalars["String"];
  day: Scalars["Float"];
  openAt: Scalars["String"];
};

export type Option = {
  __typename?: "Option";
  _id: Scalars["String"];
  baseAvailability: Availability;
  basePrice: Price;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  groupOptions: Array<GroupOption>;
  isDeleted: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  pricingRates: Array<PricingRateAvailability>;
  updatedAt: Scalars["DateTime"];
};

export type OptionOrder = {
  __typename?: "OptionOrder";
  name: Scalars["String"];
  optionId: Scalars["String"];
  price: Price;
};

export type OptionOrderInput = {
  name: Scalars["String"];
  optionId: Scalars["String"];
  price: PriceInput;
};

export type Order = {
  __typename?: "Order";
  _id: Scalars["String"];
  comment: Scalars["String"];
  commentOwner: Scalars["String"];
  createdAt: Scalars["DateTime"];
  date: Scalars["DateTime"];
  googleIdempotencyToken?: Maybe<Scalars["String"]>;
  googleUserId?: Maybe<Scalars["String"]>;
  isDeleted: Scalars["Boolean"];
  isSync: Scalars["Boolean"];
  items: OrderItems;
  merchantClient: MerchantClient;
  merchantId: Scalars["String"];
  merchantTip: Scalars["Float"];
  orderNumber: Scalars["String"];
  orderQuantity: Scalars["Float"];
  payment: PaymentOrder;
  refusalReason: Scalars["String"];
  serviceId: Scalars["String"];
  shareCode: Scalars["String"];
  slotId: Scalars["String"];
  source: Scalars["Float"];
  status: Scalars["Float"];
  timeline: Timeline;
  totalDetails: TotalDetails;
  updatedAt: Scalars["DateTime"];
};

export type OrderItems = {
  __typename?: "OrderItems";
  menus: Array<MenuOrder>;
  products: Array<ProductOrder>;
};

export type OrderItemsInput = {
  menus: Array<MenuOrderInput>;
  products: Array<ProductOrderInput>;
};

export type OrdersAndServices = {
  __typename?: "OrdersAndServices";
  accumulatedTotalInService: Scalars["Float"];
  daysAvailable: Array<Scalars["Float"]>;
  serviceId: Scalars["String"];
  serviceName: Scalars["String"];
  slots: Array<OrdersAndServicesSlot>;
  startTime: Scalars["String"];
  totalInService: Scalars["Float"];
};

export type OrdersAndServicesSlot = {
  __typename?: "OrdersAndServicesSlot";
  accumulatedTotalInSlot: Scalars["Float"];
  data: Array<Order>;
  slotId: Scalars["String"];
  slotTime: Scalars["String"];
  totalInSlot: Scalars["Float"];
};

export enum Publication_Cta {
  Buy = "BUY",
  Call = "CALL",
  MoreInfo = "MORE_INFO",
  None = "NONE",
  Order = "ORDER",
  Reserve = "RESERVE"
}

export enum Publication_Type {
  Event = "EVENT",
  NewInfo = "NEW_INFO",
  Offer = "OFFER"
}

export type Pagination = {
  limit?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Float"]>;
};

export type PaymentOrder = {
  __typename?: "PaymentOrder";
  cardPaymentStatus: Scalars["Float"];
  paid: Scalars["Boolean"];
  paymentErrorReason: Scalars["String"];
  paymentIntentId: Scalars["String"];
  paymentMethod?: Maybe<MerchantSettingsPaymentMethod>;
  paymentType: Scalars["String"];
};

export type Permission = {
  __typename?: "Permission";
  module: Modules;
  permissionLevel: PermissionLevel;
};

export type PermissionInput = {
  module: Modules;
  permissionLevel: PermissionLevel;
};

export enum PermissionLevel {
  AskPermission = "ASK_PERMISSION",
  Disabled = "DISABLED",
  Execute = "EXECUTE",
  Read = "READ",
  Write = "WRITE"
}

export type PhysicalProduct = {
  __typename?: "PhysicalProduct";
  alertLimitUnavailableForOnlineSales: Scalars["Float"];
  enableStock: Scalars["Boolean"];
  initialStock: Scalars["Float"];
  isStockDepletedByPro: Scalars["Boolean"];
  unavailableLimitForOnlineSales: Scalars["Float"];
};

export type PhysicalProductInput = {
  alertLimitUnavailableForOnlineSales: Scalars["Float"];
  enableStock: Scalars["Boolean"];
  initialStock: Scalars["Float"];
  isPhysical: Scalars["Boolean"];
  isStockDepletedByPro: Scalars["Boolean"];
  unavailableLimitForOnlineSales: Scalars["Float"];
};

export type PointCoordinates = {
  __typename?: "PointCoordinates";
  coordinates: Array<Scalars["Float"]>;
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  type: Scalars["String"];
};

export type PointCoordinatesInput = {
  coordinates: Array<Scalars["Float"]>;
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  type: Scalars["String"];
};

export type Popcall = {
  __typename?: "Popcall";
  _id: Scalars["String"];
  acknowledged: Scalars["Boolean"];
  callCallerIdValidPhone: Scalars["String"];
  callCurrentStatus: Scalars["String"];
  callDateMillis: Scalars["Float"];
  callId: Scalars["String"];
  callPhoneNumber: Scalars["String"];
  isDeleted: Scalars["Boolean"];
  isNewCustomer: Scalars["Boolean"];
  merchantClientId: Scalars["String"];
  merchantId: Scalars["String"];
};

export type Prepayment = {
  __typename?: "Prepayment";
  amountForPrepayment: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  minCapacityForPrepayment: Scalars["Float"];
  percentBookingCancel: Scalars["Float"];
  percentBookingNoShow: Scalars["Float"];
  prepaymentCancelSettings: PrepaymentCancel;
  prepaymentType: PrepaymentType;
};

export type PrepaymentCancel = {
  __typename?: "PrepaymentCancel";
  delimiter: PrepaymentCancelDelimiter;
  gracePeriodInDays: Scalars["Float"];
  gracePeriodInMinutes: Scalars["Float"];
};

export enum PrepaymentCancelDelimiter {
  Reservation = "RESERVATION",
  Service = "SERVICE"
}

export type PrepaymentCancelInput = {
  delimiter: PrepaymentCancelDelimiter;
  gracePeriodInDays: Scalars["Float"];
  gracePeriodInMinutes: Scalars["Float"];
};

export type PrepaymentInput = {
  amountForPrepayment: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  minCapacityForPrepayment: Scalars["Float"];
  percentBookingCancel: Scalars["Float"];
  percentBookingNoShow: Scalars["Float"];
  prepaymentCancelSettings: PrepaymentCancelInput;
  prepaymentType: PrepaymentType;
};

export enum PrepaymentType {
  FixedRate = "FIXED_RATE",
  PerPerson = "PER_PERSON"
}

export type Price = {
  __typename?: "Price";
  amount: Scalars["Float"];
  taxRate: Scalars["Float"];
};

export type PriceInput = {
  amount: Scalars["Float"];
  taxRate: Scalars["Float"];
};

export type PricingRate = {
  __typename?: "PricingRate";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  isDeleted: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type PricingRateAvailability = {
  __typename?: "PricingRateAvailability";
  availability: Availability;
  isEnabled: Scalars["Boolean"];
  price: Price;
  pricingRate: PricingRate;
};

export type PricingRateAvailabilityInput = {
  availability: AvailabilityInput;
  isEnabled: Scalars["Boolean"];
  price: PriceInput;
  pricingRate: Scalars["String"];
};

export type Product = {
  __typename?: "Product";
  _id: Scalars["String"];
  baseAvailability: Availability;
  basePrice: Price;
  categories: Array<ProductCategory>;
  color: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  groupOptions: Array<GroupOption>;
  isDeleted: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  isPhysicalItem: Scalars["Boolean"];
  menuExtraPrice: Price;
  merchantId: Scalars["String"];
  name: Scalars["String"];
  physicalSettings: PhysicalProduct;
  picture: Scalars["String"];
  pricingRates: Array<PricingRateAvailability>;
  productCode: Scalars["String"];
  serviceSettings: ServiceProduct;
  slug: Scalars["String"];
  soldOnlyInMenu: Scalars["Boolean"];
  suggestedProducts: Array<Product>;
  updatedAt: Scalars["DateTime"];
};

export type ProductCategory = {
  __typename?: "ProductCategory";
  _id: Scalars["String"];
  color: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  iconUrl: Scalars["String"];
  isDeleted: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  merchantId: Scalars["String"];
  name: Scalars["String"];
  position: Scalars["Float"];
  products: Array<Product>;
  updatedAt: Scalars["DateTime"];
};

export type ProductOrder = {
  __typename?: "ProductOrder";
  groupOptions: Array<GroupOptionsOrder>;
  name: Scalars["String"];
  price: Price;
  productId: Scalars["String"];
  quantity: Scalars["Float"];
};

export type ProductOrderInput = {
  groupOptions: Array<GroupOptionsOrderInput>;
  name: Scalars["String"];
  price: PriceInput;
  productId: Scalars["String"];
  quantity: Scalars["Float"];
};

export type Profile = {
  __typename?: "Profile";
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  picture: Scalars["String"];
};

export type Publication = {
  __typename?: "Publication";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  cta: Publication_Cta;
  ctaLink: Scalars["String"];
  description: Scalars["String"];
  eventCode: Scalars["String"];
  eventPrice: Scalars["Float"];
  images: Array<Scalars["String"]>;
  isBoosted: Scalars["Boolean"];
  isDeleted: Scalars["Boolean"];
  isPinned: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  isPublishedOnFacebook: Scalars["Boolean"];
  isPublishedOnInstagram: Scalars["Boolean"];
  isRecurring: Scalars["Boolean"];
  isVisibleOnline: Scalars["Boolean"];
  merchantId: Scalars["String"];
  promoCode: Scalars["String"];
  recurringDates?: Maybe<RecurringDates>;
  specificDates?: Maybe<SpecificDates>;
  tags: Array<Scalars["String"]>;
  title: Scalars["String"];
  type: Publication_Type;
  updatedAt: Scalars["DateTime"];
  usageConditions: Scalars["String"];
};

export enum Quote_Status {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Modified = "MODIFIED",
  New = "NEW",
  Rejected = "REJECTED",
  Sent = "SENT"
}

export type Query = {
  __typename?: "Query";
  getBookingAvailabilities: Array<BookingAvailability>;
  getBookingAvailabilitiesWithBookingInfo: Array<AvailabilityServiceWithModuleInfo>;
  getBookingAvailability: BookingAvailability;
  getBookingAvailabilityWithBookingInfo: AvailabilityServiceWithModuleInfo;
  getBookingFloors: BookingSettings;
  getBookingForPro: Booking;
  getBookingService: BookingSettings;
  getBookingsByMonth: BookingsByMonth;
  getBookingsByServices: Array<BookingsAndServices>;
  getBookingsForPro: Array<Booking>;
  getConversation: Conversation;
  getConversations: Array<Conversation>;
  getDeliverOrders: Array<DeliveryOrder>;
  getDeliveryOrder: DeliveryOrder;
  getDeliverySettings: DeliverySettings;
  getInvoiceForPro: Invoice;
  getInvoicesForPro: Array<Invoice>;
  getMenu: Option;
  getMenus: Array<Option>;
  getMerchant: Merchant;
  getMerchantClient: MerchantClient;
  getMerchantClientCompanies: Array<MerchantClientCompany>;
  getMerchantClientCompany: MerchantClientCompany;
  getMerchantClients: Array<MerchantClient>;
  getMerchantPersonnel: MerchantPersonnel;
  getMerchantPersonnelPermissionGroups: Array<MerchantPersonnelPermissionsGroups>;
  getMerchantPersonnels: Array<MerchantPersonnel>;
  getMerchantReview: Review;
  getMerchantReviews: Array<Review>;
  getMerchantSettings: MerchantSettings;
  getOnlineSalesAvailabilities: Array<OnlineSalesAvailability>;
  getOnlineSalesAvailabilitiesWithOrderInfo: Array<AvailabilityServiceWithModuleInfo>;
  getOnlineSalesAvailability: OnlineSalesAvailability;
  getOnlineSalesAvailabilityWithOrderInfo: AvailabilityServiceWithModuleInfo;
  getOrderForPro: Order;
  getOrdersAndServices: Array<OrdersAndServices>;
  getOrdersForPro: Array<Order>;
  getPopCall: Popcall;
  getPopCalls: Array<Popcall>;
  getPricingRateById?: Maybe<PricingRate>;
  getPricingRates: Array<PricingRate>;
  getProduct: Option;
  getProductCategories: Array<ProductCategory>;
  getProductCategory: ProductCategory;
  getProductGroupOption: GroupOption;
  getProductGroupOptions: Array<GroupOption>;
  getProductOption: Option;
  getProductOptions: Array<Option>;
  getProducts: Array<Option>;
  getPublication: Publication;
  getPublications: Array<Publication>;
  getQuoteForPro: Quote;
  getQuotesForPro: Array<QuoteInvoicesByMonth>;
  getUser: Scalars["String"];
};


export type QueryGetBookingAvailabilitiesWithBookingInfoArgs = {
  date: Scalars["DateTime"];
};


export type QueryGetBookingAvailabilityArgs = {
  availabilityId: Scalars["String"];
};


export type QueryGetBookingAvailabilityWithBookingInfoArgs = {
  date: Scalars["DateTime"];
  serviceId: Scalars["String"];
};


export type QueryGetBookingForProArgs = {
  bookingId: Scalars["String"];
};


export type QueryGetBookingsByMonthArgs = {
  date: Scalars["DateTime"];
};


export type QueryGetBookingsByServicesArgs = {
  date: Scalars["DateTime"];
};


export type QueryGetConversationArgs = {
  conversationId: Scalars["String"];
};


export type QueryGetMenuArgs = {
  menuId: Scalars["String"];
};


export type QueryGetMenusArgs = {
  pagination: Pagination;
};


export type QueryGetMerchantClientCompanyArgs = {
  companyId: Scalars["String"];
};


export type QueryGetMerchantClientsArgs = {
  pagination: Pagination;
};


export type QueryGetMerchantPersonnelArgs = {
  merchantPersonnelId: Scalars["String"];
};


export type QueryGetMerchantPersonnelsArgs = {
  pagination: Pagination;
};


export type QueryGetMerchantReviewArgs = {
  reviewId: Scalars["String"];
};


export type QueryGetMerchantReviewsArgs = {
  pagination: Pagination;
};


export type QueryGetOnlineSalesAvailabilitiesWithOrderInfoArgs = {
  date: Scalars["DateTime"];
};


export type QueryGetOnlineSalesAvailabilityArgs = {
  availabilityId: Scalars["String"];
};


export type QueryGetOnlineSalesAvailabilityWithOrderInfoArgs = {
  date: Scalars["DateTime"];
  serviceId: Scalars["String"];
};


export type QueryGetOrderForProArgs = {
  orderId: Scalars["String"];
};


export type QueryGetOrdersAndServicesArgs = {
  date: Scalars["DateTime"];
};


export type QueryGetOrdersForProArgs = {
  pagination: Pagination;
};


export type QueryGetPopCallArgs = {
  popCallId: Scalars["String"];
};


export type QueryGetPricingRateByIdArgs = {
  pricingRateId: Scalars["String"];
};


export type QueryGetProductArgs = {
  productId: Scalars["String"];
};


export type QueryGetProductCategoriesArgs = {
  pagination: Pagination;
};


export type QueryGetProductCategoryArgs = {
  categoryId: Scalars["String"];
};


export type QueryGetProductGroupOptionArgs = {
  id: Scalars["String"];
};


export type QueryGetProductGroupOptionsArgs = {
  pagination: Pagination;
};


export type QueryGetProductOptionArgs = {
  optionId: Scalars["String"];
};


export type QueryGetProductOptionsArgs = {
  pagination: Pagination;
};


export type QueryGetProductsArgs = {
  pagination: Pagination;
};


export type QueryGetPublicationArgs = {
  publicationId: Scalars["String"];
};


export type QueryGetQuoteForProArgs = {
  quoteId: Scalars["String"];
};


export type QueryGetQuotesForProArgs = {
  pagination: Pagination;
};

export type Quote = {
  __typename?: "Quote";
  _id: Scalars["String"];
  acceptedDate: Scalars["DateTime"];
  conditionOfAcceptance: Scalars["String"];
  createdAt: Scalars["DateTime"];
  deliveryAddress: Scalars["String"];
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  exipresAt: Scalars["DateTime"];
  extraField: Scalars["String"];
  isOnlinePaymentEnabled: Scalars["Boolean"];
  isSignatureRequired: Scalars["Boolean"];
  items: QuoteItems;
  merchantClient: MerchantClient;
  merchantId: Scalars["String"];
  notes: Scalars["String"];
  number: Scalars["String"];
  pdfUrl: Scalars["String"];
  recepientEmails: Array<Scalars["String"]>;
  refusalReason: Scalars["String"];
  revisionNumber: Scalars["Float"];
  sentDate: Scalars["DateTime"];
  signedAt: Scalars["DateTime"];
  status: Scalars["Float"];
  title: Scalars["String"];
  totalDetails: TotalDetails;
  updatedAt: Scalars["DateTime"];
  validityInDays: Scalars["Float"];
};

export type QuoteBasicItem = {
  __typename?: "QuoteBasicItem";
  position: Scalars["Float"];
  price: Price;
  quantity: Scalars["Float"];
  title: Scalars["String"];
};

export type QuoteBasicItemInput = {
  position: Scalars["Float"];
  price: PriceInput;
  quantity: Scalars["Float"];
  title: Scalars["String"];
};

export type QuoteCommentItem = {
  __typename?: "QuoteCommentItem";
  comment: Scalars["String"];
  position: Scalars["Float"];
};

export type QuoteCommentItemInput = {
  comment: Scalars["String"];
  position: Scalars["Float"];
};

export type QuoteInvoicesByMonth = {
  __typename?: "QuoteInvoicesByMonth";
  accumulatedTotal: Scalars["Float"];
  month: Scalars["String"];
  quotes: Array<Quote>;
};

export type QuoteItems = {
  __typename?: "QuoteItems";
  basicItems: Array<QuoteBasicItem>;
  commentItems: Array<QuoteCommentItem>;
  productItems: Array<QuoteProductItem>;
};

export type QuoteItemsInput = {
  basicItems: Array<QuoteBasicItemInput>;
  commentItems: Array<QuoteCommentItemInput>;
  productItems: Array<QuoteProductItemInput>;
};

export type QuoteProductItem = {
  __typename?: "QuoteProductItem";
  name: Scalars["String"];
  position: Scalars["Float"];
  price: Price;
  productId: Product;
  quantity: Scalars["Float"];
};

export type QuoteProductItemInput = {
  name: Scalars["String"];
  position: Scalars["Float"];
  price: PriceInput;
  productId: Scalars["String"];
  quantity: Scalars["Float"];
};

export type RangeDate = {
  __typename?: "RangeDate";
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type RangeDateInput = {
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type RecurringDates = {
  __typename?: "RecurringDates";
  days: Array<Scalars["Float"]>;
  endTime?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["String"]>;
};

export type RecurringDatesInput = {
  days: Array<Scalars["Float"]>;
  endTime: Scalars["String"];
  startTime: Scalars["String"];
};

/** New user */
export type RegisterUserInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  profile: UserProfileInput;
};

export type Review = {
  __typename?: "Review";
  _id: Scalars["String"];
  adminApprovesReportOfOwner?: Maybe<Scalars["Boolean"]>;
  answer: Scalars["String"];
  comment: Scalars["String"];
  createdAt: Scalars["DateTime"];
  date: Scalars["DateTime"];
  dateOfModule: Scalars["DateTime"];
  merchantClient?: Maybe<MerchantClient>;
  merchantId: Scalars["String"];
  module: Scalars["String"];
  reportComment: Scalars["String"];
  reported: Scalars["Boolean"];
  resourceId: Scalars["String"];
  reviewRating: Scalars["Float"];
  source: Scalars["Float"];
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export enum Service_Visibility {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
  Partially = "PARTIALLY"
}

export enum Sex {
  Female = "FEMALE",
  Male = "MALE"
}

export type Selection = {
  __typename?: "Selection";
  allowDuplicate: Scalars["Boolean"];
  authoriseExtraSelection: Scalars["Boolean"];
  extraPrice: Price;
  maxSelection: Scalars["Float"];
  minSelection: Scalars["Float"];
};

export type SelectionInput = {
  allowDuplicate: Scalars["Boolean"];
  authoriseExtraSelection: Scalars["Boolean"];
  extraPrice: PriceInput;
  maxSelection: Scalars["Float"];
  minSelection: Scalars["Float"];
};

export type ServiceProduct = {
  __typename?: "ServiceProduct";
  durationInMinutes: Scalars["Float"];
  hasDuration: Scalars["Boolean"];
  personnel: Array<MerchantPersonnel>;
};

export type ServiceProductInput = {
  durationInMinutes: Scalars["Float"];
  hasDuration: Scalars["Boolean"];
  isPhysical: Scalars["Boolean"];
  personnel: Array<Scalars["String"]>;
};

export type SpecificDates = {
  __typename?: "SpecificDates";
  endDate: Scalars["DateTime"];
  endTime?: Maybe<Scalars["String"]>;
  startDate: Scalars["DateTime"];
  startTime?: Maybe<Scalars["String"]>;
};

export type SpecificDatesInput = {
  endDate: Scalars["DateTime"];
  endTime: Scalars["String"];
  startDate: Scalars["DateTime"];
  startTime: Scalars["String"];
};

export enum Table_Form {
  Circle = "CIRCLE",
  Rectangle = "RECTANGLE",
  Square = "SQUARE"
}

export type Table = {
  __typename?: "Table";
  _id: Scalars["String"];
  averageDurationInMinutes: Scalars["Float"];
  isEligibleForBooking: Scalars["Boolean"];
  maxCapacity: Scalars["Float"];
  minCapacity: Scalars["Float"];
  positionX: Scalars["Float"];
  positionY: Scalars["Float"];
  prefix: Scalars["String"];
  rotationDeg: Scalars["Float"];
  shape: Table_Form;
  tableNumber: Scalars["String"];
};

export type TableInput = {
  _id: Scalars["String"];
  averageDurationInMinutes: Scalars["Float"];
  isEligibleForBooking: Scalars["Boolean"];
  maxCapacity: Scalars["Float"];
  minCapacity: Scalars["Float"];
  positionX: Scalars["Float"];
  positionY: Scalars["Float"];
  prefix: Scalars["String"];
  quantity: Scalars["Float"];
  rotationDeg: Scalars["Float"];
  shape: Table_Form;
  tableNumber: Scalars["String"];
};

export type Timeline = {
  __typename?: "Timeline";
  events: Array<TimelineEvent>;
  messages: Array<TimelineMessage>;
};

export type TimelineEvent = {
  __typename?: "TimelineEvent";
  eventAction: Scalars["String"];
  eventAuthor: TimelineEventAuthor;
  eventDate: Scalars["DateTime"];
  eventType: Scalars["String"];
  modifiedState: Scalars["String"];
  previousState: Scalars["String"];
  status: Scalars["Float"];
};

export type TimelineEventAuthor = {
  __typename?: "TimelineEventAuthor";
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  userType: Scalars["String"];
};

export type TimelineMessage = {
  __typename?: "TimelineMessage";
  messageContent: Scalars["String"];
  messageDate: Scalars["DateTime"];
  messageId: Scalars["String"];
  messageStatus: Scalars["String"];
  messageTitle: Scalars["String"];
  messageType: Scalars["String"];
};

export type TotalDetails = {
  __typename?: "TotalDetails";
  currency: Scalars["String"];
  discounts: Array<ComputedDiscounts>;
  shippingAmount: Scalars["Float"];
  subTotal: Scalars["Float"];
  taxes: Array<ComputedTaxes>;
  total: Scalars["Float"];
  totalDiscountAmount: Scalars["Float"];
  totalTaxAmount: Scalars["Float"];
  totalWithTaxes: Scalars["Float"];
  totalWithoutTaxes: Scalars["Float"];
};

export type UpdateBookingAvailabilityInput = {
  areLimitsEnabled: Scalars["Boolean"];
  automaticConfirmationSettings: BookingAutomaticConfirmationSettingsInput;
  daysAvailable: Array<Days>;
  durationPerCapacity: Array<DurationConfigurationPerCapacityInput>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxCapacityAllowedForSlot: Scalars["Float"];
  maxPersonsForBooking: Scalars["Float"];
  minPersonsForBooking: Scalars["Float"];
  name: Scalars["String"];
  prepaymentSettings: Array<PrepaymentInput>;
  requireReconfirmation: Scalars["Boolean"];
  slots: Array<BookingSlotInput>;
  startTime: Scalars["String"];
  totalPersonsAllowed: Scalars["Float"];
  waitingListSettings: WaitingListInput;
};

export type UpdateBookingFloorInput = {
  decorations: Array<DecorationInput>;
  height: Scalars["Float"];
  name: Scalars["String"];
  position: Scalars["Float"];
  prefix: Scalars["String"];
  tables: Array<TableInput>;
  width: Scalars["Float"];
};

/** Update Booking by pro */
export type UpdateBookingProInput = {
  allergies?: InputMaybe<Scalars["String"]>;
  client?: InputMaybe<UpdateMerchantClient>;
  commentOwner?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
  nbPersons?: InputMaybe<Scalars["Float"]>;
  refusalReason: Scalars["String"];
  serviceId?: InputMaybe<Scalars["String"]>;
  slotId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Float"]>;
  tables?: InputMaybe<Array<BookingTableInput>>;
};

export type UpdateBookingSettings = {
  alertLateBookings?: InputMaybe<Scalars["Boolean"]>;
  automaticConfirmationSettings?: InputMaybe<BookingAutomaticConfirmationSettingsInput>;
  defaultPrepaymentSettings?: InputMaybe<PrepaymentInput>;
  isBookingEnabledForGoogle?: InputMaybe<Scalars["Boolean"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateDeliveryOrderProInput = {
  amount: Scalars["Float"];
  commentOwner: Scalars["String"];
  date: Scalars["DateTime"];
  deliveryAddress: Scalars["String"];
  items: OrderItemsInput;
  merchantClient: Scalars["String"];
  merchantTip: Scalars["Float"];
  serviceId: Scalars["String"];
  slotId: Scalars["String"];
  status: Click_Status;
};

export type UpdateDeliverySettings = {
  deliveryRegions: Array<DeliveryPerimeterInput>;
  isEnabled: Scalars["Boolean"];
  message: Scalars["String"];
  pricingRate: Scalars["String"];
};

export type UpdateInvoiceProInput = {
  acceptedDate: Scalars["DateTime"];
  client: Scalars["String"];
  conditionOfAcceptance: Scalars["String"];
  deliveryAddress: Scalars["String"];
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  exipresAt: Scalars["DateTime"];
  extraField: Scalars["String"];
  isOnlinePaymentEnabled: Scalars["Boolean"];
  isSignatureRequired: Scalars["Boolean"];
  items: QuoteItemsInput;
  notes: Scalars["String"];
  number: Scalars["String"];
  pdfUrl: Scalars["String"];
  recepientEmails: Array<Scalars["String"]>;
  refusalReason: Scalars["String"];
  sentDate: Scalars["DateTime"];
  signedAt: Scalars["DateTime"];
  status: Quote_Status;
  title: Scalars["String"];
  validityInDays: Scalars["Float"];
};

export type UpdateMenu = {
  baseAvailability?: InputMaybe<AvailabilityInput>;
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  draftMenuSteps?: InputMaybe<Array<MenuStepInput>>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Float"]>;
  prices?: InputMaybe<Array<MenuPriceInput>>;
  productCode?: InputMaybe<Scalars["String"]>;
  savedMenuSteps?: InputMaybe<Array<MenuStepInput>>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type UpdateMerchant = {
  address: AddressInput;
  description: Scalars["String"];
  email: Scalars["String"];
  gps: PointCoordinatesInput;
  images: Array<Scalars["String"]>;
  label: Scalars["String"];
  openingHours: Array<OpenHourInput>;
  phone: Scalars["String"];
};

export type UpdateMerchantClient = {
  _id: Scalars["String"];
  acceptedReceiveMarketingSms?: InputMaybe<Scalars["Boolean"]>;
  acceptedReceiveNewsLetters?: InputMaybe<Scalars["Boolean"]>;
  address?: InputMaybe<AddressInput>;
  comment: Scalars["String"];
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  isBlacklisted?: InputMaybe<Scalars["Boolean"]>;
  isVIP: Scalars["Boolean"];
  language?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  merchantCompanyId?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
  picture: Scalars["String"];
  secondaryEmail?: InputMaybe<Scalars["String"]>;
  secondaryPhone: Scalars["String"];
  sex: Sex;
};

export type UpdateMerchantClientCompany = {
  address: AddressInput;
  companyName: Scalars["String"];
  phone: Scalars["String"];
  secondaryPhone: Scalars["String"];
};

export type UpdateMerchantPersonnelInput = {
  additionalPermissions: Array<PermissionInput>;
  eligibleBooking: Scalars["Boolean"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  isDeleted: Scalars["Boolean"];
  isDisabled: Scalars["Boolean"];
  jobTitle: Scalars["String"];
  lastName: Scalars["String"];
  loginCode: Scalars["String"];
  loginStrategy: LoginStrategy;
  name: Scalars["String"];
  permissionGroup: Scalars["String"];
  phone: Scalars["String"];
  role: Merchant_Personnel_Roles;
  secondaryPhone: Scalars["String"];
  workHours: Array<OpenHourInput>;
};

export type UpdateMerchantSettings = {
  billingDetails: MerchantSettingsBillingDetailsInput;
  closedDates: Array<RangeDateInput>;
  currency: Scalars["String"];
  language: Scalars["String"];
  socials: MerchantSettingSocialsInput;
  timeZone: Scalars["String"];
};

export type UpdateOnlineSalesAvailabilityInput = {
  areLimitsEnabled: Scalars["Boolean"];
  automaticClickConfirmationSettings: OnlineSalesAutomaticConfirmationSettingsInput;
  daysAvailable: Array<Scalars["Float"]>;
  endTime: Scalars["String"];
  interval: Scalars["Float"];
  isEnabled: Scalars["Boolean"];
  maxArticles: MinMaxConfigurationInput;
  maxOrdersAllowedForSlot: Scalars["Float"];
  maxPrice: MinMaxConfigurationInput;
  minArticles: MinMaxConfigurationInput;
  minPrice: MinMaxConfigurationInput;
  name: Scalars["String"];
  slots: Array<OnlineSalesServiceSlotInput>;
  startTime: Scalars["String"];
  totalOrdersAllowed: Scalars["Float"];
  waitingListSettings: OnlineSalesWaitingListInput;
};

export type UpdateOnlineSalesOrderProInput = {
  client?: InputMaybe<UpdateMerchantClient>;
  commentOwner?: InputMaybe<Scalars["String"]>;
  date?: InputMaybe<Scalars["DateTime"]>;
  refusalReason: Scalars["String"];
  serviceId?: InputMaybe<Scalars["String"]>;
  slotId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Float"]>;
};

export type UpdateOption = {
  baseAvailability?: InputMaybe<AvailabilityInput>;
  basePrice?: InputMaybe<PriceInput>;
  description?: InputMaybe<Scalars["String"]>;
  groupOptions?: InputMaybe<Array<Scalars["String"]>>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  pricingRates?: InputMaybe<PricingRateAvailabilityInput>;
};

export type UpdatePopcall = {
  acknowledged: Scalars["Boolean"];
  isNewCustomer: Scalars["Boolean"];
  merchantClientId: Scalars["String"];
};

export type UpdateProduct = {
  baseAvailability?: InputMaybe<AvailabilityInput>;
  basePrice?: InputMaybe<PriceInput>;
  categories?: InputMaybe<Array<Scalars["String"]>>;
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  groupOptions?: InputMaybe<Array<Scalars["String"]>>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  menuExtraPrice?: InputMaybe<PriceInput>;
  name?: InputMaybe<Scalars["String"]>;
  physicalSettings?: InputMaybe<PhysicalProductInput>;
  picture?: InputMaybe<Scalars["String"]>;
  pricingRates?: InputMaybe<Array<PricingRateAvailabilityInput>>;
  productCode?: InputMaybe<Scalars["String"]>;
  serviceSettings?: InputMaybe<ServiceProductInput>;
  slug?: InputMaybe<Scalars["String"]>;
  soldOnlyInMenu?: InputMaybe<Scalars["Boolean"]>;
  suggestedProducts?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateProductCategory = {
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  iconUrl?: InputMaybe<Scalars["String"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["Float"]>;
  products?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdatePublicationInput = {
  cta: Publication_Cta;
  ctaLink: Scalars["String"];
  description: Scalars["String"];
  eventCode: Scalars["String"];
  eventPrice: Scalars["Float"];
  images: Array<Scalars["String"]>;
  isBoosted: Scalars["Boolean"];
  isPinned: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  isPublishedOnFacebook: Scalars["Boolean"];
  isPublishedOnInstagram: Scalars["Boolean"];
  isRecurring: Scalars["Boolean"];
  isVisibleOnline: Scalars["Boolean"];
  promoCode: Scalars["String"];
  recurringDates?: InputMaybe<RecurringDatesInput>;
  specificDates?: InputMaybe<SpecificDatesInput>;
  tags: Array<Scalars["String"]>;
  title: Scalars["String"];
  type: Publication_Type;
  usageConditions: Scalars["String"];
};

export type UpdateQuoteProInput = {
  acceptedDate: Scalars["DateTime"];
  client?: InputMaybe<UpdateMerchantClient>;
  conditionOfAcceptance: Scalars["String"];
  deliveryAddress: Scalars["String"];
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  exipresAt: Scalars["DateTime"];
  extraField: Scalars["String"];
  isOnlinePaymentEnabled: Scalars["Boolean"];
  isSignatureRequired: Scalars["Boolean"];
  items: QuoteItemsInput;
  notes: Scalars["String"];
  number: Scalars["String"];
  pdfUrl: Scalars["String"];
  recepientEmails: Array<Scalars["String"]>;
  refusalReason: Scalars["String"];
  sentDate: Scalars["DateTime"];
  signedAt: Scalars["DateTime"];
  status: Quote_Status;
  title: Scalars["String"];
  validityInDays: Scalars["Float"];
};

export type UpdateReview = {
  answer: Scalars["String"];
  reportComment: Scalars["String"];
  reported: Scalars["Boolean"];
};

export type User = {
  __typename?: "User";
  _id: Scalars["String"];
  devices: Array<Device>;
  favoriteMerchants: Array<Scalars["String"]>;
  profile: Profile;
  settings: UserSettings;
};

/** User profile */
export type UserProfileInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  picture: Scalars["String"];
};

export type UserSettings = {
  __typename?: "UserSettings";
  language: Scalars["String"];
  shouldReceiveMarketingEmails: Scalars["Boolean"];
  shouldReceiveMarketingSms: Scalars["Boolean"];
};

export type VisibilitySettings = {
  __typename?: "VisibilitySettings";
  advanceDays: Scalars["Float"];
  disallowBookingByType: DisallowBookingType;
  isEnabled: Scalars["Boolean"];
  nbOfMinutesForDisallow: Scalars["Float"];
};

export type VisibilitySettingsInput = {
  advanceDays: Scalars["Float"];
  disallowBookingByType: DisallowBookingType;
  isEnabled: Scalars["Boolean"];
  nbOfMinutesForDisallow: Scalars["Float"];
};

export type WaitingList = {
  __typename?: "WaitingList";
  isEnabled: Scalars["Boolean"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
  totalPersonsAllowed: Scalars["Float"];
};

export type WaitingListInput = {
  isEnabled: Scalars["Boolean"];
  maxPersons: Scalars["Float"];
  minPersons: Scalars["Float"];
  totalPersonsAllowed: Scalars["Float"];
};
