import { Dimensions } from "react-native";

import type { PALETTE_COLORS } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

const MIN_WIDTH = 100;
const MIN_HEIGHT = 45;

interface FilterTab {
  label?: string;
  icon?: JSX.Element;
  iconColor?: PALETTE_COLORS;
  value: number | string;
  key: string;
}

interface ListingStatusFilterProps {
  tabs: FilterTab[];
  onSelected: (tabKey: string) => void;
  selectedStatus: string;
  containerWidth?: number;
}

const ListingStatusFilter = ({
  tabs,
  onSelected,
  selectedStatus,
  containerWidth,
}: ListingStatusFilterProps) => {
  const screenWidth = containerWidth || Dimensions.get("window").width;
  const TAB_WIDTH = screenWidth / tabs.length;

  const BASE_BOX_STYLES: React.ComponentProps<typeof Box> = {
    minHeight: MIN_HEIGHT,
    minWidth: "25%",
    alignItems: "center",
    justifyContent: "center",
    width: TAB_WIDTH,
    borderBottomColor: "disabled",
    borderBottomWidth: 1,
  };

  const SELECTED_BOX_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_BOX_STYLES,
    backgroundColor: "listItemActiveBackground",
    borderRadius: "none",
    borderWidth: 0,
  };
  const UNSELECTED_BOX_STYLES: React.ComponentProps<typeof Box> = {
    ...BASE_BOX_STYLES,
    backgroundColor: "white",
    borderTopWidth: 1,
    borderTopColor: "disabled",
    borderLeftWidth: 1,
    borderLeftColor: "disabled",
  };

  return (
    <Box flexDirection="row" alignItems="center" width="100%">
      {tabs.map(({ key, value, label, icon }) => {
        return (
          <CustomButton
            buttonVariant="primaryButton"
            onPress={() => onSelected(key)}
            key={key}
            overrideStyles={{
              borderRadius: 0,
            }}
            styles={{
              ...(selectedStatus === key ? SELECTED_BOX_STYLES : UNSELECTED_BOX_STYLES),
            }}
          >
            <Box flexDirection="row" alignItems="center" justifyContent="center">
              <>
                {!icon ? (
                  <CustomText
                    variant="paragraph"
                    color={selectedStatus === key ? "white" : "primaryTextColor"}
                  >
                    {label}
                  </CustomText>
                ) : (
                  <Box>{icon}</Box>
                )}

                <CustomText
                  variant="paragraph"
                  color={selectedStatus === key ? "white" : "primaryTextColor"}
                  ml="s"
                >
                  ({value})
                </CustomText>
              </>
            </Box>
          </CustomButton>
        );
      })}
    </Box>
  );
};

export default ListingStatusFilter;
