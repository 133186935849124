import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import Box from "../../../../../components/Base/Box";
import BookingFloorListCard from "../../../../../components/BookingFloor/BookingFloorListCard";
import BottomButton from "../../../../../components/BottomButton";
import ScreenHeader from "../../../../../components/ScreenHeader";
import type { BookingFloorFragment } from "../../../../../graphql/generated/schema";
import { useGetBookingFloorsLazyQuery } from "../../../../../graphql/generated/schema";

const BookingServiceFloorSelect = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { selectedFloors = [], onSubmit, onCompleteFlooInfoSubmit } = params;
  const [floors, setFloors] = useState<BookingFloorFragment[]>([]);
  const [finalFloors, setFinalFloors] = useState(selectedFloors);

  const [getFloors] = useGetBookingFloorsLazyQuery();

  const handleGetFloors = async () => {
    try {
      const { data } = await getFloors({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data) {
        setFloors(data.getBookingFloors);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetFloors();
  }, []);

  const handleSelectFloor = (floor: BookingFloorFragment) => {
    setFinalFloors(prev => {
      if (prev.find(item => item._id === floor._id)) {
        return prev.filter(item => item._id !== floor._id);
      } else {
        return [...prev, floor];
      }
    });
  };

  const handleSubmit = () => {
    if (onCompleteFlooInfoSubmit) {
      const selectedConvertedFloors = floors
        .filter(f => finalFloors.find(floor => floor._id === f._id))
        .map(floor => {
          const totalBookingCapacity = floor.tables.reduce((acc, table) => {
            const tableCapacity = table?.isEligibleForBooking ? table?.maxCapacity : 0;
            return acc + tableCapacity;
          }, 0);

          return {
            _id: floor._id,
            name: floor.name,
            totalBookingCapacity,
          };
        });

      onCompleteFlooInfoSubmit(selectedConvertedFloors);
    } else if (onSubmit) {
      const convertedFloors = finalFloors.map(floor => ({
        _id: floor._id,
        name: floor.name,
      }));
      onSubmit(convertedFloors);
    }
    navigation.goBack();
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <FlatList
        ListHeaderComponent={
          <>
            <ScreenHeader
              title="Salles disponibles"
              hasBackButton
              onBackPress={navigation.goBack}
            />

            {/* <Box marginVertical="s">
              <CustomTextInput
                {...{
                  placeHolder: "Rechercher",
                  // leftIcon: (
                  //   <SEARCH
                  //     height={ICON_SIZE}
                  //     width={ICON_SIZE}
                  //     fill={PALETTE.darkBlue}
                  //   />
                  // ),
                  onChangeText: (text: string) => {},
                  variant: "defaultTextInput",
                  boxProps: {
                    backgroundColor: "disabled",
                    padding: "s",
                    borderRadius: "button",
                  },
                }}
              />
            </Box> */}
          </>
        }
        showsVerticalScrollIndicator={false}
        data={floors}
        keyExtractor={item => item._id}
        renderItem={({ item }) => {
          const IS_SELECTED = finalFloors.find(floor => floor._id === item._id);
          return (
            <TouchableOpacity onPress={() => handleSelectFloor(item)}>
              <Box marginVertical="s">
                <BookingFloorListCard
                  isDraggable={false}
                  bookingFloor={item}
                  isSelected={IS_SELECTED}
                />
              </Box>
            </TouchableOpacity>
          );
        }}
      />
      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default BookingServiceFloorSelect;
