export const PALETTE = {
  white: "#FFFFFF",
  offWhite: "#E5E5E5",
  black: "#000000",
  grey: "#F2F2F2",
  darkGrey: "#72777B",
  green: "#30D587",
  greenBackground: "rgba(73,203,129,0.19)",
  blue: "#00045C",
  blueBackground: "rgba(33, 157, 227, 0.20)",

  red: "#FC5276",
  redBackground: "rgba(224, 33, 100, 0.25)",

  orange: "#FF6D4D",
  brightOrange: "#ff8000",
  orangeBackground: "rgba(255,171,99,0.20)",

  purple: "#8D7EFF",

  yellow: "#F7C604",
  yellowBackground: "#FDEEB4",

  blueSecondary: "#5FBAC2",

  blueTertiary: "#219DE3",

  lightGrey: "#A4A4A4",

  darkBlue: "#072644",
  floorBackground: "#F2F2F2F2",

  // COLOR LIST SELECTION
  gray: "#D9D9D9",
  lightGreen: "rgba(73,203,129,0.19)",
  lightPurple: "#CCCDDE",
  lightOrange: "rgba(255,109,76,0.15)",

  redStatus: "#FC5276",
  orangeStatus: "#FFAB63",
  purpleStatus: "#B67EFF",
  yellowStatus: "#F7C604",
  blueStatus: "#1A24FF",
  lightGreyStatus: "#BCBCBC",
  greenStatus: "#30C56F",
  blueSecondaryStatus: "#5FBAC2",
  saumonStatus: "#F4948B",
  brightBlue: "#0E66FF",

  // LISTING TABS
  secondaryTab: "#C3C7CB",
  tertiaryTab: "#F2F2F2",

  // BOOKING STATUS
  BOOKING_NEW: "#FF6D4C",
  BOOKING_NEW_BACKGROUND: "rgba(255, 109, 76, 0.20)",

  BOOKING_WAITING_LIST: "#AE5E00",
  BOOKING_WAITING_LIST_BACKGROUND: "rgba(174, 94, 0, 0.2)",

  BOOKING_CONFIRMED: "#30D587",
  BOOKING_CONFIRMED_BACKGROUND: "rgba(48, 213, 135, 0.20)",

  BOOKING_RECONFIRMED: "#1C8E4C",
  BOOKING_RECONFIRMED_BACKGROUND: "rgba(28, 142, 76, 0.20)",

  BOOKING_CANCELLED: "#BCBCBC",
  BOOKING_CANCELLED_BACKGROUND: "rgba(188, 188, 188, 0.20)",

  BOOKING_MODIFIED: "#9CEF7E",
  BOOKING_MODIFIED_BACKGROUND: "rgba(156, 239, 126, 0.20)",

  BOOKING_ANOTHER_TIME_SLOT: "#F7C604",

  BOOKING_ARRIVED: "#0983F3",
  BOOKING_ARRIVED_BACKGROUND: "rgba(9, 131, 243, 0.20)",

  BOOKING_ARRIVED_WAITING_LIST: "#5FBAC2",
  BOOKING_ARRIVED_WAITING_LIST_BACKGROUND: "rgba(95, 186, 194, 0.20)",

  BOOKING_ENDED: "#00478C",
  BOOKING_ENDED_BACKGROUND: "rgba(0, 71, 140, 0.20)",

  BOOKING_SEATED: "#0ED2E3",
  BOOKING_SEATED_BACKGROUND: "rgba(14, 210, 227, 0.20)",

  BOOKING_NO_SHOW: "#FC5276",
  BOOKING_NO_SHOW_BACKGROUND: "rgba(252, 82, 118, 0.20)",

  BOOKING_REFUNDED: "#E02164",
  BOOKING_REFUNDED_BACKGROUND: "rgba(224, 33, 100, 0.20)",

  // TICKET STATUS
  TICKET_OPEN: "#FF6D4C",
  TICKET_ORDERING: "#30D587",
  TICKET_SENT: "#0983F3",
  TICKET_PAID: "#1C8E4C",
  TICKET_CLOSED: "#00478C",
  TICKET_CANCELLED: "#BCBCBC",
  TICKET_REFUNDED: "#FC5276",

  // ORDER ONLINE STATUS
  ORDERONLINE_NEW: "#FF6D4C",
  ORDERONLINE_NEW_BACKGROUND: "rgba(255, 109, 76, 0.20)",

  ORDERONLINE_CONFIRMED: "#30D587",
  ORDERONLINE_CONFIRMED_BACKGROUND: "rgba(48, 213, 135, 0.20)",

  ORDERONLINE_PREPARING: "#1C8E4C",
  ORDERONLINE_PREPARING_BACKGROUND: "rgba(28, 142, 76, 0.20)",

  ORDERONLINE_PREPARED: "#5FBAC2",
  ORDERONLINE_PREPARED_BACKGROUND: "rgba(95, 186, 194, 0.20)",

  ORDERONLINE_ENDED: "#00478C",
  ORDERONLINE_ENDED_BACKGROUND: "rgba(0, 71, 140, 0.20)",

  ORDERONLINE_CANCELLED: "#BCBCBC",
  ORDERONLINE_CANCELLED_BACKGROUND: "rgba(188, 188, 188, 0.20)",

  ORDERONLINE_PAID: "#464255",
  ORDERONLINE_PAID_BACKGROUND: "rgba(70, 66, 85, 0.20)",

  ORDERONLINE_NO_SHOW: "#FC5276",
  ORDERONLINE_NO_SHOW_BACKGROUND: "rgba(252, 82, 118, 0.20)",

  ORDERONLINE_REFUNDED: "#E02164",
  ORDERONLINE_REFUNDED_BACKGROUND: "rgba(224, 33, 100, 0.20)",

  ORDERONLINE_MODIFIED: "#9CEF7E",
  ORDERONLINE_MODIFIED_BACKGROUND: "rgba(156, 239, 126, 0.20)",

  ORDERONLINE_ANOTHER_TIME_SLOT: "#F7C604",

  ORDERONLINE_ANOTHER_PAID: "#FC5276",

  ORDERONLINE_DELIVERING: "#0983F3",

  ORDERONLINE_DELIVERED: "#00478C",

  // QUOTE STATUS
  QUOTE_NEW: "#FF6D4C",
  QUOTE_ACCEPTED: "#30D587",
  QUOTE_SENT: "#0983F3",
  QUOTE_MODIFIED: "#9CEF7E",
  QUOTE_CANCELLED: "#BCBCBC",
  QUOTE_REJECTED: "#FC5276",

  // INVOICE STATUS
  INVOICE_NEW: "#FF6D4C",
  INVOICE_CONFIRMED: "#30D587",
  INVOICE_SENT: "#0983F3",
  INVOICE_PAID: "#464255",
  INVOICE_MODIFIED: "#9CEF7E",
  INVOICE_CANCELLED: "#BCBCBC",
  INVOICE_REJECTED: "#FC5276",
};

export type PALETTE_COLORS = keyof typeof PALETTE;
