import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, RefreshControl, TouchableOpacity } from "react-native";

import NEW from "../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import { CustomButton } from "../../../../components/Button";
import ErrorBoundary from "../../../../components/ErrorBoundary/index";
import ScreenHeader from "../../../../components/ScreenHeader";
import MerchantDeviceCard from "../../../../components/Settings/MerchantDeviceCard";
import type { Pagination, PrinterFragment } from "../../../../graphql/generated/schema";
import {
  useDeletePrinterMutation,
  useGetPrintersLazyQuery
} from "../../../../graphql/generated/schema";
import type { SettingsStackParamList } from "../../../../navigation/AppStack/SettingsStack/ParamList";
import { ICON_SIZE } from "../../../../theme";
import { PALETTE } from "../../../../theme/Palette";
import AddPrinter from "../AddPrinter";

interface PrinterListProps {
  goBack?: () => void;
}

const PrinterList = ({ goBack }: PrinterListProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const [selectedTab, setSelectedTab] = useState<
    keyof SettingsStackParamList | undefined
  >(undefined);
  const [isNew, setIsNew] = useState(false);
  const [printerId, setPrinterId] = useState("");

  const [getPrinters] = useGetPrintersLazyQuery();
  const [deletePrinter] = useDeletePrinterMutation();

  const handleGetPrinters = async () => {
    setLoading(true);
    try {
      const { data } = await getPrinters({
        variables: { pagination },
        fetchPolicy: "cache-and-network"
      });

      if (data) {
        setPrinters(data.getPrinters);
      }
    } catch (err) {
      console.log("err get printers", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPrinters();
  }, []);

  const handlePrinterDelete = async (id: string) => {
    try {
      await deletePrinter({
        variables: {
          id,
        },
      });
    } catch (err) {
      console.log("err delete printer", err);
    } finally {
      handleGetPrinters();
    }
  };

  const goToAddPrinter = () => {
    if (goBack) {
      setSelectedTab("ADD_PRINTER");
      setIsNew(true);
      setPrinterId("");
    } else {
      navigation.navigate("ADD_PRINTER", { isNew: true });
    }
  };

  const goToEditPrinter = (id: string) => {


    if (goBack) {
      setSelectedTab("ADD_PRINTER");
      setIsNew(false);
      setPrinterId(id);
    } else {
      navigation.navigate("ADD_PRINTER", { isNew: false, id });
    }
  };

  const goToPrinterErrorReport = () => {
    navigation.navigate("PRINTER_ERROR_REPORTS");
  };

  const displaySettingsList = () => {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          rightButtons={
            <TouchableOpacity onPress={goToAddPrinter}>
              <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            </TouchableOpacity>
          }
          title="Imprimantes"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <Box marginVertical="m">
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="disabled"
            onPress={goToPrinterErrorReport}
          >
            <CustomText variant="primaryButtonText" color="orange">
              Rapports erreurs/incidents
            </CustomText>
          </CustomButton>
        </Box>

        <Box mt="m">
          <CustomText mb="s" textTransform="uppercase" variant="content" color="success">
            Imprimantes existantes
          </CustomText>
        </Box>

        <FlatList
          data={printers}
          showsVerticalScrollIndicator={false}
          keyExtractor={(_, index) => index.toString()}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={handleGetPrinters} />
          }
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => goToEditPrinter(item._id)}>
              <Box marginVertical="s">
                <MerchantDeviceCard
                  printer={item}
                  onDelete={() => handlePrinterDelete(item._id)}
                />
              </Box>
            </TouchableOpacity>
          )}
        />
      </Box>
    );
  };

  const handleClose = () => setSelectedTab(undefined);

  const displaySelectedTab = () => {
    switch (selectedTab) {
      case "ADD_PRINTER":
        return <AddPrinter newValue={isNew} printerId={printerId} goBack={handleClose} />;

      default:
        break;
    }
    return <></>
  };

  const displayContent = () => {
    if (!selectedTab) return displaySettingsList();

    return displaySelectedTab();
  };

  return <ErrorBoundary fallback={
    <Box>
      <CustomText variant="content" color="primaryTextColor">An error occured</CustomText>

    </Box>
  }>{displayContent()}</ErrorBoundary>;
};

export default PrinterList;
