import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import ToggleInputLine from "../../../../../components/ToggleInputLine";

interface DeliveryListSettingsProps {
  goBack?: () => void;
}

const NAME_FORMATS = [
  {
    label: "JOHN DOE",
    key: "20",
  },
  {
    label: "JOHN",
    key: "20",
  },
];

const DeliveryListSettings = ({ goBack }: DeliveryListSettingsProps) => {
  const navigation = useNavigation();
  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Affichager des commandes"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="s">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Format d'affichage du nom des clients
          </CustomText>
          <SelectButton
            isMultiSelect={false}
            options={NAME_FORMATS}
            selectedOptions={[]}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onPress={() => {}}
            placeHolder="Format"
          />
        </Box>

        <Box mt="s">
          <ToggleInputLine
            text="Afficher les commandes au statut
'Annulé' dans le filtre 'TOUS'"
          />
        </Box>
        <Box mt="s">
          <ToggleInputLine
            text="Afficher et grouper par créneaux
dans la liste des commandes"
          />
        </Box>
        <Box mt="s">
          <InputSectionTitle text="Aperçu des commandes" />
        </Box>
        <Box mt="s">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            INTERACTIVE VIEW!!!!!!
          </CustomText>
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPress={() => {}}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DeliveryListSettings;
