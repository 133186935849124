import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../components/Base/Box";
import ScreenHeader from "../../../components/ScreenHeader";
import BookingAverageFrequency from "../../../components/Stats/Booking/BookingAverageFrequency/index";
import BookingOrigins from "../../../components/Stats/Booking/BookingOrigins/index";
import BookingStatTotalRecap from "../../../components/Stats/Booking/BookingStatTotalRecap/index";
import BookingsByGoals from "../../../components/Stats/Booking/BookingsByGoals";
import BookingsPerPerson from "../../../components/Stats/Booking/BookingsPerPerson/index";
import QuickLookBookingStats from "../../../components/Stats/Booking/QuickLookBookingStats/index";
import TopClients from "../../../components/Stats/Booking/TopClients/index";
import DateSelector from "../../../components/Stats/DateSelector/index";

const BookingStats = () => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  // const displayRightButton = () => {
  //   return (
  //     <TouchableOpacity>
  //       <Box>
  //         <PDF width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
  //       </Box>
  //     </TouchableOpacity>
  //   );
  // };

  return (
    <Box paddingHorizontal="s" flex={1} pt="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Réservation"
          // rightButtons={displayRightButton()}
        />
      </Box>

      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt={{
            phone: "m",
            tablet: "m",
          }}
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems={{ tablet: "flex-start" }}
          justifyContent={{ phone: "flex-start", tablet: "space-between" }}
          marginHorizontal={{
            tablet: "m",
          }}
        >
          <BookingStatTotalRecap />

          <Box
            flex={1}
            mt={{
              phone: "s",
              tablet: "none",
            }}
            borderTopColor="lightGrey"
            borderTopWidth={{ phone: 1, tablet: 0 }}
            pt={{ phone: "m", tablet: "none" }}
            borderLeftColor="lightGrey"
            borderLeftWidth={{ tablet: 1 }}
            pl={{ tablet: "m" }}
          >
            <BookingAverageFrequency />
          </Box>
        </Box>

        <Box borderTopColor="lightGrey" borderTopWidth={1} mt="s" paddingVertical="m">
          <DateSelector
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>

        <Box
          mt="m"
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems={{ tablet: "center" }}
          justifyContent={{ phone: "flex-start", tablet: "space-between" }}
        >
          <Box
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
          >
            <BookingsByGoals startDate={startDate} endDate={endDate} />
          </Box>

          <Box
            height="100%"
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
            mt={{
              phone: "m",
              tablet: "none",
            }}
          >
            <QuickLookBookingStats startDate={startDate} endDate={endDate} />
          </Box>
        </Box>

        <Box
          mt="m"
          marginHorizontal={{
            tablet: "m",
          }}
        >
          <BookingOrigins startDate={startDate} endDate={endDate} />
        </Box>

        <Box
          mt="m"
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems={{ tablet: "center" }}
          justifyContent={{ phone: "flex-start", tablet: "space-between" }}
        >
          <Box
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
          >
            <BookingsPerPerson startDate={startDate} endDate={endDate} />
          </Box>

          <Box
            height="100%"
            flex={1}
            marginHorizontal={{
              tablet: "m",
            }}
            mt={{
              phone: "m",
              tablet: "none",
            }}
          >
            <TopClients startDate={startDate} endDate={endDate} />
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default BookingStats;
