import type { BookingFragment } from "../../../../../graphql/generated/schema";
import Box from "../../../../Base/Box";

import BookingListDetailsEdit from "./Edit";
import BookingListDetailsView from "./View";

interface BookingListDetailsViewBookingTabProps {
  isEditable: boolean;
  data: BookingFragment;
  updateData: (data: BookingFragment) => void;
}

const BookingListDetailsViewBookingTab = ({
  isEditable,
  data,
  updateData,
}: BookingListDetailsViewBookingTabProps) => {
  return (
    <Box flex={1} paddingHorizontal="s">
      {isEditable ? (
        <BookingListDetailsEdit updateData={updateData} data={data} />
      ) : (
        <BookingListDetailsView data={data} />
      )}
    </Box>
  );
};

export default BookingListDetailsViewBookingTab;
