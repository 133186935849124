import type { THEME_COLORS } from "../../../theme";
import type { SERVICE_VISIBILITY } from "../../ServiceButton";

export enum MODULE_TYPE {
  BOOKING = "BOOKING",
  ACCOUNTING = "ACCOUNTING",
  CLIENTS = "CLIENTS",
  ONLINE_SALES = "ONLINE_SALES",
  DELIVERY = "DELIVERY",
  CASH_REGISTER = "CASH_REGISTER",
}

export interface ModuleListService {
  _id: string;
  title: string;
  value: string;
  serviceVisibility: SERVICE_VISIBILITY;
}

export interface ModuleListStatus {
  title: string;
  color: THEME_COLORS;
  icon: JSX.Element;
  value: number;
}

export interface ModuleListData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickets?: any[];
  servicId: string;
  daysAvailable: number[];
  serviceName: string;
  totalInService: number;
  accumulatedTotalInService: number;
  slots: {
    slotId: string;
    slotTime: string;
    totalInSlot: number;
    accumulatedTotalInSlot: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
  }[];
}
