import type { IPrinter } from "react-native-esc-pos-printer";
import type Printing from "react-native-esc-pos-printer/lib/typescript/printing";

import type {
  PrinterFragment,
  TicketFragment,
  TicketProductFragment,
} from "../../graphql/generated/schema";

import type DefaultIos from "./ThermalPrinter";

type Printer = typeof DefaultIos;

class ThermalPrinter implements Printer {
  public discoverPrinters(): Promise<IPrinter[]> {
    return [];
  }
  addCharLine(char: "*" | "-", printing: Printing): Printing {
    return;
  }
  formatProductItems(
    ticketProducts: TicketProductFragment[],
    printerRef: Printing,
  ): Printing {
    return;
  }
  formatTicketItems(
    items: {
      products: {
        ticketStepId: string;
        productId: string;
        name: string;
        uid: string;
        categoryId: string;
        comment: string;
        accumulatedTotalAmount: number;
        quantity: number;
        price: { amount: number; taxRate: number };
        discount?: { amount: number; isPercentage: boolean } | null | undefined;
        groupOptions: {
          groupOptionId: string;
          name: string;
          price: { amount: number; taxRate: number };
          options: {
            optionId: string;
            name: string;
            price: { amount: number; taxRate: number };
          }[];
        }[];
      }[];
      menus: {
        ticketStepId: string;
        menuId: string;
        name: string;
        uid: string;
        comment: string;
        quantity: number;
        accumulatedTotalAmount: number;
        price: { amount: number; taxRate: number };
        discount?: { amount: number; isPercentage: boolean } | null | undefined;
        menuSteps: {
          menuStepId: string;
          menuStepName: string;
          products: {
            ticketStepId: string;
            productId: string;
            name: string;
            uid: string;
            categoryId: string;
            comment: string;
            accumulatedTotalAmount: number;
            quantity: number;
            price: { amount: number; taxRate: number };
            discount?: { amount: number; isPercentage: boolean } | null | undefined;
            groupOptions: {
              groupOptionId: string;
              name: string;
              price: { amount: number; taxRate: number };
              options: {
                optionId: string;
                name: string;
                price: { amount: number; taxRate: number };
              }[];
            }[];
          }[];
        }[];
      }[];
    },
    stepId: string,
    printing: Printing,
  ): Printing {
    return;
  }
  printSendStepTicketItems(
    sendItems: { printer: PrinterFragment; items: TicketProductFragment[] }[],
    stepId: string,
    ticket: TicketFragment,
  ): Promise<void> {
    return;
  }
  printSendStepTicketItem(
    ticket: TicketFragment,
    stepId: string,
    items: TicketProductFragment[],
    printerRef: Printing,
    printerTarget: string,
    printerLocation: string,
  ): Promise<void> {
    return;
  }
  formatTicketItemsForTicket(
    items: {
      products: {
        ticketStepId: string;
        productId: string;
        name: string;
        uid: string;
        categoryId: string;
        comment: string;
        accumulatedTotalAmount: number;
        quantity: number;
        price: { amount: number; taxRate: number };
        discount?: { amount: number; isPercentage: boolean } | null | undefined;
        groupOptions: {
          groupOptionId: string;
          name: string;
          price: { amount: number; taxRate: number };
          options: {
            optionId: string;
            name: string;
            price: { amount: number; taxRate: number };
          }[];
        }[];
      }[];
      menus: {
        ticketStepId: string;
        menuId: string;
        name: string;
        uid: string;
        comment: string;
        quantity: number;
        accumulatedTotalAmount: number;
        price: { amount: number; taxRate: number };
        discount?: { amount: number; isPercentage: boolean } | null | undefined;
        menuSteps: {
          menuStepId: string;
          menuStepName: string;
          products: {
            ticketStepId: string;
            productId: string;
            name: string;
            uid: string;
            categoryId: string;
            comment: string;
            accumulatedTotalAmount: number;
            quantity: number;
            price: { amount: number; taxRate: number };
            discount?: { amount: number; isPercentage: boolean } | null | undefined;
            groupOptions: {
              groupOptionId: string;
              name: string;
              price: { amount: number; taxRate: number };
              options: {
                optionId: string;
                name: string;
                price: { amount: number; taxRate: number };
              }[];
            }[];
          }[];
        }[];
      }[];
    },
    printer: Printing,
  ): Printing {
    return;
  }
  formatTicketTotalDetails(ticket: TicketFragment, printer: Printing): Printing {
    throw new Error("Method not implemented.");
  }
  printTicket(ticket: TicketFragment): Promise<void> {
    return;
  }
  getAvailablePrinters(): Promise<IPrinter[]> {
    return;
  }
  connectToPrinter(target: string, printerModelName: string): Promise<Printing> {
    return;
  }
  printProofTicket(
    ticket: TicketFragment,
    nbCopies: number,
    title: string,
    amount: number,
  ): Promise<void> {
    return;
  }
  printClaimLastSentStep(ticket: TicketFragment): Promise<void> {
    return;
  }
}

const thermalPrinter = new ThermalPrinter();

export default thermalPrinter;
