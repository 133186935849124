import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import type {
  BookingSettingsFragment,
  CreateBookingProInput,
  MerchantClientFragment,
  MerchantClientInput,
  PopCallFragment,
} from "../../../graphql/generated/schema";
import {
  Booking_Form_Input,
  Event_Type,
  Origin,
  Sex,
} from "../../../graphql/generated/schema";
import { ICON_SIZE, LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import type { ERROR } from "../../../utils/common";
import { getLabelsForEvent, getLabelsForOrigin } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ClientEditTab from "../../BookingListDetails/BookingListDetailsView/Tabs/Client/Edit";
import { CustomButton } from "../../Button";
import CallList from "../../CallList";
import ClientCard from "../../ClientCard";
import ClientList from "../../ClientList";
import DateInput from "../../DateInput";
import KeyboardAwareScrollView from "../../KeyboardAwareScrollView";
import PhoneInput from "../../PhoneInput";
import RadioSelect from "../../RadioSelect";
import SelectModal from "../../SelectModal";
import SingleSelectButton from "../../SingleSelectButton";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";
import Touchable from "../../Touchable";

import ClientDetailsViewModal from "./ClientDetailsViewModal";

const DEFAULT_CLIENT: MerchantClientInput & { _id: string } = {
  _id: "",
  sex: Sex.NotSpecified,
  firstName: "",
  lastName: "",
  email: "",
  secondaryEmail: "",
  phone: "",
  secondaryPhone: "",
  dateOfBirth: "",
  isVIP: false,
  comment: "",
  language: "fr",
  acceptedReceiveNewsLetters: false,
  acceptedReceiveMarketingSms: false,
  isBlacklisted: false,
  picture: "",
  merchantCompanyId: "",
};

interface ClientSelectionProps {
  client: MerchantClientFragment;
  updateClient: (client: MerchantClientFragment) => void;
  isNotDeductedFromStock: boolean;
  setIsNotDeductedFromStock: (val: boolean) => void;
  errors: ERROR[];
  call?: PopCallFragment;
  updateCall: (call: PopCallFragment) => void;
  isOnMainClientPage: boolean;
  setIsOnMainClientPage: (val: boolean) => void;
  booking: CreateBookingProInput;
  setBooking: (booking: CreateBookingProInput) => void;
  bookingSettings?: BookingSettingsFragment;
}

const ClientSelection = ({
  client,
  updateClient,
  errors,
  isNotDeductedFromStock,
  setIsNotDeductedFromStock,
  call,
  updateCall,
  setIsOnMainClientPage,
  booking,
  setBooking,
  bookingSettings,
}: ClientSelectionProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const [showCallList, setShowCallList] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const [showPrescriberList, setShowPrescriberList] = useState(false);
  const [isClientDetailsModalOpen, setIsClientDetailsModalOpen] = useState(false);

  const handleClientSelected = (newClient: MerchantClientFragment) => {
    updateClient(newClient);
    setShowClientList(false);
    setIsOnMainClientPage(true);
  };

  const handleCallSelected = (newCall: PopCallFragment) => {
    updateCall(newCall);
    setShowCallList(false);
    setShowPrescriberList(false);
    setIsOnMainClientPage(true);

    const { callPhoneNumber = "", merchantClientId } = newCall;

    if (merchantClientId && merchantClientId._id) {
      updateClient({
        ...merchantClientId,
        phone: callPhoneNumber,
      });
    } else {
      updateClient({
        ...client,
        phone: callPhoneNumber,
      });
    }
  };

  const handleOpenCallList = () => {
    setShowCallList(true);
    setIsOnMainClientPage(false);
  };

  const handleOpenClientList = () => {
    setShowClientList(true);
    setIsOnMainClientPage(false);
  };

  const handleOpenPrescriberList = () => {
    setShowPrescriberList(true);
    setIsOnMainClientPage(false);
  };

  const handleGoBack = () => {
    setShowCallList(false);
    setShowClientList(false);
    setShowPrescriberList(false);
    setIsOnMainClientPage(true);
  };

  const handleSearchEmptyClientNewClient = (query: string) => {
    setShowCallList(false);
    setShowClientList(false);
    setShowPrescriberList(false);
    setIsOnMainClientPage(true);

    const isNumber = /^\d+$/.test(query);

    const newClient = {
      ...DEFAULT_CLIENT,
      phone: isNumber ? query : "",
      lastName: isNumber ? "" : query,
    };

    updateClient(newClient);
  };

  const handleResetClient = () => {
    updateClient(DEFAULT_CLIENT);
  };

  const updateBooking = (key: keyof CreateBookingProInput, value: string | boolean) => {
    setBooking({
      ...booking,
      [key]: value,
    });
  };

  const handleSelectEventType = () => {
    const events = Object.keys(Event_Type).map(key => ({
      _id: key,
      name: getLabelsForEvent(key),
    }));
    navigation.navigate("LIST_SELECT", {
      listItems: events,
      selectedItems: [
        {
          _id: booking.eventType,
          name: getLabelsForEvent(booking.eventType || "", booking.eventType || ""),
        },
      ],
      multiSelect: false,
      simple: true,
      onSubmit: (selectedItems: string[]) => {
        const [type] = selectedItems;

        const formattedEventType = Event_Type[type._id];

        updateBooking("eventType", formattedEventType);
        navigation.goBack();
      },
    });
  };

  const handleSelectSource = () => {
    const sources = Object.keys(Origin)
      .filter(key => {
        if (key === "Google") return false;
        return true;
      })
      .map(key => ({
        _id: key,
        name: getLabelsForOrigin(key),
      }));

    navigation.navigate("LIST_SELECT", {
      listItems: sources,
      selectedItems: [
        {
          _id: booking.source,
          name: getLabelsForOrigin(booking.source, booking.source),
        },
      ],
      simple: true,
      multiSelect: false,
      onSubmit: (selectedItems: string[]) => {
        const [type] = selectedItems;

        const formattedSource = Origin[type._id];

        updateBooking("source", formattedSource);

        navigation.goBack();
      },
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors?.some(err => err.path === key);
  };

  const isInputEnabled = (type: Booking_Form_Input) => {
    if (!bookingSettings) return true;

    const { bookingForm } = bookingSettings;

    return bookingForm.inputs.some(
      input => input.inputName === type && (input.isEnabled || input.isRequired),
    );
  };

  const isInputRequired = (type: Booking_Form_Input) => {
    if (!bookingSettings) return false;

    const { bookingForm } = bookingSettings;

    return bookingForm.inputs.some(input => input.inputName === type && input.isRequired);
  };

  const handleUpdateClient = (
    key: keyof MerchantClientFragment,
    value: MerchantClientFragment[keyof MerchantClientFragment],
  ) => {
    updateClient({ ...client, [key]: value });
  };

  const handleUpdateCivility = (sex: Sex) => {
    const newSex = client.sex === sex ? Sex.NotSpecified : sex;
    updateClient({
      ...client,
      sex: newSex,
    });
  };

  const handleOpenAddressEdit = () => {
    navigation.navigate("ADDRESS_DETAILS", {
      address: client?.address,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSubmit: (address: any) => {
        updateClient({
          ...client,
          address,
        });
        navigation.goBack();
      },
    });
  };

  const displayBookingInfo = () => {
    return (
      <Box>
        <Box mt="m">
          <CustomText textTransform="uppercase" variant="content" color="success">
            Réservation
          </CustomText>

          {isInputEnabled(Booking_Form_Input.CommentOwner) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Note sur la réservation"
                multiLine
                hasErrors={doesInputHaveError("commentOwner")}
                isRequired={isInputRequired(Booking_Form_Input.CommentOwner)}
                initialValue={booking.commentOwner || ""}
                onChangeText={t => updateBooking("commentOwner", t)}
              />
            </Box>
          )}

          <Box mt="m">
            {isInputEnabled(Booking_Form_Input.EventType) && (
              <TouchableOpacity onPress={handleSelectEventType}>
                <SelectModal
                  title="Évènement"
                  hasErrors={doesInputHaveError("eventType")}
                  isRequired={isInputRequired(Booking_Form_Input.EventType)}
                  value={
                    booking.eventType
                      ? getLabelsForEvent(booking.eventType, booking.eventType)
                      : ""
                  }
                />
              </TouchableOpacity>
            )}

            <TouchableOpacity onPress={handleSelectSource}>
              <SelectModal
                hasTopBorder={false}
                title="Source"
                hasErrors={doesInputHaveError("origin")}
                isRequired={isInputRequired(Booking_Form_Input.Origin)}
                value={getLabelsForOrigin(booking.source, booking.source) || ""}
              />
            </TouchableOpacity>
          </Box>
        </Box>

        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Ne pas déduire cette réservation du stock disponible"
            onValueChange={setIsNotDeductedFromStock}
            value={isNotDeductedFromStock}
          />
        </Box>
      </Box>
    );
  };

  const displayContent = () => {
    if (showCallList) {
      return (
        <CallList call={call} onCallSelected={handleCallSelected} goBack={handleGoBack} />
      );
    }

    if (showClientList) {
      return (
        <ClientList
          client={client}
          onClientSelected={handleClientSelected}
          goBack={handleGoBack}
          onSearchEmptyClient={handleSearchEmptyClientNewClient}
        />
      );
    }

    if (client._id) {
      return (
        <ScrollView
          scrollEnabled
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
        >
          <Box flexDirection="row" alignItems="center" mt="m">
            <Box flex={1}>
              <ClientCard client={client} isSelected={false} />
            </Box>
            <TouchableOpacity onPress={() => setIsClientDetailsModalOpen(true)}>
              <Box>
                <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </Box>
            </TouchableOpacity>
          </Box>
          <Box>
            <TouchableOpacity onPress={handleResetClient}>
              <Box marginVertical="s" alignItems="center" justifyContent="center">
                <CustomText variant="label" fontSize={14} color="danger">
                  Changer le client
                </CustomText>
              </Box>
            </TouchableOpacity>
          </Box>

          {isInputEnabled(Booking_Form_Input.Civility) && (
            <Box
              mt="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box flex={0.4}>
                <Box flexDirection="row" alignItems="center">
                  <CustomText
                    variant="label"
                    color={
                      doesInputHaveError("client.sex") ? "danger" : "primaryTextColor"
                    }
                  >
                    Civilité
                  </CustomText>
                  {isInputRequired(Booking_Form_Input.Civility) && (
                    <CustomText
                      // pl="xs"
                      variant="defaultTextInput"
                      color={doesInputHaveError("client.sex") ? "danger" : "success"}
                    >
                      *
                    </CustomText>
                  )}
                </Box>
              </Box>
              <Box flex={1} flexDirection="row" alignItems="center">
                <Box flex={0.5} marginHorizontal="s">
                  <SingleSelectButton
                    onPress={() => handleUpdateCivility(Sex.Female)}
                    isSelected={client.sex === Sex.Female}
                    title="Madame"
                  />
                </Box>
                <Box flex={0.5}>
                  <SingleSelectButton
                    onPress={() => handleUpdateCivility(Sex.Male)}
                    isSelected={client.sex === Sex.Male}
                    title="Monsieur"
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Note  permanente sur le client"
                multiLine
                isRequired={isInputRequired(Booking_Form_Input.MerchantClientNote)}
                hasErrors={doesInputHaveError("client.comment")}
                initialValue={client.comment}
                onChangeText={t => updateClient({ ...client, comment: t })}
              />
            </Box>
          </Box>

          {isInputEnabled(Booking_Form_Input.Email) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email"
                initialValue={client.email}
                isRequired={isInputRequired(Booking_Form_Input.Email)}
                keyboardType="email-address"
                hasErrors={doesInputHaveError("client.email")}
                onChangeText={value => handleUpdateClient("email", value)}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.Address) && (
            <Touchable onPress={handleOpenAddressEdit}>
              <Box mt="s">
                <CustomTextInput
                  disabled
                  boxProps={{
                    backgroundColor: "white",
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChangeText={() => {}}
                  hasErrors={doesInputHaveError("client.address")}
                  isRequired={isInputRequired(Booking_Form_Input.Address)}
                  placeHolder="Addresse"
                  // initialValue={client?.address?.formattedAddress || ""}
                  value={client?.address?.formattedAddress}
                />
              </Box>
            </Touchable>
          )}

          {isInputEnabled(Booking_Form_Input.Birthday) && (
            <Box mt="s">
              <DateInput
                placeHolder="Date d'anniversaire"
                date={new Date(client.dateOfBirth)}
                onChange={date => handleUpdateClient("dateOfBirth", date)}
                isRequired={isInputRequired(Booking_Form_Input.Birthday)}
                hasErrors={doesInputHaveError("client.dateOfBirth")}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.SecondaryPhone) && (
            <Box mt="s">
              <PhoneInput
                hasErrors={doesInputHaveError("client.secondaryPhone")}
                onChangeFormattedText={value => {
                  handleUpdateClient("secondaryPhone", value);
                }}
                value={client.secondaryPhone}
                isRequired={isInputRequired(Booking_Form_Input.SecondaryPhone)}
                placeHolder="Téléphone secondaire"
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.SecondaryEmail) && (
            <Box mt="s">
              <CustomTextInput
                placeHolder="Email secondaire"
                keyboardType="email-address"
                initialValue={client.secondaryEmail}
                isRequired={isInputRequired(Booking_Form_Input.SecondaryEmail)}
                hasErrors={doesInputHaveError("client.secondaryEmail")}
                onChangeText={value => handleUpdateClient("secondaryEmail", value)}
              />
            </Box>
          )}

          {isInputEnabled(Booking_Form_Input.Vip) && (
            <Box mt="s" borderBottomColor="lightGrey" borderBottomWidth={LINE_THICKNESS}>
              <RadioSelect
                iconOnRight
                isSelected={client.isVIP}
                label="Client VIP"
                isRequired={isInputRequired(Booking_Form_Input.Vip)}
                hasErrors={doesInputHaveError("client.isVIP")}
                onPress={() => handleUpdateClient("isVIP", !client.isVIP)}
              />
            </Box>
          )}

          {displayBookingInfo()}
        </ScrollView>
      );
    }

    return (
      <>
        <KeyboardAwareScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 100 }}
          scrollEnabled={!showClientList && !showCallList}
        >
          <Box>
            <Box paddingHorizontal="s" mb="s">
              <CustomText
                textTransform="uppercase"
                variant="content"
                color="success"
                marginVertical="m"
              >
                Appels
              </CustomText>

              <CustomButton
                onPress={handleOpenCallList}
                styles={{
                  p: "s",
                  height: 45,
                  borderRadius: "button",
                  borderColor: "lightGrey",
                  borderWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText variant="label" color="primaryTextColor">
                  Liste des appels reçus
                </CustomText>
                <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </CustomButton>
            </Box>
            {/* <Box paddingHorizontal="s" mb="s">
              <CustomText
                textTransform="uppercase"
                variant="content"
                color="success"
                marginVertical="m"
              >
                Prescripteurs
              </CustomText>

              <CustomButton
                onPress={handleOpenPrescriberList}
                styles={{
                  p: "s",
                  height: 45,
                  borderRadius: "button",
                  borderColor: "lightGrey",
                  borderWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText variant="label" color="primaryTextColor">
                  Liste des prescripteurs
                </CustomText>
                <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </CustomButton>
            </Box> */}

            <Box paddingHorizontal="s" mb="m">
              <CustomText
                textTransform="uppercase"
                variant="content"
                color="success"
                mb="m"
              >
                base de données
              </CustomText>

              <CustomButton
                onPress={handleOpenClientList}
                styles={{
                  p: "s",
                  height: 45,
                  borderRadius: "button",
                  borderColor: "lightGrey",
                  borderWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomText variant="label" color="primaryTextColor">
                  Entrez un nom, numéro etc...
                </CustomText>
              </CustomButton>
            </Box>

            <Box paddingHorizontal="s">
              <CustomText
                textTransform="uppercase"
                variant="content"
                color="success"
                mb="m"
              >
                Saisie directe
              </CustomText>
            </Box>

            <ClientEditTab
              errors={errors}
              data={client}
              updateData={updateClient}
              bookingSettings={bookingSettings}
            />

            {displayBookingInfo()}
          </Box>
        </KeyboardAwareScrollView>
      </>
    );
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
      keyboardVerticalOffset={Platform.OS === "ios" ? 0 : 20}
    >
      {displayContent()}

      <ClientDetailsViewModal
        isOpen={isClientDetailsModalOpen}
        setIsOpen={setIsClientDetailsModalOpen}
        clientId={client._id}
      />
    </KeyboardAvoidingView>
  );
};

export default ClientSelection;
