import { useState } from "react";
import { StyleSheet } from "react-native";

import INFO from "../../../../../assets/icons/BASE/INFO.svg";
import type { ProductFragment } from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import { formatCurrencyPrice } from "../../../../utils/common";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import Incrementer from "../../../Incrementer";

interface QuoteProductCardProps {
  product: ProductFragment;
  addProduct: (product: {
    _id: string;
    price: {
      amount: number;
      taxRate: number;
    };
    name: string;
    quantity: number;
  }) => void;
}

const styles = StyleSheet.create({});

const QuoteProductCard = ({ product, addProduct }: QuoteProductCardProps) => {
  const [qty, setQty] = useState(product.quantity || 0);
  const { name, basePrice } = product;

  const handleProductQtyChange = (productQty: number) => {
    addProduct({
      _id: product._id,
      price: {
        amount: basePrice.amount,
        taxRate: basePrice.tax?.taxRate || 0,
      },
      name,
      quantity: productQty,
    });
    setQty(productQty);
  };

  return (
    <Box backgroundColor="disabled" borderRadius="button" p="s">
      <Box>
        <CustomText variant="label" color="primaryTextColor">
          {name}
        </CustomText>
      </Box>
      {/* <Box mt="s" flexDirection="row" alignItems="center">
        <INFO height={20} width={20} fill={PALETTE.green} />
        <CustomText variant="content" color="primaryTextColor">
          Entrée | Salades
        </CustomText>
      </Box> */}

      <Box
        marginVertical="s"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          p="s"
          flexDirection="row"
          alignItems="center"
          backgroundColor="white"
          borderRadius="button"
        >
          <INFO height={20} width={20} fill={PALETTE.green} />
          <CustomText ml="s" variant="label" color="primaryTextColor">
            {formatCurrencyPrice(basePrice.amount, "fr", "eur")}
          </CustomText>
        </Box>
        <Incrementer
          min={0}
          max={20}
          value={qty}
          onValueChange={handleProductQtyChange}
        />
      </Box>
    </Box>
  );
};

export default QuoteProductCard;
