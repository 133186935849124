import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import InvoicesStack from "../../navigation/AppStack/InvoicesStack";
import QuotesStack from "../../navigation/AppStack/QuotesStack";
import { TAB_CONFIG } from "../TabsConfig";

import type { AccountingTabsParamList } from "./ParamList";

const Tab = createMaterialTopTabNavigator<AccountingTabsParamList>();

function MyTabs() {
  return (
    <Tab.Navigator
      sceneContainerStyle={{ overflow: "visible" }}
      screenOptions={TAB_CONFIG}
    >
      <Tab.Screen
        options={{ title: "Devis" }}
        name="QUOTES_TAB"
        component={QuotesStack}
      />
      <Tab.Screen
        options={{ title: "Factures" }}
        name="INVOICES_TAB"
        component={InvoicesStack}
      />
    </Tab.Navigator>
  );
}

const Accounting = () => {
  return <MyTabs />;
};

export default Accounting;
