import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import type { DateObject } from "react-native-calendars";

import type { QUOTE } from "../../../../Data";
import Box from "../../../Base/Box";
import { CustomText } from "../../../Base/Text";
import CustomCalendar, { CALENDAR_VIEW } from "../../../CustomCalendar";
import SelectButton from "../../../Select/SelectButton";

interface NewQuoteDateProps {
  onDateSelected: (date: Date) => void;
  selectedDate: Date | null;
  markedDates?: { [date: string]: DateObject };
  quote: QUOTE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateQuote: (key: keyof QUOTE, value: any) => void;
}

const SELECTION_OPTIONS = [
  { label: "10 jours", key: "10" },
  { label: "30 jours", key: "30" },
  { label: "60 jours", key: "60" },
];

const styles = StyleSheet.create({});

const NewQuoteDate = ({
  onDateSelected,
  selectedDate = new Date(),
  markedDates,
  quote,
  updateQuote,
}: NewQuoteDateProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const handleUpdateValidity = (value: string[]) => {
    const [validity] = value;
    updateQuote("validityInDays", +validity);
  };
  useEffect(() => {
    if (quote.validityInDays) {
      setSelectedOptions([quote.validityInDays.toString()]);
    }
  }, [quote.validityInDays]);

  return (
    <Box mt="s">
      <Box marginVertical="s">
        <CustomText variant="label" color="primaryTextColor">
          Validité
        </CustomText>

        <Box mt="s">
          <SelectButton
            isMultiSelect={false}
            options={SELECTION_OPTIONS}
            placeHolder="Validité"
            selectedOptions={selectedOptions}
            onPress={handleUpdateValidity}
            styles={{
              minWidth: 128,
            }}
          />
        </Box>
      </Box>
      <CustomCalendar
        markingType="simple"
        calendarView={CALENDAR_VIEW.MONTH}
        onDateChange={onDateSelected}
        selectedDate={new Date(selectedDate)}
        markedDates={markedDates}
      />
    </Box>
  );
};

export default NewQuoteDate;
