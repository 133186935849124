import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import NEW from "../../../../../../assets/icons/BASE/NEW.svg";
import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import Loader from "../../../../../components/Loader";
import MerchantDiscountCard from "../../../../../components/MerchantDiscountCard";
import ScreenHeader from "../../../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MerchantDiscountFragment } from "../../../../../graphql/generated/schema";
import {
  useDeleteMerchantDiscountMutation,
  useGetMerchantDiscountsLazyQuery,
} from "../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../theme";
import { PALETTE } from "../../../../../theme/Palette";
import MerchantDiscountListDetails from "../MerchantDiscountListDetails";

interface MerchantDiscountListProps {
  goBack?: () => void;
}

const MerchantDiscountList = ({ goBack }: MerchantDiscountListProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [merchantDiscounts, setMerchantDiscounts] = useState<MerchantDiscountFragment[]>(
    [],
  );
  const [selectedDiscountId, setSelectedDiscountId] = useState("");
  const [showDiscountInline, setShowDiscountInline] = useState(false);

  const [getMerchantDiscounts, { fetchMore }] = useGetMerchantDiscountsLazyQuery();
  const [deleteMerchantDiscount] = useDeleteMerchantDiscountMutation();

  const handleFetchMore = async () => {
    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            offset: merchantDiscounts.length,
          },
        },
      });

      if (data?.getMerchantDiscounts) {
        setMerchantDiscounts([...merchantDiscounts, ...data.getMerchantDiscounts]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetMerchantDiscounts = async () => {
    try {
      const { data } = await getMerchantDiscounts({
        variables: {
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      });

      if (data?.getMerchantDiscounts) {
        setMerchantDiscounts(data.getMerchantDiscounts);
      }
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_GET_DISCOUNTS",
            path: "ERROR_GET_DISCOUNTS",
            message: "Une erreur est survenue lors de la récupération des remises.",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchantDiscounts();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await deleteMerchantDiscount({
        variables: { id },
      });

      await handleGetMerchantDiscounts();
    } catch (err) {
      console.log("err", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETE_DISCOUNT",
            path: "ERROR_DELETE_DISCOUNT",
            message: "Une erreur est survenue lors de la suppression de la remise.",
          },
        ],
        "error",
      );
    }
  };

  const handleAddNew = () => {
    if (goBack) {
      setSelectedDiscountId("");
      setShowDiscountInline(true);
    } else {
      navigation.navigate("DISCOUNTS_LIST_DETAILS", { isNew: true });
    }
  };

  const handleEdit = (id: string) => {
    if (goBack) {
      setSelectedDiscountId(id);
      setShowDiscountInline(true);
    } else {
      navigation.navigate("DISCOUNTS_LIST_DETAILS", { isNew: false, id });
    }
  };

  const handleCloseSelectedTab = () => setShowDiscountInline(false);

  const displaySelectedTab = () => {
    return (
      <MerchantDiscountListDetails
        id={selectedDiscountId}
        goBack={handleCloseSelectedTab}
        newValue={!selectedDiscountId}
      />
    );
  };

  const displaySettingsList = () => {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box>
          <ScreenHeader
            title="Remises"
            rightButtons={
              <TouchableOpacity onPress={handleAddNew}>
                <NEW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              </TouchableOpacity>
            }
            hasBackButton
            onBackPress={goBack || navigation.goBack}
          />
        </Box>
        <Box marginVertical="s">
          <CustomText variant="content" color="success" textTransform="uppercase">
            Remises actives
          </CustomText>
        </Box>

        <FlatList
          data={merchantDiscounts}
          keyExtractor={item => item._id}
          onEndReachedThreshold={0.5}
          onEndReached={handleFetchMore}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <MerchantDiscountCard
                name={item.name}
                discountValue={item.value}
                isPercentage={item.isPercentage}
                onPress={() => handleEdit(item._id)}
                onDelete={() => handleDelete(item._id)}
              />
            </Box>
          )}
        />
      </Box>
    );
  };

  const displayContent = () => {
    if (showDiscountInline) {
      return displaySelectedTab();
    }

    return displaySettingsList();
  };

  if (loading) {
    return <Loader />;
  }

  return <>{displayContent()}</>;
};

export default MerchantDiscountList;
