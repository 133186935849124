import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  subDays,
} from "date-fns";
import { useState } from "react";

import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import type { Tab } from "../../OutlineTabs";
import OutlineTabs from "../../OutlineTabs";

import PersonnaliseCalendarModal from "./PersonnaliseCalendarModal/index";

interface DateSelectorProps {
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
}

const TABS: Tab[] = [
  {
    title: "Aujourd'hui",
    key: "TODAY",
  },
  {
    title: "Hier",
    key: "YESTERDAY",
  },
  {
    title: "Ce mois",
    key: "MONTH",
  },
  {
    title: "Personnaliser",
    key: "PERSONALIZED",
  },
];

const DateSelector = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DateSelectorProps) => {
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectTab = (key: string) => {
    setSelectedTab(key);
    if (key === "PERSONALIZED") {
      setIsModalOpen(true);
    } else if (key === "TODAY") {
      setStartDate(startOfDay(new Date()));
      setEndDate(endOfDay(new Date()));
    } else if (key === "YESTERDAY") {
      const yesterday = subDays(new Date(), 1);
      setStartDate(startOfDay(new Date(yesterday)));
      setEndDate(endOfDay(new Date(yesterday)));
    } else if (key === "MONTH") {
      setStartDate(startOfMonth(new Date()));
      setEndDate(endOfMonth(new Date()));
    }
  };

  const handleSelectDate = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Box>
        <OutlineTabs tabs={TABS} onTabPress={handleSelectTab} selectedTab={selectedTab} />
      </Box>

      <Box
        mt="s"
        backgroundColor="disabled"
        borderRadius="button"
        p="s"
        alignItems="flex-start"
      >
        <CustomText variant="content" color="lightGrey">
          {format(new Date(startDate), "dd MMM yyyy")} Au{" "}
          {format(new Date(endDate), "dd MMM yyyy")}
        </CustomText>
      </Box>

      <PersonnaliseCalendarModal
        isOpen={isModalOpen}
        startDate={startDate}
        endDate={endDate}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSelectDate}
      />
    </Box>
  );
};

export default DateSelector;
