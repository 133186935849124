import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useContext, useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import { CustomText } from "../../../../../components/Base/Text";
import { CustomButton } from "../../../../../components/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../../components/InputSectionTitle";
import Loader from "../../../../../components/Loader";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SelectButton from "../../../../../components/Select/SelectButton";
import ToggleInputLine from "../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  BookingListingSettings,
  OnlineSalesSettingsFragment,
} from "../../../../../graphql/generated/schema";
import {
  Module_Listing_Client_Format,
  useGetOnlineSalesSettingsLazyQuery,
  useUpdateOnlineSalesSettingsMutation,
} from "../../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../../utils/common";

import TakeAwayOrderListingSettingsPreview from "./TakeAwayOrderListingSettingsPreview/index";

interface TakeAwayListSettingsProps {
  goBack?: () => void;
}

const NAME_FORMATS = [
  {
    label: "DOE John",
    key: Module_Listing_Client_Format.LastFirstName,
  },
  {
    label: "DOE",
    key: Module_Listing_Client_Format.LastName,
  },
  {
    label: "John",
    key: Module_Listing_Client_Format.FirstName,
  },
  {
    label: "John DOE",
    key: Module_Listing_Client_Format.FirstLastName,
  },
];

const TakeAwayListSettings = ({ goBack }: TakeAwayListSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const [settings, setSettings] = useState<OnlineSalesSettingsFragment | null>(null);

  const [getSettings] = useGetOnlineSalesSettingsLazyQuery();
  const [updateSettings] = useUpdateOnlineSalesSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getSettings({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setSettings(data.getOnlineSalesSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetSettings();
    }, []),
  );

  const handleUpdateSettings = async () => {
    try {
      const updates = removeTypeNames(settings?.orderListSettings);

      await updateSettings({
        variables: {
          updates: {
            orderListSettings: updates,
          },
        },
      });

      handleGoBack();
    } catch (error) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_TAKE_AWAY_UPDATE_SETTINGS",
            message:
              "Une erreur est survenue lors de la mise à jour des paramètres de click & collect.",
          },
        ],
        "error",
      );
      console.log(error);
    }
  };

  const updateBookingSettingsData = (
    key: keyof BookingListingSettings,
    value: BookingListingSettings[keyof BookingListingSettings],
  ) => {
    setSettings(prev => {
      if (prev) {
        return {
          ...prev,
          orderListSettings: {
            ...prev.orderListSettings,
            [key]: value,
          },
        };
      }
      return null;
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!settings) {
    return (
      <Box>
        <ScreenHeader
          title="Affichage des commandes"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />
        <ErrorMessage message="Une erreur est survenue lors du chargement des paramètres de click & collect." />
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Affichage des commandes"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box mt="s">
          <CustomText mb="s" variant="label" color="primaryTextColor">
            Format d'affichage du nom des clients
          </CustomText>
          <SelectButton
            isMultiSelect={false}
            options={NAME_FORMATS}
            selectedOptions={[settings.orderListSettings.clientNameFormat]}
            onPress={([format]) => updateBookingSettingsData("clientNameFormat", format)}
            placeHolder="Format"
          />
        </Box>

        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher les commandes au statut
'Annulé' dans le filtre 'TOUS'"
            value={settings.orderListSettings.includeCancelledInAllFilter}
            onValueChange={value =>
              updateBookingSettingsData("includeCancelledInAllFilter", value)
            }
          />
        </Box>
        <Box mt="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher et grouper par créneaux
dans la liste des commandes"
            value={settings.orderListSettings.groupBySlotInterval}
            onValueChange={value =>
              updateBookingSettingsData("groupBySlotInterval", value)
            }
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher la date de création de la réservation"
            value={!!settings.orderListSettings.showCreationDate}
            onValueChange={value => updateBookingSettingsData("showCreationDate", value)}
          />
        </Box>
        <Box marginVertical="s">
          <ToggleInputLine
            textVariant="content"
            text="Afficher le commentaire du client directement dans la liste des réservations"
            value={!!settings.orderListSettings.showClientComment}
            onValueChange={value => updateBookingSettingsData("showClientComment", value)}
          />
        </Box>
        <Box mt="s">
          <InputSectionTitle text="Aperçu des commandes" />
        </Box>
        <Box mt="s">
          <TakeAwayOrderListingSettingsPreview settings={settings.orderListSettings} />
        </Box>
      </ScrollView>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        justifyContent="center"
        p="m"
        pb="l"
        backgroundColor="white"
      >
        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleUpdateSettings}
          buttonColor="success"
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default TakeAwayListSettings;
