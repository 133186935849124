import { StyleSheet } from "react-native";

import { LINE_THICKNESS } from "../../theme";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";

interface BookingFloorCardProps {
  name: string;
  isSelected: boolean;
}

const styles = StyleSheet.create({});

const BookingFloorCard = ({ name, isSelected }: BookingFloorCardProps) => {
  return (
    <Box
      borderColor="success"
      borderWidth={isSelected ? LINE_THICKNESS : 0}
      borderRadius="button"
      backgroundColor={isSelected ? "white" : "disabled"}
      alignItems="center"
      justifyContent="center"
      p="m"
      minHeight={45}
      minWidth={100}
    >
      <CustomText variant="content" color="primaryTextColor">
        {name}
      </CustomText>
    </Box>
  );
};

export default BookingFloorCard;
