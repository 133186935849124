import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import ClientCard from "../../../../components/ClientCard";
import ScreenHeader from "../../../../components/ScreenHeader";
import type { MerchantClientFragment } from "../../../../graphql/generated/schema";
import { useGetMerchantClientsLazyQuery } from "../../../../graphql/generated/schema";

const PrescriberListDetailsAddPrincipalContact = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { principalContact = [], onSubmit } = params;
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, offset: 0 });
  const [clients, setClients] = useState<MerchantClientFragment[]>([]);
  const [selectedClient, setSelectedClient] =
    useState<MerchantClientFragment>(principalContact);

  const [getClients] = useGetMerchantClientsLazyQuery();

  const handlGetClients = async () => {
    try {
      const { data } = await getClients({
        variables: {
          pagination,
        },
      });

      if (data?.getMerchantClients) {
        setClients(data.getMerchantClients);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlGetClients();
  }, []);

  const handleAddClient = (client: MerchantClientFragment) => {
    setSelectedClient(prev => {
      if (prev?._id === client._id) {
        return null;
      }

      return client;
    });
  };

  const handleSubmit = () => {
    onSubmit(selectedClient);
    navigation.goBack();
  };

  if (loading) {
    return (
      <Box>
        <CustomText variant="primaryButtonText" color="success">
          Loading...
        </CustomText>
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box>
        <ScreenHeader
          title="Séléctionner le client"
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>
      <Box mt="m">
        <FlatList
          data={clients}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <Box marginVertical="s">
              <TouchableOpacity onPress={() => handleAddClient(item)}>
                <ClientCard client={item} isSelected={selectedClient?._id === item._id} />
              </TouchableOpacity>
            </Box>
          )}
        />
      </Box>
      <BottomButton onPress={handleSubmit} title="Valider" />
    </Box>
  );
};

export default PrescriberListDetailsAddPrincipalContact;
