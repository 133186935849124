import * as WebBrowser from "expo-web-browser";
import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";

import type { BookingPdfPrintTemplateFragment } from "../../../graphql/generated/schema";
import {
  useGetBookingPdfPrintTemplatesLazyQuery,
  usePrintBookingsToPdfMutation,
} from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button/index";
import ContentModal from "../../ContentModal/index";
import SelectButton from "../../Select/SelectButton/index";

interface PrintPdfModalProps {
  isOpen: boolean;
  selectedDate: Date;
  selectedServiceId: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
}

const PrintPdfModal = ({
  isOpen,
  selectedDate,
  selectedServiceId,
  setIsOpen,
}: PrintPdfModalProps) => {
  const [templateId, setTemplateId] = useState("");
  const [isInProgress, setIsInProgress] = useState(false);
  const [templates, setTemplates] = useState<BookingPdfPrintTemplateFragment[]>([]);

  const [getBookingPdfPrintTemplates] = useGetBookingPdfPrintTemplatesLazyQuery();
  const [printBookingsToPdf] = usePrintBookingsToPdfMutation();

  const handleGetBookingPdfPrintTemplates = async () => {
    try {
      const response = await getBookingPdfPrintTemplates({
        fetchPolicy: "cache-and-network",
        variables: {
          pagination: {
            limit: 40,
            offset: 0,
          },
        },
      });

      const pdfTemplates = response.data?.getBookingPdfPrintTemplates || [];
      if (pdfTemplates.length > 0) {
        setTemplates(pdfTemplates?.filter(t => t.isEnabled));
      }
    } catch (err) {
      console.log("ERROR_GET_BOOKING_PDF_PRINT_TEMPLATES", err);
    }
  };

  const handlePrintModalConfirm = async () => {
    try {
      setIsInProgress(true);

      const response = await printBookingsToPdf({
        variables: {
          templateId,
          selectedDate,
          serviceId: selectedServiceId === "ALL" ? null : selectedServiceId,
        },
      });

      const pdfUrl = response.data?.printBookingsToPdf;

      if (pdfUrl) {
        await WebBrowser.openBrowserAsync(pdfUrl, {
          windowFeatures: {
            target: "_blank",
          },
        });
      }
    } catch (err) {
      console.log("ERROR_PRINT_BOOKINGS_TO_PDF", err);
    } finally {
      setIsInProgress(false);
    }
  };

  useEffect(() => {
    handleGetBookingPdfPrintTemplates();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTemplateId("");
  };

  const handleSelectTemplate = (templateIds: string[]) => {
    const [selectedTemplateId] = templateIds;

    if (!selectedTemplateId) return;

    setTemplateId(selectedTemplateId);
  };

  const convertTemplateToOptions = () => {
    return templates.map(t => ({
      label: t.name,
      key: t._id,
    }));
  };

  const displayContent = () => {
    if (templates.length === 0) {
      return (
        <Box height="auto">
          <CustomText variant="content" color="primaryTextColor">
            Aucun modèle de PDF n'est disponible.
          </CustomText>
          <CustomText mt="s" variant="content" color="primaryTextColor">
            Veuillez en créer un dans les paramètres de la réservation.
          </CustomText>
        </Box>
      );
    }

    const isDisabled = !templateId || isInProgress;

    return (
      <>
        <Box height="auto">
          <SelectButton
            placeHolder="Choisir un modèle"
            selectedOptions={templateId ? [templateId] : []}
            isMultiSelect={false}
            onPress={handleSelectTemplate}
            options={convertTemplateToOptions()}
          />
        </Box>

        <Box mt="m" backgroundColor="white" minHeight={100}>
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor={templateId && !isInProgress ? "success" : "disabled"}
            onPress={handlePrintModalConfirm}
            disabled={isDisabled}
          >
            {isInProgress ? (
              <ActivityIndicator color={PALETTE.white} />
            ) : (
              <CustomText
                variant="primaryButtonText"
                color={isDisabled ? "lightGrey" : "white"}
              >
                Valider
              </CustomText>
            )}
          </CustomButton>
        </Box>
      </>
    );
  };

  return (
    <ContentModal
      title="Imprimer un PDF"
      isVisible={isOpen}
      onClose={handleClose}
      styles={{
        maxWidth: 450,
        height: "auto",
      }}
    >
      <Box height="auto">{displayContent()}</Box>
    </ContentModal>
  );
};

export default PrintPdfModal;
