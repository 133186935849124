import { useNavigation, useRoute } from "@react-navigation/native";
import { omitDeep } from "deepdash-es/standalone";
import { useContext, useEffect, useState } from "react";
import type { LayoutRectangle } from "react-native";
import { Dimensions, TouchableOpacity, View } from "react-native";

import CUTLERY from "../../../../../assets/icons/BASE/CUTLERY.svg";
import EDIT from "../../../../../assets/icons/BASE/EDIT.svg";
import EIWIE from "../../../../../assets/icons/BASE/EIWIE.svg";
import INFO from "../../../../../assets/icons/BASE/INFO.svg";
import TABLE from "../../../../../assets/icons/BASE/TABLE.svg";
import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import AddDecorationModal from "../../../../components/BookingFloor/AddDecorationModal";
import AddTableModal from "../../../../components/BookingFloor/AddTableModal";
import AnimatedFloor from "../../../../components/BookingFloor/AnimatedFloor";
import BookingFloorDetailsModal from "../../../../components/BookingFloor/BookingFloorDetailsModal";
import EditDecorationModal from "../../../../components/BookingFloor/EditDecorationModal/index";
import EditTableModal from "../../../../components/BookingFloor/EditTableModal";
import type { BUTTON_ACTION } from "../../../../components/BottomButtomWithActions";
import BottomButtomWithActions from "../../../../components/BottomButtomWithActions";
import BottomButton from "../../../../components/BottomButton";
import { HEADER_HEIGHT } from "../../../../components/Header";
import LabelIncrementer from "../../../../components/LabelIncrementer";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import {
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
  BOOKING_FLOOR_HEADER_HEIGHT,
  BOOKING_FLOOR_HEIGHT_MAX,
  BOOKING_FLOOR_HEIGHT_MIN,
  BOOKING_FLOOR_HEIGHT_WIDTH_INCREMENT_AMOUNT,
  BOOKING_FLOOR_WIDTH_MAX,
  BOOKING_FLOOR_WIDTH_MIN,
} from "../../../../constants/BookingFloor";
import { AppContext } from "../../../../contexts/AppContext";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext";
import type {
  BookingFloorFragment,
  CreateBookingFloorInput,
  Decoration,
  DecorationFragment,
  Decoration_Type,
  TableFragment,
} from "../../../../graphql/generated/schema";
import {
  useCreateBookingFloorMutation,
  useDeleteBookingFloorMutation,
  useGetBookingFloorLazyQuery,
  useUpdateBookingFloorMutation,
} from "../../../../graphql/generated/schema";
import { PALETTE } from "../../../../theme/Palette";
import { getUID, removeTypeNames } from "../../../../utils/common";

interface BookingFloorProps {
  goBack?: () => void;
  newValue?: boolean;
  selectedId?: string;
  floor?: BookingFloorFragment;
}

const { height, width } = Dimensions.get("window");

const SCREEN_HEIGHT = height;
const SCREEN_WIDTH = width;

const FLOOR_AVAILABLE_HEIGHT =
  SCREEN_HEIGHT -
  HEADER_HEIGHT -
  BOOKING_FLOOR_HEADER_HEIGHT -
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT -
  20;

const ICON_SIZE = 20;

const BookingFloor = ({
  goBack,
  newValue,
  selectedId,
  floor: newFloorDetails,
}: BookingFloorProps) => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const appContext = useContext(AppContext);
  const [floorLayoutContent, setFloorLayoutContent] = useState<
    LayoutRectangle | undefined
  >(undefined);

  const DEFAULT_BOOKING_FLOOR: CreateBookingFloorInput = {
    decorations: [],
    height: 0,
    name: "",
    description: "",
    position: 0,
    prefix: "",
    tables: [],
    width: appContext.isSplittable ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH,
  };

  const isNew = newValue || params?.isNew;
  const id = selectedId || params?.id;
  const newFloor = newFloorDetails || params?.floor;

  const [loading, setLoading] = useState(true);
  const [isBookingFloorEditOpen, setIsBookingFloorEditOpen] = useState(false);
  const [isAddTableOpen, setIsAddTableOpen] = useState(false);
  const [isAddDecorationOpen, setIsAddDecorationOpen] = useState(false);
  const [isEditTableOpen, setIsEditTableOpen] = useState(false);
  const [isEditDecorationOpen, setIsEditDecorationOpen] = useState(false);
  const [floor, setFloor] = useState(DEFAULT_BOOKING_FLOOR);
  const [selectedTable, setSelectedTable] = useState<TableFragment | null>(null);
  const [selectedDecoration, setSelectedDecoration] = useState<DecorationFragment | null>(
    null,
  );

  const [getBookingFloor] = useGetBookingFloorLazyQuery();
  const [createBookingFloor] = useCreateBookingFloorMutation();
  const [updateBookingFloor] = useUpdateBookingFloorMutation();
  const [deleteBookingFloor] = useDeleteBookingFloorMutation();

  // const FLOOR_HEIGHT =
  //   floor.height > SCREEN_HEIGHT - BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT
  //     ? SCREEN_HEIGHT - BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT
  //     : floor.height;

  // const FLOOR_WIDTH = floor.width > SCREEN_WIDTH ? SCREEN_WIDTH : floor.width;

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const BUTTON_ACTIONS: BUTTON_ACTION[] = [
    {
      title: "Supprimer",
      key: "DELETE",
      variant: "outline",
      color: "danger",
    },
    {
      title: "Annuler",
      key: "CANCEL",
      variant: "primary",
      color: "danger",
    },
  ];

  const handleGetBookingFloor = async () => {
    try {
      if (isNew) {
        setFloor({
          ...DEFAULT_BOOKING_FLOOR,
          ...newFloor,
          width: appContext.isSplittable ? SCREEN_WIDTH * 0.7 : SCREEN_WIDTH,
        });
      } else {
        const { data } = await getBookingFloor({ variables: { id } });
        if (data?.getBookingFloor) {
          setFloor(data?.getBookingFloor);
        }
      }
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingFloor();
  }, []);

  const handleSubmit = async () => {
    try {
      if (isNew) {
        await createBookingFloor({
          variables: {
            floor,
          },
        });
      } else {
        let updates = removeTypeNames(floor);
        delete updates._id;
        updates = omitDeep(updates, /bookings/);
        updates = omitDeep(updates, /cashRegisterTickets/);

        await updateBookingFloor({
          variables: {
            floor: updates,
            id,
          },
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err", JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_BOOKING_FLOOR",
            message:
              "Une erreur est survenue lors de la mise à jour de la salle de réservation",
          },
        ],
        "error",
      );
    }
  };
  const handleDelete = async () => {
    try {
      await deleteBookingFloor({
        variables: {
          id,
        },
        update: cache => {
          const normalizedId = cache.identify({
            _id: id,
            __typename: "BookingFloor",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });

      handleGoBack();
    } catch (err) {
      console.log(err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_DELETE_BOOKING_FLOOR",
            message:
              "Une erreur est survenue lors de la suppression de la salle de réservation",
          },
        ],
        "error",
      );
    }
  };

  const updateFloorWidth = (w: number) => {
    const adjustedWidth = w > SCREEN_WIDTH ? SCREEN_WIDTH : w;

    setFloor(prev => ({ ...prev, width: adjustedWidth }));
  };

  const updateFloorHeight = (h: number) => {
    const adjustedHeight = h > FLOOR_AVAILABLE_HEIGHT ? FLOOR_AVAILABLE_HEIGHT : h;

    setFloor(prev => ({ ...prev, height: adjustedHeight }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFloorData = (key: keyof CreateBookingFloorInput, value: any) => {
    setFloor(prev => ({ ...prev, [key]: value }));
  };

  const displayTableCapacity = () => {
    const { tables } = floor;
    return tables.reduce((acc, table) => acc + table.minCapacity, 0);
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        await handleDelete();
      } else if (key === "MODIFY") {
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleMainButtonSubmit = async () => {
    try {
      await handleSubmit();
    } catch (error) {}
  };

  const getNewTableNumber = () => {
    const { tables } = floor;
    const tableNumbers = tables.map(table => +table.tableNumber || 0);
    const maxNumber = tableNumbers.length > 0 ? Math.max(...tableNumbers) : 0;

    return maxNumber + 1;
  };

  const handleAddTable = (table: TABLE) => {
    const newTable: TableFragment = {
      _id: getUID(),
      averageDurationInMinutes: 0,
      minCapacity: 1,
      maxCapacity: table.capacity,
      isEligibleForBooking: true,
      positionX: 0,
      positionY: 0,
      rotationDeg: 0,
      shape: table.form,
      isOrderAtTableEnabled: true,
      isPaymentAtTableEnabled: true,
      tableNumber: getNewTableNumber().toString(),
    };

    setFloor(prev => ({
      ...prev,
      tables: [...prev.tables, newTable],
    }));
  };

  const handleAddDecoration = (decoType: Decoration_Type) => {
    const decoration: Decoration = {
      _id: getUID(),
      type: decoType,
      positionX: 0,
      positionY: 0,
      rotationDeg: 0,
    };

    setFloor(prev => ({
      ...prev,
      decorations: [...prev.decorations, decoration],
    }));
  };

  const handleSelectTable = (tableId: string) => {
    const { tables } = floor;
    const table = tables.find(t => t._id === tableId);
    setSelectedTable(table || null);
    setIsEditTableOpen(true);
  };

  const deleteTable = (tableId: string) => {
    const { tables } = floor;
    const newTables = tables.filter(t => t._id !== tableId);
    setFloor(prev => ({ ...prev, tables: newTables }));
  };

  const deleteDecoration = (decorationId: string) => {
    const { decorations } = floor;
    const newDecorations = decorations.filter(d => d._id !== decorationId);
    setFloor(prev => ({ ...prev, decorations: newDecorations }));
  };

  const updateTable = (tableId: string, table: TableFragment) => {
    const { tables } = floor;
    const newTables = tables.map(t => (t._id === tableId ? table : t));
    updateFloorData("tables", newTables);
    setSelectedTable(null);
  };

  const updateDecoration = (decorationId: string, decoration: Decoration) => {
    const { decorations } = floor;
    const newDecorations = decorations.map(d =>
      d._id === decorationId ? decoration : d,
    );
    updateFloorData("decorations", newDecorations);
    setSelectedDecoration(null);
  };

  const handleSelectDecoration = (decorationId: string) => {
    const { decorations } = floor;
    const decoration = decorations.find(d => d._id === decorationId);
    setSelectedDecoration(decoration || null);
    setIsEditDecorationOpen(true);
  };

  const handleCloseTableEdit = (value: boolean) => {
    setIsEditTableOpen(value);
    setSelectedTable(null);
  };

  const handleCloseDecorationEdit = (value: boolean) => {
    setIsEditDecorationOpen(value);
    setSelectedDecoration(null);
  };

  const handleDuplicateTable = (tableId: string) => {
    const { tables } = floor;
    const table = tables.find(t => t._id === tableId);

    const newTable: TableFragment = {
      ...table,
      positionY: (table?.positionY || 0) + 20,
      _id: getUID(),
      tableNumber: getNewTableNumber().toString(),
    };

    setSelectedTable(newTable);
    setFloor(prev => ({
      ...prev,
      tables: [...prev.tables, newTable],
    }));
  };

  const handleDuplicateDecoration = (decorationId: string) => {
    const { decorations } = floor;
    const decoration = decorations.find(d => d._id === decorationId);

    const newDecoration: DecorationFragment = {
      ...decoration,
      positionY: (decoration?.positionY || 0) + 20,
      _id: getUID(),
    };
    setFloor(prev => ({
      ...prev,
      decorations: [...prev.decorations, newDecoration],
    }));
  };

  const handleCreateMultipleTables = (table: TABLE, number: number) => {
    const newTables: TableFragment[] = [];
    const latestTable = getNewTableNumber();
    for (let i = 0; i < number; i++) {
      const newTableNumber = latestTable + i;
      const newTable: TableFragment = {
        _id: getUID(),
        averageDurationInMinutes: 0,
        minCapacity: 1,
        maxCapacity: table.capacity,
        isEligibleForBooking: true,
        positionX: 10 * i,
        positionY: 10,
        rotationDeg: 0,
        shape: table.form,
        isOrderAtTableEnabled: true,
        isPaymentAtTableEnabled: true,
        tableNumber: newTableNumber.toString(),
      };
      newTables.push(newTable);
    }
    setFloor(prev => ({
      ...prev,
      tables: [...prev.tables, ...newTables],
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box pt="m" flex={1} backgroundColor="white">
      <Box height={BOOKING_FLOOR_HEADER_HEIGHT}>
        <Box paddingHorizontal="s">
          <ScreenHeader
            title={floor.name}
            hasBackButton
            onBackPress={handleGoBack}
            rightButtons={
              <Box flexDirection="row" alignItems="center">
                <TouchableOpacity
                  onPress={() => setIsBookingFloorEditOpen(true)}
                  style={{ marginRight: 8 }}
                >
                  <EDIT width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setIsAddTableOpen(true)}
                  style={{ marginRight: 8 }}
                >
                  <TABLE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setIsAddDecorationOpen(true)}
                  style={{ marginRight: 8 }}
                >
                  <EIWIE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
                </TouchableOpacity>
              </Box>
            }
          />
        </Box>

        <Box paddingHorizontal="m" mt="s" flexDirection="row" alignItems="center">
          <Box mr="m" flexDirection="row" alignItems="center">
            <TABLE width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {floor.tables.length}
            </CustomText>
          </Box>
          <Box mr="m" flexDirection="row" alignItems="center">
            <CUTLERY width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
            <CustomText ml="s" variant="content" color="lightGrey">
              {displayTableCapacity()}
            </CustomText>
          </Box>
          {floor.description && (
            <Box mr="m" flexDirection="row" alignItems="center">
              <INFO width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
              <CustomText ml="s" variant="content" color="lightGrey">
                {floor.description}
              </CustomText>
            </Box>
          )}
        </Box>
        <Box
          mt="s"
          marginHorizontal="s"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LabelIncrementer
            label="Largeur"
            value={floor.width}
            min={BOOKING_FLOOR_WIDTH_MIN}
            max={BOOKING_FLOOR_WIDTH_MAX}
            incrementor={BOOKING_FLOOR_HEIGHT_WIDTH_INCREMENT_AMOUNT}
            onValueChange={w => updateFloorData("width", w)}
          />
          <LabelIncrementer
            label="Hauter"
            value={floor.height}
            min={BOOKING_FLOOR_HEIGHT_MIN}
            max={BOOKING_FLOOR_HEIGHT_MAX}
            incrementor={BOOKING_FLOOR_HEIGHT_WIDTH_INCREMENT_AMOUNT}
            onValueChange={h => updateFloorData("height", h)}
          />
        </Box>
      </Box>

      <View
        style={{
          flex: 1,
          backgroundColor: PALETTE.grey,
        }}
        onLayout={e => setFloorLayoutContent(e.nativeEvent.layout)}
      >
        <AnimatedFloor
          decorations={floor.decorations}
          tables={floor.tables}
          updateTable={updateTable}
          updateDecoration={updateDecoration}
          selectedTable={selectedTable}
          selectedTables={selectedTable ? [selectedTable] : []}
          selectTable={handleSelectTable}
          selectDecoration={handleSelectDecoration}
          floorHeight={floor.height}
          floorWidth={floor.width}
          isSplittable={appContext.isSplittable}
          layoutContent={floorLayoutContent}
        />
      </View>

      {isNew ? (
        <BottomButton
          title="Enregistrer"
          onPress={handleSubmit}
          styles={{
            height: BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
          }}
        />
      ) : (
        <BottomButtomWithActions
          actions={BUTTON_ACTIONS}
          title="Modifier"
          onSubmit={handleOnSubmit}
          onMainActionPress={handleMainButtonSubmit}
          textVariant="primaryButtonText"
          styles={{
            paddingHorizontal: "m",
            height: BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
          }}
        />
      )}

      {isBookingFloorEditOpen && (
        <BookingFloorDetailsModal
          isOpen={isBookingFloorEditOpen}
          onClose={() => setIsBookingFloorEditOpen(false)}
          initialFloor={floor}
          onSubmit={v => setFloor(v)}
        />
      )}

      {isAddTableOpen && (
        <AddTableModal
          isOpen={isAddTableOpen}
          setIsOpen={setIsAddTableOpen}
          onSubmit={handleAddTable}
          handleCreateMultipleTables={handleCreateMultipleTables}
        />
      )}

      {isAddDecorationOpen && (
        <AddDecorationModal
          isOpen={isAddDecorationOpen}
          setIsOpen={setIsAddDecorationOpen}
          onSubmit={handleAddDecoration}
        />
      )}

      {selectedTable && isEditTableOpen && (
        <EditTableModal
          isOpen={isEditTableOpen}
          setIsOpen={handleCloseTableEdit}
          table={selectedTable}
          onSubmit={updateTable}
          deleteTable={deleteTable}
          duplicateTable={handleDuplicateTable}
        />
      )}
      {selectedDecoration && isEditDecorationOpen && (
        <EditDecorationModal
          isOpen={isEditDecorationOpen}
          setIsOpen={handleCloseDecorationEdit}
          decoration={selectedDecoration}
          onSubmit={updateDecoration}
          deleteDecoration={deleteDecoration}
          duplicateDecoration={handleDuplicateDecoration}
        />
      )}
    </Box>
  );
};

export default BookingFloor;
