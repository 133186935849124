import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";

import SELECTED_TABLE from "../../../../assets/icons/BASE/SELECTED_TABLE.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BottomButton from "../../../components/BottomButton";
import CashRegisterNumberKeyboard from "../../../components/CashRegister/CashRegisterNumberKeyboard";
import type { ListingTab } from "../../../components/ListingTabs";
import { ListingTabs } from "../../../components/ListingTabs";
import ScreenHeader from "../../../components/ScreenHeader";
import Touchable from "../../../components/Touchable";
import { AppContext } from "../../../contexts/AppContext/index";
import type {
  CreateBookingProInput,
  SelectedBookingTableFragment,
} from "../../../graphql/generated/schema";
import {
  Event_Type,
  Origin,
  useCreateBookingMutation,
} from "../../../graphql/generated/schema";
import { captureAndReportErrors } from "../../../sentry";
import { LINE_THICKNESS } from "../../../theme";
import { BOOKING_STATUS } from "../../../types";
import { getSafeNumberFromInput } from "../../../utils/common";

const TABS: ListingTab[] = [
  {
    title: "Pax",
    value: "",
    key: "PERSONS",
  },
];

const DEFAULT_BOOKING: CreateBookingProInput = {
  commentOwner: "",
  date: new Date(),
  nbPersons: 0,
  serviceId: "",
  slotId: "",
  source: Origin.Local,
  eventType: Event_Type.None,
  status: BOOKING_STATUS.VALIDATED,
  tables: [],
  isNotDeductedFromStock: false,
  includePrepaid: false,
  sendClientNotifications: false,
};

const NewLocalBooking = () => {
  const navigation = useNavigation();
  const { params: { tables = [] } = {} } = useRoute();
  const [booking, setBooking] = useState(DEFAULT_BOOKING);
  const appContext = useContext(AppContext);
  const [isInProgress, setIsInProgress] = useState(false);

  const [listTabs] = useState(TABS);
  const [currentTab, setCurrentTab] = useState(TABS[0].key);

  const [createBooking] = useCreateBookingMutation();

  const handleInit = () => {
    if (!tables) return;

    const nbPersons = tables.reduce((acc, table) => acc + table.maxCapacity, 0);

    setBooking({
      ...DEFAULT_BOOKING,
      tables,
      nbPersons,
    });
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleNewBooking = async () => {
    setIsInProgress(true);
    try {
      const consolidatedBooking = {
        ...booking,
        date: new Date(),
      };

      await createBooking({
        variables: {
          booking: consolidatedBooking,
        },
      });

      navigation.goBack();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      captureAndReportErrors(err);
    } finally {
      setIsInProgress(false);
    }
  };

  const isDisabled = () => {
    const persons = getSafeNumberFromInput(booking.nbPersons);

    return isInProgress || persons <= 0;
  };

  const handleRemoveTable = (table: SelectedBookingTableFragment) => {
    setBooking(prev => {
      const updatedTables = prev.tables.filter(t => t.tableId !== table.tableId);

      const persons = updatedTables.reduce((acc, table) => acc + table.maxCapacity, 0);

      return {
        ...prev,
        tables: updatedTables,
        nbPersons: persons,
      };
    });
  };

  const displaySelectedTable = (table: SelectedBookingTableFragment) => {
    const { prefix, tableNumber } = table;

    return (
      <Touchable onPress={() => handleRemoveTable(table)}>
        <Box mr="s">
          <SELECTED_TABLE />

          <Box
            position="absolute"
            top="35%"
            left="20%"
            alignItems="center"
            justifyContent="center"
          >
            <CustomText variant="content" fontSize={12} color="primaryTextColor">
              {prefix}-{tableNumber}
            </CustomText>
          </Box>
        </Box>
      </Touchable>
    );
  };

  return (
    <Box flex={1} paddingHorizontal="s" pt="m" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader
          title="Nouvelle réservation"
          hasBackButton
          onBackPress={navigation.goBack}
        />
      </Box>

      <Box flex={1} backgroundColor="white">
        <Box
          maxWidth={450}
          width="100%"
          flex={1}
          alignSelf={!appContext.isSplittable ? undefined : "center"}
          backgroundColor="white"
        >
          {booking?.tables && booking?.tables?.length > 0 && (
            <Box mt="s" paddingHorizontal="s" paddingVertical="s" justifyContent="center">
              <CustomText variant="label" color="primaryTextColor">
                Tables assignées
              </CustomText>

              <Box mt="s" justifyContent="flex-start">
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                  {tables.map(table => {
                    return (
                      <Box key={table.tableId} mr="s">
                        {displaySelectedTable(table)}
                      </Box>
                    );
                  })}
                </ScrollView>
              </Box>
            </Box>
          )}

          <Box mb="s">
            <ListingTabs
              tabs={listTabs}
              currentTabName={currentTab}
              onSelectTab={setCurrentTab}
              boxProps={{
                minHeight: 45,
              }}
            />
          </Box>

          <Box marginVertical="s">
            <Box
              marginVertical="s"
              minHeight={45}
              alignItems="center"
              justifyContent="center"
              borderRadius="button"
              borderWidth={LINE_THICKNESS}
              borderColor="primaryTextColor"
            >
              <CustomText variant="label" color="primaryTextColor">
                {booking.nbPersons}
              </CustomText>
            </Box>

            <CashRegisterNumberKeyboard
              showComma={false}
              handleAmountChange={value => {
                setBooking(prev => ({
                  ...prev,
                  nbPersons: +`${prev.nbPersons}${value}`,
                }));
              }}
              handleClear={() => {
                setBooking(prev => ({
                  ...prev,
                  nbPersons: 0,
                }));
              }}
            />
          </Box>

          <BottomButton
            isDisabled={isDisabled()}
            title="Valider"
            onPress={handleNewBooking}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewLocalBooking;
