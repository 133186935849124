import { useState } from "react";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";
import { CustomTextInput } from "../TextInput";

interface ColorPickerModalProps {
  isOpen: boolean;
  initialColor?: string|null;
  onClose: () => void;
  onSubmit: (color: string) => void;
}


const ColorPickerModal = ({
  isOpen,
  initialColor,
  onClose,
  onSubmit,
}: ColorPickerModalProps) => {

  const [color, setColor] = useState(initialColor || "#")


  const isColorSelectedValid = () => {
    const VALID_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    return VALID_COLOR_REGEX.test(color)
  }

  const handleSubmit = () => {
    if (!isColorSelectedValid()) return
      onSubmit(color)
  }

  const handleInputChange = (value: string) => {
    if (value[0] !== "#") {
      setColor(`#${value}`)
    }
    else {
      setColor(value)
    }
  }


  return (
    <ContentModal
      isVisible={isOpen}
      onClose={onClose}
      title="Sélectionner une couleur"
      hasBackButton={false}
    >
      <Box
      >

        <Box
          minHeight={100}
          justifyContent="center"
          alignItems="center"
        >

        {
          isColorSelectedValid() ? (
            <Box
              style={{
                backgroundColor: color,
                width: 200,
                height: 200,
                borderRadius: 25,
              }}
            />
            ) : (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  borderColor="danger"
                  borderWidth={1}
                  width={200}
                  height={200}
                  style={{
                    borderRadius: 25
                  }}
                >
                  <CustomText variant="content" color="danger">
                    Pas de couleur sélectionnée
                  </CustomText>
                  </Box>
              )

        }
        </Box>


        <Box
          my="m"
        >
          <CustomTextInput
            onChangeText={handleInputChange}
            value={color}
            placeHolder="Entrez une couleur"
          />
        </Box>

        <Box>
          <CustomButton
            onPress={handleSubmit}
            disabled={!isColorSelectedValid()}
            buttonVariant="primaryButton"
            buttonColor={isColorSelectedValid() ? "success" : "disabled"}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>


      </Box>

    </ContentModal>
  )
}

export default ColorPickerModal