import { useEffect, useState } from "react";

import HORIZONTAL_MORE_DOTS from "../../../assets/icons/BASE/HORIZONTAL_MORE_DOTS.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import VALIDATE from "../../../assets/icons/BASE/VALIDATE.svg";
import type { GetAccumulatedBookingAmountsByFilterQuery } from "../../graphql/generated/schema";
import {
  Booking_Status,
  useGetAccumulatedBookingAmountsByFilterLazyQuery,
} from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import ListingStatusFilter from "../ListingStatusFilter/index";

interface BookingListStatusFilterProps {
  date: Date;
  serviceId: string | null;
  handleSelectedFilter: (filter: string) => void;
  selectedFilter: string;
  selectedStatusFilters: Booking_Status[];
  refreshData?: boolean;
  containerWidth?: number;
  selectedFloorId: string | null;
}

const BookingListStatusFilter = ({
  date,
  serviceId,
  handleSelectedFilter,
  selectedFilter,
  selectedStatusFilters,
  refreshData,
  containerWidth,
  selectedFloorId,
}: BookingListStatusFilterProps) => {
  const [statusFilterData, setStatusFilterData] = useState([]);

  const [getAccumulatedBookingAmountsByFilter] =
    useGetAccumulatedBookingAmountsByFilterLazyQuery();

  const handleGetAccumulatedBookingAmountsByFilter = async () => {
    try {
      const response = await getAccumulatedBookingAmountsByFilter({
        fetchPolicy: "cache-and-network",
        variables: {
          date,
          serviceId,
          selectedStatusFilters,
          selectedFloorId,
        },
      });

      if (response.data) {
        consolidateBookingStatusFilterData(
          response.data.getAccumulatedBookingAmountsByFilter,
        );
      }
    } catch (err) {
      console.log("err getting accumulated booking amounts by filter", err);
    }
  };

  useEffect(() => {
    handleGetAccumulatedBookingAmountsByFilter();
  }, [date, serviceId, selectedStatusFilters, refreshData, selectedFloorId]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     handleGetAccumulatedBookingAmountsByFilter();
  //   }, [date, serviceId, selectedStatusFilters, refreshData]),
  // );

  const shouldShowOthers = () => {
    const othersStatusFilters = [
      Booking_Status.CancelledByOwner,
      Booking_Status.CancelledByUser,
      Booking_Status.Noshow,
      Booking_Status.Arrived,
      Booking_Status.ArrivedWaiting,
      Booking_Status.Delayed,
      Booking_Status.WaitingList,
      Booking_Status.Ended,
      Booking_Status.Seated,
      Booking_Status.Refunded,
    ];

    const hasSelectedOthersStatusFilters = othersStatusFilters.every(status =>
      selectedStatusFilters.includes(status),
    );

    const isCorrectTab = selectedFilter === "OTHERS";

    if (!isCorrectTab) return true;

    const hasSameLength = selectedStatusFilters.length === othersStatusFilters.length;

    return hasSelectedOthersStatusFilters && hasSameLength;
  };

  const consolidateBookingStatusFilterData = (
    data: GetAccumulatedBookingAmountsByFilterQuery["getAccumulatedBookingAmountsByFilter"],
  ) => {
    const SHOULD_SHOW_OTHERS = shouldShowOthers();

    const all = {
      label: "Tous",
      key: "ALL",
      value: data.allBookingsPersons,
    };

    const pending = {
      key: "PENDING",
      value: data.pendingBookingsPersons,
      icon: (
        <>
          <OCCURENCES
            height={ICON_SIZE}
            width={ICON_SIZE}
            fill={selectedFilter === "PENDING" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    const validated = {
      key: "VALIDATED",
      value: data.validatedBookingsPersons,
      icon: (
        <>
          <VALIDATE
            height={ICON_SIZE}
            width={ICON_SIZE}
            fill={selectedFilter === "VALIDATED" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    const others = {
      key: "OTHERS",
      value: SHOULD_SHOW_OTHERS
        ? data.otherBookingsPersons
        : data.selectedStatusBookingsPersons,
      icon: (
        <>
          <HORIZONTAL_MORE_DOTS
            // height={ICON_SIZE}
            // width={ICON_SIZE}
            fill={selectedFilter === "OTHERS" ? PALETTE.white : PALETTE.darkBlue}
          />
        </>
      ),
    };

    return setStatusFilterData([all, pending, validated, others]);
  };

  return (
    <ListingStatusFilter
      {...{
        onSelected: handleSelectedFilter,
        selectedStatus: selectedFilter,
        tabs: statusFilterData,
        containerWidth: containerWidth,
      }}
    />
  );
};

export default BookingListStatusFilter;
