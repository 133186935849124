import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { Image, ScrollView } from "react-native";

import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import SHOPPING_BAG from "../../../../../../../assets/icons/BASE/SHOPPING_BAG.svg";
import VALIDATION from "../../../../../../../assets/icons/BASE/VALIDATION.svg";
import Box from "../../../../../../components/Base/Box";
import TabTextInfo from "../../../../../../components/BookingListDetails/BookingListDetailsView/Tabs/TabTextInfo";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import type { ProductCategoryFragment } from "../../../../../../graphql/generated/schema";
import { useGetProductCategoryLazyQuery } from "../../../../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";

interface CategoryListDetailsViewProps {
  id: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 40;

const CategoryListDetailsView = ({ id, goBack }: CategoryListDetailsViewProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<ProductCategoryFragment | null>(null);

  const [getCategory] = useGetProductCategoryLazyQuery();

  const handleGetCategory = async () => {
    try {
      const { data, error } = await getCategory({
        variables: {
          categoryId: id,
        },
      });

      console.log("err", JSON.stringify(error, null, 2));

      if (data?.getProductCategory) {
        setCategory(data.getProductCategory);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleGetCategory();
    }, []),
  );

  if (loading) {
    return <Loader />;
  }

  if (!category) {
    return (
      <Box>
        <ScreenHeader
          title={"Catégorie"}
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération de la catégorie." />
      </Box>
    );
  }

  const { name, color, description, isEnabled, products, picture } = category;

  const displayProducts = products.map(product => product.name).join("\n");

  const displayImage = () => {
    if (!picture) return <></>;

    return (
      <Image
        source={{
          uri: picture,
        }}
        style={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE,
          borderRadius: IMAGE_SIZE / 2,
        }}
      />
    );
  };

  return (
    <Box flex={1}>
      <ScreenHeader
        title={name}
        rightButtons={displayImage()}
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        {description && (
          <TabTextInfo
            {...{
              title: "Description",
              content: description,
              icon: (
                <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
              ),
            }}
          />
        )}

        <TabTextInfo
          {...{
            title: "Contenu de la catégorie",
            content: displayProducts,
            icon: (
              <SHOPPING_BAG
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />

        <TabTextInfo
          {...{
            title: "Indisponible",
            content: isEnabled ? "Non" : "Oui",
            icon: (
              <VALIDATION height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
        {/* <TabTextInfo
          {...{
            title: "Disponibilité",
            content: "Mardi : 12:00-14:00\nMercredi : 12:00-14:00",
            icon: (
              <PAX_PERSONS height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        /> */}
      </ScrollView>
    </Box>
  );
};

export default CategoryListDetailsView;
