import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";

export enum HOUR_LOCATION_MODAL_CONTENT_TYPE {
  LOCATION,
  HOUR,
  CLOSURE,
  HOUR_LOCAITON_CHOICE,
}
interface AddNewMerchantHourModalContentProps {
  onPress: (type: HOUR_LOCATION_MODAL_CONTENT_TYPE) => void;
}

const AddNewMerchantHourModalContent = ({
  onPress,
}: AddNewMerchantHourModalContentProps) => {
  return (
    <Box>
      <Box mt="m">
        <CustomButton
          buttonVariant="outlineButton"
          borderColor="success"
          onPress={() => onPress(HOUR_LOCATION_MODAL_CONTENT_TYPE.HOUR)}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="outlineButtonText" color="success">
            Ajouter une horaire d'ouverture
          </CustomText>
        </CustomButton>
      </Box>
      <Box mt="m">
        <CustomButton
          buttonVariant="outlineButton"
          borderColor="success"
          onPress={() => onPress(HOUR_LOCATION_MODAL_CONTENT_TYPE.CLOSURE)}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="outlineButtonText" color="success">
            Ajouter une fermeture
          </CustomText>
        </CustomButton>
      </Box>
      {/* <Box mt="m">
        <CustomButton
          buttonVariant="outlineButton"
          borderColor="success"
          onPress={() => onPress(HOUR_LOCATION_MODAL_CONTENT_TYPE.LOCATION)}
          styles={{
            minHeight: 45,
          }}
        >
          <CustomText variant="outlineButtonText" color="success">
            Ajouter un lieu
          </CustomText>
        </CustomButton>
      </Box> */}
    </Box>
  );
};

export default AddNewMerchantHourModalContent;
