import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../../components/Base/Box";
import BottomButton from "../../../../../components/BottomButton";
import ScreenHeader from "../../../../../components/ScreenHeader";
import SettingsButtonItem from "../../../../../components/Settings/SettingsButtonItem";
import { LINE_THICKNESS } from "../../../../../theme";

const BookingServiceProductSelect = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const {
    selectedProducts = [],
    selectedCategories = [],
    selectedMenus = [],
    onSubmit,
  } = params;
  const [finalProducts, setFinalProducts] = useState(selectedProducts);
  const [finalCategories, setFinalCategories] = useState(selectedCategories);
  const [finalMenus, setFinalMenus] = useState(selectedMenus);

  const handleGetCorrectDataByType = (type: "products" | "categories" | "menus") => {
    switch (type) {
      case "products":
        return selectedProducts;
      case "categories":
        return selectedCategories;
      case "menus":
        return selectedMenus;
    }
  };

  const handleOnSubmit = (type: "products" | "categories" | "menus", updates) => {
    const convertedUpdates = updates.map(item => ({
      _id: item._id,
      name: item.name,
    }));

    switch (type) {
      case "products":
        setFinalProducts(convertedUpdates);
        break;
      case "categories":
        setFinalCategories(convertedUpdates);
        break;
      case "menus":
        setFinalMenus(convertedUpdates);
        break;
    }
  };

  const handleSubmit = () => {
    onSubmit({
      products: finalProducts,
      categories: finalCategories,
      menus: finalMenus,
    });

    navigation.goBack();
  };

  const handleNavigation = (
    screenName: string,
    type: "products" | "categories" | "menus",
  ) => {
    const data = handleGetCorrectDataByType(type);
    navigation.navigate(screenName, { type, data, onSubmit: handleOnSubmit });
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Produits disponibles"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box
          mt="s"
          paddingHorizontal="s"
          backgroundColor="disabled"
          borderRadius="button"
        >
          <SettingsButtonItem
            title="Formules"
            subText="Choix multiples avec notion de steps"
            onPress={() =>
              handleNavigation("BOOKING_SERVICES_PRODUCT_SELECT_LIST", "menus")
            }
            bottomLineWidth={LINE_THICKNESS}
          />
          {/* <SettingsButtonItem
            title="Catégories combinées"
            subText="Regroupe plusieurs catégories"
            onPress={() => handleNavigation("BOOKING_SERVICES_PRODUCT_SELECT_LIST")}
            bottomLineWidth={LINE_THICKNESS}
          /> */}
          <SettingsButtonItem
            title="Catégories"
            subText="Peut contenir un ou plusieurs produits"
            onPress={() =>
              handleNavigation("BOOKING_SERVICES_PRODUCT_SELECT_LIST", "categories")
            }
            bottomLineWidth={LINE_THICKNESS}
          />
          <SettingsButtonItem
            title="Produits"
            subText="Produit, prestation, catalogue"
            onPress={() =>
              handleNavigation("BOOKING_SERVICES_PRODUCT_SELECT_LIST", "products")
            }
            bottomLineWidth={0}
          />
        </Box>
      </ScrollView>
      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default BookingServiceProductSelect;
