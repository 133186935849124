import { useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";

import { QUOTE_STATUS } from "../../../types";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../BookingList/BookingListCardStatusButtonItem";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import { CustomTextInput } from "../../TextInput";
import QuoteStatusButtonItem from "../QuoteStatusButtonItem";

interface QuoteStatusListModalProps {
  isVisible: boolean;
  showAllStatuses?: boolean;
  status: QUOTE_STATUS;
  onClose: () => void;
  onPress: (status: QUOTE_STATUS, refusalReason: string) => void;
}

const QuoteStatusListModal = ({
  showAllStatuses = false,
  onPress,
  isVisible,
  onClose,
  status,
}: QuoteStatusListModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<QUOTE_STATUS | null>(null);
  const [refusalReason, setRefusalReason] = useState("");
  const [showRefusalReasonInput, setShowRefusalReasonInput] = useState(false);

  const determineAvailableStatus = (): QUOTE_STATUS[] => {
    // const statuses = Object.values(QUOTE_STATUS).filter(value => value > status);
    // return statuses;

    const ALL_AVAILABLE_STATUSES_FOR_OWNER = [
      QUOTE_STATUS.NEW,
      QUOTE_STATUS.ACCEPTED,
      QUOTE_STATUS.CANCELLED,
      QUOTE_STATUS.MODIFIED,
      QUOTE_STATUS.SENT,
      QUOTE_STATUS.REJECTED,
    ];

    if (showAllStatuses) {
      return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
    }

    if (status === QUOTE_STATUS.NEW) {
      return [QUOTE_STATUS.ACCEPTED, QUOTE_STATUS.REJECTED];
    }

    if (status === QUOTE_STATUS.ACCEPTED) {
      return [QUOTE_STATUS.SENT, QUOTE_STATUS.REJECTED];
    }
    if (status === QUOTE_STATUS.SENT) {
      return [QUOTE_STATUS.REJECTED];
    }

    return ALL_AVAILABLE_STATUSES_FOR_OWNER.filter(s => s !== status);
  };

  const handlePress = (newStatus: number) => {
    setSelectedStatus(newStatus);
    if (newStatus === QUOTE_STATUS.REJECTED) {
      setShowRefusalReasonInput(true);
    } else {
      onPress(newStatus, "");
      handleOnClose();
    }
    // onPress(newStatus);
  };

  const handleOnClose = () => {
    setSelectedStatus(null);
    setShowRefusalReasonInput(false);
    setRefusalReason("");
    onClose();
  };

  const handleSubmit = () => {
    if (selectedStatus) {
      onPress(selectedStatus, refusalReason);
    }

    handleOnClose();
  };

  return (
    <ContentModal title="Status" onClose={handleOnClose} isVisible={isVisible}>
      <>
        {!showRefusalReasonInput ? (
          <Box mt="s">
            <FlatList
              data={determineAvailableStatus()}
              renderItem={({ item }) => {
                return (
                  <Box mb="s">
                    <TouchableOpacity onPress={() => handlePress(item)}>
                      <QuoteStatusButtonItem
                        status={item}
                        type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
                      />
                    </TouchableOpacity>
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box mt="s">
            <CustomText variant="label" color="primaryTextColor">
              Indiquez la raison du refus
            </CustomText>
            <Box mt="s">
              <CustomTextInput
                placeHolder="Raison du refus"
                isRequired
                onChangeText={setRefusalReason}
              />
            </Box>

            <Box mt="s">
              <CustomButton
                disabled={selectedStatus === null}
                buttonVariant="primaryButton"
                buttonColor="success"
                onPress={handleSubmit}
                styles={{
                  minHeight: 45,
                }}
              >
                <CustomText variant="primaryButtonText" color="white">
                  Valider
                </CustomText>
              </CustomButton>
            </Box>
          </Box>
        )}
      </>
    </ContentModal>
  );
};

export default QuoteStatusListModal;
