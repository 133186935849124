import { Platform, TouchableOpacity as RNTouchableOpacity } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

const IS_WEB = Platform.OS === "web";
const IS_IOS = Platform.OS === "ios";

const Touchable = (props: RNTouchableOpacity["props"] & TouchableOpacity["props"]) => {
  if (IS_IOS) {
    return <TouchableOpacity {...props} />;
  }

  return <RNTouchableOpacity {...props} />;
};

export default Touchable;
