import type { Dispatch, SetStateAction } from "react";

import type {
  CreateOnlineSalesAvailabilityInput,
  OnlineSalesAvailabilityFragment,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface OrderConfirmationProps {
  service: CreateOnlineSalesAvailabilityInput;
  setService: Dispatch<SetStateAction<CreateOnlineSalesAvailabilityInput>>;
  errors: ERROR[];
}

const OrderConfirmation = ({ service, setService, errors }: OrderConfirmationProps) => {
  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const toggleConfirmationType = (type: "automatic" | "manual") => {
    setService(prev => {
      if (type === "manual") {
        return {
          ...prev,
          automaticClickConfirmationSettings: {
            ...prev.automaticClickConfirmationSettings,
            acceptAutomatically: false,
            acceptAutomaticallyWithLimits: prev.automaticClickConfirmationSettings
              .acceptAutomaticallyWithLimits
              ? false
              : true,
          },
        };
      } else {
        return {
          ...prev,
          automaticClickConfirmationSettings: {
            ...prev.automaticClickConfirmationSettings,
            acceptAutomatically: prev.automaticClickConfirmationSettings
              .acceptAutomatically
              ? false
              : true,
            acceptAutomaticallyWithLimits: false,
            minArticlesPerOrder: 0,
            minPricePerOrder: 0,
          },
        };
      }
    });
  };

  const updateServiceAutomaticClickConfirmationData = (
    key: keyof OnlineSalesAvailabilityFragment["automaticClickConfirmationSettings"],
    value: number | boolean,
  ) => {
    setService(prev => ({
      ...prev,
      automaticClickConfirmationSettings: {
        ...prev.automaticClickConfirmationSettings,
        [key]: value,
      },
    }));
  };

  return (
    <Box>
      <Box
        mt="m"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          CONFIRMATION
        </CustomText>
      </Box>
      <Box mt="s" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          hasErrors={doesInputHaveError(
            "automaticClickConfirmationSettings.acceptAutomatically",
          )}
          textVariant="content"
          text="Accepter tout automatiquement"
          value={service?.automaticClickConfirmationSettings?.acceptAutomatically}
          onValueChange={() => toggleConfirmationType("automatic")}
        />
        <CustomText mt="xs" variant="text" color="lightGrey">
          Toutes les commandes effectuées par vos clients seront automatiquement acceptées
          jusqu’à la limite de commande
        </CustomText>
      </Box>
      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <ToggleInputLine
          textVariant="content"
          text="Accepter automatiquement jusqu'à"
          value={
            service?.automaticClickConfirmationSettings?.acceptAutomaticallyWithLimits
          }
          onValueChange={() => toggleConfirmationType("manual")}
        />
        <CustomText mt="xs" variant="text" color="lightGrey">
          Toutes les commandes effectuées jusqu’à X articles ou montant seront
          automatiquement acceptées.
        </CustomText>
        {service.automaticClickConfirmationSettings.acceptAutomaticallyWithLimits && (
          <Box
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                hideFloatingLabel
                hasErrors={doesInputHaveError(
                  "automaticClickConfirmationSettings.minArticlesPerOrder",
                )}
                placeHolder="0"
                initialValue={service.automaticClickConfirmationSettings.minArticlesPerOrder.toString()}
                keyboardType="number-pad"
                onChangeText={v =>
                  updateServiceAutomaticClickConfirmationData(
                    "minArticlesPerOrder",
                    getSafeNumberFromInput(v),
                  )
                }
                boxProps={{
                  minWidth: 70,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                inputStyles={{ textAlign: "center" }}
              />
              <Box
                ml="s"
                p="s"
                backgroundColor="disabled"
                borderRadius="button"
                alignItems="center"
                justifyContent="center"
                minHeight={50}
                minWidth={50}
              >
                <CustomText variant="content" color="primaryTextColor">
                  Articles
                </CustomText>
              </Box>
            </Box>
            <CustomText variant="content" color="primaryTextColor">
              ou
            </CustomText>
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                hideFloatingLabel
                hasErrors={doesInputHaveError(
                  "automaticClickConfirmationSettings.minPricePerOrder",
                )}
                placeHolder="0"
                initialValue={service.automaticClickConfirmationSettings.minPricePerOrder.toString()}
                keyboardType="number-pad"
                onChangeText={v =>
                  updateServiceAutomaticClickConfirmationData(
                    "minPricePerOrder",
                    getSafeNumberFromInput(v),
                  )
                }
                boxProps={{
                  minWidth: 70,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                inputStyles={{ textAlign: "center" }}
              />
              <Box
                ml="s"
                p="s"
                backgroundColor="disabled"
                borderRadius="button"
                alignItems="center"
                justifyContent="center"
                minHeight={50}
                minWidth={50}
              >
                <CustomText variant="content" color="primaryTextColor">
                  €
                </CustomText>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
