import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import Box from "../../../components/Base/Box";
import BottomButtomWithActions from "../../../components/BottomButtomWithActions";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import PublicationDetails from "../../../components/Publication/PublicationDetails";
import PublicationDetailsView from "../../../components/Publication/PublicationDetailsView";
import ScreenHeader from "../../../components/ScreenHeader";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext";
import type { PublicationFragment } from "../../../graphql/generated/schema";
import {
  useDeletePublicationMutation,
  useGetPublicationLazyQuery,
} from "../../../graphql/generated/schema";

interface PublicationListDetailsProps {
  publicationId: string;
  goBack?: () => void;
}

const PublicationListDetails = ({
  goBack,
  publicationId: id,
}: PublicationListDetailsProps) => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [inEditMode, setInEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publication, setPublication] = useState<PublicationFragment | undefined>(
    undefined,
  );

  const publicationId = params?.publicationId || id;

  const [getPublication] = useGetPublicationLazyQuery();
  const [deletPublication] = useDeletePublicationMutation();

  const handleGetPublication = async () => {
    try {
      const { data } = await getPublication({
        variables: {
          publicationId,
        },
      });

      if (data?.getPublication) {
        setPublication(data?.getPublication);
      }
    } catch (err) {
      console.log("err get publication", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPublication();
  }, [publicationId]);

  const handleDeletePublication = async (pubId: string) => {
    try {
      await deletPublication({
        variables: {
          publicationId: pubId,
        },
      });
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_DELETE_PUBLICATION",
            message: "Une erreur est survenue lors de la suppression de la publication.",
          },
        ],
        "error",
      );
    }
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!publication) {
    return (
      <Box>
        <ScreenHeader title="Publication" hasBackButton onBackPress={navigation.goBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération de la publication." />
      </Box>
    );
  }

  const handleButtonSubmit = async (key: string) => {
    if (key === "CANCEL") {
      setInEditMode(false);
    }
    if (key === "DELETE") {
      await handleDeletePublication(publicationId);
      handleGoBack();
    }
  };

  return (
    <Box flex={1} pt="s" paddingHorizontal="s" backgroundColor="white">
      {inEditMode ? (
        <PublicationDetails
          publication={publication}
          isNew={false}
          onGoBack={handleGoBack}
        />
      ) : (
        <PublicationDetailsView publication={publication} goBack={handleGoBack} />
      )}
      {!inEditMode && (
        <BottomButtomWithActions
          title="Modifier"
          actions={[
            {
              title: "Supprimer",
              key: "DELETE",
              variant: "primary",
              color: "danger",
            },
            {
              title: "Annuler",
              key: "CANCEL",
              variant: "outline",
              color: "danger",
            },
          ]}
          onMainActionPress={() => setInEditMode(v => !v)}
          backGroundColor="white"
          onSubmit={handleButtonSubmit}
        />
        // <Box
        //   position="absolute"
        //   bottom={0}
        //   left={0}
        //   right={0}
        //   pb="m"
        //   justifyContent="center"
        //   backgroundColor="white"
        //   paddingHorizontal="s"
        // >
        //   {/* <CustomButton
        //     styles={{ minHeight: 45 }}
        //     onPress={() => {}}
        //     buttonVariant="outlineButton"
        //     borderColor="success"
        //   >
        //     <CustomText variant="outlineButtonText" color="success">
        //       Dupliquer
        //     </CustomText>
        //   </CustomButton> */}
        //   <CustomButton
        //     styles={{ mt: "s" }}
        //     onPress={() => setInEditMode(v => !v)}
        //     buttonVariant="primaryButton"
        //     buttonColor="success"
        //   >
        //     <CustomText variant="primaryButtonText" color="white">
        //       Modifier
        //     </CustomText>
        //   </CustomButton>
        // </Box>
      )}
    </Box>
  );
};

export default PublicationListDetails;
