import { addMonths, subMonths } from "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FlatList, type StyleProp, type TextStyle, type ViewStyle } from "react-native";

import LEFT_ARROW from "../../../assets/icons/BASE/LEFT_ARROW.svg";
import RIGHT_ARROW from "../../../assets/icons/BASE/RIGHT_ARROW.svg";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

const ICON_SIZE = 20;
type AgendaMonthView = {
  currentDate: Date;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  isDisable?: boolean;
  onDayPress?: (date: Date) => void;
  onMonthChange?: (month: Date) => void;
  items?: Record<string, unknown>;
  width?: number | string;
};

const AgendaMonthView = ({
  currentDate,
  items = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDayPress = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMonthChange = () => {},
  width = "100%",
}: AgendaMonthView) => {
  const [month, setMonth] = useState<Date>(currentDate);
  const [monthDays, setMonthDays] = useState<string[]>(
    getMonths(moment(currentDate).format("YYYY-MM")),
  );
  const ref = useRef(null);

  const isCurrentDay = day => {
    return moment(day, "YYYY-MM-DD").isSame(moment(currentDate).startOf("D"));
  };

  const handleDotDisplay = day => {
    const dateString = moment(day).format("YYYY-MM-DD");
    const itemsDateString = Object.keys(items);
    return itemsDateString.includes(dateString);
  };

  function getMonths(date) {
    const ar = [];
    const start = moment(date, "YYYY-MM");
    for (
      let end = moment(start).add(1, "month");
      start.isBefore(end);
      start.add(1, "day")
    ) {
      ar.push(start.format("YYYY-MM-DD"));
    }
    return ar;
  }

  useEffect(() => {
    onMonthChange(month);
  }, []);

  useEffect(() => {
    setMonthDays(getMonths(moment(month).format("YYYY-MM")));
    // onMonthChange(month);
  }, [month]);

  useEffect(() => {
    handleScrollToSelectedDate();
  }, [currentDate]);

  const handleScrollToSelectedDate = () => {
    const dayIndex = +moment(currentDate).format("D") - 1;

    if (isNaN(dayIndex)) return;

    ref?.current?.scrollToIndex?.({
      index: dayIndex,
      animated: true,
    });
  };

  const handleMonthChange = (type: "PREV" | "NEXT") => {
    const m = type === "PREV" ? subMonths(month, 1) : addMonths(month, 1);

    setMonth(m);
    onMonthChange(m);
  };

  const renderItem = ({ item }) => {
    return (
      <CustomButton
        styles={{
          alignItems: "center",
          marginVertical: "s",
        }}
        // hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}
        onPress={() => {
          // Haptics.selectionAsync().then(() =>
          onDayPress(moment(item, "YYYY-MM-DD").startOf("day").toDate());
          // );
        }}
      >
        <CustomText variant="description" color="lightGrey">
          {moment(item).format("dd").toUpperCase()}
        </CustomText>

        <Box
          marginVertical="s"
          backgroundColor="white"
          height={40}
          width={40}
          alignItems="center"
          justifyContent="center"
          // borderRadius="button"
          style={[
            {
              backgroundColor: isCurrentDay(item) ? PALETTE.green : PALETTE.white,
              borderRadius: 20,
            },
          ]}
        >
          <CustomText
            variant="description"
            color={isCurrentDay(item) ? "white" : "primaryTextColor"}
          >
            {moment(item).format("D").toUpperCase()}
          </CustomText>

          {handleDotDisplay(item) && (
            <Box style={{ position: "absolute", bottom: 4, alignSelf: "center" }}>
              <Box
                style={{
                  height: 4,
                  width: 4,
                  backgroundColor: isCurrentDay(item) ? PALETTE.white : PALETTE.green,
                  borderRadius: 4,
                }}
              />
            </Box>
          )}
        </Box>
      </CustomButton>
    );
  };

  return (
    <Box backgroundColor="white" paddingHorizontal="s" alignItems="center">
      <Box flexDirection="row" alignItems="center" justifyContent="center" width="100%">
        <CustomButton
          styles={{ paddingHorizontal: "s" }}
          onPress={() => handleMonthChange("PREV")}
          // onPress={() => setMonth(new Date(month.getFullYear(), month.getMonth() - 1, 1))}
        >
          <LEFT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </CustomButton>

        <CustomText
          variant="content"
          style={{
            width: 90,
            textAlign: "center",
          }}
        >
          {moment(month).format("MMM YYYY")}
        </CustomText>

        <CustomButton
          styles={{ paddingHorizontal: "s" }}
          onPress={() => handleMonthChange("NEXT")}
          // onPress={() => setMonth(new Date(month.getFullYear(), month.getMonth() + 1, 1))}
        >
          <RIGHT_ARROW width={ICON_SIZE} height={ICON_SIZE} fill={PALETTE.green} />
        </CustomButton>
      </Box>
      {monthDays.length > 0 && (
        <FlatList
          keyboardShouldPersistTaps="always"
          windowSize={50}
          maxToRenderPerBatch={50}
          initialNumToRender={50}
          // initialScrollIndex={+moment(currentDate).format("D") - 1}
          onScrollToIndexFailed={error => {
            setTimeout(() => {
              ref?.current?.scrollToIndex?.({ index: error.index, animated: true });
            }, 100);
          }}
          // style={{
          //   width: width,
          // }}
          style={{
            width: "100%",
          }}
          data={monthDays}
          ref={ref}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderItem}
        />
      )}
    </Box>
  );
};

export default AgendaMonthView;
