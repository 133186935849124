import { useNavigation } from "@react-navigation/native";
import type { ImagePickerAsset } from "expo-image-picker";
import { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import BottomButton from "../../../../../../components/BottomButton";
import { CustomButton } from "../../../../../../components/Button";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import AddNewImageModal from "../../../../../../components/Media/AddNewImageMedia";
import PhoneInput from "../../../../../../components/PhoneInput";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../../../components/TextInput";
import type { User } from "../../../../../../graphql/generated/schema";
import {
  useGetMerchantOwnerLazyQuery,
  useSavePictureMutation,
  useUpdateUserProfileMutation,
} from "../../../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../../../utils/common";

interface AccountDetailsEditProps {
  goBack?: () => void;
}

const IMAGE_SIZE = 150;

const AccountDetailsEdit = ({ goBack }: AccountDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState<User | undefined>(undefined);
  const [updates, setUpdates] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    picture: "",
  });
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  const [getMerchantOwner] = useGetMerchantOwnerLazyQuery();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetMerchantOwner = async () => {
    try {
      const { data } = await getMerchantOwner();
      if (data?.getMerchantOwner) {
        setOwner(data.getMerchantOwner);

        const profile = data.getMerchantOwner?.profile
          ? data.getMerchantOwner?.profile
          : {
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              picture: "",
            };

        setUpdates(profile);
      }

      setLoading(false);
    } catch (err) {
      console.log("err get merchant owner: ", err);
    }
  };

  useEffect(() => {
    handleGetMerchantOwner();
  }, []);

  if (loading) return <Loader />;

  const handleUpdate = (key: string, value: string) => {
    setUpdates({
      ...updates,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const allUpdates = removeTypeNames({
        ...owner?.profile,
        ...updates,
      });

      const { data } = await updateUserProfile({
        variables: {
          profile: allUpdates,
        },
      });

      if (data?.updateUserProfile) {
        handleGoBack();
      }
    } catch (err) {
      console.log("err updating user profile: ", err);
    }
  };

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setUpdates(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    if (!updates) return;

    const { picture } = updates;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );
    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  return (
    <>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <ScreenHeader
                title="Nom et coordonnées"
                hasBackButton
                onBackPress={handleGoBack}
              />
            </Box>

            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Prénom"
                initialValue={owner?.profile?.firstName || ""}
                onChangeText={t => handleUpdate("firstName", t)}
                isRequired
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Nom"
                initialValue={owner?.profile?.lastName || ""}
                onChangeText={t => handleUpdate("lastName", t)}
                isRequired
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Email"
                initialValue={owner?.profile?.email || ""}
                onChangeText={t => handleUpdate("email", t)}
                isRequired
              />
            </Box>
            <Box marginVertical="s">
              <PhoneInput
                placeHolder="Téléphone"
                onChangeFormattedText={t => handleUpdate("phone", t)}
                value={owner?.profile?.phone || ""}
              />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <BottomButton title="Enregistrer" onPress={handleSubmit} />

      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default AccountDetailsEdit;
