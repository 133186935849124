import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useState } from "react";
import { ScrollView } from "react-native";

import Box from "../../../../components/Base/Box";
import { CustomText } from "../../../../components/Base/Text";
import BottomButton from "../../../../components/BottomButton";
import { CustomButton } from "../../../../components/Button";
import ContentModal from "../../../../components/ContentModal";
import ErrorMessage from "../../../../components/ErrorMessage";
import Incrementer from "../../../../components/Incrementer";
import type { ListingTab } from "../../../../components/ListingTabs";
import { ListingTabs } from "../../../../components/ListingTabs";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import SelectButton from "../../../../components/Select/SelectButton";
import { CustomTextInput } from "../../../../components/TextInput";
import type {
  CreateStockMovementInput,
  ProductFragment,
  StockMovementFragment,
} from "../../../../graphql/generated/schema";
import {
  Stock_Movement_Reason,
  Stock_Movement_Type,
  useAddStockMovementMutation,
  useGetProductLazyQuery,
  useGetStockMovementsLazyQuery,
} from "../../../../graphql/generated/schema";
import { formatCurrencyPrice, getSafeNumberFromInput } from "../../../../utils/common";

interface StockListProps {
  goBack?: () => void;
  productId?: string;
}

const TABS: ListingTab[] = [
  {
    title: "Entrée",
    key: "ENTER",
  },
  {
    title: "Sortie",
    key: "EXIT",
  },
];

const DEFAULT_CREATE_STOCK_MOVEMENT_PARAMS: CreateStockMovementInput = {
  comment: "",
  date: new Date(),
  movementReason: Stock_Movement_Reason.AdjustStock,
  movementType: Stock_Movement_Type.Enter,
  productId: "",
  purchaseUnitPrice: 0,
  quantity: 0,
};

const StockList = ({ goBack, productId: id }: StockListProps) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { params } = route;

  const productId = id || params?.productId;
  const [selectedTab, setSelectedTab] = useState(TABS[0].key);

  const [product, setProduct] = useState<ProductFragment | undefined>(undefined);
  const [stockMovements, setStockMovements] = useState<StockMovementFragment[]>([]);
  const [addStockMovementParams, setAddStockMovementParams] =
    useState<CreateStockMovementInput>(DEFAULT_CREATE_STOCK_MOVEMENT_PARAMS);

  const [loading, setLoading] = useState(true);
  const [isAddStockModalOpen, setIsAddStockModalOpen] = useState(false);

  const [getProduct] = useGetProductLazyQuery();
  const [getStockMovmentForProduct] = useGetStockMovementsLazyQuery();
  const [addStockMovement] = useAddStockMovementMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetProduct = async () => {
    try {
      const { data } = await getProduct({
        variables: {
          productId,
        },
      });

      if (data?.getProduct) {
        setProduct(data?.getProduct);
      }
    } catch (err) {
      console.log("err geting product", err);
    }
  };

  const handleGetStockMovmentsForProduct = async () => {
    try {
      const { data } = await getStockMovmentForProduct({
        fetchPolicy: "network-only",
        variables: {
          productId,
          pagination: {
            limit: 40,
            offset: 0,
          },
        },
      });

      if (data?.getStockMovements) {
        setStockMovements(data?.getStockMovements);
      }
    } catch (err) {
      console.log("err geting product", err);
    }
  };

  const handleInit = async () => {
    setLoading(true);
    try {
      await handleGetProduct();
      await handleGetStockMovmentsForProduct();
    } catch (err) {
      console.log("init err", err);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      handleInit();
    }, []),
  );

  if (loading) return <Loader />;

  if (!product)
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue lors de la récupération du produit." />
      </Box>
    );

  const handleAddNewStockMovement = () => {
    setIsAddStockModalOpen(true);
  };

  const handleCloseAddStockModal = () => {
    handleReset();

    setIsAddStockModalOpen(false);
  };

  const updateAddStockMovementParams = (
    key: keyof CreateStockMovementInput,
    value: CreateStockMovementInput[keyof CreateStockMovementInput],
  ) => {
    setAddStockMovementParams({
      ...addStockMovementParams,
      [key]: value,
    });
  };

  const handleReset = () => {
    setSelectedTab("ENTER");
    setAddStockMovementParams(DEFAULT_CREATE_STOCK_MOVEMENT_PARAMS);
  };

  const handleSubmit = async () => {
    try {
      const { data } = await addStockMovement({
        variables: {
          stockMovement: {
            ...addStockMovementParams,
            movementType:
              selectedTab === "ENTER"
                ? Stock_Movement_Type.Enter
                : Stock_Movement_Type.Exit,
            productId: product._id,
          },
        },
      });

      if (data?.addStockMovement) {
        handleCloseAddStockModal();
        await handleInit();
      }
    } catch (err) {
      console.log("err adding stock movement", err);
    }
  };

  const handleSetSelectedTab = (tab: string) => {
    setSelectedTab(tab);

    const reason =
      tab === "ENTER"
        ? Stock_Movement_Reason.AdjustStock
        : Stock_Movement_Reason.MerchandiseBroken;

    updateAddStockMovementParams("movementReason", reason);
  };

  const getMovementReasons = () => {
    if (selectedTab === "ENTER") {
      return [
        {
          label: "Ajustement de stock",
          key: Stock_Movement_Reason.AdjustStock,
        },
        {
          label: "Achat",
          key: Stock_Movement_Reason.PurchaseMerchandise,
        },
        {
          label: "Autre",
          key: Stock_Movement_Reason.Other,
        },
      ];
    }
    return [
      {
        label: "Casse",
        key: Stock_Movement_Reason.MerchandiseBroken,
      },
      {
        label: "Rebus",
        key: Stock_Movement_Reason.MerchandiseCancelled,
      },
      {
        label: "Périmé",
        key: Stock_Movement_Reason.MerchandiseExpired,
      },
      {
        label: "Personnel",
        key: Stock_Movement_Reason.PersonalReasons,
      },
      {
        label: "Autre",
        key: Stock_Movement_Reason.Other,
      },
    ];
  };

  const displayAddStockModal = () => {
    const isDisabled =
      addStockMovementParams.quantity === 0 ||
      addStockMovementParams.movementReason.trim().length === 0 ||
      (selectedTab === "ENTER" && addStockMovementParams.purchaseUnitPrice === 0);
    return (
      <ContentModal
        title="Ajouter un mouvement de stock"
        isVisible={isAddStockModalOpen}
        onClose={handleCloseAddStockModal}
        styles={{
          minHeight: 600,
        }}
      >
        <Box height="100%">
          <Box marginVertical="s">
            <ListingTabs
              tabs={TABS}
              currentTabName={selectedTab}
              onSelectTab={handleSetSelectedTab}
              boxProps={{
                minHeight: 45,
              }}
            />
          </Box>

          <Box marginVertical="s">
            <SelectButton
              isMultiSelect={false}
              options={getMovementReasons()}
              placeHolder="Raison du mouvement"
              selectedOptions={
                addStockMovementParams.movementReason
                  ? [addStockMovementParams.movementReason]
                  : []
              }
              onPress={vals => {
                updateAddStockMovementParams("movementReason", vals[0]);
              }}
            />
          </Box>

          <Box marginVertical="s">
            <Incrementer
              value={addStockMovementParams.quantity}
              onValueChange={value => {
                updateAddStockMovementParams("quantity", value);
              }}
              max={1000}
              min={0}
            />
          </Box>

          {selectedTab === "ENTER" && (
            <Box marginVertical="s">
              <CustomTextInput
                onChangeText={value =>
                  updateAddStockMovementParams("purchaseUnitPrice", value)
                }
                onBlur={value =>
                  updateAddStockMovementParams(
                    "purchaseUnitPrice",
                    getSafeNumberFromInput(value),
                  )
                }
                value={addStockMovementParams.purchaseUnitPrice?.toString()}
                placeHolder="Prix d'achat unitaire"
                keyboardType="decimal-pad"
              />
            </Box>
          )}

          <Box marginVertical="s">
            <CustomTextInput
              onChangeText={value => updateAddStockMovementParams("comment", value)}
              initialValue={addStockMovementParams.comment}
              placeHolder="Commentaire"
            />
          </Box>

          <Box position="absolute" bottom={45} left={0} right={0} backgroundColor="white">
            <Box marginVertical="s">
              <CustomButton
                buttonVariant="outlineButton"
                borderColor="yellow"
                onPress={handleReset}
              >
                <CustomText variant="outlineButtonText" color="yellow">
                  Réinitialiser
                </CustomText>
              </CustomButton>
            </Box>
            <Box marginVertical="s">
              <CustomButton
                disabled={isDisabled}
                buttonVariant="primaryButton"
                buttonColor={isDisabled ? "disabled" : "success"}
                onPress={handleSubmit}
              >
                <CustomText
                  variant="primaryButtonText"
                  color={isDisabled ? "lightGrey" : "white"}
                >
                  Valider
                </CustomText>
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </ContentModal>
    );
  };

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <Box paddingVertical="s">
        <ScreenHeader
          title="Historique du stock produit"
          hasBackButton
          onBackPress={handleGoBack}
        />
      </Box>

      <ScrollView
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Box marginVertical="s">
          <CustomText variant="label" color="primaryTextColor">
            {product.name}
          </CustomText>
        </Box>
        <Box marginVertical="s" flexDirection="row" alignItems="center">
          <Box
            backgroundColor="greenBackground"
            p="m"
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
            mr="s"
            minWidth={100}
          >
            <CustomText variant="content" color="success">
              {product.stockAmount}
            </CustomText>
            <CustomText variant="text" color="success">
              Stock
            </CustomText>
          </Box>
          <Box
            backgroundColor="orangeBackground"
            p="m"
            borderRadius="button"
            alignItems="center"
            justifyContent="center"
            minWidth={100}
          >
            <CustomText variant="content" color="orange">
              {product.physicalSettings.alertLimitUnavailableForOnlineSales}
            </CustomText>
            <CustomText variant="text" color="orange">
              Seuil
            </CustomText>
          </Box>
        </Box>

        <Box>
          <CustomText variant="content" color="primaryTextColor">
            Légende du tableau
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            Entrée: (E)
          </CustomText>
          <CustomText variant="content" color="lightGrey">
            Sortie: (S)
          </CustomText>
        </Box>

        <Box
          mt="m"
          flexDirection="row"
          alignItems="center"
          borderColor="lightGrey"
          borderWidth={1}
          borderRadius="button"
        >
          <Box
            flex={0.3}
            borderRightColor="lightGrey"
            borderRightWidth={1}
            minHeight={45}
            justifyContent="center"
            alignItems="center"
          >
            <CustomText variant="content" color="primaryTextColor">
              Date
            </CustomText>
          </Box>
          <Box
            flex={1}
            justifyContent="center"
            alignItems="center"
            borderRightColor="lightGrey"
            borderRightWidth={1}
            minHeight={45}
          >
            <CustomText variant="content" color="primaryTextColor">
              Mouvement
            </CustomText>
          </Box>
          <Box flex={0.2} justifyContent="center" alignItems="center" minHeight={45}>
            <CustomText variant="content" color="primaryTextColor">
              Qté
            </CustomText>
          </Box>
        </Box>

        <Box mt="s">
          {stockMovements.map(item => (
            <Box
              key={item._id}
              marginVertical="s"
              borderBottomColor="lightGrey"
              borderBottomWidth={1}
              pb="s"
            >
              <Box flexDirection="row" alignItems="center">
                <Box flex={0.3} alignItems="center">
                  <CustomText variant="text" color="primaryTextColor">
                    {format(new Date(item.date), "dd/MM/yyyy HH:mm")}
                  </CustomText>
                </Box>
                <Box flex={1} alignItems="center">
                  <CustomText variant="text" color="primaryTextColor">
                    {item.movementReason} (
                    {item.movementType === Stock_Movement_Type.Enter ? "E" : "S"})
                  </CustomText>
                </Box>
                <Box flex={0.2} alignItems="center">
                  <CustomText variant="text" color="primaryTextColor">
                    {item.quantity}
                  </CustomText>
                </Box>
              </Box>

              {item.movementType === Stock_Movement_Type.Enter && (
                <Box mt="s">
                  <CustomText variant="text" color="primaryTextColor">
                    Prix d'achat:{" "}
                    {formatCurrencyPrice(item.purchaseUnitPrice, "fr", "eur")}
                  </CustomText>
                </Box>
              )}
              {item.comment && (
                <Box mt="s" flex={1} flexWrap="wrap">
                  <CustomText variant="text" color="primaryTextColor">
                    Commentaire: {item.comment}
                  </CustomText>
                </Box>
              )}
            </Box>
          ))}
          {/* <FlatList
            scrollEnabled={false}
            data={stockMovements}
            keyExtractor={item => item._id}
            renderItem={({ item }) => (

            )}
          /> */}
        </Box>
      </ScrollView>
      <BottomButton title="Ajouter un mouvement" onPress={handleAddNewStockMovement} />
      {displayAddStockModal()}
    </Box>
  );
};

export default StockList;
