import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import * as yup from "yup";

import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BottomButton from "../../../components/BottomButton";
import Loader from "../../../components/Loader";
import NumberInputLine from "../../../components/NumberInputLine";
import ScreenHeader from "../../../components/ScreenHeader";
import SelectButton from "../../../components/Select/SelectButton";
import SnackBarInfo from "../../../components/SnackBarInfo";
import ToggleInputLine from "../../../components/ToggleInputLine";
import type { TablePaymentSettings } from "../../../graphql/generated/schema";
import {
  Table_Payment_Tip_Calculation_Method,
  Table_Payment_Tip_Level,
  Table_Payment_Type,
  useGetTablePaymentSettingsLazyQuery,
  useUpdateTablePaymentSettingsMutation,
} from "../../../graphql/generated/schema";
import type { ERROR } from "../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../utils/common";

interface TablePaymentSettingsProps {
  goBack?: () => void;
}

const schema = yup.object().shape({
  isOrderAtTableEnabled: yup.boolean().required(),
  isPaymentAtTableEnabled: yup.boolean().required(),
  areTipsEnabled: yup.boolean().required(),
  paymentType: yup.string().required(),
  tipCalculationMethod: yup.string().required(),
  tips: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().required(),
        amount: yup.number().min(0).required(),
      }),
    )
    .min(3),
});

const TIP_OPTIONS = [
  { label: "Pourcentage", key: Table_Payment_Type.Percentage },
  { label: "Montant fixe", key: Table_Payment_Type.FixedRate },
];

const TIP_CALCULATION_OPTIONS = [
  { label: "Calcul automatique", key: Table_Payment_Tip_Calculation_Method.Automatic },
  { label: "Saisie Manuelle", key: Table_Payment_Tip_Calculation_Method.Manual },
];

const TablePayment = ({ goBack }: TablePaymentSettingsProps) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<TablePaymentSettings | null>(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getTablePaymentSettings] = useGetTablePaymentSettingsLazyQuery();
  const [updateTablePaymentSettings] = useUpdateTablePaymentSettingsMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGetSettings = async () => {
    try {
      const { data } = await getTablePaymentSettings();

      if (data?.getTablePaymentSettings) {
        setSettings(data.getTablePaymentSettings);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const validateForm = async () => {
    try {
      await schema.validate(settings, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      setIsSnackBarOpen(true);
    }
    return false;
  };

  const handlSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const updates = removeTypeNames(settings);
      delete updates._id;

      await updateTablePaymentSettings({
        variables: {
          updates,
        },
      });

      handleGoBack();
    } catch (err) {
      setErrors([{ path: "submit", message: err.message }]);

      setIsSnackBarOpen(true);
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const updateSettingsData = (
    key: keyof TablePaymentSettings,
    value: TablePaymentSettings[keyof TablePaymentSettings],
  ) => {
    if (settings) {
      setSettings(prev => ({ ...prev, [key]: value }));
    }
  };

  const getTipLevelValue = (key: Table_Payment_Tip_Level) => {
    if (settings) {
      return settings.tips.find(tip => tip.type === key)?.amount || 0;
    }
    return 0;
  };

  const updateTipLevelValue = (key: Table_Payment_Tip_Level, value: number) => {
    if (settings) {
      const newTips = settings.tips.map(tip => {
        if (tip.type === key) {
          return { ...tip, amount: value };
        }
        return tip;
      });

      setSettings(prev => (prev === null ? null : { ...prev, tips: newTips }));
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!settings) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <CustomText variant="label" color="primaryTextColor">
          Error
        </CustomText>
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Commande & Paiement à table"
        hasBackButton
        onBackPress={goBack || navigation.goBack}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        <Box mt="m">
          <ToggleInputLine
            text="Activer la commande à table"
            value={settings?.isOrderAtTableEnabled}
            onValueChange={val => updateSettingsData("isOrderAtTableEnabled", val)}
          />
          <Box>
            <CustomText variant="description" color="lightGrey">
              -
            </CustomText>
          </Box>
        </Box>
        <Box mt="m">
          <ToggleInputLine
            text="Activer le paiement à table"
            value={settings?.isPaymentAtTableEnabled}
            onValueChange={val => updateSettingsData("isPaymentAtTableEnabled", val)}
          />
          <Box>
            <CustomText variant="description" color="lightGrey">
              -
            </CustomText>
          </Box>
        </Box>
        <Box mt="m">
          <ToggleInputLine
            text="Activer le pourboire"
            value={settings?.areTipsEnabled}
            onValueChange={val => updateSettingsData("areTipsEnabled", val)}
          />
          <Box>
            <CustomText variant="description" color="lightGrey">
              -
            </CustomText>
          </Box>
        </Box>

        <Box mt="m">
          <SelectButton
            hasErrors={doesInputHaveError("paymentType")}
            isMultiSelect={false}
            options={TIP_OPTIONS}
            selectedOptions={[settings.paymentType]}
            onPress={([val]) => updateSettingsData("paymentType", val)}
            placeHolder="Sélectionner le type de pourboire"
          />
        </Box>

        <Box mt="m">
          <SelectButton
            hasErrors={doesInputHaveError("tipCalculationMethod")}
            isMultiSelect={false}
            options={TIP_CALCULATION_OPTIONS}
            selectedOptions={[settings.tipCalculationMethod]}
            onPress={([val]) => updateSettingsData("tipCalculationMethod", val)}
            placeHolder="Sélectionner le type de calcul"
          />
        </Box>

        {settings?.tipCalculationMethod ===
          Table_Payment_Tip_Calculation_Method.Manual && (
          <Box>
            <Box mt="m">
              <NumberInputLine
                text="Bien"
                placeHolder="Bien"
                value={getTipLevelValue(Table_Payment_Tip_Level.Good)}
                onValueChange={val =>
                  updateTipLevelValue(Table_Payment_Tip_Level.Good, val)
                }
              />
            </Box>
            <Box mt="m">
              <NumberInputLine
                text="Trés bien"
                placeHolder="Trés bien"
                value={getTipLevelValue(Table_Payment_Tip_Level.VeryGood)}
                onValueChange={val =>
                  updateTipLevelValue(Table_Payment_Tip_Level.VeryGood, val)
                }
              />
            </Box>
            <Box mt="m">
              <NumberInputLine
                text="Incroyable"
                placeHolder="Incroyable"
                value={getTipLevelValue(Table_Payment_Tip_Level.Amazing)}
                onValueChange={val =>
                  updateTipLevelValue(Table_Payment_Tip_Level.Amazing, val)
                }
              />
            </Box>
          </Box>
        )}
      </ScrollView>

      <BottomButton title="Valider" onPress={handlSubmit} />

      <SnackBarInfo
        isOpen={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
        type="error"
      >
        {errors.map(error => (
          <Box key={error.path}>
            <CustomText variant="content" color="danger">
              {error.message}
            </CustomText>
          </Box>
        ))}
      </SnackBarInfo>
    </Box>
  );
};

export default TablePayment;
