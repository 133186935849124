import { Dimensions } from "react-native";

import {
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT,
  BOOKING_FLOOR_HEADER_HEIGHT,
  TABLE_SIZE,
} from "../../../constants/BookingFloor";
import { LINE_THICKNESS } from "../../../theme";
import Box from "../../Base/Box";
import { HEADER_HEIGHT } from "../../Header";

interface BookingFloorGridProps {
  floorWidth: number;
  floorHeight: number;
}

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

const FLOOR_AVAILABLE_HEIGHT =
  SCREEN_HEIGHT -
  HEADER_HEIGHT -
  BOOKING_FLOOR_HEADER_HEIGHT -
  BOOKING_FLOOR_BOTTOM_BUTTON_HEIGHT -
  TABLE_SIZE;

const SIZE = 20;

const BookingFloorGrid = ({ floorWidth, floorHeight }: BookingFloorGridProps) => {
  const ROWS = Math.floor(floorHeight / SIZE);
  const COLS = Math.floor(floorWidth / SIZE);

  const handleBoundaryCheckForGridCellSize = (s: number) => {
    if (s < 10) {
      return 10;
    }

    if (s > 20) {
      return 20;
    }

    return s;
  };

  const getCellSize = () => {
    const xScale = handleBoundaryCheckForGridCellSize((SIZE * SCREEN_WIDTH) / floorWidth);
    const yScale = handleBoundaryCheckForGridCellSize(
      (SIZE * FLOOR_AVAILABLE_HEIGHT) / floorHeight,
    );

    return Math.min(xScale, yScale);
  };

  return (
    <Box>
      {Array(ROWS)
        .fill(1)
        .map((_, idx) => {
          return (
            <Box
              key={idx}
              position="absolute"
              top={idx * getCellSize()}
              width="100%"
              maxWidth={floorWidth}
              height={getCellSize()}
              borderBottomColor="lightGrey"
              borderBottomWidth={LINE_THICKNESS}
            >
              {Array(COLS)
                .fill(1)
                .map((__, index) => (
                  <Box
                    key={idx + index}
                    left={index * getCellSize()}
                    position="absolute"
                    borderRightColor="lightGrey"
                    borderRightWidth={LINE_THICKNESS}
                    height="100%"
                    maxHeight={floorHeight}
                    width={getCellSize()}
                  />
                ))}
            </Box>
          );
        })}
    </Box>
  );
};

export default BookingFloorGrid;
