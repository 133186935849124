import { useNavigation } from "@react-navigation/native";
import * as AuthSession from "expo-auth-session";
import { useAuthRequest } from "expo-auth-session";
import * as WebBrowser from "expo-web-browser";
import { useContext, useEffect, useState } from "react";
import { Platform, ScrollView, TouchableOpacity } from "react-native";

import MODIFY from "../../../../assets/icons/BASE/MODIFY.svg";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BottomButton from "../../../components/BottomButton";
import ErrorMessage from "../../../components/ErrorMessage/index";
import Loader from "../../../components/Loader";
import ScreenHeader from "../../../components/ScreenHeader";
import SelectButton from "../../../components/Select/SelectButton";
import Toggle from "../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { PartnerSettingsFragment } from "../../../graphql/generated/schema";
import {
  useConnectToFacebookMutation,
  useGetMerchantStripeLDashboardLinkLazyQuery,
  useGetPartnerSettingsLazyQuery,
  useSetFbIgSettingsMutation,
  useUpdatePartnerSettingsMutation,
} from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { removeTypeNames } from "../../../utils/common";

interface PartnersProps {
  goBack?: () => void;
}

const discovery: AuthSession.DiscoveryDocument = {
  authorizationEndpoint: "https://www.facebook.com/v15.0/dialog/oauth",
  tokenEndpoint: "https://graph.facebook.com/v15.0/oauth/access_token",
};

const config: AuthSession.AuthRequestConfig = {
  clientId: "1020691309199704",
  scopes: [
    "pages_manage_posts",
    "pages_show_list",
    "pages_read_engagement",
    "pages_manage_posts",
    "instagram_basic",
  ],
  redirectUri: AuthSession.makeRedirectUri({
    native: "fb1020691309199704://authorize",
  }),
  responseType: "token",
  extraParams: {
    display: Platform.select({ web: "popup" }) || "popup",
  },
};

const Partners = ({ goBack }: PartnersProps) => {
  const [loading, setLoading] = useState(true);
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const navigation = useNavigation();
  const [request, response, promptAsync] = useAuthRequest(config, discovery);
  const [partnerSettings, setPartnerSettings] = useState<PartnerSettingsFragment | null>(
    null,
  );
  const [facebookPages, setFacebookPages] = useState<{ label: string; key: string }[]>(
    [],
  );
  const [selectedFbPages, setSelectedFbPages] = useState<string[]>([]);
  const [selectedIgPages, setSelectedIgPages] = useState<string[]>([]);
  const [instagramPages, setInstagramPages] = useState<{ label: string; key: string }[]>(
    [],
  );

  const [connectToFacebook] = useConnectToFacebookMutation();
  const [getStripeDashboardLink] = useGetMerchantStripeLDashboardLinkLazyQuery();
  const [getPartnerSettings] = useGetPartnerSettingsLazyQuery();
  const [updatePartnerSettings] = useUpdatePartnerSettingsMutation();
  const [setFbIgSettings] = useSetFbIgSettingsMutation();

  const handleOpenStripeDashboard = async () => {
    try {
      const { data } = await getStripeDashboardLink();

      if (data) {
        const url = data.getMerchantStripeLoginLink;

        if (url) {
          const result = await WebBrowser.openBrowserAsync(url, {
            windowFeatures: {
              target: "_blank",
            },
          });
        }
        // if (url) {
        //   window.open(url, "_blank");
        // }
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_OPEN_STRIPE_DASHBOARD",
            message: "Une erreur est survenue lors de l'ouverture du dashboard Stripe",
          },
        ],
        "error",
      );
      console.log("err get stripe dashboard link", err);
    }
  };

  const handleGetPartnerSettings = async () => {
    try {
      const { data } = await getPartnerSettings({
        fetchPolicy: "cache-and-network",
      });

      if (data) {
        setPartnerSettings(data.getPartnersSettings);

        const { pages, selectedPages: pagesChosenForFb } =
          data.getPartnersSettings.facebookSettings;

        const { selectedPages: pagesChosenForIg } =
          data.getPartnersSettings.instagramSettings;

        setSelectedFbPages(pagesChosenForFb);
        setSelectedIgPages(pagesChosenForIg);

        const formattedFbPages = pages.map(page => ({
          label: page.name,
          key: page.id,
        }));

        setFacebookPages(formattedFbPages);

        const formattedIgPages = pages
          .filter(p => p.connectedInstagramAccount)
          .map(page => {
            const igName = `${page.name} - ${
              page?.connectedInstagramAccount?.username || ""
            }`.trim();
            return {
              label: igName,
              key: page.id,
            };
          });

        setInstagramPages(formattedIgPages);
      }
    } catch (err) {
      console.log("err get partner settings", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPartnerSettings();
  }, []);

  const handleUpdatePartnerSettings = async (
    key: keyof PartnerSettingsFragment,
    value: boolean,
  ) => {
    setPartnerSettings(prev => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: {
          ...prev[key],
          isEnabled: value,
        },
      };
    });
  };

  const handleConnectToFacebook = async () => {
    try {
      const result = await promptAsync();

      if (result.type === "success") {
        const accessToken = result.params.access_token;

        await connectToFacebook({
          variables: {
            accessToken,
          },
        });

        await handleGetPartnerSettings();
      }
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_CONNECT_TO_FACEBOOK",
            message: "Une erreur est survenue lors de la connexion à Facebook",
          },
        ],
        "error",
      );
    }
  };

  const handleUpdateSettings = async () => {
    try {
      if (!partnerSettings) return;

      const { googleBookingSettings, googleOrdersSettings, popCallSettings } =
        partnerSettings;

      const consolidatedSettings = {
        googleBookingSettings,
        googleOrdersSettings,
        popCallSettings,
      };

      const updates = removeTypeNames(consolidatedSettings);

      await updatePartnerSettings({
        variables: {
          updates,
        },
      });

      await setFbIgSettings({
        variables: {
          fbIgSettings: {
            facebookPages: selectedFbPages,
            instagramPages: selectedIgPages,
            isFacebookEnabled: partnerSettings.facebookSettings.isEnabled,
            isInstagramEnabled: partnerSettings.instagramSettings.isEnabled,
          },
        },
      });
    } catch (err) {
      console.log("err update partner settings", err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!partnerSettings) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader
          title="Partenaires"
          hasBackButton
          onBackPress={goBack || navigation.goBack}
        />

        <ErrorMessage message="Une erreur est survenue lors de la récupération de vos paramètres." />
      </Box>
    );
  }

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title="Partenaires"
        hasBackButton={!goBack}
        onBackPress={goBack || navigation.goBack}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
      >
        <Box>
          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Stripe
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <TouchableOpacity onPress={handleOpenStripeDashboard}>
                  <MODIFY fill={PALETTE.darkBlue} height={ICON_SIZE} width={ICON_SIZE} />
                </TouchableOpacity>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Associer mon compte Stripe pour profiter des fonctionnalités de paiement.
            </CustomText>
          </Box>
          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Google Reserve
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                {/* <TouchableOpacity>
              <MODIFY fill={PALETTE.darkBlue} height={ICON_SIZE} width={ICON_SIZE} />
            </TouchableOpacity> */}
                <Box ml="s">
                  <Toggle
                    value={partnerSettings?.googleBookingSettings?.isEnabled}
                    onChange={(value: boolean) =>
                      handleUpdatePartnerSettings("googleBookingSettings", value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Afficher le bouton REVERSER UNE TABLE dans votre fiche Google My Business.
            </CustomText>
          </Box>
          {/* <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Google commande
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <Box ml="s">
                  <Toggle
                    value={partnerSettings?.googleOrdersSettings?.isEnabled}
                    onChange={(value: boolean) =>
                      handleUpdatePartnerSettings("googleOrdersSettings", value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Afficher le bouton COMMANDER dans votre fiche Google My Business.
            </CustomText>
          </Box> */}
          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Eiwie call
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <Box ml="s">
                  <Toggle
                    value={partnerSettings?.popCallSettings?.isEnabled}
                    onChange={(value: boolean) =>
                      handleUpdatePartnerSettings("popCallSettings", value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Recevez des notifications lorsque vos clients vous appellent.(fiche client,
              numéro de téléphone, statistiques de réservations).
            </CustomText>
          </Box>
          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Facebook
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                <TouchableOpacity onPress={handleConnectToFacebook}>
                  <MODIFY fill={PALETTE.darkBlue} height={ICON_SIZE} width={ICON_SIZE} />
                </TouchableOpacity>
                <Box ml="s">
                  <Toggle
                    value={partnerSettings?.facebookSettings?.isEnabled}
                    onChange={(value: boolean) =>
                      handleUpdatePartnerSettings("facebookSettings", value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Lier votre compte Facebook pour partager directement vos publications en un
              clic.
            </CustomText>

            <Box mt="m">
              <SelectButton
                placeHolder="Pages facebook"
                isMultiSelect
                onPress={setSelectedFbPages}
                options={facebookPages}
                selectedOptions={selectedFbPages || []}
              />
            </Box>
          </Box>
          <Box mt="m">
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <CustomText
                variant="label"
                color="primaryTextColor"
                textTransform="uppercase"
              >
                Instagram
              </CustomText>

              <Box flexDirection="row" alignItems="center">
                {/* <TouchableOpacity>
                  <MODIFY fill={PALETTE.darkBlue} height={ICON_SIZE} width={ICON_SIZE} />
                </TouchableOpacity> */}
                <Box ml="s">
                  <Toggle
                    value={partnerSettings.instagramSettings.isEnabled}
                    onChange={(value: boolean) =>
                      handleUpdatePartnerSettings("instagramSettings", value)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <CustomText variant="content" color="lightGrey">
              Lier votre compte Instagram pour partager directement vos publications en un
              clic
            </CustomText>

            <Box mt="m">
              <SelectButton
                placeHolder="Pages Instagram"
                isMultiSelect
                onPress={setSelectedIgPages}
                options={instagramPages}
                selectedOptions={selectedIgPages || []}
              />
            </Box>
          </Box>
        </Box>
      </ScrollView>
      <BottomButton title="Valider" onPress={handleUpdateSettings} />
    </Box>
  );
};

export default Partners;
