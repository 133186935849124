import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { FlatList } from "react-native";

import Box from "../../../../components/Base/Box";
import Loader from "../../../../components/Loader";
import PrinterErrorReportListItem from "../../../../components/Printers/PrinterErrorReportListItem";
import ScreenHeader from "../../../../components/ScreenHeader/index";
import SelectButton from "../../../../components/Select/SelectButton";
import type {
  Pagination,
  PrinterErrorFragment,
  PrinterFragment,
} from "../../../../graphql/generated/schema";
import {
  useGetPrinterErrorsLazyQuery,
  useGetPrintersLazyQuery,
} from "../../../../graphql/generated/schema";

const PrinterErrorReports = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10, offset: 0 });
  const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const [printerId, setPrinterId] = useState("ALL");
  const [printersInNetwork, setPrintersInNetwork] = useState<
    { label: string; key: string }[]
  >([]);
  const [printerErrors, setPrinterErrors] = useState<PrinterErrorFragment[]>([]);
  const [getPrinterErrors] = useGetPrinterErrorsLazyQuery();

  const [getPrinters] = useGetPrintersLazyQuery();

  const filteredPrinterErrors = useMemo(() => {
    if (printerId === "ALL") return printerErrors;

    return printerErrors.filter(p => p.printer._id === printerId);
  }, [printerErrors, printerId]);

  const handleGetPrinters = async () => {
    try {
      const { data } = await getPrinters({
        variables: { pagination },
        fetchPolicy: "network-only",
      });

      if (data) {
        setPrinters(data.getPrinters);

        const formattedPrinters = data.getPrinters.map(p => {
          const label = `${p.name}  (${p.ipAddress})`;
          return { label, key: p._id };
        });

        const ALL_PRINTERS = {
          label: "Toutes les imprimantes",
          key: "ALL",
        };

        setPrintersInNetwork([ALL_PRINTERS, ...formattedPrinters]);
      }
    } catch (err) {
      console.log("err get printers", err);
    }
  };

  const handleGetPrinterError = async () => {
    try {
      const { data, error } = await getPrinterErrors({
        variables: { pagination },
        fetchPolicy: "network-only",
      });

      if (data?.getPrinterErrors) {
        setPrinterErrors(data.getPrinterErrors);
      }
    } catch (err) {
      console.log("err get printers", err);
    }
  };

  const handleInit = async () => {
    try {
      await handleGetPrinters();
      await handleGetPrinterError();
    } catch (err) {
      console.log("err init ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSelectPrinter = (printerIds: string[]) => {
    setPrinterId(printerIds[0]);
  };

  if (loading) return <Loader />;

  return (
    <Box flex={1} backgroundColor="white" pt="m" paddingHorizontal="s">
      <ScreenHeader
        title="Rapports d'erreurs"
        hasBackButton
        onBackPress={navigation.goBack}
      />

      <Box mt="s">
        <SelectButton
          isMultiSelect={false}
          selectedOptions={printerId ? [printerId] : []}
          placeHolder="Toutes les imprimantes"
          options={printersInNetwork}
          onPress={handleSelectPrinter}
        />
      </Box>

      <Box>
        <FlatList
          data={filteredPrinterErrors}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <Box
              paddingHorizontal="s"
              borderBottomWidth={1}
              borderBottomColor="lightGrey"
              paddingVertical="s"
            >
              <PrinterErrorReportListItem printerError={item} />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default PrinterErrorReports;
