import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useMemo, useState } from "react";
import { ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import InlineSelectButton from "../../../../../../components/InlineSelectButton/index";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import SelectButton from "../../../../../../components/Select/SelectButton/index";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateGroupOption,
  Pagination,
  ProductGroupOptionFragment,
  TaxFragment,
} from "../../../../../../graphql/generated/schema";
import {
  GetProductGroupOptionsDocument,
  useCreateProductGroupOptionMutation,
  useDeleteProductGroupOptionMutation,
  useGetProductGroupOptionLazyQuery,
  useGetTaxesLazyQuery,
  useUpdateProductGroupOptionMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import {
  formaYupErrors,
  getSafeNumberFromInput,
  removeTypeNames,
} from "../../../../../../utils/common";

interface GroupOptionsListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const DEFAULT_GROUP_OPTION: CreateGroupOption = {
  baseAvailability: {
    dates: [],
    days: [],
    endTime: "",
    startTime: "",
  },
  basePrice: {
    isEnabled: true,
    amount: 0,
    tax: "",
    updatedTaxRate: 0,
  },
  description: "",
  isEnabled: true,
  name: "",
  options: [],
  pricingRates: [],
  selectionSettings: {
    allowDuplicate: false,
    authoriseExtraSelection: false,
    extraPrice: {
      isEnabled: true,
      amount: 0,
      tax: "",
      updatedTaxRate: 0,
    },
    maxExtraSelection: 0,
    maxSelection: 0,
    minSelection: 0,
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Vous devez entrer un nom"),
  options: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .min(1, "Vous devez sélectionner au moins une option")
    .required("Vous devez sélectionner au moins une option"),
  basePrice: yup
    .object()
    .shape({
      isEnabled: yup.boolean().required(),
      amount: yup.number().min(0).required("Vous devez entrer un prix supérieur à 0"),
      tax: yup.string().when("amount", {
        is: (amount: number) => amount > 0,
        then: yup.string().required("Vous devez sélectionner une taxe"),
      }),
    })
    .required(),
  selectionSettings: yup
    .object()
    .shape({
      allowDuplicate: yup.boolean().required(),
      authoriseExtraSelection: yup.boolean().required(),
      minSelection: yup.number().min(0).required(),
      maxSelection: yup
        .number()
        .min(0)
        .when("minSelection", {
          is: (minSelection: number) => minSelection > 0,
          then: yup.number().min(yup.ref("minSelection")).required(),
        }),
      maxExtraSelection: yup.number().when("authoriseExtraSelection", {
        is: true,
        then: yup.number().min(1).required(),
      }),
      extraPrice: yup
        .object()
        .shape({
          isEnabled: yup.boolean().required(),
          amount: yup.number().min(0).required(),
          tax: yup.string().when("amount", {
            is: (amount: number) => amount > 0,
            then: yup.string().required("Vous devez sélectionner une taxe"),
          }),
        })
        .required(),
    })
    .required(),
});

const GroupOptionsListDetailsEdit = ({
  id,
  goBack,
}: GroupOptionsListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const isNew = !id;
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState<TaxFragment[]>([]);
  const [groupOption, setgroupOption] =
    useState<ProductGroupOptionFragment>(DEFAULT_GROUP_OPTION);
  const [errors, setErrors] = useState<ERROR[]>([]);

  const [getTaxes] = useGetTaxesLazyQuery();
  const [getGroupOption] = useGetProductGroupOptionLazyQuery();
  const [createGroupOption] = useCreateProductGroupOptionMutation();
  const [updateGroupOption] = useUpdateProductGroupOptionMutation();
  const [deleteGroupOption] = useDeleteProductGroupOptionMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const updateGroupOptionData = (
    key: keyof ProductGroupOptionFragment,
    value: string | string[] | number | boolean,
  ) => {
    setgroupOption(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateGroupOptionSelectionData = (
    key: keyof ProductGroupOptionFragment["selectionSettings"],
    value: number | boolean,
  ) => {
    setgroupOption(prev => ({
      ...prev,
      selectionSettings: {
        ...prev.selectionSettings,
        [key]: value,
      },
    }));
  };

  const updateGroupOptionPrieData = (
    key: keyof ProductGroupOptionFragment["basePrice"],
    value: number | string,
  ) => {
    setgroupOption(prev => ({
      ...prev,
      basePrice: {
        ...prev.basePrice,
        [key]: value,
      },
    }));
  };

  const updateGroupOptionExtraPrieData = (
    key: keyof ProductGroupOptionFragment["selectionSettings"]["extraPrice"],
    value: number | string,
  ) => {
    setgroupOption(prev => ({
      ...prev,
      selectionSettings: {
        ...prev.selectionSettings,
        extraPrice: {
          ...prev.selectionSettings.extraPrice,
          [key]: value,
        },
      },
    }));
  };

  const updateBasePriceAmount = (value: string) => {
    const amt = +value;
    if (isNaN(amt)) return updateGroupOptionPrieData("amount", 0);

    updateGroupOptionPrieData("amount", amt);
  };

  const updateExtraPriceAmount = (value: string) => {
    const amt = +value;
    if (isNaN(amt)) return updateGroupOptionExtraPrieData("amount", 0);

    updateGroupOptionExtraPrieData("amount", amt);
  };

  const handleSortOptions = (ids: string[]) => {
    const sortedOptions = ids.map(selectedId => {
      const option = groupOption.options.find(o => o._id === selectedId);
      return option;
    });
    updateGroupOptionData("options", sortedOptions);
  };

  const handleRemoveOption = (optionId: string) => {
    setgroupOption(prev => ({
      ...prev,
      options: prev.options.filter(option => option._id !== optionId),
    }));
  };

  const handleGoToAddOption = () => {
    navigation.navigate("GROUP_OPTIONS_LIST_DETAILS_ADD_OPTION", {
      selectedOptions: groupOption.options,
      onSubmitOptions: (options: string[]) => updateGroupOptionData("options", options),
    });
  };

  const taxSelectOptions = useMemo(
    () => taxes.map(tax => ({ label: tax.name, key: tax._id })),
    [taxes],
  );

  const handleGetTaxes = async () => {
    try {
      const pagination: Pagination = {
        limit: 100,
        offset: 0,
      };
      const { data } = await getTaxes({
        variables: { pagination },
      });
      if (data) {
        setTaxes(JSON.parse(JSON.stringify(data.getTaxes)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetGroupOption = async () => {
    try {
      if (id) {
        const { data } = await getGroupOption({
          variables: { id },
        });
        if (data) {
          const formattedGroupOption = {
            ...data.getProductGroupOption,
            basePrice: {
              ...data.getProductGroupOption.basePrice,
              tax: data.getProductGroupOption.basePrice.tax?._id || "",
            },
            selectionSettings: {
              ...data.getProductGroupOption.selectionSettings,
              extraPrice: {
                ...data.getProductGroupOption.selectionSettings.extraPrice,
                tax:
                  data.getProductGroupOption.selectionSettings.extraPrice.tax?._id || "",
              },
            },
          };
          setgroupOption(formattedGroupOption);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTaxes();
    handleGetGroupOption();
  }, []);

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const validateForm = async () => {
    try {
      await schema.validate(groupOption, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const options = groupOption.options.map(option => option._id);
      const consolidatedGroupOption = {
        ...groupOption,
        options,
      };

      if (isNew) {
        await createGroupOption({
          variables: {
            groupOption: consolidatedGroupOption,
          },
          refetchQueries: [
            {
              query: GetProductGroupOptionsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consolidatedGroupOption);
        delete updates._id;
        delete updates.nbProducts;

        await updateGroupOption({
          variables: {
            id,
            groupOption: updates,
          },
          refetchQueries: [
            {
              query: GetProductGroupOptionsDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteGroupOption({
          variables: {
            id,
          },
          // refetchQueries: [
          //   {
          //     query: GetProductGroupOptionsDocument,
          //     variables: { pagination: { limit: 10, offset: 0 } },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "GroupOption",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        handleGoBack();
      }
    } catch (err) {
      console.log("err delete", JSON.stringify(err, null, 2));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
  };

  if (loading) {
    return <Loader />;
  }

  const displayTitle = isNew ? "Nouvelle groupe d'option" : "Modifier le group d'option";

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Nom du groupe d'option"
                hasErrors={doesInputHaveError("name")}
                initialValue={groupOption.name}
                onChangeText={t => updateGroupOptionData("name", t)}
                isRequired
              />
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Description du groupe d'option"
                multiLine
                initialValue={groupOption.description}
                onChangeText={t => updateGroupOptionData("description", t)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Minimum
              </CustomText>
              <CustomTextInput
                placeHolder="0"
                keyboardType="number-pad"
                hideFloatingLabel
                hasErrors={doesInputHaveError("selectionSettings.minSelection")}
                value={groupOption.selectionSettings.minSelection.toString()}
                onChangeText={t => updateGroupOptionSelectionData("minSelection", t)}
                onBlur={t =>
                  updateGroupOptionSelectionData(
                    "minSelection",
                    getSafeNumberFromInput(t),
                  )
                }
              />
            </Box>
            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Maximum
              </CustomText>
              <CustomTextInput
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("selectionSettings.maxSelection")}
                value={groupOption.selectionSettings.maxSelection.toString()}
                onChangeText={t => updateGroupOptionSelectionData("maxSelection", t)}
                onBlur={t =>
                  updateGroupOptionSelectionData(
                    "maxSelection",
                    getSafeNumberFromInput(t),
                  )
                }
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 0.8, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Autoriser une option à être sélectionnée plusieurs fois
              </CustomText>
              <Toggle
                value={groupOption.selectionSettings.allowDuplicate}
                onChange={v => updateGroupOptionSelectionData("allowDuplicate", v)}
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 0.8, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Dépasser la quantité maximum moyennant un supplément
              </CustomText>
              <Toggle
                value={groupOption.selectionSettings.authoriseExtraSelection}
                onChange={v =>
                  updateGroupOptionSelectionData("authoriseExtraSelection", v)
                }
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Max extra selection
              </CustomText>
              <CustomTextInput
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("selectionSettings.maxExtraSelection")}
                value={groupOption.selectionSettings.maxExtraSelection.toString()}
                onChangeText={t => updateGroupOptionSelectionData("maxExtraSelection", t)}
                onBlur={t =>
                  updateGroupOptionSelectionData(
                    "maxExtraSelection",
                    getSafeNumberFromInput(t, false),
                  )
                }
              />
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="content"
                color="primaryTextColor"
              >
                Supplément extra selection
              </CustomText>
              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  variant="content"
                  placeHolder="Prix"
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("selectionSettings.extraPrice.amount")}
                  value={groupOption.selectionSettings.extraPrice.amount.toString()}
                  onChangeText={t => updateGroupOptionExtraPrieData("amount", t)}
                  onBlur={t =>
                    updateGroupOptionExtraPrieData("amount", getSafeNumberFromInput(t))
                  }
                />
                <Box ml="s">
                  <SelectButton
                    selectedTextVariant="content"
                    placeHolderTextVariant="content"
                    isMultiSelect={false}
                    options={taxSelectOptions}
                    hasErrors={doesInputHaveError("selectionSettings.extraPrice.tax")}
                    selectedOptions={
                      groupOption.selectionSettings.extraPrice.tax
                        ? [groupOption.selectionSettings.extraPrice.tax]
                        : []
                    }
                    onPress={([tax]) => updateGroupOptionExtraPrieData("tax", tax)}
                    placeHolder="Taxe"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <CustomText variant="content" color="primaryTextColor">
                Supplément
              </CustomText>
              <Box flexDirection="row" alignItems="flex-start">
                <CustomTextInput
                  variant="content"
                  placeHolder="Prix"
                  keyboardType="decimal-pad"
                  hasErrors={doesInputHaveError("basePrice.amount")}
                  value={groupOption.basePrice.amount.toString()}
                  onChangeText={t => updateGroupOptionPrieData("amount", t)}
                  onBlur={t =>
                    updateGroupOptionPrieData("amount", getSafeNumberFromInput(t))
                  }
                />
                <Box ml="s">
                  <SelectButton
                    selectedTextVariant="content"
                    placeHolderTextVariant="content"
                    isMultiSelect={false}
                    options={taxSelectOptions}
                    hasErrors={doesInputHaveError("basePrice.tax")}
                    selectedOptions={
                      groupOption.basePrice.tax ? [groupOption.basePrice.tax] : []
                    }
                    onPress={([tax]) => updateGroupOptionPrieData("tax", tax)}
                    placeHolder="Taxe"
                  />
                </Box>
              </Box>
            </Box>

            <Box mt="m">
              <CustomText
                mb="s"
                variant="content"
                color="success"
                textTransform="uppercase"
              >
                Contenu
              </CustomText>

              <Box flex={1} alignItems="flex-start">
                <DraggableFlatList
                  scrollEnabled={false}
                  data={[...groupOption.options]}
                  style={{ width: "100%" }}
                  containerStyle={{ width: "100%" }}
                  ListHeaderComponent={
                    <TouchableOpacity onPress={handleGoToAddOption}>
                      <Box
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mr="s"
                        marginVertical="s"
                      >
                        <Box flex={0.95}>
                          <InlineSelectButton
                            title="Ajouter une option"
                            options={[]}
                            selectedOptionKeys={[]}
                            isMultiSelect={false}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onPress={() => {}}
                          />
                        </Box>

                        <PLUS fill={PALETTE.green} width={15} height={15} />
                      </Box>
                    </TouchableOpacity>
                  }
                  renderItem={({ item, drag }) => (
                    <TouchableOpacity
                      onPressIn={drag}
                      style={{
                        marginVertical: 8,
                        marginRight: 8,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box flex={0.95}>
                        <InlineSelectButton
                          isDraggable
                          title={item.name}
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <TouchableOpacity onPress={() => handleRemoveOption(item._id)}>
                        <TRASH fill={PALETTE.red} width={15} height={15} />
                      </TouchableOpacity>
                    </TouchableOpacity>
                  )}
                  keyExtractor={item => `draggable-item-${item._id}`}
                  onDragEnd={({ data: sortedData }) => {
                    const ids = sortedData.slice().map(item => item._id);
                    handleSortOptions(ids);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>
    </>
  );
};

export default GroupOptionsListDetailsEdit;
