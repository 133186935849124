import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";

import AddImageModal from "../../../../components/AddImageModal";
import Box from "../../../../components/Base/Box";
import BottomButton from "../../../../components/BottomButton";
import ErrorMessage from "../../../../components/ErrorMessage/index";
import Loader from "../../../../components/Loader";
import ScreenHeader from "../../../../components/ScreenHeader";
import { CustomTextInput } from "../../../../components/TextInput";
import { ErrorInfoSuccessAlertModalContext } from "../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type { MerchantFragment } from "../../../../graphql/generated/schema";
import {
  useGetMerchantLazyQuery,
  useUpdateMerchantMutation,
} from "../../../../graphql/generated/schema";
import { removeTypeNames } from "../../../../utils/common";

interface MerchantChefProps {
  goBack?: () => void;
}

const MerchantChef = ({ goBack }: MerchantChefProps) => {
  const navigation = useNavigation();
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);

  const [getMerchant] = useGetMerchantLazyQuery();
  const [updateMerchant] = useUpdateMerchantMutation();

  const handleGetMerchant = async () => {
    setLoading(true);

    try {
      const { data } = await getMerchant();

      if (data) {
        setMerchant(data.getMerchant);
      }
    } catch (err) {
      console.log("err get merchant", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMerchant();
  }, []);

  const handleGoBack = () => {
    if (goBack) goBack();
    else navigation.goBack();
  };

  const handleSubmit = async () => {
    // const isFormValid = await validateForm();

    // if (!isFormValid) return;

    try {
      const updates = removeTypeNames({
        chef: merchant?.chef,
      });
      delete updates._id;

      await updateMerchant({
        variables: { updates },
      });

      handleGoBack();
    } catch (err) {
      console.log("err submit", err, JSON.stringify(err, null, 2));

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UPDATE_MERCHANT",
            message: "Une erreur est survenue lors de la mise à jour du chef",
          },
        ],
        "error",
      );

      // setErrors([{ path: "submit", message: err.message }]);

      // setIsSnackBarOpen(true);
    }
  };

  const handleChefImage = (image?: string) => {
    if (!image) return;

    setMerchant(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        chef: {
          ...prev.chef,
          image,
        },
      };
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (!merchant) {
    return (
      <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
        <Box mb="s">
          <ScreenHeader title="Chef" hasBackButton onBackPress={handleGoBack} />
        </Box>

        <ErrorMessage message="Une erreur est survenue lors de la récupération des informations du chef." />
      </Box>
    );
  }

  return (
    <Box flex={1} pt="m" paddingHorizontal="s" backgroundColor="white">
      <Box mb="s">
        <ScreenHeader title="Chef" hasBackButton onBackPress={handleGoBack} />
      </Box>

      <AddImageModal picture={merchant?.chef?.image} onSubmit={handleChefImage} />

      <Box mt="m">
        <CustomTextInput
          placeHolder="Nom"
          initialValue={merchant?.chef?.name}
          onChangeText={name => {
            setMerchant(prev => {
              if (!prev) return prev;
              return {
                ...prev,
                chef: {
                  ...prev.chef,
                  name,
                },
              };
            });
          }}
        />
      </Box>

      <Box mt="m">
        <CustomTextInput
          placeHolder="Commentaire"
          initialValue={merchant?.chef?.comment}
          multiLine
          onChangeText={comment => {
            setMerchant(prev => {
              if (!prev) return prev;
              return {
                ...prev,
                chef: {
                  ...prev.chef,
                  comment,
                },
              };
            });
          }}
        />
      </Box>

      <BottomButton title="Valider" onPress={handleSubmit} />
    </Box>
  );
};

export default MerchantChef;
