import { useEffect, useState } from "react";
import type { LayoutRectangle } from "react-native";
import { Dimensions } from "react-native";

import { SPLIT_VIEW_TYPE, SplitViewContext } from "../../contexts/SpitViewContext";
import { ORIENTATION, useDeviceInformation } from "../../hooks/useOrientation";
import Box from "../Base/Box";

interface SplitViewProps {
  leftComponent: JSX.Element;
  rightComponent?: JSX.Element;
}

const SplitView = ({ leftComponent, rightComponent }: SplitViewProps) => {
  const [viewType, setViewType] = useState(
    rightComponent ? SPLIT_VIEW_TYPE.SPLIT : SPLIT_VIEW_TYPE.FULL_LEFT,
  );
  const [screenContainer, setScreenContainer] = useState<LayoutRectangle | undefined>();
  const [leftContainer, setLeftContainer] = useState<LayoutRectangle | undefined>();
  const [rightContainer, setRightContainer] = useState<LayoutRectangle | undefined>();
  const [isSplittable] = useState(Dimensions.get("window").width > 600);
  const { orientation } = useDeviceInformation();

  useEffect(() => {
    setViewType(rightComponent ? SPLIT_VIEW_TYPE.SPLIT : SPLIT_VIEW_TYPE.FULL_LEFT);
  }, [rightComponent]);

  const getFlexForComponent = (side: "left" | "right") => {
    if (viewType === SPLIT_VIEW_TYPE.SPLIT && side === "left") {
      if (orientation === ORIENTATION.LANDSCAPE) return 0.4;
      return 0.5;
    } else if (viewType === SPLIT_VIEW_TYPE.SPLIT && side === "right") {
      if (orientation === ORIENTATION.LANDSCAPE) return 0.7;
      return 0.5;
    } else if (viewType === SPLIT_VIEW_TYPE.FULL_LEFT) {
      if (side === "left") return 1;
      else return 0;
    } else if (viewType === SPLIT_VIEW_TYPE.FULL_RIGHT) {
      if (side === "right") return 1;
      else return 0;
    }

    return 1;
  };

  const setLayout = (side: "full" | "left" | "right", layout: LayoutRectangle) => {
    if (side === "full") {
      setScreenContainer(layout);
    } else if (side === "left") {
      setLeftContainer(layout);
    } else if (side === "right") {
      setRightContainer(layout);
    }
  };

  return (
    <SplitViewContext.Provider
      value={{
        viewType,
        setViewType,
        screenContainer,
        leftContainer,
        rightContainer,
        isSplittable,
      }}
    >
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        onLayout={e => setLayout("full", e.nativeEvent.layout)}
      >
        <Box
          height="100%"
          flex={getFlexForComponent("left")}
          onLayout={e => setLayout("left", e.nativeEvent.layout)}
        >
          {leftComponent}
        </Box>
        <Box
          height="100%"
          flex={getFlexForComponent("right")}
          onLayout={e => setLayout("right", e.nativeEvent.layout)}
        >
          {rightComponent}
        </Box>
      </Box>
    </SplitViewContext.Provider>
  );
};

export default SplitView;
