import type { Dispatch, SetStateAction } from "react";

import type {
  BookingPersonsConfiguration,
  CreateBookingAvailabilityInput,
  MinMaxConfiguration,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import type { ERROR } from "../../../utils/common";
import { getSafeNumberFromInput } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import NumberInputLine from "../../NumberInputLine/index";
import { CustomTextInput } from "../../TextInput";
import ToggleInputLine from "../../ToggleInputLine";

interface ServicePersonsLimitsProps {
  bookingAvailability: CreateBookingAvailabilityInput;
  setBookingAvailability: Dispatch<SetStateAction<CreateBookingAvailabilityInput>>;
  errors: ERROR[];
}

const ServicePersonsLimits = ({
  bookingAvailability,
  setBookingAvailability,
  errors,
}: ServicePersonsLimitsProps) => {
  const updateMinMaxConfigByKey = (
    key: "totalPersonsAllowed" | "maxCapacityAllowedForSlot",
    innerKey: keyof MinMaxConfiguration,
    value: boolean | number,
  ) => {
    setBookingAvailability(prev => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          [innerKey]: value,
        },
      };
    });
  };

  const updateBookingPersonsLimits = (
    key: keyof BookingPersonsConfiguration,
    value: number | boolean,
  ) => {
    setBookingAvailability(prev => {
      return {
        ...prev,
        minMaxBookingPersons: {
          ...prev.minMaxBookingPersons,
          [key]: value,
        },
      };
    });
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };
  return (
    <Box>
      <Box
        mt="s"
        backgroundColor="disabled"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        <CustomText variant="content" color="primaryTextColor" textTransform="uppercase">
          LIMITATION
        </CustomText>
      </Box>

      <Box pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box mt="m">
          <ToggleInputLine
            textVariant="content"
            text="Limiter la capacité de l’établissement "
            value={bookingAvailability.totalPersonsAllowed.isEnabled}
            onValueChange={v =>
              updateMinMaxConfigByKey("totalPersonsAllowed", "isEnabled", v)
            }
          />
          <CustomText variant="content" color="lightGrey">
            Cela vous permet de gérer le nombre couverts et de réservations autorisés pour
            ce service.
          </CustomText>
        </Box>
        {bookingAvailability.totalPersonsAllowed.isEnabled && (
          <Box mt="s" ml="m">
            <NumberInputLine
              hasErrors={doesInputHaveError("totalPersonsAllowed.value")}
              textVariant="content"
              placeHolder="0"
              text="Capacité totale"
              value={bookingAvailability.totalPersonsAllowed.value}
              onValueChange={v => {
                updateMinMaxConfigByKey(
                  "totalPersonsAllowed",
                  "value",
                  getSafeNumberFromInput(v),
                );
              }}
            />

            <CustomText variant="content" color="lightGrey">
              Couverts disponibles à la réservation dans votre établissement
            </CustomText>
          </Box>
        )}
      </Box>
      <Box mt="m" pb="s" borderBottomColor="disabled" borderBottomWidth={LINE_THICKNESS}>
        <Box>
          <ToggleInputLine
            textVariant="content"
            text="Limiter le nombre de pax par créneau"
            value={bookingAvailability.maxCapacityAllowedForSlot.isEnabled}
            onValueChange={v =>
              updateMinMaxConfigByKey("maxCapacityAllowedForSlot", "isEnabled", v)
            }
          />
          <CustomText variant="content" color="lightGrey">
            Indiquez le maximum de couverts autorisés pour chaque créneau de réservation
          </CustomText>
        </Box>

        {bookingAvailability.maxCapacityAllowedForSlot.isEnabled && (
          <Box
            ml="m"
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="lightGrey">
              Nombre de couverts
            </CustomText>
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                hasErrors={doesInputHaveError("maxCapacityAllowedForSlot.value")}
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                value={bookingAvailability.maxCapacityAllowedForSlot.value?.toString()}
                onChangeText={v =>
                  updateMinMaxConfigByKey(
                    "maxCapacityAllowedForSlot",
                    "value",
                    getSafeNumberFromInput(v),
                  )
                }
                inputStyles={{ textAlign: "center" }}
              />
              {bookingAvailability?.totalPersonsAllowed?.isEnabled &&
                bookingAvailability?.totalPersonsAllowed?.value > 0 && (
                  <Box ml="s">
                    <CustomTextInput
                      placeHolder="50"
                      hideFloatingLabel
                      disabled
                      initialValue={bookingAvailability.totalPersonsAllowed.value?.toString()}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChangeText={() => {}}
                      inputStyles={{ textAlign: "center" }}
                    />
                  </Box>
                )}
            </Box>
          </Box>
        )}
      </Box>

      <Box mt="m">
        <Box>
          <ToggleInputLine
            textVariant="content"
            text="Couverts acceptés par réservation"
            value={bookingAvailability.minMaxBookingPersons.isEnabled}
            onValueChange={v => updateBookingPersonsLimits("isEnabled", v)}
          />
          <CustomText variant="content" color="lightGrey">
            Autoriser un minimum et maximum de couverts acceptées pour une réservation
          </CustomText>
        </Box>

        {bookingAvailability.minMaxBookingPersons.isEnabled && (
          <Box
            ml="m"
            mt="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomText variant="content" color="lightGrey">
              Limite de couverts
            </CustomText>
            <Box flexDirection="row" alignItems="center">
              <CustomTextInput
                placeHolder="0"
                hideFloatingLabel
                keyboardType="number-pad"
                hasErrors={doesInputHaveError("minMaxBookingPersons.minPersons")}
                value={bookingAvailability.minMaxBookingPersons.minPersons.toString()}
                onChangeText={v =>
                  updateBookingPersonsLimits("minPersons", getSafeNumberFromInput(v))
                }
                inputStyles={{ textAlign: "center" }}
              />

              <Box ml="s">
                <CustomTextInput
                  placeHolder="50"
                  hideFloatingLabel
                  keyboardType="number-pad"
                  hasErrors={doesInputHaveError("minMaxBookingPersons.maxPersons")}
                  value={bookingAvailability.minMaxBookingPersons.maxPersons.toString()}
                  onChangeText={v =>
                    updateBookingPersonsLimits("maxPersons", getSafeNumberFromInput(v))
                  }
                  inputStyles={{ textAlign: "center" }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServicePersonsLimits;
