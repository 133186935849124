import INFO from "../../../../assets/icons/BASE/INFO.svg";
import type { OrderFragment } from "../../../graphql/generated/schema";
import { ICON_SIZE } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { formatCurrencyPrice } from "../../../utils/common";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";

interface DisplayOrderItemsProps {
  items: OrderFragment["items"];
  title: string;
}

const DisplayOrderItems = ({ items, title }: DisplayOrderItemsProps) => {
  return (
    <Box
      minHeight={80}
      justifyContent="center"
      mt="s"
      borderBottomColor="disabled"
      borderBottomWidth={1}
      pb="s"
    >
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          <Box width={40}>
            <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />
          </Box>
          <CustomText variant="label" color="labelColor">
            {title}
          </CustomText>
        </Box>
      </Box>

      {items.menus.length > 0 && (
        <Box mb="s">
          {items.menus.map(menu => {
            const formattedPrice = formatCurrencyPrice(
              menu?.accumulatedTotalAmount,
              "fr",
              "eur",
            );
            const allProductsInMenu = menu.steps
              .map(step => step.products.map(p => p.name))
              .flat()
              .join(", ");
            return (
              <Box
                key={menu.menuId}
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
                style={{
                  paddingLeft: 40,
                }}
              >
                <Box>
                  <Box flexDirection="row" alignItems="center">
                    <CustomText variant="description" color="primaryTextColor">
                      {menu.quantity}x
                    </CustomText>
                    <CustomText pl="s" variant="description" color="primaryTextColor">
                      {menu.name}
                    </CustomText>
                  </Box>
                  {allProductsInMenu.length > 0 && (
                    <CustomText
                      style={{ paddingLeft: 20 }}
                      variant="description"
                      color="lightGrey"
                    >
                      {allProductsInMenu}
                    </CustomText>
                  )}
                </Box>
                <Box>
                  <CustomText variant="description" color="primaryTextColor">
                    {formattedPrice}
                  </CustomText>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}

      {items.products.length > 0 && (
        <Box mb="s">
          {items.products.map(product => {
            const formattedPrice = formatCurrencyPrice(
              product.accumulatedTotalAmount,
              "fr",
              "eur",
            );
            const allOptions = product.groupOptions
              .map(gOption => gOption.options.map(option => option.name))
              .flat()
              .join(", ");
            return (
              <Box
                key={product.productId}
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
                style={{
                  paddingLeft: 40,
                }}
              >
                <Box>
                  <Box flexDirection="row" alignItems="center">
                    <CustomText variant="description" color="primaryTextColor">
                      {product.quantity}x
                    </CustomText>
                    <CustomText pl="s" variant="description" color="primaryTextColor">
                      {product.name}
                    </CustomText>
                  </Box>
                  {allOptions.length > 0 && (
                    <CustomText
                      style={{ paddingLeft: 20 }}
                      variant="description"
                      color="lightGrey"
                    >
                      {allOptions}
                    </CustomText>
                  )}
                </Box>
                <Box>
                  <CustomText variant="description" color="primaryTextColor">
                    {formattedPrice}
                  </CustomText>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default DisplayOrderItems;
