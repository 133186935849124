import { createContext } from "react";

export interface SwitchStaffAccountInterface {
  isStaffAccountModalOpen: boolean;

  setIsStaffAccountModalOpen: (isOpen: boolean) => void;
}

export const SwitchStaffAccountContext = createContext<SwitchStaffAccountInterface>(
  {} as SwitchStaffAccountInterface,
);
