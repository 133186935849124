import { createNativeStackNavigator } from "@react-navigation/native-stack";

import PublicationDetails from "../../../components/Publication/PublicationDetails";
import PublicationVisbility from "../../../components/Publication/PublicationVisibility";
import PublicationList from "../../../screens/Publication/PublicationList";
import PublicationListDetails from "../../../screens/Publication/PublicationListDetails";

import type { PublicationStackParamList } from "./ParamList";

const Stack = createNativeStackNavigator<PublicationStackParamList>();

const PublicationStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
        // header: () => <MyTabs />,
      }}
      initialRouteName="PUBLICATION_LIST"
    >
      <Stack.Screen name="PUBLICATION_LIST" component={PublicationList} />
      <Stack.Screen name="PUBLICATION_LIST_DETAILS" component={PublicationListDetails} />
      <Stack.Screen name="NEW_PUBLICATION" component={PublicationDetails} />
      <Stack.Screen name="PUBLICATION_VISIBILITY" component={PublicationVisbility} />
    </Stack.Navigator>
  );
};

export default PublicationStack;
